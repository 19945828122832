import { Fragment, useState, useEffect } from 'react';

import { canUseLocalStorage } from '@utils/localstorage';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';
import { DownloadFormsModal } from '../../../../company-information.react/components/download-forms-modal';
import { UploadFormsModal } from '../../../../company-information.react/components/upload-forms-modal';
import { MultiEinBanksTable } from '../../../components/multi-ein-banks-table';

import { reloadCurrentPage, BANK_SUBMISSION_MESSAGE_KEY, BANK_SUBMISSION_MESSAGE } from '../../../utils/shared';
import { uuidGenerator } from '../../../../company-information.react/utils/shared';
import { AccountVerificationModal } from '../../../components/account-verification-modal/account-verification-modal';

export function MultiCompaniesStateComponent(props) {
	const { companies, onVerificationModalSubmit, onUploadForms, isProcessing, onFileDownload, plaidLinkToken, handleLinkSuccess } =
		props;

	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
	const [isVerificationsModalOpen, setIsVerificationsModalOpen] = useState(false);

	const [selectedBank, setSelectedBank] = useState({});
	const [selectedCompany, setSelectedCompany] = useState({});

	useEffect(() => {
		if (canUseLocalStorage) {
			const showMessage = localStorage.getItem(BANK_SUBMISSION_MESSAGE_KEY);
			const message = localStorage.getItem(BANK_SUBMISSION_MESSAGE);
			if (showMessage && message) {
				window.requestAnimationFrame(() => {
					window.requestAnimationFrame(() => {
						window.setMessage(message, 'success');
						localStorage.removeItem(BANK_SUBMISSION_MESSAGE_KEY);
						localStorage.removeItem(BANK_SUBMISSION_MESSAGE);
					});
				});
			}
		}
	}, []);

	const popDownloadFormsModal = (data) => {
		setSelectedBank(data);
		setSelectedCompanyByClientId(data.clientId);
		setIsDownloadModalOpen(true);
	};

	const popUploadFormsModal = (data) => {
		setSelectedBank(data);
		setSelectedCompanyByClientId(data.clientId);
		setIsUploadModalOpen(true);
	};

	const popVerificationsModal = (data) => {
		setSelectedBank(data);
		setSelectedCompanyByClientId(data.clientId);
		setIsVerificationsModalOpen(true);
	};

	const closeDownloadModal = () => {
		setIsDownloadModalOpen(false);
	};

	const closeUploadModal = () => {
		setIsUploadModalOpen(false);
	};

	const closeVerificationsModal = () => {
		setIsVerificationsModalOpen(false);
	};

	const setSelectedCompanyByClientId = (clientId) => {
		const { companies } = props;

		companies.forEach((company) => {
			if (company.clientId === clientId) {
				setSelectedCompany(company);
			}
		});
	};

	const handleVerificationsModalSubmit = () => {
		const { onVerificationModalSubmit } = props;

		setIsVerificationsModalOpen(false);
		onVerificationModalSubmit();
	};

	const submitDownloadModal = () => {
		reloadCurrentPage();
	};

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager buttonFunction={false} companies={companies} isMultiEin={true} isMultiEinBank={true} />

			<MultiEinBanksTable
				companies={companies}
				handleLinkSuccess={handleLinkSuccess}
				handleOpenDownloadModal={popDownloadFormsModal}
				handleOpenUploadModal={popUploadFormsModal}
				handleOpenVerificationModal={popVerificationsModal}
				plaidLinkToken={plaidLinkToken}
			/>

			<DownloadFormsModal
				company={selectedBank || {}}
				isProcessing={isProcessing}
				onClose={closeDownloadModal}
				onFileDownload={onFileDownload}
				onSubmit={submitDownloadModal}
				type='bank'
				visible={isDownloadModalOpen}
			/>

			<UploadFormsModal
				key={uuidGenerator()}
				bank={selectedBank}
				clientId={selectedCompany.clientId}
				company={selectedBank || {}}
				onClose={closeUploadModal}
				onSubmit={onUploadForms}
				showDownloads={true}
				type='bank'
				visible={isUploadModalOpen}
			/>

			<AccountVerificationModal
				key={uuidGenerator()}
				accountNum={selectedBank.accountNumber}
				bankId={selectedBank.bankId}
				bankName={selectedBank.accountNickname}
				clientId={selectedCompany.clientId}
				isOpen={isVerificationsModalOpen}
				onClose={closeVerificationsModal}
				onSubmit={handleVerificationsModalSubmit}
			/>
		</Fragment>
	);
}
