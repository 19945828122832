import { createStyles, makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles((theme) => {
	const { mixins, palette } = theme;

	return createStyles({
		body: {
			paddingBottom: 100,
		},
		checkboxContainer: {
			alignItems: 'center',
			display: 'flex',
		},
		footer: {
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0, rgba(255,255,255,0) 100%)',
			bottom: 91,
			height: 100,
			position: 'fixed',
			width: '100%',
		},
		header: {
			alignItems: 'center',
			borderBottom: mixins.border(1, palette.grey[400]),
			display: 'flex',
			marginBottom: 21,
			paddingBottom: 7,
		},
		headerText: {
			marginLeft: 10,
		},
		instruction: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		instructionRight: {
			display: 'flex',
		},
		notice: {
			backgroundColor: '#FEF6C7',
			marginTop: 8,
			padding: 20,
		},
		root: {
			textAlign: 'left',
		},
		selectedCount: {
			color: palette.primary.main,
			marginRight: 24,
		},
		selectedCountError: {
			color: palette.error.main,
			marginRight: 24,
		},
		table: {
			marginTop: 16,
		},
	});
});
