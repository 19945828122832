import {
	Federal1099Print,
} from './federal-1099-print';
import {
	find1099PrintOption,
} from '../domain/find-1099-print-option';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function Federal1099PrintConnector() {
	const [{
		clientTaxSettings,
	}, {
		setClientTaxSetting,
	}] = useCompanyTaxSettingsStore();
	const form1099 = find1099PrintOption(clientTaxSettings);
	if (!form1099) {
		return null;
	}
	const isFullService = form1099.value;
	const setIsFullService = setClientTaxSetting;
	return (
		<Federal1099Print
			isFullService={ isFullService }
			setIsFullService={ (fullService) => {
				setIsFullService({
					...form1099,
					value: fullService,
				});
			} }
		/>
	);
}
