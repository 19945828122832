import {
	Loader,
} from '@bamboohr/fabric';

import {
	useStyles,
} from './modal-loader.styles';

export function ModalLoader() {
	const classes = useStyles();
	return (
		<div className={ classes.root }>
			<Loader />
		</div>
	);
}
