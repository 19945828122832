import { BenefitPlanCoverages } from '../../../types';
import { CoverageTypes } from '../../../../types/coverage-type';

export const getPlanUnsupportedCoverages = (
	supportedCoverages: CoverageTypes,
	selectedCoverages: BenefitPlanCoverages[]
): BenefitPlanCoverages[] => {
	const supportedCoveragesSet = new Set(Object.keys(supportedCoverages));
	const planUnsupportedCoverages = selectedCoverages.reduce((acc: BenefitPlanCoverages[], coverage): BenefitPlanCoverages[] => {
		if (!supportedCoveragesSet.has(coverage.benefitCoverageId) && coverage.numberOfEnrollments > 0) {
			acc.push(coverage);
		}
		return acc;
	}, []);

	return planUnsupportedCoverages;
};
