import {
	Provider,
} from './state';

import LangSelector from './lang-selector';
import Table from './table';
import EditModal from './edit-modal';

import './styles.styl';


/**
 * @param {object} props
 * @param {import('./state').State} data
 */
export default function TranslationsTable({ data }) {
	return (
		<Provider value={ data }>
			<LangSelector />
			<Table />
			<EditModal />
		</Provider>
	);
}
