import { htmlentities, redirect } from 'BambooHR.util';
import Ajax from '@utils/ajax';
import { AvailableActionItems, HeaderData } from '../types';
import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';
import { unmountComponentAtNode } from 'react-dom';

function addNonEmployee() {
	redirect('/settings/users/add');
}

function duplicateAccessLevel(id) {
	Ajax.post(`/settings/access_levels/duplicate/${id}`, null, null)
		.then((result) => {
			if (!result.data.success) {
				console.error(result.data.error);
				setMessage(htmlentities(result.data.errorMessage), 'error');
				return;
			}
			redirect(`/access_levels/${result.data.accessLevelType}/edit/${result.data.accessLevelId}`, result.data.message, 'success');
		})
		.catch((error) => {
			console.error(error);
			setMessage($.__('There was a problem. Please try again or contact support.'), 'error');
		});
}

function deleteAccessLevel(id, data) {
	const deleteAccessLevelRoot = document.getElementById('delete-access-level-modal-root');

	const closeModalCallback = () => {
		unmountComponentAtNode(deleteAccessLevelRoot);
	};

	render(
		<PoMicroFrontend
			accessLevelTitle={data.title}
			accessLevelCount={data.count}
			accessLevelId={id}
			closeModalCallback={closeModalCallback}
			route='/settings/access_levels/delete-access-level-modal'
		/>,
		document.getElementById('delete-access-level-modal-root')
	);
}

const availableItems: AvailableActionItems = {
	addNonEmployee,
	duplicateAccessLevel,
	deleteAccessLevel,
};

export function handleActionSelect(selectedAction: keyof AvailableActionItems, id: string, headerData: HeaderData): void {
	const action = availableItems[selectedAction];
	if (typeof action === 'function') {
		action(id, headerData);
	} else {
		throw new Error(`Invalid action '${selectedAction}'`);
	}
}
