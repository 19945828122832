import './inline-add-form.styl';

import { addNewItem } from './utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useState } from 'react';

/**
 * @param {number} props.listId
 */
export function InlineAddForm(props) {
	const { listId } = props;

	const [value, setValue] = useState('');

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const handleButtonClick = () => {
		if ($.trim(value) === '') {
			window.setMessage($.__('Please enter a name for the new item'), 'error');
			return false;
		}
		window.closeMessage();

		addNewItem(value, listId)
			.then((response) => {
				const { data } = response;

				if (data.ret === 'SAVED') {
					if (data.message) {
						window.setMessage(data.message, 'success');
					}
					window.location.reload();
				} else {
					window.setMessage(data.error, 'error');
				}
			})
			.catch(() => window.errorFallBack());
	};

	// Prevent the default submit action on enter and handle it here
	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			handleButtonClick();
		}
	};

	return (
		<form className='BhrForms' data-bi-id='settings-employee-fields-add-form'>
			<div className='addLinks'>
				<fieldset>
					<div className='fab-InputWrapper btnGroup'>
						<input
							className={`fab-TextInput fab-TextInput--small fab-TextInput--width8 dynamicDisable ${ifFeature(
								'encore',
								'fab-TextInput--single',
								''
							)}`}
							data-list-id={listId}
							id='inline-add-label'
							maxLength='100'
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							placeholder={$.__('New Item')}
							type='text'
							value={value}
						/>
						<button
							className='fab-Button fab-Button--small EmployeeFieldsInlineAddForm__button'
							data-list-id={listId}
							onClick={handleButtonClick}
							type='button'
						>
							{$.__('Add')}
						</button>
					</div>
				</fieldset>
			</div>
		</form>
	);
}
