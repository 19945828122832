export enum Relationships {
	Self = 'self',
	Child = 'child',
	DomesticPartner = 'domestic partner',
	FosterChild = 'foster child',
	Spouse = 'spouse',
	StepChild = 'step child',
}

export interface RelationshipModel {
	key: Relationships;
	label: string;
}

export const ENABLED_RELATIONSHIPS = [
	Relationships.Self,
	Relationships.Spouse,
	Relationships.Child,
];

export const RELATIONSHIP_LABELS: { [key in Relationships]: string } = {
	[Relationships.Child]: $.__('Child(ren)'),
	[Relationships.DomesticPartner]: $.__('Domestic Partner'),
	[Relationships.FosterChild]: $.__('Foster Child'),
	[Relationships.Self]: $.__('Employee'),
	[Relationships.Spouse]: $.__('Spouse'),
	[Relationships.StepChild]: $.__('Step Child'),
};

export const RELATIONSHIPS: { [key in Relationships]: RelationshipModel } = {
	[Relationships.Self]: {
		key: Relationships.Self,
		label: RELATIONSHIP_LABELS[Relationships.Self],
	},
	[Relationships.Spouse]: {
		key: Relationships.Spouse,
		label: RELATIONSHIP_LABELS[Relationships.Spouse],
	},
	[Relationships.Child]: {
		key: Relationships.Child,
		label: RELATIONSHIP_LABELS[Relationships.Child],
	},
	[Relationships.DomesticPartner]: {
		key: Relationships.DomesticPartner,
		label: RELATIONSHIP_LABELS[Relationships.DomesticPartner],
	},
	[Relationships.StepChild]: {
		key: Relationships.StepChild,
		label: RELATIONSHIP_LABELS[Relationships.StepChild],
	},
	[Relationships.FosterChild]: {
		key: Relationships.FosterChild,
		label: RELATIONSHIP_LABELS[Relationships.FosterChild],
	},
};

