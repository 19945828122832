import {
	federalW2Box12DdHide,
	federalW2Box12DdMinimumShowCount,
	federalW2Box12DdShow,
} from '../constants';

export function handleW2Box12DdEnforceChoice(employeeCount: number, suppressCoverage: boolean) {
	if (!employeeCount || employeeCount < federalW2Box12DdMinimumShowCount) {
		return suppressCoverage ? federalW2Box12DdHide : federalW2Box12DdShow;
	}
	return federalW2Box12DdShow;
}
