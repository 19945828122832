import Ajax from '@utils/ajax';

export function deleteItem(listId, listValueId, totalHistoryCount) {
	const formData = Ajax.prepareData(
		{
			action: totalHistoryCount === 0 ? 'Delete' : 'Archive',
			id: listId,
			listValueId,
			totalCount: totalHistoryCount,
		},
		Ajax.prepareDataTypes.FORM_DATA
	);

	return Ajax.post('/settings/list.php', formData);
}
