import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles((theme) => {
	const { typography } = theme;

	return {
		helpTextButton: {
			fontWeight: typography.fontWeightSemibold,
		},
		popoverContent: {
			fontSize: typography.small.fontSize,
			lineHeight: '17px',
		},
		popoverLink: {
			fontSize: typography.medium.fontSize,
			lineHeight: typography.medium.lineHeight,
		},
	};
});
