import {
	Fragment, useEffect,
} from 'react';

import {
	useAjax,
} from '../../../shared';

import {
	useReducerContext,
} from '../state';

import EditListModal from './edit-list';
import EditValueModal from './edit-value';


export default function EditModal() {
	const {
		modal: {
			type = null,
			field_id = null,
			listid = null,
			langid = null,
			question = null,
		} = {},
	} = useReducerContext();
	const ajax = useAjax('SettingsAccountTranslations__editModal--list', {
		baseURL: '/ajax/dialog/manage/translation',
	});

	ajax.useResetEffect([type], !type);

	return (
		<Fragment>
			<EditListModal
				isOpen={ type === 'EDIT_LIST' }
				{ ...{ listid, langid, ...ajax } }
			/>
			<EditListModal
				isOpen={ type === 'EDIT_PERFORMANCE_LIST' }
				url="performance_question_ratings.php"
				params={ {
					langid,
					questionId: (question || {}).id,
				} }
				{ ...{ listid: (question || {}).id, langid, ...ajax } }
			/>
			<EditValueModal
				isOpen={ type === 'EDIT_VALUE' }
				{ ...{ field_id, langid, ...ajax } }
			/>

		</Fragment>
	);
}
