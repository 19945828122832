import {
	Button,
	Flex,
	LayoutBox,
	Loader,
	Section,
	Tab,
	TabPanel,
	Tabs,
} from '@bamboohr/fabric';
import {
	CalendarClock16x16,
	Gauge24x20,
	HandsJoined16x15,
	TargetArrow16x16,
} from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	CancellationDropdown,
	CancellationModal,
	CancellationStatus,
	CancellationType,
	SadToGoBox,
	Services,
} from 'in-app-cancellation.mod';
import { PoMicroFrontend } from 'micro-frontend.react';
import { useEffect, useState } from 'react';
import { GoalSettings } from './components/goal-settings.react';
import { ReviewCyclesSettings } from './components/review-cycles-settings.react';
import { SlidedownController } from './components/slidedown-controller.react';
import { Consumer, Provider } from './context';
import { useStyles } from './styles';
import { FilterListData, ReviewCycleConfigurationData } from './types';
import { getOneOnOnesFeatureCheck } from './services';

interface Props {
	filterListData: FilterListData;
	reviewCycleConfigurations: ReviewCycleConfigurationData[];
}

export const PerformanceSettings = (props: Props): JSX.Element => {
	const { filterListData, reviewCycleConfigurations } = props;

	const [activeTabName, setActiveTabName] = useState('reviewCycles');
	const [cancellationData, setCancellationData] =
		useState<CancellationStatus | null>(null);
	const [cancellationPending, setCancellationPending] = useState(false);
	const [cancellationStatus, setCancellationStatus] = useState([]);
	const [hasOneOnOnesEnabled, setHasOneOnOnesEnabled] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);

	const styles = useStyles({ activeTabName });

	const getCancellationStatus = () => {
		Services.getCancellationFeatureStatus(CancellationType.PERFORMANCE).then(
			(resp) => {
				setCancellationData(resp.data);
				setCancellationPending(resp.data.pending_cancellation);
			},
		);
		Services.getCancellationStatusAll().then((resp) => {
			// formating for sadbox
			setCancellationStatus(
				resp.data.filter(
					(item) =>
						item.feature === CancellationType.PERFORMANCE ||
						item.feature === CancellationType.ACCOUNT,
				),
			);
		});
	};

	const handleOnSelect = () => {
		setModalOpen(true);
	};

	useEffect(() => {
		getCancellationStatus();
	}, []);

	useEffect(() => {
		getOneOnOnesFeatureCheck()
			.then((response) => {
				// !! to ensure boolean is set and page still loads if unexpected response
				setHasOneOnOnesEnabled(!!response.data.oneOnOnes);
			})
			.catch(() => setHasOneOnOnesEnabled(false)); // Fallback to make sure rest of page still loads if request fails
	}, []);

	const settingsButtons = (
		<div className={styles.settingsButtons}>
			<Button
				color="secondary"
				variant={ifFeature('encore', undefined, 'outlined')}
				onClick={() => {
					window.location.href = '/app/setup/setup-guides/performance';
				}}
				type="button"
			>
				{$.__('View Setup Guide')}
			</Button>

			{!!cancellationData && !cancellationPending && (
				<div className={styles.settingsGearPadding}>
					<CancellationDropdown
						cancellationData={cancellationData}
						handleOnSelect={handleOnSelect}
						title={$.__('Performance')}
					/>
					<CancellationModal
						isOpen={modalOpen}
						onClose={() => setModalOpen(false)}
						onSubmitSuccess={getCancellationStatus}
						types={[CancellationType.PERFORMANCE]}
					/>
				</div>
			)}
		</div>
	);

	// Prevents 1:1 tab from popping in after the initial render
	const loaded = typeof hasOneOnOnesEnabled === 'boolean';

	return !loaded ? (
		<LayoutBox marginTop={8}>
			<Loader />
		</LayoutBox>
	) : (
		<Provider
			value={{
				slidedownData: { message: '', type: 'info' },
				reviewCycleData: reviewCycleConfigurations,
			}}
		>
			<Consumer>
				{() => {
					return (
						<>
							<SlidedownController />
							{ifFeature(
								'encore',
								<Section.Header
									size="large"
									title={$.__('Performance Management')}
									actions={settingsButtons}
									divider="bottom"
								/>,
								<div className={styles.titleWrapper}>
									<div className={styles.titleAlign}>
										<Gauge24x20 className={styles.titleIcon} />
										<span className={styles.pageTitle}>
											{$.__('Performance Management')}
										</span>
									</div>

									{settingsButtons}
								</div>,
							)}
							{cancellationPending && (
								<SadToGoBox
									cancellationData={cancellationStatus}
									isAddon={true}
									updateCancellationData={getCancellationStatus}
								/>
							)}
							<Tabs
								classes={styles}
								labelSize="large"
								onChange={(newValue) => {
									setActiveTabName(newValue as string);
								}}
								value={activeTabName}
								variant="standard"
							>
								<Tab
									icon={ifFeature(
										'encore',
										'calendar-regular',
										<CalendarClock16x16 />,
									)}
									label={
										<Flex alignItems="center">
											<span>{$.__('Review Cycles')}</span>
										</Flex>
									}
									value="reviewCycles"
								/>
								<Tab
									icon={ifFeature(
										'encore',
										'bullseye-arrow-regular',
										<TargetArrow16x16 />,
									)}
									label={$.__('Goals')}
									value="goals"
								/>
								{hasOneOnOnesEnabled && (
									<Tab
										icon={ifFeature(
											'encore',
											'handshake-simple-solid',
											<HandsJoined16x15 />,
										)}
										label={$.__('1:1s')}
										value="one-on-ones"
									/>
								)}
							</Tabs>
							{activeTabName === 'reviewCycles' && (
								<TabPanel selectedValue="reviewCycles" value="reviewCycles">
									<ReviewCyclesSettings />
								</TabPanel>
							)}
							{activeTabName === 'goals' && (
								<TabPanel mt={2} selectedValue="goals" value="goals">
									<GoalSettings filterListData={filterListData} />
								</TabPanel>
							)}
							{hasOneOnOnesEnabled && activeTabName === 'one-on-ones' && (
								<TabPanel
									mt={2}
									selectedValue="one-on-ones"
									value="one-on-ones"
								>
									<PoMicroFrontend route="/settings/performance/one-on-ones" />
								</TabPanel>
							)}
						</>
					);
				}}
			</Consumer>
		</Provider>
	);
};
