import {
	CompanyDeductionsMetadata,
	CompanyLocationMetadata,
	EmployeeDeductionsMetadata,
	FieldObject,
	FieldsById,
	GroupsNormalized,
	ItemsToMapById,
	ItemToMap,
	ManualUpdateCheckHandler,
	SectionType,
	ViewAndFixClickHandler,
	WageMetadata,
} from '../../utils/interfaces';
import {
	Icon,
	Button,
} from '@bamboohr/fabric';
import { WageOverlapDetail } from '../wage-overlap-detail';
import { CompanyDeductionOverlapDetail } from '../company-deduction-overlap-detail';
import { EmployeeDeductionsOverlapDetail } from '../employee-deductions-overlap-detail';
import {
	getWageMetadata,
	getCompanyDeductionsMetadata,
	getEmployeeDeductionsMetadata,
} from '../../utils/utilities';


export function getGroups(groups: GroupsNormalized): Array<object> {
	const {
		byId,
		allIds,
	} = groups;

	return allIds.map((groupId) => {
		const {
			bamboo: {
				name: bambooName,
				link,
			},
			trax: {
				name: traxName,
			},
		} = byId[groupId];
		const displayName = bambooName || traxName;
		const groupName = link ? <a href={ link } rel="noopener noreferrer" target="_blank">{ displayName }</a> : displayName;

		return {
			id: groupId,
			content: (
				<div className="MigrationTable__groupHeader">
					<span className="MigrationTable__groupHeaderIcon MigrationTable__groupHeaderIcon--attention">
						<Icon name="fab-triangle-exclamation-16x16" />
					</span>
					<span className="MigrationTable__groupHeaderName">
						{ groupName }
					</span>
				</div>
			),
		};
	});
}

export function getRows(
	records: FieldsById|ItemsToMapById,
	overlappingErrorFieldIds: Array<string>
): Array<FieldObject|ItemToMap<WageMetadata|CompanyLocationMetadata>> {
	return overlappingErrorFieldIds.map((id: string) => records[id]);
}

export function getColumns(
	sectionType: SectionType,
	onViewAndFixClick: ViewAndFixClickHandler,
	isMappingSection: boolean,
	onCheckboxChange: ManualUpdateCheckHandler
): Array<Record<string, unknown>> {
	if (isMappingSection) {
		return getMappingSectionColumns(sectionType, onViewAndFixClick, onCheckboxChange);
	}
	return getComparisonSectionColumns(sectionType, onViewAndFixClick, onCheckboxChange);
}

function getMappingSectionColumns(
	sectionType: SectionType,
	onViewAndFixClick: ViewAndFixClickHandler,
	onCheckboxChange: ManualUpdateCheckHandler
): Array<Record<string, unknown>> {
	const isEmployeeWage = sectionType === 'employeeWage';
	const isEmployeeDeductions = sectionType === 'employeeDeductions';
	const secondHeader = isEmployeeWage ? $.__('Compensation Record') : $.__('Deduction Details');
	const buttonText = isEmployeeWage ? $.__('View Overlapping Dates') : $.__('View & Fix Overlapping Dates');
	const columns = (isEmployeeWage || isEmployeeDeductions) ? [{
		headerAriaLabel: $.__('Name'),
		header: $.__('Name'),
		cell: (row: ItemToMap<WageMetadata|CompanyDeductionsMetadata>): JSX.Element => null,
	}] : [];
	columns.push(
		{
			header: secondHeader,
			headerAriaLabel: secondHeader,
			cell: (row: ItemToMap<WageMetadata|CompanyDeductionsMetadata|EmployeeDeductionsMetadata>): JSX.Element => {
				if (isEmployeeWage) {
					return <WageOverlapDetail { ...row.metadata } />;
				}
				if (isEmployeeDeductions) {
					return <EmployeeDeductionsOverlapDetail { ...row.metadata as EmployeeDeductionsMetadata } />;
				}
				return <CompanyDeductionOverlapDetail { ...row.metadata } />;
			},
		},
		{
			header: '',
			headerAriaLabel: $.__('View and fix overlapping dates'),
			cell: (row: ItemToMap<WageMetadata|CompanyDeductionsMetadata>): JSX.Element => (
				<Button
					clickAction={ () => onViewAndFixClick(row.value) }
					size="small"
					text={ buttonText }
					type="button"
				/>
			),
		},
		{
			header: '',
			headerAriaLabel: $.__('Record updated checkbox'),
			cell: (row: ItemToMap<WageMetadata|CompanyDeductionsMetadata>) => {
				const {
					value,
				} = row;
				return (
					<div className="fab-Checkbox">
						<input className="fab-Checkbox__input" id={ value } onChange={ event => onCheckboxChange(value, event.target.checked) } type="checkbox" />
						<label className="fab-Checkbox__label" htmlFor={ value }>
							{ $.__(`I've updated this record`) }
						</label>
					</div>
				);
			},
		}
	);
	return columns;
}

function getComparisonSectionColumns(
	sectionType: SectionType,
	onViewAndFixClick: ViewAndFixClickHandler,
	onCheckboxChange: ManualUpdateCheckHandler
): Array<Record<string, unknown>> {
	const isEmployeeWage = sectionType === 'employeeWage';
	const isEmployeeDeductions = sectionType === 'employeeDeductions';
	const secondHeader = isEmployeeWage ? $.__('Compensation Record') : $.__('Deduction Details');
	const buttonText = isEmployeeWage ? $.__('View Overlapping Dates') : $.__('View & Fix Overlapping Dates');
	return [
		{
			header: $.__('Name'),
			cell: () => null,
		},
		{
			header: secondHeader,
			cell: (row: FieldObject): JSX.Element => {
				const {
					subGroupMetadata,
					selectedDatabase,
				} = row;
				if (isEmployeeWage) {
					return <WageOverlapDetail { ...getWageMetadata(subGroupMetadata, selectedDatabase) } />;
				}
				if (isEmployeeDeductions) {
					return <EmployeeDeductionsOverlapDetail { ...getEmployeeDeductionsMetadata(subGroupMetadata, selectedDatabase) } />;
				}
				return <CompanyDeductionOverlapDetail { ...getCompanyDeductionsMetadata(subGroupMetadata, selectedDatabase) } />;
			},
		},
		{
			headerAriaLabel: $.__('View and fix overlapping dates'),
			cell: (row: FieldObject): JSX.Element => (
				<Button
					clickAction={ () => onViewAndFixClick(row.id) }
					size="small"
					text={ buttonText }
					type="button"
				/>
			),
		},
		{
			headerAriaLabel: $.__('Record updated checkbox'),
			cell: (row: FieldObject) => {
				const {
					id,
				} = row;
				return (
					<div className="fab-Checkbox">
						<input className="fab-Checkbox__input" id={ id } onChange={ event => onCheckboxChange(id, event.target.checked) } type="checkbox" />
						<label className="fab-Checkbox__label" htmlFor={ id }>
							{ $.__(`I've updated this record`) }
						</label>
					</div>
				);
			},
		},
	];
}
