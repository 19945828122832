import { useContext } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';
import { Box } from '@bamboohr/fabric';
import { uniqueId } from 'lodash';

import { EligibilityGroupCostsTable } from './eligibility-group-costs-table/eligibility-group-costs-table';
import { SafeHarborField } from '../../safe-harbor-field';
import { isFirstGroup } from '../../utils';
import { WizardStateContext } from '../../../context/wizard-state-context';
import { UpdateGroupProperty } from '../../../steps/types';
import {
	Currency,
	DisabilityCategoryDataOptionsContract,
	EligibilityGroupValues,
	PlanRateTypeEnum,
	PlanWizardData,
	SafeHarborValue,
	UpdateFieldValue,
	WizardState,
	WizardValues,
} from '../../../types';
import { isSafeHarborIncluded } from '../../../utils';
import { FEATURE_TOGGLE_KEYS } from '../../../../etc/feature-toggle';
import { useFeatureToggles } from '../../../../hooks/use-feature-toggles';
import { PLAN_TYPES } from '../../../../../../employees/shared/constants';

interface EligibilityGroupCostsProps {
	currencies: Currency[];
	group: EligibilityGroupValues;
	groupIndex: number;
	updateFieldValue: UpdateFieldValue;
	updateGroupProperty: UpdateGroupProperty;
	wizardData: PlanWizardData;
	wizardValues: WizardValues;
}

export const EligibilityGroupCosts = (props: EligibilityGroupCostsProps): JSX.Element => {
	const { currencies, group, groupIndex, updateFieldValue, updateGroupProperty, wizardData, wizardValues } = props;
	const { deductionType, planCategoryDataOptions, rateType, usesSafeHarbor } = wizardValues;
	const disabilityCategoryDataOptions = planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
	const calculatorSettings = { ...group.calculatorSettings };

	const wizardState: WizardState = useContext(WizardStateContext);

	const { isEnabled } = useFeatureToggles();
	const isBenefitsAdminEnabled = isEnabled(FEATURE_TOGGLE_KEYS.BenefitsEmployeeEnrollment);

	const isDisabilityPlan = wizardData.plan.type === PLAN_TYPES.DISABILITY;

	const updateSafeHarborValue = (newValue: SafeHarborValue): void => {
		updateFieldValue('usesSafeHarbor', newValue);
	};

	return (
		<>
			<Box marginTop={3}>
				<PoMicroFrontend
					benefitType={wizardData.plan.type}
					customClassNamePrefix='eligibility-costs-header'
					isSelfEnrollmentToggleEnabled={isBenefitsAdminEnabled}
					route='/settings/benefits/eligibility-costs-header'
				/>
			</Box>

			<EligibilityGroupCostsTable
				currencies={currencies}
				group={group}
				groupIndex={groupIndex}
				planCategoryDataOptions={planCategoryDataOptions}
				updateFieldValue={updateFieldValue}
				updateGroupProperty={updateGroupProperty}
				wizardData={wizardData}
				wizardValues={wizardValues}
			/>

			{isDisabilityPlan && rateType === PlanRateTypeEnum.standard && (
				<>
					{disabilityCategoryDataOptions &&
						disabilityCategoryDataOptions.disabilityDisbursementAmount &&
						disabilityCategoryDataOptions.disabilityDisbursementType &&
						disabilityCategoryDataOptions.disabilityType &&
						calculatorSettings && (
							<PoMicroFrontend
								customClassNamePrefix={uniqueId('example-disability-calculator-')}
								disabilityDisbursementAmount={disabilityCategoryDataOptions.disabilityDisbursementAmount}
								disabilityDisbursementType={disabilityCategoryDataOptions.disabilityDisbursementType}
								disabilityType={disabilityCategoryDataOptions.disabilityType}
								rate={calculatorSettings?.rate}
								route='/settings/benefits/example-disability-calculator'
							/>
						)}
				</>
			)}

			{isSafeHarborIncluded(deductionType, wizardData.decisionData.hasTrax) && (
				<div className='fab-FormSection'>
					<SafeHarborField
						countOfSafeHarborPlans={wizardData.decisionData.countOfSafeHarborPlans}
						groupId={group.id}
						isDisabled={!isFirstGroup(groupIndex) || Boolean(wizardState === WizardState.Reactivate)}
						planState={wizardData.plan.state}
						updateFieldValue={updateSafeHarborValue}
						value={usesSafeHarbor}
					/>
				</div>
			)}
		</>
	);
};
