import { isEnabled } from 'FeatureToggle.util';
import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';

import './styles.styl';

import { noop } from 'lodash';
import { unmountComponentAtNode } from 'react-dom';
import disableModal from 'time-tracking/modals/disable-employee';
import enableTimeTrackingModal from 'time-tracking/modals/enable-time-tracking';
import {
	openMissingDataModal,
	openNoPayScheduleModal
} from 'time-tracking/modals/missing-data';

function onErrorResponse(message = null) {
	window.setMessage(message || $.__('Sorry, something went wrong. Try giving it another shot?'), 'error');
}

function enableTimeTrackingSuccessHandler(response) {
	if (response.status === 'SUCCESS' || response.status === 'success') {
		window.location = '/employees/timesheet/?id=' + window.Employee.id;
		return;
	}

	if (response.status === 'FAIL' && response.reason && response.reason === 'no_pay_schedule') {
		window.BambooHR.Modal.setState({
			isOpen: false
		});
		openNoPayScheduleModal();
	}
}

function enableTimeTrackingFailureHandler(message = null) {
	onErrorResponse(message);
}

export function enableTimeTracking() {
	openPoModal('enable-time-tracking-modal', () => {
		const icon = document.querySelector('.js-emp-menu-tt-toggle ba-icon');
		const enabledOption = document.querySelector('.js-emp-menu-tt-toggle-enable');
		const disabledOption = document.querySelector('.js-emp-menu-tt-toggle-disable');
		const enableStatus = document.querySelector('.js-emp-menu-tt-status-enable');
		const disableStatus = document.querySelector('.js-emp-menu-tt-status-disable');
		
		enabledOption.insertBefore(icon, enabledOption.childNodes[0]);
		enabledOption.classList.add('disabled');

		disabledOption.classList.remove('disabled');
		disableStatus.classList.remove('TT_EmpMenu__status--selected');
		enableStatus.classList.add('TT_EmpMenu__status--selected');

		// Show change group button if applicable
		fetch('/time_tracking/settings')
			.then(res => res.json())
			.then(({ mode, configurations }) => {
				if (mode === 'group' && configurations.length > 1) {
					document.querySelector('.js-emp-menu-tt-change-group').hidden = false;
				}
			})
			.finally(() => {
				localStorage.setItem('__message__', $.__('Time tracking has been enabled for this employee!'));
				localStorage.setItem('__messageType__', 'success');
				window.location.reload();
			})
	})
}

function disableTimeTracking() {
	const { 
		id, 
		preferredName, 
		lastName 
	} = window.Employee;

	disableModal.openModal(id, preferredName, lastName, () => {
		const icon = document.querySelector('.js-emp-menu-tt-toggle ba-icon');
		const enabledOption = document.querySelector('.js-emp-menu-tt-toggle-enable');
		const disabledOption = document.querySelector('.js-emp-menu-tt-toggle-disable');
		const enableStatus = document.querySelector('.js-emp-menu-tt-status-enable');
		const disableStatus = document.querySelector('.js-emp-menu-tt-status-disable');
		
		disabledOption.insertBefore(icon, disabledOption.childNodes[0]);
		disabledOption.classList.add('disabled');

		enabledOption.classList.remove('disabled');
		enableStatus.classList.remove('TT_EmpMenu__status--selected');
		disableStatus.classList.add('TT_EmpMenu__status--selected');

		// Hide change group button since time tracking is disabled
		document.querySelector('.js-emp-menu-tt-change-group').hidden = true;
		// Reload page to show latest state
		localStorage.setItem('__message__', $.__('OK, this employee will no longer be able to track time worked or access the timesheet.'));
		localStorage.setItem('__messageType__', 'success');
		window.location.reload();
		
	});
}

export function handleTimeTrackingToggle(dataset) {
	const { ttAction, ttMissingData } = dataset;
	const disabledOption = document.querySelector('.js-emp-menu-tt-toggle.disabled');
	const disabledAction = disabledOption.dataset.ttAction;

	if (ttAction === disabledAction) {
		return;
	}

	if (isEnabled('custom-overtime')) {
		if (ttAction === 'enable') {
			enableTimeTracking();
		} else {
			disableTimeTracking();
		}
		return;
	}
	
	if (ttAction === 'enable') {
		if (ttMissingData.length) {
			try {
				openMissingDataModal(ttMissingData, window.Employee.preferredName);
				return;
			} catch (error) {
				// eslint-disable-next-line max-depth
				if (error !== 'no missing data') {
					throw error;
				}
				//Otherwise, let it follow the normal flow of enableTimeTracking();
			}
		}
		enableTimeTracking();
	} else {
		disableTimeTracking();
	}
}

export function handleChangeTimeTrackingGroup() {
	openPoModal('change-group-modal')
}

function openPoModal(route, onSuccess = noop) {
	const rootEl = document.createElement('div');
	document.body.appendChild(rootEl);
	
	render(
		<PoMicroFrontend
			employee={window.Employee}
			onCloseComplete={() => unmountComponentAtNode(rootEl)}
			onSuccess={onSuccess}
			route={`/time-tracking/${route}`}
		/>,
		rootEl,
	)
}
