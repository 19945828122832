import moment from 'moment.lib';

import { isPlanEndsRequired } from './plans-service';
import { PlanTypeEnumContract, RuleName, SetSpecialErrors, SpecialErrors } from '../types';
import { PlanFieldNames } from '../types/plan-field-names';

export const validateRequiredField = (
	name: keyof SpecialErrors,
	value: string | number | boolean | null,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const isValid = value !== null && Boolean(String(value).trim());

	if (isValid) {
		setSpecialErrors(name, null);
	} else {
		setSpecialErrors(name, { rule: RuleName.required, message: $.__('This field is required') });
	}

	return isValid;
};

export const validateDate = (
	name: keyof SpecialErrors,
	isRequired: boolean,
	value: string,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	if (value) {
		if (moment(value).isValid()) {
			setSpecialErrors(name, null);
			return true;
		}

		setSpecialErrors(name, { rule: RuleName.date, message: $.__('Please enter a valid date') });
		return false;
	}

	if (isRequired) {
		validateRequiredField(name, value, setSpecialErrors);
		return false;
	}

	return true;
};

export const validateDateBetween = (
	name: keyof SpecialErrors,
	enteredYmd: string,
	minYmd: string,
	maxYmd: string,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const isValid = Boolean(moment(enteredYmd).isBetween(minYmd, maxYmd, undefined, []));

	if (isValid) {
		setSpecialErrors(name, null);
	} else {
		setSpecialErrors(name, { rule: RuleName.dateRange, message: $.__('Please enter a valid date') });
	}

	return isValid;
};

export const validateDateFormat = (
	name: keyof SpecialErrors,
	isRequired: boolean,
	dateYmd: string,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	if (dateYmd) {
		const useStrictParseMode = true;
		const isValid = Boolean(moment(dateYmd, 'YYYY-MM-DD', useStrictParseMode).isValid());
		if (isValid) {
			setSpecialErrors(name, null);
		} else {
			setSpecialErrors(name, { rule: RuleName.date, message: $.__('Date format is invalid. Please try again.') });
		}
		return isValid;
	}

	if (isRequired) {
		validateRequiredField(name, dateYmd, setSpecialErrors);
		return false;
	}
	return true;
};

export const validateDateRange = (
	name: keyof SpecialErrors,
	startYmd: string,
	endYmd: string,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	if (!startYmd || !endYmd) {
		return true;
	}

	const isValid = Boolean(moment(endYmd).isAfter(moment(startYmd), 'day'));
	if (isValid) {
		setSpecialErrors(name, null);
	} else {
		setSpecialErrors(name, { rule: RuleName.dateRange, message: $.__('End date must be after start date.') });
	}

	return isValid;
};

export const validateReactivateEndDate = (value: string, setSpecialErrors: SetSpecialErrors): boolean => {
	const isValid = Boolean(value) && moment(value).isAfter(moment(), 'day');
	if (isValid) {
		setSpecialErrors(PlanFieldNames.planEnds, null);
	} else {
		setSpecialErrors(PlanFieldNames.planEnds, {
			rule: RuleName.futureDate,
			message: $.__('End date must be in the future when reactivating.'),
		});
	}
	return isValid;
};

export const validateStartDate = (value: string, endYmd: string, setSpecialErrors: SetSpecialErrors): boolean => {
	const name = PlanFieldNames.planStarts;
	return (
		validateDate(name, true, value, setSpecialErrors) &&
		validateDateFormat(name, true, value, setSpecialErrors) &&
		validateDateBetween(name, value, '1900-01-01', '2079-06-06', setSpecialErrors) &&
		validateDateRange(name, value, endYmd, setSpecialErrors)
	);
};

export const validateEndDate = (
	planType: PlanTypeEnumContract,
	value: string,
	startYmd: string,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const name = PlanFieldNames.planEnds;
	return (
		validateDate(name, isPlanEndsRequired(planType), value, setSpecialErrors) &&
		validateDateFormat(name, isPlanEndsRequired(planType), value, setSpecialErrors) &&
		validateDateBetween(name, value, '1900-01-01', '2079-06-06', setSpecialErrors) &&
		validateDateRange(name, startYmd, value, setSpecialErrors)
	);
};
