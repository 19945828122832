import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({
	spacing,
}) => {
	return createStyles({
		root: {
			marginBottom: spacing(3),
			marginTop: spacing(3),
		},
	});
});
