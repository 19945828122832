import './interstitial-footer.styl';

export function InterstitialFooter(): JSX.Element {
	const privacyLink = 'https://www.bamboohr.com/privacy-policy';
	const tosLink = 'https://www.bamboohr.com/terms-of-service';
	return (
		<footer className='InterstitialFooter'>
			<span className='InterstitialFooter__Section InterstitialFooter__PrivacyPolicy'>
				<a
					aria-label='BambooHR Privacy Policy'
					className='InterstitialFooter__Link'
					href={privacyLink}
					rel='noopener noreferrer'
					role='link'
					target='_blank'
					title='BambooHR Privacy Policy'
				>
					{$.__('Privacy Policy')}
				</a>
			</span>
			<span className='InterstitialFooter__Section InterstitialFooter__ToS'>
				<a
					aria-label='BambooHR Terms of Service'
					className='InterstitialFooter__Link'
					href={tosLink}
					rel='noopener noreferrer'
					target='_blank'
					title='BambooHR Terms of Service'
				>
					{$.__('Terms of Service')}
				</a>
			</span>
		</footer>
	);
}
