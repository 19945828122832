import {
    Action,
    createContainer,
    createHook,
    createStore,
    defaults,
} from 'react-sweet-state';

import {
    getBenefitsSettingsDataAction,
    setBenefitsSettingsStateAction,
} from './actions';

defaults.devtools = true;

export interface BenefitsSettingsState {
    isBenefitsAdminEnabled: boolean;
    isDataLoading: boolean;
}

export const initialState: BenefitsSettingsState = {
    isBenefitsAdminEnabled: false,
    isDataLoading: false,
};

const actions = {
    getBenefitsSettingsDataAction,
    setBenefitsSettingsStateAction,
};

export const BenefitsSettingsStore = createStore({
    name: 'BenefitsSettingsStore',
    initialState,
    actions,
});

export const useBenefitsSettingsStore = createHook(BenefitsSettingsStore);

export type BenefitsSettingsShape = typeof initialState;

type BenefitsSettingsActions = typeof actions;

export type BenefitsSettingsAction = Action<BenefitsSettingsShape>;

export const BenefitsSettingsContainer = createContainer<
    BenefitsSettingsShape,
    BenefitsSettingsActions
>(BenefitsSettingsStore, {
    onInit:
    () =>
    ({ dispatch }) => {
        dispatch(getBenefitsSettingsDataAction());
    },
});
