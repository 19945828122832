import { useEffect, useReducer } from "react";
import { UpcomingSurvey, UpcomingSurveysRequest } from "../../types";
import { Question } from "../types";
import reducer from './reducer';
import { useEditQuestionModalServices } from "./service";

interface UseEditQuestion {
    listOfQuestions: readonly Question[];
    save: () => void;
    select: (i: number) => void;
    neverAsk: (i: number) => void;
    enable: (i: number) =>  void;
    loading: boolean;
    canSave: boolean;
    showDone: boolean;
}

interface UseEditQuestionProps {
    updateUpcomingSurveys: (data: UpcomingSurvey[]) => void;
    topicId: number;
    scheduleData: UpcomingSurveysRequest;
    hideEditQuestion: () => void;
    surveyId: number;
}

const useEditQuestion = ({updateUpcomingSurveys, topicId, scheduleData, hideEditQuestion, surveyId} : UseEditQuestionProps): UseEditQuestion => {

    const [{currentData, loading, scheduledQuestionId, canSave, showDone}, dispatch] = useReducer(reducer, {
        currentData: [],
        lastSavedData: [],
        loading: true,
        scheduledQuestionId: undefined,
        canSave: false,
        showDone: false,
    });

    const {editQuestionService, updateTopicQuestionService} = useEditQuestionModalServices();

    useEffect(() => {
        if (topicId !== undefined && topicId !== null) {
            dispatch({
                type: 'START_LOADING'
            });
            editQuestionService(topicId, surveyId).then(({ listOfQuestions, surveyId }) => {
                // UX wants the disabled questions on bottom.
                dispatch({
                    type: 'UPDATE_DATA',
                    payload: {
                        questions: listOfQuestions
                            .sort((q1, q2) => (q2.isQuestionEnabled ? 1 : 0) - (q1.isQuestionEnabled ? 1 : 0)),
                        surveyId
                    }
                });
            });
        }
    }, [topicId, surveyId]);

    return {
        save: () => {
            dispatch({
                type: 'START_LOADING'
            });
            updateTopicQuestionService({
                surveyId,
                topicId,
                questionStatuses: currentData.map(({isQuestionEnabled, questionId}) => ({
                    questionId,
                    enabled: isQuestionEnabled
                })),
                scheduledQuestionId,
                ...scheduleData
            }).then(surveys => {
                updateUpcomingSurveys(surveys);
                hideEditQuestion();
            });
        },
        select: (i: number) => {
            dispatch({
                type: 'SELECT_QUESTION',
                payload: i
            });
        },
        neverAsk: (i: number) => {
            dispatch({
                type: 'NEVER_ASK',
                payload: i
            });
        },
        enable: (i: number) => {
            dispatch({
                type: 'ENABLE',
                payload: i
            });
        },
        listOfQuestions: currentData,
        loading,
        canSave,
        showDone
    };
};

export default useEditQuestion;
