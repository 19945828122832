import { useState } from 'react';
import { cloneDeep, merge } from 'lodash';

type stringOrNumber<T = string | number> = T | Array<T>;
type genericObjectFunction<U> = (o?: U) => U;
type createMethod = (newObjects: NormalizedObjectShape | NormalizedObjectShape[]) => void;
type updateMethod<U> = (ids: stringOrNumber, updater: U | genericObjectFunction<U>) => void;
type removeMethod = (ids: stringOrNumber) => void;

interface NormalizedObjectShape {
	id: number | string;
	[key: string]: any;
}

interface NormalizedByIdShape {
	[id: string]: NormalizedObjectShape;
}

interface NormalizedStateShape {
	byId: NormalizedByIdShape;
	allIds: string[];
}

export default function useNormalizedData
	<U extends NormalizedObjectShape>(normalizedData?: NormalizedStateShape): readonly [
		string[],
		NormalizedByIdShape,
		{
			create: createMethod;
			update: updateMethod<U>;
			remove: removeMethod;
		}
	] {

	if (!normalizedData) {
		normalizedData = {
			byId: {},
			allIds: [],
		};
	}

	const clonedData: NormalizedStateShape = cloneDeep(normalizedData);
	clonedData.allIds = Object.keys(clonedData.byId);

	const [data, setData] = useState(clonedData);
	const { byId, allIds } = data;

	const create: createMethod = function create(newObjects) {
		if (!Array.isArray(newObjects)) {
			newObjects = [newObjects];
		}

		const map = newObjects.reduce((acc, next) => {
			acc[next.id] = next;
			return acc;
		}, {});

		const newById = { ...byId, ...map };

		setData({
			byId: newById,
			allIds: Object.keys(newById),
		});
	};

	const update: updateMethod<U> = function update(ids, updater): void {
		let merger;
		if (updater instanceof Function) {
			merger = updater;
		} else {
			merger = (): U => updater;
		}

		if (!Array.isArray(ids)) {
			ids = [ids];
		}

		ids.forEach((id) => {
			byId[id] = merge(byId[id], merger(byId[id]));
		});

		setData({
			byId: { ...byId },
			allIds,
		});
	};

	const remove: removeMethod = function remove(ids): void {
		if (!Array.isArray(ids)) {
			ids = [ids];
		}

		ids.forEach((id) => {
			delete byId[id];
		});

		setData({
			byId: { ...byId },
			allIds: Object.keys(byId),
		});
	};

	return [
		allIds,
		byId,
		{
			create,
			update,
			remove,
		},
	];
}
