import { useState } from 'react';
import { ValidationError } from '@utils/validation';

import { PlanDates } from '../../../types';

import { validateDate, validateDateBetween } from '../../../utils';

type ValidateEffectiveDate = (id: number, newDate: string) => boolean;

interface EffectiveDateError {
	error: ValidationError;
}
export interface EmployeesWithDateErrors {
	[id: number]: EffectiveDateError;
}

export const useEffectiveDateValidation = (planDates: PlanDates): {
	validateEffectiveDates: ValidateEffectiveDate,
	employeesWithDateErrors: EmployeesWithDateErrors,
	clearEffectiveDateErrors: () => void,
} => {
	const [employeesWithDateError, setEmployeesWithDateError] = useState<EmployeesWithDateErrors>({});

	const updateEmployeesWithDateError = (id: number, error: ValidationError) => {
		setEmployeesWithDateError((prevDateValues) => {
			return {
				...prevDateValues,
				[id]: {
					error
				},
			};
		});
	};

	const minYmd = planDates.startYmd;
	const maxYmd = planDates.endYmd || '2079-06-06';

	const setSpecialErrors = (id: number) => (name: string, error: ValidationError) => {
		updateEmployeesWithDateError(id, error);
	};

	const validate: ValidateEffectiveDate = (id, dateValue) => {
		return validateDate('', true, dateValue, setSpecialErrors(id)) &&
			validateDateBetween('', dateValue, minYmd, maxYmd, setSpecialErrors(id));
	};

	return {
		validateEffectiveDates: validate,
		employeesWithDateErrors: employeesWithDateError,
		clearEffectiveDateErrors: () => setEmployeesWithDateError([]),
	};
};
