import Ajax from '@utils/ajax';

import { GoalSettingsResponse } from '../types';

export const getGoalSettingsData = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		Ajax.get('/settings/performance/home/goals')
			.then((response) => {
				const { data } = response as GoalSettingsResponse;
				if (data && data?.success) {
					resolve(data);
				}

				reject(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
