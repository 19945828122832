import { PoMicroFrontend } from 'micro-frontend.react';

import { generateCompanyPaysErrorKey, validateCompanyPays } from '../../../../../utils';
import { CostTypeStringEnum, Currency, SetSpecialErrors, SpecialErrors } from '../../../../../../types';
import { EligibilityGroupCalculatorSettings, EligibilityGroupValues, WizardValues } from '../../../../../types';

interface AgeBandedCostTableProps {
	currencies: Currency[];
	eligibilityGroup: EligibilityGroupValues;
	groupIndex: number;
	setSpecialErrors: SetSpecialErrors;
	specialErrors: SpecialErrors;
	updateGroupProperty: (
		groupIndex: number,
		key: keyof EligibilityGroupValues,
		calculatorSettings: EligibilityGroupCalculatorSettings
	) => void;
	wizardValues: WizardValues;
}

export const AgeBandedCostTable = (props: AgeBandedCostTableProps): JSX.Element => {
	const { currencies, eligibilityGroup, groupIndex, setSpecialErrors, specialErrors, updateGroupProperty, wizardValues } = props;
	const companyPaysErrorKey = generateCompanyPaysErrorKey(eligibilityGroup.id);

	const getValidationError = () => {
		return specialErrors[companyPaysErrorKey];
	};

	const updateCalculatorSettings = (key: string, value: number | string | null): void => {
		const newCalculatorSettings: EligibilityGroupCalculatorSettings = {
			...eligibilityGroup.calculatorSettings,
			[key]: value,
		};

		updateGroupProperty(groupIndex, 'calculatorSettings', newCalculatorSettings);
	};

	const updateAndValidateCompanyPays = (newCompanyPays: number) => {
		const { costType } = eligibilityGroup.calculatorSettings;
		validateCompanyPays(companyPaysErrorKey, newCompanyPays, costType, setSpecialErrors);
		updateCalculatorSettings('companyPays', newCompanyPays);
	};

	const updateCostType = (newCostType: CostTypeStringEnum) => {
		const { companyPays } = eligibilityGroup.calculatorSettings;
		if (companyPays !== null && companyPays >= 0) {
			validateCompanyPays(companyPaysErrorKey, companyPays, newCostType, setSpecialErrors);
		}

		updateCalculatorSettings('costType', newCostType);
	};

	return (
		<PoMicroFrontend
			calculatorSettings={eligibilityGroup.calculatorSettings}
			currencies={currencies}
			customClassNamePrefix={`age-banded-cost-table-${eligibilityGroup.id}`}
			eligibilityGroupId={eligibilityGroup.id}
			getValidationError={getValidationError}
			handleCompanyPaysChange={updateAndValidateCompanyPays}
			handleCostTypeChange={updateCostType}
			route='/settings/benefits/age-banded-cost-table'
			wizardValues={wizardValues}
		/>
	);
};
