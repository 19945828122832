import { render } from 'base/wrapped-render';
import Ctrl, {
	onReady,
	onPjaxEnd,
} from 'ctrl.deco';
import Clipboard from 'clipboard';
import { getJsonScriptVar } from 'BambooHR.util';

import SettingsApps from './settings-apps.react';

import './benetrac';
import './swipeclock';

@Ctrl('/settings/apps')
class SettingsAppsCtrl {
	get appList() {
		return getJsonScriptVar('appList', 'SettingsApps') || [];
	}

	static get ID() {
		return 'SettingsApps';
	}

	@onReady()
	@onPjaxEnd()
	_onReady(e, ctrl) {

		try {
			render(
				<SettingsApps { ...ctrl._data } />,
				ctrl._wrapper
			);
		} catch (error) {
			window.setMessage($.__('Oops! We were unable to load the page you requested. Please refresh the page to try again.'), 'error');
			window.Rollbar.error('Unable to Mount SettingsApp component', {
				error,
				wrapper: ctrl._wrapper
			});
		}

		document.getElementById('settings-main-content')
			.style.opacity = 1;

		const clip = new Clipboard('.SettingsApps__appModal [data-clipboard-text]');

		clip.on('success', () => {
			window.setMessage($.__('Copied certificate to clipboard'), 'success');
		});
	}
}
