import {DeductionDirectionEnumContract} from "base/settings/benefits/shared/deduction-migration-modal.react/types";

export const getTranslatedDeductionDateOptions = (offsetNumber: number, allowedDirections: DeductionDirectionEnumContract, showMostCommon: boolean) => {
	if(showMostCommon === true) {
		if (offsetNumber === 1) {
			if (allowedDirections === 'after') {
				return $._('**One** pay date **after** the benefit coverage effective date. *(Most Common)*');
			}
			return $._('**One** pay date **before** the benefit coverage effective date.');
		}
		if (offsetNumber === 2) {
			if (allowedDirections === 'after') {
				return $._('**Two** pay dates **after** the benefit coverage effective date.');
			}
			return $._('**Two** pay dates **before** the benefit coverage effective date.');
		}
	} else {
		if (offsetNumber === 1) {
			if (allowedDirections === 'after') {
				return $._('**One** pay date **after** the benefit coverage effective date.');
			}
			return $._('**One** pay date **before** the benefit coverage effective date.');
		}
		if (offsetNumber === 2) {
			if (allowedDirections === 'after') {
				return $._('**Two** pay dates **after** the benefit coverage effective date.');
			}
			return $._('**Two** pay dates **before** the benefit coverage effective date.');
		}
	}
}