import { ReactElement } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';

export interface HighestPriorityMissingTaxIdMessageMicroProps {
	showSetupNow: boolean;
}

export function HighestPriorityMissingTaxIdMessageMicro(props: HighestPriorityMissingTaxIdMessageMicroProps): ReactElement {

	return (
		<PoMicroFrontend
			route='/settings/payroll/highestPriorityMissingTaxIdMessage'
			{...props}
		/>
	)
}
