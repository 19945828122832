import { Fragment, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { IconButton, makeStyles, TextButton } from '@bamboohr/fabric';
import { People24x20, TrashCan14x16 } from '@bamboohr/grim';
import { PoMicroFrontend } from 'micro-frontend.react';

import { EligibilityGroupCosts } from './eligibility-group-costs/eligibility-group-costs';
import { resetSelectedCoverageLevels } from './etc/reset-selected-coverage-levels';
import { useDefaultEligibilityGroup } from './hooks';
import {
	getDefaultEligibilityGroup,
	getEligibilityGroupsWithUniqueSpecificEmployeeSelections,
	getFiltersFromInitialGroups,
	getNumberEnrolledEmployeesForGroup,
} from './utils';
import { getGroupById } from './utils/get-group-by-id';
import { isSpecificPeopleGroup } from './utils/is-specific-people-group';
import { EligibilityFilters } from '../eligibility-filters';
import { LossEligibility } from '../loss-eligibility';
import { DeleteGroupModal } from '../modals';
import { TimeToEligibility } from '../time-to-eligibility';
import { EligibilityModals, EmployeesInGroups, GroupEmployeeIds, UpdateGroupProperty } from '../types';
import {
	canDeleteFilterGroup,
	getEmployeeIdsByGroupId,
	getGroupsByEmployeeId,
	hasFilterEditRestrictions,
} from '../utils';
import { useValidationContext } from '../../context';
import { WizardStateContext } from '../../context/wizard-state-context';
import { UpdateEligibilityGroup } from '../../steps/types';
import {
	ClassCodeDetails,
	Currency,
	EligibilityGroupGetContract,
	EligibilityGroupValues,
	PlanWizardData,
	RateEnum,
	UpdateFieldValue,
	WizardValues,
} from '../../types';
import { WizardState } from '../../types/wizard-state';
import { generateEligibilityFiltersErrorKey, validateGroupFilterChange } from '../../utils';
import { FEATURE_TOGGLE_KEYS } from '../../../etc/feature-toggle/feature-toggle-keys.constant';
import { useFeatureToggles } from '../../../hooks/use-feature-toggles';

interface EligibilityGroupsProps {
	currencies: Currency[];
	initialGroups: EligibilityGroupGetContract[];
	updateEligibilityGroup: UpdateEligibilityGroup;
	updateFieldValue: UpdateFieldValue;
	wizardData: PlanWizardData;
	wizardValues: WizardValues;
}

const useStyles = makeStyles(({ palette }) => ({
	divider: {
		background: 'none',
		borderBottom: `solid 1px ${palette.gray[300]}`,
	},
	groupsHeader: {
		paddingTop: '4px',
	},
	groupsWrapper: {
		paddingLeft: '34px',
	},
	wrapper: {
		border: `1px solid ${palette.gray[300]}`,
		borderRadius: 4,
		marginTop: 16,
	},
	headerWrapper: {
		alignItems: 'center',
		backgroundColor: palette.gray[100],
		display: 'flex',
		justifyContent: 'space-between',
		padding: '18px 24px',
	},
	contentWrapper: {
		padding: '0 24px 18px',
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		gap: 8,
	},
	headerIcon: {
		fill: palette.gray[800],
	},
	headerText: {
		color: palette.gray[800],
	},
}));

export const EligibilityGroups = (props: EligibilityGroupsProps): JSX.Element => {
	const { currencies, initialGroups, updateEligibilityGroup, updateFieldValue, wizardData, wizardValues } = props;
	const { coverageLevels, eligibilityGroups, rateType } = wizardValues;
	const wizardState: WizardState = useContext(WizardStateContext);
	const { isEnabled, areFeatureTogglesLoading } = useFeatureToggles();
	const styles = useStyles();

	const [activeGroupId, setActiveGroupId] = useState<string | number>('');
	const [currentModal, setCurrentModal] = useState<EligibilityModals>(EligibilityModals.none);

	const { removeSpecificError, setSpecialErrors, specialErrors } = useValidationContext();

	const isBenefitsAdminEnabled = isEnabled(FEATURE_TOGGLE_KEYS.BenefitsEmployeeEnrollment);
	useDefaultEligibilityGroup(coverageLevels, wizardData.demographics, eligibilityGroups, rateType, updateEligibilityGroup);

	const updateGroupProperty = (
		index: number,
		key: keyof EligibilityGroupValues,
		value: EligibilityGroupValues[keyof EligibilityGroupValues],
		needsValidation?: boolean
	) => {
		const newGroup = {
			...eligibilityGroups[index],
			[key]: value,
		};
		updateEligibilityGroup(index, newGroup, needsValidation);
	};

	const getUpdateGroupProperty = (index: number): UpdateGroupProperty => {
		return (key, value, needsValidation): void => {
			updateGroupProperty(index, key, value, needsValidation);
		};
	};

	const updateClassCodesProperty = (index: number) => (key: keyof ClassCodeDetails, value: string) => {
		const tmpClassCodes = {
			...eligibilityGroups[index].class,
			[key]: value,
		};

		updateGroupProperty(index, 'class', tmpClassCodes);
	};

	const getSetSelectedEmployeeIds = (updateIndex: number) => {
		return (selectedEmployeeIds: number[]): void => {
			const newEligibilityGroups = getEligibilityGroupsWithUniqueSpecificEmployeeSelections(
				updateIndex,
				selectedEmployeeIds,
				eligibilityGroups
			);

			updateFieldValue('eligibilityGroups', newEligibilityGroups);
			validateGroupFilterChange(newEligibilityGroups, updateIndex, { setSpecialErrors, removeSpecificError });
		};
	};

	const addGroup = (): void => {
		const newGroup: EligibilityGroupValues = getDefaultEligibilityGroup(coverageLevels, rateType);
		setActiveGroupId(newGroup.id);

		updateEligibilityGroup(eligibilityGroups.length, newGroup);
	};

	const deleteEligibilityGroup = (groupId: number | string): void => {
		const groupIndex = eligibilityGroups.findIndex((group) => group.id === groupId);

		if (groupIndex > -1) {
			const allGroups = [...eligibilityGroups];
			allGroups.splice(groupIndex, 1);

			updateFieldValue('eligibilityGroups', allGroups);

			setCurrentModal(EligibilityModals.none);
		}
	};

	const handleDeleteClick = (groupIndex: number, groupId: string | number, employeeIdsInGroup: number[]): void => {
		const enrolledEmployeeCount = getNumberEnrolledEmployeesForGroup(
			employeeIdsInGroup,
			wizardData.plan.enrollments.currentEmployeeIds
		);

		if (enrolledEmployeeCount > 0) {
			setCurrentModal(EligibilityModals.deleteGroup);
		} else {
			deleteEligibilityGroup(groupId);
		}
	};

	const hasOnlyOneGroup = (): boolean => {
		return eligibilityGroups.length === 1;
	};

	const getAddGroupText = (): string => {
		if (hasOnlyOneGroup()) {
			return $.__('+ Add a group');
		}
		return $.__('+ Add another group');
	};

	const handleGroupClick = (groupId: string | number): void => {
		if (wizardValues.eligibilityGroups.length > 1) {
			setActiveGroupId(groupId);
		}
	};

	const employees = wizardData.demographics ? wizardData.demographics.employees : null;

	const employeeIdsByGroupId = useMemo<GroupEmployeeIds>(
		() => getEmployeeIdsByGroupId(employees, eligibilityGroups),
		[employees, eligibilityGroups]
	);

	const groupsByEmployeeId = useMemo<EmployeesInGroups>(
		() => getGroupsByEmployeeId(employeeIdsByGroupId, eligibilityGroups),
		[employeeIdsByGroupId, eligibilityGroups]
	);

	const selectedCoverageLevels = wizardValues.coverageLevels.filter((coverageLevel) => coverageLevel.isSelected);

	if (
		!areFeatureTogglesLoading &&
		!isBenefitsAdminEnabled &&
		wizardValues.rate === RateEnum.Variable &&
		selectedCoverageLevels.length
	) {
		const resetCoverageLevels = resetSelectedCoverageLevels(wizardValues.coverageLevels);
		updateFieldValue('coverageLevels', [...resetCoverageLevels]);
	}

	const getGroupHeaderText = (groupNumber: number): string => {
		return $.__('Group %1', groupNumber);
	};

	return (
		<Fragment>
			<div className={styles.groupsHeader}>
				<PoMicroFrontend
					icon={<People24x20 />}
					route='/settings/benefits/icon-section-header'
					subtitle='Who is eligible for this plan?'
					title='Eligibility Groups'
				/>
			</div>
			<div className={styles.groupsWrapper}>
				{eligibilityGroups.map((group: EligibilityGroupValues, index: number) => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
					<div className={classNames(styles.wrapper)} key={group.id} onClick={() => handleGroupClick(group.id)}>
						<div className={styles.headerWrapper}>
							<div className={styles.header}>
								<div className={styles.headerIcon}>
									<People24x20 />
								</div>
								<h4 className={styles.headerText}>{getGroupHeaderText(index + 1)}</h4>
							</div>
							{canDeleteFilterGroup(
								eligibilityGroups.length,
								wizardData.plan.state,
								wizardValues.startYmd,
								group.id,
								group.eligibilityFilters,
								wizardState
							) && (
								<IconButton
									color='secondary'
									data-bi-id='delete-group'
									floatingIcon={true}
									icon={<TrashCan14x16 />}
									onClick={() => handleDeleteClick(index, group.id, employeeIdsByGroupId[group.id])}
									type='button'
								/>
							)}
						</div>
						<div className={styles.contentWrapper}>
							<div className='fab-FormSection'>
								<PoMicroFrontend
									classCodeDetails={group.class}
									route='/settings/benefits/class-code-fields'
									updateField={updateClassCodesProperty(index)}
								/>
								<EligibilityFilters
									allEmployeesInGroups={groupsByEmployeeId}
									demographics={wizardData.demographics}
									eligibilityFilters={eligibilityGroups[index].eligibilityFilters}
									error={specialErrors[generateEligibilityFiltersErrorKey(group.id)]}
									groupEmployeeIds={employeeIdsByGroupId[group.id]}
									hasEditRestrictions={hasFilterEditRestrictions(
										wizardData.plan.state,
										wizardValues.startYmd,
										group.id,
										group.eligibilityFilters
									)}
									initialFilters={getFiltersFromInitialGroups(initialGroups, group.id)}
									planName={wizardValues.name}
									setSelectedEmployeeIds={getSetSelectedEmployeeIds(index)}
									updateGroupProperty={getUpdateGroupProperty(index)}
								/>
								<TimeToEligibility
									groupId={group.id}
									options={wizardData.options.timeToEligibility}
									timeToEligibility={eligibilityGroups[index].timeToEligibility}
									updateGroupProperty={getUpdateGroupProperty(index)}
								/>
								<LossEligibility
									lossOfEligibility={eligibilityGroups[index].lossOfEligibility}
									updateGroupProperty={getUpdateGroupProperty(index)}
								/>
							</div>
							<EligibilityGroupCosts
								currencies={currencies}
								group={group}
								groupIndex={index}
								updateFieldValue={updateFieldValue}
								updateGroupProperty={updateGroupProperty}
								wizardData={wizardData}
								wizardValues={wizardValues}
							/>
						</div>
					</div>
				))}
				<div className='fab-FormSection'>
					<TextButton
						data-bi-id='add-group'
						disabled={Boolean(wizardState === WizardState.Reactivate)}
						key='addGroupButton2'
						onClick={addGroup}
						text={getAddGroupText()}
						type='button'
					/>
					{hasOnlyOneGroup() && <div className='fab-FormNote'>{$.__('For employees with different eligibility')}</div>}
				</div>
			</div>
			<DeleteGroupModal
				closeModal={() => setCurrentModal(EligibilityModals.none)}
				deleteGroup={() => deleteEligibilityGroup(activeGroupId)}
				isOpen={currentModal === EligibilityModals.deleteGroup}
				isSpecificPeopleGroup={isSpecificPeopleGroup(getGroupById(Number(activeGroupId), eligibilityGroups))}
				numberEnrolledEmployees={getNumberEnrolledEmployeesForGroup(
					employeeIdsByGroupId[activeGroupId],
					wizardData.plan.enrollments.currentEmployeeIds
				)}
			/>
		</Fragment>
	);
};
