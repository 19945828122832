import type {
	Action,
} from 'react-sweet-state';

import {
	serializeClientTaxSettings,
} from '../../domain/serialize-client-tax-settings';
import {
	fetchAllClientTaxSettingsAsync,
} from '../../services/fetch-client-tax-settings';
import type {
	CompanyTaxSettingsContainerProps,
	CompanyTaxSettingsStore,
} from '../../types';

export function fetchAllClientTaxSettings(): Action<CompanyTaxSettingsStore, CompanyTaxSettingsContainerProps> {
	return async function fetchAllClientTaxSettingsDispatch({
		setState,
	}, {
		clientId,
	}) {
		try {
			setState({
				isErrorClientTaxSettings: false,
				isLoadingClientTaxSettings: true,
			});
			const clientTaxSettings = await fetchAllClientTaxSettingsAsync(clientId);
			const serializedClientTaxSettings = serializeClientTaxSettings(clientTaxSettings);
			setState({
				clientTaxSettings: serializedClientTaxSettings,
			});
		} catch (err) {
			setState({
				isErrorClientTaxSettings: true,
			});
		} finally {
			setState({
				isLoadingClientTaxSettings: false,
			});
		}
	}
}
