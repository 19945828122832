import classNames from 'classnames';

import { ApprovalFormContainer } from '../../containers/approval-form';
import { SubHeaderContainer } from '../../containers/sub-header';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { StyledBox } from '@bamboohr/fabric';

export function Path (props) {
	const {
		canSaveInitial,
		catchAll = false,
		editState,
		editingWorkflowId,
		id,
		onEdit,
		selectedPaths
	} = props;

	const editingThisWorkflow = editState && editingWorkflowId === Number(id);

	const approvalsClassName = classNames('PathContainer', {
		'PathContainer--edit': editingThisWorkflow,
		'baseBorderColor': editingThisWorkflow
	});

	return (
		<>
			{ ifFeature('encore',
				<StyledBox
					backgroundColor={editingThisWorkflow ? "neutral-forced-white" : "neutral-extra-extra-weak" }
					borderColor="neutral-extra-weak"
					borderRadius="small"
					borderStyle="solid"
					padding="16px 20px 0 20px"
					margin="16px 0px 8px 16px"
				>
					<div className={approvalsClassName}>
						<SubHeaderContainer
							catchAll={catchAll}
							id={id}
							onEdit={() => {
								onEdit(id);
							}}
							selectedPaths={selectedPaths}
						/>
						<ApprovalFormContainer
							canSaveInitial={canSaveInitial}
							id={id}
							originalSelectedPathIds={selectedPaths}
						/>
					</div>
				</StyledBox>,
				<div className={approvalsClassName}>
					<SubHeaderContainer
						catchAll={catchAll}
						id={id}
						onEdit={() => {
							onEdit(id);
						}}
						selectedPaths={selectedPaths}
					/>
					<ApprovalFormContainer
						canSaveInitial={canSaveInitial}
						id={id}
						originalSelectedPathIds={selectedPaths}
					/>
				</div>
			)}
		</>
	);
}
