import {
	useState,
} from 'react';
import {
	cloneDeep,
} from 'lodash';
import {
	FloatingIconButton,
	TextButton,
} from '@fabric/button';
import {
	Modal,
} from 'modal-legacy';
import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	NormalizedDeductions,
} from '../../utils';

import {
	handleDelete,
	handleReactivateClick,
} from './utils';

import './action-buttons-cell.styl';

interface ActionButtonsCellProps {
	deductions: NormalizedDeductions;
	setCurrentDeductions(newDeductions: NormalizedDeductions): void;
	setEditingId(id: number): void;
	companyDeductionId: number;
	deductionName: string;
	numberOfEnrollments: number;
	isActive: boolean;
	isEditing: boolean;
}

export function ActionButtonsCell(props: ActionButtonsCellProps): JSX.Element {
	const {
		deductions,
		setCurrentDeductions,
		setEditingId,
		companyDeductionId,
		deductionName,
		numberOfEnrollments,
		isActive,
		isEditing,
	} = props;
	const deductionsCopy = cloneDeep(deductions);
	const [buttonProcessing, setButtonProcessing] = useState(null);
	const [deletemodalOpen, setDeleteModalOpen] = useState(false);

	if (!isActive) {
		return (
			<div className="ActionButtonsCell">
				<TextButton
					clickAction={ (): void => {
						handleReactivateClick(companyDeductionId, deductionsCopy, setCurrentDeductions, setButtonProcessing);
					} }
					isDisabled={ buttonProcessing === 'reactivate' }
					text={ $.__('Reactivate') }
					type="button"
				/>
			</div>

		);
	}

	return (
		<div className="ActionButtonsCell">
			{ !isEditing && (
				<FloatingIconButton
					clickAction={ (): void => setEditingId(companyDeductionId) }
					icon="fab-pencil-16x16"
					outline={ true }
					secondary={ true }
					type="button"
				/>
			) }
			<FloatingIconButton
				clickAction={ numberOfEnrollments ?
					(): void => setDeleteModalOpen(true) :
					(): void => {
						handleDelete(companyDeductionId, deductionsCopy, setCurrentDeductions, setButtonProcessing);
					} }
				icon="fab-trash-can-14x16"
				isProcessing={ buttonProcessing === 'delete' }
				outline={ true }
				secondary={ true }
				type="button"
			/>
			<Modal
				alternativeAction={ null }
				headline={ $.__('Are you sure you want to delete "%1$s"?', deductionName) }
				icon="fab-trash-can-43x48"
				iconColor="danger"
				isOpen={ deletemodalOpen }
				isProcessing={ buttonProcessing === 'delete' }
				onClose={ (): void => setDeleteModalOpen(false) }
				primaryAction={ (): void => {
					handleDelete(companyDeductionId, deductionsCopy, setCurrentDeductions, setButtonProcessing);
				} }
				primaryActionText={ $.__('Delete Deduction') }
				secondaryAction={ (): void => setDeleteModalOpen(false) }
				secondaryActionText={ $.__('Keep Deduction') }
				title={ $.__('Delete Post-Tax Deduction') }
				type="aggressiveConfirmation"
			>
				<p>
					<Message
						params={ [numberOfEnrollments] }
						text={ $._n(
							'You have **{1} active employee** paying this post-tax deduction.',
							'You have **{1} active employees** paying this post-tax deduction.',
							numberOfEnrollments
						) }
					/>
					&nbsp;
					{ $.__('Deleting it will end the deduction for them.') }
				</p>
			</Modal>
		</div>
	);
}
