import { Modal } from 'modal-legacy';

interface FilterDiscardWarningModalProps {
	closeModal: () => void;
	isOpen: boolean;
	onContinueClick: () => void;
}

export const FilterDiscardWarningModal = (props: FilterDiscardWarningModalProps): JSX.Element => {
	const {
		closeModal,
		isOpen,
		onContinueClick,
	} = props;

	return (
		<Modal
			headline={ $.__('If you continue, the filters that you’ve set will be discarded.') }
			icon="fab-triangle-exclamation-48x48"
			isOpen={ isOpen }
			onClose={ closeModal }
			primaryAction={ onContinueClick }
			primaryActionText={ $.__('Continue') }
			title={ $.__('Just Checking...') }
		/>
	);
};
