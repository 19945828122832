import { ifFeature } from '@bamboohr/utils/lib/feature';
import CompensationForm from 'Employees.mod/Job/CompensationForm';

export const showPreviewModal = ({ data }) => {
	if (data.success) {
		window.BambooHR.Modal.setState({
			alternativeAction: null,
			contentHasPadding: false,
			content: (
				<div
					className={`js-request-form-wrapper ${ifFeature('encore', 'fab-paddingLeft--20 fab-paddingRight--20')}`}
					dangerouslySetInnerHTML={{ __html: data.html }}
				/>
			),
			onOpen: () => {
				$('.js-payType').on('change', handlePayTypeChange);
				CompensationForm.setupForm('approvalPreviewForm');
				CompensationForm.initializeForm('approvalPreviewForm');
				//Setup initial forms for emergency contacts if they don't already have it
				let $contactsElement = $('.employee_contacts.repeat');
				if ($contactsElement.find('.blankState:visible').length > 0) {
					$('.js-contactAddInitial').trigger('click');
					$contactsElement[0].repeat.add();
				}
			},
			onClose: handleClose,
			primaryAction: handleClose,
			primaryActionText: data.save,
			isOpen: true,
			title: data.title,
		});
	} else if (data.error) {
		setMessage(data.error, 'error');
	} else {
		errorFallBack();
	}
};

function handleClose() {
	$('.js-payType').off('change', handlePayTypeChange);
	window.BambooHR.Modal.setState({
		isOpen: false,
	});
}

function handlePayTypeChange() {
	CompensationForm.handlePayTypeChange('approvalPreviewForm');
}
