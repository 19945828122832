import { generateEligibilityFiltersErrorKey } from './error-keys';
import { validateGreaterThan, validateGreaterThanOrEqualTo, validateLessThanOrEqualTo } from './rules';
import { VALIDATION_KEYS } from '../../constants';
import { canGroupsCoexist } from '../../eligibility-cost/utils';
import { CostRow, CostTypeEnumContract, EligibilityFilterValues, EligibilityGroupValues, PlanWizardRuleName } from '../../types';
import { CostTypeStringEnum, RuleName, SetSpecialErrors } from '../../../types';
import { PlanFieldNames } from '../../../types/plan-field-names';
import { validateRequiredField } from '../../../utils';

const groupHasFiltersConfigured = (eligibilityFilters: EligibilityFilterValues): boolean => {
	return eligibilityFilters.filterGroup.order.length > 0;
};

const groupHasEmployeesSelected = (eligibilityFilters: EligibilityFilterValues): boolean => {
	return eligibilityFilters.selectedEmployeeIds.length > 0;
};

export const isGroupConfigured = (eligibilityFilters: EligibilityFilterValues): boolean => {
	return groupHasFiltersConfigured(eligibilityFilters) || groupHasEmployeesSelected(eligibilityFilters);
};

export const validateGroupsCanCoexist = (
	eligibilityGroups: EligibilityGroupValues[],
	indexOne: number,
	indexTwo: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const groupOneFilters = eligibilityGroups[indexOne].eligibilityFilters;
	const groupTwoFilters = eligibilityGroups[indexTwo].eligibilityFilters;

	if (
		!isGroupConfigured(groupOneFilters) ||
		!isGroupConfigured(groupTwoFilters) ||
		groupOneFilters.areSpecificPeopleSelected ||
		groupTwoFilters.areSpecificPeopleSelected
	) {
		// Specific people selections are constrained to avoid overlap so we don't need to validate them
		return true;
	}

	const canCoexist = canGroupsCoexist(groupOneFilters.filterGroup.values, groupTwoFilters.filterGroup.values);

	if (!canCoexist) {
		const errorKeyOne = generateEligibilityFiltersErrorKey(eligibilityGroups[indexOne].id);
		const lowerIndex = indexOne < indexTwo ? indexOne : indexTwo;
		const higherIndex = lowerIndex === indexTwo ? indexOne : indexTwo;
		const rule = {
			rule: PlanWizardRuleName.filterOverlap,
			message: $.__(
				'With the current filters, employees could belong to both Group %s and Group %s.\nTry adding or removing filters.',
				lowerIndex + 1,
				higherIndex + 1
			),
		};

		setSpecialErrors(errorKeyOne, rule);
	}

	return canCoexist;
};

export const validateCoexistenceWithOtherGroups = (
	eligibilityGroups: EligibilityGroupValues[],
	index: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const generateIndex = (curIndex) => (curIndex + 1) % eligibilityGroups.length;
	for (let j = generateIndex(index); j !== index; j = generateIndex(j)) {
		if (!validateGroupsCanCoexist(eligibilityGroups, index, j, setSpecialErrors)) {
			return false;
		}
	}

	return true;
};

export const validateRequiredSelections = (
	groupId: string | number,
	eligibilityFilters: EligibilityFilterValues,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const errorKey = generateEligibilityFiltersErrorKey(groupId);
	const isConfigured = isGroupConfigured(eligibilityFilters);

	if (isConfigured) {
		setSpecialErrors(errorKey, null);
	} else {
		const errorMessage = eligibilityFilters.areSpecificPeopleSelected
			? $.__('Please select at least one employee.')
			: $.__('Please configure the filters for this eligibility group.');

		setSpecialErrors(errorKey, {
			rule: RuleName.required,
			message: errorMessage,
		});
	}

	return isConfigured;
};

export const getMaxEmployeeCost = (employeeCostType: CostTypeEnumContract, totalCost: number): number => {
	return employeeCostType === CostTypeEnumContract.percent ? 100 : totalCost;
};

export const validateMaxEmployeeCost = (errorKey: string, costRow: CostRow, setSpecialErrors: SetSpecialErrors): boolean => {
	const maxCost = getMaxEmployeeCost(costRow.employeeCostType, costRow.totalCost);

	if (maxCost === null) {
		return true;
	}

	return validateLessThanOrEqualTo(errorKey, costRow.employeeCost, maxCost, setSpecialErrors);
};

export const validateEmployeeCost = (errorKey: string, costRow: CostRow, setSpecialErrors: SetSpecialErrors): boolean => {
	return (
		validateRequiredField(errorKey, costRow.employeeCost, setSpecialErrors) &&
		validateMaxEmployeeCost(errorKey, costRow, setSpecialErrors) &&
		validateGreaterThanOrEqualTo(errorKey, costRow.employeeCost, 0, setSpecialErrors)
	);
};

export const validateReimbursementAmount = (amount: number | null, setSpecialErrors: SetSpecialErrors): boolean => {
	const errorKey = VALIDATION_KEYS.reimbursement;

	return (
		validateRequiredField(errorKey, amount, setSpecialErrors) && validateGreaterThanOrEqualTo(errorKey, amount, 0, setSpecialErrors)
	);
};

export const validateEligibilityFilters = (
	eligibilityGroups: EligibilityGroupValues[],
	index: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const currentGroup = eligibilityGroups[index];
	const { eligibilityFilters } = currentGroup;

	return (
		validateRequiredSelections(currentGroup.id, eligibilityFilters, setSpecialErrors) &&
		validateCoexistenceWithOtherGroups(eligibilityGroups, index, setSpecialErrors)
	);
};

export const validateRadioGroup = (name: PlanFieldNames, value: string, setSpecialErrors: SetSpecialErrors): boolean => {
	if (value) {
		setSpecialErrors(name, null);
		return true;
	}
	setSpecialErrors(name, {
		rule: RuleName.required,
		message: $.__('This choice is required.'),
	});

	return false;
};

export const validatePlanDependencies = (name: PlanFieldNames, dependencies: boolean, setSpecialErrors: SetSpecialErrors) => {
	if (dependencies) {
		setSpecialErrors(name, null);
	} else {
		setSpecialErrors(name, {
			rule: RuleName.required,
			message: $.__('Please select at least one medical plan.'),
		});
		return false;
	}
};

export const validateLegalText = (
	name: PlanFieldNames,
	value: boolean,
	setSpecialErrors: SetSpecialErrors,
	characterLimitValid: boolean
) => {
	if (value && characterLimitValid) {
		setSpecialErrors(name, null);
		return true;
	} else if (!characterLimitValid) {
		setSpecialErrors(name, {
			rule: RuleName.greaterThanOrEqualTo,
			message: $.__('Legal text exceeded 50,000 character limit.'),
		});
		return false;
	}
	setSpecialErrors(name, {
		rule: RuleName.required,
		message: $.__('Please provide legal language for this plan.'),
	});
};

export const validatePercentAmount = (errorKey: string, amount: number | null, setSpecialErrors: SetSpecialErrors) => {
	return (
		validateRequiredField(errorKey, amount, setSpecialErrors) &&
		validateLessThanOrEqualTo(errorKey, amount, 100, setSpecialErrors) &&
		validateGreaterThanOrEqualTo(errorKey, amount, 0, setSpecialErrors)
	);
};

export const validateFixedAmount = (errorKey: string, amount: number | null, setSpecialErrors: SetSpecialErrors) => {
	return (
		validateRequiredField(errorKey, amount, setSpecialErrors) && validateGreaterThanOrEqualTo(errorKey, amount, 0, setSpecialErrors)
	);
};

export const validateCompanyPays = (
	errorKey: string,
	amount: number | null,
	costType: CostTypeStringEnum,
	setSpecialErrors: SetSpecialErrors
) => {
	if (costType === CostTypeStringEnum.percent) {
		return validatePercentAmount(errorKey, amount, setSpecialErrors);
	}

	return validateFixedAmount(errorKey, amount, setSpecialErrors);
};

export const validateEmployeeCoverageMultiplier = (
	name: string,
	value: number | string | null,
	setSpecialErrors: SetSpecialErrors
) => {
	const newValue = Number(value);
	const MIN_MULTIPLIER = 0.5;
	const MAX_MULTIPLIER = 15;

	return (
		validateGreaterThanOrEqualTo(name, newValue, MIN_MULTIPLIER, setSpecialErrors) &&
		validateLessThanOrEqualTo(name, newValue, MAX_MULTIPLIER, setSpecialErrors)
	);
};

export const validateFixedDependentCoverage = (name: string, value: number | string | null, setSpecialErrors: SetSpecialErrors) => {
	return validateGreaterThanOrEqualTo(name, Number(value), 500, setSpecialErrors);
};

export const validateFixedEmployeeCoverage = (name: string, value: number | string | null, setSpecialErrors: SetSpecialErrors) => {
	return validateGreaterThanOrEqualTo(name, Number(value), 1000, setSpecialErrors);
};

export const validateCoveragePercentAmount = (errorKey: string, amount: number | null, setSpecialErrors: SetSpecialErrors) => {
	return (
		validateRequiredField(errorKey, amount, setSpecialErrors) &&
		validateLessThanOrEqualTo(errorKey, amount, 100, setSpecialErrors) &&
		validateGreaterThan(errorKey, amount, 0, setSpecialErrors)
	);
};

export const validateCoverageType = (value: string, setSpecialErrors: SetSpecialErrors): boolean => {
	if (value) {
		return true;
	}

	setSpecialErrors('lifeCoverageType', { rule: RuleName.required, message: $.__('This field is required') });

	return false;
};

export const validateVoluntaryIncrement = (
	name: string,
	value: number | null,
	minAllowedValue: number,
	setSpecialErrors: SetSpecialErrors
) => {
	return validateGreaterThanOrEqualTo(name, value, minAllowedValue, setSpecialErrors);
};

export const validateVoluntaryMaximum = (
	name: string,
	maximumAmount: number | null,
	minimumAmount: number | null,
	increment: number | null,
	minAllowedValue: number,
	setSpecialErrors: SetSpecialErrors
) => {
	return (
		validateGreaterThanOrEqualTo(name, maximumAmount, increment || minAllowedValue, setSpecialErrors) &&
		validateGreaterThanOrEqualTo(name, maximumAmount, minimumAmount || minAllowedValue, setSpecialErrors)
	);
};

export const validateVoluntaryEmployeeMultiplier = (name: string, value: number | null, setSpecialErrors: SetSpecialErrors) => {
	const MIN_MULTIPLIER = 0.5;
	const MAX_MULTIPLIER = 15;

	if (value === null) {
		return true;
	}

	return (
		validateGreaterThanOrEqualTo(name, value, MIN_MULTIPLIER, setSpecialErrors) &&
		validateLessThanOrEqualTo(name, value, MAX_MULTIPLIER, setSpecialErrors)
	);
};

export const validateSpousePercentOfEmployeeCoverage = (name: string, value: number | null, setSpecialErrors: SetSpecialErrors) => {
	if (value === null) {
		return true;
	}

	return (
		validateLessThanOrEqualTo(name, value, 100, setSpecialErrors) && validateGreaterThanOrEqualTo(name, value, 0, setSpecialErrors)
	);
};
