import { PureComponent, Fragment } from 'react';
import { BodyText, Button, Headline, Icon, IconV2, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import EmailTemplatesTable from './table';

import './styles.styl';

const EmailTemplates = (props) => {
	return (
		<Fragment>
			<div className="emailTemplatesHeader">
				{ ifFeature('encore',
					<LayoutBox marginTop={'32px'} marginBottom={'4px'}>
						<Headline color={'primary'} size="small">
							{ $.__('Email Templates') }
						</Headline>
					</LayoutBox>,
					<h4>
						{ $.__('Email Templates') }
					</h4>
				)}

				{ ifFeature('encore',
					<BodyText size='small'>
						Setup email templates to send emails to your ATS candidates
					</BodyText>,
					<p>Setup email templates to send emails to your ATS candidates</p>
				)}

				{ ifFeature('encore',
					<LayoutBox marginTop={'32px'} marginBottom={'24px'}>
						<Button
							color="secondary"
							onClick={() => {
								window.location.href = '/settings/jobs/templates.php/emailTemplate';
							}}
							size="medium"
							startIcon={<IconV2 name='circle-plus-regular' size='16' color='primary-strong' />}
							type="button"
						>
							{ $.__('New Template') }
						</Button>
					</LayoutBox>,
					<a className="fab-Button fab-Button--outline fab-Button--small emailTemplatesHeader__action" href="/settings/jobs/templates.php/emailTemplate">
						<span className="fab-Button__icon fab-Button__icon--left">
							<Icon name="fab-circle-plus-14x14" />
						</span>
						{ $.__('New Template') }
					</a>
				)}
			</div>
			<EmailTemplatesTable { ...props } />
		</Fragment>
	);
};

export default EmailTemplates;
