import Ctrl from 'ctrl.deco';

@Ctrl('/settings/access_levels/admin')
class SettingsAccessLevelsAdminCtrl {

	headerTmplData = {
		showSettingsBtn: false,
	};

	tmplData = {
		canDuplicate: false,
		canDelete: false,
	};
}
