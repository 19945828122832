import { Fragment } from 'react';

import {
	AcaStatusAlignmentTable,
	formatStatuses,
} from 'aca-status-alignment-table.react';

import { Heading } from '../heading';

export function Conduct(props) {
	const {
		acaTypes,
		employmentStatusAcaTypes,
		employmentStatuses,
		onAcaStatusAssignment,
	} = props;

	const onFormChange = (employmentStatusId, acaStatusValue) => {
		onAcaStatusAssignment(acaStatusValue[0], employmentStatusId);
	};
	
	return (
		<Fragment>
			<Heading
				subText={ $.__(`The IRS has specific designations for employment status. Choose the ACA status that best fits each of your employment statuses. Don't worry, you can change these later in Settings >> Employee Fields >> Employment Status.`) }
				text={ $.__('Choose an ACA status for each Employment Status') }
				useDivider={ true }
			/>
			<AcaStatusAlignmentTable
				acaTypes={ acaTypes }
				alignments={ employmentStatusAcaTypes }
				onFormChange={ onFormChange }
				statusTypes={ formatStatuses(employmentStatuses) }
			/>
		</Fragment>
	);
}
