import { Icon, IconV2, TextButton as EncoreTextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { Button, TextButton } from '@fabric/button';
import { ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED } from '../../../../../../reports/shared/feature-toggles';
import './action.styl';

export function Action(props) {
	const { isFormValid, isSaving, isSmallScreen, onSubmit, score, isVisible = true, onAnonymityInfoClick } = props;
	const isDisabled = score === null || score < 0 || score > 10 || !isFormValid || isSaving;
	const submitLabel = $.__('Submit Answers');
	return (
		<div className={`EnpsAction ${!isVisible && 'EnpsAction--hidden'}`}>
			<div className='EnpsAction__row'>
				<div className='EnpsAction__button'>
					<Button
						clickAction={onSubmit}
						isDisabled={isDisabled}
						isProcessing={isSaving}
						size={isSmallScreen ? 'biggie' : 'medium'}
						text={submitLabel}
					/>
				</div>
				{ifFeature('encore', undefined, <div className='EnpsAction__rowSpacer'></div>)}
				{!isSmallScreen && (
					<div className='EnpsAction__privacyStatement'>
						{ifFeature('encore', <IconV2 name='lock-regular' size={16} color='neutral-strong' />, <Icon name='fab-lock-10x12' />)}
						<span className='EnpsAction__privacyStatementText'>
							{ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED ? (
								<>
									{$.__('Your feedback will be kept completely ')}
									{ifFeature(
										'encore',
										<EncoreTextButton onClick={onAnonymityInfoClick} type='button' inline>
											{$.__('anonymous')}
										</EncoreTextButton>,
										<TextButton onClick={onAnonymityInfoClick} type='button'>
											{$.__('anonymous')}
										</TextButton>
									)}
									.
								</>
							) : (
								<Message
									link={ExternalLink(
										'https://help.bamboohr.com/hc/en-us/articles/360006948434-Employee-Satisfaction-from-the-Employee-s-Perspective'
									)}
									text={$._('Your feedback will be kept completely [anonymous].')}
								/>
							)}
						</span>
					</div>
				)}
			</div>
		</div>
	);
}
