import { BlankState, Divider, Icon, LayoutBox } from '@bamboohr/fabric';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const OfferTemplatesBlankState = () => {
	return ifFeature(
		'encore',
		<LayoutBox>
			<Divider color="neutral-weak" marginBottom={6} />
			<BlankState
				icon={'bs-tumbleweed'}
				subtitle={$.__('Add a Template above.')}
				title={$.__("Hey, where'd all the Offer Templates go?")}
			/>
		</LayoutBox>,
		<div className="OfferTemplates__blankState">
			<div className="OfferTemplates__blankStateIcon">
				<Icon name="fab-pen-signing-120x100" />
			</div>
			<h3 className="OfferTemplates__blankStateTitle">
				{$.__("Hey, where'd all the Offer Templates go?")}
			</h3>
			<p className="OfferTemplates__blankStateText">
				{$.__('Add a Template above.')}
			</p>
		</div>,
	);
};

export default OfferTemplatesBlankState;
