import {
	RadioGroupItemProps,
} from '@bamboohr/fabric';

import {
	federalW2Box12DdHide,
	federalW2Box12DdShow,
} from '../constants';
import {
	L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_HIDE,
	L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_SHOW,
} from '../language';

export const federalW2Box12DdRadioOptions: Array<RadioGroupItemProps> = [
	{
		disabled: false,
		id: federalW2Box12DdShow,
		label: L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_SHOW,
		value: federalW2Box12DdShow,
	},
	{
		disabled: false,
		id: federalW2Box12DdHide,
		label: L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_HIDE,
		value: federalW2Box12DdHide,
	},
];
