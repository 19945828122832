import {
	useReducerContext,
} from '../state';

import RowGroup from '../row-group';

export default function Table() {
	const {
		language,
		userLanguage,
		pages,
		bem,
		loading,
	} = useReducerContext();

	return (
		<table className={ bem('table', { loading }) }>
			<colgroup>
				<col className={ bem('tableCol', 'key') }></col>
				<col className={ bem('tableCol', 'value') }></col>
				<col className={ bem('tableCol', 'actions') }></col>
			</colgroup>
			<thead>
				<tr>
					<th className={ bem('tableHeader', 'key') }>
						{ userLanguage.text }
					</th>
					<th className={ bem('tableHeader', 'value') }>
						{ language.text }
					</th>
					<th className={ bem('tableHeader', 'actions') }></th>
				</tr>
			</thead>
			<tbody>
				{ pages.map(page => Number(page.id) > 0 && ( // skip "Calculated" fields (id: -1)
					<RowGroup key={ page.id } page={ page } />
				)) }
			</tbody>
		</table>
	);
}
