import { ReactElement } from 'react';

export const setTooltipTextPosition = (firstLineText: string, secondLineText: string): ReactElement => {
	if (firstLineText && secondLineText) {
		return (
			<>
				<p>{ firstLineText }</p>
				<p>{ secondLineText }</p>
			</>
		)
	}

	return null;
}
