import {
	showModal,
} from 'show-modal.mod';

import {
	isCompanyTaxSettingModalOption,
} from './is-company-tax-setting-modal-options';
import type {
	CompanyTaxDropdownItem,
} from '../types';
import {
	CompanyTaxSettingsModal,
} from '../../company-tax-settings-modal/company-tax-settings-modal';

export function handleCompanyTaxSettingsSelect(itemValue: CompanyTaxDropdownItem['value'], selectedValue: {
		item: CompanyTaxDropdownItem;
        parentItem: CompanyTaxDropdownItem;
}) {
	if (typeof selectedValue === 'undefined' || selectedValue === null) {
		return;
	}
	const {
		item,
		parentItem,
	} = selectedValue;
	if (isCompanyTaxSettingModalOption(parentItem)) {
		showModal((
			<CompanyTaxSettingsModal
				clientEin={ item.clientEin }
				clientId={ item.clientId }
				clientName={ item.clientName }
			/>
		));
	}
}
