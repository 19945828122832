import { Button } from '@fabric/button';
import { Icon } from '@bamboohr/fabric';

export const DisabledOverlay = (props) => {
	const { onEnableApproval } = props;

	return (
		<div className="DisabledOverlay">
			<div className="DisabledOverlay__content">
				<div className="DisabledOverlay__icon">
					<Icon brand={ true } name="fab-triangle-exclamation-48x48" />
				</div>
				<h2>{ $.__('Approval Disabled') }</h2>
				<p>{ $.__('Enable this approval to edit it or to be able to request a change using this approval workflow.') }</p>
				<div className="DisabledOverlay__content-action eventEnable">
					<Button
						clickAction={ onEnableApproval }
						size="teenie"
						text={ $.__('Enable Approval') }
						type="button"
					/>
				</div>
			</div>
		</div>
	);
};
