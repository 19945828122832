import { TosConsentForm } from '../../shared/tos-consent-form.react';

export function VerifiedConsentBody(props) {
	const {
		consentCopy,
		continueLink,
		description,
	} = props;

	return (
		<>
			<p className="EmailVerification__instruction">{ description }</p>
			<TosConsentForm
				consentCopy={ consentCopy }
				continueLink={ continueLink }
			/>
		</>
	);
}
