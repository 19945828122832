import {
	L_COMPANY_TAX_SETTINGS,
} from './language';

export const COMPANY_TAX_SETTINGS_BI_ID_DROPDOWN_OPEN = 'settings-payrolltaxes-companytaxdropdown';
export const COMPANY_TAX_SETTINGS_BI_ID_SAVE = 'settings-payrolltaxes-companytaxsave';
export const COMPANY_TAX_SETTINGS_KEY = 'company-tax-settings';

export const COMPANY_TAX_SETTINGS_DROPDOWN_ITEM = {
	key: COMPANY_TAX_SETTINGS_KEY,
	text: L_COMPANY_TAX_SETTINGS,
	value: COMPANY_TAX_SETTINGS_KEY,
};

export const COMPANY_TAX_SETTINGS_DROPDOWN_ITEMS = [
	COMPANY_TAX_SETTINGS_DROPDOWN_ITEM,
];
