import { LayoutBox } from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { Message, ExternalLink } from '@utils/message';
import c from 'classnames';
import {
	/* @startCleanup encore */
	Fragment,
	/* @endCleanup encore */
} from 'react';

import {
	SectionStyledBox,
} from '../../../section-styled-box';
import { SectionStyledHeader } from '../../../section-styled-header';

export function FedWithholdingContainer(props) {
	const { headerHeadlineSize, showWithholding, usesSectionComponent } = props;

	const legendClassNames = c(
		'baseColor',
		'Fab-Formsection__legend',
		{ 'fab-FormSection--hidden': showWithholding == false }
	);

	return ifFeature('encore',
		(
			<SectionStyledBox usesSectionComponent={usesSectionComponent}>
				<LayoutBox
					hidden={ showWithholding === false }
					marginBottom={3}
				>
					<SectionStyledHeader
						hasPopover={true}
						headlineSize={headerHeadlineSize}
						popoverContent={
							<Message
								link={ ExternalLink('https://apps.irs.gov/app/withholdingcalculator/') }
								text={ $._('This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy [withholding calculator] to help you figure out what to elect.') }
							/>
						}
						popoverIcon="circle-question-regular"
						popoverPlacement="bottom"
						popoverTitle={$.__('W-4 Withholding')}
					>
						{ $.__('Federal W-4 Withholding') }
					</SectionStyledHeader>
				</LayoutBox>
				<div id="js-wholdings-federal">
					<div className="js-wholdings-loader circleDotLoader pi-federal-loader">
						<div className="loaderCircle1 loaderCircle"></div>
						<div className="loaderCircle2 loaderCircle"></div>
						<div className="loaderCircle3 loaderCircle"></div>
						<div className="loaderCircle4 loaderCircle"></div>
						<div className="loaderCircle5 loaderCircle"></div>
						<div className="loaderCircle6 loaderCircle"></div>
						<div className="loaderCircle7 loaderCircle"></div>
						<div className="loaderCircle8 loaderCircle"></div>
						<div className="loaderCircle9 loaderCircle"></div>
					</div>
				</div>
			</SectionStyledBox>
		),
		(
			<Fragment>
				<legend className={ legendClassNames }>
					{ $.__('Federal W-4 Withholding') }
					<div
						className="semibold pi-withhold-help"
						data-pop-content="This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy &lt;a href=&quot;https://apps.irs.gov/app/withholdingcalculator/&quot; target=&quot;_blank&quot;rel=&quot;noopener noreferrer&quot;&gt;withholding calculator&lt;/a&gt; to help you figure out what to elect."
						data-pop-content-css-override='{"maxWidth":"230px"}'
						data-pop-placement="bottom"
						data-pop-title="W-4 Withholding"
					>?
					</div>
				</legend>
				<div id="js-wholdings-federal">
					<div className="js-wholdings-loader circleDotLoader pi-federal-loader">
						<div className="loaderCircle1 loaderCircle"></div>
						<div className="loaderCircle2 loaderCircle"></div>
						<div className="loaderCircle3 loaderCircle"></div>
						<div className="loaderCircle4 loaderCircle"></div>
						<div className="loaderCircle5 loaderCircle"></div>
						<div className="loaderCircle6 loaderCircle"></div>
						<div className="loaderCircle7 loaderCircle"></div>
						<div className="loaderCircle8 loaderCircle"></div>
						<div className="loaderCircle9 loaderCircle"></div>
					</div>
				</div>
			</Fragment>
		)
	);
}
