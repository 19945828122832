export function getTranslation(field, context, questionType, resourceTranslations) {
	const {
		type,
	} = field;
	const id = field.field_id || field.id;

	if (resourceTranslations) {
		return {
			...resourceTranslations[id],
		};
	}

	if (type === 'page') {
		return {
			...context.pagesRT[id],
		};
	}

	if (type === 'performance') {
		try {
			return {
				...context.questionsRT[id][context.language.value][questionType === 'manager' ? 'mgrRt' : 'eeRt'],
			};
		} catch (err) {
			return {};
		}
	}

	return {
		...context.fieldsRT[id],
	};
}

export function isMissing(field, context, questionType) {
	if (
		!field ||
		String(field.id).includes('.')
	) {
		return false;
	}

	if (field.type === 'performance') {
		if (questionType) {
			return !!(
				field[`${ questionType }Question`] &&
				!getTranslation(field, context, questionType).translation
			);
		}

		return !!(
			(
				field.employeeQuestion &&
				!getTranslation(field, context, 'employee').translation
			) ||
			(
				field.managerQuestion &&
				!getTranslation(field, context, 'manager').translation
			)
		);
	}

	const {
		fields,
		listsFields,
		listValuesNeedTrans,
	} = context;

	const id = field.field_id || field.id;
	const {
		type = field.type,
		description = null,
	} = fields[id] || {};
	const {
		locked = false,
		translation = '',
		retranslate,
	} = getTranslation(field, context);
	let missingList = false;

	if (type === 'list') {
		const {
			id: listId,
		} = listsFields[id];
		const {
			fcnt,
			rtcnt,
		} = listValuesNeedTrans[listId];

		missingList = fcnt !== rtcnt;
	}

	return (
		missingList ||
		(description !== null && !description) ||
		(locked !== true && !translation) ||
		retranslate === 'yes'
	);
}
