module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push(''); if (isEncore) { 
p.push('  '); if (stateName !== '') { 
p.push('  <fieldset class="js-state-container">   <h3 class="fab-Section__title">    ',window.microTemplate.encodeHtml($.__("%s State W-4 Withholding", stateName)),'    <span     data-pop-title="W-4 Withholding"     data-pop-content="This determines how much you will withhold from your paycheck for state taxes."     data-pop-placement="bottom"    >     <ba-icon      encore-color="neutral-medium"      encore-name="circle-question-regular"      encore-size="16"     ></ba-icon>    </span>   </h3>  '); } 
p.push('   <div class="pi-exempt-state">    <img src="/images/pay_info/tax-exempt.svg" width="67">    '); if (stateName !== '') { 
p.push('     <p class="pi-exempt-state-info">',window.microTemplate.encodeHtml($.__("Exempt from %s State Taxes", stateName)),'</p>    '); } else { 
p.push('     <p class="pi-exempt-state-info">',window.microTemplate.encodeHtml($.__("Exempt from Federal Taxes")),'</p>    '); } 
p.push('   </div>  '); if (stateName !== '') { 
p.push('  </fieldset>  '); } 
p.push(' '); } else { 
p.push('  '); if (stateName !== '') { 
p.push('  <fieldset class="js-state-container">   <legend class="baseColor">    ',window.microTemplate.encodeHtml($.__("%s State W-4 Withholding", stateName)),'    <div class="semibold pi-withhold-help"     data-pop-title="W-4 Withholding"     data-pop-content="This determines how much you will withhold from your paycheck for state taxes."     data-pop-placement="bottom"     data-pop-content-css-override=\'{"maxWidth":"230px"}\'    >?</div>   </legend>  '); } 
p.push('   <div class="pi-exempt-state">    <img src="/images/pay_info/tax-exempt.svg" width="67">     '); if (stateName !== '') { 
p.push('     <p class="pi-exempt-state-info">',window.microTemplate.encodeHtml($.__("Exempt from %s State Taxes", stateName)),'</p>    '); } else { 
p.push('     <p class="pi-exempt-state-info">',window.microTemplate.encodeHtml($.__("Exempt from Federal Taxes")),'</p>    '); } 
p.push('   </div>  '); if (stateName !== '') { 
p.push('  </fieldset>  '); } 
p.push(' '); } 
p.push(' ');}return p.join('');
}