import {
	Button,
	Icon,
	Loader,
	Table,
	TextButton,
} from '@bamboohr/fabric';
import {
	ReactElement,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from 'react';
import { ajax } from '@utils/ajax';

import {
	showSlidedown,
} from 'Alerts.mod';
import {
	Message,
} from '@bamboohr/utils/lib/message';
import {
	PageFooter,
} from 'page-footer.react';

import {
	MAX_PAYROLL_TIME_OFF_TYPES_COUNT,
	getTimeOffTypesSummaryTableColumns,
} from './migration-section-time-off-types-summary.domain';
import {
	useStyles,
} from './migration-section-time-off-types-summary.styles';
import {
	HelpButton,
} from '../help-button';
import {
	CLOCK_ICON,
} from '../migration-section-head/migration-section-head-utils';
import {
	MigrationSectionTimeOffTypesSummaryProps,
	MigrationSectionTimeOffTypesSummarySaveType,
	MigrationSectionTimeOffTypesSummaryTableRow,
} from '../../utils/interfaces';

export function MigrationSectionTimeOffTypesSummary(props: MigrationSectionTimeOffTypesSummaryProps): ReactElement {
	const {
		onContinue,
		onExit,
	} = props;

	const styles = useStyles();
	const refIsMounted = useRef(true);
	const [rows, setRows] = useState<Array<MigrationSectionTimeOffTypesSummaryTableRow>>([]);
	const [saveType, setSaveType] = useState<MigrationSectionTimeOffTypesSummarySaveType>();
	const selectedRows = rows.filter(row => row.includeInPayroll);
	const selectedCount = selectedRows.length;
	const isSelectedCountOverLimit = selectedCount > MAX_PAYROLL_TIME_OFF_TYPES_COUNT;
	const canExit = !isSelectedCountOverLimit && !saveType;
	const canProceed = rows.length && canExit;

	useLayoutEffect(() => {
		return () => {
			refIsMounted.current = false;
		};
	}, []);
	
	useLayoutEffect(() => {
		window.scroll(0, 0);
	}, []);

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className={ styles.root }>
			<div className={ styles.header }>
				<Icon
					brand={ true }
					name={ CLOCK_ICON }
				/>
				<h2 className={ styles.headerText }>
					{ $.__('Time Off Types Summary') }
				</h2>
			</div>
			<div className={ styles.body }>
				{ rows.length ? (
					<>
						<div className={ styles.instruction }>
							<p>{ $.__('Below are the time off types we will be syncing. You can include up to %1$s of these time off types in your payroll.', MAX_PAYROLL_TIME_OFF_TYPES_COUNT) }</p>
							<div className={ styles.instructionRight }>
								<p className={ isSelectedCountOverLimit ? styles.selectedCountError : styles.selectedCount }>
									{ $.__('%1$s/%2$s Included in Payroll', selectedCount, MAX_PAYROLL_TIME_OFF_TYPES_COUNT) }
								</p>
								<HelpButton />
							</div>
						</div>
						<div className={ styles.notice }>
							<Message
								params={ ['COVID', 'FFCRA'] }
								text={ $._('If you have any time-off types in BambooHR for {1} or {2}, **do not** sync these with payroll. Continue to enter in the amounts using the {2} pay types in Step 2 of the payroll process.') }

							/>
						</div>
						<div className={ styles.table }>
							<Table
								caption={ $.__('Include in Payroll Table') }
								columns={ getTimeOffTypesSummaryTableColumns(styles, selectedCount, handleCheckboxChange) }
								rowKey={ (row: MigrationSectionTimeOffTypesSummaryTableRow) => row.id }
								rows={ rows }
							/>
						</div>
					</>
				) : (
					<div>
						<Loader />
					</div>
				) }
			</div>
			<div className={ styles.footer }>
				<PageFooter
					left={ (
						<div>
							<Button
								disabled={ !canProceed }
								onClick={ () => saveData('continue') }
								processing={ saveType === 'continue' }
								text={ $.__('Save & Continue') }
								type="button"
							/>
							<Button
								color="secondary"
								disabled={ !canProceed }
								onClick={ () => saveData('save') }
								processing={ saveType === 'save' }
								text={ $.__('Save Progress') }
								type="button"
								variant="outlined"
							/>
							<TextButton
								disabled={ !canExit }
								onClick={ () => saveData('exit') }
								processing={ saveType === 'exit' }
								text={ $.__('Save & Exit') }
								type="button"
							/>
						</div>
					) }
				/>
			</div>
		</div>
	);

	function handleCheckboxChange(id: string, includeInPayroll: boolean): void {
		setRows((prevRows) => {
			const changedRow = prevRows.find(row => row.id === id);
			changedRow.includeInPayroll = includeInPayroll;

			return [...prevRows];
		});
	}

	function getData(): void {
		ajax.get(`/data_comparison/company_time_off/selections`).then((response) => {
			if (!refIsMounted.current) {
				return;
			}
			setRows(response.data.timeOffTypes as Array<MigrationSectionTimeOffTypesSummaryTableRow>);
		}).catch(() => {
			showSlidedown($.__('Uh oh... something went wrong getting the time off summary data. Please try again later.'), 'error');
		});
	}

	function saveData(type: MigrationSectionTimeOffTypesSummarySaveType): void {
		const timeOffTypeIdsToSync = selectedRows.map(row => row.id);
		setSaveType(type);
		ajax.post(
			'/data_comparison/company_time_off/selections',
			{
				timeOffTypeIdsToSync,
			}
		).then(() => {
			showSlidedown($.__('Include in payroll selections have been saved.'), 'success');
			if (type === 'continue') {
				onContinue();
			} else if (type === 'exit') {
				onExit();
			} else {
				if (!refIsMounted.current) {
					return;
				}
				setSaveType(undefined);
			}
		}).catch(() => {
			showSlidedown($.__('Uh oh... something went wrong saving your changes. Please try again later.'), 'error');
			if (!refIsMounted.current) {
				return;
			}
			setSaveType(undefined);
		});
	}
}
