import { useEffect, useState } from 'react';

import { getToggles, isFeatureEnabled } from '../etc/feature-toggle';

interface FeatureTogglesState {
	areFeatureTogglesLoading: boolean;
	enabledFeatures: string[];
	isEnabled: (featureKey: string) => boolean;
}

/**
 * @deprecated use get-feature-toggles with is-feature-enabled instead
 */
export const useFeatureToggles = (): FeatureTogglesState => {
	const [enabledFeatures, setEnabledFeatures] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		getToggles()
			.then((response) => {
				if (response && response.data) {
					setEnabledFeatures(response.data.enabledFeatureKeys);
				} else {
					console.error(response);
				}
			})
			.finally(() => setIsLoading(false));
	}, []);

	const isEnabled = (featureKey: string): boolean => {
		return isFeatureEnabled(featureKey, enabledFeatures);
	};

	return {
		areFeatureTogglesLoading: isLoading,
		enabledFeatures,
		isEnabled,
	};
};
