import { EmbeddedVideo } from '../embedded-video';

import { makeStyles } from '@bamboohr/fabric';

const VIDEO_SRC = '//fast.wistia.net/embed/iframe/6v7ymu3zou?videoFoam=true';

const STYLES = makeStyles(({ palette, typography }) => ({
	content: {
		width: '555px',
		margin: '5px 0 30px',
		color: palette.grey[800],
		fontSize: typography.fabricFontSize('large'),
		lineHeight: typography.fabricLineHeight('teenie'),
	},
	videoWrapper: {
		width: '583px',
		height: '329px',
	},
}));

export const VideoSection = () => {
	const classes = STYLES();
	return (
		<>
			<h2>
				{ $.__('You’re Almost Ready to Manage Payroll in BambooHR!') }
			</h2>
			<div className={ classes.content }>
				{ $.__('To help you prepare for your new payroll experience please watch this 5-minute video that explains the new features you’ll see. Afterward, click to begin your new payroll experience!') }
			</div>
			<div className={ classes.videoWrapper }>
				<EmbeddedVideo videoSrc={ VIDEO_SRC } videoTitle="congratulations" />
			</div>
		</>
	);
};
