import { Flex, Loader } from '@bamboohr/fabric';
import React from 'react';

export function PageLoader() {
	return (
		<Flex marginY={37.5} minWidth='100%' alignItems='center' justifyContent='center'>
			<Loader />
		</Flex>
	);
}
