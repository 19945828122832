import {
	getBrandColor,
} from '../index';

import themes from '@bamboohr/fabric/dist/definitions/json/theme-colors.json';

/**
 * Return the current fabric theme color hex color. Example: "#82AF13"
 * Fallback to returning the legacy brand color
 *
 * @method getFabricBrandColor
 * @returns {*}
 */
export function getFabricBrandColor(variant = 'base') {
	const validOptions = ['base', 'light', 'lighter', 'lightest'];

	const brandColor = getBrandColor();
	const brandTheme = themes.themeColors.find(color => (
		color.name === brandColor ||
		color.base === brandColor
	));

	if (!validOptions.includes(variant)) {
		console.warn('Invalid Fabric variant. Valid options are: base, light, lighter, lightest');
		variant = 'base';
	}

	return brandTheme[variant];

}
