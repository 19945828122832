import {
	noop,
} from 'lodash';
import {
	Button,
	TextButton,
} from '@fabric/button';

import {
	MigrationFooterButtonsProps,
} from '../../utils/interfaces';

export function MigrationFooterButtons(props: MigrationFooterButtonsProps): JSX.Element {
	const {
		saveAndContinue: {
			onClick: onSaveAndContinueClick,
			isProcessing: saveAndContinueIsProcessing,
			isDisabled: saveAndContinueIsDisabled,
		},
		saveProgress: {
			onClick: onSaveProgressClick,
			isProcessing: saveProgressIsProcessing,
			isDisabled: saveProgressIsDisabled,
		},
		saveAndExit: {
			onClick: onSaveAndExitClick,
			isProcessing: saveAndExitIsProcessing,
			isDisabled: saveAndExitIsDisabled,
		},
	} = props;
	const saveAndExitButtonText = saveAndExitIsDisabled ? $.__('Exit') : $.__('Save & Exit');
	const saveAndExitClickAction = saveAndExitIsDisabled ? () => window.location.assign('/settings/payroll/migration_tasks') : onSaveAndExitClick;
	const disableSaveAndContinueClick = saveAndContinueIsDisabled || saveAndContinueIsProcessing;
	const disableSaveProgressClick = saveProgressIsDisabled || saveProgressIsProcessing;

	return (
		<div className="MigrationFooterButtons">
			<Button
				clickAction={ disableSaveAndContinueClick ? noop : onSaveAndContinueClick }
				isDisabled={ saveAndContinueIsDisabled }
				isProcessing={ saveAndContinueIsProcessing }
				text={ $.__('Save & Continue') }
				type="button"
			/>
			<Button
				clickAction={ disableSaveProgressClick ? noop : onSaveProgressClick }
				isDisabled={ saveProgressIsDisabled }
				isProcessing={ saveProgressIsProcessing }
				outline={ true }
				secondary={ true }
				text={ $.__('Save Progress') }
				type="button"
			/>
			<TextButton
				clickAction={ saveAndExitIsProcessing ? noop : saveAndExitClickAction }
				isProcessing={ saveAndExitIsProcessing }
				text={ saveAndExitButtonText }
				type="button"
			/>
		</div>
	);
}
