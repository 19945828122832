import React from 'react';

import { BenefitsAdminUpsell, DemoRequest, OnDemoRequest } from '../benefits-admin-upsell';
import { useBenefitsSettingsStore } from '../plans.react/data/benefits-settings-store';

interface BenefitsAdminWrapperProps {
	demoRequest: DemoRequest;
	isCompanyInTrial: boolean;
	onDemoRequest: OnDemoRequest;
	tab: JSX.Element;
}

export const BenefitsAdminWrapper = (props: BenefitsAdminWrapperProps): JSX.Element | null => {
	const { demoRequest, isCompanyInTrial, onDemoRequest, tab } = props;
	const [{
		isBenefitsAdminEnabled,
		isDataLoading,
	}] = useBenefitsSettingsStore();

	if (isDataLoading) {
		return null;
	}

	return isBenefitsAdminEnabled ? tab : (
		<BenefitsAdminUpsell demoRequest={demoRequest} isCompanyInTrial={isCompanyInTrial} onDemoRequest={onDemoRequest} />
	);
};
