import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({
	palette,
	spacing,
}) => {
	return createStyles({
		root: {
			alignItems: 'flex-start',
			backgroundColor: ifFeature('encore', "unset", palette.gray[100]),
			borderTopColor: palette.gray[300],
			borderTopStyle: 'solid',
			borderTopWidth: 1,
			display: 'flex',
			paddingBottom: ifFeature('encore', 0, spacing(1.5)),
			paddingLeft: ifFeature('encore', 0, spacing(2)),
			paddingRight: ifFeature('encore', spacing(1), spacing(2)),
			paddingTop: ifFeature('encore', spacing(2), spacing(1.5)),
			marginTop: ifFeature('encore', spacing(1), "unset"),
			margin: ifFeature('encore', "0px 20px", "unset"),
		},
		// @startCleanup encore
		icon: {
			fill: palette.gray[700],
			marginRight: spacing(1),
		},
		// @endCleanup encore
		message: {
			color: palette.gray[800],
		},
	});
});
