import Ctrl, {
	onClick,
} from 'ctrl.deco';
import {
	getParameterByName,
} from '@utils/url';
import Clipboard from 'clipboard';

import modal from './modal.micro.html';

import './styles.styl';

const MODAL_CLASS = `SettingsPermissionsApiAddEditModal`;
const actionMap = {
	delete: {
		title: $.__('Delete API Key'),
		button: $.__('Delete'),
	},
	deleteDevice: {
		title: $.__('Delete Device'),
		button: $.__('Delete'),
	},
	add: {
		title: $.__('Add New API Key'),
		button: $.__('Generate Key'),
	},
	edit: {
		title: $.__('Edit API Key'),
		button: $.__('Save'),
	},
};

let _apiKey = null;
let _modal = null;
let _clipboard = null;

@Ctrl('/settings/permissions/api.php')
class SettingsPermissionsApiCtrl {

	@onClick('.js-addApiKey')
	_onAddKeyClick(e, ctrl) {
		ctrl.showModal('add');
	}

	@onClick('.js-editApiKey')
	_onEditNameClick(e, ctrl) {
		ctrl.showModal('edit', this);
	}

	@onClick('.js-deleteApiKey')
	_onDeleteKeyClick(e, ctrl) {
		const button = e.target.closest('.js-deleteApiKey');
		const { apiKeyId, apiKeyName } = button.dataset;

		window.BambooHR.Modal.setState({
			headline: $.__('Once deleted, API keys cannot be restored. Are you sure you want to delete this API Key?'),
			iconV2Color: 'error-strong',
			isOpen: true,
			primaryAction: () => {
				window.BambooHR.Modal.setState({ isProcessing: true }, true);

				ctrl.submit(apiKeyId, 'delete', apiKeyName);
			},
			type: 'aggressiveConfirmation',
			title: $.__('Delete API Key?'),
		});
	}

	@onClick('.js-deleteDevice')
	_onDeleteDeviceClick(e, ctrl) {
		ctrl.showModal('deleteDevice', this);
	}

	get userId() {
		return parseInt(getParameterByName('id'));
	}

	get apiKey() {
		return _apiKey;
	}

	set apiKey(val) {
		const hasKey = typeof val === 'string';
		const hasModal = !!_modal;

		_apiKey = hasKey ? val : null;

		if (hasModal) {
			_modal.find('.js-newApiKey')
				.text(hasKey ? _apiKey : '');

			_modal.find('.js-keySummary')
				.toggleClass(`${ MODAL_CLASS }__keySummary--added`, hasKey);

			window.setTimeout(() => {
				window.BambooHR.Modal.setState({
					isProcessing: false,
					onClose: () => window.setTimeout(() => window.location.reload()),
					primaryActionText: $.__('Done'),
				}, true);
			});
		}
	}

	showModal(action, elem) {
		const $elem = $(elem);
		const id = elem ? parseInt($elem.attr('data-api-key-id') || $elem.attr('data-device-id')) : null;
		const IS_ADD = action === 'add';
		const IS_EDIT = action === 'edit';
		const IS_DELETE = action === 'delete';
		const IS_DELETE_DEVICE = action === 'deleteDevice';
		const IS_ADD_EDIT = IS_ADD || IS_EDIT;
		let $nameInput;

		_apiKey = null;

		window.BambooHR.Modal.setState({
			dangerousContent: microTemplate(modal, {
				get apiKey() {
					return IS_DELETE_DEVICE ? null : {
						id,
						name: $elem.attr('data-api-key-name'),
					};
				},
				get device() {
					return IS_DELETE_DEVICE ? id : null;
				},
				IS_ADD,
				IS_EDIT,
				IS_DELETE,
				IS_DELETE_DEVICE,
				IS_ADD_EDIT,
			}),
			isOpen: true,
			onClose: () => {
				_modal = null;

				if (_clipboard) {
					_clipboard.destroy();
					_clipboard = null;
				}
			},
			onOpen: () => {
				_modal = $('.js-devices-and-keys');

				$nameInput = _modal.find('input#key_name')
					.focus().select();

				_clipboard = new Clipboard('.js-copyApiKey', {
					text() {
						setMessage($.__('API Key copied to clipboard'), 'success');
						return _apiKey;
					}
				});
			},
			primaryAction: () => {
				window.BambooHR.Modal.setState({
					alternativeAction: null,
					isProcessing: true
				}, true);

				if (this.apiKey) {
					window.location.reload();
				} else {
					this.submit(id, action, $nameInput.val());
				}
			},
			primaryActionText: actionMap[action].button,
			title: actionMap[action].title
		});
	}

	submit(id, action, name) {
		const idKey = action === 'deleteDevice' ? 'deviceId' : 'key';

		$.post(window.location.pathname, {
			id: this.userId,
			[idKey]: id,
			action,
			name,
		})
			.done(({
				success,
				apiKey,
				hasDevices,
				hasApiAccess,
			}) => {
				if (
					!success ||
					(
						action === 'add' &&
						!apiKey
					)
				) {
					window.BambooHR.Modal.setState({ isProcessing: true }, true);

					setMessage($.__('There was a problem performing this action. Please try again or contact support.'), 'error');
					return;
				}

				if (action === 'delete') {
					$(`[data-api-key-id="${ id }"]`)
						.closest('tr')
						.remove();

					window.BambooHR.Modal.setState({ isOpen: false });

				} else if (action === 'deleteDevice') {
					if (hasDevices) {
						$(`[data-device-id="${ id }"]`)
							.closest('tr')
							.remove();

						window.BambooHR.Modal.setState({ isOpen: false });
					} else if (hasApiAccess) {
						window.location.reload();
					} else {
						window.location = '/home';
					}
				} else if (action === 'add') {
					this.apiKey = apiKey;
					window.BambooHR.Modal.setState({ isProcessing: true }, true);
				} else {
					window.location.reload();
				}
			})
			.fail(() => {
				window.BambooHR.Modal.setState({ isProcessing: true }, true);
				setMessage($.__('There was a problem performing this action. Please try again or contact support.'), 'error');
			});
	}
}
