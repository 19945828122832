import Ajax from '@utils/ajax';
import BEM from '@utils/dom/bem';

import Ctrl from 'ctrl.deco';


const _ajax = Ajax.create('SettingsAccessLevelsNoAccessSetAccessLevelModal', {
	baseURL: `/settings/access_levels`,
	params: {
		json: true,
	},
});

/**
 * @param {object} params
 * @param {string[]} params.userIds
 * @param {string[]} params.employeeIds
 * @returns {Promise<import('./state').ModalData>}
 */
export const getModalData = params => _ajax.get('grant_access_modal', params)
	.then(({ data }) => ({
		...params,
		...data,
	}))
	.catch((error) => {
		window.BambooHR.Modal.setState({});

		console.error(error);
		window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
	});

/**
 * @param {object} data
 * @param {string[]} data.accessLevelIds
 * @param {string[]} data.employeeIds
 * @param {string[]} data.userIds
 * @returns {Promise<SubmitResult>}
 */
export const submitModal = ({
	accessLevelIds = [''],
	employeeIds = [''],
	userIds = [''],
}) => {
	window.BambooHR.Modal.setState({
		isProcessing: true,
	}, true);

	return _ajax.post('save_employees_access_level', $.param({
		accessLevelIds,
		editType: 'change',
		employeeIds,
		userIds,
	}))
		.catch(error => ({
			data: {
				success: false,
				error,
			},
		}))
		.then(({ data: { users, ...data } }) => {
			const {
				success,
				error,
				errorMessage = window.DEFAULT_ERROR_MESSAGE,
			} = data;

			if (!success) {
				window.setMessage(errorMessage, 'error');
				console.error(error);

				window.BambooHR.Modal.setState({
					isProcessing: false,
				}, true);

				return;
			}

			window.BambooHR.Modal.setState({});

			Ctrl.refresh();

			return users;
		});
};

const _bem = new BEM(`SetAccessLevelModal`);

/**
 * @type {import('@utils/dom/bem').default['className']}
 */
export const bem = (...args) => _bem.className(...args);

/**
 * @typedef {{ [id: string]: SubmitResultUser }} SubmitResult
 */

/**
 * @typedef SubmitResultUser
 * @property {string} userId
 * @property {string[]} accessLevelIds
 * @property {boolean} noAccess
 */
