import { Button, CardSize, Select, StandardModal, TextButton, type SelectProps } from '@bamboohr/fabric';
import { TriangleExclamation48x48 } from '@bamboohr/grim';

import { showSlidedown } from 'Alerts.mod';
import { ReactElement, useEffect, useState } from 'react';

import {
	doDisableMFA,
	doGetEmployeesList,
	doPostMfaSettings,
	doResetEmployeeMFALogin,
	doUpdateMfaSettings,
	getAccountConfiguration,
	hideManageNavMfaOptions,
	MfaSettingsData,
} from 'multi-factor-authentication.mod';

import { getAccessLevelGroups } from 'access-levels.mod';

import { MFA_LEVELS, MODAL_TYPES } from '../../constants';

import { useStyles } from './styles';
import { EditMfaSettingsModalContent } from '../edit-mfa-settings-modal-content';
import { AccessLevel, parseAccessLevelGroupsToGroupedMenuItemArray } from '../../etc';
import { MfaFormData, UtilityModalProps } from './types';
import { isFormDirty, isFormValid } from './utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface ModalProps {
	content: ReactElement;
	headline?: string;
	icon?: ReactElement;
	isProcessing: boolean;
	onClose: () => void;
	primaryAction?: () => void;
	primaryActionText: string;
	size?: CardSize;
	title: string;
}

export function TwoStepLoginUtilityModal({
	activeStartDateIsPast,
	modalToRender,
	refreshSettingsPage,
	setIsParentComponentVisible,
	setToggleGetStarted,
}: UtilityModalProps): ReactElement {
	const styles = useStyles();
	const [isModalOpen, setIsModalOpen] = useState(true);
	const [selectOptionValue, setSelectOptionValue] = useState([]);
	const [isModalProcessing, setIsModalProcessing] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(true);
	const [employeesList, setEmployeesList] = useState([]);
	const [accessLevelOptions, setAccessLevelOptions] = useState([]);
	const [selectedAccessLevelIds, setSelectedAccessLevelIds] = useState<string[]>([]);
	const [selectedAccessLevelsByIds, setSelectedAccessLevelsById] = useState<Record<string, string>>({});
	const [selectedMfaLevel, setSelectedMfaLevel] = useState<MFA_LEVELS>(MFA_LEVELS.COMPANY);
	const [selectedStartDate, setSelectedStartDate] = useState<string>();
	const [currentMfaData, setCurrentMfaData] = useState<MfaFormData>({} as MfaFormData);

	const handleAccessLevel = (accessLevel) => {
		if (accessLevel === 'none') {
			return 'userOptIn';
		}
		return accessLevel;
	};

	useEffect(() => {
		setTimeout(() => $('#resetLogin').bhrValidate());

		switch (modalToRender) {
			case MODAL_TYPES.SETUP:
			case MODAL_TYPES.EDIT_SETTINGS:
				Promise.all([getAccessLevelGroups(), getAccountConfiguration()])
					.then((values) => {
						const accessLevels = (values[0] as { data: { levelTypes: AccessLevel[] } }).data.levelTypes;
						const mfaSettings = (
							values[1] as {
								data: {
									startDate: string;
									companyConfiguredAccess: MFA_LEVELS;
									selectedAccessLevelsById: Record<string, string>;
								};
							}
						).data;
						const selectedAccessLevelIds = Object.keys(mfaSettings.selectedAccessLevelsById);
						setAccessLevelOptions(parseAccessLevelGroupsToGroupedMenuItemArray(accessLevels));
						setSelectedAccessLevelsById(mfaSettings.selectedAccessLevelsById);
						setSelectedAccessLevelIds(selectedAccessLevelIds);
						setSelectedMfaLevel(handleAccessLevel(mfaSettings.companyConfiguredAccess));
						setSelectedStartDate(mfaSettings.startDate);
						setCurrentMfaData({
							startDate: mfaSettings.startDate,
							companyConfiguredAccess: mfaSettings.companyConfiguredAccess,
							selectedAccessLevelIds,
						});
					})
					.finally(() => {
						setIsModalLoading(false);
					});
				break;
			case MODAL_TYPES.RESET_MFA:
				doGetEmployeesList()
					.then((resp: { data: { name: string; userId: string }[] }) => {
						const items = resp.data.map(({ name, userId }) => ({ text: name, value: userId }));
						setEmployeesList(items);
					})
					.finally(() => {
						setIsModalLoading(false);
					});
				break;
			default:
				setIsModalLoading(false);
		}
	}, []);

	const updateModalStatus = () => {
		setIsModalOpen(false);
		setIsParentComponentVisible(false);
	};

	const handleOptInDate = (date): string => {
		if (date && selectedMfaLevel === 'userOptIn') {
			setSelectedStartDate(null);
			return null;
		}
		return date;
	};

	function postMfaSettings() {
		const formData: MfaSettingsData = {
			mfaRequiredStartDate: `${selectedStartDate ? selectedStartDate : ''}`,
			accessLevelIds: selectedAccessLevelIds,
			companyConfiguredAccess: selectedMfaLevel,
		};
		setIsModalProcessing(true);
		doPostMfaSettings(formData).then(() => {
			window.location.reload();
			updateModalStatus();
		});
	}

	function putMfaSettings() {
		const formData: MfaSettingsData = {
			mfaRequiredStartDate: handleOptInDate(selectedStartDate),
			accessLevelIds: selectedMfaLevel === MFA_LEVELS.ACCESS_LEVEL ? selectedAccessLevelIds : [],
			companyConfiguredAccess: selectedMfaLevel,
		};
		doUpdateMfaSettings(formData).then(() => {
			refreshSettingsPage();
			updateModalStatus();
		});
	}

	const resetEmployee = () => {
		// Checks validity of the select
		if ($('#resetLogin').valid()) {
			const userId = selectOptionValue[0];
			setIsModalProcessing(true);
			doResetEmployeeMFALogin(userId)
				.then(() => {
					// If user is resetting their own MFA login, we should hide the MFA related options in the manage-nav.
					if (userId === window.SESSION_USER?.id) {
						hideManageNavMfaOptions();
					}

					const { text: name } = employeesList.find((employee) => employee.value === userId);
					const successMsg = $.__('2-Step Login has been reset for %1$s', name);
					showSlidedown(successMsg, 'success');
					setIsModalOpen(false);
					setIsParentComponentVisible(false);
				})
				.catch(() => setIsModalProcessing(false));
		}
	};

	const handleDisableMFA = () => {
		setIsModalProcessing(true);
		doDisableMFA().then(() => {
			window.location.reload();
		});
	};

	const getModalContent = (): ReactElement => {
		if (modalToRender === 'resetLogin') {
			const selectProps: SelectProps = {
				onChange: (value) => setSelectOptionValue(value),
				items: employeesList,
				selectedValues: selectOptionValue,
				name: 'resetEmployeeSelect',
				// @ts-expect-error TODO: fix "isRequired does not exist in SelectProps"
				isRequired: true,
				width: 7,
			};

			return (
				<>
					<form className='fab-Form' id='resetLogin'>
						<p className={styles.resetLoginContent}>
							{$.__(
								'Use this when an employee or other user needs to reset 2-Step Login. This will disable all of their current verification methods and on their next login, they will be directed to setting up their verification methods.'
							)}
						</p>
						<label className='fab-Label fab-Label--required' htmlFor='resetEmployeeSelect'>
							{$.__('Who needs the reset?')}
						</label>
						<div className={`${styles.selectWrapper} fab-InputWrapper required`}>
							<Select {...selectProps} />
						</div>
					</form>
				</>
			);
		}
	};

	let modalProps: ModalProps;

	const isDirty = isFormDirty(currentMfaData, {
		startDate: selectedStartDate,
		companyConfiguredAccess: selectedMfaLevel,
		selectedAccessLevelIds,
	});

	switch (modalToRender) {
		case 'resetLogin':
			modalProps = {
				content: getModalContent(),
				isProcessing: isModalProcessing,
				onClose: () => updateModalStatus(),
				primaryAction: selectOptionValue.length > 0 ? () => resetEmployee() : null,
				primaryActionText: $.__('Reset'),
				size: CardSize.MEDIUM,
				title: $.__('Reset Employee’s 2-Step Login'),
			};
			break;
		case 'editTwoStep':
			modalProps = {
				content: (
					<EditMfaSettingsModalContent
						accessLevelOptions={accessLevelOptions}
						activeStartDateIsPast={activeStartDateIsPast}
						isDirty={isDirty}
						selectedAccessLevelIds={selectedAccessLevelIds}
						selectedAccessLevelsById={selectedAccessLevelsByIds}
						selectedMfaLevel={selectedMfaLevel}
						selectedStartDate={selectedStartDate}
						setSelectedAccessLevelIds={setSelectedAccessLevelIds}
						setSelectedAccessLevelsById={setSelectedAccessLevelsById}
						setSelectedMfaLevel={setSelectedMfaLevel}
						setSelectedStartDate={setSelectedStartDate}
					/>
				),
				isProcessing: isModalProcessing,
				onClose: () => updateModalStatus(),
				primaryAction:
					isDirty &&
					isFormValid(currentMfaData, {
						startDate: selectedStartDate,
						companyConfiguredAccess: selectedMfaLevel,
						selectedAccessLevelIds,
					})
						? () => putMfaSettings()
						: null,
				primaryActionText: $.__('Save Changes'),
				size: CardSize.MEDIUM,
				title: $.__('Enable 2-Step Login'),
			};
			break;
		case 'setupTwoStep':
			modalProps = {
				content: (
					<EditMfaSettingsModalContent
						accessLevelOptions={accessLevelOptions}
						activeStartDateIsPast={false}
						isDirty={isDirty}
						selectedAccessLevelIds={selectedAccessLevelIds}
						selectedAccessLevelsById={selectedAccessLevelsByIds}
						selectedMfaLevel={selectedMfaLevel}
						selectedStartDate={selectedStartDate}
						setSelectedAccessLevelIds={setSelectedAccessLevelIds}
						setSelectedAccessLevelsById={setSelectedAccessLevelsById}
						setSelectedMfaLevel={setSelectedMfaLevel}
						setSelectedStartDate={setSelectedStartDate}
					/>
				),
				isProcessing: isModalProcessing,
				onClose: () => {
					setToggleGetStarted(true);
					updateModalStatus();
				},
				primaryAction: isFormValid(currentMfaData, {
					startDate: selectedStartDate,
					companyConfiguredAccess: selectedMfaLevel,
					selectedAccessLevelIds,
				})
					? () => postMfaSettings()
					: null,
				primaryActionText: $.__('Enable'),
				size: CardSize.MEDIUM,
				title: $.__('Enable 2-Step Login'),
			};
			break;
		default:
			modalProps = {
				primaryActionText: '',
				title: '',
				content: null,
				headline: '',
				icon: null,
				isProcessing: false,
				onClose: () => updateModalStatus(),
			};
	}

	return (
		<StandardModal
			isLoading={isModalLoading}
			isOpen={isModalOpen}
			isProcessing={modalProps.isProcessing}
			onCloseComplete={() => {
				setIsParentComponentVisible(false);
			}}
			onRequestClose={modalProps.onClose}
		>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={
							ifFeature('encore',
							(
								[
									<TextButton key='secondary' onClick={modalProps.onClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
									<Button isDisabled={!modalProps.primaryAction} key='button' onClick={modalProps.primaryAction} type='button'>
										{modalProps.primaryActionText}
									</Button>,
								]
							),(
								[
									<Button isDisabled={!modalProps.primaryAction} key='button' onClick={modalProps.primaryAction} type='button'>
										{modalProps.primaryActionText}
									</Button>,
									<TextButton key='secondary' onClick={modalProps.onClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
								]
							))

						}
					/>
				}
				renderHeader={<StandardModal.Header hasCloseButton={true} title={modalProps.title} />}
				size={modalProps.size}
			>
				<StandardModal.Constraint
					spacingOverrides={{
						top: StandardModal.Constraint.Spacing.MEDIUM,
						bottom: StandardModal.Constraint.Spacing.MEDIUM,
					}}
				>
					{modalProps.headline && (
						<StandardModal.HeroHeadline icon={modalProps.icon} text={modalProps.headline}>
							<StandardModal.Constraint
								spacingOverrides={{
									bottom: StandardModal.Constraint.Spacing.TEENIE,
								}}
							/>
						</StandardModal.HeroHeadline>
					)}
					<div className=''>{modalProps.content}</div>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
