import { useEffect, useState } from 'react';
import { Flex, makeStyles, Typography } from '@bamboohr/fabric';
import { Stopwatch17x20 } from '@bamboohr/grim';
import {
	CancellationDropdown,
	CancellationModal,
	CancellationStatus,
	CancellationType,
	Services,
	SadToGoBox,
} from 'in-app-cancellation.mod';

interface SettingsHeader {
	disableCancellationDropdown?: boolean;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
	icon: {
		fill: palette.primary.main,
		marginRight: spacing(1.125), // 9px
		marginTop: spacing(0.625), // 5px
	},
	pill: {
		marginLeft: spacing(3.625), // 29px
		marginTop: spacing(0.75), // 6px. Need this margin because of padding applied to `#settings-main-content h4`. Safe to remove.
	},
}));

export const SettingsHeader = ({ disableCancellationDropdown }) => {
	const [cancellationData, setCancellationData] = useState<CancellationStatus | null>(null);
	const [cancellationPending, setCancellationPending] = useState(false);
	const [cancellationStatus, setCancellationStatus] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		getCancellationStatus();
	}, []);

	const getCancellationStatus = () => {
		Services.getCancellationFeatureStatus(CancellationType.TIME_TRACKING).then((resp) => {
			setCancellationData(resp.data);
			setCancellationPending(resp.data.pending_cancellation);
		});
		Services.getCancellationStatusAll().then((resp) => {
			// formating for sadbox
			setCancellationStatus(
				resp.data.filter((item) => item.feature === CancellationType.TIME_TRACKING || item.feature === CancellationType.ACCOUNT)
			);
		});
	};

	const handleOnSelect = () => {
		setModalOpen(true);
	};

	const title = $.__('Time Tracking Settings');
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<>
			<Flex alignItems='center' pt={0.625} justifyContent='space-between'>
				<Flex alignItems='center'>
					<Stopwatch17x20 className={classes.icon} />
					<Typography variant='h4'>{title}</Typography>
				</Flex>
				{/* TODO: Fragment can be removed once feature is in GA */}
				{!disableCancellationDropdown && !!cancellationData && !cancellationPending && (
					<>
						<CancellationDropdown cancellationData={cancellationData} handleOnSelect={handleOnSelect} title={$.__('Time Tracking')} />
						<CancellationModal
							isOpen={modalOpen}
							onClose={() => setModalOpen(false)}
							onSubmitSuccess={getCancellationStatus}
							types={[CancellationType.TIME_TRACKING]}
						/>
					</>
				)}
			</Flex>
			{cancellationPending && <SadToGoBox cancellationData={cancellationStatus} isAddon={true} />}
		</>
	);
};
