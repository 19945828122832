import {
	useMemo,
	useState,
	useCallback,
} from 'react';

import {
	Button,
	TextButton,
	Dropdown,
	ItemType,
	makeStyles,
} from '@bamboohr/fabric';

import {
	cloneDeep
} from 'lodash';

import {
	NameAndEmployees,
	AddBenefitPlanModal,
	DeductionLoanCell,
} from '../components';

import {
	DeductionPlanCellProps,
} from '../types';

const useStyles = makeStyles({
	textButton: {
		height: 20,
	}
});

export function DeductionPlanCell(props: DeductionPlanCellProps): JSX.Element {
	const styles = useStyles();
	const {
		deduction,
		deduction: {
			created,
			allowableTypes,
			postProcessingPostable,
			employees,
			deductionName,
			allowableLoanNumbers
		},
		updateDeductions,
	} = props;
	const allowableTypesLength = allowableTypes.length;
	const hasMultipleAllowableTypes = allowableTypesLength > 1;
	const allowableTypeItems: ItemType[] = useMemo(
		() => allowableTypes.map(({type, displayName}) => ({text: displayName, value: type})),
		[allowableTypes]
	);

	const [addPlanModalOpen, setAddPlanModalOpen] = useState<boolean>(false);
	const [addPlanType, setAddPlanType] = useState<ItemType>(allowableTypeItems[0]);

	const handlePlanModalOpen = useCallback(() => setAddPlanModalOpen(true), []);
	const handlePlanModalClose = useCallback(() => setAddPlanModalOpen(false), []);
	const handleAddPlanClick = useCallback((allowableType: ItemType) => {
		setAddPlanType(allowableType);
		handlePlanModalOpen();
	}, [handlePlanModalOpen]);

	const resetPlanHandler = useCallback(() => {
		const newDeduction = cloneDeep(deduction);
		newDeduction.created = false;

		updateDeductions(newDeduction);
	}, [deduction, updateDeductions])

	const {
		text: typeName,
		value: planType,
	} = addPlanType;

	if (allowableLoanNumbers && allowableLoanNumbers.length > 0) {
		return <DeductionLoanCell { ...props } />;
	}

	// Display Plan Name and Employee Count
	if (postProcessingPostable) {
		return <NameAndEmployees deductionName={ deductionName } employees={ employees } />;
	}

	// Display Plan Name, Employee Count, and Reset Text Button
	if (created) {
		return (
			<>
				<NameAndEmployees deductionName={ deductionName } employees={ employees } />
				<br />
				{ hasMultipleAllowableTypes ? (
					<Dropdown
						buttonSettings={ {
							text: $.__('Reset This Benefit Plan'),
							size: 'small',
							className: styles.textButton,
						} }
						items={ allowableTypeItems }
						onSelect={ (itemValue, {item}) => handleAddPlanClick(item) }
						type="text"
					/>
				) : (
					<TextButton
						className={ styles.textButton }
						onClick={ resetPlanHandler }
						size="small"
						type="button"
					>
						{ $.__('Reset This Benefit Plan') }
					</TextButton>
				) }
				<AddBenefitPlanModal
					deduction={ deduction }
					isOpen={ addPlanModalOpen }
					onClose={ handlePlanModalClose }
					type={ planType as string }
					typeName={ typeName as string }
					updateDeductions={ updateDeductions }
				/>
			</>
		);
	}

	// Display "Add This Plan" Button with Single Type
	if (allowableTypesLength === 1) {
		return (
			<>
				<Button
					onClick={ () => handleAddPlanClick(allowableTypeItems[0]) }
					size="small"
					type="button"
					variant="outlined"
				>
					{ $.__('Add This Plan to BambooHR') }
				</Button>
				<AddBenefitPlanModal
					deduction={ deduction }
					isOpen={ addPlanModalOpen }
					onClose={ handlePlanModalClose }
					type={ planType as string }
					typeName={ typeName as string }
					updateDeductions={ updateDeductions }
				/>
			</>
		);
	}

	// Display "Add This Plan" Button as Dropdown with multiple Allowable Types
	if (hasMultipleAllowableTypes) {
		return (
			<>
				<Dropdown
					buttonSettings={ {outline: true, text: $.__('Add This Plan to BambooHR'), size: 'small'} }
					items={ allowableTypeItems }
					onSelect={ (itemValue, {item}) => handleAddPlanClick(item) }
				/>
				<AddBenefitPlanModal
					deduction={ deduction }
					isOpen={ addPlanModalOpen }
					onClose={ handlePlanModalClose }
					type={ planType as string }
					typeName={ typeName as string }
					updateDeductions={ updateDeductions }
				/>
			</>
		);
	}

	return null;
}
