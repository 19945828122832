import {
	EmployeeTaxMetadataStateUnemploymentInsurance,
	EmployeeTaxMetadataStateUnemploymentInsuranceKey,
	EmployeeTaxMetadataStateUnemploymentInsuranceKeyStandard,
	MessageObject,
} from '../../utils/interfaces';

export const STANDARD_KEY_TO_MESSAGE_TEXT_MAP: {
	[key in EmployeeTaxMetadataStateUnemploymentInsuranceKeyStandard]: string;
} = {
	state_is_ui_exempt: $._('Exempt'),
	state_ui_type: $._('Location'),
	state_ui_work_in: $._('State'),
};

export const STATE_OPTION_NAME_TO_MESSAGE_TEXT_MAP: {
	[name: string]: string;
} = {
	'Standard Occupational Classification (SOC) Code': `SOC ${ $._('Code') }`,
};

export const TITLE_KEYS: Array<EmployeeTaxMetadataStateUnemploymentInsuranceKeyStandard> = ['state_ui_type', 'state_ui_work_in'];

export function getDetails(metadata: EmployeeTaxMetadataStateUnemploymentInsurance): Array<MessageObject> {
	const details = [];

	Object.keys(metadata).forEach((key: EmployeeTaxMetadataStateUnemploymentInsuranceKey) => {
		if (key === 'employee_tax_state_ui_options') {
			const options = metadata[key];
			options.forEach((option) => {
				const text = STATE_OPTION_NAME_TO_MESSAGE_TEXT_MAP[option.taxStateUiOption] || option.taxStateUiOption;
				details.push({
					params: [option.displayValue],
					text: `${ text }: {1}`,
				});
			});
			return;
		}

		if (TITLE_KEYS.includes(key)) {
			return;
		}

		const text = STANDARD_KEY_TO_MESSAGE_TEXT_MAP[key];
		if (text) {
			details.push({
				params: [getDisplayValue(metadata[key])],
				text: `${ text }: {1}`,
			});
		}
	});

	return details;
}

export function getDisplayValue(value: string): string {
	if (value === undefined || value === null) {
		return '--';
	}

	return value;
}
