import { createStyles, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from "@utils/feature";

export default makeStyles(({ palette, typography }) => {
	return createStyles({
		fileListItem: {
			display: 'flex',
			borderBottom: `1px solid ${ palette.grey[300] }`,
			margin: 0,
			padding: '0 16px',
			height: '64px',
			alignItems: 'center',
			width: ifFeature('encore', 'calc(100% - 32px)', 'auto'),
			justifyContent: ifFeature('encore', 'space-between', 'flex-start'),
			'&:hover': {
				background: palette.grey[100],
				cursor: 'pointer',
			},
		},
		active: {
			backgroundColor: `${ palette.primary.lightest }1A`,
			'&:hover': {
				backgroundColor: `${ palette.primary.lightest }1A`,
			},
			'& $infoTitle': {
				color: palette.grey[1000],
				fontWeight: typography.fontWeightSemibold,
			},

			'& $actions': {
				opacity: 1,
				transition: 'opacity 0.15s linear',
				visibility: 'visible',
			},
		},
		disabled: {
			cursor: 'default !important',
			'&:hover': {
				background: 'transparent',
			},
			'& > div': {
				opacity: '0.4',
			},
			'& label': {
				zIndex: 9999,
			},
		},
		check: {
			alignItems: 'center',
			display: 'flex',
			width: '35px',
			'& > label': {
				width: '16px',
			},
		},
		icon: {
			alignItems: 'center',
			display: 'flex',
			marginRight: ifFeature('encore', '8px', '5px'),
			padding: '1px 0',
			width: '27px',
		},
		info: {
			flex: 1,
			minWidth: 0,
		},
		infoLine: {
			display: 'block',
			lineHeight: typography.fabricLineHeight('teenie'),
		},
		infoTitle: {
			color: palette.grey[800],
			fontSize: typography.fabricFontSize('medium'),
		},
		infoInfo: {
			fontSize: typography.fabricFontSize('teenie'),
			color: palette.grey[700],
		},
		actions: {
			alignItems: 'center',
			display: 'flex',
			marginLeft: '10px',
			opacity: 0,
			visibility: 'hidden',
			width: '38px',
		},
		tooltip: {
			zIndex: 15000,
		},
		tooltipPosition: {
			transform: (props: {offset: number}) => `translateY(${ props.offset }px)`,
		},
	});
});
