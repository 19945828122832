import { LayoutBox } from '@bamboohr/fabric';
import { ProjectTracking } from './project-tracking';

export function EmployeeFieldsProjectTracking(props) {
	return (
		<LayoutBox paddingTop={2.5}>
			<ProjectTracking {...props} />
		</LayoutBox>
	);
}
