export const FEATURE_TOGGLE_KEYS = {
	// Dev toggles

	// GH Feature toggles. Will need to use custom hook "useFeatureToggle" from "../../hooks/use-feature-toggles"
	BenefitsEmployeeEnrollment: 'benefitsEmployeeEnrollment',

	// Release Toggles Will need to use global function "isEnabled" from "FeatureToggle.util"
	BENEFITS_VOLUNTARY_LIFE_COVERAGE_SELECTOR: 'BENEFITS_VOLUNTARY_LIFE_COVERAGE_SELECTOR',
	BENEFITS_HOME_PAGE: 'BENEFITS_HOME_PAGE',
};
