import React, {
	Fragment,
} from 'react';

import {
	Select
} from '@fabric/select';

export default (props) => {
	const {
		benefitGroup,
		benefitGroupErrorMessage,
		benefitGroupItems,
		benefitGroupOnChange,
		hasGroups
	} = props;
	
	return hasGroups ? (
		<Fragment>
			<div className="benefitGroupFieldRow fab-FormRow">
				<div className="benefitGroupInfo fab-FormNote--warning">
					<div className="fab-FormRow">
						{ $.__('Because of this change in Pay Frequency some group members will need to be moved to a new Benefit Group with a matching deduction frequency.') }
					</div>
					<div className="fab-FormColumn benefitGroupFieldBox requiredWhenVisible">
						<label className="fab-Label fab-Label--required" htmlFor="benefitGroupId">
							{ $.__('Benefit Group') }
						</label>
						<Select
							items={ benefitGroupItems }
							name={ 'benefitGroupId' }
							onChange={ benefitGroupOnChange }
							placeholder={ $.__('No Benefit Groups') }
							selectedValues={ benefitGroup }
							width={ 6 }
						/>
					</div>
				</div>
			</div>
			{ benefitGroupErrorMessage ? (
				<div className="benefitGroupPermErrorFieldRow fab-FormRow">
					<div className="fab-FormColumn">
						<div className="benefitGroupErrorInfo">
							<ul className="errorList">
								<li className="benefitGroupPermErrorMessage">
									{ benefitGroupErrorMessage }
								</li>
							</ul>
						</div>
					</div>
				</div>
			) : null }
		</Fragment>
	) : null;
}
