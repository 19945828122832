import { Component } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { isNull, isEmpty } from 'lodash';

import { LayoutBox, Loader, Section } from '@bamboohr/fabric';

import { canUseLocalStorage } from '@utils/localstorage';

import { CompanyInfoForm } from '../../../components/company-info-form';
import { AddEditHeader } from './components/add-edit-header';
import { PageLoader } from '../../../components/page-loader/page-loader';

import {
	getStatesList,
	postSaveCompanyInformation,
	getCompanyInformationMulti,
	nullCompanyObject,
	putSaveCompanyInformation,
	isValidPhoneNumber,
	showBadPhoneMsg,
	MULTI_EIN_TABLE_URL,
	COMPANY_SUBMISSION_MESSAGE_KEY,
	COMPANY_SUBMISSION_CREATE_MESSAGE,
	COMPANY_SUBMISSION_UPDATE_MESSAGE,
	COMPANY_SUBMISSION_MESSAGE,
	BAD_EIN,
	BAD_ZIP,
	MISSING_INFO,
	COMPANY_FORM_FIELDS,
} from '../../../utils/shared';

/* @startCleanup encore */
import './company-form.styl';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';

export class CompanyFormStateComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			clientId: null,
			recordId: null,

			isLoading: true,
			isProcessing: false,
			showAwayModal: false,

			errorBag: {
				companyLegalName: false,
				ein: false,
				dbaName: false,
				street1: false,
				city: false,
				state: false,
				zip: false,
				contactName: false,
				workEmail: false,
				workPhone: false,
				phoneNumber: false,
				businessType: false,
			},
			revokePreviousPayrollClientPermission: undefined,
		};
	}

	isFieldInvalid = (id) => {
		const { company } = this.state;

		return isNull(company[id]) || isEmpty(company[id]);
	};

	isFormValid = () => {
		const fieldList = [
			COMPANY_FORM_FIELDS.LEGAL_NAME,
			COMPANY_FORM_FIELDS.EIN,
			COMPANY_FORM_FIELDS.DBA_NAME,
			COMPANY_FORM_FIELDS.STREET_1,
			COMPANY_FORM_FIELDS.CITY,
			COMPANY_FORM_FIELDS.STATE,
			COMPANY_FORM_FIELDS.ZIP,
			COMPANY_FORM_FIELDS.CONTACT_NAME,
			COMPANY_FORM_FIELDS.WORK_EMAIL,
			COMPANY_FORM_FIELDS.WORK_PHONE,
			COMPANY_FORM_FIELDS.PHONE_NUMBER,
			COMPANY_FORM_FIELDS.BUSINESS_TYPE,
		];

		const { errorBag } = this.state;

		let isFormValid = true;
		let newErrorBag = Object.assign({}, errorBag);

		fieldList.forEach((field) => {
			if (this.isFieldInvalid(field)) {
				newErrorBag[field] = true;
				isFormValid = false;
			} else {
				newErrorBag[field] = false;
			}
		});

		this.setState({ errorBag: newErrorBag });

		return isFormValid;
	};

	refreshData = () => {
		const { clientId, contacts, recordId } = this.props;

		if (recordId) {
			// Adding a new company
			this.setState({
				contacts,
				recordId,
				isLoading: false,
				isProcessing: false,
				company: nullCompanyObject(),
				revokePreviousPayrollClientPermission: undefined,
			});
		} else {
			// Editing an existing
			getCompanyInformationMulti(clientId)
				.then((res) => {
					const { data } = res;

					this.setState({
						contacts,
						clientId,
						isLoading: false,
						isProcessing: false,
						company: data.companies[0],
						revokePreviousPayrollClientPermission: undefined,
					});
				})
				.catch(() => {
					window.setMessage($.__('Uh oh...something went wrong retrieving your company information. Please try again.'), 'error');
				});
		}
	};
	
	handleRevokePreviousContactPermission = (revokeAccess) => {
		this.setState({revokePreviousPayrollClientPermission: revokeAccess});
	}

	_handleFormSubmit = () => {
		const { recordId } = this.props;

		this._toggleFooterProcessing(true);

		if (recordId) {
			this._handlePostSaveCompany();
		} else {
			this._handleOnEditSubmit();
		}
	};

	_handleFormCancel = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (window.hasFormChanges) {
			this.setState({
				showAwayModal: true,
			});
		} else {
			window.location = MULTI_EIN_TABLE_URL;
		}
	};

	_handleMoveAwayModalClose = () => {
		this.setState({
			showAwayModal: false,
		});
	};

	_handleDontSaveMoveAway = () => {
		window.location = MULTI_EIN_TABLE_URL;
	};

	_handleInputChange = (propertyName, newPropertyValue) => {
		this.setState((prevState) => {
			prevState.company[propertyName] = newPropertyValue;

			return prevState;
		});
	};

	_handleOnEditSubmit = () => {
		const { company, revokePreviousPayrollClientPermission } = this.state;

		if (!this.isFormValid()) {
			window.setMessage(MISSING_INFO, 'error');
			this._toggleFooterProcessing(false);

			return;
		}

		if (!isValidPhoneNumber(company.workPhone) || !isValidPhoneNumber(company.phoneNumber)) {
			showBadPhoneMsg();
			this._toggleFooterProcessing(false);
			return;
		}

		this._toggleFooterProcessing(true);

		this.setState(
			{
				isProcessing: true,
			},
			() => {
				putSaveCompanyInformation({...company, revokePreviousPayrollClientPermission})
					.then((res) => {
						this._setSessionMessage(COMPANY_SUBMISSION_UPDATE_MESSAGE);
						window.location = MULTI_EIN_TABLE_URL;
					})
					.catch((error) => {
						this.setState({
							isProcessing: false,
							isLoading: false,
						});

						let message = $.__('Uh oh...something went wrong saving your company information. Please try again.');

						if (error.response.headers['x-bamboohr-error-message'] == 'ein is not valid.') {
							message = BAD_EIN;
						}

						if (error.response.headers['x-bamboohr-error-message'] == 'Store Payroll Client Validation Error. : zip is not valid.') {
							message = BAD_ZIP;
						}

						window.setMessage(message, 'error');
						this._toggleFooterProcessing(false);
					});
			}
		);
	};

	_handlePostSaveCompany = () => {
		const { company, recordId, revokePreviousPayrollClientPermission } = this.state;

		if (!this.isFormValid()) {
			window.setMessage(MISSING_INFO, 'error');
			this._toggleFooterProcessing(false);

			return;
		}

		if (!isValidPhoneNumber(company.workPhone) || !isValidPhoneNumber(company.phoneNumber)) {
			showBadPhoneMsg();
			this._toggleFooterProcessing(false);
			return;
		}

		this._toggleFooterProcessing(true);

		this.setState(
			{
				isProcessing: true,
			},
			() => {
				postSaveCompanyInformation({...company, revokePreviousPayrollClientPermission}, recordId)
					.then((res) => {
						this._setSessionMessage(COMPANY_SUBMISSION_CREATE_MESSAGE);
						window.location = MULTI_EIN_TABLE_URL;
					})
					.catch((error) => {
						this.setState({
							isProcessing: false,
							isLoading: false,
						});

						let message = $.__('Uh oh...something went wrong saving your company information. Please try again.');

						if (error.response.headers['x-bamboohr-error-message'] == 'ein is not valid.') {
							message = BAD_EIN;
						}

						this._toggleFooterProcessing(false);
						window.setMessage(message, 'error');
					});
			}
		);
	};

	_toggleFooterEnabled = (isEnabled) => {
		if (isEnabled) {
			document.dispatchEvent(new CustomEvent('SiteFooterActions:enable'));
		} else {
			document.dispatchEvent(new CustomEvent('SiteFooterActions:disable'));
		}
	};

	_toggleFooterProcessing = (isProcessing) => {
		if (isProcessing) {
			document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));
		} else {
			document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
		}
	};

	_setSessionMessage = (message) => {
		if (canUseLocalStorage) {
			localStorage.setItem(COMPANY_SUBMISSION_MESSAGE_KEY, 'true');
			localStorage.setItem(COMPANY_SUBMISSION_MESSAGE, message);
		}
	};

	renderLoading = () => {
		return ifFeature(
			'encore',
			<PageLoader />,
			<div className='CompanyInformation__loader'>
				<Loader />
			</div>
		);
	};

	componentWillMount() {
		getStatesList()
			.then((res) => {
				this.setState({
					stateList: res.data.data,
				});
			})
			.catch(() => {
				window.setMessage($.__('Uh oh...something went wrong. Please try again later.'), 'error');
			});

		this.refreshData();
	}

	render() {
		const { company, contacts, stateList, showAwayModal, isProcessing, isLoading, recordId, errorBag, revokePreviousPayrollClientPermission } = this.state;

		if (isLoading) {
			return this.renderLoading();
		}

		if (!company || !contacts) {
			return null;
		}

		return ifFeature(
			'encore',
			<>
				{/* Shouldn't have a recordId here if isEdit (it comes from query params) 
					I know it's weird, but deal with it */}
				<AddEditHeader isAdd={!!recordId} />
				<LayoutBox marginTop={2} marginBottom={5}>
					<Section>
						<CompanyInfoForm
							contactList={contacts}
							customErrorBag={errorBag}
							data={company}
							isProcessing={isProcessing}
							onDontSaveMoveAway={this._handleDontSaveMoveAway}
							onInputChange={this._handleInputChange}
							onMoveAwayModalClose={this._handleMoveAwayModalClose}
							onSubmit={recordId ? this._handlePostSaveCompany : this._handleOnEditSubmit}
							setFooterEnabled={this._toggleFooterEnabled}
							showAwayModal={showAwayModal}
							showCompletedFormsSection={true}
							stateList={stateList}
							useCustomErrorBag={true}
							refreshData={this.refreshData}
							revokePreviousContactPermission={revokePreviousPayrollClientPermission}
							onRevokePreviousContactPermission={this.handleRevokePreviousContactPermission}
						/>
					</Section>
				</LayoutBox>
			</>,
			<CSSTransition
				appear={true}
				classNames='CompanyInformation'
				in={!isLoading}
				key={company.clientId}
				timeout={1000}
				unmountOnExit={true}
			>
				<div className='CompanyForm'>
					{/* Shouldn't have a recordId here if isEdit (it comes from query params) 
						I know it's weird, but deal with it */}
					<AddEditHeader isAdd={!!recordId} />
					<CompanyInfoForm
						contactList={contacts}
						customErrorBag={errorBag}
						data={company}
						isProcessing={isProcessing}
						onDontSaveMoveAway={this._handleDontSaveMoveAway}
						onInputChange={this._handleInputChange}
						onMoveAwayModalClose={this._handleMoveAwayModalClose}
						onSubmit={recordId ? this._handlePostSaveCompany : this._handleOnEditSubmit}
						setFooterEnabled={this._toggleFooterEnabled}
						showAwayModal={showAwayModal}
						showCompletedFormsSection={true}
						stateList={stateList}
						useCustomErrorBag={true}
						refreshData={this.refreshData}
						revokePreviousContactPermission={revokePreviousPayrollClientPermission}
						onRevokePreviousContactPermission={this.handleRevokePreviousContactPermission}
					/>
				</div>
			</CSSTransition>
		);
	}
}
