import { BodyText, LayoutBox, Link } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { getFormattedDate } from '../../utils/benefit-plan-service';

/**
 * @startCleanup encore
 */
import './plan-name.styl';
/**
 * @endCleanup encore
 */

interface PlanNameProps {
	biId?: string;
	planId: number;
	planName: string;
	scheduledChanges: string;
}

export function PlanName(props: PlanNameProps): JSX.Element {
	const {
		biId,
		planId,
		planName,
		scheduledChanges,
	} = props;

	/**
	 * @startCleanup encore
	 */
	const jadePlanName = (
		<div className="Columns__planName">
			<a
				data-bi-id={ biId }
				href={ `/settings/benefits/plan_wizard/plan/${ planId }` }
			>
				{ planName }
			</a>
			{ (scheduledChanges) && (
				<div className="Columns__planNameChanges">
					<div>{ $.__('Changes scheduled:') }</div>
					<div>{ getFormattedDate(scheduledChanges) }</div>
				</div>
			) }
		</div>
	);
	/**
	 * @endCleanup encore
	 */

	return ifFeature(
		'encore',
		<>
			<Link
				data-bi-id={biId}
				href={ `/settings/benefits/plan_wizard/plan/${planId}`}
			>
				{planName}
			</Link>
			{scheduledChanges && (
				<BodyText color="neutral-weak" size="small">
					{$.__('Changes scheduled:')}
					<LayoutBox>
						{getFormattedDate(scheduledChanges)}
					</LayoutBox>
				</BodyText>
			)}
		</>,
		jadePlanName,
	);
}
