export { MFA_LEVELS } from 'multi-factor-authentication.mod';

export const HELP_GUIDES_URL = 'https://help.bamboohr.com/hc/en-us/articles/360052655731-2-Step-Login';

export enum MODAL_TYPES {
	EDIT_SETTINGS = 'editTwoStep',
	RESET_MFA = 'resetLogin',
	SETUP = 'setupTwoStep',
	DISABLE_MFA = '',
}
