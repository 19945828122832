import {
	federal1099PrintFullService,
	federal1099PrintSelfService,
} from '../constants';
import {
	L_FEDERAL_1099_PRINT_RADIO_OPTION_FULL_SERVICE,
	L_FEDERAL_1099_PRINT_RADIO_OPTION_SELF_SERVICE,
} from '../language';

export const federal1099PrintRadioOptions = [
	{
		id: federal1099PrintSelfService,
		label: L_FEDERAL_1099_PRINT_RADIO_OPTION_SELF_SERVICE,
		value: federal1099PrintSelfService,
	},
	{
		id: federal1099PrintFullService,
		label: L_FEDERAL_1099_PRINT_RADIO_OPTION_FULL_SERVICE,
		value: federal1099PrintFullService,
	},
];
