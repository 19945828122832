import { CoverageLevelValue } from '../types/wizard-values';

export const getSelectedCoverageLevelIds = (coverageLevels: CoverageLevelValue[]): number[] => {
	return Object.values(coverageLevels).reduce((acc: number[], coverageLevel: CoverageLevelValue) => {
		if (coverageLevel.linkId && coverageLevel.isSelected) {
			acc.push(coverageLevel.id);
		}
		return acc;
	}, []);
};
