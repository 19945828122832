import { isWeekend, parseISO } from '@bamboohr/utils/lib/datetime';

export function isParsedISODateValid(parsedDate: Date): boolean {
	// The BambooHR parseISO wrapper always returns a date even if a date cannot be parsed.
	// See https://bamboohr.slite.com/app/docs/EG4m55zGaK-K54
	// The date returned when a date is unable to be parsed is equal to new Date(0, 0).
	// Not ideal, but attempting to check parse validity by comparing to new Date(0, 0).
	// Note: this could produce a false negative if the date happens to actually be January 1, 1900.
	return parsedDate.getTime() !== new Date(0, 0).getTime();
}

export function getIsDateDisabled(date: undefined | string, holidayDisabledDates: Array<string>, payPeriodDisabledDates: Array<string>): boolean {
	if (!date) {
		return false;
	}

	const parsedDate = parseISO(date) as Date;
	if (!isParsedISODateValid(parsedDate)) {
		return false;
	}
	
	if (isWeekend(parsedDate)) {
		return true;
	}
	
	if (holidayDisabledDates.includes(date)) {
		return true;
	}
	
	if (payPeriodDisabledDates.includes(date)) {
		return true;
	}

	return false;
}
