import {
	EmployeeTaxMetadataStateTaxWitholding,
	EmployeeTaxMetadataStateTaxWithholdingKey,
	EmployeeTaxMetadataStateTaxWithholdingKeyStandard,
	MessageObject,
} from '../../utils/interfaces';

export const STANDARD_KEY_TO_MESSAGE_TEXT_MAP: {
	[key in EmployeeTaxMetadataStateTaxWithholdingKeyStandard]: string;
} = {
	state_exemptions: $._('Exemptions'),
	state_filing_status_code: $._('Filing Status'),
	state_is_exempt: $._('Exempt'),
	state_withholding_additional: $._('Additional Withholding'),
	state_withholding_type: $._('Location'),
	state_work_in: $._('State'),
};

export const TITLE_KEYS: Array<EmployeeTaxMetadataStateTaxWithholdingKeyStandard> = ['state_withholding_type', 'state_work_in'];

export function getDetails(metadata: EmployeeTaxMetadataStateTaxWitholding): Array<MessageObject> {
	const details = [];

	Object.keys(metadata).forEach((key: EmployeeTaxMetadataStateTaxWithholdingKey) => {
		if (key === 'employee_tax_state_options') {
			const options = metadata[key];
			options.forEach((option) => {
				details.push({
					params: [option.displayValue],
					text: `${ option.taxStateOption }: {1}`,
				});
			});
			return;
		}

		if (TITLE_KEYS.includes(key)) {
			return;
		}

		const text = STANDARD_KEY_TO_MESSAGE_TEXT_MAP[key];
		if (text) {
			details.push({
				params: [getDisplayValue(metadata[key])],
				text: `${ text }: {1}`,
			});
		}
	});

	return details;
}

export function getDisplayValue(value: string | number): string | number {
	if (value === undefined || value === null) {
		return '--';
	}

	return value;
}
