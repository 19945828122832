import React, {
	Fragment
} from 'react';

import {
	Select
} from '@fabric/select';

export default (props) => {
	const {
		hasSyncIntegrations,
		hasSyncTool,
		isSyncToolDisabled,
		payrollSystem,
		payrollSystemsItems,
		showSyncDropdown,
		syncIntegrationOnChange,
		syncIntegrationOnToggle
	} = props;
	
	return hasSyncIntegrations ? (
		<Fragment>
			<div className="fab-FormRow">
				<div className="fab-FormColumn">
					<div className="fab-Checkbox">
						<input
							defaultChecked={ hasSyncTool }
							className="fab-Checkbox__input"
							disabled={ isSyncToolDisabled }
							id="sync"
							name="payrollSync"
							onChange={ syncIntegrationOnToggle }
							type="checkbox" 
						/>
						<label className="fab-Checkbox__label" htmlFor="sync">
							{ $.__('Sync to a Payroll System') }
						</label>
					</div>
				</div>
			</div>
			{ showSyncDropdown ? (
				<div className="fab-FormRow js-PayGroupModal__sync">
					<div className="fab-FormColumn required">
						<label className="fab-Label fab-Label--required" htmlFor="payrollSyncId">
							{ $.__('Payroll System') }
						</label>
						<Select
							items={ payrollSystemsItems }
							isDisabled={ isSyncToolDisabled }
							name={ 'payrollSyncId' }
							onChange={ syncIntegrationOnChange }
							selectedValues={ payrollSystem }
							width={ 6 }
						/>
					</div>
				</div>
			) : null }
		</Fragment>
	) : null;
}
