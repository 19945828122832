import {
	Fragment,
} from 'react';

import {
	StepIndicator,
} from 'step-indicator.react';

import {
	useReducerContext,
} from '../state';


/** @type {React.FunctionComponent} */
export default function Title() {
	const {
		isEnabled,
		currentStep,
		stepCount,
	} = useReducerContext();

	return (
		<Fragment>
			{ $.__('ACA Tracking') }
			{ !isEnabled && (
				<StepIndicator
					currentStep={ currentStep }
					totalStepCount={ stepCount }
				/>
			) }
		</Fragment>
	);
}
