import { DateTime } from 'luxon';

export const getDatesAndStrings = (emailSurveySendDate, emailSurveyEndDate) => {
	const utcNow = DateTime.utc().set({milliseconds: 0});
	const formattedStartDate = DateTime.fromISO(emailSurveySendDate).set({milliseconds: 0});
	const formattedStartDateString = formattedStartDate.toLocaleString()
	const formattedEndDate = DateTime.fromISO(emailSurveyEndDate).set({milliseconds: 0});
	const formattedEndDateString = formattedEndDate.toLocaleString();
	const daysAgo = Math.floor(utcNow.diff(formattedStartDate, 'days').toObject().days);
	const daysToEnd = Math.ceil(Math.abs(utcNow.diff(formattedEndDate, 'days').toObject().days));
	return {
		formattedStartDateString,
		formattedEndDate,
		formattedEndDateString,
		daysAgo,
		daysToEnd,
	}
}