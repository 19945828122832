import { render } from 'base/wrapped-render';
import { unmountComponentAtNode } from 'react-dom';
import { DeleteWebhookModal } from './delete-webhook';

$('.js-delete-webhook').on('click', function() {
	const reactRootElement = document.getElementById('js-DeleteWebhookModal');
	const handleClose = () => {
		unmountComponentAtNode(reactRootElement);
	};
	render(
		<DeleteWebhookModal id={$(this).attr('wh-id')} isOpen={true} onClose={handleClose} type={$(this).attr('wh-type')}/>,
		reactRootElement
	)
})