import { Fragment, useState, useEffect } from 'react';

import { canUseLocalStorage } from '@utils/localstorage';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { MultiBanksTable } from '../../../components/multi-banks-table';
import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';
import { DownloadFormsModal } from '../../../../company-information.react/components/download-forms-modal';
import { UploadFormsModal } from '../../../../company-information.react/components/upload-forms-modal';

import {
	BANK_APP_STATE,
	getAppStateFromData,
	reloadCurrentPage,
	BANK_SUBMISSION_MESSAGE_KEY,
	BANK_SUBMISSION_MESSAGE,
} from '../../../utils/shared';
import { uuidGenerator } from '../../../../company-information.react/utils/shared';
import { AccountVerificationModal } from '../../../components/account-verification-modal/account-verification-modal';

export function MultiBanksStateComponent(props) {
	const { company, onVerificationModalSubmit, onUploadForms, onFileDownload } = props;

	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
	const [isVerificationsModalOpen, setIsVerificationsModalOpen] = useState(false);
	const [selectedBank, setSelectedBank] = useState({});

	useEffect(() => {
		if (canUseLocalStorage) {
			const showMessage = localStorage.getItem(BANK_SUBMISSION_MESSAGE_KEY);
			const message = localStorage.getItem(BANK_SUBMISSION_MESSAGE);
			if (showMessage && message) {
				window.requestAnimationFrame(() => {
					window.requestAnimationFrame(() => {
						window.setMessage(message, 'success');
						localStorage.removeItem(BANK_SUBMISSION_MESSAGE_KEY);
						localStorage.removeItem(BANK_SUBMISSION_MESSAGE);
					});
				});
			}
		}
	}, []);

	const popDownloadFormsModal = (data) => {
		setSelectedBank(data);
		setIsDownloadModalOpen(true);
	};

	const popUploadFormsModal = (data) => {
		setSelectedBank(data);
		setIsUploadModalOpen(true);
	};

	const popVerificationsModal = (data) => {
		setSelectedBank(data);
		setIsVerificationsModalOpen(true);
	};

	const closeDownloadModal = () => {
		setIsDownloadModalOpen(false);
	};

	const closeUploadModal = () => {
		setIsUploadModalOpen(false);
	};

	const closeVerificationsModal = () => {
		setIsVerificationsModalOpen(false);
	};

	const handleVerificationsModalSubmit = () => {
		const { onVerificationModalSubmit } = props;

		setIsVerificationsModalOpen(false);
		onVerificationModalSubmit();
	};

	const submitDownloadModal = () => {
		reloadCurrentPage();
	};

	const bankStatuses = company.bankAccounts.map((bank) => {
		// sick.. ugly.. gross..
		const company = [{ bankAccounts: [] }];
		company[0].bankAccounts.push(bank);

		return {
			company: bank,
			state: getAppStateFromData(company),
		};
	});

	const filteredStatuses = bankStatuses.filter((status) => {
		return status.state !== BANK_APP_STATE.VERIFIED;
	});

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager
				buttonFunction={false}
				clientId={company.clientId}
				companies={[company]}
				isMultiEin={true}
				statuses={filteredStatuses}
			/>

			<MultiBanksTable
				company={company}
				handleOpenDownloadModal={popDownloadFormsModal}
				handleOpenUploadModal={popUploadFormsModal}
				handleOpenVerificationModal={popVerificationsModal}
			/>

			<DownloadFormsModal
				company={selectedBank ? selectedBank : {}}
				onClose={closeDownloadModal}
				onFileDownload={onFileDownload}
				onSubmit={submitDownloadModal}
				type={'bank'}
				visible={isDownloadModalOpen}
			/>

			<UploadFormsModal
				bank={selectedBank}
				clientId={company.clientId}
				company={selectedBank ? selectedBank : {}}
				key={uuidGenerator()}
				onClose={closeUploadModal}
				onSubmit={onUploadForms}
				showDownloads={true}
				type={'bank'}
				visible={isUploadModalOpen}
			/>

			<AccountVerificationModal
				accountNum={selectedBank.accountNumber}
				bankId={selectedBank.bankId}
				bankName={selectedBank.accountNickname}
				clientId={company.clientId}
				isOpen={isVerificationsModalOpen}
				key={uuidGenerator()}
				onClose={closeVerificationsModal}
				onSubmit={handleVerificationsModalSubmit}
			/>
		</Fragment>
	);
}
