import {
	RadioGroup,
} from '@bamboohr/fabric';

import {
	useStyles,
} from './radio-options.styles';
import {
	federalW2Box12DdHide,
} from '../constants';
import {
	L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_LABEL,
} from '../language';
import {
	handleW2Box12DdEnforceChoice,
} from '../domain/handle-w2-box-12dd-enforce-choice';
import {
	handleW2Box12DdEnforceOptions,
} from '../domain/handle-w2-box-12dd-enforce-options';

interface FederalW2Box12DdProps {
	employeeCount: number;
	setSuppressCoverage(showCoverage: boolean): void;
	suppressCoverage: boolean;
}

export function FederalW2Box12Dd(props: FederalW2Box12DdProps) {
	const {
		employeeCount,
		setSuppressCoverage,
		suppressCoverage,
	} = props;
	const classes = useStyles();
	const itemOptions = handleW2Box12DdEnforceOptions(employeeCount);
	const checkedValue = handleW2Box12DdEnforceChoice(employeeCount, suppressCoverage);
	return (
		<div className={ classes.radioOptionsContainer }>
			<RadioGroup
				items={ itemOptions }
				label={ L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_LABEL }
				onChange={ (param) => {
					const {
						value,
					} = param;
					setSuppressCoverage(value === federalW2Box12DdHide);
				} }
				status="default"
				value={ checkedValue }
			/>
		</div>
	);
}
