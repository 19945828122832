import {
	ajax,
} from '@utils/ajax';

import {
	getPayrollServicesHost,
} from './auth/get-payroll-services-host';
import {
	injectJwtInterceptor,
} from './auth/inject-jwt-interceptor';

const PAYROLLSERVICES_HOST = getPayrollServicesHost();

export const companyTaxSettingsService = ajax.create('company-tax-settings-service', {
	baseURL: `https://${ PAYROLLSERVICES_HOST }/v2/clients/:clientId`,
});
injectJwtInterceptor(companyTaxSettingsService.instance);
