import { render } from 'base/wrapped-render';

import Ctrl, { onPjaxEnd, onReady } from 'ctrl.deco';

import { getSettingsJobTitleEEOCategoriesData } from './job-title-eeo-categories.service';
import { JobTitleEEOCategories } from './job-title-eeo-categories';

function bootstrapReact() {
	const jobTitleRoot = document.getElementById('jobTitleEEOCategoriesReactRoot');
	if (jobTitleRoot) {
		getSettingsJobTitleEEOCategoriesData()
			.then(data => render(<JobTitleEEOCategories { ...data } />, jobTitleRoot));
	}
}

@Ctrl('/settings/list.php')
class SettingsEmployeeFieldsJobTitleEEOCategories {
	@onPjaxEnd()
	@onReady()
	_handleReady() {
		bootstrapReact();
	}
}
