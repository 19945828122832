import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type StyleProps = {
	isSelected: boolean;
	isSmallScreen: boolean;
	disabled: boolean;
};

export const useStyles = makeStyles(({ palette, typography, constructs }) => ({
	container: {
		textAlign: 'center',
		cursor: 'pointer',
		background: 'transparent',
		border: 'none',
		display: 'flex',
		alignItems: 'center',
	},
	containerColumn: {
		maxWidth: '80px',
		flexDirection: 'column',
	},
	containerRow: {
		flexDirection: 'row',
		padding: '12px',

		/* @startCleanup encore */
		borderBottom: ifFeature('encore', undefined, `1px solid ${palette.gray[200]}`),
		'&:first-child': {
			borderTop: ifFeature('encore', undefined, `1px solid ${palette.gray[200]}`),
		},
		/* @endCleanup encore */
	},
	checkContainer: {
		display: 'flex',
		position: 'relative',
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '36px',
		padding: '8px',
		background: ifFeature('encore', undefined, 'white'),
		fill: ifFeature(
			'encore',
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? constructs.surface.neutral.inverted : constructs.surface.neutral.weak;
				}
				return isSelected ? constructs.surface.neutral.inverted : constructs.surface.neutral.weak;
			},
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? palette.common.white : palette.gray[400];
				}
				return isSelected ? palette.primary.main : palette.gray[400];
			}
		),
		border: ifFeature(
			'encore',
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? `2px solid ${constructs.surface.neutral.strong}` : `2px solid ${constructs.surface.neutral.weak}`;
				}
				return isSelected ? `2px solid ${constructs.surface.primary.strong}` : `2px solid ${constructs.surface.neutral.weak}`;
			},
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? `2px solid ${palette.gray[600]}` : `2px solid ${palette.gray[400]}`;
				}
				return isSelected ? `2px solid ${palette.primary.main}` : `2px solid ${palette.gray[400]}`;
			}
		),
		margin: (props: StyleProps) => (props.isSmallScreen ? '0 12px 0 0' : '0 0 15px'),
		borderRadius: '50%',
		backgroundColor: ifFeature(
			'encore',
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? constructs.surface.neutral.strong : constructs.surface.neutral.inverted;
				}
				return isSelected ? constructs.surface.primary.strong : constructs.surface.neutral.inverted;
			},
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? palette.gray[600] : palette.common.white;
				}
				return ifFeature(
					'encore',
					isSelected ? constructs.surface.primary.strong : constructs.surface.neutral.inverted,
					palette.common.white
				);
			}
		),
	},
	smallCheckContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '32px',
		padding: '7px',
		background: ifFeature(
			'encore',
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? constructs.surface.neutral.strong : constructs.surface.neutral.inverted;
				}
				return isSelected ? constructs.surface.primary.strong : constructs.surface.neutral.inverted;
			},
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? palette.gray[600] : palette.common.white;
				}
				return isSelected ? palette.primary.main : palette.common.white;
			}
		),
		fill: ifFeature(
			'encore',
			(props: StyleProps) => (props.isSelected ? constructs.icon.neutral.inverted : constructs.icon.neutral.xWeak),
			(props: StyleProps) => (props.isSelected ? palette.common.white : palette.gray[400])
		),
		border: ifFeature(
			'encore',
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? `2px solid ${constructs.surface.neutral.strong}` : `2px solid ${constructs.surface.neutral.weak}`;
				}
				return isSelected ? `2px solid ${constructs.surface.primary.strong}` : `2px solid ${constructs.surface.neutral.weak}`;
			},
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? `2px solid ${palette.gray[600]}` : `2px solid ${palette.gray[400]}`;
				}
				return isSelected ? `2px solid ${palette.primary.main}` : `2px solid ${palette.gray[400]}`;
			}
		),
		margin: (props: StyleProps) => (props.isSmallScreen ? '0 12px 0 0' : '0 0 15px'),
		borderRadius: '50%',
	},
	label: {
		color: ifFeature(
			'encore',
			(props: StyleProps) => (props.isSelected ? constructs.text.neutral.xStrong : constructs.text.neutral.strong),
			(props: StyleProps) => (props.isSelected ? palette.gray[1000] : palette.gray[800])
		),
		fontFamily: typography.fontFamily,
	},
	smallScreenLabel: {
		color: ifFeature(
			'encore',
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? constructs.text.neutral.xStrong : constructs.text.neutral.strong;
				}
				return isSelected ? constructs.text.primary.strong : constructs.text.neutral.strong;
			},
			({ isSelected, disabled }: StyleProps) => {
				if (disabled) {
					return isSelected ? palette.gray[1000] : palette.gray[800];
				}
				return isSelected ? palette.primary.main : palette.gray[800];
			}
		),
		fontFamily: typography.fontFamily,
		fontSize: '16px',
		lineHeight: '19px',
		textAlign: 'left',
		fontWeight: (props: StyleProps) => (props.isSelected ? 600 : 'normal'),
	},
}));
