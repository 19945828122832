import './score-heading.styl';

export function ScoreHeading(props) {
	const { score } = props;
	return (
		<div className="EnpsScoreHeading">
			<div className="EnpsScoreHeading__score baseColor baseBorderColor">{ score }</div>
		</div>
	);
}
