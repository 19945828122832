import React from 'react';
import moment from 'moment';
import { Message } from '@bamboohr/utils/lib/message';

export default ({ demoRequestedBy, demoRequestedDate }, showDate = true) => {
	const { firstName, lastName } = demoRequestedBy;

	const requestedBy = `${firstName} ${lastName}`;
	const requestedDate = moment(demoRequestedDate).utc();

	const hasName = firstName?.length > 0 && lastName?.length > 0;
	const hasDate = requestedDate.isValid();

	if (hasName && hasDate && showDate) {
		return <Message params={[requestedBy, requestedDate.format('MMM D, YYYY')]} text={$._('{1} requested a demo on {2}')} />;
	} else if (hasName) {
		return <Message params={[requestedBy]} text={$._('{1} requested a demo')} />;
	}

	return $.__('Demo Requested');
};
