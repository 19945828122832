import { Button, LayoutBox, Typography } from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import { redirect } from 'BambooHR.util';
import React, { ReactElement, useState } from 'react';
import { OnDemoRequest } from '../';
import UpsellModal from '../../../app-components/upsell/components/UpsellModal';
import { benefitsAdmin } from '../../../app-configs/upsell';
import api from '../../../app-services/api';
import { serializeDemoRequest } from '../serializers';

interface SubscriberUpsellProps {
	isOpen: boolean;
	onClose: () => void;
	onDemoRequest: OnDemoRequest;
}

export const SubscriberUpsell = ({ isOpen, onClose, onDemoRequest }: SubscriberUpsellProps): ReactElement => {
	const [isProcessing, setIsProcessing] = useState(false);

	const { disclaimer, headline, summary, summaryItems, title, videoId } = benefitsAdmin;

	const handleScheduleDemoClick = () => {
		setIsProcessing(true);
		api.expansion.benefitsAdminDemoRequest
			.post()
			.then(({ data }) => {
				// TODO: reimplement when Upsells are moved to Po
				// onDemoRequest(serializeDemoRequest(data));
				// onClose();
				// showSlidedown($.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."), 'success');

				//* Temporary solution until Upsells are moved to Po
				redirect(
					window.location.href,
					$.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."),
					'success'
				);
			})
			.catch(() => {
				showSlidedown($.__('Oops... something went wrong. Please try again.'), 'error');
			});
		setIsProcessing(false);
	};

	return (
		<UpsellModal isOpen={isOpen} onRequestClose={onClose} title={title} wistiaVideoId={videoId}>
			<h4>{headline}</h4>
			<p>{summary}</p>
			<ul>
				{summaryItems.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={`ben-admin-item-${index}`}>{item}</li>
				))}
			</ul>
			<Typography color={(theme) => theme.palette.gray[800]} variant='small'>
				{disclaimer}
			</Typography>
			<LayoutBox padding='14px 0 18px'>
				<Button
					data-bi-id='ben-admin-demo-modal-request-demo-button'
					isProcessing={isProcessing}
					onClick={handleScheduleDemoClick}
					size='large'
					text={$.__('Schedule a Personalized Demo')}
					type='button'
				/>
			</LayoutBox>
		</UpsellModal>
	);
};
