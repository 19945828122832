import { Checkbox, TableColumn, Tooltip } from '@bamboohr/fabric';

import {
	MigrationSectionTimeOffTypesSummaryTableRow,
} from '../../utils/interfaces';

export const MAX_PAYROLL_TIME_OFF_TYPES_COUNT = 7; 

export function getTimeOffTypesSummaryTableColumns(
	styles: {
		checkboxContainer: string;
	},
	selectedCount: number,
	handleCheckboxChange: (id: string, includeInPayroll: boolean) => void
): Array<TableColumn<MigrationSectionTimeOffTypesSummaryTableRow>> {
	return [
		{
			cell: row => row.name,
			header: $.__('BambooHR Record'),
			key: 'name',
		},
		{
			cell: (row) => {
				const selectedCountAtLimitAndNotChecked = selectedCount >= MAX_PAYROLL_TIME_OFF_TYPES_COUNT && !row.includeInPayroll;

				return (
					<div className={ styles.checkboxContainer }>
						<Tooltip
							content={ $.__('You can include up to %1$s time off types in your payroll.', MAX_PAYROLL_TIME_OFF_TYPES_COUNT) }
							open={ selectedCountAtLimitAndNotChecked ? undefined : false }
						>
							<div>
								<Checkbox
									checked={ row.includeInPayroll }
									disabled={ selectedCountAtLimitAndNotChecked }
									id={ `migration-tasks-time-off-types-summary-checkbox-id-${ row.id }` }
									label={ $.__('Yes, include this time off type in payroll.') }
									onChange={ ({ checked }) => handleCheckboxChange(row.id, checked) }
									value={ `migration-tasks-time-off-types-summary-checkbox-${ row.id }` }
								/>
							</div>
						</Tooltip>
					</div>
				);
			},
			header: $.__('Include In Payroll?'),
			key: 'checkbox',
		},
	];
}
