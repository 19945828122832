import { getInitialState } from './';

const INITIAL_STATE = getInitialState();

export const cancelEditWorkflow = (clonedState) => {
	clonedState.editState = false;
	clonedState.editingWorkflowId = false;
	clonedState.editingWorkflow = false;
	clonedState.newPathSelectedOptions = [];
	return clonedState;
};

export const createNewCancel = (clonedState) => {
	clonedState.editState = false;
	clonedState.newPathOpen = false;
	return clonedState;
};

export const createNewStart = (clonedState) => {
	clonedState.editState = true;
	clonedState.newPathOpen = true;
	return clonedState;
};

export const editWorkflow = (clonedState, payload) => {
	const {
		editingWorkflow,
		editingWorkflowId,
		newPathSelectedOptions = []
	} = payload;
	clonedState.editState = true;
	clonedState.editingWorkflowId = editingWorkflowId;
	clonedState.editingWorkflow = editingWorkflow;
	clonedState.newPathOpen = false;
	clonedState.newPathSelectedOptions = newPathSelectedOptions;
	return clonedState;
};

export const closeConfirmModal = (clonedState) => {
	clonedState.confirmationRequired = false;
	clonedState.confirmationType = undefined;
	clonedState.pathsChanging = undefined;
	return clonedState;
};

export const openConfirmModal = (clonedState, action) => {
	const {
		confirmationRequired,
		confirmationType,
		formData,
		pathsChanging,
		processing,
		workflowId
	} = action;

	clonedState.confirmationRequired = confirmationRequired;
	clonedState.confirmationType = confirmationType;
	clonedState.processing = processing;

	if (clonedState.editingWorkflow) {
		clonedState.editingWorkflow.formData = formData;
		clonedState.editingWorkflow.pathsChanging = pathsChanging;
	} else {
		clonedState.editingWorkflow = {
			formData,
			id: workflowId || clonedState.editingWorkflow.id,
			pathsChanging: pathsChanging
		};
	}

	return clonedState;
};

export const populateSubNavItems = (clonedState, payload) => {
	clonedState.customApprovalEnabled = payload.customApprovalEnabled;
	clonedState.subNavItems = payload.subNavItems;
	return clonedState;
};

export const populateWorkflowData = (clonedState, payload) => {
	const {
		allParsedOptions,
		approverOptions,
		availablePathOptions,
		availableUsers,
		customApprovers,
		editState,
		editingWorkflow,
		levels,
		newPathOpen,
		pathTypeChildren,
		pathTypeOptions,
		requestOptions,
		userGroups,
		workflow,
		workflowId
	} = payload;
	clonedState.allParsedOptions = allParsedOptions;
	clonedState.approverOptions = approverOptions;
	clonedState.availablePathOptions = availablePathOptions;
	clonedState.availableUsers = availableUsers;
	clonedState.customApprovers = customApprovers;
	clonedState.editState = editState;
	clonedState.editingWorkflow = editingWorkflow;
	clonedState.levels = levels;
	clonedState.newPathOpen = newPathOpen;
	clonedState.pathTypeChildren = pathTypeChildren;
	clonedState.pathTypeOptions = pathTypeOptions;
	clonedState.requestOptions = requestOptions;
	clonedState.userGroups = userGroups;
	clonedState.workflow = workflow;
	clonedState.workflowId = workflowId;
	return clonedState;
};

export const resetToInitialState = (clonedState, payload) => {
	return {
		...INITIAL_STATE,
		customApprovalEnabled: clonedState.customApprovalEnabled,
		subNavItems: clonedState.subNavItems,
		workflowId: payload.workflowId
	};
};

export const updateEditingApprovers = (clonedState, payload) => {
	const {
		customApprovers
	} = payload;

	clonedState.editingWorkflow.customApprovers = customApprovers;

	return clonedState;
};

export const updateEditingPathLevels = (clonedState, payload) => {
	const {
		levels
	} = payload;

	if (clonedState.editingWorkflow.levels) {
		clonedState.editingWorkflow.levels = levels;
	}

	return clonedState;
};

export const updateEditingPathOptions = (clonedState, payload) => {
	const {
		selectedPaths
	} = payload;

	if (clonedState.editingWorkflow.workflowPaths) {
		clonedState.editingWorkflow.workflowPaths = selectedPaths;
	}

	return clonedState;
};


export function updateProcessing(clonedState, payload) {
	const {
		processing
	} = payload;

	clonedState.processing = processing;

	return clonedState;
}
