import {
	StateOhioCourtesyTax,
} from './state-ohio-courtesy-tax';
import {
	findSuppressCourtesyTax,
} from '../domain/find-suppress-courtesy-tax';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function StateOhioCourtesyTaxConnector() {
	const [{
		clientTaxSettings,
	}, {
		setClientTaxSetting,
	}] = useCompanyTaxSettingsStore();
	const ohioCourtesyTax = findSuppressCourtesyTax(clientTaxSettings);
	if (!ohioCourtesyTax) {
		return null;
	}
	const suppressTax = ohioCourtesyTax.value;
	const setSuppressTax = setClientTaxSetting;
	return (
		<StateOhioCourtesyTax
			setSuppressTax={ (checked) => {
				setSuppressTax({
					...ohioCourtesyTax,
					value: checked,
				});
			} }
			suppressTax={ suppressTax }
		/>
	);
}
