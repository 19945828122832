export const createFullAddressString = (allStates, address) => {
    if (!allStates || !address) {
        return '';
    }

    const { addressLine1, addressLine2, city, state, stateId, zipcode } = address;
    const stateAbbreviation = state ? state : getStateFromId(allStates,stateId)?.name;

    if (addressLine2) {
        return `${addressLine1 ? addressLine1 : ''} ${addressLine2}, ${city}, ${stateAbbreviation || ''} ${zipcode}`;
    }

    return `${addressLine1}, ${city}, ${stateAbbreviation || ''} ${zipcode}`;
}

export const getStateFromId = (allStates, stateId) => {
    return allStates.find((state) => state.id === stateId);
}
