import { BenefitsSettingsAction } from '../benefits-settings-store';
import { FEATURE_TOGGLE_KEYS, getFeatureToggles, isFeatureEnabled } from '../../../../etc/feature-toggle';

export const getBenefitsSettingsDataAction =
    (): BenefitsSettingsAction =>
    async ({ getState, setState }) => {
        if (getState().isDataLoading === true) {
            return;
        }

        setState({
            isDataLoading: true,
        });

        try {
            const featureTogglesResponse = await getFeatureToggles();
            const isBenefitsAdminEnabled = isFeatureEnabled(
                FEATURE_TOGGLE_KEYS.BenefitsEmployeeEnrollment,
                featureTogglesResponse?.data?.enabledFeatureKeys || [],
            );
            setState({
                isBenefitsAdminEnabled,
                isDataLoading: false,
            });
        } catch (err) {
            setState({
                isDataLoading: false,
            });
            console.error(err);
        }
    };
