import React, { ReactElement } from 'react';

import { PoMicroFrontend } from 'micro-frontend.react';

export function BenefitsFiles(): ReactElement {

	const handleOpenEsignature = window.lazySigs;

	return (
		<PoMicroFrontend handleOpenEsignature={handleOpenEsignature} route='/settings/benefits/files' />
	);
}
