export const COMPANY_INFO_WHITELIST = [
	'nameOfEmployer',
	'ein',
	'employerAddress',
	'contactPhone',
	'city',
	'stateId',
	'zipCode',
	'countryId',
];

export const STEPS = {
	One: 1,
	Two: 2,
	Three: 3,
	Four: 4,
};
