import { createRef, useMemo, useEffect, useState } from 'react';

import { Tooltip, makeStyles } from '@bamboohr/fabric';

import { createFullAddressString, getStateFromId } from './helpers';

const useStyles = makeStyles(({ palette, typography }) => ({
    addressWrapper: {
        lineHeight: typography.teenie?.lineHeight,
        paddingTop: '5px',
        color: palette.gray[700],
        fontSize: typography.teenie?.fontSize,
    },
    address: {
        width: '512px', // This is a hard coded value to represent the width of the modal content
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

export const AddressFormNote = ({ allStates, selectedPayee = {} }) => {

    const ref = createRef();
    const classes = useStyles();

    const [isOverflowing, setIsOverflowing] = useState(false);

    const {
        addressLine1 = '',
        addressLine2 = '',
        city = '',
        state = '', // The state property that's given is the state abbreviation
        stateId = '', // The state id is the id that's given that can be found in the allStates array. Global
        zipcode = '',
    } = selectedPayee

    const addressText = useMemo(() => {
        const addressInformation = {
            addressLine1,
            addressLine2,
            city,
            state,
            stateId,
            zipcode,
        }
        return <div className={classes.address}>{createFullAddressString(allStates, addressInformation)}</div>
    }, [allStates, selectedPayee])

    useEffect(() => {
        const addressDiv = ref.current.firstChild;
        if (addressDiv){
            setIsOverflowing(addressDiv.clientWidth < addressDiv.scrollWidth);
        }
    }, [addressText, setIsOverflowing])

    if (!selectedPayee) {
        return null;
    }

    const stateAbbreviation = state ? state : getStateFromId(allStates, stateId)?.name;

    return (
        <div ref={ref} className={classes.addressWrapper}>
            { isOverflowing ? (
                <Tooltip
                    content={
                        <div>
                            { addressLine1 && <div>{addressLine1}</div>}
                            { addressLine2 && <div>{addressLine2}</div>}
                            <div>{`${city}, ${stateAbbreviation || ''}`}</div>
                            <div>{zipcode}</div>
                        </div>
                    }
                >
                    {addressText}
                </Tooltip>
            ) : (
                addressText
            )}
        </div>
    )
}
