import React from 'react';
import { StandardModal, Button, AdornedText, Divider } from '@bamboohr/fabric';
import { Code18x18 } from '@bamboohr/grim';
import { useStyles } from './styles';
import classNames from 'classnames';

export const WebhookDetailsModal = (props) => {
	const { isOpen, onClose, data } = props;
	const classes = useStyles();

	const format = (input) => {
		const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
		if (!input || !input.split('T')[0].match(pattern)) {
			return null;
		}
			return input.split('T')[0].replace(pattern, '$2/$3/$1');
	};

	const header = <StandardModal.Header hasCloseButton={true} title={$._('View Webhook Info')} />;

	const footer = <StandardModal.Footer actions={[<Button key="primary" onClick={onClose} type="button">{$._('Done')} </Button>]} />;

	return (
		<StandardModal isOpen={ isOpen } onRequestClose={ onClose }>
			<StandardModal.Body renderFooter={ footer } renderHeader={ header }>
				<StandardModal.Constraint spacingOverrides={{
					bottom: StandardModal.Constraint.Spacing.TEENIE, 
					top: StandardModal.Constraint.Spacing.SMALL,
				}}
				/>
				<div className={classes.modal}>
					<AdornedText adornment={<Code18x18 />} classes={{ root: classes.adornedLayout, adornment: classes.adornment }}>
						<div className={classes.title}>{data.name}</div>
					</AdornedText>
					<Divider classes={{ root: classes.divider }} />
					<div className={classes.modalContent}>
						<div className={classNames(classes.subsection, classes.user)}>
							<span className={classes.label}>{$.__('User')}</span>
							<span>{data.usersName}</span>
						</div>
						<div className={classNames(classes.subsection, classes.created)}>
							<span className={classes.label}>{$.__('Created')}</span>
							<span>{format(data.createdDate)}</span>
						</div>
						<div className={classNames(classes.subsection, classes.lastUsed)}>
							<span className={classes.label}>{$.__('Last Used')}</span>
							<span>{format(data.lastUsed)}</span>
						</div>
						<div className={classNames(classes.subsection, classes.url)}>
							<span className={classes.label}>{$.__('URL')}</span>
							<span>{data.url}</span>
						</div>
						<div className={classNames(classes.subsection, classes.app)}>
							<span className={classes.label}>{$.__('Application')}</span>
							<span>{data.applicationName}</span>
						</div>
					</div>
				</div>
			</StandardModal.Body>
		</StandardModal>
	)
}