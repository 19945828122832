import {
	noop,
} from 'lodash';
import {
	Icon,
} from '@bamboohr/fabric';
import { PageFooter } from 'page-footer.react';

import { MigrationSectionHead } from '../migration-section-head';
import { MigrationTable } from '../migration-table';
import { MigrationFooterButtons } from '../migration-footer-buttons';
import { MappingTable } from '../mapping-table';

import {
	getFilteredRecordIdsByClientId,
} from '../../utils/utilities';

import {
	MigrationSectionProps,
} from '../../utils/interfaces';

import './migration-section.styl';

import { isEnabled } from 'FeatureToggle.util';
const MX_MEIN_ENABLED = isEnabled('mxMein');

export function MigrationSection(props: MigrationSectionProps): JSX.Element {
	const {
		type,
		clients: {
			byId: clientsById,
			allIds: allClientIds,
		},
		groups,
		fields,
		isComparisonGrouped,
		isMappingGrouped,
		isMultipleEin,
		groupIcon,
		completedFields,
		totalFields,
		onFieldSelect,
		saveAndContinue,
		saveProgress,
		saveAndExit,
		onSelectAllClick,
		showMappingTool,
		traxNeedsBamboo,
		bambooNeedsTrax,
		sectionMetadata,
		selectedRecordIds,
		onRecordToMapSelect,
		onRecordMetadataChange,
	} = props;

	const sampleGroupId = groups?.allIds?.[0];
	const sampleGroup = groups?.byId?.[sampleGroupId];
	const sampleBambooLink = sampleGroup?.bamboo?.link;
	const sampleTraxLink = sampleGroup?.trax?.link;

	return (
		<div className="MigrationSection">
			<MigrationSectionHead
				bambooLink={ sampleBambooLink }
				completedFields={ completedFields }
				onSelectAllClick={ onSelectAllClick }
				showMappingTool={ showMappingTool }
				totalFields={ totalFields }
				traxLink={ sampleTraxLink }
				type={ type }
			/>

			{ MX_MEIN_ENABLED && (
				allClientIds.map((clientId: number, index: number) => {
					const {
						ein,
						clientName,
					} = clientsById[clientId];
					const filteredTraxNeedsBambooIds = getFilteredRecordIdsByClientId(traxNeedsBamboo, clientId);
					const filteredBambooNeedsTraxIds = getFilteredRecordIdsByClientId(bambooNeedsTrax, clientId);
					const filteredComparisonFieldsIds = getFilteredRecordIdsByClientId(fields, clientId);
					const showMappingTable = showMappingTool && (filteredTraxNeedsBambooIds.length || filteredBambooNeedsTraxIds.length);
					const showComparisonTable = !showMappingTool && filteredComparisonFieldsIds.length;
		
					if (showMappingTable) {
						return (
							<div className="MigrationSection__tableContainer" key={ clientId }>
								{ isMultipleEin && (
									<div className="MigrationSection__clientInfo">
										<Icon brand={ true } name="fab-company-14x16" />
										<h5 className="MigrationSection__clientName">{ clientName } - { ein }</h5>
									</div>
								) }
								<MappingTable
									bambooNeedsTrax={ { allIds: filteredBambooNeedsTraxIds, byId: bambooNeedsTrax.byId } }
									clientId={ clientId }
									groupIcon={ groupIcon }
									groups={ groups }
									hasError={ false }
									isGrouped={ isMappingGrouped }
									isMultipleEin={ isMultipleEin }
									mappingErrorFieldIds={ [] }
									onChange={ onRecordToMapSelect }
									onMetadataChange={ onRecordMetadataChange }
									sectionMetadata={ sectionMetadata }
									selectedRecordIds={ selectedRecordIds }
									traxNeedsBamboo={ { allIds: filteredTraxNeedsBambooIds, byId: traxNeedsBamboo.byId } }
									type={ type }
								/>
							</div>
						);
					}
					if (showComparisonTable) {
						return (
							<div className="MigrationSection__tableContainer" key={ clientId }>
								{ isMultipleEin && (
									<div className="MigrationSection__clientInfo">
										<Icon brand={ true } name="fab-company-14x16" />
										<h5 className="MigrationSection__clientName">{ clientName } - { ein }</h5>
									</div>
								) }
								<MigrationTable
									fields={ { allIds: filteredComparisonFieldsIds, byId: fields.byId } }
									fieldSelectChangeHandler={ onFieldSelect }
									groupIcon={ groupIcon }
									groups={ groups }
									isErrorTable={ false }
									isGrouped={ isComparisonGrouped }
									manualUpdateCheckHandler={ noop }
									sectionType={ type }
								/>
							</div>
						);
					}
					return null;
				})
			) }

			{ /* TODO: Delete this block of code when MEIN is ready for GA */ }
			{ !MX_MEIN_ENABLED && (
				<div className="MigrationSection__tableContainer">
					{ showMappingTool ? (
						<MappingTable
							bambooNeedsTrax={ bambooNeedsTrax }
							clientId={ allClientIds[0] }
							groupIcon={ groupIcon }
							groups={ groups }
							hasError={ false }
							isGrouped={ isMappingGrouped }
							isMultipleEin={ isMultipleEin }
							mappingErrorFieldIds={ [] }
							onChange={ onRecordToMapSelect }
							onMetadataChange={ onRecordMetadataChange }
							sectionMetadata={ sectionMetadata }
							selectedRecordIds={ selectedRecordIds }
							traxNeedsBamboo={ traxNeedsBamboo }
							type={ type }
						/>
					) : (
						<MigrationTable
							fields={ fields }
							fieldSelectChangeHandler={ onFieldSelect }
							groupIcon={ groupIcon }
							groups={ groups }
							isErrorTable={ false }
							isGrouped={ isComparisonGrouped }
							manualUpdateCheckHandler={ noop }
							sectionType={ type }
						/>
					) }
				</div>
			) }
			{ /* END BLOCK TO BE DELETED */ }

			<div className="MigrationSection__whiteGradient"></div>

			<PageFooter
				left={ (
					<MigrationFooterButtons
						saveAndContinue={ saveAndContinue }
						saveAndExit={ saveAndExit }
						saveProgress={ saveProgress }
					/>
				) }
			/>
		</div>
	);
}
