import { ReactElement } from 'react';
import {
	Button,
	CardContentSpacing,
	StandardModal,
	TextButton
} from '@bamboohr/fabric';
import { Stopwatch45x54 } from '@bamboohr/grim';

import { useStyles } from './styles';

type InfoStepProps = {
	closeModal: () => void,
	renderHeader: ReactElement,
	setStep: () => void
}

export function InfoStep({ closeModal, renderHeader, setStep }: InfoStepProps): ReactElement {
	const styles = useStyles();

	return (
		<StandardModal.Body
			renderFooter={(
				<StandardModal.Footer
					actions={[
						<Button data-bi-id="time-tracking-trial-cancel-step-1-continue-button" key="next-step-button" onClick={ setStep } type="button">{ $.__('Continue') }</Button>,
						<TextButton key="cancel-button" onClick={ closeModal } type="button">{ $.__('Cancel') }</TextButton>
					]}
				/>
			)}
			renderHeader={ renderHeader }
		>
			<StandardModal.Constraint
				spacingOverrides={{
					bottom: CardContentSpacing.LARGE,
					top: CardContentSpacing.LARGE
				}}
			>
				<StandardModal.HeroHeadline
					icon={(
						<span className={ styles.icon }>
							<Stopwatch45x54 />
						</span>
					)}
					text={ $.__('Maybe Next Time!') }
				/>
				<p className={ styles.bodyText }>
					{ $.__(`We’re sorry to hear that we weren’t a good fit. We will remove Time Tracking from your account.`) }
				</p>
				<h5 className={ styles.midHeadingText }>{ $.__('What happens after my trial has ended?') }</h5>
				<p>
					{ $.__('After your trial has ended, all Time Tracking timesheets, settings, and reports will be removed from your account. If you wish to save any hours entered during the trial, please export data from your Time Tracking reports before canceling.') }
				</p>
			</StandardModal.Constraint>
		</StandardModal.Body>
	);
}
