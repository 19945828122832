import { Icon } from '@bamboohr/fabric';

import Tooltip from 'tooltip.react';

import './error-tooltip.styl';

interface ErrorTooltipProps {
	message: string;
}

export const ErrorTooltip = (props: ErrorTooltipProps): JSX.Element => {
	const {
		message,
	} = props;

	return (
		<div className="fab-FormField ErrorTooltip__validationIcon">
			<Tooltip
				settings={ {
					html: `<div class="TooltipStandard TooltipStandard--textCenter">${ message }</div>`,
				} }
			>
				<Icon name="fab-circle-exclamation-14x14" />
			</Tooltip>
		</div>
	);
};
