import moment from 'moment';
import { ReactElement, useState } from 'react';
import { RadioGroup } from '@bamboohr/fabric';

import { StartDatePicker } from '../start-date-picker';

import { useStyles } from './styles';

enum RadioOptionValues {
	IMMEDIATELY = 'immediately',
	NEW_START = 'new-start',
}

export function EditStartDate({
	renderRadios,
	label,
	selectedStartDate,
	setSelectedStartDate,
}: {
	renderRadios: boolean;
	label: string;
	selectedStartDate: string;
	setSelectedStartDate: (date: string) => void;
}): ReactElement {
	const styles = useStyles();

	const [radioValue, setRadioValue] = useState<RadioOptionValues>(RadioOptionValues.IMMEDIATELY);
	return (
		<div>
			{!renderRadios && <span>{$.__('Started on %s', moment(selectedStartDate).format())}</span>}
			{renderRadios && (
				<>
					<RadioGroup
						items={[
							{
								id: 'immediately',
								label: 'Immediately',
								value: RadioOptionValues.IMMEDIATELY,
							},
							{
								id: 'new-start',
								label: 'Set New Start Date',
								value: RadioOptionValues.NEW_START,
							},
						]}
						label={label}
						onChange={({ value }: { value: RadioOptionValues }) => {
							setRadioValue(value);
						}}
						value={radioValue}
					/>
					{radioValue === RadioOptionValues.NEW_START && (
						<div className={styles.datePickerContainer}>
							<StartDatePicker
								activeStartDateIsPast={true}
								selectedStartDate={selectedStartDate}
								setSelectedStartDate={setSelectedStartDate}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
}
