import { ajax } from '@utils/ajax';

import { getJsonData } from 'Data.util';

export function getSettingsEnpsPreviewEmail(url) {
	return new Promise((resolve, reject) => {
		ajax.get(url)
			.then((response) => {
				if (response.status === 200 && response.data) {
					resolve(response.data);
				}
				reject();
			}, error => reject(error));
	});
}

export function getSettingsEnpsData() {
	const keepNodeInDOM = true;
	return new Promise(resolve => resolve(getJsonData('#enpsData', keepNodeInDOM)));
}

export function scheduleEnpsDemo() {
	ajax.get('/settings/enps?schedule_demo=1');
}
