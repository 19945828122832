import {
	createContext,
	useContext,
} from 'react';

import {
	ValidationContextState,
} from './types';

export const ValidationContext = createContext<ValidationContextState | null>(null);
export const useValidationContext = (): ValidationContextState => useContext(ValidationContext);
