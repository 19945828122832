import {
	ModalDataProvider,
} from './state';
import {
	bem,
} from './util';

import UserList from './user-list';
import GroupSelector from './group-selector';

import './styles.styl';


/**
 *
 * @param {object} props
 * @param {string[]} props.userIds
 * @param {string[]} props.employeeIds
 */
export default function SetAccessLevelModal({
	userIds,
	employeeIds,
}) {
	return (
		<ModalDataProvider
			employeeIds={ employeeIds }
			userIds={ userIds }
		>
			<div className={ bem() }>
				<div className={ bem('header') }>
					<p className={ bem('headerTitle') }>
						{ $.__(`Setting Access Level for`) }:
					</p>
					<UserList />
				</div>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<label
							className="fab-Label"
							htmlFor="accessLevel"
						>
							{ $.__(`New Access Level`) }
						</label>
						<div className="fab-InputWrapper">
							<GroupSelector />
						</div>
					</div>
				</div>
			</div>
		</ModalDataProvider>
	);
}
