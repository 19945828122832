import {
	htmlentities
} from 'String.util';
import deleteModalTemplate from './delete-modal';
import './job-title/job-title-eeo-categories.react';

import './styles.styl';

window.EmployeeFields = {
	openDeleteModal: function openDeleteModal(data = {}, options = {}) {
		const lowerTitle = data.title.toLowerCase();
		const countMsg = $.__n('%1$s active employee', '%1$s active employees', htmlentities(data.count));
		const completeCountMsg = $.__(
			'You have %1$s%2$s%3$s in this %4$s.',
			'<b class="fontSemibold">',
			htmlentities(countMsg),
			'</b>',
			htmlentities(lowerTitle),
		);
		const suggestionMsg = $.__('We suggest moving employees to a new %1$s before deleting this one.', htmlentities(lowerTitle));
		const approvalsMessage = $.__(
			'You have approvals set up for the %1$s"%2$s"%3$s %4$s. Outstanding approvals will be honored; future approvals will follow the "All other %5$ss" flow.',
			'<b class="fontSemibold">',
			htmlentities(data.label),
			'</b>',
			htmlentities(lowerTitle),
			htmlentities(lowerTitle)
		);

		const templateData = {
			confirmationMessage: $.__(
				'Are you sure you want to delete the "%1$s%2$s%3$s" %4$s?',
				'<b class="fontSemibold">',
				htmlentities(data.label),
				'</b>',
				htmlentities(lowerTitle),
			),
			deleteMessage: `${ completeCountMsg } ${ suggestionMsg }`,
			approvalsDeleteMessage: approvalsMessage,
			isUsedByApprovals: false,
			...data,
		};

		const modalTitle = $.__('Delete %1$s', data.title);
		const modalOptions = {
			title: modalTitle,
			html: window.microTemplate(deleteModalTemplate, templateData),
			footer: {
				buttons: {
					primary: {
						text: modalTitle,
						action() {
							window.SimpleModal.closeModal();
						}
					}
				}
			},
			deleteModal: {
				active: true,
				selector: '#js-deleteConfirm'
			},
			...options,
		};

		return window.SimpleModal.openModal(modalOptions);
	}
};

import './default';

import './pay-groups';

import './pay-schedule';

import './project-tracking';
