import { useState, ReactElement } from 'react';
import {
	makeStyles,
	MultiStepModal,
	StandardModal,
} from '@bamboohr/fabric';
import {
	InfoStep,
	FormStep
} from './steps';

type CancelTrialModalProps = {
	closeModal: () => void,
	isOpen: boolean,
}

export function CancelTrialModal({ closeModal, isOpen, ...rest }: CancelTrialModalProps): ReactElement {
	const [currentStep, setCurrentStep] = useState(0);
	const [isModalProcessing, setIsModalProcessing] = useState(false);

	const nextStep = () => {
		setCurrentStep((currStep: number) => {
			return ++currStep;
		});
	};

	const prevStep = () => {
		setCurrentStep((currStep: number) => {
			return --currStep;
		});
	};

	const modalHeader = <StandardModal.Header hasCloseButton={ true } />;

	const steps = [
		(
			<InfoStep
				closeModal={ closeModal }
				key="InfoStep"
				renderHeader={ modalHeader }
				setStep={ nextStep }
			/>
		),
		(
			<FormStep
				closeModal={ closeModal }
				key="FormStep"
				renderHeader={ modalHeader }
				setStep={ prevStep }
				{ ...rest }
			/>
		),
	];

	return (
		<MultiStepModal
			currentStep={ currentStep }
			hasCloseButton={ true }
			isOpen={ isOpen }
			isProcessing={ isModalProcessing }
			onRequestClose={ closeModal }
			steps={ steps }
			title={ $.__('Cancel Time Tracking Trial') }
		/>
	);
}
