import { isEqual } from 'lodash';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useSetupServices } from '../service';

import { UpcomingSurvey, UpcomingSurveysRequest } from '../types';

interface UpcomingProps {
	selectedFrequencyValue: string|null;
	scheduleStartDate: Moment;
	selectedScheduleValue: string|null;
	clearSchedule: () => void;
}

interface UpcomingReturn {
	upcomingSurveys: UpcomingSurvey[];
	updateUpcomingSurveys: (surveys: UpcomingSurvey[]) => void;
	scheduleData: UpcomingSurveysRequest;
	schedulePending: boolean;
}

const useUpcoming = ({selectedFrequencyValue, scheduleStartDate, selectedScheduleValue, clearSchedule }: UpcomingProps): UpcomingReturn => {
	const [upcomingSurveys, setUpcomingSurveys] = useState<UpcomingSurvey[]>([]);
	const [lastRequest, setLastRequest] = useState<UpcomingSurveysRequest|null>();
	const [schedulePending, setSchedulePending] = useState(false);
	const {getUpcomingSurveys} = useSetupServices();
	const request = {
		nextSurveyDate: scheduleStartDate,
		frequency: selectedFrequencyValue,
		schedule: selectedScheduleValue,
	} as UpcomingSurveysRequest;
	useEffect(() => {
		if (scheduleStartDate === null || selectedFrequencyValue === null || selectedScheduleValue === null) {
			if (upcomingSurveys.length > 0) {
				setUpcomingSurveys([]);
				setLastRequest(null);
			}
			return;
		}

		if (isEqual(request, lastRequest)) return;
		setSchedulePending(true);
		getUpcomingSurveys(request)
			.then((newUpcomingSurveys) => {
				setLastRequest(request);
				setUpcomingSurveys(newUpcomingSurveys);
			})
			.catch(clearSchedule)
			.then(() => setSchedulePending(false));
	}, [scheduleStartDate, selectedFrequencyValue, selectedScheduleValue, upcomingSurveys, lastRequest]);
	return {
		updateUpcomingSurveys: setUpcomingSurveys,
		upcomingSurveys,
		scheduleData: request,
		schedulePending,
	};
};

export default useUpcoming;
