import { unmountComponentAtNode } from 'react-dom';
import moment from 'moment';
import { Modal } from 'modal-legacy';
import { Table } from '@bamboohr/fabric';

import {
	TIME_TRACKING_NOTIFICATION_MODAL_DATA
} from './const';

import './styles.styl';

export default function TimeTrackingNotificationModal(props) {
	const { attachedElement, employees, isOpen, onClose, type } = props;
	const employeeArray = Object.keys(employees).map(key => employees[key]);

	const columns = [
		{
			header: $.__('Name'),
			cell: row => <a className="fab-Link" href={ `/employees/employee.php?id=${ row.id }` } id={ row.id }>{ row.name }</a>,
			sortBy: row => row.name,
			width: '50%'
		},
		{
			header: $.__('Time Tracking Start Date'),
			cell: (row) => {
				if (row.status === 'scheduled') {
					return moment(row.scheduleDate, 'YYYY-MM-DD').format();
				}

				if (row.status === 'not scheduled') {
					return (
						<div className="TimeTrackingNotificationModal__doubleRow">
							<span>{ TIME_TRACKING_NOTIFICATION_MODAL_DATA.statuses[row.status] }</span>
							<span className="TimeTrackingNotificationModal__doubleRow__subText">{ TIME_TRACKING_NOTIFICATION_MODAL_DATA.errors[row.error] }</span>
						</div>
					);
				}
			},
			sortBy: row => row.scheduleDate,
			width: '50%'
		}
	];

	return (
		<Modal
			alternativeAction={ null }
			headline={ TIME_TRACKING_NOTIFICATION_MODAL_DATA[type].headline }
			icon="fab-circle-info-54x54"
			isOpen={ isOpen === undefined ? true : isOpen }
			onClose={ attachedElement ? () => unmountComponentAtNode(attachedElement) : onClose }
			primaryAction={ attachedElement ? () => unmountComponentAtNode(attachedElement) : onClose }
			primaryActionText={ $.__('Okay') }
			title={ $.__('Just so you know...') }
			type="small"
		>
			<div>
				<p className="TimeTrackingNotificationModal__description">{ TIME_TRACKING_NOTIFICATION_MODAL_DATA[type].description }</p>
				<Table
					caption={ $.__('Time Tracking Employee Notification') }
					columns={ columns }
					rowKey={ row => row.id }
					rows={ employeeArray }
				/>
			</div>
		</Modal>
	);
}
