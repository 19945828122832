import { useState } from 'react';
import {
	Button,
	InPageMessaging,
	makeStyles,
	TextButton
} from '@bamboohr/fabric';
import { CalendarClock36x36 } from '@bamboohr/grim';
import {
	Message,
} from '@bamboohr/utils/lib/message';
import { SubscribeModal } from './subscribe-modal';
import { CancelTrialModal } from './cancel-trial-modal';

interface Props {
	daysRemainingInTrial: number,
	subscriptionRequested: boolean,
	trialExtended: boolean
}

const useStyles = makeStyles(() => {
	return {
		root: {
			paddingTop: '18px'
		}
	};
});

export function TrialBanner(props: Props): JSX.Element {
	const styles = useStyles();
	const {
		daysRemainingInTrial,
		subscriptionRequested,
		trialExtended
	} = props;

	const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);

	const bannerActions = (
		<>
			<Button
				color="secondary"
				data-bi-id="time-tracking-trial-open-subscribe-modal-button"
				disabled={ subscriptionRequested }
				onClick={ () => setSubscribeModalOpen(true) }
				outline={ true }
				type="button"
			>
				{ $.__('Subscribe') }
			</Button>
			<TextButton
				data-bi-id="time-tracking-trial-open-cancel-modal-button"
				onClick={ () => setCancelModalOpen(true) }
				type="button"
			>
				{ $.__('Cancel Trial') }
			</TextButton>
		</>
	);

	let bodyText = (
		<Message
			params={ [daysRemainingInTrial] }
			text={ $._n('You have {1} day left on your free trial.', 'You have {1} days left on your free trial.', daysRemainingInTrial) }
		/>
	);

	if (trialExtended) {
		bodyText = (
			<Message
				params={ [daysRemainingInTrial] }
				text={ $._n('We extended your free trial. You have {1} day remaining.', 'We extended your free trial. You have {1} days remaining.', daysRemainingInTrial) }
			/>
		);
	}

	if (subscriptionRequested) {
		bodyText = (
			<Message
				text={ $._(`Subscription requested. We'll reach out with billing info.`) }
			/>
		);
	}

	return (
		<div className={ styles.root }>
			<InPageMessaging
				body={ bodyText }
				customAction={ bannerActions }
				header={ $.__('Time Tracking Trial') }
				icon={ <CalendarClock36x36 /> }
				info={ true }
			/>

			<SubscribeModal
				closeModal={ () => setSubscribeModalOpen(false) }
				isOpen={ subscribeModalOpen }
			/>

			<CancelTrialModal
				closeModal={ () => setCancelModalOpen(false) }
				isOpen={ cancelModalOpen }
			/>
		</div>
	);
}
