import moment from 'moment';
import { BodyText, Divider, Dropdown, Headline, InPageMessaging, LayoutBox } from '@bamboohr/fabric';
import { Lock30x36 } from '@bamboohr/grim';
import { Fragment, ReactElement, useState } from 'react';

import { Message } from '@bamboohr/utils/lib/message';

import { getAccountConfiguration } from 'multi-factor-authentication.mod';

import { TwoStepLoginUtilityModal } from '../two-step-login-utility-modal';

import { useStyles } from './styles';
import { MFA_LEVELS, MODAL_TYPES } from '../../constants';
import { isStartDatePast } from '../../etc';
import { UtilityModalProps } from '../two-step-login-utility-modal/types';
import { isEnabled } from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface MfaConfiguration {
	startDate: string;
	mfaLevel: MFA_LEVELS;
	selectedAccessLevelsById: Record<string, string>;
	canEditMfa: boolean;
}

interface RoleBasedMfaSetupProps {
	mfaConfiguration: MfaConfiguration;
	toggleGetStarted(boolean): void;
}

export function TwoStepLoginSetup(props: RoleBasedMfaSetupProps): ReactElement {
	const { mfaConfiguration = {} as MfaConfiguration, toggleGetStarted } = props;

	const [modalToRender, setModalToRender] = useState<false | MODAL_TYPES>(false);
	const [mfaSettings, setMfaSettings] = useState<MfaConfiguration>(mfaConfiguration);

	const { startDate: twoStepStartDate, mfaLevel, selectedAccessLevelsById } = mfaSettings;
	const isDatePast = isStartDatePast(twoStepStartDate);

	function refreshData() {
		getAccountConfiguration().then((data) => {
			const updatedMfaConfiguration = (
				data as {
					data: {
						startDate: string;
						companyConfiguredAccess: MFA_LEVELS;
						selectedAccessLevelsById: Record<string, string>;
						canEditMfa: boolean;
					};
				}
			).data;
			setMfaSettings({
				startDate: updatedMfaConfiguration.startDate,
				mfaLevel: updatedMfaConfiguration.companyConfiguredAccess,
				selectedAccessLevelsById: updatedMfaConfiguration.selectedAccessLevelsById,
				canEditMfa: updatedMfaConfiguration.canEditMfa,
			});
		});
	}

	function getMfaDropdown() {
		const items = [
			{
				text: $.__("Reset Employee's 2-Step Login"),
				value: 'resetLogin',
			},
		];

		if (mfaSettings.canEditMfa) {
			items.push({
				text: $.__(`Edit 2-Step Login Settings`),
				value: 'editTwoStep',
			});
		}
		return items;
	}

	const styles = useStyles();

	const renderHeaderSection = (mfaLevel: string) => {
		const beforeStartDateMessage = $._('2-Step Login will be required starting {1}');
		const afterStartDateMessage = $._('Started {1}');

		return (
			<Fragment>
				{	
					ifFeature(
						'encore',
						<Divider color='neutral-extra-weak' marginBottom={2.5}/>,
					)
				}
				<div className={styles.utilityDropdown}>
					{ifFeature(
						'encore', 
						(
							<Dropdown
								biId={'2-step-gear-dropdown'}
								ButtonProps= {{
									ariaLabel: '2-step-gear-dropdown-button',
									color: 'secondary',
									icon: "gear-regular",
									size : "small",
								}}
								items={getMfaDropdown()}
								onSelect={(optionValue) => setModalToRender(optionValue)}
								type='button'
							/>
						),
						(
							<Dropdown
								biId={'2-step-gear-dropdown'}
								buttonSettings={{
									ariaLabel: '2-step-gear-dropdown-button',
									icon: 'fab-gear-15x16',
									outline: true,
									secondary: true,
								}}
								items={getMfaDropdown()}
								onSelect={(optionValue) => setModalToRender(optionValue)}
								type='button'
							/>
						)
					)}
					
				</div>
				{ifFeature(
					'encore', 
					<LayoutBox marginBottom='24px'>
						<Headline
							size='extra-small'
						>
							{$.__('2-Step Login')}
						</Headline>
					</LayoutBox>,
					<h4 className={`${styles.twoStepLoginSetup}__header`}>{$.__('2-Step Login')}</h4>
				)}
				<InPageMessaging
					body={
						<Message
							params={[moment(twoStepStartDate).format()]}
							text={isDatePast || mfaLevel === 'userOptIn' ? afterStartDateMessage : beforeStartDateMessage}
						/>
					}
					header={$.__('2-Step Login Enabled')}
					icon={ifFeature('encore', 'lock-solid', <Lock30x36 />)}
					neutral={true}
					type={ifFeature('encore', 'info')}
				/>
			</Fragment>
		);
	};

	function renderRoleBasedInformation(accessLevels: Record<string, string>) {
		return (
			<>
				<p className={styles.levelHeader}>{$.__('Required By Access Level')}</p>
				<p>{$.__('2-Step Login is required by all users with these Access Levels:')}</p>
				<ul className={styles.accessLevelList}>
					{Object.keys(accessLevels).map((key) => {
						return <li key={`${accessLevels[key]}-${key}`}>{accessLevels[key]}</li>;
					})}
				</ul>
				<p>{$.__('These users will be required to provide an extra verification code at login.')}</p>
			</>
		);
	}

	function renderRequiredAllInformation() {
		return (
			<>
				<p className={styles.levelHeader}>{$.__('Required For All')}</p>
				<p>{$.__('2-Step Login is required for all. Every user will be required to provide an extra verification code at login.')}</p>
			</>
		);
	}

	function renderOptInInformation() {
		return (
			<>
				{
					ifFeature(
						'encore',
						<LayoutBox marginBottom='2px'>
							<BodyText weight='semibold'>{$.__('Enabled For All')}</BodyText>
						</LayoutBox>,
						<p className={styles.levelHeader}>{$.__('Enabled For All')}</p>
					)
				}
				{
					ifFeature(
						'encore',
						<BodyText size='small' color='neutral-weak' weight='regular'>
							{$.__(
								'2-Step Login is not required, but is available for all users. Only those that opt-in will be required to provide an extra verification code at login.'
							)}
						</BodyText>,
						<p>
							{$.__(
								'2-Step Login is not required, but is available for all users. Only those that opt-in will be required to provide an extra verification code at login.'
							)}
						</p>
					)
				}
			</>
		);
	}

	function renderInformationSectionByLevel(mfaLevel: string, selectedAccessLevels?: Record<string, string>) {
		switch (mfaLevel) {
			case 'company':
				return renderRequiredAllInformation();
			case 'accessLevel':
				return renderRoleBasedInformation(selectedAccessLevels);
			case 'userOptIn':
				return renderOptInInformation();
			default:
				return null;
		}
	}

	function renderInformationSection(mfaLevel: string) {
		return (
			<div className={styles.mfaInfoContainer}>
				{
					ifFeature(
						'encore',
						(
							<LayoutBox marginBottom='4px'>
								<BodyText size='small' color='neutral-weak' weight='regular'>{$.__('Requirement Level')}</BodyText>
							</LayoutBox>
						),
						<p className={styles.note}>{$.__('Requirement Level')}</p>
					)
				}
				{renderInformationSectionByLevel(mfaLevel, selectedAccessLevelsById)}
			</div>
		);
	}

	const modalProps: UtilityModalProps = {
		activeStartDateIsPast: isDatePast,
		modalToRender,
		refreshSettingsPage: refreshData,
		setIsParentComponentVisible: setModalToRender,
		setToggleGetStarted: toggleGetStarted,
	};

	if (!twoStepStartDate) {
		modalProps.modalToRender = MODAL_TYPES.SETUP;
	}

	return (
		<>
			{modalProps.modalToRender && <TwoStepLoginUtilityModal {...modalProps} />}
			{twoStepStartDate && (
				<div className={styles.twoStepLoginSetup} id='twoStepLoginInfo'>
					{renderHeaderSection(mfaLevel)}
					{renderInformationSection(mfaLevel)}
				</div>
			)}
		</>
	);
}
