import React, { ReactElement } from 'react';
import { SideTab, SideTabGroup, SideTabGroupHeader, SideTabList, SideTabs, Divider } from '@bamboohr/fabric';
import { useStyles } from './styles';

export function RenderNavList({ levelTypes, setActiveTab, activeTab = '' }): ReactElement {
	const style = useStyles();
	return (
		<div className={style.navContainer}>
			<SideTabs onChange={(e, value) => setActiveTab(value)} value={activeTab}>
				<SideTabList header={$.__('Levels')} className={style.navBorder}>
					{levelTypes.map((accessLevel) => {
						return (
							<>
								{accessLevel.levels && accessLevel.levels.length > 0 ? (
									<SideTabGroup
										header={
											<SideTabGroupHeader
												{...(accessLevel.name === 'employee' && { 'data-bi-id': 'settings-access-levels-employee-levels' })}
											>
												{accessLevel.label}
											</SideTabGroupHeader>
										}
									>
										{accessLevel.levels.map((level) => (
											<SideTab href={level.href} key={level.id} value={level.id}>
												<div>
													<span className={style.labelFont} {...(level.name === 'all' && { 'data-bi-id': 'settings-access-levels-all' })}>{level.label}</span>&nbsp;
													<span className={style.countFont}>({level.count})</span>
												</div>
											</SideTab>
										))}
									</SideTabGroup>
								) : (
									<>
										{accessLevel.name === 'no_access' && <Divider light={true} orientation='horizontal' variant='fullWidth' />}
										<SideTab key={accessLevel.label} value={accessLevel.label}>
											<div>
												<span
													className={style.labelFont}
													{...(accessLevel.name === 'all' && { 'data-bi-id': 'settings-access-levels-all' })}
												>
													{accessLevel.label}
												</span>
												&nbsp;
												<span className={style.countFont}>({accessLevel.count})</span>
											</div>
										</SideTab>
									</>
								)}
							</>
						);
					})}
				</SideTabList>
			</SideTabs>
		</div>
	);
}
