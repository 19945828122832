import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	Select,
} from '@fabric/select';
import BEM from '@utils/dom/bem';

import {
	useAjax,
} from './ajax';

import sizeDefinitions from '@bamboohr/fabric/dist/definitions/json/sizes.json'
import colorDefinitions from '@bamboohr/fabric/dist/definitions/json/colors.json';
/**
 * A Hook for controlling and storing the values of form inputs
 *
 * @param {V} initialValues An object with the `key` being the name of the field, and the `value` is the initial value
 * @returns {FormDataAPI<V>}
 * @template {FormValues} V
 */
export function useFormData(initialValues) {
	const [values, setValues] = useState(initialValues || {});

	/** @type {FormDataAPI<V>['reset']} */
	const reset = (newValues = initialValues) => {
		setValues({
			...(newValues || {}),
		});
	};

	/** @type {FormDataAPI<V>['setValue']} */
	const setValue = (name, value) => {
		setValues({
			...values,
			[name]: value,
		});
	};

	/** @type {FormDataAPI<V>['onChange']} */
	const onChange = ({ target }) => {
		const {
			name,
			type,
		} = target;
		/** @type {string|string[]} */
		let {value} = target;

		if (name.endsWith('[]')) {
			/** @type {HTMLInputElement[]} */
			let inputs = Array.from(document.getElementsByName(name));

			if (
				type === 'checkbox' ||
				type === 'radio'
			) {
				inputs = inputs.filter(input => input.checked);
			}

			value = inputs
				.map(input => input.value);

		}

		setValue(name, value);
	};

	return {
		// state values
		values,

		// functions
		onChange,
		setValue,
		reset,
	};
}

/**
 * A hook for controlling the validation of a form
 *
 * @param {React.MutableRefObject<HTMLFormElement>} formRef The value of a `useRef()` hook that contains the ref to your form. (eg. `const formRef = useRef(null); <form ref={ formRef }>`)
 * @param {JQueryValidation.ValidationOptions} [opts={}] An optional configuration object for `.bhrValidate()`
 * @returns {FormValidationAPI}
 */
export function useFormValidation(formRef, opts = {}) {
	/** @type {[FormValidationState, React.Dispatch<React.SetStateAction<FormValidationState>>]} */
	const [{
		errors = {},
		valid = null,
	}, setState] = useState({});
	const $form = useMemo(() => $(formRef.current), [formRef.current]);
	const validator = useMemo(() => {
		if (formRef.current) {
			if (!$form.data('validator')) {
				$form.bhrValidate(opts);
			}

			return $form.data('validator');
		}
	}, [formRef.current]);

	/** @type {FormValidationAPI['validate']} */
	const validate = () => {
		setState({});

		if ($form.valid()) {
			window.closeMessage();

			setTimeout(() => {
				setState({
					valid: true,
				});
			}, 0);
		} else {
			const {
				errorMap,
			} = $form.data('validator');

			setTimeout(() => {
				setState({
					valid: false,
					errors: errorMap,
				});
			}, 0);
		}
	};

	/** @type {FormValidationAPI['useValidEffect']} */
	const useValidEffect = (effect, deps = []) => {
		useEffect(() => {
			if (valid === true) {
				effect();
			}
		}, [valid, ...deps]);
	};

	/** @type {FormValidationAPI['useInvalidEffect']} */
	const useInvalidEffect = (effect, deps = []) => {
		useEffect(() => {
			if (valid === false) {
				effect();
			}
		}, [valid, ...deps]);
	};

	/** @type {FormValidationAPI['useValidateEffect']} */
	const useValidateEffect = (deps = [], condition = true) => {
		useEffect(() => {
			if (condition) {
				validate();
			}
		}, deps);
	};

	return {
		// state values
		errors,
		valid,
		validator,

		// hooks
		useValidEffect,
		useInvalidEffect,
		useValidateEffect,

		// functions
		validate,
	};
}


/**
 * A hook for controlling and monitoring the submission of a form
 *
 * @param {React.MutableRefObject<HTMLFormElement>} formRef The value of a `useRef()` hook that contains the ref to your form. (eg. `const formRef = useRef(null); <form ref={ formRef }>`)
 * @returns {FormSubmitAPI<T>}
 * @template {any} T
 */
export function useFormSubmit(formRef) {
	const {
		// state values
		data: submitResult,
		error: submitError,
		processing: submitting,
		response: submitResponse,
		status: submitStatus,

		// hooks
		useAjaxEffect: useSubmitEffect,
		useErrorEffect: useSubmitErrorEffect,
		useResponseEffect: useSubmitResponseEffect,
		useSuccessEffect: useSubmitSuccessEffect,

		// functions
		request,
		reset: resetSubmitResult,
	} = useAjax();

	const submit = (opts = {}) => {
		const {
			current: form,
		} = formRef;
		const url = form.getAttribute('action') || window.location.href;
		const method = form.getAttribute('method') || 'post';
		const enctype = form.getAttribute('enctype') || 'application/x-www-form-urlencoded';

		request({
			url,
			method,
			data: form,
			...opts,
			headers: {
				'content-type': enctype,
				...opts.headers,
			},
		});
	};

	return {
		// state values
		submitting,
		submitError,
		submitResponse,
		submitResult,
		submitStatus,

		// hooks
		useSubmitEffect,
		useSubmitErrorEffect,
		useSubmitResponseEffect,
		useSubmitSuccessEffect,

		// functions
		resetSubmitResult,
		submit,
	};
}

/** @type {{ HEIGHT: string[], WIDTH: string[], COLOR: string[] }} */
const FAB_VALUES = {
	HEIGHT: Object.keys(sizeDefinitions.heights),
	WIDTH: Object.keys(sizeDefinitions.widths),
	COLOR: Object.keys(colorDefinitions.colors),
};

/**
 * A Hook for interacting with forms in React
 *
 * @returns {FormAPI<V>}
 * @template {FormValues} V
 */
export function createForm() {
	/** @type {React.Context<FormContext<V>>} */
	const Context = createContext({});

	/** @type {FormAPI<V>['FormProvider']} */
	const FormProvider = ({
		form,
		values,
		children,
	}) => {
		/** @type {FormDataAPI<V>} */
		const formData = useFormData(values);
		const formValidation = useFormValidation(form);
		const formSubmit = useFormSubmit(form);

		useEffect(() => {
			formData.reset(values);
		}, [values]);

		return (
			<Context.Provider value={ {
				...formData,
				...formValidation,
				...formSubmit,
			} }
			>
				{ children }
			</Context.Provider>
		);
	};

	/** @type {FormAPI<V>['FormProps']V>>} */
	const Form = ({
		values,
		children,
		classes,
		...props
	}) => {
		/** @type {React.MutableRefObject<HTMLFormElement>} */
		const formRef = useRef(null);
		const bem = useMemo(() => new BEM('fab-Form', null, {
			'^': () => classes,
		}), []);

		return (
			<FormProvider form={ formRef } values={ values }>
				<form
					className={ bem.className() }
					ref={ formRef }
					{ ...props }
				>
					{ children }
				</form>
			</FormProvider>
		);
	};

	/** @type {FormAPI<V>['FormLabel']} */
	const FormLabel = ({
		classes,
		name,
		id,
		children,
		required = false,
		color,
		...props
	}) => {
		const {
			errors,
		} = useContext(Context);
		const error = !!errors[name];
		const bem = useMemo(() => new BEM('fab-Label', () => ({
			required,
			[color]: !error && FAB_VALUES.COLOR.includes(String(color).trim()),
			error,
		}), {
			'^': () => classes
		}), []);

		return (
			<label
				className={ bem.className() }
				htmlFor={ id || name }
				{ ...props }
			>
				{ children }
			</label>
		);
	};

	/** @type {FormAPI<V>['FormInput']} */
	const FormInput = ({
		classes,
		name,
		width = 6,
		size = 'medium',
		hidden = false,
		...props
	}) => {
		const {
			values,
			errors,
			onChange,
		} = useContext(Context);

		const bem = useMemo(() => new BEM('fab-TextInput', () => ({
			[`width${ width }`]: FAB_VALUES.WIDTH.includes(String(width).trim()),
			[size]: FAB_VALUES.HEIGHT.includes(String(size).trim()),
			error: !!errors[name],
		}), {
			'^': () => classes,
		}), []);

		return (
			<input
				className={ hidden ? null : bem.className() }
				id={ name }
				name={ name }
				onChange={ onChange }
				type={ hidden ? 'hidden' : 'text' }
				value={ values[name] || '' }
				{ ...props }
			/>
		);
	};

	/** @type {FormAPI<V>['FormHiddenInput']} */
	const FormHiddenInput = (props) => {
		return (
			<FormInput
				{ ...props }
				hidden={ true }
			/>
		);
	};

	/** @type {FormAPI<V>['FormCheckbox']} */
	const FormCheckbox = ({
		classes,
		name,
		label,
		value,
		size = 'medium',
		note,
		...props
	}) => {
		const {
			values,
			onChange,
		} = useContext(Context);
		const id = `${ name }_${ value }`;
		const bem = useMemo(() => new BEM('fab-Checkbox', null, {
			'^': () => ({
				[`--${ size }`]: FAB_VALUES.HEIGHT.includes(String(size).trim()),
			}),
			'input': () => classes,
		}), []);

		return (
			<div className={ bem.className() }>
				<input
					checked={ Array.isArray(values[name]) && values[name].includes(value) }
					className={ bem.className('input') }
					id={ id }
					name={ name }
					onChange={ onChange }
					type="checkbox"
					value={ value }
					{ ...props }
				/>
				<FormLabel
					{ ...props }
					className={ bem.className('label') }
					id={ id }
					name={ name }
				>
					{ label }
				</FormLabel>
				{ note && (
					<div className={ bem.className('note') }>
						{ note }
					</div>
				) }
			</div>
		);
	};

	/** @type {FormAPI<V>['FormCheckboxGroup']} */
	const FormCheckboxGroup = ({
		name,
		items,
		classes,
		size,
		legend,
		color,
		...props
	}) => {
		const {
			errors,
		} = useContext(Context);
		name += '[]';
		const error = !!errors[name];
		const bem = useMemo(() => new BEM('fab-CheckboxGroup', null, {
			'^': [
				classes,
				BEM.createClassName('fab-FormSection'),
				() => ({
					[size]: FAB_VALUES.HEIGHT.includes(String(size).trim()),
				}),
			],
			'legend': [
				BEM.createClassName('fab-FormSection', 'legend'),
			],
			'label': () => ({
				[color]: !error && FAB_VALUES.COLOR.includes(String(color).trim()),
				error,
			}),
		}), []);

		const tmpItems = useMemo(() => {
			if (Array.isArray(items)) {
				return items;
			}

			if (typeof items === 'object') {
				return Object.entries(items)
					.map(([value, label]) => ({
						value,
						label,
					}));
			}

			return [items];
		}, [items]);

		return (
			<fieldset className={ bem.className() } { ...props }>
				{ legend && (
					<legend className={ bem.className('legend') }>
						{ legend }
					</legend>
				) }
				{ tmpItems.map(({ value, label, ..._props }) => (
					<FormCheckbox
						key={ value }
						label={ label }
						name={ name }
						size={ size }
						value={ value }
						{ ..._props }
					/>
				)) }
			</fieldset>
		);
	};

	/** @type {FormAPI<V>['FormSelect']} */
	const FormSelect = ({
		name,
		items = [],
		canSelectMultiple = false,
		...props
	}) => {
		const {
			values,
			setValue,
		} = useContext(Context);
		const selectedValues = useMemo(() => {
			let value = values[name];

			if (!value) {
				value = [];
			}

			if (!Array.isArray(value)) {
				value = [value];
			}

			return value;
		}, [values[name]]);
		const tmpItems = useMemo(() => {
			if (Array.isArray(items)) {
				return items;
			}

			if (
				items &&
				typeof items === 'object'
			) {
				return Object.entries(items)
					.map(([value, text]) => ({
						value,
						text,
					}));
			}

			return items || [];
		}, [items]);

		function onChange(value) {
			if (canSelectMultiple) {
				if (!value) {
					value = [];
				}

				if (!Array.isArray(value)) {
					value = [value];
				}
			} else if (Array.isArray(value)) {
				if (value.length < 1) {
					value = null;
				} else {
					value = value[0];
				}
			}

			setValue(name, value || null);
		}

		return (
			<Select
				canSelectMultiple={ !!canSelectMultiple }
				id={ name }
				items={ tmpItems }
				name={ name }
				onChange={ onChange }
				selectedValues={ selectedValues }
				{ ...props }
			/>
		);
	};

	/** @type {FormAPI<V>['FormTextarea']} */
	const FormTextarea = ({
		classes,
		name,
		color,
		width = 6,
		...props
	}) => {
		const {
			errors,
			values,
			onChange,
		} = useContext(Context);
		const error = !!errors[name];

		const bem = useMemo(() => new BEM('fab-Textarea', () => ({
			[`width${ width }`]: FAB_VALUES.WIDTH.includes(String(width).trim()),
			[color]: !error && FAB_VALUES.COLOR.includes(String(color).trim()),
			error,
		}), {
			'^': classes,
		}), []);

		return (
			<textarea
				className={ bem.className() }
				id={ name }
				name={ name }
				onChange={ onChange }
				value={ values[name] }
				{ ...props }
			/>
		);
	};

	return {
		// hooks
		useForm: () => useContext(Context),

		// components
		FormProvider,
		Form,
		FormCheckbox,
		FormCheckboxGroup,
		FormHiddenInput,
		FormInput,
		FormLabel,
		FormSelect,
		FormTextarea,
		FormRow,
		FormSection,
	};
}

/** @type {FormAPI<FormValues>['FormRow']} */
const FormRow = ({
	classes,
	tight = false,
	children,
	...props
}) => {
	const bem = useMemo(() => new BEM('fab-FormRow', { tight }, classes), []);

	return (
		<div className={ bem.className() } { ...props }>
			{ children }
		</div>
	);
}

/** @type {FormAPI<FormValues>['FormSection']} */
const FormSection = ({
	classes = '',
	children,
	legend,
	...props
}) => {
	const bem = useMemo(() => new BEM('fab-FormSection', [], {
		'^': () => classes,
	}), []);

	return (
		<fieldset className={ bem.className() } { ...props }>
			{ legend && (
				<legend className={ bem.className('legend') }>
					{ legend }
				</legend>
			) }
			{ children }
		</fieldset>
	);
}

/**
 * @typedef {Partial<Pick<FormValidationAPI, 'errors'|'valid'>>} FormValidationState
 */

/**
 * The API that is returned from the `useFormValidation()` hook
 *
 * @typedef FormValidationAPI
 * @property {{ [name: string]: string }} errors A map of current errors where the `key` is the name of the field, and the `value` is the error message
 * @property {boolean|null} valid Whether the form is currently valid
 * @property {() => Promise<JQueryValidation.Validator>} validator Validates the form and returns a promise that resolves if the form is valid, and rejects if not
 * @property {(effect: () => void, deps?: any[]) => void} useValidEffect A hook which is triggered when the form is valid
 * @property {(effect: () => void, deps?: any[]) => void} useInvalidEffect A hook which is triggered when the form is not valid
 * @property {(deps?: any[], condition?: boolean) => void} useValidateEffect A hook that will trigger validation when `deps` change and/or `condition` is `true`
 * @property {() => void} validate Triggers validation of the form and updates the state with the result
 */

/**
 * @typedef {{ [name: string]: string|string[] }} FormValues
 */

/**
 * The API that is returned from the `useFormData()` hook
 *
 * @typedef FormDataAPI
 * @property {V} values The current field values
 * @property {React.ChangeEventHandler<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>} onChange An event handler that can be passed as an `onChange` prop to an input that will automatically update the state for that input
 * @property {(name: keyof V, value: V[typeof name]) => void} setValue Updates the state value for an input by name
 * @property {(values?: V) => void} reset Resets the form back to initial values
 * @template {FormValues} V
 */

/**
 * The API that is returned from the `useFormSubmit()` hook
 *
 * @typedef FormSubmitAPI
 * @property {boolean} submitting Whether the AJAX submit is currently processing
 * @property {(opts?: import('axios').AxiosRequestConfig) => void} submit Submits the form via AJAX request
 * @property {import('./ajax').AjaxAPI<D>['processing']} submitting Whether the form is currently submitting via AJAX
 * @property {import('./ajax').AjaxAPI<D>['error']} submitError The `Error` object returned by the AJAX submit if it failed
 * @property {import('./ajax').AjaxAPI<D>['response']} submitResponse The `AjaxResponse` object returned by the AJAX submit
 * @property {import('./ajax').AjaxAPI<D>['data']} submitResult The data returned by the AJAX submit
 * @property {import('./ajax').AjaxAPI<D>['status']} submitStatus The status code returned by the AJAX submit
 * @property {import('./ajax').AjaxAPI<D>['useAjaxEffect']} useSubmitEffect A hook which triggers the form submit when the `deps` change, and/or `condition` is `true`
 * @property {import('./ajax').AjaxAPI<D>['useErrorEffect']} useSubmitErrorEffect A hook which triggers when the AJAX submit has failed
 * @property {import('./ajax').AjaxAPI<D>['useResponseEffect']} useSubmitResponseEffect A hook which triggers when the AJAX submit is completed
 * @property {import('./ajax').AjaxAPI<D>['useSuccessEffect']} useSubmitSuccessEffect A hook which triggers when the AJAX submit is successful
 * @property {import('./ajax').AjaxAPI<D>['reset']} resetSubmitResult Resets the AJAX result from the form submission
 * @template {unknown} D
 */

/**
 * The state object returned by the `useForm()` hook
 *
 * @typedef {FormValidationAPI & FormDataAPI<V> & FormSubmitAPI<unknown>} FormContext
 * @template {FormValues} V
 */

/**
 * The API that is returned from `createForm()`
 *
 * @typedef FormAPI
 * @property {() => FormContext<V>} useForm A hook that will return the current context object
 * @property {React.FunctionComponent<FormProviderProps>} FormProvider The `React.Provider` for wrapping your `<form>`
 * @property {React.FunctionComponent<FormProps>} Form A React component that includes the `FormProvider` and `<form>` for you
 * @property {React.FunctionComponent<FormInputProps>} FormInput A React component for `fab-TextInput` that automatically handles the state based on just the `name` prop
 * @property {React.FunctionComponent<FormHiddenInputProps>} FormHiddenInput A React component for inputs with `type="hidden"` that automatically handles the state based on just the `name` prop
 * @property {React.FunctionComponent<FormCheckboxProps>} FormCheckbox A React component for `fab-Checkbox` that automatically handles the state based on just the `name` prop
 * @property {React.FunctionComponent<FormCheckboxGroupProps>} FormCheckboxGroup A React component for a set of `FormCheckbox` elements wrapped in a `fab-CheckboxGroup` that automatically handles the state based on just the `name` prop
 * @property {React.FunctionComponent<FormSelectProps>} FormSelect A React component for `@fabric/select` that automatically handles the state based on just the `name` and `items` prop
 * @property {React.FunctionComponent<FormTextareaProps>} FormTextarea A React component for `fab-Textarea` that automatically handles the state based on just the `name` prop
 * @property {React.FunctionComponent<FormRowProps>} FormRow A React component for `fab-FormRow`
 * @property {React.FunctionComponent<FormSectionProps>} FormSection A React component for `fab-FormSection`
 * @property {React.FunctionComponent<FormLabelProps>} FormLabel A React component for `fab-FormLabel`
 * @template {FormValues} V
 */

/**
 * @typedef FormProviderProps
 * @property {React.MutableRefObject<HTMLFormElement>} form A ref to the `<form>` to be hooked up
 * @property {V} values The initial values to be used with the `useFormData()` hook
 * @template {FormValues} V
 */

/**
 * @typedef FormProps
 * @property {V} values The initial values to be used with the `useFormData()` hook
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 * @template {FormValues} V
 */

/**
 * @typedef FormLabelProps
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 * @property {string} name The name of the field
 * @property {string} [id=name] An optional `id` to use in the `for` attribute of the `<label>` (defaults to the value of `name`)
 * @property {boolean} [required] Whether the fields should be required
 * @property {'error'|'warning'|'info'} [color]
 */

/**
 * @typedef FormInputProps
 * @property {string} name The name of the field
 * @property {boolean} [hidden=false] Whether the input should be `type="hidden"`
 * @property {FabWidth} [width=6] The width of the input, as defined by Fabric
 * @property {FabHeight} [size='medium'] The height of the input, as defined by Fabric
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
*/

/** @typedef {FormInputProps & { hidden?: never }} FormHiddenInputProps */

/**
 * @typedef FormCheckboxProps
 * @property {string} name The name of the field
 * @property {string} [label] The translated label to be shown next to the checkbox
 * @property {string} value The value for the field
 * @property {FabHeight} [size='medium'] The height of the checkbox, as defined by Fabric
 * @property {string} [note] An optional note to be displayed next to the checkbox
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 */

/**
 * @typedef FormCheckboxGroupProps
 * @property {string} name The name of the field
 * @property {FormCheckboxProps[]|{ [value: string]: string }} items A list of `FormCheckbox` props to be included in the group
 * @property {FabHeight} [size='medium'] The height of the checkboxes in the group, as defined by Fabric
 * @property {string} [legend] An optional label for the group
 * @property {'error'|'warning'|'info'} [color] An optional color for the group `legend`, as defined by Fabric
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 */

/**
 * @typedef {import('@fabric/select').Select & { name: string }} FormSelectProps
 */

/**
 * @typedef FormTextareaProps
 * @property {string} name The name of the field
 * @property {FabWidth} [width=6] The width of the `textarea`, as defined by Fabric
 * @property {'error'|'warning'|'info'} [color] An optional color for `textarea`, as defined by Fabric
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 */

/**
 * @typedef FormRowProps
 * @property {boolean} [tight=false] Whether to show the low-padding version of `fab-FormRow`
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 */

/**
 * @typedef FormSectionProps
 * @property {string} [legend] An optional `fab-FormSection__legend` to show at the top of the `fab-FormSection`
 * @property {import('@utils/dom/bem').Modifier} [classes] Optional classes that will be passes as `extraClasses` to the `BEM` utility
 */

/** @typedef {keyof import('@bamboohr/fabric/dist/definitions/json/sizes.json')['heights']} FabHeight */
/** @typedef {keyof import('@bamboohr/fabric/dist/definitions/json/sizes.json')['widths']} FabWidth */
