import { DisplayFileCard, Flex, Icon, TextButton } from '@bamboohr/fabric';

/* @startCleanup encore */
import './completed-forms.styl';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function CompletedForms(props) {
	const openPreview = (file) => {
		window.previewFile(file.fileDataId, file.fileId, file.displayFileName, false, 'div', false, 'previewer');
	};

	const renderFiles = () => {
		const { files } = props;

		return files.map((file) => {
			if (!file.fileId) {
				return null;
			}

			return ifFeature(
				'encore',
				<DisplayFileCard fileType='pdf' onClick={() => openPreview(file)} fileName={file.displayFileName} fileId={file.fileId} />,
				<div className='CompletedForms__file' key={file.fileId} onClick={() => openPreview(file)}>
					<div
						className='CompletedForms__fileName'
						data-filemode='previewer'
						data-filename={file.displayFileName}
						data-file_id={file.fileId}
					>
						<TextButton onClick={() => openPreview(file)} inline type='button'>
							{file.displayFileName.replace('.pdf', '')}
						</TextButton>
					</div>

					<div className='CompletedForms__fileSpecs'>
						<span className='CompletedForms__fileIcon'>
							<Icon name='fab-file-pdf-16x20' />
						</span>

						<span className='CompletedForms__fileExtension'>PDF</span>
					</div>
				</div>
			);
		});
	};

	const { isBank = false } = props;

	const legendText = isBank ? $.__('Bank Authorization Forms') : $.__('Payroll Forms');
	const subLegendText = isBank
		? $.__('Here is a copy of your completed account authorization forms. Keep these for your records.')
		: $.__('Here is a copy of your completed forms.');

	return ifFeature(
		'encore',
		<div className='fab-FormSection'>
			<legend className='fab-FormSection__legend'>
				<span className='fab-FormSection__legendText'>{legendText}</span>
				<span className='fab-FormSection__legendNote'>{subLegendText}</span>
			</legend>

			<Flex gap={2}>{renderFiles()}</Flex>
		</div>,
		<div className='fab-FormSection CompletedForms'>
			<legend className='fab-FormSection__legend'>
				{legendText}
				<span className='fab-FormSection__legendNote'>{subLegendText}</span>
			</legend>

			<div className='CompletedForms__group'>{renderFiles()}</div>
		</div>
	);
}
