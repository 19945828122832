import React, { ReactElement } from 'react';

import { PlansLanding } from './plans-landing';
import { BenefitsSettingsContainer } from './data/benefits-settings-store';
import { DemoRequest } from '../benefits-admin-upsell';

interface BenefitsSettingsConnectorProps {
    demoRequestData: DemoRequest;
}

export const BenefitsSettingsConnector = (props: BenefitsSettingsConnectorProps): ReactElement => {
    const { demoRequestData } = props;

    return (
        <BenefitsSettingsContainer>
            <PlansLanding demoRequest={demoRequestData} />
        </BenefitsSettingsContainer>
    );
};
