import { PlanEnrollments } from '../types';

export const doesPlanHavePastPresentOrFutureEnrollments =
	(enrollments?: PlanEnrollments): boolean => {
		if (!enrollments) {
			return false;
		}

		return enrollments.hasFuture || enrollments.hasCurrent || enrollments.hasPast;
	};

export const doesPlanHavePresentOrFutureEnrollments =
	(enrollments?: PlanEnrollments): boolean => {
		if (!enrollments) {
			return false;
		}

		return enrollments.hasFuture || enrollments.hasCurrent;
	};
