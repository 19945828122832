import { Button, LayoutBox, Headline, Flex } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useEffect, useState } from 'react';

import { useStyles } from './styles';

import { AuthType as AuthScreen, InterstitialAuthProps } from './types';
import { AuthOptions } from '../../constants';
import { getHeaderText } from './etc';

import { MfaCodeAuthentication } from './mfa-code-authentication'; 

export function InterstitialAuth(props: InterstitialAuthProps): JSX.Element {
	const {
		isInvalid = false,
		enabledMfaChannels,
		onChange,
		onNext,
		onResendSms,
		preferredChannel = AuthOptions.APP,
		lastFour,
		hitLimit,
	} = props;
	const styles = useStyles();
	const [code, setCode] = useState<string>('');
	const [type, setType] = useState<AuthScreen>(preferredChannel);
	const [rememberMeIsChecked, setRememberMeIsChecked] = useState<boolean>(false);

	useEffect(() => {
		setType(preferredChannel);
	}, [preferredChannel]);

	return ifFeature('encore',
		(
			<LayoutBox padding={0.5}>
				<LayoutBox padding={2.5}>
					<Headline size='medium'>{getHeaderText()}</Headline>
				</LayoutBox>
				<LayoutBox paddingTop={4}>
					<MfaCodeAuthentication
						enabledMfaChannels={enabledMfaChannels}
						hitLimit={hitLimit}
						isInvalid={isInvalid}
						lastFour={lastFour}
						onCodeChange={onChange}
						onRememberMeChange={setRememberMeIsChecked}
						onResendSms={onResendSms}
						rememberMeIsChecked={rememberMeIsChecked}
						setCode={setCode}
						setType={setType}
						type={type}
					/>
				</LayoutBox>
				<Flex justifyContent='end' padding={3}>
					<Button
							clickAction={() => {
								onNext(code, rememberMeIsChecked, type);
							}}
							data-button-next='next'
							text={$.__('Continue')}
							type='button'
					/>
				</Flex>
		</LayoutBox>
		), (
		<div className={`${styles.InterstitialAuth} ${styles.mfaLoginInterstitial}`}>
			<h2 className={styles.Header}>{getHeaderText()}</h2>
			<MfaCodeAuthentication
				enabledMfaChannels={enabledMfaChannels}
				hitLimit={hitLimit}
				isInvalid={isInvalid}
				lastFour={lastFour}
				onCodeChange={onChange}
				onRememberMeChange={setRememberMeIsChecked}
				onResendSms={onResendSms}
				rememberMeIsChecked={rememberMeIsChecked}
				setCode={setCode}
				setType={setType}
				type={type}
			/>
			<div className={styles.Footer}>
				<div className={styles.FooterAction}>
					<Button
						clickAction={() => {
							onNext(code, rememberMeIsChecked, type);
						}}
						data-button-next='next'
						text={$.__('Next')}
						type='button'
					/>
				</div>
			</div>
		</div>
	));
}
