import { CoverageLevelEnrollments, CoverageLevelValue } from '../types/wizard-values';

interface UnsupportedCoverage {
	id: string;
	name: string;
	enrolledEmployeeIds: number[];
}

export const getEnrolledUnsupportedCoverages = (
	coverageLevels: CoverageLevelValue[],
	supportedCoverageIds: string[],
	enrollments: CoverageLevelEnrollments
): UnsupportedCoverage[] => {
	return coverageLevels.reduce((acc: UnsupportedCoverage[], coverageLevel) => {
		const enrolledEmployeeIds: number[] = enrollments[coverageLevel.linkId] || [];

		if (!supportedCoverageIds.includes(coverageLevel.linkId) && enrolledEmployeeIds.length) {
			acc.push({id: coverageLevel.linkId, name: coverageLevel.name, enrolledEmployeeIds});
		}
		return acc;
	}, []);
};
