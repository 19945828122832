import { Fragment } from 'react';
import { BlankState as FabricBlankState, Button } from '@bamboohr/fabric';
import BlankState from 'blank-state.react';

import { ifFeature } from '@bamboohr/utils/lib/feature';

import { BankInfoHeader } from '../../../components/bank-info-header';

import { COMPANY_INFO_URL } from '../../../utils/shared';

import './blank-state.styl';

export function BlankStateComponent() {
	const redirectToCompanyInfoPage = () => {
		window.location = COMPANY_INFO_URL;
	};

	const title = $.__("Hold on, something's missing here");
	const subtitle = $.__('You need to complete your company information before setting up a bank account.');
	const buttonText = $.__('Complete Company Info');

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<div className='BlankStateComponent__content'>
				{ifFeature(
					'encore',
					<FabricBlankState
						actions={[
							<Button color='secondary' key='redirectToCompanyPage' onClick={redirectToCompanyInfoPage} variant='contained'>
								{buttonText}
							</Button>,
						]}
						icon='bs-magnifying-glass'
						subtitle={subtitle}
						title={title}
					/>,
					<BlankState
						button={<Button clickAction={redirectToCompanyInfoPage} outline={true} size='small' text={buttonText} type='button' />}
						className='BlankStateComponent__icon'
						icon='fab-office-building-question-120x120'
						subtitle={subtitle}
						title={title}
					/>
				)}
			</div>
		</Fragment>
	);
}
