import { Flex } from '@bamboohr/fabric';
import { Fragment } from 'react';
import c from 'classnames';
/* @startCleanup encore */
import {
	ifFeature,
} from '@utils/feature';
/* @endCleanup encore */

import { FedWithholdingContainer } from '../fed-withholding-container';
import { StateWithholdingContainer } from '../state-withholding-container';
import { FedWithholding2020 } from 'payroll/withholding/components/fed-withholding-2020.react';

export function OldWithholding(props) {
	const {
		fedPerms,
		federalData2020 = {},
		headerHeadlineSize,
		sectionsFlexGap = 4,
		showFederal2020,
		statePerms,
		isNewHirePacket,
		showWithholdingInformation,
		usesSectionComponent,
	} = props;

	const oldFedClassNames = ifFeature('encore',
		c(
			'js-pi-withholdings-federal',
			{ 'hidden': showFederal2020 }
		),
		c(
			'js-pi-withholdings-federal fab-FormSection',
			{ 'hidden': showFederal2020 }
		)
	);
	const isNhpPreview = isNewHirePacket && window.previewUser;

	let showFederalWithholding = null;
	let showStateWithholding = null;
	if (showWithholdingInformation) {
		({
			showFederalWithholding,
			showStateWithholding,
		} = showWithholdingInformation);
	}

	return (
		<Flex flexDirection="column" gap={sectionsFlexGap}>
			{ fedPerms !== 0 && (
				<Fragment>
					{ showFederal2020 && (
						<FedWithholding2020
							{ ...federalData2020 }
							headerHeadlineSize={ headerHeadlineSize }
							showExemptions={ false }
							showWithholding={ showFederalWithholding }
							usesSectionComponent={ usesSectionComponent }
							viewOnly={ isNhpPreview }
						/>
					) }
					{ !(isNewHirePacket && showFederal2020) && (
						<fieldset className={ oldFedClassNames }>
							<FedWithholdingContainer
								headerHeadlineSize={headerHeadlineSize}
								showWithholding={showFederalWithholding}
								usesSectionComponent={usesSectionComponent}
							/>
						</fieldset>
					) }
				</Fragment>
			) }

			{statePerms !== 0 && (
				<StateWithholdingContainer
					showWithholding={showStateWithholding}
					usesSectionComponent={usesSectionComponent}
				/>
			)}
		</Flex>
	);
}
