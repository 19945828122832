import {
	createContainer,
} from 'react-sweet-state';

import {
	fetchAllClientTaxSettings,
} from './actions/fetch-all-client-tax-settings';
import {
	companyTaxSettingsStore,
} from './company-tax-settings-store';
import type {
	CompanyTaxSettingsActions,
	CompanyTaxSettingsContainerProps,
	CompanyTaxSettingsStore,
} from '../types';

export const CompanyTaxSettingsContainer = createContainer<CompanyTaxSettingsStore, CompanyTaxSettingsActions, CompanyTaxSettingsContainerProps>(companyTaxSettingsStore, {
	onInit() {
		return function initCompanyTaxSettingsContainer({
			dispatch,
			setState,
		}, {
			clientEin,
			clientName,
		}) {
			dispatch(fetchAllClientTaxSettings());
			setState({
				clientEin,
				clientName,
			});
		}
	}
});
