import { RuleName, SetSpecialErrors, SpecialErrors } from '../../../types';

export const validateGreaterThan = (
	name: keyof SpecialErrors,
	value: number | null,
	min: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const isValid = value !== null && value > min;

	if (isValid) {
		setSpecialErrors(name, null);
	} else {
		setSpecialErrors(name, {
			rule: RuleName.greaterThanOrEqualTo,
			message: $.__('Value must be greater than %s', min),
		});
	}

	return isValid;
};

export const validateGreaterThanOrEqualTo = (
	name: keyof SpecialErrors,
	value: number | null,
	min: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const isValid = value !== null && value >= min;

	if (isValid) {
		setSpecialErrors(name, null);
	} else {
		setSpecialErrors(name, {
			rule: RuleName.greaterThanOrEqualTo,
			message: $.__('Value must be greater than or equal to %s', min),
		});
	}

	return isValid;
};

export const validateLessThanOrEqualTo = (
	errorKey: keyof SpecialErrors,
	value: number | null,
	max: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const isValid = value !== null && value <= max;

	if (isValid) {
		setSpecialErrors(errorKey, null);
	} else {
		setSpecialErrors(errorKey, {
			rule: RuleName.lessThanOrEqualTo,
			message: $.__('Please enter a value less than or equal to %s', max),
		});
	}

	return isValid;
};
