/* @startCleanup encore */
import { makeStyles } from '@bamboohr/fabric';

export default makeStyles(() => {
	return {
		view: {
			padding: '60px 24px 0',
		},
	};
});
/* @endCleanup encore */
