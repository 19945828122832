import { getDeductionTypeBySelectedPlanType } from './get-deduction-type-by-selected-plan-type';
import { DeductionTypeEnumContract } from '../../types/contracts';
import {
	DeductionTypeValue,
	PreTaxDeductionTypeValue,
} from '../../types/wizard-values';

export const getSupplementalDeductionTypeForPostData = (
    deductionTypeValue: DeductionTypeValue,
    isPayroll: boolean,
    preTaxDeductionTypeValue: PreTaxDeductionTypeValue,
    selectedPlanType: string,
): DeductionTypeEnumContract | null => {
    if (isPayroll) {
        if (preTaxDeductionTypeValue === DeductionTypeEnumContract.postTax) {
            return DeductionTypeEnumContract.postTax;
        }

        if (deductionTypeValue === DeductionTypeEnumContract.preTaxSubtype) {
            return deductionTypeValue;
        }

        return getDeductionTypeBySelectedPlanType(selectedPlanType) as DeductionTypeEnumContract;
    }

    return null;
};
