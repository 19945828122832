import classNames from 'classnames';
import {
	useMemo,
} from 'react';

import {
	getFormattedDate,
	isDateApproachingUpcomingThreshold,
	isDatePast,
} from '../../utils/benefit-plan-service';

import './end-date.styl';

interface EndDateProps {
	active: boolean;
	endDate: string;
}

export function EndDate(props: EndDateProps): JSX.Element {
	const {
		active,
		endDate,
	} = props;

	const formattedEndDate = useMemo(
		() => getFormattedDate(endDate),
		[endDate]
	);

	return (
		<div className={ classNames({
			'Columns__endDate--inactive': (!active || isDatePast(endDate)),
			'Columns__endDate--attention': isDateApproachingUpcomingThreshold(endDate),
		}) }>{ formattedEndDate || '' }</div>
	);
}
