import { getCurrencyPosition, getCurrencySymbol, getFormattedAmountFromNumber } from 'form-fields.react';

import { CostTypeEnumContract, Currency, EmployeeCostTypeValue } from '../../../../../types';
import { CoverageTableRow } from './types';

export const constrainCostTypeValue = (value: string): CostTypeEnumContract => {
	let costType = CostTypeEnumContract.fixed;

	if (value === CostTypeEnumContract.percent) {
		costType = CostTypeEnumContract.percent;
	}

	return costType;
};

export { getFormattedCurrencyOrPercent } from '../../../../../../utils';

export const getCompanyPaysValue = (
	row: CoverageTableRow,
	employeeCostType: EmployeeCostTypeValue,
	currencies: Currency[],
	currencyCode: string
): string => {
	const { totalCost } = row.coverageLevel;

	if (row.employeeCost !== null) {
		if (employeeCostType === CostTypeEnumContract.fixed && totalCost !== null) {
			const currencySymbol = getCurrencySymbol(currencies, currencyCode);
			const currencyPosition = getCurrencyPosition(currencies, currencyCode);

			let companyPays = totalCost - row.employeeCost;
			companyPays = companyPays < 0 ? 0 : companyPays;

			return $.__('%1 per month', getFormattedAmountFromNumber(companyPays, currencySymbol, currencyPosition));
		}
		if (employeeCostType === CostTypeEnumContract.percent) {
			let companyPays = 100 - row.employeeCost;
			companyPays = companyPays < 0 ? 0 : companyPays;
			return $.__('%1%% of total cost', companyPays.toString());
		}
	}

	return '—';
};
