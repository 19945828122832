import Ctrl, {
	on,
	onFooterBtnClick,
} from 'ctrl.deco';

import {
	redirect
} from 'BambooHR.util';

import {
	getJsonData,
} from 'Data.util';

import './add';
import './edit';

import './styles.styl';

let $form = $('#SettingsUsersAddEdit');
let $footerBtn = $('.js-SettingsUsersAddEdit__submitBtn');
const $ssoCheckboxesContainer = $('.js-ssoCheckboxes');

@Ctrl('/settings/users/:method(add|edit)/:userId?')
class SettingsUsersAddEditCtrl {

	get subCtrl() {
		return (
			Ctrl.getCtrl('SettingsUsersAddCtrl') ||
			Ctrl.getCtrl('SettingsUsersEditCtrl')
		);
	}

	get redirectURL() {
		// logic to determine where to redirect goes here
		return '/settings/access_levels/all';
	}

	toggleShowSsoCheckboxes = (show) => {
		if (!$ssoCheckboxesContainer.length) {
			return;
		}

		$ssoCheckboxesContainer.toggle(show);
	}

	toggleCheckSsoCheckboxes = (checked) => {
		if (!$ssoCheckboxesContainer.length) {
			return;
		}

		$ssoCheckboxesContainer.find('input[type="checkbox"]').attr('checked', checked);
	}

	accessLevelIsFullAdmin = (accessLevelId) => {
		const accessLevelType = this.addEditUserData?.accessLevels[accessLevelId]?.type;
		return accessLevelType === 'admin';
	}

	accessLevelIsPayrollAdmin = (accessLevelId) => {
		const accessLevelType = this.addEditUserData?.accessLevels[accessLevelId]?.type;
		return accessLevelType === 'payroll-admin';
	}

	accessLevelIsFullAdminOrPayrollAdmin = (accessLevelId) => {
		return (this.accessLevelIsFullAdmin(accessLevelId) || this.accessLevelIsPayrollAdmin(accessLevelId));
	}

	@on('ba:selectChange', '#SettingsUsersAddEdit')
	handleFormBaSelectChange(event, ctrl) {
		if (ctrl.accessLevelIsFullAdmin(event.originalEvent.detail.value)) {
			ctrl.toggleCheckSsoCheckboxes(false);
			ctrl.toggleShowSsoCheckboxes(true);
		} else if (ctrl.accessLevelIsPayrollAdmin(event.originalEvent.detail.value)) {
			ctrl.toggleCheckSsoCheckboxes(true);
			ctrl.toggleShowSsoCheckboxes(true);
		} else {
			ctrl.toggleCheckSsoCheckboxes(false);
			ctrl.toggleShowSsoCheckboxes(false);
		}
	}

	initializeTraxSsoCheckboxes = () => {
		const {
			isAdd,
			userData,
		} = this.addEditUserData;

		if (isAdd) {
			this.toggleShowSsoCheckboxes(false);
			return;
		}

		if (userData?.userGroups?.items) {
			const accessLevelIds = window.Object.keys(userData.userGroups.items);

			if (accessLevelIds.some(this.accessLevelIsFullAdminOrPayrollAdmin)) {
				this.toggleShowSsoCheckboxes(true);
			} else {
				this.toggleShowSsoCheckboxes(false);
			}
		} else {
			this.toggleShowSsoCheckboxes(false);
		}
	}

	onValidFormSubmit(e, validator) {
		closeMessage();

		let subCtrl = this.subCtrl;

		$.ajax({
			url: window.location.pathname,
			type: subCtrl.submitMethod,
			dataType: 'json',
			data: $form.serialize()
		})
			.done((result) => {
				if (!result.success) {
					setMessage(result.errorMessage, 'error');
					console.error(result.error);
					$footerBtn.toggleProcessing(false);
					return;
				}

				redirect(this.redirectURL, result.message, 'success');
			})
			.fail((xhr, status, error) => {
				setMessage($.__('There was a problem. Please try again or contact support.'), 'error');
				console.error(error);

				$footerBtn.toggleProcessing(false);
			});
	}

	constructor() {
		var ctrl = this;

		this.addEditUserData = getJsonData('.js-addEditUserData');
		this.initializeTraxSsoCheckboxes();

		$form.bhrValidate({
			submitHandler(form, e) {
				var validator = this;

				ctrl.onValidFormSubmit.call(ctrl, [e, validator]);
			}
		});
	}
}
