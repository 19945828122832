import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { render as wrappedRender } from 'base/wrapped-render';
import { isEnabled } from 'FeatureToggle.util';
import { Modal } from 'modal-legacy';
import { Icon, IconV2 } from '@bamboohr/fabric';
import Select from 'select.react';
import Ajax from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const futureSchedulingEnabled = isEnabled('timeTrackingFutureScheduling');

import './styles.styl';

const getAllSchedulesEndpoint = '/time_tracking/schedule_dates/all';
const saveAllSchedulesEndpoint = '/time_tracking/schedule/pay_schedules';
const completeChecklistAndScheduleEndpoint = '/settings/time_tracking/checklist/complete_and_schedule';

const getSchedulesByEmployeeIdEndpoint = '/time_tracking/schedule_dates/employees';
const saveSchedulesByEmployeeIdEndpoint = '/time_tracking/schedule/employees';

export default class ScheduleModal extends Component {
	constructor(props) {
		super(props);
		this.employeeIds = props.employeeIds || [];
		this.state = {
			paySchedules: {
				allIds: [],
				byId: {
					dates: [],
				},
			},
			visible: false,
			isProcessing: false,
		};
	}

	_renderDropdown(id) {
		const { paySchedules } = this.state;
		const schedule = paySchedules.byId[id];

		let items = [];
		const settings = {
			notClearable: true,
		};

		if (futureSchedulingEnabled) {
			settings.width = 5;

			if (schedule.hireDate) {
				const momentHireDate = moment(schedule.hireDate);
				items.push({
					key: 'hire-date',
					text: $.__('Hire Date'),
					type: 'group',
					items: [
						{
							id,
							displayText: momentHireDate.format('ll'),
							value: schedule.hireDate,
						},
					],
				});
			}

			const payScheduleItems = [{
				key: 'pay-periods',
				text: $.__('Next Pay Periods'),
				type: 'group',
				items: schedule.dates.map(date => ({
					id,
					displayText: moment(date).format('ll'),
					value: date,
				})),
			}];

			items = [...items, ...payScheduleItems];

			items.forEach((item) => {
				if (item.items) {
					item.items.forEach((nestedItem) => {
						nestedItem.selected = nestedItem.value === schedule.selected;
					});
				}
				item.selected = item.value === schedule.selected;
			});
		} else {
			items = schedule.dates.map((date) => {
				return {
					id,
					displayText: moment(date).format('ll'),
					value: date,
					selected: date === schedule.selected,
				};
			});
		}

		const options = {
			items,
		};

		return (
			<Select
				data={ options }
				onChange={ this._handleDropdownChange }
				settings={ settings }
			/>
		);
	}

	_handleDropdownChange = (selectedItem) => {
		const { paySchedules } = this.state;
		const { id, value } = selectedItem;
		paySchedules.byId[id].selected = value;
		this.setState({ ...paySchedules });
	};

	_handleSaveModal = () => {
		const { onSave = Function.prototype, onFail = Function.prototype, type } = this.props;
		this.setState({ isProcessing: true });
		let url = type === 'checklist' ? completeChecklistAndScheduleEndpoint : saveAllSchedulesEndpoint;

		const { paySchedules: { allIds, byId } } = this.state;
		const paySchedules = allIds.map((id) => {
			return {
				id,
				date: byId[id].selected,
			};
		});

		let payload = { paySchedules };

		if (this.employeeIds.length) {
			url = saveSchedulesByEmployeeIdEndpoint;
			payload.employeeIds = this.employeeIds;
		}

		if (type !== 'checklist') {
			url = '/time_tracking/employees/reschedule';
			const paySchedulesToStartDates = {};
			allIds.forEach(id => {
				paySchedulesToStartDates[id] = byId[id].selected;
			})
			payload = {
				employeeIds: 'all',
				paySchedules: paySchedulesToStartDates
			}
		}

		Ajax
			.post(url, payload)
			.then(() => {
				onSave(paySchedules);
			})
			.catch((error) => {
				if (futureSchedulingEnabled && error.response.data.status === 'WARNING') {
					onFail(error.response.data);
				} else {
					onFail();
					this.setState({ isProcessing: false });
					window.errorFallBack();
				}
			});
	};

	componentDidMount() {
		const { onRequestComplete, onClose } = this.props;

		let method = 'get';
		let url = getAllSchedulesEndpoint;
		const payload = {};

		if (this.employeeIds.length) {
			method = 'post';
			url = getSchedulesByEmployeeIdEndpoint;
			payload.employeeIds = this.employeeIds;
		}

		Ajax[method](url, payload)
			.then(({ data }) => {
				const { byId, allIds } = data.paySchedules;
				allIds.forEach((id) => {
					byId[id].selected = byId[id].dates[0];
				});

				this.setState({
					paySchedules: data.paySchedules,
					visible: true,
				});
			})
			.catch((error) => {
				if (error.response && error.response.status === 500) {
					// if ajax Request failed...
					window.setMessage(`In order to schedule Time Tracking, you'll need to add at least one employee.`, 'error');
				} else {
					// Something else failed
					window.Rollbar.error(error);
					window.errorFallBack();
				}
				onClose();
			})
			.then(onRequestComplete);
	}

	render() {
		const { onClose, type, isSheet } = this.props;
		const { visible, paySchedules: { allIds, byId }, isProcessing } = this.state;
		let title = $.__('Schedule Time Tracking?');
		let buttonText = $.__('Schedule Time Tracking');

		if (type === 'edit') {
			title = $.__('Change Time Tracking Start Dates?');
			buttonText = $.__('Save Changes');
		}

		const modalOptions = {
			biId: 'settings-time-tracking-setup-schedule-date',
			isOpen: visible,
			onClose,
			primaryAction: this._handleSaveModal,
			primaryActionText: buttonText,
			title,
			isProcessing,
		};

		return (
			<ConditionalWrap
				condition={ !isSheet }
				wrap={ children => <Modal { ...modalOptions }> { children } </Modal> }
			>
				<div className="EnableModal">
					{ type !== 'edit' && (
						<Fragment>
							<span className="EnableModal__clock-success-icon">
								{ifFeature('encore', <IconV2 name="stopwatch-solid" size={48} />, <Icon name="fab-stopwatch-check-57x55" />)}
							</span>
							{ type === 'checklist' && (
								<h3 className="EnableModal__text">{ $.__('You’ve finished all the setup tasks!') }</h3>
							) }
							<p className="EnableModal__header--secondary">{ $.__('When do you want time tracking to start?') }</p>
						</Fragment>
					) }

					{ type === 'edit' && (
						<Fragment>
							<span className="EnableModal__clock-edit-icon">
								{ifFeature('encore', <IconV2 name="stopwatch-solid" size={48} />, <Icon name="fab-calendar-stopwatch-54x54" />)}
							</span>
							<h4 className="EnableModal__text">{ $.__('Change the day you want to start tracking.') }</h4>
							<p className="EnableModal__sub-text">
								{ $.__('This change will effect all employees that are currently scheduled to start time tracking.') }
							</p>
						</Fragment>
					) }

					<div className="EnableModalTable">

						<div className="EnableModalTable__row">
							<div className="EnableModalTable__column1 EnableModalTable__columnHeader">
								{ $.__('Pay Schedule') }
							</div>
							<div className="EnableModalTable__column2 EnableModalTable__columnHeader">
								{ $.__('Start Date') }
							</div>
						</div>

						{ allIds.map((id) => {
							const schedule = byId[id];
							return (
								<div key={ id } className="EnableModalTable__row">
									<div key={ id } className="EnableModalTable__column1 EnableModalTable__boldSchedule">
										{ schedule.name }
										{ futureSchedulingEnabled && <div className="EnableModalTable__employeeCount">{ $.__n('%1 employee', '%1 employees', schedule.employeeCount) }</div> }
									</div>
									<div className="EnableModalTable__column2 EnableModalTable__dropdown">
										{ this._renderDropdown(id) }
									</div>
								</div>
							);
						}) }
					</div>

				</div>
			</ConditionalWrap>
		);
	}
}


export function nonReactRender(type = 'add', onRequestComplete, onSave, onClose = Function.prototype, eids = [], onFail = Function.prototype) {
	const root = document.createElement('div');

	const unmount = () => {
		ReactDOM.unmountComponentAtNode(root);
	};

	const onInternalClose = (...args) => {
		onClose(...args);
		unmount();
	};

	const onInternalSave = (...args) => {
		onSave(...args);
		unmount();
	};

	wrappedRender(
		<ScheduleModal
			employeeIds={ eids }
			onClose={ onInternalClose }
			onFail={ (errorResponse) => { onInternalClose(); onFail(errorResponse); } }
			onRequestComplete={ onRequestComplete }
			onSave={ onInternalSave }
			type={ type }
		/>,
		root
	);
}

function ConditionalWrap({ condition, wrap, children }) {
	return condition ? wrap(children) : children;
}
