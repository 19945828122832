import { Headline, LayoutBox, List } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { Button } from '@fabric/button';
import { InPageMessaging } from '@fabric/in-page-messaging';
import Upsell from 'Upsell';
import { EmbedVideo } from '../shared/embed-video';
import { EnpsCopyright } from '../shared/enps-copyright';
import { useStyles } from './styles';

export function MarketingPromo(props) {
	const { isDemoScheduled, onContactBtnPress, isProcessing } = props;
	const buttonLabel = $.__('Contact Us To Learn More');
	const classes = useStyles();

	return ifFeature(
		'encore',
		<Upsell>
			<Upsell.Title>{$.__("It looks like Employee Satisfaction with eNPS® isn't part of your current package.")}</Upsell.Title>
			<Upsell.Video title={$.__('Promo Video')} videoId='g03otpk2nm' />
			<Upsell.Content
				actions={[
					<Button
						data-bi-id='settings-performance-request-pro-demo-button'
						noSpacing={true}
						disabled={isDemoScheduled}
						onClick={onContactBtnPress}
						processing={isProcessing}
						size='large'
						type='button'
						width='100'
					>
						{$.__('Schedule a Personalized Demo')}
					</Button>,
				]}
				request={{
					demoRequestedBy: '',
					demoRequestedDate: '',
					isDemoRequested: !!isDemoScheduled,
				}}
			>
				<Headline size='small'>{$.__('With eNPS®, you can:')}</Headline>
				<LayoutBox marginTop={2}>
					<List>
						<List.Item>
							<Message
								text={$.__(
									'**Avoid increased turnover** in an already tight labor market by knowing whether or not your employees are satisfied — and why.'
								)}
							/>
						</List.Item>
						<List.Item>
							<Message
								text={$.__(
									'**Find out how your employees really feel** and what you can do to improve their experience with a simple, anonymous, two-question survey and powerful trend reporting'
								)}
							/>
						</List.Item>
					</List>
				</LayoutBox>
			</Upsell.Content>
		</Upsell>,
		<div className={classes.marketingPromo}>
			<div className={classes.marketingPromoContainer}>
				<div className={classes.marketingPromoTitle}>
					{$.__(`It looks like Employee Satisfaction with eNPS® isn't part of your current package.`)}
				</div>

				<div className={classes.marketingPromoVideo}>
					<EmbedVideo videoSrc='//fast.wistia.net/embed/iframe/g03otpk2nm?videoFoam=true' videoTitle={$.__('Promo Video')} />
				</div>
				{isDemoScheduled ? (
					<div className={classes.marketingPromoContactMessage}>
						<InPageMessaging
							body={$.__('We will be contacting you shortly to schedule a personal demo.')}
							header={$.__(`Thanks for your interest in BambooHR's Employee Satisfaction with eNPS®`)}
							icon='fab-check-circle-36x36'
							success={true}
						/>
					</div>
				) : (
					<div className={classes.marketingPromoButton}>
						<Button clickAction={onContactBtnPress} size='biggie' text={buttonLabel} type='button' />
					</div>
				)}
				<div className={classes.marketingPromoSummary}>
					{$.__(
						'Knowing whether or not your employees are satisfied—and knowing why—could help you avoid increased turnover in an already tight labor market. Employee Satisfaction with eNPS® combines a simple, anonymous, two-question survey with powerful trend reporting to help you find out how your employees really feel and what you can do to improve their experience.'
					)}
				</div>

				<div className={classes.marketingPromoCopyright}>
					<EnpsCopyright />
				</div>
			</div>
		</div>
	);
}
