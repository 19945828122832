import { useContext } from 'react';
import { BodyText, IconV2, LayoutBox, makeStyles, TextButton } from '@bamboohr/fabric';
import { Eye16x12, Pencil16x16 } from '@bamboohr/grim';
import { SurveyStatus } from '../../utils/';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ palette, typography, borderRadiuses, constructs, spacing }) => ({
	root: {
		border: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`, `1px solid ${palette.gray[300]}`),
		borderRadius: ifFeature('encore', borderRadiuses[400], '2px'),
		boxShadow: ifFeature('encore', undefined, '0 2px 3px rgba(0, 0, 0, 0.1)'),
		height: '100%',
		marginLeft: ifFeature('encore', undefined, '24px'),
		width: ifFeature('encore', '100%'),
	},
	header: {
		backgroundColor: ifFeature('encore', undefined, palette.gray[300]),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: ifFeature('encore', spacing(2, 3), '0 16px 0 24px'),
		fontSize: ifFeature('encore', undefined, '16px'),
		lineHeight: ifFeature('encore', undefined, '24px'),
		fontWeight: ifFeature('encore', undefined, 500),
		height: ifFeature('encore', undefined, 43),
		borderBottom: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`),
	},
	questions: {
		padding: ifFeature('encore', spacing(2), '16px 12px 4px'),
	},
	question: {
		marginBottom: '12px',

		'& span': {
			fontSize: ifFeature('encore', undefined, '13px'),
			lineHeight: ifFeature('encore', undefined, '19px'),
			color: ifFeature('encore', undefined, palette.gray[800]),
			padding: ifFeature('encore', undefined, '0 12px'),
		},
	},
	questionButton: {
		padding: '1px 12px 5px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: ifFeature('encore', borderRadiuses[200], '2px'),
		border: 'none',
		backgroundColor: 'unset',
		width: '100%',
		textAlign: ifFeature('encore', 'left'),
		'&:hover': {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[100]),
			cursor: 'pointer',
			'& $questionEditIcon': {
				opacity: 1,
			},
		},
	},
	/* @startCleanup encore */
	questionText: {
		textAlign: 'left',
		fontWeight: typography.fontWeightRegular,
		color: palette.gray[1000],
		fontFamily: typography.fontFamily,
		...typography.small,
	},
	questionEditIcon: {
		opacity: 0,
		display: ifFeature('encore', 'flex'),
		justifyContent: ifFeature('encore', 'center'),
		fill: ifFeature('encore', undefined, palette.gray[800]),
		margin: ifFeature('encore', undefined, 5),
		flexShrink: ifFeature('encore', undefined, 0),
	},
	/* @endCleanup encore */
}));

interface UpcomingSurveyProps {
	scheduledDate: string;
	questions: Array<{ topicId: number; topic: string; question: string }>;
	canEdit: boolean;
	editQuestion: (topic: string, topicId: number, surveyId: number) => void;
	surveyId: number;
}

export const UpcomingSurvey = ({ scheduledDate, questions, canEdit, editQuestion, surveyId }: UpcomingSurveyProps) => {
	const classes = useStyles();

	const { showPreview } = useContext(SurveyStatus);

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				{ifFeature(
					'encore',
					<BodyText color='primary' size='large' weight='bold'>
						{scheduledDate}
					</BodyText>,
					<span>{scheduledDate}</span>
				)}
				<TextButton
					onClick={() => showPreview(surveyId)}
					size={ifFeature('encore', 'extra-small')}
					startIcon={ifFeature('encore', 'eye-regular', <Eye16x12 />)}
					type='button'
				>
					{$.__('Preview')}
				</TextButton>
			</div>
			<div className={classes.questions}>
				{questions.map((question) => (
					<div className={classes.question} key={question.topicId}>
						{ifFeature(
							'encore',
							<LayoutBox paddingLeft={1.5}>
								<BodyText size='extra-small' color='neutral-weak'>
									{question.topic}
								</BodyText>
							</LayoutBox>,
							<span>{question.topic}</span>
						)}
						<button
							className={classes.questionButton}
							onClick={() => canEdit && editQuestion(question.topic, question.topicId, surveyId)}
							type='button'
						>
							{ifFeature(
								'encore',
								<BodyText component='span' size='small'>
									{question.question}
								</BodyText>,
								<p className={classes.questionText}>{question.question}</p>
							)}
							{canEdit &&
								ifFeature(
									'encore',
									<div className={classes.questionEditIcon}>
										<IconV2 color='neutral-extra-strong' name='pen-solid' size={16} />
									</div>,
									<Pencil16x16 className={classes.questionEditIcon} />
								)}
						</button>
					</div>
				))}
			</div>
		</div>
	);
};
