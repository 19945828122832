import { render } from 'base/wrapped-render';
import { TimeTrackingUpsell } from './components';
import './styles.styl';

let jsonData;

export default () => {
	const rootElem = document.querySelector('.TimeTrackingUpsell');
	jsonData = JSON.parse($('#js-time-tracking-upsell-data').html());

	const { demoRequest, scheduleDemo } = jsonData;

	try {
		render(<TimeTrackingUpsell companyInTrial={ !scheduleDemo } demoRequest={ demoRequest } />, rootElem);
	} catch (error) {
		window.setMessage($.__('Oops! Looks like we had some trouble submitting your request. Refresh the page and try again!'), 'error');
	} 
};
