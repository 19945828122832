import {
	Flex,
	Headline,
	IconButton,
	Popover,
	type PopoverProps,
} from '@bamboohr/fabric';
import { type ReactNode, useRef, useState } from 'react';

type SectionStyledHeaderProps = {
	children: ReactNode;
	hasPopover: false;
	headlineSize?: undefined;
	popoverContent: never;
	popoverIcon: never;
	popoverPlacement: never;
	popoverTitle: never;
} | {
	children: ReactNode;
	hasPopover: true;
	headlineSize?: 'medium' | 'small';
	popoverContent: ReactNode;
	popoverIcon: string;
	popoverPlacement?: PopoverProps['placement'];
	popoverTitle: string;
}

export function SectionStyledHeader(props: SectionStyledHeaderProps) {
	const {
		children,
		hasPopover = false,
		headlineSize = 'medium',
		popoverContent = null,
		popoverIcon = 'circle-question-regular',
		popoverPlacement = 'bottom',
		popoverTitle,
	} = props;

	const helpPopoverRef = useRef();
	const [showHelpPopover, setShowHelpPopover] = useState(false);

	return (
		<Headline color="primary" size={headlineSize}>
			<Flex alignItems="center" flexDirection="row">
				{children}
				{hasPopover ? (
					<Flex paddingLeft={1} ref={helpPopoverRef}>
						<IconButton
							// className here is necessary as this class is looked for to prevent the sticky footer from showing when it shouldn't
							className="js-no-sticky-footer"
							color="secondary"
							icon={popoverIcon}
							noBoundingBox={true}
							onClick={(event) => {
								event.preventDefault();
								setShowHelpPopover(true);
							}}
							type="button"
						/>
						<Popover
							anchorEl={helpPopoverRef.current}
							onClose={() => {
								setShowHelpPopover(false);
							}}
							open={showHelpPopover}
							placement={popoverPlacement}
							title={popoverTitle}
						>
							{popoverContent}
						</Popover>
					</Flex>
				) : null}
			</Flex>
		</Headline>
	);
}
