import Ctrl, { onFooterAction } from 'ctrl.deco';
import { disabledForPermsTest } from 'permissions.mod';

import './styles.styl';

const FORM_SELECTOR = '.js-SettingsUsersAccountSettings__form';

@Ctrl('/settings/users/account_settings')
class SettingsUsersAccountSettingsCtrl {

	get $form() {
		return $(FORM_SELECTOR);
	}

	@onFooterAction('disabledForPermissionsTest')
	_disabledForPermissionsTest() {
		disabledForPermsTest();
	}

}
