import { map, sortBy } from 'lodash';

import { ajax } from '@utils/ajax';

import {
	BASE_URL_AJAX,
	BASE_URL_WORKFLOWS,
	DISABLE_APPROVAL,
	ENABLE_APPROVAL
} from './constants';

export const changeApprovalPathType = (pathData) => {
	return ajax.request({
		data: pathData,
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/changeApprovalPathType`
	});
};

export const deleteApprovalModal = (workflowId) => {
	return ajax.request({
		data: { workflowId },
		method: 'get',
		url: `${ BASE_URL_AJAX }/delete_modal`
	});
};

export const deleteApproval = (workflowId) => {
	return ajax.request({
		data: { workflowId },
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/custom/delete_approval`
	});
};

export const disableApprovalModal = () => {
	return ajax.request({
		method: 'get',
		url: `${ BASE_URL_AJAX }/disable_modal`
	});
};

export const disableApproval = (workflowId) => {
	return ajax.request({
		data: {
			workflowId
		},
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/custom/disable_approval`
	});
};

export const deleteApprovalPath = (id) => {
	return ajax.request({
		method: 'post',
		params: { workflowId: id },
		url: `${ BASE_URL_WORKFLOWS }/deleteChildPath`
	}).then(
		({ data }) => {
			return data;
		}
	);
};

export const enableApproval = (workflowId) => {
	return ajax.request({
		data: {
			workflowId
		},
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/custom/enable_approval`
	});
};

export const getWorkflowData = (id) => {
	return ajax.all(
		[
			ajax.request({
				method: 'get',
				params: { workflowId: id },
				url: `${ BASE_URL_WORKFLOWS }/getAdvancedApprovalDetails`
			}),
			ajax.request({
				method: 'get',
				params: { workflowId: id },
				url: `${ BASE_URL_WORKFLOWS }/getAdvancedApprovalOptionData`
			})
		]
	).then(ajax.spread(
		([
			{ data: workflowData },
			{ data: workflowOptionData }
		]) => {
			const {
				children: pathTypeChildren,
				default: workflow,
				pathTypeOptions
			} = workflowData;

			let {
				approverOptions,
				availableUsers,
				requestOptions,
				userGroups
			} = workflowOptionData;
			approverOptions = map(approverOptions, (roleName, shortName) => {
				return {
					id: shortName,
					name: roleName
				};
			});
			availableUsers = map(availableUsers, (personName, id) => {
				const numberId = Number(id);
				return {
					id: numberId,
					name: personName
				};
			}).filter((availablePerson) => {
				return availablePerson.id !== '';
			});
			requestOptions = map(requestOptions, (roleName, shortName) => {
				return {
					id: shortName,
					name: roleName
				};
			});
			userGroups = map(userGroups, (groupName, id) => {
				return {
					id,
					name: groupName
				};
			});

			let {
				advancedApprovalToolEnabled,
				id: workflowId,
				pathType
			} = workflow;

			workflowId = Number(workflowId);
			workflow.advancedWorkflow = advancedApprovalToolEnabled && !!pathType;
			workflow.curAppStatus = (
				workflow.active ? {
					label: $.__('Disable Approval'),
					status: DISABLE_APPROVAL
				} : {
					label: $.__('Enable Approval'),
					status: ENABLE_APPROVAL
				}
			);
			workflow.workflowApprovals = sortBy(workflow.workflowApprovals, 'position');

			let allPathOptions = {};
			let	availablePathOptions = {};

			pathTypeOptions.map((option) => {
				allPathOptions[option.id] = {
					text: option.name,
					value: parseInt(option.id)
				};

				if (option.used === false) {
					availablePathOptions[option.id] = {
						displayText: option.name,
						text: option.name,
						value: parseInt(option.id)
					};
				}
			});

			const {
				id: currentWorkflowId,
				workflowApprovals,
				workflowInitiators = []
			} = workflow;

			const levels = sortBy(workflowApprovals, 'position').map((approval, index) => {
				return {
					approval,
					workflowLevel: index + 1
				};
			});

			return {
				approverOptions,
				allParsedOptions: allPathOptions,
				availablePathOptions,
				availableUsers,
				editState: !workflow.advancedWorkflow || pathTypeChildren.length === 0,
				editingWorkflow: workflow.advancedWorkflow ? false : {
					customApprovers: workflowInitiators,
					id: currentWorkflowId,
					levels: levels,
					parentId: currentWorkflowId,
					workflowApprovals,
					workflowInitiators,
					workflowPaths: []
				},
				newPathOpen: pathTypeChildren.length === 0,
				pathTypeChildren,
				pathTypeOptions,
				requestOptions,
				userGroups,
				workflow,
				workflowId: workflowId
			};
		}));
};

export const getWorkflowsSubNav = () => {
	return ajax.request({
		method: 'get',
		url: `${ BASE_URL_WORKFLOWS }/getApprovalsLeftNav`
	});
};

export const previewEditApprovalModal = (workflow_id) => {
	let fields = [];

	$('#selected li.field').each(function() {
		let field = {};
		field.field_id = $(this).attr('data-id');
		field.required = $(this).find('.reqToggle').is(':checked');
		fields.push(field);
	});

	const formData = ajax.prepareData(
		{
			fields,
			workflow_id
		},
		ajax.prepareDataTypes.FORM_DATA
	);

	return ajax.post(`${ BASE_URL_AJAX }/preview_custom.php`, formData);
};


export const previewApprovalModal = (workflow_id) => {
	return ajax.request({
		method: 'get',
		params: { workflow_id },
		url: `${ BASE_URL_AJAX }/preview_custom.php`
	});
};

export const removeAdvancedApproval = (workflowId) => {
	return ajax.request({
		data: { workflowId },
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/removeAdvancedApproval`
	});
};

export const restoreApprovalModal = () => {
	return ajax.request({
		method: 'get',
		url: `${ BASE_URL_AJAX }/restore_modal`
	});
};

export const restoreApproval = (templateName) => {
	return ajax.request({
		data: {
			templateName
		},
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/custom/restore_template`
	});
};

export const submitApproval = (workflowId, data) => {
	return ajax.request({
		data,
		method: 'post',
		url: `${ BASE_URL_WORKFLOWS }/?workflow_id=${ workflowId }`
	});
};
