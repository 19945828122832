import { BasicLife } from './life-sub-types/basic-life';
import { BasicAdd } from './life-sub-types/basic-add';
import { BasicLifeAdd } from './life-sub-types/basic-life-add';
import { VoluntaryLife } from './life-sub-types/voluntary-life';
import { VoluntaryAdd } from './life-sub-types/voluntary-add';
import { VoluntaryLifeAdd } from './life-sub-types/voluntary-life-add';
import { Unknown } from './life-sub-types/unknown';

import { BasicLifeOrADandDEnum, LifeCategoryDataOptionsContract, LifeSubTypeLike, VoluntaryLifeOrADandDEnum } from '../types';

const lifeSubTypes = {
	[BasicLifeOrADandDEnum.life]: BasicLife,
	[BasicLifeOrADandDEnum.add]: BasicAdd,
	[BasicLifeOrADandDEnum.both]: BasicLifeAdd,
	[VoluntaryLifeOrADandDEnum.life]: VoluntaryLife,
	[VoluntaryLifeOrADandDEnum.add]: VoluntaryAdd,
	[VoluntaryLifeOrADandDEnum.both]: VoluntaryLifeAdd,
};

export const makeLifeSubTypeLike = (planCategoryDataOptions: LifeCategoryDataOptionsContract): LifeSubTypeLike => {
	return lifeSubTypes[planCategoryDataOptions?.type || planCategoryDataOptions?.lifeInsuranceType] || Unknown;
};
