import {
	federalW2Box12DdMinimumShowCount,
} from '../constants';
import type {
	ClientTaxSettingSuppressW2Box12Dd,
} from '../types';

export function serializeMutateW2Box12DdChoice(w2Box12Dd: ClientTaxSettingSuppressW2Box12Dd) {
	if (!w2Box12Dd || !w2Box12Dd.metadata || !w2Box12Dd.metadata.employeeCount) {
		return w2Box12Dd;
	}
	if (w2Box12Dd.metadata.employeeCount >= federalW2Box12DdMinimumShowCount) {
		w2Box12Dd.value = false;
	}
	return w2Box12Dd;
}
