import {
	Fragment,
	FunctionComponent,
	useState,
} from 'react';

import { Icon, Table } from '@bamboohr/fabric';

import { EmploymentStatusModal } from 'employment-status-modal.react';
import {
	DeleteFixItModal,
	DeleteModal,
} from '../../../modals';
import { PeopleCount } from './people-count';
import {
	generateColumns,
	getAcaStatusLabel,
	getEditValues,
	getShowChangeHistory,
	getTotalHistoryCount,
} from './utils';
import {
	MODAL_TYPES,
} from './constants';
import { ListValue } from '../types';
import { EmploymentStatusTableRow } from './types';


interface EmploymentStatusTableProps {
	acaStatuses: string[];
	listId: number;
	listValues: ListValue[];
	showAcaStatus: boolean;
	showPeople: boolean;
}

export const EmploymentStatusTable: FunctionComponent<EmploymentStatusTableProps> = (props) => {
	const {
		acaStatuses,
		listId,
		listValues,
		showAcaStatus,
		showPeople,
	} = props;

	const [currentModal, setCurrentModal] = useState(MODAL_TYPES.None);
	const [selectedStatusId, setSelectedStatusId] = useState(0);
	const [historyCount, setHistoryCount] = useState(0);
	const [activeCount, setActiveCount] = useState(0);

	const selectedItem: ListValue | undefined = listValues
		.find(listValue => listValue.id === selectedStatusId);

	const handleEditClick = (row: EmploymentStatusTableRow): void => {
		setSelectedStatusId(row.id);

		getTotalHistoryCount(row.id, listId)
			.then((response) => {
				setHistoryCount(response.data.totalHistoryCount);
			})
			.catch(() => {
				// @ts-ignore
				window.errorFallBack();
			});

		setCurrentModal(MODAL_TYPES.Edit);
	};

	const handleDeleteClick = (row: EmploymentStatusTableRow): void => {
		setSelectedStatusId(row.id);

		getTotalHistoryCount(row.id, listId)
			.then((response) => {
				const { data } = response;

				if (data.totalHistoryCount === 'ERROR') {
					return;
				}

				if (data.activeCount > 0) {
					setActiveCount(data.activeCount);
					setCurrentModal(MODAL_TYPES.DeleteFixIt);
				} else {
					setHistoryCount(data.totalHistoryCount);
					setCurrentModal(MODAL_TYPES.Delete);
				}
			})
			.catch(() => {
				// @ts-ignore
				window.errorFallBack();
			});
	};

	const handleClose = (): void => {
		setCurrentModal(MODAL_TYPES.None);
	};

	const generateRows = (): EmploymentStatusTableRow[] => {
		const data = [];
		listValues.forEach((listValue) => {
			const row = {
				id: listValue.id,
				isEditable: listValue.manageable === 'yes',
				isDeletable: listValue.manageable === 'yes' && listValue.archived === 'no',
				status: listValue.label,
				acaStatus: getAcaStatusLabel(listValue.aca_status),
				fte: listValue.FTEValue ? window.formatNumberJS(Number(listValue.FTEValue), 2) : '',
				peopleCount: null,
				people: null,
			};

			if (showPeople) {
				row.peopleCount = listValue.type === 'terminated' ? listValue.inactiveCount : listValue.count;
				row.people = (
					<PeopleCount
						count={ listValue.count }
						inactiveCount={ listValue.inactiveCount }
						listId={ listValue.list_id }
						statusId={ listValue.id }
						type={ listValue.type }
						title={listValue.label}
					/>
				);
			}

			data.push(row);
		});

		return data;
	};


	return (
		<Fragment>
			{ listValues && listValues.length > 0 ? (
				<Fragment>
					<Table
						caption={ $.__('Employment Statuses') }
						columns={ generateColumns(
							showAcaStatus,
							showPeople,
							handleEditClick,
							handleDeleteClick
						) }
						initialSort={ {
							columnIndex: 0,
							isAsc: true,
						} }
						rowKey={ (row: EmploymentStatusTableRow) => row.id }
						rowMutedBy={ (row: EmploymentStatusTableRow) => !row.isEditable }
						rows={ generateRows() }
					/>
					<EmploymentStatusModal
						acaStatuses={ acaStatuses }
						closeModal={ handleClose }
						initialValues={ getEditValues(selectedItem) }
						isEdit={ true }
						isOpen={ currentModal === MODAL_TYPES.Edit }
						listId={ listId }
						listValueId={ selectedStatusId }
						showAcaStatus={ showAcaStatus }
						showChangeHistory={ getShowChangeHistory(historyCount) }
					/>
					<DeleteFixItModal
						activeCount={ activeCount }
						closeModal={ handleClose }
						fieldTitle={ $.__('Employment Status') }
						isOpen={ currentModal === MODAL_TYPES.DeleteFixIt }
					/>
					{ selectedItem && (
						<DeleteModal
							closeModal={ handleClose }
							fieldTitle={ $.__('Employment Status') }
							isOpen={ currentModal === MODAL_TYPES.Delete }
							label={ selectedItem.label }
							listId={ listId }
							listValueId={ selectedItem.id }
							totalHistoryCount={ historyCount }
						/>
					) }
				</Fragment>
			) : (
				<div className="blankState">
					<div className="blankState__svgImage">
						<Icon name="fab-person-index-card-120x92" />
					</div>
					<p>
						{ $.__('Add an Employment Status') }
					</p>
				</div>
			) }
		</Fragment>
	);
};
