import { Fragment, useEffect } from 'react';

import { Message, InternalLink } from '@bamboohr/utils/lib/message';

import { getParameterByName } from 'BambooHR.util';

import { InputField } from './input-field';

import c from 'classnames';

export function TaxRateField(props) {
	const {
		editable,
		error,
		isEdit,
		name,
		selectedTaxType,
		updateFormData,
		value,
	} = props;

	let minTaxRate;
	let maxTaxRate;
	let formNote;

	useEffect(() => {
		if (selectedTaxType) {
			if (selectedTaxType.isCalculated) {
				updateFormData(name, { disabled: true, error: false, placeholder: 'Automatically calculated per employee', value: null, width: 9 });
			}

			if (selectedTaxType.taxRate) {
				updateFormData(name, { disabled: false, error: false, placeholder: null, value: value || selectedTaxType.taxRate, width: 3 });
			}
		} else if (!selectedTaxType) {
			updateFormData(name, { disabled: false, placeholder: null, value: null, width: 3 });
		}
	}, [selectedTaxType]);

	useEffect(() => {
		if (isEdit && !editable) {
			updateFormData(name, { disabled: true });
		}
	}, [isEdit, editable]);

	if (selectedTaxType) {
		minTaxRate = selectedTaxType.minTaxRate;
		maxTaxRate = selectedTaxType.maxTaxRate;
	}

	if (minTaxRate && maxTaxRate) {
		formNote = $.__('Rate must be between %1$s%2$s and %3$s%4$s', minTaxRate, '%', maxTaxRate, '%');
	} else if (minTaxRate) {
		formNote = $.__('Rate must be above %1$s%2$s', minTaxRate, '%');
	} else if (maxTaxRate) {
		formNote = $.__('Rate must be below %1$s%2$s', maxTaxRate, '%');
	}

	if (isEdit && !editable && !selectedTaxType.isCalculated) {
		formNote = (
			<Message
				link={ InternalLink(`/settings/payroll/taxes/tax_type/add_tax_rate?clientId=${ getParameterByName('clientId') }&taxTypeName=${ getParameterByName('taxTypeName') }&clientTaxTypeIncludeId=${ getParameterByName('clientTaxTypeIncludeId') }`) }
				text={ $._(`You can’t edit the original tax rate. [Add a new rate here.]`) }
			/>
		);
	}

	const formNoteClasses = c(
		'fab-FormNote',
		{ 'fab-FormNote--error': error }
	);

	return (
		<Fragment>
			<InputField { ...props } />
			{ formNote && (
				<span className={ formNoteClasses }>
					{ formNote }
				</span>
			) }
		</Fragment>
	);
}
