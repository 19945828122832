import { ReactElement } from 'react';
import { SelectField, TextField, IconButton, Tooltip } from '@bamboohr/fabric';
import { Question14x14 } from '@bamboohr/grim';
import { SmsMfaSetupProps } from './types';
import { useStyles } from './styles';
import { getSmsCountries } from './get-sms-countries/get-sms-countries';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function SmsInputContainer(props: SmsMfaSetupProps): ReactElement {
	const { phoneNumber, updatePhoneNumber, countryCode, updateCountryCode, countries } = props;
	const styles = useStyles();

	const tooltipNote = $.__('SMS verification not available in all countries')

	// @startCleanup encore
	const getLabel = () => (  
		<>
			<div className={styles.label}>{$.__('Country Code')}</div>
			<Tooltip content={tooltipNote}>
				<IconButton
					ariaLabel='sms-country-code'
					color='secondary'
					data-bi-id='sms-country-code'
					floatingIcon={true}
					icon={<Question14x14 />}
					noBoundingBox={true}
					type='button'
				/>
			</Tooltip>
		</>
	);
	// @endCleanup encore

	return (
		<>
			<SelectField
				id='smsMfaSelect'
				isClearable={false}
				items={getSmsCountries(countries)}
				label={ifFeature('encore', $.__('Country Code'), getLabel())}
				name='smsMfaSelect'
				note={ifFeature('encore', tooltipNote)}
				onSelect={(value: string): void => {
					updateCountryCode(value);
				}}
				placeholder={$.__('-Select-')}
				required={true}
				showSearch='always'
				value={countryCode}
				width={7}
			/>
			<TextField
				id='smsNumberField'
				label={$.__('Phone Number')}
				onChange={(e): void => {
					updatePhoneNumber(e.target.value);
				}}
				required={true}
				value={phoneNumber}
				width={ifFeature('encore', 7, 6)}
			/>
		</>
	);
}
