import { sortBy } from 'lodash';
import { connect } from 'react-redux';

import {
	editWorkflow,
	submitPathForm,
	updateEditingWorkflowCustomApprovers,
	updateEditingWorkflowLevels
} from '../store/actions';
import { ApprovalForm } from '../components/approval-form';

const mapStateToProps = (state, ownProps) => {
	const {
		approverOptions,
		availableUsers,
		editState,
		editingWorkflow,
		editingWorkflowId,
		newPathSelectedOptions,
		pathTypeChildren,
		processing,
		requestOptions,
		userGroups,
		workflow,
		workflowId,
	} = state;

	const {
		active,
		advancedWorkflow,
		pathType,
		roleSectionText,
		roleSectionTitle,
		type,
		workflowApprovals,
		workflowInitiators = []
	} = (workflow || {});

	let customApprovers = workflowInitiators;

	const {
		id
	} = ownProps;

	let pathWorkflowApprovals = workflowApprovals;
	let pathWorkflowInitiators = workflowInitiators;
	let selectedPaths = [];

	if (parseInt(id) !== parseInt(workflowId)) {

		const child = pathTypeChildren.find((ele) => { return parseInt(ele.id) === parseInt(id) });

		if (child !== undefined) {
			const {
				workflowApprovals,
				workflowInitiators
			} = child;

			pathWorkflowApprovals = workflowApprovals;
			pathWorkflowInitiators = workflowInitiators;
		}
	}

	if (editingWorkflow) {
		const {
			workflowPaths,
			customApprovers: approvers,
			workflowApprovals,
			workflowInitiators
		} = editingWorkflow;

		customApprovers = approvers;
		selectedPaths = workflowPaths;
		pathWorkflowApprovals = workflowApprovals;
		pathWorkflowInitiators = workflowInitiators;
	}

	pathWorkflowApprovals = sortBy(pathWorkflowApprovals, 'position');

	return {
		active,
		advancedApprovalPath: advancedWorkflow,
		approverOptions,
		availableUsers,
		customApprovers,
		editState,
		editingWorkflow,
		editingWorkflowId,
		newPathSelectedOptions,
		parentId: workflowId,
		pathType: pathType,
		processing,
		requestOptions,
		roleSectionText,
		roleSectionTitle,
		selectedPathIds: selectedPaths,
		type,
		userGroups,
		workflowApprovals: pathWorkflowApprovals,
		workflowInitiators: pathWorkflowInitiators
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleCancelEdit: (workflowId) => { dispatch(editWorkflow(workflowId)); },
		handleSave: () => { dispatch(submitPathForm(false)); },
		updateCustomApprovers: (approvers) => { dispatch(updateEditingWorkflowCustomApprovers(approvers)); },
		updateLevels: (levels) => { dispatch(updateEditingWorkflowLevels(levels)); }
	};
};

export const ApprovalFormContainer = connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
