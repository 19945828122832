import { Fragment } from 'react';
import { Button, Headline, Icon, IconV2, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from "@bamboohr/utils/lib/feature";

import OfferTemplatesTable from './table';
import BlankState from '../blankState/';

import './styles.styl';

const OfferTemplates = (props) => {
	const {
		templates
	} = props;

	return (
		<Fragment>
			<div className="OfferTemplatesHeader">
				{ ifFeature('encore',
					<LayoutBox marginTop={'24px'} marginBottom={'16px'}>
						<Headline color={'primary'} size="small">
							{ $.__('Offer Templates') }
						</Headline>
					</LayoutBox>,
					<h4>
						{ $.__('Offer Templates') }
					</h4>
				)}
				<p>Offer templates help bridge the gap between the applicant tracking system and employee self-onboarding</p>
				{ ifFeature('encore',
					<LayoutBox marginTop={'24px'} marginBottom={'16px'}>
						<Button
							color="secondary"
							onClick={() => {
								window.location.href = '/settings/jobs/templates.php/editOfferTemplate';
							}}
							size="medium"
							startIcon={<IconV2 name='circle-plus-regular' size='16' color='primary-strong' />}
							type="button"
						>
							{ $.__('New Template') }
						</Button>
					</LayoutBox>,
					<a
						className="fab-Button fab-Button--outline fab-Button--small emailTemplatesHeader__action"
						href="/settings/jobs/templates.php/editOfferTemplate"
					>
						<span className="fab-Button__icon fab-Button__icon--left">
							<Icon name="fab-circle-plus-14x14" />
						</span>
						{ $.__('New Template') }
					</a>
				)}
			</div>
			{
				templates.length
					? <OfferTemplatesTable { ...props } />
					: <BlankState />
			}
		</Fragment>
	);
};

export default OfferTemplates;
