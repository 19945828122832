import { Divider } from '@bamboohr/fabric';

export function SectionDivider() {
	return (
		<Divider
			color='neutral-extra-weak'
			/* @startCleanup encore */
			light
			/* @endCleanup encore */
		/>
	);
}
