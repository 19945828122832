import { Fragment, useState, useEffect } from 'react';

import { canUseLocalStorage } from '@utils/localstorage';

import { CompaniesTable } from '../../../components/companies-table';
import { InfoCardManager } from '../../../components/info-card-manager';
import { CompanyInfoHeader } from '../../../components/company-info-header';
import { DownloadFormsModal } from '../../../components/download-forms-modal';
import { UploadFormsModal } from '../../../components/upload-forms-modal';

import {
	getAppStateFromData,
	reloadCurrentPage,
	postUploadFiles,
	uuidGenerator,
	COMPANY_SUBMISSION_MESSAGE_KEY,
	COMPANY_SUBMISSION_MESSAGE,
} from '../../../utils/shared';

import { DEFAULT_ERROR_MESSAGE } from '../../../../bank-info.react/utils/shared';
import { LayoutBox } from '@bamboohr/fabric';

export function CompaniesTableStateComponent(props) {
	const { addedEinsSuggestedNames, companies, isProcessing, onFileDownload } = props;

	const [selectedCompany, setSelectedCompany] = useState({});
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

	useEffect(() => {
		if (canUseLocalStorage) {
			const showMessage = localStorage.getItem(COMPANY_SUBMISSION_MESSAGE_KEY);
			const message = localStorage.getItem(COMPANY_SUBMISSION_MESSAGE);
			if (showMessage && message) {
				window.requestAnimationFrame(() => {
					window.requestAnimationFrame(() => {
						window.setMessage(message, 'success');
						localStorage.removeItem(COMPANY_SUBMISSION_MESSAGE_KEY);
						localStorage.removeItem(COMPANY_SUBMISSION_MESSAGE);
					});
				});
			}
		}
	}, []);

	const popDownloadFormsModal = (data) => {
		setSelectedCompany(data);
		setIsDownloadModalOpen(true);
	};

	const popUploadFormsModal = (data) => {
		setSelectedCompany(data);
		setIsUploadModalOpen(true);
	};

	const closeDownloadModal = () => {
		setIsDownloadModalOpen(false);
	};

	const closeUploadModal = () => {
		setIsUploadModalOpen(false);
	};

	const submitDownloadModal = () => {
		reloadCurrentPage();
	};

	const submitUploadModal = (clientId, uploadedFiles) => {
		postUploadFiles(clientId, uploadedFiles)
			.then((res) => {
				window.setMessage(
					"Forms uploaded successfully! Almost there, just add your company's payroll bank account information",
					'success'
				);
				reloadCurrentPage();
			})
			.catch((error) => {
				window.setMessage(DEFAULT_ERROR_MESSAGE, 'error');
			});
	};

	const companyStatuses = companies.map((company) => {
		return {
			company: company,
			state: getAppStateFromData(company),
		};
	});

	return (
		<Fragment>
			<CompanyInfoHeader hasStatus={companyStatuses.length} anotherEinLink={true} />

			<InfoCardManager
				buttonFunction={false}
				clientId={selectedCompany.clientId}
				companies={companies}
				isMultiEin={true}
				statuses={companyStatuses}
			/>

			<LayoutBox marginTop={3}>
				<CompaniesTable
					addedEinsSuggestedNames={addedEinsSuggestedNames}
					companies={companies}
					handleOpenDownloadModal={popDownloadFormsModal}
					handleOpenUploadModal={popUploadFormsModal}
				/>
			</LayoutBox>

			<DownloadFormsModal
				company={selectedCompany}
				isProcessing={isProcessing}
				onClose={closeDownloadModal}
				onFileDownload={onFileDownload}
				onSubmit={submitDownloadModal}
				visible={isDownloadModalOpen}
			/>

			<UploadFormsModal
				clientId={selectedCompany.clientId}
				company={selectedCompany}
				key={uuidGenerator()}
				onClose={closeUploadModal}
				onSubmit={submitUploadModal}
				showDownloads={true}
				type={'company'}
				visible={isUploadModalOpen}
			/>
		</Fragment>
	);
}
