import Ctrl, {onReady} from 'ctrl.deco';
import {PoMicroFrontend} from 'micro-frontend.react';

import {render} from 'base/wrapped-render';
import {getJsonData} from 'base/_utils/Data.util';
import { mfaData } from './mfa-data';

@Ctrl('/settings/users/mfa_settings')
class SettingsUsersMfaSettingsCtrl {
	@onReady()
	_onReady() {
		const poData = getJsonData('#poData') || {};

		render(
			<PoMicroFrontend
				{...poData}
				{...mfaData}
			/>,
			document.getElementById('poRoot')
		);
	}
}
