import {
	useMemo,
	useState,
} from 'react';

import {
	Select,
} from '@fabric/select';


export default function RatingsSelect({
	ratings,
}) {
	const items = useMemo(() => (
		Object.entries(ratings).map(([value, text]) => ({ value, text }))
	), Object.keys(ratings));

	const [value, setValue] = useState((items[0] || {}).value);

	return (
		<Select
			isClearable={ false }
			items={ items }
			onChange={ ([newValue]) => setValue(newValue) }
			selectedValues={ [value] }
			width={ 5 }
		/>
	);
}
