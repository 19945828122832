import { TextButton as EncoreTextButton, Icon, IconV2, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { TextButton } from '@fabric/button';
import { Fragment } from 'react';
import { ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED } from '../../../../../../reports/shared/feature-toggles';
import { Action } from '../action';
import { SurveyPage } from '../constants';
import { ScoreHeading } from '../heading';
import { MeteredTextarea } from '../metered-textarea';
import { Question } from '../question';
import './detail-answer-page.styl';

export function DetailAnswerPage(props) {
	const {
		currentPage,
		detailAnswer,
		isSaving,
		onAnonymityInfoClick,
		onNavBack,
		onSubmit,
		onTextareaChange,
		questionClassNames,
		questionText,
		score,
	} = props;
	const backLabel = $.__('Back');
	return (
		<Fragment>
			{currentPage === SurveyPage.Two ? (
				<div>
					{ifFeature(
						'encore',
						<LayoutBox paddingY={2} paddingX={5}>
							<EncoreTextButton onClick={onNavBack} startIcon='chevron-left-regular' color='secondary' type='button'>
								{backLabel}
							</EncoreTextButton>
						</LayoutBox>,
						<div className='EnpsDetailAnswer__navigation'>
							<TextButton
								clickAction={onNavBack}
								iconBefore='fab-arrow-left-8x7'
								muted={true}
								size='small'
								text={backLabel}
								type='button'
							/>
						</div>
					)}
					<ScoreHeading score={score} />
					<ol className='EnpsDetailAnswer__questions'>
						<Question text={questionText} textClassNames={questionClassNames}>
							<MeteredTextarea onChange={onTextareaChange} value={detailAnswer} />
						</Question>
					</ol>
					<Action
						isFormValid={detailAnswer && detailAnswer.length}
						isSaving={isSaving}
						isSmallScreen={true}
						onSubmit={onSubmit}
						score={score}
					/>
					<div className='ConductSurvey__privacyMessage'>
						{ifFeature('encore', undefined, <Icon name='fab-lock-10x12' />)}
						<span className='ConductSurvey__privacyMessageText'>
							{ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED ? (
								<>
									{ifFeature(
										'encore',
										<LayoutBox display='inline-block' marginRight={0.5} position='relative' top='1px'>
											<IconV2 name='lock-regular' size={16} color='neutral-strong' />
										</LayoutBox>
									)}
									{$.__('Your feedback will be kept completely ')}
									{ifFeature(
										'encore',
										<EncoreTextButton onClick={onAnonymityInfoClick} type='button' inline>
											{$.__('anonymous')}
										</EncoreTextButton>,
										<TextButton onClick={onAnonymityInfoClick} type='button'>
											{$.__('anonymous')}
										</TextButton>
									)}
									.
								</>
							) : (
								<Message
									link={ExternalLink(
										'https://help.bamboohr.com/hc/en-us/articles/360006948434-Employee-Satisfaction-from-the-Employee-s-Perspective'
									)}
									text={$._('Your feedback will be kept completely [anonymous].')}
								/>
							)}
						</span>
					</div>
				</div>
			) : null}
		</Fragment>
	);
}
