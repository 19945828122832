import { SelectItem } from 'form-fields.react';

import { TTE_REQUIREMENT_TEXT, TTE_WAIT_PERIOD_TEXT } from './constants';
import { hasEligibilityWaitPeriod } from '../../etc/has-eligibility-wait-period';
import {
	TimeToEligibilityValues,
	TTERequirement,
	TTEWaitPeriod,
} from '../../types';

export const prepareNewTimeToEligibility = (
	timeToEligibility: TimeToEligibilityValues,
	requirementSelections: TTERequirement[]
): TimeToEligibilityValues => {
	const newRequirement = requirementSelections.length ? requirementSelections[0] : '';
	const requirementHasWaitingPeriod = hasEligibilityWaitPeriod(newRequirement);

	return {
		requirement: newRequirement,
		waitTime: requirementHasWaitingPeriod ? timeToEligibility.waitTime : '',
		waitPeriod: requirementHasWaitingPeriod ? timeToEligibility.waitPeriod : '',
	};
};

export const getTTERequirementItems =
	(allowedRequirements: TTERequirement[]): SelectItem[] => {
		return allowedRequirements.map((requirement) => {
			return {
				text: TTE_REQUIREMENT_TEXT[requirement],
				value: requirement,
			};
		});
	};

export const getTTEWaitPeriodItems =
	(allowedWaitPeriods: TTEWaitPeriod[]): SelectItem[] => {
		return allowedWaitPeriods.map((waitPeriod) => {
			return {
				text: TTE_WAIT_PERIOD_TEXT[waitPeriod],
				value: waitPeriod,
			};
		});
	};
