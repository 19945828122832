import { Ajax } from "@bamboohr/utils";
import { getParameterByName } from "@utils/url";
import { CancelToken } from "axios";
import { SubmitSurveyData, QuestionData, EmployeeWellbeingSurveyService } from "base/survey/employee_wellbeing/types";
import { EmployeeWellbeingErrorHandler } from "../utils";

interface SavedResponse {
	score: number;
	freeResponse: string;
}

interface ClosedData {
	closed: boolean;
	submitted: boolean;
}

export default class BackendSurveyPreviewService implements EmployeeWellbeingSurveyService {
	_numberOfQuestions = 4;
	_questionAnswers: SavedResponse[] = Array.from(Array(this._numberOfQuestions), () => ({ score: null, freeResponse: null }));
	_endpoint: string;

	constructor(endPoint: string) {
		this._endpoint = endPoint;
	}

	saveResponse = ({freeResponse, score, question}: SubmitSurveyData): Promise<void> => {
		this._questionAnswers[question - 1].freeResponse = freeResponse;
		this._questionAnswers[question - 1].score = score;
		return new Promise(res => res());
	}

	getData = (): Promise<QuestionData> => {
		return Ajax.get<QuestionData>(this._endpoint).then(({data}) => data);
	}

	getQuestion = (url: string, cancelToken: CancelToken): Promise<QuestionData> => {
		const question = parseInt(getParameterByName('question', url) as string);
		return Ajax.get<QuestionData>(url, null, {cancelToken}).then(({data}) => ({ ...data, savedResponse: this._questionAnswers[question - 1] })) as Promise<QuestionData>;
	}

	submit = (): Promise<ClosedData> => {
		return new Promise(res => res({
			closed: false,
			submitted: true
		}));
	}

	confirmSubmit = (): Promise<void> => {
		return new Promise(res => res());
	}
}
