import type {
	ClientTaxSettingsResponseDataCollection,
	SaveClientTaxSettingsRequestDataCollection,
} from '../types';

export function prepareSaveClientTaxSettings(clientTaxSettings: ClientTaxSettingsResponseDataCollection): SaveClientTaxSettingsRequestDataCollection {
	if (!Array.isArray(clientTaxSettings)) {
		return [];
	}
	return clientTaxSettings.map((clientTaxSetting) => {
		return {
			settingName: clientTaxSetting.settingName,
			value: clientTaxSetting.value,
		};
	});
}
