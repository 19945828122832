import './forms.styl';

import { BadgeV2, LayoutBox, Loader, StandardModal } from '@bamboohr/fabric';
import {
	XPath,
	getRelativePosition,
	toArray,
	toCamelCase,
	validateEmail,
} from 'BambooHR.util';

import Ajax from '@utils/ajax';
import { convertHTMLEntitiesInString } from 'String.util';
import { getFormPayRollDetails } from 'Form.util';
import { getMaxZIndex } from '@utils/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isEnabled } from 'FeatureToggle.util';
import { isUndefined } from 'lodash';
import { render } from 'base/wrapped-render';
// Templates
import showChangedTemplate from './templates/form-change-modal.micro.html';

export { validateEmail };

export function showMaskedValue(selector) {
	$('.' + selector).css('display', 'none');
	$('.' + selector + '_value').css('display', 'inline-block');
}

const JADE_ENABLED = isEnabled('jade');

/* Numbers with commas function.
 * Modified from public domain source found at: http://www.mredkj.com/javascript/nfbasic.html
 */
function addCommas(nStr) {
	nStr += '';

	while (nStr.indexOf(window.GLOBAL_THOUSANDS_SEPARATOR) != -1) {
		nStr = nStr.replace(window.GLOBAL_THOUSANDS_SEPARATOR, '');
	}

	let x = nStr.split(window.GLOBAL_DECIMAL_CHAR);
	let x1 = x[0];
	let x2 = x.length > 1 ? window.GLOBAL_DECIMAL_CHAR + x[1] : '';
	var rgx = /(\d+)(\d{3})/;

	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + window.GLOBAL_THOUSANDS_SEPARATOR + '$2');
	}

	return x1 + x2;
}

(function ($) {
	jQuery.fn.toggleSwitch = function () {
		$(this).each(function (idx, el) {
			$(`
				<input
					type="hidden"
					name="${xmlEntities(el.name)}"
					value="${xmlEntities(el.value)}"
					${el.checked ? '' : 'disabled="disabled"'}
				/>
				<span class="toggleSwitch ${el.checked ? '' : 'off'}"></span>
			`).insertAfter(el);
			$(el).remove();
			$(`input[name="${el.name}"]`)
				.next('span')
				.click(function () {
					if ($(this).prev('input').is(':disabled')) {
						$(this).removeClass('off').prev('input').removeAttr('disabled');
					} else {
						$(this).addClass('off').prev('input').attr('disabled', 'disabled');
					}
				});
		});
	};

	/**
	 * Alternative method to serialize a form with disabled inputs
	 */
	jQuery.fn.serializeIncludeDisabled = function () {
		let disabled = this.find(':input:disabled, ba-select:disabled').removeAttr(
			'disabled',
		);
		let serialized = this.serialize();
		disabled.attr('disabled', 'disabled');
		return serialized;
	};
})(jQuery);

// BEGIN CURRENCY FORMAT FUNCTION FROM: http://bendewey.wordpress.com/2008/11/11/jquery-formatcurrency-plugin/
(function ($) {
	$.fn.formatCurrency = function (settings) {
		settings = jQuery.extend(
			{
				decimal: '.',
				global: true,
				name: 'formatCurrency',
				oldSymbol: '',
				position: 0,
				symbol: '$',
				thousands: ',',
				useHtml: false,
			},
			settings,
		);

		/**
		 * Format the currency by replacing characters that aren't part of a valid JavaScript float and returning it.
		 *
		 * ******************
		 * ****** NOTE ******
		 * ******************
		 * If you plan to change the regex, make sure to also update the PHP unit tests in tests/JavaScript/JQueryCurrencyTest.php.
		 * Because we don't currently have a JavaScript testing framework, this is the best we can do, but I wanted to make sure we
		 * have some kind of coverage. I also wanted the ability to quickly test various sets of data with the current fix.
		 *
		 * @param {String} num The number as a string
		 *
		 * @returns {Number} the unformatted number
		 */
		function formatCurrencyReplace(num) {
			var regex = new RegExp('[^-0-9.,]+', 'gi'); //Remove $ or any leading characters besides numeric and separators
			var thousandsRegex = '';
			if (settings.thousands !== '') {
				thousandsRegex = new RegExp('\\' + settings.thousands, 'gi'); //Remove the thousands characters
			}
			//Please check out the above note before changing this regex.
			num = num
				.replace(settings.symbol, '')
				.replace(regex, '')
				.replace(thousandsRegex, '');
			if (settings.decimal === num[0]) {
				num = '0' + num;
			}
			return Number(num.replace(',', '.').replace(/^(-?)([^0-9-]+)/, '$1'));
		}

		return this.each(function () {
			var num = $(this)[settings.useHtml ? 'html' : 'val']();
			if (num !== '') {
				num = formatCurrencyReplace(num);
				if (isNaN(num)) {
					num = '0';
				}
				var sign = num == (num = Math.abs(num));
				num = Math.floor(num * 100 + 0.50000000001);
				var cents = num % 100;
				num = Math.floor(num / 100).toString();
				if (cents < 10) {
					cents = '0' + cents;
				}
				for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
					num =
						num.substring(0, num.length - (4 * i + 3)) +
						settings.thousands +
						num.substring(num.length - (4 * i + 3));
				}
				let formattedCurrency = settings.position
					? (sign ? '' : '-') + num + settings.decimal + cents + settings.symbol
					: (sign ? '' : '-') +
					  settings.symbol +
					  num +
					  settings.decimal +
					  cents;

				$(this)[settings.useHtml ? 'html' : 'val'](formattedCurrency);
			}
		});
	};
})(jQuery);

//END CURRENCY FORMAT FUNCTION

//SIMPLE CHARACTER LIMIT NOTIFIER FROM http://www.ajaxray.com/blog/2007/11/09/interactive-character-limit-for-textarea-using-jquery/
function limitChars(textid, limit, infodiv) {
	var text = $('#' + textid).val();
	var textlength = text.length;
	if (textlength > limit) {
		$('#' + infodiv).html(
			$.__('You cannot write more then %1 characters!', limit),
		);
		$('#' + textid).val(text.substr(0, limit));
		return false;
	}

	$('#' + infodiv).html(
		$.__('This field can accept %1 more characters.', limit - textlength),
	);
	return true;
}
//END CHARACTER LIMIT NOTIFIER

export function handleFormChange(url, event, noChangeCallback) {
	if (
		url &&
		typeof url === 'string' &&
		url !== '' &&
		// eslint-disable-next-line no-script-url
		url !== `javascript:void(0)` &&
		!~url.indexOf('#') &&
		window.formChanged
	) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		const customAction =
			typeof window.customFormChangedAction === 'function'
				? window.customFormChangedAction
				: null;

		showFormChangedForm($(`#${window.trackedFormId}`), url, customAction);
		return false;
	}
	if (noChangeCallback) {
		noChangeCallback();
	}
}

//-- This line won't work unless jQuery has already been included.
$(function () {
	if (!isUndefined($('.calendar-field:not([name*=":idx:"])').bhrdatepicker)) {
		try {
			$('.calendar-field:not([name*=":idx:"])')
				.bhrdatepicker(window.HSHQ_DATEPICKER_OPTIONS)
				.attr('autocomplete', 'off');
		} catch (e) {
			Rollbar.error(
				'Error setting bhrdatepicker with window.HSHQ_DATEPICKER_OPTIONS',
				{
					error: e,
					options: window.HSHQ_DATEPICKER_OPTIONS,
				},
			);
		}

		$('.calendar-field:not([name*=":idx:"])').each(function () {
			if ($(this).attr('defaultDate') !== '') {
				$(this).datepicker(
					'option',
					'defaultDate',
					$(this).attr('defaultDate'),
				);
			}
		});
	}

	//Close the datepicker when clicking on another select element
	//(or any other element other than the datepicker window itself)
	$(document).click(function (e) {
		var ele = $(e.target);
		if (
			ele.closest('#ui-datepicker-div').prop('tagName') !== 'DIV' &&
			!ele.closest('.ui-datepicker').hasClass('ui-datepicker') &&
			!ele.closest('.ui-icon').hasClass('ui-icon') &&
			!ele.closest('.ui-datepicker-next').hasClass('ui-datepicker-next') &&
			!ele.closest('.ui-datepicker-prev').hasClass('ui-datepicker-prev') &&
			!ele.closest('.hasDatepicker').hasClass('hasDatepicker')
		) {
			$('.hasDatepicker').datepicker('hide');
		}
	});

	$(document).on(
		'blur',
		`.currency-field:not('.dynamic-field-react')`,
		function () {
			var thousands =
				$(this).data('thousands-separator') ||
				window.GLOBAL_THOUSANDS_SEPARATOR ||
				',';
			var decimal =
				$(this).data('decimal-char') || window.GLOBAL_DECIMAL_CHAR || '.';
			var currency = window.GLOBAL_CURRENCY_SYMBOL || '$';
			var defaultCurrency = currency;

			currency = $(this).data('cursymbol') || defaultCurrency;

			$(this)
				.formatCurrency({
					decimal,
					oldSymbol: $(this).data('oldcursymbol'),
					position: $(this).data('currencysymbolposition'),
					symbol: currency,
					thousands,
				})
				.trigger('bhr:format');
		},
	);
	$(document).on('blur', '.percent-field', function () {
		var value = $(this).val();
		var regex = new RegExp('[^-?0-9.,]+', 'gi');
		var num = value.replace(regex, '');
		var decimal = window.GLOBAL_DECIMAL_CHAR || '.';
		if (num.indexOf(decimal) > 0) {
			num = num.replace(new RegExp('\\' + decimal + '*?(0)*$'), '');
		}
		if (num != '') {
			num += '%';
		}
		$(this).val(num);
	});

	$('body').on('blur', '.number-field:not(.no-commas)', function () {
		this.value = addCommas(this.value);
		$(this).trigger('bhr:format');
	});

	$('body').on(
		'click',
		`a[target!='_blank']:not('.history-modal,.no-form-changed-popup')`,
		function (e) {
			handleFormChange(this.href, e);
		},
	);

	$('#quicksearchform').submit(function () {
		var form = $(this);
		if (window.formChanged) {
			return modalDialogAlert(
				$.__('Discard Changes'),
				$.__('Do you want to discard your changes?'),
				$.__('Yes'),
				function () {
					window.formChanged = false;
					form.submit();
				},
				'',
				{
					cancel_message: $.__('No'),
				},
			);
		}
	});

	/* handle focus state for .BhrForms inputIcons */
	$('body').on(
		'focus',
		".BhrForms input[type='text'], .BhrForms input[type='email']",
		function () {
			$(this).parent('.inputIcons').addClass('focused');
			$(this).parent('.inputGrabHandle').addClass('focused');
		},
	);
	$('body').on(
		'blur',
		".BhrForms input[type='text'], .BhrForms input[type='email']",
		function () {
			$(this).parent('.inputIcons').removeClass('focused');
			$(this).parent('.inputGrabHandle').removeClass('focused');
		},
	);
});

function showFormChangedForm($trackedForm, url, customAction) {
	const validator = $trackedForm.validate();

	if (JADE_ENABLED) {
		window.BambooHR.Modal.setState({
			headline: $.__(`You haven't saved your changes`),
			dangerousContent: `<div class="Forms__confirmationMessage">${$.__(
				'Your changes will be lost if you navigate away from this page without saving.',
			)}</div>`,
			icon: ifFeature(
				'encore',
				'triangle-exclamation-solid',
				'fab-triangle-exclamation-48x48',
			),
			iconColor: 'attention',
			iconV2Color: 'warning-strong',
			isOpen: true,
			isProcessing: false,
			primaryAction: function () {
				window.BambooHR.Modal.setState({ isProcessing: true }, true);
				if (typeof customAction === 'function') {
					customAction();
				} else {
					$trackedForm.submit();
					if (validator.numberOfInvalids() > 0) {
						window.BambooHR.Modal.setState({ isOpen: false });
					}
				}
			},
			primaryActionText: $.__('Save Changes'),
			secondaryAction: function () {
				window.BambooHR.Modal.setState({ isProcessing: true }, true);
				window.location = url;
			},
			secondaryActionText: $.__(`Don't Save`),
			title: $.__('Just Checking...'),
		});
		return;
	}

	let options = {
		title: $.__('Just Checking...'),
		html: window.microTemplate(showChangedTemplate, {}),
		footer: {
			show: true,
			buttons: {
				primary: {
					show: true,
					text: $.__('Save Changes'),
					action: function () {
						if (typeof customAction === 'function') {
							SimpleModal.Footer.Buttons.Primary.startProcessing();
							SimpleModal.Footer.Buttons.Secondary.disable();
							customAction();
						} else {
							$trackedForm.submit();
							if (validator.numberOfInvalids() === 0) {
								SimpleModal.Footer.Buttons.Primary.startProcessing();
								SimpleModal.Footer.Buttons.Secondary.disable();
							}
						}
					},
				},
				secondary: {
					show: true,
					text: $.__(`Don't Save`),
					action: function () {
						SimpleModal.Footer.Buttons.Primary.disable();
						SimpleModal.Footer.Buttons.Secondary.startProcessing();
						window.location = url;
					},
				},
				cancel: {
					show: true,
				},
			},
		},
	};
	window.SimpleModal.openModal(options);
}

/*
 NOTE: formChanged is now declared in base.js because quicksearch needs to test for it.
 var formChanged=false;
 */

export function markFormChanged() {
	window.formChanged = true;
}
window.trackedFormId = isUndefined(window.trackedFormId)
	? false
	: window.trackedFormId;
export function enableFormChangeTracking(form) {
	let $form = $(form);
	let id = $form.attr('id');

	if (!$form.length || !id) {
		return;
	}

	window.trackedFormId = $form.attr('id');

	$form
		.find('input,textarea,select')
		.filter(':not(.control-field):not(.js-no-sticky-footer)')
		.change(markFormChanged);

	$form.find('input[type=checkbox]').click(markFormChanged);
	$form.on('ba:selectChange', markFormChanged);
}

/**
 * Register modal links with an <a> tag as the selector
 *
 * Make sure to use the url attribute instead of the href attribute for modal links.
 * Example:
 * <a class="register-link" url="/ajax/test/dir/ajax.php?id=1">Test Link</a>
 * registerModalLink("a.register-link", "Title", "Save", {}, {});
 *
 * @param {String} selector
 * @param {String} dialog_title
 * @param {String} accept_message
 * @param {function} saveHandler
 * @param {Array} options
 * @param {function} presubmit
 *
 * @returns {void}
 */
export function registerModalLink(
	selector,
	dialog_title,
	accept_message,
	saveHandler,
	options,
	presubmit,
) {
	$(document).on('click', selector, function () {
		var clicked = $(this);

		var settings = {
			cancel_message: $.__('Cancel'),
			open_callback: function () {
				clicked.removeAttr('modalClicked');
			},
			initCompensationModal: function () {
				if ($('#historyEdit.CompensationForm').length > 0) {
					window.Employees.Job.CompensationForm.initializeForm(
						'updateCompensation',
					);
				}
			},
		};

		if (!isUndefined(options)) {
			$.extend(settings, options);
		}

		if (clicked.attr('modalClicked') !== 'true') {
			clicked.attr('modalClicked', 'true');
			openRegisteredModal({
				settings,
				chosenModalSize: clicked.data('modal-size'),
				url: clicked.attr('url'),
				options,
				saveHandler,
				presubmit,
				accept_message,
				dialog_title,
			});
		}

		return false;
	});
}

export function openRegisteredModal({
	settings,
	chosenModalSize = 'small',
	url,
	options,
	saveHandler,
	presubmit,
	accept_message,
	dialog_title,
	rowDisplayType,
	historyId,
	secondaryAction,
}) {
	const modalSize = chosenModalSize;
	let header = null;
	let headerType = null;

	if (window.Employee) {
		const { jobTitle, lastName, photoURL, preferredName } = window.Employee;

		const photoUrl = photoURL || '/images/photo_person_150x150.png';
		const name = `${preferredName} ${lastName}`;

		// @startCleanup encore
		header = (
			<div className="historyModalHeader">
				<img
					className="historyModalHeader__avatar"
					src={convertHTMLEntitiesInString(photoUrl)}
				/>
				<span className="historyModalHeader__info">
					<div
						className="historyModalHeader__infoName"
						dangerouslySetInnerHTML={{
							__html: BambooHR.Utils.sanitizeHtml(name),
						}}
					/>
					<div
						className="historyModalHeader__infoTitle"
						dangerouslySetInnerHTML={{
							__html: BambooHR.Utils.sanitizeHtml(jobTitle),
						}}
					/>
				</span>
			</div>
		);

		headerType = 'filled';
		/* @endCleanup encore */

		// @startCleanup encore
		const encoreEnabled = ifFeature('encore', true, false);
		if (encoreEnabled) {
			// @endCleanup encore
			header = (
				<StandardModal.UpperContent>
					<BadgeV2
						icon={
							<BadgeV2.Avatar src={convertHTMLEntitiesInString(photoUrl)} />
						}
						size="large"
						subtitle={
							<div
								dangerouslySetInnerHTML={{
									__html: BambooHR.Utils.sanitizeHtml(jobTitle),
								}}
							/>
						}
						title={
							<div
								dangerouslySetInnerHTML={{
									__html: BambooHR.Utils.sanitizeHtml(name),
								}}
							/>
						}
					/>
				</StandardModal.UpperContent>
			);
			headerType = '';
			/* @startCleanup encore */
		}
		/* @endCleanup encore */
	}

	function fetchFormData(updatedUrl = url, selectedBoxDefault = '') {
		Ajax.get(updatedUrl)
			.then((response) => {
				//We are sending <script> from the BE which we need so we are going dangerous
				const $topModal = $('#historyModalContent');
				$topModal.html(response.data);
				const $titleH3 = $topModal.find('h3');
				const $title = `${$.__('Update')} ${$titleH3.first().text()}`;

				$titleH3.remove();

				window.BambooHR.Modal.setState(
					{
						isLoading: false,
						title: $title,
					},
					true,
				);

				window.setTimeout(() => {
					if ($('#historyEdit.CompensationForm').length > 0) {
						window.Employees.Job.CompensationForm.setupForm(
							'updateCompensation',
						);
					}

					if (
						$.isPlainObject(options) &&
						!options.omit_validation &&
						$.isFunction($.fn.bhrValidate)
					) {
						$topModal.find('.BhrForms:not(".js-noValidate")').bhrValidate({});
					}

					settings.open_callback();
					settings.initCompensationModal();
					$topModal.initializeElements();

					// calling a global from employee.js
					// must be inside of the timeout, or the z-index of the modal setup will still cut off the tooltip
					if (typeof window.addTooltipsToDisabledRequestFields === 'function') {
						window.addTooltipsToDisabledRequestFields();
					}

					// We want this function to run only on the employment status modal once the BE html has returned
					if ($topModal.find('div.js-EmploymentStatusForm').length === 1) {
						window.Employees.Job.EmploymentStatusModal.updateTaxTypeInitialOptions(
							$topModal
								.find('[name="employmentStatusId"]')
								.find('[selected]')
								.attr('value'),
						);
					}

					if (
						historyId &&
						document.querySelector('div#compensation-update-or-correct-section')
					) {
						window.Employees.Job.CompensationForm.displayUpdateOrCorrectSection(
							rowDisplayType,
							updateFormData,
							selectedBoxDefault,
						);
					}
				});
			})
			.catch(() => {
				window.BambooHR.Modal.setState({ isLoading: false, isOpen: false });
				window.setMessage(
					'An error has occurred while getting the data. Please reload the page and try again.',
					'error',
				);
			});
	}

	function updateFormData(isNewEntry) {
		const modalContainer = document.querySelector('#historyEdit');
		const formContainer = modalContainer.querySelector('fieldset');
		if (formContainer) {
			render(
				<LayoutBox margin={'10px 0'}>
					<Loader />
				</LayoutBox>,
				formContainer,
			);
		}

		const regex = /\/\d+$/;
		const match = url?.match(regex);
		let updatedUrl = url;

		if (isNewEntry && match) {
			const historyIdMatch = match?.[0];
			updatedUrl = updatedUrl.replace(historyIdMatch, '');
		}

		const selectedBoxDefault = isNewEntry ? 'update' : 'correction';

		fetchFormData(updatedUrl, selectedBoxDefault);
	}

	window.BambooHR.Modal.setState({
		alternativeActionText: settings.cancel_message,
		alternativeAction: function () {
			window.BambooHR.Modal.setState({ isOpen: false });
			secondaryAction();
		},
		biId: 'employee-benefits-add-dependent',
		content: <div id="historyModalContent"></div>,
		isLoading: true,
		header,
		headerType,
		isOpen: true,
		onClose: secondaryAction,
		primaryAction: function () {
			event.preventDefault();
			if (window.GLOBAL_IS_ASSUMED_USER) {
				window.disabledForPermsTest();
			} else {
				const $topModal = $('#fabricModalContent');
				const $form = $topModal.find('form[bhrvalidate-initiated]');
				window.modalIdSelector = $topModal.selector;

				if ($form.length > 0 && !$form.valid()) {
					return;
				}

				window.modalSubmit(saveHandler, presubmit);
			}
		},
		primaryActionText: accept_message,
		title: dialog_title,
		type: modalSize,
	});

	fetchFormData(url);
}

var widget;

function getPayRollDetails($element) {
	const restrictCurrency =
		$element.siblings('.js-overtime-rate, .js-overtimeRate, .js-rate').length >
		0;

	return !restrictCurrency ? {} : getFormPayRollDetails($element);
}

export function showNewCurrencyPicker(
	trigger,
	amountField,
	codeField,
	onClose,
	selectorsToUpdate,
) {
	const { employeeId: traxEmployeeId, payScheduleId = null } =
		getPayRollDetails($(trigger));

	// On the Employee tabs we need the id to decide if the user should get a limited currency view
	let employeeId = $(trigger).data('employee-id') || traxEmployeeId;

	if (payScheduleId !== null) {
		employeeId = null;
	}

	var field = $(amountField);
	if (isUndefined(codeField)) {
		codeField = $(amountField + '_code');
	}
	widget = $('#newCurrencyPickerWidget');
	if (widget.length === 0) {
		$.getJSON(
			'/ajax/get_currency',
			{ field: amountField, employeeId, payScheduleId },
			function (data) {
				widget = $('<div id="newCurrencyPickerWidget"><ul></ul></div>').hide();
				let ul = widget.find('ul');

				$.each(data.data, function (index, value) {
					let li;
					if (index == 4) {
						li = $('<li class="divider"><hr></li>');
						li.appendTo(ul);
					}
					li = $(`
					<li class="linkColor baseBgColor-hover">
						<span class="code">${window.js(this.code)}</span> - ${this.name}
					</li>`);
					li.data('cursymbol', this.symbol).data('curid', this.id);
					li.bind('click.bhr.selectCurrency', function (e) {
						e.stopPropagation();
						var jThis = $(this);
						field.data('oldcursymbol', field.data('cursymbol'));
						field.data('cursymbol', jThis.data('cursymbol'));
						field.data('currencysymbolposition', value.symbolPosition);
						$(codeField).val(value.code).trigger('change');
						field.blur();
						$(trigger).text(value.code);
						if (selectorsToUpdate) {
							// Useful to update more than more element with the code
							$(selectorsToUpdate).text(value.code);
						}
						$('.pickerOn').removeClass('pickerOn');
						widget.hide().remove();
						if (typeof onClose === 'function') {
							onClose();
						}
						$(document).unbind('click.bhr.currencyHideWidget');
					});
					li.appendTo(ul);
				});
				//TODO: update when Modals are updated
				if ($('.ui-dialog').length > 0) {
					widget.css('z-index', $('.ui-dialog').css('z-index'));
				}
				widget.appendTo('body');
				showNewCurrencyPicker(trigger, data.field, codeField); //yea recursion
			},
		);
	} else {
		trigger = $(trigger);
		// Update Position and show
		trigger.addClass('pickerOn');
		updateCurrencyPicker();
		widget.show();
		var zindex = getMaxZIndex() + 1;
		widget.css('z-index', zindex);

		$(document).bind('click.bhr.currencyHideWidget', function (e) {
			trigger.removeClass('pickerOn');
			$('#newCurrencyPickerWidget').hide().remove();
			if (typeof onClose === 'function') {
				onClose();
			}
			$(document).unbind('click.bhr.currencyHideWidget');
		});
	}
}

/**
 * Updates the position of the open CurrencyPicker
 *
 * @return {undefined}
 */
function updateCurrencyPicker() {
	var field = $('.pickerOn');
	let bodyScrollDif = 0;

	// Fix for currency picker usage until component gets ported over to fabric
	if (JADE_ENABLED) {
		const bodyStyle = getComputedStyle(document.body);

		if (bodyStyle.position === 'fixed') {
			bodyScrollDif = parseInt(bodyStyle.top.replace('px', ''));
		}
	}

	// Update position of picker only if visible
	if (field.length) {
		widget.css({
			top: getRelativePosition(field, 'bottom') - bodyScrollDif,
			left: getRelativePosition(field, 'left'),
		});
	}
}

export function importAddress(me) {
	var data = $(me).data('params');

	const { countryId, stateId } = data;

	const $country = $('[name=countryId]');

	$(':input[name=stateId], ba-select[name=stateId]').attr(
		'data-csz-value',
		stateId,
	);

	if (countryId) {
		$country.val(countryId);
		$country.trigger('ba:selectChange');
	}

	$.each(data, function (index, value) {
		if (index != 'countryId' && index != 'stateId') {
			$('[name=' + index + ']').val(value);
			if (value !== '') {
				$('[name=' + index + ']').trigger('paste');
			}
		}
	});
}

$(function () {
	$(window).resize(function () {
		updateCurrencyPicker();
	});
});

$('.js-pdf-export').bind('click', function (event) {
	event.preventDefault();
	const url = this.getAttribute('href');
	const hideFontSize = this.getAttribute('hide-font-size');
	const orientations = this.dataset.orientations
		? this.dataset.orientations.split(/\s+/)
		: [];

	const options = { hideFontSize };

	if (orientations.length) {
		options.orientations = orientations;
	}

	window.Reports.exportPdf(url, options);
});

// tasklist forms
export function addTasklist() {
	return ajaxModalDialog(
		$.__('Add a new tasklist'),
		'/ajax/dialog/manage/tasklist/add_tasklist.php',
		$.__('Save'),
		function () {
			if ($.trim($('#add_tasklist input').val()) === '') {
				var please = $.__('Please enter a tasklist name.');
				if ($('#add_tasklist p.please').html() != please) {
					$('#add_tasklist').append("<p class='please'>" + please + '</p>');
				}
			} else {
				modalFormSubmit($('#add_tasklist')[0], function (result) {
					if (result.ret == 'SAVED') {
						document.location.reload();
					}
				});
			}
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__('Cancel'),
		},
	);
}

export function renameTasklist(id) {
	return ajaxModalDialog(
		$.__('Rename Selected Tasklist'),
		'/ajax/dialog/manage/tasklist/rename_tasklist.php?id=' + id,
		$.__('Save'),
		function () {
			if ($.trim($('#rename_tasklist input').val()) === '') {
				var please = $.__('Please enter a tasklist name.');
				if ($('#rename_tasklist p.please').html() != please) {
					$('#rename_tasklist').append("<p class='please'>" + please + '</p>');
				}
			} else {
				modalFormSubmit($('#rename_tasklist')[0], function (result) {
					if (result.ret == 'SAVED') {
						document.location.reload();
					}
				});
			}
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__('Cancel'),
		},
	);
}

export function deleteTasklist(id) {
	return ajaxModalDialog(
		$.__('Delete Selected Tasklist'),
		'/ajax/dialog/manage/tasklist/delete_tasklist.php?id=' + id,
		$.__('Yes'),
		function () {
			modalFormSubmit($('#delete_tasklist')[0], function (result) {
				if (result.ret == 'DELETED') {
					document.location.reload();
				}
			});
		},
		undefined,
		{
			modal: true,
			cancel_message: $.__('Cancel'),
		},
	);
}

/**

 * This function can be called from .BhrForms $stickySaveCancelUrl
 * to cancel edit state on form without reloading page.
 * Takes form handler as argument
 *
 * @param form Form selector
 * @param dontResetForm Boolean Pass true to prevent the form from resetting
 */
export function bhrFormCancel(form, dontResetForm) {
	var footer = $('.BhrPage-wrap + footer:not(.SiteFooter)');
	$(form).addClass('BhrForms--view');

	if (!dontResetForm) {
		$(form).trigger('reset');

		const formElm = document.querySelector(form);

		if (formElm) {
			formElm
				.querySelectorAll('ba-select')
				.forEach((baSelect) => baSelect.__ba.resetSelect());

			if (
				$(form) &&
				$(form).data('validator') &&
				$(form).data('validator').resetForm
			) {
				$(form).data('validator').resetForm();
			}
		}
	}

	$(form).addClass('BhrForms--view');
	$('select').trigger('liszt:updated');
	$('.fieldBox.masked_value').removeAttr('style');
	$('.fieldBox.masked').removeAttr('style');
	$('.fieldBox').children('.formNote.error').remove();
	$('.fieldGroup.validate-group-error').removeClass('validate-group-error');
	$('.fieldBox.error').removeClass('error');
	$('.js-country-value-initialized').removeClass(
		'js-country-value-initialized',
	);

	footer.css('bottom', '0px');
	footer.addClass('fixedFooter');
	footer.animate({ bottom: '-60px' }, 400, function () {
		$(this).removeClass('fixedFooter');
		$('#content').css('margin-bottom', '0px');
		$('#footerHolder').remove();
	});
	window.formChanged = false;
}

/*
 * add ability to .BhrForms to be able to set reset value for forms
 * hooking into reset event
 * setTimeout used to put custom code after the default reset code in execution
 */
$(document).on('reset', '.BhrForms', function () {
	var $inputs = $(this).find(
		':input[data-initial-value], ba-select[data-initial-value]',
	);
	setTimeout(function () {
		$inputs.each(function () {
			var $input = $(this);
			$input.val($input.attr('data-initial-value'));
			//for chosen elements
			if ($input.is('select')) {
				$input.trigger('liszt:updated');
			}
			if ($input.is('[class*=bhrToggle]')) {
				$input.trigger('change');
			}
			if (
				$input.hasClass('currency-field') ||
				$input.hasClass('percent-field')
			) {
				$input.blur();
			}
		});
	}, 0);
});

/*
 * Initializes all forms with .BhrForms--view for edit state
 * Removes .BhrForms--view from <form>
 * requires stickyFooter on page
 */
$(function () {
	// if user selects form element before js runs, start editMode immediately
	if ($('.BhrForms').find(document.activeElement).length > 0) {
		$('.BhrForms').removeClass('BhrForms--view');
	}
	$('.BhrForms--view').on(
		'click chzn:chzn-choice-close',
		'input[type=checkbox]:not(:disabled) + label, input[type=radio]:not(:disabled) + label, select:not(:disabled):not(.js-no-sticky-footer) + div.chzn-container, select:not(:disabled):not(.js-no-sticky-footer) + div.chzn-container + .chzn-single > .search-choice-close',
		function () {
			$(this).closest('.BhrForms--view').trigger('focusin');
		},
	);
	$(document).on('focusin', '.BhrForms--view', function (e) {
		// IE11 fix... IE11 thinks <td>s, <fieldset>s, and a few other elements should be focusable. Use jquery-ui's
		// white-lists for focusable elements to ensure that clicking on a td does not have undesired effects
		if (this !== e.target && !$(e.target).is(':focusable')) {
			return false;
		}

		// Test for exceptions for edit mode (especially links on the form)
		if (
			$(e.target).closest('.chzn-container').hasClass('js-no-sticky-footer')
		) {
			e.stopPropagation();
			return false;
		}

		if (
			$(e.target).parent('.chzn-container').hasClass('chzn-disabled') ||
			$(e.target).is('a') ||
			$(e.target).parents('ba-select').hasClass('disabled') ||
			$(e.target).parents('ba-select').attr('disabled') === 'disabled'
		) {
			return false;
		}

		if ($(e.target).closest('.js-no-sticky-footer').length === 0) {
			document.dispatchEvent(new CustomEvent('SiteFooter:open'));
		}

		var footer = $('footer:not(.SiteFooter)');

		$(this).removeClass('BhrForms--view');
		if (
			!footer.hasClass('fixedFooter') &&
			!$(this).hasClass('js-no-sticky-footer')
		) {
			//only do this if form is not in a modal
			footer.after('<div id="footerHolder" style="height: 60px"></div>');
			footer.css('bottom', '-60px').addClass('fixedFooter');
			footer.css('display', 'block');
			footer.animate({ bottom: '0px' }, 400);
		}
		//call chosen to run on new selects shown - jn commented out to fix datepicker color issue in .bhrForm
		//Needs to be shown for places like the emergency tab to update the select dropdowns with chosen - add :not's for the datepicker
		$(
			'select:not(.ui-datepicker-month):not(.ui-datepicker-year):visible',
		).chosen(bhrChosenOptions);

		// SSN and SIN Fields are "masked". Therefore, there are two inputs for that actual field. One that is hidden,
		// and the other that is shown. They swap when the form is in edit mode. In order to focus the newly shown field
		// we have to attempt to focus the other paired input after the swap (CSS driven, when BhrForms--view is removed)
		if (e && e.target.id && /_masked$/.test(e.target.id)) {
			$('#' + e.target.id.replace('_masked', '')).focus();
		}

		/**
		 * Runs initial validation on some fields if it is a TRAX company
		 * Prevents bad data being saved and catches validation race errors
		 */
		$(this).initialTraxValidation();
	});
	$(document).on('modalDialog:closed', function () {
		$('#currencyPickerWidget').hide().remove();
	});
	$(document).on('modalDialog:closed', function () {
		$('#newCurrencyPickerWidget').hide().remove();
	});
});

/*
 * Initializes a plugin for showing/hiding elements based on form input values.
 *
 *
 * Available attributes:
 *
 * Equals:
 * show-if-...
 * hide-if-...
 *
 * Contains:
 * show-in-...
 * hide-in-...
 *
 * RegExp:
 * show-re-...
 * hide-re-...
 *
 *
 * Simply append the name of the form field you want the element to watch.
 *
 * For example:
 *
 * <div show-if-first_name="Panda"></div>
 *
 * The above example will search the parent form element for an element matching '[name="first_name"]'
 * Whenever the input changes, it will show the div if the value is equal to "Panda"
 *
 * <div hide-re-zip_code="[^\d]+"></div>
 *
 * The above example will hide the div if the "zip_code" field contains anything but digits
 */

(function () {
	var defaultConf = {
			selector: '[name]',
			name: null,
			value: null,
			action: 'show',
			method: 'if',
			container: 'form',
			class: null,
		},
		actions = {
			show: 'hide',
			hide: 'show',
			addClass: 'removeClass',
			removeClass: 'addClass',
		};

	$.fn.toggleIf = function (conf) {
		conf = $.extend({}, defaultConf, conf);

		conf.target = $(this);
		conf.action = toCamelCase(conf.action);
		conf.container = this.closest(conf.container) || $(document.body);

		var fields = conf.container.find(conf.selector).filter(function () {
			if (this.name) {
				return conf.name.split('|').indexOf(this.name.toLowerCase()) >= 0;
			}
		});

		fields
			.each(function () {
				var field = $(this),
					data = field.data('toggleIf');

				if (isUndefined(data)) {
					field.data('toggleIf', {});

					field.on('change', function () {
						$.each(field.data('toggleIf'), function (action, configs) {
							$.each(configs, function (index, cfg) {
								var valid = [];

								$.each(fields, function () {
									var value = $(this).val();

									switch (cfg.method) {
										case 'if':
											valid.push(cfg.value.split('|').indexOf(value) >= 0);
											break;
										case 'in':
											if (
												field.is(
													[
														':not([type="checkbox"]:checked)',
														':not([type="radio"]:checked)',
													].join(', '),
												)
											) {
												valid.push(value.indexOf(cfg.value) >= 0);
											}
											break;
										case 're':
											var regex = new RegExp(cfg.value, 'g');
											valid.push(regex.exec(value));
											break;
										case 'gt':
											break;
										case 'lt':
											break;
									}
								});

								cfg.target[valid.indexOf(true) >= 0 ? action : actions[action]](
									cfg.class,
								);
							});
						});
					});
				}

				data = field.data('toggleIf') || {};
				data[conf.action] = data[conf.action] || [];
				data[conf.action].push(conf);
				field.data('toggleIf', data);
			})
			.change();
	};
})();

$(function () {
	var elements;

	// Use XPath whenever possible, for performance
	if (!isUndefined(document.evaluate)) {
		elements = $(
			[].concat(
				XPath("//form//*[@*[starts-with(name(), 'ba-show-')]]"),
				XPath("//form//*[@*[starts-with(name(), 'ba-hide-')]]"),
				XPath("//form//*[@*[starts-with(name(), 'ba-add-class-')]]"),
				XPath("//form//*[@*[starts-with(name(), 'ba-remove-class-')]]"),
			),
		).hide();
		// hide immediately to prevent flash on page load
	} else {
		// Fallback to ugly jQuery if not (*cough* IE)
		elements = $('form *');
	}

	elements.each(function () {
		var $el = $(this);

		$.each(toArray(this.attributes), function () {
			var attr = this,
				m = attr.name.match(
					/^ba\W(show|hide|add-class|remove-class)\W?(.+)*?\W(if|in|re|gt|lt)\W(.+)$/,
				);

			if (m) {
				$el.toggleIf({
					action: m[1],
					class: m[2],
					method: m[3],
					name: m[4],
					value: attr.value,
				});
			}
		});
	});
});
