import EmailSubjectConfig from 'TinyMCE/email-subject';
import baseMessage from 'TinyMCE/base-message';

function setupEditor(initSelector, inputSelector, toolbarSelector, subjectSelector, message, customOptions, hasSubject = false) {
	const tinyMCE = window.tinymce;

	const mceMessageOptions = {
		selector: initSelector,
		plugins: [
			// tinymce
			'autolink link image lists directionality textcolor colorpicker tabfocus',
			// custom bhr
			'common placeholders placeholderattribute embedimage validate contentcleaner scrollposition paste',
		],
		valid_styles: {
			'*': 'color,font-family,font-weight,text-decoration',
			a: '',
			p: 'margin,margin-bottom,margin-top',
			ol: 'margin,margin-bottom,margin-top',
			ul: 'margin,margin-bottom,margin-top',
		},
		object_resizing: 'img:not(.placeholder)',
		fixed_toolbar_container: toolbarSelector,
		validate_required: true,
		verify_html: true,
		hidden_input: false,
		setup(editor) {
			editor
				.on('init', function(event) {
					editor.setContent(message);
					if (editor.id === 'js-mce-message') {
						$('input[name="name"]').focus();
					}
				})
				.on('Change', function(e) {
					window.markFormChanged();
				})
				.on('PostProcess', function(contentEvent) {
					if (contentEvent.save) {
						$(inputSelector).val(editor.getContent());
					}
				});
		},
	};

	// Initialize the message body
	tinyMCE.init({ ...baseMessage, ...mceMessageOptions, ...customOptions });

	if (hasSubject) {
		tinyMCE.init({ ...EmailSubjectConfig, selector: subjectSelector });
	}
}

export default setupEditor;
