import {
	Button,
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';

import {
	showSlidedown
} from 'Alerts.mod';

import {
	handleCloseModal,
} from '../domain/handle-close-modal';
import {
	COMPANY_TAX_SETTINGS_BI_ID_SAVE,
} from '../../company-tax-settings/constants';

import {
	L_CANCEL,
	L_SAVE,
	L_SAVE_ERROR,
	L_SAVE_SUCCESS,
} from '../language';

interface ModalFooterDefaultProps {
	onSubmit(): Promise<boolean>;
}

export function ModalFooterDefault(props: ModalFooterDefaultProps) {
	const {
		onSubmit,
	} = props;
	return (
		<StandardModal.Footer
			actions={ [
				(
					<TextButton
						key="cancel"
						onClick={ handleCloseModal }
						type="button"
					>
						{ L_CANCEL }
					</TextButton>
				),
				(
					<Button
						data-bi-id={ COMPANY_TAX_SETTINGS_BI_ID_SAVE }
						key="primary"
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onClick={ async () => {
							const success = await onSubmit();
							if (success) {
								showSlidedown(L_SAVE_SUCCESS, 'success');
							} else {
								showSlidedown(L_SAVE_ERROR, 'error');
							}
							handleCloseModal();
						} }
						type="button"
					>
						{ L_SAVE }
					</Button>
				)
			] }
		/>
	)
}
