import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';

import './styles.styl';

function renderTimeOffSettings(rootId) {
	render(<PoMicroFrontend route='/time-off/settings' />, document.getElementById(rootId));
}


window.TimeOffCtrl = {
	renderTimeOffSettings,
};

