import {
	BodyText,
	Flex,
	Headline,
	Label,
	LayoutBox,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { Message, InternalLink } from '@utils/message';
import React, { Component } from 'react';

import { Withholding } from 'payroll/withholding';
import { OldTraxWithholding } from './components/old-trax-withholding';
import { OldWithholding } from './components/old-withholding';
import { OtherWithholding } from './components/other-withholding/other-withholding';
import { EmployeeTaxStates } from './components/employee-tax-states';
import { SectionStyledBox } from '../section-styled-box';

/* @startCleanup encore */
import { Select as OldSelect } from '@fabric/select';
/* @endCleanup encore */

import {
	initializeNewTraxWithholding,
	initializeOldWithholding,
	initializeOtherWithholding,
	initializeEmployeeTaxStates,
} from './utils';

import './withholding-wrapper.styl';


/**
 * Expected props:
 * - canManageBhrPayroll
 * - companyTaxStates
 * - employeeId
 * - federalTaxDeductionToggle
 * - federalTaxDeductionsPermissions
 * - hasBhrPayroll
 * - hasTempTraxFeature
 * - hasTrax
 * - isAdminUser
 * - newTraxEEData
 * - residentTaxState
 * - showResidentTaxState
 * - stateTaxDeductionsPermissions
 * - unemploymentTaxState
 * - withholdings
 */

export class WithholdingWrapper extends Component {
	constructor(props) {
		super(props);

		let defaultTaxType;
		if (props.employeeTaxType) {
			defaultTaxType = props.employeeTaxType.items.filter(taxType => taxType.default)[0];

			// If the employeeTaxType property exists but one of the items doesn't have the default property set as true,
			// defaults to setting the first item as the initial value. If this is happening there might be an error on data side of things.
			if (!defaultTaxType) {
				defaultTaxType = props.employeeTaxType.items[0];
			}
		}

		this.state = {
			showTraxWithholding: true,
			is2020Release: false,
			has2020W4: false,
			federalData2020: {},
			selectedTaxType: defaultTaxType ? defaultTaxType.value : null,
			temporary2020W4: false,
		};
	}

	_handleNewTraxCancel = () => {
		// Hide and then show (re-render) the Trax Withholding app upon cancel
		// to re-set the form with the original data
		this.setState({ showTraxWithholding: false }, () => {
			this.setState({ showTraxWithholding: true });
		});

	};

	_handle2020Update = (is2020Release, has2020W4, federalData2020) => this.setState({ is2020Release, has2020W4, federalData2020 });

	_handleTemporary2020Cancel = () => this.setState({ temporary2020W4: false });

	_handleFormClick = (event) => {
		if (event.target.matches('.js-toggle2020W4')) {
			this.setState({ temporary2020W4: true });
		}
	};

	_renderMainContent = () => {
		let {
			hasTrax,
			hasTempTraxFeature,
			hasBhrPayroll,
			hasBlankStateHeader,
			headerHeadlineSize,
			newTraxEEData,
			federalTaxDeductionToggle,
			federalTaxDeductionsPermissions,
			stateTaxDeductionsPermissions,
			isAdminUser,
			isPayrollAdminUser,
			canAccessStateUiFields,
			isNewHirePacket,
			employeeTaxType,
			usesSectionComponent,
			withholdingSectionsFlexGap,
		} = this.props;

		let {
			showTraxWithholding,
			is2020Release,
			has2020W4,
			federalData2020,
			selectedTaxType,
			temporary2020W4,
		} = this.state;

		let showWithholdingInformation = null;
		if (employeeTaxType) {
			const selectedTaxTypeInfo = employeeTaxType.items.filter(taxType => taxType.value === selectedTaxType)[0];

			showWithholdingInformation = {
				showFederalWithholding: selectedTaxTypeInfo.showFederal,
				showStateWithholding: selectedTaxTypeInfo.showState,
				showStateUi: selectedTaxTypeInfo.showStateUi,
			};
		}

		if (hasTrax && hasTempTraxFeature) {
			return (
				<Withholding
					canAccessStateUiFields={ canAccessStateUiFields }
					data={ newTraxEEData }
					fedPerms={ federalTaxDeductionsPermissions }
					hasBlankStateHeader={ hasBlankStateHeader }
					headerHeadlineSize={ headerHeadlineSize }
					isAdminUser={ isAdminUser }
					isPayrollAdminUser={ isPayrollAdminUser }
					newHirePacket={ isNewHirePacket }
					sectionsFlexGap={ withholdingSectionsFlexGap }
					show={ showTraxWithholding }
					showWithholdingInformation={ showWithholdingInformation }
					statePerms={ stateTaxDeductionsPermissions }
					usesSectionComponent={ usesSectionComponent }
				/>
			);
		}

		if (hasTrax && federalTaxDeductionToggle) {
			return (
				<OldTraxWithholding
					federalData2020={ federalData2020 }
					fedPerms={ federalTaxDeductionsPermissions }
					headerHeadlineSize={ headerHeadlineSize }
					isNewHirePacket={ isNewHirePacket }
					isPayrollAdminUser={ isPayrollAdminUser }
					sectionsFlexGap={ withholdingSectionsFlexGap }
					showFederal2020={ is2020Release && (has2020W4 || temporary2020W4) }
					showWithholdingInformation={ showWithholdingInformation }
					usesSectionComponent={ usesSectionComponent }
				/>
			);
		}

		if (hasBhrPayroll) {
			return (
				<OldWithholding
					federalData2020={ federalData2020 }
					fedPerms={ federalTaxDeductionsPermissions }
					headerHeadlineSize={ headerHeadlineSize }
					isNewHirePacket={ isNewHirePacket }
					sectionsFlexGap={ withholdingSectionsFlexGap }
					showFederal2020={ is2020Release && (has2020W4 || temporary2020W4) }
					showWithholdingInformation={ showWithholdingInformation }
					statePerms={ stateTaxDeductionsPermissions }
					usesSectionComponent={ usesSectionComponent }
				/>
			);
		}

		return <OtherWithholding
			headerHeadlineSize={ headerHeadlineSize }
			usesSectionComponent={ usesSectionComponent }
		/>;
	};

	componentDidMount() {
		let {
			canManageBhrPayroll,
			employeeId,
			hasTrax,
			hasTempTraxFeature,
			hasBhrPayroll,
			federalTaxDeductionToggle,
			federalTaxDeductionsPermissions,
			stateTaxDeductionsPermissions,
			withholdings,
			isNewHirePacket,
		} = this.props;

		const submitBtnSelector = 'button[data-action="SiteFooterAction:submitPayInfoForm"]';
		const cancelBtnSelector = 'button[data-action="SiteFooterAction:cancel"]';
		const form = document.querySelector('#payInfoForm');
		const submitBtn = document.querySelector(submitBtnSelector);
		const $cancelBtn = $(cancelBtnSelector);

		if (hasTrax && hasTempTraxFeature) {
			initializeNewTraxWithholding(employeeId, form, submitBtn, $cancelBtn, this._handleNewTraxCancel);
		} else if (hasBhrPayroll || (hasTrax && federalTaxDeductionToggle)) {
			initializeOldWithholding(federalTaxDeductionsPermissions, stateTaxDeductionsPermissions, hasTrax, employeeId, withholdings, submitBtn, form, this._handle2020Update, isNewHirePacket);
		} else {
			initializeOtherWithholding(federalTaxDeductionsPermissions, stateTaxDeductionsPermissions, hasTrax, employeeId, withholdings, submitBtn, form, this._handle2020Update);
		}

		// Toggle 2020 Federal Withholding UI off when canceling
		$cancelBtn.click(this._handleTemporary2020Cancel);
	}


	render() {
		let {
			canManageBhrPayroll,
			employeeTaxType,
			hasTrax,
			showResidentTaxState,
			companyTaxStates,
			residentTaxState,
			unemploymentTaxState,
			isNewHirePacket,
			usesSectionComponent,
		} = this.props;

		const {
			selectedTaxType,
		} = this.state;

		if (isNewHirePacket) {
			return this._renderMainContent();
		}

		let taxTypeLabel;
		let taxTypes;
		let employeeId;
		let pageId;
		if (employeeTaxType) {
			({
				label: taxTypeLabel,
				items: taxTypes,
				employeeId,
				pageId,
			} = employeeTaxType);
		}

		const employmentStatusUrl = employeeId && pageId ? `/employees/employee.php?id=${ employeeId }&page=${ pageId }` : null;

		return ifFeature('encore',
			(
				<form
					action=""
					className="BhrForms BhrForms--view BhrForms--tight stickyFooter js-payinfoForm WithholdingWrapper"
					id="payInfoForm"
					method="POST"
					name="payinfoForm"
					onClick={ this._handleFormClick }
				>
					<Flex
						flexDirection="column"
						gap={ 4 }
						marginTop={ 1.25 }
					>
						{ employeeTaxType ? (
							<SectionStyledBox usesSectionComponent={ usesSectionComponent }>
								<Headline
									color="primary"
									size="medium"
								>
									{ taxTypeLabel }
								</Headline>
								<LayoutBox marginTop={ 2 }>
									<Label htmlFor="employeeTaxType">
										{ taxTypeLabel }
									</Label>
									<Flex
										alignItems="center"
										gap={ 2 }
									>
										<Select
											isClearable={ false }
											isDisabled={ true }
											items={ taxTypes }
											name="employeeTaxType"
											onChange={ (selectedValues) => {
												this.setState({ selectedTaxType: selectedValues[0] });
											} }
											selectedValues={ [selectedTaxType] }
											width={ 7 }
										/>

										<BodyText color="neutral-weak">
											<Message
												link={ InternalLink(employmentStatusUrl) }
												text={ $._('Tax type can be updated with the [employment status].') }
											/>
										</BodyText>
									</Flex>
								</LayoutBox>
							</SectionStyledBox>
						) : null }

						{
							canManageBhrPayroll && !hasTrax && (
								<EmployeeTaxStates
									companyTaxStates={ companyTaxStates }
									residentTaxState={ residentTaxState }
									showResidentTaxState={ showResidentTaxState }
									unemploymentTaxState={ unemploymentTaxState }
									usesSectionComponent={ usesSectionComponent }
								/>
							)
						}

						{ this._renderMainContent() }
					</Flex>
				</form>
			),
			(
				<form
					action=""
					className="BhrForms BhrForms--view BhrForms--tight stickyFooter js-payinfoForm WithholdingWrapper"
					id="payInfoForm"
					method="POST"
					name="payinfoForm"
					onClick={ this._handleFormClick }
				>
					{ employeeTaxType && (
						<fieldset className="WithholdingWrapper__taxType fab-FormSection fab-FormSection--disabled">
							<label className="fab-Label" htmlFor="employeeTaxType">
								{ $.__('%1$s', taxTypeLabel) }
							</label>
							<div className="fab-FormSection--content">
								<OldSelect
									isClearable={ false }
									isDisabled={ true }
									items={ taxTypes }
									name="employeeTaxType"
									onSelect={ value => this.setState({ selectedTaxType: value }) }
									selectedValues={ [selectedTaxType] }
									width={ 7 }
								/>

								<div className="WithholdingWrapper__explanation">
									<Message
										link={ InternalLink(employmentStatusUrl) }
										text={ $._('Tax type can be updated with the [employment status].') }
									/>
								</div>
							</div>
						</fieldset>
					) }

					{
						canManageBhrPayroll && !hasTrax && (
							<EmployeeTaxStates
								companyTaxStates={ companyTaxStates }
								residentTaxState={ residentTaxState }
								showResidentTaxState={ showResidentTaxState }
								unemploymentTaxState={ unemploymentTaxState }
							/>
						)
					}

					{ this._renderMainContent() }
				</form>
			)
		);
	}
}
