import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import {
	camelCase,
} from 'lodash';

import Ajax from '@utils/ajax';

import {
	validateEmail,
} from 'BambooHR.util';


const ACTIONS = {
	/**
	 * @param {State} state
	 * @param {object} payload
	 * @param {string[]} payload.employeeIds
	 * @param {boolean} payload.isSelected
	 * @returns {State}
	 */
	changeSelectedEmployees(state, {
		employeeIds,
		isSelected,
	}) {
		const employeeList = state.employeeList
			.map(employee => ({
				...employee,
				selected: employeeIds.includes(employee.id) ? isSelected : !!employee.selected,
			}));

		const selectedEmployees = employeeList
			.filter(({ selected }) => selected);

		employeeIds = selectedEmployees
			.map(({ id }) => id);

		return {
			...state,
			employeeIds,
			employeeList,
			selectedEmployees,
		};
	},

	/**
	 * @param {State} state
	 * @param {object} payload
	 * @param {string} employeeId
	 * @returns {State}
	 */
	selectEmployee: (state, employeeId ) => {
		const employeeList = state.employeeList
			.map(employee => ({
				...employee,
				selected: employee.id === employeeId,
			}));
		const selectedEmployees = employeeList
			.filter(({ selected }) => selected);
		const employeeIds = selectedEmployees
			.map(({ id }) => id);

		return {
			...state,
			employeeIds,
			employeeList,
			selectedEmployees,
		};
	},

	/**
	 * @param {State} state
	 * @param {{ homeEmail: string, workEmail?: string } | { homeEmail?: string, workEmail: string }} payload
	 * @returns {State}
	 */
	updateEmail: (state, { homeEmail, workEmail }) => {
		homeEmail = typeof homeEmail === 'string' ? homeEmail : state.homeEmail;
		workEmail = typeof workEmail === 'string' ? workEmail : state.workEmail;

		const hasValidHomeEmail = !!(
			!homeEmail || validateEmail(homeEmail)
		);
		const hasValidWorkEmail = !!(
			!workEmail || validateEmail(workEmail)
		);
		const hasValidEmail = !!(
			(
				homeEmail ||
				workEmail
			) &&
			hasValidHomeEmail &&
			hasValidWorkEmail
		);

		return {
			...state,
			homeEmail,
			workEmail,
			hasValidEmail,
			hasValidHomeEmail,
			hasValidWorkEmail,
		};
	},

	/**
	 * @param {State} state
	 * @param {object} payload
	 * @param {State['editType']} payload.editType
	 * @returns {State}
	 */
	updateEditType: (state, { editType }) => ({
		...state,
		editType,
	}),
	/**
	 * @param {State} state
	 * @param {never} payload
	 * @returns {State}
	 */
	submit: (state) => {
		window.BambooHR.Modal.setState({
			isProcessing: true,
		}, true);

		return state;
	},
};

/** @type {React.Context<Partial<{ state: State, actions: Actions }>>} */
const StateContext = createContext({});

/** @type {() => [State, Actions]} */
export const useStateContext = () => {
	const {
		state,
		actions,
	} = useContext(StateContext);

	return [state, actions];
};

/**
 *
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
function reducer(state, { type, payload, callbacks }) {
	if (type === '__UPDATE_STATE__') {
		state = payload;
	} else if (typeof ACTIONS[type] === 'function') {
		state = ACTIONS[type](state, payload, ACTIONS) || state;
	}

	const fn = camelCase('on ' + type);

	if (typeof callbacks[fn] === 'function') {
		callbacks[fn](state);
	}

	return state;
}

/**
 * @param {object} props
 * @param {JSX.Element|JSX.Element[]} props.children
 * @param {State} props.value
 */
export function StateContextProvider({
	children,
	value,
	...props
}) {
	const [state, dispatch] = useReducer(reducer, value);
	const callbacks = useMemo(() => Object.entries(props)
		.filter(([key, val]) => key.startsWith('on') && typeof val === 'function')
		.reduce((funcs, [fn, func]) => ({
			...funcs,
			[fn]: func,
		}), {}), [props]);
	/** @type {Actions} */
	const actions = useMemo(() => Object.keys(ACTIONS)
		.reduce((funcs, type) => ({
			...funcs,
			[type]: payload => dispatch({ type, payload, callbacks }),
		}), {}), []);
	const context = useMemo(() => ({
		state,
		actions,
	}), [state, actions]);

	useEffect(() => {
		if (value !== state) {
			dispatch({
				type: '__UPDATE_STATE__',
				payload: value,
				callbacks,
			});
		}
	}, [value]);

	return (
		<StateContext.Provider value={ context }>
			{ children }
		</StateContext.Provider>
	);
}

/**
 * @param {string} url
 * @param {object} [params]
 * @returns {[object, boolean]}
 */
export function useAjax(url, params) {
	const [result, setResult] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		Ajax.get(url, params)
			.then(({ data }) => {
				setResult(data);
				setLoading(false);
			});
	}, [url, JSON.stringify(params)]);

	return [result, loading];
}

/**
 * @typedef State
 * @property {AccessLevel} accessLevel
 * @property {boolean} canCombine
 * @property {EmployeeFilter[]} employeeFilters
 * @property {string[]} employeeIds
 * @property {Employee[]} employeeList
 * @property {boolean} [expandAdvanced]
 * @property {boolean} multiple
 * @property {Employee[]} selectedEmployees
 * @property {'change'|'combine'} editType
 * @property {string} [homeEmail]
 * @property {string} [workEmail]
 * @property {boolean} [hasValidEmail]
 * @property {boolean} [hasValidHomeEmail]
 * @property {boolean} [hasValidWorkEmail]
 */

/**
 * @typedef AccessLevel
 * @property {'yes'|'no'} assignable
 * @property {boolean} canCombine
 * @property {boolean} canDelete
 * @property {boolean} canDuplicate
 * @property {string} companyId
 * @property {string} count
 * @property {'yes'|'no'} countsAsFullUser
 * @property {string} [description]
 * @property {string} id
 * @property {string} limitTo
 * @property {'yes'|'no'} locked
 * @property {string} name
 * @property {string} selfAccessGroupId
 * @property {string} selfAccessLevel
 * @property {'admin'|'custom'|'employee'|'manager'|'payroll-admin'} type
 */

/**
 * @typedef Employee
 * @property {Pick<AccessLevel,'canCombine'|'id'|'name'|'type'>[]} accessLevels
 * @property {boolean} canCombine
 * @property {string} [departmentId]
 * @property {string} displayName
 * @property {string} [divisionId]
 * @property {string} employeeNumber
 * @property {string} [employmentStatus]
 * @property {string} firstName
 * @property {boolean} hasEmail
 * @property {string} id
 * @property {string} [jobTitleId]
 * @property {string} lastName
 * @property {string} lastNameFirstName
 * @property {string} [locationId]
 * @property {boolean} [selected]
 */

/**
 * @typedef EmployeeFilter
 * @property {number} count
 * @property {string} id
 * @property {string} key
 * @property {string} name
 * @property {EmployeeFilterOption[]} options
 * @property {string} table
 */

/**
 * @typedef {{ [id: string]: string }} EmployeeFilterOption
 */

/**
 * @typedef {typeof ACTIONS} ActionDefinition
 */

/**
 * @typedef {keyof ActionDefinition} ActionType
 */

/**
 * @typedef {{ [type in ActionType]: Parameters<ActionDefinition[type]>[1] }} ActionPayloadMap
 */

/**
 * @typedef {{ [type in ActionType]: { type: type, payload: ActionPayloadMap[type] } }[ActionType]} Action
 */

/**
 * @typedef {{ [type in ActionType]: (payload: ActionPayloadMap[type]) => void }} Actions
 */
