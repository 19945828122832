let sortableElem = null;

export const sortable = (elem, handle, items, callback = () => {}) => {

	const fixHelper = function(e, ui) {
		ui.children().each(function() {
			$(this).width($(this).width());
		});
		return ui;
	};

	/**
	 * Sortable options
	 */
	const sortable_options = {
		handle,
		axis: 'y',
		cursor: 'move',
		containment: 'parent',
		delay: 250,
		opacity: 0.6,
		placeholder: 'ui-sortable-placeholder',
		forcePlaceholderSize: true,
		tolerance: 'pointer',
		helper: fixHelper,
		items,
		start: function(e, ui) {
			ui.helper.addClass('ui-sortable-helper');
			ui.placeholder.css('height', ui.item.height() - 2 + 'px');
			ui.placeholder.css('width', ui.item.width() - 2 + 'px');
			ui.placeholder.html('<td colspan="5"></td>');
		},
		stop: function(e, ui) {
			let item = ui.item;
			let previousItem = item.prev();
			let nextItem = item.next();
			// make sure the item is not below an add tasks row
			if (previousItem.hasClass('addNewRow')) {
				item.insertBefore(previousItem);
			}
			// make sure that the item is not put in a hidden category
			if (previousItem.hasClass('hidden') || nextItem.hasClass('hidden')) {
				elem.sortable('cancel');

			}

			callback(elem.sortable('serialize'));
		}
	};

	sortableElem = elem.sortable(sortable_options);
};

export const refreshSortable = () => {
	sortableElem.sortable('refresh');
};
