import {
	useState,
} from 'react';
import {
	noop,
	cloneDeep,
} from 'lodash';
import {
	Icon,
} from '@bamboohr/fabric';
import {
	Modal,
} from 'modal-legacy';

import { MigrationTable } from '../migration-table';
import { MappingTable } from '../mapping-table';
import { OverlappingDatesTable } from '../overlapping-dates-table';
import { OverlapDetail } from '../overlap-detail';

import {
	saveDatesForOverlapRecords,
} from './migration-error-modal-utils';
import {
	getCompanyDeductionsMetadata,
	getEmployeeDeductionsMetadata,
	getParentFieldsById,
	getWageMetadata,
	getFilteredRecordIdsByClientId,
} from '../../utils/utilities';

import {
	FieldsNormalized,
	MigrationErrorModalProps,
	NormalizedItemsToMap,
} from '../../utils/interfaces';

import './migration-error-modal.styl';

import { isEnabled } from 'FeatureToggle.util';
const MX_MEIN_ENABLED = isEnabled('mxMein');

export function MigrationErrorModal(props: MigrationErrorModalProps): JSX.Element {
	const [numOfFieldsChecked, setNumOfFieldsChecked] = useState(0);
	const [sheetOpen, setSheetOpen] = useState(false);
	const [sheetTitle, setSheetTitle] = useState('');
	const [overlapRecords, setOverlapRecords] = useState([]);
	const [erroredField, setErroredField] = useState(null);
	const [sheetProcessing, setSheetProcessing] = useState(false);
	const {
		allFieldIds,
		clients: {
			byId: clientsById,
			allIds: allClientIds,
		},
		groups,
		fields,
		fields: {
			allIds: errorFieldIds,
		},
		isComparisonGrouped,
		isMappingGrouped,
		isMultipleEin,
		isOpen,
		groupIcon,
		onDoneClick,
		onClose,
		fieldSelectChangeHandler,
		sectionMetadata,
		sectionType,
		bambooNeedsTrax,
		traxNeedsBamboo,
		isMappingError,
		mappingErrorFieldIds,
		overlapErrorFieldIds,
	} = props;
	const totalNumOfFields = errorFieldIds.length + overlapErrorFieldIds.length;
	const isEmployeeWage = sectionType === 'employeeWage';
	const allMappingRecordsById = { ...bambooNeedsTrax.byId, ...traxNeedsBamboo.byId };
	const overlappingErrorRecordsById = isMappingError ? allMappingRecordsById : fields.byId;

	function handleViewOverlappingDatesClick(id: string): void {
		const parentFieldsObject = getParentFieldsById(id, allFieldIds, bambooNeedsTrax.allIds, traxNeedsBamboo.allIds);
		const field = props[parentFieldsObject].byId[id];
		const {
			subGroupMetadata,
			metadata,
			overlapMetadata,
			group,
			selectedDatabase,
		} = field;
		const groupName = groups.byId[group].bamboo.name;
		const newSheetTitle = isEmployeeWage ? $.__('%1$s - View Overlapping Dates', groupName) : $.__('%1$s - View & Fix Overlapping Dates', groupName);
		let originalRecordMetadata = metadata;

		if (!isMappingError) {
			if (isEmployeeWage) {
				originalRecordMetadata = getWageMetadata(subGroupMetadata, selectedDatabase);
			} else if (sectionType === 'employeeDeductions') {
				originalRecordMetadata = getEmployeeDeductionsMetadata(subGroupMetadata, selectedDatabase);
			} else {
				originalRecordMetadata = getCompanyDeductionsMetadata(subGroupMetadata, selectedDatabase);
			}
		}

		originalRecordMetadata.id = id;

		setErroredField(field);
		setSheetTitle(newSheetTitle);
		setOverlapRecords([originalRecordMetadata, ...overlapMetadata]);
		setSheetOpen(true);
	}

	function handleOverlapDateChange(id: string, startOrEnd: string, value: string): void {
		const recordIndex = overlapRecords.findIndex(record => record.id === id);
		const newOverlapRecords = cloneDeep(overlapRecords);
		newOverlapRecords[recordIndex][startOrEnd] = value || null;
		setOverlapRecords(newOverlapRecords);
	}

	return (
		<Modal
			alternativeAction={ null }
			headline={ $.__(`We Were Unable to Save Some of Your Answers`) }
			icon="fab-triangle-exclamation-24x24"
			isOpen={ isOpen }
			onClose={ onClose }
			primaryAction={ isMappingError || (totalNumOfFields === numOfFieldsChecked) ? onDoneClick : null }
			primaryActionText={ $.__('Done') }
			sheetProps={ {
				alternativeAction: null,
				content: (
					<OverlapDetail
						isMappingTool={ isMappingError }
						onDateChange={ isEmployeeWage ? noop : handleOverlapDateChange }
						records={ overlapRecords }
						type={ sectionType }
					/>
				),
				title: sheetTitle,
				isOpen: sheetOpen,
				isProcessing: sheetProcessing,
				onClose: () => setSheetOpen(false),
				primaryAction: isEmployeeWage
					? () => setSheetOpen(false)
					: () => saveDatesForOverlapRecords(isMappingError, erroredField, overlapRecords, groups.byId, sectionType, setSheetProcessing, setSheetOpen),
				primaryActionText: isEmployeeWage ? $.__('Done') : $.__('Save'),
				type: 'largeSheet',
			} }
			type="fullScreen"
		>
			<div className="MigrationErrorModal">
				<p className="MigrationErrorModal__explanation">
					{ $.__(`A number of records were unable to save for the reasons listed below`) }.
					&nbsp;
					{ $.__(`After you are done updating these records, click 'done' and we will get you on your way!`) }
				</p>

				{ MX_MEIN_ENABLED && (
					<div className="MigrationErrorModal__tables">
						{ allClientIds.map((clientId: number) => {
							const {
								ein,
								clientName,
							} = clientsById[clientId];
							const filteredMappingErrorIds = getFilteredRecordIdsByClientId({ allIds: mappingErrorFieldIds, byId: allMappingRecordsById}, clientId);
							const filteredComparisonErrorIds = getFilteredRecordIdsByClientId(fields, clientId);
							const filteredOverlappingErrorIds = getFilteredRecordIdsByClientId(
								{ allIds: overlapErrorFieldIds, byId: overlappingErrorRecordsById } as NormalizedItemsToMap|FieldsNormalized,
								clientId
							);

							if (filteredComparisonErrorIds.length || filteredMappingErrorIds.length || filteredOverlappingErrorIds.length) {
								return (
									<div key={ clientId }>
										{ isMultipleEin && (
											<div className="MigrationErrorModal__clientInfo">
												<Icon brand={ true } name="fab-company-14x16" />
												<h5 className="MigrationErrorModal__clientName">{ clientName } - { ein }</h5>
											</div>
										) }
										{ isMappingError && !!filteredMappingErrorIds.length && (
											<div className="MigrationErrorModal__tableContainer">
												<h5 className="MigrationErrorModal__typeExplanation">
													{ getMappingErrorMessage() }
												</h5>
												<MappingTable
													bambooNeedsTrax={ bambooNeedsTrax }
													clientId={ clientId }
													groupIcon={ groupIcon }
													groups={ groups }
													hasError={ true }
													isGrouped={ isMappingGrouped }
													isMultipleEin={ isMultipleEin }
													mappingErrorFieldIds={ filteredMappingErrorIds }
													onChange={ null }
													onMetadataChange={ noop }
													sectionMetadata={ sectionMetadata }
													selectedRecordIds={ [] }
													traxNeedsBamboo={ traxNeedsBamboo }
													type={ sectionType }
												/>
											</div>
										) }

										{ !isMappingError && !!filteredComparisonErrorIds.length && (
											<div className="MigrationErrorModal__tableContainer">
												<h5 className="MigrationErrorModal__typeExplanation">
													{ $.__(`Some of the correct values you selected didn't save. Please update them manually.`) }
												</h5>
												<MigrationTable
													fields={ { allIds: filteredComparisonErrorIds, byId: fields.byId } }
													fieldSelectChangeHandler={ fieldSelectChangeHandler }
													groupIcon={ groupIcon }
													groups={ groups }
													isErrorTable={ true }
													isGrouped={ isComparisonGrouped }
													manualUpdateCheckHandler={ (fieldId, checked) => {
														setNumOfFieldsChecked(checked ? (numOfFieldsChecked + 1) : (numOfFieldsChecked - 1));
													} }
													sectionType={ sectionType }
												/>
											</div>
										) }

										{ !!filteredOverlappingErrorIds.length && (
											<div className="MigrationErrorModal__tableContainer">
												<h5 className="MigrationErrorModal__typeExplanation">
													{ $.__('Some of your record dates overlap') }
												</h5>
												<OverlappingDatesTable
													bambooNeedsTrax={ bambooNeedsTrax }
													fields={ fields }
													groups={ groups }
													isMappingSection={ isMappingError }
													manualUpdateCheckHandler={ (fieldId, checked) => {
														setNumOfFieldsChecked(checked ? (numOfFieldsChecked + 1) : (numOfFieldsChecked - 1));
													} }
													onViewAndFixClick={ handleViewOverlappingDatesClick }
													overlappingErrorFieldIds={ filteredOverlappingErrorIds }
													traxNeedsBamboo={ traxNeedsBamboo }
													type={ sectionType }
												/>
											</div>

										) }
									</div>
								);
							}
							return null;
						}) }
					</div>
				) }

				{ !MX_MEIN_ENABLED && (
					<>
						{ isMappingError && !!mappingErrorFieldIds.length && (
							<div className="MigrationErrorModal__tableContainer">
								<h4>{ getMappingErrorMessage() }</h4>
								<MappingTable
									bambooNeedsTrax={ bambooNeedsTrax }
									clientId={ allClientIds[0] }
									groupIcon={ groupIcon }
									groups={ groups }
									hasError={ true }
									isGrouped={ isMappingGrouped }
									isMultipleEin={ isMultipleEin }
									mappingErrorFieldIds={ mappingErrorFieldIds }
									onChange={ null }
									onMetadataChange={ noop }
									sectionMetadata={ sectionMetadata }
									selectedRecordIds={ [] }
									traxNeedsBamboo={ traxNeedsBamboo }
									type={ sectionType }
								/>
							</div>
						) }

						{ !isMappingError && !!errorFieldIds.length && (
							<div className="MigrationErrorModal__tableContainer">
								<h4>{ $.__(`Some of the correct values you selected didn't save. Please update them manually.`) }</h4>
								<MigrationTable
									fields={ fields }
									fieldSelectChangeHandler={ fieldSelectChangeHandler }
									groupIcon={ groupIcon }
									groups={ groups }
									isErrorTable={ true }
									isGrouped={ isComparisonGrouped }
									manualUpdateCheckHandler={ (fieldId, checked) => {
										setNumOfFieldsChecked(checked ? (numOfFieldsChecked + 1) : (numOfFieldsChecked - 1));
									} }
									sectionType={ sectionType }
								/>
							</div>
						) }

						{ !!overlapErrorFieldIds.length && (
							<div className="MigrationErrorModal__tableContainer">
								<h4>{ $.__('Some of your record dates overlap') }</h4>
								<OverlappingDatesTable
									bambooNeedsTrax={ bambooNeedsTrax }
									fields={ fields }
									groups={ groups }
									isMappingSection={ isMappingError }
									manualUpdateCheckHandler={ (fieldId, checked) => {
										setNumOfFieldsChecked(checked ? (numOfFieldsChecked + 1) : (numOfFieldsChecked - 1));
									} }
									onViewAndFixClick={ handleViewOverlappingDatesClick }
									overlappingErrorFieldIds={ overlapErrorFieldIds }
									traxNeedsBamboo={ traxNeedsBamboo }
									type={ sectionType }
								/>
							</div>

						) }
					</>
				) }
			</div>
		</Modal>
	);

	function getMappingErrorMessage() {
		if (sectionType === 'timeOff') {
			return $.__(`Please make sure that any time off types you are trying to add to either TRAX or BambooHR don't already have time off types with the same name in the respective system.`);
		}

		return $.__(`Please go into remote link, accept any changes, and make sure these records aren't archived.`);
	}
}
