import {
	Agreement,
	ReportableOnW2Value,
} from '../types/wizard-values';

export function getReportableOnW2PostData(reportableOnW2Value: ReportableOnW2Value): (boolean | null) {
	let reportableOnW2 = null;

	if (reportableOnW2Value !== '') {
		reportableOnW2 = Boolean(reportableOnW2Value === Agreement.yes);
	}

	return reportableOnW2;
}
