import { connect } from 'react-redux';

import { Path } from '../components/path';

const mapStateToProps = (state) => {
	const {
		editState,
		editingWorkflowId
	} = state;

	return {
		editState: editState,
		editingWorkflowId: editingWorkflowId
	};
};

export const PathContainer = connect(mapStateToProps)(Path);
