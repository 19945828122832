import Ctrl, { onFooterAction, onPjaxEnd, onReady } from 'ctrl.deco';
import Ajax from '@utils/ajax';
import { redirect } from 'BambooHR.util';

import { getJsonData } from 'Data.util';
import { initSharedFilesManager } from 'shared-files-manager.mod';
import { NewHirePacketAPI } from 'NHPTemplates.mod/API';
import { isEnabled } from 'FeatureToggle.util';

import './styles.styl';
import { render } from 'base/wrapped-render';
import { NewHirePacketTemplateSettings } from './onboarding/new_hire_packet_template_settings';
import { PoMicroFrontend } from 'micro-frontend.react';

let categoryUrl;
let tasklistId;
let editTaskValidationObject = {
	isValid: false,
	invalidKeys: [],
	invalidEntries: {},
};
const ASSIGNED_TO = 'assignedUserId';
const ASSIGNED_TO_BA_SELECT_SELECTOR = `#${ASSIGNED_TO}`;
const ASSIGNED_TO_EE_VALUE = 'self';
const ALLOW_EE_UPLOADS_SELECTOR = '.js-allow-ee-uploads';

@Ctrl(['/settings/tasklists/edit.php/:type?'])
class SettingsTasklistsController {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const jsonData = getJsonData('#taskList-data', true);
		let reactRoot = document.getElementById('js-SettingsPoRoot');
		if (!reactRoot) {
			reactRoot = document.createElement('div');
			reactRoot.id = 'js-SettingsPoRoot';
			document.querySelector('#settings-main-content').appendChild(reactRoot);
		}

		if (!reactRoot || !jsonData) {
			redirect('/home', $.__('Uh Oh! There was an error. Please try again or contact BambooHR support.'), 'error');
			return;
		}

		if (!window.sharedFilesManager) {
			window.sharedFilesManager = initSharedFilesManager();
		}

		const { type } = jsonData;

		render(
			<PoMicroFrontend
				key={type}
				route='/tasks/settings/task-lists/'
				{...jsonData}
				sharedFilesManager={window.sharedFilesManager}
			/>,
			reactRoot
		);
	}
}

function handleShowAllowEmployeeUploads(comparisonValue) {
	const el = document.querySelector(ALLOW_EE_UPLOADS_SELECTOR);
	if (el) {
		el.classList.toggle('hidden', comparisonValue !== ASSIGNED_TO_EE_VALUE);
	}
}

function changeFilterText() {
	const $filterMessage = $('#filterMessage');

	if ($('#filter-description').html() == '') {
		$filterMessage.text($.__('All Employees'));
	} else {
		$filterMessage.text($.__('Just These Employees'));
	}
}

function setHiddenVar(postVars) {
	let tmp = '';
	$.each(postVars, function (key, value) {
		if ($.isArray(value)) {
			$.each(value, function (index, value2) {
				tmp += `${key}=${value2}&`;
			});
		} else {
			tmp += `${key}=${value}&`;
		}
	});
	$('#hiddenFilters').val(tmp);
	changeFilterText();
}

@Ctrl('/settings/onboarding/new_hire_packet_template')
class NHPTemplatesCtrl {
	@onPjaxEnd()
	@onReady()
	ready(e, ctrl) {
		ctrl.endpointRootTemplate = '/settings/onboarding/new_hire_packet_template/';
		ctrl.packetAPI = new NewHirePacketAPI(Ajax);

		ctrl._deleteTemplate = ctrl._deleteTemplate.bind(ctrl);

		ctrl.packetAPI.getTemplates(`${ctrl.endpointRootTemplate}list`).then((data) => {
			const { list = [] } = data;
			render(
				<NewHirePacketTemplateSettings handleDelete={ctrl._deleteTemplate} templateListData={list} />,
				document.getElementById('js-NHPTemplatesApp')
			);
		});
	}

	_deleteTemplate(templateId) {
		return this.packetAPI.deleteTemplate(`/settings/onboarding/new_hire_packet_template/${templateId}`);
	}
}
