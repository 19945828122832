import { useEffect } from 'react';

import { Select } from '@fabric/select';

import Ajax from '@utils/ajax';

import { showSlidedown } from 'Alerts.mod';

import { LoadingPlaceholder } from '../loading-placeholder/loading-placeholder';

export function EffectiveDateField(props) {
	const {
		clientId,
		error,
		disabled,
		isLoading,
		items,
		name,
		placeholder,
		renderOptionContent,
		renderToggleContent,
		updateFormData,
		value,
		width,
	} = props;

	useEffect(() => {
		if (!clientId) {
			updateFormData(name, { disabled: true, value: null });
			return;
		}

		updateFormData(name, { isLoading: true, value: null });

		Ajax.get(`/settings/payroll/taxes/tax_type/effective_dates?clientId=${ clientId }`).then((response) => {
			if (response.status === 200 && response.data) {
				updateFormData(name, { disabled: false, isLoading: false, items: response.data });
			}
		}).catch(() => {
			updateFormData(name, { isLoading: false });
			showSlidedown('We were unable to generate your effective dates. Please try again or contact support@bamboohr.com', 'error');
		});
	}, [clientId]);


	function handleOnChange(selection) {
		updateFormData(name, { error: false, value: selection[0] });
	}

	let selectPlaceholder;
	if (isLoading) {
		selectPlaceholder = <LoadingPlaceholder />;
	} else {
		selectPlaceholder = disabled ? placeholder : `-${ $.__('Select') }-`;
	}

	return (
		<Select
			condition={ error ? 'error' : '' }
			isDisabled={ disabled }
			items={ items }
			name={ name }
			onChange={ handleOnChange }
			placeholder={ selectPlaceholder }
			renderOptionContent={ renderOptionContent }
			renderToggleContent={ renderToggleContent }
			selectedValues={ [value] }
			width={ width || 5 }
		/>
	);
}
