import { ReactElement, useState } from 'react';
import {
	Button,
	CardContentSpacing,
	RadioGroup,
	StandardModal,
	TextButton,
	TextField
} from '@bamboohr/fabric';
import { Stopwatch45x54 } from '@bamboohr/grim';
import { cancelTimeTrackingTrial } from 'time-tracking/http';
import { useStyles } from './styles';

type FormStepProps = {
	closeModal: () => void;
	hasRedirect?: boolean;
	onSuccess?: () => void;
	renderHeader: ReactElement;
	setStep: () => void;
};

type ComponentStatuses = {
	cancelReason: null | 'error';
	missingFunctionality: null | 'error';
	other: null | 'error';
}

export function FormStep({ closeModal, hasRedirect, onSuccess, renderHeader, setStep }: FormStepProps): ReactElement {
	const styles = useStyles();
	const [cancelReason, setCancelReason] = useState('');
	const [missingFunctionalityValue, setMissingFunctionalityValue] = useState('');
	const [otherValue, setOtherValue] = useState('');
	const [additionalFeedback, setAdditionalFeedback] = useState('');
	const [isFooterProcessing, setIsFooterProcessing] = useState(false);
	const [componentStatuses, setComponentStatuses] = useState<ComponentStatuses>({cancelReason: null, missingFunctionality: null, other: null})

	const validateForm = (): boolean => {
		let isValid = true;
		if (cancelReason === '') {
			componentStatuses.cancelReason = 'error';
			isValid = false;
		} else {
			componentStatuses.cancelReason = null;
		}

		if (cancelReason === 'missing_functionality' && missingFunctionalityValue === '') {
			componentStatuses.missingFunctionality = 'error';
			isValid = false;
		} else {
			componentStatuses.missingFunctionality = null;
		}

		if (cancelReason === 'other' && otherValue === '') {
			componentStatuses.other = 'error';
			isValid = false;
		} else {
			componentStatuses.other = null;
		}

		setComponentStatuses({...componentStatuses});
		return isValid;
	};

	const handleSubmit = (): void => {
		setIsFooterProcessing(true);
		const formIsValid = validateForm();
		if (formIsValid) {
			const cancelData = {
				cancelReason,
				missingFunctionalityValue,
				otherValue,
				additionalFeedback
			};

			cancelTimeTrackingTrial(cancelData, hasRedirect)
				.then(() => {
					if (typeof onSuccess === 'function') {
						onSuccess();
					}
				});
		} else {
			setIsFooterProcessing(false);
		}
	};

	const isSubmitDisabled = (): boolean => {
		let isDisabled = false;
		if (cancelReason === '') {
			isDisabled = true;
		}

		if (cancelReason === 'missing_functionality' && missingFunctionalityValue === '') {
			isDisabled = true;
		}

		if (cancelReason === 'other' && otherValue === '') {
			isDisabled = true;
		}

		return isDisabled;
	};

	const items = [
		{
			id: 'cannot_get_approval',
			label: $.__('Cannot Get Approval'),
			value: 'cannot_get_approval'
		},
		{
			id: 'not_ready_to_use_it',
			label: $.__('Not Ready To Use It'),
			value: 'not_ready_to_use_it'
		},
		{
			id: 'hard_to_use',
			label: $.__('Hard To Use'),
			value: 'hard_to_use'
		},
		{
			id: 'too_expensive',
			label: $.__('Too Expensive'),
			value: 'too_expensive'
		},
		{
			id: 'missing_functionality',
			label: $.__('Missing Functionality'),
			value: 'missing_functionality'
		},
		{
			id: 'other',
			label: $.__('Other:'),
			value: 'other'
		}
	];

	return (
		<StandardModal.Body
			renderFooter={(
				<StandardModal.Footer
					actions={[
						<Button data-bi-id="time-tracking-trial-cancel-trial-button" key="cancel-trial-button" disabled={ isSubmitDisabled() } onClick={ handleSubmit } processing={ isFooterProcessing }>{ $.__('Cancel Trial') }</Button>,
						<Button key="go-back-button" color="secondary" onClick={ setStep } processing={ isFooterProcessing } variant="outlined">{ $.__('Back') }</Button>,
						<TextButton key="cancel-button" onClick={ closeModal } processing={ isFooterProcessing }>{ $.__('Cancel') }</TextButton>
					]}
				/>
			)}
			renderHeader={ renderHeader }
		>
			<StandardModal.Constraint
				spacingOverrides={{
					bottom: CardContentSpacing.MEDIUM,
					top: CardContentSpacing.MEDIUM
				}}
			>
				<StandardModal.HeroHeadline
					icon={(
						<span className={ styles.icon }>
							<Stopwatch45x54 />
						</span>
					)}
					text={ $.__('Help Us Help You') }
				/>
				<p className={ styles.bodyText }>
					{ $.__('To better understand how we can improve our Time Tracking product for you and others, please let us know what led to your decision to cancel your trial.') }
				</p>
				<RadioGroup
					items={ items }
					label={ $.__(`Select the main reason things didn't work out:`) }
					onChange={ ({value}) => {
						setCancelReason(value as string);
						if (value && value !== '') {
							componentStatuses.cancelReason = null;
							setComponentStatuses({...componentStatuses});
						}
					} }
					required
					status={ componentStatuses.cancelReason }
					value={ cancelReason }
				/>
				{ cancelReason === 'missing_functionality' && (
					<div className={ styles.inlineTextField}>
						<TextField
							label={ $.__('What additional functionality are you looking for?') }
							onChange={ ({target}) => {
								setMissingFunctionalityValue(target.value);
								if (target.value && target.value !== '') {
									componentStatuses.missingFunctionality = null;
									setComponentStatuses({...componentStatuses});
								}
							} }
							required
							status={ componentStatuses.missingFunctionality }
							value={ missingFunctionalityValue }
						/>
					</div>
				)}
				{ cancelReason === 'other' && (
					<div className={ styles.inlineTextField}>
						<TextField
							label={ $.__(`Please tell us why Time Tracking wasn’t a good fit.`) }
							onChange={ ({target}) => {
								setOtherValue(target.value);
								if (target.value && target.value !== '') {
									componentStatuses.cancelReason = null;
									setComponentStatuses({...componentStatuses});
								}
							} }
							required
							status={ componentStatuses.other }
							value={ otherValue }
						/>
					</div>
				)}
				<TextField
					classes={ {root: styles.textArea} }
					label={ $._('Any additional feedback or information you could give us on how to improve?') }
					multiline={ true }
					onChange={ ({target}) => setAdditionalFeedback(target.value) }
					rows={ 4 }
					value={ additionalFeedback }
				/>
			</StandardModal.Constraint>
		</StandardModal.Body>
	);
}
