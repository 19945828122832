export const BASE_URL_AJAX = '/ajax/dialog/approvals';
export const BASE_URL_WORKFLOWS = '/settings/workflows';
export const CONTAINER_SELECTOR = '#settings-main-content';
export const DELETE_APPROVAL = 'delete_approval';
export const DELETE_MODAL = 'delete_modal';
export const DISABLE_APPROVAL = 'disable_approval';
export const DISABLE_MODAL = 'disable_modal';
export const ENABLE_APPROVAL = 'enable_approval';
export const LEVEL_ID_PREFIX = 'level_';
export const MAX_LEVELS = 5;
export const PARENT_LINK_SELECTOR = '.settingsApprovalsLink';
export const REACT_CONTAINER = 'workflowSettings';
export const REACT_CONTAINER_SELECTOR = `#${ REACT_CONTAINER }`;
export const RESTORE_MODAL = 'restore_modal';
export const RESTORE_TEMPLATE = 'restore_template';
export const ROLES_ORDER = [
	'user group',
	'owner',
	'owner/admin',
	'reports to',
	'managers manager',
	'specific person'
];
export const SETTINGS_SELECTED = 'settings-selected';
export const SETTINGS_SUB_NAV_SELECTOR = '.settings-sub.Settings__subNav';
export const SPECIFIC_PERSON = 'specific person';
export const USER_GROUP = 'user group';
