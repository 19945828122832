import Popover from 'Popover.mod';
import {
	handleWithholdingData,
	populateFederalAndState,
	validateTaxStates
} from '../../../payroll/withholding';
import { ifFeature } from '@utils/feature';


// BEGIN Fuctions for New Trax Withholding Initialization
export function initializeNewTraxWithholding(employeeId, form, submitBtn, $cancelBtn, handleCancel) {
	if (submitBtn) {
		submitBtn.addEventListener('click', () => saveTraxPayInfoForm(employeeId, form, submitBtn), false);
	}

	window.customFormChangedAction = () => saveTraxPayInfoForm(employeeId, form, submitBtn);

	if ($cancelBtn.length) {
		$cancelBtn
			.addClass('no-form-changed-popup')
			.click(() => {
				handleCancel();
				window.enableFormChangeTracking('.js-payinfoForm');
			});
	}
}

function saveTraxPayInfoForm(employeeId, form, submitBtn) {
	if (validateTraxPayInfoFields()) {
		submitBtn.classList.add('processing');
		$.ajax({
			type: 'POST',
			url: `/payroll_ajax/taxDeductionInformation/${ employeeId }`,
			data: $(form).serializeArray(),
		})
			.success((data) => {
				if (data.success) {
					window.setMessage($.__('Your changes have been saved.'), 'success');
					// Passing in true because we do not want the form to reset
					window.bhrFormCancel('#payInfoForm', true);
					window.location.reload();
				} else {
					window.setMessage(data.message, 'error');
				}
				submitBtn.classList.remove('processing');
				window.SimpleModal.closeModal();
			})
			.fail((data) => {
				let errorMsg = $.__('There was an error saving your data');

				if (data && typeof data.getResponseHeader === 'function') {
					const reqErrorMsg = data.getResponseHeader('x-bamboohr-error-messsage');

					if (reqErrorMsg) {
						errorMsg = reqErrorMsg;
					}
				}

				window.SimpleModal.closeModal();
				window.setMessage(errorMsg, 'error');
				submitBtn.classList.remove('processing');
			});
	} else {
		window.SimpleModal.closeModal();
		window.setMessage($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
	}
}

function validateTraxPayInfoFields() {
	const $stateLocationSelect = $('.js-stateLocationSelect');
	const $stateLocationFieldBox = $('.js-stateLocationFieldBox');
	const $stateStatusSelect = $('.js-stateStatusSelect');
	const $stateStatusFieldBox = $('.js-stateStatusFieldBox');
	const $stateStatusExemptCheckbox = $('.js-stateStatusExemptCheckbox');
	const $uiStateLocationSelect = $('.js-uiStateLocationSelect');
	const $uiStateLocationFieldBox = $('.js-uiStateLocationFieldBox');
	const $uiStateExemptCheckbox = $('.js-uiStateExempt');
	const $stateWithholdingInput = $('.js-stateAdditionalWithholding');
	const $fedWithholdingInput = $('.js-fedAdditionalWithholding');
	let valid = true;

	const maxWithholdingAmount = ifFeature('TAX_BUGS_CLEANUP', 999999999.99);
	
	if ($stateStatusSelect.length > 0) {
		if (($stateStatusSelect.length && $stateStatusSelect.val() === '') && ($stateStatusExemptCheckbox.length && !$stateStatusExemptCheckbox.prop('checked'))) {
			valid = false;
			$stateStatusFieldBox.addClass('error');
		}
	
		if ($stateLocationSelect.length && $stateLocationSelect.val() === '') {
			valid = false;
			$stateLocationFieldBox.addClass('error');
		}
	
		if ($uiStateExemptCheckbox.length && $uiStateLocationSelect.length && $uiStateLocationSelect.val() === '' && !$uiStateExemptCheckbox.prop('checked')) {
			valid = false;
			$uiStateLocationFieldBox.addClass('error');
		}
	}
	if (maxWithholdingAmount !== undefined){
		const stateValue = parseFloat($stateWithholdingInput.val().replace('$', '').replaceAll(',', '')) || 0;
		const fedValue = parseFloat($fedWithholdingInput.val().replace('$', '').replaceAll(',', '')) || 0;

		if ($stateWithholdingInput.length && (stateValue > maxWithholdingAmount || stateValue < 0)){
			valid = false;
			$stateWithholdingInput.addClass('fab-TextInput--error');
		}

		if ($fedWithholdingInput.length && (fedValue > maxWithholdingAmount || fedValue < 0)){
			valid = false;
			$fedWithholdingInput.addClass('fab-TextInput--error');
		}
	}

	return valid;
}

// END Fuctions for New Trax Withholding Initialization

// BEGIN Functions for Old Withholding Initialization
export function initializeOldWithholding(fedPerms, statePerms, hasTrax, employeeId, withholdings, submitBtn, form, handle2020Update, isNewHirePacket) {
	const options = isNewHirePacket
		? {
			federal: '/self_onboarding/packet/payroll_ajax/taxDeductions/federal/',
			state: '/self_onboarding/packet/payroll_ajax/taxDeductions/state/'
		}
		: null;

	populateFederalAndState(
		fedPerms,
		statePerms,
		hasTrax,
		employeeId,
		withholdings,
		options,
		handle2020Update,
	);

	if (submitBtn && !isNewHirePacket) {
		submitBtn.addEventListener('click', () => savePayinfoForm(submitBtn, form, hasTrax, fedPerms, statePerms, employeeId, withholdings, handle2020Update), false);
	}
}

function savePayinfoForm(submitBtn, form, hasTrax, fedPerms, statePerms, employeeId, withholdings, handle2020Update) {
	// Tax fields are required
	if (!validateTaxStates()) {
		window.setMessage($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
		return;
	}

	enableTaxFieldsBrieflyPriorToSaveIfTaxExemptFlagIsSet(form, hasTrax);

	submitBtn.classList.add('processing');
	$.ajax({
		type: 'POST',
		url: `/payroll_ajax/taxDeductions/${ employeeId }`,
		data: $(form).serializeArray(),
	})
		.success((data) => {
			if (data.success) {
				populateFederalAndState(
					fedPerms,
					statePerms,
					hasTrax,
					employeeId,
					withholdings,
					{update: true},
					handle2020Update,
				);
				window.setMessage($.__('Your changes have been saved.'), 'success');
				// Passing in true because we do not want the form to reset
				window.bhrFormCancel('#payInfoForm', true);
			} else {
				window.setMessage(data.message, 'error');
			}

			submitBtn.classList.remove('processing');
		})
		.fail(function() {
			window.setMessage($.__('There was an error saving your data'), 'error');
			submitBtn.classList.remove('processing');
		});

	disableTaxFieldsIfTaxExemptFlagIsSet(form, hasTrax);
}

function enableTaxFieldsBrieflyPriorToSaveIfTaxExemptFlagIsSet(form, hasTrax) {
	const variables = $(form).serializeArray();
	if (hasTrax && elementExistsAndValueIsEqualTo(variables, 'fed[taxExempt]', 'on')) {
		enableTaxFields(); // we want to enable the disabled tax fields so any values can be read in and saved
	}
}

function disableTaxFieldsIfTaxExemptFlagIsSet(form, hasTrax) {
	const variables = $(form).serializeArray();
	if (hasTrax && elementExistsAndValueIsEqualTo(variables, 'fed[taxExempt]', 'on')) {
		disableTaxFields(); // we want to disable any tax fields so they can't change values unless they un-check the tax exempt flag
	}
}

function elementExistsAndValueIsEqualTo(variableList, elementName, elementValue) {
	for (let i = 0; i < variableList.length; i++)	{
		if ((variableList[i].name === elementName) && (variableList[i].value === elementValue)) {
			return true;
		}
	}
	return false;
}

function enableTaxFields() {
	$('.js-wholdings-federal-status').enable();
	$('select').trigger('liszt:updated');
	$('.js-wholdings-federal-dependents').enable();
	$('.js-fedWithholdExtraType-1').enable();
	$('.js-fedWithholdExtraType-2').enable();
	$('.js-wholding-federal-extra').enable();
	$('.js-headOfHousehold').enable();
	$('.js-headOfHouseholdText').show();
}

function disableTaxFields() {
	$('.js-wholdings-federal-status').disable();
	$('select').trigger('liszt:updated');
	$('.js-wholdings-federal-dependents').disable();
	$('.js-headOfHousehold').disable();
	$('.js-headOfHouseholdText').hide();
}

// END Functions for Old Withholding Initialization

// BEGIN Functions for "Other" Withholding Initialization
export function initializeOtherWithholding(fedPerms, statePerms, hasTrax, employeeId, withholdings, submitBtn, form) {
	handleWithholdingData(withholdings);

	if(submitBtn){
		submitBtn.addEventListener('click', () => savePayinfoForm(submitBtn, form, hasTrax, fedPerms, statePerms, employeeId, withholdings), false);
	}

}

// BEGIN Functions for Employee Tax State Initialization

export function initializeEmployeeTaxStates() {

	const $taxContainer = $('.js-employee_tax_states_container');
	const $taxStates = $('.js-employee_tax_states');
	const $unemploymentState = $('.js-employee_unemployment_state');
	const $residentTaxState = $('.js-employee_resident_tax_state');
	let stateWarningPopover;

	const createWarningPopover = () => {
		return Popover.create('.js-employee_unemployment_state_box', {
			template: {
				name: 'popover-standard',
				data: {
					content: $.__('The selected unemployment state is different than the resident tax state.')
				}
			},
			position: 'right',
			persistent: true,
			toggleOnClick: false,
			onHide() {
				stateWarningPopover.suppress();
			},
			tailOnAnchor: '32px'
		});
	};

	// Callback to be executed when resident state or unemployment state dropdowns are changed
	const taxStateChangeCallback = () => {
		if ($residentTaxState.val() !== $unemploymentState.val()) {
			if (stateWarningPopover) {
				stateWarningPopover.release().show();
			} else {
				stateWarningPopover = createWarningPopover();
			}
		} else if (stateWarningPopover) {
			stateWarningPopover.suppress();
		}
	};

	// Since $taxStates may not exist on every ee tab, we need to check
	if ($taxStates.length) {
		$taxStates.trigger('liszt:updated');
		$unemploymentState.trigger('liszt:updated');
		$residentTaxState.trigger('liszt:updated');
	}

	// We want to initially hide both fields if only 1 tax state is returned
	if ($taxStates.find('option').size() === 1) {
		$taxContainer.hide();
	} else {
		$taxStates.show();
	}

	$taxStates.on('change', function() {
		const value = $(this).val();

		if (!value) { return; }

		// hide all of these
		$('.js-state-withholding').addClass('hidden');

		// now show only the state withholding information for the tax state selected
		$(`.js-state-withholding[data-state-tax=${  value  }]`).removeClass('hidden');
	});

	// Attach change event listeners to Unemployment State and Resident Tax State selects
	$unemploymentState.change(taxStateChangeCallback);
	$residentTaxState.change(taxStateChangeCallback);
}
