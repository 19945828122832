import c from 'classnames';

import './styles.styl';
export function DateSelectOption(item) {
	const classes = c(
		'DateSelectOption',
		{ 'DateSelectOption--notActive': !item.isActive }
	);

	return (
		<div className={ classes }>
			<span className="DateSelectOption__text">
				{ item.quarterText }
			</span>
			<span className="DateSelectOption__date">
				({ item.quarterDate })
			</span>
		</div>
	);
}
