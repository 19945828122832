import { PlanTypeEnumContract, coverageOptionsPlanTypes } from '../../types';

/**
 * All plan types that allow the admin to define fixed or calculated costs during plan setup.
 */
export const planTypeAllowsDefinedCosts = (planType: PlanTypeEnumContract, isBenefitsAdminEnabled?: boolean): boolean => {
	if (isBenefitsAdminEnabled) {
		return [...coverageOptionsPlanTypes, PlanTypeEnumContract.disability].includes(planType);
	}

	return coverageOptionsPlanTypes.includes(planType);
};
