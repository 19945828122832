/* @startCleanup encore */
import { Modal } from 'modal-legacy';
import './move-away-modal.styl';
/* @endCleanup encore */
import { Button, IconV2, StandardModal, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function MoveAwayModal(props) {
	const { visible, onClose, onSave, onDontSave } = props;

	/* @startCleanup encore */
	const renderModalContents = () => {
		return (
			<div>
				<div className=''>
					<div className='MoveAwayModal__text'>
						{$.__("You haven't saved your changes. Your changes will be lost if you navigate away from this page without saving.")}
					</div>
				</div>
			</div>
		);
	};

	const modalOptions = {
		type: 'small',
		title: $.__('Just Checking...'),
		isOpen: visible,
		icon: 'fab-triangle-exclamation-54x54',
		iconColor: 'attention',
		onClose,
		primaryAction: onSave,
		primaryActionText: $.__('Save Changes'),
		secondaryAction: onDontSave,
		secondaryActionText: $.__("Don't Save"),
		content: renderModalContents(),
	};
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<StandardModal isOpen={visible} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton type='button' onClick={onClose}>
								{$.__('Cancel')}
							</TextButton>,
							<Button type='button' color='secondary' onClick={onDontSave}>
								{$.__("Don't Save")}
							</Button>,
							<Button type='button' onClick={onSave}>
								{$.__('Save Changes')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={$.__('Just Checking...')} />}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon='triangle-exclamation-solid'
						iconColor='warning-medium'
						text={$.__(
							"You haven't saved your changes. Your changes will be lost if you navigate away from this page without saving."
						)}
					/>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>,
		<Modal {...modalOptions} />
	);
}
