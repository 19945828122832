import {
    useEffect,
    useMemo,
    useState
} from 'react'

import { 
    Flex,
    Select,
    Label,
} from "@bamboohr/fabric"

import { AddressFormNote } from './address-form-note';
import { SelectItem } from './select-item';
import { createFullAddressString } from './helpers';
import { showSlidedown } from 'Alerts.mod';

import { getGlobalPayees } from '../../../deductions.react/apis';
import { ifFeature } from "@bamboohr/utils/lib/feature";

// Note: Throughout this file we are using == to check for equality, because depending on where the data comes from the ID can either
//       be string or a number. We will want to change the the BE endpoint later, but we need a quick fix for this
export const PayeeSelector = ({
    allowAddPayee,
    allStates = [],
    authToken,
    customPayees = [],
    deductionType,
    federalStateObject,
    globalPayeeId,
    isPayeeFromValidator,
    isSetUpPayees,
    newPayeeId,
    selectedPayee,
    selectedState,
    setIsPayeeFromValidator,
    setSelectedPayee,
    showPayeeAddModal,
    showAddressFormNote = true,
}) => {
    const [payeeDisabled, setPayeeDisabled] = useState(true);
    const [payeeList, setPayeeList] = useState([]);
    const [selectItems, setSelectItems] = useState([]);
    const [payeeFieldName, setPayeeFieldName] = useState('deductionPayeeId');
    const [payeeNullFieldName, setPayeeNullFieldName] = useState('globalPayeeId');

    const onClearHandler = () => {
        if (!isPayeeFromValidator) {
            setSelectedPayee([]);
        }
        setIsPayeeFromValidator(false);
    }

    const payeeSelectHandler = (value) => {
        if (value[0] === 'addPayeeModal'){
            showPayeeAddModal();
        } else {
            setSelectedPayee(value);
        }
    }

    // Sets payee list
    useEffect(() => {
        setPayeeDisabled(true);

        // Child Support -> 3
	    // Tax Levy -> 18
	    // Other Garnishment -> 19

        // Note: isLegacyGlobalPayee checks if the payee is a Legacy Payee(payee that is a child support or tax levy that has a deductionPayeeId instead of a globalPayeeId)
        const isLegacyGlobalPayee = ((deductionType == 3 || deductionType == 18) && selectedPayee && globalPayeeId === null );
        if ((isLegacyGlobalPayee && selectedState.length > 0) || (deductionType == 19 && selectedState.length > 0)){
            const filteredCustomPayees = customPayees.filter((payee) => payee.stateId === selectedState[0]);
            setPayeeList(filteredCustomPayees);
            if(!isLegacyGlobalPayee) {
                setPayeeDisabled(false);
            }
        }
        if (!isLegacyGlobalPayee && authToken && (deductionType == 3 || deductionType == 18) && selectedState.length > 0){
            let garnishmentType;

            if (deductionType == 3){
                garnishmentType = 'CS';
            }
            if (deductionType == 18){
                if (selectedState[0] === federalStateObject?.id) {
                    garnishmentType = 'LF';
                } else {
                    garnishmentType = 'LS';
                }
            }

            const stateAbbreviation = [...allStates, federalStateObject].find(state => parseInt(state.id) === parseInt(selectedState[0]))?.name;

            getGlobalPayees(authToken, garnishmentType, stateAbbreviation).then(response => {
                setPayeeList(response.data);
                setPayeeDisabled(false);
            }).catch(() => {
                showSlidedown($.__('Uh oh...something went wrong getting your payees. Please refresh and try again.'), 'error');
            });
        }
    }, [allStates, authToken, customPayees, deductionType, selectedState, setPayeeDisabled, setPayeeList])

    useEffect(() => {
        if (newPayeeId && payeeList.find((payee) => payee.id === newPayeeId)){
            setSelectedPayee([newPayeeId]);
        }
    }, [newPayeeId, payeeList, setSelectedPayee])

    // Clears selected payee when the state is cleared and a payee is selected
    useEffect(() => {
        if (selectedState.length === 0 && selectedPayee.length > 0) {
            setSelectedPayee([]);
        }
    }, [selectedState])

    // Creates the items for the select component
    useEffect(() => {
		const items = [];
		if (payeeList.length !== 0) {
            const payeeItems = payeeList.map((payee) => {
                const {
                    addressLine1,
                    addressLine2,
                    city,
                    payeeName,
                    state,
                    stateId,
                    zipcode,
                } = payee;

                const addressInformation = {
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    stateId,
                    zipcode,
                }

                return {
                    type: 'name-and-address',
                    address: createFullAddressString(allStates, addressInformation),
                    payeeName,
                    text: payee.payeeName,
                    value: payee.id,
                    key: payee.id,
                };
            });
            const payeeItemGroup = {
                items: payeeItems,
                type: 'group',
                value: 'allPayees'
            };
			items.push(payeeItemGroup);
		} else {

            let noPayeeMessageText;
			if (deductionType == 19){
                noPayeeMessageText = $.__(`You don't have any payees set up yet. You can add one now.`)
            } else {
                noPayeeMessageText = $.__(`There are no payees for this state. Please contact Payroll Support to add.`)
            }
            
            const noPayeeMessage = {
				type: 'group',
                items: [
                    {
                        type: 'text',
                        key: 'noPayeeMessageText',
                        isDisabled: true,
                        text: noPayeeMessageText
                    },
                ],
                value: 'noPayeeMessageText'
			};
			items.push(noPayeeMessage);
		}

        if (allowAddPayee && deductionType == 19) {
			items.push({
				type: 'group',
				anchor: 'bottom',
				items: [
					{
                        type: 'text-button',
                        text: `+ ${$.__('Add Payee')}`,
						value: 'addPayeeModal',
                        key: 'addPayeeModal'
					},
				],
                value: 'addPayee'
			});
		}
        setSelectItems(items);
    }, [allowAddPayee, deductionType, payeeList, setSelectItems ]) 

    // Sets payee field name when deduction type changes
    useEffect(() => {
        if (deductionType == 19){
            setPayeeFieldName('deductionPayeeId');
            setPayeeNullFieldName('globalPayeeId');
        } else {
            setPayeeFieldName('globalPayeeId');
            setPayeeNullFieldName('deductionPayeeId');
        }
    }, [deductionType, setPayeeFieldName, setPayeeNullFieldName])

    // DisablesPayee when state isn't selected
    useEffect(() => {
        if(selectedState.length === 0){
            setPayeeDisabled(true);
        }
    }, [selectedState, setPayeeDisabled])

    const fullPayee = useMemo(() => {
        if (selectedPayee.length > 0) {
            return payeeList.find(payee => payee.id === selectedPayee[0]);
        }
        return null;
    }, [selectedPayee, payeeList])

    return(
        <Flex flexDirection="column">
            {!isSetUpPayees && <Label disabled={payeeDisabled}>{$.__('Payee')}</Label>}
            <Flex flexDirection="column">
                <div>
                    <Select
                        id='payeeSelect'
                        isClearable={ true }
                        isDisabled={ payeeDisabled }
                        items={ selectItems }
                        name={ payeeFieldName }
                        onClear={ () => onClearHandler() }
                        onSelect={ (value, currentValue) => payeeSelectHandler(currentValue) }
                        placeholder={ payeeDisabled && $.__('First Select Payee State') }
                        renderOptionContent={(item, state) => { return <SelectItem item={item} state={state}/> }}
                        required={ true }
                        searchThreshold={ 5 }
                        selectedValues={ selectedPayee }
                        size={ifFeature('encore', isSetUpPayees && "small")}
                        width={ 9 }
                    />
                </div>
                { showAddressFormNote && fullPayee &&
                    <AddressFormNote allStates={ allStates } selectedPayee={ fullPayee }/>
                }
                <input id='otherPayeeTypeNull' name={ payeeNullFieldName } type='hidden'  value={ undefined } />
            </Flex>
        </Flex>
    )
}
