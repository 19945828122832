import Ctrl, { onPjaxEnd, onReady } from 'ctrl.deco';
import { render } from 'base/wrapped-render';
import { initFilesManager } from 'shared-files-manager.mod/training-files-manager';
import { PoMicroFrontend } from 'micro-frontend.react';
import { getJsonData } from 'Data.util';
import { isEnabled } from 'FeatureToggle.util';

import './add-edit';
import './styles.styl';

@Ctrl(['/settings/training/edit/:trainingTypeId?', '/settings/training/add'])
class SettingsTaskListCtrl {
	constructor() {
		window.sharedFilesManager = initFilesManager();
	}
}


@Ctrl('/settings/training/list')
class SettingsTrainingListCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const root = document.getElementById('trainingListRoot');
		const trainings = getJsonData('.js-pageData-trainings');
		const categories = getJsonData('.js-pageData-categories');
		const settings = getJsonData('.js-pageData-trainingSettings');
		if (root) {
			render(
				<PoMicroFrontend
					customClassNamePrefix='training-list'
					route='/settings/training/list'
					trainings={trainings}
					categories={categories}
					trackingSettings={settings}
				/>,
				root
			);
		}
	}
}