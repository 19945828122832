import { Component } from 'react';
import { disableEnps, handleRequest } from './service';
import { Promo } from './promo';
import { Setup } from './setup';
import { Status } from './status';

const STEP_COMPONENTS = [Promo, Setup, Status];

export class Steps extends Component {
	constructor(props) {
		super(props);

		const { data } = this.props;
		this.state = {
			stepData: data.stepData,
			stepNumber: data.stepNumber,
		};
	}

	_handleCancel = () => {
		const { isEditing } = this.state;

		if (isEditing) {
			this.setState({ stepNumber: 2, isEditing: false });
		} else {
			this.setState({ stepData: {}, stepNumber: 0, isEditing: false });
			disableEnps();
		}
	};

	_handleEdit = () => {
		this.setState({ stepNumber: 1, isEditing: true });
	};

	_handleNext = (currentStepData) => {
		return handleRequest(currentStepData).then((data) => {
			const scrollContainer = document.getElementById(
				'js-GlobalScrollContainer',
			);

			if (data.nextStep === false) {
				return;
			}

			const { stepNumber } = this.state;

			scrollContainer?.scrollTo({ top: 0, behavior: 'smooth' });
			const newStepNumber =
				stepNumber + 1 < STEP_COMPONENTS.length ? stepNumber + 1 : stepNumber;
			this.setState({
				isEditing: false,
				stepData: data.enpsData.stepData,
				stepNumber: newStepNumber,
			});
		});
	};

	render() {
		const { isEditing, stepData, stepNumber, surveyId } = this.state;
		const Component = STEP_COMPONENTS[stepNumber] || STEP_COMPONENTS[0];
		const componentData = stepData[stepNumber];

		return (
			<Component
				data={componentData}
				isEditing={isEditing}
				onCancel={this._handleCancel}
				onEdit={this._handleEdit}
				onNext={this._handleNext}
				stepNumber={stepNumber}
				surveyId={surveyId}
			/>
		);
	}
}
