import { Headline } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import './radio-button.styl';

export function RadioButton(props) {
	const { choice, onChoiceClick, selectedValue } = props;
	const isButtonSelected = selectedValue === choice.value;
	return (
		<button
			className={classNames('EnpsRadioButton__number baseBorderColor--hover', {
				'EnpsRadioButton__number--selected baseColorBg baseBorderColor': isButtonSelected,
				baseColorHover: selectedValue !== choice.value,
			})}
			onClick={() => {
				onChoiceClick(choice);
			}}
			type='button'
			value={choice.value}
		>
			{ifFeature(
				'encore',
				<Headline color={isButtonSelected ? 'neutral-inverted' : 'neutral-weak'} size='small'>
					{choice.value}
				</Headline>,
				choice.value
			)}
		</button>
	);
}
