import {
	clientTaxSettingName_suppressW2Box12Dd,
} from '../constants';
import type {
	ClientTaxSettingsResponseDataCollection,
	ClientTaxSettingSuppressW2Box12Dd,
} from '../types';

export function findW2Box12DdOption(clientTaxSettings: ClientTaxSettingsResponseDataCollection): ClientTaxSettingSuppressW2Box12Dd | null {
	if (!Array.isArray(clientTaxSettings)) {
		return undefined;
	}
	const tax = clientTaxSettings.find((clientTaxSetting) => {
		return clientTaxSetting.settingName === clientTaxSettingName_suppressW2Box12Dd;
	});
	return tax as ClientTaxSettingSuppressW2Box12Dd;
}
