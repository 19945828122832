import { Interstitial } from '@fabric/interstitial';
import { Icon } from '@bamboohr/fabric';

import './trial-expired.styl';

export function TrialExpired() {
	return (
		<Interstitial renderFooterEtc={ () => null }>
			<div className="TrialExpired">
				<Icon brand={ true } name="fab-alarm-clock-72x72" />
				<h4 className="TrialExpired__header">{ $.__(`Time's up!`) } { $.__('Your trial has ended') }</h4>
				<p className="TrialExpired__thanks">
					<span>{ $.__('Thanks a bunch for trying out BambooHR!') } </span>
					<span>{ $.__('If you have any additional questions or would like to subscribe, you can call us at 1-866-387-9595') }.</span>
				</p>
			</div>
		</Interstitial>
	);
}
