import { Typography, makeStyles } from '@bamboohr/fabric';
import { PlanCategoryDataOptionsContract, PlanRateTypeEnum } from 'base/settings/benefits/plan-wizard.react/types';
import { getCostsMessage } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
	message: {
		color: theme.palette.gray[800],
		lineHeight: '15px',
		paddingBottom: '16px',
	},
}));

interface VariableCostsMessageProps {
	planCategoryDataOptions: PlanCategoryDataOptionsContract;
	rateType: PlanRateTypeEnum;
}

export const VariableCostsMessage = (props: VariableCostsMessageProps): JSX.Element => {
	const { planCategoryDataOptions, rateType } = props;
	const styles = useStyles();

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const costsMessage: string | null = getCostsMessage(rateType, planCategoryDataOptions);

	return costsMessage ? (
		<Typography className={styles.message} variant='medium'>
			{costsMessage}
		</Typography>
	) : (
		<></>
	);
};
