import {
	PlanWizardRuleName,
	EligibilityGroupValues,
	RemoveSpecificError,
	EmployeeCostTypeValue,
	CoverageLevelValue,
	CostRow,
} from '../../types';
import { generateEligibilityFiltersErrorKey, generateEmployeeCostErrorKey } from './error-keys';
import {
	validateCoexistenceWithOtherGroups,
	validateMaxEmployeeCost,
	validateEligibilityFilters,
} from './special-errors';
import { RuleName, SetSpecialErrors } from '../../../types';

export const validateEmployeeCostsForTotalCostChange = (
	linkId: string,
	totalCost: number | null,
	eligibilityGroups: EligibilityGroupValues[],
	employeeCostType: EmployeeCostTypeValue,
	validationContext: {
		setSpecialErrors: SetSpecialErrors,
		removeSpecificError: RemoveSpecificError
	}
): void => {
	const {
		setSpecialErrors,
		removeSpecificError,
	} = validationContext;

	eligibilityGroups.forEach((group) => {
		const coverageCost = group.coverageCosts.find(cost => cost.coverageLevelLinkId === linkId);
		if (coverageCost && coverageCost.employeeCost !== null) {
			const errorKey = generateEmployeeCostErrorKey(group.id, linkId);

			const isValid = validateMaxEmployeeCost(
				errorKey,
				{ employeeCostType, totalCost, employeeCost: coverageCost.employeeCost },
				setSpecialErrors
			);
			if (isValid) {
				removeSpecificError(errorKey, RuleName.lessThanOrEqualTo);
			}
		}
	});
};

export const validateEmployeeCostForCostTypeChange = (
	errorKey: string,
	costRow: CostRow,
	setSpecialErrors: SetSpecialErrors
): void => {
	if (costRow.employeeCost === null) {
		return;
	}

	validateMaxEmployeeCost(errorKey, costRow, setSpecialErrors);
};

export const validateEmployeeCostsForCostTypeChange = (
	employeeCostType: EmployeeCostTypeValue,
	eligibilityGroups: EligibilityGroupValues[],
	coverageLevels: CoverageLevelValue[],
	setSpecialErrors: SetSpecialErrors
): void => {
	eligibilityGroups.forEach((group) => {
		group.coverageCosts.forEach((coverageCost) => {
			const { coverageLevelLinkId, employeeCost } = coverageCost;
			const errorKey = generateEmployeeCostErrorKey(group.id, coverageLevelLinkId);
			const coverageLevel = coverageLevels.find(level => level.linkId === coverageLevelLinkId);
			const totalCost = coverageLevel ? coverageLevel.totalCost : null;

			validateEmployeeCostForCostTypeChange(
				errorKey,
				{ totalCost, employeeCostType, employeeCost },
				setSpecialErrors
			);
		});
	});
};

export const validateGroupFilterChange = (
	groups: EligibilityGroupValues[],
	modifiedIndex: number,
	validationContext: {
		setSpecialErrors: SetSpecialErrors,
		removeSpecificError: RemoveSpecificError
	}
): void => {
	const {
		setSpecialErrors,
		removeSpecificError,
	} = validationContext;

	// Run complete validation on the modified group
	validateEligibilityFilters(groups, modifiedIndex, setSpecialErrors);

	// Check for filter overlap on all the other groups
	groups.forEach((eligibilityGroup, index) => {
		if (index !== modifiedIndex) {
			const isGroupValid = validateCoexistenceWithOtherGroups(groups, index, setSpecialErrors);
			if (isGroupValid) {
				const errorKey = generateEligibilityFiltersErrorKey(eligibilityGroup.id);
				removeSpecificError(errorKey, PlanWizardRuleName.filterOverlap);
			}
		}
	});
};
