import { Icon, Table, Flex, BodyText, IconV2, TextButton } from '@bamboohr/fabric';

import { BANK_APP_STATE, takeToMultiEinEditBankPage, getAppStateFromData } from '../../utils/shared';

import './multi-banks-table.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function MultiBanksTable(props) {
	const COLUMNS = [
		{
			header: $.__('Account Nickname'),
			cell: (row) => renderBankName(row),
			sortBy: (row) => row.accountNickname,
		},
		{
			header: $.__('Account #'),
			cell: (row) => <div>{row.accountNumber}</div>,
			sortBy: (row) => row.accountNumber,
		},
		{
			header: $.__('Routing #'),
			cell: (row) => <div>{row.routingNumber}</div>,
		},
		{
			header: $.__('Account Type'),
			cell: (row) => <div>{row.accountType}</div>,
		},
		{
			header: $.__('Status'),
			cell: (row) => renderStatus(row),
		},
	];

	const renderBankName = (row) => {
		const { accountNickname, bankName, clientId, bankId } = row;

		let name = accountNickname ? accountNickname : bankName;

		return (
			<span className='MultiBanksTable__companyLegalName'>
				<TextButton clickAction={() => takeToMultiEinEditBankPage(clientId, bankId)} text={name} type='button' />
			</span>
		);
	};

	const statusBankSaved = (row) => {
		const { handleOpenDownloadModal } = props;
		const tempText = $.__('Download Forms');

		return ifFeature(
			'encore',
			<Flex flexDirection='column' alignItems='flex-start'>
				<Flex gap={0.75} alignItems='center'>
					<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
					<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
				</Flex>
				<TextButton onClick={() => handleOpenDownloadModal(row)} inline type='button'>
					{tempText}
				</TextButton>
			</Flex>,
			<div className='MultiBanksTable__statusPending'>
				<span className='MultiBanksTable__statusPendingRow'>
					<span className='MultiBanksTable__statusPendingIcon'>
						<Icon name='fab-hourglass-13x16' />
					</span>

					<span className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</span>
				</span>

				<div className='CompaiesTable__statusPendingLink'>
					<TextButton clickAction={() => handleOpenDownloadModal(row)} inline={true} text={tempText} type='button' />
				</div>
			</div>
		);
	};

	const statusDocsDownloaded = (row) => {
		const { handleOpenUploadModal } = props;
		const tempText = $.__('Upload Signed Forms');

		return ifFeature(
			'encore',
			<Flex flexDirection='column' alignItems='flex-start'>
				<Flex gap={0.75} alignItems='center'>
					<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
					<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
				</Flex>
				<TextButton onClick={() => handleOpenUploadModal(row)} inline type='button'>
					{tempText}
				</TextButton>
			</Flex>,
			<div className='MultiBanksTable__statusPending'>
				<span className='MultiBanksTable__statusPendingRow'>
					<span className='MultiBanksTable__statusPendingIcon'>
						<Icon name='fab-hourglass-13x16' />
					</span>

					<span className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</span>
				</span>

				<div className='MultiBanksTable__statusPendingLink'>
					<TextButton clickAction={() => handleOpenUploadModal(row)} inline={true} text={tempText} type='button' />
				</div>
			</div>
		);
	};

	const statusDocsUploaded = (row) => {
		const { handleOpenVerificationModal } = props;

		return ifFeature(
			'encore',
			<Flex flexDirection='column' alignItems='flex-start'>
				<Flex gap={0.75} alignItems='center'>
					<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
					<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
				</Flex>
				<TextButton onClick={() => handleOpenVerificationModal(row)} inline type='button'>
					{$.__('Verify Account')}
				</TextButton>
			</Flex>,
			<div className='MultiBanksTable__statusPending'>
				<span className='MultiBanksTable__statusPendingRow'>
					<span className='MultiBanksTable__statusPendingIcon'>
						<Icon name='fab-hourglass-13x16' />
					</span>

					<span className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</span>
				</span>

				<div className='CompaiesTable__statusPendingLink'>
					<TextButton clickAction={() => handleOpenVerificationModal(row)} inline={true} text={$.__('Verify Account')} type='button' />
				</div>
			</div>
		);
	};

	const statusVerifingManualMode = () => {
		return ifFeature(
			'encore',
			<Flex flexDirection='column' alignItems='flex-start'>
				<Flex gap={0.75} alignItems='center'>
					<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
					<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
				</Flex>
				<BodyText color='neutral-weak'>{$.__('Verifying deposit amount')}</BodyText>
			</Flex>,
			<div className='MultiBanksTable__statusPending'>
				<span className='MultiBanksTable__statusPendingRow'>
					<span className='MultiBanksTable__statusPendingIcon'>
						<Icon name='fab-hourglass-13x16' />
					</span>

					<div className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</div>
				</span>
				<div className='MultiBanksTable__statusVerifyingText'>{$.__('Verifying deposit amount')}</div>
			</div>
		);
	};

	const statusVerified = (row) => {
		return ifFeature(
			'encore',
			<Flex gap={0.75} alignItems='center'>
				<IconV2 name='circle-check-solid' color='success-strong' size={16} />
				<BodyText color='success-strong'>{$.__('Verified')}</BodyText>
			</Flex>,
			<div className='MultiBanksTable__statusVerified'>
				<Icon name='fab-check-circle-14x14' />
				<span className='MultiBanksTable__statusVerifiedText'>{$.__('Verified')}</span>
			</div>
		);
	};

	const renderStatus = (row) => {
		// sick.. ugly.. gross..
		const company = [{ bankAccounts: [] }];
		company[0].bankAccounts.push(row);

		if (row.penniesHasBeenStored && !row.isVerified) {
			return statusVerifingManualMode();
		}

		if (row.penniesHasBeenStored && row.isVerified) {
			return statusVerified(row);
		}

		const state = row.recordId ? BANK_APP_STATE.PRE_EIN : getAppStateFromData(company);

		switch (state) {
			case BANK_APP_STATE.PRE_EIN:
				return null;

			case BANK_APP_STATE.BANK_SAVED:
				return statusBankSaved(row);

			case BANK_APP_STATE.DOCS_DOWNLOADED:
				return statusDocsDownloaded(row);

			case BANK_APP_STATE.DOCS_UPLOADED:
				return statusDocsUploaded(row);

			case BANK_APP_STATE.VERIFIED:
				return statusVerified(row);

			default:
				break;
		}
	};

	const { company } = props;

	return (
		<Table
			caption={$.__('List of Employees')}
			columns={COLUMNS}
			initialSort={{
				columnIndex: 0,
				isAsc: true,
			}}
			rowKey={(row) => {
				return row.bankId ? row.bankId : row.clientId;
			}}
			rows={company.bankAccounts}
		/>
	);
}
