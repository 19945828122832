import {
	useEffect,
	useState,
	useCallback,
} from 'react';
import {
	showSlidedown,
} from 'Alerts.mod';
import {
	cloneDeep
} from 'lodash';

import {
	TextButton,
	Dropdown,
	ItemType,
	makeStyles,
	Loader,
} from '@bamboohr/fabric';

import {
	NameAndEmployees,
	LoanCellRow,
} from '../components';
import {
	DeductionPlanCellProps
} from '../types';
import {
	assignLoan,
	deleteLoan,
	getAvailableLoanNumbers,
} from '../api';
import {
	responseMessages,
} from '../utils';


const useStyles = makeStyles({
	textButton: {
		height: 20,
	},
	loaderWrapper: {
		display: 'inline-block',
	}
});

const EMPTY_ITEM = { text: '', value: '' };

export function DeductionLoanCell(props: DeductionPlanCellProps): JSX.Element {
	const styles = useStyles();
	const {
		deduction,
		deduction: {
			created,
			allowableTypes,
			employees,
			deductionName,
			allowableLoanNumbers,
			traxCldId,
			deductionTypeId,
		},
		updateDeductions,
		updateAllowableLoanNumbersByType,
		validLoanNumbers,
	} = props;

	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<ItemType>(EMPTY_ITEM);
	const [loanItems, setLoanItems] = useState<ItemType[]>(allowableLoanNumbers);

	const errorHandler = (errorText: string): void => {
		showSlidedown(errorText, 'error');
		setIsProcessing(false);
	}

	const handleAddLoanClick = useCallback((loanNumber: ItemType) => {
		setIsProcessing(true);
		const targetLoanNumber = { bhrCldId: loanNumber.value, displayName: loanNumber.text };
		updateAllowableLoanNumbersByType('remove', deductionTypeId, [targetLoanNumber]);

		assignLoan(traxCldId, loanNumber.value).then((response) => {
			if (response.status === 200) {
				const currentDeduction = cloneDeep(deduction);
				currentDeduction.created = true;

				updateDeductions(currentDeduction);
				setSelectedItem(loanNumber);
				setIsProcessing(false);
			}
		}).catch(() => {
			updateAllowableLoanNumbersByType('add', deductionTypeId, [targetLoanNumber]);
			errorHandler(responseMessages.saveBenefitPlanError(deduction.deductionName));
		})
	}, [deduction, traxCldId, validLoanNumbers, updateDeductions,])

	const handleEditLoanClick = useCallback((bhrCldId: number) => {
		setIsProcessing(true);
		deleteLoan(bhrCldId).then((response) => {
			if (response.status === 200) {
				getAvailableLoanNumbers(traxCldId, deductionTypeId).then(response => {
					const currentDeduction = cloneDeep(deduction);
					currentDeduction.created = false;

					updateAllowableLoanNumbersByType('replace', deductionTypeId, response.data);
					updateDeductions(currentDeduction);
					setSelectedItem(EMPTY_ITEM);
					setIsProcessing(false);
				}).catch(() => {
					errorHandler(responseMessages.standardError);
				})
			}
		}).catch(() => {
			errorHandler(responseMessages.resetBenefitPlanError(deduction.deductionName));
		});
	}, [deduction, updateDeductions, deductionTypeId, traxCldId])

	useEffect(() => {
		if (created) {
			setSelectedItem({ text: allowableLoanNumbers[0].displayName,  value: allowableLoanNumbers[0].bhrCldId  });
		}
	}, [])

	useEffect(() => {
		const formattedItems = validLoanNumbers.map(({bhrCldId, displayName}) => ({text: displayName, value: bhrCldId}));
		setLoanItems(formattedItems);
	}, [validLoanNumbers])

	if (isProcessing) {
		return (
			<>
				<div className={ styles.loaderWrapper }>
					<Loader small={ true } />
				</div>
			</>
		)
	}

	// Display Plan Name, Employee Count, and Reset Text Button
	if (created) {
		return (
			<>
				<LoanCellRow label={ $.__('Loan Type') } value={ allowableTypes[0].displayName } />
				<LoanCellRow label={ $.__('Loan Number') } value={ selectedItem.text } />
				<LoanCellRow label={ $.__('Loan Name') } value={ deductionName } />
				<NameAndEmployees deductionName={ deductionName } employees={ employees } showDeductionName={ false } />
				<br />
				<TextButton
					className={ styles.textButton }
					onClick={ () => handleEditLoanClick(selectedItem.value) }
					size="small"
					type="button"
				>
					{ $.__('Reset Loan Number') }
				</TextButton>
			</>
		);
	}

	if (allowableLoanNumbers && allowableLoanNumbers.length > 0) {
		return (
			<>
				<Dropdown
					buttonSettings={ {outline: true, text: $.__('Add This Loan to BambooHR'), size: 'small'} }
					items={ loanItems }
					onSelect={ (itemValue, {item}) => handleAddLoanClick(item) }
				/>
			</>
		)
	}

	return null;
}
