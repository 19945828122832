import {
	createContext,
	useContext,
	useState,
	useEffect,
} from 'react';

import {
	getModalData,
} from './util';


/** @type {React.Context<ModalData>} */
const Context = createContext(null);

export const useModalData = () => useContext(Context);

/**
 *
 * @param {object} props
 * @param {string[]} props.userIds
 * @param {string[]} props.employeeIds
 * @param {React.ReactNode} props.children
 */
export function ModalDataProvider({
	userIds,
	employeeIds,
	children,
}) {
	/** @type {[ModalData, React.Dispatch<ModalData>]} */
	const [data, setData] = useState(null);
	const isLoading = !data;

	useEffect(() => {
		if (data) {
			setData(null);
		}

		getModalData({
			userIds,
			employeeIds,
		})
			.then(setData);
	}, [...userIds, ...employeeIds].sort());

	useEffect(() => {
		window.BambooHR.Modal.setState({
			isLoading,
		}, true);
	}, [isLoading]);

	return data && (
		<Context.Provider value={ data }>
			{ children }
		</Context.Provider>
	);
}

/**
 * @typedef User
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef Group
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef ModalData
 * @property {User[]} users
 * @property {Group[]} groups
 * @property {string[]} userIds
 * @property {string[]} employeeIds
 */
