import { intersection } from 'lodash';
import uniqueId from 'lodash/uniqueId';

import { DEFAULT_CLASS_CODES, INITIAL_FILTERS } from '../../../constants';
import {
	CoverageCostValues,
	CoverageLevelValue,
	EligibilityFilterValues,
	EligibilityGroupValues,
	PlanRateTypeEnum,
} from '../../../types';
import { getInitialCalculatorSettings, getInitialLossOfEligibility, getInitialTimeToEligibility } from '../../../utils';

export const getDefaultEligibilityFilters = (): EligibilityFilterValues => {
	return {
		areSpecificPeopleSelected: false,
		selectedEmployeeIds: [],
		filterGroup: { ...INITIAL_FILTERS },
	};
};

export const getNumberEnrolledEmployeesForGroup = (employeeIdsInGroup: number[], enrolledEmployeeIds: number[]): number => {
	return intersection(employeeIdsInGroup, enrolledEmployeeIds).length;
};

export const getEligibilityGroupsWithUniqueSpecificEmployeeSelections = (
	updateIndex: number,
	selectedEmployeeIds: number[],
	eligibilityGroups: EligibilityGroupValues[]
): EligibilityGroupValues[] => {
	// Remove the selected employees from other specific people groups
	const newEligibilityGroups = [...eligibilityGroups];
	eligibilityGroups.forEach((group, index) => {
		if (index !== updateIndex && group.eligibilityFilters.areSpecificPeopleSelected) {
			const newSelectedEmployeeIds = [...group.eligibilityFilters.selectedEmployeeIds];
			selectedEmployeeIds.forEach((selectedEmployeeId) => {
				const removeIndex = newSelectedEmployeeIds.indexOf(selectedEmployeeId);
				if (removeIndex > -1) {
					newSelectedEmployeeIds.splice(removeIndex, 1);
				}
			});

			newEligibilityGroups[index] = {
				...group,
				eligibilityFilters: {
					...group.eligibilityFilters,
					selectedEmployeeIds: newSelectedEmployeeIds,
				},
			};
		}
	});

	newEligibilityGroups[updateIndex] = {
		...eligibilityGroups[updateIndex],
		eligibilityFilters: {
			...eligibilityGroups[updateIndex].eligibilityFilters,
			selectedEmployeeIds,
		},
	};

	return newEligibilityGroups;
};

export const getDefaultEligibilityGroup = (
	coverageLevels: CoverageLevelValue[],
	rateType: PlanRateTypeEnum
): EligibilityGroupValues => {
	const coverageCosts: CoverageCostValues[] = [];

	coverageLevels.forEach((coverageLevel) => {
		if (coverageLevel.isSelected) {
			coverageCosts.push({
				coverageLevelLinkId: coverageLevel.linkId,
				employeeCost: null,
			});
		}
	});

	return {
		class: DEFAULT_CLASS_CODES,
		calculatorSettings: rateType ? getInitialCalculatorSettings(rateType) : null,
		coverageCosts,
		eligibilityFilters: getDefaultEligibilityFilters(),
		id: uniqueId('new_'),
		lossOfEligibility: getInitialLossOfEligibility(),
		timeToEligibility: getInitialTimeToEligibility(),
	};
};
