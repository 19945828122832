import { EligibilityGroupValues } from '../types';

export enum CostsTableType {
	ageBanded,
	coverageCost,
	monthlyRate,
	variableCostsMessage,
}

export type UpdateEligibilityGroup = (index: number, group: EligibilityGroupValues, needsValidation?: boolean) => void;
export type UpdateGroupProperty = (
	index: number,
	key: keyof EligibilityGroupValues,
	value: EligibilityGroupValues[keyof EligibilityGroupValues],
	needsValidation?: boolean
) => void;
