import React, { ReactElement } from 'react';
import { Button } from '@bamboohr/fabric';
import { redirect } from 'BambooHR.util';

export function AccessSettingsButton({ headerData }): ReactElement {
	return (
		<Button
			color='secondary'
			data-bi-id='settings-access-levels-edit-settings'
			key='secondary'
			onClick={() => {
				redirect(`/access_levels/${headerData.levelType}/edit/${headerData.levelId}`);
			}}
			outline={true}
			size='small'
			text={$.__('Access Level Settings')}
			type='button'
		/>
	);
}
