import {
	StandardModal,
} from '@bamboohr/fabric';

import {
	ModalLoader,
} from './modal-loader';

export function ModalBodyLoading() {
	return (
		<StandardModal.Constraint>
			<ModalLoader />
		</StandardModal.Constraint>
	);
}
