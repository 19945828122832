module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push('<div class="SettingsAccountTimeTrackingResultsModal__subHeader">  <h2>',window.microTemplate.encodeHtml(title),'</h2>    <div class="SettingsAccountTimeTrackingResultsModal__info">   <p class="SettingsAccountTimeTrackingResultsModal__infoDetails">   '); if (problemCount > 0) { 
p.push('    ',window.microTemplate.encodeHtml($.__('%1 potential problems', problemCount)),'    '); if (importedCount > 0) { 
p.push('     &#8226;    '); } 
p.push('   '); } 
p.push('   '); if (importedCount > 0) { 
p.push('    ',window.microTemplate.encodeHtml($.__('%1 successfully imported', importedCount)),'   '); } 
p.push('   </p>   <p>  '); if (nav.previous) { 
p.push('   <button    class="fab-Link fab-TextButton fab-TextButton--inline"    data-action="modalNav" data-id="',window.microTemplate.encodeHtml(nav.previous.id),'"    data-date="',window.microTemplate.encodeHtml(nav.previous.date),'"   >    &laquo; ',window.microTemplate.encodeHtml($.__('Previous')),': ',window.microTemplate.encodeHtml(nav.previous.date),'   </button>   '); if (nav.next) { 
p.push('    <span>&#124;</span>   '); } 
p.push('  '); } 
p.push('  '); if (nav.next) { 
p.push('   <button    class="fab-Link fab-TextButton fab-TextButton--inline"    data-action="modalNav"    data-id="',window.microTemplate.encodeHtml(nav.next.id),'"    data-date="',window.microTemplate.encodeHtml(nav.next.date),'">     ',window.microTemplate.encodeHtml($.__('Next')),': ',window.microTemplate.encodeHtml(nav.next.date),' &raquo;   </span>  '); } 
p.push('   </p>  </div>  <div class="SettingsAccountTimeTrackingResultsModal__body scrollX">    <table class="fab-Table sortable">    <thead>     <tr class="fab-Table__row">      <td class="fab-Table__header TimeTrackingImport__cell TimeTrackingImport__cell--rowNum">       ',window.microTemplate.encodeHtml($.__('Row #')),'      </td>      <td class="fab-Table__header  TimeTrackingImport__cell TimeTrackingImport__cell--result">       ',window.microTemplate.encodeHtml($.__('Result')),'      </td>     '); headers.forEach(function(header) { 
p.push('      <td class="fab-Table__header  TimeTrackingImport__cell TimeTrackingImport__cell--',window.microTemplate.encodeHtml(header.data),'">       ',window.microTemplate.encodeHtml(header.title),'      </td>     '); }); 
p.push('     </tr>    </thead>    <tbody>    '); for (index in importedRows) { 
p.push('     '); var row = importedRows[index]; 
p.push('     <tr class="fab-Table__row">      <td class="fab-Table__cell TimeTrackingImport__cell TimeTrackingImport__cell--rowNum">       ',window.microTemplate.encodeHtml(parseInt(index) + 1),'      </td>      <td data-success="',window.microTemplate.encodeHtml(row.rowImportSuccess),'" class="fab-Table__cell TimeTrackingImport__cell TimeTrackingImport__cell--result">       ',window.microTemplate.encodeHtml(row.importMessage),'      </td>     '); headers.forEach(function(header) { 
p.push('      <td class="fab-Table__cell TimeTrackingImport__cell TimeTrackingImport__cell--',window.microTemplate.encodeHtml(header.data),'">       ',window.microTemplate.encodeHtml((row[header.data.replace(/Id$/, 'Name')] || row[header.data])),'      </td>     '); }); 
p.push('     </tr>    '); } 
p.push('    </tbody>   </table>  </div> </div>');}return p.join('');
}