import {
	Modal,
} from 'modal-legacy';
import {
	BEM,
} from '@utils/dom';


import './mfa-manual-code-modal.styl';

type MfaManualCodeModalProps = {
	code: string;
	onClose(): void;
}

const bem = new BEM('MfaManualCodeModal');

export function MultiFactorAuthManualCodeModal(props: MfaManualCodeModalProps): JSX.Element {
	const {
		code,
		onClose,
	} = props;
	return (
		<Modal
			alternativeAction={ null }
			isOpen={ true }
			onClose={ onClose }
			primaryAction={ onClose }
			primaryActionText={ $.__('Done') }
			title={ $.__('Enter Code Manually') }
		>
			<div className={ bem.elem('content') }>
				<div className={ bem.elem('body') }>
					<h4>
						{ $.__('When prompted, enter your account (this is usually your login email), the secret code below and set to a time based refresh.') }
					</h4>
					<h2 className={ bem.elem('bodyCode') }>
						{ code }
					</h2>
				</div>
			</div>
		</Modal>
	);
}
