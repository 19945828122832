import { Button, CardContentSpacing, SheetModal, StandardModal, StandardModalProps, LayoutBox, useTheme } from '@bamboohr/fabric';
// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore
import { TrashCan43x48 } from '@bamboohr/grim';
import React, { ComponentProps, ReactNode, useState } from 'react';

export interface ConfirmDeleteModalProps
	extends Omit<StandardModalProps, 'onRequestClose'> {
	/** Will pass `true` if the user confirms they want to delete. */
	onRequestClose: (confirmed?: boolean) => void;

	// Optional props
	cancelButtonText?: ReactNode;
	confirmationBoxChildren?: ReactNode;
	confirmationButtonText?: ReactNode;
	/** The text that the user needs to type in before confirming deletion. */
	confirmationMatchText?: string;
	/** The text that appears just above the input where the user types the `confirmationMatchText`. */
	confirmationInputLabel?: ComponentProps<(typeof StandardModal)['ConfirmationContent']>['confirmationText'];
	headlineText?: ReactNode;
	isSheet?: boolean;
	title?: string;
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
	// @startCleanup encore
	const { palette } = useTheme();
	// @endCleanup encore

	const {
		onRequestClose,
		cancelButtonText = $.__('Cancel'),
		children,
		confirmationBoxChildren,
		confirmationButtonText = $.__('Delete'),
		confirmationMatchText = $.__('Delete'),
		headlineText = $.__('Are you sure you want to delete this?'),
		isSheet = false,
		title = $.__('Confirm Deletion'),
		...modalProps
	} = props;

	const {
		confirmationInputLabel = $.__('Type **"%s"** to continue', confirmationMatchText),
	} = props;

	const [isConfirmationValid, setIsConfirmationValid] = useState(false);
	const Modal = isSheet ? SheetModal : StandardModal;

	return (
		<Modal
			{...(modalProps as StandardModalProps)}
			onAfterClose={() => {
				setIsConfirmationValid(false);
				modalProps?.onAfterClose?.();
			}}
			onRequestClose={() => onRequestClose()}
		>
			<form onSubmit={() => onRequestClose(true)}>
				<Modal.Body
					renderFooter={() => (
						<Modal.Footer actions={ifFeature('encore', [
							<Button
								color="primary"
								key="cancel"
								onClick={() => onRequestClose()}
								type="button"
								variant="text"
							>
								{cancelButtonText}
							</Button>,
							<Button
								disabled={!isConfirmationValid}
								key="confirm"
								type="submit"
							>
								{confirmationButtonText}
							</Button>
						], [
							<Button
								disabled={!isConfirmationValid}
								key="confirm"
								type="submit"
							>
								{confirmationButtonText}
							</Button>,
							<Button
								color="primary"
								key="cancel"
								onClick={() => onRequestClose()}
								type="button"
								variant="text"
							>
								{cancelButtonText}
							</Button>
						])} />
					)}
					renderHeader={
						<Modal.Header title={title} />
					}
				>
					<Modal.Constraint
						spacingOverrides={{
							bottom: CardContentSpacing.LARGE,
							top: CardContentSpacing.LARGE,
						}}
					>
						<Modal.HeroHeadline
							icon={ifFeature(
								'encore',
								'trash-can-regular',
								<TrashCan43x48 fill={palette.error.main} />,
							)}
							iconColor={ifFeature('encore', 'error-strong')}
							text={headlineText}
						/>
						<LayoutBox marginY={children ? 1.5 : 3}>
							{children}
						</LayoutBox>
						<Modal.ConfirmationContent
							confirmationText={confirmationInputLabel}
							onConfirmationChange={({ target: { value } }) => {
								setIsConfirmationValid(
									value.toLocaleLowerCase() ===
										confirmationMatchText.toLocaleLowerCase(),
								);
							}}
						>
							<LayoutBox marginBottom={confirmationBoxChildren ? 2 : 0}>
								{confirmationBoxChildren}
							</LayoutBox>
						</Modal.ConfirmationContent>
					</Modal.Constraint>
				</Modal.Body>
			</form>
		</Modal>
	);
}
