import moment, { Moment } from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Data } from '../../utils/';
import { SelectItem } from '../../types';
import { SaveRequest } from '../types';
import { getSelectedValue } from './shared';
import { useSetupServices } from '../service';

type UseScheduleManagerReturn = {
	includeContractors: boolean;
	setIncludeContractors: (val: boolean) => void;
	scheduleStartDate: Moment;
	setScheduleStartDate: (date: Moment) => void;
	selectedFrequencyValue: string;
	setSelectedFrequencyValue: (val: string) => void;
	selectedScheduleValue: string;
	setSelectedScheduleValue: (val: string) => void;
	submitData: SaveRequest;
	scheduleOptions: SelectItem[];
	handleCancelClick: () => void;
	clearSchedule: () => void;
}

const useScheduleManager = (): UseScheduleManagerReturn => {

	const {
		state: {
			nextSurveyScheduledDate,
			frequencyOptions,
			scheduleOptions: initialScheduleOptions,
			includeContractors: initialIncludeContractors,
		}
	} = useContext(Data);

	const [includeContractors, setIncludeContractors] = useState(initialIncludeContractors);
	const [scheduleStartDate, setScheduleStartDate] = useState<Moment>(nextSurveyScheduledDate ? moment(nextSurveyScheduledDate) : null);
	const [selectedFrequencyValue, setSelectedFrequencyValue] = useState<string>(getSelectedValue(frequencyOptions));
	const [selectedScheduleValue, setSelectedScheduleValue] = useState<string>(getSelectedValue(initialScheduleOptions));
	const [scheduleOptions, setScheduleOptions] = useState<SelectItem[]>(initialScheduleOptions);

	// Updates start date and performs actions that are necessary, when the date is updated.
	const {getScheduleOptions} = useSetupServices();
	const clearSchedule = () => {
		// Clear scheduling options, if fail to get schedule options or upcoming surveys
		setScheduleStartDate(null);
		setSelectedScheduleValue(null);
		setSelectedFrequencyValue(null);
	};
	const updateDate = useCallback((startDate: Moment) => {
		if (startDate) {
			getScheduleOptions(startDate)
				.then((scheduleOptions) => {
					setSelectedScheduleValue(null);
					setScheduleOptions(scheduleOptions);
					setScheduleStartDate(startDate);
				})
				.catch(clearSchedule);
			return;
		}
		setSelectedScheduleValue(null);
		setScheduleOptions([]);
	}, []);

	const resetScheduleValues = useCallback(() => {
		setScheduleStartDate(nextSurveyScheduledDate ? moment(nextSurveyScheduledDate) : null);
		setSelectedFrequencyValue(getSelectedValue(frequencyOptions));
		setSelectedScheduleValue(getSelectedValue(initialScheduleOptions));
		setScheduleOptions(initialScheduleOptions);
		setIncludeContractors(initialIncludeContractors);
	}, [nextSurveyScheduledDate, frequencyOptions, initialScheduleOptions, initialIncludeContractors]);

	// Keep FE values in sync, with latest data from server
	useEffect(() => {
		resetScheduleValues();
	}, [nextSurveyScheduledDate, frequencyOptions, initialScheduleOptions, initialIncludeContractors, resetScheduleValues]);

	const submitData = {
		nextSurveyScheduleDate: scheduleStartDate?.format('MM/DD/YYYY'),
		schedule: selectedScheduleValue,
		frequency: selectedFrequencyValue,
		includeContractors,
	};

	return {
		includeContractors,
		setIncludeContractors,
		scheduleStartDate,
		setScheduleStartDate: updateDate,
		selectedFrequencyValue,
		setSelectedFrequencyValue,
		selectedScheduleValue,
		setSelectedScheduleValue,
		submitData,
		scheduleOptions,
		handleCancelClick: resetScheduleValues,
		clearSchedule,
	};
};

export default useScheduleManager;
