import AppModalWrapper from './modal';


export default function InstallModal(props) {
	const {
		app = {},
		actions: {
			saveConfig,
		},
	} = props;

	return (
		<AppModalWrapper
			{ ...props }
			headline={ $.__(`You're good to go! We'll go ahead and install %1$s`, app.title) }
			primaryAction={ app.canCompleteInstall ? (() => saveConfig(app, { action: 'Save' })) : null }
			primaryActionText={ $.__('Install') }
			title={ $.__('Installation ready') }
			type="install"
		/>
	);
}
