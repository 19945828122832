import { useState } from 'react';
import { Button, CardContentSpacing, makeStyles, RadioGroup, RadioGroupProps, StandardModal, TextButton } from '@bamboohr/fabric';

interface CancelAccountModalProps {
	isOpen: boolean;
	onClose: () => void;
	onNext: (selectedValue: RadioGroupProps['value']) => void;
}

const BI_ID = 'payroll-cancellation-complete-cancel-account-modal';
export const RADIO_ITEMS = [
	{
		label: $.__('Yes'),
		value: 'yes',
	},
	{
		label: $.__('No'),
		value: 'no',
	},
];

const useStyles = makeStyles(({ spacing }) => ({
	copy: {
		marginBottom: spacing(2.5),
	},
}));

export const CancelAccountModal = ({ isOpen, onClose, onNext }: CancelAccountModalProps) => {
	const [selectedValue, setSelectedValue] = useState('');
	const classes = useStyles();

	const handleOnChange: RadioGroupProps['onChange'] = (param) => { setSelectedValue(param.value as string); }

	return (
		<StandardModal
			isOpen={ isOpen }
			onRequestClose={ onClose }
		>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={ [
							<Button
								data-bi-id={ `${BI_ID}-next-button` }
								disabled={ !selectedValue.length }
								key={ `${BI_ID}-next-button` }
								onClick={ () => onNext(selectedValue) }
								type="button"
							>
								{ $.__('Next') }
							</Button>,
							<TextButton
								data-bi-id={ `${BI_ID}-cancel-button` }
								key={ `${BI_ID}-cancel-button` }
								onClick={ onClose }
								type="button"
							>
								{ $.__('Cancel') }
							</TextButton>,
						] }
					/>
				}
				renderHeader={ <StandardModal.Header title={ $.__('Cancel Payroll') } /> }
			>
				<StandardModal.Constraint spacingOverrides={ { bottom: CardContentSpacing.LARGE, top: CardContentSpacing.LARGE } }>
					<p className={ classes.copy }>{ $.__('Your payroll account has been cancelled, do you also want to cancel your core BambooHR account?') }</p>
					<RadioGroup
						ariaLabel={$.__('Cancel core BambooHR account?')}
						items={ RADIO_ITEMS }
						onChange={ handleOnChange }
						value={ selectedValue }
					/>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
