import { render } from 'base/wrapped-render';
import { isEnabled } from 'FeatureToggle.util';
import { getParameterByName } from 'BambooHR.util';
import { initProjectTracking } from '../project-tracking';
import { initSettings } from './init-settings';
import initEmployees from './employees';
import initUpsell from './upsell';
import initTrial from './trial';
import initChecklist from './setup-checklist.react/init-checklist';
import { setupEmploymentStatuses, pjaxCleanup } from './employment-statuses';
import createNavigationPopover from './navigation-popover';
import { createEmployeesNavigationPopover } from './employees-navigation-popover/create';
import { TrialBanner } from './trial/trial-banner';
import { SettingsHeader } from './settings-header';
import { TimeTrackingSettingsPo } from './settings/time-tracking-settings-po';

import './styles.styl';

let hasPaidHolidays = false;
let paidHolidayStorage = null;

const attachListeners = () => {
	$('.js-sts-undo-changes').click((e) => {
		e.preventDefault();
		$.pjax.reload('#settings-main-content');
		pjaxCleanup();
	});

	$('.js-sts-role-select').on('ba:selectChange', (e) => hideShowSpecificPerson(e.originalEvent.detail.value));

	$('.js-sts-form').on('ba:selectChange keyup change', '.js-toggle-buttons', toggleSaveUndoButtons);

	$('.js-sts-type-multiple, .js-sts-type-clock, .js-sts-type-single, .js-sts-total-hours-single, .js-sts-total-hours-multiple').change(
		() => handleTimesheetType()
	);

	$('.js-week-starts-on').on('ba:selectChange', () => {
		$('.js-week-starts-on-notice').show();
	});

	$('.js-mobile-enabled-checkbox').on('change', handleMobileAppCheckbox);

	if (hasPaidHolidays) {
		const $typeRadio = $('.js-holiday-work-type-radio');

		if ($typeRadio.filter(':checked').val() === 'multiplier') {
			paidHolidayStorage.appendChild($('.js-holiday-normal-setup')[0]);
		} else {
			paidHolidayStorage.appendChild($('.js-holiday-multiplier-setup')[0]);
		}

		$typeRadio.change(handleHolidayPayTypeChange);
	}
};

function handleHolidayPayTypeChange({ target: { value: type } }) {
	let toStore;

	if (type === 'multiplier') {
		toStore = $('.js-holiday-normal-setup')[0];
	} else {
		toStore = $('.js-holiday-multiplier-setup')[0];
	}

	$('.js-paid-holiday-setup').append(paidHolidayStorage);
	paidHolidayStorage.appendChild(toStore);
}

function handleMobileAppCheckbox({ target: { checked } }) {
	const value = checked ? 'yes' : 'no';
	$('#js-mobile-enabled-hidden').val(value);

	if (!checked) {
		$('.js-mobile-geolocation-checkbox').attr('checked', false);
	}
	$('.js-mobile-geolocation-checkbox').attr('disabled', !checked);
}

function handleTimesheetType(value) {
	if (!value && $('.js-sts-type-multiple').prop('checked')) {
		value = 'multiple';
		if ($('.js-sts-type-clock').prop('checked')) {
			value = 'clock';
		}
	} else if (!value) {
		value = 'single';

		if ($('.js-sts-total-hours-multiple').prop('checked')) {
			value = 'hour';
		}
	}

	if (value === 'multiple' || value === 'clock') {
		$('.js-sts-total-hours-type-column').hide();
		$('.js-sts-type-multiple').prop('checked', true);
		$('.js-sts-type-clock-row').show();
		if (value === 'clock') {
			$('.js-sts-type-clock').prop('checked', true);
			$('.js-sts-geolocation-row').show();
		} else {
			$('.js-sts-geolocation-row').hide();
			$('.js-mobile-geolocation-checkbox').attr('checked', false);
		}
	} else {
		$('.js-sts-type-single').prop('checked', true);
		$('.js-sts-type-clock').prop('checked', false);
		$('.js-sts-total-hours-type-column').show();
		$('.js-sts-type-clock-row').hide();
		$('.js-sts-geolocation-row').hide();
		$('.js-mobile-geolocation-checkbox').attr('checked', false);

		if (value === 'hour') {
			$('.js-sts-total-hours-multiple').prop('checked', true);
		} else {
			$('.js-sts-total-hours-single').prop('checked', true);
		}
	}

	$('.js-sts-timesheet-type').val(value);
}

const hideShowSpecificPerson = (value) => {
	$('.js-sts-people-select').toggle(value === 'specific person');
};

const toggleSaveUndoButtons = () => {
	$('.js-sts-submit').removeAttr('disabled');
	$('.js-sts-undo-changes').show();
};

window.TimeTrackingSettings = {
	initEmployees: initSettings,

	/**
	 * @deprecated - use `initSettings` from `./init-settings.tsx`
	 * @param {{
	 *   renderInPo?: {
	 *     root: string;
	 *     props: { defaultValues: any };
	 *   }
	 * }} options
	 */
	initSettings({ renderInPo } = {}) {
		const timeTrackingTrialStatus = JSON.parse(document.getElementById('js-time-tracking-trial-status').innerHTML);
		if (timeTrackingTrialStatus?.trialActive) {
			const { daysRemainingInTrial, subscriptionRequested, trialExtended } = timeTrackingTrialStatus;

			render(
				<TrialBanner
					daysRemainingInTrial={daysRemainingInTrial}
					subscriptionRequested={subscriptionRequested}
					trialExtended={trialExtended}
				/>,
				document.getElementById('js-time-tracking-trial-banner-root')
			);
		}

		const headerRoot = document.getElementById('js-time-tracking-settings-header');
		if (headerRoot) {
		render(<SettingsHeader disableCancellationDropdown={timeTrackingTrialStatus.trialActive} />, headerRoot);
		}

		if (renderInPo) {
			const {
				props: { defaultValues },
				root,
			} = renderInPo;
			render(<TimeTrackingSettingsPo defaultValues={defaultValues} onDirty={toggleSaveUndoButtons} />, document.getElementById(root));
		}

		hasPaidHolidays = !!document.querySelector('.js-paid-holiday-setup');
		if (hasPaidHolidays) {
			setupEmploymentStatuses();
			paidHolidayStorage = document.createDocumentFragment();
		}
		// setTimeout because ba-select's DOM insertion mutation observers have not fired
		setTimeout(attachListeners);
		handleTimesheetType($('.js-sts-timesheet-type').val());

		const type = $('select[name="role"]');
		const roleSelectValue = $(`.js-sts-role-select ba-option[value="${type.val()}"]`).val();
		hideShowSpecificPerson(roleSelectValue);
	},

	/**
	 * @deprecated - use `initSettings` from `./init-settings.tsx`
	 * @param {string} root
	 * @param {{
	 *   approvers: import('@bamboohr/fabric').ItemType[],
	 *   config: import('./settings/types').Settings,
	 *   role: 'reports to' | 'specific person',
	 *   specificPerson: number | null,
	 *   trial: import('./settings/types').Trial
	 * }}
	 */
	initSettingsInPo(
		root,
		{
			approvers,
			config: {
				customOvertimeEnabled,
				approvedByDays,
				approvedByHour,
				approvedByMeridiem,
				geolocationEnabled: locationEnabled,
				mobileEnabled,
				timesheetType,
				overtimeDailyHours,
				overtimeDailyDoubleHours,
				overtimeWeeklyHours,
				workWeekStartsOn,
			},
			role,
			specificPerson,
			trial,
		}
	) {
		/** @type {Parameters<TimeTrackingSettingsPo>[0]['defaultValues']} */
		const defaultValues = {
			approvals: {
				approverId: specificPerson?.toString() ?? null,
				approverType: role === 'reports to' ? 'manager' : 'specificPerson',
				days: approvedByDays,
				hour: approvedByHour,
				meridiem: approvedByMeridiem,
			},
			entry: {
				clockEnabled: timesheetType === 'clock',
				entriesPerDay: timesheetType === 'hour' ? 'multiple' : 'single',
				type: timesheetType === 'clock' || timesheetType === 'multiple' ? 'startEnd' : 'total',
			},
			overtimeRulesType: customOvertimeEnabled ? 'custom' : 'default',
			overtimeAmounts: {
				daily: {
					amount: overtimeDailyHours,
					checked: overtimeDailyHours !== null,
				},
				dailyDouble: {
					amount: overtimeDailyDoubleHours,
					checked: overtimeDailyDoubleHours !== null,
				},
				weekly: {
					amount: overtimeWeeklyHours,
					checked: overtimeWeeklyHours !== null,
				},
			},
			workWeekStartsOn: parseInt(workWeekStartsOn, 10),
			mobileEnabled,
			locationEnabled,
		};

		initSettings({ approvers, defaultValues, trial });
	},
	initUpsell,
	initTrial,
	initChecklist: initSettings,
	initProjectTracking: initSettings,
};

const whichTimeTrackingSetupPopover = getParameterByName('time_tracking_setup');

if (whichTimeTrackingSetupPopover !== false) {
	switch (whichTimeTrackingSetupPopover) {
		case 'pay_schedule':
			createNavigationPopover('.settingsTimeTrackingLink', $.__('Pay Schedules'));
			break;
		case 'holidays':
			createNavigationPopover('.settingsTimeTrackingLink', $.__('Holidays'));
			break;
		case 'locations':
			createNavigationPopover('.settingsTimeTrackingLink', $.__('Locations'));
			break;
		// other popovers are handled in Po
		default:
			break;
	}
}
