import {
	toQueryString,
} from '@utils/url';

import {
	useReducerContext,
} from '../state';
import TranslationInput from '../translation-input';

import {
	useAjax,
} from '../../../shared';

export default function TranslationCell({
	question,
	type,
}) {
	const {
		id: questionId,
	} = question;
	const context = useReducerContext();
	const {
		dispatch,
		SAVE_PERFORMANCE_TRANSLATION,
		language: {
			value: langId,
		},
	} = context;
	const {
		processing,
		data,
		request,
		useSuccessEffect,
	} = useAjax();

	useSuccessEffect(() => {
		dispatch(SAVE_PERFORMANCE_TRANSLATION(data[type === 'employee' ? 'eeRt' : 'mgrRt']));
	});

	return (
		<TranslationInput
			item={ question }
			onSubmit={ ({ translation: newValue, ...postData }) => request({
				url: '/ajax/manage/translation/update_performance_question.php',
				method: 'post',
				params: {
					questionId,
					langId,
					[type === 'employee' ? 'eeQuestion' : 'mgrQuestion']: newValue,
				},
				data: new URLSearchParams(toQueryString({
					[type === 'employee' ? 'eeRt-obj' : 'mgrRt-obj']: {
						...postData,
						translation: newValue,
					},
				})),
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
			}) }
			questionType={ type }
		/>
	);
}
