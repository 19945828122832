import classNames from 'classnames';

import {
	BankBoxContentProps,
} from '../../utils/interfaces';

import './bank-box-content.styl';

export function BankBoxContent(props: BankBoxContentProps): JSX.Element {
	const {
		database,
		depositData,
		isSelected,
	} = props;
	const titleClasses = classNames(
		'BankBoxContent__title',
		{
			'BankBoxContent__title--selected': isSelected,
		}
	);

	const breakouts = depositData.map((breakout, index) => {
		const {
			accountType,
			accountNumber,
			amount,
			bank,
			order,
		} = breakout;
		const isFirst = index === 0;
		const isPaper = accountType === 'PAPER';
		const isTrax = database === 'trax';
		const prefix = isTrax ? `- ${ amount }` : `${ order }. ${ amount }`;
		const accountInfo = `${ bank } ${ accountType } ${ accountNumber }`;
		let sentence;

		if (isPaper && (isFirst || isTrax)) {
			sentence = $.__('%1$s goes to paper check', prefix);
		} else if (isPaper) {
			sentence = $.__('%1$s remaining goes to paper check', prefix);
		} else if (isTrax || isFirst) {
			sentence = $.__('%1$s goes to %2$s', prefix, accountInfo);
		} else {
			sentence = $.__('%1$s remaining goes to %2$s', prefix, accountInfo);
		}

		return <p key={ accountInfo } className="BankBoxContent__breakout">{ sentence }</p>;
	});

	return (
		<div className="BankBoxContent">
			<p className={ titleClasses }>
				{ $.__n('%1$s Deposit Breakout', '%1$s Deposit Breakouts', depositData.length) }
			</p>
			<div className="BankBoxContent__breakoutContainer">
				{ breakouts }
			</div>
		</div>
	);
}
