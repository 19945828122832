import { ReactElement } from 'react';
import { Box, makeStyles } from '@bamboohr/fabric';

interface UpsellBannerVideoPreviewProps {
	onClick: () => void;
}

const useStyles = makeStyles(() => ({
	videoPreview: {
		cursor: 'pointer',
		borderRadius: '8px',
		height: '136px',
		transition: '.2s',
		'&:hover': {
			opacity: '.9',
		},
	},
}));

export const UpsellBannerVideoPreview = (props: UpsellBannerVideoPreviewProps): ReactElement => {
	const { onClick } = props;
	const styles = useStyles();

	const videoUrl = '/images/ben-admin-video-preview.png';

	return (
		<Box onClick={onClick}>
			<img alt='Video preview' className={styles.videoPreview} src={videoUrl} />
		</Box>
	);
};
