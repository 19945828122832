import { Modal } from 'modal-legacy';

export function TaxIdReminderModal(props) {
	const {
		feeInfo: {
			daysUntilAssessed,
			lateFeeRate,
			reoccurringDaysUntilAssessed,
		},
		onClose,
		primaryAction,
	} = props;

	return (
		<Modal
			headline="Remember to come back and enter the Tax ID"
			icon="fab-alarm-clock-filled-49x54"
			isOpen={ true }
			onClose={ onClose }
			primaryAction={ primaryAction }
			primaryActionText={ $.__('OK, Will Do') }
			title={ $.__(`Don't forget about the Tax ID`) }
		>
			<span className="TaxesTableModal TaxesTableModal--taxIdReminderModal">
				<div className="TaxesTableModal__text">
					{ (lateFeeRate && daysUntilAssessed && reoccurringDaysUntilAssessed) ? (
						$.__(`It's important that you enter the Tax ID as soon as possible. If you don't enter it within %2$s days you will be charged a $%1$s fee. You will continue to incur a $%1$s fee every %3$s days until the Tax ID is entered.`, lateFeeRate, daysUntilAssessed, reoccurringDaysUntilAssessed)
					) : (
						$.__(`It's important that you enter the Tax ID as soon as possible. If you don't enter it soon you will be charged a fee. You will continue to incur a fee until the Tax ID is entered.`)
					) }
				</div>
				<div>
					{ $.__(`We'll send you reminders until it's entered.`) }
				</div>
			</span>
		</Modal>
	);
}
