import type {
	Action,
} from 'react-sweet-state';

import {
	prepareSaveClientTaxSettings,
} from '../../domain/prepare-save-client-tax-settings';
import {
	saveClientTaxSettingsAsync,
} from '../../services/save-client-tax-settings';
import type {
	CompanyTaxSettingsContainerProps,
	CompanyTaxSettingsStore,
} from '../../types';

export function saveAllClientTaxSettings(): Action<CompanyTaxSettingsStore, CompanyTaxSettingsContainerProps, Promise<boolean>> {
	return async function saveAllClientTaxSettingsDispatch({
		getState,
		setState,
	}, {
		clientId,
	}) {
		try {
			const {
				clientTaxSettings,
			} = getState();
			setState({
				isProcessingClientTaxSettings: true,
			});
			const preparedClientTaxSettings = prepareSaveClientTaxSettings(clientTaxSettings);
			await saveClientTaxSettingsAsync(clientId, preparedClientTaxSettings);
			return true;
		} catch (err) {
			console.error('saveAllClientTaxSettingsDispatch err -', err);
			return false;
		} finally {
			setState({
				isProcessingClientTaxSettings: false,
			});
		}
	}
}
