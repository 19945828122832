import type {
	Action,
} from 'react-sweet-state';

import type {
	ClientTaxSetting,
	CompanyTaxSettingsStore,
} from '../../types';

export function setClientTaxSetting(newClientTaxSetting: ClientTaxSetting): Action<CompanyTaxSettingsStore> {
	return function setClientTaxSettingDispatch({
		getState,
		setState,
	}) {
		try {
			const {
				clientTaxSettings,
			} = getState();
			const updatedClientTaxSettings = clientTaxSettings.map((currentClientTaxSetting) => {
				if (currentClientTaxSetting.settingName === newClientTaxSetting.settingName) {
					return newClientTaxSetting;
				}
				return currentClientTaxSetting;
			});
			setState({
				clientTaxSettings: updatedClientTaxSettings,
			});
		} catch (err) {
			console.error('setClientTaxSettingDispatch err -', err);
		}
	}
}
