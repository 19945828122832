import {
	useState,
	useEffect,
} from 'react';
import {
	Table,
} from '@bamboohr/fabric/';

import {
	getGroups,
	getRows,
	getColumns,
} from './overlapping-dates-table-utils';

import {
	OverlappingDatesTableProps,
} from '../../utils/interfaces';

export function OverlappingDatesTable(props: OverlappingDatesTableProps): JSX.Element {
	const [groupsArray, setGroupsArray] = useState([]);
	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);

	const {
		groups,
		fields,
		bambooNeedsTrax,
		traxNeedsBamboo,
		type,
		isMappingSection,
		overlappingErrorFieldIds,
		onViewAndFixClick,
		manualUpdateCheckHandler,
	} = props;
	useEffect(() => {
		if (type === 'employeeWage' || type === 'employeeDeductions') {
			setGroupsArray(getGroups(groups));
		}
	}, [groups, type]);
	useEffect(() => {
		setRows(getRows(isMappingSection ? { ...bambooNeedsTrax.byId, ...traxNeedsBamboo.byId } : fields.byId, overlappingErrorFieldIds));
	}, [isMappingSection, bambooNeedsTrax, traxNeedsBamboo, fields, overlappingErrorFieldIds]);
	useEffect(() => {
		setColumns(getColumns(type, onViewAndFixClick, isMappingSection, manualUpdateCheckHandler));
	}, [type, onViewAndFixClick, isMappingSection, manualUpdateCheckHandler]);

	if (!columns.length || !rows.length) {
		return null;
	}

	return (
		<Table
			caption={ $.__('Compensation records with overlapping date errors') }
			columns={ columns }
			groups={ groupsArray }
			rowKey={ (row: { id: string, value: (string | null) }) => row.value || row.id }
			rows={ rows }
		/>
	);
}
