import { Moment } from 'moment';

import Ajax from '@utils/ajax';

import { useEmployeeWellbeingErrorHandler } from '../utils';
import { GetScheduleOptionsService, GetUpcomingSurveysService, SaveSettingsService, SetupServices } from './types';

export const saveSurvey: SaveSettingsService = (request) => {
	return Ajax.post('/settings/employee_wellbeing/saveSurveyData', request)
		.then(({ data: { employeeWellbeingData } }) => employeeWellbeingData);
};

export const getScheduleOptions: GetScheduleOptionsService = (scheduleDate) => {
	return Ajax.get(`/settings/employee_wellbeing/getSchedulingData?nextSurveyScheduledDate=${ scheduleDate.format('MM/DD/YYYY') }`)
		.then(({ data: { scheduleOptions } }) => scheduleOptions);
};

export const getUpcomingSurveys: GetUpcomingSurveysService = (request) => {
	return Ajax.get(`/settings/employee_wellbeing/upcomingSurveys?nextSurveyDate=${ (request.nextSurveyDate as Moment).format('MM/DD/YYYY') }&frequency=${ request.frequency }&schedule=${ request.schedule }`)
		.then(({ data }) => data);
};

export const useSetupServices = (): SetupServices => {
	const handleError = useEmployeeWellbeingErrorHandler();
	return {
		saveSurvey: ((...rest) => saveSurvey(...rest).catch(handleError)) as SaveSettingsService,
		getScheduleOptions: ((...rest) => getScheduleOptions(...rest).catch(handleError)) as GetScheduleOptionsService,
		getUpcomingSurveys: ((...rest) => getUpcomingSurveys(...rest).catch(handleError)) as GetUpcomingSurveysService,
	}
};
