import { render } from 'base/wrapped-render';
import Ctrl, {
	onPjaxEnd,
	onReady,
} from 'ctrl.deco';

import TranslationsTable from './translations-table.react';

/**
 * @class
 * @member {HTMLDivElement} _wrapper
 * @member {{}} _data
 */
@Ctrl('/settings/account/translations.php')
class SettingsAccountTranslationsCtrl {
	/** @type {Partial<import('./translations-table.react/state').State>} */
	get data() {
		const {
			userLanguageId,
			languageId,
			languages: langs,
			...data
		} = this._data;
		const languages = Object.entries(langs)
			.reduce((obj, [value, text]) => ({
				...obj,
				[value]: {
					value,
					text,
				},
			}), {});

		return {
			...data,
			language: languages[languageId],
			userLanguage: languages[userLanguageId] || {
				text: 'English',
			},
			languages,
		};
	}

	/**
	 * @param {any} e
	 * @param {SettingsAccountTranslationsCtrl} ctrl
	 */
	@onPjaxEnd()
	@onReady()
	_mount(e, { data, _wrapper }) {
		render(
			<TranslationsTable data={ data } />,
			_wrapper
		);
	}

}
