import { BodyText, Section } from '@bamboohr/fabric';
import { Smile18x18, TriangleExclamation13x11 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button } from '@fabric/button';
import { Fragment } from 'react';
import { useStyles } from './styles';

export function EnpsHeader(props) {
	const { hasTrialMessage, onKillSwitchClick, showKillSwitch } = props;

	const buttonLabel = $.__('Turn off eNPS');
	const classes = useStyles();
	return ifFeature(
		'encore',
		<>
			<Section.Header
				divider='bottom'
				size='large'
				title={$.__('Employee Satisfaction (eNPS®)')}
				actions={
					showKillSwitch && <Button clickAction={onKillSwitchClick} outline={true} secondary={true} text={buttonLabel} type='button' />
				}
			/>
		</>,
		<div className={classes.enpsHeader}>
			<div className={classes.enpsHeading}>
				<div className={classes.enpsHeadingIcon}>
					<Smile18x18 />
				</div>
				<div className={classes.enpsHeaderTitle}>{$.__('Employee Satisfaction (eNPS®)')}</div>
				{showKillSwitch && (
					<Fragment>
						<div className={classes.enpsHeadingSpacer}></div>
						<Button clickAction={onKillSwitchClick} outline={true} secondary={true} text={buttonLabel} type='button' />
					</Fragment>
				)}
			</div>
			{hasTrialMessage && renderTrialMessage()}
		</div>
	);
}

function renderTrialMessage() {
	const classes = useStyles();
	return (
		window.GLOBAL_IS_TRIAL === '' && (
			<div className={classes.trialMessage}>
				<span className={classes.trialMessageIcon}>
					<TriangleExclamation13x11 />
				</span>
				<span className={classes.trialMessageHighlight}>{$.__('Disabled for trial accounts.')}</span>
			</div>
		)
	);
}
