import { BodyText, Button, Flex, Headline, Icon, IconV2, LayoutBox, SlidedownPortal, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ScreenSize } from 'screen-size.react';
import { SurveyStatus } from '../shared/survey-status.react';
import { SurveyWrapper } from '../shared/survey-wrapper.react';
import { QuestionImage } from './question-image';
import { ResponseOptions } from './responseOptions';
import { useStyles } from './styles';
import { SurveyTextField } from './survey-text-field';
import { EmployeeWellbeingSurveyProps } from './types';
import usePulseSurvey from './utils';

export const EmployeeWellbeingSurvey: React.FC<EmployeeWellbeingSurveyProps> = ({
	historyHook,
	updateQuestionUrl,
	service,
	surveyId = 0,
	isPreview = false,
}) => {
	const classes = useStyles();
	const {
		canContinue,
		closed,
		submitted,
		dispatchUpdate,
		totalQuestions,
		answer,
		questionNumber,
		nextButtonLabel,
		nextQuestionUrl,
		answers,
		question,
		freeResponse,
		loaded,
		imageUrl,
		updatePending,
		serverError,
	} = usePulseSurvey({
		service,
		surveyId,
		useHistoryHook: historyHook,
		updateQuestionUrl,
	});

	// Only render if data has loaded
	if (!loaded) {
		return null;
	}

	return (
		<LayoutBox marginBottom={ifFeature('encore', undefined, '35px')}>
			{!closed && !submitted ? (
				<SurveyWrapper isFullBleed={true} isPreview={isPreview} surveyName='employee-wellbeing'>
					<ScreenSize
						render={({ isSmallScreen }) => (
							<form
								className={classes.cardContentWrapper}
								onSubmit={(event) => dispatchUpdate({ type: 'SUBMIT_ANSWER', payload: event })}
							>
								{isSmallScreen
									? ifFeature(
											'encore',
											<Flex justifyContent='center' marginBottom={3}>
												<BodyText size='small'>
													{$.__('Question %1$s of %2$s', questionNumber.toString(), totalQuestions.toString())}
												</BodyText>
											</Flex>,
											<div className={classes.questionCount}>
												{$.__('Question %1$s of %2$s', questionNumber.toString(), totalQuestions.toString())}
											</div>
									  )
									: ifFeature(
											'encore',
											<Flex alignItems='center' gap={0.5} height={40}>
												{questionNumber !== 1 && (
													<>
														<TextButton
															onClick={() => dispatchUpdate({ type: 'PREVIOUS_QUESTION' })}
															startIcon='arrow-left-regular'
															type='button'
														>
															{$.__('Back')}
														</TextButton>
														<BodyText size='small'>|</BodyText>
													</>
												)}
												<BodyText size='small'>
													{$.__('Question %1$s of %2$s', questionNumber.toString(), totalQuestions.toString())}
												</BodyText>
											</Flex>,
											<div className={classes.backAndQuestionWrapper}>
												{questionNumber !== 1 && (
													<TextButton
														className={classes.back}
														onClick={() => dispatchUpdate({ type: 'PREVIOUS_QUESTION' })}
														startIcon={<Icon name='fab-arrow-left-8x7' />}
														type='button'
													>
														{$.__('Back')}
													</TextButton>
												)}
												<div className={classes.questionCounter}>
													{$.__('Question %1$s of %2$s', questionNumber.toString(), totalQuestions.toString())}
												</div>
											</div>
									  )}
								<QuestionImage imageUrl={imageUrl} isSmallScreen={isSmallScreen} />
								{ifFeature(
									'encore',
									<Flex justifyContent='center' marginBottom={6}>
										<Headline size='small' color='neutral-strong'>
											{question}
										</Headline>
									</Flex>,
									<div className={classes.question}>
										<h3>{question}</h3>
									</div>
								)}
								{ifFeature(
									'encore',
									<LayoutBox marginBottom={6}>
										<ResponseOptions
											answers={answers}
											disabled={updatePending}
											handleAnswerChange={(value) =>
												dispatchUpdate({
													type: 'UPDATE_ANSWER',
													payload: value,
												})
											}
											isSmallScreen={isSmallScreen}
											selectedAnswerValue={answer}
										/>
									</LayoutBox>,
									<div className={classes.answers}>
										<ResponseOptions
											answers={answers}
											disabled={updatePending}
											handleAnswerChange={(value) =>
												dispatchUpdate({
													type: 'UPDATE_ANSWER',
													payload: value,
												})
											}
											isSmallScreen={isSmallScreen}
											selectedAnswerValue={answer}
										/>
									</div>
								)}
								<SurveyTextField
									disabled={updatePending}
									freeResponse={freeResponse}
									isSmallScreen={isSmallScreen}
									onChange={(value: string) =>
										dispatchUpdate({
											type: 'UPDATE_RESPONSE',
											payload: value,
										})
									}
									questionNumber={questionNumber}
								/>
								{ifFeature(
									'encore',
									<Flex justifyContent='center'>
										<LayoutBox
											marginX={isSmallScreen ? 2.5 : undefined}
											maxWidth={isSmallScreen ? '450px' : undefined}
											width={isSmallScreen ? '100%' : undefined}
										>
											<Button
												fullWidth={isSmallScreen}
												disabled={!canContinue}
												endIcon={nextQuestionUrl && 'chevrons-right-regular'}
												size='medium'
												type='submit'
											>
												{nextButtonLabel}
											</Button>
										</LayoutBox>
									</Flex>,
									<div className={`${classes.submit} ${isSmallScreen && classes.smallSubmitButton}`}>
										<Button
											disabled={!canContinue}
											endIcon={nextQuestionUrl && <Icon name='fab-double-chevron-right-14x11' />}
											noSpacing={true}
											size='biggie'
											type='submit'
										>
											{nextButtonLabel}
										</Button>
									</div>
								)}
								{isSmallScreen && questionNumber !== 1 && (
									<Flex justifyContent='center' marginTop={1}>
										<TextButton onClick={() => dispatchUpdate({ type: 'PREVIOUS_QUESTION' })} type='button'>
											{$.__('Previous Question')}
										</TextButton>
									</Flex>
								)}
							</form>
						)}
					/>
				</SurveyWrapper>
			) : (
				<SurveyWrapper surveyName='employee-wellbeing'>
					{!closed && submitted && (
						<SurveyStatus
							headerMessage={$.__('Thanks so much for your feedback!')}
							iconName={ifFeature(
								'encore',
								<IconV2 name='circle-check-solid' size={48} color='primary-strong' />,
								'fab-check-circle-48x48'
							)}
							id='submitted-status'
							messages={[$.__('You’re all set, feel free to close this window and move on with your life.')]}
						/>
					)}
					{closed && (
						<SurveyStatus
							headerMessage={$.__('Survey Period is Now Closed')}
							iconName={ifFeature(
								'encore',
								<IconV2 name='triangle-exclamation-solid' size={48} color='primary-strong' />,
								'fab-triangle-exclamation-54x54'
							)}
							id='closed-status'
							messages={[
								$.__('Your feedback is very important.'),
								$.__('Please be sure to look for the next opportunity to answer the survey.'),
							]}
						/>
					)}
				</SurveyWrapper>
			)}
			<SlidedownPortal
				action={{
					text: $.__('Refresh Page'),
					url: window.location.href,
				}}
				message={$.__('Something went wrong.')}
				show={serverError}
				type='error'
			/>
		</LayoutBox>
	);
};
