import {
	useState,
} from 'react';
import {
	Headline,
	/* @startCleanup encore */
	Icon,
	/* @endCleanup encore */
	LayoutBox,
	Loader,
	/* @startCleanup encore */
	makeStyles,
	/* @endCleanup encore */
	Select,
	Table,
	TextButton,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
import { Modal } from 'modal-legacy';

import {
	EligibleEmployeeContract,
} from '../types/contracts';

import { ELIGIBILITY_LABELS } from './constants';
import { getEligibleEmployees, saveEligibleEmployees } from './utils/api-service';
import { useEligibiltyModal } from './hooks/use-eligibility-modal';
import { EligibilityOption, EligibilityData } from './types';
import { DateField } from 'form-fields.react';
import { PlanDates } from '../../types/dates';
import { useEffectiveDateValidation } from './hooks/use-effective-date-validation';
import { getEmployeesChangingEligibility } from './utils/get-employees-changing-eligibility';
import { redirect } from 'BambooHR.util';

interface EligibilityModalProps {
	id: number;
	isOpen: boolean;
	onClose: () => void;
	planName: string;
	planDates: PlanDates;
}

/* @startCleanup encore */
const useStyles = makeStyles((theme) => ({
	heading: {
		alignItems: 'center',
		display: 'flex',
		margin: '0px 32px',
		padding: '8px 0px 0px',
	},
	icon: {
		alignItems: 'center',
		display: 'flex',
		fill: theme.palette.primary.main
	},
	headingText: {
		marginLeft: 8,
	},
}));
/* @endCleanup encore */

export function EligibilityModal(props: EligibilityModalProps): JSX.Element {
	const {
		id,
		isOpen,
		onClose,
		planName,
		planDates,
	} = props;

	/* @startCleanup encore */
	const classes = useStyles();
	/* @endCleanup encore */
	const [eligibleEmployees, setEligibleEmployees] = useState<EligibleEmployeeContract[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState(false);

	const {
		updateEligibility,
		updateEffectiveDate,
		employeeEligibility,
	} = useEligibiltyModal(eligibleEmployees);

	const {
		validateEffectiveDates,
		employeesWithDateErrors,
		clearEffectiveDateErrors
	} = useEffectiveDateValidation(planDates);

	const handleSelectChange = (id: number) => (option: EligibilityOption) => {
		updateEligibility(id, option);
	};

	const handleDateChange = (id: number) => (date: string) => {
		updateEffectiveDate(id, date);
		validateEffectiveDates(id, date);
	};

	const handleCloseModal = () => {
		clearEffectiveDateErrors();
		onClose();
	};

	const validate = (): boolean => {
		const effectiveDateErrors = [];

		Object.values(employeeEligibility).forEach(employee => {
			if (employee.eligibility === EligibilityOption.eligible && !validateEffectiveDates(employee.id, employee.effectiveDate)) {
				effectiveDateErrors.push(employee);
			}

		});

		return effectiveDateErrors.length === 0;
	};

	const handleSaveChanges = () => {
		setIsProcessing(true);
		const isValid = validate();

		const employeesChangingEligibility = getEmployeesChangingEligibility(Object.values(employeeEligibility));

		if (isValid && employeesChangingEligibility.length > 0) {
			saveEligibleEmployees(id, employeesChangingEligibility)
				.then((response) => {
					if (response) {
						window.setMessage($.__('Eligibility changes have been saved successfully'), 'success');
						setIsProcessing(false);
						handleCloseModal();
						redirect('/settings/benefits');
					}
				}, (error) => {
					console.error(error);
					window.setMessage(error, 'error');
					setIsProcessing(false);

				});
		} else {
			setIsProcessing(false);
			window.setMessage($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
		}
	};

	/* @startCleanup encore */
	const jade = (
		<div className={ classes.heading }>
			<div className={ classes.icon }>
				<Icon name="fab-heart-person-18x16" />
			</div>
			<div className={ classes.headingText }>
				<h4>{ `${ $.__('Set Employees\' Eligibility') }` }</h4>
			</div>
		</div>
	);
	/* @endCleanup encore */

	return (
		<Modal
			alternativeAction={ handleCloseModal }
			alternativeActionText={ $.__('Cancel')}
			contentHasPadding={ false }
			headline={ifFeature(
				'encore',
				<LayoutBox padding={2.5}>
					<Headline component='h4' icon="user-gear-solid" size='small'>
						{ $.__('Set Employees\' Eligibility') }
					</Headline>
				</LayoutBox>,
				jade
			)}
			isOpen={ isOpen }
			isProcessing={ isProcessing }
			onClose={ handleCloseModal }
			onOpen={ () => {
				setIsLoading(true);
				getEligibleEmployees(id)
					.then((response) => {
						setIsLoading(false);
						if (response && response.data) {
							setEligibleEmployees(response.data.employees);
							setIsLoading(false);
						}
					}, (error) => {
						setIsLoading(false);
						console.error(error);
					});
			}}
			primaryAction={ handleSaveChanges }
			primaryActionText={ $.__('Update Eligibility') }
			title={ planName }
			type="medium"
		>
			<div className="EligibilityModal">
				{ (isLoading) && (
					<Loader />
				) }
				<Table
					caption={ $.__('Employees') }
					columns={ [
						{
							cell: (row: EligibilityData) => (
								<TextButton
									children={ row.displayName }
									inline={ true }
									onClick={ () => {
										window.location.href = `/employees/employee.php?id=${ row.id }`;
									} }
									type="button"
								/>
							),
							header: $.__('Name'),
							key: 'name',
							sortBy: (row: EligibilityData) => row.displayName,
							verticalAlign: true,
						},
						{
							cell: (row: EligibilityData) => (
								<Select
									items={ Object.keys(ELIGIBILITY_LABELS).map((option) => ({ text: ELIGIBILITY_LABELS[option], value: option })) }
									onClear={ () => handleSelectChange(row.id)(EligibilityOption.notEligible) }
									onSelect={ handleSelectChange(row.id) }
									selectedValues={ [row.eligibility] }
									width={ 6 }
								/>
							),
							header: $.__('Status'),
							key: 'status',
							verticalAlign: true,
						},
						{
							cell: (row: EligibilityData) => (
								<div>
									{
										// if row.status === eligible, show effective date
										row.eligibility === EligibilityOption.eligible
											? (
												<DateField
													hasError={ Boolean(Object.keys(employeesWithDateErrors).includes(`${ row.id }`) && employeesWithDateErrors[row.id].error) }
													id="set-eligibility-effective-date"
													label=""
													max={ planDates.endYmd }
													min={ planDates.startYmd }
													onChange={ handleDateChange(row.id) }
													value={ row.effectiveDate }
												/>
											)
											: (<span>&mdash;</span>)
									}
								</div>
							),
							header: $.__('Effective Date'),
							key: 'effectiveDate',
							verticalAlign: true,
						},
					] }
					preSorted={ {
						columnIndex: 0,
						isAsc: true,
					} }
					rowKey={ (row: EligibilityData) => row.id }
					rows={ Object.values(employeeEligibility) }
					stickyHeader={ true }
				/>
			</div>
		</Modal>
	);
}
