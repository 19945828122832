import Ctrl from 'ctrl.deco';
import { render } from 'base/wrapped-render'
import { ApiKeyManagementMicro } from './apiKeyManagementMicro'
@Ctrl('/settings/account/api_keys_manager')
class SettingsAccountApiKeysManagerCtrl {

	get mainTmpl() {
		return `<div id="js-SettingsPoRoot"></div>`;
	}

	onMainReady() {
		const settingsApiKeyManager = document.getElementById('js-SettingsPoRoot');
		if (settingsApiKeyManager) {
			render(
				(
					<ApiKeyManagementMicro />
				),
				settingsApiKeyManager
			);
		}
	}
}
