import {
	isString,
} from 'lodash';

/**
 * Strip out the non digit characters while preserving the decimal
 * @param {string} value Value to be stripped of all non numeric characters while preserving decimal
 */
export function stripNonNumericCharacters(value): string {
	if (!isString(value)) {
		throw new Error('Invalid value supplied');
	}
	const isNegative = value.startsWith('-');
	let returnValue = value.replace(/[^\d\.]/g, ''); // removes all non-digit/non-decimal characters
	returnValue = `${ isNegative ? '-' : '' }${ returnValue }`; // adds negative sign if first character is a negative
	return returnValue;
}
