import { AxiosError } from "axios";
import { useContext } from "react";
import { SlidedownContext } from "./slidedown";
import { EmployeeWellbeingErrorHandler } from "./types";

export const useEmployeeWellbeingErrorHandler = (): EmployeeWellbeingErrorHandler => {
    const {showError, hide} = useContext(SlidedownContext);

    return (error: AxiosError) => {
        const {response} = error;
        if (response && response.status >= 400) {
            hide();
            setTimeout(showError, 300);
        }
        throw error;
    };
};

