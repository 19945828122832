import { Fragment, useState } from 'react';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { BankAccountForm } from '../../../components/bank-account-form';

import { StatusRow } from '../../../../company-information.react/components/status-row';
import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';
import { UploadFormsModal } from '../../../../company-information.react/components/upload-forms-modal';

import { BANK_APP_STATE } from '../../../utils/shared';
import { uuidGenerator } from '../../../../company-information.react/utils/shared';

export function DocsDownloadedStateComponent(props) {
	const { company, onSubmit, isProcessing, onUploadForms, onInputChange, plaidLinkToken, plaidUpdateSuccess } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager
				buttonFunction={setIsModalOpen}
				clientId={company.clientId}
				onFileDownload={() => {}}
				statuses={[BANK_APP_STATE.DOCS_DOWNLOADED]}
			/>

			<StatusRow status={BANK_APP_STATE.BANK_SAVED} />

			<BankAccountForm
				bankAccount={company.bankAccounts[0]}
				company={company}
				isEdit
				isProcessing={isProcessing}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
				plaidLinkToken={plaidLinkToken}
				plaidUpdateSuccess={plaidUpdateSuccess}
			/>

			<UploadFormsModal
				key={uuidGenerator()}
				bank={company.bankAccounts[0]}
				clientId={company.clientId}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSubmit={onUploadForms}
				type='bank'
				visible={isModalOpen}
			/>
		</Fragment>
	);
}
