import { MarketingPromo } from './marketing-promo';
import { GetStarted } from './get-started';
import { Setup } from './setup';
import { EmployeeWellbeingSettingsPageProvider } from './utils/';
import { EmployeeWellbeingData } from './types';
import SurveyPreviewModal from './survey-preview';
import { EmployeeWellbeingSettingsSlidedown } from './slidedown';

type AppProps = {
	data: EmployeeWellbeingData;
};

export const App: React.FC<AppProps> = ({ data }) => {
	return (
		<EmployeeWellbeingSettingsPageProvider data={data}>
			<EmployeeWellbeingSettingsSlidedown />
			<Setup />
			<GetStarted />
			<MarketingPromo />
			<SurveyPreviewModal />
		</EmployeeWellbeingSettingsPageProvider>
	);
};
