import { BodyText, Button, Divider, Flex, Headline, IconV2, LayoutBox, StyledBox } from '@bamboohr/fabric';
import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';
import themes from '@bamboohr/fabric/dist/definitions/json/theme-colors.json';
import { CheckCircle64x64, EnvelopeCheck64x48, TriangleExclamation54x54 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getBrandColor } from 'BambooHR.util';
import Donut from 'charts/donut-chart.react';
import moment from 'moment.lib';
import { Component, Fragment } from 'react';
import { getSettingsEnpsPreviewEmail } from '../../../../settings-enps-service';
import { EnpsHeader } from '../../enps-header';
import { EnpsSection } from '../../enps-section';
import { EnpsModal } from '../../shared/enps-modal';
import { PreviewEmailModal } from '../../shared/preview-email-modal';
import { EnpsIconOverHeading } from './enps-icon-over-heading';
import { EnpsStatusActions } from './enps-status-actions';
import './status.styl';
import { Message } from '@bamboohr/utils/lib/message';

const brandTheme = themes.themeColors.find((color) => color.name === getBrandColor());

const EnpsStatus = {
	InProgress: 'in_progress',
	Ready: 'ready',
	Sent: 'sent',
};
Object.freeze(EnpsStatus);

export class Status extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	_renderStatusHeading(status) {
		const { data } = this.props;
		switch (status) {
			case EnpsStatus.Ready:
				return (
					<EnpsIconOverHeading
						icon={ifFeature(
							'encore',
							<LayoutBox marginBottom={3}>
								<IconV2 name='circle-check-solid' size={60} color='primary-strong' />
							</LayoutBox>,
							<CheckCircle64x64 />
						)}
						subTitle={$.__('Now just sit back, relax, and wait for the responses to start rolling in.')}
						title={$.__('Your Employee Satisfaction survey is ready to go.')}
					/>
				);
			case EnpsStatus.Sent:
				return (
					<EnpsIconOverHeading
						icon={ifFeature(
							'encore',
							<LayoutBox marginBottom={1.5}>
								<IconV2 name='envelope-circle-check-solid' size={60} color='primary-strong' />
							</LayoutBox>,
							<EnvelopeCheck64x48 />
						)}
						title={$.__(
							`The intro email has been sent, the survey will be sent out on %1$s.`,
							moment(data.emailSurveySendDate).format('ll')
						)}
					/>
				);
			case EnpsStatus.InProgress:
				return (
					<EnpsIconOverHeading
						icon={ifFeature(
							'encore',
							<LayoutBox marginBottom={1.5}>
								<IconV2 name='envelope-circle-check-solid' size={60} color='primary-strong' />
							</LayoutBox>,
							<EnvelopeCheck64x48 />
						)}
						subTitle={ifFeature('encore', $.__('Here’s how things are looking so far.'))}
						title={ifFeature(
							'encore',
							$.__('Your survey is in full swing.'),
							$.__('Your survey is in full swing. Here’s how things are looking so far.')
						)}
					/>
				);
		}
	}

	_renderStepOne({ status, emailIntroSendDate }) {
		const headingClasses = ['Status__stepHeading', status === EnpsStatus.Sent ? 'Status__stepHeading--success' : 'baseColor'];
		const isStatusReady = status === EnpsStatus.Ready;
		const isStatusSent = status === EnpsStatus.Sent;
		const introEmailText = isStatusReady
			? {
					__html: $.__('Will be sent to all employees on %1%2%3.', '<strong>', moment(emailIntroSendDate).format('ll'), '</strong>'),
			  }
			: { __html: $.__('Sent to all employees on %1%2%3.', '<strong>', moment(emailIntroSendDate).format('ll'), '</strong>') };
		const encoreIntroEmailText = (
			<Message
				params={[moment(emailIntroSendDate).format('ll')]}
				text={isStatusReady ? $.__('Will be sent to all employees on **{1}**.') : $.__('Sent to all employees on **{1}**')}
			/>
		);
		const step = '1';
		return (
			<Fragment key={step}>
				<EnpsSection
					checked={isStatusSent}
					className='Status__flexElemsJustified'
					iconName='fab-envelope-24x20'
					step={step}
					isSubdued={true}
				>
					{ifFeature(
						'encore',
						<>
							<Flex alignItems='center' justifyContent='space-between'>
								<Flex gap={1.5}>
									<LayoutBox position='relative' bottom={isStatusSent && '2px'} left={isStatusSent && '4px'}>
										<IconV2
											name={isStatusSent ? 'check-solid' : 'envelope-solid'}
											size={24}
											color={isStatusSent ? 'success-strong' : 'neutral-strong'}
										/>
									</LayoutBox>
									<div>
										<LayoutBox marginBottom={0.25}>
											<BodyText color={isStatusSent && 'primary'} weight='semibold'>
												{$.__('Employee Satisfaction Introduction Email')}
											</BodyText>
										</LayoutBox>
										<BodyText color='neutral-strong' size='small'>
											{encoreIntroEmailText}
										</BodyText>
									</div>
								</Flex>
								<div>
									<EnpsStatusActions
										onEditClick={this._handleEditIntroEmailClick}
										onPreviewClick={this._handlePreviewEmailOpen}
										status={status}
									/>
								</div>
							</Flex>
							<LayoutBox marginY={3}>
								<Divider color='neutral-extra-weak' />
							</LayoutBox>
						</>,
						<div className='Status__flexElemsJustified'>
							<div>
								<div className={headingClasses.join(' ')}>{$.__('Employee Satisfaction Introduction Email')}</div>
								<div className='Status__stepDescription' dangerouslySetInnerHTML={introEmailText}></div>
							</div>
							<div>
								<EnpsStatusActions
									onEditClick={this._handleEditIntroEmailClick}
									onPreviewClick={this._handlePreviewEmailOpen}
									status={status}
								/>
							</div>
						</div>
					)}
				</EnpsSection>
			</Fragment>
		);
	}

	_renderStepTwo(data) {
		const { emailSurveySendDate, emailSurveyEndDate, timeZone } = data;
		const surveyEmailText1 = {
			__html: $.__('Will be sent to all employees on %1%2%3.', '<strong>', moment(emailSurveySendDate).format('ll'), '</strong>'),
		};
		const encoreSurveyEmailText1 = (
			<Message params={[moment(emailSurveySendDate).format('ll')]} text={$.__('Will be sent to all employees on **{1}**.')} />
		);
		const surveyEmailText2 = {
			__html: $.__(
				'Survey closes %1%2%3 %4 %5.',
				'<strong>',
				moment(emailSurveyEndDate).format('ll'),
				'</strong>',
				moment(emailSurveyEndDate).format('LT'),
				timeZone
			),
		};
		const encoreSurveyEmailText2 = (
			<Message
				params={[moment(emailSurveyEndDate).format('ll'), moment(emailSurveyEndDate).format('LT'), timeZone]}
				text={$.__('Survey closes on **{1}** at {2} {3}.')}
			/>
		);
		const step = '2';

		return ifFeature(
			'encore',
			<Flex alignItems='center' justifyContent='space-between'>
				<Flex gap={1.5}>
					<LayoutBox>
						<IconV2 name='envelope-solid' size={24} color='neutral-strong' />
					</LayoutBox>
					<div>
						<LayoutBox marginBottom={0.25}>
							<BodyText weight='semibold'>{$.__('Employee Satisfaction Survey Email')}</BodyText>
						</LayoutBox>
						<LayoutBox marginBottom={0.25}>
							<BodyText color='neutral-strong' size='small'>
								{encoreSurveyEmailText1}
							</BodyText>
						</LayoutBox>
						<BodyText color='neutral-strong' size='small'>
							{encoreSurveyEmailText2}
						</BodyText>
					</div>
				</Flex>
				<div>
					<EnpsStatusActions onPreviewClick={this._handlePreviewSurveyOpen} />
				</div>
			</Flex>,
			<Fragment key={step}>
				<EnpsSection iconName='fab-envelope-24x20' step={step} isSubdued={true}>
					<div className='Status__flexElemsJustified'>
						<div>
							<div className='Status__stepHeading baseColor'>{$.__('Employee Satisfaction Survey Email')}</div>
							<div className='Status__stepDescription' dangerouslySetInnerHTML={surveyEmailText1}></div>
							<div className='Status__stepDescription' dangerouslySetInnerHTML={surveyEmailText2}></div>
						</div>
						<div>
							<EnpsStatusActions onPreviewClick={this._handlePreviewSurveyOpen} />
						</div>
					</div>
				</EnpsSection>
			</Fragment>
		);
	}

	_renderStepThree({ completionRate, emailSurveySendDate, emailSurveyEndDate, employeesComplete, employeesTotal, timeZone }) {
		const completeText = $.__('Complete');
		const incompleteText = $.__('Incomplete');

		const incompletionRate = 100 - completionRate;

		const chartIcon = '#fab-smile-48x48';

		const donutData = {
			data: [
				{
					color: brandTheme.base,
					count: completionRate,
					label: completeText,
					meta: {
						name: completeText,
						piePercent: completionRate,
						count: completionRate,
					},
				},
				{
					color: '#d8d8d8',
					count: incompletionRate,
					label: incompleteText,
					meta: {
						name: incompleteText,
						piePercent: incompletionRate,
						count: incompletionRate,
					},
				},
			],
			icon: {
				height: ifFeature('encore', 48, 52),
				path: chartIcon,
				width: ifFeature('encore', 48, 45),
			},
		};

		const donutSettings = {
			chartClass: 'Status__completeChart',
			size: 170,
			valueLabel: {
				visible: true,
			},
		};

		/* @startCleanup encore */
		const surveyText = {
			__html: $.__(
				'The survey was sent on %1%3%2 (%4) and closes on %1%5%2 (%6) %7.',
				'<strong>',
				'</strong>',
				moment(emailSurveySendDate).format('ll'),
				moment(emailSurveySendDate).fromNow(),
				moment(emailSurveyEndDate).format('ll'),
				moment(emailSurveyEndDate).fromNow(),
				this._formatTimeZoneString(emailSurveyEndDate, timeZone)
			),
		};
		/* @endCleanup encore */
		const encoreSurveyText = (
			<Message
				params={[
					moment(emailSurveySendDate).format('ll'),
					moment(emailSurveySendDate).fromNow(),
					moment(emailSurveyEndDate).format('ll'),
					moment(emailSurveyEndDate).fromNow(),
					moment(emailSurveyEndDate).format(`[${$.__('at')}] LT [${timeZone}]`),
				]}
				text={$.__('The survey was sent on **{1}** ({2}) and closes on **{3}** ({4}) {5}.')}
			/>
		);

		return (
			<div className='Status__complete'>
				<figure className='Status__completeFigure'>
					<Donut data={donutData} settings={donutSettings} />
				</figure>
				{ifFeature(
					'encore',
					<LayoutBox marginLeft={2}>
						<LayoutBox marginBottom={1}>
							<BodyText size='large' weight='semibold'>
								{$.__('%1%% Completion Rate', completionRate)}
							</BodyText>
						</LayoutBox>
						<LayoutBox marginBottom={2}>
							<BodyText size='small' color='neutral-strong'>
								{$.__('%1 out of %2 employees have finished the survey.', employeesComplete, employeesTotal)}
							</BodyText>
						</LayoutBox>
						<LayoutBox marginBottom={2}>
							<BodyText>{encoreSurveyText}</BodyText>
						</LayoutBox>
						<Button
							onClick={() => {
								location.href = '/reports/enps';
							}}
							size='small'
							type='button'
						>
							{$.__('Go to Employee Satisfaction Report')}
						</Button>
					</LayoutBox>,
					<div>
						<div className='Status__completeTitle baseColor'>{$.__('%1%% Completion Rate', completionRate)}</div>
						<div className='Status__completeSubTitle baseColor'>
							{$.__('%1 out of %2 employees have finished the survey.', employeesComplete, employeesTotal)}
						</div>
						<div className='Status__completeText' dangerouslySetInnerHTML={surveyText}></div>
						<Button
							clickAction={() => {
								location.href = '/reports/enps';
							}}
							outline={true}
							secondary={true}
							text={$.__('Go to Employee Satisfaction Report')}
							type='button'
						/>
					</div>
				)}
			</div>
		);
	}

	/* @startCleanup encore */
	_formatTimeZoneString(date, timeZone) {
		const at = $.__('at');
		return moment(date).format(`[${at}] LT [${timeZone}]`);
	}
	/* @endCleanup encore */

	_showErrorMessage() {
		setMessage($.__('It looks like something went wrong! Try again later.'), 'error');
	}

	_handleCancelModalClose = () => {
		this.setState(() => {
			return {
				isCancelModalOpen: false,
			};
		});
	};

	_handleCancelModalOpen = () => {
		this.setState(() => {
			return {
				isCancelModalOpen: true,
			};
		});
	};

	_handlePreviewClose = () => {
		this.setState(() => {
			return {
				isPreviewModalOpen: false,
			};
		});
	};

	_handlePreviewEmailOpen = () => {
		getSettingsEnpsPreviewEmail('/settings/enps/introEmail').then(
			(email) => {
				this.setState(() => {
					return {
						isPreviewModalOpen: true,
						previewEmailContent: email,
						previewEmailTitle: $.__('Employee Satisfaction Introduction Email'),
					};
				});
			},
			() => {
				this._showErrorMessage();
			}
		);
	};

	_handlePreviewSurveyOpen = () => {
		getSettingsEnpsPreviewEmail('/settings/enps/surveyEmail').then(
			(email) => {
				this.setState(() => {
					return {
						isPreviewModalOpen: true,
						previewEmailContent: email,
						previewEmailTitle: $.__('Employee Satisfaction Survey Email'),
					};
				});
			},
			() => {
				this._showErrorMessage();
			}
		);
	};

	_handleEditIntroEmailClick = () => {
		const { onEdit } = this.props;
		onEdit();
	};

	render() {
		const { data, onCancel } = this.props;
		const { isCancelModalOpen, isPreviewModalOpen, previewEmailContent, previewEmailTitle } = this.state;
		const {
			status, // 'ready', 'sent', 'in_progress'
			nextSurveyDate,
			showKillSwitch,
		} = data;

		return (
			<section className='Status'>
				<EnpsHeader onKillSwitchClick={this._handleCancelModalOpen} showKillSwitch={showKillSwitch} />
				<EnpsModal
					isVisible={isCancelModalOpen}
					onCloseModal={this._handleCancelModalClose}
					options={{
						alternativeAction: this._handleCancelModalClose,
						alternativeActionText: $.__('No, keep it on'),
						primaryAction: () => {
							onCancel();
							this._handleCancelModalClose();
						},
						primaryActionText: $.__('Yes, turn off eNPS'),
						title: $.__('Just Checking...'),
					}}
				>
					<Flex
						alignItems='center'
						flexDirection='column'
						margin='auto'
						padding={ifFeature('encore', undefined, '20px 20px 25px')}
						textAlign='center'
					>
						{ifFeature(
							'encore',
							<LayoutBox marginBottom={1.5}>
								<IconV2 size={55} name='triangle-exclamation-solid' color='warning-strong' />
							</LayoutBox>,
							<LayoutBox margin='10px 0 20px'>
								<TriangleExclamation54x54 fill={colors.attention} />
							</LayoutBox>
						)}
						<Headline color='neutral-medium' size='small'>
							{$.__('Are you sure you want to turn off eNPS?')}
						</Headline>
						{status === EnpsStatus.InProgress && (
							<LayoutBox marginTop='8px'>
								<BodyText color='neutral-strong' size='medium' weight='regular' justify={ifFeature('encore', 'center')}>
									{$.__('You will not be able to send out another survey until')}
									<br />
									{$.__('%1 (six months after your last survey.)', moment(nextSurveyDate).format('ll'))}
								</BodyText>
							</LayoutBox>
						)}
					</Flex>
				</EnpsModal>

				{this._renderStatusHeading(status)}

				{ifFeature(
					'encore',
					<Flex justifyContent='center' marginTop={4}>
						<StyledBox
							borderRadius='small'
							backgroundColor='neutral-extra-extra-weak'
							borderColor='neutral-extra-weak'
							boxSizing='border-box'
							borderStyle='solid'
							maxWidth='776px'
							width='100%'
							padding={4}
						>
							{status === EnpsStatus.InProgress ? this._renderStepThree(data) : [this._renderStepOne(data), this._renderStepTwo(data)]}
						</StyledBox>
					</Flex>,
					<div className='Status__steps'>
						{status === EnpsStatus.InProgress ? this._renderStepThree(data) : [this._renderStepOne(data), this._renderStepTwo(data)]}
					</div>
				)}
				<PreviewEmailModal
					content={previewEmailContent}
					isVisible={isPreviewModalOpen}
					onCloseModal={this._handlePreviewClose}
					title={previewEmailTitle}
				/>
			</section>
		);
	}
}
