import { makeStyles, createStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => {
	return createStyles({
		resetLoginContent: {
			marginBottom: '16px',
		},
		selectWrapper: {
			marginBottom: '31px',
		},
		IconAttention: {
			'& > svg': {
				fill: palette.warning.main,
			},
		},
	});
});
