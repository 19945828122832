import { Headline, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import './question.styl';

export function Question(props) {
	const { children, orderDisplay, subtext, text, textClassNames, isVisible = true } = props;
	return (
		<li className={`EnpsQuestion ${!isVisible && 'EnpsQuestion--hidden'}`}>
			{ifFeature(
				'encore',
				<div className='EnpsQuestion__heading--encore'>
					<Headline size='extra-small'>
						{orderDisplay && <span className='EnpsQuestion__number'>{orderDisplay}</span>}
						<span className={textClassNames}>{text}</span>
					</Headline>
				</div>,
				<h2 className='EnpsQuestion__heading baseColor'>
					{orderDisplay && <span className='EnpsQuestion__number'>{orderDisplay}</span>}
					<span className={textClassNames}>{text}</span>
				</h2>
			)}
			{subtext && <div className='EnpsQuestion__subtitle'>{subtext}</div>}
			{children}
		</li>
	);
}
