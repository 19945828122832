import { useState } from 'react';
import {
	Button,
	CardContentSpacing,
	makeStyles,
	StandardModal,
	TextButton
} from '@bamboohr/fabric';
import { Stopwatch45x54 } from '@bamboohr/grim';
import { requestTimeTrackingSubscription } from 'time-tracking/http';

const useStyles = makeStyles(({palette}) => {
	return {
		icon: {
			fill: palette.primary.main
		},
		bodyText: {
			textAlign: 'center',
			paddingTop: '8px'
		}
	};
});

interface SubscribeModalProps {
	closeModal: () => void;
	hasRedirect?: boolean;
	isOpen: boolean;
	onSuccess?: () => void;
}

export function SubscribeModal({closeModal, hasRedirect, isOpen, onSuccess}: SubscribeModalProps): JSX.Element {
	const styles = useStyles();
	const [isProcessing, setIsProcessing] = useState(false);

	const requestSubscription = (): void => {
		setIsProcessing(true);
		requestTimeTrackingSubscription(hasRedirect)
			.then(() => {
				if (typeof onSuccess === 'function') {
					onSuccess();
				}
			})
			.catch(() => {
				setIsProcessing(false);
			});
	};

	return (
		<StandardModal
			isOpen={ isOpen }
			onRequestClose={ closeModal }
		>
			<StandardModal.Body
				renderFooter={(
					<StandardModal.Footer
						actions={[
							<Button data-bi-id="time-tracking-trial-request-billing-info-button" key="request-billing-info" onClick={ requestSubscription } processing={ isProcessing }>{ $.__('Request Billing Info') }</Button>,
							<TextButton key="cancel-button" onClick={ closeModal } processing={ isProcessing }>{ $.__('Cancel') }</TextButton>
						]}
					/>
				)}
				renderHeader={ <StandardModal.Header title={ $.__('Subscribe to Time Tracking') } /> }
			>
				<StandardModal.Constraint
					spacingOverrides={{
						bottom: CardContentSpacing.MEDIUM,
						top: CardContentSpacing.MEDIUM
					}}
				>
					<StandardModal.HeroHeadline
						icon={(
							<span className={ styles.icon }>
								<Stopwatch45x54 />
							</span>
						)}
						text={ $.__(`We're happy to hear Time Tracking is working for you!`) }
					/>
					<p className={ styles.bodyText }>
						{ $.__('Someone will reach out shortly with additional billing information before adding Time Tracking to your subscription. In the meantime, Time Tracking will remain active for your team!') }
					</p>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
