import {
	BodyText,
	Button as EncoreButton,
	Flex,
	Headline,
	Icon,
	IconV2,
	InlineMessage,
	LayoutBox,
	Section,
	TextButton as EncoreTextButton,
} from '@bamboohr/fabric';
import { TrafficCone54x54 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TextButton } from '@fabric/button';
import Tooltip from 'Tooltip.mod';
import CalendarPicker from 'calendar-picker.react';
import { cloneDeep, noop, uniqueId } from 'lodash';
import { Component, Fragment } from 'react';
import Select from 'select.react';
import { getSettingsEnpsPreviewEmail } from '../../../../settings-enps-service';
import { EnpsHeader } from '../../enps-header';
import { EnpsSection } from '../../enps-section';
import { EnpsModal } from '../../shared/enps-modal';
import { PreviewEmailModal } from '../../shared/preview-email-modal';
import { getRequestData } from './setup-service';
import './setup.styl';
import { SurveyEmail } from './survey-email';

export class Setup extends Component {
	constructor(props) {
		super(props);

		const { data } = this.props;

		this.state = {
			...cloneDeep(data),
			isSaving: false,
		};

		/* Todo: delete this line once the `calenderPicker`
		 ** object is removed from the BE data. It causes
		 ** issues with the calendarPicker min date when
		 ** switching beteen survey frequencies */
		delete this.state.calendarPicker;
	}

	get selectedSurveyFrequency() {
		const {
			surveyFrequencyData: { items },
		} = this.state;
		return items.find((item) => item.selected);
	}

	set selectedSurveyFrequency(selected) {
		if (typeof selected !== 'object') {
			throw new Error('"selected" for "surveyFrequency" must be an object');
		}

		const { surveyFrequencyData } = this.state;
		const newSurveyFrequencyData = { ...surveyFrequencyData };

		newSurveyFrequencyData.items = newSurveyFrequencyData.items.map((item) => {
			item.selected =
				item.selected === selected.selected && item.value === selected.value;
			return item;
		});

		this.setState(() => {
			return {
				calendarPickerSettings: {
					min: selected.minDate,
					start: selected.sendDate,
				},
				surveyFrequency: selected.value,
				sendDate: selected.sendDate,
				surveyFrequencyData: newSurveyFrequencyData,
				surveyFrequencyText: this._getSurveyFrequencyText(selected.value),
			};
		}, this._updateQuestionIconTooltipText(this.questionIconTooltip));
	}

	_getSurveyFrequencyText(value) {
		return value === 3 ? 'three' : 'six';
	}

	_getQuestionIconTooltipText() {
		const { surveyFrequencyText } = this.state;
		return $.__(
			'Select a date at least %1 months from the last eNPS survey.',
			surveyFrequencyText,
			{
				note: '%1 refers to the number of months for a survey timeframe',
			},
		);
	}

	_updateQuestionIconTooltipText(tooltip) {
		setTimeout(() => {
			tooltip.balloonContentElement.querySelector(
				'.TooltipStandard.TooltipStandard--textLeft',
			).innerText = this._getQuestionIconTooltipText();
		});
	}

	_setupQuestionIconTooltip(parent) {
		if (parent) {
			this.questionIconTooltip = Tooltip.create(parent, {
				template: {
					name: 'tooltip-standard',
					data: {
						content: {}, // can't pass in content until the `surveyFrequencyText` state is set
					},
				},
				position: 'right',
			});
		}
	}

	_setupValidation() {
		setTimeout(() => {
			$(this._formElement).bhrValidate();
		});
	}

	_renderStepOne() {
		const { data } = this.props;
		const {
			calendarPickerSettings,
			fromUserId,
			includeContractors,
			isPreviewEmailModalOpen,
			previewEmailContent,
			selectData,
			surveyFrequencyText,
		} = this.state;

		const previewLabel = $.__('Preview Email');
		const selectSettings = {
			name: 'formSelect',
			required: true,
		};

		const sendToAllId = uniqueId('sendToAllEmployeesOn');
		const includeContractorsId = uniqueId('includeContractors');
		const showAsFromId = uniqueId('showAsFrom');

		return (
			// ToDo Performance Cleanup - Remove call to EnpsSection
			<EnpsSection iconName="fab-envelope-24x20" step="1">
				{ifFeature(
					'encore',
					<>
						{window.GLOBAL_IS_TRIAL === '' && (
							<LayoutBox marginBottom={2}>
								<InlineMessage
									icon="triangle-exclamation-solid"
									status="warning"
									title={$.__('Disabled for trial accounts.')}
								/>
							</LayoutBox>
						)}
						<LayoutBox marginBottom={2.5}>
							<Section.Header
								description={$.__(
									'This email introduces the survey, encourages honest feedback, and assures anonymity. An email with the actual survey will be sent one day after this email.',
								)}
								size="small"
								title={$.__('Employee Satisfaction Introduction Email')}
							/>
						</LayoutBox>
					</>,
					<>
						<div className="Setup__stepHeading">
							{$.__('Employee Satisfaction Introduction Email')}
						</div>
						<p className="Setup__stepParagraph">
							{$.__(
								'This email introduces the survey, encourages honest feedback, and assures anonymity. An email with the actual survey will be sent one day after this email.',
							)}
						</p>
					</>,
				)}

				<div className="fieldRow Setup__sendDateWrapper">
					<div className="fieldBox">
						{ifFeature('encore',
							<LayoutBox marginBottom={0.5}>
								<label
									className="fab-Label fab-Label--required"
									htmlFor={sendToAllId}
								>
									{$.__('Send to all employees on')}
								</label>
							</LayoutBox>,
							<label
								className="fab-Label fab-Label--required"
								htmlFor={sendToAllId}
							>
								{$.__('Send to all employees on')}
							</label>
						)}
						<div className="fieldDiv">
							<CalendarPicker
								cssClasses={{
									single: ifFeature(
										'encore',
										'fab-TextInput--width7',
										'fab-TextInput--width5',
									),
								}}
								id={sendToAllId}
								name="sendDate"
								onChange={this._handleSendDateChange}
								required="true"
								settings={calendarPickerSettings}
								type="date"
							/>
						</div>

						{data.showDateInfo &&
							ifFeature(
								'encore',
								<Flex
									alignItems="center"
									display="inline-flex"
									marginLeft={1.5}
								>
									<BodyText color="neutral-medium" size="small">
										{$.__(
											'Surveys can only be sent out every %1 months.',
											surveyFrequencyText,
											{
												note: '%1 refers to the number of months for a survey timeframe',
											},
										)}
									</BodyText>
									<Flex marginLeft={0.5} ref={(el) => (this.questionIcon = el)}>
										<IconV2
											name="circle-question-regular"
											size={16}
											color="neutral-medium"
										/>
									</Flex>
								</Flex>,
								<span className="fab-FormNote">
									{$.__(
										'Surveys can only be sent out every %1 months.',
										surveyFrequencyText,
										{
											note: '%1 refers to the number of months for a survey timeframe',
										},
									)}
									<span
										ref={(el) => (this.questionIcon = el)}
										className="Setup__6MthInfoIcon"
									>
										<Icon name="fab-question-outline-14x14" />
									</span>
								</span>,
							)}
					</div>
				</div>

				<div className="fab-Checkbox Setup__includeContractors">
					<input
						checked={includeContractors}
						className="fab-Checkbox__input"
						id={includeContractorsId}
						name="includeContractors"
						onChange={this._handleIncludeContractors}
						type="checkbox"
					/>
					<label className="fab-Checkbox__label" htmlFor={includeContractorsId}>
						{$.__('Include Contractors')}
					</label>
				</div>

				<div className="fieldRow">
					<div
						aria-labelledby="showAsLabel"
						className="fieldBox required"
						role="listbox"
					>
						{ifFeature('encore',
							<LayoutBox marginBottom={0.5}>
								<label
									id="showAsLabel"
									className="fab-Label fab-Label--required"
									htmlFor={showAsFromId}
								>
									{$.__('Send email as')}
								</label>
							</LayoutBox>,
							<label
								id="showAsLabel"
								className="fab-Label fab-Label--required"
								htmlFor={showAsFromId}
							>
								{$.__('Show as from')}
							</label>
						)}
						<div className="fieldDiv">
							{
								<Select
									className="xlong"
									data={selectData}
									id={showAsFromId}
									onChange={this._handleSurveyFromChange}
									selectedValues={[fromUserId]}
									settings={selectSettings}
									width="7"
								/>
							}
						</div>
						{ifFeature(
							'encore',
							<Flex display="inline-flex" marginLeft={1.5}>
								<BodyText color="neutral-medium" size="small">
									{$.__('Send from a company leader to get more responses.')}
								</BodyText>
							</Flex>,
							<span className="fab-FormNote">
								{$.__('Send from a company leader to get more responses.')}
							</span>,
						)}
					</div>
				</div>

				<div className="Setup__lessMargin fieldRow">
					<div className="fieldBox fullWidth">
						<label
							id="emailLabel"
							className="fab-Label fab-Label--required"
							htmlFor="emailMessage"
						>
							{$.__('Email Message')}
						</label>
						<textarea
							aria-required="true"
							aria-labelledby="emailLabel"
							className="fab-Textarea fab-Textarea--width100 Setup__emailBody"
							defaultValue={data.emailMessage}
							id="emailMessage"
							name="emailMessage"
							role="textbox"
						></textarea>
					</div>
				</div>

				<div className="Setup__emailEnd">
					<p>
						{$.__(
							'The survey will be administered through BambooHR and the results are completely anonymous (emails or IP addresses will not be captured). Please be thoughtful, honest, and candid when you complete the survey.',
						)}
					</p>
					<p>
						{$.__(
							'Our goal is 100%% completion—each department is unique and everyone has valuable feedback. The survey will be open for one week.',
						)}
					</p>
					<p>
						{$.__(
							'Heads up! If you sign your name (or use any other clearly distinguishing bit of information) the survey is no longer anonymous!',
						)}
					</p>
				</div>

				{ifFeature(
					'encore',
					<>
						<Flex
							justifyContent="space-between"
							alignItems="center"
							height="22px"
						>
							<BodyText color="neutral-medium" size="small">
								{$.__(
									'Pssst...we will add this text to the end of your email. It’s important stuff!',
								)}
							</BodyText>
							<EncoreTextButton
								onClick={this._handlePreviewEmailOpen}
								startIcon="eye-regular"
								type="button"
							>
								{previewLabel}
							</EncoreTextButton>
						</Flex>
						<PreviewEmailModal
							content={previewEmailContent}
							isVisible={isPreviewEmailModalOpen}
							onCloseModal={this._handlePreviewEmailClose}
							title={$.__('Employee Satisfaction Introduction Email')}
						/>
					</>,
					<div className="Setup__pssst">
						<span>
							{$.__(
								'Pssst...we will add this text to the end of your email. It’s important stuff!',
							)}
						</span>
						<TextButton
							clickAction={this._handlePreviewEmailOpen}
							iconBefore="eye-16x12"
							text={previewLabel}
							type="button"
						/>
						<PreviewEmailModal
							content={previewEmailContent}
							isVisible={isPreviewEmailModalOpen}
							onCloseModal={this._handlePreviewEmailClose}
							title={$.__('Employee Satisfaction Introduction Email')}
						/>
					</div>,
				)}
			</EnpsSection>
		);
	}

	_autoSave() {
		return setInterval(() => {
			this._save('autosave');
		}, 30000);
	}

	_save(endpoint = 'save') {
		const { onNext } = this.props;
		const requestData = getRequestData(this.state, endpoint);
		return onNext(requestData);
	}

	_showErrorMessage() {
		setMessage(
			$.__('It looks like something went wrong! Try again later.'),
			'error',
		);
	}

	_handleIncludeContractors = () => {
		const { includeContractors } = this.state;
		this.setState({ includeContractors: !includeContractors });
	};

	_handlePreviewEmailClose = () => {
		this.setState(() => {
			return {
				isPreviewEmailModalOpen: false,
			};
		});
	};

	_handlePreviewEmailOpen = () => {
		this._save('autosave').then(
			() => {
				getSettingsEnpsPreviewEmail('/settings/enps/introEmailPreview').then(
					(email) => {
						this.setState(() => {
							return {
								isPreviewEmailModalOpen: true,
								previewEmailContent: email,
							};
						});
					},
					() => {
						this._showErrorMessage();
					},
				);
			},
			() => {
				this._showErrorMessage();
			},
		);
	};

	_handleSave = (e) => {
		e.preventDefault();
		if ($(this._formElement).valid()) {
			this.setState({ isSaving: true });
			return window.GLOBAL_IS_TRIAL === 'true'
				? this._handleTrialModalOpen()
				: this._save();
		}
	};

	_handleSendDateChange = (newDate) => {
		const { calendarPickerSettings } = this.state;
		this.setState({
			calendarPickerSettings: { ...calendarPickerSettings, start: newDate },
			sendDate: newDate,
		});
	};

	_handleSurveyFromChange = (selectedItem) => {
		const { selectData } = this.state;
		const newSelectData = { ...selectData };

		newSelectData.items = newSelectData.items.map((item) => {
			item.selected = item === selectedItem;
			return item;
		});

		this.setState({
			fromUserId: selectedItem ? selectedItem.value : '',
			selectData: newSelectData,
		});
	};

	_handleSurveyFrequencyChange = (selectedItem) => {
		this.selectedSurveyFrequency = selectedItem;
	};

	_handleTrialModalClose = () => {
		this.setState(() => {
			return {
				isSaving: false,
				isTrialModalOpen: false,
			};
		});
	};

	_handleTrialModalOpen = () => {
		this.setState(() => {
			return {
				isSaving: true,
				isTrialModalOpen: true,
			};
		});
	};

	questionIconTooltip = null;

	componentDidMount() {
		const { isEditing } = this.props;
		this._setupValidation();
		this._setupQuestionIconTooltip(this.questionIcon);
		this.selectedSurveyFrequency = this.selectedSurveyFrequency;
		this.autoSave = isEditing ? noop : this._autoSave();
	}

	componentDidUpdate() {
		this._setupValidation();
	}

	componentWillUnmount() {
		if (this.questionIconTooltip) {
			this.questionIconTooltip.destroy();
		}

		clearInterval(this.autoSave);
	}

	render() {
		const {
			data: { showKillSwitch },
			onCancel,
			onNext,
		} = this.props;
		const { isSaving, isTrialModalOpen, sendDate } = this.state;
		const labels = {
			cancel: $.__('Cancel'),
			save: $.__('Save'),
		};
		return (
			<Fragment>
				<section className="Setup BhrForms">
					<EnpsHeader hasTrialMessage={true} showKillSwitch={showKillSwitch} />
					<form
						ref={(element) => (this._formElement = element)}
						onSubmit={this._handleSave}
					>
						{this._renderStepOne()}

						<SurveyEmail
							enpsData={this.state}
							newSendDate={sendDate}
							onNextHandler={onNext}
							onSurveyFrequencyChange={this._handleSurveyFrequencyChange}
						/>

						<footer className="Setup__footer" role="contentinfo">
							<Flex>
								<EncoreButton processing={isSaving}>{labels.save}</EncoreButton>
								{ifFeature(
									'encore',
									<LayoutBox marginLeft={2}>
										<EncoreButton
											type="button"
											onClick={onCancel}
											color="secondary"
											variant="outlined"
										>
											{labels.cancel}
										</EncoreButton>
									</LayoutBox>,
									<span className="Setup__footerCancelButton">
										<TextButton
											clickAction={onCancel}
											text={labels.cancel}
											type="button"
										/>
									</span>,
								)}
							</Flex>
						</footer>
					</form>
				</section>
				<EnpsModal
					isVisible={isTrialModalOpen}
					onCloseModal={this._handleTrialModalClose}
					options={{
						alternativeAction: null,
						primaryAction: this._handleTrialModalClose,
						primaryActionText: $.__('Done'),
						title: $.__('Heads Up'),
					}}
				>
					<Flex
						alignItems="center"
						flexDirection="column"
						margin="auto"
						maxWidth="456px"
						padding="20px 20px 25px"
						textAlign="center"
					>
						<LayoutBox margin="10px 0 20px">
							<TrafficCone54x54 />
						</LayoutBox>
						<Headline color="neutral-medium" size="medium">
							{$.__(
								"You can't send Employee Satisfaction surveys in the trial account of BambooHR.",
							)}
						</Headline>
					</Flex>
				</EnpsModal>
			</Fragment>
		);
	}
}