export const radioOptions = [
	{
		id: 'none',
		label: $.__('None'),
		value: 'none'
	},
	{
		id: 'all',
		label: $.__('All Employees'),
		value: 'all'
	},
	{
		id: 'some',
		label: $.__('Only Some Employees'),
		value: 'some'
	}
];
