import Ajax from '@utils/ajax';

// Only set alternative action info if we are past the first page
export const getAlternativeActionInfo = (currentStep, alternativeAction) => {
	const info = {
		action: null,
		text: '',
	};

	if (currentStep > 1) {
		info.action = alternativeAction;
		info.text = $.__('Go Back');
	}

	return info;
};

export function postEnableAca(data) {
	return Ajax.post('/settings/aca/enable', data);
}
