import { Table } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';

import moment from 'moment';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { formatAmount } from '../shared/code-rate-row/utils';

export function RateHistoryModal(props) {
	const {
		code,
		description,
		isOpen,
		handleClose,
		rowData,
	} = props;

	return (
		<Modal
			alternativeAction={ null }
			headerType="text"
			headline={ `${ code } — ${ description }` }
			icon={ifFeature('encore', null, 'fab-clock-reverse-20x18')}
			isOpen={ isOpen }
			onClose={ handleClose }
			primaryAction={ handleClose }
			primaryActionText={ $.__('Done') }
			title={ $.__('Rate History') }
			type="medium"
		>
			<Table
				caption={ $.__('Class codes and rate history') }
				columns={ COLUMNS }
				rowKey={ row => row.startDate }
				rows={ rowData }
			/>
		</Modal>
	);
}

const BLANK_DATA = '--';
const COLUMNS = [
	{
		header: $.__('Start Date'),
		cell: row => formatDate(row.startDate) || BLANK_DATA,
	},
	{
		header: $.__('End Date'),
		cell: row => formatDate(row.endDate) || BLANK_DATA,
	},
	{
		header: $.__('Employer Rate'),
		cell: (row) => {
			const {
				employerRate,
			} = row;
			return employerRate ? getHourlyRateText(employerRate) : BLANK_DATA;
		},
	},
	{
		header: $.__('Employee Rate'),
		cell: (row) => {
			const {
				employeeRate,
			} = row;
			return employeeRate ? getHourlyRateText(employeeRate) : BLANK_DATA;
		},
	},
	{
		header: $.__('Total'),
		cell: (row) => {
			const {
				totalRate,
			} = row;
			return totalRate ? getHourlyRateText(totalRate) : BLANK_DATA;
		},
	},
];

/**
 * Get the text for the cells that contain a rate
 * @param rate {string} The hourly rate
 * @returns    {string}
 */
function getHourlyRateText(rate) {
	return $.__('%1$s/hour', formatAmount(rate));
}

/**
 * Format the date given from the BE
 * @param date 	{string} The date given from the BE
 * @returns		{string} Formatted string
 */
function formatDate(date) {
	if (!date) {
		return null;
	}
	return moment(date).format('M/D/YYYY');
}
