import {
	useReducerContext,
} from '../state';

import { Icon } from '@bamboohr/fabric';

import TranslationInput from '../translation-input';
import RowActions from '../row-actions';

import {
	isMissing,
} from '../utils';

/**
 * @param {object} props
 * @param {Field} [props.field]
 * @param {Page} [props.page]
 * @param {Field} [props.table]
 * @param {[number, number]} [props.index]
 */
export default function Row(props) {
	const {
		bem,
		dispatch,
		TOGGLE_PAGE,
		SAVE_TRANSLATION,
		...context
	} = useReducerContext();
	const {
		field,
		page,
		table,
		index,
		NameCell,
		TranslationCell,
		ActionsCell,
		missing: _missing = null,
		missingFields: _missingFields = null,
	} = props;
	const item = page || table || field;
	const {
		id,
		name,
	} = item;
	const fields = Object.values(item.fields || {})
		.filter(({ id }) => !String(id).includes('.'));
	const [n, count] = index || [-1, 0];
	const first = n === 1;
	const last = n === count;
	const missingFields = typeof _missingFields === 'boolean' ? _missingFields : hasMissingFields(fields, context);
	const missing = typeof _missing === 'boolean' ? _missing : isMissing(item, context);
	const noToggle = (
		fields &&
		Object.values(fields).length < 1
	);
	const renderProps = {
		item,
		index: n,
		count,
		first,
		last,
		missing,
	};

	return (
		<tr className={ bem('tableRow', { first, last, missing: missingFields }, props) }>
			<td
				className={ bem('tableCell', ['key', { noToggle }], props) }
				onClick={ () => page && dispatch(TOGGLE_PAGE({ id }))}
			>
				{ NameCell ? (
					<NameCell { ...renderProps } />
				) : name }
			</td>
			<td
				className={ bem('tableCell', ['value', {
					missing,
				}], props) }
			>
				{ TranslationCell ? (
					<TranslationCell { ...renderProps } />
				) : (
					<TranslationInput
						item={ item }
						onSave={ newItem => dispatch(SAVE_TRANSLATION({ item: newItem })) }
						size="teenie"
					/>
				) }
			</td>
			<td className={ bem('tableCell', 'actions', props) }>
				{ ActionsCell ? (
					<ActionsCell { ...renderProps } />
				) : field && (
					<RowActions id={ field.id } />
				) }
			</td>
		</tr>
	);
}

/**
 *
 * @param {Field[] | FieldList} [fields]
 * @param {TranslationTableContext} context
 * @returns {boolean}
 */
function hasMissingFields(fields, context) {
	if (!Array.isArray(fields)) {
		fields = Object.values(fields || {});
	}

	return fields.some((field) => {
		if (field.fields) {
			return hasMissingFields(field.fields, context);
		}

		return isMissing(field, context);
	});
}

/** @typedef {import('../state').Page} Page */
/** @typedef {import('../state').Field} Field */
/** @typedef {import('../state').FieldList} FieldList */
/** @typedef {import('../state').FieldsRT} FieldsRT */
/** @typedef {import('../state').TranslationTableContext} TranslationTableContext */
