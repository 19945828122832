import { FilterSelections, FilterType } from '../../types';

export const groupHasSelectionForFilter = (
	group: FilterSelections,
	filterType: FilterType
): boolean => {
	return group[filterType] && group[filterType].length > 0;
};

export const bothGroupsHaveSelectionsForFilter = (
	firstGroup: FilterSelections,
	secondGroup: FilterSelections,
	filterType: FilterType
): boolean => {
	return groupHasSelectionForFilter(firstGroup, filterType) &&
		groupHasSelectionForFilter(secondGroup, filterType);
};
