import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { getGoalSettingsData } from './services/get-goal-settings-data';
import { ContextType, FiltersPayload, GoalSettingsData } from './types';

const Context = createContext<ContextType>(undefined);

export default Context;

interface Props {
	value: Record<string, unknown>;
	children: JSX.Element;
}

export const Provider = (props: Props): JSX.Element => {
	const { value, children } = props;

	const [selectedFilters, setSelectedFilters] = useState<FiltersPayload>(null);
	const [cancelButtonClicked, setCancelButtonClicked] = useState(false);

	useEffect(() => {
		getGoalSettingsData().then((response) => {
			const { filters } = response as GoalSettingsData;
			const filtersSelected = { ...filters };

			setSelectedFilters(filtersSelected);
		});
	}, []);

	const state = useMemo(
		() => [
			{
				cancelButtonClicked,
				selectedFilters,
			},
			{
				setCancelButtonClicked,
				setSelectedFilters,
			},
		],
		[cancelButtonClicked, selectedFilters]
	);

	return <Context.Provider value={state as ContextType}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useGoalSettingsContext = (): ContextType => useContext(Context);
