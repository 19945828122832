import {
	findW2Box12DdOption,
} from './find-w2-box-12dd-option';
import {
	serializeMutateW2Box12DdChoice,
} from './serialize-mutate-w2-box-12dd-choice';
import type {
	ClientTaxSettingsResponseDataCollection,
} from '../types';

export function serializeClientTaxSettings(clientTaxSettings: ClientTaxSettingsResponseDataCollection) {
	if (!Array.isArray(clientTaxSettings)) {
		return [];
	}
	const w2Box12Dd = findW2Box12DdOption(clientTaxSettings);
	serializeMutateW2Box12DdChoice(w2Box12Dd);
	return clientTaxSettings;
}
