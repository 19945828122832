import { ajax } from '@bamboohr/utils/lib/ajax';

export const getAuthToken = () => {
    return ajax.post('/auth/token_sessions');
}
export const getGlobalPayees = (authToken, globalPayeeId) => {
    const url = `https://${ window.PAYROLLSERVICES_HOST }/v2/global/payees?globalPayeeIds=${globalPayeeId}`;
    const headers = {
		"headers": {
			"Authorization": `Bearer ${ authToken }`
		}
	}
    return ajax.get(url,null,headers);
}
