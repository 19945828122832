import {
	format,
	lastDayOfYear,
} from '@bamboohr/utils/lib/datetime';
import {
	InlineMessage,
	InlineMessageStatusType,
} from '@bamboohr/fabric';

import {
	useStyles,
} from './modal-footer-message-info.styles';
import {
	L_FUNC_MODAL_FOOTER_MESSAGE_INFO_SUB_TEXT,
	L_FUNC_MODAL_FOOTER_MESSAGE_INFO_TEXT,
} from '../language';

export function ModalFooterMessageInfo() {
	const classes = useStyles();
	const now = lastDayOfYear(new Date());
	const shortMonth = format(now, 'MMM');
	const month = format(now, 'MMMM');
	const day = format(now, 'd');
	const ordinalDay = format(now, 'do');
	const year = format(now, 'yyyy');
	return (
		<div className={ classes.root }>
			<InlineMessage
				// eslint-disable-next-line new-cap
				description={ L_FUNC_MODAL_FOOTER_MESSAGE_INFO_SUB_TEXT(shortMonth, day) }
				status={ InlineMessageStatusType.info }
				// eslint-disable-next-line new-cap
				title={ L_FUNC_MODAL_FOOTER_MESSAGE_INFO_TEXT(month, ordinalDay, year) }
			/>
		</div>
	);
}
