import { AdvancedWorkflowContainer } from '../../containers/advanced-workflow';
import { StandardWorkflowContainer } from '../../containers/standard-workflow';

export const Workflows = (props = {}) => {
	const { advancedWorkflow } = props;
	if (!props || typeof advancedWorkflow === 'undefined') {
		return null;
	}

	return (
		advancedWorkflow
			? <AdvancedWorkflowContainer />
			: <StandardWorkflowContainer />
	);
}
