import {
	BEM,
} from '@utils/dom';
import {
	Button,
} from '@fabric/button';


import {
	ExternalLink,
	Message,
} from '@bamboohr/utils/lib/message';

import {
	HELP_GUIDES_URL,
} from '../../constants';
import './get-started.styl';

const bem = new BEM('GetStarted');

export function GetStarted({ toggleGetStarted }) {

	return (
		<div className={ bem.elem() }>
			<h3 className={ bem.elem('header') }>
				{ $.__('Add more security to your BambooHR Account') }
			</h3>

			<iframe
				allowFullScreen={ true }
				allowtransparency="true" // eslint-disable-line
				className="wistia_embed"
				frameBorder="0"
				height="320px"
				mozallowfullscreen="true"
				msallowfullscreen="true"
				name="wistia_embed"
				oallowfullscreen="true"
				scrolling="no"
				src="//fast.wistia.net/embed/iframe/an3889g6ev?videoFoam=true"
				title="2 step login video"
				webkitallowfullscreen="true"
				width="570px"
			>
			</iframe>
			<script async={ true } src="//fast.wistia.net/assets/external/E-v1.js"></script>
			<span className={ bem.elem('getStartedBtn') }>
				<Button
					clickAction={ () => toggleGetStarted(false) }
					size="biggie"
					text={ $.__('Get Started') }
				/>
			</span>
			<p className={ bem.elem('info') }>
				{ $.__('The bad guys are getting pretty good at getting into things they shouldn\'t. 2-Step Login adds another layer of security to protect your account. When enabled, BambooHR access will require a password and a code generated from an authenticator app.') }
			</p>
			<p className={ bem.elem('info') }>
				<Message
					link={ ExternalLink(HELP_GUIDES_URL) }
					text={ $._('Learn more with our [help guide]') }
				/>.
			</p>
		</div>
	);

}
