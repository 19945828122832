export class FranklinWebComponent extends HTMLElement {
	constructor() {
		super();

		// Attaches a shadow DOM tree to the element
		// With mode open the shadow root elements are accessible from JavaScript outside the root
		this.attachShadow({ mode: 'open' });

		// Keep track if we have rendered the fragment yet.
		this.initialized = false;
		window.franklinCache = {};
	}

	createStyle(origin, path, onload, onerror) {
		const styles = document.createElement('link');
		styles.setAttribute('rel', 'stylesheet');
		styles.setAttribute('href', `${origin}${path}`);
		if (onload) {
			styles.onload = onload;
		}
		if (onerror) {
			styles.onerror = onerror;
		}
		return styles;
	}

	/**
	 * Invoked each time the custom element is appended into a document-connected element.
	 * This will happen each time the node is moved, and may happen before the element's contents
	 * have been fully parsed.
	 */
	async connectedCallback() {
		if (!this.initialized) {
			try {
				const urlAttribute = this.attributes.getNamedItem('url');
				if (!urlAttribute) {
					throw new Error('franklin-fragment missing url attribute');
				}

				const body = document.createElement('body');
				body.style = 'display: none';
				this.shadowRoot.append(body);

				const { href, origin } = new URL(`${urlAttribute.value}.plain.html`);

				// Load fragment
				const resp = await fetch(href);
				if (!resp.ok) {
					throw new Error(`Unable to fetch ${href}`);
				}

				const styles = this.createStyle(
					origin,
					'/styles/styles.css',
					() => (body.style = ''),
					() => (body.style = '')
				);
				this.shadowRoot.append(styles);

				const main = document.createElement('main');
				body.append(main);

				let htmlText = await resp.text();
				// Fix relative image urls
				const regex = /\.\/media/g;
				htmlText = htmlText.replace(regex, `${origin}/media`);
				main.innerHTML = htmlText;

				// Set initialized to true so we don't run through this again
				this.initialized = true;

				// Query all the blocks in the fragment
				const blockElements = main.querySelectorAll(':scope > div > div');

				// Did we find any blocks or all default content?
				if (blockElements.length > 0) {
					// Get the block names
					const blocks = Array.from(blockElements).map((block) => block.classList.item(0));

					// Load scripts file for fragment host site
					window.hlx = window.hlx || {};
					window.hlx.suppressLoadPage = true;
					window.hlx.serverPath = origin;
					window.hlx.codeBasePath = '';

					// Loads the main script
					await this.loadMainScript(origin, main);
					body.classList.add('appear');

					// For each block in the fragment load it's js/css
					await this.buildBlocks(blockElements, blocks, origin, body);

					const sections = main.querySelectorAll('.section');
					sections.forEach((s) => {
						s.dataset.sectionStatus = 'loaded';
					});
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err || 'An error occurred while loading the fragment');
			}
		}
		this.dispatchEvent(new CustomEvent('isLoaded'));
	}

	async loadMainScript(origin, main) {
		// having webpack ignore dynamic import so it is handled by the browser
		const { decorateMain } = await import(/* webpackIgnore: true */ `${origin}/scripts/scripts.js`);
		if (decorateMain) {
			await decorateMain(main);
		}
	}

	async buildBlocks(blockElements, blocks, origin, body) {
		for (let i = 0; i < blockElements.length; i += 1) {
			const blockName = blocks[i];
			const block = blockElements[i];

			// Skip section-metadata block
			if (blockName === 'section-metadata') {
				// eslint-disable-next-line no-continue
				continue;
			}

			// Load block css
			const styleSheetPath = `${origin}/blocks/${blockName}/${blockName}.css`;
			if (!window.franklinCache[styleSheetPath]) {
				const link = document.createElement('link');
				link.setAttribute('rel', 'stylesheet');
				link.setAttribute('href', styleSheetPath);

				const cssLoaded = new Promise((resolve) => {
					link.onload = resolve;
					link.onerror = resolve;
				});

				body.appendChild(link);
				await cssLoaded;
				window.franklinCache[styleSheetPath] = true;
			}

			// Load block js and decorate it
			try {
				const blockScriptUrl = `${origin}/blocks/${blockName}/${blockName}.js`;

				let decorateBlock;
				if (window.franklinCache[blockScriptUrl]) {
					decorateBlock = window.franklinCache[blockScriptUrl];
				} else {
					// having webpack ignore dynamic import so it is handled by the browser
					decorateBlock = await import(/* webpackIgnore: true */ blockScriptUrl);
					window.franklinCache[blockScriptUrl] = decorateBlock;
				}

				if (decorateBlock.default) {
					await decorateBlock.default(block);
				}
			} catch (e) {
				console.log('An error occurred while loading the fragment');
			}

			// If 2 buttons are present in a single block add `secondary` dataset;
			const buttons = block.querySelectorAll('.button.accent');
			if (buttons.length === 2) {
				// eslint-disable-next-line prefer-destructuring
				const second = buttons[1];
				second.dataset.secondary = 1;
			}
		}
	}
}

customElements.define('franklin-web-component', FranklinWebComponent);
