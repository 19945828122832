import { Fragment } from 'react';

import {
	renderCompanySelectOption,
	renderDateSelectOption,
} from '../utils';

import { CompanyTaxField } from '../components/company-tax-field/company-tax-field';

export function AddTaxForm(props) {
	const {
		clientId,
		effectiveDate,
		notes,
		payFrequencyId,
		state,
		employerTaxId,
		taxRate,
		taxTypeId,
		taxTypeId: {
			selectedTaxType,
		},
		updateFormData,
	} = props;

	return (
		<Fragment>
			<CompanyTaxField
				{ ...clientId }
				fieldType="select"
				label={ $.__('Company/EIN') }
				renderOptionContent={ renderCompanySelectOption }
				renderToggleContent={ renderCompanySelectOption }
				updateFormData={ updateFormData }
				width={ 8 }
			/>
			<CompanyTaxField
				{ ...effectiveDate }
				clientId={ clientId.value }
				fieldType="effectiveDate"
				label={ $.__('Effective Date') }
				placeholder="First Select Company/EIN"
				renderOptionContent={ renderDateSelectOption }
				renderToggleContent={ renderDateSelectOption }
				updateFormData={ updateFormData }
				width={ 8 }
			/>
			<CompanyTaxField
				{ ...state }
				clientId={ clientId.value }
				fieldType="state"
				label={ $.__('State') }
				placeholder="First Select Company/EIN"
				taxTypeIdName={ taxTypeId.name }
				updateFormData={ updateFormData }
				width={ 6 }
			/>
			<CompanyTaxField
				{ ...taxTypeId }
				clientId={ clientId.value }
				effectiveDate={ effectiveDate.value }
				fieldType="taxType"
				label={ $.__('Tax Type') }
				placeholder="First Select State"
				selectedState={ state.selectedState }
				updateFormData={ updateFormData }
				width={ 6 }
			/>
			<CompanyTaxField
				{ ...payFrequencyId }
				fieldType="payFrequency"
				label={ $.__('Pay Frequency') }
				placeholder="First Select Tax Type"
				selectedTaxType={ selectedTaxType }
				updateFormData={ updateFormData }
				width={ 6 }
			/>
			<CompanyTaxField
				{ ...employerTaxId }
				fieldType="tax-id"
				label={ $.__('Tax ID') }
				name="employerTaxId"
				selectedTaxType={ selectedTaxType }
				updateFormData={ updateFormData }
				width={ 8 }
			/>
			<CompanyTaxField
				{ ...taxRate }
				fieldType="taxRate"
				label={ $.__('Tax Rate') }
				selectedTaxType={ taxTypeId.selectedTaxType }
				unit="%"
				updateFormData={ updateFormData }
			/>
			{/* <CompanyTaxField
				{ ...notes }
				fieldType="textarea"
				label={ $.__('Notes') }
				updateFormData={ updateFormData }
				width={ 9 }
			/> */}
		</Fragment>
	);
}
