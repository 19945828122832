import { Select } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	useReducerContext,
} from '../state';

export default function LangSelector() {
	const {
		languages,
		language: {
			value,
		},
		loading,
		bem,
		dispatch,
		SET_LANGUAGE,
	} = useReducerContext();

	return (
		<div className={ bem('langSelector') }>
			<Select
				isClearable={ false }
				isDisabled={ loading }
				items={ Object.values(languages) }
				onChange={ ([id]) => dispatch(SET_LANGUAGE({ id })) }
				selectedValues={ [value] }
				size={ifFeature('encore', 'small')}
				variant={ifFeature('encore', 'single')}
			/>
		</div>
	);
}
