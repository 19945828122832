import Ajax from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import listTemplate from './forms.react';

export const showEditEmployeeFieldModal = (data) => {
	let theForm;
	let $validator;

	const primaryAction = () => {
		const isValid = $validator.form();

		if (isValid) {
			window.BambooHR.Modal.setState({ isProcessing: true }, true);

			const preppedData = new FormData(theForm);

			Ajax.post(`/settings/list.php?id=${data.list_id}`, preppedData)
				.then((res) => {
					const data = JSON.parse(res.request.responseText);

					if (data && data.ret && data.ret === 'ERROR') {
						window.BambooHR.Modal.setState({ isProcessing: false }, true);
						return setMessage(data.error, 'error');
					}
					window.location.reload();
				})
				.catch(() => {
					window.errorFallBack();
				});
		}
	};

	window.BambooHR.Modal.setState({
		content: listTemplate(data),
		isOpen: true,
		isProcessing: false,
		primaryAction,
		primaryActionText: $.__('Save Change'),
		title: $.__('Edit'),
	});

	theForm = document.querySelector('#editValueForm');
	$validator = $(theForm).bhrValidate();

	// Handle submit
	theForm.addEventListener('submit', (e) => {
		e.preventDefault();
		primaryAction();
	});
};

export const showDeleteEmployeeFieldModal = (data, primaryAction) => {
	const { action, id, label, list_id, title, totalHistoryCount } = data;

	const defaultPrimaryAction = function () {
		window.BambooHR.Modal.setState(
			{
				isProcessing: true,
			},
			true
		);

		const postData = Ajax.prepareData(
			{
				action,
				id,
				totalCount: totalHistoryCount,
			},
			Ajax.prepareDataTypes.FORM_DATA
		);

		Ajax.post(`/settings/list.php?id=${list_id}`, postData)
			.then(() => window.location.reload())
			.catch(() => window.errorFallBack());
	};

	window.BambooHR.Modal.setState({
		headline: $.__('Are you sure you want to delete the "%s" %s?', label, title),
		isOpen: true,
		primaryAction: primaryAction || defaultPrimaryAction,
		primaryActionText: $.__('Yes, Delete %s', title),
		title: ifFeature('encore', null, $.__('Delete %s', title)),
		type: 'aggressiveConfirmation',
		iconColor: ifFeature('encore', 'error-strong', 'danger'),
		iconV2Color: ifFeature('encore', 'error-strong', 'danger'),
	});
};

export const showFabricEmployeeFieldFixItModal = (activeCount, fieldTitle) => {
	let headline = $.__(`You've got 1 employee assigned to this %1$s`, fieldTitle);

	if (activeCount > 1) {
		headline = $.__(`You've got %1$s employees in this %2$s`, activeCount, fieldTitle);
	}

	window.BambooHR.Modal.setState({
		isOpen: true,
		dangerousContent: `<p class="EmployeeFieldsForm__deleteWarning">${$.__(
			`Don't leave them hanging; move them to another %1$s before deleting this.`,
			fieldTitle
		)}</p>`,
		title: $.__("Can't Delete Yet..."),
		headline: headline,
		icon: ifFeature('encore', 'triangle-exclamation-regular', 'fab-triangle-exclamation-54x54'),
		iconColor: ifFeature('encore', 'warning-medium', 'attention'),
		iconV2Color: ifFeature('encore', 'warning-medium', 'attention'),
		primaryActionText: $.__("I'll Fix It"),
		primaryAction: function () {
			window.BambooHR.Modal.setState({ isOpen: false });
		},
	});
};
