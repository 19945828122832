import { render } from 'base/wrapped-render';

import Ctrl, {
	onReady,
} from 'ctrl.deco';

import { PrivateSignatureKeyField } from './private-signature-key-field.react';

@Ctrl('/settings/account/webhooks/edit.php*')
class SettingsAccountWebhooksEditCtrl {
	@onReady()
	_onReady() {
		const privateSignatureKeyRoot = document.getElementById('js-webhooks-private-signature-key');
		if (privateSignatureKeyRoot) {
			const { signatureKey } = privateSignatureKeyRoot.dataset;
			render(<PrivateSignatureKeyField savedKey={ signatureKey } />, privateSignatureKeyRoot);
		}
	}
}
