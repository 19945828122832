import { makeStyles, createStyles } from "@bamboohr/fabric";

export const useStyles = makeStyles(({ palette, typography }) => {
	return createStyles({
		enpsIconOverHeading: {
			margin: '72px 110px 35px 110px',
			textAlign: 'center',
		},
		enpsIconOverHeadingFigure: {
			margin: '0 0 16px',
		},
		enpsIconOverHeadingTitle: {
			color: palette.primary.main,
			fontWeight: 'semibold',
			fontSize: '22px',
			lineHeight: '30px',
		},
		enpsIconOverHeadingSubtitle: {
			marginTop: '13px',
			color: palette.gray[900],
			fontSize: typography.medium.fontSize,
			lineHeight: `${typography.medium.fontSize}px`,
		},
		enpsIconOverHeadingIcon: {
			fill: palette.primary.main,
		},
		enpsIconOverHeadingStatusHeading: {
			margin: '72px 110px 35px 110px',
		}
	})
});
