/* @startCleanup encore */
import { FloatingIconButton } from '@fabric/button';
/* @endCleanup encore */
import { BodyText, IconButton, Link } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isEnabled } from 'FeatureToggle.util';
import { htmlentities } from 'String.util';
import axios from 'axios';
import { keyBy } from 'lodash';
import { Fragment, PureComponent } from 'react';
import Tooltip from 'tooltip.react';
import { DeleteModal } from '../../delete-modal';
import * as utils from '../../utils';
import { sortable } from '../../utils/sortable';

const AUTOMATED_EMAILS_ENABLED = isEnabled('ATS_AUTOMATED_EMAILS');

export default class EmailTemplatesTable extends PureComponent {
	getThead = (headers) => {
		return (
			<tr className='fab-Table__row' key='tableHead'>
				{headers.map((elem) => {
					return (
						<th className='fab-Table__header' key={elem}>
							{elem}
						</th>
					);
				})}
			</tr>
		);
	};

	getTbody = (rowData, isSystem) => {
		const { automatedEmailCounts, companyAtsPlusEnabled } = this.state;
		return rowData.map((data) => {
			return (
				<tr className='fab-Table__row' id={`template_row_id_${data.id}`} key={`key__${data.id}`}>
					<td className={`fab-Table__cell ${isSystem ? '' : 'moveme'}`}>
						{ifFeature(
							'encore',
							<Link href={`emailTemplate?id=${data.id}`} size='medium' title={data.name}>
								{data.name}
							</Link>,
							<a href={`emailTemplate?id=${data.id}`} title={data.name}>
								{data.name}
							</a>
						)}
						{isSystem ? (
							<>
								{ifFeature(
									'encore',
									<BodyText size='extra-small'>
										{$.__('This email template will automatically be sent to applicants when they submit an application.')}
									</BodyText>,
									<p className='emailTemplates__note'>
										{$.__('This email template will automatically be sent to applicants when they submit an application.')}
									</p>
								)}
							</>
						) : null}
						{AUTOMATED_EMAILS_ENABLED && companyAtsPlusEnabled && automatedEmailCounts[data.id] ? (
							<>
								{ifFeature(
									'encore',
									<BodyText size='extra-small'>
										{$.__n('%s automated email', '%s automated emails', automatedEmailCounts[data.id].totalRules)}
									</BodyText>,
									<p className='emailTemplates__note'>
										{$.__n('%s automated email', '%s automated emails', automatedEmailCounts[data.id].totalRules)}
									</p>
								)}
							</>
						) : null}
					</td>
					<td className={`fab-Table__cell ${isSystem ? '' : 'moveme'}`}>
						{ifFeature(
							'encore',
							<BodyText href={`emailTemplate?id=${data.id}`} size='medium' title={data.subject}>
								{data.subject}
							</BodyText>,
							data.subject
						)}
					</td>
					{isSystem ? (
						<td className='fab-Table__cell'></td>
					) : (
						<td className='fab-Table__cell'>
							<div className='fab-Table__cellMask'>
								<div className='fab-Table__cellActions'>
									<div className='fab-Table__cellAction'>
										<Tooltip
											settings={{
												content: 'Delete',
											}}
										>
											{ifFeature(
												'encore',
												<IconButton
													color="inherit"
													floatingIcon={true}
													icon="trash-regular"
													onClick={() => {
														this.deleteTemplate(data);
													}}
													size="small"
													type="button"
													variant="outlined"
												/>,
												<FloatingIconButton
													clickAction={() => {
														this.deleteTemplate(data);
													}}
													icon='fab-trash-can-14x16'
													secondary={true}
													type='button'
												/>
											)}
										</Tooltip>
									</div>
								</div>
							</div>
						</td>
					)}
				</tr>
			);
		});
	};

	handleDeleteTemplate = (templateId) => {
		const { templates } = this.state;
		utils.deleteTemplate('emailTemplate', templateId).then(() => {
			const updatedTemplates = utils.deleteTemplateData(templates, templateId);
			this.setState({
				templates: updatedTemplates,
				modalProps: {
					isOpen: false,
				},
				deletingTemplate: null,
			});
			window.setMessage($.__('Email Template has been successfully removed.'), 'success');
			this._queryJobCounts();
		});
	};

	deleteTemplate = (template) => {
		const { templates } = this.state;

		const escapedName = htmlentities(template.name).trim();
		const primaryAction = () => {
			utils.deleteTemplate('emailTemplate', template.id).then(() => {
				const updatedTemplates = utils.deleteTemplateData(templates, template.id);
				this.setState({
					templates: updatedTemplates,
					modalProps: {
						isOpen: false,
					},
				});
			});
		};

		if (AUTOMATED_EMAILS_ENABLED) {
			this.setState({ deletingTemplate: template });
		} else {
			this.setState({
				modalProps: {
					isOpen: true,
					name: escapedName,
					primaryAction,
				},
			});
		}
	};

	closeModal = () => {
		this.setState({
			modalProps: {
				isOpen: false,
			},
		});
	};

	_queryJobCounts = () => {
		axios.get('/hiring/api/permissions').then((res) => {
			const { companyAtsPlusEnabled } = res.data.result;
			this.setState({ companyAtsPlusEnabled });
			if (companyAtsPlusEnabled && AUTOMATED_EMAILS_ENABLED) {
				axios.get('/hiring/api/job-rule/jobCounts').then((res) => {
					const { emailTemplateId } = res.data.result;
					if (emailTemplateId) {
						this.setState({ automatedEmailCounts: keyBy(emailTemplateId, 'id') });
					}
				});
			}
		});
	};

	state = {
		systemTemplates: this.props.systemTemplates,
		templates: this.props.templates,
		modalProps: {
			isOpen: false,
			name: null,
			content: null,
		},
		companyAtsPlusEnabled: false,
		automatedEmailCounts: {},
		deletingTemplate: null,
	};

	componentDidMount() {
		sortable($('.js-sortable'), 'td.moveme', 'tr:not(.doNotSort)', utils.updateEmailsSortOrder);
		this._queryJobCounts();
	}

	render() {
		const { systemTemplates, templates, modalProps, deletingTemplate, automatedEmailCounts, companyAtsPlusEnabled } = this.state;

		const headers = [$.__('Name'), $.__('Subject'), ''];

		return (
			<Fragment>
				<table className='fab-Table fab-Table--hoverable'>
					<thead>{this.getThead(headers)}</thead>
					<tbody className='emailTemplates__tbody emailTemplates__tbodyGroup '>{this.getTbody(systemTemplates, true)}</tbody>
					<tbody className='emailTemplates__tbody js-sortable'>{this.getTbody(templates, false)}</tbody>
				</table>
				<DeleteModal
					automatedEmailCounts={automatedEmailCounts[deletingTemplate?.id]}
					closeModal={() => this.setState({ deletingTemplate: null })}
					companyAtsPlusEnabled={companyAtsPlusEnabled}
					deleting={deletingTemplate}
					deletingType='template'
					isOpen={!!deletingTemplate}
					onDelete={this.handleDeleteTemplate}
				/>
			</Fragment>
		);
	}
}
