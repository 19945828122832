import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(() => {
	return createStyles({
		actions: {},
		spacer: {
			flexGrow: 1,
		},
	});
});
