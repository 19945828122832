import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type StyleProps = {
	isSmallScreen: boolean;
};

export const useStyles = makeStyles(({ palette, constructs }) => ({
	container: {
		position: 'relative',
		padding: (props: StyleProps) => (props.isSmallScreen ? '0 24px' : '0 45px'),
	},
	responseOptionContainer: {
		display: 'flex',
		flexDirection: (props: StyleProps) => (props.isSmallScreen ? 'column' : 'row'),
		justifyContent: (props: StyleProps) => (props.isSmallScreen ? 'flex-start' : 'space-between'),
	},
	connectingBar: {
		position: 'absolute',
		background: ifFeature('encore', constructs.border.neutral.xWeak, palette.gray[100]),
		zIndex: 1,
		top: '18px',
		left: '75px',
		height: '6px',
		width: 'calc(100% - 150px)',
	},
}));
