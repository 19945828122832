import { Table } from '@bamboohr/fabric';

import { AcaStatusRow } from './aca-status-row';
import { formatDataForDataTable } from './service';

export function AcaStatusAlignmentTable(props) {
	const {
		acaTypes,
		alignments,
		onFormChange,
		statusTypes,
	} = props;

	const columns = [
		{
			header: $.__('Employment Status'),
			cell: row => row.employmentStatus,
			width: '30%',
		},
		{
			header: $.__('ACA Status'),
			cell: row => (
				<AcaStatusRow
					acaTypes={ acaTypes }
					alignment={ alignments[row.key] }
					onRowChange={ (value) => {
						onFormChange(row.key, value);
					} }
				/>
			),
		},
	];
	const data = formatDataForDataTable(statusTypes);
	return (
		<Table
			caption="Benefits Enrollment Align Statuses"
			columns={ columns }
			rowKey={ row => row.key }
			rows={ data }
		/>
	);
}
