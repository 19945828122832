import { render } from 'base/wrapped-render';
import Ctrl, { onPjaxEnd, onReady } from 'ctrl.deco';
import { PoMicroFrontend } from 'micro-frontend.react';
import { uniqueId } from 'lodash';

const renderSettingsCustomFields = () => {
    const customFieldsRoot = document.getElementById('js-customFieldsRoot');

    if (customFieldsRoot) {
        render(
            <PoMicroFrontend customClassNamePrefix={uniqueId('custom-fields-settings-page')}/>,
            customFieldsRoot
        );
    }
};

@Ctrl('/settings/custom_fields')
class CustomFieldsCtrl {
    @onReady()
    @onPjaxEnd()
    _readyHandler() {
        renderSettingsCustomFields();
    }
}
