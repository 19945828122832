import {
	camelCase,
	isString,
} from 'lodash';

import colors from '@bamboohr/fabric/dist/definitions/json/colors.json';

/**
 * Returns the non-brand Fabric color's hex value as a string. Example: "#0772b3"
 * @method getFabricNonBrandColor
 * @param {String} colorName Name of non-brand Fabric color
 * @returns {String} Color hex value as string or undefined.
 */
export function getFabricNonBrandColor(colorName) {
	if (!isString(colorName)) {
		throw new Error('Invalid input type.');
	}

	const { colors: nonBrandColors } = colors;
	const camelCaseColorName = camelCase(colorName);

	const color = nonBrandColors[camelCaseColorName];
	if (!color) {
		console.warn('Invalid Fabric non-brand color name.');
		return;
	}

	return color;
}
