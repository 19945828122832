import Ajax from '@utils/ajax';
import { showSlidedown } from 'Alerts.mod';
import {
	EmployeeFieldsObject,
	SerializedArrayObject,
} from '../types';

function showGeneralSuccessMessage(name: string): void {
	showSlidedown($.__('Great, the information for %1$s has been updated.', name), 'success');
}

function showSuccessButTaxFailedMessage(name: string): void {
	showSlidedown($.__('The information for %1$s has been updated, but they still need Tax Information.', name), 'info');
}

function showFailedMessage(): void {
	showSlidedown($.__(`Uh oh, something went wrong. We couldn't save the updates to this employee.`), 'error');
}

function serializeArrayFromForm(form: HTMLFormElement): SerializedArrayObject[] {
	const serializedArray = [];

	const formDataObject = new FormData(form);

	formDataObject.forEach((value, name) => {
		serializedArray.push({name, value});
	});

	return serializedArray;
}

function saveGeneralEmployeeInfo(
	employeeId: string,
	employeeName: string,
	employeeFields: EmployeeFieldsObject,
	taxDataSaveSuccess: boolean,
	onSaveSuccess: () => void,
	onSaveFailure: () => void
): void {
	const successMessage = taxDataSaveSuccess ? showGeneralSuccessMessage : showSuccessButTaxFailedMessage;
	Ajax.put(`/settings/payroll/payroll_checklist/active_employees/update/${ employeeId }`, employeeFields)
		.then(({ status }) => {
			if (status === 200) {
				onSaveSuccess();
				successMessage(employeeName);
			} else {
				showFailedMessage();
				onSaveFailure();
			}
		})
		.catch((error) => {
			const {
				response: {
					status,
					data: {
						message
					}
				}
			} = error;
			if (status === 400 && message) {
				showSlidedown(message, 'error');
			} else {
				showFailedMessage();
			}
			onSaveFailure();
		});
}

export function saveEmployeeData(
	employeeId: string,
	employeeName: string,
	taxData: HTMLFormElement,
	employeeFields: EmployeeFieldsObject,
	onSaveSuccess: () => void,
	onSaveFailure: () => void
): void {
	if (taxData) {
		const saveTaxData = serializeArrayFromForm(taxData);

		Ajax.post(`/payroll_ajax/taxDeductionInformation/${ employeeId }`, saveTaxData, {serializeArray: true})
			.then(({ status }) => {
				const taxDataSaveSuccess = status === 200;
				saveGeneralEmployeeInfo(employeeId, employeeName, employeeFields, taxDataSaveSuccess, onSaveSuccess, onSaveFailure);
			})
			.catch(() => {
				saveGeneralEmployeeInfo(employeeId, employeeName, employeeFields, false, onSaveSuccess, onSaveFailure);
			});
	} else {
		saveGeneralEmployeeInfo(employeeId, employeeName, employeeFields, true, onSaveSuccess, onSaveFailure);
	}
}
