import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ borderRadiuses, palette, constructs }) => ({
	cardContentWrapper: {
		borderRadius: ifFeature('encore', borderRadiuses[400]),
		width: '100%',
		padding: '25px 32px 58px',
		backgroundColor: ifFeature('encore', constructs.surface.neutral.forcedWhite),
	},
	/* @startCleanup encore */
	backAndQuestionWrapper: {
		display: 'flex',
		fontSize: '13px',
		lineHeight: '16px',
		color: palette.gray[700],
		marginBottom: '55px',
	},
	questionCount: {
		fontSize: '14px',
		lineHeight: '18px',
		color: palette.gray[600],
		textAlign: 'center',
		marginBottom: '24px',
	},
	/* @endCleanup encore */
	back: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: '8px',
		borderRight: `1px solid ${palette.gray[700]}`,
		color: palette.gray[700],
		fill: palette.gray[700],
		height: '16px',
		fontSize: '13px',
		lineHeight: '16px',
	},
	/* @startCleanup encore */
	questionCounter: {
		paddingLeft: '8px',
	},
	question: {
		display: 'flex',
		justifyContent: 'center',
		margin: 'auto 25px 52px',

		'& h3': {
			maxWidth: '540px',
			textAlign: 'center',
			color: palette.gray[800],
			margin: 0,
		},
	},
	answers: {
		marginBottom: '57px',
		textAlign: 'center',
	},
	submit: {
		display: 'flex',
		justifyContent: 'center',

		'& .MuiButton-endIcon': {
			marginLeft: '2px',
		},

		'& svg': {
			height: '6px',
			position: 'relative',
			top: '2px',
		},
	},
	smallSubmitButton: {
		'& button': {
			margin: '0 20px',
			width: '100%',
			maxWidth: '450px',
		},
	},
	/* @endCleanup encore */
}));
