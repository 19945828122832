import { useEffect, useState } from 'react';
import {
	Divider,
	Flex,
	Headline,
	/* @startCleanup encore */ Icon,
	/* @endCleanup encore */ LayoutBox,
	Link,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @startCleanup encore */
import { TextButton } from '@fabric/button';
/* @endCleanup encore */
import {
	CancellationDropdown,
	CancellationModal,
	CancellationType,
	Services,
	SadToGoBox,
} from 'in-app-cancellation.mod';
import {
	CancelAccountModal,
	RADIO_ITEMS,
} from '../../../cancel-account-modal.react/cancel-account-modal';

/* @startCleanup encore */
import classNames from 'classnames';

import './company-info-header.styl';
/* @endCleanup encore */

export function CompanyInfoHeader(props) {
	const [cancellationType, setCancellationType] = useState(
		CancellationType.PAYROLL,
	);
	const [cancellationData, setCancellationData] = useState(null);
	const [cancellationStatus, setCancellationStatus] = useState([]);
	const [cancellationPending, setCancellationPending] = useState(false);
	const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
	const [followUpModalOpen, setFollowUpModalOpen] = useState(false);

	useEffect(() => {
		getCancellationStatus();
	}, []);

	const getCancellationStatus = () => {
		Services.getCancellationFeatureStatus(CancellationType.PAYROLL).then(
			(resp) => {
				setCancellationData(resp.data);
				setCancellationPending(resp.data.pending_cancellation);
			},
		);
		Services.getCancellationStatusAll().then((resp) => {
			// formatting for sadbox
			setCancellationStatus(
				resp.data.filter(
					(item) =>
						item.feature === CancellationType.PAYROLL ||
						item.feature === CancellationType.ACCOUNT,
				),
			);
		});
	};

	const { anotherEinLink, hasStatus } = props;

	/* @startCleanup encore */
	const componentClasses = classNames('CompanyInfoHeader', {
		'CompanyInfoHeader--noBottom': hasStatus,
	});
	const openFormstackTab = () => {
		window.open(
			'https://bamboohr.formstack.com/forms/payrollqs?type=Add-EIN',
			'_blank',
		);
	};
	/* @endCleanup encore */

	const handleOnSelect = () => {
		setCancellationModalOpen(true);
	};

	const cancellationDropdown =
		!!cancellationData && !cancellationPending ? (
			<LayoutBox>
				<CancellationDropdown
					cancellationData={cancellationData}
					handleOnSelect={handleOnSelect}
					title={$.__('Payroll')}
				/>
				<CancellationModal
					isOpen={cancellationModalOpen}
					onClose={() => setCancellationModalOpen(false)}
					onSubmitSuccess={() => {
						getCancellationStatus();
						if (cancellationType === CancellationType.PAYROLL) {
							setFollowUpModalOpen(true);
						}
					}}
					types={[cancellationType]}
				/>
				<CancelAccountModal
					isOpen={followUpModalOpen}
					onClose={() => setFollowUpModalOpen(false)}
					onNext={(val) => {
						// if 'yes' is selected.
						if (val === RADIO_ITEMS[0].value) {
							setCancellationType(CancellationType.ACCOUNT);
							setCancellationModalOpen(true);
						}
						setFollowUpModalOpen(false);
					}}
				/>
			</LayoutBox>
		) : null;

	return ifFeature(
		'encore',
		<>
			<Flex alignItems="center" justifyContent="space-between">
				<Headline size="small">{$.__('Company Information')}</Headline>
				<Flex gap={2} alignItems="center">
					{anotherEinLink ? (
						<Link
							href="https://bamboohr.formstack.com/forms/payrollqs?type=Add-EIN"
							target="_blank"
							color="muted"
							size="small"
						>
							{$.__('Need to add another company/EIN?')}
						</Link>
					) : null}
					{cancellationDropdown}
				</Flex>
			</Flex>
			{cancellationPending ? (
				<LayoutBox marginTop={1}>
					<SadToGoBox
						cancellationData={cancellationStatus}
						updateCancellationData={getCancellationStatus}
						isAddon={true}
					/>
				</LayoutBox>
			) : null}
			{!hasStatus ? (
				<LayoutBox marginTop={3} marginBottom={1}>
					<Divider color="neutral-weak" />
				</LayoutBox>
			) : null}
		</>,
		<>
			<div className={componentClasses}>
				<div className="CompanyInfoHeader__left">
					<Flex alignItems="center" height="32px">
						<Icon brand={true} name="fab-money-circle-20x20" />
						<span className="CompanyInfoHeader__text">
							{$.__('Company Information')}
						</span>
					</Flex>
				</div>

				<Flex alignItems="center" height="32px">
					{anotherEinLink && (
						<span className="CompanyInfoHeader__anotherEin">
							<TextButton
								clickAction={openFormstackTab}
								muted={true}
								size="small"
								text={$.__('Need to add another company/EIN?')}
								type="button"
							/>
						</span>
					)}
					{!!cancellationData && !cancellationPending && (
						<div className="CompanyInfoHeader__cancellationDropdown">
							<CancellationDropdown
								cancellationData={cancellationData}
								handleOnSelect={handleOnSelect}
								title={$.__('Payroll')}
							/>
							<CancellationModal
								isOpen={cancellationModalOpen}
								onClose={() => setCancellationModalOpen(false)}
								onSubmitSuccess={() => {
									getCancellationStatus();
									if (cancellationType === CancellationType.PAYROLL) {
										setFollowUpModalOpen(true);
									}
								}}
								types={[cancellationType]}
							/>
							<CancelAccountModal
								isOpen={followUpModalOpen}
								onClose={() => setFollowUpModalOpen(false)}
								onNext={(val) => {
									// if 'yes' is selected.
									if (val === RADIO_ITEMS[0].value) {
										setCancellationType(CancellationType.ACCOUNT);
										setCancellationModalOpen(true);
									}
									setFollowUpModalOpen(false);
								}}
							/>
						</div>
					)}
				</Flex>
			</div>
			{cancellationPending && (
				<SadToGoBox
					cancellationData={cancellationStatus}
					updateCancellationData={getCancellationStatus}
					isAddon={true}
				/>
			)}
		</>,
	);
}
