import { BodyText, Divider, Flex, IconV2, useTheme } from '@bamboohr/fabric';
import { PersonCircle16x16 } from '@bamboohr/grim';

import { FilterAccordion } from './components/filter-accordion.react';
import { FilterListData } from '../../types';
import { convertFiltersToArray } from './util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Props {
	filterListData: FilterListData;
	selectedEmployeeCount: number;
}

export const EmployeeFilter = (props: Props): JSX.Element => {
	const { filterListData, selectedEmployeeCount } = props;

	const { palette } = useTheme();

	const hasNoEmployeesSelected = selectedEmployeeCount === 0;

	const renderAccordions = () => {
		const employeeFilterCategories = convertFiltersToArray(filterListData);

		return employeeFilterCategories.map((category) => {
			if (category) {
				const { categoryValue, filters, icon, name } = category;

				return <FilterAccordion categoryValue={categoryValue} filters={filters} icon={icon} key={categoryValue} title={name} />;
			}
		});
	};

	return (
		<>
			<div>
				<Flex alignItems='center' paddingTop='3px' marginBottom='16px' justifyContent='space-between'>
					<BodyText color='neutral-strong' size='small'>
						{$.__('Filter Employees By')}
					</BodyText>
					<Flex alignItems='center' gap='8px'>
						{ifFeature(
							'encore',
							<IconV2 name='circle-user-regular' color='neutral-extra-extra-strong' size={16} />,
							<PersonCircle16x16 fill={palette.gray[700]} />
						)}
						<BodyText
							size='small'
							color={hasNoEmployeesSelected ? 'warning' : 'neutral-strong'}
							weight={hasNoEmployeesSelected ? 'semibold' : 'regular'}
						>
							{$.__n('%s Employee Included', '%s Employees Included', selectedEmployeeCount)}
						</BodyText>
					</Flex>
				</Flex>
				{renderAccordions()}
			</div>
		</>
	);
};
