import { IconV2, Link } from '@bamboohr/fabric';
import { ifFeature } from "@bamboohr/utils/lib/feature";

export const columns = [
	{
		header: $.__('Name'),
		cell: (row) => {
			return (
				ifFeature('encore',
					<Link href={`editOfferTemplate?id=${ row.id }`} title={row.name} size='medium'>
						{row.name}
					</Link>,
					<a href={ `editOfferTemplate?id=${ row.id }` } title={ row.name }>
						{ row.name }
					</a>
				)
			);
		}
	},
	{
		showOnHover: true,
		width: '120px',
		cell: {
			type: 'actions',
			actions: (row) => {
				return [
					{
						action: () => { window.location.href = `editOfferTemplate?id=${ row.id }&duplicate=1` },
						icon: ifFeature('encore', 'clone-regular', 'fab-overlapping-boxes-16x16'),
						tooltipContent: $.__('Duplicate Template'),
					},
					{
						icon: ifFeature('encore', 'trash-regular', 'fab-trash-can-14x16'),
						action: () => { row.action(row); },
						tooltipContent: $.__('Delete Template'),
					},
				];
			}
		},
	},
];
