import { Button } from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import { redirect } from 'BambooHR.util';
import React, { useState } from 'react';
import Upsell from 'Upsell';
import { performanceItems } from '../../app-configs/upsell';
import api from './api';
import { serializeDemoRequest } from './serializers';

const PerformanceUpsell = ({ demoRequest }) => {
	// See example of demo state shape below in defaultProps
	const [demo, setDemo] = useState(serializeDemoRequest(demoRequest));
	const [isRequestPending, setIsRequestPending] = useState(false);

	const handleUpsellRequest = () => {
		setIsRequestPending(true);

		// Make the request
		api.scheduleDemo
			.post()
			.then(({ data }) => {
				// TODO: reimplement when Upsells are moved to Po
				// setDemo(serializeDemoRequest(data));
				// showSlidedown($.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."), 'success');

				redirect(
					window.location.href,
					$.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."),
					'success'
				);
			})
			.catch(() => {
				// Show error slidedown
				showSlidedown($.__('Oops... something went wrong. Please try again.'), 'error');
			});

		// Disable processing state
		setIsRequestPending(false);
	};

	return (
		<Upsell>
			<Upsell.Title>{$.__('Forward-Looking Performance Management for Real Employee Development')}</Upsell.Title>
			<Upsell.Video title={$.__('Performance Management Video')} videoId='3vyztgr30f' />
			<Upsell.Content
				actions={[
					<Button
						data-bi-id='settings-performance-request-pro-demo-button'
						disabled={demo.isRequested}
						noSpacing={true}
						onClick={handleUpsellRequest}
						processing={isRequestPending}
						size='large'
						type='button'
						width='100'
					>
						{$.__('Schedule a Personalized Demo')}
					</Button>,
				]}
				request={{
					demoRequestedBy: demo.requestedBy,
					demoRequestedDate: demo.requestDate,
					isDemoRequested: demo.isRequested,
				}}
			>
				<h4>{$.__('With BambooHR Performance Management, you can:')}</h4>

				<ul>
					{performanceItems.map((item, index) => (
						<li key={`performance-item-${index}`}>{item}</li>
					))}
				</ul>
			</Upsell.Content>
		</Upsell>
	);
};

PerformanceUpsell.defaultProps = {
	demoRequest: {
		isRequested: false,
		requestedBy: {
			id: null,
			firstName: '',
			lastName: '',
		},
		requestDate: null,
	},
};

export default PerformanceUpsell;
