import React from 'react';
import PropTypes from 'prop-types';

import { BodyText, Divider, Headline, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/**
 * Wrap headline and sub-headline for use in ACA modal
 *
 * @param {object} props
 * @param {string} props.headlineText
 * @param {string} props.subHeadlineText
 * @returns {JSX.Element}
 */
export function HeadlineWrapper(props) {
	const {
		headlineText,
		subHeadlineText,
	} = props;

	// @startCleanup encore
	const jadeHeadlineWrapper = (
		<>
			<LayoutBox marginBottom={2}>
				<Headline
					color="primary"
					size="medium"
				>{ headlineText }</Headline>
			</LayoutBox>
			<LayoutBox marginBottom={1}>
				<BodyText
					color="neutral-strong"
					component="p"
					size="medium"
					weight='regular'
				>
					{ subHeadlineText }
				</BodyText>
			</LayoutBox>
		</>
	);
	// @endCleanup encore

	return (
		<LayoutBox marginBottom={3}>
				{ ifFeature('encore',
					<>
						<Headline
							color="neutral-strong"
							size="small"
						>{ headlineText }</Headline>
						<LayoutBox marginBottom={2.5}>
							<BodyText
								color="neutral-weak"
								component="p"
								size="large"
								weight='regular'
							>
								{ subHeadlineText }
							</BodyText>
						</LayoutBox>
					</>,
					jadeHeadlineWrapper
					)
				}
			<Divider color='neutral-extra-weak' />
		</LayoutBox>
	);
}

HeadlineWrapper.propTypes = {
	headlineText: PropTypes.string.isRequired,
	subHeadlineText: PropTypes.string.isRequired,
};
