import { Fragment, useEffect, useState } from 'react';
import { render } from 'base/wrapped-render';
import { Flex, Typography, makeStyles, Table, Divider, TextField, Tooltip, IconV2, Headline, BodyText, LayoutBox, Button } from '@bamboohr/fabric';
// @startCleanup encore
import { Stopwatch17x20, CircleMoneyCheck16x18 } from '@bamboohr/grim';
// @endCleanup encore
import { TextButton } from '@fabric/button';
import { Select } from '@fabric/select';
import { Modal } from 'modal-legacy';
import Ajax from '@utils/ajax';
import { Message } from '@bamboohr/utils/lib/message';
import { isEnabled, ifFeature } from '@bamboohr/utils/lib/feature';
import { splitOnCondition } from 'Array.util';
import useNormalizedData from 'use-normalized-data.hook';
import {
	EmployeesColumnHeader,
	ConfirmDeleteModal,
	ConfirmArchiveModal,
	ProjectTrackingBlankState,
	EmployeesAssignedModal,
} from './components';

import './styles.styl';

// @startCleanup encore
const useStyles = makeStyles(({ palette }) => {
	return {
		header: {
			display: 'flex',
			alignItems: 'center',
		},
		headerIcon: {
			fill: palette.primary.main,
			display: 'flex',
			alignItems: 'center',
		},
	};
});
// @endCleanup encore

// @startCleanup encore
const IncludeInPayrollCell = () => {
	const styles = useStyles();
	return(
		<Tooltip content={<span>{$.__('Included in Payroll')}</span>}>
			<div><CircleMoneyCheck16x18 className={styles.headerIcon} /></div>
		</Tooltip>
	);
};
// @endCleanup encore

export function ProjectTracking(props) {
	const styles = useStyles();
	const { projectTrackingData, isPayrollEnabled = false, hasTimeTrackingTool = false } = props;

	const [filter, setFilter] = useState('active');
	const [filterName, setFilterName] = useState('');
	const [allIds, byId, { update, remove }] = useNormalizedData(projectTrackingData);
	const [isProcessing, setProcessing] = useState(false);
	const [projectSelectedId, setProjectSelectedId] = useState(null);
	const projectSelected = byId[projectSelectedId] || null;
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showEmployeesAssignedModal, setShowEmployeesAssignedModal] = useState(false);
	const [employeesAssignedProjectData, setEmployeesAssignedProjectData] = useState({
		id: '',
		name: '',
	});

	const [archivedIds, activeIds] = splitOnCondition(allIds, (id) => byId[id].archived);

	useEffect(() => {
		if (filter === 'archived' && archivedIds.length === 0) {
			setFilter('active');
		}
	}, [filter, archivedIds.length]);

	const deleteProject = () => {
		setProcessing(true);
		Ajax.delete('/settings/time_tracking/projects/delete', { projectId: projectSelected?.id })
			.then((res) => {
				if (res.data.status === 'success') {
					remove(projectSelected?.id);
					window.setMessage($.__('Project %1$s was successfully deleted.', projectSelected?.name), 'success');
					setShowDeleteModal(false);
				} else {
					window.errorFallBack();
				}
			})
			.catch(() => {
				window.errorFallBack();
			})
			.finally(() => {
				setProcessing(false);
				setProjectSelectedId(null);
			});
	};

	const handleArchiving = (row) => {
		setIsLoading(true);
		const isActive = !row.archived;
		Ajax.post(`/settings/time_tracking/projects/${isActive ? 'archive' : 'unarchive'}/${row.id}`)
			.then(() => {
				update(row.id, { archived: isActive, includeInPayroll: false });

				const successMessage = isActive
					? $.__('Project %1$s was successfully archived.', row.name)
					: $.__('Project %1$s was successfully unarchived.', row.name);

				window.setMessage(successMessage, 'success');

				setShowArchiveModal(false);
			})
			.catch(() => {
				const failureMessage = isActive
					? $.__('We could not archive %1$s, try again?', row.name)
					: $.__('We could not unarchive %1$s, try again?', row.name);

				window.setMessage(failureMessage, 'error');
			})
			.finally(() => {
				setIsLoading(false);
				setProjectSelectedId(null);
			});
	};

	const editProjectLink = (id) => {
		window.location.assign(`/settings/time_tracking/projects/edit/${id}`);
	};

	const openEmployeeModal = (row) => {
		setEmployeesAssignedProjectData({ id: row.id, name: row.name });
		setShowEmployeesAssignedModal(true);
	};

	const isProjectPayEnabled = isEnabled('PROJECT_PAY');
	const isPayrollProjectPayEnabled = isEnabled('PAYROLL_PROJECT_PAY');

	const employeeCellTextButton = (row) => {
		if (parseInt(row.employeeCount) === 0 && isProjectPayEnabled) {
			return row.allEmployees ? $.__('All (%1)') : row.employeeCount;
		}

		return (
			<TextButton
				clickAction={() => openEmployeeModal(row)}
				id={row.id}
				inline={true}
				text={row.allowAllEmployees ? $.__('All (%1)', row.employeeCount) : row.employeeCount}
			/>
		);
	};

	let employeeColumn = null;
	if (hasTimeTrackingTool) {
		employeeColumn = {
			header: <EmployeesColumnHeader />,
			cell: employeeCellTextButton,
			key: 'employees',
		};
	}

	const columns = [
		...(isPayrollProjectPayEnabled && isPayrollEnabled
		? [
			{
				width: '10px',
				cell: (row) => {
					if( row.includeInPayroll ){
						return ifFeature('encore', <IconV2 color="primary-strong" name="circle-dollar-solid" size="20" />, <IncludeInPayrollCell />);
					}
					return null;
				},
				align: 'center',
				key: 'icon',
			},
		]
		: []),
		{
			header: $.__('Projects'),
			cell: (row) => <TextButton clickAction={() => editProjectLink(row.id)} id={row.id} inline={true} text={row.name} />,
			...(isEnabled('dupProject') ? { sort: (a, b) => a.name.localeCompare(b.name) } : { sortBy: (row) => row.name }),
			key: 'projects',
		},
		...(employeeColumn ? [employeeColumn] : []),
		{
			header: $.__('Tasks'),
			cell: (row) => row.taskCount,
			key: 'tasks',
		},
		{
			showOnHover: true,
			headerAriaLabel: $.__('Action Buttons'),
			width: '100px',
			key: 'actions',
			cell: {
				type: 'actions',
				actions: (row) => [
					...(isEnabled('dupProject')
						? [
							{
								biId: 'settings-time-tracking-project-tracking-duplicate-project',
								icon: ifFeature('encore', 'copy-regular', 'overlapping-boxes-16x16'),
								href: `/settings/time_tracking/projects/duplicate/${row.id}`,
								tooltipContent: $.__('Duplicate'),
								key: 'duplicate',
							},
						  ]
						: []),
					{
						biId: 'settings-time-tracking-project-tracking-archive-toggle',
						icon: ifFeature('encore', row.archived ? 'upload-regular' : 'box-archive-regular', row.archived ? 'fab-unarchive-13x12' : 'fab-archive-16x16'),
						action: () => {
							if (isEnabled('PROJECT_PAY') && !row.archived) {
								setProjectSelectedId(row.id);
								setShowArchiveModal(true);
							} else {
								handleArchiving(row);
							}
						},
						tooltipContent: row.archived ? $.__('Unarchive') : $.__('Archive'),
						key: 'archive',
					},
					{
						biId: 'settings-time-tracking-project-tracking-delete-project',
						icon: ifFeature('encore', 'trash-can-regular', 'fab-trash-can-14x16'),
						action: () => {
							setProjectSelectedId(row.id);
							setShowDeleteModal(true);
						},
						tooltipContent: $.__('Delete'),
						key: 'delete',
					},
				],
			},
		},
	];

	const handleSelect = (e) => {
		setFilter(e);
	};

	const handleTableContent = () => {
		return (filter === 'active' ? activeIds : archivedIds).map((id) => byId[id]);
	};

	const title = $.__('Project Tracking');
	useEffect(() => {
		document.title = title;
	}, [title]);

	const totalNumProjects = allIds.length;
	const showProjectsSearchThreshold = 25;
	const addProjectBtn = <Button
		color={ifFeature('encore', 'secondary', 'primary')}
		href='/settings/time_tracking/projects/add'
		noSpacing
		iconBefore={ifFeature('encore', 'circle-plus-regular', 'fab-circle-plus-14x14')}
		size='small'
		type='button'
		variant={ifFeature('encore', undefined, 'outlined')}
	>{$.__('Add Project')}</Button>

	const showSetupGuideButton = isEnabled("PROJECT_PAY_IMPLEMENTATION_STEP")

	return (
		<Fragment>
			<div>
				<Flex alignItems="center" gap={1} mb={1.5}>
					{ifFeature('encore',
						<Headline size="small">
							{title}
						</Headline>,
						<>
							<div className={styles.headerIcon}>
								<Stopwatch17x20 />
							</div>
							<Typography fontWeight='semibold' variant='biggie'>
								{title}
							</Typography>
						</>
					)}

					{showSetupGuideButton && (
						<Flex flexGrow={1} gap={2} justifyContent="flex-end">
							<Button
								color="secondary"
								data-bi-id="project-tracking-view-setup-guide-button"
								onClick={() => {
									window.location.href = '/app/setup/setup-guides/project-pay';
								}}
								size={ifFeature('encore', 'small', undefined)}
								type="button"
								variant="outlined"
							>
								{$.__("View Setup Guide")}
							</Button>
						</Flex>
					)}
				</Flex>
				<LayoutBox marginBottom={2}>
					<BodyText>
						{$.__(
							'Use Project Tracking to assign billable and non-billable hours to jobs, clients, projects, tasks or whatever else your organization needs to track and report time on.'
						)}
					</BodyText>
				</LayoutBox>
			</div>

			<LayoutBox marginY={2}>{addProjectBtn}</LayoutBox>
			
			{(totalNumProjects >= showProjectsSearchThreshold || archivedIds.length > 0) && (
				<LayoutBox marginBottom={2}>
					{ifFeature('encore', null, <Divider marginBottom={2} />)}
					<Flex justifyContent="space-between">
						<div>
							{totalNumProjects >= showProjectsSearchThreshold && (
								<TextField
									onChange={e => setFilterName(e.target.value)}
									placeholder="Search by project name..."
									size="small"
									width={8} />
							)}
						</div>
						<div>
							{archivedIds.length > 0 && (
								<Select
									biId='settings-time-tracking-project-status'
									isClearable={false}
									items={[
										{ text: $.__('Active'), value: 'active' },
										{ text: $.__('Archived'), value: 'archived' },
									]}
									onSelect={handleSelect}
									selectedValues={[filter]}
									size='small'
								/>
							)}
						</div>
					</Flex>
				</LayoutBox>
			)}

			<div className={isLoading ? 'TTProjectTracking__isLoading' : ''}>
				{activeIds.length === 0 && filter === 'active' ? (
					<ProjectTrackingBlankState addProjectBtn={addProjectBtn} />
				) : (
					<Table
						caption='Projects'
						columns={columns}
						initialSort={isPayrollProjectPayEnabled && isPayrollEnabled ? { columnIndex: 1, isAsc: true } : { columnIndex: 0, isAsc: true }}
						rowKey={(row) => row.id}
						rows={handleTableContent().filter(({ name }) => name.toLowerCase().includes(filterName.toLowerCase()))}
					/>
				)}
				{isProjectPayEnabled ? (
					<ConfirmDeleteModal
						cancelButtonText={$.__('Cancel')}
						confirmationButtonText={$.__('Delete Project')}
						confirmationInputLabel={$.__('Type “Delete” to delete this project.')}
						headlineText={$.__('Are you sure you want to delete this project?')}
						isOpen={showDeleteModal}
						isProcessing={isProcessing}
						onRequestClose={(confirmed) => {
							if (confirmed) {
								deleteProject();
							} else {
								setProjectSelectedId(null);
								setShowDeleteModal(false);
							}
						}}
						title={$.__('Just Checking...')}
					>
						<div className='TTProjectTracking__modalContent'>
							{$.__(
								'Employees will no longer be able to log time to this project and all references will be removed, but will show in historical data.'
							)}
						</div>
					</ConfirmDeleteModal>
				) : (
					<Modal
						alternativeAction={null}
						content={
							<div className='TTProjectTracking__modalContent'>
								{$.__(
									'Employees will no longer be able to log time to this project and any time already logged will no longer be included in reports.'
								)}
							</div>
						}
						headline={<Message params={[projectSelected?.name]} text={$._(`Are you sure you want to delete the {1} project?`)} />}
						icon='fab-trash-can-43x48'
						iconColor='danger'
						isOpen={showDeleteModal}
						isProcessing={isProcessing}
						onClose={() => setShowDeleteModal(false)}
						primaryAction={deleteProject}
						primaryActionText={$.__('Delete Project')}
						secondaryAction={() => setShowDeleteModal(false)}
						secondaryActionText={$.__('Keep Project')}
						title={$.__('Just Checking...')}
						type='small'
					/>
				)}

				<ConfirmArchiveModal
					cancelButtonText={$.__('Cancel')}
					confirmationButtonText={$.__('Archive Project')}
					confirmationInputLabel={$.__('Type “Delete” to delete this project.')}
					headlineText={$.__('Are you sure you want to archive this project?')}
					isOpen={showArchiveModal}
					isProcessing={isProcessing}
					onRequestClose={(confirmed) => {
						if (confirmed) {
							handleArchiving(projectSelected);
						} else {
							setShowArchiveModal(false);
							setProjectSelectedId(null);
						}
					}}
					title={$.__('Just Checking...')}
				>
					<div className='TTProjectTracking__modalContent'>
						{isPayrollProjectPayEnabled && projectSelected?.includeInPayroll
							? $.__('Employees will no longer be able to log time to this project and it will be excluded from payroll.')
							: $.__('Employees will no longer be able to log time to this project.')}
					</div>
				</ConfirmArchiveModal>

				<EmployeesAssignedModal
					isOpen={showEmployeesAssignedModal}
					onClose={() => setShowEmployeesAssignedModal(false)}
					project={employeesAssignedProjectData}
				/>
			</div>
		</Fragment>
	);
}

export function initProjectTracking() {
	if (document.getElementById('js-project-tracking-root')) {
		const jsonData = JSON.parse(document.getElementById('js-timetracking-project-data').innerHTML);
		const { projects, timeTrackingTrialStatus } = jsonData;

		render(
			<ProjectTracking projectTrackingData={projects} timeTrackingTrialStatus={timeTrackingTrialStatus} />,
			document.getElementById('js-project-tracking-root')
		);
	}
}
