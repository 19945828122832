import {
    Dropdown
} from '@bamboohr/fabric';
import {
    Gear14x13,
} from '@bamboohr/grim';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
    COMPANY_TAX_SETTINGS_BI_ID_DROPDOWN_OPEN,
} from './constants';
import {
    generateCompanyTaxSettingsGroupedItems,
} from './domain/generate-company-tax-settings-grouped-items';
import {
    handleCompanyTaxSettingsSelect,
} from './domain/handle-company-tax-settings-select';
import {
    L_COMPANY_TAX_SETTINGS,
} from './language';
import type {
    CompanyTaxDataCollection,
} from './types';

interface CommpanyTaxSettingsDropdownProps {
	companyTaxes: CompanyTaxDataCollection;
}

export function CommpanyTaxSettingsDropdown(props: CommpanyTaxSettingsDropdownProps) {
    const {
        companyTaxes,
    } = props;
    return (
        <Dropdown
            ButtonProps={ {
                ariaLabel: L_COMPANY_TAX_SETTINGS,
                color: 'secondary',
                startIcon: (
                    ifFeature('encore', 'gear-regular', <Gear14x13 />)
                ),
                variant: 'outlined',
                size: ifFeature('encore', 'small', 'teenie'),
            } }
            biId={ COMPANY_TAX_SETTINGS_BI_ID_DROPDOWN_OPEN }
            items={ generateCompanyTaxSettingsGroupedItems(companyTaxes) }
            onSelect={ handleCompanyTaxSettingsSelect }
        />
	);
}
