import { Briefcase20x19, Company20x20, ContactCard24x20, GrimIcon, Location18x24, PeopleOrgChart24x24 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FilterData } from '../../types';

export const filterCategoryMapping = {
	departments: {
		name: $.__('Department'),
		icon: ifFeature('encore', 'building-regular', Company20x20),
		value: 'departmentId[]',
	},
	divisions: {
		name: $.__('Division'),
		icon: ifFeature('encore', 'sitemap-solid', PeopleOrgChart24x24),
		value: 'divisionId[]',
	},
	employmentStates: {
		name: $.__('Employee Status'),
		icon: ifFeature('encore', 'id-card-solid', ContactCard24x20),
		value: 'employmentStatusId[]',
	},
	jobTitles: {
		name: $.__('Job Title'),
		icon: ifFeature('encore', 'briefcase-solid', Briefcase20x19),
		value: 'jobTitleId[]',
	},
	locations: {
		name: $.__('Location'),
		icon: ifFeature('encore', 'location-pin-solid', Location18x24),
		value: 'locationId[]',
	},
};

export const convertFiltersToArray = (filterListData) => {
	return Object.keys(filterListData)
		.map((categoryName) => {
			if (filterCategoryMapping[categoryName]) {
				return {
					name: filterCategoryMapping[categoryName].name as string,
					categoryValue: filterCategoryMapping[categoryName].value as string,
					icon: filterCategoryMapping[categoryName].icon as GrimIcon | string,
					filters: {
						...filterListData[categoryName],
					} as FilterData,
				};
			}

			return null;
		})
		.sort((a, b) => a?.name.localeCompare(b?.name));
};
