import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default makeStyles(({ spacing, palette, typography }) => {
	return {
		view: {
			display: 'flex',
			marginTop: ifFeature('encore', spacing(5), '48px'),
		},
		sidenav: {
			flex: 0,
		},
		sideNavMessage: {
			color: palette.gray[700],
			fontSize: typography.fabricFontSize('small'),
			fontWeight: typography.fontWeightRegular,
			lineHeight: typography.fabricLineHeight('small'),
			marginTop: '20px',
		},
		content: {
			flex: '1',
			marginRight: '36px',
			'& h4': {
				color: palette.primary.main,
				marginBottom: '16px',
			},
			'& p': {
				marginBottom: '16px',
			},
			'& ul': {
				marginBottom: '16px',
				marginLeft: '14px',
			},
			'& ul li:before': {
				content: `'\u2022'`,
				color: palette.primary.main,
				display: 'inline-block',
				width: '1em',
				marginLeft: '-1em',
			},
		},
		actions: {
			backgroundColor: ifFeature('encore', undefined, palette.gray[100]),
			borderRadius: '12px',
			boxSizing: 'border-box',
			height: 'fit-content',
			padding: '24px',
		},
		action: {
			'&:not(:first-of-type)': {
				marginTop: '12px',
			},
		},
		request: {
			color: palette.success.main,
			display: 'block',
			fontSize: typography.fabricFontSize('small'),
			fontWeight: typography.fontWeightSemibold,
			lineHeight: typography.fabricLineHeight('small'),
			margin: '12px auto -5px',
			textAlign: 'center',
			width: '203px',
		},
	};
});
