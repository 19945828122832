import Ajax from '@utils/ajax';
import { AxiosPromise } from 'axios';
import { CoverageTypes } from '../types/coverage-type';

export interface CoverageLevelsGETContract {
	coverages: CoverageTypes;
}

export const getCoverageLevelRelationships = (): AxiosPromise<CoverageLevelsGETContract> => {
	return Ajax.get('/settings/benefits/plan_wizard/coverage_options');
};
