import { BodyText, Flex, Headline, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

export function EnpsIconOverHeading({ icon, title, subTitle }) {
	const classes = useStyles();

	return ifFeature(
		'encore',
		<Flex alignItems='center' flexDirection='column' marginTop={6}>
			{icon}
			{title && <Headline size='small'>{title}</Headline>}
			{subTitle && (
				<LayoutBox marginTop={1.5}>
					<BodyText>{subTitle}</BodyText>
				</LayoutBox>
			)}
		</Flex>,
		<div className={classes.enpsIconOverHeading}>
			<figure className={classes.enpsIconOverHeadingFigure}>
				<span className={classes.enpsIconOverHeadingIcon}>{icon}</span>
			</figure>
			{title && <div className={classes.enpsIconOverHeadingTitle}>{title}</div>}
			{subTitle && <div className={classes.enpsIconOverHeadingSubtitle}>{subTitle}</div>}
		</div>
	);
}

EnpsIconOverHeading.propTypes = {
	icon: PropTypes.object.isRequired,
	title: PropTypes.string,
	subTitle: PropTypes.string,
};
