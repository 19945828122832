import { BodyText, Icon, Svg } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { BEM } from '@utils/dom';
import { Fragment } from 'react';
import './floating-card.styl';

export function FloatingCard(props) {
	const { children, renderPrivacy } = props;
	const bem = new BEM('FloatingCard');

	const privacy = ifFeature(
		'encore',
		<BodyText icon='lock-regular' size='medium' color='neutral-weak'>
			<Message
				link={ExternalLink('https://www.bamboohr.com/privacy.php')}
				text={$._('Your feedback will be kept completely [anonymous].')}
			/>
		</BodyText>,
		<Fragment>
			<span className={bem.elem('footerLock')}>
				<Icon name='fab-lock-10x12' />
			</span>
			<Message
				link={ExternalLink('https://www.bamboohr.com/privacy.php')}
				text={$._('Your feedback will be kept completely [anonymous].')}
			/>
		</Fragment>
	);

	return (
		<div className={bem.elem()}>
			<div className={ifFeature('encore', undefined, bem.elem('body'))}>{children}</div>

			<div className={bem.elem('footer')}>
				<div className={bem.elem('privacy')}>{renderPrivacy ? renderPrivacy() : privacy}</div>
				<div className={bem.elem('poweredBy')}>
					<a href='https://www.bamboohr.com' rel='noopener external nofollow noreferrer' target='_blank'>
						<span className={bem.elem('footerLogo')}>
							{ifFeature('encore', 
								<Svg
									color="neutral-strong"
									height={17}
									image="bamboohr-logo"
									width={115}
								/>,
								<Icon name='fab-bamboohr-logo-115x17' />
							)}
						</span>
					</a>
				</div>
			</div>
		</div>
	);
}
