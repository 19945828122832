import {
	useEffect,
	useState,
	Dispatch,
	SetStateAction,
} from 'react';

import {
	cloneDeep,
} from 'lodash';

import {
	Table,
	TableColumn,
} from '@bamboohr/fabric';

import {
	DeductionPlanCell,
	NameAndEmployees
} from '../components';

import {
	AllowableLoanNumberObject,
	DeductionObject,
	DeductionPlanCellProps,
} from '../types';

interface AllowableLoanNumbersByTypeObject {
	[key: number]: AllowableLoanNumberObject[];
}

const getColumns = (
	updateDeductions: (newDeductionData: DeductionObject) => void,
	allowableLoanNumbersByType: AllowableLoanNumbersByTypeObject,
	updateAllowableLoanNumbersByType: (action: 'add'|'remove'|'replace', deductionTypeId: number, loanNumbers: AllowableLoanNumberObject[]) => void,
): TableColumn<DeductionObject>[] => {
	return [
		{
			cell: ({deductionName, employees}) => <NameAndEmployees deductionName={ deductionName } employees={ employees } />,
			header: $.__('TRAX Company Deduction'),
			key: 'traxDeduction',
			width: '325px',
		},
		{
			cell: (deduction) => {
				const props: DeductionPlanCellProps = {
					deduction,
					updateDeductions,
					updateAllowableLoanNumbersByType,
				}

				if (deduction.allowableLoanNumbers && deduction.allowableLoanNumbers.length > 0) {
					props.validLoanNumbers = allowableLoanNumbersByType[deduction.deductionTypeId] || [];
				}

				return <DeductionPlanCell { ...props } />
			},
			header: $.__('BambooHR Benefit Plan'),
			key: 'bambooBenefitPlan',
			verticalAlign: true,
		}
	];
}

interface DeductionSyncTableProps {
	deductions: DeductionObject[];
	setDeductions: Dispatch<SetStateAction<DeductionObject[]>>;
}

export function DeductionSyncTable(props: DeductionSyncTableProps): JSX.Element {
	const {
		deductions,
		setDeductions,
	} = props;

	const [allowableLoanNumbersByType, setAllowableLoanNumbersByType] = useState({});

	const updateDeductions = (newDeductionData: DeductionObject): void => {
		setDeductions(prevState => {
			const newDeductions: DeductionObject[] = cloneDeep(prevState);
			const targetIndex = newDeductions.findIndex(d => d.traxCldId === newDeductionData.traxCldId);
			newDeductions[targetIndex] = newDeductionData;

			return newDeductions;
		})
	}

	const updateAllowableLoanNumbersByType = (action: 'add'|'remove'|'replace', deductionTypeId: number, loanNumbers: AllowableLoanNumberObject[]): void => {
		setAllowableLoanNumbersByType(prevState => {
			let newLoanValues: AllowableLoanNumberObject[] = [...prevState[deductionTypeId]];

			if (action === 'add') {
				newLoanValues = [...newLoanValues, ...loanNumbers];
			}

			if (action === 'remove') {
				newLoanValues = newLoanValues.filter(loanValue => {
					return loanNumbers.findIndex(loanNumber => loanNumber.bhrCldId === loanValue.bhrCldId) === -1
				});
			}

			if (action === 'replace') {
				newLoanValues = loanNumbers;
			}
			return {
				...prevState,
				[deductionTypeId]: newLoanValues
			}
		})
	}

	useEffect(() => {
		const allowableLoanNumbers = {};
		deductions.forEach(deduction => {
			if (deduction.allowableLoanNumbers && deduction.allowableLoanNumbers.length > 0) {
				if (!allowableLoanNumbers[deduction.deductionTypeId]) {
					allowableLoanNumbers[deduction.deductionTypeId] = deduction.allowableLoanNumbers;
				}
			}
		})
		setAllowableLoanNumbersByType(allowableLoanNumbers);
	},[])

	return (
		<Table
			caption={ $.__('TRAX deductions and BambooHR benefit plans') }
			columns={ getColumns(updateDeductions, allowableLoanNumbersByType, updateAllowableLoanNumbersByType) }
			rowKey={ (deduction: DeductionObject) => deduction.traxCldId }
			rows={ deductions }
		/>
	);
}
