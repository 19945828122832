import {
	Fragment,
	FunctionComponent,
	useState,
} from 'react';


import { AcaMigrationModal } from './aca-migration-modal';
import { AddEmploymentStatusWrapper } from './add-employment-status-wrapper';
import { EmploymentStatusTable } from './employment-status-table';
import { ListValue } from './types';

import './employment-status-wrapper.styl';

interface EmploymentStatusWrapperProps {
	acaStatuses: string[] | null;
	listId: number;
	listValues: ListValue[];
	needsACA2019Migration: boolean;
	showAcaStatus: boolean;
	showPeople: boolean;
}

export const EmploymentStatusWrapper: FunctionComponent<EmploymentStatusWrapperProps> = (props) => {
	const {
		acaStatuses,
		listId,
		listValues,
		needsACA2019Migration,
		showAcaStatus,
		showPeople,
	} = props;
	const [isAcaMigrationModalOpen, setIsAcaMigrationModalOpen] = useState(needsACA2019Migration);

	function handleModalClose() {
		setIsAcaMigrationModalOpen(false);
	}

	return (
		<Fragment>
			<AddEmploymentStatusWrapper
				acaStatuses={ acaStatuses || [] }
				listId={ listId }
				showAcaStatus={ showAcaStatus }
			/>
			<EmploymentStatusTable
				acaStatuses={ acaStatuses || [] }
				listId={ listId }
				listValues={ listValues }
				showAcaStatus={ showAcaStatus }
				showPeople={ showPeople }
			/>
			<AcaMigrationModal
				handleModalClose={ handleModalClose }
				isOpen={ isAcaMigrationModalOpen }
			/>
		</Fragment>
	);
};
