import { Icon } from '@bamboohr/fabric';
import { StepCheckFeedback } from '../step-check-feedback';

import {
	CheckMigrationProgressProps,
} from '../../utils/interfaces';

import './check-migration-progress.styl';

export function CheckMigrationProgress(props: CheckMigrationProgressProps): JSX.Element {
	const {
		steps,
	} = props;
	const stepsLength = steps.length;

	return (
		<div className="CheckMigrationProgress">
			<div className="CheckMigrationProgress__header">
				<Icon brand={ true } name="fab-person-circle-28x28" />

				<h2 className="CheckMigrationProgress__headerText">
					{ $.__('Checking Progress') }
				</h2>
			</div>

			<div className="CheckMigrationProgress__message">
				<span className="CheckMigrationProgress__messageIcon">
					<Icon name="fab-clipboard-check-154x184" />
				</span>

				<h3 className="CheckMigrationProgress__messageHeadline">
					{ $.__(`Alright! Let's See If There's More To Work On...`) }
				</h3>

				<p className="CheckMigrationProgress__messageText">
					{ $.__('This may take a few moments') }.
				</p>
			</div>

			<div className="CheckMigrationProgress__feedback">
				{ steps.map((step, index) => (
					<StepCheckFeedback
						key={ step }
						isComplete={ index !== (stepsLength - 1) }
						stepDisplay={ step }
					/>
				)) }
			</div>
		</div>
	);
}
