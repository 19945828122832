import {
	Fragment,
} from 'react';

import { LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	ReviewTable,
} from 'aca-status-alignment-table.react';
import {
	ACA_TYPES,
} from 'aca-status-select.react';

import { HeadlineWrapper } from '../headline-wrapper';

/** @type {React.FunctionComponent} */
export default function ModalContentEnableStep4(props) {
	const {
		employeeData,
		employmentStatusAcaTypes,
	} = props;

	return (
		<Fragment>
			<HeadlineWrapper
				headlineText={ $.__(`Verify Employees' Employment Status`) }
				subHeadlineText={ $.__(`Take a moment to double check that your employees have the correct employment and ACA status.`) }
			/>
			<LayoutBox marginBottom={ifFeature('encore', 0.5, 0)}>
				<ReviewTable
					columns={ [
						{
							header: $.__('Name'),
							cell: row => `${ row.firstName } ${ row.lastName }`,
							sortBy: row => `${ row.firstName } ${ row.lastName }`,
						},
						{
							header: $.__('ACA Status'),
							cell: (row) => {
								return (ACA_TYPES[row.acaType] ? ACA_TYPES[row.acaType].label : row.acaType);
							},
							sortBy: (row) => {
								return (ACA_TYPES[row.acaType] ? ACA_TYPES[row.acaType].label : row.acaType);
							},
						},
						{
							header: $.__('Employment Status'),
							cell: row => row.employmentStatusName,
							sortBy: row => row.employmentStatusName,
						},
					] }
					employeeData={ employeeData }
					employmentStatusAcaTypes={ employmentStatusAcaTypes }
				/>
			</LayoutBox>
		</Fragment>
	);
}
