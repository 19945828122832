import { FilterGroup, FilterType, FilterValue } from '../../../types';
import { INITIAL_FILTERS } from '../../../constants';
import { UpdateGroupProperty } from '../../types';

type SetFilter = (filterType: FilterType, selectedValues: FilterValue[]) => void;

export const useSetFilter = (updateGroupProperty: UpdateGroupProperty, filterGroup: FilterGroup): { setFilter: SetFilter } => {
	const setFilterGroup = (newFilterGroup: FilterGroup): void => {
		const newEligibleEmployees = {
			selectedEmployeeIds: [],
			areSpecificPeopleSelected: false,
			filterGroup: newFilterGroup,
		};
		updateGroupProperty('eligibilityFilters', newEligibleEmployees, true);
	};

	const resetFilters = (): void => {
		setFilterGroup(INITIAL_FILTERS);
	};

	const addFilter = (filterType: FilterType, selectedValues: FilterValue[]): void => {
		const newOrder = [...filterGroup.order];
		newOrder.push(filterType);

		setFilterGroup({
			isAutoGenerated: false,
			order: newOrder,
			values: {
				...filterGroup.values,
				[filterType]: selectedValues,
			},
		});
	};

	const removeFilter = (filterType: FilterType): void => {
		if (filterType === FilterType.employmentStatus) {
			resetFilters();
			return;
		}

		const newOrder = [...filterGroup.order];
		const filterIndex = newOrder.findIndex((orderedFilterType) => orderedFilterType === filterType);
		if (filterIndex > -1) {
			newOrder.splice(filterIndex, 1);
		}

		setFilterGroup({
			isAutoGenerated: false,
			order: newOrder,
			values: {
				...filterGroup.values,
				[filterType]: [],
			},
		});
	};

	const updateFilter = (filterType: FilterType, selectedValues: FilterValue[]): void => {
		setFilterGroup({
			isAutoGenerated: false,
			order: filterGroup.order,
			values: {
				...filterGroup.values,
				[filterType]: selectedValues,
			},
		});
	};

	const setFilter: SetFilter = (filterType: FilterType, selectedValues: FilterValue[]): void => {
		if (selectedValues.length) {
			const newOrder = [...filterGroup.order];
			const filterIndex = newOrder.findIndex((orderedFilterType) => orderedFilterType === filterType);
			if (filterIndex > -1) {
				updateFilter(filterType, selectedValues);
			} else {
				addFilter(filterType, selectedValues);
			}
		} else {
			removeFilter(filterType);
		}
	};

	return { setFilter };
};
