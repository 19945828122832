import React, { ReactElement } from 'react';
import { SideSubNavigation, Flex, Headline } from '@bamboohr/fabric';
import { RenderNavDropdown } from '../render-nav-dropdown/render-nav-dropdown';

export function RenderSideSubNavigation({ levelTypes, setActiveTab, activeTab = '', canAddLevels }): ReactElement {
	return (
		<Flex flexDirection='column' marginTop={1.5}>
			<Flex alignItems='center' justifyContent='space-between' marginBottom={2.5}>
				<Headline size='extra-small'>{$.__('Levels')}</Headline>
				{canAddLevels && <RenderNavDropdown />}
			</Flex>
			<SideSubNavigation items={levelTypes.map((accessLevel) => {
					return (
							accessLevel.levels?.length > 0 ? (
								<>
									<SideSubNavigation.Divider />
									<SideSubNavigation.GroupTitle {...(accessLevel.name === 'employee' && { 'data-bi-id': 'settings-access-levels-employee-levels' })}>
										{accessLevel.label}
									</SideSubNavigation.GroupTitle>
									{accessLevel.levels.map((level) => (
										<SideSubNavigation.Link active={level.id === activeTab} href={level.href} {...(level.name === 'all' && { 'data-bi-id': 'settings-access-levels-all' })} onClick={(e: Event) => { e.preventDefault(); setActiveTab(level.id) }}>
											{`${level.label} (${level.count})`}
										</SideSubNavigation.Link>
									))}
								</>
							) : (
								<>
									{accessLevel.name === 'no_access' && <SideSubNavigation.Divider />}
									<SideSubNavigation.Link active={activeTab === accessLevel.label} href={accessLevel.href} {...(accessLevel.name === 'all' && { 'data-bi-id': 'settings-access-levels-all' })} onClick={(e: Event) => { e.preventDefault(); setActiveTab(accessLevel.label) }}>
										{`${accessLevel.label} (${accessLevel.count})`}
									</SideSubNavigation.Link>
								</>
							)
					);
				})
			} />
		</Flex>
	);
}
