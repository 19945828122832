import { LifeOrADandDEnum } from '../types';

export type FunctionList<T> = {
	[key in LifeOrADandDEnum]?: () => T;
};

export const makeLifeOrAddValue = <T>(
	type: string | null,
	functionList: FunctionList<T>,
	defaultFunction: () => T,
): T => {
	if (!type) {
		return defaultFunction();
	}
	return functionList[type]?.() ?? defaultFunction();
};
