import { Icon } from '@bamboohr/fabric';

import {
	StepCheckFeedbackProps,
} from '../../utils/interfaces';

import './step-check-feedback.styl';

export function StepCheckFeedback(props: StepCheckFeedbackProps): JSX.Element {
	const {
		stepDisplay,
		isComplete,
	} = props;

	return (
		<div className="StepCheckFeedback">
			<p>{ stepDisplay }</p>
			{ isComplete ? (
				<div className="StepCheckFeedback__complete">
					<Icon name="fab-check-circle-18x18" />
					<p className="StepCheckFeedback__completeText">{ $.__('Looks Good!') }</p>
				</div>
			) : (
				<div className="StepCheckFeedback__checking">
					<div className="dotLoader small">
						<div className="bounce1 StepCheckFeedback__dot"></div>
						<div className="bounce2 StepCheckFeedback__dot"></div>
						<div className="bounce3 StepCheckFeedback__dot"></div>
					</div>

					<p className="StepCheckFeedback__checkingText">{ $.__('Checking') }</p>
				</div>
			) }
		</div>
	);
}
