import {
	Fragment,
	useEffect,
	useMemo,
} from 'react';
import Multiple from './multiple';
import { PoMicroFrontend } from 'micro-frontend.react';
import AdvancedOptions from './advanced-options';
import {
	PayrollFooter,
	PayrollHeader,
} from './payroll';

import {
	StateContextProvider,
	useAjax,
	useStateContext,
} from './state';
import {
	buildContext,
} from './util';

/**
 * @param {object} props
 * @param {string} props.levelId
 * @param {boolean} [props.multiple=false]
 */
export default function AddEmployeeModal({
	levelId,
	multiple = false,
	...props
}) {
	const [data, isLoading] = useAjax(`/settings/access_levels/get_add_employee_modal/${ levelId }`, {
		json: true,
		multiple,
	});

	const context = useMemo(() => buildContext(data), [data]);

	useEffect(() => {
		window.BambooHR.Modal.setState({
			isLoading,
		}, true);
	}, [isLoading]);
	return context && (
		<StateContextProvider
			{ ...props }
			value={ context }
		>
			<AddEmployeeModalContent />
		</StateContextProvider>
	);
}

function AddEmployeeModalContent() {
	const [{
		selectedEmployees,
		accessLevel: {
			name: headline,
			type,
		},
		accessLevel,
		employeeList,
		multiple,
		hasValidEmail = true,
		homeEmail,
		workEmail
	}, {
		submit,
		selectEmployee,
		updateEmail
	}] = useStateContext();
	const {
		isAdmin,
		isPayrollAdmin,
	} = useMemo(() => ({
		isAdmin: type === 'admin',
		isPayrollAdmin: type === 'payroll-admin',
	}), [type]);

	useEffect(() => {
		setTimeout(() => {
			window.BambooHR.Modal.setState({
				headline,
			}, true);
		}, 0);
	}, [headline]);

	useEffect(() => {
		setTimeout(() => {
			window.BambooHR.Modal.setState({
				primaryAction: selectedEmployees.length > 0 &&
				(multiple || (hasValidEmail !== false || selectedEmployees[0].hasEmail))
					? submit
					: null,
			}, true);
		}, 0);
	}, [selectedEmployees, multiple, hasValidEmail, submit]);

	const handleUpdateEmail = (email) => {
		updateEmail(email)
	}

	useEffect(() => {
		if (!multiple) {
			setTimeout(() => {
				window.BambooHR.Modal.setState({
					isLoading: false,
					title: $.__(`Add Employee`),
					type: 'small',

				}, true);
			}, 0);
		}
	}, [multiple]);


	return (
		<Fragment>
			<PayrollHeader />
			<Multiple />
			<PoMicroFrontend
				selectEmployee={selectEmployee}
				updateEmail={handleUpdateEmail}
				accessLevel={accessLevel}
				multiple={multiple}
				employeeList={employeeList}
				isAdmin={isAdmin}
				isPayrollAdmin={isPayrollAdmin}
				homeEmail={homeEmail}
				workEmail={workEmail}
				hasValidEmail={hasValidEmail}
				route='/settings/access_levels/add-single-employee'
			/>
			<AdvancedOptions />
			<PayrollFooter />
		</Fragment>
	);
}
