import { BodyText, Button, DonutChart, Flex, Headline, IconV2, LayoutBox, Typography, useTheme } from '@bamboohr/fabric';
import { ClipboardPulse30x36, EnvelopeCheck64x48 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { DateTime } from 'luxon';
import { getDatesAndStrings } from './date-service';
import { useStyles } from './styles';

interface Data {
	completionRate: number;
	emailSurveyEndDate: string;
	emailSurveySendDate: string;
	employeesComplete: number;
	employeesTotal: number;
	reportPreviewUrl: string;
	timeZone: string;
}

const useGetColor = () => {
	const {
		palette: { primary, gray },
	} = useTheme();
	return (i) => [primary.main, gray[400]][i % 2];
};

export const SurveyInProgressView = (data: Data): JSX.Element => {
	const getColor = useGetColor();
	const classes = useStyles();
	const { emailSurveySendDate, emailSurveyEndDate, employeesTotal, employeesComplete, completionRate, reportPreviewUrl, timeZone } =
		data;
	const { formattedStartDateString, formattedEndDate, formattedEndDateString, daysAgo, daysToEnd } = getDatesAndStrings(
		emailSurveySendDate,
		emailSurveyEndDate
	);
	const slices = [
		{
			label: 'completed',
			value: employeesComplete,
		},
		{
			label: 'incomplete',
			value: employeesTotal === 0 ? 1 : employeesTotal - employeesComplete,
		},
	];

	const icon = ifFeature(
		'encore',
		<IconV2 name='chart-line-up-regular' size={40} color='neutral-strong' />,
		<ClipboardPulse30x36 className={classes.centerIcon} />
	);

	return (
		<Flex justifyContent='center' width='100%' marginTop={3}>
			<Flex justifyContent='center' alignItems='center' flexDirection='column' width='70%' paddingTop={ifFeature('encore', 1.5, 4.5)}>
				{ifFeature(
					'encore',
					<IconV2 name='envelope-circle-check-solid' size={60} color='primary-strong' />,
					<div className={classes.titleIcon}>
						<EnvelopeCheck64x48 />
					</div>
				)}

				{ifFeature(
					'encore',
					<Flex flexDirection='column' alignItems='center'>
						<Headline size='medium'>{$.__('Your survey is in full swing!')}</Headline>
						<LayoutBox marginTop={1.5}>
							<BodyText color='neutral-extra-strong'>{$.__("Here's how things are looking so far:")}</BodyText>
						</LayoutBox>
					</Flex>,
					<Typography className={classes.title}>
						{$.__("Your survey is in full swing. Here's how things are looking so far.")}
					</Typography>
				)}
				<div className={classes.dataOuterContainer}>
					<LayoutBox width='26%'>
						<DonutChart
							animateInitial={true}
							data={slices}
							getColor={getColor}
							pieKey={(slice) => slice.label}
							pieValue={(slice) => slice.value}
							LabelProps={{
								icon,
								label: '',
							}}
						/>
					</LayoutBox>

					<div className={classes.dataItems}>
						{ifFeature(
							'encore',
							<>
								<LayoutBox marginTop={1} marginBottom={2}>
									<BodyText color='neutral-extra-strong' weight='semibold' size='large'>
										{$.__('%1%% Completion Rate', completionRate)}
									</BodyText>
									<BodyText color='neutral-extra-strong' size='small'>
										{$.__('%1$s out of %2$s employees have finished the survey', employeesComplete, employeesTotal)}
									</BodyText>
								</LayoutBox>
								<BodyText color='neutral-extra-strong'>
									{$.__(
										'The survey was sent on %1$s (%2$s days ago) and closes on %3$s (in %4$s days) at %5$s %6$s',
										formattedStartDateString,
										daysAgo,
										formattedEndDateString,
										daysToEnd,
										formattedEndDate.toLocaleString(DateTime.TIME_SIMPLE),
										timeZone
									)}
								</BodyText>
							</>,
							<>
								<Typography className={classes.title}>{$.__('%1%% Completion Rate', completionRate)}</Typography>
								<Typography className={classes.subTitle}>
									{$.__('%1$s out of %2$s employees have finished the survey', employeesComplete, employeesTotal)}
								</Typography>
								<Typography>
									{$.__(
										'The survey was sent on %1$s (%2$s days ago) and closes on %3$s (in %4$s days) at %5$s %6$s',
										formattedStartDateString,
										daysAgo,
										formattedEndDateString,
										daysToEnd,
										formattedEndDate.toLocaleString(DateTime.TIME_SIMPLE),
										timeZone
									)}
								</Typography>
							</>
						)}

						<LayoutBox marginTop={ifFeature('encore', 3, 1)}>
							<Button variant={ifFeature('encore', undefined, 'outlined')} href={reportPreviewUrl}>
								{$.__('Go to Employee Wellbeing Report')}
							</Button>
						</LayoutBox>
					</div>
				</div>
			</Flex>
		</Flex>
	);
};
