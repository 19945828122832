import React from 'react';

const listTemplate = (data) => {
	const { adp_code, id, label, list_id, showCode = false, totalHistoryCount } = data;

	const Code = showCode ? (
		<div className='fieldRow fte' key='fte-code'>
			<div clasName='fieldBox'>
				<label htmlFor='ADPCode'>{$.__('ADP Code')}</label>
				<div className='fieldDiv'>
					<input type='text' className='text' name='ADPCode' value={adp_code} />
				</div>
			</div>
		</div>
	) : null;

	const PreForm =
		totalHistoryCount > 0 ? (
			<div className='fab-FormRow' id='preForm'>
				<fieldset className='fab-RadioGroup check-radio EmployeeFieldsForm__modal--radiogroup'>
					<legend className='fab-RadioGroup__legend EmployeeFieldsForm__modal--legend'>{$.__('How should this change occur?')}</legend>
					<div className='fab-Radio'>
						<input className='fab-Radio__input' type='radio' id='changeHistory1' name='changeHistory' value='noHistory' />
						<label className='fab-Radio__label' htmlFor='changeHistory1'>
							{$.__('Change for future but leave history alone.')}
						</label>
					</div>
					<div className='fab-Radio'>
						<input className='fab-Radio__input' type='radio' id='changeHistory2' name='changeHistory' value='history' />
						<label className='fab-Radio__label' htmlFor='changeHistory2'>
							{$.__('Change anywhere it is used, including history.')}
						</label>
					</div>
				</fieldset>
			</div>
		) : null;

	return (
		<div id='form' data-itemid={id} data-listid={list_id}>
			<form method='post' action='' id='editValueForm' className='BhrForms'>
				<input type='hidden' name='id' value={id} />
				<input type='hidden' name='changeHistory' value='history' />
				<input type='hidden' name='action' value='Update' />
				<input type='hidden' name='totalCount' value={totalHistoryCount} />
				<input type='hidden' name='ajax' value='1' />
				<fieldset>
					<div className='fab-FormRow'>
						<div className='fab-FormColumn'>
							<label className='fab-Label EmployeeFieldsForm__modal--label' htmlFor='value'>
								{$.__('Change %s to...', label)}
							</label>
							<input
								className='fab-TextInput fab-TextInput--width8'
								defaultValue={label}
								id='value'
								maxLength='100'
								name='value'
								required={true}
								type='text'
							/>
						</div>
					</div>
					{Code}
					{PreForm}
				</fieldset>
			</form>
		</div>
	);
};

export default listTemplate;
