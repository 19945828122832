/* eslint-disable max-classes-per-file */
import { render } from 'base/wrapped-render';
import Ctrl, {
	onReady,
} from 'ctrl.deco';
import {
	getJsonData,
} from 'Data.util';
import {
	ConsentOnlyInterstitial,
} from './consent-only-interstitial.react';

@Ctrl('/settings/users/consent/*')
class SettingsUsersConsentCtrl {
	@onReady()
	_readyHandler() {
		const consentOnlyRoot = document.getElementById('consentOnlyRoot');
		const consentOnlyData = getJsonData('.js-consentOnlyData');

		if (consentOnlyRoot && consentOnlyData) {
			render(<ConsentOnlyInterstitial { ...consentOnlyData } />, consentOnlyRoot);
		}
	}
}
