import { DeductionTypeEnumContract, SupplementalPlanType } from '../../types/contracts';

export const getDeductionTypeBySelectedPlanType = (selectedPlanType: string): string => {
	switch (selectedPlanType) {
		case SupplementalPlanType.HospitalIndemnity: {
			return DeductionTypeEnumContract.hospitalIndemnityPreTax;
		}
		case SupplementalPlanType.CriticalIllness:
		case SupplementalPlanType.Accident:
		case SupplementalPlanType.Cancer: {
			return DeductionTypeEnumContract.preTaxGeneralHealthcare;
		}
		default: {
			return '';
		}
	}
};
