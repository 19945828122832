import { ChangeEvent } from 'react';
import classNames from 'classnames';

import { CostTypeEnumContract } from '../../types/plan-contracts';

interface FixedPercentToggleProps {
	costType: CostTypeEnumContract;
	currencySymbol: string;
	isDisabled?: boolean;
	idSuffix: string;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FixedPercentToggle = (props: FixedPercentToggleProps): JSX.Element => {
	const {
		costType,
		currencySymbol,
		isDisabled,
		idSuffix,
		handleChange,
	} = props;

	return (
		<div className="fab-FormField">
			<div className={ classNames('bhrToggleSmall bhrToggle--btn', { 'bhrToggle--btn--disabled': isDisabled }) }>
				<input
					checked={ costType === CostTypeEnumContract.fixed }
					className="bhrToggle--btn-1"
					disabled={ isDisabled }
					id={ `toggleFixed${ idSuffix }` }
					onChange={ handleChange }
					type="radio"
					value={ CostTypeEnumContract.fixed }
				/>
				<label className="bhrToggle__label bhrToggle--btn__label" htmlFor={ `toggleFixed${ idSuffix }` }>
					{ currencySymbol }
				</label>

				<input
					checked={ costType === CostTypeEnumContract.percent }
					className="bhrToggle--btn-2"
					disabled={ isDisabled }
					id={ `togglePercent${ idSuffix }` }
					onChange={ handleChange }
					type="radio"
					value={ CostTypeEnumContract.percent }
				/>
				<label className="bhrToggle__label bhrToggle--btn__label" htmlFor={ `togglePercent${ idSuffix }` }>
					%
				</label>

				<span className="bhrToggle__handle bhrToggle--btn__handle brandedToggle"></span>
			</div>
		</div>
	);
};
