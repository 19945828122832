import { CompanyEmploymentStatusMetadataProps } from '../../utils/interfaces';

import './company-employment-status-metadata.styl';
export function CompanyEmploymentStatusMetadata(props: CompanyEmploymentStatusMetadataProps): JSX.Element {
	let value = '';
	const {
		data,
	} = props;

	if (data && data.acaStatus) {
		value = data.acaStatus;
	}

	return (
		<div className="CompanyEmploymentStatusMetadata">
			{ value && (
				$.__('ACA Status: ') + value
			) }
		</div>
	);
}
