import { Icon } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useStyles } from './styles';

// ToDo Performance Cleanup - Remove component
export function EnpsSection(props) {
	const { checked, children, iconName, isSubdued, step } = props;
	const classes = useStyles();
	const renderStep = (
		<Fragment>
			<span className={classNames([classes.icon], { [classes.iconSubdued]: isSubdued })}>
				<Icon name={iconName} />
			</span>
			{step && <span className={classNames([classes.bubble], { [classes.bubbleSubdued]: isSubdued })}>{step}</span>}
		</Fragment>
	);

	return ifFeature(
		'encore',
		<>{children}</>,
		<div className={classes.enpsSection}>
			<figure className={classes.enpsFigure}>
				{checked ? (
					<span className={classNames([classes.icon], [classes.iconSuccess], { [classes.iconSubdued]: isSubdued })}>
						<Icon name='fab-envelope-check-31x23' />
					</span>
				) : (
					renderStep
				)}
			</figure>

			<div className={classes.enpsBody}>{children}</div>
		</div>
	);
}
