import { BodyText, Button, Flex, Headline, Icon, LayoutBox, Link, StyledBox, TextButton, WistiaVideo } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useContext } from 'react';
import { EmbedVideo } from '../shared/embed-video';
import { EmployeeWellbeingHeader } from '../shared/employee-wellbeing-header';
import { Data, SurveyStatus } from '../utils/';
import './get-started.styl';

export const GetStarted: React.FC = () => {
	const {
		state: { reportPreviewUrl, videoUrl, showPreviewLinks },
	} = useContext(Data);

	const { onGetStartedClick, showPreview, showGetStartedPage } = useContext(SurveyStatus);

	if (!showGetStartedPage) {
		return null;
	}

	return ifFeature(
		'encore',
		<Flex flexDirection='column' justifyContent='center' paddingTop={1.5} textAlign='center'>
			<StyledBox backgroundColor='neutral-medium' borderRadius='large' marginX='auto' maxWidth='700px' width='100%'>
				<EmbedVideo videoSrc={videoUrl} videoTitle={$.__('Promo Video')} />
			</StyledBox>
			<LayoutBox maxWidth={585} marginX='auto'>
				<LayoutBox marginTop={5} marginBottom={3}>
					<LayoutBox marginBottom={2}>
						<Headline size='small'>{$.__('Gain Valuable Insights on Employee Engagement with Employee Wellbeing')}</Headline>
					</LayoutBox>
					<BodyText>
						{$.__(
							'Employee Wellbeing uses monthly, bi-monthly, or quarterly surveys to measure four broad aspects of employee experience: happiness, personal motivators, company motivators, and relationships.'
						)}
					</BodyText>
				</LayoutBox>
				{showPreviewLinks && (
					<Flex justifyContent='center' alignItems='center' gap={1}>
						<TextButton onClick={() => showPreview()} type='button' startIcon='eye-solid'>
							{$.__('Preview Sample Survey')}
						</TextButton>

						<BodyText color='neutral-weak'>|</BodyText>

						<Link href={reportPreviewUrl} rel='noopener noreferrer' target='_blank'>
							<BodyText icon='chart-line-up-regular'>{$.__('Preview Report (Demo Data)')}</BodyText>
						</Link>
					</Flex>
				)}
				<Flex alignItems='center' justifyContent='center' marginTop={2.5}>
					<Button onClick={onGetStartedClick} type='button' data-bi-id='employee-wellbeing-settings-get-started-button'>
						{$.__("Let's Get Started")}
					</Button>
				</Flex>
			</LayoutBox>
		</Flex>,
		<div className='EmployeeWellbeingGetStarted'>
			<EmployeeWellbeingHeader borderBottom={false} />
			<div className='EmployeeWellbeingGetStarted__container'>
				<div className='EmployeeWellbeingGetStarted__video'>
					<EmbedVideo videoSrc={videoUrl} videoTitle={$.__('Promo Video')} />
				</div>
				<div className='EmployeeWellbeingGetStarted__summary'>
					{$.__(
						'Employee Wellbeing uses monthly, bi-monthly, or quarterly surveys to measure four broad aspects of employee experience: happiness, personal motivators, company motivators, and relationships.'
					)}
				</div>
				<div className='EmployeeWellbeingGetStarted__previewButtons'>
					{showPreviewLinks && (
						<>
							<div>
								<a onClick={() => showPreview()} rel='noopener noreferrer' target='_blank'>
									<Icon name='fab-eye-16x12' />
									<span>{$.__('Preview Sample Survey')}</span>
								</a>
							</div>
							<div>
								<a href={reportPreviewUrl} rel='noopener noreferrer' target='_blank'>
									<Icon name='fab-bar-chart-arrow-16x16' />
									<span>{$.__('Preview Report (Demo Data)')}</span>
								</a>
							</div>
						</>
					)}
				</div>
				<Flex justifyContent='center' alignItems='center' marginTop={2.5}>
					<Button onClick={onGetStartedClick} type='button' data-bi-id='employee-wellbeing-settings-get-started-button'>
						{$.__("Let's get started")}
					</Button>
				</Flex>
			</div>
		</div>
	);
};
