import React, {
	Fragment,
} from 'react';

import {
	Button
} from '@fabric/button';

export default (props) => {
	const {
		buttonOnClick,
		buttonText,
		editMessage,
		isSyncToolDisabled,
		syncToolId
	} = props;
	
	return editMessage && syncToolId ? (
		<Fragment>
			<div className="fab-FormRow">
				<div className="fieldBox">
					<div className="fab-FormNote--warning EditPayGroupModal__label">
						{ editMessage }
					</div>
				</div>
			</div>
			{ isSyncToolDisabled ? (
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<div className="fieldDiv">
							<Button
								clickAction={ buttonOnClick }
								outline={ true }
								size={ 'small' }
								text={ buttonText }
								type={ 'button' }
							/>
						</div>
					</div>
				</div>
			) : null }
		</Fragment>
	) : null;
}
