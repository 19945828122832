import {
	useState,
	useEffect,
	createRef,
} from 'react';
import moment from 'moment';
import {
	Popover,
	makeStyles,
} from '@bamboohr/fabric';

import {
	getCompanyDeductionsMetadata,
} from '../../utils/utilities';

import {
	DeductionGroupPopoverProps,
	CompanyDeductionsMetadata,
} from '../../utils/interfaces';

const PRESENT_TEXT = $.__('Present');
const useStyles = makeStyles({
	popoverBody: {
		maxWidth: '450px',
	},
});

export function DeductionGroupPopover(props: DeductionGroupPopoverProps): JSX.Element {
	const [bambooMetadata, setBambooMetadata] = useState<CompanyDeductionsMetadata>(null);
	const [traxMetadata, setTraxMetadata] = useState<CompanyDeductionsMetadata>(null);
	const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);
	const styles = useStyles();
	const textButton = createRef<HTMLButtonElement>();
	const {
		deductionMetadata,
	} = props;

	useEffect(() => {
		setBambooMetadata(getCompanyDeductionsMetadata(deductionMetadata, 'bamboo'));
		setTraxMetadata(getCompanyDeductionsMetadata(deductionMetadata, 'trax'));
	}, [deductionMetadata]);

	if (!bambooMetadata || !traxMetadata) {
		return null;
	}
	const {
		name: bambooName,
		startDate: bambooStartDate,
		endDate: bambooEndDate,
	} = bambooMetadata;
	const {
		name: traxName,
		startDate: traxStartDate,
		endDate: traxEndDate,
	} = traxMetadata;
	const isInactive = bambooEndDate ? moment(bambooEndDate, moment.defaultFormat).isBefore(moment(), 'day') : null;
	const title = `${ bambooName } (${ isInactive ? $.__('Inactive') : $.__('Active') })`;

	return (
		<>
			<button
				ref={ textButton }
				className="fab-TextButton fab-Link"
				onClick={ (): void => setPopoverIsOpen(!popoverIsOpen) }
				type="button"
			>
				{ bambooName } / { traxName }
			</button>
			<Popover
				anchorEl={ () => textButton.current }
				classes={ {
					popover: styles.popoverBody,
				} }
				onClose={ () => setPopoverIsOpen(false) }
				open={ popoverIsOpen }
				placement="right"
				title={ title }
			>
				<p>{ $.__('Deduction Start') }: { bambooStartDate } (BHR) | { traxStartDate } (TRAX)</p>
				<p>{ $.__('Deduction End') }: { bambooEndDate || PRESENT_TEXT } (BHR) | { traxEndDate || PRESENT_TEXT } (TRAX)</p>
			</Popover>
		</>
	);
}
