import { connect } from 'react-redux';

import { WorkflowsApp } from '../components/workflows-app';
import { getData } from '../store/actions';

const mapStateToProps = (state) => {
	const {
		customApprovalEnabled,
		subNavItems,
		workflow
	} = state;
	const {
		active,
		advancedApprovalToolEnabled,
		curAppStatus,
		id,
		name,
		pathType,
		template,
		type
	} = workflow || {};

	return {
		active,
		advancedApprovalToolEnabled,
		customApprovalEnabled,
		curAppStatus,
		id,
		name,
		pathType,
		subNavItems,
		template,
		type
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getWorkflowData: (workflowId) => {
			return dispatch(getData(workflowId));
		}
	};
};

export const WorkflowsAppContainer = connect(mapStateToProps, mapDispatchToProps)(WorkflowsApp);
