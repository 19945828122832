import { useState } from 'react';
import {
	Button,
	Checkbox,
	TextButton,
} from '@bamboohr/fabric';

import './styles.styl';

export function TosConsentForm(props) {
	const {
		consentCopy,
		continueLink,
	} = props;

	const [isAccepted, setIsAccepted] = useState(false);

	const checkboxLabel = $.__('I agree to the Terms of Use and Privacy Policy');

	const handleCheckboxChange = ({ checked }) => {
		setIsAccepted(checked);
	};

	return (
		<form action="/settings/users/consent/" method="post">
			<input name="continueLink" type="hidden" value={ continueLink } />
			<input name="CSRFToken" type="hidden" value={ CSRF_TOKEN } />
			<div className="TosConsent__box">
				{ /* eslint-disable react/no-danger */ }
				<p
					className="TosConsent__copy"
					dangerouslySetInnerHTML={ { __html: consentCopy } }
				/>
				{ /* eslint-enable */ }
				<Checkbox
					checked={ isAccepted }
					label={ checkboxLabel }
					name="consent"
					onChange={ handleCheckboxChange }
					value="1"
				/>
			</div>
			<div className="TosConsent__buttons">
				<Button
					disabled={ !isAccepted }
					type="submit"
				>
					{ $.__('Accept & Continue') }
				</Button>
				<TextButton
					href="/"
				>
					{ $.__('Return to BambooHR') }
				</TextButton>
			</div>
		</form>
	);
}
