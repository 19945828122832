import { getJsonScriptVar } from 'BambooHR.util';
import { render } from 'base/wrapped-render';

import Ctrl, { onPjaxEnd, onReady } from 'ctrl.deco';
import { useDragAndDrop } from 'drag-and-drop';
import { PoMicroFrontend } from 'micro-frontend.react';

import PerformanceUpsell from './PerformanceUpsell';

import { PerformanceSettings } from './performance-settings.react';

@Ctrl('/settings/performance/(home)?/(setup)?/:id?')
class SettingsPerformance {
	@onReady()
	@onPjaxEnd()
	_readyHandler(e, ctrl) {

		const mountReactApps = () => {
			const performanceUpsellReactRoot = document.getElementById('js-performanceUpsellReactRoot');

			/**
			 * React Performance up sell
			 * @param {performanceUpsellReactRoot} root to mount the react app to
			 */
			if (performanceUpsellReactRoot) {
				const upsellData = getJsonScriptVar('upsellData', 'Performance') || {};
				const upsellABQueryParam = 'up=1';

				if (window.location.href.indexOf(upsellABQueryParam) < 0) {
					window.location = `/settings/performance/home?${ upsellABQueryParam }`;
					return;
				}

				return render(<PerformanceUpsell demoRequest={upsellData} />, performanceUpsellReactRoot);
			}

			const reviewCyclesRoot = document.getElementById('js-settingsReviewCyclesRoot');
			const reviewCyclesWizardRoot = document.getElementById('js-setupReviewCyclesRoot');

			if (reviewCyclesWizardRoot) {
				const { id } = ctrl._params;

				const filterListData = getJsonScriptVar('filterLists', 'PerformanceWizard');
				return render(
					(
						<PoMicroFrontend
							route='/settings/performance/ReviewCyclesWizard'
							filterListData={filterListData}
							id={id}
							useDragAndDrop={useDragAndDrop}
						/>
					),
					reviewCyclesWizardRoot
				);
			}

			if (reviewCyclesRoot) {
				const filterListData = getJsonScriptVar('filterLists', 'Performance');
				const reviewCycleConfigurations = getJsonScriptVar('reviewCycleConfigurations', 'Performance');

				return render(
					(
						<PerformanceSettings
							filterListData={filterListData}
							reviewCycleConfigurations={reviewCycleConfigurations}
						/>
					),
					reviewCyclesRoot
				);
			}

		};

		mountReactApps();
	}

}
