
import { Fragment } from 'react';

import { StatusRow } from '../../../components/status-row';
import { CompanyInfoForm } from '../../../components/company-info-form';
import { CompanyInfoHeader } from '../../../components/company-info-header';

import { COMPANY_APP_STATE } from '../../../utils/shared';
	
export function VerifiedStateComponent(props) {
	const {
		onSubmit,
		onInputChange,
		stateList,
		contacts,
		company,
		isProcessing,
		refreshData,
		revokePreviousContactPermission, 
		onRevokePreviousContactPermission, 
	} = props;

	return (
		<Fragment>
			<CompanyInfoHeader anotherEinLink={ true } />

			<StatusRow status={ COMPANY_APP_STATE.VERIFIED } />

			<CompanyInfoForm
				contactList={ contacts }
				data={ company }
				isProcessing={ isProcessing }
				onInputChange={ onInputChange }
				onSubmit={ onSubmit }
				showCompletedFormsSection={ !company.isLegacy }
				stateList={ stateList }
				refreshData={ refreshData }
				revokePreviousContactPermission={revokePreviousContactPermission}
				onRevokePreviousContactPermission={onRevokePreviousContactPermission}
			/>
		</Fragment>
	);
}
