import Ctrl from 'ctrl.deco';

@Ctrl('/settings/access_levels/all')
class SettingsAccessLevelsAllCtrl {

	columns = [
		{
			name: 'employeeName',
			title: $.__('Name'),
			type: 'employeeName',
		},
		{
			name: 'accessLevel',
			title: $.__('Level'),
		},
		{
			name: 'lastLogin',
			title: $.__('Last Login'),
			type: 'date',
		},
	];

	tmplData = {
		canAdd: false,
		canDuplicate: false,
		canDelete: false,
	};

	headerTmplData = {
		showAddBtn: false,
		showSettingsBtn: false,
	};
}
