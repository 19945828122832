import {
	ChangeEvent,
	useState,
} from 'react';

import { CheckboxGroup } from 'form-fields.react';

import { SectionTitle } from '../../../shared/section-title.react';
import { SafeHarborWarningModal } from '../../../shared/safe-harbor-warning-modal.react';
import { SAFE_HARBOR_ITEMS } from './constants';
import {
	Agreement,
	PlanState,
	SafeHarborValue,
} from '../../types';

import './safe-harbor-field.styl';
import { otherSafeHarborPlansExist } from './utils';

interface SafeHarborFieldProps {
	countOfSafeHarborPlans: number;
	groupId: string | number;
	isDisabled?: boolean;
	planState: PlanState;
	updateFieldValue: (newValue: SafeHarborValue) => void;
	value: SafeHarborValue;
}

export const SafeHarborField = (props: SafeHarborFieldProps): JSX.Element => {
	const {
		countOfSafeHarborPlans,
		groupId,
		isDisabled,
		planState,
		updateFieldValue,
		value,
	} = props;

	const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const newValue = event.target.checked ? Agreement.yes : Agreement.no;
		updateFieldValue(newValue);

		if (otherSafeHarborPlansExist(countOfSafeHarborPlans, planState)) {
			setIsWarningModalOpen(true);
		}
	};

	return (
		<div className="BftSafeHarborField">
			<SectionTitle text={ $.__('Company Contribution') } />
			<CheckboxGroup
				disabled={ isDisabled }
				id={ `safeHarbor-${ groupId }` }
				items={ SAFE_HARBOR_ITEMS }
				onChange={ handleChange }
				values={ [value] }
			/>
			<SafeHarborWarningModal
				closeModal={ (): void => setIsWarningModalOpen(false) }
				isModalOpen={ isWarningModalOpen }
			/>
		</div>
	);
};

