import { useState } from 'react';
import {
	TextButton,
	makeStyles,
} from '@bamboohr/fabric';

import { EmployeeObject } from '../types';
import { EnrolledEmployeesModal } from '../components';

const useStyles = makeStyles(({typography}) => ({
	deductionName: {
		fontSize: typography.fabricFontSize('small'),
		fontWeight: typography.fontWeightBold,
	},
	textButton: {
		height: 20,
	}
}));

interface NameAndEmployeesProps {
	deductionName: string;
	employees: EmployeeObject[];
	showDeductionName?: boolean;
}

export function NameAndEmployees(props: NameAndEmployeesProps): JSX.Element {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const styles = useStyles();
	const {
		deductionName,
		employees,
		showDeductionName = true,
	} = props;

	return (
		<>
			{ showDeductionName &&
				<p className={styles.deductionName}>{deductionName}</p>
			}
			<TextButton
				className={styles.textButton}
				muted={true}
				onClick={() => setModalOpen(true)}
				size="small"
				type="button"
			>
				{$.__n('%1$s Employee Enrolled', '%1$s Employees Enrolled', employees.length)}
			</TextButton>
			<EnrolledEmployeesModal
				deductionName={deductionName}
				employees={employees}
				isOpen={modalOpen}
				isSheetModal={false}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
}
