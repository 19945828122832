import {
	mapKeys,
	snakeCase
} from 'lodash';

// Note: This is to handle the BE sending us differently 
// cased list ids
export function convertDataToSnake(data) {
	return mapKeys(data, (value, key) => {
		return snakeCase(key);
	});
}
