import {
	Fragment,
} from 'react';

import {
	useReducerContext,
} from '../state';

import Row from '../row';
import PerformanceRow from '../performance-row';


export default function RowGroup(props) {
	const {
		activePage,
	} = useReducerContext();
	const {
		page,
		table,
	} = props;
	const group = page || table;
	const {
		id,
	} = group;
	const fields = Object.values(group.fields || {})
		.filter(({ id }) => !String(id).includes('.'));
	const isActive = !page || activePage === id;

	return (
		<Fragment>
			<Row
				isActive={ isActive }
				page={ page }
				table={ table }
			/>
			{ isActive && fields.map((field, i) => (
				<FieldRow
					index={ [i + 1, fields.length] }
					key={ field.field_id || field.id }
					row={ field }
					table={ table }
				/>
			)) }
		</Fragment>
	);
}

function FieldRow({
	index,
	row,
	table,
}) {
	if (row.type === 'table') {
		return (
			<RowGroup table={ row } />
		);
	}

	if (row.type === 'performance') {
		return (
			<PerformanceRow question={ row } />
		);
	}

	return (
		<Row
			field={ row }
			index={ index }
			parent={ table }
		/>
	);
}
