import { DeductionNameInput } from '../deduction-name-input';

import {
	NormalizedDeductions,
} from '../../utils';

import './deduction-name-cell.styl';

interface DeductionNameCellProps {
    deductions: NormalizedDeductions;
    setCurrentDeductions(newDeductions: NormalizedDeductions): void;
    setEditingId(id: number|null): void;
    isEditing: boolean;
    companyDeductionId: number;
    deductionName: string;
    isActive: boolean;
	managedDeductionType: string;
}

export function DeductionNameCell(props: DeductionNameCellProps): JSX.Element {
	const {
		isActive,
		isEditing,
		deductionName,
		deductions,
		setCurrentDeductions,
		companyDeductionId,
		setEditingId,
		managedDeductionType,
	} = props;

	if (isActive && isEditing) {
		return (
			<div className="DeductionNameCell DeductionNameCell--input">
				<DeductionNameInput
					companyDeductionId={ companyDeductionId }
					deductionName={ deductionName }
					deductions={ deductions }
					isEdit={ true }
					managedDeductionType={ managedDeductionType }
					setCurrentDeductions={ setCurrentDeductions }
					setEditingId={ setEditingId }
				/>
			</div>
		);
	}

	return (
		<div className="DeductionNameCell DeductionNameCell--name">
			<p>{ deductionName }</p>
		</div>
	);
}
