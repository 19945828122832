import { useState, useEffect, ReactElement } from 'react';
import { CurrencyField, CurrencyPickerProvider, DatePicker, Headline, LayoutBox, makeStyles, Select } from '@bamboohr/fabric';
import { CompensationInfoProps, EmployeeSelectField } from '../types';
import { getCurrencyFieldValue, getCurrencyItems } from '../utils';
import { FormSection } from './form-section';

export function CompensationInfo(props: CompensationInfoProps): ReactElement {
	const [showCompInfo, setShowCompInfo] = useState<boolean>(false);
	const [showOvertimeRate, setShowOvertimeRate] = useState<boolean>(false);
	const {
		fields: { compensationStartDate, payType, payRate, payPer, overtimeStatus, overtimeRate },
		missingFields: {
			compensationStartDate: compensationStartDateMissing,
			payType: payTypeMissing,
			payRate: payRateMissing,
			payPer: payPerMissing,
			overtimeStatus: overtimeStatusMissing,
			overtimeRate: overtimeRateMissing,
		},
		onChange,
	} = props;
	const { options: payTypeOptions, selected: payTypeSelected } = payType as EmployeeSelectField;
	const { options: payPerOptions, selected: payPerSelected } = payPer as EmployeeSelectField;
	const { options: overtimeStatusOptions, selected: overtimeStatusSelected } = overtimeStatus as EmployeeSelectField;

	useEffect(() => {
		setShowCompInfo(
			(compensationStartDateMissing as boolean) ||
				!!payTypeMissing ||
				(payRateMissing as boolean) ||
				!!payPerMissing ||
				!!overtimeStatusMissing ||
				((overtimeRateMissing as boolean) && showOvertimeRate)
		);
	}, [
		compensationStartDateMissing,
		payTypeMissing,
		payRateMissing,
		overtimeStatusMissing,
		payPerMissing,
		overtimeRateMissing,
		showOvertimeRate,
	]);
	useEffect(() => {
		setShowOvertimeRate(payTypeSelected === 'Salary' && overtimeStatusSelected === 'Non-exempt');
	}, [payTypeSelected, overtimeStatusSelected]);

	if (!showCompInfo) {
		return null;
	}

	const payRateCurrencyFieldValue = getCurrencyFieldValue(payRate ? (payRate as string) : '');
	const overtimeRateCurrencyFieldValue = getCurrencyFieldValue(overtimeRate ? (overtimeRate as string) : '');

	return (
		<CurrencyPickerProvider currencyCode='USD' decimalCharacter='.' getCurrencyItems={getCurrencyItems} thousandsSeparator=','>
			<FormSection title={$.__('Compensation')}>
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='compensation-info-start-date'>
							{$.__('Compensation Start Date')}
						</label>
						<DatePicker
							id='compensation-info-start-date'
							onChange={(params) => onChange('compensationStartDate', params.value || '', false)}
							value={compensationStartDate ? (compensationStartDate as string) : undefined}
						/>
					</div>
				</div>

				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='pay type'>
							{$.__('Pay Type')}
						</label>
						<Select
							isClearable={false}
							items={payTypeOptions}
							name='pay type'
							onSelect={(value: string) => onChange('payType', value || '', true)}
							selectedValues={[payTypeSelected]}
							width={7}
						/>
					</div>
				</div>

				<div className='fab-FormColumn'>
					<label className='fab-Label' htmlFor='payRateCurrencyField'>
						{$.__('Pay Rate')}
					</label>
					<div className='fab-FormRow'>
						<div className='fab-FormField'>
							<CurrencyField
								id='payRateCurrencyField'
								onChange={({ amount }) => onChange('payRate', amount, false)}
								value={payRateCurrencyFieldValue}
								size='medium'
							/>
						</div>
						<div className='fab-FormField'>{$.__('per', { note: 'As in "$15 per hour"' })}</div>
						<div className='fab-FormField'>
							<Select
								isClearable={false}
								items={payPerOptions}
								onSelect={(value: string) => onChange('payPer', value || '', true)}
								selectedValues={[payPerSelected]}
								width={5}
							/>
						</div>
					</div>
				</div>

				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='overtime status'>
							{$.__('Overtime Status')}
						</label>
						<Select
							isClearable={false}
							items={overtimeStatusOptions}
							name='overtime status'
							onSelect={(value: string) => onChange('overtimeStatus', value || '', true)}
							selectedValues={[overtimeStatusSelected]}
							width={7}
						/>
					</div>
				</div>

				{showOvertimeRate && (
					<div className='fab-FormRow'>
						<div className='fab-FormColumn'>
							<CurrencyField
								label={$.__('Overtime Rate')}
								onChange={({ amount }) => onChange('overtimeRate', amount, false)}
								value={overtimeRateCurrencyFieldValue}
							/>
						</div>
					</div>
				)}
			</FormSection>
		</CurrencyPickerProvider>
	);
}
