import {
	Fragment,
	useState,
	useEffect,
} from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	Button,
	Flex,
	TextButton,
} from '@fabric/button';
import Ajax from '@utils/ajax';
import { convertHTMLEntitiesInString } from 'String.util';
import {
	toQueryString,
} from '@utils/url/query';

import {
	useAjax,
} from '../../../shared';

import {
	useReducerContext,
} from '../state';
import {
	getTranslation,
} from '../utils';


export default function TranslationInputWrapper({
	onSubmit,
	onSave,
	...props
}) {
	const {
		processing,
		data: {
			rt = {},
		} = {},
		request,
		useSuccessEffect,
	} = useAjax();

	useSuccessEffect(() => {
		if (typeof onSave === 'function') {
			onSave(rt);
		}
	});

	return (
		<TranslationInput
			{ ...{
				...props,
				processing,
			} }
			onSubmit={ data => (
				typeof onSubmit === 'function'
					? onSubmit(data)
					: request({
						url: '/ajax/manage/translation/update.php',
						method: 'post',
						data: new URLSearchParams(toQueryString(data)),
						headers: { 'content-type': 'application/x-www-form-urlencoded' },
					})
			) }
		/>
	);
}

export function TranslationInput({
	item,
	onSubmit,
	onChange,
	size = 'medium',
	questionType,
	resourceTranslations,
}) {
	let {
		id,
	} = item;

	const context = useReducerContext();
	const {
		bem,
		fab,
	} = context;
	const resourceTranslation = getTranslation(item, context, questionType, resourceTranslations);
	const {
		translation = '',
		locked = false,
	} = resourceTranslation;
	const [currentValue, setCurrentValue] = useState(translation);
	const changed = (
		!!(translation || currentValue) &&
		currentValue !== translation
	);

	useEffect(() => {
		setCurrentValue(translation);
	}, [translation]);

	useEffect(() => {
		if (typeof onChange === 'function') {
			onChange(currentValue);
		}
	}, [currentValue]);

	return locked ? (
		<span>
			{ translation || $.__('No Translation') }
		</span>
	) : (
		<Flex gap={ifFeature('encore', 1)}>
			<input
				className={ bem('translationInput', null, { size }) }
				id={ `field_trans_${ id }` }
				onChange={ ({ target: { value } }) => setCurrentValue(value) }
				onKeyDown={ ({ key }) => key === 'Enter' && onSubmit({
					...resourceTranslation,
					translation: currentValue || '',
				}) }
				placeholder={ $.__('Enter Translation') }
				value={ convertHTMLEntitiesInString(currentValue) || '' }
			/>
			{ changed && (
				<Flex alignItems={ifFeature('encore', 'center')} gap={ifFeature('encore', 1)}>
					<Button
						clickAction={ () => onSubmit({
							...resourceTranslation,
							translation: currentValue || '',
						}) }
						size={ size }
						text={ $.__('Save') }
						type="button"
					/>
					<TextButton
						clickAction={ () => setCurrentValue(translation) }
						size={ size }
						text={ $.__('Cancel') }
						type="button"
					/>
				</Flex>
			) }
		</Flex>
	);
}
