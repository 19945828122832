import { ValidationError, ValidationResult } from '@bamboohr/utils/lib/validation';
import { SetSpecialErrors, SpecialErrors } from '../../types';
import { EmployeeCostTypeValue } from './wizard-values';

export enum PlanWizardRuleName {
	filterOverlap = 'filterOverlap',
}

export interface RefErrors {
	planName?: ValidationError;
	planSummary?: ValidationError;
	planDescription?: ValidationError;
	planUrl?: ValidationError;
	planRate?: ValidationError;
	deductionType?: ValidationError;
	reimbursementAmount?: ValidationError;
	acaMinValueStandard?: ValidationError;
	acaMinEssentialCoverage?: ValidationError;
	coverageLevels?: ValidationError;
	preTax?: ValidationError;
	reportableOnW2?: ValidationError;
	vendorId?: ValidationError;

	[key: string]: ValidationError;
}

export type RemoveSpecificError = (fieldName: keyof SpecialErrors, ruleName: string) => void;

export interface ValidationContextState extends ValidationResult {
	specialErrors: SpecialErrors;
	removeSpecificError: RemoveSpecificError;
	resetSpecialErrors: () => void;
	setSpecialErrors: SetSpecialErrors;
}

export interface CostRow {
	employeeCost: number | null;
	employeeCostType: EmployeeCostTypeValue;
	totalCost: number | null;
}
