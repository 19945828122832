import React from 'react';
import { LayoutBox, Loader } from '@bamboohr/fabric';

export function InvoiceHistoryLoader() {
	return (
		<LayoutBox marginY={9}>
			<Loader />
		</LayoutBox>
	);
}
