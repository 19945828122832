import React from 'react';

import {
	Select
} from '@fabric/select';

export default (props) => {
	const {
		hasPayGroupIntegrations,
		payGroup,
		paygroupTypesItems,
		payGroupTypesOnChange
	} = props;
	
	return hasPayGroupIntegrations ? (
		<div className="fab-FormRow">
			<div className="fab-FormColumn required">
				<label className="fab-Label fab-Label--required" htmlFor="type">
					{ $.__('Pay Frequency') }
				</label>
				<Select
					items={ paygroupTypesItems }
					name={ 'type' }
					onChange={ payGroupTypesOnChange }
					selectedValues={ payGroup }
					width={ 6 }
				/>
			</div>
		</div>
	) : null;
}
