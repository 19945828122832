import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette }) => ({
	popover: {
		maxWidth: '325px',
	},
	questionButton: {
		backgroundColor: 'unset',
		borderWidth: 0,
		cursor: 'pointer',
		display: 'flex',
		padding: 0,
	},
	questionIcon: {
		fill: palette.gray[600],
	},
}));
