import { render } from 'base/wrapped-render';

import Ctrl, {
	onPjaxEnd,
	onReady,
} from 'ctrl.deco';
import { getJsonData } from 'Data.util';

import { BenefitsSettingsConnector } from './benefits-settings-connector';

const renderSettingsBenefitsPlans = () => {
	const settingsBenefitsPlansRoot = document.getElementById('js-settingsBenefitsPlansRoot');
	const demoRequest = getJsonData('.js-benAdminUpsellDemoRequest');

	if (settingsBenefitsPlansRoot) {
		render(<BenefitsSettingsConnector demoRequestData={demoRequest} />, settingsBenefitsPlansRoot);
	}
};

@Ctrl('/settings/benefits/plans*')
class SettingsBenefitsPlans {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		document.title = $.__('Benefits');
		renderSettingsBenefitsPlans();
	}
}
