import Ajax from '@utils/ajax';
import { EmployeeWellbeingData } from './types';


export const createSurvey = (): Promise<EmployeeWellbeingData> => {
	return Ajax.post('/settings/employee_wellbeing/initialize').then(({data: { employeeWellbeingData }}) => employeeWellbeingData);
};

export const cancelSurvey = (): Promise<any> => {
	return Ajax.post('/settings/employee_wellbeing/cancelSurvey').then(({data: { employeeWellbeingData }}) => employeeWellbeingData);
};

export const requestDemo = (): Promise<any> => {
	return Ajax.put('/settings/employee_wellbeing/demo_request')
	.then(({data: { employeeWellbeingData }}) => {
		return employeeWellbeingData
	});
}