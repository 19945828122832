import React, { ReactElement } from 'react';
import { Button } from '@bamboohr/fabric';
import { CirclePlus14x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function HeaderAddEmployeeButton({ employeeModal }): ReactElement {
	return (
		<Button
			color='primary'
			data-bi-id='settings-access-levels-add-employee-btn'
			key='primary'
			onClick={() => employeeModal()}
			outline={true}
			size='small'
			startIcon={ifFeature('encore', 'circle-plus-regular', <CirclePlus14x14 />)}
			text={$.__('Add Employee')}
			type='button'
		/>
	);
}
