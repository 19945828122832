import {
	ChangeEvent,
	Fragment,
} from 'react';

import {
	NumberField,
	SelectField,
} from 'form-fields.react';

import {
	getTTERequirementItems,
	getTTEWaitPeriodItems,
	prepareNewTimeToEligibility,
} from './utils';
import { UpdateGroupProperty } from '../types';
import { useValidationContext } from '../../context';
import { hasEligibilityWaitPeriod } from '../../etc/has-eligibility-wait-period';
import { useReactivateDisabledField } from '../../hooks/use-reactivate-disabled-field';
import {
	TimeToEligibilityOptionsContract,
	TimeToEligibilityValues,
	TTERequirement,
	TTEWaitPeriod,
} from '../../types';

interface TimeToEligibilityProps {
	groupId: string | number;
	options: TimeToEligibilityOptionsContract;
	timeToEligibility: TimeToEligibilityValues;
	updateGroupProperty: UpdateGroupProperty;
}

export const TimeToEligibility = (props: TimeToEligibilityProps): JSX.Element => {
	const {
		groupId,
		options,
		timeToEligibility,
		updateGroupProperty,
	} = props;

	const {
		errors,
		register,
	} = useValidationContext();

	const setTimeToEligibility = (newTTE: TimeToEligibilityValues): void => {
		updateGroupProperty('timeToEligibility', newTTE);
	};

	const handleRequirementChange = (selectedValues: TTERequirement[]): void => {
		const newTimeToEligibility = prepareNewTimeToEligibility(timeToEligibility, selectedValues);
		setTimeToEligibility(newTimeToEligibility);
	};

	const handleWaitTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setTimeToEligibility({
			...timeToEligibility,
			waitTime: event.target.value,
		});
	};

	const handleWaitPeriodChange = (selectedValues: TTEWaitPeriod[]): void => {
		setTimeToEligibility({
			...timeToEligibility,
			waitPeriod: selectedValues.length ? selectedValues[0] : '',
		});
	};

	const tteRequirementId = `tteRequirement-${ groupId }`;
	const tteWaitTimeId = `tteWaitTime-${ groupId }`;
	const tteWaitPeriodId = `tteWaitPeriod-${ groupId }`;

	const requiredRule = { required: true };
	const tteWaitPeriodRules = { required: true, min: 0 };
	const isRequirementDisabled = useReactivateDisabledField(timeToEligibility.requirement, requiredRule.required);
	const isWaitTimeDisabled = useReactivateDisabledField(timeToEligibility.waitTime, tteWaitPeriodRules.required);
	const isWaitPeriodDisabled = useReactivateDisabledField(timeToEligibility.waitPeriod, false);

	return (
		<Fragment>
			<label
				className="fab-Label fab-Label--required"
				htmlFor={ tteRequirementId }
			>
				{ $.__('When do new employees become eligible?') }
			</label>
			<div className="fab-FormRow">
				<span className="fab-FormField">
					<SelectField
						disabled={ isRequirementDisabled }
						error={ errors[tteRequirementId] ? errors[tteRequirementId].message : '' }
						id={ tteRequirementId }
						inputRef={ register ? register(requiredRule) : null }
						isClearable={ true }
						items={ getTTERequirementItems(options.requirementOptions) }
						name={ tteRequirementId }
						onChange={ handleRequirementChange }
						required={ true }
						selectedValues={ [timeToEligibility.requirement] }
						width={ 8 }
					/>
				</span>
				{ hasEligibilityWaitPeriod(timeToEligibility.requirement) && (
					<Fragment>
						<span className="fab-FormField">
							<NumberField
								disabled={ isWaitTimeDisabled }
								error={ errors[tteWaitTimeId] ? errors[tteWaitTimeId].message : '' }
								id={ tteWaitTimeId }
								inputRef={ register ? register(tteWaitPeriodRules) : null }
								name={ tteWaitTimeId }
								onChange={ handleWaitTimeChange }
								value={ timeToEligibility.waitTime }
								width={ 1 }
							/>
						</span>
						<span className="fab-FormField">
							<SelectField
								disabled={ isWaitPeriodDisabled }
								error={ errors[tteWaitPeriodId] ? errors[tteWaitPeriodId].message : '' }
								id={ tteWaitPeriodId }
								inputRef={ register ? register(requiredRule) : null }
								isClearable={ true }
								items={ getTTEWaitPeriodItems(options.waitPeriods) }
								name={ tteWaitPeriodId }
								onChange={ handleWaitPeriodChange }
								required={ true }
								selectedValues={ [timeToEligibility.waitPeriod] }
								width={ 4 }
							/>
						</span>
						<span className="fab-FormField">
							{ $.__('after hire.') }
						</span>
					</Fragment>
				) }
			</div>
		</Fragment>
	);
};
