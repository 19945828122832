import moment from 'moment';

export function getMinDate(selectedStartDate?: string): string {
	const today = moment();
	const selectedDate = moment(selectedStartDate);
	return selectedDate.isBefore(today) ? today.toISOString() : selectedDate.toISOString();
}

export function getToday(): string {
	return moment().toISOString();
}
