import {
	Table,
} from '@bamboohr/fabric/';
import {
	GROUPS,
	addGroupToRows,
	getColumnsByType,
} from './overlap-detail-utils';
import {
	OverlapDetailProps,
	WageOverlapDateObject,
} from '../../utils/interfaces';

export function OverlapDetail(props: OverlapDetailProps): JSX.Element {
	const {
		isMappingTool,
		type,
		records,
		onDateChange,
	} = props;

	return (
		<Table
			caption={ $.__('overlapping dates') }
			columns={ getColumnsByType(type, onDateChange) }
			groups={ GROUPS }
			rowKey={ (row: WageOverlapDateObject) => row.id }
			rows={ isMappingTool ? addGroupToRows(records) : records }
		/>
	);
}
