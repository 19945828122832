import { CurrencyItem, VerticalWizardStep } from '@bamboohr/fabric';
import {
	AdditionalPlanUrlsContract,
	CostTypeEnumContract,
	DecisionDataContract,
	DeductionFrequencyEnumContract,
	DeductionTypeEnumContract,
	DemographicsContract,
	EligibilityGroupCalculatorSettings,
	LossOfEligibilityContract,
	MetalTier,
	OptionsContract,
	OrdinalPayDateEnumContract,
	PlanCategoryDataOptionsContract,
	PlanDependencyContract,
	PlanFilesContract,
	PlanRateTypeEnum,
	ReimbursementFrequencyEnumContract,
	SalaryBasedSpouseType,
	SimpleItem,
	TTERequirement,
	TTEWaitPeriod,
	Vendor,
} from '.';
import { CoverageTypes, PlanTypeEnumContract } from '../../types';
import { DeductionDirectionEnumContract } from '../../shared/deduction-migration-modal.react/types';

export enum PlanState {
	complete = 'COMPLETE',
	draft = 'DRAFT',
	duplicate = 'DUPLICATE',
	recovery = 'RECOVERY',
	revision = 'REVISION',
	new = 'NEW',
}

export type DeductionTypeValues = {
	[key: string]: DeductionTypeEnumContract;
};

export type DeductionTypeValue = DeductionTypeEnumContract | '';

export type PreTaxDeductionTypeValue =
	| DeductionTypeEnumContract.preTaxSubtype
	| DeductionTypeEnumContract.preTaxGeneralHealthcare
	| DeductionTypeEnumContract.hospitalIndemnityPreTax
	| DeductionTypeEnumContract.postTax
	| '';

export type BooleanSelectValue = boolean | '';

export enum AutoEnrollTypeEnum {
	disabled = 'DISABLED',
	enabledOptional = 'ENABLED_OPTIONAL',
	enabledRequired = 'ENABLED_REQUIRED',
}

export enum AutoEnrollOptionEnum {
	disabled = 'disabled',
	enabled = 'enabled',
}

export enum RateEnum {
	Standard,
	Variable,
}

export enum LifeInsuranceTypeEnum {
	basic = 'basic',
	voluntary = 'voluntary',
}

export enum LifeOrADandDEnum {
	life = 'life',
	add = 'add',
	both = 'both',
}

export enum LifeInsuranceCoverageOptions {
	childCovered = 'lifeInsuranceChildCoverage',
	spouseCovered = 'lifeInsuranceSpouseCoverage',
}

export enum PlanDependencyEnum {
	planDependent = 'dependent',
	planIndependent = 'independent',
}

export type RateValue = RateEnum | '';

export enum StepName {
	PlanDetails,
	CoverageOptions,
	EligibilityAndCost,
	EnrollmentDetails,
	PayrollDeduction,
}

export enum IsLegalTextIncludedEnum {
	true = 'legalTextIsIncluded',
	false = 'legalTextIsNotIncluded',
}

export interface WizardStep extends VerticalWizardStep {
	id: number;
}

export interface CompletedStep {
	complete: boolean;
	disabled: boolean;
	status?: string;
}

export interface CompletedSteps {
	[key: number]: CompletedStep;
}

export interface CoverageLevelEnrollments {
	[key: string]: number[];
}

export interface PlanEnrollments {
	hasFuture: boolean;
	hasPast: boolean;
	hasCurrent: boolean;
	currentEmployeeIds: number[];
	futureEmployeeIds: number[];
	perCoverageLevel: CoverageLevelEnrollments;
}

export { Currency } from '../../types';

export enum FilterType {
	employmentStatus = 'employmentStatus',
	department = 'department',
	division = 'division',
	location = 'location',
	payType = 'payType',
	jobTitle = 'jobTitle',
}

export type FilterValue = number | string;

export type FilterSelections = {
	[key in FilterType]: FilterValue[];
};

export interface FilterGroup {
	isAutoGenerated: boolean;
	order: FilterType[];
	values: FilterSelections;
}
export interface PlanWizardDataPlan {
	id?: number;
	state: PlanState;
	type: PlanTypeEnumContract;
	enrollments: PlanEnrollments;
	initialStartDate: string;
	planCategoryDataOptions?: PlanCategoryDataOptionsContract;
	rateType: PlanRateTypeEnum | null;
}

export interface PlanWizardData {
	demographics: DemographicsContract | null;
	plan: PlanWizardDataPlan;
	metalTiers: MetalTier[];
	planTypes: SimpleItem[];
	options: OptionsContract;
	decisionData: DecisionDataContract;
	vendors: Vendor[];
	supportedCoverages: CoverageTypes;
}

export interface CoverageLevelValue {
	id?: number;
	linkId: string;
	isDefault: boolean;
	isSelected: boolean;
	name: string;
	description: string;
	sortOrder?: number;
	totalCost: number | null;
}

export interface EligibilityFilterValues {
	selectedEmployeeIds: number[];
	areSpecificPeopleSelected: boolean;
	filterGroup: FilterGroup;
}

export interface TimeToEligibilityValues {
	requirement: TTERequirement | '';
	waitTime: string;
	waitPeriod: TTEWaitPeriod | '';
}

export interface CoverageCostValues {
	coverageLevelLinkId: string;
	employeeCost: number | null;
}

export interface ClassCodeDetails {
	code: string;
	description: string;
	name: string;
}

export interface EligibilityGroupValues {
	id: string | number;
	eligibilityFilters: EligibilityFilterValues;
	lossOfEligibility: LossOfEligibilityContract;
	timeToEligibility: TimeToEligibilityValues;
	coverageCosts: CoverageCostValues[];
	calculatorSettings?: EligibilityGroupCalculatorSettings;
	class: ClassCodeDetails;
}

export enum Agreement {
	no = 'no',
	yes = 'yes',
}

export type ReportableOnW2Value = Agreement | '';

export type DeductionEveryPaycheckValue = Agreement;

export type OrdinalPayDateValue = OrdinalPayDateEnumContract | '';

export interface OrdinalPayDatesInPeriodValue {
	first: OrdinalPayDateValue;
	second: OrdinalPayDateValue;
}

export interface PerPayScheduleDeductionValue {
	frequency: DeductionFrequencyEnumContract;
	ordinalPayDatesInPeriod: OrdinalPayDatesInPeriodValue;
}

export interface DeductionOccurrenceValue {
	isEveryPaycheck: DeductionEveryPaycheckValue;
	perPaySchedule: {
		[key: number]: PerPayScheduleDeductionValue;
	};
}

export type SafeHarborValue = Agreement | '';

export interface DeductionOffsetValue {
	number: number;
	direction: DeductionDirectionEnumContract;
}

export interface ReimbursementValue {
	currencyCode: string;
	amount: number | null;
	frequency: ReimbursementFrequencyEnumContract;
}

export type EmployeeCostTypeValue = CostTypeEnumContract;

export interface LifeCoverageOptions {
	spouseCoverage: boolean;
	childCoverage: boolean;
	type: string;
	employeeAmount: number | null;
	employeeIncrement: number | null;
	employeeMinimumAmount: number | null;
	employeeMaximumAmount: number | null;
	employeeMultiplier: number | null;
	spouseAmount: number | null;
	spouseAmountType: SalaryBasedSpouseType | null;
	spouseIncrement: number | null;
	spouseMinimumAmount: number | null;
	spouseMaximumAmount: number | null;
	spousePercentOfEmployeeCoverage: number | null;
	childAmount: number | null;
	childIncrement: number | null;
	childMinimumAmount: number | null;
	childMaximumAmount: number | null;
}

export interface WizardValues {
	acaMetalTier: string;
	acaMinEssentialCoverage: BooleanSelectValue;
	acaMinValueStandard: BooleanSelectValue;
	additionalPlanUrls: AdditionalPlanUrlsContract[];
	autoEnroll: AutoEnrollOptionEnum | null;
	autoEnrollRequired: boolean;
	carrierPlanId: string | null;
	coverageLevels: CoverageLevelValue[];
	coverageOptions: LifeCoverageOptions | null;
	coverageRelationships?: string[];
	currencies: CurrencyItem[];
	currencyCode: string;
	deductionOccurrence: DeductionOccurrenceValue;
	deductionOffset?: DeductionOffsetValue;
	deductionType: DeductionTypeValue;
	deletedPlanFileIds: string[];
	dependentRequiredQuestionIds: string[];
	description: string;
	duplicatedPlanFileIds: string[];
	eligibilityGroups: EligibilityGroupValues[];
	employeeCostType: EmployeeCostTypeValue;
	employeeRequiredQuestionIds: string[];
	endYmd: string;
	fixedCurrency: number | null;
	fixedCurrencyType: CurrencyItem;
	groupNumber: string | null;
	isLegalTextIncluded: string;
	legalText: string | null;
	lifeInsuranceType: LifeInsuranceTypeEnum | null;
	lifeOrAdd: LifeOrADandDEnum | null;
	medicalPlanDependency: PlanDependencyEnum | null;
	medicalPlanDependencyOptions: PlanDependencyContract[] | null;
	name: string;
	planCategoryDataOptions: PlanCategoryDataOptionsContract;
	planFiles: PlanFilesContract[];
	preTaxDeductionType: PreTaxDeductionTypeValue;
	rate: RateValue;
	rateType: PlanRateTypeEnum | null;
	reimbursement?: ReimbursementValue;
	reportableOnW2: ReportableOnW2Value;
	startYmd: string;
	summary?: string;
	temporaryPlanFileIds: string[];
	url: string;
	usesSafeHarbor: SafeHarborValue;
	vendorId: string;
}

export type UpdateFieldValue = (key: keyof WizardValues, value: WizardValues[keyof WizardValues]) => void;

export interface TextFieldProps {
	updateFieldValue: UpdateFieldValue;
	value: string;
}

export enum PlanModals {
	none,
	endDateWarning,
	removeFutureEnrollments,
	newDeductionAmounts,
	reactivateConfirmation,
}

export interface AcceptedModals {
	[PlanModals.endDateWarning]: boolean;
	[PlanModals.removeFutureEnrollments]: boolean;
	[PlanModals.newDeductionAmounts]: boolean;
}
