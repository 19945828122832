import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	EmployeeTaxMetadataFederalTaxWitholding,
	EmployeeTaxMetadataFederalTaxWithholdingKey,
} from '../../utils/interfaces';

export const KEY_TO_MESSAGE_TEXT_MAP: {
	[key in EmployeeTaxMetadataFederalTaxWithholdingKey]: string;
} = {
	additional_withholding_amount: $._('Extra Withholding: {1}'),
	deductions: $._('Deductions Amount: {1}'),
	dependents_amount: $._('Dependents Amount: {1}'),
	exemptions: $._('Exemptions: {1}'),
	federal_fica_is_exempt: $._('FICA Exempt: {1}'),
	federal_filing_status: $._('Filing Status: {1}'),
	federal_futa_is_exempt: $._('FUTA Exempt: {1}'),
	federal_tax_exempt: $._('Ignore Filing Status / Other Adjustments: {1}'),
	multiple_jobs: $._('Works two jobs or spouse works: {1}'),
	other_income: $._('Other Income: {1}'),
};

export const TITLE_KEYS: Array<EmployeeTaxMetadataFederalTaxWithholdingKey> = ['federal_filing_status'];

export function getDetail(key: string, metadata: EmployeeTaxMetadataFederalTaxWitholding): React.ReactNode {
	const messageText = getDetailMessageText(key);
	const displayValue = getDisplayValue(metadata[key]);

	if (messageText) {
		return (
			<Message
				params={ [displayValue] }
				text={ messageText }
			/>
		);
	}

	return `${ key }: ${ displayValue }`;
}

export function getDetailKeys(metadata: EmployeeTaxMetadataFederalTaxWitholding): Array<EmployeeTaxMetadataFederalTaxWithholdingKey> {
	return (Object.keys(metadata) as Array<EmployeeTaxMetadataFederalTaxWithholdingKey>).filter(key => !TITLE_KEYS.includes(key));
}

export function getDetailMessageText(key: string): string | undefined {
	return KEY_TO_MESSAGE_TEXT_MAP[key];
}

export function getDisplayValue(value: string | number): string | number {
	if (value === undefined || value === null) {
		return '--';
	}

	return value;
}

export function getTitle(metadata: EmployeeTaxMetadataFederalTaxWitholding): React.ReactNode {
	return (
		<Message
			params={ [...TITLE_KEYS.map(key => getDisplayValue(metadata[key]))] }
			text={ $._('Filing Status: {1}') }
		/>
	);
}
