import { last } from 'lodash';

import {
	ALL_FILTER_TYPES,
	DEMOGRAPHICS_FILTER_KEYS,
	EMPLOYEE_FILTER_TYPE_KEYS,
	FILTER_TYPE_LABELS,
	PAY_TYPE_LABELS,
	SPECIFIC_PEOPLE_VALUE,
} from '../constants';
import {
	DemographicsContract,
	EligibilityFilterValues,
	EmployeeList,
	FilterGroup,
	FilterSelections,
	FilterType,
	FilterValue,
	PayTypeEnumContract,
} from '../../../types';
import { EmployeesInGroups } from '../../types';
import { ChildItem, EmployeeForPicker, ListEmployee, PickerFilter, PrimaryItem } from '../types';

export const getDisabledEmployeeIdsForPicker = (hasFilterEditRestrictions: boolean, initialSelections: number[]): number[] => {
	return hasFilterEditRestrictions ? initialSelections : [];
};

export const getDisabledFilterValues = (
	hasFilterEditRestrictions: boolean,
	initialFilters: EligibilityFilterValues,
	filterType: FilterType
): FilterValue[] => {
	return hasFilterEditRestrictions ? initialFilters.filterGroup.values[filterType] : [];
};

export const generateToggleText = (selectedItems: ChildItem[], filterType: FilterType): string => {
	if (selectedItems.length === 1) {
		return last(selectedItems).text;
	}

	return `${selectedItems.length} ${FILTER_TYPE_LABELS[filterType].pluralText}`;
};

export const getPayTypeLabel = (payType: PayTypeEnumContract): string => {
	if (PAY_TYPE_LABELS[payType]) {
		return PAY_TYPE_LABELS[payType];
	}

	return payType;
};

export const generateChildItems = (
	demographics: DemographicsContract,
	filterType: FilterType,
	disabledSelections: FilterValue[]
): ChildItem[] => {
	if (!demographics) {
		return [];
	}

	const filterItems = [];

	if (filterType === FilterType.payType) {
		demographics.payTypes.forEach((payType) => {
			filterItems.push({
				text: getPayTypeLabel(payType),
				value: payType,
				isDisabled: disabledSelections.includes(payType),
			});
		});
	} else {
		const { pluralValueKey, pluralKey } = DEMOGRAPHICS_FILTER_KEYS[filterType];

		demographics[pluralValueKey].forEach((id) => {
			if (demographics[pluralKey][id]) {
				filterItems.push({
					text: demographics[pluralKey][id].name,
					value: id,
					isDisabled: disabledSelections.includes(id),
				});
			}
		});
	}

	return filterItems;
};

export const isFilterTypeInActiveMenu = (filterType: FilterType, menuFilterTypeSelections: FilterType[]): boolean => {
	return menuFilterTypeSelections.includes(filterType);
};

export const hasSelectionsInFilterType = (filterType: FilterType, filterSelections: FilterSelections): boolean => {
	return filterSelections[filterType].length > 0;
};

const hasSecondaryFilterTypesAvailable = (filters: FilterGroup): boolean => {
	return filters.order.length > 0 && filters.order.length < Object.keys(ALL_FILTER_TYPES).length;
};

export const hasSelectionsInActiveMenu = (menuFilterTypeSelections: FilterType[]): boolean => {
	return menuFilterTypeSelections.length > 0;
};

export const secondaryFilterTypesAreSelectable = (filters: FilterGroup, hasEditRestrictions: boolean): boolean => {
	return !hasEditRestrictions && hasSecondaryFilterTypesAvailable(filters);
};

export const areAllSecondaryFiltersEmpty = (filters: FilterGroup): boolean => {
	return filters.order.length === 1;
};

export const areAllFiltersEmpty = (filterGroup: FilterGroup): boolean => {
	return filterGroup.order.length === 0;
};

export const areAllSecondaryFiltersInActiveMenu = (filters: FilterGroup, menuFilterTypeSelections: FilterType[]): boolean => {
	return menuFilterTypeSelections.length + 1 === filters.order.length;
};

export const generatePrimaryFilterItems = (
	demographics: DemographicsContract | null | undefined,
	hasEditRestrictions: boolean,
	initialFilters: EligibilityFilterValues
): PrimaryItem[] => {
	if (!demographics) {
		return [];
	}

	let disabledStatuses: FilterValue[] = [];
	if (hasEditRestrictions) {
		if (initialFilters.areSpecificPeopleSelected) {
			disabledStatuses = demographics.employmentStatusIds;
		} else {
			disabledStatuses = getDisabledFilterValues(hasEditRestrictions, initialFilters, FilterType.employmentStatus);
		}
	}

	return [
		{
			items: generateChildItems(demographics, FilterType.employmentStatus, disabledStatuses),
			key: FilterType.employmentStatus,
			type: 'group',
		},
		{
			isActionOnly: true,
			items: [
				{
					isDisabled: hasEditRestrictions,
					text: $.__('Specific people'),
					value: SPECIFIC_PEOPLE_VALUE,
				},
			],
			key: 'specific-people-group',
			type: 'group',
		},
	];
};

export const getEmployeesForList = (employeeIds: number[], allEmployees: EmployeeList): ListEmployee[] => {
	const modalEmployees = [];

	employeeIds.forEach((employeeId) => {
		if (allEmployees[employeeId]) {
			modalEmployees.push({
				name: `${allEmployees[employeeId].firstName} ${allEmployees[employeeId].lastName}`,
				imageUrl: allEmployees[employeeId].photoUrl,
			});
		}
	});

	return modalEmployees;
};

export const getEmployeesForPicker = (
	employeeIds: number[],
	employees: EmployeeList,
	employeesInGroups: EmployeesInGroups,
	selectedEmployeeIds: number[],
	disabledEmployeeIds: number[]
): EmployeeForPicker[] => {
	const pickerEmployees: EmployeeForPicker[] = [];

	employeeIds.forEach((employeeId) => {
		const employee = employees[employeeId];
		if (employee) {
			pickerEmployees.push({
				...employee,
				displayName: `${employees[employeeId].firstName} ${employees[employeeId].lastName}`,
				groupName: employeesInGroups[employeeId] ? employeesInGroups[employeeId].groupName : '',
				lastNameFirstName: `${employees[employeeId].lastName} ${employees[employeeId].firstName}`,
				selected: selectedEmployeeIds.includes(employeeId),
				disabled: disabledEmployeeIds.includes(employeeId),
			});
		}
	});

	return pickerEmployees;
};

export const getPickerFilters = (demographics: DemographicsContract): PickerFilter[] => {
	const filters = [];

	ALL_FILTER_TYPES.forEach((filterType) => {
		const { pluralKey, pluralValueKey } = DEMOGRAPHICS_FILTER_KEYS[filterType];
		const { singularText } = FILTER_TYPE_LABELS[filterType];

		if (demographics && demographics[pluralKey]) {
			const options = {};

			if (filterType === FilterType.payType) {
				demographics.payTypes.forEach((payType) => {
					options[payType] = payType;
				});
			} else if (pluralValueKey && demographics[pluralValueKey]) {
				demographics[pluralValueKey].forEach((id) => {
					if (demographics[pluralKey][id]) {
						options[id] = demographics[pluralKey][id].name;
					}
				});
			}

			filters.push({
				name: singularText,
				key: EMPLOYEE_FILTER_TYPE_KEYS[filterType],
				options,
			});
		}
	});

	return filters;
};

export const canSkipFilterDiscardWarning = (filterGroup: FilterGroup): boolean => {
	return areAllFiltersEmpty(filterGroup) || filterGroup.isAutoGenerated;
};
