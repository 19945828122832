import { Box, Table } from '@bamboohr/fabric';

import { CompanyPaysCell } from './company-pays-cell';
import { MonthlyRateMultiplier } from './monthly-rate-multiplier';
import {
	DisabilityCategoryDataOptionsContract,
	EligibilityGroupCalculatorSettings,
	EligibilityGroupValues,
	PlanCategoryDataOptionsContract,
	WizardValues,
} from '../../../../../types';

interface MonthlyRateTableProps {
	eligibilityGroup: EligibilityGroupValues;
	groupIndex: number;
	planCategoryDataOptions: PlanCategoryDataOptionsContract;
	updateGroupProperty: (
		groupIndex: number,
		key: keyof EligibilityGroupValues,
		calculatorSettings: EligibilityGroupCalculatorSettings
	) => void;
	wizardValues: WizardValues;
}
interface EligibilityGroupCalculatorSettingsRow {
	companyPays: number | null;
	employeePays: string;
	rate: number | null;
}

export const MonthlyRateTable = (props: MonthlyRateTableProps): JSX.Element => {
	const { eligibilityGroup, groupIndex, planCategoryDataOptions, updateGroupProperty } = props;
	const { disabilityType } = planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
	const calculatorSettings = { ...eligibilityGroup.calculatorSettings };

	const columns = [
		{
			cell: () => {
				return (
					<MonthlyRateMultiplier
						disabilityType={disabilityType}
						eligibilityGroup={eligibilityGroup}
						groupIndex={groupIndex}
						updateGroupProperty={updateGroupProperty}
					/>
				);
			},
			header: $.__('Monthly Rate'),
			key: 'monthlyRate',
		},
		{
			cell: () => {
				return (
					<CompanyPaysCell eligibilityGroup={eligibilityGroup} groupIndex={groupIndex} updateGroupProperty={updateGroupProperty} />
				);
			},
			header: $.__('Company Pays'),
			key: 'companyPays',
		},
		{
			cell: (row: EligibilityGroupCalculatorSettingsRow) => {
				return <Box>{row.employeePays}</Box>;
			},
			header: $.__('Employee Pays'),
			key: 'employeePays',
			verticalAlign: true,
		},
	];

	const getEmployeePaysValue = (companyPays: number | null): string => {
		if (companyPays !== null && companyPays >= 0 && companyPays <= 100) {
			const employeePays = (100 - companyPays).toFixed(2);
			return $.__('%1%% of total cost', employeePays.toString());
		}
		return '-';
	};

	const generateRows = () => {
		const employeePays = getEmployeePaysValue(calculatorSettings.companyPays);
		return [{ companyPays: calculatorSettings.companyPays, employeePays, rate: calculatorSettings.rate }];
	};

	return <Table caption={$.__('How much will they pay?')} columns={columns} rows={generateRows()} />;
};
