import type {
	AjaxStatic,
} from '@utils/ajax';

import {
	jwtInteceptor,
} from './jwt-interceptor';

export function injectJwtInterceptor(ajaxInstance: AjaxStatic['instance']): void {
	ajaxInstance.interceptors.request.use(jwtInteceptor);
}
