import moment from 'moment.lib';

export default (data, invoices) => {
	const csv = encodeURI(`data:text/csv;charset=utf-8, ${data}`);

	const companyName = window.SESSION_COMPANY?.name || 'billing';

	let suffix = '';

	if (invoices.length === 1) {
		suffix = `_${moment(invoices[0].billingDate).utc().format('YYYY-MM-DD')}`;
	}

	const link = document.createElement('a');
	link.setAttribute('href', csv);
	link.setAttribute('download', `${companyName}_Invoice${suffix}.csv`);
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
