import {
	Flex,
	StandardModal,
} from '@bamboohr/fabric';

import {
	Federal1099PrintConnector,
} from './federal-1099-print-connector';
import {
	FederalW2Box12DdConnector,
} from './federal-w2-box-12dd-connector';
import {
	L_FEDERAL_SECTION_LABEL,
} from '../language';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function FederalConnector() {
	const [{
		isLoadingClientTaxSettings,
	}] = useCompanyTaxSettingsStore();
	if (isLoadingClientTaxSettings) {
		return null;
	}
	return (
		<Flex
			flexDirection="column"
			gap={ 2 }
		>
			<StandardModal.StandardHeadline
				text={ L_FEDERAL_SECTION_LABEL }
			/>
			<FederalW2Box12DdConnector />
			<Federal1099PrintConnector />
		</Flex>
	);
}
