import {
	FederalW2Box12Dd,
} from './federal-w2-box-12dd';
import {
	findW2Box12DdOption,
} from '../domain/find-w2-box-12dd-option';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function FederalW2Box12DdConnector() {
	const [{
		clientTaxSettings,
	}, {
		setClientTaxSetting,
	}] = useCompanyTaxSettingsStore();
	const w2Box12Dd = findW2Box12DdOption(clientTaxSettings);
	if (!w2Box12Dd) {
		return null;
	}
	const {
		metadata: {
			employeeCount,
		},
		value: suppressCoverage,
	} = w2Box12Dd;
	const setSuppressCoverage = setClientTaxSetting;
	return (
		<FederalW2Box12Dd
			employeeCount={ employeeCount }
			setSuppressCoverage={ (coverage) => {
				setSuppressCoverage({
					...w2Box12Dd,
					value: coverage,
				});
			} }
			suppressCoverage={ suppressCoverage }
		/>
	);
}
