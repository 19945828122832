import Ajax from '@utils/ajax';

export const deactivateReviewCycleConfiguration = (id: number): Promise<any> => {
	const payload = {
		reviewCycleConfigurationId: id,
	};

	return new Promise((resolve, reject) => {
		Ajax.put('/settings/performance/home/reviewCycles/deactivate', payload)
			.then((response) => {
				const { data = {} } = response;

				if (data && data.success) {
					resolve(data);
				}

				reject(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
