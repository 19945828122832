import classNames from 'classnames';
import { cloneDeep, isEqual, uniqueId, isEmpty } from 'lodash';
import { Component, createRef, Fragment } from 'react';

import { Button, TextButton } from '@fabric/button';
import { Select } from '@fabric/select';

import { WorkflowLevel } from '../workflow-level';
import { LEVEL_ID_PREFIX, MAX_LEVELS, ROLES_ORDER, SPECIFIC_PERSON, USER_GROUP } from '../../utils/constants';
import { Divider, StyledBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// todo refactor and clean up
export class ApprovalForm extends Component {
	constructor(props) {
		super(props);

		const {
			availableUsers,
			approverOptions,
			canSaveInitial = false,
			id,
			pathType = null,
			requestOptions,
			userGroups,
			workflowApprovals,
			workflowInitiators
		} = props;

		let levels = workflowApprovals.map((approval, index) => {
			return {
				approval,
				workflowLevel: index + 1
			};
		});

		const {
			availableGroups,
			availablePeople,
			availableRoles
		} = this._generateAvailableSelections({
			levels,
			approverOptions,
			availableUsers,
			userGroups
		});

		levels = levels.map((level) => {
			const newLevelData = this._generateNewLevelDropdownData({
				availableGroups,
				availablePeople,
				availableRoles,
				level,
				approverOptions,
				availableUsers,
				userGroups
			});

			return {
				...level,
				...newLevelData
			};
		});

		const customRolesDropdownData = this._generateCustomRolesDropdownData({
			requestOptions,
			workflowInitiators,
			userGroups
		});

		this.state = {
			approverOptions,
			availableRoles,
			availableGroups,
			availablePeople,
			availableUsers,
			canCancel: pathType !== null,
			canSave: canSaveInitial,
			customApprovers: workflowInitiators,
			customRolesDropdownData,
			id,
			levels,
			requestOptions,
			userGroups
		};

		this._originalState = cloneDeep(this.state);
	}

	_generateAvailableSelections({ levels, approverOptions, availableUsers, userGroups }) {
		const {
			selectedGroupIds,
			selectedPeople,
			selectedRoles
		} = levels.reduce((selections, level) => {
			// groups
			(level.approval.approvalGroupIds || []).forEach((approvalGroupId) => {
				selections.selectedGroupIds.push(approvalGroupId);
			});
			// people
			if (level.approval.specificPersonUserId) {
				selections.selectedPeople.push(level.approval.specificPersonUserId);
			}
			// approverOptions
			selections.selectedRoles.push(level.approval.approvalBy);
			return selections;
		}, {
			selectedGroupIds: [],
			selectedPeople: [],
			selectedRoles: []
		});

		const availableGroups = userGroups.filter((userGroup) => {
			return !selectedGroupIds.includes(userGroup.id);
		});

		const availablePeople = availableUsers.filter((availablePerson) => {
			return !selectedPeople.includes(availablePerson.id);
		});

		const availableRoles = approverOptions.filter((role) => {
			if (role.id === USER_GROUP) {
				return userGroups.length !== selectedGroupIds.length;
			}
			if (role.id === SPECIFIC_PERSON) {
				return availableUsers.length !== selectedPeople.length;
			}
			return !selectedRoles.includes(role.id);
		});

		return {
			availableGroups,
			availablePeople,
			availableRoles
		};
	}

	_generateCustomRolesDropdownData({ requestOptions, workflowInitiators, userGroups }) {
		let selectedValues = [];
		const customRolesDropdownData = {
			items: requestOptions.map(({ id, name }) => {
				if ((workflowInitiators.indexOf(id) > -1)) {
					selectedValues.push(id);
				}
				return {
					displayText: name,
					selected: (workflowInitiators.indexOf(id) > -1),
					text: name,
					value: id
				};
			})
		};
		const userGroupsDropdownData = userGroups.map(({ id, name }) => {
			if ((workflowInitiators.indexOf(id) > -1)) {
				selectedValues.push(id);
			}
			return {
				displayText: name,
				selected: (workflowInitiators.indexOf(id) > -1),
				text: name,
				value: id
			};
		});

		if (userGroups.length > 0) {
			customRolesDropdownData.items.push({
				className: 'multipleSelect--seperator',
				displayText: $.__('Access Levels'),
				items: userGroupsDropdownData,
				text: $.__('Access Levels'),
				type: 'group'
			});
		}

		customRolesDropdownData.selectedValues = selectedValues;

		return customRolesDropdownData;
	}

	_generateNewLevelDropdownData({ availableGroups, availablePeople, availableRoles, level, approverOptions, availableUsers, userGroups }) {
		const {
			approval: levelApproval
		} = level;

		// groups
		let levelGroups = availableGroups.slice(0);
		const selectedGroupIds = userGroups.filter((userGroup) => {
			return (levelApproval.approvalGroupIds || []).includes(userGroup.id);
		});
		if (selectedGroupIds) {
			levelGroups = levelGroups.concat(selectedGroupIds);
		}
		levelGroups.sort((a, b) => {
			if (a.id < b.id) {
				return -1;
			}
			if (a.id > b.id) {
				return 1;
			}
			return 0;
		});
		// people
		const levelPeople = availablePeople.slice(0);
		const selectedPerson = availableUsers.find((availablePerson) => {
			return availablePerson.id === levelApproval.specificPersonUserId;
		});
		if (selectedPerson) {
			levelPeople.push(selectedPerson);
		}
		levelPeople.sort((a,b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		// approverOptions
		let levelRoles = availableRoles.slice(0);
		const selectedRole = approverOptions.find((role) => {
			return role.id === levelApproval.approvalBy;
		});
		if (selectedRole) {
			if (!levelRoles.includes(selectedRole)) {
				levelRoles.push(selectedRole);
			}
		}
		levelRoles = levelRoles.sort((a, b) => {
			return ROLES_ORDER.indexOf(a.id) - ROLES_ORDER.indexOf(b.id);
		});

		level.approverOptions = levelRoles;
		level.availableUsers = levelPeople;
		level.userGroups = levelGroups;

		return level;
	}

	_handleAddNewLevel() {
		const {
			updateLevels
		} = this.props;

		this.setState((prevState) => {
			const newLevel = prevState.levels.length + 1;
			const newLevels = prevState.levels.concat([{
				approval: {
					approvalBy: '',
					id: uniqueId(LEVEL_ID_PREFIX),
					position: `${ newLevel }`,
					workflowId: prevState.id
				},
				workflowLevel: newLevel,
				approverOptions: prevState.availableRoles,
				availableUsers: prevState.availablePeople,
				userGroups: prevState.availableGroups
			}]);
			updateLevels(newLevels);
			return {
				levels: newLevels
			};
		});
	}

	_handleAlteredState({ approval: alteredApproval } = {}) {
		const {
			updateLevels
		} = this.props;

		this._pristineForm = false;

		this.setState((prevState) => {
			let newLevels = prevState.levels.map((stateLevel) => {
				const {
					approval: stateApproval
				} = stateLevel;

				const workingApproval = (alteredApproval && alteredApproval.id === stateApproval.id ? alteredApproval : stateApproval);

				return {
					...stateLevel,
					approval: workingApproval
				};
			});

			const {
				approverOptions: prevRoles,
				availableUsers: prevSpecificPeople,
				userGroups: prevUserGroups
			} = prevState;

			const {
				availableGroups,
				availablePeople,
				availableRoles
			} = this._generateAvailableSelections({
				levels: newLevels,
				approverOptions: prevRoles,
				availableUsers: prevSpecificPeople,
				userGroups: prevUserGroups
			});

			newLevels.forEach((newLevel) => {
				newLevel = this._generateNewLevelDropdownData({
					availableGroups,
					availablePeople,
					availableRoles,
					approverOptions: prevRoles,
					availableUsers: prevSpecificPeople,
					level: newLevel,
					userGroups: prevUserGroups
				});
			});

			updateLevels(newLevels);

			return {
				availableGroups,
				availablePeople,
				availableRoles,
				levels: newLevels
			};
		});
	}

	_handleCustomApprovalChange(item) {
		const {
			updateCustomApprovers
		} = this.props;

		const {
			customApprovers
		} = this.state;
		let newApprovers = customApprovers.slice(0);

		updateCustomApprovers(item);
		this._pristineForm = false;

		this.setState((prevState) => {
			return {
				customApprovers: item,
				customRolesDropdownData: this._generateCustomRolesDropdownData({
					requestOptions: prevState.requestOptions,
					workflowInitiators: item,
					userGroups: prevState.userGroups
				})
			};
		});
	}

	_handleFormSubmit(event) {
		event.preventDefault();

		const {
			handleSave,
		} = this.props;

		handleSave();

	}

	_handleRemove(removeLevel) {
		this.setState((prevState) => {
			const updatedLevels = prevState.levels.filter((stateLevel) => {
				return stateLevel.approval.id !== removeLevel.approval.id;
			}).map((updatedLevel, index) => {
				const workflowLevel = index + 1;
				updatedLevel = {
					...updatedLevel,
					approval: {
						...updatedLevel.approval,
						position: `${ workflowLevel }`
					},
					workflowLevel
				};
				return updatedLevel;
			});
			return {
				levels: updatedLevels
			};
		}, this._handleAlteredState);
	}

	_handleUndo() {
		const {
			advancedApprovalPath,
			handleCancelEdit,
			parentId
		} = this.props;

		this._pristineForm = true;

		this.setState(() => {
			return cloneDeep(this._originalState);
		}, (advancedApprovalPath) ? handleCancelEdit() : handleCancelEdit(parentId));
	}

	_showControls() {
		const form = this._refForm.current;
		const {
			editingWorkflow,
			pathType,
			type
		} = this.props;

		let canSave = false;
		const {
			customApprovers,
			levels,
			id
		} = editingWorkflow;

		if (editingWorkflow === false || isEmpty(levels)) {
			return {
				canCancel: false,
				canSave: false
			};
		}

		canSave = levels.reduce((saveState, level) => {

			const {
				approval
			} = level;

			const {
				approvalBy,
				approvalGroupIds,
				specificPersonUserId
			} = approval;

			switch (approvalBy) {
				case '':
					return false;
				case SPECIFIC_PERSON:
					return (specificPersonUserId ? !!specificPersonUserId : false);
				case USER_GROUP:
					return (Array.isArray(approvalGroupIds) && approvalGroupIds.length > 0 ? !!approvalGroupIds[0] : false);
				default:
					return saveState;
			}
		}, true);

		if (this._pristineForm && id !== 0) {
			canSave = false;
		}

		if (type === 'custom' && canSave) {
			if (customApprovers.length === 0) {
				canSave = false;
			} // Require a requester!
		}

		const canCancel = pathType !== null || canSave;

		return {
			canCancel: canCancel,
			canSave: canSave
		};
	}

	_originalState = {};
	_refForm = createRef();
	_pristineForm = true; //for tracking form changes (new or editing)

	render() {
		let {
			active,
			advancedApprovalPath,
			editState,
			editingWorkflowId,
			id,
			originalSelectedPathIds = [],
			processing,
			roleSectionText,
			roleSectionTitle,
			selectedPathIds = [],
			type,
			workflowId
		} = this.props;

		const {
			customRolesDropdownData,
			levels,
			userGroups
		} = this.state;

		let {
			canCancel,
			canSave
		} = this.state;
		const showControlOptions = this._showControls();
		canCancel = showControlOptions.canCancel;
		canSave = showControlOptions.canSave;

		const _classnamesAddButton = classNames({ 'hidden': levels.length >= MAX_LEVELS });
		const _classnamesUndoButton = classNames('Settings__undo', { 'hidden': !canCancel && !advancedApprovalPath });
		const _classnamesSaveButton = classNames('btn', 'btnAction', { processing });

		const editingThisWorkflow = (editState && editingWorkflowId === Number(id)) || Number(id) === 0 || !advancedApprovalPath;

		const customRolesDropdownSettings = {
			disabled: (editingThisWorkflow) ? null : true,
			multiselect: true,
			notClearable: !editingThisWorkflow || !active,
			showSearch: 'never'
		};

		const formClassName = classNames(
			[
				'BhrForms',
				'BhrForms--noSpace',
				'settingsMainContentForm',
				'WorkflowsForm'
			], {
				eventDisable: !active
			}, {
				AdvancedWorkflowForm: advancedApprovalPath
			}
		);

		const selectClassName = classNames(
			[
				'Approvals_customApproval--Select',
				'xlong'
			],
			{ 'Approvals__Select--readonly': !editingThisWorkflow || !active }
		);

		const disableButton = isEqual(originalSelectedPathIds, selectedPathIds) &&
			(!canSave || processing);

		return (
			<form
				className={ formClassName }
				method="post"
				onSubmit={ (event) => { this._handleFormSubmit(event); } }
				ref={ this._refForm }
			>
				<input name="workflow_id" type="hidden" value={ workflowId } />
				{
					type == 'custom' && (
						<Fragment>
							<div className="Approvals_customApproval">
								{ /* TODO: title should come from controller */ }
								<p className="Approvals__section-title">{ $.__('Who can make this type of request?') }</p>
								<div className="js-Approvals_customApproval--SelectContainer">
									<Select
										canSelectMultiple={ true }
										isClearable={ ifFeature('encore', editingThisWorkflow, editingThisWorkflow || active) }
										isDisabled={ (editingThisWorkflow) ? null : true }
										items={ customRolesDropdownData.items }
										menuWidth={7}
										name="customRoles[]"
										onChange={ (item) => { this._handleCustomApprovalChange(item); } }
										selectedValues={ customRolesDropdownData.selectedValues }
										showSearch="never"
										size="medium"
										width="7"
									/>
								</div>
							</div>
						</Fragment>
					)
				}
				<div>
					<div className="Approvals__list" data-groupcount={ userGroups.length }>
						<p className="Approvals__section-title">{ roleSectionTitle }</p>
						<p className="Approvals__section-text">{ roleSectionText }</p>
						<div className="Approvals__list-levels">
							{levels.map((level) => {
								const {
									approval,
									approverOptions,
									availableUsers,
									userGroups
								} = level;
								const workflowLevel = Number(approval.position);

								return (
									<WorkflowLevel
										active={ active }
										advancedApprovalPath={ advancedApprovalPath }
										approval={ approval }
										approverOptions={ approverOptions }
										availableUsers={ availableUsers }
										editState={ editState }
										editingWorkflow={ editingWorkflowId }
										id={ id }
										key={ approval.id }
										levelsCount={ levels.length }
										onAlteredState={ (level) => { this._handleAlteredState(level); } }
										onRemove={ (level) => { this._handleRemove(level); } }
										userGroups={ userGroups }
										workflowLevel={ workflowLevel }
									/>
								);
							})}
						</div>
					</div>

					<div className="Approvals__actions showOnEdit" data-bi-id="settings-approvals-add-approver-container-div">
						{
							BambooHR.Utils.getParameterByName('type') !== 'time off' && (
								<Fragment>
									<span className={ _classnamesAddButton }>
										<TextButton
											clickAction={ () => { this._handleAddNewLevel(); } }
											text={ '+ ' + $.__('Add') }
											type="button"
										/>
									</span>
								</Fragment>
							)
						}
					</div>
				</div>
				{ifFeature('encore', editingThisWorkflow && <Divider color='neutral-extra-weak'/>)}				
				<div className="ApprovalsForm__footer showOnEdit">
					{ifFeature('encore', null,<hr className="ApprovalsForm__footerBreak" />)}
					<StyledBox encoreOnly={true} padding='20px 16px'>
						{
							!advancedApprovalPath && (
								<Fragment>
									<p className="Approvals__section-text">
										{ $.__('Emails will be sent to notify the appropriate people when an item is waiting for their review.') }
									</p>
								</Fragment>
							)
						}
						<input name="form_action" type="hidden" value="Save" />
						<Fragment>
							<span data-bi-id="settings-approvals-save-approval-button">
								<Button
									isDisabled={ disableButton }
									text={ $.__('Save Changes') }
									type="submit"
								/>
							</span>
							<span className={ _classnamesUndoButton }>
								<TextButton
									clickAction={ () => { this._handleUndo(); } }
									isDisabled={ !canCancel && !advancedApprovalPath }
									text={ $.__('Cancel') }
									type="button"
								/>
							</span>
						</Fragment>
					</StyledBox>
				</div>
			</form>
		);
	}
}
