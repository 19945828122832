import Ajax from '@utils/ajax';

export function formatDataForDataTable(statusTypes) {
	return Object.keys(statusTypes)
		.map((statusId) => {
			return {
				employmentStatus: statusTypes[statusId],
				key: statusId,
			};
		});
}

export function formatEmployeeData(employees, statuses) {
	const employeeEmploymentStatus = [];
	if (employees) {
		Object.entries(employees).forEach((employee) => {
			const employmentStatus = statuses[employee[1].employmentStatusId];
			employeeEmploymentStatus.push(Object.assign(employee[1], {
				employeeId: Number(employee[0]),
				employmentStatusName: employmentStatus ? employmentStatus.name : '',
			}));
		});
	}
	return employeeEmploymentStatus;
}

export function formatStatuses(statuses) {
	const formatted = { ...statuses };
	if (formatted) {
		Object.values(statuses).forEach((status) => {
			formatted[status.id] = status.name;
		});
	}
	return formatted;
}

export function getAcaAssignedStatuses(acaStatus, employmentStatusId, employmentStatusAcaTypes) {
	if (employmentStatusId) {
		const id = employmentStatusId;
		if (employmentStatusAcaTypes) {
			const tempStatuses = employmentStatusAcaTypes;
			if (acaStatus) {
				tempStatuses[id] = {
					id,
					acaStatus,
				};
			} else {
				delete tempStatuses[id];
			}
			return tempStatuses;
		} else if (acaStatus) {
			return {
				[id]: {
					id,
					acaStatus,
				},
			};
		}
	}
}

export function getAcaWizard() {
	return Ajax.get('/settings/aca/wizard');
}

export function getAcaMigrationWizard() {
	return Ajax.get('/settings/employment_status/aca_wizard');
}

export function resetModalContentScroll() {
	const $modalContent = $('#fabricModalContent');
	if ($modalContent && $modalContent.length) {
		$modalContent[0].scrollTo(0, 0);
	}
}

export function validateStatusAlignment(employmentStatuses, employmentStatusAcaTypes) {
	let isStatusAlignmentMissing = false;
	Object.keys(employmentStatuses).forEach((statusId) => {
		if (!employmentStatusAcaTypes[statusId]) {
			isStatusAlignmentMissing = true;
			return false;
		}
	});
	return isStatusAlignmentMissing;
}
