import { Button, Flex, Dropdown, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isNil, replace } from 'lodash';
import { isEnabled } from 'FeatureToggle.util';

import { taxTypeEngines } from '../modals/domain';
import { GearPopover, LockAction } from './components';
import { isTaxActive } from './utils';

const monthlyPayFrequencyId = 2;
const tpauEnabled = isEnabled('TPAU_VIEW_ACCESS');

export const getRowAction = (row, handler, isTaxFrequencyLocked, refreshTableData) => {
	// don't render any row actions for a NewTax for TPAU
	if (row.currentTaxSetupStatus && row.currentTaxSetupStatus.currentStatus === "NewTax" && tpauEnabled) {
		return null;
	}

	if (row.taxTypeName === 'Federal') {
		return renderFederalTaxAction(row, handler, isTaxFrequencyLocked, refreshTableData);
	}

	return renderGearDropdown(row, handler);
};

function renderGearDropdown(row, gearSelectHandler) {
	if (!isTaxActive(row)) {
		return null;
	}

	const isTaxSetup = row.currentTaxSetupStatus && row.currentTaxSetupStatus.currentStatus !== "Complete" && tpauEnabled;

	const buttonProps = {
		size: ifFeature('encore', 'small', 'teenie'),
		width: 1,
		icon: ifFeature('encore', 'ellipsis-vertical-solid', 'fab-gear-15x16'),
		outline: true,
		secondary: true,
	};

	const items = [{ text: $.__('Remove'), value: 'delete' }];

	if (!isTaxSetup) {
		items.unshift({ text: $.__('Edit'), value: 'edit' })
	}

	if (row.latestDatedTax) { 
		items.unshift({ text: $.__('End on...'), value: 'end' });
	}

	if (!row.isPfml && row.taxTypeEngine !== taxTypeEngines.OptionalEmployeePaidTax) {
		if	(row.isWorkersComp)	{
			items.unshift({
				anchor: 'top',
				type: 'group',
				items: [
					{
						text: $.__('Update Codes & Rates'),
						value: 'updateCodesRates',
					},
				],
			}); 
		} else if (!isTaxSetup || row.currentTaxSetupStatus.currentStatus === "ThirdPartyAccessNotGranted") {
			items.unshift({ text: $.__('Add New Rate'), value: 'add' });
		}
	}

	const gear = <div className='TaxesTable__gearDropdown'>
		<Dropdown ButtonProps={buttonProps} items={items} onSelect={(value) => gearSelectHandler(value, row)} showCaret={false} type='icon' />
	</div>
	
	return isTaxSetup ? <LayoutBox paddingTop="10px">{gear}</LayoutBox> : gear;
}

function renderFederalTaxAction(row, handler, isTaxFrequencyLocked, refreshTableData) {
	const isLocked = isTaxFrequencyLocked(row.group);

	if (isNil(window.SESSION_USER?.supportAdmin)) {
		throw new Error('Session user not initialized when loading taxes table');
	}

	const { supportAdmin } = window.SESSION_USER;

	if (!supportAdmin) {
		return isLocked ? <GearPopover onClick={() => handler('emailSupport', row)} /> : renderGearDropdown(row, handler);
	}

	return row.payFrequencyId !== monthlyPayFrequencyId ? (
		<LockAction
			clientId={row.group}
			isLocked={isLocked}
			openUnlockModal={() => handler('unlockFederalTaxFrequency', row)}
			reloadTable={refreshTableData}
		/>
	) : null;
}

export function getRowStatusButton(currentStatus, daysUntilEvent, event, row) {
	let statusButton = null;

	if (currentStatus === "PayrollPaused") {
		// return Payroll paused (red)
		// return "Add Tax ID" button that routes to Edit form.
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/edit_tax?clientId=${row.group}&taxTypeName=${row.taxTypeName}&clientTaxTypeIncludeId=${row.clientTaxTypeIncludeId}`}
				type="button"
				variant="outlined"
			>
				{$.__('Add Tax ID')}
			</Button>;
	} else if (currentStatus === "NewTax") {
		// status: Set Up ASAP (red)
		// return "Set Up Tax" button that routes to Add form.
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/tax_type?taxName=${replace(
					row.taxTypeName,
					/\s/g,
					'+',
				)}&state=${row.state}&taxTypeId=${row.taxTypeId}${
					row.group ? `&clientId=${row.group}` : ''
				}`}
				type="button"
				variant="outlined"
			>
				{$.__('Set Up Tax')}
			</Button>;
	} else if (event === "PayrollWillPause" && daysUntilEvent <= 7) {
		// return Payroll paused in *daysUntilEvent* (red)
		// return "Add Tax ID" button that routes to Edit form.
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/edit_tax?clientId=${row.group}&taxTypeName=${row.taxTypeName}&clientTaxTypeIncludeId=${row.clientTaxTypeIncludeId}`}
				type="button"
				variant="outlined"
			>
				{$.__('Add Tax ID')}
			</Button>;
	} else if (currentStatus === "FeesApply" && event === "PayrollWillPause" && daysUntilEvent > 7) {
		// return Fees apply (red)
		// return "Add Tax ID" button that routes to Edit form.
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/edit_tax?clientId=${row.group}&taxTypeName=${row.taxTypeName}&clientTaxTypeIncludeId=${row.clientTaxTypeIncludeId}`}
				type="button"
				variant="outlined"
			>
				{$.__('Add Tax ID')}
			</Button>;
	} else if (currentStatus === "ThirdPartyAccessNotGranted") {
		// return Pending Access Confirmation (blue)
		// return "Review Access" button that routes to Edit form.		
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/edit_tax?clientId=${row.group}&taxTypeName=${row.taxTypeName}&clientTaxTypeIncludeId=${row.clientTaxTypeIncludeId}`}
				type="button"
				variant="outlined"
			>
				{$.__('Review Access')}
			</Button>;
	} else if (currentStatus === "AddTaxId" && event === "FeesWillApply" && daysUntilEvent <= 30) {
		// return Fees apply in *daysUntilEvent* (yellow)
		// return "Add Tax ID" button that routes to Edit form.
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/edit_tax?clientId=${row.group}&taxTypeName=${row.taxTypeName}&clientTaxTypeIncludeId=${row.clientTaxTypeIncludeId}`}
				type="button"
				variant="outlined"
			>
				{$.__('Add Tax ID')}
			</Button>;
	} else if (currentStatus === "AddTaxId" && (!event || (event === "FeesWillApply" && daysUntilEvent > 30))) {
		// return Set Up ASAP (blue)
		// return "Add Tax ID" button that routes to Edit form.
		statusButton = <Button
				color="secondary"
				href={`/settings/payroll/taxes/edit_tax?clientId=${row.group}&taxTypeName=${row.taxTypeName}&clientTaxTypeIncludeId=${row.clientTaxTypeIncludeId}`}
				type="button"
				variant="outlined"
			>
				{$.__('Add Tax ID')}
			</Button>;
	}

	return <LayoutBox paddingLeft={2} paddingTop={2}>
		{statusButton}
	</LayoutBox>;
}