import { makeStyles } from '@bamboohr/fabric';

export default makeStyles(({ palette }) => {
	return {
		view: {
			padding: '11px 4px 7px',
			width: '682px',
		},
		content: {
			'& h4': {
				color: palette.primary.main,
				marginBottom: '16px',
			},
			'& p': {
				marginBottom: '16px',
			},
			'& ul': {
				marginBottom: '16px',
				marginLeft: '14px',
				maxWidth: '619px',
			},
			'& ul li:before': {
				content: `'\u2022'`,
				color: palette.primary.main,
				display: 'inline-block',
				width: '1em',
				marginLeft: '-1em',
			}
		},
		videoPlayer: {
				height: '384px',
				margin: '0 auto',
				width: '682px',
		},
		videoWrapper: {
			paddingBottom: '12px',
		}
	}
});
