import { render } from 'base/wrapped-render';
import Ctrl, { onReady } from 'ctrl.deco';
import { queryStringToObject } from '@utils/url';
import { InvoiceHistory } from '../../components';

/**
 * Invoice History Page
 */
 class SettingsAccountInvoiceHistoryCtrl {
	@onReady()
	_render() {
		const url = new URL(window.location.href);
		const root = document.getElementById('settings-account-billing-history-root');
		
		// Example
		// ?invoices[]=8ad08aff7b0c0703017b1829cd3f5b7e&invoices[]=2c92c0867ac81a12017aca3057af7b0b		
		const { invoices = [] } = queryStringToObject();

		if (invoices.length > 0) {
			// use `replaceState()` to remove the special params from the browser URL
			window.history.replaceState(url.origin, document.title, url.pathname);
		}

		if (root) {
			render(<InvoiceHistory selectedInvoices={ invoices } />, root);
		}
	}
}

Ctrl('/settings/account/invoice_history.php')(SettingsAccountInvoiceHistoryCtrl);