import classNames from "classnames";

import { BodyText, Flex, makeStyles, Truncate, TextButton } from "@bamboohr/fabric";
import { ifFeature } from "@bamboohr/utils/lib/feature";


// @startCleanup encore
const useStyles = makeStyles(({ palette, typography}) => ({
    textButton: {
        color: palette.info.main,
    },
    address: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: palette.gray[600],
        fontSize: typography?.teenie.fontSize,
    },
    hoverState: {
        color: palette.common.white,
    },
}))
// @endCleanup encore

export const SelectItem = ({ item, state }) => {
    // @startCleanup encore
    const classes = useStyles();
    // @endCleanup encore
    const {
        address,
        payeeName,
        text,
        type,
    } = item;

    if (type === 'text-button') {
        return (
            ifFeature('encore',
                <TextButton>
                    {text}
                </TextButton>,
                <div className={classNames(classes.textButton, {[classes.hoverState]: state.isActive})} type="button">
                    {text}
                </div>
            )
        )
    }

    if (type === 'name-and-address') {
        return (
            <Flex flexDirection="column">
                <BodyText>
                    <Truncate lines={ 1 } trimWhitespace={false} width={ 200 }>{payeeName}</Truncate>
                </BodyText>
                {ifFeature('encore',
                    <BodyText  size="small">
                        <Truncate lines={ 1 } trimWhitespace={true}>
                            <div>{address}</div>
                        </Truncate>
                    </BodyText>,
                    <div className={classNames(classes.address, {[classes.hoverState]: state.isActive})}>{address}</div>
                )}
            </Flex>
        )
    }

    return (
        <div>
            {text}
        </div>
    ) 
}
