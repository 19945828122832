import { connect } from 'react-redux';

import { HeaderActions } from '../components/header-actions';
import {
	getData,
	handleDeleteApproval,
	handleDisableApproval,
	handleEnableApproval
} from '../store/actions';

const mapStateToProps = (state) => {
	const { workflow } = state;
	const {
		active,
		advancedApprovalToolEnabled,
		curAppStatus,
		id,
		name,
		pathType,
		template,
		type
	} = workflow || {};
	return {
		active,
		advancedApprovalToolEnabled,
		curAppStatus,
		id,
		name,
		pathType,
		template,
		type
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getWorkflowData: (workflowId) => {
			return dispatch(getData(workflowId));
		},
		handleDeleteApproval: (workflowId, name) => {
			return dispatch(handleDeleteApproval(workflowId, name));
		},
		handleDisableApproval: (appStatus, workflowId) => {
			return dispatch(handleDisableApproval(appStatus, workflowId));
		},
		handleEnableApproval: (appStatus, workflowId) => {
			return dispatch(handleEnableApproval(appStatus, workflowId));
		}
	}
};

export const HeaderActionsContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderActions);
