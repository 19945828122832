import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({
	palette,
	spacing,
}) => {
	return createStyles({
		root: {},
		body: {
			marginBottom: spacing(20),
			marginTop: spacing(2),
		},
		iconContainer: {
			fill: palette.warning.main,
		},
	});
});
