import { memo, useEffect, useState } from 'react';

import { sortBy } from 'lodash';

import c from 'classnames';

import { showSlidedown } from 'Alerts.mod';

import { Select } from '@fabric/select';

import Ajax from '@utils/ajax';

import { LoadingPlaceholder } from '../loading-placeholder/loading-placeholder';

import { findSelectWidth, formatTaxTypes } from '../utils';

import './tax-type-field.styl';

export const TaxTypeField = memo((props) => {
	const {
		clientId,
		disabled,
		effectiveDate,
		error,
		isLoading,
		items,
		name,
		placeholder,
		selectedState,
		selectedTaxType,
		updateFormData,
		value,
		width,
	} = props;

	const [hasActiveTax, setHasActiveTax] = useState(false);
	const [selectWidth, setSelectWidth] = useState(width || 5);

	useEffect(() => {
		// Resets tax type field data
		updateFormData(name, { disabled: true, error: false, isLoading: true, items: [], selectedTaxType: null, value: null });
		if (selectedState && clientId && effectiveDate) {

			const {
				value: stateAbbreviation,
			} = selectedState;

			Ajax.get(`/settings/payroll/ajax/taxes/tax_types?stateAbbrev=${ stateAbbreviation }&clientId=${ clientId }&startDate=${ effectiveDate }`).then((response) => {
				if (response.status === 200 && response.data.allowableTaxTypes) {

					const longestCharacterCount = findSelectWidth(response.data.allowableTaxTypes);
					setSelectWidth(longestCharacterCount.selectWidth || 6);

					const { allowableTaxTypes } = response.data;
					const sortedAllowableTaxTypes = sortBy(allowableTaxTypes, ['taxTypeName']);

					if (allowableTaxTypes.length === 1) {
						updateFormData(name, { disabled: true, isLoading: false, items: sortedAllowableTaxTypes, selectedTaxType: sortedAllowableTaxTypes[0], value: sortedAllowableTaxTypes[0].taxTypeId });
					} else {
						updateFormData(name, { disabled: false, isLoading: false, items: sortedAllowableTaxTypes, selectedTaxType: null, value: null });
					}

				}
			}).catch(() => {
				showSlidedown(window.DEFAULT_ERROR_MESSAGE, 'error');
			});
		} else {
			setSelectWidth(width || 6);
			updateFormData(name, { isLoading: false });
		}
	}, [effectiveDate, selectedState, clientId]);

	useEffect(() => {
		if (selectedTaxType && selectedTaxType.hasActiveTax === true) {
			setHasActiveTax(true);
			updateFormData(name, { error: true });
		} else {
			setHasActiveTax(false);
			updateFormData(name, { error: false });
		}
	}, [selectedTaxType]);

	function handleOnChange(selection) {
		const newTaxType = items.filter(item => item.taxTypeId === selection[0])[0];

		if (newTaxType) {
			updateFormData(name, { error: false, value: selection[0], selectedTaxType: newTaxType });
		} else {
			updateFormData(name, { error: false, selectedTaxType: null, value: null });
		}
	}

	let selectPlaceholder;
	if (isLoading) {
		selectPlaceholder = <LoadingPlaceholder />;
	} else {
		selectPlaceholder = disabled ? placeholder : `-${ $.__('Select') }-`;
	}

	let formNote;
	if (hasActiveTax) {
		formNote = $.__('This tax type has already been added.');
	} else if (items.length === 1 && selectedState) {
		formNote = $.__('This is the only tax type for %1$s', selectedState.text);
	}

	const formNoteClassNames = c(
		'fab-FormNote',
		{ 'fab-FormNote--error': error }
	);

	return (
		<div className="TaxTypeField">
			<Select
				condition={ error ? 'error' : '' }
				isDisabled={ disabled }
				items={ formatTaxTypes(items) }
				name={ name }
				onChange={ handleOnChange }
				placeholder={ selectPlaceholder }
				selectedValues={ [value] }
				width={ selectWidth }
			/>
			{ formNote && (
				<span className={ formNoteClassNames }>
					{ formNote }
				</span>
			) }
		</div>
	);
});
