import {
	HeaderDataObject,
	HeaderIcon,
	MappingToolActionParam,
	SectionType,
} from '../../utils/interfaces';

const PERSON_CIRCLE_ICON: HeaderIcon = 'fab-person-circle-28x28';
const MONEY_CIRCLE_ICON: HeaderIcon = 'fab-money-circle-28x28';
const LOCATION_ICON: HeaderIcon = 'fab-location-21x28';
export const CLOCK_ICON: HeaderIcon = 'fab-clock-28x28';
const EE_RECORD_HEADER_TEXT: string = $.__('Employee Record');
const EE_BANK_HEADER_TEXT: string = $.__('Employee Bank');
const EE_WAGE_HEADER_TEXT: string = $.__('Employee Compensation');
const COMPANY_LOCATION_HEADER_TEXT: string = $.__('Company Location');
const COMPANY_DEDUCTIONS_HEADER_TEXT: string = $.__('Company Benefit/Non-Benefit Deductions');
const EMPLOYEE_DEDUCTIONS_HEADER_TEXT: string = $.__('Employee Benefit/Non-Benefit Deductions');
const EMPLOYEE_DEMOGRAPHICS_HEADER_TEXT: string = $.__('Employee Demographics');
const COMPANY_DIVISION_HEADER_TEXT: string = $.__('Company Division');
const COMPANY_EMPLOYMENT_STATUS_HEADER_TEXT: string = $.__('Company Employment Status');
const EMPLOYEE_TAX_HEADER_TEXT: string = $.__('Employee Tax');
const COMPANY_DEPARTMENT_HEADER_TEXT: string = $.__('Company Departments');
const TIME_OFF_HEADER_TEXT = $.__('Time Off Types');
const EE_RECORD_MAPPING_INSTRUCTION = $.__('For each employee, select a connecting employee record in the other system. You can also add them as a new employee if none of the other dropdown options are correct.');
const EE_WAGE_MAPPING_INSTRUCTION = `
	${ $.__('For each compensation record, select a connecting compensation record in the other system.') }
	${ $.__(`You can also add a new record if it doesn't exist in the other system.`) }
	${ $.__('If a record needs to be removed/changed, make changes in the settings in BambooHR/TRAXPayroll.') }
`;
const COMPANY_LOCATION_MAPPING_INSTRUCTION = `
	${ $.__('For each company location, select a connecting location.') }
	${ $.__(`You can also add a new location if you don't see its match.`) }
	${ $.__('If you need to remove or change a location, you will need to do that in BambooHR and TRAXPayroll settings.') }
`;
const COMPANY_DEDUCTIONS_MAPPING_INSTRUCTION = `
	${ $.__('For each deduction, select a connecting deduction in the other system.') }
	${ $.__(`You can also add it as a new record if it doesn't exist in TRAXPayroll.`) }
	${ $.__('If a record needs to be removed/changed, make changes on the employee record or in settings in BambooHR/TRAXPayroll.') }
	${ $.__('Any changes made at the company plan level will be applied to all employees enrolled in the plan.') }
`;
const EMPLOYEE_DEDUCTIONS_MAPPING_INSTRUCTION = `
	${ $.__('For each deduction, select a connecting deduction in the other system.') }
	${ $.__(`You can also add it as a new record if it doesn't exist in TRAXPayroll.`) }
	${ $.__('If a record needs to be removed/changed, make changes on the employee record or in settings in BambooHR/TRAXPayroll.') }
`;
const COMPANY_DIVISION_MAPPING_INSTRUCTION = `
	${ $.__('For each division, select a connecting division in the other system.') }
	${ $.__(`You can also add it as a new record if it doesn't exist in the other system.`) }
	${ $.__(`If a record needs to be removed/changed, make changes in the settings in BambooHR/TRAXPayroll.`) }
`;
const COMPANY_DEPARTMENTS_MAPPING_INSTRUCTION = `
	${ $.__(`For each department, select a connecting department in the other system.`) }
	${ $.__(`You can also add it as a new record if it doesn’t exist in the other system.`) }
	${ $.__(`If a record needs to be removed/changed, make changes in the settings in BambooHR/TRAXPayroll.`) }
`;
const COMPANY_EMPLOYMENT_STATUS_MAPPING_INSTRUCTIONS = `
	${ $.__('For each employment status, select a connecting employment status in the other system.') }
	${ $.__('You can also add it as a new record if it doesn’t exist in the other system.') }
	${ $.__('If a record needs to be removed/changed, make changes in the settings in BambooHR/TRAXPayroll.') }
`;
const TIME_OFF_MAPPING_INSTRUCTION = `
	${ $.__('For each time off type in BambooHR, select a matching type in TRAXPayroll.') }
	${ $.__(`You can also add it as a new record if it doesn't exist.`) }
	${ $.__('If a record needs to be removed/changed, make changes in the settings in BambooHR or TRAXPayroll respectively.') }
`;

const BHR_EMPLOYEES_LINK = '/employees/';
const BHR_SETTINGS_LINK = '/settings/';
const BHR_SETTINGS_BENEFIT_LINK = '/settings/benefits';
const BHR_SETTINGS_TIME_OFF_LINK = '/settings/pto';
const TRAX_EMPLOYER_LINK = '/trax_login.php?type=Employer';

export function getHeaderDataByType(type: SectionType, showMappingTool: boolean): HeaderDataObject {
	switch (type) {
		case 'employeeRecord':
			return {
				iconName: PERSON_CIRCLE_ICON,
				headerText: EE_RECORD_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: EE_RECORD_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: {
					action: (): void => openNewTab(BHR_EMPLOYEES_LINK),
					text: $.__('Manage Employees in BambooHR'),
				},
				mappingToolBtnRight: {
					action: (): void => openNewTab(TRAX_EMPLOYER_LINK),
					text: $.__('Manage Employees in TRAXPayroll'),
				},
			};
		case 'employeeBank':
			return {
				iconName: MONEY_CIRCLE_ICON,
				headerText: EE_BANK_HEADER_TEXT,
				upNextText: null,
				showButtons: !showMappingTool,
				subtitle: null,
				mappingToolInstruction: null,
				mappingToolBtnLeft: null,
				mappingToolBtnRight: null,
			};
		case 'employeeWage':
			return {
				iconName: MONEY_CIRCLE_ICON,
				headerText: EE_WAGE_HEADER_TEXT,
				upNextText: null,
				showButtons: !showMappingTool,
				subtitle: null,
				mappingToolInstruction: EE_WAGE_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: null,
				mappingToolBtnRight: null,
			};
		case 'companyLocation':
			return {
				iconName: LOCATION_ICON,
				headerText: COMPANY_LOCATION_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: COMPANY_LOCATION_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.bambooLink || BHR_SETTINGS_LINK),
					text: $.__('Manage Locations in BambooHR'),
				},
				mappingToolBtnRight: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.traxLink || TRAX_EMPLOYER_LINK),
					text: $.__('Manage Locations in TRAXPayroll'),
				},
			};
		case 'companyDeductions':
			return {
				iconName: MONEY_CIRCLE_ICON,
				headerText: COMPANY_DEDUCTIONS_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: COMPANY_DEDUCTIONS_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.bambooLink || BHR_SETTINGS_BENEFIT_LINK),
					text: $.__('Manage Deductions in BambooHR'),
				},
				mappingToolBtnRight: null,
			};
		case 'employeeDeductions':
			return {
				iconName: MONEY_CIRCLE_ICON,
				headerText: EMPLOYEE_DEDUCTIONS_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: EMPLOYEE_DEDUCTIONS_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: {
					action: (): void => openNewTab(BHR_SETTINGS_BENEFIT_LINK),
					text: $.__('Manage Deductions in BambooHR'),
				},
				mappingToolBtnRight: null,
			};
		case 'employeeDemographics':
			return {
				iconName: PERSON_CIRCLE_ICON,
				headerText: EMPLOYEE_DEMOGRAPHICS_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: null,
				mappingToolBtnLeft: null,
				mappingToolBtnRight: null,
			};
		case 'companyDivision':
			return {
				iconName: LOCATION_ICON,
				headerText: COMPANY_DIVISION_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: COMPANY_DIVISION_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.bambooLink || BHR_SETTINGS_LINK),
					text: $.__('Manage Divisions in BambooHR'),
				},
				mappingToolBtnRight: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.traxLink || TRAX_EMPLOYER_LINK),
					text: $.__('Manage Divisions in TRAXPayroll'),
				},
			};
		case 'companyEmploymentStatus':
			return {
				iconName: LOCATION_ICON,
				headerText: COMPANY_EMPLOYMENT_STATUS_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: COMPANY_EMPLOYMENT_STATUS_MAPPING_INSTRUCTIONS,
				mappingToolBtnLeft: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.bambooLink || BHR_SETTINGS_LINK),
					text: $.__('Manage Employment Status in BambooHR'),
				},
				mappingToolBtnRight: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.traxLink || TRAX_EMPLOYER_LINK),
					text: $.__('Manage Employment Status in TRAXPayroll'),
				},
			};
		case 'employeeTax':
			return {
				iconName: MONEY_CIRCLE_ICON,
				headerText: EMPLOYEE_TAX_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: null,
				mappingToolBtnLeft: null,
				mappingToolBtnRight: null,
			};
		case 'companyDepartment':
			return {
				iconName: PERSON_CIRCLE_ICON,
				headerText: COMPANY_DEPARTMENT_HEADER_TEXT,
				upNextText: null,
				showButtons: true,
				subtitle: null,
				mappingToolInstruction: COMPANY_DEPARTMENTS_MAPPING_INSTRUCTION,
				mappingToolBtnLeft: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.bambooLink || BHR_SETTINGS_LINK),
					text: $.__('Manage Departments in BambooHR'),
				},
				mappingToolBtnRight: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.traxLink || TRAX_EMPLOYER_LINK),
					text: $.__('Manage Departments in TRAXPayroll'),
				},
			};
		case 'timeOff':
			return {
				headerText: TIME_OFF_HEADER_TEXT,
				iconName: CLOCK_ICON,
				mappingToolBtnLeft: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.bambooLink || BHR_SETTINGS_TIME_OFF_LINK),
					text: $.__('Manage Time Off in BambooHR'),
				},
				mappingToolBtnRight: {
					action: (param: MappingToolActionParam): void => openNewTab(param?.traxLink || TRAX_EMPLOYER_LINK),
					text: $.__('Manage Time Off in TRAXPayroll'),
				},
				mappingToolInstruction: TIME_OFF_MAPPING_INSTRUCTION,
				showButtons: true,
				subtitle: null,
				upNextText: null,
			};
		default:
			return {
				iconName: PERSON_CIRCLE_ICON,
				headerText: EE_RECORD_HEADER_TEXT,
				upNextText: null,
				showButtons: !showMappingTool,
				subtitle: null,
				mappingToolInstruction: null,
				mappingToolBtnLeft: null,
				mappingToolBtnRight: null,
			};
	}
}

export function openNewTab(url: string): void {
	window.open(url, '_blank', 'noopener,noreferrer');
}
