import {
	ModalBodyDefault,
} from './modal-body-default';
import {
	ModalBodyError,
} from './modal-body-error';
import {
	ModalBodyLoading,
} from './modal-body-loading';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function ModalBodyConnector() {
	const [{
		isErrorClientTaxSettings,
		isLoadingClientTaxSettings,
	}] = useCompanyTaxSettingsStore();
	if (isLoadingClientTaxSettings) {
		return (
			<ModalBodyLoading />
		);
	}
	if (isErrorClientTaxSettings) {
		return (
			<ModalBodyError />
		);
	}
	return (
		<ModalBodyDefault />
	);
}
