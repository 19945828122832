import { Button, IconButton, Popover, makeStyles } from '@bamboohr/fabric';
import { Gear15x16 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useRef, useState } from 'react';

const useStyles = makeStyles(() => {
	return {
		popoverText: {
			marginBottom: 10,
		},
	};
});

export function GearPopover({ onClick }) {
	const [popoverOpen, setPopoverOpen] = useState(false);
	const buttonRef = useRef(null);
	const styles = useStyles();

	return (
		<div className='TaxesTable__gearDropdown'>
			<IconButton
				color='secondary'
				floatingIcon={true}
				icon={ifFeature('encore', 'gear-solid', <Gear15x16 height='16px' width='15px' />)}
				noSpacing={true}
				onClick={(e) => {
					e.stopPropagation();
					setPopoverOpen((prev) => !prev);
				}}
				ref={buttonRef}
				type='button'
			/>

			<Popover
				anchorEl={() => buttonRef.current}
				onClose={() => {
					setPopoverOpen((prev) => !prev);
					buttonRef.current.blur();
				}}
				open={popoverOpen}
				placement='bottom'
				title={$.__('Need Changes?')}
			>
				<div className={styles.popoverText}>{$.__('Let us know what you need to change and we’ll unlock it for you!')}</div>

				<Button color='primary' onClick={onClick} type='button'>
					{$.__('Email Us')}
				</Button>
			</Popover>
		</div>
	);
}
