import {
	COMPANY_TAX_SETTINGS_KEY,
} from '../constants';
import type {
	CompanyTaxDropdownItem,
} from '../types';

export function isCompanyTaxSettingModalOption(parentItem: CompanyTaxDropdownItem) {
	if (typeof parentItem === 'undefined' || parentItem === null) {
		return false;
	}
	if (typeof parentItem.key !== 'string') {
		return false;
	}
	return parentItem.key.includes(COMPANY_TAX_SETTINGS_KEY);
}
