import {
	WageMetadataProps,
} from '../../utils/interfaces';

import './wage-metadata.styl';

export function WageMetadata(props: WageMetadataProps): JSX.Element {
	const {
		data,
	} = props;

	if (!data) {
		return <div className="WageMetadata"></div>;
	}

	const {
		payType,
		overtime,
	} = data;

	return (
		<div className="WageMetadata">
			<div className="WageMetadata__labels">
				<p className="WageMetadata__detail">{ $.__('Pay Type') }:</p>
				<p className="WageMetadata__detail">{ $.__('Overtime') }:</p>
			</div>
			<div className="WageMetadata__values">
				<p className="WageMetadata__detail">{ payType || '--' }</p>
				<p className="WageMetadata__detail">{ overtime || '--' }</p>
			</div>
		</div>
	);
}
