import LegacyBlankState from 'blank-state.react';
import { BlankState, LayoutBox } from '@bamboohr/fabric';
import { isEnabled, ifFeature } from '@bamboohr/utils/lib/feature';
import { Clipboard60x72 } from '@bamboohr/grim';

export function ProjectTrackingBlankState({ addProjectBtn }) {
	return isEnabled('PROJECT_PAY') ? (
		<LayoutBox paddingTop={6}>
			<BlankState
				actions={[addProjectBtn]}
				icon={ifFeature('encore', 'bs-clipboard', <Clipboard60x72 />)}
				subtitle={$.__('Let your people track their work on projects and tasks.')}
				title={$.__('Need to get specific?')}
			/>
		</LayoutBox>
	) : (
		<div>
			<hr />
			<LegacyBlankState
				button={addProjectBtn}
				className='TTProjectTracking__emptyTable'
				icon='fab-time-off-72x58'
				title={$.__(`You don't have any projects set up.`)}
			/>
		</div>
	);
}
