import {
	PlanTypeEnumContract,
} from '../types';

export const isPlanEndsRequired = (planType: PlanTypeEnumContract): boolean => {
	const requiredTypes = [
		PlanTypeEnumContract.health,
		PlanTypeEnumContract.dental,
		PlanTypeEnumContract.vision,
		PlanTypeEnumContract.supplemental,
	];

	return requiredTypes.includes(planType);
};
