import React, { Component } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import {
	BodyText,
	Checkbox,
	Flex,
	Headline,
	Icon,
	LayoutBox
} from '@bamboohr/fabric';
import Ajax from '@utils/ajax';
import Tooltip from 'tooltip.react';

import { CHILD_SUPPORT, TAX_LEVIES, OTHER, checkToLaunchModal } from './utils';

import './garnishment-payments.styl';
import { SetUpPayees } from './components/set-up-payees';
import { JustCheckingModal } from './components/just-checking-modal';
import { getAuthToken } from '../../payroll/payee-settings.react/apis';
import { SadToGoBox, CancellationType } from 'in-app-cancellation.mod';
import { ifFeature } from "@bamboohr/utils/lib/feature";
import { Message } from "@bamboohr/utils/lib/message";

export class GarnishmentPayments extends Component {
	constructor(props) {
		super(props);

		const {
			collectChildSupportGarnishmentPayments: childSupport,
			collectTaxLevyGarnishmentPayments: taxLevies,
			collectOtherGarnishmentPayments: other,
		} = props;

		this.state = {
			activeCheckbox: null,
			byType: props.byType,
			childSupport,
			taxLevies,
			other,
			showSetUpPayees: false,
			showJustCheckingModal: false,
			cancellationData: {},
			cancellationPending: false,
		};

		this._initialState = {
			childSupport,
			taxLevies,
			other,
		};

		this._getCancellationStatusAll = this._getCancellationStatusAll.bind(this);
	}

	get _canAddPayees() {
		return !(window.SESSION_USER.supportAdmin || window.SESSION_USER.auditUserId);
	}

	_getAuthToken = () => {
		getAuthToken().then((response) => {
			if (response?.data?.token) {
				this.setState({ authToken: response.data.token });
			}
		});
	};

	_getJustCheckingData = () => {
		const { activeCheckbox, byType } = this.state;

		if (activeCheckbox === null) {
			return;
		}

		return {
			name: activeCheckbox,
			data: byType[activeCheckbox],
		};
	};

	_handleModalClose = (modalType) => this.setState({ [modalType]: false });

	_handleCheckboxChange = (value, type) => {
		const { byType: garnishmentsByType } = this.props;
		const url = '/payroll/payee/garnishmentInfoByType';
		this.setState({ isLoading: true });
		this.setState({ loadingType: type });
		Ajax.get(url)
			.then((response) => {
				if (response.status === 200 && response.data) {
					this.setState({ byType: response.data });
				}
				const showModal = checkToLaunchModal(this._initialState[type], value, response.data[type].garnishments);

				// Determine if either modal should be displayed
				if (showModal) {
					this.setState({
						[type]: value,
						[showModal]: true,
						activeCheckbox: type,
					});

					return;
				}

				// If neither modal should be displayed, update state and save
				this.setState({ [type]: value }, this._handleSave);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	_handleCancel = () => this.setState(this._initialState);

	_handleSave = () => {
		const { childSupport, taxLevies, other } = this.state;

		Ajax.post('/settings/payroll/ajax/bamboohr_payroll_settings', {
			collectChildSupportGarnishmentPayments: childSupport,
			collectTaxLevyGarnishmentPayments: taxLevies,
			collectOtherGarnishmentPayments: other,
		})
			.then((response) => {
				if (response.status === 200) {
					this._handleSaveSuccess();
				} else {
					this._handleSaveFail();
				}
			})
			.catch(() => {
				this._handleSaveFail();
			});
	};

	_handleSaveFail = () =>
		window.setMessage($.__('Whoops... There was a problem trying to save your changes. Please try again.'), 'error');

	_handleSaveSuccess = () => {
		this.setState({
			showSetUpPayees: false,
			showJustCheckingModal: false,
			activeCheckbox: null,
		});

		this._initialState = cloneDeep(this.state);

		window.setMessage($.__('Got it! Your changes were successfully saved.'), 'success');
	};

	_renderGarnishmentLabel = (garnishmentEmployees, garnishmentName) => {
		const {
			byType: { employees },
		} = this.state;

		const isChecked = this.state[garnishmentName];

		if (isChecked && !isEmpty(garnishmentEmployees)) {
			const employeePlural = garnishmentEmployees.length > 1 ? $.__('Employees') : $.__('Employee');
			const toolTipList = [];

			employees.allIds.forEach((employeeId) => {
				garnishmentEmployees.forEach((garnishmentEmployee) => {
					if (garnishmentEmployee == employeeId) {
						toolTipList.push(employees.byId[garnishmentEmployee].name);
					}
				});
			});

			return (
				<Tooltip
					settings={{
						content: toolTipList,
						position: 'top',
					}}
				>
					<BodyText>
						{garnishmentEmployees.length} {employeePlural}
					</BodyText>

				</Tooltip>
			);
		}
	};

	_renderSetUpPayeesModals = () => {
		const { payees, states } = this.props;
		const { byType, showSetUpPayees, activeCheckbox } = this.state;
		const garnishmentTypesArray = [CHILD_SUPPORT, TAX_LEVIES, OTHER];

		return garnishmentTypesArray.map((type) => {
			return (
				<SetUpPayees
					allowAddPayee={this._canAddPayees}
					authToken={this.state.authToken}
					byType={byType}
					employees={byType.employees}
					garnishments={byType[type].garnishments}
					key={type}
					onCancel={this._handleCancel}
					onClose={() => this._handleModalClose('showSetUpPayees')}
					onSave={this._handleSaveSuccess}
					payees={payees}
					states={states}
					type={type}
					visible={showSetUpPayees && activeCheckbox === type}
				/>
			);
		});
	};

	_getCancellationStatusAll() {
		Ajax.get('/settings/cancellation/status_all').then((response) => {
			if (response.data) {
				this.setState({
					cancellationData: response.data.filter(
						(item) => item.feature === CancellationType.PAYROLL || item.feature === CancellationType.ACCOUNT
					),
					cancellationPending: response.data.find((item) => item.feature === CancellationType.PAYROLL).pending_cancellation,
				});
			}
		});
	}

	componentDidMount() {
		this._getAuthToken();
		this._getCancellationStatusAll();
	}

	render() {
		const {
			byType,
			childSupport,
			isLoading,
			loadingType,
			other,
			showJustCheckingModal,
			taxLevies,
			cancellationData,
			cancellationPending,
		} = this.state;

		const leviesEmployees = byType.taxLevies.employees;
		const childSupportEmployees = byType.childSupport.employees;
		const otherEmployees = byType.other.employees;

		const handleChange = (params, event) => {
			const { target } = event;
			this._handleCheckboxChange(target.checked, target.value);
		};

		return (
			<div className='GarnishmentPayments'>
				{ifFeature('encore',
					<LayoutBox marginBottom={3}>
						<Headline size="small">
							{$.__('Garnishment Payments')}
						</Headline>
					</LayoutBox>,
					<div className='GarnishmentPayments__heading'>
						<span className='GarnishmentPayments__icon'>
							<Icon name='fab-money-circle-20x20'/>
						</span>
						<h4>{$.__('Garnishment Payments')}</h4>
					</div>
				)}
				{this.state.cancellationPending && (
					<SadToGoBox cancellationData={cancellationData} updateCancellationData={this._getCancellationStatusAll} isAddon={true} />
				)}
				<form className='BhrForms GarnishmentPayments__form fab-Form'>
					<BodyText color='neutral-strong' size='large'>
						{$.__('Which types are collected and paid by BambooHR Payroll?')}
					</BodyText>
					<LayoutBox marginBottom={2} marginTop={2}>
						<Flex alignItems='center' gap={1} marginBottom={1.75}>
							<Checkbox
								checked={childSupport}
								id='childSupport'
								label={$.__('Child Support')}
								onChange={handleChange}
								value={CHILD_SUPPORT}
							/>
							<BodyText color='neutral-weak' size='extra-small'>
								{isLoading && loadingType === CHILD_SUPPORT ? (
									$.__('Setting Up...')
								) : (
									this._renderGarnishmentLabel(childSupportEmployees, CHILD_SUPPORT)
								)}
							</BodyText>
						</Flex>
						<Flex alignItems='center' gap={1} marginBottom={1.75}>
							<Checkbox
								checked={taxLevies}
								id='taxLevy'
								label={$.__('Tax Levies')}
								onChange={handleChange}
								value={TAX_LEVIES}
							/>
							<BodyText color='neutral-weak' size='extra-small'>
								{isLoading && loadingType === TAX_LEVIES ? (
									$.__('Setting Up...')
								) : (
									this._renderGarnishmentLabel(leviesEmployees, TAX_LEVIES)
								)}
							</BodyText>
						</Flex>
						<Flex alignItems='center' gap={1} marginBottom={1.75}>
							<Checkbox
								checked={other}
								id='other'
								label={$.__('Other Garnishments')}
								onChange={handleChange}
								value={OTHER}
							/>
							<BodyText color='neutral-weak' size='extra-small'>
								{isLoading && loadingType === OTHER ? (
									$.__('Setting Up...')
								) : (
									this._renderGarnishmentLabel(otherEmployees, OTHER)
								)}
							</BodyText>
						</Flex>
					</LayoutBox>
					<BodyText color='neutral'>
						{ifFeature('encore',
							$.__('Note: There is a charge of $4 per garnishment per pay cycle'),
							<Message text={$.__('**Note:** There is a charge of $4 per garnishment per pay cycle')} />
						)}
					</BodyText>
				</form>

				{this._renderSetUpPayeesModals()}

				<JustCheckingModal
					garnishmentTypeData={this._getJustCheckingData()}
					onCancel={this._handleCancel}
					onClose={() => this._handleModalClose('showJustCheckingModal')}
					onOkClick={this._handleSave}
					visible={showJustCheckingModal}
				/>
			</div>
		);
	}
}
