import {
	getJwtToken,
	resetJwtToken,
	setJwtToken,
} from './jwt';
import {
	jwtAuthServiceService,
} from '../jwt-auth-service';

export async function getJwtAuthToken({
	reset = false,
}: {
	reset?: boolean;
} = {}): Promise<string> {
	const currentToken = getJwtToken();
	if (!reset && currentToken) {
		return Promise.resolve(currentToken);
	} else if (currentToken) {
		resetJwtToken();
	}
	try {
		const {
			data: {
				token,
			},
		} = await jwtAuthServiceService.request<{ token: string; }>({
			method: 'post',
			url: 'auth/token_sessions',
		});
		setJwtToken(token);
		return token;
	} catch (err) {
		console.error('getJwtAuthToken service error -', err);
		throw err;
	}
}
