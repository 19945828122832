import { useCallback, useState, useEffect } from 'react';
import { EmployeeEligibility, UpdateEligibility, UpdateEffectiveDate, EligibleEmployee, EligibilityOption } from '../types';

export const useEligibiltyModal = (eligibleEmployees: EligibleEmployee[]): {
	updateEligibility: UpdateEligibility,
	updateEffectiveDate: UpdateEffectiveDate,
	employeeEligibility: EmployeeEligibility,
} => {
	const [employeeEligibility, setEmployeeEligibility] = useState<EmployeeEligibility>({});

	useEffect(() => {
		const eligibilityData = {};
		eligibleEmployees.forEach((employee) => {
			eligibilityData[employee.id] = {
				...employee,
				eligibility: EligibilityOption.notEligible,
				effectiveDate: ''
			};
		}
		);
		setEmployeeEligibility(eligibilityData);
	}, [eligibleEmployees]);
	
	const updateEligibility = useCallback((id: number, value: EligibilityOption) => {
		setEmployeeEligibility((prevFormValues) => {
			return {
				...prevFormValues,
				[id]: {
					...prevFormValues[id],
					eligibility: value,
				},
			};
		});
	}, []);

	const updateEffectiveDate = useCallback((id: number, date: string) => {

		setEmployeeEligibility((prevFormValues) => {
			return {
				...prevFormValues,
				[id]: {
					...prevFormValues[id],
					effectiveDate: date,
				},
			};
		});
	}, []);

	return {
		updateEligibility,
		updateEffectiveDate,
		employeeEligibility,
	};
};
