export const payerType = Object.freeze({
    Employee: 'Employee',
    Employer: 'Employer',
});

export const taxTypeEngines = Object.freeze({
    OptionalEmployeePaidTax: 'OptionalEmployeePaidTax',
	PaidFamilyMedicalLeaveV2: 'PaidFamilyMedicalLeaveV2',
});

/**
 * Determines whether to display a sibling message based on the given parameters.
 *
 * This function evaluates various conditions related to the employee's tax configuration, 
 * the payer type, and the tax engine used. It will return `true` if the conditions satisfy 
 * the criteria for showing a message, and `false` otherwise.
 *
 * @param {number} employeePercent - The percentage of tax paid by the employee.
 * @param {string} payer - The type of payer, such as 'Employer' or 'Employee'. Should match values from `payerType`.
 * @param {string} taxTypeEngine - The tax engine used to calculate the tax type. Should match values from `taxTypeEngines`.
 * @param {string} taxTypeName - The name of the tax type being evaluated.
 * 
 * @returns {boolean} - Returns `true` if the sibling message should be shown, `false` otherwise.
 */
export function showSiblingMessage(employeePercent, payer, taxTypeEngine, taxTypeName) {
	if (!taxTypeName) {
		return false;
	}

	if (!taxTypeEngine) {
		return false;
	}

	if (taxTypeEngine !== taxTypeEngines.OptionalEmployeePaidTax && taxTypeEngine !== taxTypeEngines.PaidFamilyMedicalLeaveV2) {
		return false;
	}

	if (taxTypeEngine === taxTypeEngines.OptionalEmployeePaidTax && payer === payerType.Employer && employeePercent === 0) {
		return false;
	}

	return true;
}

/**
 * Reverses the substring '- EE' or '- ER' in the provided string and returns the modified string.
 *
 * If the string contains '- EE', it will be replaced with '- ER'.
 * If the string contains '- ER', it will be replaced with '- EE'.
 * If neither substring is found, the string is returned unchanged.
 *
 * @param {string} input - The input string that contains the substrings '- EE' or '- ER'.
 * @returns {string} - The modified string with the reversed substring, or the original string if no match is found.
 */
export function getReverseEmploymentSuffix(input) {
    if (input.includes('- EE')) {
        return input.replace('- EE', '- ER');
    }

	if (input.includes('-EE')) {
        return input.replace('-EE', '-ER');
    }
	
	if (input.includes('- ER')) {
        return input.replace('- ER', '- EE');
    }

	if (input.includes('-ER')) {
        return input.replace('-ER', '-EE');
    }
    
    // Return the original string if no substring match is found
    return input;
}
