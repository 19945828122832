import {
	makeStyles,
} from '@bamboohr/fabric';

const useStyles = makeStyles(({typography}) => ({
	label: {
		fontSize: typography.fabricFontSize('small'),
		fontWeight: typography.fontWeightBold,
	},
}));

interface LoanCellRowProps {
	label: string;
	value: string;
}

export const LoanCellRow = (props: LoanCellRowProps):JSX.Element => {
	const styles = useStyles();
	const {
		label,
		value
	} = props;

	return (
		<div>
			<span className={ styles.label }>{ `${ label }: ` }</span>
			<span>{ value }</span>
		</div>
	)
}
