import { BodyText, Divider, Flex, IconV2, LayoutBox, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import BEM from '@utils/dom/bem';

import { Provider, } from './state';

import { CompanyInfo } from './company-info';
import ToggleButton from './toggle-button';
import EnableModal from './enable-modal';
import DisableModal from './disable-modal';


const bem = (...args) => BEM.createClassName('SettingsAccountACA', ...args);


export default function AccountACA({ data }) {

	return (
		<Provider value={ { ...data, bem } }>
			<LayoutBox width={ifFeature("encore", "max-content", undefined)}>
				{
					ifFeature('encore',
					<LayoutBox>
						<LayoutBox>
							<LayoutBox marginBottom={2}>
								<BodyText color='neutral-strong' component='p' id='step-sub-headline' size='small' weight='medium'>
									{ (data && data.isEnabled) ? (
										$.__('Configure ACA-related settings for your account. ACA tracking makes available the following:')
									) : (
										$.__('Enable tracking to gain access to:')
									) }
								</BodyText>
							</LayoutBox>
							<LayoutBox>
								<Flex alignItems="center">
									<LayoutBox marginRight={0.5}>
										<IconV2 color="primary-strong" name="check-regular" size={20} />
									</LayoutBox>
									<BodyText color="neutral-extra-strong" size="small">{ $.__('ACA Minimum Value setting in Benefits Tracking') }</BodyText>
								</Flex>
								<Flex alignItems="center">
									<LayoutBox marginRight={0.5}>
										<IconV2 color="primary-strong" name="check-regular" size={20} />
									</LayoutBox>
									<BodyText color="neutral-extra-strong" size="small">{ $.__('ACA-related benefits and employment status reports') }</BodyText>
								</Flex>
							</LayoutBox>
							<LayoutBox marginBottom={1}>
								<TextButton
									href="https://help.bamboohr.com/hc/en-us/articles/216833417-ACA-Employer-Mandate"
									target="_blank"
									title={$.__('Learn more about ACA Employer Mandate')}
									type="button"
								>
									{ $.__('Learn more about ACA Employer Mandate') }
								</TextButton>
							</LayoutBox>
						</LayoutBox>
						<Divider color='neutral-extra-weak' light={true} marginBottom="0px !important" marginTop="8px !important" />
					</LayoutBox>,
					<>
						<div className="SettingsAccountACA__section">
							<p>
								{ (data && data.isEnabled) ? (
									$.__('Configure ACA-related settings for your account. ACA tracking makes available the following:')
								) : (
									$.__('Enable tracking to gain access to:')
								) }
							</p>
							<ul className="SettingsAccountACA__list">
								<li><span>{ $.__('ACA Minimum Value setting in Benefits Tracking') }</span></li>
								<li><span>{ $.__('ACA-related benefits and employment status reports') }</span></li>
							</ul>
							<a href="https://help.bamboohr.com/hc/en-us/articles/216833417-ACA-Employer-Mandate" rel="noopener noreferrer" target="_blank">
							{ $.__('Learn more about ACA Employer Mandate') }
							</a>
						</div>
						<hr />
					</>
					)
				}
				{ data.isEnabled && <CompanyInfo /> }
				<ToggleButton />
				<EnableModal />
				<DisableModal />
			</LayoutBox>
		</Provider>
	);
}
