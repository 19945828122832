import {
	COMPANY_TAX_SETTINGS_DROPDOWN_ITEM,
} from '../constants';
import type {
	CompanyTaxDataCollection,
	CompanyTaxDropdownItem,
} from '../types';

export function generateCompanyTaxSettingsGroupedItems(companyTaxes: CompanyTaxDataCollection): Array<CompanyTaxDropdownItem> {
	const groupedItems: Array<CompanyTaxDropdownItem> = [];
	if (!Array.isArray(companyTaxes)) {
		companyTaxes = [];
	}
	const companyTaxSettings = companyTaxes.map((companyTax): CompanyTaxDropdownItem => {
		const text = `${companyTax.clientName} (${companyTax.clientEin})`;
		return {
			clientEin: companyTax.clientEin,
			clientId: companyTax.clientId,
			clientName: companyTax.clientName,
			key: companyTax.clientEin,
			text,
			value: companyTax.clientId,
		};
	});
	groupedItems.push({
		...COMPANY_TAX_SETTINGS_DROPDOWN_ITEM,
		items: companyTaxSettings,
		type: 'group',
		value: undefined,
	});
	return groupedItems;
}
