import { ReactElement } from 'react';
import { canUseLocalStorage } from '@utils/localstorage';
import { Checkbox, Flex, ItemType, SelectField, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { OneTimeCodeInput } from 'one-time-code-input.react';
import { CODE_LENGTH_BACKUP, CODE_LENGTH_LOGIN } from '../../domain';
import { getInstructionText } from './etc';
import { AuthOptions } from '../../constants';
import { MfaCodeAuthenticationProps } from './types';
import { useStyles } from './styles';
import { SmsResendCode } from '../sms-mfa/sms-resend-code/sms-resend-code';
import { CircleArrow16x16 } from '@bamboohr/grim';

export function MfaCodeAuthentication(props: MfaCodeAuthenticationProps): ReactElement {
	const {
		enabledMfaChannels,
		isInvalid,
		type,
		onCodeChange,
		onRememberMeChange,
		onResendSms,
		rememberMeIsChecked,
		setCode,
		setType,
		lastFour = '',
		hitLimit,
	} = props;
	const authCodeLength = type === AuthOptions.BACKUP ? CODE_LENGTH_BACKUP : CODE_LENGTH_LOGIN;
	const isLocalStorageUsable = canUseLocalStorage();
	const styles = useStyles();

	const mfaChannelMap = {
		[AuthOptions.APP]: { text: $.__('Use authenticator app'), value: AuthOptions.APP },
		[AuthOptions.SMS]: { text: $.__('Send a text message'), value: AuthOptions.SMS },
		[AuthOptions.BACKUP]: { text: $.__('Use a backup code'), value: AuthOptions.BACKUP },
	};

	const getSelectItems = (): ItemType[] => {
		const filteredItems = enabledMfaChannels.filter((item) => item !== type);
		return filteredItems.map((el) => {
			return mfaChannelMap[el];
		});
	};

	const renderCountdown = () => {
		if (!hitLimit) {
			return <SmsResendCode onResendSms={onResendSms} />;
		}
		return (
			<TextButton onClick={onResendSms} startIcon={ifFeature('encore', 'arrow-rotate-right-regular', <CircleArrow16x16 />)} type='button'>
				{$.__(`Resend Code`)}
			</TextButton>
		);
	};

	return (
		<>
			<div className={`${styles.Auth} ${styles.authContent}`}>
				<div className={`${styles.Instructions} ${styles.mfaLoginInstructionText}`}>
					{getInstructionText(type, lastFour)}
					{isInvalid && (
						<div className={styles.InstructionsError}>
							<Message text={$._('Oops, something wasn’t right. Try again.')} />
						</div>
					)}
				</div>
				<div className={styles.Code}>
					<OneTimeCodeInput
						digitLength={authCodeLength}
						isInvalid={isInvalid}
						key={type}
						onChange={(newCode) => {
							onCodeChange(newCode);
							setCode(newCode);
							if (newCode.length === authCodeLength) {
								const nextButton = document.querySelector<HTMLButtonElement>('[data-button-next]');
								if (nextButton) {
									nextButton.focus();
								}
							}
						}}
					/>
				</div>
				{isLocalStorageUsable && (
					<div className={styles.RememberMe}>
						{type === AuthOptions.BACKUP ? null : (
							<Checkbox
								checked={rememberMeIsChecked}
								id='rememberMe'
								label={$.__('Trust this device')}
								name='rememberMe'
								onChange={({ checked }) => {
									onRememberMeChange(checked);
								}}
								value='rememberMe'
							/>
						)}
					</div>
				)}
				<Flex className={styles.mfaChannelContainer} justifyContent='center'>
					{type === AuthOptions.SMS && renderCountdown()}
					{enabledMfaChannels?.length > 1 && (
						<Flex flexDirection='column' justifyContent='center'>
							<div className={type === AuthOptions.SMS ? styles.smsResendCode : ''}>
								<SelectField
									id='mfaSelector'
									items={getSelectItems()}
									onSelect={(value) => {
										setType(value);
										if (value === AuthOptions.SMS) {
											onResendSms();
										}
									}}
									placeholder={$.__('Use a Different Method')}
									type='text'
									value={[]}
								/>
							</div>
						</Flex>
					)}
				</Flex>
			</div>
		</>
	);
}
