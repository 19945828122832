import classNames from 'classnames';
import {
	Fragment,
} from 'react';

import Tooltip from 'tooltip.react';

import { TOOLTIP_LIST_ITEM_MAX } from '../../constants';
import { EligibilityContract } from '../../types';
import { isDatePast } from '../../utils/benefit-plan-service';

import './eligible.styl';

function getEligibleFilters(eligible: EligibilityContract): string {
	let eligibleLabelItems = [];
	if (eligible && eligible.filters && eligible.filters.length) {
		eligibleLabelItems = [].concat(eligible.filters);
	}
	if (eligible && eligible.listOfEmployees && eligible.listOfEmployees.length) {
		eligibleLabelItems.push(getListOfEmployeesLabel(eligible.listOfEmployees));
	}
	return eligibleLabelItems.join(' + ');
}

function getListOfEmployeesLabel(listOfEmployees: string[]): string {
	if (listOfEmployees && listOfEmployees.length) {
		return `${ listOfEmployees.length } ${ (listOfEmployees.length === 1) ? $.__('Person') : $.__('People') }`;
	}
	return '';
}

function EligibleRow(props): JSX.Element {
	const {
		eligible,
	} = props;

	function getTooltipEmployees(): string[] {
		if (eligible.listOfEmployees.length > TOOLTIP_LIST_ITEM_MAX) {
			const newList = eligible.listOfEmployees.slice(0, TOOLTIP_LIST_ITEM_MAX);
			const modifiedLast = newList.pop();
			newList.push(`${ modifiedLast } ${ $.__('and') } ${ (eligible.listOfEmployees.length - TOOLTIP_LIST_ITEM_MAX) } ${ $.__('more') }...`);
			return newList;
		}
		return eligible.listOfEmployees;
	}

	return (
		<Fragment>
			{ (eligible && eligible.filters && eligible.filters.length > 0) ? (
				<Fragment>
					{ getEligibleFilters(eligible) }
				</Fragment>
			) : (
				<Fragment>
					{ (eligible && eligible.listOfEmployees && eligible.listOfEmployees.length > 0) && (
						<Tooltip
							settings={ {
								html: `<div class="TooltipStandard TooltipStandard--textCenter">${ getTooltipEmployees().join('<br />') }</div>`,
							} }
						>
							{ getListOfEmployeesLabel(eligible.listOfEmployees) }
						</Tooltip>
					) }
				</Fragment>
			) }
		</Fragment>
	);
}

interface EligibleProps {
	active: boolean;
	eligibleList: EligibilityContract[];
	endDate: string;
}

export function Eligible(props: EligibleProps): JSX.Element {
	const {
		active,
		eligibleList,
		endDate,
	} = props;

	return (
		<div className={ classNames({ 'Columns__eligible--inactive': (!active || isDatePast(endDate)) }) }>
			{ (eligibleList && eligibleList.length > 1) ? (
				<ol className="Columns__eligibleList">
					{ (eligibleList).map((eligible, index) => (
						<li key={ `eligible-${ index }` }>
							<EligibleRow eligible={ eligible } />
						</li>
					)) }
				</ol>
			) : (
				<Fragment>
					<EligibleRow eligible={ eligibleList[0] } />
				</Fragment>
			) }
		</div>
	);
}
