import { clamp } from 'lodash';
import {
	useEffect,
	useState,
} from 'react';
import { Modal } from 'modal-legacy';

import {
	formatEmployeeData,
	getAcaAssignedStatuses,
	getAcaMigrationWizard,
	resetModalContentScroll,
	validateStatusAlignment,
} from 'aca-status-alignment-table.react';

import {
	Conduct,
	Intro,
	Review,
} from './steps';
import { Title } from './title';
import { postAcaMigration } from './utils';

const STEPS = {
	Intro: 1,
	Conduct: 2,
	Review: 3,
};

export function AcaMigrationModal(props) {
	const {
		handleModalClose,
		isOpen,
	} = props;
	const startingStep = 1;
	const stepCount = 3;
	const [currentStep, setCurrentStep] = useState(startingStep);
	const [acaTypes, setAcaTypes] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);
	const [employmentStatusAcaTypes, setEmploymentStatusAcaTypes] = useState({});
	const [employmentStatuses, setEmploymentStatuses] = useState({});
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

	useEffect(() => {
		getAcaMigrationWizard()
			.then((response) => {
				if (response && response.data) {
					setEmploymentStatuses(response.data.employmentStatuses);
					setEmployeeData(formatEmployeeData(response.data.employees, response.data.employmentStatuses));
					setAcaTypes(response.data.acaStatuses);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	function handleAcaStatusAssignment(acaStatus, employmentStatusId) {
		setEmploymentStatusAcaTypes(getAcaAssignedStatuses(acaStatus, employmentStatusId, employmentStatusAcaTypes));
		if (employmentStatuses && currentStep === STEPS.Conduct) {
			setIsSubmitButtonDisabled(validateStatusAlignment(employmentStatuses, employmentStatusAcaTypes));
		} else {
			setIsSubmitButtonDisabled(false);
		}
	}

	function moveNext() {
		return clamp(currentStep + 1, 1, stepCount);
	}

	function movePrev() {
		return clamp(currentStep - 1, 1, stepCount);
	}

	return (
		<Modal
			alternativeAction={ (currentStep !== STEPS.Intro) ? () => {
				setCurrentStep(movePrev());
				resetModalContentScroll();
			} : null }
			alternativeActionText={ $.__('Go Back') }
			isOpen={ isOpen }
			onClose={ handleModalClose }
			primaryAction={ (currentStep === STEPS.Conduct && isSubmitButtonDisabled) ? null : () => {
				if (currentStep === stepCount) {
					postAcaMigration({
						employmentStatuses: employmentStatusAcaTypes,
					})
						.then(() => {
							handleModalClose();
							location.reload();
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					if (currentStep === STEPS.Conduct) {
						validateStatusAlignment(employmentStatuses, employmentStatusAcaTypes);
					}
					setCurrentStep(moveNext());
					resetModalContentScroll();
				}
			} }
			primaryActionText={ (currentStep < stepCount) ? $.__('Next') : $.__('Save and Finish') }
			title={ <Title currentStep={ currentStep } stepCount={ stepCount } /> }
			type="medium"
		>
			{ (currentStep === STEPS.Intro) && (
				<Intro />
			) }
			{ (currentStep === STEPS.Conduct) && (
				<Conduct
					acaTypes={ acaTypes }
					employmentStatusAcaTypes={ employmentStatusAcaTypes }
					employmentStatuses={ employmentStatuses }
					onAcaStatusAssignment={ handleAcaStatusAssignment }
				/>
			) }
			{ (currentStep === STEPS.Review) && (
				<Review
					employeeData={ employeeData }
					employmentStatusAcaTypes={ employmentStatusAcaTypes }
				/>
			) }
		</Modal>
	);
}
