import { LayoutBox } from '@bamboohr/fabric';
/* @startCleanup encore */
import {
	ifFeature,
} from '@utils/feature';
/* @endCleanup encore */

import { SectionStyledBox } from '../../../section-styled-box';
import { SectionStyledHeader } from '../../../section-styled-header';

export function OtherWithholding(props) {
	const { headerHeadlineSize, usesSectionComponent } = props;

	return ifFeature('encore',
		(
			<SectionStyledBox usesSectionComponent={ usesSectionComponent }>
				<fieldset>
					<LayoutBox marginBottom={3}>
						<SectionStyledHeader
							hasPopover={true}
							headlineSize={headerHeadlineSize}
							popoverContent={
								$.__('This is what you elected to withhold from your paychecks htmlFor tax purposes. Talk to your HR admin if you would like to make a change.')
							}
							popoverIcon="circle-question-regular"
							popoverPlacement="bottom"
						>
							{$.__('W-4 Withholding')}
						</SectionStyledHeader>
					</LayoutBox>
					<LayoutBox>
						<div className="floatLeft semibold pi-withhold__box">
							<div className="js-pi-federal-num"></div>
							<div>{ $.__('Federal') }</div>
						</div>
						<div className="floatLeft semibold pi-withhold__box">
							<div className="js-pi-state-num"></div>
							<div className="js-piStateName" id="js-pi-state-name"></div>
						</div>
						<p className="pi-withhold__written js-piWithholdWritten" id="js-pi-withHold-written"></p>
					</LayoutBox>
				</fieldset>
			</SectionStyledBox>
		),
		(
			<fieldset className="clear clearfix">
				<legend className="baseColor">
					{ $.__('W-4 Withholding') }
					<div
						className="semibold pi-withhold-help bhrTooltip"
						data-tip-content={ $.__('This is what you elected to withhold from your paychecks htmlFor tax purposes. Talk to your HR admin if you would like to make a change.') }
						data-tip-delay="100"
						data-tip-text-align="left"
					>
						?
					</div>
				</legend>
				<div className="clear clearfix">
					<div className="floatLeft semibold pi-withhold__box">
						<div className="js-pi-federal-num"></div>
						<div>{ $.__('Federal') }</div>
					</div>
					<div className="floatLeft semibold pi-withhold__box">
						<div className="js-pi-state-num"></div>
						<div className="js-piStateName" id="js-pi-state-name"></div>
					</div>
					<p className="pi-withhold__written js-piWithholdWritten" id="js-pi-withHold-written"></p>
				</div>
			</fieldset>
		)
	);
}
