import { LifeCoverageOptions, VoluntaryLifeCategoryDataOptionsContract } from '../../types';

export const mapVoluntaryLifeCoverageOptions = (
	planCategoryDataOption: VoluntaryLifeCategoryDataOptionsContract
) => {
	const options = {
		spouseCoverage: null,
		childCoverage: null,
		type: null,
		coverage: {
			employee: {
				amount: null,
				increment: null,
				maximumAmount: null,
				minimumAmount: null,
				multiplier: null,
			},
			spouse: {
				type: null,
				amount: null,
				increment: null,
				minimumAmount: null,
				maximumAmount: null,
				percentOfEmployeeCoverage: null,
			},
			child: {
				amount: null,
				increment: null,
				minimumAmount: null,
				maximumAmount: null,
			},
			type: null,
		},
		...(planCategoryDataOption as object),
	};

	return {
		spouseCoverage: options.spouseCoverage,
		childCoverage: options.childCoverage,
		type: options.coverage.type,
		employeeAmount: options.coverage.employee?.amount,
		employeeIncrement: options.coverage.employee?.increment,
		employeeMinimumAmount: options.coverage.employee?.minimumAmount,
		employeeMaximumAmount: options.coverage.employee?.maximumAmount,
		employeeMultiplier: options.coverage.employee?.multiplier,
		spouseAmount: options.coverage.spouse?.amount,
		spouseAmountType: options.coverage.spouse?.type,
		spouseIncrement: options.coverage.spouse?.increment,
		spouseMinimumAmount: options.coverage.spouse?.minimumAmount,
		spouseMaximumAmount: options.coverage.spouse?.maximumAmount,
		spousePercentOfEmployeeCoverage: options.coverage.spouse?.percentOfEmployeeCoverage,
		childAmount: options.coverage.child?.amount,
		childIncrement: options.coverage.child?.increment,
		childMinimumAmount: options.coverage.child?.minimumAmount,
		childMaximumAmount: options.coverage.child?.maximumAmount,
	} as LifeCoverageOptions;
};
