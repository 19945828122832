import c from 'classnames';

import './styles.styl';
export function CompanySelectOption(item) {

	const classes = c(
		'CompanySelectOption',
		{ 'CompanySelectOption--notActive': !item.isActive }
	);

	return (
		<div className={ classes }>
			<span className="CompanySelectOption__name truncate">{ item.text }</span>
			{ item.clientEin &&
				<span className="CompanySelectOption__ein truncate">({ item.clientEin })</span>
			}
		</div>
	);
}
