import { Box } from '@bamboohr/fabric';
import { ValidationError } from '@utils/validation';

import { CurrencySelectorField } from 'form-fields.react';

import { SetSpecialErrors } from '../../../../../../../types';
import { useReactivateDisabledField } from '../../../../../../hooks/use-reactivate-disabled-field';
import { CoverageLevelValue, Currency, PlanState } from '../../../../../../types';
import { isCompletePlan, validateFixedAmount } from '../../../../../../utils';
import { ErrorTooltip } from '../../error-tooltip/error-tooltip';
import { CoverageTableRow } from '../types';

interface TotalCostCellProps {
	currencies: Currency[];
	currencyCode: string;
	currencyWarning?: boolean;
	error?: ValidationError;
	handleCurrencyCodeChange: (currencyCode: string) => void;
	isFirstGroup: boolean;
	name: string;
	planState: PlanState;
	row: CoverageTableRow;
	setSpecialErrors: SetSpecialErrors;
	updateTotalCost: (newTotalCost: number, coverageLinkId: string) => void;
}

export const TotalCostCell = (props: TotalCostCellProps): JSX.Element => {
	const {
		currencies,
		currencyCode,
		currencyWarning = false,
		error,
		handleCurrencyCodeChange,
		isFirstGroup,
		name,
		planState,
		row,
		setSpecialErrors,
		updateTotalCost,
	} = props;
	const isDisabledForReactivate = useReactivateDisabledField(row.coverageLevel.totalCost, true);

	const showError = isFirstGroup ? Boolean(error) : false;

	const handleTotalCostReformat = (newTotalCost: number, coverageLevel: CoverageLevelValue, errorKey: string) => {
		updateTotalCost(newTotalCost, coverageLevel.linkId);
		validateFixedAmount(errorKey, newTotalCost, setSpecialErrors);
	};

	return (
		<Box display='flex' flexDirection='row'>
			{showError && (
				<Box alignSelf='center' paddingRight={1}>
					<ErrorTooltip message={error.message} />
				</Box>
			)}
			<CurrencySelectorField
				amount={row.coverageLevel.totalCost}
				currencies={currencies}
				currencyCode={currencyCode}
				currencyEditingDisabled={!isFirstGroup || isCompletePlan(planState) || isDisabledForReactivate}
				currencyWarning={currencyWarning}
				handleAmountReformat={(newFormattedValue, numericValue) => {
					handleTotalCostReformat(numericValue, row.coverageLevel, name);
				}}
				handleCurrencyCodeChange={handleCurrencyCodeChange}
				hasError={showError}
				inputDisabled={!isFirstGroup || isDisabledForReactivate}
				name={name}
				text={$.__('per month')}
			/>
		</Box>
	);
};
