import { Component } from 'react';

import './metered-textarea.styl';

export class MeteredTextarea extends Component {
	_getProgress() {
		const { characterCount } = this.state;
		const meter = {
			comment: '',
			progress: 'default',
		};

		if (characterCount >= 300) {
			meter.comment = $.__('This is gold, Jerry! Gold!');
			meter.progress = 'best';
		} else if (characterCount >= 200 && characterCount < 300) {
			meter.comment = $.__('Now we’re talking! Thanks for your great feedback.');
			meter.progress = 'best';
		} else if (characterCount >= 100 && characterCount < 200) {
			meter.comment = $.__('Ooh, this is better. But we’re sure you have a little bit more to add…');
			meter.progress = 'better';
		} else if (characterCount >= 50 && characterCount < 100) {
			meter.comment = $.__('C’mon…what else are you thinking? Add some more details.');
		} else {
			meter.comment = $.__('The more detail you can add, the better. Watch this meter as you type...');
		}

		return meter;
	}

	_renderComment() {
		const meter = this._getProgress();
		return (
			<div className={ `MeteredTextarea__comment MeteredTextarea__comment--${ meter.progress }` }>{ meter.comment }</div>
		);
	}

	_renderMeter() {
		const { characterCount } = this.state;
		const meter = this._getProgress();
		let width = Math.round((characterCount / 300) * 100);

		if (width < 2) {
			width = 2;
		}
		return (
			<div className={ `MeteredTextarea__progress MeteredTextarea__progress--${ meter.progress }` } style={ { width: `${ width }%` } }></div>
		);
	}

	_handleCharacterCountChange = (e) => {
		const string = e.target.value;
		const characterCount = string.length;
		const { onChange } = this.props;

		this.setState({ characterCount });
		onChange(string);
	}

	state = {
		characterCount: 0,
	}

	render() {
		const { value } = this.props;
		return (
			<div className="BhrForms MeteredTextarea fieldRow">
				<div aria-required="true" className="fieldBox required fullWidth">
					<div className="fieldDiv MeteredTextarea__wrapper">
						<textarea
							aria-required="true"
							className="fab-Textarea MeteredTextarea__body"
							name="body"
							onChange={ this._handleCharacterCountChange }
							value={ value }
						>
						</textarea>
						<div className="MeteredTextarea__meter">
							{ this._renderMeter() }
						</div>
					</div>
				</div>
				{ this._renderComment() }
			</div>
		);
	}
}
