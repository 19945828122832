import { makeStyles, createStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, typography }) => {
	return createStyles({
		marketingPromo: {
			paddingTop: 18,
			textAlign: 'center',
		},
		marketingPromoContainer: {
			marginLeft: 'auto',
			marginRight: 'auto',
			maxWidth: 682,
		},
		marketingPromoTitle: {
			fontSize: 32,
			fontWeight: typography.fontWeightSemibold,
			color: palette.primary.main,
			lineHeight: '42px',
		},
		marketingPromoVideo: {
			height: 386,
			marginTop: 40,
		},
		marketingPromoButton: {
			marginTop: 40,
		},
		marketingPromoContactMessage: {
			marginTop: 40,
		},
		marketingPromoSummary: {
			marginTop: 40,
			color: palette.gray[700],
			fontSize: 18,
			lineHeight: '26px',
		},
		marketingPromoCopyright: {
			color: palette.gray[1000],
			fontSize: ifFeature('encore', 11, 9), // This font-size will be supported soon for BodyText, but isn't currently
			lineHeight: ifFeature('encore', '14px', '11px'),
			marginTop: 60,
		},
	});
});
