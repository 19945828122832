import BEM from '@utils/dom/bem';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	createReducerContext,
} from '../../shared';

let prevActivePage;

const actions = {
	/**
	 * @param {State} state
	 * @param {{ id: string }} payload
	 * @returns {State}
	 */
	SET_LANGUAGE(state, { id }) {
		const url = new URL('', window.location.href);

		url.searchParams.set('lid', id);

		const newHref = url.href.replace(url.origin, '');
		window.history.pushState({}, null, newHref);
		$.pjax.reload('#settings-main-content');

		return {
			...state,
			loading: true,
		};
	},

	/**
	 * @param {State} state
	 * @param {{ id: string }} payload
	 * @returns {State}
	 */
	TOGGLE_PAGE(state, { id }) {
		const {
			pages,
			activePage,
		} = state;
		const page = pages.find(pg => pg.id == id);

		if (page) {
			prevActivePage = id == activePage ? null : id;

			return {
				...state,
				activePage: prevActivePage,
			};
		}
	},

	/**
	 * @param {State} state
	 * @param {{ item: FieldRT|PageRT }} payload
	 * @returns {State}
	 */
	SAVE_TRANSLATION(state, { item }) {
		const key = item.type === 'page' ? 'pagesRT' : 'fieldsRT';

		return {
			...state,
			[key]: {
				...state[key],
				[item.remoteId]: item,
			},
		};
	},

	/**
	 * @param {State} state
	 * @param {object} payload
	 * @param {object} payload.question
	 * @returns {State}
	 */
	SAVE_PERFORMANCE_TRANSLATION(state, data) {
		const {
			remoteId,
			languageId,
			type,
		} = data;
		const key = type === 'assessment_employee_question' ? 'eeRt' : 'mgrRt';

		return {
			...state,
			questionsRT: {
				...(state.questionsRT || {}),
				[remoteId]: {
					...(state.questionsRT ? state.questionsRT[remoteId] || {} : {}),
					[languageId]: {
						...(state.questionsRT[remoteId] ? state.questionsRT[remoteId][languageId] || {} : {}),
						[key]: data,
					},
				},
			},
		};
	},

	/**
	 * @param {State} state
	 * @param {State['modal']} modal
	 * @returns {State}
	 */
	OPEN_MODAL(state, { type, ...payload }) {
		const {
			listsFields,
			language: {
				value: langid,
			},
		} = state;

		if (type.startsWith('EDIT_')) {
			var {
				id: field_id,
				question,
			} = payload;

			payload = {
				field_id,
				langid,
				question,
			};

			if (listsFields[field_id]) {
				payload.listid = listsFields[field_id].id;
			}
		}

		return {
			...state,
			modal: {
				type,
				...payload,
			},
		};
	},

	/**
	 * @param {State} state
	 * @returns {State}
	 */
	CLOSE_MODAL: ({ modal, ...state}) => state,
};

/** @type {import('../../shared').ReducerContextAPI<State, typeof actions>} */
const {
	useReducerContext,
	Provider: ReducerProvider,
} = createReducerContext(actions);

const fab = (block, elem, mods) => BEM.create(`fab-${ block }`, elem, mods);

/** @returns {string} */
fab.Table = (elem, mods) => fab('Table', elem, mods);

const _bem = new BEM('SettingsAccountTranslations', null, {
	'table': [
		fab.Table(),
	],
	'tableHeader': [
		fab.Table('header'),
	],
	'tableRow': [
		({ page }) => fab.Table('row', {
			'--group': ifFeature('encore', page),
		}),
		({ page, isActive, parent, table }) => ({
			'__pageHeader': page,
			'__pageHeader--active': page && isActive,
			'__subTableRow': parent,
			'__subTableHeader': table,
		}),
	],
	'tableCell': [
		({ page }) => fab.Table('cell', {
			'--group': page,
			'__group': ifFeature('encore', page),
		}),
		({ page }) => ({
			'__group': ifFeature('encore', page),
		}),
	],
	'cellActions': [
		fab.Table('cellActions'),
	],
	'translationInput': [
		({
			size = 'medium',
			width = 5,
		}) => fab('TextInput', '^', [
			size,
			`width${ width }`,
		]),
	],
});

/**
 * @param {string} [elem]
 * @param {any} [mods]
 * @param {{ [key: string]: any }} [data]
 * @returns {string}
 */
const bem = (elem, mods, data = {}) => _bem.className(elem, mods, data);

export {
	useReducerContext,
};

/** @type {React.Provider<Context>} */
export const Provider = ({
	value,
	children,
	...props
}) => (
	<ReducerProvider
		value={ {
			...value,
			bem,
			fab,
		} }
		{ ...props }
	>
		{ children }
	</ReducerProvider>
);

/**
 * @typedef Field
 * @property {string} id
 * @property {string} [field_id]
 * @property {string} name
 * @property {'field'|'fields'|'table'} type
 * @property {Field[]} [fields]
 * @property {boolean} [history]
 * @property {boolean} [custom]
 */

/** @typedef {{ [id: string]: Field }} FieldList */

/**
 * @typedef FieldObj
 * @property {string} id
 * @property {string} type
 * @property {string} name
 * @property {string} [fieldSectionId]
 * @property {string} [fieldRowId]
 * @property {string} [description]
 */

/** @typedef {{ [id: string]: FieldObj }} Fields */

/**
 * @typedef FieldRT
 * @property {'yes'|'no'} clientTranslated
 * @property {string} id
 * @property {string} languageId
 * @property {boolean} locked
 * @property {string} [modifiedDate]
 * @property {string} remoteId
 * @property {'yes'|'no'} retranslate
 * @property {string} translation
 * @property {'field'} type
 */

/**
 * @typedef {{ [id: string]: FieldRT }} FieldsRT
 */

/**
 * @typedef Page
 * @property {string} id
 * @property {string} name
 * @property {Field[]} fields
 */

/**
 * @typedef PageRT
 * @property {'yes'|'no'} clientTranslated
 * @property {string} id
 * @property {string} languageId
 * @property {boolean} locked
 * @property {string} [modifiedDate]
 * @property {string} remoteId
 * @property {'yes'|'no'} retranslate
 * @property {string} translation
 * @property {'page'} type
 */

/** @typedef {{ [id: string]: PageRT }} PagesRT */

/**
 * @typedef Language
 * @property {string} text
 * @property {LanguageId} value
 */

/** @typedef {string} LanguageId */

/**
 * @typedef ListsField
 * @property {string} id
 * @property {string} field_id
 */

/** @typedef {{ [id: string]: ListsField }} ListsFields */

/** @typedef {{ [id: string]: string }} ListValueCounts */

/**
 * @typedef ListValueNeedTrans
 * @property {string} fcnt
 * @property {string} field_id
 * @property {string} id
 * @property {string} rtcnt
 */

/** @typedef {{ [id: string]: ListValueNeedTrans}} ListValuesNeedTrans */

/**
 * @typedef State
 * @property {Page[]} pages
 * @property {PagesRT} pagesRT
 * @property {Fields} fields
 * @property {FieldsRT} fieldsRT
 * @property {ListsFields} listsFields
 * @property {ListValueCounts} listValueCounts
 * @property {ListValuesNeedTrans} listValuesNeedTrans
 * @property {Language} language
 * @property {Language[]} userLanguage
 * @property {Language[]} languages
 * @property {string} [activePage]
 * @property {boolean} [loading]
 * @property {{ type: 'EDIT_LIST'|'EDIT_VALUE' } & { [key: string]: any}} [modal]
 * @property {typeof bem} bem
 * @property {typeof fab} fab
 */

/** @typedef {import('../../shared').ReducerContext<State, typeof actions>} Context */

/**
 * @typedef SetLanguagePayload
 * @property {LanguageId} id
 */

/**
 * @typedef SetLanguageAction
 * @property {'SET_LANGUAGE'} type
 * @property {SetLanguagePayload} payload
 */

/**
 * @typedef TogglePagePayload
 * @property {string} id
 */

/**
 * @typedef TogglePageAction
 * @property {'TOGGLE_PAGE'} type
 * @property {TogglePagePayload} payload
 */

/**
 * @typedef SaveTranslationPayload
 * @property {Field} field
 * @property {string} value
 */

/**
 * @typedef SaveTranslationAction
 * @property {'SAVE_TRANSLATION'} type
 * @property {SaveTranslationPayload} payload
 */

/**
 * @typedef OpenModalPayload
 * @property {string} type
 */

/**
 * @typedef OpenModalAction
 * @property {'OPEN_MODAL'} type
 * @property {OpenModalPayload} payload
 */

/** @typedef {SetLanguageAction | TogglePageAction | SaveTranslationAction | OpenModalAction} TranslationTableAction */
/** @typedef {React.Reducer<State, TranslationTableAction>} TranslationTableReducer */
