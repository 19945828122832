import classNames from 'classnames';
import { isEqual, map } from 'lodash';
import { Component } from 'react';

import { FloatingIconButton } from '@fabric/button';
import { Select } from '@fabric/select';

import { IconButton, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LEVEL_ID_PREFIX, SPECIFIC_PERSON, USER_GROUP } from '../../utils/constants';

export class WorkflowLevel extends Component {
	constructor(props) {
		super(props);

		const { approval, onAlteredState, userGroups } = this.props;

		this.approval = approval;
		this._handleAlteredState = onAlteredState;

		this.state = {
			approvalId: approval.id,
			userGroups: userGroups,

			...this._generateSelectData(),
		};
	}

	_generateSelectData() {
		const { approval, approverOptions, availableUsers, userGroups } = this.props;

		let selectedApprovals = [];
		let selectedPeople = [];
		let selectedGroups = [];
		return {
			approvalRolesData: {
				items: approverOptions.map(({ id, name }) => {
					if (approval.approvalBy === id) {
						selectedApprovals.push(id);
					}

					return {
						displayText: name,
						selected: approval.approvalBy === id,
						text: name,
						value: id,
					};
				}),
				selectedValues: selectedApprovals,
			},
			specificPeopleData: {
				items: availableUsers.map(({ id, name }) => {
					if (approval.specificPersonUserId === id) {
						selectedPeople.push(id);
					}
					return {
						displayText: name,
						selected: approval.specificPersonUserId === id,
						text: name,
						value: id,
					};
				}),
				selectedValues: selectedPeople,
			},
			userGroupsData: {
				items: userGroups.map(({ id, name }) => {
					if (approval.approvalGroupIds && approval.approvalGroupIds.includes(id)) {
						selectedGroups.push(id);
					}
					return {
						displayText: name,
						selected: approval.approvalGroupIds && approval.approvalGroupIds.includes(id),
						text: name,
						value: id,
					};
				}),
				selectedValues: selectedGroups,
			},
		};
	}

	_generateShowDropdownConfig(roleSelection) {
		return {
			showSpecificPeople: roleSelection === SPECIFIC_PERSON,
			showUserGroups: roleSelection === USER_GROUP,
		};
	}

	_handleChangeApprovalRoleState({ levelDropdowns, prevState, roleSelection }) {
		const newApprovalRolesData = {
			items: prevState.approvalRolesData.items.map((data) => {
				return {
					...data,
					selected: roleSelection === data.value,
				};
			}),
		};
		return {
			approvalRolesData: newApprovalRolesData,
		};
	}

	_handleChangeApprovalRole(item, selectedItems) {
		let roleSelection = selectedItems[0]?.value;

		const levelDropdowns = this._levelReset(roleSelection);
		this.setState(
			(prevState) => {
				return this._handleChangeApprovalRoleState({ levelDropdowns, prevState, roleSelection });
			},
			() => {
				this._handleAlteredState(this);
			}
		);
	}

	_handleChangeSpecificPeopleState({ itemValue, prevState }) {
		const newSpecificPeopleData = {
			items: prevState.specificPeopleData.items.map((data) => {
				return {
					...data,
					selected: itemValue === data.value,
				};
			}),
		};
		return {
			specificPeopleData: newSpecificPeopleData,
		};
	}

	_handleChangeSpecificPeople(item, selectedItems) {
		let itemValue = (item && item.value) || '';

		if (selectedItems.length > 0) {
			itemValue = selectedItems[0].value;
		}

		this.approval.specificPersonUserId = itemValue;
		this.setState(
			(prevState) => {
				return this._handleChangeSpecificPeopleState({ itemValue, prevState });
			},
			() => {
				this._handleAlteredState(this);
			}
		);
	}

	_handleChangeUserGroupsState({ newApprovalGroupIds, prevState }) {
		const newUserGroupsData = {
			items: map(prevState.userGroups, ({ id, name }) => {
				return {
					displayText: name,
					selected: (newApprovalGroupIds && newApprovalGroupIds.includes(id)) || false,
					value: id,
				};
			}),
		};
		return {
			userGroupsData: newUserGroupsData,
		};
	}

	_handleChangeUserGroups(item) {
		const newApprovalGroupIds = (this.approval.approvalGroupIds || []).slice(0);
		this.approval.approvalGroupIds = item;
		this.setState(
			(prevState) => {
				return this._handleChangeUserGroupsState({ newApprovalGroupIds, prevState });
			},
			() => {
				this._handleAlteredState(this);
			}
		);
	}

	_levelReset(roleSelection = '') {
		const { approvalBy: currentSelection } = this.approval;

		this.approval.approvalBy = roleSelection;

		if (currentSelection === roleSelection) {
			return;
		}
		if (currentSelection === SPECIFIC_PERSON) {
			this.approval.specificPersonUserId = undefined;
		}
		if (currentSelection === USER_GROUP) {
			this.approval.approvalGroupIds = [];
		}
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps, this.props)) {
			const { approval } = this.props;

			this.approval = approval;

			this.setState(() => {
				return this._generateSelectData();
			});
		}
	}

	render() {
		const { approvalId, approvalRolesData, specificPeopleData, userGroupsData } = this.state;

		const { advancedApprovalPath, editState, editingWorkflow, id, levelsCount, levelsRef, onRemove, workflowLevel } = this.props;

		const { showSpecificPeople, showUserGroups } = this._generateShowDropdownConfig(this.approval.approvalBy);

		const editingThisWorkflow = (editState && editingWorkflow === Number(id)) || Number(id) === 0 || !advancedApprovalPath;

		if (!editingThisWorkflow) {
			specificPeopleData.items = specificPeopleData.items.filter((specificPersonData) => {
				return specificPersonData.selected;
			});
		}

		const classnamesRemoveLevel = classNames('Approvals__removeLevel', 'showOnEdit', { hidden: levelsCount <= 1 });

		return (
			<div className="Approvals__level" data-level={ workflowLevel }>
				{ifFeature('encore', 
					<span className="Approvals__level-mark">
						<IconV2 name={`circle-${workflowLevel}-solid`} size={24} color='neutral-strong'/>
					</span>,
					<span className="Approvals__level-mark" data-bi-id={ 'settings-approvals-approver-level-' + workflowLevel + '-span' }>{ workflowLevel }</span>)}
				<span className="Approvals__select">
					<Select
						isClearable={approvalId.indexOf(LEVEL_ID_PREFIX) !== -1}
						isDisabled={editingThisWorkflow ? null : true}
						items={approvalRolesData.items}
						menuWidth={7}
						name={`approval_by[${workflowLevel}]`}
						onChange={(item, selectedItems) => {
							this._handleChangeApprovalRole(item, selectedItems);
						}}
						ref={showSpecificPeople || showUserGroups ? null : levelsRef}
						selectedValues={approvalRolesData.selectedValues}
						showSearch={'never'}
						size='medium'
						width='7'
					/>
				</span>
				{showSpecificPeople && (
					<div className='Approvals__people'>
						<Select
							isClearable={ifFeature('encore', editingThisWorkflow, true)}
							isDisabled={editingThisWorkflow ? null : true}
							items={specificPeopleData.items}
							menuWidth={7}
							name={`specific_person[${workflowLevel}]`}
							onChange={(item, selectedItems) => {
								this._handleChangeSpecificPeople(item, selectedItems);
							}}
							ref={levelsRef}
							selectedValues={specificPeopleData.selectedValues}
							showSearch={'auto'}
							size='medium'
							width='7'
						/>
					</div>
				)}
				{showUserGroups && (
					<div className='Approvals__group'>
						<Select
							canSelectMultiple={true}
							isClearable={ifFeature('encore', editingThisWorkflow, true)}
							isDisabled={editingThisWorkflow ? null : true}
							items={userGroupsData.items}
							menuWidth={7}
							name={`approval_groups[${workflowLevel}]`}
							onChange={(item, selectedItems) => {
								this._handleChangeUserGroups(item, selectedItems);
							}}
							ref={levelsRef}
							selectedValues={userGroupsData.selectedValues}
							showSearch={'never'}
							size='medium'
							width='7'
						/>
					</div>
				)}
				<span className={classnamesRemoveLevel}>
					{ifFeature(
						'encore',
						<IconButton
							color='primary'
							floatingIcon={true}
							icon='circle-xmark-solid'
							onClick={() => {
								onRemove(this);
							}}
							size='small'
							type='button'
							variant='outlined'
						/>,
						<FloatingIconButton
							clickAction={() => {
								onRemove(this);
							}}
							icon='fab-circle-x-16x16'
							secondary={true}
							size='teenie'
							type='button'
						/>
					)}
				</span>
			</div>
		);
	}
}
