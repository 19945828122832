import { render } from 'base/wrapped-render';
import { onPjaxEnd, onReady } from 'ctrl.deco';
import { PoMicroFrontend } from 'micro-frontend.react';

export class HiringFlows {
	@onReady()
	@onPjaxEnd()
	onReady(): void {
		render(<PoMicroFrontend />, document.getElementById('js-settingsFlowsApp'));
	}
}
