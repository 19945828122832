import React, { FunctionComponent, useState } from 'react';

import { Button } from '@fabric/button';
import { EmploymentStatusModal } from 'employment-status-modal.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface AddButtonWrapperProps {
	acaStatuses: string[];
	listId: number;
}

export const AddButtonWrapper: FunctionComponent<AddButtonWrapperProps> = (props) => {
	const { acaStatuses, listId } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleClick = (): void => {
		setIsModalOpen(true);
	};

	return (
		<React.Fragment>
			<div className='EmploymentStatusWrapper__addButtonContainer'>
				<Button
					size='small'
					children={$.__('Add')}
					type='button'
					variant='outlined'
					startIcon={ifFeature('encore', 'circle-plus-regular', 'fab-circle-plus-14x14')}
					onClick={handleClick}
				/>
			</div>
			<EmploymentStatusModal
				acaStatuses={acaStatuses}
				closeModal={(): void => setIsModalOpen(false)}
				isEdit={false}
				isOpen={isModalOpen}
				listId={listId}
				showAcaStatus={true}
			/>
		</React.Fragment>
	);
};
