import { connect } from 'react-redux';

import { Header } from '../components/header';
import {
	getData,
	handleRestoreApproval
} from '../store/actions';

const mapStateToProps = (state) => {
	const { workflow } = state;
	const {
		active,
		advancedApprovalToolEnabled,
		advancedWorkflow,
		curAppStatus,
		customFieldCount,
		description,
		id,
		lastModified,
		name,
		roleSectionNote,
		pathType,
		template,
		templateName,
		type
	} = workflow;
	return {
		active,
		advancedApprovalToolEnabled,
		advancedWorkflow,
		curAppStatus,
		customFieldCount,
		description,
		id,
		lastModified,
		name,
		pathType,
		roleSectionNote,
		template,
		templateName,
		type
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getWorkflowData: (workflowId) => {
			return dispatch(getData(workflowId));
		},
		handleRestoreApproval: (workflowId, templateName) => {
			return dispatch(handleRestoreApproval(workflowId, templateName));
		}
	}
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
