import { values } from 'lodash';

import { FilterData, FiltersPayload } from '../../../../types';
import { CheckboxGroupKeys } from './types';

interface FormatDataParams {
	filters: FilterData;
	categoryValue: string;
	selectedFilters: FiltersPayload;
}

export const formatFilterData = (params: FormatDataParams): Array<CheckboxGroupKeys> => {
	const { filters, categoryValue, selectedFilters } = params;
	const filterDataToArray = values(filters);

	return filterDataToArray
		.map((currentFilter) => {
			const { id, label } = currentFilter;
			let checked = false;

			if (selectedFilters && selectedFilters[categoryValue]?.indexOf(id) >= 0) {
				checked = true;
			}

			return {
				checked,
				label,
				value: id,
			};
		})
		.sort((a, b) => a?.label.localeCompare(b?.label));
};
