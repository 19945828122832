import { Flex, IconButton, Tooltip, Typography } from "@bamboohr/fabric";
import { InfoCircle13x13 } from "@bamboohr/grim";
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function EmployeesColumnHeader() {
	return (
		<Flex flexDirection='row' gap={0.5}>
			<Typography>{$.__('Employees')}</Typography>
			<Tooltip content={$.__('Number of employees able to log time to this project')}>
				{ifFeature('encore', <IconButton floatingIcon noBoundingBox icon="circle-info-solid" />, <IconButton floatingIcon noBoundingBox icon={<InfoCircle13x13 />} />)}
			</Tooltip>
		</Flex>
	)
}
