import {
	useState,
} from 'react';
import {
	TextButton,
} from '@fabric/button';

import { DeductionNameInput } from '../deduction-name-input';
import {
	NormalizedDeductions,
} from '../../utils';

import './add-deduction-row.styl';

interface AddDeductionRowProps {
	managedDeductionType: string;
	deductions: NormalizedDeductions;
	setCurrentDeductions(deductions: NormalizedDeductions): void;
	setEditingId(id: number|null): void;
}

export function AddDeductionRow(props: AddDeductionRowProps): JSX.Element {
	const {
		managedDeductionType,
		deductions,
		setCurrentDeductions,
		setEditingId,
	} = props;

	const [inputOpen, setInputOpen] = useState(false);

	if (inputOpen) {
		return (
			<div className="AddDeductionRow">
				<DeductionNameInput
					companyDeductionId={ null }
					deductionName=""
					deductions={ deductions }
					isEdit={ false }
					managedDeductionType={ managedDeductionType }
					setCurrentDeductions={ setCurrentDeductions }
					setEditingId={ setEditingId }
					setShowInput={ setInputOpen }
				/>
			</div>
		);
	}

	return (
		<div className="AddDeductionRow">
			<TextButton
				clickAction={ (): void => setInputOpen(true) }
				text={ `+ ${ $.__('Add Post-Tax Deduction') }` }
				type="button"
			/>
		</div>
	);
}
