import { validateGreaterThanOrEqualTo } from './rules';
import {
	validateCoveragePercentAmount,
	validateEmployeeCoverageMultiplier,
	validateFixedDependentCoverage,
	validateFixedEmployeeCoverage,
	validateSpousePercentOfEmployeeCoverage,
	validateVoluntaryEmployeeMultiplier,
	validateVoluntaryIncrement,
	validateVoluntaryMaximum,
} from './special-errors';
import { LifeCoverageEnum, LifeCoverageOptions, SalaryBasedSpouseType } from '../../types';
import { SetSpecialErrors } from '../../../types';

export function areLifeCoverageAmountsValid(coverageOptions: LifeCoverageOptions, setSpecialErrors: SetSpecialErrors) {
	let isSpouseValid = true;
	let isChildValid = true;
	let isEmployeeValid = true;
	let isMultiplierValid = true;
	let spouseCoverageKey = 'spouse_amount';
	const maximumAmountKey = 'maximumAmount';
	const {
		spouseCoverage,
		childCoverage,
		type,
		employeeAmount,
		employeeIncrement,
		employeeMultiplier,
		employeeMinimumAmount,
		employeeMaximumAmount,
		childAmount,
		childIncrement,
		childMaximumAmount,
		childMinimumAmount,
		spouseAmount,
		spouseAmountType,
		spouseIncrement,
		spouseMaximumAmount,
		spouseMinimumAmount,
		spousePercentOfEmployeeCoverage,
	} = coverageOptions;

	const MINIMUM_EMPLOYEE = 1000;
	const MINIMUM_DEPENDENT = 500;

	switch (type) {
		case LifeCoverageEnum.fixed:
			isSpouseValid =
				!spouseCoverage ||
				(spouseCoverage && validateFixedDependentCoverage(spouseCoverageKey, Number(spouseAmount), setSpecialErrors));
			isChildValid = getIsChildValid();
			isEmployeeValid = validateFixedEmployeeCoverage('employee_amount', Number(employeeAmount), setSpecialErrors);
			break;
		case LifeCoverageEnum.salary:
			isMultiplierValid = validateEmployeeCoverageMultiplier('multiplier', employeeMultiplier, setSpecialErrors);
			spouseCoverageKey = getSpouseInputKey(spouseCoverage);
			if (spouseCoverage) {
				isSpouseValid =
					(spouseAmountType === SalaryBasedSpouseType.percentage &&
						validateCoveragePercentAmount(spouseCoverageKey, Number(Number(spouseAmount)), setSpecialErrors)) ||
					(spouseAmountType === SalaryBasedSpouseType.fixed_amount &&
						validateFixedDependentCoverage(spouseCoverageKey, Number(spouseAmount), setSpecialErrors));
			}
			isChildValid = getIsChildValid();
			isEmployeeValid =
				employeeMaximumAmount && employeeMinimumAmount
					? validateGreaterThanOrEqualTo(
							maximumAmountKey,
							Number(employeeMaximumAmount),
							Number(employeeMinimumAmount),
							setSpecialErrors
					  )
					: true;
			isEmployeeValid = isEmployeeValid && validateFixedEmployeeCoverage(maximumAmountKey, employeeMaximumAmount, setSpecialErrors);
			isEmployeeValid = isEmployeeValid && validateEmployeeCoverageMultiplier('multiplier', employeeMultiplier, setSpecialErrors);
			break;
		case LifeCoverageEnum.voluntary: {
			const isEmployeeIncrementValid = validateVoluntaryIncrement(
				'employeeIncrement',
				employeeIncrement,
				MINIMUM_EMPLOYEE,
				setSpecialErrors
			);
			const isEmployeeMaximumAmountValid = validateVoluntaryMaximum(
				'employeeMaximumAmount',
				employeeMaximumAmount,
				employeeMinimumAmount,
				employeeIncrement,
				MINIMUM_EMPLOYEE,
				setSpecialErrors
			);

			const isEmployeeMultiplierValid = validateVoluntaryEmployeeMultiplier(
				'employeeMultiplier',
				employeeMultiplier,
				setSpecialErrors
			);

			isEmployeeValid = isEmployeeIncrementValid && isEmployeeMaximumAmountValid && isEmployeeMultiplierValid;

			if (spouseCoverage) {
				const isSpouseIncrementValid = validateVoluntaryIncrement(
					'spouseIncrement',
					spouseIncrement,
					MINIMUM_DEPENDENT,
					setSpecialErrors
				);

				const isSpouseMaximumAmountValid = validateVoluntaryMaximum(
					'spouseMaximumAmount',
					spouseMaximumAmount,
					spouseMinimumAmount,
					spouseIncrement,
					MINIMUM_DEPENDENT,
					setSpecialErrors
				);

				const isSpousePercentOfEmployeeCoverageValid = validateSpousePercentOfEmployeeCoverage(
					'spousePercentOfEmployeeCoverage',
					spousePercentOfEmployeeCoverage,
					setSpecialErrors
				);

				isSpouseValid = isSpouseIncrementValid && isSpouseMaximumAmountValid && isSpousePercentOfEmployeeCoverageValid;
			}
			if (childCoverage) {
				const isChildIncrementValid = validateVoluntaryIncrement(
					'childIncrement',
					childIncrement,
					MINIMUM_DEPENDENT,
					setSpecialErrors
				);

				const isChildMaximumAmountValid = validateVoluntaryMaximum(
					'childMaximumAmount',
					childMaximumAmount,
					childMinimumAmount,
					childIncrement,
					MINIMUM_DEPENDENT,
					setSpecialErrors
				);

				isChildValid = isChildIncrementValid && isChildMaximumAmountValid;
			}
			break;
		}
		default:
			break;
	}

	return isSpouseValid && isChildValid && isEmployeeValid && isMultiplierValid;

	function getIsChildValid(): boolean {
		return !childCoverage || (childCoverage && validateFixedDependentCoverage('child_amount', Number(childAmount), setSpecialErrors));
	}
	function getSpouseInputKey(spouseCoverage: boolean): string {
		if (!spouseCoverage) {
			return '';
		}
		return spouseAmountType === SalaryBasedSpouseType.percentage ? 'spouse_percent_amount' : 'spouse_fixed_amount';
	}
}
