import Ajax from '@utils/ajax';

const POST = () => {
	return Ajax.post(`/settings/benefits/enable_ben_admin_trial`).then((response) => {
		if (response.status >= 400) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
		return response;
	});
};

export default POST;
