import {
	Dispatch,
	SetStateAction,
} from 'react';

import { cloneDeep } from 'lodash';

import {
	AddPlanFormDataObject,
	DeductionObject,
	CreateBenefitPlanDeductionPayloadObject
} from '../types';

export const buildCreateBenefitPlanDeductionPayload = (
	deduction: DeductionObject,
	planFormData: AddPlanFormDataObject,
	planType: string,
	setPlanFormData: Dispatch<SetStateAction<AddPlanFormDataObject>>,
): CreateBenefitPlanDeductionPayloadObject => {
	const newPlanFormData = cloneDeep(planFormData);
	let isValid = true;

	const payload = {
		traxCldId: deduction.traxCldId,
		traxClientId: deduction.traxClientId,
		traxCldStartDate: deduction.traxCldStartDate,
		traxCldEndDate: deduction.traxCldEndDate || null,
		deductionTypeId: deduction.deductionTypeId,
		planType,
		planName: deduction.deductionName,
		planStartDate: (newPlanFormData.startDate.value as string),
		planEndDate: (newPlanFormData.endDate.value as string) || null,
		offsetAmount: (newPlanFormData.offsetAmount.value as number),
		offsetDirection: (newPlanFormData.beforeOrAfter.value as string),
		minValueStandard: (newPlanFormData.meetsValueStandard.value as boolean) || null,
		minEssentialCoverage: (newPlanFormData.meetsEssentialCoverage.value as boolean) || null,
		safeHarbor: deduction.useSafeHarbor || null,
		traxEldIds: deduction.employees.map(employee => employee.traxEldId),
	}

	for (const fieldName in newPlanFormData) {
		if (newPlanFormData[fieldName].required && !newPlanFormData[fieldName].value) {
			newPlanFormData[fieldName].error = true;
			isValid = false;
		}
	}

	if (!isValid) {
		setPlanFormData(newPlanFormData);
		return null;
	}

	return payload;
}
