import { Button, Divider, Flex, LayoutBox, RadioGroup, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { usePerformanceSettingsContext } from '../../../../context';
import { useGoalSettingsContext } from '../../context';
import { saveGoalFilters } from '../../services/save-goal-filters';
import { updateEmployeeMatches } from '../../services/update-employee-matches';
import { EmployeesIncluded, FilterListData } from '../../types';
import { EmployeeFilter } from '../employee-filter.react';
import { radioOptions } from './constants';

interface Props {
	filterListData: FilterListData;
	selectedGoalGroup: string;
}

export const GoalSettingsWrapper = (props: Props): JSX.Element => {
	const { filterListData, selectedGoalGroup = 'none' } = props;
	const [{ selectedFilters }, { setCancelButtonClicked, setSelectedFilters }] = useGoalSettingsContext();
	const [, { setSlidedownData }] = usePerformanceSettingsContext();

	const originalSelectedFilters = useRef(selectedFilters);

	const [defaultSelectedOption, setDefaultSelectedOption] = useState<string>(selectedGoalGroup);
	const [selectedGroupOption, setSelectedGroupOption] = useState<string>(defaultSelectedOption);
	const [selectedEmployeeCount, setSelectedEmployeeCount] = useState<number>(null);
	const [areButtonsProcessing, setAreButtonsProcessing] = useState<boolean>(false);

	useEffect(() => {
		updateEmployeeMatches(selectedFilters).then((data: EmployeesIncluded) => {
			setSelectedEmployeeCount(data.count);
		});
	}, [selectedFilters]);

	const handleCancelSelect = () => {
		setSelectedGroupOption(defaultSelectedOption);

		if (defaultSelectedOption !== 'some') {
			setSelectedFilters(null);
		} else if (defaultSelectedOption === 'some') {
			setSelectedFilters(originalSelectedFilters.current);
			setCancelButtonClicked(true);
		}
	};

	const onOptionSave = async () => {
		let goalGroupSelected = selectedGroupOption;
		setAreButtonsProcessing(true);

		if (selectedGroupOption === 'some' && isEmpty(selectedFilters)) {
			goalGroupSelected = 'all';
			setSelectedGroupOption('all');
		} else if (selectedGroupOption !== 'some') {
			setSelectedFilters(null);
		}

		await saveGoalFilters(selectedFilters, goalGroupSelected)
			.then(() => {
				setDefaultSelectedOption(goalGroupSelected);
				setSlidedownData({
					message: $.__('Goal settings were successfully saved.'),
					type: 'success',
				});
				originalSelectedFilters.current = selectedFilters;
			})
			.catch(() => {
				setSlidedownData({
					message: $.__('Oops something went wrong! Try saving again later.'),
					type: 'error',
				});
			});

		setAreButtonsProcessing(false);
	};

	const shouldSaveButtonDisable = () => {
		const isDefaultAndSelectedOptionSomeEmployees = defaultSelectedOption === 'some' && selectedGroupOption === 'some';
		if (isDefaultAndSelectedOptionSomeEmployees && !isEqual(selectedFilters, originalSelectedFilters.current)) {
			return false;
		}

		if (defaultSelectedOption === selectedGroupOption) {
			return true;
		}

		return false;
	};

	return (
		<>
			<form>
				<LayoutBox marginBottom='21px'>
					<RadioGroup
						items={radioOptions}
						label={$.__('Which employees do you want to be able to create and share goals?')}
						onChange={({ value }): void => setSelectedGroupOption(value as string)}
						value={selectedGroupOption}
					/>

					{selectedGroupOption === 'some' && (
						<LayoutBox margin='18px 0 3px'>
							<EmployeeFilter filterListData={filterListData} selectedEmployeeCount={selectedEmployeeCount} />
						</LayoutBox>
					)}
				</LayoutBox>

				{ifFeature('encore', undefined, <Divider light noGap />)}
				<Flex alignItems='center' gap={1} paddingTop={ifFeature('encore', 1, '24px')}>
					<Button
						onClick={() => onOptionSave()}
						data-bi-id='goal-settings-save-settings-button'
						disabled={shouldSaveButtonDisable()}
						processing={areButtonsProcessing}
						size={ifFeature('encore', 'medium', 'large')}
						type='button'
					>
						{$.__('Save')}
					</Button>
					{!shouldSaveButtonDisable() && (
						<TextButton
							onClick={() => handleCancelSelect()}
							data-bi-id='goal-settings-cancel-settings-button'
							processing={areButtonsProcessing}
							size={ifFeature('encore', 'medium', 'large')}
							type='button'
						>
							{$.__('Cancel')}
						</TextButton>
					)}
				</Flex>
			</form>
		</>
	);
};
