import { CurrencyItem } from '@bamboohr/fabric';
import { isSafeHarborIncluded } from './general';
import { getInitialEligibilityGroups } from './get-initial-eligibility-groups';
import { planTypeAllowsDefinedCosts } from './plan-type-allows-defined-costs';
import { DEFAULT_AGE_BANDS } from '../eligibility-cost/constants';
import { CONTRACT_FILTER_KEYS, DEFAULT_CLASS_CODES, DEFAULT_COVERAGE_OPTIONS_VALUES } from '../constants';
import {
	isDeductionOffsetIncluded,
	isDeductionTypeSupported,
	isPreTaxFieldIncluded,
	isReimbursementAmountIncluded,
} from '../steps/utils';
import {
	Agreement,
	AutoEnrollOptionEnum,
	AutoEnrollTypeEnum,
	BasicLifeCategoryDataOptionsContract,
	BasicLifeOrADandDEnum,
	BenefitPlanGetContract,
	BooleanSelectValue,
	ClassCodeDetails,
	CostTypeEnumContract,
	CoverageCostGetContract,
	CoverageCostValues,
	CoverageLevelValue,
	CustomCoverageLevelGetContract,
	DeductionFrequencyContract,
	DeductionOccurrenceValue,
	DeductionOffsetValue,
	DeductionTypeEnumContract,
	DeductionTypeValue,
	DisabilityCategoryDataOptionsContract,
	DisabilityDisbursementTypeEnum,
	EligibilityFilterValues,
	EmployeeCostTypeValue,
	FiltersContract,
	FilterType,
	HealthCategoryDataOptionsContract,
	IsLegalTextIncludedEnum,
	LifeCoverageOptions,
	LifeInsuranceTypeEnum,
	LossOfEligibilityContract,
	NewEligibilityGroupGetContract,
	OptionsContract,
	PlanCalculatorSettings,
	PlanCategoryDataOptionsContract,
	PlanDependencyEnum,
	PlanRateTypeEnum,
	PlanState,
	PlanTypeContract,
	PlanWizardGetContract,
	PreTaxDeductionTypeValue,
	RateEnum,
	RateValue,
	ReimbursementFrequencyEnumContract,
	ReimbursementValue,
	ReportableOnW2Value,
	SafeHarborValue,
	SelectedCoverageLevelContract,
	TimeToEligibilityContract,
	TimeToEligibilityValues,
	VoluntaryLifeCategoryDataOptionsContract,
	VoluntaryLifeOrADandDEnum,
	WizardValues,
} from '../types';
import { benefitTypeAllowsAgeBands } from '../../plans.react/etc/benefit-type-allows-age-bands';
import { DeductionDirectionEnumContract } from '../../shared/deduction-migration-modal.react/types';
import { PlanTypeEnumContract } from '../../types';
import { PLAN_TYPES } from '../../../../employees/shared/constants';
import { makeLifeSubTypeLike } from './life-sub-type-like-factory';
import { getLifeOrAddValue } from './initialization/get-life-or-add-value';
import { getLifeInsuranceTypeValue } from './initialization/get-life-insurance-type-value';

export const getDefaultCurrencyCode = (): string => {
	return window.GLOBAL_CURRENCY_CODE ? window.GLOBAL_CURRENCY_CODE : 'USD';
};

export const getInitialTextValue = (
	key: 'name' | 'description' | 'url' | 'summary' | 'carrierPlanId',
	benefitPlan: BenefitPlanGetContract
): string => {
	if (benefitPlan && benefitPlan[key]) {
		return benefitPlan[key];
	}

	return '';
};

export const getInitialDeductionTypeValue = (wizardContract: PlanWizardGetContract): DeductionTypeValue => {
	const { benefitPlan, decisionData, planType } = wizardContract;

	if (benefitPlan && benefitPlan.planTypeData.deductionTypeId !== null) {
		if (isDeductionTypeSupported(planType.type, decisionData)) {
			// Added specific rule: if legacy general healthcare can be updated, update it
			if (
				planType.type === PlanTypeEnumContract.flex &&
				benefitPlan.planTypeData.deductionTypeId === DeductionTypeEnumContract.preTaxGeneralHealthcare &&
				(benefitPlan.planState === PlanState.duplicate || !benefitPlan.enrollmentInfo?.hasHadEnrollments)
			) {
				return DeductionTypeEnumContract.updatedPreTaxGeneralHealthcare;
			}
			return benefitPlan.planTypeData.deductionTypeId;
		}
	}

	return '';
};

export const getInitialRateValue = (benefitPlan?: BenefitPlanGetContract): RateValue => {
	let rateValue: RateValue = '';

	if (benefitPlan && benefitPlan.planTypeData.isVariable !== null) {
		const { planTypeData } = benefitPlan;
		rateValue = planTypeData.isVariable ? RateEnum.Variable : RateEnum.Standard;
	}

	return rateValue;
};

export const getInitialRateTypeValue = (wizardContract: PlanWizardGetContract): PlanRateTypeEnum | null => {
	const { benefitPlan, planType } = wizardContract;
	const existingRateType: PlanRateTypeEnum = benefitPlan?.planTypeData?.rateType;

	if (existingRateType) {
		return existingRateType;
	}

	if (planType.type === PlanTypeEnumContract.reimbursement) {
		return PlanRateTypeEnum.reimbursement;
	}
	// Selects Age banded if its available, otherwise standard if its available
	if (benefitTypeAllowsAgeBands(planType.type)) {
		return PlanRateTypeEnum.ageBanded;
	}

	if (planTypeAllowsDefinedCosts(planType.type) || planType.type === PlanTypeEnumContract.disability) {
		return PlanRateTypeEnum.standard;
	}

	return PlanRateTypeEnum.variable;
};

export const getInitialAcaValue = (
	key: keyof BenefitPlanGetContract['planTypeData']['aca'],
	benefitPlan?: BenefitPlanGetContract
): BooleanSelectValue => {
	if (benefitPlan?.planTypeData?.aca && benefitPlan.planTypeData.aca[key] !== null) {
		return benefitPlan.planTypeData.aca[key];
	}

	return '';
};

export const getInitialDateValue = (key: keyof BenefitPlanGetContract['planDates'], benefitPlan?: BenefitPlanGetContract): string => {
	if (!benefitPlan || benefitPlan.planState === PlanState.duplicate) {
		return '';
	}

	return benefitPlan.planDates[key] === null ? '' : benefitPlan.planDates[key];
};

export const getInitialReimbursementValues = (wizardContract: PlanWizardGetContract): ReimbursementValue => {
	const { benefitPlan, planType } = wizardContract;

	if (isReimbursementAmountIncluded(planType.type)) {
		const reimbursementValues: ReimbursementValue = {
			amount: null,
			frequency: ReimbursementFrequencyEnumContract.month,
			currencyCode: getDefaultCurrencyCode(),
		};

		if (benefitPlan?.planTypeData?.reimbursement) {
			const reimbursementGetValue = benefitPlan.planTypeData.reimbursement;

			if (reimbursementGetValue.amount !== null) {
				reimbursementValues.amount = Number(reimbursementGetValue.amount);
			}

			if (reimbursementGetValue.currencyCode !== null) {
				reimbursementValues.currencyCode = reimbursementGetValue.currencyCode;
			}

			if (reimbursementGetValue.frequency !== null) {
				reimbursementValues.frequency = reimbursementGetValue.frequency;
			}
		}

		return reimbursementValues;
	}

	return null;
};

export const getFlatSelectedCoverageLevels = (benefitPlan?: BenefitPlanGetContract): SelectedCoverageLevelContract[] => {
	let selectedCoverageLevels: SelectedCoverageLevelContract[] = [];

	if (benefitPlan) {
		selectedCoverageLevels = [
			...benefitPlan.planTypeData.coverageLevels.selected.default,
			...benefitPlan.planTypeData.coverageLevels.selected.custom.existing,
			...benefitPlan.planTypeData.coverageLevels.selected.custom.new,
		];
	}

	return selectedCoverageLevels;
};

const getInitialCurrencyCode = (wizardContract: PlanWizardGetContract): string => {
	let currencyCode = getDefaultCurrencyCode();
	const selectedCoverageLevels = getFlatSelectedCoverageLevels(wizardContract.benefitPlan);

	if (selectedCoverageLevels.length > 0) {
		currencyCode = selectedCoverageLevels[0].currencyCode;
	}

	return currencyCode;
};

export const getInitialEmployeeCostType = (benefitPlan?: BenefitPlanGetContract): EmployeeCostTypeValue => {
	let savedCostType = null;
	if (benefitPlan?.eligibilityGroups) {
		const { eligibilityGroups } = benefitPlan;

		if (eligibilityGroups.existing?.length > 0 && eligibilityGroups.existing[0].coverageCosts.length > 0) {
			savedCostType = eligibilityGroups.existing[0].coverageCosts[0].employeeCostType;
		} else if (eligibilityGroups.new?.length > 0 && eligibilityGroups.new[0].coverageCosts.length > 0) {
			savedCostType = eligibilityGroups.new[0].coverageCosts[0].employeeCostType;
		}
	}

	return savedCostType || CostTypeEnumContract.fixed;
};

const pushCustomCoverageLevel = (
	isNew: boolean,
	customCoverageLevels: CustomCoverageLevelGetContract[],
	selectedLevel: SelectedCoverageLevelContract,
	coverageLevels: CoverageLevelValue[]
): void => {
	const customIndex = customCoverageLevels.findIndex((customLevel) => {
		return String(customLevel.linkId) === String(selectedLevel.linkId);
	});

	if (customIndex > -1) {
		coverageLevels.push({
			id: isNew ? undefined : Number(selectedLevel.linkId),
			linkId: selectedLevel.linkId,
			name: String(customCoverageLevels[customIndex].name),
			description: '',
			isDefault: false,
			isSelected: true,
			totalCost: selectedLevel.totalCost,
		});
	}
};

export const getInitialCoverageLevels = (options: OptionsContract, benefitPlan?: BenefitPlanGetContract): CoverageLevelValue[] => {
	const coverageLevels: CoverageLevelValue[] = [];

	const selectedCoverageLevels = getFlatSelectedCoverageLevels(benefitPlan);

	if (options.defaultCoverageLevels) {
		options.defaultCoverageLevels.forEach((defaultLevel, index) => {
			const selectedIndex = selectedCoverageLevels.findIndex((selectedLevel) => {
				return String(selectedLevel.linkId) === String(defaultLevel.id);
			});

			coverageLevels.push({
				id: defaultLevel.id,
				linkId: String(defaultLevel.id),
				name: defaultLevel.shortName,
				description: defaultLevel.description,
				isDefault: true,
				isSelected: Boolean(selectedIndex > -1),
				sortOrder: defaultLevel.sortOrder ? Number(defaultLevel.sortOrder) : index,
				totalCost: selectedIndex > -1 ? selectedCoverageLevels[selectedIndex].totalCost : null,
			});
		});
	}

	if (benefitPlan?.planTypeData.coverageLevels) {
		selectedCoverageLevels.forEach((selectedLevel) => {
			if (benefitPlan.planTypeData.coverageLevels.custom.existing.length > 0) {
				pushCustomCoverageLevel(false, benefitPlan.planTypeData.coverageLevels.custom.existing, selectedLevel, coverageLevels);
			}

			if (benefitPlan.planTypeData.coverageLevels.custom.new.length > 0) {
				pushCustomCoverageLevel(true, benefitPlan.planTypeData.coverageLevels.custom.new, selectedLevel, coverageLevels);
			}
		});
	}

	return coverageLevels.sort((a, b) => a.sortOrder - b.sortOrder);
};

export const getInitialOrder = (filters: FiltersContract): FilterType[] => {
	const order = [];

	const preferredOrder = [
		FilterType.employmentStatus,
		FilterType.department,
		FilterType.division,
		FilterType.location,
		FilterType.payType,
		FilterType.jobTitle,
	];

	preferredOrder.forEach((filterType) => {
		const contractKey = CONTRACT_FILTER_KEYS[filterType];
		if (filters[contractKey] && filters[contractKey].length) {
			order.push(filterType);
		}
	});

	return order;
};

export const getInitialEligibilityFiltersFromSavedGroup = (group: NewEligibilityGroupGetContract): EligibilityFilterValues => {
	return {
		areSpecificPeopleSelected: group.specificEmployeeIds.length > 0,
		selectedEmployeeIds: group.specificEmployeeIds,
		filterGroup: {
			order: getInitialOrder(group.filters),
			values: {
				[FilterType.employmentStatus]: group.filters.employmentStatuses,
				[FilterType.department]: group.filters.departments,
				[FilterType.division]: group.filters.divisions,
				[FilterType.location]: group.filters.locations,
				[FilterType.payType]: group.filters.payTypes,
				[FilterType.jobTitle]: group.filters.jobTitles,
			},
			isAutoGenerated: false,
		},
	};
};

export const getInitialClassCodes = (classCodeDetails: ClassCodeDetails): ClassCodeDetails => {
	return classCodeDetails ? classCodeDetails : DEFAULT_CLASS_CODES;
};

export const getInitialLossOfEligibility = (lossOfEligibility?: LossOfEligibilityContract): LossOfEligibilityContract => {
	if (!lossOfEligibility) {
		return {
			ruleCalculationType: null,
		};
	}
	return {
		ruleCalculationType: lossOfEligibility.ruleCalculationType,
	};
};

export const getInitialTimeToEligibility = (tte?: TimeToEligibilityContract): TimeToEligibilityValues => {
	if (!tte) {
		return {
			requirement: '',
			waitTime: '',
			waitPeriod: '',
		};
	}

	return {
		requirement: tte.requirement === null ? '' : tte.requirement,
		waitTime: tte.waitTime === null ? '' : String(tte.waitTime),
		waitPeriod: tte.waitPeriod === null ? '' : tte.waitPeriod,
	};
};

export const getInitialCoverageCosts = (
	savedCoverageCosts: CoverageCostGetContract[],
	coverageLevels: CoverageLevelValue[]
): CoverageCostValues[] => {
	const coverageCosts: CoverageCostValues[] = [];

	savedCoverageCosts.forEach((savedCoverageCost) => {
		const coverageLevelMatch = coverageLevels.find((coverageLevel) => {
			return coverageLevel.linkId === savedCoverageCost.linkId;
		});

		if (coverageLevelMatch) {
			coverageCosts.push({
				coverageLevelLinkId: coverageLevelMatch.linkId,
				employeeCost: savedCoverageCost.employeeCost,
			});
		}
	});

	return coverageCosts;
};

export const getConstrainedPreTaxDeductionTypeId = (deductionTypeId: string): PreTaxDeductionTypeValue => {
	if (
		deductionTypeId === DeductionTypeEnumContract.preTaxSubtype ||
		deductionTypeId === DeductionTypeEnumContract.preTaxGeneralHealthcare ||
		deductionTypeId === DeductionTypeEnumContract.hospitalIndemnityPreTax ||
		deductionTypeId === DeductionTypeEnumContract.postTax
	) {
		return deductionTypeId;
	}

	return '';
};

export const getInitialPreTaxDeductionType = (wizardContract: PlanWizardGetContract): PreTaxDeductionTypeValue => {
	const { benefitPlan, planType, decisionData } = wizardContract;

	if (benefitPlan && benefitPlan.planTypeData.deductionTypeId !== null && isPreTaxFieldIncluded(planType.type, decisionData.hasTrax)) {
		return getConstrainedPreTaxDeductionTypeId(benefitPlan.planTypeData.deductionTypeId);
	}

	return '';
};

export const getInitialReportableOnW2 = (wizardContract: PlanWizardGetContract): ReportableOnW2Value => {
	const { benefitPlan } = wizardContract;

	if (benefitPlan && benefitPlan.planTypeData.reportableOnW2 !== null) {
		return benefitPlan.planTypeData.reportableOnW2 ? Agreement.yes : Agreement.no;
	}

	return '';
};

export const getInitialDeductionOffset = (wizardContract: PlanWizardGetContract): DeductionOffsetValue => {
	const { benefitPlan, decisionData, planType } = wizardContract;

	if (!isDeductionOffsetIncluded(decisionData)) {
		return null;
	}

	const deductionOffset: DeductionOffsetValue = {
		number: planType?.defaults?.deductionDateRule?.number ? planType.defaults.deductionDateRule.number : 1,
		direction: planType?.defaults?.deductionDateRule?.direction
			? planType.defaults.deductionDateRule.direction
			: DeductionDirectionEnumContract.after,
	};

	if (benefitPlan?.planTypeData.deductionDateRule) {
		if (benefitPlan.planTypeData.deductionDateRule.number !== null) {
			deductionOffset.number = benefitPlan.planTypeData.deductionDateRule.number;
		}

		if (benefitPlan.planTypeData.deductionDateRule.direction !== null) {
			deductionOffset.direction = benefitPlan.planTypeData.deductionDateRule.direction;
		}
	}

	return deductionOffset;
};

const getInitialPerPaySchedule = (deductionFrequencies: DeductionFrequencyContract[]): DeductionOccurrenceValue['perPaySchedule'] => {
	const perPaySchedule: DeductionOccurrenceValue['perPaySchedule'] = {};

	deductionFrequencies.forEach((savedFrequency) => {
		perPaySchedule[savedFrequency.payScheduleId] = {
			frequency: savedFrequency.frequency,
			ordinalPayDatesInPeriod: {
				first: '',
				second: '',
			},
			// TODO: uncomment once BE provides this property
			// ordinalPayDatesInPeriod: {
			// 	first: savedFrequency.ordinalPayDatesInPeriod.length > 0 ? savedFrequency.ordinalPayDatesInPeriod[0] : '',
			// 	second: savedFrequency.ordinalPayDatesInPeriod.length > 1 ? savedFrequency.ordinalPayDatesInPeriod[1] : '',
			// },
		};
	});

	return perPaySchedule;
};

const getInitialDeductionOccurrence = (wizardContract: PlanWizardGetContract): DeductionOccurrenceValue => {
	const { benefitPlan } = wizardContract;

	let initialDeductionOccurrence: DeductionOccurrenceValue = {
		isEveryPaycheck: Agreement.yes,
		perPaySchedule: {},
	};

	if (benefitPlan && benefitPlan.planTypeData.deductionFrequencies.length > 0) {
		initialDeductionOccurrence = {
			isEveryPaycheck: Agreement.no,
			perPaySchedule: getInitialPerPaySchedule(benefitPlan.planTypeData.deductionFrequencies),
		};
	}

	return initialDeductionOccurrence;
};

const getInitialCoverageRelationships = (benefitPlan: BenefitPlanGetContract) => {
	if (benefitPlan && benefitPlan.planTypeData && benefitPlan.planTypeData.coverageRelationships) {
		return benefitPlan.planTypeData.coverageRelationships;
	}
	return [];
};

export const getInitialNameValue = (benefitPlan?: BenefitPlanGetContract): string => {
	if (benefitPlan) {
		if (benefitPlan.planState === PlanState.duplicate) {
			return `${getInitialTextValue('name', benefitPlan)} - ${$.__('Copy')}`;
		}
		if (benefitPlan.planState === PlanState.revision) {
			return `${getInitialTextValue('name', benefitPlan)} (New Plan Year)`;
		}
	}

	return getInitialTextValue('name', benefitPlan);
};

export const getInitialSafeHarborValue = (wizardContract: PlanWizardGetContract): SafeHarborValue => {
	const { benefitPlan, decisionData } = wizardContract;

	let usesSafeHarbor: SafeHarborValue = '';

	if (
		benefitPlan &&
		benefitPlan.planTypeData.safeHarbor !== null &&
		isSafeHarborIncluded(benefitPlan.planTypeData.deductionTypeId, decisionData.hasTrax)
	) {
		usesSafeHarbor = benefitPlan.planTypeData.safeHarbor ? Agreement.yes : Agreement.no;
	}

	return usesSafeHarbor;
};

export const getInitialAutoEnrollValue = (
	benefitPlan: BenefitPlanGetContract,
	planTypeContract: PlanTypeContract
): AutoEnrollOptionEnum => {
	const hasAutoEnroll = planTypeContract.type === PLAN_TYPES.DISABILITY || planTypeContract.type === PLAN_TYPES.LIFE;
	if (hasAutoEnroll) {
		if (benefitPlan?.planTypeData?.autoEnrollSetting && benefitPlan.planTypeData.autoEnrollSetting !== AutoEnrollTypeEnum.disabled) {
			return AutoEnrollOptionEnum.enabled;
		}
		return benefitPlan && benefitPlan.planTypeData?.autoEnrollSetting ? AutoEnrollOptionEnum.disabled : null;
	}

	return null;
};

const getInitialCurrencyType = (wizardValues): CurrencyItem => {
	if (wizardValues && wizardValues.fixedCurrencyType) {
		return wizardValues.fixedCurrencyType;
	} else if (wizardValues && wizardValues.maxBenefitAmountType) {
		return wizardValues.maxBenefitAmountType;
	}
	return {
		code: 'USD',
		decodedSymbol: '$',
		id: 1,
		name: 'US Dollar',
		symbol: '$',
		symbolPosition: 0,
	};
};

const getInitialPlanDependency = (wizardContract: PlanWizardGetContract, benefitPlan: BenefitPlanGetContract) => {
	if (!benefitPlan) {
		return null;
	}

	if (wizardContract?.medicalPlans) {
		const hasDependents = Object.values(wizardContract.medicalPlans).find((plan) => plan.isDependency);
		return hasDependents ? PlanDependencyEnum.planDependent : PlanDependencyEnum.planIndependent;
	}

	return PlanDependencyEnum.planIndependent;
};
const getInitialPlanDependencyOptions = (wizardContract: PlanWizardGetContract) => {
	if (wizardContract?.medicalPlans) {
		return Object.values(wizardContract.medicalPlans);
	}

	return [];
};

const getInitialGroupNumber = (wizardContract: PlanWizardGetContract): string => {
	const carrierId = wizardContract?.benefitPlan?.planTypeData?.carrierId;
	if (carrierId) {
		const groupNumber = wizardContract?.planType?.vendors.find((vendor) => vendor.id === carrierId)?.groupNumber;
		if (groupNumber) {
			return groupNumber;
		}
	}
	return '';
};

const getDuplicateFileIds = (benefitPlan: BenefitPlanGetContract) => {
	const planFiles = benefitPlan?.planFiles || [];
	const planState = benefitPlan?.planState;

	if (planState === PlanState.duplicate) {
		const duplicatedPlans = planFiles.map((file) => file.id);
		return duplicatedPlans;
	}

	return [];
};

const getInitialPlanCalculatorSettings = (benefitPlan: BenefitPlanGetContract, rateType: PlanRateTypeEnum): PlanCalculatorSettings => {
	const calculatorSettings = benefitPlan?.planTypeData?.planCategoryDataOptions?.calculatorSettings;
	const isAgeBanded = rateType === PlanRateTypeEnum.ageBanded;

	if (isAgeBanded) {
		return { ...calculatorSettings, ageBands: calculatorSettings?.ageBands ?? DEFAULT_AGE_BANDS };
	}

	return calculatorSettings;
};

export const getInitialCoverageOptions = (wizardContract: PlanWizardGetContract): LifeCoverageOptions => {
	const lifeCategoryDataOptions = wizardContract.benefitPlan?.planTypeData?.planCategoryDataOptions as
		| BasicLifeCategoryDataOptionsContract
		| VoluntaryLifeCategoryDataOptionsContract;
	const lifeSubType = makeLifeSubTypeLike(lifeCategoryDataOptions);

	return lifeSubType.getCoverageOptions(lifeCategoryDataOptions) || DEFAULT_COVERAGE_OPTIONS_VALUES;
};

export const getInitialPlanCategoryDataOptions = (wizardContract: PlanWizardGetContract, rateType: PlanRateTypeEnum) => {
	const { benefitPlan } = wizardContract;
	const planType: PlanTypeEnumContract = wizardContract.planType.type;
	const planCategoryDataOptions: PlanCategoryDataOptionsContract = wizardContract.benefitPlan?.planTypeData?.planCategoryDataOptions;

	switch (planType) {
		case PlanTypeEnumContract.health: {
			const calculatorSettings: PlanCalculatorSettings = getInitialPlanCalculatorSettings(benefitPlan, rateType);
			return { ...planCategoryDataOptions, calculatorSettings } as HealthCategoryDataOptionsContract;
		}
		case PlanTypeEnumContract.life: {
			const lifePlanCategoryDataOptions = (planCategoryDataOptions || {
				type: null,
			}) as BasicLifeCategoryDataOptionsContract | VoluntaryLifeCategoryDataOptionsContract;
			const { coverage = { type: '' } } = lifePlanCategoryDataOptions;
			let { lifeInsuranceType = null } = lifePlanCategoryDataOptions;

			if (Object.values(BasicLifeOrADandDEnum).includes(lifePlanCategoryDataOptions.type as BasicLifeOrADandDEnum)) {
				lifeInsuranceType = LifeInsuranceTypeEnum.basic;
			}
			if (Object.values(VoluntaryLifeOrADandDEnum).includes(lifePlanCategoryDataOptions.type as VoluntaryLifeOrADandDEnum)) {
				lifeInsuranceType = LifeInsuranceTypeEnum.voluntary;
			}

			return { ...lifePlanCategoryDataOptions, lifeInsuranceType, coverage };
		}
		case PlanTypeEnumContract.disability: {
			const disabilityPlanCategoryData = planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
			return {
				...disabilityPlanCategoryData,
				disabilityDisbursementAmount: disabilityPlanCategoryData?.disabilityDisbursementAmount || null,
				disabilityDisbursementCurrencyCode: disabilityPlanCategoryData?.disabilityDisbursementCurrencyCode || 'USD',
				disabilityDisbursementType:
					disabilityPlanCategoryData?.disabilityDisbursementType || DisabilityDisbursementTypeEnum.percentage,
				disabilityMaxBenefitAmount: disabilityPlanCategoryData?.disabilityMaxBenefitAmount || null,
				isMaxBenefitAmountChecked:
					disabilityPlanCategoryData?.isMaxBenefitAmountChecked ||
					Boolean(disabilityPlanCategoryData?.disabilityMaxBenefitAmount) ||
					undefined,
			};
		}
		default:
			return planCategoryDataOptions;
	}
};

export const initializeWizardValues = (wizardContract: PlanWizardGetContract): WizardValues => {
	const { benefitPlan, planType } = wizardContract;
	const coverageLevels = getInitialCoverageLevels(wizardContract.options, benefitPlan);
	const rateType = getInitialRateTypeValue(wizardContract);

	return {
		additionalPlanUrls: benefitPlan?.additionalPlanUrls || [],
		groupNumber: getInitialGroupNumber(wizardContract),
		carrierPlanId: getInitialTextValue('carrierPlanId', benefitPlan),
		coverageOptions: getInitialCoverageOptions(wizardContract),
		dependentRequiredQuestionIds: benefitPlan?.dependentQuestions,
		employeeRequiredQuestionIds: benefitPlan?.employeeQuestions,
		planCategoryDataOptions: getInitialPlanCategoryDataOptions(wizardContract, rateType),
		name: getInitialNameValue(benefitPlan),
		description: getInitialTextValue('description', benefitPlan),
		summary: getInitialTextValue('summary', benefitPlan),
		url: getInitialTextValue('url', benefitPlan),
		planFiles: benefitPlan?.planFiles || [],
		duplicatedPlanFileIds: getDuplicateFileIds(benefitPlan),
		deletedPlanFileIds: [],
		temporaryPlanFileIds: [],
		rate: getInitialRateValue(benefitPlan),
		rateType,
		deductionType: getInitialDeductionTypeValue(wizardContract),
		acaMinValueStandard: getInitialAcaValue('isMinValueStandard', benefitPlan),
		acaMinEssentialCoverage: getInitialAcaValue('isMinEssentialCoverage', benefitPlan),
		startYmd: getInitialDateValue('startYmd', benefitPlan),
		endYmd: getInitialDateValue('endYmd', benefitPlan),
		reimbursement: getInitialReimbursementValues(wizardContract),
		currencies: [],
		currencyCode: getInitialCurrencyCode(wizardContract),
		employeeCostType: getInitialEmployeeCostType(benefitPlan),
		coverageLevels,
		usesSafeHarbor: getInitialSafeHarborValue(wizardContract),
		eligibilityGroups: getInitialEligibilityGroups(wizardContract, coverageLevels),
		preTaxDeductionType: getInitialPreTaxDeductionType(wizardContract),
		reportableOnW2: getInitialReportableOnW2(wizardContract),
		deductionOffset: getInitialDeductionOffset(wizardContract),
		deductionOccurrence: getInitialDeductionOccurrence(wizardContract),
		coverageRelationships: getInitialCoverageRelationships(benefitPlan),
		fixedCurrency: null,
		fixedCurrencyType: getInitialCurrencyType(wizardContract),
		vendorId: benefitPlan?.planTypeData?.carrierId,
		acaMetalTier: benefitPlan?.planTypeData.metalTier,
		autoEnroll: getInitialAutoEnrollValue(benefitPlan, planType),
		autoEnrollRequired: benefitPlan?.planTypeData?.autoEnrollSetting === AutoEnrollTypeEnum.enabledRequired || false,
		medicalPlanDependency: getInitialPlanDependency(wizardContract, benefitPlan),
		medicalPlanDependencyOptions: getInitialPlanDependencyOptions(wizardContract),
		isLegalTextIncluded: benefitPlan?.legalText ? IsLegalTextIncludedEnum.true : IsLegalTextIncludedEnum.false,
		legalText: benefitPlan?.legalText,
		lifeInsuranceType: getLifeInsuranceTypeValue(wizardContract),
		lifeOrAdd: getLifeOrAddValue(wizardContract),
	};
};
