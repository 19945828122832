/* eslint-disable max-classes-per-file */
import { render } from 'base/wrapped-render';
import Ctrl, {
	onAction,
	onReady,
} from 'ctrl.deco';
import Ajax from '@utils/ajax';
import {
	redirect,
} from 'BambooHR.util';
import {
	getJsonData,
} from 'Data.util';
import {
	EmailVerification,
} from './email-verification.react';
import {
	EmailVerified,
} from './email-verified.react';

import './styles.styl';

@Ctrl('/settings/users/email/validate')
class SettingsUsersEmailValidateCtrl {
	@onReady()
	_readyHandler() {
		const emailVerificationRoot = document.getElementById('emailVerificationRoot');
		const emailVerificationData = getJsonData('.js-emailVerificationData');

		if (emailVerificationRoot && emailVerificationData) {
			render(<EmailVerification { ...emailVerificationData } />, emailVerificationRoot);
		}
	}

	@onAction('post')
	_onResendEmail(e, ctrl, ...args) {
		const {
			href,
		} = new URL(args.join(':'), window.location.href);

		Ajax.post(href)
			.then(() => {
				window.setMessage($.__('We just sent you another verification email.'), 'success');
			})
			.catch((...args) => {
				redirect('/home', $.__('We were unable to send your verification email.'), 'error');
			});
	}
}

@Ctrl('/settings/users/email/validate/:validationHash/ticket/:ticketId')
class SettingsUsersEmailValidatedCtrl {
	@onReady()
	_readyHandler() {
		const emailVerificationRoot = document.getElementById('emailVerificationRoot');
		const emailVerificationData = getJsonData('.js-emailVerificationData');

		if (emailVerificationRoot && emailVerificationData) {
			render(<EmailVerified { ...emailVerificationData } />, emailVerificationRoot);
		}
	}
}
