import {
	TTERequirement,
	TTEWaitPeriod,
} from '../../types';

export const TTE_REQUIREMENT_TEXT: {[key in TTERequirement]: string} = {
	[TTERequirement.markedManually]: $.__('On a manually entered date'),
	[TTERequirement.immediatelyOnHire]: $.__('Immediately upon hire'),
	[TTERequirement.afterWaitingPeriod]: $.__('After a waiting period'),
	[TTERequirement.firstOfMonthAfterWaitingPeriod]: $.__('First of the month following a waiting period'),
};

export const TTE_WAIT_PERIOD_TEXT: {[key in TTEWaitPeriod]: string} = {
	[TTEWaitPeriod.day]: $.__('days'),
	[TTEWaitPeriod.week]: $.__('weeks'),
	[TTEWaitPeriod.month]: $.__('months'),
	[TTEWaitPeriod.year]: $.__('years'),
};
