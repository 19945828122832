import {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	pick,
} from 'lodash';
import {
	Modal,
} from 'modal-legacy';

import {
	formatEmployeeData,
	getAcaAssignedStatuses,
	getAcaWizard,
	resetModalContentScroll,
	validateStatusAlignment,
} from 'aca-status-alignment-table.react';

import {
	useReducerContext,
} from '../state';

import { COMPANY_INFO_WHITELIST, STEPS } from './constants';
import {
	getAlternativeActionInfo,
	postEnableAca,
} from './utils';

import Title from './title';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

import {
	useForm,
	FormProvider,
} from '../form';


/** @type {React.FunctionComponent} */
export default function ACAEnableModalWrapper() {
	const {
		currentStep,
	} = useReducerContext();
	const formRef = useRef(null);
	const [employeeData, setEmployeeData] = useState([]);
	const [employerInfo, setEmployerInfo] = useState({});
	const [employmentStatuses, setEmploymentStatuses] = useState({});
	const [acaTypes, setAcaTypes] = useState([]);
	const [employmentStatusAcaTypes, setEmploymentStatusAcaTypes] = useState({});
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

	useEffect(() => {
		getAcaWizard()
			.then((response) => {
				if (response && response.data) {
					setEmployerInfo(response.data.employerInfo);
					setEmploymentStatuses(response.data.acaWizard.employmentStatuses);
					setEmployeeData(formatEmployeeData(response.data.acaWizard.employees, response.data.acaWizard.employmentStatuses));
					setAcaTypes(response.data.acaWizard.acaStatuses);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	function handleAcaStatusAssignment(acaStatus, employmentStatusId) {
		setEmploymentStatusAcaTypes(getAcaAssignedStatuses(acaStatus, employmentStatusId, employmentStatusAcaTypes));
		if (employmentStatuses && currentStep === STEPS.Three) {
			setIsSubmitButtonDisabled(validateStatusAlignment(employmentStatuses, employmentStatusAcaTypes));
		} else {
			setIsSubmitButtonDisabled(false);
		}
	}

	return (
		<FormProvider
			form={ formRef }
			values={ employerInfo }
		>
			<ACAEnableModal
				employmentStatuses={ employmentStatuses }
				employmentStatusAcaTypes={ employmentStatusAcaTypes }
				isSubmitButtonDisabled={ isSubmitButtonDisabled }
				validateStatusAlignment={ validateStatusAlignment }
			>
				<form ref={ formRef }>
					<div style={ { ...(currentStep > 1 && { display: 'none' }) } }>
						<Step1 />
					</div>
					{ currentStep > 1 && (
						<div style={ { ...(currentStep > 2 && { display: 'none' }) } }>
							<Step2 />
						</div>
					) }
					{ currentStep > 2 && (
						<div style={ { ...(currentStep > 3 && { display: 'none' }) } }>
							<Step3
								acaTypes={ acaTypes }
								employmentStatusAcaTypes={ employmentStatusAcaTypes }
								employmentStatuses={ employmentStatuses }
								onAcaStatusAssignment={ handleAcaStatusAssignment }
							/>
						</div>
					) }
					{ currentStep > 3 && (
						<Step4
							employeeData={ employeeData }
							employmentStatusAcaTypes={ employmentStatusAcaTypes }
						/>
					) }
				</form>
			</ACAEnableModal>
		</FormProvider>
	);
}

/** @type {React.FunctionComponent} */
function ACAEnableModal(props) {
	const {
		children,
		employmentStatuses,
		employmentStatusAcaTypes,
		isSubmitButtonDisabled,
		validateStatusAlignment,
	} = props;
	const context = useReducerContext();
	const formContext = useForm();
	const {
		submitting,
		reset,
		useValidEffect,
		useSubmitSuccessEffect,
		validate,
		values,
	} = formContext;

	const {
		modal,
		dispatch,
		CLOSE_MODAL,
		NEXT_STEP,
		PREV_STEP,
		ENABLE,
		currentStep,
		stepCount,
	} = context;

	const isLast = currentStep === stepCount;

	const goBack = () => {
		dispatch(PREV_STEP());
		resetModalContentScroll();
	};

	const alternativeActionInfo = getAlternativeActionInfo(currentStep, goBack);

	useValidEffect(() => {
		if (isLast) {
			const postData = {
				employmentStatuses: employmentStatusAcaTypes,
				companyInfo: pick(values, COMPANY_INFO_WHITELIST),
			};
			postEnableAca(postData)
				.then(() => {
					dispatch(CLOSE_MODAL());
					location.reload();
				})
				.catch((error) => {
					console.error(error);
				});

		} else {
			if (currentStep === STEPS.Three) {
				validateStatusAlignment(employmentStatuses, employmentStatusAcaTypes);
			}
			dispatch(NEXT_STEP());
			resetModalContentScroll();
		}
	});

	useSubmitSuccessEffect(() => {
		dispatch(ENABLE());
		location.reload();
	});

	return (
		<Modal
			alternativeAction={ alternativeActionInfo.action }
			alternativeActionText={ alternativeActionInfo.text }
			isOpen={ modal === 'ENABLE' }
			isProcessing={ submitting }
			onClose={ () => dispatch(CLOSE_MODAL()) }
			onOpen={ reset }
			primaryAction={ (currentStep === STEPS.Three && isSubmitButtonDisabled) ? null : validate }
			primaryActionText={ isLast ? $.__('Save and Finish') : $.__('Next') }
			title={ <Title /> }
			type="medium"
		>
			{ children }
		</Modal>
	);
}
