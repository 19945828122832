import React, { useState } from 'react';
import { Button} from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import Upsell from 'Upsell';
import serializeDemoRequest from '../../../upsell/components/TimeTrackingUpsell/serializers/demo-request';
import { timeTrackingItems } from '../../../../app-configs/upsell';
import TrialActivationModal from 'time-tracking/modals/trial-activation-modal';
import api from '../../../../app-services/api';

const TimeTrackingTrial = ({ demoRequest,  trialSettings }) => {
	// See example of demo state shape below in defaultProps
	const [demo, setDemo] = useState(serializeDemoRequest(demoRequest));
	const [isActivationOpen, setIsActivationOpen] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);

	const {  canEnableTimeTrackingTrial, canScheduleDemo } = trialSettings;
	const { requestedBy, requestDate, isRequested } = demo;

	const showTrialModal = () => setIsActivationOpen(true);

	const handleUpsellRequest = () => {
		setIsRequestPending(true);

		// Make the request
		api.expansion.timeTrackingDemoRequest.post().then(({ data }) => {
			// Update the state
			setDemo(serializeDemoRequest(data));

			// Show success slidedown
			showSlidedown($.__('Thanks for letting us know! We\'ll be getting in touch soon to schedule your demo.'), 'success');
		}).catch(() => {

			// Show error slidedown
			showSlidedown($.__('Oops... something went wrong. Please try again.'), 'error');
		});

		// Disable processing state
		setIsRequestPending(false);
	}

	const actions = [
		<Button
			data-bi-id='time-tracking-trial-page-try-it-out-button'
			disabled={ !canEnableTimeTrackingTrial }
			noSpacing={true}
			onClick={ showTrialModal }
			size='large'
			type='button'
			width='100'
		>
			{ $.__('Give Time Tracking A Try') }
		</Button>
	];

	if (canScheduleDemo) {
		actions.push(
			<Button
				color='secondary'
				data-bi-id='settings-time-tracking-trial-page-request-demo-button'
				disabled={isRequested}
				noSpacing={true}
				onClick={ handleUpsellRequest }
				processing={ isRequestPending }
				size='large'
				type='button'
				variant='outlined'
				width='100'
			>
				{$.__('Schedule a Personalized Demo')}
			</Button>
		)
	}

	return (
		<Upsell>
			<Upsell.Title>{$.__('Spend Minutes Tracking Hours')}</Upsell.Title>
			<Upsell.Video title={$.__('Payroll Video')} videoId="3ot9fc24vr" />
			<Upsell.Content
				actions={ actions }
				request={{
					demoRequestedBy: requestedBy,
					demoRequestedDate: requestDate,
					isDemoRequested: isRequested,
				}}
			>
				<h4>{ $.__('Ready to start your Free Time Tracking trial now?') }</h4>
				<p>{ $.__('BambooHR Time Tracking adds time management capabilities to BambooHR so you can:') }</p>
				<ul>
					{ timeTrackingItems.map((item, index) => (
						<li key={`time-tracking-item-${ index }`}>{ item }</li>
					)) }
				</ul>
			</Upsell.Content>
			<TrialActivationModal isActivationOpen={isActivationOpen} setIsActivationOpen={setIsActivationOpen} />
		</Upsell>
	);
}

TimeTrackingTrial.defaultProps = {
	demoRequest: {
		isRequested: false,
		requestedBy: {
			id: null,
			firstName: '',
			lastName: '',
		},
		requestDate: null,
	},
	trialSettings: {
		canEnableTimeTracking: false,
		canEnableTimeTrackingTrial: false,
		canScheduleDemo: false,
	}
};

export default TimeTrackingTrial;
