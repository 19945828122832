import Ajax from '@utils/ajax';

import {
	AxiosPromise,
} from 'axios';

// import { MOCK_DEDUCTIONS } from '../api';
// import { DeductionObject } from '../types';

// export function getDeductionsData(): Promise<DeductionObject[]> {
// 	return new Promise((resolve) => {
// 		window.setTimeout(() => resolve(MOCK_DEDUCTIONS), 5000);
// 	});
// }

export function getDeductionsData(): AxiosPromise {
	return Ajax.get('/payroll/deduction_sync/ajax/list_page_plan_data');
}
