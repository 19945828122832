import { Message } from '@bamboohr/utils/lib/message';

import { Modal } from 'modal-legacy';

export const DeleteGroupModal = (props) => {
	const {
		closeModal,
		deleteGroup,
		isOpen,
		isSpecificPeopleGroup,
		numberEnrolledEmployees,
	} = props;

	return (
		<Modal
			alternativeAction={ null }
			content={ (
				<Message
					params={ [numberEnrolledEmployees] }
					text={ (isSpecificPeopleGroup) ? $.__('Employees will remain enrolled in this plan until removed manually.') : $.__('If you continue, **{1} employees** will be unenrolled and ineligible for this plan.') }
				/>
			) }
			headline={ $.__('Are you sure you want to delete this group?') }
			icon="fab-trash-can-43x48"
			iconColor="danger"
			isOpen={ isOpen }
			onClose={ closeModal }
			primaryAction={ deleteGroup }
			primaryActionText={ $.__('Delete Group') }
			secondaryAction={ closeModal }
			secondaryActionText={ $.__('Keep Group') }
			title={ $.__('Delete Group') }
			type="aggressiveConfirmation"
		/>
	);
};

