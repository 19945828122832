import {
	useContext,
	useEffect,
	useState,
} from 'react';

import { WizardStateContext } from '../context/wizard-state-context';
import { isFieldDisabled } from '../etc/is-field-disabled';
import { WizardState } from '../types/wizard-state';

export function useReactivateDisabledField(value: any, isRequired: boolean): boolean {
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const wizardState: WizardState = useContext(WizardStateContext);

	useEffect(() => {
		if (wizardState === WizardState.Reactivate) {
			setIsDisabled(isFieldDisabled(value, isRequired));
		}
	}, [wizardState]);

	return isDisabled;
}
