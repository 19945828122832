import {useEffect, useState} from 'react';
import { 
	BadgeV2,
	Button,
	Divider,
	Flex,
	/* @startCleanup encore */
	Icon,
	/* @endCleanup encore */
	IconV2, 
	LayoutBox, 
	StandardModal, 
	Table 
} from '@bamboohr/fabric';
import Tooltip from 'tooltip.react';
import moment from 'moment.lib';
import ChangeDate from './change-date';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const today = moment().startOf('day');

export default function PayScheduleCalendarModal(props) {
	const {payPeriods, isOpen, isPreview, disableDays = [], title, handleClose} = props;

	const [allIds, setAllIds] = useState([]);
	const [byId, setById] = useState({});
	const [calendarEditIsOpen, setCalendarEditIsOpen] = useState(false);
	const [calendarEditDate, setCalendarEditDate] = useState(null);

	useEffect(() => {
		if (isOpen && payPeriods && payPeriods.length > 0) {
			const {allIdsTemp, byIdTemp} = payPeriods.reduce((obj, schedule) => {
				const id = isPreview ? schedule.payPeriodStartDate : schedule.id;
				obj.allIdsTemp.push(id);
				obj.byIdTemp[id] = schedule;
				return obj;
			}, {allIdsTemp: [], byIdTemp: {},});
			setAllIds(allIdsTemp);
			setById(byIdTemp);
		}
	}, [isOpen])

	const _renderIsShiftedIcon = (period) => {
		if (typeof period.shiftedByUser === 'string' && period.shiftedByUser.length > 0) {
			return (
				<Tooltip
					settings={ {
						template: {
							name: 'tooltip-standard',
							data: {
								content: $.__('Manually updated'),
								textAlign: 'center',
							},
						},
					} }
					wrapperClassName="PS_Calendar__adjustedIcon"
				>
					{ifFeature('encore', <IconV2 name="wrench-solid" size={12} />, <Icon name="fab-wrench-12x12" />)}
				</Tooltip>
			);
		}

		if (period.shifted === 'yes') {
			return (
				<Tooltip
					settings={ {
						template: {
							name: 'tooltip-standard',
							data: {
								content: $.__('Pay date adjusted for weekend or holiday'),
								textAlign: 'center',
							},
						},
					} }
					wrapperClassName="PS_Calendar__adjustedIcon"
				>
					{ifFeature('encore', <IconV2 name="circle-exclamation-solid" size={12} />, <Icon name="fab-info-circle-13x13" />)}
				</Tooltip>
			);
		}

		return null;
	};

	const _makeColumns = () => {
		const columns = [
			{
				header: $.__('Period Start'),
				cell: id => moment(byId[id].payPeriodStartDate).format(),
			},
			{
				header: $.__('Period End'),
				cell: id => moment(byId[id].payPeriodEndDate).format(),
			},
			{
				header: $.__('Pay Date'),
				cell: (id) => {
					const row = byId[id];
					const isShifted = row.shifted === 'yes' || typeof row.shiftedByUser === 'string' && row.shiftedByUser.length > 0;
					if (isShifted) {
						return (
							<Flex alignItems="center">
								{ moment(row.payDate).format() }
								{ isShifted && _renderIsShiftedIcon(row) }
							</Flex>
						);
					}
					return moment(row.payDate).format();
				},
			},
		];

		if (!isPreview) {
			columns.push({
				headerAriaLabel: $.__('Edit pay date'),
				showOnHover: true,
				cell: {
					type: 'actions',
					actions: (id) => {
						const row = byId[id];
						const momentPayDate = moment(row.payDate);
						const canEditPayDate = momentPayDate.diff(today, 'days') > 3;
						return [
							{
								icon: ifFeature('encore', 'pen-regular', 'fab-pencil-16x16'),
								action: canEditPayDate ? () => _launchSheet(row) : null,
								tooltipContent: canEditPayDate
									? $.__('Change Pay Date')
									: $.__('Pay dates within three days cannot be changed'),
							},
						];
					},
				},
			},);
		}

		return columns;
	}

	const _launchSheet = (row) => {
		setCalendarEditDate(row);
		setCalendarEditIsOpen(true);
	};

	return (
		<>
			{calendarEditIsOpen && 
				<ChangeDate
					disableDays={ disableDays }
					handleClose={ () => setCalendarEditIsOpen(false) }
					isOpen={ calendarEditIsOpen }
					onSave={ (id, newDate) => {
						const byIdCopy = {...byId};
						byIdCopy[id].shiftedByUser = `${ window.SESSION_USER.firstName } ${ window.SESSION_USER.lastName }`;
						byIdCopy[id].payDate = newDate;
						setById(byIdCopy);
						setCalendarEditIsOpen(false);
					} }
					payDate={ calendarEditDate }
					title={$.__('Change Pay Date')}
				/>}
			<StandardModal isOpen={isOpen} onRequestClose={handleClose}>
				<StandardModal.Body
					renderFooter={ (
						<StandardModal.Footer 
							actions={ [
								<Button
									key="primary"
									onClick={handleClose}
									type="button"
								>
									{$.__('Done')}
								</Button>,
							] }
						/>
					)}
					renderHeader={ (
						<StandardModal.Header hasCloseButton={true} title={title} />
					) }
				>
					<LayoutBox marginBottom={3} marginTop={ifFeature('encore', 3)} marginX={ifFeature('encore', 3)}>
						{ifFeature('encore',
							<BadgeV2 
								icon="calendar-regular"
								title="Pay Schedule"
							/>,
							<StandardModal.StandardHeadline text="Pay Schedule" />
						)}
					</LayoutBox>
					{ifFeature('encore',
						<LayoutBox marginX={3}>
							<Divider color="neutral-extra-weak" />
						</LayoutBox>
					)}
					{ifFeature('encore',
						<StandardModal.Constraint>
							<Table
								caption={ $.__('Upcoming pay schedules for the next year') }
								columns={ _makeColumns() }
								rowKey={ id => id }
								rows={ allIds }
							/>
						</StandardModal.Constraint>,
						<Table
							caption={ $.__('Upcoming pay schedules for the next year') }
							columns={ _makeColumns() }
							rowKey={ id => id }
							rows={ allIds }
						/>
					)}
				</StandardModal.Body>
			</StandardModal>
		</>
	);

}
