import { useState } from 'react';
import { Flex, Loader as FabricLoader } from '@bamboohr/fabric';

export interface LoaderProps { 
	delay?: number;
}

export function Loader({ delay = 500 }: LoaderProps) {
	const [isLoaderVisible, setIsLoaderVisible] = useState(false);

	if (!isLoaderVisible) {
		setTimeout(() => {
			setIsLoaderVisible(true);
		}, delay);
	}
	

	return isLoaderVisible ? (
		<Flex alignItems='center' flex={1} py={6} width='100%'>
			<FabricLoader />
		</Flex>
	) : null;
}

Loader.defaultProps = {
	delay: 500,
}
