import React, { ReactElement } from 'react';
import { Button } from '@bamboohr/fabric';
import { CirclePlus14x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { redirect } from 'BambooHR.util';

export function HeaderGoogleButton(): ReactElement {
	return (
		<Button
			color='secondary'
			data-bi-id='settings-access-levels-add-employee-btn'
			key='secondaryGoogle'
			onClick={() => redirect(`/settings/permissions/import_users_from_google.php`)}
			outline={true}
			size='small'
			startIcon={ifFeature('encore', 'circle-plus-regular', <CirclePlus14x14 />)}
			text={$.__('Add Google Users')}
			type='button'
		/>
	);
}
