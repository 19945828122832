import CustomTables from './custom-tables';
import { Message } from '@bamboohr/utils/lib/message';
import Reminders from './reminders';
import SendAt from './send-at';
import { StateProvider } from './state';

export default function WhenSent({ children, ...props }) {
	const { isReminder, reminderFieldLabel, dayOfAlert } = props;

	return (
		<StateProvider value={props}>
			<CustomTables />
			{isReminder && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<div className='fab-Checkbox'>
							<input
								className='fab-Checkbox__input'
								defaultChecked={dayOfAlert}
								id='dayOfAlert'
								name='dayOfAlert'
								type='checkbox'
								value='true'
							/>
							<label className='fab-Checkbox__label' htmlFor='dayOfAlert'>
								<span>
									<Message params={[reminderFieldLabel.toLowerCase()]} text={$._('Send me an alert on the **{1}**.')} />
								</span>
							</label>
						</div>
					</div>
				</div>
			)}
			<div className='fab-FormRow fab-marginBottom--12' style={{ alignItems: 'start' }}>
				<Reminders />
			</div>
			<SendAt />
			{children}
		</StateProvider>
	);
}
