import { render } from 'base/wrapped-render';

import PaySchedulesList from './pay-schedules-list.react';

import './styles.styl';

let psList;

window.PaySchedules = {
	init: function () {
		psList = JSON.parse($('#js-ps-data').html());
		render(<PaySchedulesList paySchedules={ psList } />, document.getElementById('js-pay-schedules-list'));
	}
};
