import { CompletedStep, CompletedSteps, StepName } from '../../types';

export const setWizardStepWarning = (stepId: StepName, completedSteps: CompletedSteps): CompletedStep => {
	const currentStep = completedSteps[stepId];
	if (currentStep.complete) {
		return {
			...currentStep,
			status: 'error',
		};
	}

	return currentStep;
};
