import Decimal from 'decimal.js-light';

import {
	EmployeeFieldsObject,
	EmployeeSelectField,
} from '../types';

const FIELD_NAME_MAP = {
	addressLine1: $.__('Address Line 1'),
	city: $.__('City'),
	compensationStartDate: $.__('Compensation Start Date'),
	dateOfBirth: $.__('Birth Date'),
	employmentStatus: $.__('Employment Status'),
	firstName: $.__('First Name'),
	gender: $.__('Gender'),
	employeeNumber: $.__('Employee Number'),
	hireDate: $.__('Hire Date'),
	jobLocation: $.__('Job Location'),
	lastName: $.__('Last Name'),
	maritalStatus: $.__('Marital Status'),
	overtimeRate: $.__('Overtime Rate'),
	overtimeStatus: $.__('Overtime Status'),
	payPer: $.__('Pay Frequency'),
	payRate: $.__('Pay Rate'),
	paySchedule: $.__('Pay Schedule'),
	payType: $.__('Pay Type'),
	ssn: $.__('Social Security Number'),
	stateId: $.__('State'),
	taxType: $.__('Tax Type'),
	zipcode: $.__('ZIP Code'),
};

function getRequireOvertimeRate(employeeDataFields: EmployeeFieldsObject): boolean {
	const {
		payType,
		overtimeStatus,
	} = employeeDataFields;
	const {
		selected: payTypeSelected,
	} = payType as EmployeeSelectField;
	const {
		selected: overtimeStatusSelected,
	} = overtimeStatus as EmployeeSelectField;

	return payTypeSelected === 'Salary' && overtimeStatusSelected === 'Non-exempt';
}

export function formatRateForSave(value: string) {
	if (!value) {
		return value;
	}

	value = value.replace('USD', '').trim();
	value = new Decimal(value).toFixed(2);

	return `${value} USD`;
}

export function formatSaveData(employeeDataFields: EmployeeFieldsObject): { fields: EmployeeFieldsObject, stillMissing: string[] } {
	const fields: EmployeeFieldsObject = {};
	const stillMissing = [];
	const requireOvertimeRate = getRequireOvertimeRate(employeeDataFields);
	const employeeDataArray = window.Object.keys(employeeDataFields);

	employeeDataArray.forEach((fieldName: string) => {
		let fieldValue = employeeDataFields[fieldName];

		if (fieldValue !== null && typeof fieldValue === 'object' && 'selected' in fieldValue) {
			fieldValue = fieldValue.selected;
		}

		if ((!fieldValue && fieldName !== 'addressLine2' && fieldName !== 'middleName' && fieldName !== 'overtimeRate' && fieldName !== 'isPaidByCheck') ||
			(!fieldValue && fieldName === 'overtimeRate' && requireOvertimeRate)) {
			stillMissing.push(FIELD_NAME_MAP[fieldName]);
		}

		if (fieldValue || fieldName === 'isPaidByCheck') {
			fields[fieldName] = fieldValue;
		}

		if (fieldName === 'payRate' || fieldName === 'overtimeRate') {
			if (fieldValue && typeof fieldValue === 'string') {
				fields[fieldName] = formatRateForSave(fieldValue);
			}
		}
	});

	return {
		fields,
		stillMissing,
	};
}
