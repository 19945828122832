import { MenuList, MenuItem, MenuItemText, Menu, Button, IconButton, IconV2 } from '@bamboohr/fabric';
import { CirclePlus14x14, DropdownCaret9x5 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactElement, useState } from 'react';
import { getDropdownItems } from './get-dropdown-items';
import { redirect } from 'BambooHR.util';
import { useStyles } from './styles';

export function RenderNavDropdown(): ReactElement {
	const [open, setOpen] = useState(false);
	const dropdownItems = getDropdownItems();
	const styles = useStyles();
	return (
		<div data-bi-id="settings-access-levels-gear-dropdown">
			<Menu
				isOpen={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				renderToggle={(options, buttonProps) => (
					ifFeature('encore', <IconButton
						{...buttonProps}
						endIcon={<IconV2 color='neutral-extra-strong' name='caret-down-solid' size={16} />}
						icon={<IconV2 color='neutral-extra-strong' name='circle-plus-regular' size={16} />}
						outline={true}
						secondary={true}
						size='small'
						type='button'
					/>, <Button
						{...buttonProps}
						endIcon={<DropdownCaret9x5 />}
						icon={<CirclePlus14x14 />}
						outline={true}
						secondary={true}
						size='small'
						type='button'
					/>)
				)}
			>
				{open && (
					<div className={styles.dropdown}>
						<MenuList
							onSelect={(index) => {
								if (dropdownItems[index].href) {
									redirect(dropdownItems[index].href);
								}
							}}
							renderItem={(index, props) => (
								<MenuItem {...props} data-bi-id={`${dropdownItems[index].biId}`}>
									<MenuItemText primary={`${dropdownItems[index].label}`} secondary={dropdownItems[index].note} />
								</MenuItem>
							)}
							total={dropdownItems.length}
						/>
					</div>
				)}
			</Menu>
		</div>
	);
}
