import { ReactElement } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';

export interface CompanyTaxObject {
	clientId: number;
	clientName: string;
	creationDate: string;
	ein: string;
	taxName: string;
	taxTypeIncludedId: number;
}

export interface MissingTaxIdMessageMicroProps {
	isMultiEin: boolean;
	masterTaxStartDate: string | null | undefined;
	showSetupNow: boolean;
	taxes: CompanyTaxObject[];
}

export function MissingTaxIdMessageMicro(props: MissingTaxIdMessageMicroProps): ReactElement {

	return (
		<PoMicroFrontend
			route='/settings/payroll/missingTaxIdMessage'
			{...props}
		/>
	)
}
