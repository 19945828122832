import { AxiosPromise } from 'axios';

import Ajax from '@utils/ajax';

import { BenefitsBannerGetContract, DuplicatePlanContract, MissingFieldsContract } from '../types';
import { DeductionMigrationContract } from '../../shared/deduction-migration-modal.react/types';

export function deletePlan(planId: number): Promise<any> {
	return Ajax.post(`/settings/benefits/plan/${planId}/delete`);
}

export function duplicatePlan(planId: number, plan: DuplicatePlanContract): Promise<any> {
	return Ajax.post(`/settings/benefits/plan/${planId}/duplicate`, plan);
}

export function getEnrolledEmployeesForPlan(planId: number) {
	return Ajax.get(`/settings/benefits/plan/${planId}/enrolled`);
}

export function getDeductionMigration(): AxiosPromise<DeductionMigrationContract> {
	return Ajax.get('/settings/benefits/deduction_migration');
}

export function getPlansData() {
	return Ajax.get('/settings/benefits/plans_data/');
}

export function validatePlanMissingFields(planId: number): AxiosPromise<MissingFieldsContract> {
	return Ajax.get(`/settings/benefits/plan/${planId}/required_fields_missing`);
}

export const getShowBenefitsBanner = (): AxiosPromise<BenefitsBannerGetContract> => {
	return Ajax.get('/settings/benefits/benefitsAdminBanner');
};

export const postDismissBenefitsBanner = (): AxiosPromise<void> => {
	return Ajax.post('/settings/benefits/benefitsAdminBanner/dismiss');
};
