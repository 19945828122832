import { Component } from 'react';
import c from 'classnames';
import { Modal } from 'modal-legacy';
import { TextInput } from '@fabric/form';
import Ajax from '@utils/ajax';
import { Message, ExternalLink } from '@bamboohr/utils/lib/message';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './account-verification-modal.styl';
import { Flex, StyledBox, BodyText, Table, Headline } from '@bamboohr/fabric';

const AMOUNT_REGEX = /^(\d*\.)?\d+$/;

export class AccountVerificationModal extends Component {
	_getSheetProps = () => {
		const { failedSheetOpen, successSheetOpen, verifyingSheetOpen } = this.state;

		if (verifyingSheetOpen) {
			return this._renderVerifyingSheet();
		}

		if (failedSheetOpen) {
			return this._renderFailedSheet();
		}

		if (successSheetOpen) {
			return this._renderSuccessSheet();
		}

		return null;
	};

	_renderFailedSheet = () => {
		const { onSubmit } = this.props;

		return {
			alternativeAction: null,
			title: $.__('Sorry, unable to verify account'),
			icon: ifFeature('encore', 'lock-regular', 'fab-bank-lock-50x54'),
			iconColor: 'danger',
			iconV2Color: 'error-strong',
			isOpen: true,
			onClose: this._handleSheetClose,
			primaryActionText: $.__('Done'),
			primaryAction: () => {
				this._handleSheetClose();

				if (onSubmit) {
					onSubmit();
				}
			},
			header: ifFeature(
				'encore',
				<Headline color='neutral-strong' size='small'>
					{$.__(`You've exceeded the allowed number of attempts to enter the correct deposit amount.`)}
				</Headline>,
				<h4 className='AccountVerificationModal__sheetHeader--danger'>
					{$.__(`You've exceeded the allowed number of attempts to enter the correct deposit amount.`)}
				</h4>
			),
			content: (
				<BodyText justify='center'>
					<Message
						link={ExternalLink('mailto:payrollimp@bamboohr.com')}
						text={$._('Please email [payrollimp@bamboohr.com] and we will help you complete your bank account verification.')}
					/>
				</BodyText>
			),
		};
	};

	_renderSuccessSheet = () => {
		return {
			alternativeAction: null,
			title: $.__('Success!'),
			icon: ifFeature('encore', 'badge-check-regular', 'fab-bank-check-54x54'),
			iconColor: 'success',
			iconV2Color: 'primary-strong',
			isOpen: true,
			onClose: () => this._handleSheetClose(true),
			primaryActionText: $.__('Done'),
			primaryAction: () => this._handleSheetClose(true),
			header: ifFeature(
				'encore',
				<Headline color='primary' size='small'>
					{$.__(`Your account verification is complete`)}
				</Headline>,
				<h4 className='AccountVerificationModal__sheetHeader--success'>{$.__(`Your account verification is complete`)}</h4>
			),
			content: ifFeature(
				'encore',
				<BodyText justify='center'>
					{$.__(
						'Your company and bank account info has been verified. Your payroll project manager will be reaching out to assist you with completing your payroll setup.'
					)}
				</BodyText>,
				<p className='AccountVerificationModal__sheetContent'>
					{`
						${$.__('Your company and bank account info has been verified')}.
						${$.__('Your payroll project manager will be reaching out to assist you with completing your payroll setup')}.
					`}
				</p>
			),
		};
	};

	_renderVerifyingSheet = () => {
		return {
			alternativeAction: null,
			title: $.__('Verifying Deposit Amount'),
			icon: ifFeature('encore', 'building-columns-regular', 'fab-bank-clock-plane-54x58'),
			iconColor: 'success',
			iconV2Color: 'primary-strong',
			isOpen: true,
			onClose: () => this._handleSheetClose(true),
			primaryActionText: $.__('OK, Got It'),
			primaryAction: () => this._handleSheetClose(true),
			content: ifFeature(
				'encore',
				<BodyText weight='semibold' size='large' justify='center'>
					{$.__('Thank you for entering the deposit amount. We will contact you once we receive a verification response.')}
				</BodyText>,
				<p className='AccountVerificationModal__sheetContent AccountVerificationModal__sheetContent--verifying'>
					{`
						${$.__('Thank you for entering the deposit amount. We will')}
						${$.__('contact you once we receive a verification response.')}
					`}
				</p>
			),
		};
	};

	_handleSheetClose = (reloadPage) => {
		const { onClose, onSubmit } = this.props;

		this.setState({
			failedSheetOpen: false,
			successSheetOpen: false,
		});

		// Close parent modal as well
		window.closeMessage();
		onClose();

		if (reloadPage && onSubmit) {
			onSubmit();
		}
	};

	_handleAmountChangeFocus = (event) => {
		const { value } = event.target;

		if (value && value !== '0.00') {
			return;
		}

		this.setState({ amount: '0.00' });
	};

	_handleAmountChange = (event) => {
		// Acts more like a financial calculator, right to left with a base of 0.00
		const regex = /(\d*?)(\d{2})?$/;
		let number = event.target.value.replace(/[^\d]/g, '').replace(/^0*/, '');

		if (number) {
			number = number.padStart(3, '0');
			let [, dollars, cents] = number.match(regex);
			dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			number = `${dollars}.${cents}`;
		}

		const newValue = number || '0.00';

		this.setState({ amount: newValue, hasError: false });
	};

	_handleNeedHelpClick = () => {
		const url =
			'https://help.bamboohr.com/hc/en-us/articles/360036626833-Company-Bank-Account#do-you-need-help-confirming-the-penny-amount';

		window.open(url, '_blank');
	};

	_handleModalClose = () => {
		const { onClose } = this.props;

		setTimeout(() => {
			window.closeMessage();
		}, 1000);

		onClose();
	};

	_handleFormSubmit = () => {
		const { amount } = this.state;
		const { clientId, bankId } = this.props;

		if (!AMOUNT_REGEX.test(amount)) {
			this.setState({ hasError: true });
			window.setMessage($.__('Oops, the deposit amount should be a valid dollar amount.'), 'error');
			return;
		}

		Ajax.post('/settings/payroll/ajax/bank_account/verify_pennies', {
			clientId,
			bankId,
			pennyAmount: amount,
		})
			.then((response) => {
				if (response.status === 200) {
					if (response.data.error === true) {
						if (response.data.timesRemaining === null) {
							this.setState({
								failedSheetOpen: true,
							});
						}

						window.setMessage(response.data.message, 'error');
						return;
					}

					if (response.data.isNpcOnboardingFlowManual) {
						this.setState({
							verifyingSheetOpen: true,
						});

						return;
					}

					this.setState({
						successSheetOpen: true,
					});
				}
			})
			.catch(() => {
				window.setMessage(
					$.__('There was an error during the bank verification process. Please contact BambooHR Payroll Support'),
					'error'
				);
			});
	};

	state = {
		amount: '',
		hasError: false,
		failedSheetOpen: false,
		successSheetOpen: false,
		verifyingSheetOpen: false,
	};

	render() {
		const { isOpen, bankName, accountNum } = this.props;
		const { amount, hasError } = this.state;

		const instructionClasses = c('AccountVerificationModal__formInstruction', { 'fab-Label--error': hasError });

		return (
			<Modal
				additionalAction={this._handleNeedHelpClick}
				additionalActionText={$.__('Need help?')}
				headline={$.__('Enter the deposited amount to verify your account')}
				icon={ifFeature('encore', 'building-columns-regular', 'fab-bank-coin-54x54')}
				iconV2Color='primary-strong'
				isOpen={isOpen}
				onClose={this._handleModalClose}
				primaryAction={this._handleFormSubmit}
				primaryActionKey='Enter'
				primaryActionText={$.__('Verify Account')}
				sheetProps={this._getSheetProps()}
				title={$.__('Bank Account Verification')}
			>
				{ifFeature(
					'encore',
					<Flex flexDirection='column' gap={3} textAlign='center'>
						<BodyText>
							{$.__(
								'We sent a small deposit to your %1$s %2$s account and retrieved it in one withdrawal. After it appears in your bank account activity, enter the amount below.',
								bankName,
								accountNum
							)}
						</BodyText>

						<Table
							rows={[{ bankName, accountNum }]}
							rowKey={({ accountNum }) => accountNum}
							columns={[{ header: $.__('Bank Statement'), cell: () => '...NPC00001', width: '100%' }, { cell: () => '$*.**' }]}
						/>

						<StyledBox padding={3} backgroundColor='neutral-extra-weak' borderRadius='small'>
							<Flex flexDirection='column' alignItems='center' justifyContent='center' gap={0.5}>
								<BodyText>{$.__('Enter deposit amount')}</BodyText>
								<Flex alignItems='center' gap={1.25}>
									<BodyText>$</BodyText>
									<TextInput
										hasError={hasError}
										onChange={this._handleAmountChange}
										onFocus={this._handleAmountChangeFocus}
										placeholder='0.00'
										value={amount}
										width={4}
									/>
								</Flex>
							</Flex>
						</StyledBox>
					</Flex>,
					<div className='AccountVerificationModal'>
						<p className='AccountVerificationModal__explanation'>
							{`
							${$.__('We sent a small deposit to your %1$s %2$s account and retrieved it in 1 withdrawal', bankName, accountNum)}.
							${$.__('After it appears in your bank account activity, enter the amount below')}.
						`}
						</p>
						<div className='AccountVerificationModal__example'>
							<div className='AccountVerificationModal__exampleStatement'>{$.__('Bank Statement')}</div>

							<div className='AccountVerificationModal__exampleDeposit'>
								<p className='AccountVerificationModal__exampleName'>...NPC00001</p>
								<p className='AccountVerificationModal__exampleAmount'>$*.**</p>
							</div>
						</div>
						<div className='AccountVerificationModal__form'>
							<p className={instructionClasses}>{$.__('Enter deposit amount')}</p>
							<span className='fab-FormField'>$</span>
							<span className='fab-FormField'>
								<TextInput
									hasError={hasError}
									onChange={this._handleAmountChange}
									onFocus={this._handleAmountChangeFocus}
									placeholder='0.00'
									value={amount}
									width='2'
								/>
							</span>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}
