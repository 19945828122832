import {
	Fragment,
	useContext,
} from 'react';
import { Select } from '@bamboohr/fabric';

import { getLossOfEligibilityOptions } from './get-loss-of-eligibility-options';
import { UpdateGroupProperty } from '../types';
import { WizardStateContext } from '../../context/wizard-state-context';
import { LossOfEligibilityContract } from '../../types/contracts';
import { WizardState } from '../../types/wizard-state';

interface LossEligibilityProps {
	lossOfEligibility: LossOfEligibilityContract;
	updateGroupProperty: UpdateGroupProperty;
}

export function LossEligibility(props: LossEligibilityProps): JSX.Element {
	const {
		lossOfEligibility,
		updateGroupProperty,
	} = props;
	const wizardState = useContext(WizardStateContext);

	function prepareLossOfEligibilityOptions(selectedValues: string[]): LossOfEligibilityContract {
		if (selectedValues && selectedValues.length) {
			return {
				ruleCalculationType: selectedValues[0],
			};
		}
		return {
			ruleCalculationType: null,
		};
	}

	return (
		<Fragment>
			<label
				className="fab-Label"
				htmlFor="lossOfEligibility"
			>
				{ $.__('When do terminated employees lose eligibility?') }
			</label>
			<div className="fab-FormRow">
				<span className="fab-FormField">
					<Select
						isDisabled={ (wizardState === WizardState.Reactivate) }
						items={ getLossOfEligibilityOptions() }
						onChange={ (selectedValues: string[]) => {
							const newLossOfEligibilityOptions = prepareLossOfEligibilityOptions(selectedValues);
							updateGroupProperty('lossOfEligibility', newLossOfEligibilityOptions);
						} }
						selectedValues={ (lossOfEligibility) ? [lossOfEligibility.ruleCalculationType] : [] }
						width={ 8 }
					/>
				</span>
			</div>
		</Fragment>
	);
}
