import { LayoutBox, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { redirect } from 'BambooHR.util';

import { useBenefitsSettingsStore } from '../../../data/benefits-settings-store';
import { BenefitPlanStatusContract } from '../../../types';
import { PlanTypeEnumContract, coverageOptionsPlanTypes } from '../../../../types';

interface EnrolledProps {
	planId: number;
	planIsMissingCoverageOptions: boolean;
	planType: PlanTypeEnumContract;
	setIsEligibilityModalOpen: (open: boolean) => void;
	setIsMissingCoveragesModalOpen: (open: boolean) => void;
	status: BenefitPlanStatusContract;
}

export const Enrolled = (props: EnrolledProps): JSX.Element => {
	const { planId, planIsMissingCoverageOptions, planType, setIsEligibilityModalOpen, setIsMissingCoveragesModalOpen, status } = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const enrolledWaivedLink = (
		<TextButton
			color='secondary'
			inline={true}
			onClick={() => {
				if (
					isBenefitsAdminEnabled &&
					coverageOptionsPlanTypes.includes(planType) &&
					planIsMissingCoverageOptions
				) {
					setIsMissingCoveragesModalOpen(true);
					return;
				}
				redirect(`/settings/benefits/plan/${planId}/bulk_status`);
			}}
			type='button'
		>
			<>
				{$.__('%s Enrolled', status.enrolled)}
				{status.notEnrolledOrWaived > 0 && <>{$.__(' %s Not Enrolled/Waived', status.notEnrolledOrWaived)}</>}
			</>
		</TextButton>
	);

	const eligibleLink = (
		<TextButton
			color='secondary'
			inline={true}
			onClick={() => {
				setIsEligibilityModalOpen(true);
			}}
			type='button'
		>
			{$.__('%s will be eligible when marked manually.', status.eligibleWhenMarkedManually)}
		</TextButton>
	);

	/* @startCleanup encore */
	const jade = (
		<>
			<div className='Columns__statusActiveNotDraft'>
				<div>{enrolledWaivedLink}</div>
				{status.eligibleWhenMarkedManually > 0 && <div>{eligibleLink}</div>}
			</div>
		</>
	);
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<>
			<LayoutBox>{enrolledWaivedLink}</LayoutBox>
			{status.eligibleWhenMarkedManually > 0 && <LayoutBox>{eligibleLink}</LayoutBox>}
		</>,
		jade,
	);
};
