import Ctrl, {
	onSubmit,
	onClick
} from 'ctrl.deco';

@Ctrl('/settings/account/feeds.php')
class SettingsCalendarFeedCtrl {
	get submitButton() {
		return $('.js-disableOnSubmit__submitButton');
	}

	@onSubmit('.js-disableOnSubmit')
	_submit(e, { submitButton }) {
		submitButton.toggleClass('fab-Button--processing');
	}

	@onClick('#settings-page label')
	_clickHandler() {
		$('#saveFeeds').prop('disabled', false);
	}
}
