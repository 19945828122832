import { Fragment, useState } from 'react';

import { Modal } from 'modal-legacy';
import { Tooltip, makeStyles, createStyles } from '@bamboohr/fabric';
import { Icon, TextButton } from '@fabric/button';

import EmployeesList from 'employees-list.react';

import { getEmployeesForList } from './utils';
import { EmployeeList } from '../../types';

interface EmployeeListModalProps {
	allEmployees: EmployeeList;
	employeeIds: number[];
	noEmployeesCapturedWarning: boolean;
	planName: string;
}

const useStyles = makeStyles(({ palette }) => {
	return createStyles({
		warningText: {
			color: palette.warning.main,
			fontSize: 14,
		},
	});
});

export const EmployeeListModal = (
	props: EmployeeListModalProps,
): JSX.Element => {
	const { allEmployees, employeeIds, noEmployeesCapturedWarning, planName } =
		props;

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const classes = useStyles();

	const closeModal = (): void => {
		setIsModalOpen(false);
	};

	return (
		<Fragment>
			{noEmployeesCapturedWarning ? (
				<Tooltip
					content={
						<div>
							<div>{$.__('No employees match')}</div>
							<div>{$.__('your current filters')}</div>
						</div>
					}
				>
					<span className="EmployeeList__warning">
						<Icon name="fab-triangle-exclamation-13x11" />
						<span className={classes.warningText}>{$.__('0 selected')}</span>
					</span>
				</Tooltip>
			) : (
				<TextButton
					clickAction={(): void => setIsModalOpen(true)}
					data-bi-id="preview-employees-selected"
					muted={true}
					size="small"
					text={$.__('%1 selected', employeeIds.length)}
					type="button"
				/>
			)}
			<Modal
				alternativeAction={null}
				contentHasPadding={false}
				isOpen={isModalOpen}
				onClose={closeModal}
				primaryAction={closeModal}
				primaryActionText={$.__('Done')}
				title={planName}
			>
				<EmployeesList
					employees={getEmployeesForList(employeeIds, allEmployees)}
					title={$.__('%s Employees Eligible', employeeIds.length)}
				/>
			</Modal>
		</Fragment>
	);
};
