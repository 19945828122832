import { WizardState } from '../../types/wizard-state';
import { EligibilityFilterValues, PlanState } from '../../types/wizard-values';
import { hasPlanStarted, isCompletePlan } from '../../utils';
import { hasEligibilityFiltersCount } from './has-eligibility-filters-count';

export const isFirstGroup = (index: number): boolean => {
	return index === 0;
};

export const hasFilterEditRestrictions = (
	planState: PlanState,
	startDate: string,
	groupId: string | number,
	eligibilityFilters?: EligibilityFilterValues,
	wizardState?: WizardState
): boolean => {
	if (wizardState === WizardState.Reactivate) {
		return true;
	}

	// Allow deletion of group if no filters are found in filterGroups and no EE ids found in specificSelection
	if (
		eligibilityFilters &&
		(eligibilityFilters.areSpecificPeopleSelected || !hasEligibilityFiltersCount(eligibilityFilters.filterGroup.values))
	) {
		return false;
	}

	return isCompletePlan(planState) && !String(groupId).includes('new') && hasPlanStarted(startDate);
};
