import { DeductionDateRuleContract } from '../types/contracts';
import { DeductionOffsetValue } from '../types/wizard-values';

export function getDeductionDateRulePostData(
	deductionOffset?: DeductionOffsetValue
): DeductionDateRuleContract {
	const deductionDateRule = {
		number: null,
		direction: null,
	};

	if (deductionOffset !== null) {
		deductionDateRule.number = deductionOffset.number;
		deductionDateRule.direction = deductionOffset.direction;
	}

	return deductionDateRule;
}
