import c from 'classnames';

export function InputField(props) {
	const {
		customClass,
		disabled,
		name,
		placeholder,
		updateFormData,
		value,
		width,
	} = props;

	const inputClassName = c(
		'fab-TextInput',
		{ [`fab-TextInput--width${ width }`]: width },
		{ [`${ customClass }`]: customClass }
	);

	function handleOnChange(e) {
		updateFormData(name, { error: false, value: e.target.value });
	}

	return (
		<input
			className={ inputClassName }
			disabled={ disabled }
			name={ name }
			onChange={ handleOnChange }
			placeholder={ placeholder }
			value={ value || '' }
		/>
	);
}
