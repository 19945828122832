import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(() => ({
	dropdown: {
		margin: ifFeature('encore', '0 1px'),
		maxWidth: '365px',
		maxHeight: '358px',
		width: '300px',
	},
}));
