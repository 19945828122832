import React, { useState } from 'react';
import { Button, Typography } from '@bamboohr/fabric';
import { UpsellModal } from '../components';
import { benefitsAdmin } from '../../../app-configs/upsell';
import api from '../../../app-services/api';
import styles from './styles';

const BenefitsAdminInTrialUpsell = ({ isOpen, onClose }) => {
	const [isProcessing, setIsProcessing] = useState(false);

	const {
		disclaimer,
		trialButton,
		trialDisclaimer,
		trialHeadline,
		trialSummary,
		trialSummary2,
		trialSummaryItems,
		trialTitle,
		videoId,
	} = benefitsAdmin;

	const classes = styles();

	const enableBenefitsAdminInTrial = () => {
		setIsProcessing(true);
		api.benefitsAdmin.enable
			.post()
			.then(() => {
				window.location.reload();
			})
			.catch(() => {
				window.errorFallBack();
				setIsProcessing(false);
			});
	};

	return (
		<UpsellModal isOpen={isOpen} onRequestClose={onClose} title={trialTitle} wistiaVideoId={videoId}>
			<h4>{trialHeadline}</h4>
			<p>{trialSummary}</p>
			<p>{trialSummary2}</p>
			<ul>
				{trialSummaryItems.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={`benefits-admin-item-${index}`}>{item}</li>
				))}
			</ul>
			<Typography color={(theme) => theme.palette.gray[800]} variant='small'>
				{disclaimer}
			</Typography>
			<div className={classes.wrapper}>
				<Button
					data-bi-id='benefits-admin-demo-modal-enable-addOn-button'
					isProcessing={isProcessing}
					onClick={enableBenefitsAdminInTrial}
					size='large'
					text={trialButton}
					type='button'
				/>
				<div className={classes.note}>*{trialDisclaimer}</div>
			</div>
		</UpsellModal>
	);
};

BenefitsAdminInTrialUpsell.defaultProps = {
	isOpen: false,
	onClose: () => {},
};

export default BenefitsAdminInTrialUpsell;
