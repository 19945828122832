import { BasicLifeOrADandDEnum, LifeInsuranceTypeEnum, VoluntaryLifeOrADandDEnum } from '../types';

export type FunctionList<T> = {
	[key in LifeInsuranceTypeEnum | BasicLifeOrADandDEnum | VoluntaryLifeOrADandDEnum]?: () => T;
}

export const makeLifePlanTypeLikeValue = <T>(
	type: string | null,
	functionList: FunctionList<T>,
	defaultFunction: () => T,
) => functionList[type]?.() ?? defaultFunction()
