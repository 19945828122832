import { isEqual } from 'lodash';
import { MFA_LEVELS } from '../../constants';
import { MfaFormData } from './types';

export function isFormDirty(originalFormData: MfaFormData, currentFormData: MfaFormData): boolean {
	if (originalFormData.startDate !== currentFormData.startDate) {
		return true;
	}
	if (originalFormData.companyConfiguredAccess !== currentFormData.companyConfiguredAccess) {
		return true;
	}
	if (!isEqual(originalFormData.selectedAccessLevelIds, currentFormData.selectedAccessLevelIds)) {
		return true;
	}
	return false;
}

export function isFormValid(originalFormData: MfaFormData, currentFormData: MfaFormData): boolean {
	if (currentFormData.companyConfiguredAccess === 'userOptIn') {
		return true;
	}
	if (!currentFormData.startDate) {
		return false;
	}

	if (!currentFormData.companyConfiguredAccess) {
		return false;
	}

	if (currentFormData.companyConfiguredAccess === MFA_LEVELS.ACCESS_LEVEL && currentFormData?.selectedAccessLevelIds?.length === 0) {
		return false;
	}

	return true;
}
