export default (selectedInvoices = [], invoices) => {
	if (selectedInvoices.length === 0) {
		return null;
	}
	
	const invoicesToGet = invoices.filter(invoice => selectedInvoices.includes(invoice.id));
	const lastIndex = invoicesToGet.length -1;
	
	// Update selected Invoices
	const updatedInvoices = invoices.map(({ id, selected, ...data }) => {
		return {
			id,
			selected: selectedInvoices.includes(id) ? true : selected,
			...data
		}
	});

	if (invoicesToGet.length > 0) {
		return {
			endDate: invoicesToGet[0].billingDate,
			updatedInvoices,
			startDate: invoicesToGet[lastIndex].billingDate,
		}
	}

	return null;
}
