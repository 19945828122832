import { Fragment, useState } from 'react';
import { BodyText, Flex, LayoutBox, Tooltip } from '@bamboohr/fabric';

import { isEnabled as isFeatureEnabled } from 'FeatureToggle.util';

import { Draft } from './statuses/draft';
import { Enrolled } from './statuses/enrolled';
import { Inactive } from './statuses/inactive';
import { NotEnrolled } from './statuses/not-enrolled';

import './status.styl';
import { PoMicroFrontend } from 'micro-frontend.react';
import { getPlanIsMissingCoverageLevels} from './utils/get-plan-is-missing-coverage-levels';
import { getPlanUnsupportedCoverages } from './utils/get-plan-unsupported-coverages';
import { EligibilityModal } from '../../eligibility-modal/eligibility-modal';
import { BenefitPlanCoverages, BenefitPlanStatusContract } from '../../types';
import { CoverageTypes } from '../../../types/coverage-type';
import { PlanTypeEnumContract, coverageOptionsPlanTypes } from '../../../types';
import { useBenefitsSettingsStore } from '../../data/benefits-settings-store';
import { FEATURE_TOGGLE_KEYS } from '../../../etc/feature-toggle';

interface StatusProps {
	coverages: BenefitPlanCoverages[];
	endDate: string;
	isActive: boolean;
	isDraft: boolean;
	isFutureDated: boolean;
	isRestorable?: boolean;
	missingPlanDetails: boolean;
	planId: number;
	planName: string;
	planType: PlanTypeEnumContract;
	startDate: string;
	status: BenefitPlanStatusContract;
	supportedCoverageLevels: CoverageTypes;
}

export function Status(props: StatusProps): JSX.Element {
	const {
		coverages,
		isActive,
		isDraft,
		isFutureDated,
		isRestorable,
		missingPlanDetails,
		planId,
		planName,
		planType,
		status,
		startDate,
		endDate,
		supportedCoverageLevels,
	} = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState(false);
	const [isMissingCoveragesModalOpen, setIsMissingCoveragesModalOpen] = useState<boolean>(false);
	const planDates = { startYmd: startDate, endYmd: endDate || '2079-06-06' };


	const selectedCoverageLevelIds = Object.values(coverages).map((plan: BenefitPlanCoverages) => plan.benefitCoverageId);
	const planIsMissingCoverageOptions: boolean = getPlanIsMissingCoverageLevels(supportedCoverageLevels, selectedCoverageLevelIds);
	const planUnsupportedCoverages = getPlanUnsupportedCoverages(supportedCoverageLevels, coverages).length;

	const showUnsupportedCoverageStatus = isBenefitsAdminEnabled && Boolean(planUnsupportedCoverages);
	const showMissingCoveragesStatus =
		isBenefitsAdminEnabled &&
		coverageOptionsPlanTypes.includes(planType) &&
		planIsMissingCoverageOptions &&
		Boolean(!planUnsupportedCoverages)

	return (
		<Fragment>
			{isDraft ? (
				<Draft planId={planId} />
			) : (
				<Fragment>
					{isActive || isFutureDated ? (
						<Fragment>
							{status.enrolled > 0 ? (
								<Enrolled
									planId={planId}
									planIsMissingCoverageOptions={planIsMissingCoverageOptions}
									planType={planType}
									setIsEligibilityModalOpen={setIsEligibilityModalOpen}
									setIsMissingCoveragesModalOpen={
										setIsMissingCoveragesModalOpen
									}
									status={status}
								/>
							) : (
								<NotEnrolled
									eligibleWhenMarkedManually={status.eligibleWhenMarkedManually}
									planId={planId}
									planIsMissingCoverageOptions={planIsMissingCoverageOptions}
									planType={planType}
									setIsEligibilityModalOpen={setIsEligibilityModalOpen}
									setIsMissingCoveragesModalOpen={
										setIsMissingCoveragesModalOpen
									}
								/>
							)}
						</Fragment>
					) : (
						<Inactive isRestorable={isRestorable} planId={planId} />
					)}
					<EligibilityModal
						id={planId}
						isOpen={isEligibilityModalOpen}
						onClose={() => {
							setIsEligibilityModalOpen(false);
						}}
						planDates={planDates}
						planName={planName}
					/>
					<PoMicroFrontend
						isOpen={isMissingCoveragesModalOpen}
						onClose={() => setIsMissingCoveragesModalOpen(false)}
						planId={planId.toString()}
						route="/settings/benefits/missing-coverages-modal"
					/>

					{isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_HOME_PAGE) ? (
						missingPlanDetails && (
							<LayoutBox marginTop={2}>
								<Tooltip
									content={$.__(
										'New plan details need to be provided before you can continue managing enrollments in this plan.',
									)}
									placement='bottom-start'
								>
									<Flex>
										<BodyText
											color="error-strong"
											icon="triangle-exclamation-regular"
										>
											{$.__('Additional Details Needed')}
										</BodyText>
									</Flex>
								</Tooltip>
							</LayoutBox>
						)
					) : (
						<>
							{showUnsupportedCoverageStatus && (
								<PoMicroFrontend
									planId={planId}
									planIsMissingCoverageOptions={planIsMissingCoverageOptions}
									route="/settings/benefits/unsupported-coverages-popover"
									unsupportedCoverageLevelAmount={planUnsupportedCoverages}
								/>
							)}
							{showMissingCoveragesStatus && (
								<PoMicroFrontend
									planId={planId}
									route="/settings/benefits/missing-coverages-popover"
								/>
							)}
						</>
					)}
				</Fragment>
			)}
		</Fragment>
	);
}
