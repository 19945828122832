import { useEffect } from 'react';

import { Select } from '@fabric/select';

import { formatPayFrequencies } from '../utils';

export function PayFrequencyField(props) {
	const {
		disabled,
		error,
		items,
		name,
		placeholder,
		selectedTaxType,
		updateFormData,
		value,
		width,
	} = props;

	useEffect(() => {
		if (selectedTaxType) {
			const formattedPayFrequencies = formatPayFrequencies(selectedTaxType.allowablePayFrequencies);
			if (formattedPayFrequencies.length === 1) {
				updateFormData(name, { disabled: true, items: formattedPayFrequencies, value: formattedPayFrequencies[0].value });
			} else {
				updateFormData(name, { disabled: false, items: formattedPayFrequencies });
			}
		} else {
			updateFormData(name, { disabled: true, error: false, items: [], value: null });
		}
	}, [selectedTaxType]);

	function handleOnChange(selection) {
		updateFormData(name, { error: false, value: selection[0] });
	}

	return (
		<div>
			<Select
				condition={ error ? 'error' : '' }
				isClearable={ !disabled }
				isDisabled={ disabled }
				items={ items }
				name={ name }
				onChange={ handleOnChange }
				placeholder={ disabled ? placeholder : `-${ $.__('Select') }-` }
				selectedValues={ [value] }
				width={ width || 5 }
			/>
			{ items.length === 1 && (
				<span className="fab-FormNote">
					{ $.__('This is the only available pay frequency.') }
				</span>
			) }
		</div>
	);
}
