import {
	Flex,
	Label,
	LayoutBox,
	NameValuePair,
	Section,
	Select
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import classNames from 'classnames';
import {
	useEffect,
	useState,
} from 'react';

import { CodesAndRates } from '../components/shared/codes-and-rates';
import {
	saveCodesRatesUpdate,
} from './utils';

import './update-codes-rates.styl';

export function UpdateCodesRates(props) {
	const {
		taxDetails: {
			codesAndRates,
			company,
			ein,
			taxTypeState,
			taxType,
			frequency,
			clientTaxTypeIncludeId: taxId,
			clientId,
		},
		effectiveDateOptions,
	} = props;
	const [hasError, setHasError] = useState(false);
	const [selectedDate, setSelectedDate] = useState(['']);
	const effectiveDateError = hasError && !selectedDate[0];
	const taxTypeDisplay = taxType || 'WA L&I'; // WA L&I is the default for now
	const effectiveDateLabelClasses = classNames(
		'fab-Label fab-Label--required',
		{ 'fab-Label--error': effectiveDateError }
	);
	const formattedEffectiveDateOptions = effectiveDateOptions.map((option) => {
		const {
			value,
			quarterDate,
			quarterText,
		} = option;
		return {
			text: `${ quarterText } (${ quarterDate })`,
			value,
		};
	});

	useEffect(() => {
		const saveButton = document.querySelector('button[data-action="SiteFooterAction:saveCodesRatesUpdate"]');
		saveButton.addEventListener('click', () => saveCodesRatesUpdate('.js-updateCodesRates', setHasError));
	}, []);

	return (
		<form className={`${ifFeature('encore', '', 'UpdateCodesRates')} js-updateCodesRates`}>
			<Flex flexDirection="column" gap={ ifFeature('encore', '40px', undefined) }>
				<input name="taxId" type="hidden" value={ taxId || '' } />
				<input name="clientId" type="hidden" value={ clientId || '' } />
				{ ifFeature('encore',
					<Section title={ $.__('Tax Details') }>
						<Flex flexDirection="row" gap="40px" marginTop="24px">
						{ company && ein && <NameValuePair name={$.__('Company/EIN')} value={`${ company } — ${ ein }`} /> }
						{ taxTypeState && <NameValuePair name={$.__('State')} value={taxTypeState} /> }
						{ taxTypeDisplay && <NameValuePair name={$.__('Tax Type')} value={taxTypeDisplay} /> }
						{ frequency && <NameValuePair name={$.__('Pay Frequency')} value={frequency} /> }
						{ taxId && <NameValuePair name={$.__('Tax ID')} value={taxId} /> }
						</Flex>
					</Section>,
					<h5>{ $.__('Tax Details') }</h5>
				)}

				{ ifFeature('encore', 
					null, 
					<div className="UpdateCodesRates__detail">
						{ company && ein && renderTaxDetail($.__('Company/EIN'), `${ company } — ${ ein }`) }
						{ taxTypeState && renderTaxDetail($.__('State'), taxTypeState) }
						{ taxTypeDisplay && renderTaxDetail($.__('Tax Type'), taxTypeDisplay) }
						{ frequency && renderTaxDetail($.__('Pay Frequency'), frequency) }
						{ taxId && renderTaxDetail($.__('Tax ID'), taxId) }
					</div>
				) }

				{ ifFeature('encore', null , <hr />) }

				{ ifFeature('encore', 
					<Section title={ $.__('When will these changes take effect?') }>
						<Flex flexDirection="column" marginTop={3}>
							<Label className={ effectiveDateLabelClasses } htmlFor="effectiveDate">
								{ $.__('Effective Date') }
							</Label>
							<Select
								condition={ effectiveDateError ? 'error' : 'none' }
								items={ formattedEffectiveDateOptions }
								name="effectiveDate"
								onChange={ value => setSelectedDate(value) }
								selectedValues={ selectedDate }
								width="7"
							/>
						</Flex>
					</Section>,
					<div className="UpdateCodesRates__effectiveDate">
						<h5>{ $.__('When will these changes take effect?') }</h5>
						<label className={ effectiveDateLabelClasses } htmlFor="effectiveDate">
							{ $.__('Effective Date') }
						</label>
						<Select
							condition={ effectiveDateError ? 'error' : 'none' }
							items={ formattedEffectiveDateOptions }
							name="effectiveDate"
							onChange={ value => setSelectedDate(value) }
							selectedValues={ selectedDate }
							width="7"
						/>
					</div>
				) }

				{ ifFeature('encore', null , <hr />) }
				{ ifFeature('encore', 
					<Section title={ $.__('Codes & Rates') }>
						<LayoutBox marginTop={3}>
							<CodesAndRates
								codesRatesData={ codesAndRates }
								hasError={ hasError }
								isCodesRatesEdit={ true }
								isTaxEdit={ false }
							/>
						</LayoutBox>
					</Section>,
					<CodesAndRates
						codesRatesData={ codesAndRates }
						hasError={ hasError }
						isCodesRatesEdit={ true }
						isTaxEdit={ false }
					/>
				) }
			</Flex>
		</form>
	);
}

function renderTaxDetail(label, value) {
	return (
		<div className="UpdateCodesRates__detailItem">
			<p className="UpdateCodesRates__detailLabel">{ label }</p>
			<p className="UpdateCodesRates__detailValue">{ value }</p>
		</div>
	);
}

