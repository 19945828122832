import {
	includes,
} from 'lodash';

const uids = [];
/**
 * generate a random and unique string of a given length (defaults to 8 characters)
 *
 * @method makeUid
 *
 * @param  {Number} [length] the length of the resulting string
 * @param  {Number} radix the numeral system to use for the string
 *
 * @return {String}            the random, unique string
 */
export function makeUid(length = 8, radix = 36) {
	let uid = '';
	while (uid == '' || !includes(uids, uid)) {
		uid = (Math.random() + 1).toString(radix).substr(2, length);
		uids.push(uid);
	}

	while (uid.length < length) {
		uid += makeUid(length - uid.length, radix);
	}

	return uid;
}
