import Ajax from '@utils/ajax';

const POST = () => {
	return Ajax.post('/settings/account/subscription/product_performance/request_demo', {
		pageName: 'feature-page',
		pageUrl: window.location.href,
	}).then((response) => {
		if (response.status !== 200) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
		return response;
	});
};

export default POST;
