import { Fragment } from 'react';

import {Message, SpanClass} from '@bamboohr/utils/lib/message';
import './deduction-date-option.styl';
import {isEnabled} from "base/_utils/FeatureToggle.util";
import {getTranslatedDeductionDateOptions} from "./utils";

export function DeductionDateOption(props) {
	const { direction, number, showMostCommon } = props;
	const FIX_DEDUCTION_QUESTIONS_IS_ENABLED = isEnabled('fixDeductionQuestions');
	
	if(FIX_DEDUCTION_QUESTIONS_IS_ENABLED) {
		return ((direction && number) ? (
			<Fragment>
				<Message
					em={ SpanClass(`DeductionDate__optionNote`) }
					text={ getTranslatedDeductionDateOptions(number, direction, showMostCommon) } />
			</Fragment>
		) : (null));
	} 
	return ((direction && number) ? (
		<Fragment>
			{ (number === 2) ? (
				<Fragment>
					<span className="DeductionDate__option--bold">{ $.__('Two') }</span>
					{ $.__(' pay dates') }
				</Fragment>
			) : (
				<Fragment>
					<span className="DeductionDate__option--bold">{ $.__('One') }</span>
					{ $.__(' pay date') }
				</Fragment>
			) }
			<span className="DeductionDate__option--bold">
				{ (direction === 'before') ? $.__(' before') : $.__(' after') }
			</span>
			{ (showMostCommon && number === 1 && direction === 'after') ? (
				<Fragment>
					{ $.__(' the benefit effective date') }
					<div className="DeductionDate__optionNote">{ `(${ $.__('Most common') })` }</div>
				</Fragment>
			) : (
				$.__(' benefit effective date')
			) }
		</Fragment>
	) : (null));
}
