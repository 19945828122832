import { createContext, useMemo, useState, useContext } from 'react';
import { SettingsContext } from './types';

const Context = createContext<SettingsContext>(undefined);

export default Context;

interface Props {
	value: Record<string, unknown>,
	children: JSX.Element,
}

export const Provider = (props: Props): JSX.Element => {
	const {
		value,
		children,
	} = props;

	const [slidedownData, setSlidedownData] = useState(value.slidedownData);
	const [reviewCycleData, setReviewCycleData] = useState(value.reviewCycleData);

	const state = useMemo(() => ([{
		slidedownData,
		reviewCycleData,
	}, {
		setReviewCycleData,
		setSlidedownData,
	}]), [slidedownData, reviewCycleData]);

	return (
		<Context.Provider value={ state as SettingsContext }>
			{ children }
		</Context.Provider>
	);
};

export const { Consumer } = Context;

export const usePerformanceSettingsContext = (): SettingsContext => useContext(Context);
