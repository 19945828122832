import { Flex, InputAdornment, TextField } from '@bamboohr/fabric';
import { ChangeEvent, useEffect, useState } from 'react';

import { ErrorTooltip } from '../error-tooltip/error-tooltip';
import { DEFAULT_CALCULATOR_SETTINGS } from '../../../../constants';
import { useValidationContext } from '../../../../../context';
import { EligibilityGroupCalculatorSettings, EligibilityGroupValues } from '../../../../../types';
import { generateCompanyPaysErrorKey, validateDisabilityCompanyPays } from '../../../../../utils';

interface CompanyPaysCellProps {
	eligibilityGroup: EligibilityGroupValues;
	groupIndex: number;
	updateGroupProperty: (
		groupIndex: number,
		key: keyof EligibilityGroupValues,
		calculatorSettings: EligibilityGroupCalculatorSettings
	) => void;
}

export const CompanyPaysCell = (props: CompanyPaysCellProps): JSX.Element => {
	const { eligibilityGroup, groupIndex, updateGroupProperty } = props;
	const companyPays = String(eligibilityGroup?.calculatorSettings?.companyPays ?? '');
	const errorKey = generateCompanyPaysErrorKey(eligibilityGroup.id);

	const { setSpecialErrors, specialErrors } = useValidationContext();
	const [inputValue, setInputValue] = useState(companyPays);

	const rules = {
		required: true,
		lessThanOrEqualTo: true,
		greaterThanOrEqualTo: true,
	};

	useEffect(() => {
		if (eligibilityGroup?.calculatorSettings?.companyPays) {
			setInputValue(companyPays);
		} else {
			setInputValue(null);
		}
	}, [eligibilityGroup, companyPays]);

	const packageCalculatorSettings = (newCompanyPays: number): EligibilityGroupCalculatorSettings => {
		let tempCalculatorSettings: EligibilityGroupCalculatorSettings;

		if (eligibilityGroup.calculatorSettings) {
			tempCalculatorSettings = {
				...eligibilityGroup.calculatorSettings,
				companyPays: newCompanyPays,
			} as EligibilityGroupCalculatorSettings;
		} else {
			tempCalculatorSettings = {
				...DEFAULT_CALCULATOR_SETTINGS,
				companyPays: newCompanyPays,
			} as EligibilityGroupCalculatorSettings;
		}
		return tempCalculatorSettings;
	};

	const handleCompanyPaysChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleCompanyPaysBlur = (): void => {
		const isEmptyString = inputValue === '';
		const newCompanyPays = isEmptyString ? null : Number(inputValue);
		if (isEmptyString && newCompanyPays < 0) {
			setInputValue('');
		}

		const newCalculatorSettings: EligibilityGroupCalculatorSettings = packageCalculatorSettings(newCompanyPays);
		updateGroupProperty(groupIndex, 'calculatorSettings', newCalculatorSettings);
		validateDisabilityCompanyPays(errorKey, newCompanyPays, setSpecialErrors);
	};

	return (
		<Flex alignItems='center' gap={1}>
			{Boolean(specialErrors[errorKey]) && <ErrorTooltip message={specialErrors[errorKey].message} />}
			<TextField
				InputProps={{
					endAdornment: <InputAdornment position='end'>%</InputAdornment>,
				}}
				biId='disability-company-pays-input'
				id={`companyPays_${groupIndex}`}
				name='companyPays'
				onBlur={handleCompanyPaysBlur}
				onChange={handleCompanyPaysChange}
				required={rules.required}
				status={specialErrors[errorKey] ? 'error' : undefined}
				value={inputValue}
				width={4}
			/>
		</Flex>
	);
};
