import { makeStyles, createStyles } from "@bamboohr/fabric";

export const useStyles = makeStyles(({ palette}) => {
	return createStyles({
		enpsSection: {
			display: 'flex',
			alignItems: 'flex-start',
			'& + &': {
				marginTop: 30,
				paddingTop: 30,
				borderTop: `1px solid ${palette.gray[300]}`,
			}
		},
		enpsFigure: {
			margin: '0 15px 0 0',
			position: 'relative',
		},
		enpsBody: {
			flex: 1,
		},
		icon: {
			lineHeight: 0,
			fontSize: 0,
			fill: palette.primary.main,
		},
		iconSubdued: {
			fill: palette.gray[700],
		},
		iconSuccess: {
			fill: palette.success.main,
		},
		bubble: {
			border: '1px solid',
			borderRadius: '50%',
			display: 'block',
			width: 14,
			height: 14,
			lineHeight: '13px',
			textAlign: 'center',
			fontSize: 11,
			fontWeight: 'bold',
			position: 'absolute',
			right: -7,
			bottom: 2,
			boxShadow: `0 0 0 2px ${palette.common.white}`,
			backgroundColor: palette.common.white,
			borderColor: palette.primary.main,
			color: palette.primary.main,
		},
		bubbleSubdued: {
			boxShadow: `0 0 0 2px ${palette.gray[100]}`,
			backgroundColor: palette.gray[100],
			borderColor: palette.gray[700],
			color: palette.gray[700],
		}
	});
});
