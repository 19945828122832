import { ItemType } from '@bamboohr/fabric';
export interface AccessLevel {
	id: string;
	label: string;
	name: string;
	levels?: { label: string; id: string; }[]
}

export function parseAccessLevelGroupsToGroupedMenuItemArray(accessLevels: AccessLevel[]):ItemType[] {

	if (!Array.isArray(accessLevels)) {
		console.error(`Array expected`);
		return [];
	}
	const accessLevelGroups: Record<string, ItemType[]> = {
		admins: [],
		employee: [],
		manager: [],
		custom: [],
	}

	const LevelGroups: Record<string, string> = {
		admins: $.__('Admins'),
		employee: $.__('Employee Levels'),
		manager: $.__('Manager Levels'),
		custom: $.__('Custom Levels'),
	}

	accessLevels.forEach((accessLevel) => {
		switch(accessLevel.name) {
			case 'admin':
			case 'payroll-admin':
				accessLevelGroups.admins.push({text: accessLevel.label, value: accessLevel.id, key: accessLevel.id });
				break;
			case 'employee':
			case 'manager':
			case 'custom':
				if (accessLevel.levels.length > 0) {
					accessLevelGroups[accessLevel.name] = accessLevel.levels.map((level) => {
						return { text: level.label, value: level.id, key: level.id }
					});
				} else {
					delete accessLevelGroups[accessLevel.name];
				}
				break;
			default:
				break;
		}
	});

	return Object.keys(accessLevelGroups).map((accessLevel) => {
		return {
			items: [...accessLevelGroups[accessLevel]],
			key: accessLevel,
			text: LevelGroups[accessLevel] || '',
			type: 'group',
		}
	})
}
