import { render } from 'base/wrapped-render';
import { SetupChecklist } from '.';

export default function initChecklist() {
	if (document.getElementById('js-checklist-root')) {
		document.title = $.__('Time Tracking Checklist');
		render(
			<SetupChecklist />,
			document.getElementById('js-checklist-root')
		);
	}
}
