import { useState } from 'react';

interface EditQuestionReturn {
    editQuestion: (topic: string, topicId: number, surveyId: number) => void;
    hideEditQuestion: () => void;
    topic: string|null;
	topicId: number|null;
	surveyId: number|null;
    isOpen: boolean;
}

const useEditQuestion = (): EditQuestionReturn => {
	const [topicId, setTopicId] = useState<number|null>(null);
	const [topic, setTopic] = useState<string|null>(null);
	const [surveyId, setSurveyId] = useState<number|null>(null);
	return {
		editQuestion: (topic, topicId, surveyId) => {
			setTopic(topic);
			setTopicId(topicId);
			setSurveyId(surveyId);
		},
		hideEditQuestion: () => {
			setTopic(null);
			setTopicId(null);
			setSurveyId(null);
		},
		topic,
		topicId,
		surveyId,
        isOpen: topic !== null && surveyId !== null && topicId !== null,
	};
};

export default useEditQuestion;
