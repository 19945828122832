import {
	useMemo,
	useRef,
} from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	Modal,
} from 'modal-legacy';

import {
	createForm,
} from '../../../shared';

import {
	useReducerContext,
} from '../state';


/** @type {import('../../../shared').FormAPI<FormData>} */
const {
	useForm,
	FormProvider,
	FormHiddenInput,
	FormTextarea,
	FormSection,
	FormLabel,
	FormRow,
} = createForm();

export default function EditValueModalWrapper({
	data,
	useAjaxEffect,
	isOpen,
	...props
}) {
	const {
		field_id,
		langid,
	} = props;
	const formRef = useRef(null);

	useAjaxEffect({
		method: 'get',
		url: 'information_fields.php',
		params: {
			langid,
			field_id,
		},
	}, [field_id, langid], isOpen);

	const formData = useMemo(() => {
		if (!data || !isOpen) {
			return null;
		}

		const {
			translatedField: {
				id: translated_id = '',
				translation = data.field.description,
			} = {},
			languageId: language_id,
		} = data;

		return {
			field_id,
			language_id,
			translated_id,
			translation,
		};
	}, [data, isOpen]);

	return (
		<FormProvider form={ formRef } values={ formData }>
			<EditValueModal
				{ ...props }
				formRef={ formRef }
				isOpen={ isOpen }
				name={ (data && data.field) ? data.field.name : null }
			/>
		</FormProvider>
	);
}

/**
 *
 * @param {Props} props
 */
function EditValueModal({
	isOpen,
	processing,
	formRef,
	name,
}) {
	const {
		dispatch,
		CLOSE_MODAL,
	} = useReducerContext();
	const {
		submitting,
		useSubmitSuccessEffect,
		submit,
	} = useForm();

	useSubmitSuccessEffect(() => {
		dispatch(CLOSE_MODAL());
	});


	return (
		<Modal
			isOpen={ isOpen }
			isLoading={ processing || submitting }
			onClose={ () => dispatch(CLOSE_MODAL()) }
			primaryAction={ () => submit() }
			primaryActionText={ $.__('Save') }
			title={ $.__('Update Information Field') }
		>
			<form
				action="/ajax/dialog/manage/translation/information_fields.php?update=1"
				method="post"
				ref={ formRef }
			>
				{ifFeature(
					'encore',
					<fieldset>
						<FormLabel htmlFor="translation">{name}</FormLabel>
						<FormHiddenInput name="field_id" />
						<FormHiddenInput name="language_id" />
						<FormHiddenInput name="translated_id" />
						<FormRow>
							<FormTextarea
								name="translation"
								width={ 100 }
							/>
						</FormRow>
					</fieldset>,
					<FormSection legend={ name }>
						<FormHiddenInput name="field_id" />
						<FormHiddenInput name="language_id" />
						<FormHiddenInput name="translated_id" />
						<FormRow>
							<FormTextarea
								name="translation"
								width={ 100 }
							/>
						</FormRow>
					</FormSection>
				)}
			</form>
		</Modal>
	);
}

/**
 * @typedef FormData
 * @property {string} field_id
 * @property {string} language_id
 * @property {string} [translated_id]
 * @property {string} translation
 */

/**
 * @typedef IncomingProps
 * @property {string} field_id
 * @property {string} langid
 * @property {(title: string) => void} setTitle
 * @property {(isProcessing: boolean) => void} setProcessing
 */

/** @typedef {import('../../../shared').AjaxAPI<AjaxResponse> & IncomingProps} Props */

/**
 * @typedef AjaxResponse
 * @property {object} field
 * @property {string} languageId
 * @property {object} [translatedField]
 */

/**
 * @typedef Field
 * @property {string} id
 * @property {string} type
 * @property {string} name
 * @property {unknown} [expression]
 * @property {unknown} [apiAlias]
 * @property {unknown} [fieldSectionId]
 * @property {unknown} [fieldRowId]
 * @property {unknown} [description]
 */
