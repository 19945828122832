import { TextButton } from '@fabric/button';

interface DraftProps {
	planId: number;
}

export function Draft(props: DraftProps): JSX.Element {
	const {
		planId,
	} = props;

	return (
		<TextButton
			color="secondary"
			inline={true}
			onClick={() => {
				location.href = `/settings/benefits/plan_wizard/plan/${ planId }`;
			}}
			type="button"
		>
			{$.__('Plan setup incomplete')}
		</TextButton>
	);
}
