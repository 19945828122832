import {
	map
} from 'lodash';

import Tooltip from 'Tooltip.mod';

export function convertToItems(collection) {
	return map(collection, (value, key) => {
		return {
			'text': value,
			'value': key
		};
	});
}

export function attachTooltips() {
	const payGroupsTable = document.querySelector('.js-pay-groups-table');

	const editSelector = '.js-edit-pay-group';
	const deleteSelector = '.js-archive-pay-group';

	const hasEditButton = document.querySelector(editSelector);
	const hasDeleteButton = document.querySelector(deleteSelector);

	const editGroupTooltip = hasEditButton ? Tooltip.create(editSelector, {
		template: {
			name: 'tooltip-standard',
			data: {
				content: $.__('Edit Pay Group'),
			}
		}
	}) : null;

	const deleteGroupTooltip = hasDeleteButton ? Tooltip.create(deleteSelector, {
		template: {
			name: 'tooltip-standard',
			data: {
				content: $.__('Delete Pay Group'),
			}
		}
	}) : null;

	/**
	 * 
	 * @param { string } selector 
	 * @param { elem } button 
	 * @param { object } tooltip 
	 */
	const needsTooltip = function(selector, button, tooltip) {
		if (
			button &&
			button.matches(selector) &&
			tooltip &&
			tooltip.anchor !== button
		) {
			tooltip.setAnchor(button);
		}
	}

	payGroupsTable.addEventListener('mouseenter', (event) => {
		const button = event.target.closest('.js-edit-pay-group, .js-archive-pay-group');

		needsTooltip('.js-edit-pay-group', button, editGroupTooltip);
		needsTooltip('.js-archive-pay-group', button, deleteGroupTooltip);

	}, true);
}
