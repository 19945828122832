import { getJsonData } from 'Data.util';
import { SelectItem } from './components/app.react/types';

type EmployeeWellbeingData = {
	nextSurveyScheduledDate: string | null;
	frequencyOptions: SelectItem[];
	schedulingOptions: SelectItem[];
	includeContractors: boolean;
}

export function getSettingsData(): Promise<EmployeeWellbeingData> {
	const keepNodeInDOM = true;
	return new Promise(resolve => resolve(getJsonData('#employeeWellbeingData', keepNodeInDOM) as EmployeeWellbeingData));
}
