import { Icon, StyledBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import './survey-status.styl';

export function SurveyStatus(props) {
	const { headerMessage, iconName, imagePath, messages } = props;
	return ifFeature(
		'encore',
		<StyledBox
			backgroundColor='neutral-forced-white'
			borderColor='neutral-extra-weak'
			borderStyle='solid'
			borderRadius='large'
			boxShadow='large'
			overflow='hidden'
			paddingY={4}
			paddingX={2}
			display='flex'
			flexDirection='column'
			alignItems='center'
			width='100%'
		>
			{iconName && iconName}
			{imagePath && <img alt='status' src={imagePath} />}
			<div className='SurveyStatus__header baseColor'>{headerMessage}</div>
			{messages.map((message) => (
				<div key={message} className='SurveyStatus__subtitle'>
					{message}
				</div>
			))}
		</StyledBox>,
		<div className={classNames('SurveyStatus branded-border-bottom', { 'SurveyStatus--has-image': imagePath })}>
			{iconName && (
				<span className='SurveyStatus__icon baseFillColor'>
					<Icon name={iconName} />
				</span>
			)}
			{imagePath && <img alt='status' src={imagePath} />}
			<div className='SurveyStatus__header baseColor'>{headerMessage}</div>
			{messages.map((message) => (
				<div key={message} className='SurveyStatus__subtitle'>
					{message}
				</div>
			))}
		</div>
	);
}
