import { ReactElement } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';

export interface TaxTypeObject {
    taxTypeId: number;
    taxType: string;
    taxTypeDesc: string;
    taxPayer: string;
    isCalculated: boolean;
    taxRate: number;
    minTaxRate: number;
    maxTaxRate: number;
    employerTaxIdValidationRegex: string | null;
    employerTaxIdValidationFormat: string | null;
    allowablePayFrequencies: {
        PayFrequencyID: string;
        PayFrequencyDescription: string;
    }[];
    isWorkersComp: boolean;
    hasActiveTax: boolean;
    state: string;
}

export interface AddTaxMessageProps {
    clientId?: string;
    clientName?: string;
    ein?: string;
    isTaxSettingsPage: boolean;
    taxTypes: TaxTypeObject[];
}

export interface AddTaxMessageMicroProps {
    addTaxMessagePropsArray: AddTaxMessageProps[];
}

export function AddTaxMessageMicro(props: AddTaxMessageMicroProps): ReactElement {

    return (
        <PoMicroFrontend
            customClassNamePrefix='add-tax-message'
            route='/settings/payroll/addTaxMessage'
            {...props}
        />
    )
}
