/**
 * Returns a file size in a human readable format
 * (i.e 2.5MB)
 *
 * credit: http://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable
 *
 * @param {Number} bytes
 *
 * @return {String}
 */
export function humanFileSize(bytes) {
	const thresh = 1024; // 1024

	if (Math.abs(bytes) < thresh) {
		return `${ bytes } B`;
	}

	const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let u = -1;

	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);

	return bytes.toFixed(1) + units[u];
}
