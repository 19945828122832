import {
	queryStringToObject,
	toQueryString,
} from '@utils/url/query';
import Ctrl, {
	on,
	onAction,
	onChange,
} from 'ctrl.deco';
import {
	createPrivateContext,
} from '@utils/private-context';

import {
	postConfig,
} from './api';

const _ = createPrivateContext();
const MODAL_SELECTOR = '.SettingsApps__appModal--benetrac';

@Ctrl('/settings/apps')
class SettingsAppsBenetracCtrl {
	get app() {
		return _(this).app;
	}

	get modal() {
		return _(this).modal;
	}

	get form() {
		return this.modal.querySelector('form.SettingsApps__appModalForm--benetrac');
	}

	get syncCheckboxes() {
		return Array.from(this.form.querySelectorAll('input.js-SettingsApps__appModalSyncCheckbox'));
	}

	get canSync() {
		return !this.syncCheckboxes
			.some(({ checked }) => checked);
	}

	get syncBtn() {
		return this.form.querySelector('button[data-action="SettingsApps__appModalBtn:toggleSync"]');
	}

	@on('SettingsAppsModal:open:config:benetrac')
	_onBenetracModalOpen(e, ctrl) {
		Object.assign(_(ctrl), e.detail);
	}

	@onChange(`${ MODAL_SELECTOR } .js-SettingsApps__appModalSyncCheckbox`)
	_onSyncCheckboxChange(e, { canSync, syncBtn }) {
		toggleDisabled(syncBtn, canSync);
	}

	@onAction(`SettingsApps__appModalBtn:toggleSync`)
	_onSyncBtnClick({ target: syncBtn }, { app, form }) {
		const data = toQueryString({
			...queryStringToObject($(form).serialize()),
			action: 'sync',
		});

		toggleProcessing(syncBtn, true);

		postConfig(app, data)
			.finally(() => {
				toggleProcessing(syncBtn, false);
			});
	}
}


function toggleProcessing(btn, force) {
	btn.classList
		.toggle('fab-Button--processing', force);

	toggleDisabled(btn, force);
}

function toggleDisabled(btn, force) {
	btn.toggleAttribute('disabled', force);
}
