import { Dependents } from '../../../types/dependent';
import { CostTypeEnumContract, DeductionFrequencyEnumContract, PlanTypeEnumContract } from '../../../types/plan-contracts';

enum EnrollmentStatus {
	enrolled = 'Enrolled',
	waived = 'Waived',
	withdrew = 'Withdrew',
	terminated = 'Terminated',
	notEligible = 'Ineligible',
	eligible = 'Eligible',
}

enum DeductionDateRuleDirection {
	before = 'before',
	after = 'after',
}

export enum PercentDeductionType {
	gross = 'Gross',
	net = 'Net',
	employee = 'Employee Contribution',
}
export interface Payments {
	perMonth: number | null;
	perPaycheck: number;
	symbol: CostTypeEnumContract;
	percentDeductionType: PercentDeductionType | null;
}

export interface DeductionDateRule {
	number: number;
	direction: DeductionDateRuleDirection;
}

export interface BulkEditStatusBenefitPlan {
	id: number;
	name: string;
	type: PlanTypeEnumContract;
	isVariable: boolean | null;
	startYmd: string;
	endYmd: string | null;
	deductionDateRule: DeductionDateRule;
	usesSafeHarbor: boolean;
}

export interface EnrollmentRecord {
	benefitCoverageId: number | null;
	status: EnrollmentStatus;
	effectiveDate: string;
	coverageName: string | null;
	deductionFrequency: DeductionFrequencyEnumContract | null;
	employeePays: Payments | null;
	companyPays: Payments | null;
	currencyCode: string | null;
	firstPayrollDeductionDate?: string | null;
	lastPayrollDeductionDate?: string | null;
	selectedDependentIds: number[];
}

export interface EnrolledEmployee {
	id: number;
	displayName: string;
	currentRecord: EnrollmentRecord;
	dependents: Dependents | [];
	futureRecords?: EnrollmentRecord[];
	inEligibilityWaitingPeriodUntil: string | null;
	isBenAdminEmployee: boolean;
	isTraxPayrollEmployee: boolean;
	lastName: string;
}

export interface EligibleWaivedEmployee {
	id: number;
	dependents: Dependents | [];
	displayName: string;
	effectiveDate: string;
	inEligibilityWaitingPeriodUntil: string | null;
	isBenAdminEmployee: boolean;
	isTraxPayrollEmployee: boolean;
	lastName: string;
	status?: EnrollmentStatus;
}

export interface BulkEditStatusGetContract {
	benefitPlan: BulkEditStatusBenefitPlan;
	enrolledEmployees: EnrolledEmployee[];
	waivedEmployees: EligibleWaivedEmployee[];
	eligibleEmployees: EligibleWaivedEmployee[];
}
