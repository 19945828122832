import { PlanState } from '../../types';
import { isIncompletePlan } from '../../utils';

export function otherSafeHarborPlansExist(
	safeHarborPlanCount: number,
	planState: PlanState
): boolean {
	// The safeHarborPlanCount includes the plan currently being edited
	if (isIncompletePlan(planState)) {
		return safeHarborPlanCount > 0;
	}

	return safeHarborPlanCount > 1;
}
