import { PlanDependencyEnum, PlanDependencyContract } from '../types';

export function getPlanDependencyPostData(
	medicalPlanDependency: PlanDependencyEnum,
	medicalPlanDependencyOptions: PlanDependencyContract[]
): number[] | null {
	// When 'Yes' is selected, one or more planDependents must be selected
	if (medicalPlanDependency === PlanDependencyEnum.planDependent && medicalPlanDependencyOptions) {
		return medicalPlanDependencyOptions.reduce((acc: number[], medicalPlan: PlanDependencyContract) => {
			if (medicalPlan.isDependency) {
				acc.push(medicalPlan.id);
			}
			return acc;
		}, []);
	}
	return [];
}
