import { Checkbox } from '@bamboohr/fabric';
import classNames from 'classnames';

import {
	CompanyLocationMappingRecordMetadataParam,
	LocationMetadataProps,
} from '../../utils/interfaces';

import './location-metadata.styl';

export function LocationMetadata(props: LocationMetadataProps): JSX.Element {
	const {
		data,
		databaseToAssign,
		databaseToAssignItems,
		disableSelect,
		onChange,
		recordId,
		selectedRecordId,
	} = props;

	const address = data ? `${ data.street1 }, ${ data.city }, ${ data.state } ${ data.zip }` : ' ';
	const isRemote = data?.isRemote;
	const displayAddress = isRemote ? $.__('Remote Location') : address;
	const showRemoteLocationCheckbox = databaseToAssign === 'trax' && selectedRecordId === 'addNew';
	const createValue = databaseToAssignItems.byId?.[recordId]?.createValue;
	const isRemoteLocationChecked = typeof createValue === 'object' ? !!createValue.remote_location : false;

	return (
		<div
			className={
				classNames({
					LocationMetadata: true,
					'LocationMetadata--blank': !displayAddress,
				})
			}
		>
			<p>{ displayAddress }</p>
			{ showRemoteLocationCheckbox && (
				<Checkbox
					checked={ isRemoteLocationChecked }
					disabled={ disableSelect }
					label={ $.__('This is a Remote Location') }
					onChange={ ({ checked }) => {
						const param: CompanyLocationMappingRecordMetadataParam = {
							checked,
							databaseToAssign,
							type: 'companyLocation',
							recordId,
						};
						onChange(param);
					} }
					value="remote-location-checkbox"
				/>
			) }
		</div>
	);
}
