import './styles.styl';

export default function ChecklistItem(props) {

	const {
		id,
		title,
		helpText,
		linkText,
		link,
		isChecked,
		onCheck,
		ariaLabel
	} = props;

	return (
		<div className="ChecklistItem">
			<div className="ChecklistItem__checkbox fab-Checkbox fab-Checkbox--large" onClick={ () => onCheck(id) }>
				<input id={`checkbox-${id}`} aria-label={ariaLabel} checked={ isChecked } className="fab-Checkbox__input" name={`checkbox-${id}`} type="checkbox" />
				<label
					id={`settings-time-tracking-checkbox-step-${ id }` }
					aria-label={ariaLabel}
					className="fab-Checkbox__label"
					data-bi-id={ `settings-time-tracking-checkbox-step-${ id }` }
					htmlFor={`checkbox-${id}`}
				/>
			</div>
			<div className="ChecklistItem__content">
				<h4 className="ChecklistItem__title">{ title }</h4>
				<p className="ChecklistItem__text">{ helpText }</p>
				<a className="ChecklistItem__link" data-bi-id={ `settings-time-tracking-setup-${ id }-checkbox-link` } href={ link }>{ linkText }</a>
			</div>
		</div>
	);
}
