import {
	useState,
} from 'react';

import Ajax from '@utils/ajax';

import {
	redirect,
} from 'BambooHR.util';

import {
	showSlidedown,
} from 'Alerts.mod';

import {
	Button,
	makeStyles,
} from '@bamboohr/fabric';

import {
	RedirectMessage,
} from './redirect-message';

const useStyles = makeStyles(({ palette }) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	sectionBody: {
		boxSizing: 'border-box',
		marginTop: '3px',
		paddingTop: '23px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: palette.grey[100],
		width: '436px',
		height: '128px',
	},
	checkBoxRow: {
		marginBottom: '17px',
	},
}));

const ERROR_MESSAGE = $.__(`Uh oh, something went wrong switching to BambooHR Payroll. Please try again or contact support.`);

export const BeginPayrollSection = () => {
	const [isChecked, setIsChecked] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const redirectMessageHtml = RedirectMessage();
	const classes = useStyles();

	function clickHandler(): void {
		if (isChecked) {
			setIsProcessing(true);
			Ajax.put('/settings/payroll/migration_tasks/complete').then((response) => {
				const {
					status,
				} = response;

				if (status === 200) {
					redirect('/home', redirectMessageHtml, 'success');
				} else {
					showSlidedown(ERROR_MESSAGE, 'error');
					setIsProcessing(false);
				}
			}).catch(() => {
				showSlidedown(ERROR_MESSAGE, 'error');
				setIsProcessing(false);
			});
		}
	}

	return (
		<div className={ classes.wrapper }>
			<h3>{ $.__('Click Here to Begin Payroll Greatness!') }</h3>

			<div className={ classes.sectionBody }>
				<div className={ `fab-Checkbox ${ classes.checkBoxRow }` }>
					<input
						checked={ isChecked }
						className="fab-Checkbox__input"
						id="click-here-to-begin-payroll-greatness"
						onChange={ event => setIsChecked(event.target.checked) }
						type="checkbox"
					/>

					<label className="fab-Checkbox__label" htmlFor="click-here-to-begin-payroll-greatness">
						{ $.__('I’ve watched the full video') }
					</label>
				</div>

				<Button clickAction={ clickHandler } isDisabled={ !isChecked } isProcessing={ isProcessing } type="button">
					{ $.__('Begin My New Payroll Experience!') }
				</Button>

			</div>
		</div>
	);
};
