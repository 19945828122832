import { Fragment } from 'react';
import { TextButton } from '@bamboohr/fabric';

import { redirect } from 'BambooHR.util';

import { useBenefitsSettingsStore } from '../../../data/benefits-settings-store';
import { PlanTypeEnumContract, coverageOptionsPlanTypes } from '../../../../types';

interface NotEnrolledProps {
	planId: number;
	planIsMissingCoverageOptions: boolean;
	planType: PlanTypeEnumContract;
	setIsEligibilityModalOpen: (open: boolean) => void;
	setIsMissingCoveragesModalOpen: (open: boolean) => void;
	eligibleWhenMarkedManually: number;
}

export function NotEnrolled(props: NotEnrolledProps): JSX.Element {
	const {
		eligibleWhenMarkedManually,
		planId,
		planIsMissingCoverageOptions,
		planType,
		setIsEligibilityModalOpen,
		setIsMissingCoveragesModalOpen,
	} = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	return (
		<Fragment>
			{eligibleWhenMarkedManually > 0 && (
				<div>
					<TextButton
						color="secondary"
						inline={true}
						onClick={() => {
							setIsEligibilityModalOpen(true);
						}}
						type="button"
					>
						{$.__('%s will be eligible when marked manually.', eligibleWhenMarkedManually)}
					</TextButton>
				</div>
			)}
			<div className="Columns__statusNotEnrolled">{$.__('No one is marked enrolled in this plan.')}</div>
			<div>
				<TextButton
					children={$.__('Enroll someone.')}
					onClick={() => {
						if (
							isBenefitsAdminEnabled  &&
							coverageOptionsPlanTypes.includes(planType) &&
							planIsMissingCoverageOptions
						) {
							setIsMissingCoveragesModalOpen(true);
							return;
						}
						redirect(`/settings/benefits/plan/${planId}/bulk_enroll`);
					}}
					inline={true}
					type="button"
				/>
			</div>
		</Fragment>
	);
}
