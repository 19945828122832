import { areLifeCoverageAmountsValid } from './are-life-coverage-amounts-valid';
import {
	validateDisabilityCompanyPays,
	validateDisabilityDisbursementAmount,
	validateDisabilityRate,
	validateDisabilityType,
} from './disability-validation';
import {
	generateAgeBandRateErrorKey,
	generateCompanyPaysErrorKey,
	generateEmployeeCostErrorKey,
	generateRateErrorKey,
	generateTotalCostErrorKey,
} from './error-keys';
import {
	validateCompanyPays,
	validateCoverageType,
	validateEligibilityFilters,
	validateEmployeeCost,
	validateFixedAmount,
	validateLegalText,
	validateRadioGroup,
	validateReimbursementAmount,
} from './special-errors';

import { isPlanEndsIncluded } from '../../plan-details/utils';
import { isPlanDependenciesDisabled } from '../../steps/is-plan-dependencies-disabled';
import { isReimbursementAmountIncluded } from '../../steps/utils';
import {
	CoverageCostValues,
	CoverageLevelValue,
	DisabilityCategoryDataOptionsContract,
	DisabilityDisbursementTypeEnum,
	EligibilityGroupCalculatorSettings,
	EmployeeCostTypeValue,
	PlanCalculatorSettings,
	PlanCategoryDataOptionsContract,
	PlanDependencyEnum,
	PlanRateTypeEnum,
	PlanWizardData,
	StepName,
	ValidationContextState,
	WizardValues,
} from '../../types';
import { WizardState } from '../../types/wizard-state';
import {
	IsLegalTextIncludedEnum,
	LifeInsuranceTypeEnum,
	PlanWizardDataPlan,
	RateEnum,
} from '../../types/wizard-values';
import { validateCoveragesRequired } from '../../utils/validation/validate-coverages-required';
import { FEATURE_TOGGLE_KEYS } from '../../../etc/feature-toggle';
import { benefitTypeAllowsAgeBands } from '../../../plans.react/etc/benefit-type-allows-age-bands';
import { SetSpecialErrors } from '../../../types';
import { EnabledFeatures } from '../../../types/enabled-features';
import { PlanFieldNames } from '../../../types/plan-field-names';
import {
	validateEndDate,
	validateMedicalPlanDependency,
	validateReactivateEndDate,
	validateStartDate,
} from '../../../utils';
import { PLAN_TYPES } from '../../../../../employees/shared/constants';

export const validateEmployeeCosts = (
	groupId: string | number,
	coverageCosts: CoverageCostValues[],
	coverageLevels: CoverageLevelValue[],
	employeeCostType: EmployeeCostTypeValue,
	rateType: PlanRateTypeEnum,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	let areEmployeeCostsValid = true;

	// For Variable plans, Costs are not required. For Age Banded plans, validateAgeBandedCosts is used instead
	if (rateType !== PlanRateTypeEnum.standard) {
		return areEmployeeCostsValid;
	}

	coverageCosts.forEach((coverageCost) => {
		const { coverageLevelLinkId, employeeCost } = coverageCost;
		const errorKey = generateEmployeeCostErrorKey(groupId, coverageLevelLinkId);
		const coverageLevel = coverageLevels.find((level) => level.linkId === coverageLevelLinkId);
		const totalCost = coverageLevel ? coverageLevel.totalCost : null;
		const isEmployeeCostValid = validateEmployeeCost(errorKey, { employeeCostType, totalCost, employeeCost }, setSpecialErrors);

		if (!isEmployeeCostValid && coverageLevelLinkId) {
			areEmployeeCostsValid = false;
		}
	});

	return areEmployeeCostsValid;
};

export const validateTotalCosts = (
	groupId: string | number,
	coverageLevels: CoverageLevelValue[],
	rateType: PlanRateTypeEnum,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	let areAllTotalCostsValid = true;

	if (rateType !== PlanRateTypeEnum.standard) {
		return areAllTotalCostsValid;
	}

	coverageLevels.forEach((coverageLevel) => {
		if (coverageLevel.isSelected) {
			const errorKey = generateTotalCostErrorKey(groupId, coverageLevel.linkId);
			const totalCost = coverageLevel ? coverageLevel.totalCost : null;
			const isTotalCostValid = validateFixedAmount(errorKey, totalCost, setSpecialErrors);

			if (!isTotalCostValid) {
				areAllTotalCostsValid = false;
			}
		}
	});

	return areAllTotalCostsValid;
};

const validateDisabilityCalculatorSettings = (
	calculatorSettings: EligibilityGroupCalculatorSettings,
	groupId: string | number,
	plan: PlanWizardDataPlan,
	planCategoryDataOptions: PlanCategoryDataOptionsContract,
	rateType: PlanRateTypeEnum,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	let areCalculatorSettingsValid = true;
	const disabilityCategoryDataOptions = planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
	// Additional check to determine if rate and companyPays need to be validated
	const shouldValidateDisabilityCalculator =
		disabilityCategoryDataOptions?.disabilityDisbursementType === DisabilityDisbursementTypeEnum.percentage &&
		rateType === PlanRateTypeEnum.standard;

	if (plan.type === PLAN_TYPES.DISABILITY && shouldValidateDisabilityCalculator) {
		const companyPays = calculatorSettings?.companyPays ?? null;
		const rate = calculatorSettings?.rate || null;

		const rateErrorKey = generateRateErrorKey(groupId);
		const isRateValid = validateDisabilityRate(disabilityCategoryDataOptions?.disabilityType, rateErrorKey, rate, setSpecialErrors);

		const companyPaysErrorKey = generateCompanyPaysErrorKey(groupId);
		const isCompanyPaysValid = validateDisabilityCompanyPays(companyPaysErrorKey, companyPays, setSpecialErrors);

		areCalculatorSettingsValid = isRateValid && isCompanyPaysValid;
	}

	return areCalculatorSettingsValid;
};

const validateAgeBands = (
	calculatorSettings: PlanCalculatorSettings,
	plan: PlanWizardDataPlan,
	rateType: PlanRateTypeEnum,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	let areAllAgeBandsValid = true;

	const shouldValidateAgeBands = benefitTypeAllowsAgeBands(plan.type) && rateType === PlanRateTypeEnum.ageBanded;

	if (shouldValidateAgeBands) {
		const { ageBands } = calculatorSettings;
		ageBands.forEach((ageBand, index) => {
			const rate = ageBand?.rate ?? null;
			const rateErrorKey = generateAgeBandRateErrorKey(index);
			const isRateValid = validateFixedAmount(rateErrorKey, rate, setSpecialErrors);

			if (!isRateValid) {
				areAllAgeBandsValid = false;
			}
		});
	}

	return areAllAgeBandsValid;
};

const validateAgeBandedCosts = (
	calculatorSettings: EligibilityGroupCalculatorSettings,
	groupId: string | number,
	plan: PlanWizardDataPlan,
	rateType: PlanRateTypeEnum,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	let areAgeBandedCostsValid = true;
	const shouldValidateAgeBandedCosts = benefitTypeAllowsAgeBands(plan.type) && rateType === PlanRateTypeEnum.ageBanded;
	const companyPaysErrorKey = generateCompanyPaysErrorKey(groupId);
	const isCompanyPaysValid = validateCompanyPays(
		companyPaysErrorKey,
		calculatorSettings.companyPays,
		calculatorSettings.costType,
		setSpecialErrors
	);

	if (shouldValidateAgeBandedCosts) {
		if (!isCompanyPaysValid) {
			areAgeBandedCostsValid = false;
		}
	}

	return areAgeBandedCostsValid;
};

export const validateEligibilityGroups = (
	wizardValues: WizardValues,
	plan: PlanWizardDataPlan,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	let areAllGroupsValid = true;
	const { coverageLevels, eligibilityGroups, employeeCostType, planCategoryDataOptions, rateType } = wizardValues;

	eligibilityGroups.forEach((eligibilityGroup, index) => {
		const { calculatorSettings, coverageCosts, id } = eligibilityGroup;
		const areFiltersValid = validateEligibilityFilters(eligibilityGroups, index, setSpecialErrors);
		const areTotalCostsValid = validateTotalCosts(id, coverageLevels, rateType, setSpecialErrors);
		const areEmployeeCostsValid = validateEmployeeCosts(
			id,
			coverageCosts,
			coverageLevels,
			employeeCostType,
			rateType,
			setSpecialErrors
		);
		const areDisabilityCalculatorSettingsValid = validateDisabilityCalculatorSettings(
			calculatorSettings,
			id,
			plan,
			planCategoryDataOptions,
			rateType,
			setSpecialErrors
		);
		const areAgeBandedCostsValid = validateAgeBandedCosts(calculatorSettings, id, plan, rateType, setSpecialErrors);

		const isEligibilityGroupValid =
			areFiltersValid && areTotalCostsValid && areEmployeeCostsValid && areDisabilityCalculatorSettingsValid && areAgeBandedCostsValid;

		if (!isEligibilityGroupValid) {
			areAllGroupsValid = false;
		}
	});

	return areAllGroupsValid;
};

export const validateSpecial = (
	currentStepId: StepName,
	wizardValues: WizardValues,
	wizardData: PlanWizardData,
	wizardState: WizardState,
	setSpecialErrors: SetSpecialErrors,
	resetSpecialErrors: ValidationContextState['resetSpecialErrors'],
	enabledFeatures: EnabledFeatures
): boolean => {
	const { plan } = wizardData;
	const isBenefitsAdminEnabled = enabledFeatures[FEATURE_TOGGLE_KEYS.BenefitsEmployeeEnrollment];
	const isLifePlan = wizardData.plan.type === PLAN_TYPES.LIFE;

	resetSpecialErrors();

	const lifePlanValidation = (() => {
		const { coverageOptions } = wizardValues;
		if (isLifePlan) {
			return {
				areLifeCoverageAmountsValid: () => areLifeCoverageAmountsValid(coverageOptions, setSpecialErrors),
				isLifeCoverageTypeValid: () => validateCoverageType(coverageOptions.type, setSpecialErrors),
			};
		}

		return {
			areLifeCoverageAmountsValid: () => true,
			isFixedAmountSectionValid: true,
			isSalaryBasedSectionValid: true,
			isLifeCoverageTypeValid: () => true,
		};
	})();

	let areFieldsOnCurrentStepValid = true;

	// Plan Details Step
	if (currentStepId === StepName.PlanDetails) {
		const isStartDateValid = validateStartDate(wizardValues.startYmd, wizardValues.endYmd, setSpecialErrors);

		const isEndDateValid = isPlanEndsIncluded(plan.type, plan.state, wizardValues.endYmd)
			? validateEndDate(plan.type, wizardValues.endYmd, wizardValues.startYmd, setSpecialErrors)
			: true;

		const isReactivateEndDateValid =
			isPlanEndsIncluded(plan.type, plan.state, wizardValues.endYmd) && wizardState === WizardState.Reactivate
				? validateReactivateEndDate(wizardValues.endYmd, setSpecialErrors)
				: true;

		const isReimbursementAmountValid = isReimbursementAmountIncluded(plan.type)
			? validateReimbursementAmount(wizardValues.reimbursement.amount, setSpecialErrors)
			: true;

		const disabilityPlanCategoryData = wizardValues.planCategoryDataOptions as DisabilityCategoryDataOptionsContract;

		const isDisabilityTypeValid: boolean =
			isBenefitsAdminEnabled && plan.type === PLAN_TYPES.DISABILITY && disabilityPlanCategoryData?.disabilityType
				? validateDisabilityType(disabilityPlanCategoryData?.disabilityType, setSpecialErrors)
				: true;

		const isAutoEnrollValid: boolean =
			isBenefitsAdminEnabled &&
			(plan.type === PLAN_TYPES.DISABILITY || (isLifePlan && wizardValues.lifeInsuranceType !== LifeInsuranceTypeEnum.voluntary))
				? validateRadioGroup(PlanFieldNames.autoEnroll, wizardValues.autoEnroll, setSpecialErrors)
				: true;

		const arePlanDependenciesValidationRelevant: boolean =
			isBenefitsAdminEnabled && (plan.type === PLAN_TYPES.HSA || plan.type === PLAN_TYPES.FLEX);
		const arePlanDependenciesDisabled =
			plan.type === PLAN_TYPES.FLEX &&
			isPlanDependenciesDisabled(wizardValues.deductionType, wizardValues.medicalPlanDependency === PlanDependencyEnum.planDependent);
		const areMedicalPlanDependenciesValid: boolean =
			arePlanDependenciesValidationRelevant && !arePlanDependenciesDisabled
				? validateMedicalPlanDependency(
						wizardValues.medicalPlanDependency,
						wizardValues.medicalPlanDependencyOptions,
						setSpecialErrors
				  )
				: true;

		areFieldsOnCurrentStepValid =
			isStartDateValid &&
			isEndDateValid &&
			isReimbursementAmountValid &&
			isReactivateEndDateValid &&
			isDisabilityTypeValid &&
			areMedicalPlanDependenciesValid &&
			isAutoEnrollValid;
	}

	// Coverage Options Step
	if (currentStepId === StepName.CoverageOptions) {
		let areRequiredCoverageLevelsValid = true;
		let isDisabilityCoverageStepValid = true;

		// Validate Coverage levels if rate is used
		if (wizardValues.rate === RateEnum.Standard || (isBenefitsAdminEnabled && wizardValues.rate === RateEnum.Variable)) {
			areRequiredCoverageLevelsValid = validateCoveragesRequired(wizardValues.coverageLevels, setSpecialErrors);
		}

		// Validate disability disbursement amount (disability plan only)
		if (wizardData.plan.type === PLAN_TYPES.DISABILITY) {
			const disabilityPlanCategoryData = wizardValues.planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
			isDisabilityCoverageStepValid = validateDisabilityDisbursementAmount(disabilityPlanCategoryData, setSpecialErrors);
		}

		areFieldsOnCurrentStepValid =
			areRequiredCoverageLevelsValid &&
			isDisabilityCoverageStepValid &&
			lifePlanValidation.areLifeCoverageAmountsValid() &&
			lifePlanValidation.isLifeCoverageTypeValid();
	}

	// Enrollment Details Step
	if (currentStepId === StepName.EnrollmentDetails) {
		const textAreaIsValid = wizardValues.isLegalTextIncluded === IsLegalTextIncludedEnum.true ? Boolean(wizardValues.legalText) : true;
		const characterLimitIsValid = wizardValues.legalText ? wizardValues.legalText?.length <= 50000 : true;

		areFieldsOnCurrentStepValid = validateLegalText(
			PlanFieldNames.legalText,
			textAreaIsValid,
			setSpecialErrors,
			characterLimitIsValid
		);
	}

	// Eligibility and Cost Step
	if (currentStepId === StepName.EligibilityAndCost) {
		if (isBenefitsAdminEnabled) {
			const areEligibilityGroupsValid = validateEligibilityGroups(wizardValues, wizardData.plan, setSpecialErrors);
			const areAgeBandsValid = validateAgeBands(
				wizardValues.planCategoryDataOptions?.calculatorSettings,
				plan,
				wizardValues.rateType,
				setSpecialErrors
			);
			areFieldsOnCurrentStepValid = areEligibilityGroupsValid && areAgeBandsValid;
		}
	}

	return areFieldsOnCurrentStepValid;
};

export const validateFields = (
	validationContext: ValidationContextState,
	currentStepId: StepName,
	wizardValues: WizardValues,
	wizardData: PlanWizardData,
	wizardState: WizardState,
	enabledFeatures: EnabledFeatures
): boolean => {
	const { validate, resetSpecialErrors, setSpecialErrors } = validationContext;
	const isValid = validate();
	const isSpecialValid = validateSpecial(
		currentStepId,
		wizardValues,
		wizardData,
		wizardState,
		setSpecialErrors,
		resetSpecialErrors,
		enabledFeatures
	);

	return isValid && isSpecialValid;
};
