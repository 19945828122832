import {
	isFunction,
} from 'lodash';
import {
	useEffect,
	useState,
} from 'react';

import {
	Message,
} from '@bamboohr/utils/lib/message';
import {
	doDisableMFA,
} from 'multi-factor-authentication.mod';
import {
	closeModal,
} from 'show-modal.mod';

import {
	bem,
} from '../../../utils';

export function getMfaSheetProps(props) {
	const {
		app,
		callback,
		hasMfaEnabled,
	} = props;
	const [isOpen, setIsOpen] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);

	useEffect(() => {
		/**
		 * Add a timeout for updating the sheet's isOpen prop due to some
		 * weird issues with show-modal.mod.
		 */
		const timeout = setTimeout(() => {
			setIsOpen(hasMfaEnabled);
		}, 150);

		return () => {
			clearTimeout(timeout);
		};
	}, [hasMfaEnabled]);

	return {
		alternativeAction: () => closeModal(),
		content: (
			<p className={ bem('appModalCopy', ['centered']) }>
				<Message
					text={ $.__("By adding a {1} login, your employees will use the {1} authentication rather than BambooHR's 2-Step Login.") }
					params={ [app.title] }
				/>
			</p>
		),
		headline: $.__('2-Step Login will be disabled.'),
		icon: 'fab-triangle-exclamation-54x54',
		iconColor: 'attention',
		isOpen,
		isProcessing,
		onClose: () => {
			if (hasMfaEnabled) {
				closeModal();
			} else {
				setIsOpen(false);
			}
		},
		primaryAction: () => {
			setIsProcessing(true);

			doDisableMFA()
				.then(() => {
					setIsProcessing(false);
					if (isFunction(callback)) {
						callback();
					}
				})
				.catch(() => {
					setIsProcessing(false);
					setMessage($.__('Uh-oh. Something went wrong, please try again!', 'error'));
				});
		},
		primaryActionText: $.__('Ok, Continue'),
		title: $.__('Just so you know...'),
	};
}
