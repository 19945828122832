import { generateChildItems, hasSelectionsInFilterType, isFilterTypeInActiveMenu } from './general-helpers';
import { FILTER_TYPE_LABELS, SECONDARY_FILTER_TYPES } from '../constants';
import { ParentItem } from '../types';
import { DemographicsContract, FilterSelections, FilterType } from '../../../types';

export const generateParentFilterItems = (
	demographics: DemographicsContract,
	filterSelections: FilterSelections,
	menuFilterTypeSelections: FilterType[]
): ParentItem[] => {
	const filterItems: ParentItem[] = [];

	SECONDARY_FILTER_TYPES.forEach((filterType) => {
		if (!hasSelectionsInFilterType(filterType, filterSelections) ||
			isFilterTypeInActiveMenu(filterType, menuFilterTypeSelections)
		) {
			filterItems.push({
				key: filterType,
				text: FILTER_TYPE_LABELS[filterType].singularText,
				value: filterType,
				type: 'single',
				items: generateChildItems(demographics, filterType, []),
			});
		}
	});

	return filterItems;
}
