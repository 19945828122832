import { VerticalWizardStep } from '@bamboohr/fabric';
import { difference, union } from 'lodash';
import { isEnabled } from 'FeatureToggle.util';
import { SelectItemValue } from 'form-fields.react';

import { hasDatePassed } from './';
import { makeLifePlanTypeLikeValue } from './life-plan-type-like-factory';
import { planTypeAllowsDefinedCosts } from './plan-type-allows-defined-costs';
import { PLAN_STEP_TITLES, TRANSLATED_PLAN_TYPES } from '../constants';
import { getEmployeeIdsThatMatchFilters } from '../eligibility-cost';
import {
	CompletedStep,
	CompletedSteps,
	DeductionTypeEnumContract,
	DeductionTypeValue,
	EligibilityGroupValues,
	EmployeeList,
	LifeInsuranceTypeEnum,
	PlanRateTypeEnum,
	PlanState,
	StepName,
	WizardState,
	WizardStep,
	WizardValues,
} from '../types';
import { PlanTypeEnumContract } from '../../types/plan-contracts';
import { FEATURE_TOGGLE_KEYS } from '../../etc/feature-toggle';

export { getPlanIcon } from '../../utils';

export interface EligibilityParameters {
	areFeatureTogglesLoading?: boolean;
	isBenefitsAdminEnabled: boolean;
	planType: PlanTypeEnumContract;
	rateType: PlanRateTypeEnum;
}

export const isCompletePlan = (planState: PlanState): boolean => {
	return planState === PlanState.complete;
};

export const isIncompletePlan = (planState: PlanState): boolean => {
	return !isCompletePlan(planState);
};

const includePayrollDeductionStep = (planType: PlanTypeEnumContract): boolean => {
	return planType !== PlanTypeEnumContract.reimbursement;
};

const getEligibilityPlanName = (eligibilityParameters: EligibilityParameters): string => {
	const { isBenefitsAdminEnabled, planType } = eligibilityParameters;

	return planTypeAllowsDefinedCosts(planType, isBenefitsAdminEnabled) ? $.__('Eligibility and Cost') : $.__('Eligibility');
};

export const getWizardStepTitle = (stepName: StepName, eligibilityParameters?: EligibilityParameters) => {
	if (eligibilityParameters && stepName === StepName.EligibilityAndCost) {
		return getEligibilityPlanName(eligibilityParameters);
	}

	return PLAN_STEP_TITLES[stepName];
};

export const includeCoverageOptionsStep = (
	isBenefitsAdminEnabled: boolean,
	planType: PlanTypeEnumContract,
	wizardValues: WizardValues
): boolean => {
	const { rateType } = wizardValues;
	if (isBenefitsAdminEnabled) {
		if (planType === PlanTypeEnumContract.life) {
			// a life insurance type is used along with toggle to determine if step is shown
			return makeLifePlanTypeLikeValue(
				wizardValues.lifeInsuranceType,
				{
					[LifeInsuranceTypeEnum.basic]: () => true,
					[LifeInsuranceTypeEnum.voluntary]: () => isEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_VOLUNTARY_LIFE_COVERAGE_SELECTOR),
				},
				() => false
			);
		}

		return planTypeAllowsDefinedCosts(planType, isBenefitsAdminEnabled);
	}

	// Variable plans only have coverage options step when benefitsAdmin is enabled
	return rateType !== PlanRateTypeEnum.variable && planTypeAllowsDefinedCosts(planType, isBenefitsAdminEnabled);
};

const buildStep = (id: StepName, step: CompletedStep, eligibilityInfo?: EligibilityParameters): WizardStep => {
	return {
		id,
		title: getWizardStepTitle(id, eligibilityInfo),
		disabled: step.disabled,
		hideIcon: !step.complete,
		status: step.status as VerticalWizardStep['status'],
	};
};

export const getWizardSteps = (
	planType: PlanTypeEnumContract,
	wizardValues: WizardValues,
	isBenefitsAdminEnabled: boolean,
	stepsCompleted: CompletedSteps
): WizardStep[] => {
	const { rateType } = wizardValues;
	const eligibilityInfo = { planType, rateType, isBenefitsAdminEnabled } as EligibilityParameters;
	const wizardSteps = [buildStep(StepName.PlanDetails, stepsCompleted[StepName.PlanDetails])];

	if (includeCoverageOptionsStep(isBenefitsAdminEnabled, planType, wizardValues)) {
		wizardSteps.push(buildStep(StepName.CoverageOptions, stepsCompleted[StepName.CoverageOptions]));
	}

	wizardSteps.push(buildStep(StepName.EligibilityAndCost, stepsCompleted[StepName.EligibilityAndCost], eligibilityInfo));

	if (isBenefitsAdminEnabled) {
		wizardSteps.push(buildStep(StepName.EnrollmentDetails, stepsCompleted[StepName.EnrollmentDetails]));
	}

	if (includePayrollDeductionStep(planType)) {
		wizardSteps.push(buildStep(StepName.PayrollDeduction, stepsCompleted[StepName.PayrollDeduction]));
	}

	return wizardSteps;
};

export const getTranslatedPlanType = (planType: PlanTypeEnumContract): string => {
	return TRANSLATED_PLAN_TYPES[planType] ? TRANSLATED_PLAN_TYPES[planType] : '';
};

export const getTitleText = (planState: PlanState, planType: PlanTypeEnumContract, wizardState: WizardState): string => {
	let title = '';
	if (planState === PlanState.new) {
		if (planType === PlanTypeEnumContract.other) {
			title = $.__('Add an %1 Plan', getTranslatedPlanType(planType));
		} else {
			title = $.__('Add a %1 Plan', getTranslatedPlanType(planType));
		}
	} else if (planState === PlanState.duplicate) {
		title = $.__('Duplicate %1 Plan', getTranslatedPlanType(planType));
	} else if (wizardState === WizardState.Reactivate) {
		title = $.__('Reactivate %1 Plan', getTranslatedPlanType(planType));
	} else {
		title = $.__('Edit %1 Plan', getTranslatedPlanType(planType));
	}

	return title;
};

export enum NavKeys {
	nextStep,
	finalStep,
}

export const planWizardButtonDataBiIds = {
	[PlanState.complete]: {
		[NavKeys.nextStep]: 'update-plan-next-step',
		[NavKeys.finalStep]: 'update-plan-save',
	},
	[PlanState.draft]: {
		[NavKeys.nextStep]: 'draft-plan-next-step',
		[NavKeys.finalStep]: 'draft-plan-save',
	},
	[PlanState.duplicate]: {
		[NavKeys.nextStep]: 'wizard-duplicate-plan-next-step',
		[NavKeys.finalStep]: 'wizard-duplicate-plan-save',
	},
	[PlanState.recovery]: {
		[NavKeys.nextStep]: 'reactivate-plan-next-step',
		[NavKeys.finalStep]: 'reactivate-plan-save',
	},
	[PlanState.revision]: {
		[NavKeys.nextStep]: 'revision-plan-next-step',
		[NavKeys.finalStep]: 'revision-plan-save',
	},
	[PlanState.new]: {
		[NavKeys.nextStep]: 'add-plan-next-step',
		[NavKeys.finalStep]: 'add-plan-save',
	},
};

export const getPlanWizardButtonDataBiId = (planState: PlanState, isLastStep: boolean): string => {
	const step = isLastStep ? NavKeys.finalStep : NavKeys.nextStep;
	return planWizardButtonDataBiIds[planState][step];
};

export const closeErrorMessage = (): void => {
	window.closeMessage();
};

export const needsPastEndDateWarning = (date: string, hasCurrentEnrollments: boolean, hasAcceptedWarning?: boolean): boolean => {
	if (hasAcceptedWarning) {
		return false;
	}

	return hasCurrentEnrollments && hasDatePassed(date);
};

const getAllEligibleEmployeeIds = (employees: EmployeeList, eligibilityGroups: EligibilityGroupValues[]): number[] => {
	let allEligibleEmployeeIds = [];

	eligibilityGroups.forEach((group: EligibilityGroupValues) => {
		const groupEmployeeIds = group.eligibilityFilters.areSpecificPeopleSelected
			? group.eligibilityFilters.selectedEmployeeIds
			: getEmployeeIdsThatMatchFilters(employees, group.eligibilityFilters.filterGroup);

		allEligibleEmployeeIds = union(groupEmployeeIds, allEligibleEmployeeIds);
	});

	return allEligibleEmployeeIds;
};

export const getFutureEmployeeIdsLosingEligibility = (
	futureEmployeeIds: number[],
	employees: EmployeeList,
	eligibilityGroups: EligibilityGroupValues[]
): number[] => {
	if (futureEmployeeIds.length === 0) {
		return [];
	}

	const allEligibleEmployeeIds: number[] = getAllEligibleEmployeeIds(employees, eligibilityGroups);

	return difference(futureEmployeeIds, allEligibleEmployeeIds);
};

export function generateSelectedValue(newValues: SelectItemValue[]): SelectItemValue {
	return newValues.length ? newValues[0] : '';
}

export const isSafeHarborIncluded = (deductionType: DeductionTypeValue, hasTrax: boolean): boolean => {
	const safeHarborDeductionTypes = [
		DeductionTypeEnumContract.roth401k,
		DeductionTypeEnumContract['401k'],
		DeductionTypeEnumContract.afterTax401k,
	];
	return hasTrax && safeHarborDeductionTypes.some((item) => item === deductionType);
};
