import Ajax from '@utils/ajax';

import { unpackFiltersToQueryParams } from '../utils/unpack-filters-to-query-params';
import { FiltersPayload } from '../types';

export const updateEmployeeMatches = (selectedFilters: FiltersPayload) => {
	const filterParameters = unpackFiltersToQueryParams(selectedFilters);

	return new Promise((resolve, reject) => {
		Ajax.get(`/ajax/filters/filter_count.php?status[]=Active${filterParameters}`)
			.then((response) => {
				if (response) {
					resolve(response.data);
				}

				reject(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
