import { isBenefitPlanActive } from './is-benefit-plan-active';
import { isPlanExpired } from './is-plan-expired';
import { BenefitPlanListContract } from '../types/contracts';
import { PlanFilter } from '../types/plan-filter';

export function getFilteredPlans(unfiltered: BenefitPlanListContract[], planFilter: PlanFilter): BenefitPlanListContract[] {
	if (unfiltered) {
		return unfiltered.filter((plan: BenefitPlanListContract) => {
			if (planFilter === PlanFilter.Active) {
				return isBenefitPlanActive(plan);
			} else if (planFilter === PlanFilter.Inactive) {
				return isPlanExpired(plan);
			} else if (planFilter === PlanFilter.All) {
				return plan;
			}
		});
	}
	return [];
}
