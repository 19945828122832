import { ReactElement } from 'react';
import { DatePicker } from '@bamboohr/fabric';

import { getMinDate, getToday } from './utils';

export function StartDatePicker(args: {label?: string; activeStartDateIsPast: boolean; selectedStartDate: string; setSelectedStartDate: (date: string) => void}): ReactElement {
	const {
		label = null,
		activeStartDateIsPast,
		selectedStartDate,
		setSelectedStartDate
	} = args;
	return (
		<DatePicker
			key='mfaStartDate'
			label={label}
			minDate={ getToday() }
			note={$.__('Prior to Start Date, users can opt to set up 2-Step Login. After this date, 2-Step Login setup will be required.')}
			onChange={({value}) => {setSelectedStartDate(value)}}
			value={activeStartDateIsPast ? getMinDate(selectedStartDate) : selectedStartDate}
		/>
	)
}
