import {
	getDefaultEligibilityFilters,
} from './general';
import {
	EligibilityFilterValues,
	EligibilityGroupGetContract,
} from '../../../types';
import {
	getInitialEligibilityFiltersFromSavedGroup,
} from '../../../utils';

export const getFiltersFromInitialGroups = (
	initialGroups: EligibilityGroupGetContract[],
	id: string | number
): EligibilityFilterValues => {
	const index = initialGroups.findIndex(group => group.id === id);
	if (index > -1) {
		return getInitialEligibilityFiltersFromSavedGroup(initialGroups[index]);
	}

	return getDefaultEligibilityFilters();
};

