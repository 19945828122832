import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => {
	return {
		labelContainer: {
			alignItems: 'center',
			color: palette.gray[800],
			display: 'flex',
			fontSize: typography.small.fontSize,
		},
		questionContainer: {
			marginLeft: '8px',
		},
	};
});
