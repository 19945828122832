import { Button, TextButton } from '@fabric/button';

import { FileTile } from '../file-tile';
import { COMPANY_APP_STATE, companyFiles, getFileDownload, getFilesDownloadList, takeToBankAccountSection } from '../../utils/shared';
import { BANK_APP_STATE, BANK_FILES_ARRAY, BANK_FILES } from '../../../bank-info.react/utils/shared';

/* @startCleanup encore */
import './info-card.styl';
import { BankCoin36x36, DownloadSign37x36, UploadSign37x36 } from '@bamboohr/grim';
import { getFabricBrandColor } from 'BambooHR.util';
/* @endCleanup encore */
import { BodyText, Flex, InPageMessaging, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function InfoCard(props) {
	const renderMultiRequiredDownloadAndUploadState = () => {
		const { company } = props;

		return ifFeature(
			'encore',
			<InPageMessaging
				type='info'
				icon='file-signature-solid'
				header={$.__('You need to sign the required payroll forms for %1$s below', company.companyLegalName)}
				body={$.__('These forms are required so we can get your company set up to run payroll with BambooHR Payroll.')}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<DownloadSign37x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>
						{$.__('You need to sign the required payroll forms for %1$s below', company.companyLegalName)}
					</div>
					<div className='InfoCardBody__text InfoCardBody__text--nopadding'>
						{$.__('These forms are required so we can get your company set up to run payroll with BambooHR Payroll.')}
					</div>
				</div>
			</div>
		);
	};

	const renderMultiBankDownloadState = () => {
		const { company } = props;

		const nickName = company.length ? company[0].companyLegalName : company.accountNickname;

		return ifFeature(
			'encore',
			<InPageMessaging
				type='info'
				icon='file-signature-solid'
				header={$.__('Sign the required authorization forms for %1$s below', nickName)}
				body={$.__('We need your permission to verify and make withdrawals and deposits to your bank account.')}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<DownloadSign37x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Sign the required authorization forms for %1$s below', nickName)}</div>
					<div className='InfoCardBody__text InfoCardBody__text--nopadding'>
						{$.__('We need your permission to verify and make withdrawals and deposits to your bank account.')}
					</div>
				</div>
			</div>
		);
	};

	const renderMultiRequiredBankState = () => {
		const { company } = props;

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='building-columns-solid'
				type='info'
				header={$.__('Add bank account information for %1$s below', company.companyLegalName)}
				body={$.__('Before we can verify your company information, you will need to add the payroll bank account information.')}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<BankCoin36x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Add bank account information for %1$s below', company.companyLegalName)}</div>
					<div className='InfoCardBody__text InfoCardBody__text--nopadding'>
						{$.__('Before we can verify your company information, you will need to add the payroll bank account information.')}
					</div>
				</div>
			</div>
		);
	};

	const renderMultiLinkPlaidState = () => {
		const { company } = props;

		return ifFeature(
			'encore',
			<InPageMessaging
				type='info'
				icon='building-columns-solid'
				header={$.__('You need to link a bank account to %1$s below', company.companyLegalName)}
				body={$.__('Once it is authenticated you will be able to use this bank account for payroll.')}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<BankCoin36x36 fill={getFabricBrandColor()} brand />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('You need to link a bank account to %1$s below', company.companyLegalName)}</div>
					<div className='InfoCardBody__text InfoCardBody__text--nopadding'>
						{$.__('Once it is authenticated you will be able to use this bank account for payroll.')}
					</div>
				</div>
			</div>
		);
	};

	const renderAddBankAccountState = () => {
		return ifFeature(
			'encore',
			<InPageMessaging
				type='info'
				icon='building-columns-solid'
				header={$.__('You need to set up your payroll bank account')}
				body={
					<>
						<BodyText>
							{$.__('Before we can verify your company information, you will need to complete your bank account setup.')}
						</BodyText>
						<LayoutBox marginTop={2}>
							<Button onClick={takeToBankAccountSection} type='button'>
								{$.__('Go to Bank Account Settings')}
							</Button>
						</LayoutBox>
					</>
				}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<BankCoin36x36 fill={getFabricBrandColor()} brand />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('You need to set up your payroll bank account')}</div>
					<div className='InfoCardBody__text'>
						{$.__('Before we can verify your company information, you will need to complete your bank account setup.')}
					</div>

					<div className='InfoCardBody__button'>
						<Button clickAction={takeToBankAccountSection} size='medium' text={$.__('Go to Bank Account Settings')} type='button' />
					</div>
				</div>
			</div>
		);
	};

	const renderBankVerifyDepositState = () => {
		const { company } = props;

		const nickName = company.length ? company[0].companyLegalName : company.accountNickname;

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='money-bill-wave-solid'
				type='info'
				header={$.__('Verify the deposit amount to complete setup for %1$s below', nickName)}
				body={$.__(
					'We will send a small deposit to your account. You will see a deposit from "NATPAY or Penny Test". Once the deposit appears in your bank account, come back and enter the amount to verify your account.'
				)}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<BankCoin36x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Verify the deposit amount to complete setup for %1$s below', nickName)}</div>
					<div className='InfoCardBody__text InfoCardBody__text--nopadding'>
						{$.__(
							'We will send a small deposit to your account. You will see a deposit from "NATPAY or Penny Test". Once the deposit appears in your bank account, come back and enter the amount to verify your account.'
						)}
					</div>
				</div>
			</div>
		);
	};

	const renderBankVerifyDepositSingleState = () => {
		const openVerificationModal = () => {
			const { buttonFunction } = props;

			buttonFunction(true);
		};

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='money-bill-wave-solid'
				header={$.__('Confirm deposit amount to complete bank account setup')}
				type='info'
				body={
					<>
						<BodyText>
							{$.__(
								'We will send a small deposit to your account. You will see a deposit from "NATPAY or Penny Test". Once the deposit appears in your bank account, come back and enter the amount to verify your account.'
							)}
						</BodyText>
						<LayoutBox marginTop={2}>
							<Button onClick={openVerificationModal} type='button'>
								{$.__('Verify Account')}
							</Button>
						</LayoutBox>
					</>
				}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<BankCoin36x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Confirm deposit amount to complete bank account setup')}</div>
					<div className='InfoCardBody__text'>
						{$.__(
							'We will send a small deposit to your account. You will see a deposit from "NATPAY or Penny Test". Once the deposit appears in your bank account, come back and enter the amount to verify your account.'
						)}
					</div>

					<div className='InfoCardBody__button'>
						<Button clickAction={openVerificationModal} size='medium' text='Verify Account' type='button' />
					</div>
				</div>
			</div>
		);
	};

	const renderDownloadAndSignState = () => {
		const { clientId, onFileDownload, isProcessing } = props;

		const companyFileTiles = companyFiles.map((file) => {
			return (
				<FileTile
					key={file.id}
					clientId={clientId}
					fileName={file.name}
					fileType={file.type}
					isProcessing={isProcessing}
					onFileDownload={onFileDownload}
				/>
			);
		});

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='file-signature-solid'
				type='info'
				header={$.__('Download and sign your company payroll forms')}
				body={
					<>
						<BodyText>
							{$.__('These forms are required so we can get your company set up to run payroll with BambooHR Payroll.')}
						</BodyText>

						<Flex gap={2} marginTop={2}>
							{companyFileTiles}
						</Flex>
					</>
				}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<DownloadSign37x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Download and sign your company payroll forms')}</div>
					<div className='InfoCardBody__text'>
						{$.__('These forms are required so we can get your company set up to run payroll with BambooHR Payroll.')}
					</div>

					<div className='InfoCardBody__additionalContent'>{companyFileTiles}</div>
				</div>
			</div>
		);
	};

	const renderBankDownloadAndSignState = () => {
		const { clientId, onFileDownload, isProcessing } = props;

		const bankFileTiles = BANK_FILES_ARRAY.map((file) => {
			return (
				<FileTile
					key={file.id}
					clientId={clientId}
					fileName={file.name}
					fileType={file.type}
					isProcessing={isProcessing}
					onFileDownload={onFileDownload}
				/>
			);
		});

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='file-signature-solid'
				header={$.__('Download and sign your bank account authorization forms')}
				type='info'
				body={
					<>
						<BodyText>{$.__('We need your permission to verify and make withdrawals and deposits to your bank account.')}</BodyText>
						<Flex gap={2} marginTop={2}>
							{bankFileTiles}
						</Flex>
					</>
				}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<DownloadSign37x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Download and sign your bank account authorization forms')}</div>
					<div className='InfoCardBody__text '>
						{$.__('We need your permission to verify and make withdrawals and deposits to your bank account.')}
					</div>

					<div className='InfoCardBody__additionalContent InfoCardBody__additionalContent--bank'>{bankFileTiles}</div>
				</div>
			</div>
		);
	};

	const renderDocsUploadedState = () => {
		const openUploadFileModal = () => {
			const { buttonFunction } = props;

			buttonFunction(true);
		};

		/* @startCleanup encore */
		const infoCardContent = <div className='InfoCardBody__text'>{getFilesDownloadList(companyFiles, props.clientId)}</div>;
		/* @endCleanup encore */

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='file-arrow-up-solid'
				header={$.__('Upload your completed company payroll forms')}
				type='info'
				body={
					<>
						{getFilesDownloadList(companyFiles, props.clientId)}
						<LayoutBox marginTop={2}>
							<Button onClick={openUploadFileModal} startIcon='upload-solid' type='button'>
								{$.__('Upload Signed Forms')}
							</Button>
						</LayoutBox>
					</>
				}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<UploadSign37x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Upload your completed company payroll forms')}</div>

					{infoCardContent}

					<div className='InfoCardBody__button'>
						<Button
							clickAction={openUploadFileModal}
							iconBefore='fab-upload-12x16'
							size='medium'
							text='Upload Signed Forms'
							type='button'
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderBankUploadedState = () => {
		const openUploadFileModal = () => {
			const { buttonFunction } = props;

			buttonFunction(true);
		};

		/* @startCleanup encore */
		const startDownload = (fileType) => {
			const { clientId } = props;

			getFileDownload(fileType, clientId);
		};
		/* @endCleanup encore */

		return ifFeature(
			'encore',
			<InPageMessaging
				icon='file-arrow-up-solid'
				header={$.__('Upload your signed bank account authorization forms')}
				type='info'
				body={
					<>
						{getFilesDownloadList(BANK_FILES_ARRAY, props.clientId)}
						<LayoutBox marginTop={2}>
							<Button onClick={openUploadFileModal} startIcon='upload-solid' type='button'>
								{$.__('Upload Forms')}
							</Button>
						</LayoutBox>
					</>
				}
			/>,
			<div className='InfoCard'>
				<div className='InfoCard__icon'>
					<UploadSign37x36 fill={getFabricBrandColor()} />
				</div>

				<div className='InfoCardBody'>
					<div className='InfoCardBody__title'>{$.__('Upload your signed bank account authorization forms')}</div>
					<div className='InfoCardBody__text'>
						{$.__('You can download your')}&nbsp;
						<TextButton
							clickAction={() => startDownload(BANK_FILES.FORM_ACH_AUTHORIZATION)}
							inline={true}
							noSpacing={true}
							text={`${BANK_FILES_ARRAY[0].name}.pdf`}
							type='button'
						/>{' '}
						again if needed.
					</div>

					<div className='InfoCardBody__button'>
						<Button clickAction={openUploadFileModal} iconBefore='fab-upload-12x16' size='medium' text='Upload Forms' type='button' />
					</div>
				</div>
			</div>
		);
	};

	const { status, isMultiEin } = props;

	switch (status) {
		case COMPANY_APP_STATE.COMPANY_SAVED:
			return isMultiEin ? renderMultiRequiredDownloadAndUploadState() : renderDownloadAndSignState();

		case COMPANY_APP_STATE.DOCS_DOWNLOADED:
			return isMultiEin ? renderMultiRequiredDownloadAndUploadState() : renderDocsUploadedState();

		case COMPANY_APP_STATE.DOCS_UPLOADED:
			return isMultiEin ? renderMultiRequiredBankState() : renderAddBankAccountState();

		// BANK SECTION
		case BANK_APP_STATE.BANK_SAVED:
			return isMultiEin ? renderMultiBankDownloadState() : renderBankDownloadAndSignState();

		case BANK_APP_STATE.DOCS_DOWNLOADED:
			return isMultiEin ? renderMultiBankDownloadState() : renderBankUploadedState();

		case BANK_APP_STATE.DOCS_UPLOADED:
			return isMultiEin ? renderBankVerifyDepositState() : renderBankVerifyDepositSingleState();

		case BANK_APP_STATE.LINK_PLAID_SINGLE:
			return isMultiEin ? renderMultiLinkPlaidState() : null;

		default:
			return null;
	}
}
