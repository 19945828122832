import {
	Button,
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';

import {
	L_CANCEL,
	L_SAVE,
} from '../language';

export function ModalFooterLoading() {
	return (
		<StandardModal.Footer
			actions={ [
				(
					<TextButton
						key="cancel"
						type="button"
					>
						{ L_CANCEL }
					</TextButton>
				),
				(
					<Button
						key="primary"
						type="button"
					>
						{ L_SAVE }
					</Button>
				)
			] }
		/>
	)
}
