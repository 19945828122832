import {
	useCallback,
	useMemo,
	useState,
	useEffect,
	useRef,
	useLayoutEffect,
} from 'react';

import {
	Button,
	TextButton,
	Loader,
	makeStyles,
} from '@bamboohr/fabric';
import {
	HeartEkg28x24,
} from '@bamboohr/grim';

import {
	showSlidedown,
} from 'Alerts.mod';
import {
	PageFooter
} from 'page-footer.react';
import { get } from 'lodash';

import {
	HelpButton
} from '../../components/help-button';
import {
	DeductionSyncOption,
	DeductionSyncTable
} from './components';
import {
	deleteBenefitPlans,
	finishDeductionSyncWizard,
	getDeductionsData
} from './api';
import {
	DeductionObject
} from './types';
import {
	responseMessages
} from './utils';

const useStyles = makeStyles(({palette, mixins}) => ({
	container: {
		textAlign: 'left',
		marginBottom: '100px',
	},
	headerContainer: {
		borderBottom: mixins.border(1, palette.grey[400]),
		display: 'flex',
		alignItems: 'center',
	},
	headerIcon: {
		fill: palette.primary.main,
	},
	stepTitle: {
		marginLeft: '8px',
	},
	needHelpContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	instructionContainer: {
		margin: '8px 0 16px',
	},
	optionsContainer: {
		maxWidth: '830px',
		marginBottom: '210px',
	},
	loaderContainer: {
		paddingTop: 200
	}
}));

interface DeductionSyncWizardProps {
	continueWithMx(): void;
	skipOptionsStep: boolean;
}

export function DeductionSyncWizard(props: DeductionSyncWizardProps): JSX.Element {
	const {
		skipOptionsStep,
		continueWithMx,
	} = props;
	const styles = useStyles();

	const [goToPlanCreation, setGoToPlanCreation] = useState<boolean>(false);
	const [selectedRadioOption, setSelectedRadioOption] = useState<string>('');
	const [recommendedCheckboxChecked, setRecommendedCheckboxChecked] = useState<boolean>(false);
	const [notRecommendedCheckboxChecked, setNotRecommendedCheckboxChecked] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [deductions, setDeductions] = useState<DeductionObject[]>([]);
	const [deductionSyncFinished, setDeductionSyncFinished] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const mounted = useRef(false);
	const showPlanCreationContent = useMemo<boolean>(
		() => skipOptionsStep || goToPlanCreation,
		[skipOptionsStep, goToPlanCreation]
	);
	const recommendedRouteEnabled = useMemo<boolean>(
		() => selectedRadioOption === 'recommendedRadioOption' && recommendedCheckboxChecked,
		[selectedRadioOption, recommendedCheckboxChecked]
	);
	const notRecommendedRouteEnabled = useMemo<boolean>(
		() => selectedRadioOption === 'notRecommendedRadioOption' && notRecommendedCheckboxChecked,
		[selectedRadioOption, notRecommendedCheckboxChecked]
	);
	const continueButtonEnabled = useMemo<boolean>(
		() => {
			if (showPlanCreationContent) {
				return deductionSyncFinished;
			}
			return recommendedRouteEnabled || notRecommendedRouteEnabled;
		},
		[recommendedRouteEnabled, notRecommendedRouteEnabled, showPlanCreationContent, deductionSyncFinished]
	);

	const handleCheckboxChange = useCallback((value: string, checked: boolean): void => {
		if (value === 'recommendedCheckbox') {
			setRecommendedCheckboxChecked(checked);
		}
		if (value === 'notRecommendedCheckbox') {
			setNotRecommendedCheckboxChecked(checked);
		}
	}, []);
	const handleRadioChange = useCallback((value: string): void => {
		setSelectedRadioOption(value || '');
		setRecommendedCheckboxChecked(false);
		setNotRecommendedCheckboxChecked(false);
	}, []);

	const handleContinueClick = useCallback((): void => {
		setIsProcessing(true);
		if (deductionSyncFinished) {
			const postProcessingPostableIds = [];
			deductions.forEach(deduction => {
				if (deduction.postProcessingPostable) {
					postProcessingPostableIds.push(deduction.traxCldId);
				}
			});

			finishDeductionSyncWizard(postProcessingPostableIds).then(response => {
				if (response.status === 201) {
					continueWithMx();
					setIsProcessing(false);
				}
			}).catch((error) => {
				let errorMessage = responseMessages.standardError;

				if (get(error, 'response.status') === 400) {
					errorMessage = responseMessages.badPayeeDataError;
				}

				showSlidedown(errorMessage, 'error');
				setIsProcessing(false);
			});
			return;
		}

		if (recommendedRouteEnabled && !deductionSyncFinished) {
			deleteBenefitPlans().then((response) => {
				if (response.status === 200) {
					setGoToPlanCreation(true);
					setIsProcessing(false);
				}
			}).catch(() => {
				showSlidedown(responseMessages.standardError, 'error');
				setIsProcessing(false);
			});
		} else if (notRecommendedRouteEnabled) {
			continueWithMx();
			setIsProcessing(false);
		}
	}, [deductions, recommendedRouteEnabled, notRecommendedRouteEnabled, continueWithMx, deductionSyncFinished]);

	useLayoutEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	// Get deductions data when appropriate
	useEffect(() => {
		if (showPlanCreationContent) {
			setIsLoading(true);
			getDeductionsData()
				.then((response) => {
					if (mounted.current && response.data) {
						if (response.data.length === 0) {
							setDeductionSyncFinished(true);
							showSlidedown(responseMessages.noDeductionsContinueText, 'info');
						} else {
							setDeductions(response.data);
						}
					}
				})
				.catch(() => {
					if (mounted.current) {
						showSlidedown(responseMessages.getDeductionDataError, 'error');
						setIsProcessing(false);
					}
				})
				.finally(() => {
					if (mounted.current) {
						setIsLoading(false);
					}
				})
		}
	}, [showPlanCreationContent]);

	useEffect(() => {
		let deductionsAllValid = deductions.length > 0;
		if (deductionsAllValid){
			deductions.forEach((deduction) => {
				if (deduction.created === false && deduction.postProcessingPostable === false) {
					deductionsAllValid = false;
				}
			})
		}
		setDeductionSyncFinished(deductionsAllValid)
	}, [deductions])

	if (isLoading) {
		return (
			<div className={ styles.loaderContainer }>
				<Loader />
			</div>
		);
	}

	return (
		<div className={ styles.container }>
			<div className={ styles.headerContainer }>
				<HeartEkg28x24 className={ styles.headerIcon } />
				<h2 className={ styles.stepTitle }>
					{ $.__('Company Deductions') }
				</h2>
			</div>

			<div className={ styles.instructionContainer }>
				<div className={ styles.needHelpContainer }>
					<h4>
						{ showPlanCreationContent
							? $.__(`Let's create your benefit plans in BambooHR!`)
							: $.__('How would you like us to match your benefit deduction records?') }
					</h4>
					<HelpButton />
				</div>

				<p>
					{ showPlanCreationContent
						? $.__('We just need to know the plan details. All of your employees will be assigned to the plans the same as they are in TRAXPayroll.')
						: $.__(`The fastest way to get everything to match is to create new plans in BambooHR that match what you've been using in payroll.`) }
				</p>
			</div>

			<div>
				{ (showPlanCreationContent) ? (
					<DeductionSyncTable deductions={ deductions } setDeductions={ setDeductions } />
				) : (
					<div className={ styles.optionsContainer }>
						<DeductionSyncOption
							checkboxIsChecked={ recommendedCheckboxChecked }
							checkboxLabel={ $.__(`I understand that all of my benefit information in BambooHR will be permanently replaced by the new plans I create using my TRAXPayroll information. Should I need it, a copy of my BambooHR benefit enrollment data will be saved on my company files tab. Let's do this!`) }
							checkboxValue="recommendedCheckbox"
							onCheckboxChange={ handleCheckboxChange }
							onRadioChange={ handleRadioChange }
							radioIsSelected={ selectedRadioOption === 'recommendedRadioOption' }
							radioLabel={ $.__(`Use the plans from TRAXPayroll and replace my BambooHR plans (Recommended)`)}
							radioNote={ $.__(`If you haven't kept your benefit plans accurate or up-to-date in BambooHR, this is your best bet. We will clear out all of your benefit plans and start fresh using what you have in TRAXPayroll as a starting point. A copy of your BambooHR benefit enrollment data will be saved on your company files tab.`) }
							radioValue="recommendedRadioOption"
						/>

						<DeductionSyncOption
							checkboxIsChecked={ notRecommendedCheckboxChecked }
							checkboxLabel={ $.__(`My benefit plans are set up in BambooHR the same way I have them set up in TRAXPayroll when I run payroll. Matching them up should be a breeze!`) }
							checkboxValue="notRecommendedCheckbox"
							onCheckboxChange={ handleCheckboxChange }
							onRadioChange={ handleRadioChange }
							radioIsSelected={ selectedRadioOption === 'notRecommendedRadioOption' }
							radioLabel={ $.__(`Use the plans from BambooHR and clear up any differences between TRAXPayroll.`)}
							radioNote={ $.__(`If your benefits are set up in BambooHR the same way as they are in TRAXPayroll, this might be a good option for you. Please note that we will have to verify each company and employee deduction, so this could take a while.`) }
							radioValue="notRecommendedRadioOption"
						/>
					</div>
				) }

			</div>

			<PageFooter
				left={ (
					<>
						<Button
							clickAction={ handleContinueClick }
							isDisabled={ !continueButtonEnabled }
							processing={ isProcessing }
							type="button"
						>
							{ $.__('Continue') }
						</Button>
						<TextButton
							href="/settings/payroll/migration_tasks"
						>
							{ $.__('Save & Exit') }
						</TextButton>
					</>
				) }
			/>
		</div>
	);
}
