
import { createStyles, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default makeStyles(({ palette, typography }) => {
	return createStyles({
		addFileModal: {
			maxHeight: 'inherit',
			overflowY: 'hidden',
		},
		addFileForm: {
			marginTop: 0,
			paddingBottom: 0,
			maxHeight: 'inherit',
			overflowY: 'hidden',
			position: 'relative',
		},
		fileListModalSubheader: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '36px',
			
			// startCleanup encore
			padding: ifFeature('encore', '0px 16px 16px 16px', '16px'),
			marginTop: ifFeature('encore', '', '20px'),
			backgroundColor: ifFeature('encore', '', palette.grey[100]),
			borderBottom:  ifFeature('encore', '', `solid ${ palette.grey[300] } 1px`),
			borderRadius: ifFeature('encore', '8px', ''),
			// endCleanup encore
		},
		fileListCount: {
			color: palette.grey[800],
			lineHeight: typography.fabricLineHeight('teenie'),
			fontSize: typography.fabricFontSize('teenie'),
			fontWeight: typography.fontWeightRegular,
		},
	});
});

