import Ctrl, {
	onChange,
	onSelectChange,
	onSubmit,
	onPjaxEnd,
	onReady,
} from 'ctrl.deco';


const FORM_SELECTOR = '.settingsMainContentForm';

@Ctrl('/settings/account/general.php')
class SettingsAccountGeneralCtrl {
	_loaded = false;

	get $submitBtn(): JQuery {
		return $('.js-disableSubmitOnclick');
	}

	@onPjaxEnd()
	@onReady()
	_onReady(e, ctrl: SettingsAccountGeneralCtrl) {
		setTimeout(() => {
			ctrl._loaded = true;
		}, 0);
	}

	@onChange(`input, select, textarea`, () => $(FORM_SELECTOR))
	@onSelectChange('ba-select', () => $(FORM_SELECTOR))
	_onChange(e, { _loaded, $submitBtn }: SettingsAccountGeneralCtrl) {
		// don't enable $submitBtn before ready
		if (_loaded) {
			$submitBtn.enable();
		}
	}

	@onSubmit(FORM_SELECTOR)
	_onSubmit(e, { $submitBtn }: SettingsAccountGeneralCtrl) {
		$submitBtn.toggleProcessing(true);
	}
}
