import {
	Modal,
} from './modal';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function ModalConnector() {
	const [{
		clientEin,
		clientName,
		isLoadingClientTaxSettings,
		isProcessingClientTaxSettings,
	}] = useCompanyTaxSettingsStore();
	return (
		<Modal
			clientEin={ clientEin }
			clientName={ clientName }
			isLoading={ isLoadingClientTaxSettings }
			isProcessing={ isProcessingClientTaxSettings }
		/>
	);
}
