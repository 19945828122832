import { useRef, useState } from 'react';
import { Modal } from 'modal-legacy';

import { CompanyInfoFieldset } from './company-info-fieldset';
import { postCompanyInfo } from './utils';
import {
	useReducerContext,
} from '../state';
import {
	createForm,
} from '../../../shared';

const {
	FormProvider,
	FormInput,
	FormSelect,
	useForm,
} = createForm();

export function EditCompanyInfoModal(props) {
	const {
		isModalOpen,
		onModalClose,
	} = props;
	const {
		companyInfo,
	} = useReducerContext();
	const formRef = useRef(null);
	const [isProcessing, setIsProcessing] = useState(false);

	return (
		<Modal
			isHeadless={ true }
			isOpen={ isModalOpen }
			isProcessing={ isProcessing }
			onClose={ onModalClose }
			primaryAction={ () => {
				if (formRef && formRef.current && formRef.current.checkValidity()) {
					setIsProcessing(true);
					postCompanyInfo(new FormData(formRef.current))
						.then(() => {
							onModalClose();
							location.reload();
						}, (error) => {
							console.error(error);
							setIsProcessing(false);
						});
				} else {
					window.setMessage($.__('The Company Information form is invalid.'), 'error');
				}
			} }
			primaryActionText={ $.__('Save') }
			type="medium"
		>
			<FormProvider
				form={ formRef }
				values={ companyInfo }
			>
				<form ref={ formRef }>
					<CompanyInfoFieldset
						FormInput={ FormInput }
						FormSelect={ FormSelect }
						companyNameField="companyName"
						useForm={ useForm }
					/>
				</form>
			</FormProvider>
		</Modal>
	);
}
