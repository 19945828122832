import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';
import { EmployeeFieldsProjectTracking } from '../../project-tracking';

window.ProjectTracking = {
	init: () => {
		render(
			<PoMicroFrontend render={(props) => <EmployeeFieldsProjectTracking {...props} />} route='/time-tracking/settings/project-tracking' />,
			document.getElementById('js-project-tracking-root')
		);
	},
};
