import { BodyText, Button, Flex, LayoutBox, Link, Table, TableColumn, TextButton } from '@bamboohr/fabric';
import { CirclePlus16x16, EyeOff16x16, TrashCan14x16 } from '@bamboohr/grim';
import { Message } from '@bamboohr/utils/lib/message';
import { useState } from 'react';
import { usePerformanceSettingsContext } from '../../context';
import { ReviewCycleConfigurationData } from '../../types';
import { DeactivateReviewCycleModal } from './components/deactivate-review-cycle-modal.react';
import { DeleteReviewCycleModal } from './components/delete-review-cycle-modal.react';
import { ReviewCyclesBlankState } from './components/review-cycles-blank-state.react';
import { ScheduleIcon } from './components/schedule-icon.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const ReviewCyclesSettings = (): JSX.Element => {
	const [{ reviewCycleData }] = usePerformanceSettingsContext();
	const [activeRowData, setActiveRowData] = useState({} as ReviewCycleConfigurationData);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [toggleModalIsOpen, setToggleModalIsOpen] = useState(false);

	const [activeToggles] = useState(() => {
		if (reviewCycleData?.length) {
			return Object.fromEntries(
				reviewCycleData.map((rcc) => {
					return [rcc.id, rcc.status === 'active'];
				})
			);
		}

		return {};
	});

	const handleCreateReviewCycleClick = () => {
		window.location.href = '/settings/performance/home/setup';
	};

	const handleToggleChange = (row: ReviewCycleConfigurationData) => {
		setActiveRowData(row);
		setToggleModalIsOpen(true);
	};

	const toggleDeleteModal = (row) => {
		setActiveRowData(row);
		setIsDeleteModalOpen(true);
	};

	const columns: TableColumn<ReviewCycleConfigurationData>[] = [
		{
			header: $.__('Name'),
			key: 'name-key',
			cell: (row) => (
				<Flex alignItems='center' maxWidth='440px'>
					{ifFeature(
						'encore',
						<Link href={`/settings/performance/home/setup/${row.id}`}>{row.name}</Link>,
						<TextButton href={`/settings/performance/home/setup/${row.id}`} inline={true} type='button'>
							{row.name}
						</TextButton>
					)}
				</Flex>
			),
			sortBy: (row) => row.name.toLowerCase(),
		},
		{
			align: 'center' as 'left' | 'center' | 'right',
			header: $.__('Schedule'),
			key: 'schedule-key',
			cell: (row) => {
				return <ScheduleIcon rowData={row} />;
			},
			width: '15%',
		},
		{
			align: 'center',
			header: $.__('Employees'),
			key: 'employees-key',
			cell: (row) => <p> {row.employees} </p>,
			width: '15%',
		},
		{
			align: 'center',
			header: $.__('Status'),
			key: 'active-key',
			cell: (row) => {
				let status = '';
				switch (row.status) {
					case 'inactive':
						status = $.__('Inactive');
						break;
					case 'active':
						status = $.__('Active');
						break;
					default:
						status = $.__('Draft');
				}

				return ifFeature(
					'encore',
					<Flex justifyContent='center'>
						<BodyText>{status}</BodyText>
					</Flex>,
					row.status === 'active' ? (
						<Flex justifyContent='center'>
							<BodyText weight='bold'>{status}</BodyText>
						</Flex>
					) : (
						<Flex justifyContent='center'>
							<BodyText>
								<Message params={[status]} text={'_{1}_'} />
							</BodyText>
						</Flex>
					)
				);
			},
			sortBy: (row) => row.status,
			width: '15%',
		},
		{
			headerAriaLabel: $.__('Review cycle configuration utilities'),
			key: 'utility-wrapper',
			showOnHover: true,
			cell: {
				type: 'actions',
				actions: (row) => {
					const isReviewCycleActive = activeToggles[row.id] && row.status === 'active';
					const deactivateActionData = {
						icon: ifFeature('encore', 'eye-slash-regular', <EyeOff16x16 />),
						action: () => handleToggleChange(row),
						tooltipContent: $.__('Deactivate'),
					};

					const actions = [
						{
							icon: ifFeature('encore', 'trash-can-regular', <TrashCan14x16 />),
							action: () => toggleDeleteModal(row),
							tooltipContent: $.__('Delete'),
						},
					];

					if (isReviewCycleActive) {
						actions.unshift(deactivateActionData);
					}

					return actions;
				},
			},
			width: '10%',
		},
	];

	return reviewCycleData?.length ? (
		<>
			<LayoutBox margin='24px 0'>
				<Button
					data-bi-id='performance-settings-create-review-cycle'
					onClick={() => handleCreateReviewCycleClick()}
					size={ifFeature('encore', 'medium', 'large')}
					startIcon={ifFeature('encore', 'plus-regular', <CirclePlus16x16 />)}
					type='button'
					variant={ifFeature('encore', undefined, 'outlined')}
					color={ifFeature('encore', 'secondary')}
				>
					{$.__('Create a Review Cycle')}
				</Button>
			</LayoutBox>

			<DeactivateReviewCycleModal
				activeRowData={activeRowData}
				modalIsOpen={toggleModalIsOpen}
				setModalIsOpen={setToggleModalIsOpen}
			/>
			<DeleteReviewCycleModal
				activeRowData={activeRowData}
				isDeleteModalOpen={isDeleteModalOpen}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
			/>
			<Table
				caption={$.__('Review Cycle Configurations Table')}
				columns={columns}
				rowKey={(row: ReviewCycleConfigurationData) => row.id}
				rows={reviewCycleData}
				stickyHeader={true}
			/>
		</>
	) : (
		<ReviewCyclesBlankState />
	);
};
