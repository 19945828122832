import {Component} from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import Ajax from '@utils/ajax';
import {classNameFromObject} from '@utils/dom';
import moment from 'moment.lib';
import PaySchedule from 'PaySchedule.mod';
import { BodyText, Button, DatePicker, Flex, FormNote, Label, LayoutBox, SheetModal, TextButton } from '@bamboohr/fabric';

import { getIsDateDisabled } from './change-date.domain';

const today = moment().startOf('day');
const datePickerFormat = "YYYY-MM-DD";
const businessDays = 3;

const ERROR_MAP = {
	NO_WEEKENDS: $.__('Pay days cannot be on a weekend'),
	THREE_DAYS_MINIMUM: $.__('Must be at least three business days from today'),
	BEFORE_END_DATE: $.__('Pay days cannot be before the period end date'),
	BANK_HOLIDAY: $.__('Pay days cannot be on a bank holiday'),
};

export default class ChangeDate extends Component {
	constructor(props) {
		super(props);
		const {payDate, disableDays = []} = this.props;
		this.state = {
			selectedDate: payDate.payDate,
			errorReason: null,
			isProcessing: false,
		};
		this.threeBusinessDaysFromTodayDate = PaySchedule.getBusinessDaysInTheFutureFromDate(today, businessDays, disableDays);
	}

	_calculateDisabledDays = () => {
		const {payDate} = this.props;
		const {payPeriodEndDate} = payDate;
		const momentPeriodEndDate = moment(payPeriodEndDate);
		const minDate = momentPeriodEndDate.diff(this.threeBusinessDaysFromTodayDate, 'days') < 0 ? this.threeBusinessDaysFromTodayDate : momentPeriodEndDate;
		return Array
			.from({length: minDate.diff(today, 'days')})
			.map((_, i) => today.clone().add(i, 'days').format('YYYY-MM-DD'));
	};


	_handleDateChange = (newDate) => {
		if (newDate === undefined) {
			this.setState({
				selectedDate: newDate,
			});
			return;
		}

		this.setState({
			selectedDate: newDate.value,
			errorReason: this._isValidateDate(newDate.value),
		});
	};

	_isValidateDate = (date) => {
		if (date === undefined) {
			return 'NOT_SET';
		}

		const {payDate, disableDays = []} = this.props;
		const {payPeriodEndDate} = payDate;
		const momentDate = moment(date);
		const dayOfWeek = momentDate.day();

		if (dayOfWeek === 0 || dayOfWeek === 6) {
			return 'NO_WEEKENDS';
		}

		if (momentDate.diff(this.threeBusinessDaysFromTodayDate, 'days') < 0) {
			return 'THREE_DAYS_MINIMUM';
		}

		if (momentDate.isBefore(moment(payPeriodEndDate), 'day')) {
			return 'BEFORE_END_DATE';
		}

		if (disableDays.indexOf(moment(date).format('YYYY-MM-DD')) > -1) {
			return 'BANK_HOLIDAY';
		}

		return null;
	};

	_handleSubmitFutureDate = () => {
		const {payDate, onSave} = this.props;
		const {selectedDate} = this.state;
		const errorReason = this._isValidateDate(selectedDate);
		if (errorReason) {
			setWhoopsMessage();
			this.setState({
				errorReason,
			});
			return;
		}
		window.closeMessage();
		this.setState({isProcessing: true});

		Ajax
			.post('/settings/pay_schedules/pay_cycles/update_pay_date', {
				payCycles: [{
					id: payDate.id,
					payDate: moment(selectedDate).format('YYYY-MM-DD'),
				}]
			})
			.then(() => {
				onSave(payDate.id, selectedDate);
				window.setMessage($.__('Pay date successfully updated.'), 'success');
			})
			.catch((error) => {
				if (error.response?.data?.error?.code === 'PAY_CYCLE_APPROVED') {
					window.setMessage($.__("Whoops. This pay cycle has already been approved. You'll need to re-open the payroll or reach out to our Payroll Support team to move the pay date (866-872-9123 or payrollhelp@bamboohr.com)."), 'error');
				} else {
					setWhoopsMessage();
				}

				this.setState({isProcessing: false});
			});
	};

	render () {
		const {disableDays, isOpen, handleClose, title, payDate} = this.props;
		const {errorReason, isProcessing} = this.state;
		
		const payPeriodDisabledDates = this._calculateDisabledDays();

		return (
			<SheetModal isOpen={isOpen} isProcessing={isProcessing} onRequestClose={handleClose}>
				<SheetModal.Body
					renderFooter={ (
						<SheetModal.Footer 
							actions={ifFeature(
								'encore',
								[
									<TextButton key='secondary' onClick={handleClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
									<Button key='primary' onClick={this._handleSubmitFutureDate} type='button'>
										{$.__('Save')}
									</Button>,
								],
								[
									<Button key='primary' onClick={this._handleSubmitFutureDate} type='button'>
										{$.__('Save')}
									</Button>,
									<TextButton key='secondary' onClick={handleClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
								]
							)}
						/>
					)}
					renderHeader={ (
						<SheetModal.Header hasCloseButton={true} title={title} />
					) }
				>
					<LayoutBox margin={3} marginLeft={2} marginRight={0}>
						<form className="js-noValidate ">
							<div
								className={ classNameFromObject({
									'error': !!errorReason,
								}) }
							>
								<Label required={true} status={errorReason ? 'error' : 'default'}>
									{ $.__('Pay date for %1$s - %2$s', moment(payDate.payPeriodStartDate).format(), moment(payDate.payPeriodEndDate).format()) }
								</Label>
								<Flex alignItems="center" flexDirection="row" gap={3}>
									<DatePicker
										getDateDisabled={(dateInfo) => getIsDateDisabled(dateInfo.date, disableDays, payPeriodDisabledDates)}
										id="future-date-change"
										maxDate={today.clone().startOf('month').add(14, 'months').endOf('month').format(datePickerFormat)}
										minDate={today.format(datePickerFormat)}
										name="futureDateChange"
										onChange={ this._handleDateChange }
										required={ true }
										status={ errorReason ? "error" : "default" }
										value={this.state.selectedDate}
									/>
									{ errorReason !== null && (
										<FormNote error={true}>
											<BodyText weight="semibold">
												{ ERROR_MAP[errorReason] }
											</BodyText>
										</FormNote>
									) }
								</Flex>
							</div>
						</form>
					</LayoutBox>
				</SheetModal.Body>
			</SheetModal>
		);
	}
}

function setWhoopsMessage() {
	window.setMessage($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
}
