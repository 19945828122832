import {
	useState,
	useRef,
	useEffect,
} from 'react';
/** @type {import('axios').AxiosStatic} */
import Ajax from '@utils/ajax';

/**
 * @param {string} [name]
 * @param {AjaxConfig} [config]
 * @returns {AjaxAPI<D>}
 * @template {any} D
 */
export function useAjax(name, config) {
	/** @type {[AjaxState<D>, React.Dispatch<React.SetStateAction<AjaxState<D>>>]} */
	const [{
		data,
		error,
		success,
		processing = false,
		response,
		status,
	}, setState] = useState({
		processing: false,
	});
	const ajax = name ? (Ajax.instances[name] || Ajax.create(name, config)) : Ajax;

	/** @type {AjaxFunctions['request']} */
	const request = (_config) => {
		setState({
			processing: true,
		});

		ajax.request(_config)
			.catch((error) => {
				console.error(error);
				let errorMessage;

				if (error.response.status === 403) {
					errorMessage = window.DEFAULT_PERMISSIONS_ERROR_MESSAGE;
				}

				return {
					data: {
						success: false,
						errorMessage,
					},
				};
			})
			.then((response) => {
				const {
					success: _success,
					successMessage,
					message,
					error: _error,
					errorMessage,
					..._data
				} = response.data;

				if (_success === false) {
					if (_error) {
						console.error(_error);
					}

					throw Ajax.createError(errorMessage || window.DEFAULT_ERROR_MESSAGE, response);
				}

				if (successMessage || message) {
					window.setMessage(successMessage || message, 'success');
				}

				setState({
					processing: false,
					response,
					status: response.status,
					success: true,
					data: _data,
				});
			})
			.catch((error) => {
				window.setMessage(error.message, 'error');

				setState({
					processing: false,
					response: error.response,
					status: error.response.status,
					success: false,
					error,
				});
			});
	};

	/** @type {AjaxFunctions['reset']} */
	const reset = () => {
		setState({
			processing: false,
		});
	};

	const useResetEffect = (deps = [], condition = true) => {
		useEffect(() => {
			if (condition) {
				reset();
			}
		}, deps);
	};

	/**
	 * @type {AjaxHooks['useAjaxEffect']}
	 */
	const useAjaxEffect = (_config = config, deps = [], condition = true) => {
		useEffect(() => {
			if (condition) {
				request(_config);
			}
		}, deps);
	};

	/**
	 * @type {AjaxHooks['useSuccessEffect']}
	 */
	const useSuccessEffect = (effect, extraDeps = []) => {
		useEffect(() => {
			if (
				!processing &&
				success
			) {
				effect();
			}
		}, [processing, success, ...extraDeps]);
	};


	/**
	 * @type {AjaxHooks['useErrorEffect']}
	 */
	const useErrorEffect = (effect, extraDeps = []) => {
		useEffect(() => {
			if (
				!processing &&
				!success &&
				error
			) {
				effect();
			}
		}, [processing, success, error, ...extraDeps]);
	};

	/**
	 * @type {AjaxHooks['useResponseEffect']}
	 */
	const useResponseEffect = (effect, extraDeps = []) => {
		useEffect(() => {
			if (
				!processing &&
				response
			) {
				effect();
			}
		}, [processing, response, ...extraDeps]);
	};

	return {
		// state values
		data,
		error,
		processing,
		response,
		status,

		// functions
		request,
		reset,

		// hooks
		useAjaxEffect,
		useErrorEffect,
		useResetEffect,
		useResponseEffect,
		useSuccessEffect,
	};
}

/**
 * @typedef AjaxState
 * @property {D} [data]
 * @property {import('axios').AxiosError} [error]
 * @property {boolean} processing
 * @property {import('axios').AxiosResponse<D>} [response]
 * @property {number} [status]
 * @property {boolean} [success]
 * @template {any} D
 */

/**
 * @typedef AjaxFunctions
 * @property {(config?: AjaxConfig) => void} request
 * @property {() => void} reset
 */


/**
 * @typedef AjaxHooks
 * @property {(config?: AjaxConfig, deps?: any[], condition?: boolean = true) => void} useAjaxEffect
 * @property {(effect: React.EffectCallback, deps?: any[]) => void} useErrorEffect
 * @property {(effect: React.EffectCallback, deps?: any[]) => void} useResponseEffect
 * @property {(deps?: any[], condition?: boolean = true) => void} useResetEffect
 * @property {(effect: React.EffectCallback, deps?: any[]) => void} useSuccessEffect
 */

/**
 * @typedef {import('axios').AxiosRequestConfig} AjaxConfig
 */

/**
 * @typedef {AjaxState<D> & AjaxFunctions & AjaxHooks} AjaxAPI
 * @template {any} D
 */
