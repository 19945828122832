import {
	Fragment,
} from 'react';
import {
	makeStyles,
} from '@bamboohr/fabric';
import {
	EmployeeDeductionsMetadata,
} from '../../utils/interfaces';

const useStyles = makeStyles(({ palette, typography }) => ({
	nameAndDates: {
		color: palette.grey[1000],
	},
	detailContainer: {
		display: 'flex',
		color: palette.grey[800],
		fontSize: typography.fabricFontSize('teenie'),
	},
	labels: {
		marginRight: '18px',
	},
}));

export function EmployeeDeductionsOverlapDetail(props: EmployeeDeductionsMetadata) {
	const {
		startDate,
		endDate,
		name,
		employerPays,
		employeePays,
	} = props;
	const classes = useStyles();

	return (
		<Fragment>
			<p className={ classes.nameAndDates }>
				{ name } ({ $.__('Deduction Start') }: { startDate })
			</p>

			<div className={ classes.detailContainer }>
				<div className={ classes.labels }>
					<p>{ $.__('Deduction End') }:</p>
					<p>{ $.__('Employer Pays') }:</p>
					<p>{ $.__('Employee Pays') }:</p>
				</div>
				<div>
					<p>{ endDate || '--' }</p>
					<p>{ employerPays || '--' }</p>
					<p>{ employeePays || '--' }</p>
				</div>
			</div>
		</Fragment>
	);
}
