import Ajax from '@utils/ajax';
import {
	redirect,
} from 'BambooHR.util';
import {
	showSlidedown,
} from 'Alerts.mod';
import {
	isEnabled,
} from 'FeatureToggle.util';
import {
	StepDataObject,
	SectionUrl,
	NormalizeRecordsReturnObject,
	ResponseRecordObject,
	FieldsNormalized,
	GroupsNormalized,
	SaveFieldObject,
	SaveDataObject,
	ErrorFieldObject,
	SectionType,
	ItemToMap,
	DatabaseString,
	WageMetadata,
	CompanyLocationMetadata,
	ResponseFieldObject,
	ItemTextAndMetadataObject,
	CreateValueObject,
	CreateValueObjectJoined,
	NormalizedItemsToMap,
	MappingFieldNamesByType,
	ItemsToMapById,
	RelatedValuesObject,
	WageOverlapDateFieldObject,
	OverlappingErrorFields,
	FieldObject,
	CompanyDeductionsMetadata,
	CompanyDivisionMetadata,
	CompanyDepartmentMetadata,
	CompanyEmploymentStatusMetadata,
	EmployeeRecordMappingMetadata,
	EmployeeDeductionsMetadata,
	ResponseClientObject,
	TimeOffMetadata,
} from './interfaces';
import {
	AxiosPromise,
} from 'axios';

// Constants
const MX_STEPS_IN_DEV = isEnabled('mxStepsInDev');

export const ADDITIONAL_BAMBOO_OPTION_ID_KEY = 'additionalBambooOptionId_';

export const EMPLOYEE_RECORD: StepDataObject = {
	checkingProgressName: $.__('Employee Records'),
	sectionTitle: $.__('Employee Record'),
	url: 'employee',
	type: 'employeeRecord',
	groupIcon: 'fab-person-circle-20x20',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const EMPLOYEE_BANK: StepDataObject = {
	checkingProgressName: $.__('Employee Bank Information'),
	sectionTitle: $.__('Employee Bank'),
	url: 'employee_direct_deposit',
	type: 'employeeBank',
	groupIcon: 'fab-person-circle-20x20',
	isMappingGrouped: true,
	isComparisonGrouped: true,
};

export const EMPLOYEE_WAGE: StepDataObject = {
	checkingProgressName: $.__('Employee Wage Information'),
	sectionTitle: $.__('Employee Compensation'),
	url: 'employee_compensation',
	type: 'employeeWage',
	groupIcon: 'fab-person-circle-20x20',
	isMappingGrouped: true,
	isComparisonGrouped: true,
};

export const COMPANY_LOCATION: StepDataObject = {
	checkingProgressName: $.__('Company Location Information'),
	sectionTitle: $.__('Company Location'),
	url: 'company_location',
	type: 'companyLocation',
	groupIcon: 'fab-location-15x20',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const COMPANY_DEDUCTIONS: StepDataObject = {
	checkingProgressName: $.__('Company Deduction Information'),
	sectionTitle: $.__('Company Benefit/Non-Benefit Deductions'),
	url: 'company_deductions',
	type: 'companyDeductions',
	groupIcon: null,
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const EMPLOYEE_DEDUCTIONS: StepDataObject = {
	checkingProgressName: $.__('Employee Deduction Information'),
	sectionTitle: $.__('Employee Benefit/Non-Benefit Deductions'),
	url: 'employee_deductions',
	type: 'employeeDeductions',
	groupIcon: 'fab-person-circle-20x20',
	isMappingGrouped: true,
	isComparisonGrouped: true,
};

export const COMPANY_DEPARTMENT: StepDataObject = {
	checkingProgressName: $.__('Company Department Information'),
	sectionTitle: $.__('Company Department'),
	url: 'company_department',
	type: 'companyDepartment',
	groupIcon: 'fab-employees-12x11',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const COMPANY_EMPLOYMENT_STATUS: StepDataObject = {
	checkingProgressName: $.__('Company Employment Status'),
	sectionTitle: $.__('Company Employment Status'),
	url: 'company_employment_status',
	type: 'companyEmploymentStatus',
	groupIcon: 'fab-location-15x20',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const EMPLOYEE_DEMOGRAPHICS: StepDataObject = {
	checkingProgressName: $.__('Employee Demographics Information'),
	sectionTitle: $.__('Employee Demographics'),
	url: 'employee_demographics',
	type: 'employeeDemographics',
	groupIcon: 'fab-person-circle-20x20',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const COMPANY_DIVISION: StepDataObject = {
	checkingProgressName: $.__('Company Division Information'),
	sectionTitle: $.__('Company Division'),
	url: 'company_division',
	type: 'companyDivision',
	groupIcon: 'fab-employees-14x12',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};
export const EMPLOYEE_TAX: StepDataObject = {
	checkingProgressName: $.__('Employee Tax Information'),
	sectionTitle: $.__('Employee Tax'),
	url: 'employee_tax',
	type: 'employeeTax',
	groupIcon: 'fab-person-circle-20x20',
	isMappingGrouped: false,
	isComparisonGrouped: true,
};

export const TIME_OFF: StepDataObject = {
	checkingProgressName: $.__('Time Off Type Information'),
	sectionTitle: $.__('Time Off Types'),
	url: 'company_time_off',
	type: 'timeOff',
	groupIcon: null,
	isMappingGrouped: false,
	isComparisonGrouped: false,
};

export const MAPPING_FIELD_NAMES: MappingFieldNamesByType = {
	employeeWage: 'Compensation',
	companyLocation: 'location',
	companyDeductions: 'company_deductions',
	employeeDeductions: 'employee_deductions',
	companyEmploymentStatus: 'employment_status',
	companyDepartment: 'department',
	companyDivision: 'division',
	employeeRecord: 'employee',
	timeOff: 'time_off_type',
};

export const MAPPING_STEPS: Array<SectionType> = ['employeeRecord', 'employeeWage', 'companyLocation', 'companyDeductions', 'companyEmploymentStatus', 'companyDepartment', 'companyDivision', 'employeeDeductions', 'timeOff'];
export const SUB_GROUPED_STEPS: Array<SectionType> = ['employeeWage', 'employeeDeductions'];
export const ALL_STEPS_IN_ORDER: Array<StepDataObject> = MX_STEPS_IN_DEV ?
	// All steps, including those that are currently in development
	[COMPANY_LOCATION, COMPANY_EMPLOYMENT_STATUS, COMPANY_DEPARTMENT, COMPANY_DIVISION, EMPLOYEE_RECORD, EMPLOYEE_DEMOGRAPHICS, EMPLOYEE_BANK, EMPLOYEE_WAGE, EMPLOYEE_TAX, COMPANY_DEDUCTIONS, EMPLOYEE_DEDUCTIONS, TIME_OFF] : // TIME_OFF must be last
	// Steps that have been released to customers
	[COMPANY_LOCATION, COMPANY_EMPLOYMENT_STATUS, COMPANY_DEPARTMENT, COMPANY_DIVISION, EMPLOYEE_RECORD, EMPLOYEE_DEMOGRAPHICS, EMPLOYEE_BANK, EMPLOYEE_WAGE, EMPLOYEE_TAX, COMPANY_DEDUCTIONS, EMPLOYEE_DEDUCTIONS, TIME_OFF]; // TIME_OFF must be last
export const REDIRECT_URL = '/settings/payroll/migration_tasks';
export const REDIRECT_MSG = $.__('Uh oh...there was a problem retrieving migration tasks. Please try again later.');
export const SUCCESS_MESSAGE = $.__('Records were saved successfully.');
export const ERROR_MESSAGE = $.__('Uh oh...something went wrong saving your changes. Please try again later.');
export const REMOTE_HELPER_TEXT = `
	${ $.__('Because remote locations are handled differently, we keep the address associated with your selection') }.
	${ $.__('If this is incorrect, please update your BambooHR/TRAXPayroll locations') }.
`;

export const ITEM_TEXT_AND_METADATA: ItemTextAndMetadataObject = {
	employeeRecord: {
		getMetadata: getEmployeeRecordMappingItemMetadata,
		getText: getEmployeeRecordMappingItemText,
	},
	employeeWage: {
		getMetadata: getWageMetadata,
		getText: getWageItemToMapText,
	},
	companyLocation: {
		getMetadata: getCompanyLocationMetadata,
		getText: (metadata: CompanyLocationMetadata): string => metadata.name,
	},
	companyDeductions: {
		getMetadata: getCompanyDeductionsMetadata,
		getText: (metadata: CompanyDeductionsMetadata): string => $.__('%1$s: (Deduction Start: %2$s)', metadata.name, metadata.startDate),
	},
	employeeDeductions: {
		getMetadata: getEmployeeDeductionsMetadata,
		getText: (metadata: EmployeeDeductionsMetadata): string => $.__('%1$s: (Deduction Start: %2$s)', metadata.name, metadata.startDate),
	},
	companyDivision: {
		getMetadata: getCompanyDivisionMetadata,
		getText: (metadata: CompanyDivisionMetadata): string => metadata.divisionName,
	},
	companyDepartment: {
		getText: (metadata: CompanyDepartmentMetadata): string => metadata.departmentName,
		getMetadata: getCompanyDepartmentMetadata,
	},
	companyEmploymentStatus: {
		getMetadata: getCompanyEmploymentStatusMetadata,
		getText: (metadata: CompanyEmploymentStatusMetadata): string => metadata.employmentStatusName,
	},
	timeOff: {
		getMetadata: getTimeOffMetadata,
		getText: (metadata: TimeOffMetadata): string => metadata.name,
	},
};

// Functions
export function getStepData(url: SectionUrl): AxiosPromise {
	return Ajax.get(`/data_comparison/${ url }`);
}

export function saveStepData(url: SectionUrl, data: SaveDataObject): AxiosPromise {
	return Ajax.post(`/data_comparison/${ url }`, data);
}

export function getWizardStatus(): AxiosPromise {
	return Ajax.get('/payroll/deduction_sync/ajax/get_wizard_status');
}

export function handleRedirect(): void {
	redirect(REDIRECT_URL, REDIRECT_MSG, 'error');
}

export function handleSuccessRedirect(): void {
	redirect(REDIRECT_URL, SUCCESS_MESSAGE, 'success');
}

export function handleSaveSuccess(): void {
	showSlidedown(SUCCESS_MESSAGE, 'success');
}

export function handleSaveError(): void {
	showSlidedown(ERROR_MESSAGE, 'error');
}


export function getEmployeeRecordMappingItemMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): EmployeeRecordMappingMetadata {
	const employeeRecordMetadata: EmployeeRecordMappingMetadata = {
		employmentStatus: '',
		firstName: '',
		hireDate: '',
		jobTitle: '',
		lastName: '',
		terminationDate: '',
	};

	fields.forEach((field) => {
		const {
			fieldName,
			[database]: {
				displayValue,
			},
		} = field;

		if (fieldName === 'employment_status') {
			employeeRecordMetadata.employmentStatus = displayValue;
		}
		if (fieldName === 'First Name') {
			employeeRecordMetadata.firstName = displayValue;
		}
		if (fieldName === 'Hire Date') {
			employeeRecordMetadata.hireDate = displayValue;
		}
		if (fieldName === 'Job Title') {
			employeeRecordMetadata.jobTitle = displayValue;
		}
		if (fieldName === 'Last Name') {
			employeeRecordMetadata.lastName = displayValue;
		}
		if (fieldName === 'Termination Date') {
			employeeRecordMetadata.terminationDate = displayValue;
		}
	});

	return employeeRecordMetadata;
}

export function getEmployeeRecordMappingItemText(metadata: EmployeeRecordMappingMetadata): string {
	const {
		firstName,
		lastName,
		hireDate,
		terminationDate,
	} = metadata;

	const name = `${ firstName } ${ lastName }`;
	const endDate = terminationDate || $.__('Present');
	const employementRange = `${ hireDate } - ${ endDate }`;

	return `${ name } (${ $.__('Employed') } ${ employementRange })`;
}

export function getWageMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): WageMetadata {
	const wageMetadata: WageMetadata = {
		startDate: '',
		endDate: 'Present',
		payType: '',
		payRate: '',
		overtime: '',
	};

	fields.forEach((field: ResponseFieldObject): void => {
		const {
			fieldName,
			[database]: {
				displayValue,
				relatedValues,
			},
		} = field;
		let payRateField: RelatedValuesObject;

		switch (fieldName) {
			case 'Start Date':
				wageMetadata.startDate = displayValue;
				break;
			case 'End Date':
				if (displayValue) {
					wageMetadata.endDate = displayValue;
				}
				break;
			case 'Pay Rate':
				wageMetadata.payRate = displayValue;
				payRateField = relatedValues.find(relatedValue => relatedValue.fieldName === 'Pay Type');
				wageMetadata.payType = payRateField ? payRateField.value : '';
				break;
			case 'Overtime':
				wageMetadata.overtime = displayValue;
				break;
			default:
				// Do nothing
		}
	});

	return wageMetadata;
}

function getCompanyLocationMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): CompanyLocationMetadata {
	const companyLocationMetadata: CompanyLocationMetadata = {
		name: '',
		street1: '',
		city: '',
		state: '',
		zip: '',
		isRemote: false,
	};

	fields.forEach((field: ResponseFieldObject): void => {
		const {
			fieldName,
			[database]: {
				displayValue,
				value,
			},
		} = field;

		switch (fieldName) {
			case 'location_name':
				companyLocationMetadata.name = displayValue;
				break;
			case 'location_street_1':
				companyLocationMetadata.street1 = displayValue;
				break;
			case 'location_city':
				companyLocationMetadata.city = displayValue;
				break;
			case 'location_state':
				companyLocationMetadata.state = displayValue;
				break;
			case 'location_zip':
				companyLocationMetadata.zip = displayValue;
				break;
			case 'Remote Location':
				companyLocationMetadata.isRemote = !!value;
				break;
			default:
			// Do nothing
		}
	});

	return companyLocationMetadata;
}

export function getCompanyDeductionsMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): CompanyDeductionsMetadata {
	const companyDeductionsMetadata: CompanyDeductionsMetadata = {
		name: '',
		startDate: '',
		endDate: '',
		deductionTypeId: '',
	};

	fields.forEach((field: ResponseFieldObject): void => {
		const {
			fieldName,
			[database]: {
				displayValue,
			},
		} = field;

		switch (fieldName) {
			case 'deduction_name':
				companyDeductionsMetadata.name = displayValue;
				break;
			case 'start_date':
				companyDeductionsMetadata.startDate = displayValue;
				break;
			case 'end_date':
				companyDeductionsMetadata.endDate = displayValue;
				break;
			case 'deduction_type_id':
				companyDeductionsMetadata.deductionTypeId = displayValue;
				break;
			default:
			// Do nothing
		}
	});

	return companyDeductionsMetadata;
}

export function getEmployeeDeductionsMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): EmployeeDeductionsMetadata {
	const employeeDeductionsMetadata: EmployeeDeductionsMetadata = {
		name: '',
		startDate: '',
		endDate: '',
		employeePays: '',
		employerPays: '',
		deductionTypeId: '',
	};

	fields.forEach((field: ResponseFieldObject): void => {
		const {
			fieldName,
			[database]: {
				displayValue,
			},
		} = field;

		switch (fieldName) {
			case 'employee_deduction_name':
				employeeDeductionsMetadata.name = displayValue;
				break;
			case 'employee_deduction_start_date':
				employeeDeductionsMetadata.startDate = displayValue;
				break;
			case 'employee_deduction_end_date':
				employeeDeductionsMetadata.endDate = displayValue;
				break;
			case 'deduction_type_id':
				employeeDeductionsMetadata.deductionTypeId = displayValue;
				break;
			case 'employee_pays':
				employeeDeductionsMetadata.employeePays = displayValue;
				break;
			case 'company_pays':
				employeeDeductionsMetadata.employerPays = displayValue;
				break;
			default:
			// Do nothing
		}
	});

	return employeeDeductionsMetadata;
}

function getCompanyDivisionMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): CompanyDivisionMetadata {
	const divisionNameField = fields.find(field => field.fieldName === 'division_name');

	return {
		divisionName: divisionNameField ? divisionNameField[database].displayValue : '',
	};
}

function getCompanyDepartmentMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): CompanyDepartmentMetadata {
	const companyDepartmentMetadata: CompanyDepartmentMetadata = {
		departmentName: '',
	};

	fields.forEach((field: ResponseFieldObject): void => {
		const {
			fieldName,
			[database]: {
				displayValue,
			},
		} = field;

		switch (fieldName) {
			case 'department_name':
				companyDepartmentMetadata.departmentName = displayValue;
				break;
			default:
				// Do nothing.
		}
	});

	return companyDepartmentMetadata;
}

function getCompanyEmploymentStatusMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): CompanyEmploymentStatusMetadata {
	const companyEmploymentStatusMetadata: CompanyEmploymentStatusMetadata = {
		employmentStatusName: '',
		acaStatus: '',
	};

	fields.forEach((field: ResponseFieldObject): void => {
		const {
			fieldName,
			[database]: {
				displayValue,
			},
		} = field;

		switch (fieldName) {
			case 'employment_status_name':
				companyEmploymentStatusMetadata.employmentStatusName = displayValue;
				break;
			case 'aca_status':
				companyEmploymentStatusMetadata.acaStatus = displayValue;
				break;
			default:
			// Do nothing
		}
	});

	return companyEmploymentStatusMetadata;
}

function getTimeOffMetadata(fields: Array<ResponseFieldObject>, database: DatabaseString): TimeOffMetadata {
	const nameField = fields.find(field => field.fieldName === 'time_off_name');

	return {
		name: nameField ? nameField[database].displayValue : '',
	};
}

function getWageItemToMapText(wageMetadata: WageMetadata): string {
	const {
		startDate,
		endDate,
		payRate,
	} = wageMetadata;
	return `${ startDate } - ${ endDate }: ${ payRate }`;
}

function getItemToMapData(
	record: ResponseRecordObject,
	stepType: SectionType,
	value: string,
	originalDatabase: DatabaseString,
	groupId: string,
	createValue: CreateValueObject,
	bambooRowId: number,
	traxRowId: number
): ItemToMap<WageMetadata|CompanyLocationMetadata|CompanyDeductionsMetadata|CompanyEmploymentStatusMetadata|CompanyDepartmentMetadata|CompanyDivisionMetadata|EmployeeRecordMappingMetadata|TimeOffMetadata> {
	const {
		getMetadata,
		getText,
	} = ITEM_TEXT_AND_METADATA[stepType];
	const {
		groupData: {
			clientId,
			metadata,
		},
	} = record;

	const formattedMetadata = getMetadata(metadata, originalDatabase);

	return {
		text: getText(formattedMetadata),
		metadata: formattedMetadata,
		selectedRecordId: null,
		group: groupId,
		value,
		originalDatabase,
		createValue,
		bambooRowId,
		traxRowId,
		clientId,
	};
}

export function isRemoteCompanyLocation(fields: Array<FieldObject>): boolean {
	let isRemoteCompanyLocation = false;

	fields.forEach((field: FieldObject) => {
		const {
			fieldName,
			bamboo: {
				value,
			},
		} = field;

		if (fieldName === 'Remote Location' && value) {
			isRemoteCompanyLocation = true;
		}
	});

	return isRemoteCompanyLocation;
}

export function normalizeRecords(records: Array<ResponseRecordObject>, clients: Array<ResponseClientObject>, stepType: SectionType): NormalizeRecordsReturnObject {
	const returnObject = {
		clients: {
			byId: {},
			allIds: [],
		},
		groups: {
			byId: {},
			allIds: [],
		},
		subGroups: {
			byId: {},
			allIds: [],
		},
		fields: {
			byId: {},
			allIds: [],
		},
		bambooNeedsTrax: {
			byId: {},
			allIds: [],
		},
		traxNeedsBamboo: {
			byId: {},
			allIds: [],
		},
		selectedMappingItems: [],
	};
	const isSubGroupedStep = SUB_GROUPED_STEPS.indexOf(stepType) > -1;
	const checkForItemsToMap = MAPPING_STEPS.indexOf(stepType) > -1;

	records.forEach((record: ResponseRecordObject, index: number): void => {
		const {
			groupData,
			groupData: {
				bamboo: {
					id: bambooId,
					groupId: bambooGroupId,
				},
				trax: {
					id: traxId,
					groupId: traxGroupId,
				},
				clientId,
				createValue,
				metadata,
			},
			fields,
		} = record;

		const groupId = `${ bambooGroupId }_${ traxGroupId }`;
		const itemToMapId = `${ groupId }_${ index }`;
		const groupFields: Array<string> = [];

		if (traxId === null && checkForItemsToMap) {
			returnObject.bambooNeedsTrax.allIds.push(itemToMapId);
			returnObject.bambooNeedsTrax.byId[itemToMapId] = getItemToMapData(record, stepType, itemToMapId, 'bamboo', groupId, createValue, bambooId, traxId);
		} else if (bambooId === null && checkForItemsToMap) {
			returnObject.traxNeedsBamboo.allIds.push(itemToMapId);
			returnObject.traxNeedsBamboo.byId[itemToMapId] = getItemToMapData(record, stepType, itemToMapId, 'trax', groupId, createValue, bambooId, traxId);
		} else if (checkForItemsToMap && stepType === 'timeOff') {
			// Separate joined record into two mappable items so that mappings can be changed if desired.
			const bambooItemToMapId = `${ itemToMapId }_bambooSplit`;
			const traxItemToMapId = `${ itemToMapId }_traxSplit`;
			const joinedCreateValue = createValue as CreateValueObjectJoined;
			const bambooCreateValue = joinedCreateValue.bamboo;
			const traxCreateValue = joinedCreateValue.trax;
			returnObject.bambooNeedsTrax.allIds.push(bambooItemToMapId);
			returnObject.bambooNeedsTrax.byId[bambooItemToMapId] = getItemToMapData(record, stepType, bambooItemToMapId, 'bamboo', groupId, traxCreateValue, bambooId, null);
			returnObject.traxNeedsBamboo.allIds.push(traxItemToMapId);
			returnObject.traxNeedsBamboo.byId[traxItemToMapId] = getItemToMapData(record, stepType, traxItemToMapId, 'trax', groupId, bambooCreateValue, null, traxId);

			// Initially maintain that they are joined.
			returnObject.bambooNeedsTrax.byId[bambooItemToMapId].selectedRecordId = traxItemToMapId;
			returnObject.selectedMappingItems.push(traxItemToMapId);
		} else {
			const isRemoteLocation = stepType === 'companyLocation' ? isRemoteCompanyLocation(metadata) : false;

			fields.forEach((field: ResponseFieldObject, index: number) => {
				const {
					fieldName,
				} = field;
				const fieldId = `${ groupId }_${ fieldName }_${ itemToMapId }_${ index }`.replace(' ', '_');
				field.id = fieldId;
				field.selectedDatabase = null;
				field.group = groupId;
				field.subGroup = itemToMapId;
				field.subGroupMetadata = metadata;
				field.manualUpdate = false;
				field.clientId = clientId;

				if (isRemoteLocation) {
					if (field.fieldName !== 'location_name') {
						field.bamboo.displayValue = $.__('Remote Location');
					}
					field.selectSubGroup = true;

					if (index === 0) {
						field.helperText = REMOTE_HELPER_TEXT;
					}
				}

				returnObject.fields.byId[fieldId] = field;
				returnObject.fields.allIds.push(fieldId);
				groupFields.push(fieldId);
			});
		}

		groupData.fields = groupFields;
		returnObject.groups.byId[groupId] = groupData;
		if (returnObject.groups.allIds.indexOf(groupId) === -1) {
			returnObject.groups.allIds.push(groupId);
		}

		if (isSubGroupedStep) {
			returnObject.subGroups.byId[itemToMapId] = groupData;
			if (returnObject.subGroups.allIds.indexOf(itemToMapId) === -1) {
				returnObject.subGroups.allIds.push(itemToMapId);
			}
		}
	});

	clients.forEach((client: ResponseClientObject) => {
		const {
			clientId,
		} = client;

		returnObject.clients.byId[clientId] = client;
		returnObject.clients.allIds.push(clientId);
	});

	return returnObject;
}

export function formatDataForSave(fields: FieldsNormalized, groups: GroupsNormalized, subGroups: GroupsNormalized, sectionType: SectionType): SaveDataObject {
	const {
		allIds: allFieldIds,
		byId: allFieldsById,
	} = fields;
	const useSubGroup = (SUB_GROUPED_STEPS.indexOf(sectionType) > -1) && subGroups;
	const allGroupsById = useSubGroup ? subGroups.byId : groups.byId;
	const fieldsArray: Array<SaveFieldObject> = [];

	allFieldIds.forEach((fieldId) => {
		const field = allFieldsById[fieldId];
		const {
			id,
			selectedDatabase,
			fieldName,
			group,
			subGroup,
			canEdit,
			clientId,
		} = field;

		if (selectedDatabase && canEdit) {
			const {
				value,
				relatedValues,
			} = field[selectedDatabase];
			const {
				bamboo: {
					id: bambooRowId,
				},
				trax: {
					id: traxRowId,
				},
			} = allGroupsById[useSubGroup ? subGroup : group];

			fieldsArray.push({
				id,
				systemChosen: selectedDatabase,
				clientId,
				bambooRowId,
				traxRowId,
				fieldName,
				value,
				relatedValues,
			});

		}
	});

	return {
		fields: fieldsArray,
	};
}

export function getMappingItemSaveObject(
	item: ItemToMap<WageMetadata|CompanyLocationMetadata|CompanyDeductionsMetadata>,
	itemId: string,
	originalDatabase: DatabaseString,
	clientId: number,
	oppositeItemsToMapById: ItemsToMapById,
	sectionType: SectionType
): SaveFieldObject {
	const {
		selectedRecordId,
		createValue,
		bambooRowId,
		traxRowId,
	} = item;
	const fieldName = MAPPING_FIELD_NAMES[sectionType];
	let tempTraxRowId: number = traxRowId;
	let tempBambooRowId: number = bambooRowId;
	let value: CreateValueObject;
	let systemChosen: DatabaseString = 'bamboo';

	if (selectedRecordId === 'addNew') {
		systemChosen = originalDatabase;
		value = createValue;
	} else if (originalDatabase === 'trax') {
		if (selectedRecordId.startsWith(ADDITIONAL_BAMBOO_OPTION_ID_KEY)) {
			tempBambooRowId = Number(selectedRecordId.replace(ADDITIONAL_BAMBOO_OPTION_ID_KEY, ''));
		} else {
			tempBambooRowId = oppositeItemsToMapById[selectedRecordId].bambooRowId;
		}

		value = tempBambooRowId;
	} else {
		value = bambooRowId;
		tempTraxRowId = oppositeItemsToMapById[selectedRecordId].traxRowId;
	}

	return {
		id: itemId,
		systemChosen,
		clientId,
		bambooRowId: tempBambooRowId,
		traxRowId: tempTraxRowId,
		fieldName,
		value,
	};
}

export function formatMappingDataForSave(
	bambooNeedsTrax: NormalizedItemsToMap,
	traxNeedsBamboo: NormalizedItemsToMap,
	sectionType: SectionType
): SaveDataObject {
	const {
		allIds: allBambooNeedsTraxIds,
		byId: bambooNeedsTraxById,
	} = bambooNeedsTrax;
	const {
		allIds: allTraxNeedsBambooIds,
		byId: traxNeedsBambooById,
	} = traxNeedsBamboo;
	const fieldsArray: Array<SaveFieldObject> = [];

	allBambooNeedsTraxIds.forEach((bambooNeedsTraxId) => {
		const field = bambooNeedsTraxById[bambooNeedsTraxId];
		const {
			selectedRecordId,
			clientId,
		} = field;

		if (!selectedRecordId) {
			return;
		}

		fieldsArray.push(getMappingItemSaveObject(field, bambooNeedsTraxId, 'bamboo', clientId, traxNeedsBambooById, sectionType));
	});

	allTraxNeedsBambooIds.forEach((traxNeedsBambooId) => {
		const field = traxNeedsBambooById[traxNeedsBambooId];
		const {
			selectedRecordId,
			clientId,
		} = field;

		if (!selectedRecordId) {
			return;
		}

		fieldsArray.push(getMappingItemSaveObject(field, traxNeedsBambooId, 'trax', clientId, bambooNeedsTraxById, sectionType));
	});

	return {
		fields: fieldsArray,
	};
}

export function getWageMetadataFromOverlapError(fields: Array<WageOverlapDateFieldObject>, index: number): WageMetadata {
	const wageMetadata: WageMetadata = {
		id: '',
		startDate: '',
		endDate: 'Present',
		payType: '',
		payRate: '',
		overtime: '',
	};

	fields.forEach((field: WageOverlapDateFieldObject): void => {
		const {
			fieldName,
			displayValue,
		} = field;

		switch (fieldName) {
			case 'Start Date':
				wageMetadata.startDate = displayValue;
				break;
			case 'End Date':
				if (displayValue) {
					wageMetadata.endDate = displayValue;
				}
				break;
			case 'Pay Rate':
				wageMetadata.payRate = displayValue;
				break;
			case 'Pay Type':
				wageMetadata.payType = displayValue;
				break;
			case 'Overtime':
				wageMetadata.overtime = displayValue;
				break;
			default:
			// Do nothing
		}
	});
	wageMetadata.id = `${ wageMetadata.startDate }_${ wageMetadata.endDate }_${ index }`;

	return wageMetadata;
}

export function getCompanyDeductionsMetadataFromOverlap(fields: Array<WageOverlapDateFieldObject>, clientId: number): CompanyDeductionsMetadata {
	const companyDeductionsMetadata: CompanyDeductionsMetadata = {
		name: '',
		id: '',
		deductionTypeId: '',
		bambooRowId: null,
		traxRowId: null,
		startDate: '',
		endDate: null,
		clientId,
	};

	fields.forEach((field: WageOverlapDateFieldObject) => {
		const {
			fieldName,
			displayValue,
		} = field;

		switch (fieldName) {
			case 'deduction_name':
				companyDeductionsMetadata.name = displayValue;
				break;
			case 'traxRowId':
				companyDeductionsMetadata.id = displayValue;
				companyDeductionsMetadata.traxRowId = displayValue;
				break;
			case 'deduction_type_id':
				companyDeductionsMetadata.deductionTypeId = displayValue;
				break;
			case 'bambooRowId':
				companyDeductionsMetadata.bambooRowId = displayValue;
				break;
			case 'start_date':
				companyDeductionsMetadata.startDate = displayValue;
				break;
			case 'end_date':
				companyDeductionsMetadata.endDate = displayValue;
				break;
			default:
			// Do nothing
		}
	});

	return companyDeductionsMetadata;
}

export function getEmployeeDeductionsMetadataFromOverlap(fields: Array<WageOverlapDateFieldObject>, clientId: number): EmployeeDeductionsMetadata {
	const employeeDeductionsMetadata: EmployeeDeductionsMetadata = {
		name: '',
		id: '',
		deductionTypeId: '',
		bambooRowId: null,
		traxRowId: null,
		startDate: '',
		endDate: null,
		employeePays: '',
		employerPays: '',
		clientId,
	};

	fields.forEach((field: WageOverlapDateFieldObject) => {
		const {
			fieldName,
			displayValue,
		} = field;

		switch (fieldName) {
			case 'employee_deduction_name':
				employeeDeductionsMetadata.name = displayValue;
				break;
			case 'deduction_type_id':
				employeeDeductionsMetadata.deductionTypeId = displayValue;
				break;
			case 'employee_deduction_start_date':
				employeeDeductionsMetadata.startDate = displayValue;
				break;
			case 'employee_deduction_end_date':
				employeeDeductionsMetadata.endDate = displayValue;
				break;
			case 'employee_pays':
				employeeDeductionsMetadata.employeePays = displayValue;
				break;
			case 'company_pays':
				employeeDeductionsMetadata.employerPays = displayValue;
				break;
			case 'bambooRowId':
				employeeDeductionsMetadata.bambooRowId = displayValue;
				break;
			case 'traxRowId':
				employeeDeductionsMetadata.id = displayValue;
				employeeDeductionsMetadata.traxRowId = displayValue;
				break;
			default:
			// Do nothing
		}
	});

	return employeeDeductionsMetadata;
}

export function getErrorIds(errorFields: Array<ErrorFieldObject>, type: SectionType): {genericErrorFields: Array<string>, overlappingErrorFields: Array<OverlappingErrorFields>} {
	const genericErrorFields = [];
	const overlappingErrorFields = [];

	errorFields.forEach((field: ErrorFieldObject) => {
		const {
			id,
			code,
			metadata,
			clientId,
		} = field;

		if (code === 3) {
			const formattedMetadata = metadata.map((record, index) => {
				if (type === 'companyDeductions') {
					return getCompanyDeductionsMetadataFromOverlap(record, clientId);
				}
				if (type === 'employeeDeductions') {
					return getEmployeeDeductionsMetadataFromOverlap(record, clientId);
				}
				return getWageMetadataFromOverlapError(record, index);
			});
			overlappingErrorFields.push({
				id,
				metadata: formattedMetadata,
			});
		} else {
			genericErrorFields.push(id);
		}
	});

	return {
		genericErrorFields,
		overlappingErrorFields,
	};
}

export function getIsFieldDisabled(field: FieldObject, database: DatabaseString): boolean {
	const {
		value,
		disabled,
	} = field[database];

	return value === null || disabled;
}

export function getParentFieldsById(
	id: string,
	allFieldIds: Array<string>,
	allBambooNeedsTraxIds: Array<string>,
	allTraxNeedsBambooIds: Array<string>
): string {
	if (allFieldIds.indexOf(id) > -1) {
		return 'fields';
	}

	if (allBambooNeedsTraxIds.indexOf(id) > -1) {
		return 'bambooNeedsTrax';
	}

	if (allTraxNeedsBambooIds.indexOf(id) > -1) {
		return 'traxNeedsBamboo';
	}
}

export function getFilteredRecordIdsByClientId(normalizedRecords: NormalizedItemsToMap|FieldsNormalized, clientId: number): Array<string> {
	return normalizedRecords.allIds.filter((recordId) => {
		const record = normalizedRecords.byId[recordId];
		return record?.clientId === clientId;
	});
}
