import { makeStyles, createStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, typography, constructs }) => {
	return createStyles({
		boxLabel: {
			height: '110px',
		},
		mfaLevelOption: {
			marginRight: '16px',
		},
		mfaLevelOptionContainer: {
			marginTop: ifFeature('encore', '12px', '16px'),
			marginBottom: ifFeature('encore', '22px', '15px'),
		},
		mfaSettingsContentHeader: {
			fontSize: typography.fabricFontSize('biggie'),
			fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightBold),
			marginTop: ifFeature('encore', '0', '22px'),
			color: ifFeature('encore', constructs.text.neutral.xStrong),
		},
		mfaStartDateWrapper: {
			marginTop: '23px',
		},
		dividerLine: {
			padding: 0,
			marginTop: '24px',
			marginBottom: '24px',
			borderTop: `solid 1px ${palette.gray[300]}`,
		},
		selectedNote: {
			fontSize: typography.fabricFontSize('teenie'),
			marginTop: '12px',
			lineHeight: typography.fabricLineHeight('teenie'),
		},
		dateContainer: {
			marginTop: '26px',
		},
		optInNoteContainer: {
			padding: '10px 0 8px',
		},
		optInNote: {
			fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightBold),
			color: ifFeature('encore', constructs.text.neutral.xStrong),
		},
	});
});
