import { Modal } from 'modal-legacy';


import './preview-email-modal.styl';

export function PreviewEmailModal(props) {
	const { content, isVisible, onCloseModal, title } = props;
	return (
		<Modal
			alternativeAction={ null }
			isOpen={ isVisible }
			onClose={ onCloseModal }
			primaryAction={ onCloseModal }
			primaryActionText={ $.__('Done') }
			title={ title }
			type="medium"
		>
			<div className="EnpsEmailPreviewModal">
				<div className="EnpsEmailPreviewModal__blocker"></div>
				{ content && (
					<div dangerouslySetInnerHTML={ {__html: content} }></div>
				) }
			</div>
		</Modal>
	);
}
