import {
	Fragment,
} from 'react';


const nameMap = {
	employee: $.__('Employee'),
	manager: $.__('Manager'),
	rating: $.__('Rating'),
};

export default function NameCell({
	question,
	type,
}) {
	const {
		questionNumber,
	} = question;

	return (
		<Fragment>
			<small>
				{ nameMap[type] }
			</small>
			<br />
			{ question[`${ type }Question`] }&nbsp;
		</Fragment>
	);
}
