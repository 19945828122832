import {
	ModalConnector,
} from './components/modal-connector';
import {
	CompanyTaxSettingsContainer,
} from './store/company-tax-settings-container';

interface CompanyTaxSettingsModalProps {
	clientEin: string;
	clientId: number;
	clientName: string;
}

export function CompanyTaxSettingsModal(props: CompanyTaxSettingsModalProps) {
	const {
		clientEin,
		clientId,
		clientName,
	} = props;
	return (
		<CompanyTaxSettingsContainer
			clientEin={ clientEin }
			clientId={ clientId }
			clientName={ clientName }
			isGlobal={ true }
		>
			<ModalConnector />
		</CompanyTaxSettingsContainer>
	);
}
