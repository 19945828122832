export enum PayFrequencyEnumContract {
	daily = 'daily',
	weekly = 'weekly',
	everyOtherWeek = 'bi-weekly',
	twiceAMonth = 'semi-monthly',
	monthly = 'monthly',
	quarterly = 'quarterly',
	twiceAYear = 'semi-annually',
	yearly = 'annually',
}
