/**
 * Will decide whether the end of year message should be displayed
 * Current form looks at the current month.
 * If it is Nov or Dec it will return true.
 * @returns {boolean} decision if should show message
 */
export function shouldShowEndOfYearMessage() {
	const overrideParams = new URLSearchParams(window.location.search);
	const overrideSave = parseInt(overrideParams.get('OVERRIDE_MONTH') ?? '-1', 10);
	const currentMonth = overrideSave === -1 ? (new Date()).getMonth() : overrideSave;
	// Month from JS date is index 0, so think -1 for the number you expect
	return currentMonth >= 10;
}
