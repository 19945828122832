import {
	Flex,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import c from 'classnames';
import { useEffect } from 'react';

import { SectionStyledBox } from '../../../section-styled-box';
import { initializeEmployeeTaxStates } from '../../utils';

export function EmployeeTaxStates(props) {
	useEffect(initializeEmployeeTaxStates, []);

	const {
		showResidentTaxState,
		companyTaxStates,
		residentTaxState,
		unemploymentTaxState,
		usesSectionComponent,
	} = props;

	const residentTaxStateSelectClasses = c(
		'xlong js-employee_resident_tax_state requiredWhenVisible EmployeeTaxStates__select',
		{ 'hidden': !showResidentTaxState }
	);

	const residentTaxStateFieldBoxClasses = c(
		'fieldBox js-employee_resident_tax_states_box required',
		{ 'hidden': !showResidentTaxState }
	);

	return ifFeature('encore',
		(
			<SectionStyledBox usesSectionComponent={ usesSectionComponent }>
				<fieldset className="EmployeeTaxStates clearfix js-employee_tax_states_container" id="">
					<legend className="baseColor fab-FormSection__legend">
						{ $.__('Employee Tax States') }
						{ ' ' }
						<span className="legendNote legendNote--inline">{ $.__(`(Employees won't see this section)`) }</span>
					</legend>

					<div className="fieldRow fab-FormRow">
						<div className="fieldBox js-employee_tax_states_box required">

							<label className="fab-Label" htmlFor="companyTaxStates">
								{ showResidentTaxState ? $.__('Work Tax State') : $.__('Employee Tax State') }
							</label>

							<div className="fieldDiv">
								<ba-select
									class="xlong js-employee_tax_states requiredWhenVisible EmployeeTaxStates__select"
									name="companyTaxStates[]"
									style={ { display: 'none' } }
									width="5"
								>
									{ companyTaxStates.map((taxState) => {
										return (
											<ba-option
												key={ taxState.state }
												selected={ taxState.selected ? 'selected' : null }
												value={ taxState.state }
											>
												{ taxState.state }
											</ba-option>
										);
									}) }
								</ba-select>
							</div>
						</div>

						<div className={ residentTaxStateFieldBoxClasses }>
							<label className="fab-Label" htmlFor="residentTaxState">{ $.__('Resident Tax State') }</label>
							<div className="fieldDiv">
								<ba-select
									class={ residentTaxStateSelectClasses }
									name="residentTaxState"
									width="5"
								>
									{ companyTaxStates.map((taxState) => {
										return (
											<ba-option
												key={ taxState.state }
												selected={ taxState.state === residentTaxState ? 'selected' : null }
												value={ taxState.state }
											>
												{ taxState.state }
											</ba-option>
										);
									}) }
								</ba-select>
							</div>
						</div>

						<div className="fieldBox js-employee_unemployment_state_box required">
							<label className="fab-Label" htmlFor="unemploymentTaxState">{ $.__('Unemployment State') }</label>
							<div className="fieldDiv">
								<ba-select
									class="js-employee_unemployment_state requiredWhenVisible EmployeeTaxStates__select"
									name="unemploymentTaxState"
									width="5"
								>
									{ companyTaxStates.map((taxState) => {
										return (
											<ba-option
												key={ taxState.state }
												selected={ taxState.state === unemploymentTaxState ? 'selected' : null }
												value={ taxState.state }
											>
												{ taxState.state }
											</ba-option>
										);
									}) }
								</ba-select>
							</div>
						</div>
					</div>
				</fieldset>
			</SectionStyledBox>
		),
		(
			<fieldset className="EmployeeTaxStates clearfix js-employee_tax_states_container fab-FormSection" id="">
				<legend className="baseColor fab-FormSection__legend">
					{ $.__('Employee Tax States') }
					{ ' ' }
					<span className="legendNote legendNote--inline">{ $.__(`(Employees won't see this section)`) }</span>
				</legend>

				<div className="fieldRow fab-FormRow">
					<div className="fieldBox js-employee_tax_states_box required">

						<label className="fab-Label" htmlFor="companyTaxStates">
							{ showResidentTaxState ? $.__('Work Tax State') : $.__('Employee Tax State') }
						</label>

						<div className="fieldDiv">
							<ba-select
								class="xlong js-employee_tax_states requiredWhenVisible EmployeeTaxStates__select"
								name="companyTaxStates[]"
								style={ { display: 'none' } }
								width="5"
							>
								{ companyTaxStates.map((taxState) => {
									return (
										<ba-option
											key={ taxState.state }
											selected={ taxState.selected ? 'selected' : null }
											value={ taxState.state }
										>
											{ taxState.state }
										</ba-option>
									);
								}) }
							</ba-select>
						</div>
					</div>

					<div className={ residentTaxStateFieldBoxClasses }>
						<label className="fab-Label" htmlFor="residentTaxState">{ $.__('Resident Tax State') }</label>
						<div className="fieldDiv">
							<ba-select
								class={ residentTaxStateSelectClasses }
								name="residentTaxState"
								width="5"
							>
								{ companyTaxStates.map((taxState) => {
									return (
										<ba-option
											key={ taxState.state }
											selected={ taxState.state === residentTaxState ? 'selected' : null }
											value={ taxState.state }
										>
											{ taxState.state }
										</ba-option>
									);
								}) }
							</ba-select>
						</div>
					</div>

					<div className="fieldBox js-employee_unemployment_state_box required">
						<label className="fab-Label" htmlFor="unemploymentTaxState">{ $.__('Unemployment State') }</label>
						<div className="fieldDiv">
							<ba-select
								class="js-employee_unemployment_state requiredWhenVisible EmployeeTaxStates__select"
								name="unemploymentTaxState"
								width="5"
							>
								{ companyTaxStates.map((taxState) => {
									return (
										<ba-option
											key={ taxState.state }
											selected={ taxState.state === unemploymentTaxState ? 'selected' : null }
											value={ taxState.state }
										>
											{ taxState.state }
										</ba-option>
									);
								}) }
							</ba-select>
						</div>
					</div>
				</div>
			</fieldset>
		)
	);
}
