import { CheckboxItem } from 'form-fields.react';
import { Agreement } from '../../types';

export const SAFE_HARBOR_ITEMS: CheckboxItem[] = [
	{
		text: $.__('Use Payroll Safe Harbor Match'),
		value: Agreement.yes,
		note: $.__('The company matches employee contributions dollar-for-dollar up to 3%% of compensations, and 50 cents on the dollar of contributions between 3–5%% of compensation.'),
	},
];
