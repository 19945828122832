import { Fragment } from 'react';

import './aca-migration-modal-heading.styl';

export function Heading(props) {
	const {
		subText,
		text,
		useDivider,
	} = props;
	return (
		<Fragment>
			<h3>{ text }</h3>
			<p>{ subText }</p>
			{ (useDivider) && <div className="AcaMigrationModalTitle__divider"></div> }
		</Fragment>
	);
}
