module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push(''); if (is2020Release) { 
p.push('  '); if (isEncore) { 
p.push('     <div class="FedMessage2020">   <span class="FedMessage2020__icon">    <ba-icon     encore-color="info-medium"     encore-name="circle-info-solid"     encore-size="16"    ></ba-icon>   </span>    <p class="FedMessage2020__text">    <span>',window.microTemplate.encodeHtml($.__('The IRS W4 withholding form has changed')),'.</span>    <button class="fab-TextButton fab-TextButton--inline fab-link js-toggle2020W4" type="button">',window.microTemplate.encodeHtml($.__('Make changes using the new form fields')),'.</button>   </p>  </div>  '); } else { 
p.push('     <div class="FedMessage2020">   <span class="FedMessage2020__icon">    <ba-icon name="fab-info-circle-15x15"></ba-icon>   </span>    <p class="FedMessage2020__text">    <span>',window.microTemplate.encodeHtml($.__('The IRS W4 withholding form has changed')),'.</span>    <button class="fab-TextButton fab-TextButton--inline fab-link js-toggle2020W4" type="button">',window.microTemplate.encodeHtml($.__('Make changes using the new form fields')),'.</button>   </p>  </div>  '); } 
p.push(' '); } 
p.push('  <div class="fieldRow fab-FormRow">  <div class="fieldBox">   <label class="fab-Label ',window.microTemplate.encodeHtml(is2020Release ? 'fab-Label--disabled' : ''),' ">',window.microTemplate.encodeHtml($.__('Filing Status')),'</label>   <div class="fieldDiv">    <ba-select name="fed[status]" style="width: 135px;" width="5" class="js-wholdings-federal-status pi-wholdings-federal-status" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'>     <ba-option value="Single">',window.microTemplate.encodeHtml($.__('Single')),'</ba-option>     <ba-option value="Married">',window.microTemplate.encodeHtml($.__('Married')),'</ba-option>    </ba-select>   </div>  </div>  <div class="fieldBox ExemptionsCheckbox">   <label class="fab-Label ',window.microTemplate.encodeHtml(is2020Release ? 'fab-Label--disabled' : ''),' ">',window.microTemplate.encodeHtml($.__('Exemptions')),'</label>   <div class="fieldDiv">    <input type="text" name="fed[dependents]" class="js-wholdings-federal-dependents xxxshort fab-TextInput fab-TextInput--width1" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),' />   </div>  </div> </div>  <div class="fieldRow fab-FormRow ',window.microTemplate.encodeHtml(hasTrax ? '' : 'hidden'),'">  <div class="fieldBox fab-FormField mlSpaced fab-Checkbox">   <input type="checkbox" class="js-headOfHousehold fab-Checkbox__input" name="fed[headOfHousehold]" id="headOfHousehold" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),' />   <label for="headOfHousehold" class="pi-head-of-household-inline fab-Checkbox__label">',window.microTemplate.encodeHtml($.__('Head of Household')),'</label>   <span class="pi-head-of-household js-headOfHouseholdText fab-Checkbox__note">    (',window.microTemplate.encodeHtml($.__('Select to use single rate but file as married')),')    '); if (isEncore) { 
p.push('    <span     data-pop-title="Head of Household"     data-pop-content="The Head of Household status generally applies if you are not married and have paid more than half the cost of maintaining a home for yourself and a qualifying person."     data-pop-placement="bottom"     style="margin-left: 4px;"    >     <ba-icon      encore-color="neutral-weak"      encore-name="circle-question-regular"      encore-size="16"     ></ba-icon>    </span>    '); } else { 
p.push('    <span class="semibold pi-withhold-help"          data-pop-title="Head of Household"          data-pop-content="The Head of Household status generally applies if you are not married and have paid more than half the cost of maintaining a home for yourself and a qualifying person."          data-pop-placement="bottom"          data-pop-content-css-override=\'{"maxWidth":"230px"}\'    >?    </span>    '); } 
p.push('   </span>   </div> </div>  <div class="fieldRow fab-FormRow">  <label class="fab-FormField">',window.microTemplate.encodeHtml($.__('Additional Withholding')),'</label>  <div class="fieldBox fab-FormField">   <div class="fieldDiv">   '); if (isEncore) { 
p.push('    <div class="fab-TextToggle ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW ? 'fab-TextToggle--disabled' : ''),'">     <input type="radio" id="fedWithholdExtraType-1" class="fab-TextToggle__input js-fedWithholdExtraType-1" name="fed[withholdExtraType]" value="$" onchange="debugger; BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-federal-extra\')" data-currency-toggle="$" checked data-flag="currency" data-field-class="js-wholding-federal-extra" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'>     <label for="fedWithholdExtraType-1" class="fab-TextToggle__label">$</label>     <input type="radio" id="fedWithholdExtraType-2" class="fab-TextToggle__input js-fedWithholdExtraType-2" name="fed[withholdExtraType]" value="%" onchange="debugger; BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-federal-extra\')" data-currency-toggle="%" data-flag="percent" data-field-class="js-wholding-federal-extra" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'>     <label for="fedWithholdExtraType-2" class="fab-TextToggle__label">%</label>     <span class="fab-TextToggle__handle"></span>    </div>    '); } else { 
p.push('    <div class="bhrToggleSmall bhrToggle--btn ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW ? 'bhrToggle--btn--disabled' : ''),'">     <input type="radio" id="fedWithholdExtraType-1" class="bhrToggle--btn-1 js-fedWithholdExtraType-1" name="fed[withholdExtraType]" value="$" onchange="BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-federal-extra\')" data-currency-toggle="$" checked data-flag="currency" data-field-class="js-wholding-federal-extra" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'>     <label for="fedWithholdExtraType-1" class="bhrToggle__label bhrToggle--btn__label">$</label>     <input type="radio" id="fedWithholdExtraType-2" class="bhrToggle--btn-2 js-fedWithholdExtraType-2" name="fed[withholdExtraType]" value="%" onchange="BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-federal-extra\')" data-currency-toggle="%" data-flag="percent" data-field-class="js-wholding-federal-extra" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'>     <label for="fedWithholdExtraType-2" class="bhrToggle__label bhrToggle--btn__label">%</label>     <span class="bhrToggle__handle bhrToggle--btn__handle brandedToggle"></span>    </div>    '); } 
p.push('   </div>  </div>  <div class="fieldBox fab-FormField">   <div class="fieldDiv">    <input type="text" name="fed[withholdExtraValue]" class="js-wholding-federal-extra xxshort fab-TextInput fab-TextInput--width3" value="0.00" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'/>   </div>  </div> </div>  <div class="fieldRow fab-FormRow ',window.microTemplate.encodeHtml(hasTrax ? '' : 'hidden'),'">  <div class="fieldBox fab-FormField fab-Checkbox mlSpaced">   <input type="checkbox" class="js-fedWithholdingTaxExempt fab-Checkbox__input" name="fed[taxExempt]" id="fedWithholdingTaxExempt" ',window.microTemplate.encodeHtml(federal === PERM_LEVEL.VIEW || is2020Release ? 'disabled' : ''),'/>   <label class="fab-Checkbox__label" for="fedWithholdingTaxExempt">',window.microTemplate.encodeHtml($.__('Employee is exempt from federal taxes')),'</label>  </div> </div> ');}return p.join('');
}