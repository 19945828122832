import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({
	spacing,
}) => {
	return createStyles({
		radioOptionsContainer: {
			marginLeft: spacing(3),
		},
	});
});
