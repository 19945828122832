/**
 * @startCleanup encore
 */
import {
	Eye28x20,
	HeartAdd32x28,
	HeartEkg28x24,
	HeartMoney28x24,
	MedicalShield24x28,
	MoneyReverse28x28,
	PiggyBank28x26,
	Star28x27,
	Tooth28x28,
	Wallet28x26,
	Wheelchair25x28,
} from '@bamboohr/grim';
/**
 * @endCleanup encore
 */

import { EnrollmentStatusTab } from './bulk-editing.react/enrollment-status-tabs/types';
import { DeductionFrequencyEnumContract } from './types';

export type BenefitPlanTypeEnumContract =
	| 'health'
	| 'dental'
	| 'vision'
	| 'supplemental'
	| 'retirement'
	| 'reimbursement'
	| 'hsa'
	| 'flex'
	| 'life'
	| 'disability'
	| 'other';

export const BENEFIT_KEYS = {
	health: 'health',
	dental: 'dental',
	vision: 'vision',
	supplemental: 'supplemental',
	retirement: 'retirement',
	reimbursement: 'reimbursement',
	hsa: 'hsa',
	flex: 'flex',
	life: 'life',
	disability: 'disability',
	other: 'other',
};

export const BENEFIT_PLAN_TYPE_NAMES = {
	[BENEFIT_KEYS.health]: $.__('Medical'),
	[BENEFIT_KEYS.dental]: $.__('Dental'),
	[BENEFIT_KEYS.vision]: $.__('Vision'),
	[BENEFIT_KEYS.supplemental]: $.__('Supplemental'),
	[BENEFIT_KEYS.retirement]: $.__('Retirement'),
	[BENEFIT_KEYS.reimbursement]: $.__('Reimbursement'),
	[BENEFIT_KEYS.hsa]: $.__('HSA'),
	[BENEFIT_KEYS.flex]: $.__('FSA'),
	[BENEFIT_KEYS.life]: $.__('Life'),
	[BENEFIT_KEYS.disability]: $.__('Disability'),
	[BENEFIT_KEYS.other]: $.__('Other'),
};

export const V2_BENEFIT_TYPE_ICON_NAMES = {
	[BENEFIT_KEYS.health]: 'heart-pulse',
	[BENEFIT_KEYS.dental]: 'tooth',
	[BENEFIT_KEYS.vision]: 'eye',
	[BENEFIT_KEYS.supplemental]: 'heart-circle-plus',
	[BENEFIT_KEYS.retirement]: 'piggy-bank',
	[BENEFIT_KEYS.reimbursement]: 'rotate-left',
	[BENEFIT_KEYS.hsa]: 'building-columns',
	[BENEFIT_KEYS.flex]: 'wallet',
	[BENEFIT_KEYS.life]: 'shield-heart',
	[BENEFIT_KEYS.disability]: 'crutch',
	[BENEFIT_KEYS.other]: 'star',
};

/**
 * @startCleanup encore
 */
export const BENEFIT_PLAN_TYPE_ICONS_SMALL = {
	[BENEFIT_KEYS.health]: 'fab-heart-ekg-16x13',
	[BENEFIT_KEYS.dental]: 'fab-tooth-16x16',
	[BENEFIT_KEYS.vision]: 'fab-eye-16x12',
	[BENEFIT_KEYS.supplemental]: 'fab-heart-add-16x14',
	[BENEFIT_KEYS.retirement]: 'fab-piggy-bank-16x16',
	[BENEFIT_KEYS.reimbursement]: 'fab-money-reverse-16x16',
	[BENEFIT_KEYS.hsa]: 'fab-heart-money-16x14',
	[BENEFIT_KEYS.flex]: 'fab-wallet-16x15',
	[BENEFIT_KEYS.life]: 'fab-medical-shield-14x16',
	[BENEFIT_KEYS.disability]: 'fab-wheelchair-14x16',
	[BENEFIT_KEYS.other]: 'fab-star-16x15',
};
export const BENEFIT_TYPE_ICONS_MEDIUM = {
	[BENEFIT_KEYS.health]: HeartEkg28x24,
	[BENEFIT_KEYS.dental]: Tooth28x28,
	[BENEFIT_KEYS.vision]: Eye28x20,
	[BENEFIT_KEYS.supplemental]: HeartAdd32x28,
	[BENEFIT_KEYS.retirement]: PiggyBank28x26,
	[BENEFIT_KEYS.reimbursement]: MoneyReverse28x28,
	[BENEFIT_KEYS.hsa]: HeartMoney28x24,
	[BENEFIT_KEYS.flex]: Wallet28x26,
	[BENEFIT_KEYS.life]: MedicalShield24x28,
	[BENEFIT_KEYS.disability]: Wheelchair25x28,
	[BENEFIT_KEYS.other]: Star28x27,
};
export const BENEFIT_PLAN_TYPES = [
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.health,
		id: BENEFIT_KEYS.health,
		title: BENEFIT_PLAN_TYPE_NAMES.health,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.dental,
		id: BENEFIT_KEYS.dental,
		title: BENEFIT_PLAN_TYPE_NAMES.dental,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.vision,
		id: BENEFIT_KEYS.vision,
		title: BENEFIT_PLAN_TYPE_NAMES.vision,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.supplemental,
		id: BENEFIT_KEYS.supplemental,
		title: BENEFIT_PLAN_TYPE_NAMES.supplemental,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.retirement,
		id: BENEFIT_KEYS.retirement,
		title: BENEFIT_PLAN_TYPE_NAMES.retirement,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.reimbursement,
		id: BENEFIT_KEYS.reimbursement,
		title: BENEFIT_PLAN_TYPE_NAMES.reimbursement,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.hsa,
		id: BENEFIT_KEYS.hsa,
		title: BENEFIT_PLAN_TYPE_NAMES.hsa,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.flex,
		id: BENEFIT_KEYS.flex,
		title: BENEFIT_PLAN_TYPE_NAMES.flex,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.life,
		id: BENEFIT_KEYS.life,
		title: BENEFIT_PLAN_TYPE_NAMES.life,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.disability,
		id: BENEFIT_KEYS.disability,
		title: BENEFIT_PLAN_TYPE_NAMES.disability,
	},
	{
		icon: BENEFIT_PLAN_TYPE_ICONS_SMALL.other,
		id: BENEFIT_KEYS.other,
		title: BENEFIT_PLAN_TYPE_NAMES.other,
	},
];
/**
 * @endCleanup encore
 */

export const DEDUCTION_FREQUENCY_LABELS: { [key in DeductionFrequencyEnumContract]: string } = {
	[DeductionFrequencyEnumContract.daily]: $.__('Daily'),
	[DeductionFrequencyEnumContract.weekly]: $.__('Weekly'),
	[DeductionFrequencyEnumContract.everyOtherWeek]: $.__('Every other week'),
	[DeductionFrequencyEnumContract.twiceAMonth]: $.__('Twice a month'),
	[DeductionFrequencyEnumContract.fourTimesAMonth]: $.__('Four times a month'),
	[DeductionFrequencyEnumContract.monthly]: $.__('Monthly'),
	[DeductionFrequencyEnumContract.quarterly]: $.__('Quarterly'),
	[DeductionFrequencyEnumContract.twiceAYear]: $.__('Twice a year'),
	[DeductionFrequencyEnumContract.yearly]: $.__('Yearly'),
};

export const ENROLLMENT_STATUS_TAB_LABEL: { [key in EnrollmentStatusTab]: string } = {
	[EnrollmentStatusTab.enrolled]: $.__('Enrolled'),
	[EnrollmentStatusTab.eligible]: $.__('Eligible'),
	[EnrollmentStatusTab.waived]: $.__('Waived'),
};
