import { Fragment } from 'react';

import { RadioButton } from './radio-button';

import './radio-scale.styl';

export function RadioScale(props) {
	const { choices, onChange, selectedValue } = props;

	return (
		<Fragment>
			<div className="EnpsRadioScale__scale">
				{ choices.map(choice => (
					<RadioButton
						key={ choice.value }
						choice={ choice }
						onChoiceClick={ onChange }
						selectedValue={ selectedValue }
					/>
				)) }
			</div>
			<div className="EnpsRadioScale__divider"></div>
			<div className="EnpsRadioScale__legend">
				<span>
					{ $.__('Very Unlikely') }
				</span>
				<span>
					{ $.__('Very Likely') }
				</span>
			</div>
		</Fragment>
	);
}
