import {
	TextButton as EncoreTextButton,
	IconV2,
	LayoutBox,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { Icon } from '@fabric/button';
import classNames from 'classnames';
import { useEffect } from 'react';
import { FloatingCard } from '../floating-card.react';
import './survey-wrapper.styl';

export function SurveyWrapper(props) {
	const {
		children,
		hasAnonymityFooter,
		isFullBleed,
		isSmall,
		surveyName = 'default',
		isPreview = false,
		surveySubmitted,
		surveyClosed,
	} = props;
	const anonymityUrls = {
		enps: 'https://help.bamboohr.com/hc/en-us/articles/360006948434-Employee-Satisfaction-from-the-Employee-s-Perspective#is-the-enps-survey-really-anonymous',
		'employee-wellbeing':
			'https://help.bamboohr.com/hc/en-us/articles/4882699743757-employee-wellbeing-from-the-employee-s-perspective#is-the-employee-wellbeing-survey-anonymous',
		default: 'https://www.bamboohr.com/privacy-policy',
	};
	const isEmployeeWellbeing = surveyName === 'employee-wellbeing';
	const isEmployeeWellbeingPreview = isPreview && isEmployeeWellbeing;
	const shouldRenderPrivacyBlock =
		surveySubmitted || surveyClosed || isEmployeeWellbeingPreview;

	useEffect(() => {
		if (isPreview) return;

		$('body').addClass('EnpsSurveyBodyStyles');

		return () => $('body').removeClass('EnpsSurveyBodyStyles');
	}, [isPreview]);

	return (
		<div className="SurveyWrapper">
			<div
				className={classNames('SurveyWrapper__content', {
					'SurveyWrapper__content--small': isSmall,
				})}
			>
				<FloatingCard
					renderPrivacy={
						hasAnonymityFooter
							? null
							: () => {
									return ifFeature(
										'encore',
										shouldRenderPrivacyBlock ? (
											<div className="EnpsAction__privacyStatement">
												<LayoutBox position="relative" top="1px">
													<IconV2
														name="lock-regular"
														size={16}
														color="neutral-strong"
													/>
												</LayoutBox>
												<span className="EnpsAction__privacyStatementText">
													<>
														{$.__('Your feedback will be kept completely ')}
														<EncoreTextButton
															href={anonymityUrls[surveyName]}
															type="button"
															inline
														>
															{$.__('anonymous')}
														</EncoreTextButton>
														.
													</>
												</span>
											</div>
										) : (
											<></>
										),
										<div className="SurveyWrapper__customFooter SurveyWrapper__customFooter--muted">
											<Icon name="fab-lock-12x15" />
											<Message
												link={ExternalLink(
													anonymityUrls[surveyName],
													'fab-TextButton fab-TextButton--small fab-TextButton--inline fab-Link',
												)}
												text={$.__('Your feedback will be kept [anonymous.]')}
											/>
										</div>,
									);
							  }
					}
				>
					<div
						className={classNames('SurveyWrapper__body', {
							'SurveyWrapper__body--full-bleed': ifFeature(
								'encore',
								isFullBleed || surveyClosed || surveySubmitted,
								isFullBleed,
							),
						})}
					>
						{children}
					</div>
				</FloatingCard>
			</div>
		</div>
	);
}
