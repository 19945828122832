import Ajax from '@utils/ajax';

import { showSlidedown } from 'Alerts.mod';

import {
	isEmployerIdValid,
	isTaxRateValid,
} from '../components/company-tax-field/utils';

import {
	formatSaveData as formatCodesAndRates,
} from '../update-codes-rates/utils';

export function createInitialState(addEditData) {
	const initialState = {
		isLoading: true,
	};

	// Identify if there are multiple client's
	const formattedClientIdList = formatClientIdList(addEditData.clientIdList);
	let clientIdValue = null;
	if (addEditData.clientId) {
		clientIdValue = addEditData.clientId;
	} else if (formattedClientIdList.length === 1) {
		clientIdValue = formattedClientIdList[0].value;
	}
	const hideClientId = clientIdValue !== null;

	initialState.clientId = {
		name: 'clientId',
		hidden: hideClientId,
		items: formattedClientIdList,
		required: true,
		disabled: false,
		error: false,
		value: clientIdValue,
	};

	initialState.effectiveDate = {
		name: 'effectiveDate',
		isLoading: false,
		items: addEditData.allowableEffectiveDates,
		required: true,
		disabled: !hideClientId,
		error: false,
		value: null,
	};

	initialState.state = {
		name: 'state',
		items: [],
		required: true,
		disabled: !hideClientId,
		error: false,
		selectedState: null,
		value: null,
	};

	initialState.taxTypeId = {
		name: 'taxTypeId',
		isLoading: false,
		items: [],
		required: true,
		disabled: true,
		error: false,
		selectedTaxType: null,
		value: null,
	};

	initialState.payFrequencyId = {
		name: 'payFrequencyId',
		items: [],
		required: true,
		disabled: true,
		error: false,
		value: null,
	};

	initialState.employerTaxId = {
		name: 'employerTaxId',
		required: true,
		disabled: false,
		error: false,
		value: null,
	};

	initialState.taxRate = {
		name: 'taxRate',
		required: true,
		disabled: false,
		error: false,
		value: null,
		width: 3,
	};

	initialState.notes = {
		name: 'notes',
		required: false,
		disabled: false,
		error: false,
		value: null,
	};

	initialState.codesRatesError = false;

	return initialState;
}

export function mergeExistingTaxData(initialState, existingTaxType) {
	const {
		clientId,
		employerTaxId,
		notes,
		payFrequencyId,
		startDate,
		taxRate,
		isTaxRateEditable,
		taxTypeId,
		taxTypeState,
	} = existingTaxType;

	const {
		state,
	} = initialState;

	return Ajax.get(`/settings/payroll/ajax/taxes/tax_types?stateAbbrev=${ taxTypeState }&clientId=${ clientId }`).then((response) => {

		// Set start date
		if (startDate) {
			initialState.effectiveDate.value = startDate;
		}

		// Find State object
		if (taxTypeState) {
			const selectedState = state.items.filter(item => item.value === taxTypeState)[0];
			initialState.state.selectedState = selectedState;
			initialState.state.value = taxTypeState;
		}

		// Find selected tax type from this response
		if (taxTypeId) {
			const selectedTaxType = response.data.allowableTaxTypes.filter(taxType => taxType.taxTypeId === taxTypeId)[0];
			initialState.taxTypeId.selectedTaxType = selectedTaxType;
			initialState.taxTypeId.value = taxTypeId;
			initialState.taxTypeId.items = response.data.allowableTaxTypes;
		}

		// Find selected pay frequency option from tax type's allowable pay frequencies
		if (payFrequencyId) {
			initialState.payFrequencyId.value = payFrequencyId;
		}

		// Update all other data (tax rate, id, etc.)
		if (employerTaxId) {
			initialState.employerTaxId.value = employerTaxId;
		}

		if (taxRate) {
			initialState.taxRate.value = taxRate;
		}

		if (typeof isTaxRateEditable !== 'undefined') {
			initialState.taxRate.editable = isTaxRateEditable;
		}

		if (notes) {
			initialState.notes.value = notes;
		}

		return initialState;
	}).catch(() => {
		showSlidedown(window.DEFAULT_ERROR_MESSAGE, 'error');
	});
}

export function formatEffectiveDates(allowableEffectiveDates) {
	const effectiveDates = [];

	Object.keys(allowableEffectiveDates).forEach((key) => {
		effectiveDates.push({
			value: key,
			quarterText: allowableEffectiveDates[key].split(' (')[0],
			quarterDate: moment(key, 'YYYY-MM-DD').format('M/D/YYYY'),
		});
	});

	return effectiveDates;
}

export function formatStatesArray(states) {
	const formattedStates = [];

	states.forEach((state) => {
		formattedStates.push({
			text: state.full_name,
			value: state.name,
		});
	});

	return formattedStates;
}

export function formatClientIdList(clientIdList) {
	const formattedClientIdList = [];
	for (const key in clientIdList) {
		formattedClientIdList.push({
			clientEin: clientIdList[key].clientEin,
			text: clientIdList[key].clientName,
			value: clientIdList[key].clientId,
		});
	}
	return formattedClientIdList;
}

/**
 * Function used to format the data for both create and edit tax to match the BE's expended payload.
 * Will also do FE validation of input and add error states to the fields that fail.
 * @param formData					{object}	| Object from add-edit-tax's state with form field's input
 * @param updateFormData			{function}	| Function used to update add-edit-tax's state
 */
export function formatForSave(formData, updateFormData) {
	let readyForSave = true;
	const saveObject = {};

	for (const key in formData) {
		const fieldData = formData[key];
		if (!fieldData.disabled && fieldData.required) {
			let rowValid = true;
			if (!fieldData.value || fieldData.value === '') {
				rowValid = false;
			}

			// Check for existing errors
			if (fieldData.error) {
				rowValid = false;
			}

			// Check taxId validation
			if (key === 'employerTaxId' && formData.taxTypeId.selectedTaxType) {
				const selectedTax = formData.taxTypeId.selectedTaxType;
				// eslint-disable-next-line max-depth
				if (!isEmployerIdValid(fieldData.value, selectedTax.employerTaxIdValidationRegex)) {
					rowValid = false;
				}
			}

			// Check min/max values
			if (key === 'taxRate' && formData.taxTypeId.selectedTaxType) {
				const {
					minTaxRate,
					maxTaxRate,
				} = formData.taxTypeId.selectedTaxType;

				// eslint-disable-next-line max-depth
				if (!isTaxRateValid(parseFloat(fieldData.value), minTaxRate, maxTaxRate)) {
					rowValid = false;
				}
			}

			if (rowValid) {
				// eslint-disable-next-line max-depth
				if (fieldData.value && fieldData.name) {
					saveObject[fieldData.name] = fieldData.value;
				}
			} else {
				readyForSave = false;
				updateFormData(fieldData.name, { error: true });
			}
		} else if (fieldData.value && fieldData.name) {
			saveObject[fieldData.name] = fieldData.value;
		}
	}

	return { readyForSave, saveObject };
}

/**
 * Runs function to format data for save and also holds the actual save function. Upon passing
 * the format function's valiation will cause the create to happen. Upon failure will trigger
 * slidedown failure message.
 * @param formData					{object}	| Object from add-edit-tax's state with form field's input
 * @param updateFormData			{function}	| Function used to update add-edit-tax's state
 * @param callbackFunctions			{object}	| Object holding both success and failure functions { success: function, failure: function }
 * @param handleCodesRatesError
 */
export function createTax(formData, updateFormData, callbackFunctions, handleCodesRatesError) {
	const $codesAndRatesForm = $('.js-codesAndRatesForm');

	let {
		readyForSave,
		saveObject,
	} = formatForSave(formData, updateFormData);

	// Checks specific to Codes and Rates
	if ($codesAndRatesForm.length) {
		const {
			error,
			codesObj,
		} = validateAndFormatCodesAndRates($codesAndRatesForm.serializeArray());

		if (error) {
			readyForSave = false;
			handleCodesRatesError();
		} else {
			saveObject.codes = codesObj;
		}
	}

	if (readyForSave) {
		saveObject.modifiedBy = window.SESSION_USER.id;
		Ajax.post(`/settings/payroll/taxes/tax_type`, saveObject).then((response) => {
			if (response.status && response.status === 200) {
				if (callbackFunctions) {
					callbackFunctions.success();
				}
			} else {
				callbackFunctions.failure('Uh oh...something went wrong. Please try again or contact support.');
			}
		}).catch(() => {
			callbackFunctions.failure('Uh oh...something went wrong. Please try again or contact support.');
		});


	} else {
		callbackFunctions.failure('Whoops...please fill out all required fields and try again.');
	}
}

/**
 * Runs function to format data for save and also holds the actual edit function. Upon passing
 * the format function's valiation will cause the edit to happen. Upon failure will trigger
 * slidedown failure message.
 * @param formData					{object}	| Object from add-edit-tax's state with form field's input
 * @param updateFormData			{function}	| Function used to update add-edit-tax's state
 * @param callbackFunctions			{object}	| Object holding both success and failure functions { success: function, failure: function }
 * @param clientTaxTypeIncludeId	{int}		| id the BE uses to identify which tax to edit
 */
export function editTax(formData, updateFormData, callbackFunctions, clientTaxTypeIncludeId) {
	const {
		readyForSave,
		saveObject,
	} = formatForSave(formData, updateFormData);

	if (readyForSave) {
		saveObject.modifiedBy = window.SESSION_USER.id;
		saveObject.clientTaxTypeIncludeId = clientTaxTypeIncludeId;

		Ajax.put(`/settings/payroll/taxes/tax_type`, saveObject).then((response) => {
			if (response.status && response.status === 200) {
				if (callbackFunctions) {
					callbackFunctions.success();
				}
			} else {
				callbackFunctions.failure('Uh oh...something went wrong. Please try again or contact support.');
			}
		}).catch(() => {
			callbackFunctions.failure('Uh oh...something went wrong. Please try again or contact support.');
		});


	} else {
		callbackFunctions.failure('Whoops...please fill out all required fields and try again.');
	}
}

/**
 * Validate and format the codes and rates fields when present
 * @param fieldsArray  {array}  | Array of codes and rates fields
 * @returns            {object} | Contains 'error' and 'codesObj' properties
 */
function validateAndFormatCodesAndRates(fieldsArray) {
	let error = false;
	let codesObj = null;

	fieldsArray.forEach((field) => {
		if (!field.value) {
			error = true;
		}
	});

	if (!error) {
		const { codesAndRates } = formatCodesAndRates(fieldsArray);
		codesObj = codesAndRates;
	}

	return {
		error,
		codesObj,
	};
}
