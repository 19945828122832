import { createStyles, makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles((theme) => {
	const { mixins, palette, typography } = theme;

	return createStyles({
		body: {
			paddingBottom: 100,
		},
		checkboxContainer: {
			backgroundColor: '#FEF6C7',
			marginTop: 36,
			padding: 20,
		},
		footer: {
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0, rgba(255,255,255,0) 100%)',
			bottom: 91,
			height: 100,
			position: 'fixed',
			width: '100%',
		},
		header: {
			alignItems: 'center',
			borderBottom: mixins.border(1, palette.grey[400]),
			display: 'flex',
			marginBottom: 21,
			paddingBottom: 7,
		},
		headerText: {
			marginLeft: 10,
		},
		link: {
			fontWeight: typography.fontWeightSemibold,
		},
		paragraphBody: {
			marginBottom: 16,
		},
		root: {
			textAlign: 'left',
		},
		settingsHeader: {
			marginBottom: 0,
		},
		settingsHeaderContainer: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		trainingHeader: {
			marginBottom: 0,
			marginTop: 36,
		},
	});
});
