import { Fragment } from 'react';
import { Headline } from '@bamboohr/fabric';
import { ifFeature } from "@bamboohr/utils/lib/feature";

import SourcesTable from './table';

import './styles.styl';


const CandidateSources = (props) => {
	return (
		<Fragment>
			{ ifFeature('encore',
				<Headline
					color={'primary'}
					size={'small'}
				>
					{ $.__('Candidate Sources')}
				</Headline>,
				<div className="candidateSources__header">
					<h4>
						{ $.__('Candidate Sources') }
					</h4>
				</div>
			)}
			<SourcesTable { ...props } />
		</Fragment>
	);
};

export default CandidateSources;
