export const getDropdownItems = () => {
	return [
		{
			biId: 'settings-access-level-menu-option-access-levels-employee-create',
			key: 'employee_access',
			label: $.__(`Employee Access Level`),
			note: $.__(`For Employees who should never see sensitive information for anyone but themselves.`),
			href: `/access_levels/employee/create`,
		},
		{
			biId: 'settings-access-level-menu-option-access-levels-manager-create',
			key: 'manager_access',
			label: $.__(`Manager Access Level`),
			note: $.__(`For Employees who can only see information for indirect and/or direct reports.`),
			href: `/access_levels/manager/create`,
		},
		{
			biId: 'settings-access-level-menu-option-access-levels-custom-create',
			key: 'custom_access',
			label: $.__(`Custom Access Level`),
			note: $.__(`For Employees who can see and/or edit information for some or all employees`),
			href: `/access_levels/custom/create`,
		},
	];
};
