import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	useStateContext,
} from '../state';


export function PayrollHeader() {
	const [{
		accessLevel: {
			type,
		},
	}] = useStateContext();
	const isPayrollAdmin = type === 'payroll-admin';

	return isPayrollAdmin && (
		<div>
			<p>
				{ $.__(`Heads up!`) }&nbsp;
				{ $.__(`You are granting access to run payroll, approve timesheets, and update payroll-related employee information for all employees.`) }
				{ /* TODO: Need help page URL
				<a href="">
					{ $.__(`More Info`) }
				</a>
				*/ }
			</p>
		</div>
	);
}

export function PayrollFooter() {
	const [{
		defaultESS,
		accessLevel: {
			type,
		},
	}] = useStateContext();

	return !!(defaultESS && type === 'payroll-admin') && (
		<p>
			<Message
				params={ [$.__(`Current`), defaultESS.name] }
				text={ $._(`This person's Employee Access Level will change from **{1}** to **{2}**`) }
			/>
			&nbsp;({ $.__(`which is currently set as the default level for Payroll Admins`) }).
		</p>
	);
}
