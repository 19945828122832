export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';

export const CREATE_NEW_CANCEL = 'CREATE_NEW_CANCEL';
export const CREATE_NEW_PENDING = 'CREATE_NEW_PENDING';

export const DELETE_APPROVAL_ERROR = 'DELETE_APPROVAL_ERROR';
export const DELETE_APPROVAL_PENDING = 'DELETE_APPROVAL_PENDING';
export const DELETE_APPROVAL_SUCCESS = 'DELETE_APPROVAL_SUCCESS';

export const DELETE_CHILD_PATH_ERROR = 'DELETE_CHILD_PATH_ERROR';
export const DELETE_CHILD_PATH_PENDING = 'DELETE_CHILD_PATH_PENDING';
export const DELETE_CHILD_PATH_SUCCESS = 'DELETE_CHILD_PATH_SUCCESS';

export const DISABLE_APPROVAL_ERROR = 'DISABLE_APPROVAL_ERROR';
export const DISABLE_APPROVAL_PENDING = 'DISABLE_APPROVAL_PENDING';
export const DISABLE_APPROVAL_SUCCESS = 'DISABLE_APPROVAL_SUCCESS';

export const ENABLE_APPROVAL_ERROR = 'ENABLE_APPROVAL_ERROR';
export const ENABLE_APPROVAL_PENDING = 'ENABLE_APPROVAL_PENDING';
export const ENABLE_APPROVAL_SUCCESS = 'ENABLE_APPROVAL_SUCCESS';

export const GET_DATA_ERROR = 'GET_DATA_ERROR';
export const GET_DATA_PENDING = 'GET_DATA_PENDING';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';

export const EDIT_WORKFLOW_START = 'EDIT_WORKFLOW_START';
export const EDIT_WORKFLOW_CANCEL = 'EDIT_WORKFLOW_CANCEL';

export const FORM_SUBMIT_CONFIRMATION = 'FORM_SUBMIT_CONFIRMATION';
export const FORM_SUBMIT_ERROR = 'FORM_SUBMIT_ERROR';
export const FORM_SUBMIT_PENDING = 'FORM_SUBMIT_PENDING';
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';

export const PROCESSING_ACTION = 'PROCESSING_ACTION';

export const REMOVE_PATH = 'REMOVE_PATH';

export const RESTORE_APPROVAL_ERROR = 'RESTORE_APPROVAL_ERROR';
export const RESTORE_APPROVAL_PENDING = 'RESTORE_APPROVAL_PENDING';
export const RESTORE_APPROVAL_SUCCESS = 'RESTORE_APPROVAL_SUCCESS';

export const SAVE_PATH = 'SAVE_PATH';

export const SUBNAV_ITEMS_PENDING = 'SUBNAV_ITEMS_PENDING';
export const SUBNAV_ITEMS_SUCCESS = 'SUBNAV_ITEMS_SUCCESS';

export const UPDATE_EDITING_APPROVERS = 'UPDATE_EDITING_APPROVERS';
export const UPDATE_EDITING_LEVELS = 'UPDATE_EDITING_LEVELS';
export const UPDATE_EDITING_PATH_OPTIONS = 'UPDATE_EDITING_PATH_OPTIONS';
