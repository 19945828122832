const SELECT_WIDTH_BREAKPOINTS = [
	{
		minCharacterCount: 0,
		selectWidth: 6,
	},
	{
		minCharacterCount: 20,
		selectWidth: 7,
	},
	{
		minCharacterCount: 24,
		selectWidth: 8,
	},
	{
		minCharacterCount: 90,
		selectWidth: 9,
	},
];

export function formatTaxTypes(taxTypes) {
	const formattedTaxTypes = taxTypes.map((tax) => {
		return (
			{
				text: tax.taxTypeName,
				value: tax.taxTypeId,
			}
		);
	});

	return formattedTaxTypes;
}

export function formatPayFrequencies(payFrequencies) {
	const formattedPayFrequencies = [];
	for (const key in payFrequencies) {
		formattedPayFrequencies.push({ value: parseInt(key), text: payFrequencies[key] });
	}

	return formattedPayFrequencies;
}

export function isEmployerIdValid(value, idValidationRegex) {
	if (!idValidationRegex || idValidationRegex.length === 0) {
		return true;
	}
	for (let i = 0; i < idValidationRegex.length; i++) {
		const regex = RegExp(idValidationRegex[i]);
		if (regex.test(value)) {
			return true;
		}
	}
	return false;
}

export function isTaxRateValid(taxRate, minTaxRate, maxTaxRate) {
	if (isNaN(taxRate)) {
		return false;
	}

	if (minTaxRate) {
		if (taxRate < minTaxRate) {
			return false;
		}
	}
	if (maxTaxRate) {
		if (taxRate > maxTaxRate) {
			return false;
		}
	}

	return true;
}

export function findSelectWidth(allowableTaxTypes) {
	let longestCharacterCount = 0;
	allowableTaxTypes.forEach((taxType) => {
		if (taxType.taxTypeName && taxType.taxTypeName.length > longestCharacterCount) {
			longestCharacterCount = taxType.taxTypeName.length;
		}
	});

	let selectBreakpoint;
	SELECT_WIDTH_BREAKPOINTS.forEach((breakpoint) => {
		if (longestCharacterCount >= breakpoint.minCharacterCount) {
			selectBreakpoint = breakpoint;
		}
	});

	return selectBreakpoint;
}
