export const EmbeddedVideo = (props) => {
	const {
		videoSrc,
		videoTitle,
	} = props;

	return (
		<>
			<iframe
				allowFullScreen={ true }
				className="wistia_embed"
				frameBorder="0"
				height="100%"
				mozallowfullscreen="true"
				msallowfullscreen="true"
				name="wistia_embed"
				oallowfullscreen="true"
				scrolling="no"
				src={ videoSrc }
				title={ videoTitle }
				webkitallowfullscreen="true"
				width="100%"
			>
			</iframe>
			<script async={ true } src="//fast.wistia.net/assets/external/E-v1.js"></script>
		</>
	);
};
