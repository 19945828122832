import { COMPANY_APP_STATE, takeToBankAccountSection } from '../../utils/shared';
import { BANK_APP_STATE } from '../../../bank-info.react/utils/shared';

import { TextButton } from '@fabric/button';
import { BodyText, Flex, Icon, IconV2 } from '@bamboohr/fabric';

/* @startCleanup encore */
import './status-row.styl';
/* @endCleanup encore */
import { InternalLink, Message } from '@bamboohr/utils/lib/message';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const commonFlexProps = {
	alignItems: 'center',
	gap: 1,
	marginTop: 4,
	marginBottom: 2,
};

export function StatusRow(props) {
	const renderSavedState = (text) => {
		return ifFeature(
			'encore',
			<Flex {...commonFlexProps}>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
				<BodyText color='neutral-medium'>{text}</BodyText>
			</Flex>,
			<div className='StatusRow StatusRow__pending'>
				<span className='StatusRow__pendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>
				<span className='StatusRow__pendingText'>{text}</span>
			</div>
		);
	};

	const renderVerfiedState = () => {
		return ifFeature(
			'encore',
			<Flex {...commonFlexProps}>
				<IconV2 name='circle-check-solid' color='success-strong' size={16} />
				<BodyText color='success-strong'>{$.__('Verified')}</BodyText>
			</Flex>,
			<div className='StatusRow StatusRow__verified'>
				<Icon name='fab-check-circle-14x14' />
				<span className='StatusRow__verifiedText'>{$.__('Verified')}</span>
			</div>
		);
	};

	const renderDocsUploadedState = () => {
		return ifFeature(
			'encore',
			<Flex {...commonFlexProps}>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />

				<BodyText color='neutral-medium'>
					<Message text={$._('Pending - [Bank Account Setup]')} link={InternalLink('/settings/payroll/bank_account')} />
				</BodyText>
			</Flex>,
			<div className='StatusRow StatusRow__pending'>
				<span className='StatusRow__pendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>
				<span className='StatusRow__pendingText'>{$.__('Pending')} -&nbsp;</span>
				<span className='StatusRow__pendingLink'>
					<TextButton clickAction={takeToBankAccountSection} inline={true} text={$.__('Bank Account Setup')} type='button' />
				</span>
			</div>
		);
	};

	const renderVerifyingManualModeState = () => {
		return ifFeature(
			'encore',
			<Flex {...commonFlexProps}>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
				<BodyText color='neutral-medium'>{$.__('Pending - Verifying deposit amount')}</BodyText>
			</Flex>,
			<div className='StatusRow StatusRow__pending'>
				<span className='StatusRow__pendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>
				<span className='StatusRow__pendingText'>{$.__('Pending - Verifying deposit amount')}</span>
			</div>
		);
	};

	const renderVerifyBankState = () => {
		const doClickAction = () => {
			const { clickAction } = props;

			clickAction(true);
		};

		return ifFeature(
			'encore',
			<Flex {...commonFlexProps}>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />

				<BodyText color='neutral-medium'>
					<Message
						text={$._('Pending - [Verify bank account]')}
						link={(text) => (
							<TextButton onClick={doClickAction} inline={true} type='button'>
								{text}
							</TextButton>
						)}
					/>
				</BodyText>
			</Flex>,
			<div className='StatusRow StatusRow__pending'>
				<span className='StatusRow__pendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>
				<span className='StatusRow__pendingText'>Pending -&nbsp;</span>
				<span className='StatusRow__pendingLink'>
					<TextButton clickAction={doClickAction} inline={true} text={$.__('Verify bank account')} type='button' />
				</span>
			</div>
		);
	};

	const { status } = props;

	switch (status) {
		// COMPANY SECTION
		case COMPANY_APP_STATE.COMPANY_SAVED:
		case COMPANY_APP_STATE.DOCS_DOWNLOADED:
			return renderSavedState($.__('Pending - Sign and upload your company payroll forms'));

		case COMPANY_APP_STATE.DOCS_UPLOADED:
			return renderDocsUploadedState();

		case COMPANY_APP_STATE.VERIFIED:
			return renderVerfiedState();

		// BANK SECTION
		case BANK_APP_STATE.BANK_SAVED:
			return renderSavedState($.__('Pending - Sign and upload your bank account authorization forms'));

		case BANK_APP_STATE.DOCS_UPLOADED:
			return renderVerifyBankState();

		case BANK_APP_STATE.VERIFIED:
			return renderVerfiedState();

		case BANK_APP_STATE.VERIFYING_MANUAL_MODE:
			return renderVerifyingManualModeState();

		default:
			return null;
	}
}
