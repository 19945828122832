import BEM from '@utils/dom/bem';

export const _bem = (elem, mods) => BEM.create('SettingsAccessLevelsAddEmployeeModal', elem, mods);

export const buildContext = data => data && ({
	...data,
	employeeFilters: data.employeeFilters.employeeFilters,
	selectedEmployees: data.employeeList.filter(({ selected }) => selected),
	employeeIds: data.employeeList.filter(({ selected }) => selected).map(({ id }) => id),
	editType: 'change',
});

