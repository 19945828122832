import { AxiosPromise } from 'axios';

import Ajax from '@utils/ajax';

import { FeatureToggleContract } from '../../types/feature-toggle-contract';

/**
 * @deprecated use get-feature-toggles instead
 */
export const getToggles = (): AxiosPromise<FeatureToggleContract> => {
	return Ajax.get('/settings/account/features');
};
