import { connect } from 'react-redux';

import { AdvancedWorkflow } from '../components/advanced-workflow';
import {
	createNew,
	editWorkflow,
	handleEnableApproval
} from '../store/actions';

const mapStateToProps = (state) => {
	const {
		approverOptions,
		availablePathOptions,
		availableUsers,
		confirmationRequired,
		confirmationType,
		editState,
		editingWorkflow,
		editingWorkflowId,
		newPathOpen,
		newPathSelectedOptions,
		pathsChanging,
		pathTypeChildren,
		requestOptions,
		userGroups,
		workflow,
		workflowId
	} = state;
	const {
		active,
		advancedApprovalToolEnabled,
		curAppStatus,
		customFieldCount,
		description,
		lastModified,
		name,
		pathType,
		roleSectionNote,
		roleSectionText,
		roleSectionTitle,
		template,
		templateName,
		type,
		workflowApprovals,
		workflowInitiators
	} = (workflow || {});

	return {
		active,
		advancedApprovalToolEnabled,
		approverOptions,
		availablePathOptions,
		availableUsers,
		confirmationRequired,
		confirmationType,
		curAppStatus,
		customFieldCount,
		description,
		editState,
		editingWorkflow,
		editingWorkflowId,
		id: workflowId,
		lastModified,
		name,
		newPathOpen,
		newPathSelectedOptions,
		pathsChanging,
		pathType,
		pathTypeChildren,
		requestOptions,
		roleSectionNote,
		roleSectionText,
		roleSectionTitle,
		template,
		templateName,
		type,
		userGroups,
		workflowApprovals,
		workflowInitiators
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleCreateNew: () => {
			dispatch(createNew());
		},
		handleEnableApproval: (workflowId) => {
			return dispatch(handleEnableApproval(workflowId));
		},
		handleEdit: (workflowId) => {
			dispatch(editWorkflow(workflowId));
		}
	}
};

export const AdvancedWorkflowContainer = connect(mapStateToProps, mapDispatchToProps)(AdvancedWorkflow);
