import { createStyles, makeStyles } from '@bamboohr/fabric';

import { ifFeature } from '@bamboohr/utils/lib/feature';

export default makeStyles(({ palette }) => {
	return createStyles({
		modalBody: {
			maxHeight: '50vh',
			overflow: 'auto',
			margin: ifFeature('encore', '16px 14px 0 14px', '0'),
		},
	});
});
