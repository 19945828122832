import { Message } from '@bamboohr/utils/lib/message';
import {
	Button,
	StandardModal,
	TextButton,
	/* @startCleanup encore */
	useTheme
	/* @endCleanup encore */
} from "@bamboohr/fabric";
/* @startCleanup encore */
import { TriangleExclamation48x48 } from "@bamboohr/grim";
import { ifFeature } from "@bamboohr/utils/lib/feature";
/* @endCleanup encore */

export function JustCheckingModal(props) {
	const {
		onClose,
		visible,
		onCancel,
		onOkClick,
		garnishmentTypeData,
	} = props;

	/* @startCleanup encore */
	const { palette } = useTheme();
	/* @endCleanup encore */

	if (garnishmentTypeData === undefined) {
		return null;
	}

	const employeeCount = garnishmentTypeData.data.employees.length;
	const employeeString = employeeCount > 1 ? $.__('employees') : $.__('employee');
	const garnishmentName = mapTypeToName(garnishmentTypeData.name);
	const primaryText = $.__('Are you sure you want BambooHR Payroll to no longer collect and pay %1$s?', garnishmentName);
	const closeModal = () => {
		onClose();
		onCancel();
	};

	const actions = ifFeature(
		'encore',
		[
			<TextButton
				key="close-button"
				onClick={ closeModal }
			>
				{ $.__(`Cancel`) }
			</TextButton>,
			<Button
				key="button"
				onClick={ onOkClick }
				type="button"
			>
				{ $.__('Continue') }
			</Button>,
		],
		[
			<Button
				key="button"
				onClick={ onOkClick }
				type="button"
			>
				{ $.__('Continue') }
			</Button>,
			<TextButton
				key="close-button"
				onClick={ closeModal }
			>
				{ $.__(`Cancel`) }
			</TextButton>,
		]
	);

	return (
		<StandardModal
			isOpen={ visible }
			onCloseComplete={ closeModal }
			onRequestClose={ closeModal }
		>
			<StandardModal.Body
				renderFooter={(
					<StandardModal.Footer
						actions={actions}
					/>
				)}
				renderHeader={(
					<StandardModal.Header
						hasCloseButton={ false }
						title={ $.__('Just Checking...') }
					/>
				)}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon={
							ifFeature('encore',
							'triangle-exclamation-solid',
								<TriangleExclamation48x48 fill={ palette.warning.main } />
							)
						}
						iconColor={ifFeature('encore', 'warning-medium')}
						subtext={ getSecondaryText(employeeCount, employeeString) }
						text={ primaryText }
					/>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}

function getSecondaryText(employeeCount, employeeString) {
	return (
		<Message
			params={ [employeeCount, employeeString] }
			text={ $._('You will be responsible for collecting and paying garnishments for **{1} {2}**.') }
		/>
	);
}

function mapTypeToName(name) {
	let actualName = null;

	switch (name) {
		case 'childSupport':
			actualName = 'Child Support';
			break;

		case 'taxLevies':
			actualName = 'Tax Levies';
			break;

		case 'other':
			actualName = 'Other Garnishments';
			break;

		default:
			break;
	}

	return actualName;
}
