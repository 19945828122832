let jwtToken;

export function getJwtToken(): string {
	return jwtToken;
}

export function resetJwtToken(): void {
	jwtToken = undefined;
}

export function setJwtToken(newToken: string): void {
	jwtToken = newToken;
}
