import { Fragment } from 'react';

import {
	DateField,
	ErrorPosition,
} from 'form-fields.react';

import './date-range-input.styl';

interface DateRangeInputProps {
	doShowEndDate?: boolean;
	endDate?: string;
	endErrorMessage?: string;
	endId?: string;
	endLabel?: string;
	endWarning?: string;
	errorPosition?: ErrorPosition;
	isEndRequired?: boolean;
	isStartDisabled?: boolean;
	isStartRequired?: boolean;
	onEndChange?: (date: string) => void;
	onStartChange?: (date: string) => void;
	startDate?: string;
	startErrorMessage?: string;
	startId?: string;
	startLabel?: string;
	startBiId?: string;
	endBiId?: string;
}

export function DateRangeInput(props: DateRangeInputProps): JSX.Element {
	const {
		doShowEndDate,
		endDate,
		endErrorMessage,
		endId,
		endLabel,
		endWarning,
		errorPosition,
		isEndRequired,
		isStartDisabled,
		isStartRequired,
		onEndChange,
		onStartChange,
		startDate,
		startErrorMessage,
		startId,
		startLabel,
		startBiId,
		endBiId,
	} = props;

	return (
		<div className="DateRangeInput">
			<DateField
				biId={ startBiId }
				disabled={ isStartDisabled }
				error={ startErrorMessage }
				errorPosition={ errorPosition }
				id={ startId }
				label={ startLabel }
				onChange={ onStartChange }
				required={ isStartRequired }
				value={ startDate }
			/>
			{ (doShowEndDate) && (
				<Fragment>
					<div className="DateRangeInput__separator"><span>&ndash;</span></div>
					<DateField
						biId={ endBiId }
						error={ endErrorMessage }
						errorPosition={ errorPosition }
						id={ endId }
						label={ endLabel }
						onChange={ onEndChange }
						required={ isEndRequired }
						value={ endDate }
						warning={ endWarning }
					/>
				</Fragment>
			) }
		</div>
	);
}

DateRangeInput.defaultProps = {
	doShowEndDate: true,
	endDate: '',
	endErrorMessage: '',
	endWarning: '',
	isEndRequired: false,
	isStartDisabled: false,
	isStartRequired: false,
	startDate: '',
	startErrorMessage: '',
};
