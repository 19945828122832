import { Button as EncoreButton, Flex, Section } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button } from '@fabric/button';
import classNames from 'classnames';
import moment from 'moment';
import { Component } from 'react';
import Select from 'select.react';
import { getSettingsEnpsPreviewEmail } from '../../../../../settings-enps-service';
import { EnpsSection } from '../../../enps-section';
import { PreviewEmailModal } from '../../../shared/preview-email-modal';
import { getRequestData } from '../setup-service';

export class SurveyEmail extends Component {
	constructor(props) {
		super(props);

		const { enpsData } = this.props;
		this.state = {
			...enpsData,
			isPreviewButtonProcessing: false,
			isPreviewEmailModalOpen: false,
		};
	}

	_renderSurveyFrequencySelector() {
		const { onSurveyFrequencyChange } = this.props;
		const { surveyFrequencyData } = this.state;
		const selectSettings = {
			name: 'surveyFrequency',
			notClearable: true,
			required: true,
		};
		const previewLabel = $.__('Preview Survey Email');

		return (
			<div className="fieldRow Setup__surveyFrequency">
				<div
					aria-labelledby="repeatSurveyLabel"
					className="fieldBox required"
					role="listbox"
				>
					<label
						id="repeatSurveyLabel"
						className="fab-Label fab-Label--required"
						htmlFor={selectSettings.name}
					>
						{$.__('Repeat survey every')}
					</label>
					<div className="fieldDiv">
						{ifFeature(
							'encore',
							<Flex alignItems="center" gap={2}>
								<Select
									className="long"
									data={surveyFrequencyData}
									id={selectSettings.name}
									onChange={onSurveyFrequencyChange}
									settings={selectSettings}
									width={7}
								/>
								<EncoreButton
									onClick={this._handlePreviewEmailOpen}
									startIcon="eye-regular"
									type="button"
									variant="text"
								>
									{previewLabel}
								</EncoreButton>
							</Flex>,
							<Select
								className="long"
								data={surveyFrequencyData}
								id={selectSettings.name}
								onChange={onSurveyFrequencyChange}
								settings={selectSettings}
								width={ifFeature('encore', 7, 5)}
								variant="single"
							/>,
						)}
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Format display text of survey start date
	 * @param {string} originalSendDate Setup component state sendDate
	 * @param {string} newSendDate Latest date string selected from calendar picker
	 * @return {strings} Formatted start date
	 */
	_formatStartDate(originalSendDate, newSendDate) {
		const calendarPicker = newSendDate;
		let startDate;

		if (calendarPicker) {
			startDate = moment(calendarPicker).format('ll');
		} else {
			startDate = moment(originalSendDate).format('ll');
		}
		return startDate;
	}

	_togglePreviewButtonProcessing(doToggle) {
		this.setState(() => {
			return {
				isPreviewButtonProcessing: doToggle,
			};
		});
	}

	_handlePreviewEmailClose = () => {
		this.setState(() => {
			return {
				isPreviewEmailModalOpen: false,
			};
		});
	};

	_handlePreviewEmailOpen = () => {
		const { enpsData, newSendDate, onNextHandler } = this.props;
		const introDate = newSendDate || moment().format('YYYY-MM-DD');
		const requestData = getRequestData(enpsData, 'autosave');
		this._togglePreviewButtonProcessing(true);

		return onNextHandler(requestData).then(() => {
			getSettingsEnpsPreviewEmail(
				`/settings/enps/surveyEmail?introDate=${introDate}`,
			).then(
				(email) => {
					this.setState(() => {
						return {
							isPreviewEmailModalOpen: true,
							previewEmailContent: email,
						};
					});
					this._togglePreviewButtonProcessing(false);
				},
				() => {
					setMessage(
						$.__('It looks like something went wrong! Try again later.'),
						'error',
					);
					this._togglePreviewButtonProcessing(false);
				},
			);
		});
	};

	render() {
		const { enpsData, newSendDate } = this.props;
		const {
			isPreviewButtonProcessing,
			isPreviewEmailModalOpen,
			previewEmailContent,
		} = this.state;
		const previewLabel = $.__('Preview Survey Email');
		return (
			<EnpsSection iconName="fab-envelope-24x20" step="2">
				{ifFeature(
					'encore',
					<Section.Subsection>
						<Section.Header
							description={$.__(
								'Survey will close 6 business days after %1.',
								this._formatStartDate(enpsData.sendDate, newSendDate),
							)}
							divider="top"
							size="small"
							title={$.__(
								'Employee Satisfaction Survey email will be sent to all employees on the first business day after  %1.',
								this._formatStartDate(enpsData.sendDate, newSendDate),
							)}
						/>
						{this._renderSurveyFrequencySelector()}
						<PreviewEmailModal
							content={previewEmailContent}
							isVisible={isPreviewEmailModalOpen}
							onCloseModal={this._handlePreviewEmailClose}
							title={$.__('Employee Satisfaction Survey Email')}
						/>
					</Section.Subsection>,
					<>
						<div className={classNames('Setup__stepHeading')}>
							{$.__(
								'Employee Satisfaction Survey Email will be sent to all employees on the first business day after  %1.',
								this._formatStartDate(enpsData.sendDate, newSendDate),
							)}
						</div>
						<p className="Setup__stepParagraph">
							{$.__(
								'Survey will close 6 business days after %1.',
								this._formatStartDate(enpsData.sendDate, newSendDate),
							)}
						</p>

						{this._renderSurveyFrequencySelector()}

						{
							<Button
								clickAction={this._handlePreviewEmailOpen}
								iconBefore="eye-16x12"
								outline={false}
								secondary={true}
								size="small"
								text={previewLabel}
								type="button"
							/>
						}
						<PreviewEmailModal
							content={previewEmailContent}
							isVisible={isPreviewEmailModalOpen}
							onCloseModal={this._handlePreviewEmailClose}
							title={$.__('Employee Satisfaction Survey Email')}
						/>
					</>,
				)}
			</EnpsSection>
		);
	}
}
