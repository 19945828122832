import {
	BasicLifeCategoryDataOptionsContract,
	BasicLifeOrADandDEnum,
	LifeInsuranceTypeEnum,
	PlanWizardGetContract,
	VoluntaryLifeOrADandDEnum,
} from '../../types';
import { PlanTypeEnumContract } from '../../../types';
import { makeLifePlanTypeLikeValue } from '../life-plan-type-like-factory';

export const getLifeInsuranceTypeValue = (wizardContract: PlanWizardGetContract) : LifeInsuranceTypeEnum | null => {
	const { benefitPlan, planType } = wizardContract;
	if (planType.type === PlanTypeEnumContract.life) {
		const planCategoryDataOptions = benefitPlan?.planTypeData
			?.planCategoryDataOptions as BasicLifeCategoryDataOptionsContract;
		return makeLifePlanTypeLikeValue(
			planCategoryDataOptions?.type ??
			planCategoryDataOptions?.lifeInsuranceType,
			{
				[LifeInsuranceTypeEnum.basic]: () => LifeInsuranceTypeEnum.basic,
				[BasicLifeOrADandDEnum.life]: () => LifeInsuranceTypeEnum.basic,
				[BasicLifeOrADandDEnum.add]: () => LifeInsuranceTypeEnum.basic,
				[BasicLifeOrADandDEnum.both]: () => LifeInsuranceTypeEnum.basic,
				[LifeInsuranceTypeEnum.voluntary]: () => LifeInsuranceTypeEnum.voluntary,
				[VoluntaryLifeOrADandDEnum.life]: () => LifeInsuranceTypeEnum.voluntary,
				[VoluntaryLifeOrADandDEnum.add]: () => LifeInsuranceTypeEnum.voluntary,
				[VoluntaryLifeOrADandDEnum.both]: () => LifeInsuranceTypeEnum.voluntary,
			},
			() => null,
		);
	}
	return null;
};
