import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	useStyles,
} from './employee-tax-box-content.styles';
import {
	TITLE_KEYS,
	getDetails,
	getDisplayValue,
} from './employee-tax-box-content-state-tax-withholding.domain';
import {
	EmployeeTaxBoxContentStateTaxWithholdingProps,
} from '../../utils/interfaces';

export function EmployeeTaxBoxContentStateTaxWithholding({
	isSelected,
	metadata,
}: EmployeeTaxBoxContentStateTaxWithholdingProps): JSX.Element {
	const styles = useStyles({
		isSelected,
	});

	return (
		<div className={ styles.root }>
			<p className={ styles.title }>
				<Message
					params={ TITLE_KEYS.map(key => getDisplayValue(metadata[key])) }
					text={ $._('Location/State: {1}/{2}') }
				/>
			</p>
			<div className={ styles.details }>
				{ getDetails(metadata).map(detail => (
					<p
						key={ `${ detail.text }_${ detail.params[0] }` }
						className={ styles.detail }
					>
						<Message
							params={ detail.params }
							text={ detail.text }
						/>
					</p>
				)) }
			</div>
		</div>
	);
}
