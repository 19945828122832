import { IconV2, LayoutBox, TextButton } from '@fabric/button';
import QRCode from 'qrcode.react';
import { BEM } from '@utils/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { closeModal, showModal } from 'show-modal.mod/index';
import { MultiFactorAuthManualCodeModal } from '../index';
import { APP_LINK_ANDROID, APP_LINK_IOS, generateQrCode } from '../../domain';

import '../interstitial-setup.react/interstitial-setup.styl';
type InterstitialSetupProps = {
	secret: string;
	userEmail: string;
};

const bem = new BEM('InterstitialSetup');

export function AuthSetupComponent(props: InterstitialSetupProps): JSX.Element {
	const { userEmail, secret } = props;

	const companyDomain = window.location.hostname;

	const QrCodeValue = generateQrCode(userEmail, secret, companyDomain);

	const instructionText = ifFeature('encore', $._('Follow these instructions to set up your Authenticator App.'), $._('**Follow these instructions to set up your Authenticator App.**'));

	return (
		<div>
			<div className={bem.elem('Instructions')}>
				<Message text={instructionText} />
			</div>
			<div className={bem.elem('Setup')}>
				<div className={bem.elem('Steps')}>
					<div className={bem.elem('Step')}>
						{ifFeature('encore', <LayoutBox marginRight={1} marginTop={0.25}><IconV2 color='neutral-medium' name='circle-1-regular' size={16} /></LayoutBox>, <div className={bem.elem('StepNumber')}>1</div>)}
						<div className={bem.elem('StepText')}>
							<Message text={$._('Visit the App Store on your mobile device.')} />
						</div>
					</div>
					<div className={bem.elem('Step')}>
						{ifFeature('encore', <LayoutBox marginRight={1} marginTop={0.25}><IconV2 color='neutral-medium' name='circle-2-regular' size={16} /></LayoutBox>, <div className={bem.elem('StepNumber')}>2</div>)}
						<div className={bem.elem('StepText')}>
							<Message text={$._('Search and download an Authenticator app.')} />
							<br />
							<Message
								link1={ExternalLink(APP_LINK_IOS)}
								link2={ExternalLink(APP_LINK_ANDROID)}
								text={$._('Google Authenticator for example [iOS](1) | [Android](2).')}
							/>
						</div>
					</div>
					<div className={bem.elem('Step')}>
						{ifFeature('encore', <LayoutBox marginRight={1} marginTop={0.25}><IconV2 color='neutral-medium' name='circle-3-regular' size={16} /></LayoutBox>, <div className={bem.elem('StepNumber')}>3</div>)}
						<div className={bem.elem('StepText')}>
							<Message text={$._('Scan the QR Code to the right with the Google Authenticator app.')} />
						</div>
					</div>
				</div>
				<div className={bem.elem('QR')}>
					<div className={bem.elem('QRCode')}>
						<QRCode value={QrCodeValue} />
					</div>
					<div className={bem.elem('QRManual')}>
						<TextButton
							clickAction={() => {
								showModal(<MultiFactorAuthManualCodeModal code={secret} onClose={closeModal} />);
							}}
							size='small'
							text={$.__('Enter Code Manually')}
							type='button'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
