import { Fragment } from 'react';

import { BankAccountForm } from '../../../components/bank-account-form';
import { BankInfoHeader } from '../../../components/bank-info-header';

import { nullBankAccount } from '../../../utils/shared';

export function PreBankStateComponent(props) {
	const { company, onSubmit, isProcessing, onInputChange } = props;

	return (
		<Fragment>
			<BankInfoHeader />

			<BankAccountForm
				bankAccount={nullBankAccount}
				company={company}
				isProcessing={isProcessing}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
			/>
		</Fragment>
	);
}
