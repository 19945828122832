import { createStyles, makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => {
	return createStyles({
		// @startCleanup encore 
		icon: {
			fill: palette.primary.main,
			margin: '34px auto 20px auto',
			textAlign: 'center'
		},
		// @endCleanup encore
		tryItText: {
			fontSize: typography.fabricFontSize('large'),
			fontWeight: typography.fontWeightSemibold,
			textAlign: 'center',
			marginBottom: '20px'
		},
		topText: {
			color: palette.gray[700],
			fontWeight: typography.fontWeightLight
		},
		bottomText: {
			color: palette.gray[700],
			fontWeight: typography.fontWeightLight,
			marginTop: '20px'
		},
		// @startCleanup encore
		separator: {
			color: '#ccc',
			height: '2px',
			margin: '28px 0 25px 0'
		},
		// @endCleanup encore
		// @startCleanup encore
		subHeader: {
			color: palette.primary.main,
			fontWeight: typography.fontWeightSemibold,
			textAlign: 'center',
			marginBottom: '18px'
		},
		// @endCleanup encore
		// @startCleanup encore
		label: {
			display: 'block',
			marginBottom: '7px',
			fontSize: typography.fabricFontSize('small'),
			color: palette.gray[700],
		},
		labelTop: {
			'& + div.fab-Select': {
				marginBottom: '10px'
			}
		},
		labelBottom: {
			'& + div.fab-Select': {
				marginBottom: '48px'
			}
		}
		// @endCleanup encore
	});
});
