export function VerifiedOnlyBody(props) {
	const {
		typeData,
		continueLink,
	} = props;

	return (
		<>
			<div className="EmailVerification__instructionContainer">
				<p className="EmailVerification__instruction">{ typeData.bodyText }</p>
				<p className="EmailVerification__instruction">{ $.__('Happy Bambooing!') }</p>
			</div>
			<a className="fab-Button fab-Button--biggie" href={ continueLink }>{ $.__('Continue') } &raquo;</a>
		</>
	);
}
