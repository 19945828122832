import { useState } from 'react';
import { BodyText, Divider, Flex, Grid, IconV2, LayoutBox, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { InternalLink, Message } from '@bamboohr/utils/lib/message';
import { TextButton as LegacyTextButton} from '@fabric/button';

import { EditCompanyInfoModal } from './edit-company-info-modal';
import { useReducerContext } from '../state';

import './company-info.styl';

export function CompanyInfo() {
	const {
		companyInfo,
		isBambooHrPayrollEnabled,
	} = useReducerContext();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		ifFeature('encore',
		<LayoutBox marginTop={1}>
			<Flex alignItems='center' justifyContent='space-between'>
				<BodyText color='neutral-strong' component='span' id='company-information' size='large' weight='semibold'>
					{$.__('Company Information') }
				</BodyText>
				{ (!isBambooHrPayrollEnabled) && (
					<LayoutBox>
							<TextButton
								color='secondary'
								onClick={ () => {
									setIsEditModalOpen(true);
								} }
								startIcon={<IconV2 color='neutral-medium' name='pencil-solid' size={16} />}
								title={$.__('Change Company Info')}
								type="button"
							>
								{ $.__('Change Company Info') }
							</TextButton>
							<EditCompanyInfoModal
								companyInfo={ companyInfo }
								isModalOpen={ isEditModalOpen }
								onModalClose={ () => {
									setIsEditModalOpen(false);
								} }
							/>
					</LayoutBox>
				) }
			</Flex>
			{ (isBambooHrPayrollEnabled) ? (
				<LayoutBox marginBottom={1}>
					<BodyText color="neutral-strong" component="p" id='payroll-company-information' size='small' weight='regular'>
						<LayoutBox marginTop={2}>
							<Message
								link={InternalLink("/settings/payroll/company_information")}
								text={$.__('The Employer section of your 1095-C exports will be populated with company information found [here].')}
							/>
						</LayoutBox>
					</BodyText>
					<Divider color='neutral-extra-weak' light={true} />
				</LayoutBox>
			) : (
				<LayoutBox>
					<LayoutBox marginBottom={2}>
						<BodyText color="neutral-strong" component="p" id='company-information-description' size='small' weight='regular'>
							{ $.__('This is the information that is used to populate the Employer section of your 1095-C exports.') }
						</BodyText>
					</LayoutBox>
					<LayoutBox marginBottom={2}>
						<Grid columnSpacing={4} columns={2} container={true}>
							<Grid item={true}>
								<LayoutBox marginBottom={0.5}>
									<BodyText color="neutral-weak" component="span" id='company-name-label' size='extra-small' weight='regular'>
										{ `${ $.__('Company Name') } (${ $.__('Legal Full Name') })` }
									</BodyText>
								</LayoutBox>
								<BodyText color="primary" component="p" id='company-name' size='medium' weight='semibold'>
										{ companyInfo.companyName }
								</BodyText>
							</Grid>
							<Grid item={true}>
								<LayoutBox marginBottom={0.5}>
									<BodyText color="neutral-weak" component="span" id='company-ein-label' size='extra-small' weight='regular'>
										{ $.__('EIN #') }
									</BodyText>
								</LayoutBox>
								<BodyText color="primary" component="p" id='company-ein' size='medium' weight='semibold'>
									{ companyInfo.ein }
								</BodyText>
							</Grid>
						</Grid>
					</LayoutBox>
					<LayoutBox marginBottom={2}>
						<Grid container={true}>
							<Grid item={true}>
								<LayoutBox marginBottom={0.25}>
									<BodyText color="neutral-weak" component="span" id='company-contact-phone-label' size='extra-small' weight='regular'>
										{ $.__('Contact Phone #') }
									</BodyText>
								</LayoutBox>
								<BodyText color="primary" component="p" id='company-contact-phone' size='medium' weight='semibold'>
									{ companyInfo.contactPhone }
								</BodyText>
							</Grid>
						</Grid>
					</LayoutBox>
					<LayoutBox marginBottom={2}>
						<Grid container={true}>
							<Grid item={true}>
								<LayoutBox marginBottom={0.25}>
									<BodyText color="neutral-weak" component="span" id='company-address-label' size='extra-small' weight='regular'>
										{ $.__('Company Address') }
									</BodyText>
								</LayoutBox>
								<LayoutBox marginBottom={0.25}>
									<BodyText color="primary" component="p" id='company-address' size='medium' weight='semibold'>
											{ companyInfo.employerAddress }
									</BodyText>
								</LayoutBox>
								<LayoutBox marginBottom={0.25}>
									<BodyText color="primary" component="p" id='company-city-state-zip' size='medium' weight='semibold'>
										{ `${ companyInfo.city }, ${ companyInfo.state.name } ${ companyInfo.zipCode }` }
									</BodyText>
								</LayoutBox>
								<LayoutBox marginBottom={0.25}>
									<BodyText color="primary" component="p" id='company-address-country' size='medium' weight='semibold'>
										{ companyInfo.country.name }
									</BodyText>
								</LayoutBox>
							</Grid>
						</Grid>
						<LayoutBox marginBottom={2} marginTop={1}>
							<Divider color='neutral-extra-weak' light={true} />
						</LayoutBox>
					</LayoutBox>
				</LayoutBox>
			) }
		</LayoutBox>, 
		<div className="SettingsAccountACA__section">
			<div className="CompanyInfo__sectionHeading">
				<h5>
					{ $.__('Company Information') }
				</h5>
				{ (!isBambooHrPayrollEnabled) && (
					<>
						<div className="CompanyInfo__spacer"></div>
						<div className="CompanyInfo__edit">
							<LegacyTextButton
								clickAction={ () => {
									setIsEditModalOpen(true);
								} }
								iconBefore="fab-pencil-14x14"
								muted={ true }
								text={ $.__('Change Company Info') }
							/>
							<EditCompanyInfoModal
								companyInfo={ companyInfo }
								isModalOpen={ isEditModalOpen }
								onModalClose={ () => {
									setIsEditModalOpen(false);
								} }
							/>
						</div>
					</>
				) }
			</div>

			{ (isBambooHrPayrollEnabled) ? (
				<div className="CompanyInfo__row">
					<p>
						{ $.__('The Employer section of your 1095-C exports will be populated with company information found ') }
						<a href="/settings/payroll/company_information" rel="noopener noreferrer" target="_blank">{ $.__('here.') }</a>
					</p>
				</div>
			) : (
				<>
					<div className="CompanyInfo__row">
						<p>
							<span className="CompanyInfo__description">{ $.__('This is the information that is used to populate the Employer section of your 1095-C exports.') }</span>
						</p>
					</div>

					<div className="CompanyInfo__row">
						<div className="CompanyInfo__column">
							<span className="CompanyInfo__label">
								{ `${ $.__('Company Name') } (${ $.__('Legal Full Name') })` }
							</span>
							<div className="CompanyInfo__info">
								<span className="CompanyInfo__info--branded">
									{ companyInfo.companyName }
								</span>
							</div>
						</div>

						<div className="CompanyInfo__column">
							<span className="CompanyInfo__label">
								{ $.__('EIN #') }
							</span>
							<span className="CompanyInfo__info--branded">
								{ companyInfo.ein }
							</span>
						</div>
					</div>

					<div className="CompanyInfo__row">
						<div className="CompanyInfo__column">
							<span className="CompanyInfo__label">
								{ $.__('Contact Phone #') }
							</span>
							<div className="CompanyInfo__info">
								<span className="CompanyInfo__info--branded">
									{ companyInfo.contactPhone }
								</span>
							</div>
						</div>
					</div>

					<div className="CompanyInfo__row">
						<div className="CompanyInfo__column">
							<span className="CompanyInfo__label">
								{ $.__('Company Address') }
							</span>
							<div className="CompanyInfo__info">
								<span className="CompanyInfo__info--branded">
									{ (companyInfo && companyInfo.employerAddress && companyInfo.city && companyInfo.state && companyInfo.zipCode && companyInfo.country) && (
										<>
											{ companyInfo.employerAddress }
											<br />
											{ `${ companyInfo.city }, ${ companyInfo.state.name } ${ companyInfo.zipCode }` }
											<br />
											{ companyInfo.country.name }
										</>
									) }
								</span>
							</div>
						</div>
					</div>
				</>
			) }
			<hr className="sg_hr" />
	</div>)
	);
}
