import { Fragment, useState } from 'react';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { BankAccountForm } from '../../../components/bank-account-form';

import { StatusRow } from '../../../../company-information.react/components/status-row';
import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';

import { BANK_APP_STATE } from '../../../utils/shared';
import { uuidGenerator } from '../../../../company-information.react/utils/shared.js';
import { AccountVerificationModal } from '../../../components/account-verification-modal/account-verification-modal';

export function DocsUploadedStateComponent(props) {
	const { company, onSubmit, isProcessing, onInputChange, plaidLinkToken, plaidUpdateSuccess } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const status = {
		company,
		state: BANK_APP_STATE.DOCS_UPLOADED,
	};

	const handleVerificationsModalSubmit = () => {
		const { onVerificationModalSubmit } = props;

		onVerificationModalSubmit();
	};

	const hasPenniesBeenStored = () => {
		const { company } = props;

		return company.bankAccounts[0].penniesHasBeenStored;
	};

	const statusRowStatus = hasPenniesBeenStored() ? BANK_APP_STATE.VERIFYING_MANUAL_MODE : BANK_APP_STATE.DOCS_UPLOADED;

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			{!hasPenniesBeenStored() && (
				<InfoCardManager buttonFunction={setIsModalOpen} clientId={company.clientId} onFileDownload={() => {}} statuses={[status]} />
			)}

			<StatusRow clickAction={setIsModalOpen} status={statusRowStatus} />

			<BankAccountForm
				isEdit
				bankAccount={company.bankAccounts[0]}
				company={company}
				isProcessing={isProcessing}
				isVerified={true}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
				plaidLinkToken={plaidLinkToken}
				plaidUpdateSuccess={plaidUpdateSuccess}
				showCompletedFormsSection={true}
			/>

			<AccountVerificationModal
				key={uuidGenerator()}
				accountNum={company.bankAccounts[0].accountNumber}
				bankId={company.bankAccounts[0].bankId}
				bankName={company.bankAccounts[0].accountNickname}
				clientId={company.clientId}
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSubmit={handleVerificationsModalSubmit}
			/>
		</Fragment>
	);
}
