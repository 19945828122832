import Ajax from '@utils/ajax';

import { FiltersPayload } from '../types';

export async function saveGoalFilters(filtersPayload: FiltersPayload, selectedGoalGroup: string): Promise<boolean> {
	const payload = {
		goalsEligibleParticipants: selectedGoalGroup,
		filters: filtersPayload || {},
	};

	return new Promise((resolve, reject) => {
		Ajax.post('/settings/performance/home/goals/save', payload)
			.then((response) => {
				const { data = {} } = response;

				if (data && data.success) {
					resolve(data);
				}

				reject(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
