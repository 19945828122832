import Ctrl, {onReady, onPjaxEnd} from 'ctrl.deco';
import { getJsonData } from 'Data.util';
import { render } from 'base/wrapped-render';
import StatusTable from './component';

const APP_ELEM = 'settings-statuses-app';

@Ctrl('/settings/jobs/')
export default class CandidateStatuses {
	constructor() {
		this._pageData = getJsonData('#js-statuses', true);
	}

	@onReady()
	_readyHandler(e, ctrl) {
		ctrl.renderStatusesPage(ctrl._pageData);
	}

	@onPjaxEnd()
	_pjaxEndHandler(e, ctrl) {
		window.requestAnimationFrame(() => {
			const pageData = getJsonData('#js-statuses', true);
			ctrl.renderStatusesPage(pageData);
		});
	}

	renderStatusesPage(pageData) {
		render(
			<StatusTable { ...pageData } />,
			document.getElementById(APP_ELEM)
		);
	}

}
