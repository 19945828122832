import moment from 'moment';

import { BenefitPlanListContract } from '../types/contracts';

export function isPlanExpired(plan: BenefitPlanListContract): boolean {
	if (plan && plan.endDate) {
		return (moment(plan.endDate).isBefore(moment()));
	}
	return false;
}
