import Ajax from '@utils/ajax';

const POST = () => {
	return Ajax.post(`/settings/time_tracking/enable`).then((response) => {
		if (response.status !== 200) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
		return response;
	});
};

export default POST;
