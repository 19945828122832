import {
	useState,
} from 'react';
import classNames from 'classnames';
import {
	TextButton,
} from '@fabric/button';

import {
	handleAddName,
	handleEditName,
} from './utils';

import {
	NormalizedDeductions,
} from '../../utils';

interface DeductionNameInputProps {
	managedDeductionType: string;
	deductions: NormalizedDeductions;
	setCurrentDeductions(newDeductions: NormalizedDeductions): void;
	setEditingId(id: number|null): void;
	setShowInput?(show: boolean): void;
	companyDeductionId: number;
    isEdit: boolean;
    deductionName: string;
}

export function DeductionNameInput(props: DeductionNameInputProps): JSX.Element {
	const {
		managedDeductionType,
		deductions,
		setCurrentDeductions,
		companyDeductionId,
		isEdit,
		deductionName,
		setEditingId,
		setShowInput,
	} = props;
	const [saveProcessing, setSaveProcessing] = useState(false);
	const [localName, setLocalName] = useState(deductionName || '');
	const saveButtonClasses = classNames(
		'fab-Button',
		{ 'fab-Button--processing': saveProcessing }
	);
	const cancelHandler = isEdit ? (): void => setEditingId(null) : (): void => setShowInput(false);
	const saveHandler = isEdit ?
		(): void => handleEditName(
			companyDeductionId,
			managedDeductionType,
			localName,
			deductions,
			setSaveProcessing,
			setCurrentDeductions,
			() => setEditingId(null)
		) :
		(successCallback: Function|null): void => handleAddName(
			localName,
			managedDeductionType,
			deductions,
			setSaveProcessing,
			setCurrentDeductions,
			successCallback
		);

	return (
		<div className="DeductionNameInput">
			<span className="fab-FormField">
				<div className="fab-InputWrapper">
					<input
						className="fab-TextInput fab-TextInput--width8"
						onChange={ (event): void => setLocalName(event.target.value) }
						onKeyDown={ (event): void => {
							if (event.keyCode === 13) {
								saveHandler(() => setLocalName(''));
							}
						} }
						value={ localName }
					/>
					<button
						className={ saveButtonClasses }
						disabled={ saveProcessing }
						onClick={ (): void => saveHandler(() => setShowInput(false)) }
						type="button"
					>
						{ isEdit ? $.__('Save') : $.__('Add') }
					</button>
				</div>
			</span>

			<span className="fab-FormField">
				<TextButton
					clickAction={ cancelHandler }
					text={ $.__('Cancel') }
					type="button"
				/>
			</span>

			{ !isEdit && (
				<span className="fab-FormNote">
					{ $.__('Press return to quickly add multiple names') }.
				</span>
			) }
		</div>
	);
}
