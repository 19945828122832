import { useEffect } from 'react';

import c from 'classnames';

import { TaxIdFormatNote } from '../tax-id-format-note/tax-id-format-note';

import './tax-id-field.styl';
export function TaxIdField(props) {
	const {
		customClass,
		disabled,
		error,
		name,
		placeholder,
		selectedTaxType,
		updateFormData,
		value,
		width,
	} = props;

	useEffect(() => {
		if (value === 'Applied For' && $('#waitingForTaxId').prop('checked') === false) {
			$('#waitingForTaxId').trigger('click');
		}
	}, [value]);

	const inputClassName = c(
		'TaxIdField__input',
		'fab-TextInput',
		{ [`fab-TextInput--width${ width }`]: width },
		{ [`${ customClass }`]: customClass }
	);

	function handleOnChange(e) {
		updateFormData(name, { error: false, value: e.target.value });
	}

	function toggleTaxIdRequired(e) {
		if (e.target.checked) {
			updateFormData(name, { value: 'Applied For', disabled: true, error: false, required: false });
		} else {
			updateFormData(name, { value: null, disabled: false, error: false, required: true });
		}
	}

	return (
		<div className="TaxIdField">
			<div className="TaxIdField__inputs">
				<input
					className={ inputClassName }
					disabled={ disabled }
					name={ name }
					onChange={ handleOnChange }
					placeholder={ disabled ? $.__('Applied For') : placeholder }
					value={ disabled ? '' : (value || '') }
				/>

				<div className="fab-Checkbox">
					<input
						className="fab-Checkbox__input"
						id="waitingForTaxId"
						onChange={ toggleTaxIdRequired }
						type="checkbox"
					/>
					<label className="fab-Checkbox__label" htmlFor="waitingForTaxId">{ $.__('Waiting for Tax ID') }</label>
				</div>
			</div>

			<TaxIdFormatNote error={ error } selectedTaxType={ selectedTaxType } />

		</div>

	);
}
