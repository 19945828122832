import { forOwn } from 'lodash';

import { FiltersPayload } from '../types';

export const unpackFiltersToQueryParams = (selectedFilters: FiltersPayload): string => {
	let filterParamsString = '';

	if (selectedFilters) {
		forOwn(selectedFilters, (filterIds: [string], categoryValue) => {
			if (filterIds) {
				filterIds.forEach((filterId) => {
					if (filterId) {
						filterParamsString += `&${categoryValue}=${filterId}`;
					}
				});
			}
		});
	}

	return filterParamsString;
};
