import { StandardModal, Button, TextButton, Checkbox, makeStyles, StyledBox, IconV2 } from '@bamboohr/fabric';
import { TriangleExclamation54x54 } from '@bamboohr/grim';
import { useState } from 'react';
import { putUnlockCompanyInformation } from '../../../utils/shared';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/* @startCleanup encore */
const useStyles = makeStyles(({ palette, spacing }) => ({
	warningIcon: {
		fill: palette.warning.main,
		marginTop: spacing(2.5),
	},
}));
/* @endCleanup encore */

export const CompanyNameChangeModal = ({ isOpen, setIsOpen, refreshData, setContactFields, clientId }) => {
	const [confirmed, setConfirmed] = useState(false);
	const [unlockButtonProcessing, setUnlockButtonProcessing] = useState(false);
	/* @startCleanup encore */
	const classes = useStyles();
	/* @endCleanup encore */

	const handleUnlockCompanyLegalName = () => {
		setUnlockButtonProcessing(true);
		putUnlockCompanyInformation({ locked: false }, clientId)
			.then(() => {
				refreshData();
				setContactFields();
				setIsOpen(false);
				setConfirmed(false);
				setUnlockButtonProcessing(false);
			})
			.catch(() => {
				setUnlockButtonProcessing(false);
				window.setMessage($.__('Uh oh...something went wrong. Please try again later.'), 'error');
			});
	};

	const header = <StandardModal.Header title={$.__('Just Checking...')} />;
	const buttons = [
		<Button
			disabled={!confirmed}
			key='unlock'
			onClick={handleUnlockCompanyLegalName}
			processing={unlockButtonProcessing}
			type='button'
		>
			{$.__('Unlock Company Legal Name')}
		</Button>,
		<TextButton key='cancel' onClick={() => setIsOpen(false)} type='button'>
			{$.__('Cancel')}
		</TextButton>,
	];
	const footer = <StandardModal.Footer actions={ifFeature('encore', buttons.toReversed(), buttons)} />;

	return (
		<StandardModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<StandardModal.Body renderFooter={footer} renderHeader={header}>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						iconColor='warning-medium'
						icon={ifFeature('encore', 'triangle-exclamation-solid', <TriangleExclamation54x54 className={classes.warningIcon} />)}
						text={$.__('Are you sure you want to unlock company legal name?')}
						subtext={$.__(
							'This will allow the customer to make a one time edit. You must verify that the customer has provided legal documentation for this change.'
						)}
					>
						<StyledBox
							paddingY={2}
							paddingX={3}
							margin={2}
							backgroundColor='neutral-medium'
							borderRadius={ifFeature('encore', 'small', undefined)}
						>
							<Checkbox
								checked={confirmed}
								onChange={(e) => setConfirmed(e.checked)}
								label={$.__('I have verified that the customer has legal documentation.')}
							/>
						</StyledBox>
					</StandardModal.HeroHeadline>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
