import {
	/* @startCleanup encore */
	makeStyles,
	/* @endCleanup encore */
	BodyText,
	Flex,
	TextButton,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface InactiveProps {
	isRestorable: boolean;
	planId: number;
}

/* @startCleanup encore */
const useStyles = makeStyles({
	reactivateButton: {
		marginLeft: '3px',
	},
});
/* @endCleanup encore */

export function Inactive(props: InactiveProps): JSX.Element {
	const { isRestorable, planId } = props;
	/* @startCleanup encore */
	const classes = useStyles();
	const jade = (
		<div className='Columns__status--inactive'>
			<span>{$.__('Inactive plan')}</span>
			{Boolean(isRestorable) && (
				<TextButton
					className={classes.reactivateButton}
					data-bi-id='reactivate-plan'
					href={`/settings/benefits/plan_wizard/plan/${planId}`}
					inline={true}
					type="button"
				>
					{$.__('Reactivate')}
				</TextButton>
			)}
		</div>
	);
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<Flex alignItems="center" gap={1}>
			<BodyText color="neutral-weak">{$.__('Inactive plan')}</BodyText>
			{Boolean(isRestorable) && (
				<TextButton
					data-bi-id='reactivate-plan'
					inline={true}
					onClick={() => {
						location.href = `/settings/benefits/plan_wizard/plan/${planId}`;
					}}
					type="button"
				>
					{$.__('Reactivate')}
				</TextButton>
			)}
		</Flex>,
		jade,
	);
}
