import {
	ModalHeaderDefault,
} from './modal-header-default';
import {
	ModalHeaderError,
} from './modal-header-error';
import {
	ModalHeaderLoading,
} from './modal-header-loading';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function ModalHeaderConnector() {
	const [{
		isErrorClientTaxSettings,
		isLoadingClientTaxSettings,
	}] = useCompanyTaxSettingsStore();
	if (isLoadingClientTaxSettings) {
		return (
			<ModalHeaderLoading />
		);
	}
	if (isErrorClientTaxSettings) {
		return (
			<ModalHeaderError />
		);
	}
	return (
		<ModalHeaderDefault />
	);
}
