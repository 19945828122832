import { ReactElement } from 'react';
import BenefitsAdminInTrialUpsell from '../../../app-components/upsell/BenefitsAdminInTrialUpsell';
import { SubscriberUpsell } from './subscriber-upsell';
import { OnDemoRequest } from '../';

interface BenefitsAdminUpsellModalProps {
	isCompanyInTrial: boolean;
	isOpen: boolean;
	onClose: () => void;
	onDemoRequest: OnDemoRequest;
}

export const BenefitsAdminUpsellModal = ({
	isCompanyInTrial,
	isOpen,
	onClose,
	onDemoRequest,
}: BenefitsAdminUpsellModalProps): ReactElement => {
	if (isCompanyInTrial) {
		return <BenefitsAdminInTrialUpsell isOpen={isOpen} onClose={onClose} />;
	}

	return <SubscriberUpsell isOpen={isOpen} onClose={onClose} onDemoRequest={onDemoRequest} />;
};
