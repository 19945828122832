import { PureComponent, ReactElement } from 'react';

import { TemplateListBlankState } from './components/blank-state';
import { TemplateListHeader } from './components/header';
import { TemplateList } from './components/template-list';

import './styles.styl';
import { LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface TemplateListData {
	id: string;
	name: string;
}

interface State {
	templateList: Array<TemplateListData>;
}

interface Props {
	templateListData: Array<TemplateListData>;
	handleDelete: (id: string) => Promise<any>;
}

export class NewHirePacketTemplateSettings extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const { templateListData } = props;

		this.state = {
			templateList: templateListData,
		};

		this._deleteTemplate = this._deleteTemplate.bind(this);
	}

	_deleteTemplate(templateId: string): Promise<any> {
		const { handleDelete } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		return handleDelete(templateId).then(() => {
			const { templateList } = self.state;

			const index = templateList.findIndex((templateItem) => {
				return templateItem.id == templateId;
			});

			if (index > -1) {
				const newList = [].concat(templateList);
				newList.splice(index, 1);

				self.setState({
					templateList: newList as Array<TemplateListData>,
				});
			}
		});
	}

	render(): ReactElement {
		const { templateList = [] } = this.state;

		const wrapperClassNames =
			templateList.length > 0 ? 'NHPTemplateSettings__wrapper' : 'NHPTemplateSettings__wrapper NHPTemplateSettings__wrapper--empty';

		return ifFeature(
			'encore',
			<LayoutBox>
				<LayoutBox>
					<TemplateListHeader firstTemplate={templateList.length === 0} isBlankState={templateList.length === 0} />
				</LayoutBox>
				{templateList.length === 0 && <TemplateListBlankState />}
				{templateList.length > 0 && (
					<LayoutBox paddingTop={ifFeature('encore', 1.5)}>
						<TemplateList handleDelete={this._deleteTemplate} templateListData={templateList} />
					</LayoutBox>
				)}
			</LayoutBox>,
			<div className='NHPTemplateSettings'>
				<div className={wrapperClassNames}>
					<TemplateListHeader firstTemplate={templateList.length === 0} />
				</div>
				{templateList.length === 0 && <TemplateListBlankState />}
				{templateList.length > 0 && <TemplateList handleDelete={this._deleteTemplate} templateListData={templateList} />}
			</div>
		);
	}
}
