import Ctrl, {
	onChange,
	onClick,
} from 'ctrl.deco';

import {
	mapValues
} from 'lodash';

import mainTmpl from './main.micro.html';
import tableTmpl from './table.micro.html';
import emptyTmpl from './empty.micro.html';
import modalTmpl from './modal.micro.html';
import poTmpl from './po.micro.html';

import { isEnabled } from 'FeatureToggle.util';
import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';

let $main;
let $tableWrapper;
let $filterByYear;

const HISTORY_ENDPOINT_URL = '/settings/account/time_tracking/history/%1';
const MODAL_ENDPOINT_URL = '/settings/account/time_tracking/history/import/%1';
const YEARS_ENDPOINT_URL = '/settings/account/time_tracking/years';
const CURRENT_YEAR = moment().year();

import './styles.styl';

@Ctrl('/settings/account/time_tracking')
class SettingsAccountTimeTrackingCtrl {
	get title() {
		return $.__('Import Hours');
	}

	get mainTmpl() {
		return poTmpl;
	}

	get tableTmpl() {
		return tableTmpl;
	}

	@onClick('[data-action="showImportHistory"]')
	onShowImportHistory(e, ctrl) {
		let $row = $(this).closest('tr');

		ctrl.showImportResultsModal({
			id: $row.attr('data-id'),
			date: moment.utc($row.attr('data-date'))
		});
	}

	onMainReady($_main) {
		render(
			<PoMicroFrontend
				onImportClick={(id) => {
					this.showImportResultsModal({ id });
				}}
				route='/time-tracking/import-settings'
			/>,
			document.getElementById('po-hours-importer')
		);
	}

	renderTable(currentYear = CURRENT_YEAR) {
		$tableWrapper.addClass('hideWhileProcessing--processing');
		let start = new Date();

		$.getJSON(sprintf(HISTORY_ENDPOINT_URL, currentYear), (result) => {
			if (!result.success) {
				setMessage(result.errorMessage);
				console.error(result.error);
				return;
			}

			$('.js-SettingsAccountTimeTracking__filterWrapper')
				.toggle(result.years.length > 0);

			const buildOption = year => `
			<ba-option
				${ year == currentYear ? 'selected' : '' }
				value=${ year }
			>
				${ year == CURRENT_YEAR ? $.__('Year-to-Date') : year }
			</ba-option>`;

			$filterByYear.html(result.years.map(year => buildOption(year)));

			// if request takes less than 200ms,
			// we need to wait until the fade-out is finished
			let duration = new Date() - start;
			duration = (duration > 0) ? Math.min(duration, 200) : 0;

			if (result.years.length > 0) {
				$tableWrapper
					.html(microTemplate(this.tableTmpl, {
						importHistory: result.importHistory
					}));
			} else {
				$tableWrapper
					.html(microTemplate(emptyTmpl, {}));
			}

			setTimeout(() => {
				$tableWrapper
					.removeClass('hideWhileProcessing--processing');
			}, duration);
		})
			.fail((xhr, status, err) => {
				setMessage($.__('Uh Oh! There was an error. Please try again or contact BambooHR support.'), 'error');
				console.error(err);
			})
	}

	showImportResultsModal(row) {
		let ctrl = this;

		ctrl.renderResultsModal(row);

		$(document).on('click', '[data-action="modalNav"]', function() {
			let $this = $(this);
			ctrl.renderResultsModal({
				id: $this.attr('data-id'),
				date: moment($this.attr('data-date'), 'll')
			});
		});
	}

	renderResultsModal(row) {
		$.getJSON(sprintf(MODAL_ENDPOINT_URL, row.id))
			.done((result) => {
				if (!result.success) {
					setMessage(result.errorMessage, 'error');
					console.error(result.error);
					return;
				}

				let formattedDate = moment.utc(result.importInfo.date).format('ll');

				let modalTmplData = {
					title: $.__('%1 Hours Import', formattedDate),
					importedRows: result.importedRows,
					importedCount: parseInt(result.importInfo.importedRows),
					problemCount: parseInt(result.importInfo.errorCount),
					headers: result.importResultsTableHeaders,
					nav: mapValues(result.navigation, (nav) => {
						if (nav) {
							nav.date = moment.utc(nav.date).format('ll');
						}

						return nav;
					})
				};

				window.BambooHR.Modal.setState({
					alternativeAction: null,
					dangerousContent: microTemplate(modalTmpl, modalTmplData),
					isOpen: true,
					primaryAction: () => window.BambooHR.Modal.setState({ isOpen: false }),
					primaryActionText: $.__('Close'),
					title: $.__('Result of Hours Import on %1', formattedDate),
					type: 'fullScreen'
				});
			})
			.fail((xhr, status, err) => {
				setMessage($.__('Uh Oh! There was an error. Please try again or contact BambooHR support.'), 'error');
				console.error(err);
			});
	}
}
