import {
	StandardModal,
} from '@bamboohr/fabric';

import {
	L_COMPANY_TAX_SETTINGS,
} from '../language';

export function ModalHeaderError() {
	return (
		<StandardModal.Header
			hasCloseButton={ true }
			title={ L_COMPANY_TAX_SETTINGS }
		/>
	)
}
