import {
	Modal,
} from 'modal-legacy';
import {
	Moment,
} from 'moment';
import {
	BEM,
} from '@utils/dom';

import moment from 'moment.lib';

import {
	calculateTimeRemaining,
} from '../../domain';

import './reminder-modal.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type ReminderModalProps = {
	startDate: Moment;
	onActionDelay(): void;
	onActionSetup(): void;
}

const bem = new BEM('ReminderModal');
const NOW = moment() as Moment;
const TODAY = NOW.startOf('day');

export function ReminderModal(props: ReminderModalProps): JSX.Element {
	const {
		startDate,
		onActionDelay,
		onActionSetup,
	} = props;
	const remainingDaysDiff = calculateTimeRemaining(startDate, TODAY, 'days');
	const remainingDaysText = $.__n(
		'In %1$s day 2-Step Login will be Required',
		'In %1$s days 2-Step Login will be Required',
		remainingDaysDiff
	);

	return (
		<Modal
			alternativeAction={ null }
			icon={ifFeature('encore', 'lock-regular', "fab-lock-45x54")}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore--next-line
			iconColor=""
			iconV2Color={ifFeature('encore', 'primary-strong')}
			isOpen={ true }
			onClose={ onActionDelay }
			primaryAction={ onActionSetup }
			primaryActionText={ $.__('Setup Now') }
			secondaryAction={ onActionDelay }
			secondaryActionText={ $.__('Not Yet') }
			title={ $.__('Just so you know...') }
		>
			<div className={ bem.elem('content') }>
				<div className={ bem.elem('header') }>
					<h4 className={ bem.elem('headerTitle') }>
						{ remainingDaysText }
					</h4>
				</div>
				<div className={ bem.elem('body') }>
					<span>
						{ $.__('To protect your private information, you will need to provide a verification code from your mobile device. It’s easy to setup and only takes a few minutes.') }
					</span>
					<div className={ bem.elem('body', 'block') }>
						{ $.__('Would you like to do that now?') }
					</div>
				</div>
			</div>
		</Modal>
	);
}
