import { Fragment } from 'react';
import { Table } from '@bamboohr/fabric';

import { DeductionDateSelect, DeductionDateOption } from 'benefits/deduction-date-select.react';

import { formatDataForConfigureTable } from './service';

import './deduction-migration-configure.styl';

export function DeductionMigrationConfigure(props) {
	const {
		individualPlans,
		isConfigureMode,
		onConfigChange,
		rollupPlans,
		subHeadingContent,
	} = props;

	function handleRowChange(selected, plan) {
		const key = (plan.isRollup) ? plan.type : plan.id;
		if (selected && selected.length) {
			const values = selected[0].split('-');
			if (values && values.length === 3) {
				onConfigChange(key, {
					type: plan.type,
					deductionRuleDirection: values[1],
					deductionRuleNumber: Number(values[2]),
					id: plan.id,
					isRollup: plan.isRollup,
					name: plan.name,
				}, plan.isRollup);
			}
		} else if (selected) {
			onConfigChange(key, null, plan.isRollup);
		}
	}

	return (
		<div className="DeductionMigrationConfigure">
			<div className="DeductionMigrationConfigure__heading">
				{ (isConfigureMode) ? (
					<h3>{ $.__('BambooHR now supports pre-paying for benefits') }</h3>
				) : (
					<h4>{ $.__('You are about to set the payroll deduction schedule for all benefit plans.') }</h4>
				) }
				<div className="DeductionMigrationConfigure__subHeading">{ subHeadingContent }</div>
			</div>
			{ (isConfigureMode) && (
				<div className="DeductionMigrationConfigure__instruction">{ $.__('Select when the first payroll deduction happens relative to the benefit effective date.') }</div>
			) }
			<Table
				caption={ $.__('Benefit deduction migration configure') }
				columns={[
					{
						cell: row => row.name,
						header: $.__('Benefit Plan'),
						key: 'benefitPlan',
					},
					{
						cell: row => (
							<Fragment>
								{ (isConfigureMode) ? (
									<DeductionDateSelect
										onSelectChange={ handleRowChange }
										plan={ row }
									/>
								) : (
									<DeductionDateOption
										direction={ row.deductionRuleDirection }
										number={ row.deductionRuleNumber }
										showMostCommon={ false }
									/>
								) }
							</Fragment>
						),
						header: $.__('When should this benefit deduction start in payroll?'),
						key: 'deductionRule',
					},
				]}
				rowKey={ row => (row.id ? row.id : row.type) }
				rows={ formatDataForConfigureTable(rollupPlans, individualPlans) }
			/>
		</div>
	);
}
