import { IconV2, PageHeader } from '@bamboohr/fabric';
import { MoneyCircle28x28 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { redirect } from 'base/_utils/BambooHR.util';
import React from 'react';

export interface AddEditHeaderProps {
	isAdd: boolean;
}

export function AddEditHeader({ isAdd }: AddEditHeaderProps) {
	return (
		<PageHeader
			icon={ifFeature('encore', <IconV2 name='circle-dollar-solid' size={32} />, <MoneyCircle28x28 />)}
			back={$.__('Company Information')}
			onBack={() => redirect('/settings/payroll/company_information')}
			title={isAdd ? $.__('Add Company/EIN') : $.__('Edit Company Info')}
			divider={ifFeature('encore', false, true)}
		/>
	);
}
