import { render } from 'base/wrapped-render';
import { ClipboardPulse16x20 } from '@bamboohr/grim';
import { isEnabled } from '@bamboohr/utils/lib/feature';

import Ctrl, {
	onPjaxEnd,
	onReady,
} from 'ctrl.deco';
import { App } from './components/app.react';
import { getSettingsData } from './settings-employee-wellbeing-service';

@Ctrl('/settings/employee_wellbeing')
class SettingsEmployeeWellbeing {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		document.title = $.__('Employee Wellbeing');
		getSettingsData().then((data) => {
			render(<App data={ data } />, document.getElementById('employeeWellbeingSurveyReactRoot'));
		});
	}
}

// @startCleanup encore
if (!isEnabled('encore')) {
	const iconRoot = document.getElementById('employeeWellbeingSurveySettingsNavIconReactRoot');
	if (iconRoot) {
		render(<ClipboardPulse16x20 className="SettingsNav__itemIcon"/>, iconRoot);
	}
}
// @endCleanup encore
