import {BodyText, Divider, Flex, LayoutBox, StyledBox, TextButton} from '@bamboohr/fabric';
import {colors} from '@bamboohr/fabric/dist/definitions/json/colors.json';
import {BarChartArrow16x16, Eye16x12} from '@bamboohr/grim';
import {ifFeature} from '@bamboohr/utils/lib/feature';
import {InternalLink, Message} from '@bamboohr/utils/lib/message';
import {Button} from '@fabric/button';
import Ajax from '@utils/ajax';
import {useEffect, useState} from 'react';
import {EnpsHeader} from '../../enps-header';
import {EmbedVideo} from '../../shared/embed-video';
import {EnpsCopyright} from '../../shared/enps-copyright';
import {SurveyPreviewModal} from './survey-preview-modal';

export const Promo = ({ onNext }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showSurveyPreviewModal, setShowSurveyPreviewModal] = useState(false);
	const [hasReportDemoData, setHasReportDemoData] = useState(false);

	const buttonLabel = $.__("Let's get started");

	const summaryText = (
		<Message
			link={InternalLink('https://www.bamboohr.com/blog/ask-an-hr-expert-measuring-employee-satisfaction-with-bamboohr/')}
			strong={{ tag: ({ children }) => <sup style={{ fontSize: '.55em' }}>{children}</sup> }}
			text={$._(
				'Employee Net Promoter Score**SM** (eNPS) is a standard method companies use to measure employee satisfaction and engagement. BambooHR will automate the survey process for you to make it easy as pie. Watch the short video above to learn more, or [read more about it].'
			)}
		/>
	);

	const handleButtonClick = (e) => {
		if (isLoading) return;

		const requestData = {
			endpoint: '',
			method: 'POST',
		};

		e.target.classList.add('processing');
		e.target.setAttribute('disabled', 'disabled');

		setIsLoading(true);
		return onNext(requestData);
	};

	useEffect(() => {
		Ajax.get('/settings/enps/hasDemoData').then(({ data }) => setHasReportDemoData(data.hasDemoData));
	}, []);

	return (
		<>
			{ifFeature('encore', undefined, <EnpsHeader />)}
			<Flex alignItems='center' flexDirection='column' justifyContent='center'>
				<StyledBox
					backgroundColor='neutral-medium'
					borderRadius={ifFeature('encore', 'large', 'small')}
					height={ifFeature('encore', '394px', '260px')}
					marginTop='36px'
					width={ifFeature('encore', '700px', '456px')}
				>
					<EmbedVideo videoSrc='//fast.wistia.net/embed/iframe/g03otpk2nm?videoFoam=true' videoTitle={$.__('Promo Video')} />
				</StyledBox>
				<StyledBox marginTop={5} maxWidth='800px' textAlign='center'>
					<BodyText color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')} size='large' weight='regular'>
						{summaryText}
					</BodyText>
				</StyledBox>
				{hasReportDemoData ? (
					<Flex alignItems='center' justifyContent='center' margin='24px 0 20px'>
						<LayoutBox padding='4px 16px'>
							<TextButton
								onClick={() => setShowSurveyPreviewModal(true)}
								startIcon={ifFeature('encore', 'eye-solid', <Eye16x12 fill={colors.info} />)}
							>
								{$.__('Preview Sample Survey')}
							</TextButton>
						</LayoutBox>
						<Flex height={ifFeature('encore', '18px', '42px')} position='relative' right={ifFeature('encore', '2px')} width={0}>
							<Divider color='neutral-medium' noGap={true} orientation='vertical' />
						</Flex>
						<LayoutBox padding='4px 16px'>
							<TextButton
								href='/reports/enps'
								startIcon={ifFeature('encore', 'chart-column-solid', <BarChartArrow16x16 fill={colors.info} />)}
								target='_blank'
							>
								{$.__('Preview Report (Demo Data)')}
							</TextButton>
						</LayoutBox>
					</Flex>
				) : (
					<LayoutBox margin='16px 0 12px'>
						<BodyText color='neutral-weak' size='medium' weight='regular'>
							{$.__('Ready to start collecting your employees’ feedback?')}
						</BodyText>
					</LayoutBox>
				)}
				<Button onClick={handleButtonClick} size='biggie' type='button'>
					{buttonLabel}
				</Button>
				<LayoutBox marginTop='60px' maxWidth='800px' textAlign='center'>
					<BodyText color='neutral-weak' size='extra-small' weight='regular'>
						<EnpsCopyright />
					</BodyText>
				</LayoutBox>
			</Flex>
			{hasReportDemoData && <SurveyPreviewModal onClose={() => setShowSurveyPreviewModal(false)} visible={showSurveyPreviewModal} />}
		</>
	);
};
