import { EligibilityGroupValues } from '../types';

export type UpdateGroupProperty = (
	key: keyof EligibilityGroupValues,
	value: EligibilityGroupValues[keyof EligibilityGroupValues],
	needsValidation?: boolean
) => void;

export enum EligibilityModals {
	none,
	deleteGroup,
}

export interface GroupEmployeeIds {
	[key: string]: number[];
}

export interface EmployeesInGroups {
	[key: number]: {
		groupId: string;
		groupName: string;
	};
}

export interface SupersetSubsetPair {
	supersetId: string | number;
	subsetId: string | number;
}

