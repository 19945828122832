import c from 'classnames';

import { Popover } from 'popover.react';

import { Icon } from '@bamboohr/fabric';

import { TextButton } from '@fabric/button';

import { generatePopoverHtml } from './utils';

import './tax-id-format-note.styl';
import './tax-id-format-popover.styl';
export function TaxIdFormatNote(props) {
	const {
		error,
		selectedTaxType,
	} = props;

	const noteContent = error ? <Icon name="fab-question-14x14" /> : <TextButton inline={ true } muted={ true } text={ $.__('Where can I find my tax ID?') } type="button" />;

	const classes = c(
		'TaxIdFormatNote',
		{ 'TaxIdFormatNote--error': error }
	);

	const popoverHtml = generatePopoverHtml(selectedTaxType);

	return (
		<div className={ classes }>
			{ error && $.__('Tax ID is not a vaild format for the selected tax type.') }
			<Popover
				settings={ {
					template: {
						name: 'popover-standard',
						data: {
							content: popoverHtml,
							popoverClass: 'TaxIdFormatPopover',
							rawContent: true,
						},
					},
					position: 'right',
				} }
			>
				{ noteContent }
			</Popover>
		</div>

	);
}
