import { Ajax } from "@bamboohr/utils";
import { useEmployeeWellbeingErrorHandler } from "../../../utils";
import { UpcomingSurvey, UpcomingSurveysRequest } from "../../types";
import { EditQuestionLoadData, UpdateQuestionData } from "../types";

type EditQuestionService = (topicId: number, surveyId: number) => Promise<EditQuestionLoadData>;

export const editQuestionService: EditQuestionService = (topicId, surveyId) => {
    return Ajax.get<EditQuestionLoadData>('/settings/employee_wellbeing/topicQuestions/', { topicId, surveyId }).then(({ data }) => data);
};

type UpdateTopicQuestionService = (data: UpdateQuestionData & UpcomingSurveysRequest) => Promise<UpcomingSurvey[]>;

export const updateTopicQuestionService: UpdateTopicQuestionService = (data) => {
    return Ajax.put<UpcomingSurvey[]>('/settings/employee_wellbeing/updateTopicQuestions', data).then(({ data }) => data);
};

interface EditQuestionModalServices {
    editQuestionService: EditQuestionService;
    updateTopicQuestionService: UpdateTopicQuestionService;
}

export const useEditQuestionModalServices = (): EditQuestionModalServices => {
    const handleError = useEmployeeWellbeingErrorHandler();
    return {
        editQuestionService: ((...rest) => editQuestionService(...rest).catch(handleError)) as EditQuestionService,
        updateTopicQuestionService: ((...rest) => updateTopicQuestionService(...rest).catch(handleError)) as UpdateTopicQuestionService,
    };
};
