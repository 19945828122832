import { IconButton, Tooltip } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FloatingIconButton } from '@fabric/button';

export const EnpsStatusActions = (props) => {
	const { status, onEditClick, onPreviewClick } = props;

	return (
		<>
			{status === 'ready' &&
				ifFeature(
					'encore',
					<Tooltip content={$.__('Edit')}>
						<IconButton
							onClick={onEditClick}
							icon="pen-regular"
							color="secondary"
							floatingIcon={true}
							size="small"
							type="button"
							variant="outlined"
						/>
					</Tooltip>,
					<Tooltip content={$.__('Edit')}>
						<FloatingIconButton
							clickAction={onEditClick}
							icon="fab-pencil-16x16"
							noSpacing={true}
							secondary={true}
							type="button"
						/>
					</Tooltip>,
				)}
			{ifFeature(
				'encore',
				<Tooltip content={$.__('Preview')}>
					<IconButton
						onClick={onPreviewClick}
						icon="eye-solid"
						color="secondary"
						floatingIcon={true}
						size="small"
						type="button"
						variant="outlined"
					/>
				</Tooltip>,
				<Tooltip content={$.__('Preview')}>
					<FloatingIconButton
						clickAction={onPreviewClick}
						icon="fab-eye-20x14"
						noSpacing={true}
						secondary={true}
						type="button"
					/>
				</Tooltip>,
			)}
		</>
	);
};
