import {
	BodyText,
	IconTile,
	LayoutBox,
	StandardModal,
} from '@bamboohr/fabric';

/* @startCleanup encore */
import {
	CompanyOffice16x18,
} from '@bamboohr/grim';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	ModalBodyConnector,
} from './modal-body-connector';
import {
	ModalFooterConnector,
} from './modal-footer-connector';
import {
	ModalHeaderConnector,
} from './modal-header-connector';
import {
	useStyles,
} from './modal.styles';
import {
	handleCloseModal,
} from '../domain/handle-close-modal';

interface CompanyTaxSettingsModalProps {
	clientEin: string;
	clientName: string;
	isLoading: boolean;
	isProcessing: boolean;
}

export function Modal(props: CompanyTaxSettingsModalProps) {
	const {
		clientEin,
		clientName,
		isLoading,
		isProcessing,
	} = props;
	const classes = useStyles();
	return (
		<StandardModal
			isOpen={ true }
			isProcessing={ isLoading || isProcessing }
			onRequestClose={ handleCloseModal }
		>
			<StandardModal.Body
				renderFooter={ (
					<ModalFooterConnector />
				) }
				renderHeader={ (
					<ModalHeaderConnector />
				) }
			>
				<StandardModal.UpperContent>
					<div className={ classes.upperContainer }>
						{ ifFeature('encore', 
							<LayoutBox marginRight={ 2 }>
								<IconTile icon="buildings-regular" size={ 44 } />
							</LayoutBox>,
							<span className={ classes.upperIcon }>
								<CompanyOffice16x18 />
							</span>
						) }
						<BodyText size='medium' weight='bold'>
							{ clientName } ({ clientEin })
						</BodyText>
					</div>
				</StandardModal.UpperContent>
				<ModalBodyConnector />
			</StandardModal.Body>
		</StandardModal>
	);
}
