import { render } from 'base/wrapped-render';

import Ctrl, {
	onPjaxEnd,
	onReady,
} from 'ctrl.deco';

import { getSettingsEnpsData } from './settings-enps-service';
import { App } from './components/app.react';

@Ctrl('/settings/enps')
class SettingsEnps {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		getSettingsEnpsData()
			.then(data => render(<App data={ data } />, document.getElementById('enpsReactRoot')));
	}
}
