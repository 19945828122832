import { Button, ButtonProps } from '@bamboohr/fabric';
import { usePlaidLink } from 'react-plaid-link';
import { PlaidLinkOptions } from 'react-plaid-link/src/types';

interface LaunchPlaidProps {
	plaidLinkToken: string;
	buttonVariant?: ButtonProps['variant'];
	buttonText: string;
	onSuccess(token: string, metadata: object): void;
	onError(error: ErrorEvent): void;
}

export function LaunchPlaid(props: LaunchPlaidProps): JSX.Element {
	const { buttonVariant, buttonText, onSuccess, onError, plaidLinkToken } = props;

	const config: PlaidLinkOptions = {
		token: plaidLinkToken,
		onSuccess,
	};

	const { open, ready, error } = usePlaidLink(config);

	if (error) {
		onError(error);
	}

	return (
		<Button type='button' disabled={!ready || !!error} onClick={(): void => open()} variant={buttonVariant}>
			{buttonText}
		</Button>
	);
}
