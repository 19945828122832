import { useState } from 'react';
import { PayeeModal } from 'payroll/modals/payee.react';
import {
	StateSelector
} from "base/employees/pay-info/deductions/components/non-benefit-deduction-modal.react/components/state-selector";
import {
	PayeeSelector
} from "base/employees/pay-info/deductions/components/non-benefit-deduction-modal.react/components/payee-selector";
import './payee-needed.styl';

export function PayeeNeeded(props) {
	const {
		allowAddPayee,
		allStates,
		authToken,
		caseNumber,
		customPayees,
		deductionPayeeId,
		deductionType,
		description,
		employee,
		error,
		federalStateObject,
		formattedAmount,
		globalPayeeId,
		id,
		isChildSupport,
		isSetUpPayees,
		onFieldChange,
		onPayeeSave,
		remittanceId,
		setShowPayeeAddModal,
		setUpSetSelectedState,
	} = props;

	const [isPayeeFromValidator, setIsPayeeFromValidator] = useState(false);
	const [newPayeeId, setNewPayeeId] = useState(null);
	const [selectedState, setSelectedState] = useState([]);
	const [showPayeeModal, setShowPayeeModal] = useState(false);

	const descriptionClasses = 'PayeeNeeded__description fab-TextInput fab-TextInput--small PayeeNeeded__description--childSupport--globalPayees'

	const clearNewPayeeId = () => setNewPayeeId(null);

	const handleModalClose = () => setShowPayeeModal(false);

	const handleAddPayeeSuccess = (newPayee) => {
		onPayeeSave(newPayee);
		setPayeeSelectedPayee([newPayee.id]);
	}

	const setPayeeIsPayeeFromValidator = (value) => {
		setIsPayeeFromValidator(value);
	}

	const showPayeeAddModal = () => {
		setShowPayeeModal(true);
		setShowPayeeAddModal();
	}

	const setPayeeSelectedPayee = (selectedPayee) => {
		if(selectedPayee.length > 0) {
			let payeeIdType;
			// Child Support -> 3 / Tax Levy -> 18 / Other Garnishment -> 19
			if(deductionType === 3 || deductionType === 18) {
				payeeIdType = 'globalPayeeId'
			} else {
				payeeIdType = 'deductionPayeeId'
			}
			onFieldChange(id, payeeIdType, selectedPayee[0])
		}
		if(selectedPayee.length === 0) {
			onFieldChange(id, 'globalPayeeId', null)
			onFieldChange(id, 'deductionPayeeId', null)
		}
	}

	const setPayeeSelectedState = (selectedState) => {
		setSelectedState(selectedState);
		setUpSetSelectedState(selectedState);
		onFieldChange(id, 'globalPayeeId', null)
		onFieldChange(id, 'deductionPayeeId', null)
	}

	return (
		<div className="PayeeNeeded--globalPayees">
			<div className="PayeeNeeded__name--globalPayees">
				<p className="PayeeNeeded__paragraph--globalPayees truncate">{ employee.name }</p>
			</div>
			<div className="PayeeNeeded__amount">
				<p className="PayeeNeeded__paragraph--globalPayees truncate">{ formattedAmount }</p>
			</div>
			<div className="PayeeNeeded__select">
				<StateSelector
					allStates={ allStates }
					clearNewPayeeId={ clearNewPayeeId }
					deductionType={ deductionType }
					federalStateObject={ federalStateObject }
					isSetUpPayees={ isSetUpPayees }
					selectedState={ selectedState }
					setSelectedState={ setPayeeSelectedState }
				/>
			</div>
			<div className="PayeeNeeded__select">
				<PayeeSelector
					allStates={ allStates }
					allowAddPayee={ allowAddPayee }
					authToken={ authToken }
					customPayees={ customPayees }
					deductionType={ deductionType }
					federalStateObject={ federalStateObject }
					isPayeeFromValidator={ isPayeeFromValidator }
					isSetUpPayees={ isSetUpPayees }
					newPayeeId={ newPayeeId }
					selectedPayee={ [deductionPayeeId || globalPayeeId] }
					selectedState={ selectedState }
					setIsPayeeFromValidator={ setPayeeIsPayeeFromValidator }
					setSelectedPayee={ setPayeeSelectedPayee }
					showAddressFormNote={ false }
					showPayeeAddModal={ showPayeeAddModal }
				/>
			</div>
			<input
				className="PayeeNeeded__caseNumber--globalPayees fab-TextInput fab-TextInput--width3 fab-TextInput--small"
				onChange={ event => onFieldChange(id, 'caseNumber', event.target.value) }
				type="text"
				value={ caseNumber || '' }
			/>
			{ isChildSupport && (
				<input
					className="PayeeNeeded__remittanceId--globalPayees fab-TextInput fab-TextInput--width4 fab-TextInput--small"
					onChange={ event => onFieldChange(id, 'remittanceId', event.target.value) }
					type="text"
					value={ remittanceId || '' }
				/>
			) }
			<input
				className={ descriptionClasses }
				onChange={ event => onFieldChange(id, 'description', event.target.value) }
				type="text"
				value={ description || '' }
			/>
			{ allowAddPayee && (
				<PayeeModal
					allStates={ allStates }
					customPayees={ customPayees }
					onClose={ () => handleModalClose() }
					onSaveSuccess={ handleAddPayeeSuccess }
					payeeData={ customPayees }
					selectedState={ selectedState }
					setIsPayeeFromValidator={ setPayeeIsPayeeFromValidator }
					setSelectedPayee={ setPayeeSelectedPayee }
					setSelectedState={ setPayeeSelectedState }
					type="add"
					visible={ showPayeeModal }
				/>
			) }
		</div>
	);
}