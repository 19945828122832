import { toNumber, toString } from 'lodash';

const serializeDemoRequest = ((demoRequest = {}) => {
	// TODO: ia-upsell-performance-2022: Clean-up after GA release
	const handleOldFlag = (value) => {
		// Handle old performance request that doesn't have a date
		return value === '1' ? null : new Date(value).toISOString();
	};

	return {
		isRequested: !!demoRequest?.isRequested,
		requestedBy: {
			id: toNumber(demoRequest?.requestedBy?.id) || null,
			firstName: toString(demoRequest?.requestedBy?.firstName),
			lastName: toString(demoRequest?.requestedBy?.lastName),
		},
		requestDate: demoRequest?.requestDate ? handleOldFlag(demoRequest.requestDate) : null,
	}
});

export default serializeDemoRequest;