import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({
	palette,
	spacing,
	typography,
}) => {
	return createStyles({
		root: {},
		upperContainer: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
		},
		upperIcon: {
			fill: palette.gray[600],
			marginRight: 8,
		},
		upperText: {
			fontWeight: typography.fontWeightBold,
			marginLeft: spacing(1),
		},
	});
});
