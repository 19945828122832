import { IconV2, IconV2Name, TableGroup } from '@bamboohr/fabric';

import { BENEFIT_KEYS, BENEFIT_PLAN_TYPE_NAMES, V2_BENEFIT_TYPE_ICON_NAMES } from '../../constants';

export const getBenefitTypeTableGroups = (): TableGroup[] => {
    return Object.values(BENEFIT_KEYS).map((benefitType) => ({
        icon: <IconV2 name={`${V2_BENEFIT_TYPE_ICON_NAMES[benefitType]}-regular` as IconV2Name} size={16} />,
        id: benefitType,
        title: BENEFIT_PLAN_TYPE_NAMES[benefitType],
    }));
};
