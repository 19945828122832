import {
	Button,
	StandardModal,
} from '@bamboohr/fabric';

import {
	L_GO_BACK_TO_TAXES,
} from '../language';
import {
	handleCloseModal,
} from '../domain/handle-close-modal';

export function ModalFooterError() {
	return (
		<StandardModal.Footer
			actions={ [
				(
					<Button
						key="primary"
						onClick={ () => {
							handleCloseModal();
						} }
						type="button"
					>
						{ L_GO_BACK_TO_TAXES }
					</Button>
				),
			] }
		/>
	);
}
