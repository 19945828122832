const FORMAT_HINTS = [
	{
		regexTest: /\d/,
		text: $.__('Replace 1-9 with your numbers.'),
	},
	{
		regexTest: /[@]+/,
		text: $.__('Replace @ with a letter or number.'),
	},
	{
		regexTest: /(?=[\D])(?=[^@])/,
		text: $.__('Other characters must be entered as displayed.'),
	},
];

export function generatePopoverHtml(selectedTaxType) {
	if (!selectedTaxType) {
		return (
			$.__('You should have received a tax ID from the state. Once you have selected the Tax Type, applicable Tax ID examples will display inside this popover.')
		);
	}

	if (!selectedTaxType.employerTaxIdValidationFormat || selectedTaxType.employerTaxIdValidationFormat.length === 0) {
		return (
			$.__('You should have received a tax ID from the state. Please enter the tax ID in the format shown on the tax form.')
		);
	}

	const {
		employerTaxIdValidationFormat,
	} = selectedTaxType;

	return (
		`${ $.__('You should have received a tax ID from the state. It must be in one of the following formats:') }
		<br>
		<br>
		${ generateValidFormatsHtml(employerTaxIdValidationFormat) }
		<br>
		${ generateFormatHintsHtml(employerTaxIdValidationFormat) }
		`
	);
}

function generateValidFormatsHtml(employerTaxIdValidationFormat) {
	let html = '';
	employerTaxIdValidationFormat.forEach((format) => {
		html += `${ format }<br>`;
	});

	return html;
}

function generateFormatHintsHtml(employerTaxIdValidationFormat) {
	let html = '';

	FORMAT_HINTS.forEach((hint) => {
		let validId = false;

		for (const validFormat of employerTaxIdValidationFormat) {
			if (hint.regexTest.test(validFormat)) {
				validId = true;
				break;
			}
		}

		if (validId) {
			html += `${ hint.text }<br>`;
		}
	});

	return html;
}
