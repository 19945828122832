import classNames from 'classnames';
import { map, noop } from 'lodash';
import {
	Component,
	Fragment
} from 'react';

import { changeApprovalPathType, removeAdvancedApproval } from '../../utils';
import { BodyText, Flex, Icon, IconV2, LayoutBox, Link, SelectableBox, SelectableBoxGroup } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export class AdvancedApprovalsModal extends Component {
	constructor(props = {}) {
		super(props);

		const {
			onClose,
			selectedPath
		} = props;

		const originalPath = this._pathOptions[selectedPath] || {};

		this.state = {
			disableSaveApproval: true,
			modalConfirmOpen: false,
			modalRemoveOpen: false,
			originalPath: originalPath.type,
			pathTypesMeta: [],
			selectedPath
		};

		this._handleParentClose = onClose || noop;
	}

	_handleModalConfirmClose() {
		this.setState(() => {
			return {
				modalConfirmOpen: false,
				sheetOpen: false
			};
		});
	}

	_handleModalConfirmSave() {
		return this._savePathOption().then(({ data }) => {
			if (!data.success) {
				throw new Error();
			}
			this.setState(() => {
				return {
					modalConfirmOpen: false,
					sheetOpen: false
				};
			}, () => {
				this._handleModalMainClose(true);
				window.setMessage($.__('Approval updated!'), 'success');
			});
		}).catch(() => {
			window.setMessage(this._saveErrorMessage, 'error');
		});
	}

	_handleModalMainCancel() {
		this._handleModalMainClose(false);
	}

	_handleModalMainClose(saved) {
		const {
			selectedPath
		} = this.state;

		this.setState(() => {
			const newState = {
				originalPath: selectedPath
			};
			if (!saved) {
				const {
					selectedPath: propsSelectedPath
				} = this.props;

				const tempPath = this._pathOptions[propsSelectedPath] || {};

				newState.originalPath = tempPath.type;
				newState.selectedPath = propsSelectedPath;
			}
			return newState;
		}, () => {
			this._handleParentClose(saved, selectedPath);
		});
	}

	_handleModalRemoveOpen() {
		this.setState(() => {
			return {
				modalRemoveOpen: true,
				sheetOpen: true
			};
		});
	}

	_handleModalRemoveClose() {
		this.setState(() => {
			return {
				modalRemoveOpen: false,
				sheetOpen: false
			};
		});
	}

	_handleModalRemoveSave() {
		const {
			id
		} = this.props;

		return removeAdvancedApproval(id).then(({ data }) => {
			if (!data.success) {
				throw new Error();
			}
			this.setState(() => {
				return {
					modalRemoveOpen: false,
					sheetOpen: false
				};
			}, () => {
				this._handleModalMainClose(true);
				window.setMessage($.__('Advanced approval removed.'), 'success');
			});
		}).catch(() => {
			window.setMessage(this._removeErrorMessage, 'error');
		});
	}

	_handleSavePathOption() {
		const {
			originalPath,
			selectedPath
		} = this.state;

		// standard workflow, save button clicked with no selection, do nothing
		// or
		// advanced workflow, save button clicked with selection that wasn't changed, do nothing
		if (
			(typeof originalPath === 'undefined' && (typeof selectedPath === 'undefined' || selectedPath === null)) ||
			(originalPath === selectedPath)
		) {
			return this._handleModalMainClose(false);
		}

		// standard workflow, save button clicked with selection, save new path type
		if (typeof originalPath === 'undefined' && typeof selectedPath !== 'undefined') {
			return this._savePathOption().then(({ data }) => {
				if (!data.success) {
					throw new Error();
				}
				this._handleModalMainClose(true);
				window.setMessage(this._pathOptions[selectedPath].success, 'success');
			}).catch(() => {
				window.setMessage(this._saveErrorMessage, 'error');
			});
		}

		// advanced workflow, save button clicked with selection that was changed, launch confirm modal
		if (originalPath !== selectedPath) {
			this.setState(() => {
				return {
					modalConfirmOpen: true,
					sheetOpen: true
				};
			});
		}
	}

	_renderModalFooter() {
		const {
			disableSaveApproval,
			originalPath
		} = this.state;

		return (
			<div className="SimpleModal__footer">
				<button
					className="btn btnAction SimpleModal__footer-button-primary"
					disabled={ disableSaveApproval }
					onClick={ () => { this._handleSavePathOption(); } }
					type="button"
				>
					{ $.__('Save') }
				</button>
				<button
					className="SimpleModal__footer-cancel AdvancedApprovalsPathModal__footer-link-look-button"
					onClick={ () => { this._handleModalMainCancel(); } }
					type="button"
				>
					{ $.__('Cancel') }
				</button>
				{ originalPath && (
					<button
						className="SimpleModal_footer-delete AdvancedApprovalsPathModal__footer-link-look-button fl-right"
						onClick={ () => { this._handleModalRemoveOpen(); } }
						type="button"
					>
						{ $.__('Remove Advanced Approval') }
					</button>
				) }
			</div>
		);
	}

	_renderPathOptions() {
		const {
			selectedPath
		} = this.state;

		const keys = Object.keys(this._pathOptions);
		const lastKey = keys[keys.length - 1];
		return ifFeature(
			'encore',
			<SelectableBoxGroup type="radio">
				<Flex gap={2} maxWidth="756px">
					{map(this._pathOptions, (pathOption, index) => {
						return <SelectableBox
							icon={pathOption.icon}
							inputId={pathOption.type}
							isChecked={pathOption.type === selectedPath}
							key={index}
							name={pathOption.name}
							onChange={() => { this._updateSelection(pathOption.type); }}
							title={pathOption.name}
						/>
					})}
				</Flex>
			</SelectableBoxGroup>,
			<div className="PathOptions">
				{
					map(this._pathOptions, (pathOption, index) => {
						return this._renderPathOption(pathOption, selectedPath, (index === lastKey));
					})
				}
			</div>
		);
	}

	_renderPathOption(pathOption, selectedPath, lastItem) {
		const {
			icon,
			name,
			type
		} = pathOption;
		const selected = selectedPath === type;
		const cardClassName = classNames(
			'ba-CheckboxCard', 'ba-CheckboxCard--no-description', 'PathOption__option',
			{
				'checkboxCardSpacing': !lastItem,
			}
		);
		const key = `aapOption-${ type }`;
		return (
			<div className="PathOption" key={ key }>
				<input checked={ selected } className="hidden" id={ key } name="aapSelection" readOnly={ true } type="radio" />
				<label
					className={ cardClassName }
					htmlFor={ key }
					onClick={ () => { this._updateSelection(type); } }
				>
					<div className="ba-CheckboxCard__icon--no-description">
						<Icon brand={ true } name={ icon } />
					</div>
					<p>
						{ name }
					</p>
				</label>
			</div>
		);
	}

	_renderModalContent(originalPath, selectedPath, workflowName) {
		const {
			hasOptions,
			listId
		} = this._getPathTypeMeta(selectedPath);

		const showPathWarning = (typeof hasOptions !== 'undefined' && !hasOptions) || false;
		const showWarning = typeof originalPath !== 'undefined' && originalPath !== selectedPath;
		const pathTypeListLink = `/settings/list.php?id=${ listId || -1 }`;

		const {
			warning
		} = this._pathOptions[originalPath] || {};

		const {
			noDataLinkText,
			noDataWarningText
		} = this._pathOptions[selectedPath] || {};
		const iconName = ifFeature('encore', 'triangle-exclamation-solid', 'fab-triangle-exclamation-13x11');

		return ifFeature(
			'encore',
			<Flex alignItems="center" flexDirection="column" gap={2} paddingBottom={4}>
				<BodyText justify="center">
					{$.__('You can set different approval paths by location, division, or department.')}
					<br />
					{$.__('All future approvals will follow this pattern.')}
				</BodyText>
				<BodyText justify="center">
					{$.__('Set up specific approval paths by:')}
				</BodyText>
				<Flex alignItems="center" flexDirection="column" gap={2}>
					{this._renderPathOptions()}
					{
						showWarning && (
							<BodyText color="warning-strong" justify="center">
								<LayoutBox display="inline" marginRight="4px">
									<IconV2 name={iconName} size={12}/>
								</LayoutBox>
								{warning}
								<br />
								{$.__('Changing this will delete all approvals for "%1"', workflowName)}
							</BodyText>
						)
					}
					{
						showPathWarning && (
							<BodyText color="warning-strong" justify="center">
								<LayoutBox display="inline" marginRight="4px">
									<IconV2 name={iconName} size={12}/>
								</LayoutBox>
								{noDataWarningText} <Link href={pathTypeListLink}>{noDataLinkText}</Link>
							</BodyText>
						)
					}
				</Flex>
			</Flex>,
			<div className="AdvancedApprovalsPathModal">
				<div>
					<div>
						<p>
							{ $.__('You can set different approval paths by location, division, or department.') }
							<br />
							{ $.__('All future approvals will follow this pattern.') }
						</p>
					</div>
				</div>
				<div className="AdvancedApprovalsPathModal__options-container">
					<span className="AdvancedApprovalsPathModal__options-container__options-header">
						{ $.__('Set up specific approval paths by:') }
					</span>
					{ this._renderPathOptions() }
					{
						showWarning && (
							<div className="AdvancedApprovalsPathModal__warning-container">
								<span className="AdvancedApprovalsPathModal__warning-container__text">
									<span className="AdvancedApprovalsPathModal__warning-container__icon">
										<Icon name={ iconName } />
									</span>
									{ warning }
									<br />
									{ $.__('Changing this will delete all approvals for "%1"', workflowName) }
								</span>
							</div>
						)
					}
					{
						showPathWarning && (
							<div className="AdvancedApprovalsPathModal__warning-container">
								<span className="AdvancedApprovalsPathModal__warning-container__text">
									<span className="AdvancedApprovalsPathModal__warning-container__icon">
										<Icon name={ iconName } />
									</span>
									{ noDataWarningText } <a href={ pathTypeListLink }>{ noDataLinkText }</a>
								</span>
							</div>
						)
					}
				</div>
			</div>,
		);
	}

	_renderConfirmationModalContent() {
		return (
			<div className="AdvancedApprovalsPathConfirmationModal">
				<div className="AdvancedApprovalsPathConfirmationModal__text-container">
					<p>{ $.__('If you change your approvals configuration, all current approvals will be deleted.') }</p>
				</div>
			</div>
		);
	}

	_renderRemoveModalContent(workflowName) {
		return (

			<div className="AdvancedApprovalsPathConfirmationModal">
				<div className="AdvancedApprovalsPathConfirmationModal__text-container">
					<p>{ $.__('If you change your approvals configuration, all current approvals will be deleted for "%1"', workflowName) }</p>
				</div>
			</div>
		);
	}

	_savePathOption() {
		const {
			id
		} = this.props;
		const {
			selectedPath
		} = this.state;
		const postData = {
			workflowId: id,
			pathType: selectedPath
		};
		return changeApprovalPathType(postData);
	}

	_getPathTypeMeta = (selectedPath) => {
		const { pathTypesMeta } = this.state;
		const pathTypeMeta = pathTypesMeta.find((meta) => {
			return meta.pathType === selectedPath;
		});
		return pathTypeMeta || {};
	};

	_getSheetProps = () => {
		const {
			workflowName
		} = this.props;

		const {
			modalRemoveOpen,
			modalConfirmOpen,
			sheetOpen
		} = this.state;

		let sheetProps = { isOpen: false };

		if (sheetOpen) {
			sheetProps = {
				icon: ifFeature('encore', 'triangle-exclamation-solid', 'fab-triangle-exclamation-48x48'),
				iconColor: 'attention',
				iconV2Color: ifFeature('encore', 'warning-medium'),
				isOpen: true,
				primaryActionText: $.__('Continue'),
				title: $.__('Just Checking...')
			};

			if (modalConfirmOpen) {
				sheetProps.children = this._renderConfirmationModalContent();
				sheetProps.onClose = () => { this._handleModalConfirmClose(); };
				sheetProps.primaryAction = () => { this._handleModalConfirmSave(); };
			} else if (modalRemoveOpen) {
				sheetProps.children = this._renderRemoveModalContent(workflowName);
				sheetProps.onClose = () => { this._handleModalRemoveClose(); };
				sheetProps.primaryAction = () => { this._handleModalRemoveSave(); };
			}

		}

		return sheetProps;
	};

	_onAdvancedApprovalsModalFetch = (data) => {
		if (!Array.isArray(data)) {
			return;
		}
		this.setState(() => {
			return {
				pathTypesMeta: data
			};
		});
	};

	_updateSelection = (choice) => {
		const pathOption = this._pathOptions[choice] || {};
		const pathTypeMeta = this._getPathTypeMeta(choice);

		this.setState(() => {
			return {
				disableSaveApproval: !pathTypeMeta.hasOptions,
				selectedPath: pathOption.type
			};
		});
	};

	_removeErrorMessage = $.__('Whoops... There was a problem trying to remove this advanced approval.');

	_saveErrorMessage = $.__('Whoops... There was a problem trying to save this advanced approval.');

	_pathOptions = {
		department: {
			icon: ifFeature('encore', 'user-group-solid', 'fab-employees-28x24'),
			message: $.__('This will delete all department-specific approvals and this approval will become a single approval path for the whole company.'),
			name: $.__('Department'),
			noDataLinkText: $.__('Add departments'),
			noDataWarningText: $.__(`You don't have any departments enabled.`),
			success: $.__('Advanced approval by department created.'),
			type: 'department',
			warning: $.__('Current approvals are set by department.'),
		},
		division: {
			icon: ifFeature('encore', 'sitemap-solid', 'fab-org-chart-26x28'),
			message: $.__('This will delete all division-specific approvals and this approval will become a single approval path for the whole company.'),
			name: $.__('Division'),
			noDataLinkText: $.__('Add divisions'),
			noDataWarningText: $.__(`You don't have any divisions enabled.`),
			success: $.__('Advanced approval by division created.'),
			type: 'division',
			warning: $.__('Current approvals are set by division.'),
		},
		location: {
			icon: ifFeature('encore', 'location-dot-solid', 'fab-location-21x28'),
			message: $.__('This will delete all location-specific approvals and this approval will become a single approval path for the whole company.'),
			name: $.__('Location'),
			noDataLinkText: $.__('Add locations'),
			noDataWarningText: $.__(`You don't have any locations enabled.`),
			success: $.__('Advanced approval by location created.'),
			type: 'location',
			warning: $.__('Current approvals are set by location.'),
		},
	};

	render() {
		const {
			modalOpen,
			workflowName
		} = this.props;

		const {
			originalPath,
			selectedPath
		} = this.state;

		const modalTitle = $.__('Advanced Approvals');

		return (
			<Fragment>
				<Fragment>
					<Modal
						additionalAction={ (originalPath) ? () => { this._handleModalRemoveOpen(); } : null }
						additionalActionText={ (originalPath) ? $.__('Remove Advanced Approval') : null }
						contentHasPadding={ifFeature('encore', false)}
						headline={ $.__('Advanced Approvals') }
						icon={ifFeature('encore', 'arrow-progress-regular', "fab-road-sign-54x54")}
						iconColor="theme"
						isOpen={ modalOpen }
						onClose={ () => { this._handleModalMainClose(false); } }
						primaryAction={ () => { this._handleSavePathOption(); } }
						primaryActionText={ $.__('Save') }
						sheetProps={ this._getSheetProps() }
						title={ modalTitle }
						type={ifFeature('encore', 'medium', 'small')}
					>
						{ this._renderModalContent(originalPath, selectedPath, workflowName) }
					</Modal>
				</Fragment>
			</Fragment>
		);
	}
}
