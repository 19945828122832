import { makeStyles, createStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({typography}) => {
	return createStyles({
		datePickerContainer: {
			marginLeft: '24px',
		},
		dateStartedNote: {
			fontSize: typography.fabricFontSize('small'),
		}
	})
});
