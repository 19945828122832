import { ifFeature } from '@bamboohr/utils/lib/feature';
import AppModal from './modal';

import './styles.styl';


export default function ConflictModal({
	conflict = {},
	...props
}) {
	const {
		app = {},
	} = props;

	return (
		<AppModal
			{ ...props }
			alternativeAction={ null }
			headline={ $.__(`Before %1$s can be installed, you'll need to uninstall %2$s`, app.title, conflict.title) }
			icon={ifFeature('encore', 'triangle-exclamation-regular', "fab-triangle-exclamation-48x48")}
			iconV2Color='primary-strong'
			primaryActionText={ $.__('Ok, Got It') }
			title={ $.__('Install Conflict') }
			type="conflict"
		/>
	);
}
