import { render } from 'base/wrapped-render';
import { unmountComponentAtNode } from 'react-dom';
import { WebhookDetailsModal } from './webhook-details-modal';

const getData = (id) => {
	return $.ajax({
		type: 'get',
		url: `/settings/account/webhooks/details/${id}`,
	});	
}

$('.js-webhook-details').on('click', async function() {
	const reactRootElement = document.getElementById('js-WebhookDetailsModal');
	const handleClose = () => {
		unmountComponentAtNode(reactRootElement);
	};
	const id = $(this).attr('wh-id');
	const data = await getData(id);

	render(
		<WebhookDetailsModal data={data} isOpen={true} onClose={handleClose}/>,
		reactRootElement
	)
})