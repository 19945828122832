module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push('<div class="fieldRow fieldRow--tight STS_Holidays__employmentStatusRow js-employment-status-row" data-status-id="',window.microTemplate.encodeHtml(id),'">   <div class="fieldBox">    <input type="text"    class="STS_Holidays__statusInput fab-TextInput fab-TextInput--width1 js-toggle-buttons required js-validate-range-0-24 inline-error"    name="',window.microTemplate.encodeHtml(inputName),'"    value="',window.microTemplate.encodeHtml(defaultHours),'"    maxlength="4"    />    <input    type="hidden"    name="',window.microTemplate.encodeHtml(hiddenInputName),'"    value="',window.microTemplate.encodeHtml(id),'"    maxlength="4"   />    <div class="STS_Holidays__groupFormNote STS_Holidays__formNote fab-FormNote">    ',window.microTemplate.encodeHtml($.__("hours for", {note: "As in '2 days before the pay date'"})),'   </div>    <div class="STS_Holidays__employeeStatuses js-employment-statuses-container">    <ba-select     class="long js-toggle-buttons js-employment-statuses-dropdown ignore BhrForms__visuallyIgnored"     required="false"     not-clearable     width="6"     '); if(disabled){
p.push(' disabled '); } 
p.push('    >     ');     options.forEach(function (option) {      var isSelected = (option.id === id) ? 'selected' : '';     
p.push('     <ba-option value="',window.microTemplate.encodeHtml(option.id),'" ',window.microTemplate.encodeHtml(isSelected),'>      ',window.microTemplate.encodeHtml(option.name),'     </ba-option>     '); })
p.push('    </ba-select>   </div>    '); if (showRemove) { 
p.push('    <div class="STS_Holidays__deleteRow js-remove-status-row">×</div>   '); } 
p.push('    '); if (showAdd) { 
p.push('    <ba-icon     name="',window.microTemplate.encodeHtml(addIcon),'"     class="      STS_Holidays__addRowIcon      ',window.microTemplate.encodeHtml((canAdd) ? 'STS_Holidays__addRowIcon--clickable js-add-status-row' : 'js-cannot-add-status-row'),'     "    >    </ba-icon>   '); } 
p.push('   </div>  </div> ');}return p.join('');
}