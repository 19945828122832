import { useMemo, useState } from 'react';

import { Select } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import moment from 'moment';
import { useStateContext } from '../state';

const times = Array.from(Array(24).keys())
	.map((hour) => moment({ hour }))
	.map((time) => ({
		text: time.format('h:00 A'),
		value: time.format('HH:00:00'),
	}));

export default function SendAt() {
	const { runAtTime: _time, runAtTimeTZ: _tz, timezones } = useStateContext();

	const initialTime = useMemo(() => moment(_time, 'h::mma').format('HH:00:00'), []);
	const [runAtTime, setRunAtTime] = useState(initialTime);
	const [runAtTimeTz, setRunAtTimeTz] = useState(_tz);

	return (
		<div className='fab-FormRow fab-marginBottom--4'>
			<div className='fab-FormColumn'>
				<label className='fab-Label fab-marginBottom--4' htmlFor='run_at_time'>
					{$.__('Send Alerts at')}
				</label>
				<Select
					id='run_at_time'
					isClearable={false}
					items={times}
					name='run_at_time'
					onChange={([value]) => setRunAtTime(value)}
					selectedValues={[runAtTime]}
					width='4'
				/>
			</div>
			<div className='fab-FormColumn'>
				<label className='fab-Label' htmlFor='run_at_time_tz'>
					&nbsp;
				</label>
				<Select
					id='run_at_time_tz'
					isClearable={false}
					items={timezones}
					name='run_at_time_tz'
					onChange={([value]) => setRunAtTimeTz(value)}
					selectedValues={[runAtTimeTz]}
					width='7'
				/>
			</div>
		</div>
	);
}
