import { Button, CardContentSpacing, SheetModal, StandardModal, StandardModalProps, LayoutBox } from '@bamboohr/fabric';
// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Archive54x54 } from '@bamboohr/grim';
// @endCleanup encore
import React, { ComponentProps, ReactNode } from 'react';

export interface ConfirmArchiveModalProps
	extends Omit<StandardModalProps, 'onRequestClose'> {
	/** Will pass `true` if the user confirms they want to archive. */
	onRequestClose: (confirmed?: boolean) => void;

	// Optional props
	cancelButtonText?: ReactNode;
	confirmationBoxChildren?: ReactNode;
	confirmationButtonText?: ReactNode;
	/** The text that the user needs to type in before confirming archiving. */
	confirmationMatchText?: string;
	/** The text that appears just above the input where the user types the `confirmationMatchText`. */
	confirmationInputLabel?: ComponentProps<(typeof StandardModal)['ConfirmationContent']>['confirmationText'];
	headlineText?: ReactNode;
	isSheet?: boolean;
	title?: string;
}

export function ConfirmArchiveModal(props: ConfirmArchiveModalProps) {
	const {
		onRequestClose,
		cancelButtonText = $.__('Cancel'),
		children,
		confirmationBoxChildren,
		confirmationButtonText = $.__('Archive'),
		confirmationMatchText = $.__('Archive'),
		headlineText = $.__('Are you sure you want to archive this?'),
		isSheet = false,
		title = $.__('Confirm Archiving'),
		...modalProps
	} = props;

	const Modal = isSheet ? SheetModal : StandardModal;

	return (
		<Modal
			{...(modalProps as StandardModalProps)}
			onAfterClose={() => {
				modalProps?.onAfterClose?.();
			}}
			onRequestClose={() => onRequestClose()}
		>
			<form onSubmit={() => onRequestClose(true)}>
				<Modal.Body
					renderFooter={() => (
						<Modal.Footer actions={ifFeature('encore', [
							<Button
								color="primary"
								key="cancel"
								onClick={() => onRequestClose()}
								type="button"
								variant="text"
							>
								{cancelButtonText}
							</Button>,
							<Button
								key="confirm"
								type="submit"
							>
								{confirmationButtonText}
							</Button>
						], [
							<Button
								key="confirm"
								type="submit"
							>
								{confirmationButtonText}
							</Button>,
							<Button
								color="primary"
								key="cancel"
								onClick={() => onRequestClose()}
								type="button"
								variant="text"
							>
								{cancelButtonText}
							</Button>
						])} />
					)}
					renderHeader={
						<Modal.Header title={title} />
					}
				>
					<Modal.Constraint
						spacingOverrides={{
							bottom: CardContentSpacing.LARGE,
							top: CardContentSpacing.LARGE,
						}}
					>
						<Modal.HeroHeadline icon={ifFeature('encore', "box-archive-regular", <Archive54x54 />)} text={headlineText} />
						<LayoutBox marginY={children ? 1.5 : 3}>
							{children}
						</LayoutBox>
					</Modal.Constraint>
				</Modal.Body>
			</form>
		</Modal>
	);
}
