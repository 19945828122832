import { ItemType } from '@bamboohr/fabric';
import { CountryPhonePrefixOptions } from '../../sms-mfa/types';

export function getSmsCountries(countries: CountryPhonePrefixOptions[]): ItemType[] {
	const topCountries = countries.filter((country) =>
		['United States +1', 'Canada +1', 'United Kingdom +44', 'Australia +61'].includes(country.value)
	);

	const regularCountries = countries.filter(
		(country) => !['United States +1', 'Canada +1', 'United Kingdom +44', 'Australia +61'].includes(country.value)
	);

	return [
		{
			items: topCountries,
			type: 'group',
			value: 'top-countries',
		},
		{
			items: regularCountries,
			type: 'group',
			value: 'countries',
		},
	] as ItemType[];
}
