import Ajax from '@utils/ajax';

import { cloneDeep } from 'lodash';

import { showSlidedown } from 'Alerts.mod';

import {
	isTaxRateValid,
} from '../components/company-tax-field/utils';

export function createInitialState(addTaxRateData) {
	const initialState = {};

	initialState.effectiveDate = {
		name: 'effectiveDate',
		items: addTaxRateData.allowableEffectiveDates,
		required: true,
		disabled: false,
		error: false,
		value: null,
	};

	initialState.payFrequencyId = {
		name: 'payFrequencyId',
		items: formatPayFrequencies(addTaxRateData.allowablePayFrequencies),
		required: true,
		disabled: true,
		error: false,
		value: null,
	};

	initialState.taxRate = {
		name: 'taxRate',
		editable: true,
		required: true,
		disabled: false,
		error: false,
		value: null,
		width: 3,
	};

	initialState.notes = {
		name: 'notes',
		required: false,
		disabled: false,
		error: false,
		value: null,
	};

	return initialState;
}

export function formatPayFrequencies(payFrequencies) {
	const formattedPayFrequencies = [];

	for (const key in payFrequencies) {
		formattedPayFrequencies.push({ value: parseInt(key), text: payFrequencies[key] });
	}

	return formattedPayFrequencies;
}

export function formatForSave(saveObject, taxTypeData) {
	const newSaveObject = cloneDeep(saveObject);

	newSaveObject.clientTaxTypeIncludeId = taxTypeData.clientTaxTypeIncludeId;
	newSaveObject.clientId = taxTypeData.clientId;
	newSaveObject.state = taxTypeData.taxTypeState;

	return newSaveObject;
}

export function saveNewRate(formData, taxTypeData, updateFormData, callbackFunctions) {
	let readyForSave = true;
	let saveObject = {};

	for (const key in formData) {
		const fieldData = formData[key];
		if (!fieldData.disabled && fieldData.required) {
			let rowValid = true;
			if (!fieldData.value || fieldData.value === '') {
				rowValid = false;
			}

			// Check min/max values
			if (key === 'taxRate') {
				const {
					minTaxRate,
					maxTaxRate,
				} = taxTypeData;

				// eslint-disable-next-line max-depth
				if (!isTaxRateValid(parseFloat(fieldData.value), minTaxRate, maxTaxRate)) {
					rowValid = false;
				}
			}

			if (rowValid) {
				saveObject[fieldData.name] = fieldData.value;
			} else {
				readyForSave = false;
				updateFormData(fieldData.name, { error: true });
			}
		} else {
			saveObject[fieldData.name] = fieldData.value;
		}
	}

	if (readyForSave) {
		saveObject = formatForSave(saveObject, taxTypeData);

		Ajax.post('/settings/payroll/ajax/taxes/add_new_tax_rate', saveObject).then((result) => {
			if (result.status === 200) {
				callbackFunctions.success();
			} else {
				callbackFunctions.failure($.__('There was a problem. Please try again or contact support.'));
			}
		}).catch((error) => {
			callbackFunctions.failure($.__('There was a problem. Please try again or contact support.'));
		});
	} else {
		callbackFunctions.failure($.__('Whoops...please fill out all required fields and try again.'));
	}
}
