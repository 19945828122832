import React, { ReactElement } from 'react';

import { PoMicroFrontend } from 'micro-frontend.react';

export function BenefitsCarriers(): ReactElement {

	return (
		<PoMicroFrontend route='/settings/benefits/carriers' />
	);
}
