import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type StyleProps = {
	partialPage: boolean;
};

export const useStyles = makeStyles(({ typography, palette, spacing, constructs }) => ({
	root: {
		borderBottom: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`, `1px solid ${palette.gray[300]}`),
		paddingBottom: '32px',
		marginTop: ifFeature('encore', spacing(3)),
	},
	/* @startCleanup encore */
	sectionHeader: {
		display: 'flex',
		alignItems: 'center',
		'& h5': {
			...typography.h5,
			marginLeft: '8px',
		},
	},
	fields: {
		marginTop: ifFeature('encore', spacing(2)),
		paddingLeft: ifFeature('encore', undefined, '28px'),
	},
	scheduleStart: {
		marginBottom: '14px',
		'& label': {
			...typography.body2,
			color: palette.gray[800],
		},
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			'& span': {
				marginLeft: '13px',
				fontSize: '13px',
				lineHeight: '18px',
				color: palette.gray[700],
			},
		},
	},
	scheduleAndFrequency: {
		'& p': {
			...typography.body2,
			color: palette.gray[800],
		},
		'& > div': {
			display: 'flex',
			alignItems: 'center',
			'& .fab-Select': {
				marginRight: '12px',
				'&:nth-child(2)': {
					transition: 'max-width 300ms ease-out, opacity 300ms ease-out, margin-right 300ms ease-out',
					maxWidth: (props: StyleProps) => (props.partialPage ? 0 : '1000px'),
					opacity: (props: StyleProps) => (props.partialPage ? 0 : 1),
					marginRight: (props: StyleProps) => (props.partialPage ? 0 : '12px'),
				},
			},
			'& #popoverAnchor': {
				marginLeft: '4px',
			},
		},
	},
	/* @endCleanup encore */
}));
