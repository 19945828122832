import { Relationships } from '../../../../relationship-constants';
import { CoverageTypes, CoverageType } from '../../../../types/coverage-type';

interface SupportedCoverageData {
	employeeCoverage: CoverageType;
	supportedCoverageLevelIds: string[];
}

export const getPlanIsMissingCoverageLevels = (
	supportedCoverageLevels: CoverageTypes,
	selectedCoverageLevelIds: string[]
): boolean => {
	const supportedCoverageData: SupportedCoverageData = Object.values(supportedCoverageLevels).reduce(
		(acc: SupportedCoverageData, coverageLevel: CoverageType) => {
			if (coverageLevel.relationships.length === 1 && coverageLevel.relationships[0] === Relationships.Self) {
				acc.employeeCoverage = coverageLevel;
			}

			acc.supportedCoverageLevelIds.push(coverageLevel.id.toString());

			return acc;
		},
		{ employeeCoverage: {}, supportedCoverageLevelIds: [] }
	);
	const { employeeCoverage, supportedCoverageLevelIds } = supportedCoverageData;
	const employeeId = employeeCoverage?.id?.toString();

	return (
		!selectedCoverageLevelIds.some((coverage) => supportedCoverageLevelIds.includes(coverage)) ||
		!selectedCoverageLevelIds.includes(employeeId)
	);
};
