export const BENEFIT_TYPES = {
	health: 1,
	dental: 2,
	vision: 3,
	supplemental: 5,
	retirement: 6,
	reimbursement: 7,
	hsa: 8,
	flex: 9,
	life: 10,
	disability: 11,
	other: 12,
};

export const CHANGE_AVAILABILITY = {
	Allowed: 'allowed',
	Blocked: 'blocked',
	NotOffered: 'notOffered',
};

export const PLAN_TYPE_NAMES = {
	dental: $.__('Dental'),
	flex: $.__('FSA'),
	health: $.__('Medical'),
	hsa: $.__('HSA'),
	vision: $.__('Vision'),
};
