import { Component } from 'react';

import { cloneDeep } from 'lodash';

import Ajax from '@utils/ajax';

import { showSlidedown } from 'Alerts.mod';

import { redirect } from 'BambooHR.util';

import { Loader } from '@bamboohr/fabric';

import { AddTaxForm } from './add-tax-form';

import { EditTaxForm } from './edit-tax-form';

import {
	createInitialState,
	formatStatesArray,
	mergeExistingTaxData,
	createTax,
	editTax,
} from './utils';

import { CodesAndRates } from '../components/shared/codes-and-rates';

import { TaxIdReminderModal } from '../components/modals/tax-id-reminder-modal';

import './add-edit-tax.styl';

export class AddEditTax extends Component {
	_successCallback = () => {

		const { isEdit } = this.props;
		const {
			taxTypeId: {
				selectedTaxType: {
					taxTypeName,
				},
			},
			employerTaxId,
		} = this.state;

		if (isEdit) {
			redirect('/settings/payroll/taxes', $.__('Your changes to %1$s have been saved.', taxTypeName), 'success');
		} else if (employerTaxId.value === 'Applied For') {
			redirect('/settings/payroll/taxes', $.__('%1$s has been added but still needs a Tax ID. Please enter it as soon as possible.', taxTypeName), 'success');
		} else {
			redirect('/settings/payroll/taxes', $.__('%1$s has been added to your company taxes.', taxTypeName), 'success');
		}
	}

	_failureCallback = (errorMessage) => {
		if (errorMessage) {
			showSlidedown(errorMessage, 'error');
		} else {
			showSlidedown(window.DEFAULT_ERROR_MESSAGE, 'error');
		}

		document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
	}

	_saveData = () => {
		this.setState({
			showModal: false,
		});

		const {
			addEditData: {
				clientTaxTypeIncludeId,
			},
			isEdit,
		} = this.props;

		const callbackFunctions = {
			success: this._successCallback,
			failure: this._failureCallback,
		};

		if (isEdit) {
			editTax(this.state, this._updateFormData, callbackFunctions, clientTaxTypeIncludeId);
		} else {
			createTax(this.state, this._updateFormData, callbackFunctions, this._handleCodesRatesError);
		}
	}

	_handleOnClose = () => {
		this.setState({
			showModal: false,
		});
		document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
	}

	_footerOnClickHandler = () => {
		document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));

		const {
			employerTaxId,
		} = this.state;

		if (employerTaxId.value === 'Applied For') {
			this.setState({
				showModal: true,
			});
		} else {
			this._saveData();
		}
	}

	_updateFormData = (field, updates) => {
		this.setState(prevState => ({
			[field]: {
				...prevState[field],
				...updates,
			},
		}));
	}

	_handleCodesRatesError = () => this.setState({ codesRatesError: true });

	state = createInitialState(this.props.addEditData);

	componentDidMount() {
		const {
			addEditData,
		} = this.props;

		const footerSaveButton = document.querySelector('[data-action="SiteFooterAction:saveTaxType"]');
		footerSaveButton.addEventListener('click', this._footerOnClickHandler);

		Ajax.get(`/ajax/get_states?country=${ 1 }&includeFederal=true`).then((response) => {
			const formattedStates = formatStatesArray(response.data.data);

			if (addEditData.clientIncludedTaxType && addEditData.clientIncludedTaxType.taxTypeState) {
				const stateCopy = cloneDeep(this.state);
				stateCopy.state.items = formattedStates;

				mergeExistingTaxData(stateCopy, addEditData.clientIncludedTaxType).then((mergedTaxData) => {
					this.setState({ ...mergedTaxData, isLoading: false });
				});
			} else {
				this.setState(prevState => ({
					isLoading: false,
					state: {
						...prevState.state,
						items: formattedStates,
					},
				}));
			}
		});
	}

	componentWillUnmount() {
		const footerSaveButton = document.querySelector('[data-action="SiteFooterAction:saveTaxType"]');
		footerSaveButton.removeEventListener('click', this._footerOnClickHandler);
	}

	render() {
		const {
			isLoading,
			taxTypeId: {
				selectedTaxType,
			},
			codesRatesError,
			showModal,
		} = this.state;

		const {
			addEditData: {
				clientId,
				clientTaxTypeIncludeId,
				clientIncludedTaxType,
				feeInfo,
			},
			isEdit,
		} = this.props;

		const codesAndRates = clientIncludedTaxType ? clientIncludedTaxType.codesAndRates : null;
		const isWorkersComp = selectedTaxType ? selectedTaxType.isWorkersComp : false;

		if (isLoading) {
			return (
				<div className="AddEditTax__loadingState">
					<Loader />
				</div>
			);
		}

		return (
			<div className="AddEditTax">
				<div className="AddEditTax__fields js-AddEditTaxForm">
					{ isEdit ?
						<EditTaxForm { ...this.state } updateFormData={ this._updateFormData } />
						:
						<AddTaxForm { ...this.state } updateFormData={ this._updateFormData } />
					}

					{ isWorkersComp && (
						<form className="AddEditTax__codesAndRates js-codesAndRatesForm">
							<CodesAndRates
								clientId={ clientId }
								clientTaxTypeIncludeId={ clientTaxTypeIncludeId }
								codesRatesData={ codesAndRates }
								hasError={ codesRatesError }
								isCodesRatesEdit={ false }
								isTaxEdit={ isEdit }
							/>
						</form>
					) }
				</div>
				{ showModal && (
					<TaxIdReminderModal
						feeInfo={ feeInfo }
						onClose={ this._handleOnClose }
						primaryAction={ this._saveData }
					/>
				) }
			</div>
		);
	}
}
