import { ifFeature } from '@bamboohr/utils/lib/feature';
import AppModal from './modal';

export default function UninstallModal(props) {
	const {
		app = {},
		actions: {
			uninstall,
		},
	} = props;

	/**
	 * Add callback to show "Change Password" option from Site Nav dropdown
	 * if uninstalling Google SSO.
	 */
	const callback = app.name === 'googleSso'
		? () => {
			const siteNavItem = document.querySelector('.js-siteManageNavPasswordItem');

			if (siteNavItem) {
				window.SESSION_COMPANY.allowPasswordLogin = true;
				siteNavItem.classList.remove('hidden');
			}
		} : null;

	return (
		<AppModal
			{ ...props }
			headline={ $.__(`Are you sure you want to uninstall %1$s?`, app.title) }
			dangerousContent={ app.uninstallExtraContent }
			icon={ifFeature('encore', 'trash-can-regular', "fab-trash-can-43x48")}
			iconColor="danger"
			iconV2Color='primary-strong'
			primaryAction={ () => uninstall(app, callback) }
			primaryActionText={ $.__('Yes, Uninstall This') }
			title={ $.__('Uninstall') }
			type="uninstall"
		/>
	);
}
