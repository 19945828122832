import { PlanFilter } from './types/plan-filter';
import {
	PlanTypeEnumContract,
} from '../types';

export const DATE_MASK_TO_MOMENT = {
	'dd MMM YYYY': 'DD MMM YYYY',
	'mm/dd/yyyy': 'MM/DD/YYYY',
	'dd/mm/yyyy': 'DD/MM/YYYY',
	'yyyy-mm-dd': 'YYYY-MM-DD'
};

export const UPCOMING_DAYS_THRESHOLD = 30;

export const PLAN_FILTER_LABEL = {
	[PlanFilter.Active]: $.__('Active plans'),
	[PlanFilter.Inactive]: $.__('Inactive plans'),
	[PlanFilter.All]: $.__('All plans'),
};

export const PLAN_TYPE_DROPDOWN_LABEL = {
	[PlanTypeEnumContract.health]: $.__('Medical'),
	[PlanTypeEnumContract.dental]: $.__('Dental'),
	[PlanTypeEnumContract.vision]: $.__('Vision'),
	[PlanTypeEnumContract.supplemental]: $.__('Supplemental Health'),
	[PlanTypeEnumContract.retirement]: $.__('Retirement'),
	[PlanTypeEnumContract.reimbursement]: $.__('Reimbursement'),
	[PlanTypeEnumContract.hsa]: $.__('Health Savings Account'),
	[PlanTypeEnumContract.flex]: $.__('Flex Savings Account'),
	[PlanTypeEnumContract.life]: $.__('Life Insurance'),
	[PlanTypeEnumContract.disability]: $.__('Disability'),
	[PlanTypeEnumContract.other]: $.__('Other'),
};

export const TOOLTIP_LIST_ITEM_MAX = 5;
