import { BodyText, Button, Flex, LayoutBox, StandardModal, StyledBox, Table, TextButton } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { Modal } from 'modal-legacy';
import { Fragment, PureComponent } from 'react';
import { columns } from './columns';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import * as utils from '../../utils';

export default class OfferTemplatesTable extends PureComponent {
	deleteTemplate = (template) => {
		const primaryAction = () => {
			utils.deleteTemplate('editOfferTemplate', template.id).then(() => {
				this.updateRowData(template.id);
			});
		};
		this.openModal(template.name, primaryAction);
	};

	updateRowData = (id) => {
		const { templates } = this.state;
		const updatedTemplates = utils.deleteTemplateData(templates, id);
		this.setState({
			templates: updatedTemplates,
			modalProps: {
				isOpen: false,
			},
		});
	};

	openModal = (name, primaryAction) => {
		this.setState({
			modalProps: {
				isOpen: true,
				name,
				primaryAction,
			},
		});
	};

	closeModal = () => {
		this.setState({
			modalProps: {
				isOpen: false,
			},
		});
	};

	state = {
		templates: this.props.templates,
		modalProps: {
			isOpen: false,
			name: null,
			content: null,
		},
	};

	render() {
		const { templates, modalProps } = this.state;

		const rowData = templates.map((template) => {
			return {
				...template,
				action: () => {
					this.deleteTemplate(template);
				},
			};
		});

		return (
			<Fragment>
				<Table columns={columns} rowKey={(row) => row.id} rows={rowData} />
				{ifFeature(
					'encore',
					<StandardModal isOpen={modalProps.isOpen} onRequestClose={this.closeModal}>
						<StandardModal.Body
							renderFooter={
								<StandardModal.Footer
									actions={[
										<TextButton onClick={this.closeModal} type='button'>
											{$.__('Cancel')}
										</TextButton>,
										<Button key='primary' onClick={modalProps.primaryAction} type='button'>
											{$.__('Delete Template')}
										</Button>,
									]}
								/>
							}
						>
							<StandardModal.Constraint>
								<StandardModal.HeroHeadline
									icon='trash-can-regular'
									iconColor='error-strong'
									text={<Message text={$._('Are you sure you want to delete this offer template?')} />}
								/>
								<LayoutBox marginTop={4}>
									<StyledBox backgroundColor="neutral-extra-extra-weak" borderRadius="small" width="100%">
										<LayoutBox padding="20px">
											<Flex alignItems='center' columnGap={2} flexDirection='column'>
												<BodyText color='neutral-strong' size='small' weight='bold'>
													{modalProps.name}
												</BodyText>
											</Flex>
										</LayoutBox>
									</StyledBox>
								</LayoutBox>
							</StandardModal.Constraint>
						</StandardModal.Body>
					</StandardModal>,
					<Modal
						alternativeActionText={null}
						content={modalProps.content}
						headline={<Message params={[modalProps.name]} text={$._('Are you sure you want to delete the template \n {1}')} />}
						icon='fab-trash-can-43x48'
						iconColor='danger'
						isOpen={modalProps.isOpen}
						onClose={() => {
							this.closeModal();
						}}
						primaryAction={modalProps.primaryAction}
						primaryActionText={$.__('Delete Template')}
						secondaryAction={() => {
							this.closeModal();
						}}
						secondaryActionText={$.__('Keep Template')}
						title={$.__('Just Checking...')}
					/>
				)}
			</Fragment>
		);
	}
}
