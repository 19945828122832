export const getRowData = (applicantStatuses) => {
	const activeStatus = applicantStatuses['Active Statuses'].statuses.map((status) => {
		if (status.enabled) {
			return status;
		}
	}).sort((a,b) => {
		return a.listOrder - b.listOrder;
	});

	const notHired = applicantStatuses.Disqualified.statuses
		.map((status) => {
			if (status.enabled) {
				return status;
			}
		})
		.sort((a, b) => {
			return a.listOrder - b.listOrder;
		});

	const rowData = [
		{
			// TODO: Cleanup - BE is expecting this group to be included even though it's not displayed.
			groupId: applicantStatuses.New.groupId,
			icon: null,
			title: $.__('New'),
			rowData: applicantStatuses.New.statuses,
			hidden: true,
		},
		{
			groupId: applicantStatuses['Active Statuses'].groupId,
			icon: 'fab-clipboard-12x14',
			title: $.__('Active Statuses'),
			rowData: activeStatus,
			hidden: false,
		},
		{
			groupId: applicantStatuses.Disqualified.groupId,
			icon: 'fab-clipboard-12x14',
			title: $.__('Disqualified'),
			rowData: notHired,
			hidden: false,
		},
	];

	return rowData;
};

