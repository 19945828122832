import { AuthOptions } from '../../constants';
import { AuthType } from './types';

function getHeaderText(): string {
	return $.__('2-Step Login Required');
}

function getInstructionText(type: AuthType, lastFour?: string | number): string | JSX.Element {
	if (type === AuthOptions.BACKUP) {
		return $.__('Enter a backup code');
	}

	if (type === AuthOptions.SMS) {
		return $.__('Enter the code that was texted to the number ending in %s', lastFour);
	}

	return $.__('Enter the code from your authenticator app');
}

export { getHeaderText, getInstructionText };
