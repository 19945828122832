import {
	useContext,
	useEffect,
	useState,
	createContext,
} from 'react';

/**
 * A utility for combining the `useState` and `useContext` hooks
 *
 * @returns {StateContextAPI<S>}
 * @template {{}} S
 */
export function createStateContext() {
	/** @type {React.Context<Partial<StateContext<S>>>} */
	const Context = createContext({});

	return {
		useStateContext: () => useContext(Context),
		Provider({ value, children }) {
			const [state, setState] = useState(value);

			useEffect(() => {
				setState({
					...state,
					...value,
				});
			}, [value]);

			return (
				<Context.Provider
					value={ {
						...state,
						setState,
					} }
				>
					{ children }
				</Context.Provider>
			);
		},
	};
}

/**
 * The state returned by the `useStateContext()` hook
 *
 * @typedef {S & { setState: React.Dispatch<S>}} StateContext
 * @template {{}} S
*/

/**
 * The API returned by the `createStateContext()` utility
 *
 * @typedef StateContextAPI
 * @property {() => StateContext<S>} useStateContext A hook that returns the current state of the `StateContext`
 * @property {React.Provider<StateContext<S>>} Provider The `React.Provider` for wrapping your shared context
 * @template {{}} S
 */
