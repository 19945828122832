import { EmployeeJobInfoProps, EmployeeSelectField } from '../types';
import { Select } from '@bamboohr/fabric';
import { FormSection } from './form-section';

export function JobInfo({
	fields: { jobLocation, employmentStatus },
	missingFields: { jobLocation: jobLocationMissing, employmentStatus: employmentStatusMissing },
	onChange,
}: EmployeeJobInfoProps) {
	const { options: employmentStatusOptions, selected: employmentStatusSelected } = employmentStatus as EmployeeSelectField;
	const { options: jobLocationOptions, selected: jobLocationSelected } = jobLocation as EmployeeSelectField;

	return !!jobLocationMissing || !!employmentStatusMissing ? (
		<FormSection title={$.__('Job Information')}>
			{!!jobLocationMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='job location'>
							{$.__('Job Location')}
						</label>
						<Select
							isClearable={false}
							items={jobLocationOptions}
							name='job location'
							onSelect={(value: string) => onChange('jobLocation', value || '', true)}
							selectedValues={[jobLocationSelected]}
							width={7}
						/>
					</div>
				</div>
			)}
			{!!employmentStatusMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='employment status'>
							{$.__('Employment Status')}
						</label>
						<Select
							isClearable={false}
							items={employmentStatusOptions}
							name='employment status'
							onSelect={(value: string) => onChange('employmentStatus', value || '', true)}
							selectedValues={[employmentStatusSelected]}
							width={7}
						/>
					</div>
				</div>
			)}
		</FormSection>
	) : null;
}
