import {
	federalW2Box12DdRadioOptions,
} from './federal-w2-box-12dd-radio-options';
import {
	federalW2Box12DdHide,
	federalW2Box12DdMinimumShowCount,
	federalW2Box12DdShow,
} from '../constants';
import {
	L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_SHOW_SUBTEXT,
} from '../language';

export function handleW2Box12DdEnforceOptions(employeeCount: number) {
	if (!employeeCount || employeeCount < federalW2Box12DdMinimumShowCount) {
		return federalW2Box12DdRadioOptions;
	}
	return federalW2Box12DdRadioOptions.map((option) => {
		if (option.id === federalW2Box12DdShow) {
			return {
				...option,
				checked: true,
				note: L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_SHOW_SUBTEXT,
			}
		}
		if (option.id === federalW2Box12DdHide) {
			return {
				...option,
				checked: false,
				disabled: true,
			};
		}
		return {
			...option,
		};
	});
}
