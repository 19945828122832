import { useEffect } from 'react';

import { Select } from '@fabric/select';

export function StateField(props) {
	const {
		disabled,
		clientId,
		error,
		items,
		name,
		placeholder,
		renderOptionContent,
		renderToggleContent,
		taxTypeIdName,
		updateFormData,
		value,
		width,
	} = props;

	useEffect(() => {
		if (clientId) {
			updateFormData(name, { disabled: false, value: null });
		} else {
			updateFormData(name, { disabled: true, value: null });
		}
	}, [clientId]);

	useEffect(() => {
		if (items.length > 0 && value) {
			const selectedState = items.filter(item => item.value === value)[0];
			updateFormData(name, { error: false, selectedState });
		} else {
			updateFormData(name, { error: false, selectedState: null });
		}
	}, [items, value]);

	function handleOnChange(stateAbbreviation) {
		updateFormData(name, { error: false, value: stateAbbreviation[0] || null });
	}

	return (
		<Select
			condition={ error ? 'error' : '' }
			isDisabled={ disabled }
			items={ items }
			name={ name }
			onChange={ handleOnChange }
			placeholder={ disabled ? placeholder : `-${ $.__('Select') }-` }
			renderOptionContent={ renderOptionContent }
			renderToggleContent={ renderToggleContent }
			selectedValues={ [value] }
			width={ width || 5 }
		/>
	);
}
