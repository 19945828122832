import {
	isNil,
	isString,
} from 'lodash';

import {
	stripNonNumericCharacters,
} from './strip-non-numeric-characters';

/**
 * Transforms Number into formatted number string
 * @param {string|number} amount Amount in for formatting
 */
export function formatAmount(amount: string | number = 0): string {
	if (isString(amount)) {
		amount = stripNonNumericCharacters(amount);
	}
	amount = Number(amount);
	if (isNil(amount) || isNaN(amount)) {
		throw new Error('Invalid value supplied');
	}

	return amount.toLocaleString(undefined, { minimumFractionDigits: 2 });
}
