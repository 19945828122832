import Ajax from '@utils/ajax';

const requestAdvantagePackageDemoAsync = () => {
	return Ajax.post('/settings/account/billing/request_advantage_demo').then((response) => {
		if (response.status !== 200) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
	});
};

const requestPerformanceDemoAsync = () => {
	return Ajax.get('/settings/performance', { schedule_demo: true }).then((response) => {
		if (response.status !== 200) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
	});
};

const requestProPackageDemoAsync = () => {
	return Ajax.post('/settings/account/billing/request_pro_demo').then((response) => {
		if (response.status !== 200) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
	});
};

const requestTimeTrackingDemoAsync = (formData) => {
	return Ajax.post('/settings/time_tracking/upsell', formData).then((response) => {
		if (response.status !== 200) {
			throw new Error(`Responded with a status of ${response.status}.`);
		}
	});
};

export default {
	requestAdvantagePackageDemoAsync,
	requestPerformanceDemoAsync,
	requestProPackageDemoAsync,
	requestTimeTrackingDemoAsync,
};
