import { useEffect, useState } from 'react';

import { AgeBandedCostTable } from './age-banded-cost-table';
import { CoverageCostTable } from './coverage-costs-table';
import { MonthlyRateTable } from './monthly-rate-table';
import { getCostsTableType } from './utils';
import { VariableCostsMessage } from './variable-cost-message';
import { useValidationContext } from '../../../../context';
import { CostsTableType, UpdateGroupProperty } from '../../../../steps/types';
import {
	CostTypeEnumContract,
	CoverageCostValues,
	Currency,
	EligibilityGroupValues,
	PlanCategoryDataOptionsContract,
	PlanWizardData,
	UpdateFieldValue,
	WizardValues,
} from '../../../../types';
import { isSafeHarborIncluded, validateEmployeeCostsForTotalCostChange } from '../../../../utils';
import { FEATURE_TOGGLE_KEYS } from '../../../../../etc/feature-toggle';
import { useFeatureToggles } from '../../../../../hooks/use-feature-toggles';

interface EligibilityGroupCostsTableProps {
	currencies: Currency[];
	group: EligibilityGroupValues;
	groupIndex: number;
	planCategoryDataOptions: PlanCategoryDataOptionsContract;
	updateFieldValue: UpdateFieldValue;
	updateGroupProperty: UpdateGroupProperty;
	wizardData: PlanWizardData;
	wizardValues: WizardValues;
}
export const EligibilityGroupCostsTable = (props: EligibilityGroupCostsTableProps): JSX.Element => {
	const { currencies, group, groupIndex, updateFieldValue, updateGroupProperty, wizardData, wizardValues } = props;
	const { coverageLevels, currencyCode, deductionType, eligibilityGroups, employeeCostType, rateType, planCategoryDataOptions } =
		wizardValues;

	const { isEnabled } = useFeatureToggles();
	const { removeSpecificError, setSpecialErrors, specialErrors } = useValidationContext();

	const isBenefitsAdminEnabled = isEnabled(FEATURE_TOGGLE_KEYS.BenefitsEmployeeEnrollment);
	const [currencyWarning, setCurrencyWarning] = useState<boolean>(false);

	const costsTableType = getCostsTableType(wizardData.plan.type, rateType, planCategoryDataOptions, isBenefitsAdminEnabled);

	useEffect(() => {
		// Clear out the safe harbor value when it becomes hidden
		if (!isSafeHarborIncluded(deductionType, wizardData.decisionData.hasTrax)) {
			updateFieldValue('usesSafeHarbor', '');
		}

		// Set currency warning if TRAX client uses non USD currency
		if (wizardData.decisionData.hasTrax && currencyCode !== 'USD') {
			setCurrencyWarning(true);
		}
	}, [wizardData.decisionData.hasTrax, currencyCode, deductionType, updateFieldValue]);

	const getCoverageLevelIndex = (coverageLinkId: string): number => {
		return coverageLevels.findIndex((coverageLevel) => {
			return coverageLevel.linkId === coverageLinkId;
		});
	};

	const handleCurrencyCodeChange = (currencyCode: string): void => {
		if (wizardData.decisionData.hasTrax && currencyCode !== 'USD') {
			setCurrencyWarning(true);
		}
		if (wizardData.decisionData.hasTrax && currencyCode === 'USD') {
			setCurrencyWarning(false);
		}

		updateFieldValue('currencyCode', currencyCode);
	};

	const updateCoverageCostProperty = (
		key: keyof CoverageCostValues,
		newValue: CoverageCostValues[keyof CoverageCostValues],
		groupIndex: number,
		coverageLinkId: string
	) => {
		const coverageLevelLinkIds = [];
		const newCoverageCosts = [...eligibilityGroups[groupIndex].coverageCosts].filter((item) => {
			if (coverageLevelLinkIds.includes(item.coverageLevelLinkId)) {
				return false;
			}
			coverageLevelLinkIds.push(item.coverageLevelLinkId);
			return true;
		});
		const coverageCostIndex = newCoverageCosts.findIndex((item) => {
			return item.coverageLevelLinkId === coverageLinkId;
		});

		if (coverageCostIndex > -1) {
			newCoverageCosts[coverageCostIndex] = {
				...newCoverageCosts[coverageCostIndex],
				[key]: newValue,
			};

			updateGroupProperty(groupIndex, 'coverageCosts', newCoverageCosts);
		}
	};

	const updateEmployeeCost = (newValue: number, groupIndex: number, coverageLinkId: string) => {
		updateCoverageCostProperty('employeeCost', newValue, groupIndex, coverageLinkId);
	};

	const updateEmployeeCostType = (newCostType: CostTypeEnumContract) => {
		updateFieldValue('employeeCostType', newCostType);
	};

	const updateTotalCost = (newTotalCost: number, coverageLinkId: string): void => {
		const updateIndex = getCoverageLevelIndex(coverageLinkId);

		if (updateIndex > -1) {
			const newCoverageLevels = [...coverageLevels];
			newCoverageLevels[updateIndex] = {
				...newCoverageLevels[updateIndex],
				totalCost: newTotalCost,
			};

			updateFieldValue('coverageLevels', newCoverageLevels);
		}

		validateEmployeeCostsForTotalCostChange(coverageLinkId, newTotalCost, eligibilityGroups, employeeCostType, {
			setSpecialErrors,
			removeSpecificError,
		});
	};

	return (
		<>
			{costsTableType === CostsTableType.coverageCost && (
				<div className='fab-FormSection'>
					<CoverageCostTable
						coverageCosts={group.coverageCosts}
						currencies={currencies}
						currencyWarning={currencyWarning}
						groupId={group.id}
						groupIndex={groupIndex}
						handleCurrencyCodeChange={handleCurrencyCodeChange}
						isBenefitsAdminEnabled={isBenefitsAdminEnabled}
						key={group.id}
						planState={wizardData.plan.state}
						supportedCoverages={wizardData.supportedCoverages}
						updateEmployeeCost={updateEmployeeCost}
						updateEmployeeCostType={updateEmployeeCostType}
						updateTotalCost={updateTotalCost}
						wizardValues={wizardValues}
					/>
				</div>
			)}

			{costsTableType === CostsTableType.monthlyRate && (
				<MonthlyRateTable
					eligibilityGroup={group}
					groupIndex={groupIndex}
					planCategoryDataOptions={planCategoryDataOptions}
					updateGroupProperty={updateGroupProperty}
					wizardValues={wizardValues}
				/>
			)}

			{costsTableType === CostsTableType.ageBanded && (
				<AgeBandedCostTable
					currencies={currencies}
					eligibilityGroup={group}
					groupIndex={groupIndex}
					setSpecialErrors={setSpecialErrors}
					specialErrors={specialErrors}
					updateGroupProperty={updateGroupProperty}
					wizardValues={wizardValues}
				/>
			)}
			{costsTableType === CostsTableType.variableCostsMessage && (
				<VariableCostsMessage planCategoryDataOptions={planCategoryDataOptions} rateType={rateType} />
			)}
		</>
	);
};
