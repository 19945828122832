import {
	Fragment,
	useMemo,
} from 'react';

import Row from '../row';
import RowActions from '../row-actions';
import {
	useReducerContext,
} from '../state';

import {
	isMissing,
} from '../utils';

import NameCell from './name-cell';
import TranslationCell from './translation-cell';
import RatingsSelect from './ratings-select';


export default function PerformanceRow({
	question,
}) {
	const {
		employeeQuestion,
		managerQuestion,
		ratings,
	} = question;
	const {
		...context
	} = useReducerContext();
	const hasRatings = useMemo(() => (
		Object.keys(ratings).length > 0
	), [ratings]);
	const ratingsItems = useMemo(() => ratings && (
		Object.entries(ratings).map(([value, text]) => ({ value, text }))
	), [ratings]);

	const rowCount = !!employeeQuestion + !!managerQuestion + hasRatings;
	const index = {
		employee: [1, rowCount],
		manager: [!!employeeQuestion + 1, rowCount],
		rating: [!!employeeQuestion + !!managerQuestion + 1, rowCount],
	};

	return (
		<Fragment>
			{ employeeQuestion && (
				<Row
					field={ question }
					index={ index.employee }
					missing={ isMissing(question, context, 'employee') }
					missingFields={ false }
					NameCell={ () => (
						<NameCell
							question={ question }
							type={ 'employee' }
						/>
					) }
					parent={ question }
					TranslationCell={ () => (
						<TranslationCell
							question={ question }
							type={ 'employee' }
						/>
					) }
				/>
			) }
			{ managerQuestion && (
				<Row
					field={ question }
					index={ index.manager }
					missing={ isMissing(question, context, 'manager') }
					missingFields={ false }
					NameCell={ () => (
						<NameCell
							question={ question }
							type={ 'manager' }
						/>
					) }
					parent={ question }
					TranslationCell={ () => (
						<TranslationCell
							question={ question }
							type={ 'manager' }
						/>
					) }
				/>
			) }
			{ hasRatings && (
				<Row
					ActionsCell={ () => (
						<RowActions
							id={ question.id }
							question={ question }
						/>
					) }
					field={ question }
					index={ index.rating }
					missing={ false }
					missingFields={ false }
					NameCell={ () => (
						<NameCell
							question={ question }
							type={ 'rating' }
						/>
					) }
					parent={ question }
					TranslationCell={ () => (
						<RatingsSelect
							ratings={ ratings }
						/>
					) }
				/>
			) }
		</Fragment>
	);
}
