import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import { reducers } from './reducers';

const middleware = composeWithDevTools(applyMiddleware(thunk));

export function getInitialState() {
	return {
		allParsedOptions: {},
		approverOptions: {},
		availableUsers: {},
		confirmationRequired: false,
		confirmationType: null,
		editState: false,// false or true depending on if currently editing a workflow
		editingWorkflow: false, // false or {} depending on if currently editing a workflow
		editingWorkflowId: false, // false or Number depending on if currently editing a workflow
		newPathOpen: false,
		newPathSelectedOptions: [],
		pathTypeChildren: [],
		pathTypeOptions: [],
		processing: false,
		requestOptions: {},
		subNavItems: [],
		userGroups: {},
		workflow: null,
		workflowId: null
	};
}

export function createWorkflowStore() {
	return createStore(
		reducers,
		getInitialState(),
		middleware
	);
}
