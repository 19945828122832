import {
	Popover,
	TextButton,
} from '@bamboohr/fabric';
import React, {
	ReactElement,
	useRef,
	useState,
} from 'react';
import {
	ExternalLink,
	InternalLink,
	Message,
} from '@bamboohr/utils/lib/message';

import {
	useStyles,
} from './help-button.styles';

export function HelpButton(): ReactElement {
	const refHelpButton = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const styles = useStyles();

	return (
		<>
			<span className={ styles.helpTextButton }>
				<TextButton
					ref={ refHelpButton }
					inline={ true }
					onClick={ () => setIsOpen(true) }
					type="button"
				>
					{ $.__('Need Help?') }
				</TextButton>
			</span>
			{ refHelpButton.current && (
				<Popover
					anchorEl={ refHelpButton.current }
					onClose={ () => setIsOpen(false) }
					open={ isOpen }
					title={ $.__(`We're Here For You!`) }
				>
					<div className={ styles.popoverContent }>
						<Message
							text={ $._(`If you're stuck, check out our\n**[Payroll Migration help page](1)**\nor shoot us a quick email at\n**[{1}](2)**`) }
							link1={ExternalLink('https://help.bamboohr.com/hc/en-us/articles/4409784880781', styles.popoverLink)}
							link2={InternalLink('mailto:payrollmigration@bamboohr.com', styles.popoverLink)}
							params={['payrollmigration@bamboohr.com']}
						/>
					</div>
				</Popover>
			) }
		</>
	);
}
