import { Fragment } from 'react';

export function EnpsCopyright() {
	return (
		<Fragment>
			{$.__(
				'Net Promoter, NPS, and the NPS-related emoticons are registered U.S. trademarks, and NetPromoter Score and Net Promoter System are service marks, of Bain & Company, Inc., NICE Systems, Inc. and Fred Reichheld.',
			)}
		</Fragment>
	);
}
