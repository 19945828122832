import { BasicLifeCategoryDataOptionsContract, LifeCoverageOptions } from '../../types';
import { DEFAULT_COVERAGE_OPTIONS_VALUES } from '../../constants';

export const mapBasicLifeCoverageOptions = (
	planCategoryDataOption: BasicLifeCategoryDataOptionsContract
) => {
	const options = planCategoryDataOption as any;

	return {
		spouseCoverage: planCategoryDataOption.spouseCoverage || DEFAULT_COVERAGE_OPTIONS_VALUES.spouseCoverage,
		childCoverage: planCategoryDataOption.childCoverage || DEFAULT_COVERAGE_OPTIONS_VALUES.childCoverage,
		type: planCategoryDataOption.coverage?.type || null,
		employeeAmount: options.coverage?.employee?.amount || null,
		employeeIncrement: null,
		employeeMinimumAmount: options.coverage?.employee?.minimumAmount || null,
		employeeMaximumAmount: options.coverage?.employee?.maximumAmount || null,
		employeeMultiplier: options.coverage?.employee?.multiplier || 1,
		spouseAmount: options.coverage?.spouse?.amount || null,
		spouseAmountType: options.coverage?.spouse?.type || DEFAULT_COVERAGE_OPTIONS_VALUES.spouseAmountType,
		spouseIncrement: null,
		spouseMinimumAmount: null,
		spouseMaximumAmount: null,
		spousePercentOfEmployeeCoverage: null,
		childAmount: options.coverage?.child?.amount || null,
		childIncrement: null,
		childMinimumAmount: null,
		childMaximumAmount: null,
	} as LifeCoverageOptions;
};
