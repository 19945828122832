import { BlankState, Flex, Headline, LayoutBox, Section } from '@bamboohr/fabric';
import React from 'react';

export const TasksToCompleteBlankState = () => {
	return (
		<Section>
			<Section.Header icon='list-check-solid' title={<Headline size='medium'>{$.__('Tasks to Complete')}</Headline>} />
			<Flex justifyContent='center' width='100%'>
				<LayoutBox maxWidth='616px' paddingY={3}>
					<BlankState icon='bs-clipboard' title={$.__('Onboarding tasks can be added when the New Hire Packet is being created.')} />
				</LayoutBox>
			</Flex>
		</Section>
	);
};
