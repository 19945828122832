import {
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles((theme) => ({
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	heading: {
		width: '512px',
		textAlign: 'center',
	},
	icon: {
		fill: theme.palette.error.main,
		marginBottom: '12px',
	},
	title: {
		fontSize: '18px',
		fontWeight: 600,
	},
	content: {
		color: '#444',
		fontSize: 14,
	},
	subsection: {
		backgroundColor: '#F4F4F4',
		margin: '12px 0px 24px 0px',
		borderRadius: '2px',
		padding: '18px 0px 24px 0px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '512px',
		textAlign: 'center',
	},
	subheader: {
		fontWeight: 600,
		fontSize: '16px',
	},
	warning: {
		fontSize: '14px',
		margin: '15px 0px 8px 0px',
		color: theme.palette.error.main,
	}
}));