import {
	useMemo,
} from 'react';
import { Table } from '@bamboohr/fabric';

import {
	getAdditionalOptionsFromSectionMetadata,
	getMappingTableColumns,
	getArrayFromNormalizedItems,
	getShouldUseOnlyAdditionalOptions,
} from './mapping-table-utils';
import {
	getGroups,
} from '../migration-table/migration-table-utils';

import {
	MappingTableProps,
	MappingTableRowItem,
} from '../../utils/interfaces';

import './mapping-table.styl';

// TODO: Delete with release of MEIN changes
import { isEnabled } from 'FeatureToggle.util';
export const MX_MEIN_ENABLED = isEnabled('mxMein');

export function MappingTable(props: MappingTableProps): JSX.Element {
	const {
		clientId,
		type,
		isGrouped,
		isMultipleEin,
		groups,
		groupIcon,
		bambooNeedsTrax,
		traxNeedsBamboo,
		onChange,
		onMetadataChange,
		sectionMetadata,
		selectedRecordIds,
		hasError,
		mappingErrorFieldIds,
	} = props;
	const doMultipleEinStuff = MX_MEIN_ENABLED && isMultipleEin;
	const useOnlyAdditionalBambooOptions = useMemo(() => getShouldUseOnlyAdditionalOptions(doMultipleEinStuff, type), [doMultipleEinStuff, type]);
	const {
		itemsToMap: bambooItemsToMap,
		options: bambooOptions,
	} = getArrayFromNormalizedItems(bambooNeedsTrax, selectedRecordIds, hasError, mappingErrorFieldIds);
	const {
		itemsToMap: traxItemsToMap,
		options: traxOptions,
	} = getArrayFromNormalizedItems(traxNeedsBamboo, selectedRecordIds, hasError, mappingErrorFieldIds);

	const {
		additionalBambooOptions,
	} = getAdditionalOptionsFromSectionMetadata(type, sectionMetadata, clientId, doMultipleEinStuff);

	const allBambooOptions = useOnlyAdditionalBambooOptions ? additionalBambooOptions : [...bambooOptions, ...additionalBambooOptions];

	return (
		<div className="MappingTable">
			<Table
				caption={ $.__('Items to be mapped') }
				columns={ getMappingTableColumns(isGrouped, type, onChange, allBambooOptions, traxOptions, hasError, selectedRecordIds, onMetadataChange, bambooNeedsTrax, traxNeedsBamboo) }
				groups={ isGrouped ? getGroups(groups, groupIcon, hasError, type) : [] }
				rowKey={ (row: MappingTableRowItem) => row.value }
				rows={ [...bambooItemsToMap, ...traxItemsToMap] }
			/>
		</div>
	);
}
