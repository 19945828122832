import Ajax from '@utils/ajax';
import {
	queryStringToObject,
} from '@utils/url/query';
import { showSlidedown } from 'Alerts.mod';

import {
	App,
} from './settings-apps.react/context/state';

const ajax = Ajax.create('SettingsApps', {
	headers: {
		Accept: 'application/json',
	},
});

type AjaxResponseData = {
	installedApps: string[];
};

type AjaxSuccessResponse = {
	success?: string;
	error: never;
} & AjaxResponseData;

type AjaxErrorResponse = {
	success: never;
	error: string;
};

type AjaxResponse = (
	AjaxSuccessResponse |
	AjaxErrorResponse
);

ajax.instance.interceptors.response.use((response) => {
	const {
		data: {
			success,
			error,
			...data
		},
	} = response;

	if (error) {
		if (
			error &&
			typeof error === 'string'
		) {
			showSlidedown(error, 'error');
		}

		return Promise.reject(new Error(error));
	}

	if (
		success &&
		typeof success === 'string'
	) {
		showSlidedown(success, 'success');
	}

	return response;
});


function _cleanUrl(url: string, params = {}): { url: string; params: object } {
	const _url = new URL(url, window.location.href);

	url = _url.pathname;

	if (_url.hostname !== window.location.hostname) {
		url = _url.origin + url;
	}

	params = {
		...queryStringToObject(_url.search),
		...(params || {}),
	};

	return {
		url,
		params,
	};
}

export const uninstall = (app: App, callback?: Function): Promise<AjaxResponseData> => {
	return ajax.delete<AjaxResponseData>(`/settings/apps/${ app.name }`)
		.then(({ data: { installedApps } }) => {
			showSlidedown($.__(`%1$s was successfully uninstalled`, app.title), 'success');

			if (callback) {
				callback();
			}

			return { installedApps };
		});
}

export const postConfig = (app: App, data): Promise<AjaxResponseData> => {
	const {
		url,
		params,
	} = _cleanUrl(app.installUrl, {
		SettingsApps: true,
	});

	if (data instanceof HTMLFormElement) {
		data = new FormData(data);
	}

	return ajax.request<AjaxResponseData>({
		url,
		method: 'post',
		params,
		data,
	})
		.then(({ data: { installedApps } }) => ({ installedApps }));
};

export const saveConfig = (app: App, data, callback?: Function) => {
	return postConfig(app, data)
		.then((data) => {
			let msg = "";
			if ( app.customInstallMessage ) {
				msg = app.customInstallMessage;
			} else {
				msg = app.installed ?
					$.__('%1$s settings were successfully updated', app.title) :
					$.__('%1$s was successfully installed', app.title);
			}

			showSlidedown(msg, 'success');

			if (callback) {
				callback();
			}

			return data;
		});
}
