import { BodyText, Flex, Icon, IconButton, LayoutBox, TextButton, TextField } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button, ButtonGroup, FloatingIconButton } from '@fabric/button';
import { Fragment, PureComponent } from 'react';
import Tooltip from 'tooltip.react';

import { isEnabled } from 'FeatureToggle.util';
import axios from 'axios';
import { keyBy } from 'lodash';
/* @startCleanup encore */
/* @endCleanup encore */
import { DeleteModal } from '../../delete-modal';
import * as utils from '../../utils';
import { refreshSortable, sortable } from '../../utils/sortable';
import { getRowData } from '../component/columns';
import { AUTOMATED_EMAIL_RULE_TYPE, DISQUALIFYING_QUESTION_RULE_TYPE } from '../constants';

const JOB_PIPELINE_ENABLED = isEnabled('ATS_CANDIDATE_PIPELINE');
const AUTOMATED_EMAILS_ENABLED = isEnabled('ATS_AUTOMATED_EMAILS');
const DISQUALIFYING_QUESTIONS_ENABLED = isEnabled('ATS_DISQUALIFYING_QUESTION');

export default class SettingTable extends PureComponent {
	getThead = (headers) => {
		return (
			<tr className='fab-Table__row' key='tableHead'>
				{headers.map((elem) => {
					return (
						<th className='fab-Table__header' key={elem}>
							{elem}
						</th>
					);
				})}
			</tr>
		);
	};

	getTbody = (rowData) => {
		return rowData.map((rowGroup) => {
			const group = [
				this.getTbodyGroups(rowGroup),
				this.getTbodyRow(rowGroup.rowData),
				rowGroup.hidden ? null : this.addStatusBtn(rowGroup.groupId),
			];
			return group;
		});
	};

	getTbodyGroups = (rowGroup) => {
		const { groupId, icon, title, hidden } = rowGroup;
		if (hidden) {
			return (
				<tr className='hidden' id={`status_row_status_id-${groupId}cat`} key={groupId} name={`applicantStatus['${groupId}']`}></tr>
			);
		}

		return (
			<tr className='fab-Table__row' id={`status_row_status_id-${groupId}cat`} key={groupId} name={`applicantStatus['${groupId}']`}>
				{ifFeature(
					'encore',
					<th className='fab-Table__header' colSpan='2'>
						{title}
					</th>,
					<td className='fab-Table__cell fab-Table__cell--group' colSpan='2'>
						<div className='fab-Table__group'>
							<div className='fab-Table__groupIcon'>
								<ba-icon name={icon}></ba-icon>
							</div>
							{title}
						</div>
					</td>
				)}
			</tr>
		);
	};

	getRowNote = (status) => {
		const { companyAtsPlusEnabled, jobPipelineCounts, jobRuleCounts } = this.state;
		if (!companyAtsPlusEnabled) {
			return null;
		}

		const automatedEmailsNote =
			AUTOMATED_EMAILS_ENABLED && jobRuleCounts[status.id] && jobRuleCounts[status.id].ruleTotals[AUTOMATED_EMAIL_RULE_TYPE] > 0
				? $.__n('%s automated email', '%s automated emails', jobRuleCounts[status.id].ruleTotals[AUTOMATED_EMAIL_RULE_TYPE])
				: '';

		const disqualifyingQuestionsNote =
			DISQUALIFYING_QUESTIONS_ENABLED &&
			jobRuleCounts[status.id] &&
			jobRuleCounts[status.id].ruleTotals[DISQUALIFYING_QUESTION_RULE_TYPE] > 0
				? $.__n(
						'%s disqualifying question',
						'%s disqualifying questions',
						jobRuleCounts[status.id].ruleTotals[DISQUALIFYING_QUESTION_RULE_TYPE]
				  )
				: '';

		const jobPipelineNote =
			JOB_PIPELINE_ENABLED && jobPipelineCounts[status.id] && jobPipelineCounts[status.id].totalJobs > 0
				? $.__n('%s job pipeline', '%s job pipelines', jobPipelineCounts[status.id].totalJobs)
				: '';

		const notes = [disqualifyingQuestionsNote, automatedEmailsNote, jobPipelineNote].filter(Boolean);
		const constructedString = notes.join(' - ');

		return ifFeature(
			'encore',
			<BodyText color='neutral-medium' size='extra-small' weight='regular'>
				{constructedString}
			</BodyText>,
			<p className='candidateStatuses__note'>{constructedString}</p>
		);
	};

	getTbodyRow = (rowData) => {
		const { statusId } = this.state;
		return rowData.map((data) => {
			if (statusId === data.id) {
				return this.editStatus();
			}
			return (
				<tr
					className={`fab-Table__row category=${data.id} status_row_group_id${data.id} ${
						data.applicantStatusGroupId == 1 ? 'hidden' : ''
					}`}
					id={`status_row_status_id_${data.id}`}
					key={data.id}
				>
					<td className='fab-Table__cell moveme' id={data.id}>
						{ifFeature(
							'encore',
							<Flex alignContent='space-between' flexDirection='column' justifyContent='center' minHeight='40px'>
								<BodyText color='neutral-strong' size='medium' weight='regular'>
									{data.translatedName || data.name}
								</BodyText>
								{this.getRowNote(data)}
							</Flex>,
							<div>
								<p>{data.translatedName || data.name}</p>
								{this.getRowNote(data)}
							</div>
						)}
					</td>
					<td className='fab-Table__cell moveme'>
						<div className='fab-Table__cellMask'>
							{ifFeature(
								'encore',
								<Flex alignItems='center' justifyContent='end'>
									{data.manageable == 'yes' ? (
										<div className='fab-Table__cellAction'>
											<IconButton
												floatingIcon={true}
												icon='pen-regular'
												onClick={() => {
													this.setState({ statusId: data.id, editValue: data.name });
												}}
												type='button'
												variant='outlined'
											/>
										</div>
									) : null}
									<div className='fab-Table__cellAction'>
										<IconButton
											floatingIcon={true}
											icon='trash-regular'
											onClick={() => {
												this.removeStatus(data);
											}}
											type='button'
											variant='outlined'
										/>
									</div>
								</Flex>,
								<div className='fab-Table__cellActions'>
									<div className='fab-Table__cellAction'>
										<ButtonGroup>
											{data.manageable == 'yes' ? (
												<Tooltip
													settings={{
														content: $.__('Edit'),
													}}
												>
													<FloatingIconButton
														clickAction={() => {
															this.setState({ statusId: data.id, editValue: data.name });
														}}
														icon='fab-pencil-16x16'
														secondary={true}
														type='button'
													/>
												</Tooltip>
											) : null}
											<Tooltip
												settings={{
													content: $.__('Delete'),
												}}
											>
												<FloatingIconButton
													clickAction={() => {
														this.removeStatus(data);
													}}
													icon='fab-trash-can-14x16'
													secondary={true}
													type='button'
												/>
											</Tooltip>
										</ButtonGroup>
									</div>
								</div>
							)}
						</div>
					</td>
				</tr>
			);
		});
	};

	/**
	 * Show/Hide the Edit status input
	 *
	 * @return {jsx}  	The element to be rendered
	 * */
	editStatus = () => {
		const { groupId, editValue, isProcessing } = this.state;
		return (
			<tr className='fab-Table__row fab-Table__row--highlighted' key={`groupInput${groupId}`}>
				<td
					className={ifFeature(
						'encore',
						'fab2-Table__cell fab2-Table__cell--noVerticalPadding fab2-Table__cell--noHorizontalPadding',
						'fab-Table__cell fab-Table__cell--group'
					)}
					colSpan='2'
				>
					<div className='candidateStatuses__wrapper'>
						{ifFeature(
							'encore',
							<LayoutBox padding={2}>
								<Flex alignItems='center' flexDirection='row' gap={2} justifyItems='space-between'>
									<TextField
										InputProps={{
											endAdornment: (
												<Button
													onClick={(e) => {
														this._handleStatusClick(true);
													}}
													size='small'
													type='button'
													width={4}
												>
													{$.__('Save')}
												</Button>
											),
										}}
										onChange={(e) => {
											this.setState({ editValue: e.target.value });
										}}
										onKeyPress={(event) => {
											this._handleStatusKeyPress(event, true);
										}}
										placeholder={$.__('Status Named')}
										position='end'
										size='small'
										tabIndex='0'
										type='text'
										value={editValue}
										variant='single'
										width={8}
									/>
									<TextButton
										clickAction={() => {
											this.setState({ statusId: null, editValue: '' });
										}}
										size='small'
										text={$.__('Cancel')}
										type='button'
									/>
								</Flex>
							</LayoutBox>,
							<LayoutBox>
								<div className='fab-InputWrapper'>
									<input
										className='fab-TextInput fab-TextInput--width8'
										onChange={(e) => {
											this.setState({ editValue: e.target.value });
										}}
										onKeyPress={(event) => {
											this._handleStatusKeyPress(event, true);
										}}
										placeholder={$.__('Status Name')}
										ref={this._focusInput}
										tabIndex='0'
										type='text'
										value={editValue}
									/>
									<Button
										clickAction={() => {
											this._handleStatusClick(true);
										}}
										isProcessing={isProcessing}
										text={$.__('Save')}
									/>
								</div>
								<div className='candidateStatuses__wrapperCancel'>
									<TextButton
										clickAction={() => {
											this.setState({ statusId: null, editValue: '' });
										}}
										text={$.__('Cancel')}
									/>
								</div>
							</LayoutBox>
						)}
					</div>
				</td>
			</tr>
		);
	};

	/**
	 * Add Status input component rendering
	 *
	 * @return {jsx}  	The element to be rendered
	 * */
	addStatus = () => {
		const { groupId, statusValue, isProcessing } = this.state;
		return (
			<>
				<tr className='fab-Table__row fab-Table__row--highlighted' key={`groupInput${groupId}`}>
					<td
						className={ifFeature(
							'encore',
							'fab2-Table__cell fab2-Table__cell--noVerticalPadding fab2-Table__cell--noHorizontalPadding',
							'fab-Table__cell fab-Table__cell--group'
						)}
						colSpan='2'
					>
						<div className='candidateStatuses__wrapper'>
							{ifFeature(
								'encore',
								<LayoutBox padding={2}>
									<Flex alignItems='center' flexDirection='row' gap={2} justifyItems='space-between'>
										<TextField
											InputProps={{
												endAdornment: (
													<Button
														onClick={(e) => {
															this._handleStatusClick(false);
														}}
														size='small'
														type='button'
														width={4}
													>
														{$.__('Add')}
													</Button>
												),
												onKeyPress: (event) => {
													this._handleStatusKeyPress(event, false);
												},
											}}
											onChange={(e) => {
												this.setState({ statusValue: e.target.value });
											}}
											placeholder={$.__('Status Name')}
											position='end'
											size='small'
											tabIndex='0'
											type='text'
											value={statusValue}
											variant='single'
											width={8}
										/>
										<TextButton
											clickAction={() => {
												this.setState({ groupId: null, statusValue: '' });
											}}
											size='small'
											text={$.__('Cancel')}
											type='button'
										/>
										<Flex flexDirection='column' justifyContent='center'>
											<BodyText color='neutral-strong' size='extra-small' weight='regular'>
												{$.__('Press return to quickly add multiple statuses.')}
											</BodyText>
										</Flex>
									</Flex>
								</LayoutBox>,
								<LayoutBox>
									<div className='fab-InputWrapper'>
										<input
											className='fab-TextInput fab-TextInput--width8'
											onChange={(e) => {
												this.setState({ statusValue: e.target.value });
											}}
											onKeyPress={(event) => {
												this._handleStatusKeyPress(event);
											}}
											placeholder={$.__('Status Name')}
											ref={this._focusInput}
											tabIndex='0'
											type='text'
											value={statusValue}
										/>
										<Button
											clickAction={() => {
												this._handleStatusClick();
											}}
											isProcessing={isProcessing}
											text={$.__('Add')}
										/>
									</div>
									<div className='candidateStatuses__wrapperCancel'>
										<TextButton
											clickAction={() => {
												this.setState({ groupId: null, statusValue: '' });
											}}
											text={$.__('Cancel')}
										/>
										<span className='fab-FormNote fab-FormNote candidateStatuses__wrapperNote'>
											{$.__('Press return to quickly add multiple statuses.')}
										</span>
									</div>
								</LayoutBox>
							)}
						</div>
					</td>
				</tr>
				<tr>
					<LayoutBox marginTop='16px' />
				</tr>
			</>
		);
	};

	/**
	 * Show/Hide the Add/Edit status input
	 *
	 * @param {int}		id	The GroupId - This is used to determine which group section we are toggling.
	 *
	 * @return {jsx}  	The element to be rendered
	 * */
	addStatusBtn = (id) => {
		const { groupId } = this.state;
		const btn = (
			<>
				<tr className='fab-Table__row js-AddNewStatus' key={`groupAction${groupId}`}>
					<td className='fab-Table__cell' colSpan='2'>
						<TextButton
							clickAction={() => {
								this.setState({ groupId: id });
							}}
							inline={true}
							text={$.__('+ Add Status')}
							type='button'
						/>
					</td>
				</tr>
				<tr>
					<LayoutBox marginTop='16px' />
				</tr>
			</>
		);

		return id === groupId ? this.addStatus() : btn;
	};

	/**
	 * Focus on the proper input
	 *
	 * @param {ref}		input
	 * */
	_focusInput = (input) => {
		if (input) {
			input.focus();
		}
	};

	/**
	 * Allow the user to hit the enter key add/edit a status.
	 *
	 * @param {event}		event
	 * @param {boolean}		isEditing	Are we editing an existing status
	 * */
	_handleStatusKeyPress = (event, isEditing) => {
		if (event.key === 'Enter') {
			if (isEditing) {
				this.editCustomStatus();
			} else {
				this.addNewCustomStatus();
			}
		}
	};

	/**
	 * Handle the button click
	 *
	 * @param {boolean}	isEditing	Are we editing an existing status
	 * */
	_handleStatusClick = (isEditing) => {
		if (isEditing) {
			this.editCustomStatus();
		} else {
			this.addNewCustomStatus();
		}
	};

	reorderStatus = (data) => {
		utils.updateStatusesSortOrder(data).then((response) => {
			if (response[0]) {
				const updateData = getRowData(response[0].applicantStatuses);
				this.setState({
					rowData: updateData,
				});
			}
		});
	};

	handleDeleteStatus = (status) => {
		const { disableDefaultStatus } = this.props;
		const { rowData } = this.state;
		utils.removeStatus(status.id).then((response) => {
			const updateData = utils.deleteRowData(rowData, response.status_id);
			this.setState({ rowData: updateData });
			if (status.manageable !== 'yes') {
				disableDefaultStatus(status);
			}
			refreshSortable();
			this.setState({ deletingStatus: null });
			this._queryJobCounts();
		});
	};

	/**
	 * Remove/disable a status from the list
	 *
	 * @param {object}	status	The Statuses object
	 * */
	removeStatus = (status) => {
		const { rowData } = this.state;
		const { disableDefaultStatus } = this.props;

		if (JOB_PIPELINE_ENABLED) {
			this.setState({ deletingStatus: status });
		} else {
			const primaryAction = () => {
				utils.removeStatus(status.id).then((response) => {
					const updateData = utils.deleteRowData(rowData, response.status_id);
					this.setState({ rowData: updateData });
					if (status.manageable !== 'yes') {
						disableDefaultStatus(status);
					}
					refreshSortable();
					this.closeModal();
				});
			};

			this.setState({
				modalProps: {
					isOpen: true,
					content: this.getModalContent(status.name),
					name: status.name,
					primaryAction,
				},
			});
		}
	};

	/**
	 * save and update the rowData when editing a status
	 * */
	editCustomStatus = () => {
		const { editValue, statusId, rowData } = this.state;

		if (editValue.trim() === '') {
			window.setMessage($.__('Please enter a name for the applicant status'), 'error');
			return false;
		}
		window.closeMessage();

		this.setState({ isProcessing: true });

		const data = {
			edit_custom_status: 1,
			status: editValue,
			status_id: statusId,
		};

		utils
			.addEditStatusEndpoint(data)
			.then((response) => {
				const editStatus = {
					id: response.status_id,
					name: response.status,
				};

				const updateData = utils.editRowData(rowData, editStatus);

				this.setState({
					isProcessing: false,
					editValue: '',
					statusId: null,
					rowData: updateData,
				});
			})
			.catch(() => {
				this.setState({ isProcessing: false });
			});
	};

	/**
	 * Save the newly created status.
	 * */
	addNewCustomStatus = () => {
		const { statusValue, groupId, rowData } = this.state;

		if (statusValue.trim() === '') {
			window.setMessage($.__('Please enter a name for the new applicant status'), 'error');
			return false;
		}
		window.closeMessage();

		this.setState({ isProcessing: true });

		const data = {
			add_new_custom_status: 1,
			status: statusValue,
			status_group_id: groupId,
		};

		utils
			.addEditStatusEndpoint(data)
			.then((response) => {
				const newStatus = {
					id: `${response.status_id}`,
					code: null,
					applicantStatusGroupId: groupId,
					name: response.status,
					listOrder: null,
					manageable: 'yes',
					enabled: 'yes',
				};

				const updateData = utils.addRowData(rowData, newStatus);

				this.setState({
					isProcessing: false,
					statusValue: '',
					rowData: updateData,
				});
			})
			.catch(() => {
				this.setState({
					isProcessing: false,
				});
			});
	};

	closeModal = () => {
		this.setState({
			modalProps: {
				isOpen: false,
			},
		});
	};

	getModalContent = (name) => {
		return (
			<Fragment>
				<div className='candidateStatuses__modal' key={`${name}_delete`}>
					<div className='candidateStatuses__modalType' key={`${name}_deleteType`}>
						<Icon name='fab-ats-27x32' />
						<h4>{name}</h4>
					</div>
				</div>
				<span className='candidateStatuses__modalFooter' key={`${name}_footer`}>
					{$.__('Candidates currently in this status will remain so until they are moved into a different status.')}
				</span>
			</Fragment>
		);
	};

	_queryJobCounts = () => {
		axios.get('/hiring/api/permissions').then((res) => {
			const { companyAtsPlusEnabled } = res.data.result;
			this.setState({ companyAtsPlusEnabled });
			if (companyAtsPlusEnabled) {
				if (JOB_PIPELINE_ENABLED) {
					axios.get('/hiring/api/job-pipeline/jobCounts').then((res) => {
						const { result } = res.data;
						if (result) {
							this.setState({ jobPipelineCounts: keyBy(result, 'id') });
						}
					});
				}
				if (AUTOMATED_EMAILS_ENABLED) {
					axios.get('/hiring/api/job-rule/jobCounts').then((res) => {
						const { applicantStatusId } = res.data.result;
						if (applicantStatusId) {
							this.setState({ jobRuleCounts: keyBy(applicantStatusId, 'id') });
						}
					});
				}
			}
		});
	};

	state = {
		groupId: null,
		statusValue: '',
		editValue: '',
		statusId: null,
		isProcessing: false,
		rowData: this.props.rowData,
		modalProps: {
			content: null,
			primaryAction: null,
			isOpen: false,
		},
		deletingStatus: null,
		companyAtsPlusEnabled: false,
		jobRuleCounts: {},
		jobPipelineCounts: {},
	};

	componentDidMount() {
		sortable($('.js-sortable'), 'td.moveme', 'tr:not(.doNotSort)', this.reorderStatus);
		this._queryJobCounts();
	}

	componentDidUpdate(prevProps, prevState) {
		const { statusId, groupId } = this.state;
		if (prevState.statusId !== statusId || prevState.groupId !== groupId) {
			refreshSortable();
		}
	}

	render() {
		const { headers } = this.props;
		const { rowData, modalProps, deletingStatus, companyAtsPlusEnabled, jobRuleCounts, jobPipelineCounts } = this.state;
		return (
			<LayoutBox marginTop='12px'>
				<table className='fab-Table fab-Table--hoverable js-sortable'>
					<thead>{this.getThead(headers)}</thead>
					<tbody className='candidateStatuses__tbody'>{this.getTbody(rowData)}</tbody>
				</table>
				<DeleteModal
					closeModal={() => this.setState({ deletingStatus: null })}
					companyAtsPlusEnabled={companyAtsPlusEnabled}
					deleting={deletingStatus}
					deletingType='status'
					isOpen={!!deletingStatus}
					jobPipelineCounts={jobPipelineCounts[deletingStatus?.id]}
					jobRuleCounts={jobRuleCounts[deletingStatus?.id]}
					onDelete={this.handleDeleteStatus}
				/>
			</LayoutBox>
		);
	}
}
