import {
	showSlidedown,
} from 'Alerts.mod';
import {
	addDeductionName,
	editDeductionName,
	NormalizedDeductions,
} from '../../utils';

export function handleAddName(
	deductionName: string,
	managedDeductionType: string,
	deductions: NormalizedDeductions,
	setSaveProcessing: Function,
	setCurrentDeductions: Function,
	successCallback: Function|null
): void {
	setSaveProcessing(true);

	addDeductionName(deductionName, managedDeductionType)
		.then((response) => {
			const {
				status,
				data,
			} = response;
			if (status === 200) {
				data.numberOfEnrollments = 0;
				data.isActive = true;
				const {
					byId,
					allIds,
				} = deductions;
				byId[data.companyDeductionId] = data;
				allIds.push(data.companyDeductionId);

				setCurrentDeductions({ byId, allIds });

				if (successCallback) {
					successCallback();
				}
			} else {
				showAddNameError();
			}

			setSaveProcessing(false);
		})
		.catch(() => {
			showAddNameError();
			setSaveProcessing(false);
		});
}

export function handleEditName(
	companyDeductionId: number,
	managedDeductionType: string,
	deductionName: string,
	deductions: NormalizedDeductions,
	setSaveProcessing: Function,
	setCurrentDeductions: Function,
	successCallback: Function|null
): void {
	setSaveProcessing(true);

	editDeductionName(companyDeductionId, managedDeductionType, deductionName)
		.then((response) => {
			if (response.status === 200) {
				deductions.byId[companyDeductionId].deductionName = deductionName;
				setCurrentDeductions(deductions);

				if (successCallback) {
					successCallback();
				}
			} else {
				showEditNameError();
			}
			setSaveProcessing(false);
		})
		.catch(() => {
			showEditNameError();
			setSaveProcessing(false);
		});
}

// Local Functions //////
function showAddNameError(): void {
	showSlidedown($.__('Whoops...something went wrong adding a deduction name. Please try again later.'), 'error');
}

function showEditNameError(): void {
	showSlidedown($.__('Whoops...something went wrong editing the deduction name. Please try again later.'), 'error');
}

