import { PlanDependencyContract, PlanDependencyEnum } from '../plan-wizard.react/types';
import { validatePlanDependencies, validateRadioGroup } from '../plan-wizard.react/utils';
import { PlanFieldNames } from '../types/plan-field-names';

export const validateMedicalPlanDependency = (
	medicalPlanDependency: PlanDependencyEnum,
	medicalPlanDependencyOptions: PlanDependencyContract[],
	setSpecialErrors?
): boolean => {
	const hasDependents = Boolean(medicalPlanDependencyOptions.find((plan) => plan.isDependency));
	if (medicalPlanDependency === PlanDependencyEnum.planDependent) {
		return !hasDependents ? validatePlanDependencies(PlanFieldNames.planDependencyOptions, hasDependents, setSpecialErrors) : true;
	}
	if (medicalPlanDependency === PlanDependencyEnum.planIndependent) {
		return true;
	}

	return validateRadioGroup(PlanFieldNames.planDependency, medicalPlanDependency, setSpecialErrors);
};
