import {
	Icon,
	makeStyles,
} from '@bamboohr/fabric';
import {
	useLayoutEffect,
} from 'react';

import { VideoSection } from './video-section';
import { BeginPayrollSection } from './begin-payroll-section';
import { QuestionsSection } from './questions-section';

const STYLES = makeStyles(() => ({
	pageBody: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '43px 0 112px',
	},
	videoWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: '41px',
	},
	beginPayrollWrapper: {
		marginBottom: '152px',
	},
	questionWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '583px',
	},
}));

export const MigrationComplete = () => {
	const classes = STYLES();

	useLayoutEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className="CheckMigrationProgress">
			<div className="CheckMigrationProgress__header">
				<Icon brand={ true } name="fab-check-circle-28x28" />

				<h2 className="CheckMigrationProgress__headerText">
					{ $.__('Congratulations!') }
				</h2>
			</div>

			<div className={ classes.pageBody }>
				<div className={ classes.videoWrapper }>
					<VideoSection />
				</div>

				<div className={ classes.beginPayrollWrapper }>
					<BeginPayrollSection />
				</div>

				<div className={ classes.questionWrapper }>
					<QuestionsSection />
				</div>
			</div>
		</div>
	);
};
