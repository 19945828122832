import { useState, useEffect, useContext, useCallback, ReactElement } from 'react';
import classnames from 'classnames';
import { DatePicker, Select, TextField } from '@bamboohr/fabric';
import MaskedInput from 'react-text-mask';
import { commonInputMasks } from 'dynamic-form/fields/input-masks';
import Ajax from '@utils/ajax';

import { ActiveMissingInfoContextData, EmployeeJobInfoProps, EmployeeSelectField } from '../types';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';
import { format } from '@bamboohr/utils/lib/datetime';
import { FormSection } from './form-section';

const TODAY = format(new Date(), 'yyyy-MM-dd');

export function EmployeeInfo(props: EmployeeJobInfoProps): ReactElement {
	const [addressMissing, setAddressMissing] = useState<boolean>(false);
	const [ssnValid, setSsnValid] = useState<boolean>(true);
	const [ssnLabelClasses, setSsnLabelClasses] = useState<string>('fab-Label');
	const [ssnInputClasses, setSsnInputClasses] = useState<string>('fab-TextInput fab-TextInput--width4');
	const { states } = useContext(ActiveMissingInfoContext) as ActiveMissingInfoContextData;

	const {
		employeeId,
		fields: {
			addressLine1,
			addressLine2,
			city,
			stateId,
			zipcode,
			ssn,
			dateOfBirth,
			gender,
			paySchedule,
			employeeNumber,
			firstName,
			lastName,
			maritalStatus,
			hireDate,
		},
		missingFields: {
			addressLine1: addressLine1Missing,
			city: cityMissing,
			stateId: stateIdMissing,
			zipcode: zipcodeMissing,
			ssn: ssnMissing,
			dateOfBirth: dateOfBirthMissing,
			gender: genderMissing,
			paySchedule: payScheduleMissing,
			employeeNumber: employeeNumberMissing,
			firstName: firstNameMissing,
			lastName: lastNameMissing,
			maritalStatus: maritalStatusMissing,
			hireDate: hireDateMissing,
		},
		onChange,
	} = props;

	const { options: genderOptions, selected: genderSelected } = gender as EmployeeSelectField;

	const { options: payScheduleOptions, selected: payScheduleSelected } = paySchedule as EmployeeSelectField;

	const { options: maritalStatusOptions, selected: maritalStatusSelected } = maritalStatus as EmployeeSelectField;

	useEffect(() => {
		setAddressMissing(
			(addressLine1Missing as boolean) || (cityMissing as boolean) || (stateIdMissing as boolean) || (zipcodeMissing as boolean)
		);
	}, [addressLine1Missing, cityMissing, stateIdMissing, zipcodeMissing]);

	useEffect(() => {
		setSsnLabelClasses(classnames('fab-Label', { 'fab-Label--error': !ssnValid }));
		setSsnInputClasses(classnames('fab-TextInput fab-TextInput--width4', { 'fab-TextInput--error': !ssnValid }));
	}, [ssnValid]);

	const handleSsnBlur = useCallback(() => {
		if (ssn) {
			validateSsn(ssn as string, employeeId, setSsnValid);
		}
	}, [ssn, employeeId]);

	const showEmployeeInfo =
		addressMissing ||
		(ssnMissing as boolean) ||
		(dateOfBirthMissing as boolean) ||
		!!genderMissing ||
		!!payScheduleMissing ||
		(employeeNumberMissing as boolean) ||
		(firstNameMissing as boolean) ||
		(lastNameMissing as boolean) ||
		!!maritalStatusMissing ||
		(hireDateMissing as boolean);

	return showEmployeeInfo ? (
		<FormSection title={$.__('Employee Information')}>
			{firstNameMissing && (
				<div className='fab-FormRow'>
					<TextField
						label={$.__('First Name')}
						onChange={({ target: { value } }) => onChange('firstName', value, false)}
						size='medium'
						value={firstName || ''}
						width={8}
					/>
				</div>
			)}
			{lastNameMissing && (
				<div className='fab-FormRow'>
					<TextField
						label={$.__('Last Name')}
						onChange={({ target: { value } }) => onChange('lastName', value, false)}
						size='medium'
						value={lastName || ''}
						width={8}
					/>
				</div>
			)}
			{employeeNumberMissing && (
				<div className='fab-FormRow'>
					<TextField
						label={$.__('Employee Number')}
						onChange={({ target: { value } }) => onChange('employeeNumber', value, false)}
						size='medium'
						value={employeeNumber || ''}
						width={8}
					/>
				</div>
			)}
			{addressMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<div className='fab-FormRow fab-FormRow--tight'>
							<TextField
								label={$.__('Address')}
								onChange={({ target: { value } }) => onChange('addressLine1', value, false)}
								placeholder={$.__('Street 1')}
								size='medium'
								value={addressLine1 || ''}
								width={8}
							/>
						</div>
						<div className='fab-FormRow fab-FormRow--tight'>
							<TextField
								onChange={({ target: { value } }) => onChange('addressLine2', value, false)}
								placeholder={$.__('Street 2')}
								size='medium'
								value={addressLine2 || ''}
								width={8}
							/>
						</div>
						<div className='fab-FormRow fab-FormRow--tight'>
							<div className='fab-FormField'>
								<TextField
									onChange={({ target: { value } }) => onChange('city', value, false)}
									placeholder={$.__('City')}
									size='medium'
									value={city || ''}
									width={6}
								/>
							</div>
							<div className='fab-FormField'>
								<Select
									isClearable={false}
									items={states}
									name='state'
									onSelect={(value: string) => onChange('stateId', value || '', false)}
									placeholder={$.__('State')}
									selectedValues={[stateId]}
									width={4}
								/>
							</div>
							<div className='fab-FormField'>
								<TextField
									onChange={({ target: { value } }) => onChange('zipcode', value, false)}
									placeholder={$.__('ZIP')}
									size='medium'
									value={zipcode || ''}
									width={3}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			{ssnMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className={ssnLabelClasses} htmlFor='ssn'>
							{$.__('Social Security Number')}
						</label>
						<MaskedInput
							className={ssnInputClasses}
							mask={commonInputMasks['ssn-field']}
							name='ssn'
							onBlur={handleSsnBlur}
							onChange={({ target: { value } }) => onChange('ssn', value, false)}
							type='text'
							value={(ssn as string) || ''}
						/>
						{!ssnValid && (
							<span className='fab-FormNote fab-FormNote--error'>{$.__(`This SSN is either invalid or a duplicate`)}.</span>
						)}
					</div>
				</div>
			)}
			{dateOfBirthMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='dateOfBirth'>
							{$.__('Birth Date')}
						</label>
						<DatePicker
							id='dateOfBirth'
							maxDate={TODAY}
							onChange={({ value }) => onChange('dateOfBirth', value || '', false)}
							value={(dateOfBirth as string) ?? undefined}
						/>
					</div>
				</div>
			)}
			{hireDateMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='hireDate'>
							{$.__('Hire Date')}
						</label>
						<DatePicker
							id='hireDate'
							onChange={({ value }) => onChange('hireDate', value || '', false)}
							value={(hireDate as string) ?? undefined}
						/>
					</div>
				</div>
			)}
			{!!maritalStatusMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='marital status'>
							{$.__('Marital Status')}
						</label>
						<Select
							isClearable={false}
							items={maritalStatusOptions}
							name='marital status'
							onSelect={(value: string) => onChange('maritalStatus', value || '', true)}
							selectedValues={[maritalStatusSelected]}
							width={7}
						/>
					</div>
				</div>
			)}
			{!!genderMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='gender'>
							{$.__('Gender')}
						</label>
						<Select
							isClearable={false}
							items={genderOptions}
							name='gender'
							onSelect={(value: string) => onChange('gender', value || '', true)}
							selectedValues={[genderSelected]}
							width={7}
						/>
					</div>
				</div>
			)}
			{!!payScheduleMissing && (
				<div className='fab-FormRow'>
					<div className='fab-FormColumn'>
						<label className='fab-Label' htmlFor='pay schedule'>
							{$.__('Pay Schedule')}
						</label>
						<Select
							isClearable={false}
							items={payScheduleOptions}
							name='pay schedule'
							onSelect={(value: string) => onChange('paySchedule', value || '', true)}
							selectedValues={[payScheduleSelected]}
							width={7}
						/>
					</div>
				</div>
			)}
		</FormSection>
	) : null;
}

function validateSsn(ssn: string, employeeId: string, setSsnValid: (valid: boolean) => void): void {
	Ajax.post(
		'/employees/validate',
		[
			{ name: 'field_7', value: ssn },
			{ name: 'id', value: employeeId },
			{ name: 'ssn', value: ssn },
			{ name: 'action', value: 'checkSSN' },
			{ name: 'validate', value: true },
		],
		{ serializeArray: true }
	)
		.then((response) => {
			setSsnValid(response.data as boolean);
		})
		.catch(() => {
			setSsnValid(false);
		});
}
