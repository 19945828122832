import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette }) => ({
    /* @startCleanup encore */
    modalIcon: {
        fill: palette.error.main,
        marginTop: '18px',
    },
    /* @endCleanup encore */
    modalSubtext: {
        display: 'block',
        marginTop: '12px',
    },
    modalTitle: {
        marginTop: '-14px'
    },
    showMoreBtn: {
        marginTop: '-4px',
        '&:hover': {
            color: palette.info.main,
        }
    }
}));
