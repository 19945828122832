import { ValidationContextState } from '../types';
import { PlanTypeEnumContract, SetSpecialErrors } from '../../types';
import { validateEndDate, validateStartDate } from '../../utils/validation';
import { FieldValues } from './types';

export const validateSpecial = (
	planType: PlanTypeEnumContract,
	fieldValues: FieldValues,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const {
		endYmd,
		startYmd,
	} = fieldValues;

	const isStartDateValid = validateStartDate(startYmd, endYmd, setSpecialErrors);
	const isEndDateValid = validateEndDate(planType, endYmd, startYmd, setSpecialErrors);

	return isStartDateValid && isEndDateValid;
};

export const validateFields = (
	validationContext: ValidationContextState,
	fieldValues: FieldValues,
	planType: PlanTypeEnumContract
): boolean => {
	const {
		validate,
		setSpecialErrors,
	} = validationContext;

	window.closeMessage();

	const areStandardFieldsValid = validate();
	const areSpecialFieldsValid = validateSpecial(planType, fieldValues, setSpecialErrors);
	const isValid = areStandardFieldsValid && areSpecialFieldsValid;

	if (!isValid) {
		const errorMessage = $.__('Whoops... No worries. Please fix any missing or incorrect information and try again.');
		window.setMessage(errorMessage, 'error');
	}

	return isValid;
};
