import { useEffect, useState } from 'react';
import { CardSize, StandardModal, Button, Flex, Table, Typography, useTheme, Headline } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';
// @startCleanup encore
import { Employees16x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore


export function EmployeesAssignedModal(props) {
	const { palette } = useTheme();

	const { isOpen, onClose, project } = props;

	const [employees, setEmployees] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (project.id) {
			setIsLoading(true);
			Ajax.get(`/settings/time_tracking/projects/employees/${project.id}`)
				.then((response) => {
					setEmployees(response.data);
					setIsLoading(false);
				})
				.catch(() => {
					onClose();
					window.errorFallBack();
				});
		}
	}, [project.id, onClose]);

	const columns = [
		{
			header: $.__('Name'),
			cell: (row) => (
				<a className='fab-Link' href={`/employees/employee.php?id=${row.id}`} id={row.id}>
					{row.name}
				</a>
			),
			sortBy: (row) => row.name,
		},
	];

	const header = (
		<Flex alignItems='center' justifyContent='space-between'>
			{ifFeature(
				'encore',
				<Headline icon='user-group-regular' size='small'>
					{$.__('People on %1 (%2)', project.name, employees.length)}
				</Headline>,
				<Flex alignItems='center' gap={1}>
					<Employees16x14 fill={palette.primary.main} />
					<Typography variant='h4'>{$.__('People on %1 (%2)', project.name, employees.length)}</Typography>
				</Flex>
			)}
			<Button href={`/settings/time_tracking/projects/edit/${project.id}`} variant='outlined'>
				{$.__('Edit Project')}
			</Button>
		</Flex>
	);

	return (
		<StandardModal isLoading={isLoading} isOpen={isOpen} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button key='done' onClick={onClose}>
								{$.__('Done')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={project.name} />}
				size={CardSize.MEDIUM}
			>
				<StandardModal.Constraint>
					{ifFeature(
						'encore',
						<Flex flexDirection='column' gap={2}>
							{header}
							<Table
								caption={$.__('Employees Assigned to %1', project.name)}
								columns={columns}
								rowKey={(row) => row.id}
								rows={employees}
								stickyHeader={true}
							/>
						</Flex>,
						<>
							{header}
							<Table
								caption={$.__('Employees Assigned to %1', project.name)}
								columns={columns}
								rowKey={(row) => row.id}
								rows={employees}
								stickyHeader={true}
							/>
						</>
					)}
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
