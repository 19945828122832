import { isString } from 'lodash';
import c from 'classnames';

import { InfoCard } from '../info-card';

import { statusCardNeeded, COMPANY_APP_STATE } from '../../utils/shared';
import { getAppStateFromData, BANK_APP_STATE } from '../../../bank-info.react/utils/shared';

/* @startCleanup encore */
import './info-card-manager.styl';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '@bamboohr/fabric';

export function InfoCardManager(props) {
	const { statuses, companies, isMultiEin, isMultiEinBank } = props;

	if (!isMultiEinBank && (!statuses || !statusCardNeeded(statuses))) {
		return null;
	}

	let contents = [];
	if (companies && isMultiEinBank) {
		contents = createContentsForMultiEinBank();
	} else {
		contents = createContentsForSingleEin();
	}

	function createContentsForSingleEin() {
		const { statuses, buttonFunction, clientId, isMultiEin, isProcessing, onFileDownload } = props;

		const testCases = [COMPANY_APP_STATE.VERIFIED, COMPANY_APP_STATE.PRE_EIN, BANK_APP_STATE.PRE_BANK, BANK_APP_STATE.VERIFIED];

		const filteredStatuses = statuses.filter((status) => {
			if (status.state) {
				return !testCases.includes(status.state);
			}

			return !testCases.includes(status);
		});

		return filteredStatuses.map((status, i) => {
			const company = isString(status) ? null : status.company;

			return ifFeature(
				'encore',
				<InfoCard
					key={i}
					buttonFunction={buttonFunction}
					clientId={clientId}
					company={company}
					isMultiEin={isMultiEin}
					isProcessing={isProcessing}
					onFileDownload={onFileDownload}
					status={isString(status) ? status : status.state}
				/>,
				<div key={i} className='InfoCardManager__spacer'>
					<InfoCard
						key={i}
						buttonFunction={buttonFunction}
						clientId={clientId}
						company={company}
						isMultiEin={isMultiEin}
						isProcessing={isProcessing}
						onFileDownload={onFileDownload}
						status={isString(status) ? status : status.state}
					/>
				</div>
			);
		});
	}

	function createContentsForMultiEinBank() {
		const { buttonFunction, onFileDownload, isProcessing } = props;

		const results = companies.map((company, companyIndex) => {
			const { bankAccounts } = company;

			if (Array.isArray(bankAccounts) && bankAccounts.length === 0) {
				return [
					ifFeature(
						'encore',
						<InfoCard
							key={`${companyIndex}`}
							buttonFunction={buttonFunction}
							clientId={company.clientId}
							company={company}
							isMultiEin={true}
							isProcessing={isProcessing}
							onFileDownload={onFileDownload}
							status={getAppStateFromData([company])}
						/>,
						<div key={`${companyIndex}`} className='InfoCardManager__spacer'>
							<InfoCard
								buttonFunction={buttonFunction}
								clientId={company.clientId}
								company={company}
								isMultiEin={true}
								isProcessing={isProcessing}
								onFileDownload={onFileDownload}
								status={getAppStateFromData([company])}
							/>
						</div>
					),
				];
			}

			return bankAccounts.map((bankAccount, bankIndex) => {
				const tempCompany = [{ bankAccounts: [] }];
				tempCompany[0].companyLegalName = company.companyLegalName;
				tempCompany[0].bankAccounts.push(bankAccount);
				const tempCompanyState = getAppStateFromData(tempCompany);

				if (tempCompanyState === BANK_APP_STATE.VERIFIED) {
					return false;
				}

				return ifFeature(
					'encore',
					<InfoCard
						key={`${companyIndex}${bankIndex}`}
						buttonFunction={buttonFunction}
						clientId={companies[companyIndex].clientId}
						company={tempCompany}
						isMultiEin={true}
						isProcessing={isProcessing}
						onFileDownload={onFileDownload}
						status={tempCompanyState}
					/>,
					<div key={`${companyIndex}${bankIndex}`} className='InfoCardManager__spacer'>
						<InfoCard
							key={`${companyIndex}${bankIndex}`}
							buttonFunction={buttonFunction}
							clientId={companies[companyIndex].clientId}
							company={tempCompany}
							isMultiEin={true}
							isProcessing={isProcessing}
							onFileDownload={onFileDownload}
							status={tempCompanyState}
						/>
					</div>
				);
			});
		});

		const filteredResults = results.filter((result) => {
			return result.length && result[0] !== false;
		});

		return filteredResults;
	}

	/* @startCleanup encore */
	const infoCardManagerClasses = c('InfoCardManager', { 'InfoCardManager--no-padding': !isMultiEin });
	/* @endCleanup encore */

	if (!contents.length) {
		return null;
	}

	return ifFeature(
		'encore',
		<Flex marginTop={2} marginBottom={isMultiEin ? 4 : undefined} flexDirection='column' gap={2}>
			{contents}
		</Flex>,
		<div className={infoCardManagerClasses}>{contents}</div>
	);
}
