import { ValidationError } from '@utils/validation'

export enum RuleName {
	lessThanOrEqualTo = 'lessThanOrEqualTo',
	greaterThanOrEqualTo = 'greaterThanOrEqualTo',
	required = 'required',
	date = 'date',
	dateRange = 'dateRange',
	futureDate = 'futureDate',
}

export interface SpecialErrors {
	[key: string]: ValidationError;
}

export type SetSpecialErrors =
	(fieldName: keyof SpecialErrors, newValue: ValidationError | null) => void;

