import {
	companyTaxSettingsService,
} from './company-tax-settings-service';
import type {
	ClientTaxSettingsResponseDataCollection,
} from '../types';

export async function fetchAllClientTaxSettingsAsync(clientId: number): Promise<ClientTaxSettingsResponseDataCollection> {
	try {
		const {
			data,
		} = await companyTaxSettingsService.request<ClientTaxSettingsResponseDataCollection>({
			method: 'GET',
			url: '/taxes/settings',
			urlParams: {
				clientId,
			},
		});
		return data;
	} catch (err) {
		console.error('fetchAllClientTaxSettingsAsync err -', err);
		throw err;
	}
}
