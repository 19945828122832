import { Icon, BlankState, Button, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	bem,
} from '../../../utils';

import './styles.styl';


export default function EmptyState() {
	return (
		ifFeature('encore',
			<LayoutBox paddingTop='34px'>
				<BlankState
					actions={[
						<Button color="secondary" href="https://marketplace.bamboohr.com" variant="outlined">
							{ $.__(`BambooHR Marketplace`) }
						</Button>
					]}
					title={ $.__(`You don't have any apps installed ... yet.`) }
					subtitle={ $.__(`Enhance your BambooHR experience and connect with some of our exceptional technology partners. Some apps can be installed below.`) }
					icon="bs-tumbleweed"
				/>
			</LayoutBox>,
			<div className={ bem('appListEmptyState') }>
				<span className={ bem('appListEmptyStateIcon') }>
					<Icon name="fab-cloud-plus-120x90" />
				</span>
				<p className={ bem('appListEmptyStateTitle') }>
					{ $.__(`You don't have any apps installed ... yet.`) }
				</p>
				<p className={ bem('appListEmptyStateMsg') }>
					{ $.__(`Enhance your BambooHR experience and connect with some of our exceptional technology partners. Some apps can be installed below.`) }
				</p>
				<a
					className={ bem('appListEmptyStateBtn', '^fab-Button ^fab-Button--secondary ^fab-Button--outline') }
					href="https://marketplace.bamboohr.com"
				>
					{ $.__(`BambooHR Marketplace`) }
				</a>
			</div>
		)
	);
}
