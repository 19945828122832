import { useState, Fragment } from 'react';
import CalendarPicker from 'calendar-picker.react';
import { TextButton } from '@fabric/button';
import Ajax from '@utils/ajax';
import moment from 'moment.lib';

function changeHandler(value, setStartState, setEndState) {
	if (typeof value === 'string') {
		setStartState(value);
		return;
	}
	setStartState(value.start || '');
	setEndState(value.end || value.start || '');
}

function checkWeekend(start, end, setRangeState, setEndState) {
	// If the user is setting the start and end days the same, set the state back to one date rather than a range so they can remove a multiple day holiday
	if (start === end) {
		end = '';
		setEndState(end);
		setRangeState(false);
	}

	const startDate = moment(start).format(moment.defaultFormat);
	const endDate = moment(end).format(moment.defaultFormat);

	Ajax.get('/ajax/manage/holidays/falls_on_weekend.php', {
		startDate,
		endDate
	}).then((response) => {
		if (response.status === 200) {
			const { data } = response;
			switch (data) {
				case 'endBeforeStart':
					window.setMessage($.__('The end date must be after the start date'), 'error');
					break;
				case 'yes':
					window.setMessage($.__('This holiday falls on a weekend this year. You may wish to change the observance date.'), 'info');
					break;
				default:
					window.closeMessage();
			}
		}
	});
}

export default function HolidaySettingsDate(props) {
	const {
		end,
		range,
		start,
		width = 5,
	} = props;
	const [startState, setStartState] = useState(start);
	const [endState, setEndState] = useState(end);
	const [rangeState, setRangeState] = useState(range);

	const inputClasses = `fab-TextInput fab-TextInput--width${ width }`;

	const DateCalendar = () => (
		<CalendarPicker
			cssClasses={ {
				single: inputClasses,
				rangeStart: inputClasses,
				rangeEnd: inputClasses,
			} }
			name={ {
				start: 'startDate',
				end: 'endDate'
			} }
			onChange={ (value) => {
				changeHandler(value, setStartState, setEndState);
				if (!value.end && value.start) {
					document.querySelector('input[range-end] ~ button').click();
				}
				checkWeekend(value.start || startState, value.end || endState, setRangeState, setEndState);
			} }
			required={ true }
			settings={ {
				start: startState,
				end: endState
			} }
			type="date-range"
		/>
	);
	const SingleCalendar = () => (
		<CalendarPicker
			cssClasses={ {
				single: inputClasses,
			} }
			name="startDate"
			onChange={ (value) => {
				changeHandler(value, setStartState);
				checkWeekend(value, '');
			} }
			required={ true }
			settings={ {
				start: startState
			} }
			type="date"
		/>
	);

	return (
		<Fragment>
			<label className="fab-Label fab-Label--required" htmlFor="startDate">
				{ $.__('Date') }
			</label>
			{ (endState.length || rangeState) && <DateCalendar /> }
			{ (!endState.length && !rangeState) && (
				<Fragment>
					<SingleCalendar />
					<span className="HolidayAdd__addDateRange">
						<TextButton
							clickAction={ () => setRangeState(true) }
							text={ $.__('More than one day?') }
							type={ 'button' }
						/>
					</span>
				</Fragment>
			) }
		</Fragment>
	);

}
