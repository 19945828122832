import { Icon } from '@bamboohr/fabric';

import './company-taxes-blank-state.styl';
export function CompanyTaxesBlankState(props) {
	const {
		buttonText,
		buttonHref,
		headerText,
		iconName,
		subText,
	} = props;

	return (
		<div className="CompanyTaxesBlankState">
			<span className="CompanyTaxesBlankState__iconWrapper">
				<Icon name={ iconName } />
			</span>
			<h3 className="CompanyTaxesBlankState__header">
				{ $.__('%1$s', headerText) }
			</h3>
			<div className="CompanyTaxesBlankState__text">
				{ $.__('%1$s', subText) }
			</div>
			<a className="fab-Button fab-Button--outline fab-Button--medium" href={ buttonHref }>
				<span className="fab-Button__text">
					{ $.__('%1$s', buttonText) }
				</span>
			</a>
		</div>
	);
}
