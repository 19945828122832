import { Fragment } from 'react';

import {
	StepIndicator,
} from 'step-indicator.react';

export function Title(props) {
	const {
		currentStep,
		stepCount,
	} = props;
	return (
		<Fragment>
			{ $.__('Updating ACA Tracking') }
			<StepIndicator
				currentStep={ currentStep }
				totalStepCount={ stepCount }
			/>
		</Fragment>
	);
}
