import { render } from 'base/wrapped-render';
import HolidayDate from './date.react';
import Ctrl, {
	onReady,
	onFooterAction
} from 'ctrl.deco';
import moment from 'moment.lib';
// import { isEnabled } from 'FeatureToggle.util';

// const partialHolidaysEnabled = isEnabled('partial_holidays_full_stack_toggle');

@Ctrl('/settings/holidays/add.php')
class SettingsHolidayPage {
	_renderDate(range) {
		const wrapper = document.querySelector('.js-date-wrapper');
		const { start, end } = wrapper.dataset;

		render(
			<HolidayDate { ...{ start, end, range } } />,
			document.querySelector('.js-date-wrapper')
		);
	}
	@onReady()
	_onReadyHandler(event, ctrl) {
		const {
			id,
			year
		} = window.holidayConfig;

		ctrl._renderDate();

		$('input[name=name]').focus();

		window.filterSettings.listOnly = true;
		window.filterSettings.postFunc = ctrl._setHiddenVar;

		$('.filter-list:has(input:checked)').show();
		window.updateFilter();

		if (id === '0') {
			const opts = {
				delay: 50,
				cacheLength: 1,
				selectFirst: true,
				matchSubset: false,
				max: 20
			};
			$('#holidayquicksearch').autocomplete('/ajax/manage/holidays/quicksearch.php', opts).result((event, data, formatted) => {
				$.getJSON('/ajax/manage/holidays/qs_getdates.php', {
					shid: data[1],
					year: year,
				}, (info) => {
					$('input[name=standardHolidayId]').val(data[1]);
					$('#startDate').val(info.startDate);
					$('#endDate').val(info.startDate);
					if (info.endDate != '') {
						$('#endDate').val(info.endDate);
						ctrl._renderDate();
					}
				});
			});
		}
	}
	@onFooterAction('addSubmit')
	_handleSave(event) {
		event.preventDefault();

		const form = $('#addform');

		if (form.valid()) {
			document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));

			const form = document.getElementById('addform');
			const startDate = document.querySelector('input[name="startDate"]');
			const endDate = document.querySelector('input[name="endDate"]');

			if (startDate) {
				startDate.value = moment(startDate.value).format(moment.defaultFormat);
			}
			if (endDate) {
				endDate.value = moment(endDate.value).format(moment.defaultFormat);
			}
			form.submit();
		}
	}
	@onFooterAction('cancel')
	_handleCancel() {
		const { year } = window.holidayConfig;

		window.location.href = `/settings/holidays/view?year=${ year }`;
	}

	_setHiddenVar() {
		var tmp = '';
		$.each(window.postVars, function (key, value) {
			if ($.isArray(value)) {
				$.each(value, function (index, value2) {
					tmp += key + '=' + value2 + '&';
				});
			} else {
				tmp += key + '=' + value + '&';
			}
		});
		$('#hiddenFilters').val(tmp);
	}
}
