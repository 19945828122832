const SUPPORT_EMAIL = 'payrollmigration@bamboohr.com';

export const responseMessages = {
	getDeductionDataError: $.__(`Sorry, we were unable to gather your deduction data. Please refresh the page and try again or contact support at %1$s.`, SUPPORT_EMAIL),
	standardError: $.__(`Oops, something went wrong. Please refresh the page and try again or contact support at %1$s.`, SUPPORT_EMAIL),
	standardDeductionError: (deductionName = 'your deduction'): string => {
		return (
			$.__(`Oops, something went wrong with %1$s. Please try again or contact support at %2$s.`, deductionName, SUPPORT_EMAIL)
		)
	},
	resetBenefitPlanError: (deductionName = 'your deduction'): string => {
		return (
			$.__(`Sorry, we were unable to reset %1$s. Please try again or contact support at %2$s.`, deductionName, SUPPORT_EMAIL)
		)
	},
	saveBenefitPlanError: (deductionName = 'your deduction'): string => {
		return (
			$.__(`Sorry, we were unable to save %1$s. Please try again or contact support at %2$s.`, deductionName, SUPPORT_EMAIL)
		)
	},
	badPayeeDataError: $.__('Your deduction payees are not ready to be copied over to BambooHR. Please return to TRAX and ensure that your deduction payees do not have duplicate names and that the address for each payee is complete.'),
	unableToSyncError: $.__(`We are unable to sync your deductions. Please contact support at %1$s.`, SUPPORT_EMAIL),
	noDeductionsContinueText: $.__('You have no company-level deductions to resolve. Click the Continue button to proceed to the next step.'),
}
