import React from 'react';
import styles from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Headline, LayoutBox } from '@bamboohr/fabric';

const Title = ({ children }) => {
	const classes = styles();

	return ifFeature(
		'encore',
		<LayoutBox textAlign='center'>
			<Headline justify='center' size='large'>
				{children}
			</Headline>
		</LayoutBox>,
		<div className={classes.view}>
			<h2>{children}</h2>
		</div>
	);
};

Title.defaultProps = { children: null };

export default Title;
