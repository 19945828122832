import { render } from 'base/wrapped-render';
import { Loader } from '@bamboohr/fabric';
import Ctrl from 'ctrl.deco';

@Ctrl('/settings/*')
class GlobalSettingsCtrl {
	constructor() {
		const loaderElem = document.querySelector('#settingsLoaderReactRoot');

		if (loaderElem) {
			render(<Loader />, loaderElem);
		}
	}
}
