import moment from 'moment.lib';

$(function () {
	const alertEditForm = $('form#edit_form.js-timeoff-balance-alert');
	if (alertEditForm.length) {
		const timepicker = $('.timepicker');
		timepicker.timepicker({ step: 60 });
		timepicker.timepicker('option', { useSelect: true });

		const inputs = alertEditForm.find(':input, ba-select');
		let freqField = inputs.filter('[name="schedule"]');
		let startField = inputs.filter('[name="requiredDateField"]');

		const weekDay = inputs.filter('[name="weekDay"]');
		const monthlyField = inputs.filter('[name="monthly"]');
		const semiMonthlyField = inputs.filter('[name="semiMonthly"]');
		const annualMonthField = inputs.filter('[name="annual[month]"]');
		const annualDateField = inputs.filter('[name="annual[date]"]');
		const semiAnnualMonthField = inputs.filter('[name="semiAnnual[month]"]');
		const semiAnnualDateField = inputs.filter('[name="semiAnnual[date]"]');
		const q3MonthField = inputs.filter('[name="quarterly3[month]"]');
		const q3DateField = inputs.filter('[name="quarterly3[date]"]');
		const q4MonthField = inputs.filter('[name="quarterly4[month]"]');
		const q4DateField = inputs.filter('[name="quarterly4[date]"]');
		const table = alertEditForm.find('table#AlertDates');
		const tableWrapper = table.closest('.alertDateTableWrapper');

		const weekendMsg = alertEditForm.find('strong.weekend-msg');

		const dateFormat = moment.defaultFormat;
		const tbodies = {};

		tableWrapper.on('click', function () {
			tableWrapper.removeClass('collapsed');
		});

		table.on('change', '.actionCell input', function (e) {
			const $el = $(e.currentTarget);
			const date = moment.utc($el.val());
			const row = $el.closest('tr');

			row.find('.dateCell').text(date.format(dateFormat));

			row.find('.dayCell').text(date.format('dddd')).toggleClass('color-warn bold dayCell--warning', date.isWeekend());

			setWeekendMsg(weekendMsg, table);
		});

		table.find('.actionCell input').addClass('calendar-field date-field').datepicker({
			dateFormat: 'yy-mm-dd',
		});

		$([
			startField[0],
			freqField[0],

			weekDay[0],
			monthlyField[0],
			semiMonthlyField[0],
			annualMonthField[0],
			annualDateField[0],
			semiAnnualMonthField[0],
			semiAnnualDateField[0],
			q3MonthField[0],
			q3DateField[0],
			q4MonthField[0],
			q4DateField[0],
		]).on('change', function (e) {
			// Fixes a timing issue, since the
			// element objects aren't updated yet
			if (startField.is(e.target)) {
				startField = $(e.target);
			} else if (freqField.is(e.target)) {
				freqField = $(e.target);
			}

			const start = startField.val();
			const freq = freqField.val();
			let alertDates = [];
			let curDate;
			let startDate;
			let endDate;
			let day;

			if (start && freq) {
				startDate = moment.utc(start, dateFormat);
				endDate = moment.utc({
					year: startDate.year() + 1,
					month: 0,
					day: 31,
				});
				curDate = startDate.clone();

				table.empty().append(
					$(
						`<colgroup>
								<col class="dateCell"></col>
								<col class="dayCell"></col>
								<col class="actionCell"></col>
							</colgroup>`
					)
				);

				for (let year = startDate.year(); year <= endDate.year(); year++) {
					tbodies[year] = $('<tbody class="BhrTable__body fab-Table__body" data-year="' + year + '"></tbody>');
					table.append(tbodies[year]);
				}

				while (curDate.isSameOrBefore(endDate)) {
					const lastDayInMonth = curDate.daysInMonth();

					switch (freq) {
						case 'weekly':
						case 'bi-weekly':
							day = parseInt(weekDay.val());
							if (day >= 0) {
								// eslint-disable-next-line max-depth
								while (curDate.day() !== day) {
									curDate = curDate.add(1, 'day');
								}
							}
							appendDate(curDate);
							break;
						case 'semi-monthly':
						case 'monthly':
							day = parseInt(monthlyField.val());
							if (day > lastDayInMonth) {
								day = lastDayInMonth;
							}
							curDate.date(day);
							if (curDate.isSameOrAfter(startDate)) {
								appendDate(curDate);
							}
							break;
						case 'quarterly':
						case 'semi-annually':
							curDate = moment.utc({
								year: curDate.year(),
								month: annualMonthField.val(),
								date: annualDateField.val(),
							});
							if (curDate.isSame(startDate) || curDate.isBetween(startDate, endDate)) {
								appendDate(curDate);
							}
							break;
						default:
							appendDate(curDate);
					}

					switch (freq) {
						case 'weekly':
							curDate = curDate.add(1, 'week');
							break;
						case 'bi-weekly':
							curDate = curDate.add(2, 'weeks');
							break;
						case 'semi-monthly':
							day = semiMonthlyField.val();
							if (day > lastDayInMonth) {
								day = lastDayInMonth;
							}
							if (day > curDate.date()) {
								curDate.date(day);
								appendDate(curDate);
							}
						// fall through to monthly:
						case 'monthly':
							curDate = curDate.add(1, 'month');
							break;
						case 'quarterly': {
							const q3Month = q3MonthField.val();
							const q3Date = q3DateField.val();
							const q4Month = q4MonthField.val();
							const q4Date = q4DateField.val();

							appendDate({
								year: curDate.year(),
								month: q3Month,
								date: q3Date,
							});
							appendDate({
								year: curDate.year(),
								month: q4Month,
								date: q4Date,
							});
						}
						// fall through to semi-annually
						case 'semi-annually': {
							const annualMonth = annualMonthField.val();
							const annualDate = annualDateField.val();
							const semiMonth = semiAnnualMonthField.val();
							const semiDate = semiAnnualDateField.val();

							appendDate({
								year: curDate.year(),
								month: semiMonth,
								date: semiDate,
							});

							curDate = moment.utc({
								year: curDate.year() + 1,
								month: annualMonth,
								date: annualDate,
							});
							break;
						}
					}
				}

				const dateMap = [];
				alertDates = alertDates
					.filter(function (date) {
						if (date.isAfter(endDate) || date.isBefore(startDate)) {
							return false;
						}

						if (dateMap.indexOf(date.unix()) >= 0) {
							return false;
						}

						dateMap.push(date.unix());

						return true;
					})
					.sort(function (a, b) {
						if (a.unix() > b.unix()) {
							return 1;
						}
						if (a.unix() < b.unix()) {
							return -1;
						}
						return 0;
					});

				$.each(alertDates, function (i, date) {
					appendToTable(date, tbodies, dateFormat);
				});

				setWeekendMsg(weekendMsg, table);

				table.find('.BhrTable__row').each(function () {
					const row = $(this);
					const field = row.find('input.calendar-field');

					field.datepicker({
						dateFormat: 'yy-mm-dd',
						defaultDate: field.val(),
					});
				});
			}

			function appendDate(date) {
				alertDates.push(moment.utc(date));
			}
		});
	}
});

function countWeekends(table) {
	let weekendCount = 0;
	table.find('input.calendar-field').each(function (n, el) {
		if (moment.utc($(el).val()).isWeekend()) {
			weekendCount++;
		}
	});

	return weekendCount;
}

function setWeekendMsg(weekendMsg, table) {
	const count = countWeekends(table);

	if (count > 0) {
		weekendMsg.text($.__('%1 of the scheduled alert dates fall on a weekend.', count));
		weekendMsg.show();
	} else {
		weekendMsg.hide();
		weekendMsg.text('');
	}
}

function appendToTable(date, tbodies, dateFormat) {
	const row = createTableRow(date, dateFormat);
	tbodies[date.year()].append(row);
}

function createTableRow(date, dateFormat) {
	const row = $('<tr class="BhrTable__row fab-Table__row"></tr>');
	const dateCell = $('<td class="BhrTable__cell fab-Table__cell dateCell"></td>');
	const dayCell = $('<td class="BhrTable__cell fab-Table__cell dayCell"></td>');
	const actionCell = $('<td class="BhrTable__cell fab-Table__cell actionCell"></td>');
	const cells = $([dateCell[0], dayCell[0], actionCell[0]]);
	const pickerField = $('<input type="text" name="alertDates[]" readonly class="inline-bicon-edit calendar-field date-field"/>');

	dayCell.toggleClass('color-warn bold dayCell--warning', date.isWeekend());

	dateCell.text(date.format(dateFormat));

	dayCell.text(date.format('dddd'));

	pickerField.val(date.format('YYYY-MM-DD'));
	actionCell.append(pickerField);

	return row.append(cells);
}
