import React, {
	useState
} from 'react';

import {
	convertToItems
} from '../utils';

import Ajax from '@utils/ajax';

import BenefitGroupSection from './benefit-groups';
import EditMessageSection from './edit-message';
import PayFrequencySection from './pay-frequency';
import SyncSection from './sync-integration';

const payGroupForm = (data) => {
	const [benefitGroupItems, setBenefitGroupItems] = useState(convertToItems(data.benefitGroups));
	const [benefitGroup, setBenefitGroup] = useState([]);
	const [benefitGroupErrorMessage, setBenefitGroupErrorMessage] = useState('');
	const [hasGroups, setHasGroups] = useState(false);
	const [payGroup, setPayGroup] = useState([data.type]);
	const [payrollSystem, setPayrollSystem] = useState([data.syncToolId]);
	const [showSyncToolAlert, setShowSyncStateAlert] = useState(false);
	const [showSyncDropdown, setShowSyncDropdown] = useState(data.syncToolId !== null);

	const {
		action,
		benefitGroups,
		buttonText,
		editMessage,
		hasPayGroupIntegrations,
		hasSyncIntegrations,
		id,
		name,
		paygroupTypes,
		payrollSystems,
		syncToolId,
		type
	} = data;

	const paygroupTypesItems = convertToItems(paygroupTypes);
	const payrollSystemsItems = convertToItems(payrollSystems);
	const hasSyncTool = syncToolId !== null;
	const isSyncToolDisabled = editMessage !== null && hasSyncTool && showSyncToolAlert === false;

	const _handlePayGroups = (value) => {
		setPayGroup(value);

		if (action === 'edit') {
			if ($('#origType').val() === value[0]) {
				setHasGroups(false);
			} else {
				_showBenefitGroupInfo(value[0]);
			}
		}
	}

	const _handleBenifitGroups = value => setBenefitGroup(value);
	const _handlePayrollSystem = value => setPayrollSystem(value);
	const _handleSyncToolEditClick = () => setShowSyncStateAlert(true);
	const _handleSyncToolToggle = e => setShowSyncDropdown(e.target.checked);

	const _showBenefitGroupInfo = (newPayFrequency) => {
		let origFrequency = $('#origType').val();
		let payGroupId = $('#id').val();
		
		Ajax.get(`/settings/pay_groups.php?getBenefitGroupInfo=1&newFrequency=${ newPayFrequency }&origFrequency=${ origFrequency }&payGroupId=${ payGroupId }`)
			.then(function(res) {
				const {
					benefitGroupOptions,
					hasBenefitGroups,
					success
				} = res.data;
	
				if (success) {
					if (hasBenefitGroups) {
						setHasGroups(true);
						setBenefitGroupItems(convertToItems(benefitGroupOptions));
					}
				} else {
					setHasGroups(false);
					setBenefitGroupErrorMessage(data.error);
				}
			})
			.catch(() => window.errorFallBack());
	}

	return (
		<div id="form" data-itemid={ id }>
			<form method="post" action="" id="editForm" className="BhrForms">
				<input type="hidden" name="id" id="id" defaultValue={ id } />
				<input type="hidden" id="action" name="action" defaultValue={ action } />
				<input type="hidden" name="origType" id="origType" defaultValue={ type }/>
				<fieldset>
					<div className="fab-FormRow">
						<div className="fab-FormColumn required">
							<label className="fab-Label fab-Label--required" htmlFor="value">
								{ $.__('Pay Group Name') }
							</label>
							<input
								className="fab-TextInput fab-TextInput--width7"
								defaultValue={ name }
								id="value"
								name="name"
								required={ true }
								type="text"
							/>
						</div>
					</div>
					<PayFrequencySection
						hasPayGroupIntegrations={ hasPayGroupIntegrations }
						payGroup={ payGroup }
						paygroupTypesItems={ paygroupTypesItems }
						payGroupTypesOnChange={ _handlePayGroups }
					/>
					<BenefitGroupSection
						benefitGroup={ benefitGroup }
						benefitGroupErrorMessage= { benefitGroupErrorMessage }
						benefitGroupItems={ benefitGroupItems }
						benefitGroupOnChange={ _handleBenifitGroups }
						hasGroups={ hasGroups }
					/>
					<EditMessageSection
						buttonText={ buttonText }
						buttonOnClick={ _handleSyncToolEditClick }
						editMessage={ editMessage }
						isSyncToolDisabled={ isSyncToolDisabled }
						syncToolId={ syncToolId }
					/>
					<SyncSection
						hasSyncIntegrations={ hasSyncIntegrations }
						hasSyncTool={ hasSyncTool }
						isSyncToolDisabled={ isSyncToolDisabled }
						payrollSystem={ payrollSystem }
						payrollSystemsItems={ payrollSystemsItems }
						showSyncDropdown={ showSyncDropdown }
						syncIntegrationOnChange={ _handlePayrollSystem }
						syncIntegrationOnToggle={ _handleSyncToolToggle }
					/>
				</fieldset>
			</form>
			<div id="preForm"></div>
		</div>
	);
}

export default payGroupForm;
