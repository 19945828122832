import {
	createForm,
} from '../../shared';

/** @type {import('../../../shared').FormAPI<FormState>} */
const {
	useForm,
	FormProvider,
	FormInput,
	FormCheckbox,
	FormCheckboxGroup,
	FormSelect,
} = createForm();

export {
	useForm,
	FormProvider,
	FormInput,
	FormCheckbox,
	FormCheckboxGroup,
	FormSelect,
};

/** @typedef {import('../state').CompanyInfo} FormState */

/** @typedef {import('../../../shared').FormContext<FormState>} FormContext */
