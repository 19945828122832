import { difference } from 'lodash';

import { EligibilityGroupValues, EmployeeList, FilterGroup, FilterSelections } from '../../types';
import { EmployeesInGroups, GroupEmployeeIds, SupersetSubsetPair } from '../types';
import { areGroupsMutuallyExclusive } from './are-groups-mutually-exclusive';
import { areAllFiltersEmpty } from '../eligibility-filters/utils';
import { getSupersetGroup } from './get-superset-group';
import { EMPLOYEE_FILTER_TYPE_KEYS } from '../eligibility-filters/constants';
import { SupersetType } from '../../../etc/superset-type';

export const getEmployeeIdsThatMatchFilters = (employees: EmployeeList, filterGroup: FilterGroup): number[] => {
	const employeeIds = Object.keys(employees);
	const matchingEmployees: EmployeeList = { ...employees };

	if (areAllFiltersEmpty(filterGroup)) {
		return [];
	}

	employeeIds.forEach((id) => {
		filterGroup.order.forEach((filterType) => {
			const selectedValues = filterGroup.values[filterType];

			if (matchingEmployees[id] && selectedValues.length) {
				const employee = matchingEmployees[id];

				// Remove employees that don't match any of the filter selections
				const filterKey = EMPLOYEE_FILTER_TYPE_KEYS[filterType];
				if (filterKey && !selectedValues.includes(employee[filterKey])) {
					delete matchingEmployees[id];
				}
			}
		});
	});

	return Object.keys(matchingEmployees).map(Number);
};

export const getGroupsByEmployeeId = (
	groupEmployeeIds: GroupEmployeeIds,
	eligibilityGroups: EligibilityGroupValues[]
): EmployeesInGroups => {
	const employeesInGroups = {};

	eligibilityGroups.forEach((group, index) => {
		groupEmployeeIds[group.id].forEach((employeeId) => {
			employeesInGroups[employeeId] = {
				groupId: group.id,
				groupName: $.__('Group %1', index + 1),
			};
		});
	});

	return employeesInGroups;
};

const getAllSpecificPeopleIds = (eligibilityGroups: EligibilityGroupValues[]): number[] => {
	let employeeIds = [];

	eligibilityGroups.forEach((group) => {
		if (group.eligibilityFilters.areSpecificPeopleSelected) {
			employeeIds = employeeIds.concat(group.eligibilityFilters.selectedEmployeeIds);
		}
	});

	return employeeIds;
};

const isSupersetSubset = (firstGroup: FilterSelections, secondGroup: FilterSelections): boolean => {
	return getSupersetGroup(firstGroup, secondGroup) !== SupersetType.None;
};

export const canGroupsCoexist = (firstGroup: FilterSelections, secondGroup: FilterSelections): boolean => {
	return areGroupsMutuallyExclusive(firstGroup, secondGroup) || isSupersetSubset(firstGroup, secondGroup);
};

const removeSpecificEmployeeIdsFromGroup = (filteredEmployeeIds: number[], specificPeopleIds: number[]): number[] => {
	return difference(filteredEmployeeIds, specificPeopleIds);
};

const getSupersetSubsetPairs = (groups: EligibilityGroupValues[]): SupersetSubsetPair[] => {
	const supersetSubsetPairs = [];
	for (let i = 0; i < groups.length; i++) {
		for (let j = i + 1; j < groups.length; j++) {
			const supersetIndex = getSupersetGroup(
				groups[i].eligibilityFilters.filterGroup.values,
				groups[j].eligibilityFilters.filterGroup.values
			);

			if (supersetIndex > 0) {
				supersetSubsetPairs.push({
					supersetId: supersetIndex === SupersetType.FirstIsSuperset ? groups[i].id : groups[j].id,
					subsetId: supersetIndex === SupersetType.FirstIsSuperset ? groups[j].id : groups[i].id,
				});
			}
		}
	}

	return supersetSubsetPairs;
};

const removeSubsetEmployeeIdsFromSupersets = (
	groupEmployeeIds: GroupEmployeeIds,
	eligibilityGroups: EligibilityGroupValues[]
): GroupEmployeeIds => {
	const updatedEmployeeIds = { ...groupEmployeeIds };
	const supersetSubsetPairs = getSupersetSubsetPairs(eligibilityGroups);

	supersetSubsetPairs.forEach((pair) => {
		updatedEmployeeIds[pair.supersetId] = difference(updatedEmployeeIds[pair.supersetId], updatedEmployeeIds[pair.subsetId]);
	});

	return updatedEmployeeIds;
};

export const getEmployeeIdsByGroupId = (employees: EmployeeList, eligibilityGroups: EligibilityGroupValues[]): GroupEmployeeIds => {
	let groupEmployeeIds = {};
	const allSpecificPeopleIds = getAllSpecificPeopleIds(eligibilityGroups);

	eligibilityGroups.forEach((group) => {
		if (!employees) {
			groupEmployeeIds[group.id] = [];
		} else if (group.eligibilityFilters.areSpecificPeopleSelected) {
			groupEmployeeIds[group.id] = group.eligibilityFilters.selectedEmployeeIds;
		} else {
			groupEmployeeIds[group.id] = getEmployeeIdsThatMatchFilters(employees, group.eligibilityFilters.filterGroup);

			groupEmployeeIds[group.id] = removeSpecificEmployeeIdsFromGroup(groupEmployeeIds[group.id], allSpecificPeopleIds);
		}
	});

	groupEmployeeIds = removeSubsetEmployeeIdsFromSupersets(groupEmployeeIds, eligibilityGroups);

	return groupEmployeeIds;
};
