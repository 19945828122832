import { createStyles, makeStyles } from '@bamboohr/fabric';

import { EmployeeTaxBoxContentStyleProps } from '../../utils/interfaces';

export const useStyles = makeStyles((theme) => {
	const { palette, typography } = theme;

	return createStyles({
		detail: {
			color: palette.grey[800],
			fontSize: typography.fabricFontSize('teenie'),
		},
		details: {
			paddingTop: 7,
			minWidth: 274,
		},
		root: {},
		title: ({ isSelected }: EmployeeTaxBoxContentStyleProps) => ({
			color: isSelected ? palette.primary.main : palette.grey[800],
			fontWeight: isSelected ? typography.fontWeightBold : typography.fontWeightMedium,
		}),
	});
});
