import { Flex, LayoutBox, NameValuePair } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import './tax-type-details.styl';

export function TaxTypeDetails(props) {
	const { details } = props;
	const detailSections = details.map((detail) => {
		return ifFeature('encore', 
			<NameValuePair key={ detail.header } name={ detail.header } value={ detail.value }/>,
			<DetailSection key={ detail.header } { ...detail } />
		);
	});
	return ifFeature('encore', 
		<LayoutBox paddingTop={1}>
			<Flex flexDirection="row" gap={5}>
				{ detailSections }
			</Flex>
		</LayoutBox>,
		<div className="TaxTypeDetails">
			<div className="TaxTypeDetails__header">
				{ $.__('Tax Details') }
			</div>
			<div className="TaxTypeDetails__sections">
				{ detailSections }
			</div>
		</div>
	);
}

function DetailSection(props) {
	const {
		header,
		value,
	} = props;
	return (
		<div className="TaxTypeDetails__section">
			<div className="TaxTypeDetails__sectionHeader">{ header }</div>
			<div>{ value }</div>
		</div>
	);
}
