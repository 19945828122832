import { CostsTableType } from '../../../../steps/types';
import { planTypeAllowsDefinedCosts } from '../../../../utils/plan-type-allows-defined-costs';
import {
	DisabilityCategoryDataOptionsContract,
	DisabilityDisbursementTypeEnum,
	PlanCategoryDataOptionsContract,
	PlanRateTypeEnum,
} from '../../../../types';
import { BENEFIT_KEYS } from '../../../../../constants';
import { PlanTypeEnumContract, coverageOptionsPlanTypes } from '../../../../../types';

export const getCostsTableType = (
	planType: PlanTypeEnumContract,
	rateType: PlanRateTypeEnum,
	planCategoryDataOptions: PlanCategoryDataOptionsContract,
	isBenefitsAdminEnabled: boolean
): CostsTableType => {
	const isDisbursementTypeOther = (): boolean => {
		if (planType === PlanTypeEnumContract.disability) {
			const disabilityCategoryDataOptions = planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
			return disabilityCategoryDataOptions?.disabilityDisbursementType === DisabilityDisbursementTypeEnum.other;
		}

		return false;
	};

	if (isBenefitsAdminEnabled && rateType === PlanRateTypeEnum.ageBanded) {
		return CostsTableType.ageBanded;
	}

	if (coverageOptionsPlanTypes.includes(planType) && rateType === PlanRateTypeEnum.standard) {
		return CostsTableType.coverageCost;
	}

	if (isBenefitsAdminEnabled && planType === BENEFIT_KEYS.disability && rateType === PlanRateTypeEnum.standard) {
		return CostsTableType.monthlyRate;
	}

	if (
		(planTypeAllowsDefinedCosts(planType, isBenefitsAdminEnabled) && rateType === PlanRateTypeEnum.variable) ||
		isDisbursementTypeOther()
	) {
		return CostsTableType.variableCostsMessage;
	}
};
