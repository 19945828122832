import { ItemType, Loader, Stack } from '@bamboohr/fabric';
import { PoMicroFrontend } from 'micro-frontend.react';
import { SettingsHeader } from '../settings-header';
import { OvertimeSettingsFormValues, Trial } from './types';

export interface TimeTrackingSettingsPoProps {
	defaultValues: OvertimeSettingsFormValues;
	approvers: ItemType[];
	trial: Trial;
}

/** Container for new time tracking settings in Po */
export function TimeTrackingSettingsPo({ approvers = [], defaultValues, trial }: TimeTrackingSettingsPoProps) {

	const fallback = (
		<section style={{ borderTop: '1px solid #e0e0e0', padding: '18px 0', textAlign: 'center' }}>
			<Loader small={true} />
		</section>
	);

	return (
		<Stack spacing={1}>
			<SettingsHeader disableCancellationDropdown={trial?.trialActive} />
			<PoMicroFrontend
				approvers={approvers}
				defaultValues={defaultValues}
				fallback={fallback}
				route='/time-tracking/settings'
				trial={trial}
			/>
		</Stack>
	);
}
