import React from 'react';
import {
	BodyText,
	Button,
	Flex,
	Headline,
	HeroHeadlineSize,
	StandardModal,
} from '@bamboohr/fabric';

interface PreviousContactAccessModalProps {
	isOpen: boolean;
	onCancel: () => void;
	previousContactName?: string;
	onSubmit: (keepAccess: boolean) => void;
	companyName: string | null;
}

export const PreviousContactAccessModal = ({
	isOpen,
	onCancel,
	previousContactName,
	onSubmit,
	companyName,
}: PreviousContactAccessModalProps) => {
	return (
		<StandardModal isOpen={isOpen} onRequestClose={onCancel}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button
								key="previous-contact-keep-access"
								onClick={() => onSubmit(true)}
								variant="outlined"
							>
								Keep Access
							</Button>,
							<Button
								key="previous-contact-remove-access"
								onClick={() => onSubmit(false)}
								variant="outlined"
							>
								Remove Access
							</Button>,
						]}
					/>
				}
			>
				<StandardModal.Constraint>
					<Flex
						alignItems="center"
						flexDirection={'column'}
						gap={2}
						textAlign="center"
					>
						<StandardModal.HeroHeadline
							icon="user-regular"
							size={HeroHeadlineSize.LARGE}
						/>

						<Headline color="neutral-strong" size="small">
							Do you want to remove{' '}
							{previousContactName || 'the previous contact'}’s access to
							payroll for
							{companyName ? ` ${companyName}?` : ' this company?'}
						</Headline>
						<BodyText>
							{$.__(
								'We’ll remove their access if they no longer need it. You can also change this on the Settings page anytime.',
							)}
						</BodyText>
					</Flex>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
