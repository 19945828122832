import Ajax from '@utils/ajax';
import { AxiosPromise } from 'axios';

import { includeCoverageOptionsStep } from './general';

import { getAcaPostData } from '../etc/get-aca-post-data';
import { getDeductionDateRulePostData } from '../etc/get-deduction-date-rule-post-data';
import { getDeductionTypePostData } from '../etc/get-deduction-type-post-data';
import { getPlanDependencyPostData } from '../etc/get-plan-dependency-post-data';
import { getPlanTypeId } from '../etc/get-plan-type-id';
import { getReportableOnW2PostData } from '../etc/get-reportable-on-w2-post-data';
import { hasEligibilityWaitPeriod } from '../etc/has-eligibility-wait-period';
import { isEligibilityFiltersValid } from '../etc/is-eligibility-filters-valid';
import {
	AdditionalPlanUrlsContract,
	Agreement,
	AutoEnrollOptionEnum,
	AutoEnrollTypeEnum,
	CoverageCostPostContract,
	CoverageCostValues,
	CoverageLevelsPostContract,
	CoverageLevelValue,
	DeductionFrequencyContract,
	DeductionOccurrenceValue,
	DemographicsContract,
	EligibilityGroupValues,
	EmployeeCostTypeValue,
	ExistingSelectedCoverageLevelContract,
	FiltersContract,
	FilterSelections,
	FilterType,
	FilterValue,
	HealthCategoryDataOptionsContract,
	NewEligibilityGroupPostContract,
	OrdinalPayDateEnumContract,
	PayTypeEnumContract,
	PlanRateTypeEnum,
	PlanWizardData,
	PlanWizardPostContract,
	ReimbursementContract,
	ReimbursementValue,
	SafeHarborValue,
	TimeToEligibilityContract,
	TimeToEligibilityValues,
	WizardValues,
} from '../types';
import { coverageOptionsPlanTypes, PlanTypeEnumContract } from '../../types';
import { packagePlanCategoryDataOptions } from './package-plan-category-data-options';

export const getDemographicData = (): AxiosPromise<DemographicsContract> => {
	return Ajax.get('/settings/benefits/employee_demographic');
};

export { getCurrencies } from '../../utils';

export const packageIsVariableProperty = (rateType: PlanRateTypeEnum, planType: PlanTypeEnumContract): boolean | null => {
	const isCoverageOptionPlanType = coverageOptionsPlanTypes.includes(planType);
	if (!isCoverageOptionPlanType) {
		return null;
	}

	if (rateType === PlanRateTypeEnum.standard) {
		return false;
	}

	return true;
};

export const packageReimbursementValues = (reimbursementValue?: ReimbursementValue): ReimbursementContract => {
	let reimbursement: ReimbursementContract = {
		amount: null,
		frequency: null,
		currencyCode: null,
	};

	if (reimbursementValue) {
		reimbursement = { ...reimbursementValue };
	}

	return reimbursement;
};

const packageSafeHarborValue = (usesSafeHarborValue: SafeHarborValue): boolean | null => {
	let safeHarbor = null;

	if (usesSafeHarborValue !== '') {
		safeHarbor = Boolean(usesSafeHarborValue === Agreement.yes);
	}

	return safeHarbor;
};

const packageDeductionFrequencies = (deductionOccurrenceValue: DeductionOccurrenceValue): DeductionFrequencyContract[] => {
	const deductionFrequencies: DeductionFrequencyContract[] = [];

	if (deductionOccurrenceValue.isEveryPaycheck === Agreement.no) {
		const payScheduleKeys = Object.keys(deductionOccurrenceValue.perPaySchedule);
		payScheduleKeys.forEach((payScheduleKey) => {
			const payScheduleId = Number(payScheduleKey);
			const frequencySelections = deductionOccurrenceValue.perPaySchedule[payScheduleId];

			const ordinalKeys = Object.keys(frequencySelections.ordinalPayDatesInPeriod);
			const ordinalPayDatesInPeriod: OrdinalPayDateEnumContract[] = [];
			ordinalKeys.forEach((key) => {
				if (frequencySelections.ordinalPayDatesInPeriod[key] !== '') {
					ordinalPayDatesInPeriod.push(frequencySelections.ordinalPayDatesInPeriod[key]);
				}
			});

			deductionFrequencies.push({
				payScheduleId,
				frequency: frequencySelections.frequency,
				ordinalPayDatesInPeriod,
			});
		});
	}

	return deductionFrequencies;
};

const packageNumericFilter = (filterValues: FilterSelections, key: FilterType): number[] => {
	return filterValues[key] === undefined ? [] : filterValues[key].map((selection) => Number(selection));
};

const constrainPayTypeValues = (values: FilterValue[]): PayTypeEnumContract[] => {
	const payTypes: PayTypeEnumContract[] = [];
	const payTypeKeys = Object.keys(PayTypeEnumContract);
	payTypeKeys.forEach((payTypeKey) => {
		if (values.includes(PayTypeEnumContract[payTypeKey])) {
			payTypes.push(PayTypeEnumContract[payTypeKey]);
		}
	});

	return payTypes;
};

const packagePayTypeFilter = (filterValues: FilterSelections): PayTypeEnumContract[] => {
	return filterValues[FilterType.payType] === undefined ? [] : constrainPayTypeValues(filterValues[FilterType.payType]);
};

const packageEligibilityFilters = (filterValues: FilterSelections): FiltersContract => {
	return {
		employmentStatuses: packageNumericFilter(filterValues, FilterType.employmentStatus),
		departments: packageNumericFilter(filterValues, FilterType.department),
		divisions: packageNumericFilter(filterValues, FilterType.division),
		locations: packageNumericFilter(filterValues, FilterType.location),
		payTypes: packagePayTypeFilter(filterValues),
		jobTitles: packageNumericFilter(filterValues, FilterType.jobTitle),
	};
};

const packageTimeToEligibility = (timeToEligibilityValues: TimeToEligibilityValues): TimeToEligibilityContract => {
	const includeWaitFields = hasEligibilityWaitPeriod(timeToEligibilityValues.requirement);
	return {
		requirement: timeToEligibilityValues.requirement === '' ? null : timeToEligibilityValues.requirement,
		waitTime: includeWaitFields && timeToEligibilityValues.waitTime !== '' ? Number(timeToEligibilityValues.waitTime) : null,
		waitPeriod: includeWaitFields && timeToEligibilityValues.waitPeriod !== '' ? timeToEligibilityValues.waitPeriod : null,
	};
};

const packageCoverageCosts = (
	coverageCosts: CoverageCostValues[],
	employeeCostType: EmployeeCostTypeValue
): CoverageCostPostContract[] => {
	return coverageCosts
		.filter((coverageCost) => coverageCost.coverageLevelLinkId !== '')
		.map((coverageCost) => {
			return {
				linkId: coverageCost.coverageLevelLinkId,
				employeeCostType,
				employeeCost: coverageCost.employeeCost,
			};
		});
};

const packageEligibilityGroups = (
	wizardValues: WizardValues,
	planType: PlanTypeEnumContract
): PlanWizardPostContract['eligibilityGroups'] => {
	const { eligibilityGroups, employeeCostType, rateType } = wizardValues;
	const packagedGroups: PlanWizardPostContract['eligibilityGroups'] = {
		existing: [],
		new: [],
	};

	eligibilityGroups.forEach((eligibilityGroup: EligibilityGroupValues) => {
		const packagedGroup: NewEligibilityGroupPostContract = {
			calculatorSettings: eligibilityGroup.calculatorSettings,
			class: eligibilityGroup.class,
			coverageCosts: packageIsVariableProperty(rateType, planType)
				? []
				: packageCoverageCosts(eligibilityGroup.coverageCosts, employeeCostType),
			lossOfEligibility: eligibilityGroup.lossOfEligibility,
			timeToEligibility: packageTimeToEligibility(eligibilityGroup.timeToEligibility),
			filters: packageEligibilityFilters(eligibilityGroup.eligibilityFilters.filterGroup.values),
			specificEmployeeIds: eligibilityGroup.eligibilityFilters.selectedEmployeeIds,
		};

		if (isEligibilityFiltersValid(eligibilityGroup.eligibilityFilters.filterGroup.values, packagedGroup.specificEmployeeIds)) {
			if (String(eligibilityGroup.id).includes('new')) {
				packagedGroups.new.push(packagedGroup);
			} else {
				packagedGroups.existing.push({
					...packagedGroup,
					id: Number(eligibilityGroup.id),
				});
			}
		}
	});

	return packagedGroups;
};

const packageStringValue = (wizardValue: string): null | string => {
	return wizardValue === '' ? null : wizardValue;
};

export const packageCoverageLevels = (
	planType: PlanTypeEnumContract,
	wizardValues: WizardValues,
	isBenefitsAdminEnabled: boolean
): CoverageLevelsPostContract => {
	const { coverageLevels, currencyCode, rateType } = wizardValues;
	const rateTypeIncludesCoverageLevels =
		rateType === PlanRateTypeEnum.standard ||
		(isBenefitsAdminEnabled && rateType === PlanRateTypeEnum.variable) ||
		rateType === PlanRateTypeEnum.ageBanded;

	const packagedCoverageLevels = {
		custom: {
			existing: [],
			new: [],
		},
		selected: {
			default: [],
			custom: {
				existing: [],
				new: [],
			},
		},
	};

	if (includeCoverageOptionsStep(isBenefitsAdminEnabled, planType, wizardValues) && rateTypeIncludesCoverageLevels) {
		coverageLevels.forEach((coverageLevel: CoverageLevelValue) => {
			if (!coverageLevel.isDefault) {
				if (coverageLevel.id) {
					packagedCoverageLevels.custom.existing.push({
						id: coverageLevel.id,
						linkId: coverageLevel.linkId,
						name: coverageLevel.name,
					});
				} else {
					packagedCoverageLevels.custom.new.push({
						linkId: coverageLevel.linkId,
						name: coverageLevel.name,
					});
				}
			}

			if (coverageLevel.isSelected) {
				if (coverageLevel.id) {
					const selectedLevel: ExistingSelectedCoverageLevelContract = {
						id: coverageLevel.id,
						linkId: coverageLevel.linkId,
						totalCost: coverageLevel.totalCost,
						currencyCode,
					};

					if (coverageLevel.isDefault) {
						packagedCoverageLevels.selected.default.push(selectedLevel);
					} else {
						packagedCoverageLevels.selected.custom.existing.push(selectedLevel);
					}
				} else {
					packagedCoverageLevels.selected.custom.new.push({
						linkId: coverageLevel.linkId,
						totalCost: coverageLevel.totalCost,
						currencyCode,
					});
				}
			}
		});
	}

	return packagedCoverageLevels;
};

const packageAutoEnrollData = (autoEnroll: AutoEnrollOptionEnum | null, autoEnrollRequired: boolean): AutoEnrollTypeEnum | null => {
	if (autoEnroll === AutoEnrollOptionEnum.enabled) {
		return autoEnrollRequired ? AutoEnrollTypeEnum.enabledRequired : AutoEnrollTypeEnum.enabledOptional;
	}
	if (autoEnroll === AutoEnrollOptionEnum.disabled) {
		return AutoEnrollTypeEnum.disabled;
	}

	return null;
};

const packageAdditionalUrls = (additionalPlanUrls: AdditionalPlanUrlsContract[]): AdditionalPlanUrlsContract[] | [] => {
	additionalPlanUrls.forEach((planUrlObject) => {
		Object.keys(planUrlObject).forEach((key) => (planUrlObject[key] = planUrlObject[key].trim()));
	});

	return additionalPlanUrls;
};

const packagePlanDataForPost = (
	wizardData: PlanWizardData,
	wizardValues: WizardValues,
	isBenefitsAdminEnabled: boolean,
	parentBenefitPlanId?: string
): PlanWizardPostContract => {
	const { decisionData, plan } = wizardData || {};
	const typeId = getPlanTypeId(wizardValues.planCategoryDataOptions as HealthCategoryDataOptionsContract);
	const postValues: PlanWizardPostContract = {
		additionalPlanUrls: packageAdditionalUrls(wizardValues.additionalPlanUrls),
		groupNumber: wizardValues.groupNumber,
		carrierPlanId: wizardValues.carrierPlanId,
		deletedPlanFileIds: wizardValues.deletedPlanFileIds,
		dependentRequiredQuestionIds: wizardValues.dependentRequiredQuestionIds,
		employeeRequiredQuestionIds: wizardValues.employeeRequiredQuestionIds,
		type: plan.type,
		name: wizardValues.name,
		planDates: {
			startYmd: packageStringValue(wizardValues.startYmd),
			endYmd: packageStringValue(wizardValues.endYmd),
		},
		description: packageStringValue(wizardValues.description),
		summary: packageStringValue(wizardValues.summary),
		temporaryPlanFileIds: wizardValues.temporaryPlanFileIds,
		duplicatePlanFileIds: wizardValues.duplicatedPlanFileIds,
		url: packageStringValue(wizardValues.url),
		planTypeData: {
			aca: getAcaPostData(wizardValues.acaMinEssentialCoverage, wizardValues.acaMinValueStandard),
			autoEnrollSetting: packageAutoEnrollData(wizardValues.autoEnroll, wizardValues.autoEnrollRequired),
			carrierId: wizardValues.vendorId || null,
			coverageLevels: packageCoverageLevels(plan.type, wizardValues, isBenefitsAdminEnabled),
			coverageRelationships: wizardValues.coverageRelationships,
			deductionTypeId: getDeductionTypePostData(
				wizardValues.deductionType,
				wizardValues.preTaxDeductionType,
				plan.type,
				typeId,
				decisionData
			),
			deductionDateRule: getDeductionDateRulePostData(wizardValues.deductionOffset),
			deductionFrequencies: packageDeductionFrequencies(wizardValues.deductionOccurrence),
			isVariable: packageIsVariableProperty(wizardValues.rateType, plan.type),
			planCategoryDataOptions: packagePlanCategoryDataOptions(wizardValues, plan.type),
			rateType: wizardValues.rateType,
			reimbursement: packageReimbursementValues(wizardValues.reimbursement),
			reportableOnW2: getReportableOnW2PostData(wizardValues.reportableOnW2),
			requiredPlanIds: getPlanDependencyPostData(wizardValues.medicalPlanDependency, wizardValues.medicalPlanDependencyOptions),
			safeHarbor: packageSafeHarborValue(wizardValues.usesSafeHarbor),
		},
		eligibilityGroups: packageEligibilityGroups(wizardValues, plan.type),
		legalText: wizardValues.legalText || null,
	};

	if (plan.id) {
		postValues.id = plan.id;
	}

	if (parentBenefitPlanId) {
		postValues.parentBenefitPlanId = parentBenefitPlanId;
	}

	return postValues;
};
export const upsertPlan = (
	wizardData: PlanWizardData,
	wizardValues: WizardValues,
	isBenefitsAdminEnabled: boolean,
	parentBenefitPlanId?: string
): AxiosPromise<{ null }> => {
	const planData = packagePlanDataForPost(wizardData, wizardValues, isBenefitsAdminEnabled, parentBenefitPlanId);

	let url = '/settings/benefits/plan_wizard';
	if (wizardData.plan.id) {
		url = `${url}/${wizardData.plan.id}`;
	}

	return Ajax.post(url, planData);
};

export const upsertDraftPlan = (
	wizardData: PlanWizardData,
	wizardValues: WizardValues,
	isBenefitsAdminEnabled: boolean
): AxiosPromise<null> => {
	const planData = packagePlanDataForPost(wizardData, wizardValues, isBenefitsAdminEnabled);
	return Ajax.post('/settings/benefits/plan_wizard/drafts', planData);
};

export const generateSuccessMessage = (planName: string, planId?: number): string => {
	return planId ? $.__('%s plan has been updated.', planName) : $.__('%s plan has been created.', planName);
};
