import React, { createContext, useState } from "react";
import { EmployeeWellbeingData, EmployeeWellbeingDataManager } from "../types";
import { EmployeeWellbeingSettingsProviderProps } from "./types";

export const Data = createContext<EmployeeWellbeingDataManager>(null);

export const useData = (data: EmployeeWellbeingData): EmployeeWellbeingDataManager => {
	const [state, update] = useState(data);
	return {
		state,
		update
	};
};

export const DataProvider: React.FC<EmployeeWellbeingSettingsProviderProps> = ({ children, data }) => {
	const dataManager = useData(data);
	return <Data.Provider value={dataManager}>{children}</Data.Provider>;
};
