import Ajax from '@utils/ajax';
import { getJsonData } from 'Data.util';

export function getSettingsJobTitleEEOCategoriesData() {
	const keepNodeInDOM = true;

	return new Promise(resolve => resolve(getJsonData('#jobTitleEEOCategoriesData', keepNodeInDOM)));
}

export function fetchCategoryModalData() {
	return Ajax.get(`/reports/widget/-6/categoryModal?format=json`);
}
