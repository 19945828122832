import React from 'react';
import { DataProvider } from './data';
import { SlidedownProvider } from './slidedown';
import { SurveyStatusProvider } from './status';
import { EmployeeWellbeingSettingsProviderProps } from './types';

export const EmployeeWellbeingSettingsPageProvider: React.FC<EmployeeWellbeingSettingsProviderProps> = ({ data, children }) => {
    return (
        <DataProvider data={data}>
            <SlidedownProvider>
                <SurveyStatusProvider>
                    {children}
                </SurveyStatusProvider>
            </SlidedownProvider>
        </DataProvider>
    );
};
