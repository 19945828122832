import { EmployeeWellbeingSurveyState, QuestionData, ReducerAction, SavedResponse } from '../types';

/**
 * Computes whether the survey can be continued using the saved response to the survey
 * @param savedResponse object of type SavedReponse or null
 * @returns boolean that indicates whether survey can be continued
 */
const canContinue = (savedResponse: SavedResponse|null) => {
	if (savedResponse) {
		return savedResponse.score !== null && savedResponse.score !== undefined;
	}
	return false;
};

/**
 * Maps data recieved from BE contract to FE state
 * @param data QuestionData
 * @returns EmployeeWellbeingSurveyState
 */

export const mapDataToState = (data: QuestionData): EmployeeWellbeingSurveyState => {
	const {
		submitted, 
		closed, 
		savedResponse, 
		question, 
		questionNumber, 
		nextButtonLabel, 
		nextQuestionUrl, 
		previousQuestionUrl, 
		answers,
		imageUrl
	} = data;

	return {
		canContinue: canContinue(savedResponse),
		question,
		submitted,
		closed,
		answer: savedResponse?.score,
		freeResponse: savedResponse?.freeResponse ? savedResponse?.freeResponse : '',
		totalQuestions: 4,
		loaded: true,
		previousQuestionUrl,
		nextButtonLabel,
		questionNumber,
		nextQuestionUrl,
		answers,
		imageUrl,
		updatePending: false,
		serverError: false,
	};
};


export const reducer = (state: EmployeeWellbeingSurveyState, {type, payload}: ReducerAction): EmployeeWellbeingSurveyState => {
	
	switch (type) {
		case 'INIT_STATE':
			return mapDataToState(payload as QuestionData);
		case 'UPDATE_ANSWER':
			return {
				...state,
				answer: payload as number,
				canContinue: payload !== undefined && payload !== null,
			};
		case 'UPDATE_RESPONSE':
			return {
				...state,
				freeResponse: payload as string
			};
		case 'UPDATE_QUESTION':
			return mapDataToState(payload as QuestionData);
		case 'CLOSE_SURVEY':
			return {
				...state,
				closed: true
			};
		case 'SUBMIT_SURVEY':
			return {
				...state,
				submitted: true
			};
		case 'START_PENDING_UPDATE':
			return {
				...state,
				updatePending: true,
				canContinue: false,
				serverError: false,
			};
		case 'SERVER_ERROR':
			return {
				...state,
				updatePending: false,
				canContinue: state.answer !== undefined && state.answer !== null,
				serverError: true,
			};
		default: 
			return {
				...state
			};
	}
};

