export const getActionContent = (isActionDisabled: boolean, defaultDeleteAction: () => void) => {
	if (isActionDisabled) {
		return {
			deleteActions: null,
			tooltipContent: $.__('Plans with enrollment history cannot be deleted. Update the end date in plan settings to end the plan.'),
		};
	}

	return {
		deleteActions: defaultDeleteAction,
		tooltipContent: $.__('Delete'),
	};
};
