import {
	Fragment,
	useEffect,
} from 'react';

import {
	TheNewEmployeeSelector as EmployeeSelector,
} from 'employee-selector.react';
import Tooltip from 'tooltip.react';

import {
	useStateContext,
} from '../state';


export default function AddMultipleEmployees() {
	const [{
		accessLevel,
		canCombine,
		editType,
		employeeFilters,
		employeeList,
		multiple,
	}, {
		changeSelectedEmployees,
	}] = useStateContext();

	useEffect(() => {
		if (multiple) {
			window.BambooHR.Modal.setState({
				isLoading: false,
				title: $.__('Add Employees'),
				type: 'medium',
			}, true);
		}
	}, [multiple]);

	return multiple && (
		<Fragment>
			<p id="js-multiple-employee-label">
				{ $.__(`Select the Employees you'd like to add to %1$s:`, accessLevel.name) }
			</p>
			<EmployeeSelector
				employees={ employeeList }
				filters={ employeeFilters }
				isRowDisabled={ (employee) => {
					if (!employee.hasEmail) {
						return true;
					} else if (
						canCombine &&
						editType === 'combine'
					) {
						return !employee.canCombine;
					}
				} }
				onDeselect={ employees => changeSelectedEmployees({
					employeeIds: employees.map(({ id }) => id),
					isSelected: false,
				}) }
				onSelect={ employees => changeSelectedEmployees({
					employeeIds: employees.map(({ id }) => id),
					isSelected: true,
				}) }
				rowDisabledTooltip={ (employee) => {
					if (!employee.hasEmail) {
						return $.__(`%1$s needs a home or work email in order to be added to an Access Level.`, employee.firstName);
					}

					return $.__(`%1$s is in an Access Level that cannot be combined with %2$s`, employee.firstName, accessLevel.name);
				} }
				secondaryContent={ ({ accessLevels }) => ((accessLevels.length === 1) ? (
					accessLevels[0].name
				) : (
					<Tooltip
						settings={ {
							content: accessLevels.map(({ name }) => name).join(', '),
						} }
					>
						{ $.__(`%1 Access Levels`, accessLevels.length) }
					</Tooltip>
				)) }
			/>
		</Fragment>
	);
}
