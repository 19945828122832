export enum BasicLifeOrADandDEnum {
	life = 'basicLife',
	add = 'basicAdd',
	both = 'basicLifeAdd',
}

export enum VoluntaryLifeOrADandDEnum {
	life = 'voluntaryLife',
	add = 'voluntaryAdd',
	both = 'voluntaryLifeAdd',
}
