import { BENEFIT_KEYS, BenefitPlanTypeEnumContract } from '../../constants';

export const benefitTypeAllowsAgeBands = (benefitType: BenefitPlanTypeEnumContract): boolean => {
	switch (benefitType) {
		case BENEFIT_KEYS.health:
			return true;
		default:
			return false;
	}
};
