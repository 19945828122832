import { ChangeEvent, useEffect, useState } from 'react';
import { Modal } from 'modal-legacy';

import {
	ErrorPosition,
	TextField,
} from 'form-fields.react';

import { useValidationContext } from '../context';
import { BenefitPlanListContract } from '../types';
import { DateRangeInput } from '../../shared/date-range-input.react';
import { duplicatePlan } from '../utils/api-service';
import {
	isPlanEndsRequired, validateEndDate, validateStartDate,
} from '../../utils';
import { validateFields } from './utils';
import { FieldValues } from './types';

interface DuplicateModalProps {
	isOpen: boolean;
	onClose: () => void;
	onDuplicate: () => void;
	rowData: BenefitPlanListContract;
}

export function DuplicateModal(props: DuplicateModalProps): JSX.Element {
	const {
		isOpen,
		onClose,
		onDuplicate,
		rowData,
	} = props;
	const [copiedEndDate, setCopiedEndDate] = useState('');
	const [copiedPlanName, setCopiedPlanName] = useState('');
	const [copiedStartDate, setCopiedStartDate] = useState('');
	const [isProcessing, setIsProcessing] = useState(false);

	useEffect(() => {
		if (rowData) {
			setCopiedPlanName(`${ rowData.planName } - ${ $.__('Copy') }`);
		}
	}, [rowData]);

	const validationContext = useValidationContext();
	const {
		errors,
		register,
		resetSpecialErrors,
		setSpecialErrors,
		specialErrors,
	} = validationContext;

	function resetDates(): void {
		setCopiedStartDate('');
		setCopiedEndDate('');
	}

	const MAX_LENGTH = 50;
	const rules = {
		required: true,
		maxLength: MAX_LENGTH,
	};

	const fieldValues: FieldValues = {
		name: copiedPlanName,
		startYmd: copiedStartDate,
		endYmd: copiedEndDate,
	};

	if (!rowData) {
		return null;
	}

	return (
		<Modal
			biId="duplicate-plan"
			isOpen={ isOpen }
			isProcessing={ isProcessing }
			onClose={ () => {
				resetSpecialErrors();
				resetDates();
				onClose();
			} }
			primaryAction={ () => {
				const isValid = validateFields(validationContext, fieldValues, rowData.type);
				if (!isValid) {
					return;
				}

				setIsProcessing(true);
				duplicatePlan(rowData.id, fieldValues)
					.then((response) => {
						if (response) {
							window.setMessage($.__('%1$s was duplicated successfully', rowData.planName), 'success');
							setIsProcessing(false);
							resetDates();
							onDuplicate();
						} else {
							console.error(response);
							window.setMessage($.__('Something went wrong.'), 'error');
							setIsProcessing(false);
						}
					})
					.catch((error) => {
						console.error(error);
						window.setMessage(error.response.data.error.message, 'error');
						setIsProcessing(false);
					});
			} }
			primaryActionText={ $.__('Duplicate Plan') }
			title={ $.__('Duplicate %1 Plan', rowData.planName) }
		>
			<form>
				<fieldset>
					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<TextField
								error={ (errors.planName) ? errors.planName.message : '' }
								id="planName"
								inputRef={ register(rules) }
								label={ $.__('Select a name for your duplicated plan') }
								maxLength={ MAX_LENGTH }
								name="planName"
								onChange={ (event: ChangeEvent<HTMLInputElement>): void => {
									if (event && event.target) {
										setCopiedPlanName(event.target.value);
									}
								} }
								required={ true }
								value={ copiedPlanName }
								width={ 9 }
							/>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<div className="fab-FormRow BftDateRange">
						<div className="fab-FormColumn">
							<DateRangeInput
								endDate={ copiedEndDate }
								endErrorMessage={ specialErrors.planEnds ? specialErrors.planEnds.message : '' }
								endId="planEnds"
								endLabel={ $.__('Plan Ends') }
								errorPosition={ ErrorPosition.bottom }
								isEndRequired={ isPlanEndsRequired(rowData.type) }
								isStartRequired={ true }
								onEndChange={ (date: string) => {
									setCopiedEndDate(date);
									validateEndDate(rowData.type, date, copiedStartDate, setSpecialErrors);
								} }
								onStartChange={ (date: string) => {
									setCopiedStartDate(date);
									validateStartDate(date, copiedEndDate, setSpecialErrors);
								} }
								startDate={ copiedStartDate }
								startErrorMessage={ specialErrors.planStarts ? specialErrors.planStarts.message : '' }
								startId="planStarts"
								startLabel={ $.__('Plan Starts') }
							/>
						</div>
					</div>
				</fieldset>
			</form>
		</Modal>
	);
}
