import {
	FilterGroup,
	FiltersContract,
	FilterType,
	LifeCoverageOptions,
	SalaryBasedCoverageContract,
	SalaryBasedSpouseType,
	StepName,
} from './types';
import { PlanTypeEnumContract } from '../types';
import { MoneyWaving24x20, List24x20, Family24x24, GroupMoney24x20, ClipboardQuestion20x24 } from '@bamboohr/grim';

export const DEFAULT_CLASS_CODES = {
	code: '',
	description: '',
	name: '',
};

export const PLAN_STEP_DESCRIPTIONS = {
	[StepName.PlanDetails]: $.__('How will you explain this plan to your employees?'),
	[StepName.CoverageOptions]: $.__('Determine which coverage options are available for your employees and their dependents.'),
	[StepName.EligibilityAndCost]: $.__('Which employees are eligible for this plan, and how much will it cost?'),
	[StepName.EnrollmentDetails]: $.__('What information do you need to gather from employees and/or their dependents?'),
	[StepName.PayrollDeduction]: $.__('Define when payments for this benefit are deducted.'),
};

export const PLAN_STEP_ICONS = {
	[StepName.PlanDetails]: List24x20,
	[StepName.CoverageOptions]: Family24x24,
	[StepName.EligibilityAndCost]: GroupMoney24x20,
	[StepName.EnrollmentDetails]: ClipboardQuestion20x24,
	[StepName.PayrollDeduction]: MoneyWaving24x20,
};

export const PLAN_STEP_TITLES = {
	[StepName.PlanDetails]: $.__('Plan Details'),
	[StepName.CoverageOptions]: $.__('Coverage Options'),
	[StepName.EligibilityAndCost]: $.__('Eligibility'),
	[StepName.EnrollmentDetails]: $.__('Enrollment Details'),
	[StepName.PayrollDeduction]: $.__('Payroll Deduction'),
};

export const TRANSLATED_PLAN_TYPES: { [key in PlanTypeEnumContract]: string } = {
	[PlanTypeEnumContract.health]: $.__('Medical'),
	[PlanTypeEnumContract.dental]: $.__('Dental'),
	[PlanTypeEnumContract.vision]: $.__('Vision'),
	[PlanTypeEnumContract.disability]: $.__('Disability'),
	[PlanTypeEnumContract.flex]: $.__('Flex Spending'),
	[PlanTypeEnumContract.hsa]: $.__('Health Savings'),
	[PlanTypeEnumContract.supplemental]: $.__('Supplemental Health'),
	[PlanTypeEnumContract.retirement]: $.__('Retirement'),
	[PlanTypeEnumContract.reimbursement]: $.__('Reimbursement'),
	[PlanTypeEnumContract.life]: $.__('Life Insurance'),
	[PlanTypeEnumContract.other]: $.__('Other'),
};

export const PLAN_ICONS = {
	[PlanTypeEnumContract.health]: 'fab-heart-ekg-28x24',
	[PlanTypeEnumContract.dental]: 'fab-tooth-28x28',
	[PlanTypeEnumContract.vision]: 'fab-eye-28x20',
	[PlanTypeEnumContract.supplemental]: 'fab-heart-plus-28x24',
	[PlanTypeEnumContract.retirement]: 'fab-piggy-bank-28x26',
	[PlanTypeEnumContract.reimbursement]: 'fab-money-reverse-28x28',
	[PlanTypeEnumContract.hsa]: 'fab-heart-money-28x24',
	[PlanTypeEnumContract.flex]: 'fab-wallet-28x26',
	[PlanTypeEnumContract.life]: 'fab-medical-shield-24x28',
	[PlanTypeEnumContract.disability]: 'fab-wheelchair-25x28',
	[PlanTypeEnumContract.other]: 'fab-star-28x27',
};

export const SMALL_PLAN_ICONS = {
	[PlanTypeEnumContract.health]: 'fab-heart-ekg-16x13',
	[PlanTypeEnumContract.dental]: 'fab-tooth-16x16',
	[PlanTypeEnumContract.vision]: 'fab-eye-16x12',
	[PlanTypeEnumContract.supplemental]: 'fab-heart-plus-20x17',
	[PlanTypeEnumContract.retirement]: 'fab-piggy-bank-16x16',
	[PlanTypeEnumContract.reimbursement]: 'fab-money-reverse-16x16',
	[PlanTypeEnumContract.hsa]: 'fab-heart-money-16x14',
	[PlanTypeEnumContract.flex]: 'fab-wallet-16x15',
	[PlanTypeEnumContract.life]: 'fab-medical-shield-14x16',
	[PlanTypeEnumContract.disability]: 'fab-wheelchair-14x16',
	[PlanTypeEnumContract.other]: 'fab-star-16x15',
};

export const INITIAL_FILTERS: FilterGroup = {
	isAutoGenerated: false,
	order: [],
	values: {
		[FilterType.employmentStatus]: [],
		[FilterType.location]: [],
		[FilterType.department]: [],
		[FilterType.division]: [],
		[FilterType.payType]: [],
		[FilterType.jobTitle]: [],
	},
};

export const CONTRACT_FILTER_KEYS: { [key in FilterType]: keyof FiltersContract } = {
	[FilterType.employmentStatus]: 'employmentStatuses',
	[FilterType.location]: 'locations',
	[FilterType.department]: 'departments',
	[FilterType.division]: 'divisions',
	[FilterType.payType]: 'payTypes',
	[FilterType.jobTitle]: 'jobTitles',
};

export const VALIDATION_KEYS = {
	reimbursement: 'reimbursementAmount',
};

export const DEFAULT_COVERAGE_AMOUNTS: SalaryBasedCoverageContract = {
	employee: null,
	spouse: null,
	child: null,
	type: '',
};

export const DEFAULT_COVERAGE_OPTIONS_VALUES: LifeCoverageOptions = {
	spouseCoverage: false,
	childCoverage: false,
	type: null,
	employeeAmount: null,
	employeeIncrement: null,
	employeeMinimumAmount: null,
	employeeMaximumAmount: null,
	employeeMultiplier: null,
	spouseAmount: null,
	spouseAmountType: SalaryBasedSpouseType.percentage,
	spouseIncrement: null,
	spouseMinimumAmount: null,
	spouseMaximumAmount: null,
	spousePercentOfEmployeeCoverage: null,
	childAmount: null,
	childIncrement: null,
	childMinimumAmount: null,
	childMaximumAmount: null,
};
