import {useEffect} from 'react'
import {
	showAppModal
} from '../app-modal'
import {
	useSettingsApps
} from '../../context';

import AppItem from '../app-item';
import EmptyState from '../empty-state';

import {
	bem,
} from '../../../utils';

import './styles.styl';


export default function AppList({
	installed = false,
}) {
	const context = useSettingsApps()
	const[{appList}] = context
	const apps = appList.filter(app => !!app.installed === !!installed);
	const empty = apps.length === 0;

	useEffect(() => {
		const params = new URLSearchParams(location.search)

		const appName = params.get('app')
		const status = params.get('status')

		if(appName){
			const app = apps.find(e => e.name === appName)
			if(app){
				// setTimeout gives the modal styles time to load.  Without it the modal doesn't look right.
				setTimeout(() => {
					showAppModal(app, app.installed ? 'uninstall' : 'install', context)
				}, 100)
			}
		}

		if(status && status === '0'){
			// same idea as above.  We have to give the window time to initialize
			setTimeout(() => {
				window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error')
			}, 100)
		}
	}, [apps, context])

	return (
		<div
			className={ bem('appList', {
				empty,
				installed,
				uninstalled: !installed,
			}) }
		>
			{ apps.map(app => (
				<AppItem app={ app } key={ app.name } />
			)) }
			{ empty && (
				<EmptyState { ...{ installed } } />
			) }
		</div>
	);
}
