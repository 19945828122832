import React from 'react';
import { WistiaVideo } from '@bamboohr/fabric';
import styles from './styles';

const Video = ({ title, videoId }) => {
	const classes = styles();
	
	return (
		<WistiaVideo
			className={ classes.videoPlayer }
			wistiaId={ videoId }
			iframeProps={ { title } }
		/>
	);
}

Video.defaultProps = { title: '', videoId: '' }

export default Video;