import { Headline, LayoutBox } from '@bamboohr/fabric';
import React, { FC } from 'react';
import { SectionDivider } from './section-divider';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export interface FormSectionProps {
	title: string;
}

export const FormSection: FC<FormSectionProps> = ({ children, title }) => {
	return (
		<fieldset>
			<SectionDivider />
			<LayoutBox marginTop={ifFeature('encore', 2, 0.5)} marginBottom={1.5}>
				<Headline color={ifFeature('encore', undefined, 'neutral-strong')} size={ifFeature('encore', 'small', 'extra-small')}>
					{title}
				</Headline>
			</LayoutBox>
			{children}
		</fieldset>
	);
};
