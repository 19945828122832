import { Icon, Flex, BodyText, IconV2, TextButton } from '@bamboohr/fabric';
import { PlaidOrManualDropdown } from '../plaid-or-manual-dropdown';
import { BANK_APP_STATE, getAppStateFromData, showPlaidFailedMessage } from '../../utils/shared';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function getColumns(
	handleOpenDownloadModal,
	handleOpenUploadModal,
	handleOpenVerificationModal,
	plaidLinkToken,
	handleLinkSuccess
) {
	return [
		{
			header: $.__('Account Nickname'),
			cell: (row) => renderAccountNickname(row, plaidLinkToken, handleLinkSuccess),
		},
		{
			header: $.__('Account #'),
			cell: (row) => <div>{row.accountNumber}</div>,
		},
		{
			header: $.__('Routing #'),
			cell: (row) => <div>{row.routingNumber}</div>,
		},
		{
			header: $.__('Account Type'),
			cell: (row) => <div>{row.accountType}</div>,
		},
		{
			header: $.__('Status'),
			cell: (row) => renderStatus(row, handleOpenDownloadModal, handleOpenUploadModal, handleOpenVerificationModal),
		},
	];
}

export function getGroupsAndRows(companies) {
	const groups = [];
	const rows = [];

	companies.forEach((company) => {
		const { clientId, companyLegalName, ein, bankAccounts } = company;

		let truncatedCompanyName = companyLegalName;
		if (truncatedCompanyName.length > 24) {
			truncatedCompanyName = `${truncatedCompanyName.substring(0, 24)}...`;
		}

		// Add EIN as a row group
		groups.push({
			id: clientId,
			icon: 'fab-company-12x14',
			paddingReset: 'vertical',
			content: (
				<span className='MultiEinBanksTable__group'>
					{truncatedCompanyName}
					<span className='MultiEinBanksTable__companyEin'> - {ein}</span>
				</span>
			),
		});

		if (bankAccounts.length) {
			// Add each bank account as an individual row
			bankAccounts.forEach((account) => {
				account.group = clientId;
				account.isAddNew = false;
				rows.push(account);
			});
		} else {
			rows.push({
				clientId,
				group: clientId,
				accountNickname: `+ ${$.__('Add Bank Account')}`,
				accountNumber: null,
				routingNumber: null,
				accountType: null,
				accountStatus: null,
				isAddNew: true,
			});
		}
	});

	// return both, in order to only loop through data once
	return {
		groups,
		rows,
	};
}

function renderAccountNickname(rowData, plaidLinkToken, handleLinkSuccess) {
	const { clientId, bankId, accountNickname, isAddNew, bankName } = rowData;

	const clickManualAdd = () => {
		window.location.assign(`/settings/payroll/bank_account/multi_ein/${clientId}`);
	};

	if (isAddNew) {
		if (plaidLinkToken) {
			return (
				<PlaidOrManualDropdown
					clickManualAdd={clickManualAdd}
					isAddAccount={true}
					onError={showPlaidFailedMessage}
					onSuccess={(token, metadata) => {
						handleLinkSuccess(token, metadata, clientId);
					}}
					plaidLinkToken={plaidLinkToken}
				/>
			);
		}
		return (
			<a className='MultiEinBanksTable__addNew' href={`/settings/payroll/bank_account/multi_ein/${clientId}`}>
				{accountNickname}
			</a>
		);
	}
	return (
		<a className='MultiEinBanksTable__bankName' href={`/settings/payroll/bank_account/multi_ein/${clientId}/${bankId}`}>
			{accountNickname || bankName}
		</a>
	);
}

function renderStatus(rowData, handleOpenDownloadModal, handleOpenUploadModal, handleOpenVerificationModal) {
	if (rowData.isAddNew) {
		return null;
	}

	if (rowData.penniesHasBeenStored && !rowData.isVerified) {
		return statusVerifingManualMode();
	}

	if (rowData.penniesHasBeenStored && rowData.isVerified) {
		return statusVerified(rowData);
	}

	const company = [{ bankAccounts: [] }];
	company[0].bankAccounts.push(rowData);

	const state = rowData.recordId ? BANK_APP_STATE.PRE_EIN : getAppStateFromData(company);

	switch (state) {
		case BANK_APP_STATE.PRE_EIN:
			return null;

		case BANK_APP_STATE.BANK_SAVED:
			return statusCompanySaved(rowData, handleOpenDownloadModal);

		case BANK_APP_STATE.DOCS_DOWNLOADED:
			return statusDocsDownloaded(rowData, handleOpenUploadModal);

		case BANK_APP_STATE.DOCS_UPLOADED:
			return statusDocsUploaded(rowData, handleOpenVerificationModal);

		case BANK_APP_STATE.VERIFIED:
			return statusVerified(rowData);

		default:
			break;
	}
}

const statusCompanySaved = (row, handleOpenDownloadModal) => {
	const tempText = $.__('Download Forms');

	return ifFeature(
		'encore',
		<Flex flexDirection='column' alignItems='flex-start'>
			<Flex gap={0.75} alignItems='center'>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
				<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
			</Flex>
			<TextButton onClick={() => handleOpenDownloadModal(row)} inline type='button'>
				{tempText}
			</TextButton>
		</Flex>,
		<div className='MultiBanksTable__statusPending'>
			<span className='MultiBanksTable__statusPendingRow'>
				<span className='MultiBanksTable__statusPendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>

				<span className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</span>
			</span>

			<div className='MultiBanksTable__statusPendingLink'>
				<TextButton clickAction={() => handleOpenDownloadModal(row)} inline={true} text={tempText} type='button' />
			</div>
		</div>
	);
};

const statusDocsDownloaded = (row, handleOpenUploadModal) => {
	const tempText = $.__('Upload Signed Forms');

	return ifFeature(
		'encore',
		<Flex flexDirection='column' alignItems='flex-start'>
			<Flex gap={0.75} alignItems='center'>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
				<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
			</Flex>
			<TextButton onClick={() => handleOpenUploadModal(row)} inline type='button'>
				{tempText}
			</TextButton>
		</Flex>,
		<div className='MultiBanksTable__statusPending'>
			<span className='MultiBanksTable__statusPendingRow'>
				<span className='MultiBanksTable__statusPendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>

				<span className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</span>
			</span>
			<div className='MultiBanksTable__statusPendingLink'>
				<TextButton clickAction={() => handleOpenUploadModal(row)} inline={true} text={tempText} type='button' />
			</div>
		</div>
	);
};

const statusVerifingManualMode = () => {
	return ifFeature(
		'encore',
		<Flex flexDirection='column' alignItems='flex-start'>
			<Flex gap={0.75} alignItems='center'>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
				<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
			</Flex>
			<BodyText color='neutral-weak'>{$.__('Verifying deposit amount')}</BodyText>
		</Flex>,
		<div className='MultiBanksTable__statusPending'>
			<span className='MultiBanksTable__statusPendingRow'>
				<span className='MultiBanksTable__statusPendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>

				<div className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</div>
			</span>
			<div className='MultiBanksTable__statusVerifyingText'>{$.__('Verifying deposit amount')}</div>
		</div>
	);
};

const statusDocsUploaded = (row, handleOpenVerificationModal) => {
	return ifFeature(
		'encore',
		<Flex flexDirection='column' alignItems='flex-start'>
			<Flex gap={0.75} alignItems='center'>
				<IconV2 name='hourglass-solid' color='neutral-medium' size={16} />
				<BodyText color='neutral-medium'>{$.__('Pending')}</BodyText>
			</Flex>
			<TextButton onClick={() => handleOpenVerificationModal(row)} inline type='button'>
				{$.__('Verify Account')}
			</TextButton>
		</Flex>,
		<div className='MultiBanksTable__statusPending'>
			<span className='MultiBanksTable__statusPendingRow'>
				<span className='MultiBanksTable__statusPendingIcon'>
					<Icon name='fab-hourglass-13x16' />
				</span>

				<span className='MultiBanksTable__statusPendingText'>{$.__('Pending')}</span>
			</span>

			<div className='MultiBanksTable__statusPendingLink'>
				<TextButton clickAction={() => handleOpenVerificationModal(row)} inline={true} text={$.__('Verify Account')} type='button' />
			</div>
		</div>
	);
};

const statusVerified = () => {
	return ifFeature(
		'encore',
		<Flex gap={0.75} alignItems='center'>
			<IconV2 name='circle-check-solid' color='success-strong' size={16} />
			<BodyText color='success-strong'>{$.__('Verified')}</BodyText>
		</Flex>,
		<div className='MultiBanksTable__statusVerified'>
			<Icon name='fab-check-circle-14x14' />
			<span className='MultiBanksTable__statusVerifiedText'>{$.__('Verified')}</span>
		</div>
	);
};
