import Ajax from '@utils/ajax';
import { showSlidedown } from 'Alerts.mod';

import { BlankStateComponent } from '../states/single-account/0-blank/blank-state';
import { PreBankStateComponent } from '../states/single-account/1-pre-bank/pre-bank-state';
import { BankSavedStateComponent } from '../states/single-account/2-bank-saved/bank-saved-state';
import { DocsDownloadedStateComponent } from '../states/single-account/3-docs-downloaded/docs-downloaded-state';
import { DocsUploadedStateComponent } from '../states/single-account/4-docs-uploaded';
import { BankVerifiedStateComponent } from '../states/single-account/5-bank-verified/bank-verified-state';
import { LinkPlaidState } from '../states/single-account/6-link-plaid';
import { MultiBanksStateComponent } from '../states/multi-account/0-multi-banks/multi-banks';
import { MultiCompaniesStateComponent } from '../states/multi-ein/0-multi-companies/multi-companies';

import { FILE_STATUSES } from '../../company-information.react/utils/shared';

export const getBankInformation = () => {
	return Ajax.get('/settings/payroll/bank_account/data');
};

export const postCompanyBankData = (clientId, data) => {
	const payload = buildPayloadObject(clientId, data);

	return Ajax.post('/settings/payroll/bank_account', payload);
};

export const putCompanyBankData = (clientId, data) => {
	const payload = buildPayloadObject(clientId, data);

	return Ajax.put('/settings/payroll/bank_account', payload);
};

export const putCompanyBankNickname = (clientId, data) => {
	const { bankId, accountNickname: nickname } = data;
	const payload = { bankId, clientId, nickname };

	return Ajax.put('/settings/payroll/bank_account/nickname', payload);
};

export const getCompanyBankData = (clientId, bankId) => {
	return Ajax.get(`/settings/payroll/bank_account/multi_ein/data/${clientId}/${bankId}`);
};

export const validateRoutingNumber = (number) => {
	return Ajax.get(`/payroll/direct_deposit/routing/validate/${number}`);
};

const buildPayloadObject = (clientId, bankAccount, bankId = null) => {
	return {
		clientId,
		nickName: bankAccount.accountNickname,
		accountNumber: bankAccount.accountNumber,
		routingNumber: bankAccount.routingNumber,
		accountType: bankAccount.accountType,
		bankId: bankAccount.bankId ? bankAccount.bankId : bankId,
	};
};

export const reloadCurrentPage = () => {
	window.location.reload();
};

export const getUpdateMessage = (isVerifiedOrLegacy) => {
	return isVerifiedOrLegacy ? BANK_SUBMISSION_UPDATE_MESSAGE_VERIFIED : BANK_SUBMISSION_UPDATE_MESSAGE;
};

export const takeToMultiEinEditBankPage = (clientId, bankId) => {
	window.location = `${BANK_ACCOUNT_MULTI_EDIT}${clientId}/${bankId}`;
};

export const DEFAULT_ERROR_MESSAGE = $.__('Oops, something went wrong.');
export const MISSING_INFO = $.__('Whoops... No worries. Please fix any missing or incorrect information and try again.');

export const COMPANY_INFO_URL = '/settings/payroll/company_information';
export const BANK_ACCOUNT_URL = '/settings/payroll/bank_account';
export const BANK_ACCOUNT_MULTI_EDIT = '/settings/payroll/bank_account/multi_ein/';

export const BANK_SUBMISSION_MESSAGE_KEY = 'X-BAMBOOHR-PAYROLL-BANK-APPLICATION-SHOW-MESSAGE';
export const BANK_SUBMISSION_MESSAGE = 'X-BAMBOOHR-PAYROLL-BANK-APPLICATION-MESSAGE';
export const BANK_SUBMISSION_CREATE_MESSAGE = 'Success! Please download and complete the bank account authorization forms below.';
export const BANK_SUBMISSION_UPDATE_MESSAGE = $.__('Bank account changes saved! You will need to download new forms to sign.');
export const BANK_SUBMISSION_UPDATE_MESSAGE_VERIFIED = 'Bank account changes saved!';

// Possible App State list
export const BANK_APP_STATE = {
	// Single Company & Single Bank
	BLANK: 'bank_blank', // showing blank state
	PRE_BANK: 'bank_pre', // showing forms
	LINK_PLAID_SINGLE: 'link_plaid_single',
	BANK_SAVED: 'bank_saved', // showing download forms card
	DOCS_DOWNLOADED: 'bank_docs_downloaded', // showing upload forms card
	DOCS_UPLOADED: 'bank_docs_uploaded', // showing verify deposit card
	VERIFIED: 'bank_verified', // showing verified,

	VERIFYING_MANUAL_MODE: 'bank_verifying_manual_mode',

	// Single Company & Multiple Banks
	MULTI_BANK_ACCOUNTS: 'bank_multi_accounts',

	// Multiple Companies
	MULTI_COMPANIES: 'bank_multi_companies',
};

// Condensed States into a single Const for use further down
const STATE_BLANK = BANK_APP_STATE.BLANK;
const STATE_PRE_BANK = BANK_APP_STATE.PRE_BANK;
const STATE_LINK_PLAID_SINGLE = BANK_APP_STATE.LINK_PLAID_SINGLE;
const STATE_BANK_SAVED = BANK_APP_STATE.BANK_SAVED;
const STATE_DOCS_DOWNLOADED = BANK_APP_STATE.DOCS_DOWNLOADED;
const STATE_DOCS_UPLOADED = BANK_APP_STATE.DOCS_UPLOADED;
const STATE_VERIFIED = BANK_APP_STATE.VERIFIED;
const STATE_MULTI_BANK_ACCOUNTS = BANK_APP_STATE.MULTI_BANK_ACCOUNTS;
const STATE_MULTI_COMPANIES = BANK_APP_STATE.MULTI_COMPANIES;

// List of Parent State Components that renders each state
export const COMPONENTS = {
	[STATE_BLANK]: BlankStateComponent,
	[STATE_PRE_BANK]: PreBankStateComponent,
	[STATE_LINK_PLAID_SINGLE]: LinkPlaidState,
	[STATE_BANK_SAVED]: BankSavedStateComponent,
	[STATE_DOCS_DOWNLOADED]: DocsDownloadedStateComponent,
	[STATE_DOCS_UPLOADED]: DocsUploadedStateComponent,
	[STATE_VERIFIED]: BankVerifiedStateComponent,
	[STATE_MULTI_BANK_ACCOUNTS]: MultiBanksStateComponent,
	[STATE_MULTI_COMPANIES]: MultiCompaniesStateComponent,
};

export const getComponentForState = (currentState) => COMPONENTS[currentState];

export const getAppStateFromData = (companies, bypassPlaid) => {
	// BLANK STATE
	if (companies.length === 0) {
		return BANK_APP_STATE.BLANK;
	}

	// SINGLE COMPANY
	if (companies.length === 1) {
		const company = companies[0];

		// MULTI_BANK_ACCOUNTS
		if (company.bankAccounts.length > 1) {
			return BANK_APP_STATE.MULTI_BANK_ACCOUNTS;
		}

		// PRE_BANK
		if (company.bankAccounts.length === 0 || company.bankAccounts[0].bankId === null) {
			// LINK_PLAID
			if (!bypassPlaid) {
				return BANK_APP_STATE.LINK_PLAID_SINGLE;
			}

			return BANK_APP_STATE.PRE_BANK;
		}

		// 1 Bank Account
		if (company.bankAccounts.length === 1) {
			const {
				isVerified,
				formAchAuthorization: { fileStatus: achFileStatus },
			} = company.bankAccounts[0];
			const statusArray = fileStatusArray(achFileStatus);
			const setArray = new Set(statusArray);

			// VERIFIED
			if (isVerified === true) {
				return BANK_APP_STATE.VERIFIED;
			}

			// BANK_SAVED
			if (setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED) && !setArray.has(FILE_STATUSES.UPLOADED)) {
				return BANK_APP_STATE.BANK_SAVED;
			}

			// DOCS_DOWNLOADED
			if (setArray.has(FILE_STATUSES.DOWNLOADED) && !setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED)) {
				return BANK_APP_STATE.DOCS_DOWNLOADED;
			}

			// DOCS_DOWNLOADED
			if (setArray.has(FILE_STATUSES.UPLOADED) && setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED)) {
				return BANK_APP_STATE.DOCS_DOWNLOADED;
			}

			// DOCS_UPLOADED
			if (setArray.has(FILE_STATUSES.UPLOADED) && isVerified === false) {
				return BANK_APP_STATE.DOCS_UPLOADED;
			}
		}
	}

	// MULTI COMPANIES
	if (companies.length > 1) {
		return BANK_APP_STATE.MULTI_COMPANIES;
	}
};

export const BANK_FILES = {
	FORM_ACH_AUTHORIZATION: 'achAuthorization',
};

export const BANK_FILES_ARRAY = [
	{
		id: 1,
		name: 'ACH Authorization',
		type: BANK_FILES.FORM_ACH_AUTHORIZATION,
	},
];

export const nullBankAccount = {
	clientId: null,
	bankId: null,
	accountNickname: null,
	accountNumber: null,
	routingNumber: null,
	accountType: null,
	bankLogo: null,
	bankName: null,
	isVerified: false,
	npcAccountNumber: null,
	formAchAuthorization: {
		fileId: null,
		fileDataId: null,
		displayFileName: null,
		fileStatus: null,
		url: null,
	},
};

export const fileStatusArray = (...args) => [...args];

const PLAID_FAIL_MSG = $.__('Uh oh...something went wrong linking your account to Plaid. Please try again later.');
const PLAID_UPDATE_FAIL_MSG = $.__('Uh oh...something went wrong updating your account. Please try again.');
/**
 * @param {boolean} isUpdate whether to show the update message or the initial link message
 */
export function showPlaidFailedMessage(isUpdate = false) {
	showSlidedown(isUpdate ? PLAID_UPDATE_FAIL_MSG : PLAID_FAIL_MSG, 'error');
}

export function handlePlaidUpdateSuccess(token, metadata, bankAccount, onSuccess) {
	const { account_id } = metadata;
	const { bankId, clientId } = bankAccount;
	return Ajax.put('/settings/payroll/bank_account/plaid', {
		bankId,
		clientId,
		plaidToken: token,
		plaidAccountId: account_id,
	})
		.then((response) => {
			if (response.status === 200) {
				onSuccess?.();
			} else {
				showPlaidFailedMessage(true);
			}
		})
		.catch(() => {
			showPlaidFailedMessage(true);
		});
}
