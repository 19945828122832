import {
	BasicLifeCategoryDataOptionsContract,
	BasicLifeOrADandDEnum,
	LifeOrADandDEnum,
	PlanWizardGetContract,
	VoluntaryLifeCategoryDataOptionsContract,
	VoluntaryLifeOrADandDEnum,
} from '../../types';
import { PlanTypeEnumContract } from '../../../types';
import { makeLifePlanTypeLikeValue } from '../life-plan-type-like-factory';

export const getLifeOrAddValue = (wizardContract: PlanWizardGetContract): LifeOrADandDEnum | null => {
	const { benefitPlan, planType } = wizardContract;
	const { type } = planType;

	if (type === PlanTypeEnumContract.life) {
		const planCategoryDataOptions = benefitPlan?.planTypeData?.planCategoryDataOptions as
			| BasicLifeCategoryDataOptionsContract
			| VoluntaryLifeCategoryDataOptionsContract;

		return makeLifePlanTypeLikeValue(
			planCategoryDataOptions?.type ?? planCategoryDataOptions?.lifeInsuranceType,
			{
				[BasicLifeOrADandDEnum.life]: () => LifeOrADandDEnum.life,
				[BasicLifeOrADandDEnum.add]: () => LifeOrADandDEnum.add,
				[BasicLifeOrADandDEnum.both]: () => LifeOrADandDEnum.both,
				[VoluntaryLifeOrADandDEnum.life]: () => LifeOrADandDEnum.life,
				[VoluntaryLifeOrADandDEnum.add]: () => LifeOrADandDEnum.add,
				[VoluntaryLifeOrADandDEnum.both]: () => LifeOrADandDEnum.both,
			},
			() => null
		);
	}
	return null;
};
