import { Dropdown, Flex, Section } from '@bamboohr/fabric';
import React from 'react';
import { downloadCsvFile } from '../../helpers';
import { api } from '../../services';
import { BlankStateError, InvoiceDateRangePicker, InvoiceHistoryTable, InvoiceHistoryLoader } from '..';

export function InvoiceHistoryBody({ isLoading, isError, onRowSelect, selectedInvoices, rows, ...props }) {
	const hasSelectedInvoices = selectedInvoices.length > 0;

	const handleExportSelect = () => {
		const invoiceIds = selectedInvoices.map((inv) => inv.id);

		api.invoices.exportForInvoices
			.get(invoiceIds)
			.then((response) => downloadCsvFile(response, selectedInvoices))
			.catch((error) => {
				window.Rollbar.error('POSH: Unable to retrieve invoice data', {
					error,
					invoiceIds,
				});
				window.setMessage('Oops! Looks like the file export failed. Please try again.', 'error');
			});
	};

	return (
		<Section height='100%'>
			{!isLoading && !isError && (
				<>
					<Flex alignItems='flex-end' marginBottom={4}>
						<InvoiceDateRangePicker {...props} />
						<Dropdown
							biId='settings-account-billing-invoice-history-export'
							ButtonProps={{ outline: true, secondary: true, title: $.__('Export') }}
							isDisabled={!hasSelectedInvoices}
							items={[
								{
									items: [{ key: 'csv', text: $.__('CSV Spreadsheet'), value: 'csv' }],
									key: 'export',
									text: $.__('Export as...'),
									type: 'group',
								},
							]}
							onSelect={handleExportSelect}
						>
							{$.__('Export')}
						</Dropdown>
					</Flex>
					<InvoiceHistoryTable onRowSelect={onRowSelect} rows={rows} />
				</>
			)}
			{isLoading && <InvoiceHistoryLoader />}
			{isError && <BlankStateError />}
		</Section>
	);
}
