import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Dropdown, ItemType } from '@bamboohr/fabric';
import { accessLevelActionStyles } from './styles';
import { isEnabled } from 'FeatureToggle.util';

interface ExportOptionsProps {
	text: string;
	value: string;
}
interface ExportOptions {
	text: string;
	label: string;
	items: ExportOptionsProps[];
}

interface AccessLevelActionProps {
	[key: string]: ExportOptions[];
}

const ACCESS_LEVEL_PERMISSIONS_EXPORT = isEnabled('ACCESS_LEVEL_PERMISSION_EXPORT');

export function AccessLevelActions(props: AccessLevelActionProps): ReactElement {
	const { exportOptions } = props;
	const [dropdownItems, setDropdownItems] = useState<ItemType[]>([]);
	const styles = accessLevelActionStyles();

	//Remove items with ACCESS_LEVEL_PERMISSIONS_EXPORT toggle removal
	const items = [
		{
			anchor: 'bottom',
			items: [{ text: 'Print', value: 'print' }],
			key: 'anchoredBottom',
			type: 'group',
		},
		{
			items: [],
			key: 'exportOptions',
			type: 'group',
			text: 'Export List as...',
		},
	];
	const grabDropdownItems = () => {
		if (ACCESS_LEVEL_PERMISSIONS_EXPORT) {
			const array = [];

			for (const key of Object.keys(exportOptions)) {
				// This check is for grouped items
				if (exportOptions[key].text) {
					const group = {
						items: [
							{
								items: exportOptions[key].items,
								key: `${key}Items`,
								type: 'group',
								text: exportOptions[key].label,
							},
						],
						key,
						text: exportOptions[key].text,
						value: key,
					};

					array.push(group);
				} else {
					// Not grouped items will display normally
					const group = {
						items: exportOptions[key].items,
						key,
						text: exportOptions[key].label,
						type: 'group',
					};

					array.push(group);
				}
			}
			return array;
		} 
			items.map((el) => {
				if (el.key === 'exportOptions') {
					el.items = exportOptions;
				}
			});
			return items;
		
	};

	useEffect(() => {
		return setDropdownItems(grabDropdownItems());
	}, []);

	const handleExportSelect = (val) => {
		if (val === 'print') {
			window.print();
			return;
		}
		const ENDPOINT = `group_info/all?format=${val}`;
		window.open(ENDPOINT);
	};

	return (
		<div>
			<Button
				className={styles.historyButton}
				data-bi-id='settings-access-level-history'
				href="/settings/access_levels/history"
				outline={true}
				secondary={true}
				size='small'
				text={$.__('History')}
				type='button'
			/>
			<Dropdown
				ButtonProps={{
					text: $.__('Export'),
					color: 'secondary',
					variant: 'outlined',
					size: 'small',
				}}
				biId='settings-access-level-export'
				// @startCleanup encore
				buttonSettings={{
					text: $.__('Export'),
					color: 'secondary',
					variant: 'outlined',
					size: 'small',
				}}
				// @endCleanup encore
				items={dropdownItems }
				onSelect={handleExportSelect}
			/>
		</div>
	);
}
export default AccessLevelActions;
