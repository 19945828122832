import {
	extendURL,
	extendURLDeep,
} from '@utils/url';

/**
 * Updates the URL parameters with given keys and values
 * @param  {Object} params   Key/value pair whe key is the param key and value is the param value
 */
export function setParams(params, deep): void {
	const url = deep ? extendURLDeep(params) : extendURL(params);
	window.history.replaceState(null, null, url);
}
