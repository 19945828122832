import React from 'react';
import { StandardModal, WistiaVideo } from '@bamboohr/fabric';
import styles from './styles';

const UpsellModal = ({ children, isOpen, onRequestClose, title, wistiaVideoId, wistiaVideoTitle }) => {
	const classes = styles();

	return (
		<StandardModal
				isOpen={ isOpen }
				onRequestClose={ onRequestClose }
			>
				<StandardModal.Body
					renderHeader={ <StandardModal.Header title={ title } /> }
					size="medium"
				>
					<StandardModal.Constraint spacingOverrides={{
						bottom: StandardModal.Constraint.Spacing.BIGGIE, 
						top: StandardModal.Constraint.Spacing.LARGE,
						left: StandardModal.Constraint.Spacing.MEDIUM,
						right: StandardModal.Constraint.Spacing.MEDIUM,
					}}>
						<div className={ classes.view }>
							{ wistiaVideoId.length > 0 && (
								<div className={ classes.videoWrapper }>
									<WistiaVideo
										className={ classes.videoPlayer }
										wistiaId={ wistiaVideoId }
										iframeProps={ { title: wistiaVideoTitle } }
									/>
								</div>
							) }
							<div className={ classes.content }>
								{ children }
							</div>
						</div>
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
	);
}

UpsellModal.defaultProps = {
	children: null,
	isOpen: false,
	onClose: () => {},
	title: '',
	wistiaVideoId: '',
	wistiaVideoTitle: 'Promo Video',
}

export default UpsellModal;