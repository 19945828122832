import {
	ReactElement,
} from 'react';
import {
	makeStyles,
} from '@bamboohr/fabric';
import {
	EmployeeDeductionsMetadataProps,
} from '../../utils/interfaces';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		marginTop: '26px',
	},
	blankState: {
		height: '83px',
	},
	label: {
		marginBottom: '8px',
	},
	labelContainer: {
		marginRight: '20px',
	},
	value: {
		marginBottom: '8px',
	},
}));

export function EmployeeDeductionsMetadata(props: EmployeeDeductionsMetadataProps): ReactElement {
	const {
		data,
	} = props;
	const styles = useStyles();

	if (!data) {
		return (
			<div className={ styles.blankState }></div>
		);
	}

	const {
		endDate,
		employerPays,
		employeePays,
	} = data;

	return (
		<div className={ styles.container }>
			<div className={ styles.labelContainer }>
				<p className={ styles.label }>{ $.__('Deduction End') }:</p>
				<p className={ styles.label }>{ $.__('Employer Pays') }:</p>
				<p className={ styles.label }>{ $.__('Employee Pays') }:</p>
			</div>

			<div>
				<p className={ styles.value }>{ endDate || '--' }</p>
				<p className={ styles.value }>{ employerPays || '--' }</p>
				<p className={ styles.value }>{ employeePays || '--' }</p>
			</div>
		</div>
	);
}
