import { ChangeEvent, Fragment, useEffect, useState } from 'react';

import { convertStringToNumber, getCurrencySymbol, TextField } from 'form-fields.react';

import { FixedPercentToggle } from '../../../../../../../shared/fixed-percent-toggle';
import { useValidationContext } from '../../../../../../context';
import { useReactivateDisabledField } from '../../../../../../hooks/use-reactivate-disabled-field';
import { CostRow, CostTypeEnumContract, CoverageLevelValue, Currency, WizardValues } from '../../../../../../types';
import { generateEmployeeCostErrorKey, validateEmployeeCost, validateEmployeeCostsForCostTypeChange } from '../../../../../../utils';
import { ErrorTooltip } from '../../error-tooltip/error-tooltip';
import { constrainCostTypeValue, getFormattedCurrencyOrPercent } from '../utils';

import './employee-pays-cell.styl';

interface EmployeePaysCellProps {
	coverageLevel: CoverageLevelValue;
	currencies: Currency[];
	employeeCost: number | null;
	groupId: string | number;
	groupIndex: number;
	updateEmployeeCost: (value: number, groupIndex: number, coverageLinkId: string) => void;
	updateEmployeeCostType: (newValue: CostTypeEnumContract) => void;
	wizardValues: WizardValues;
}

export function EmployeePaysCell(props: EmployeePaysCellProps): JSX.Element {
	const { coverageLevel, currencies, employeeCost, groupId, groupIndex, updateEmployeeCostType, updateEmployeeCost, wizardValues } =
		props;

	const { currencyCode, employeeCostType } = wizardValues;

	const [inputValue, setInputValue] = useState(() =>
		getFormattedCurrencyOrPercent(employeeCost, employeeCostType, currencies, currencyCode)
	);

	const { setSpecialErrors, specialErrors } = useValidationContext();
	const errorKey = generateEmployeeCostErrorKey(groupId, coverageLevel.linkId);

	const handleEmployeePaysChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleEmployeePaysBlur = () => {
		const newEmployeeCost = convertStringToNumber(inputValue);

		if (newEmployeeCost !== employeeCost) {
			updateEmployeeCost(newEmployeeCost, groupIndex, coverageLevel.linkId);

			setInputValue(getFormattedCurrencyOrPercent(newEmployeeCost, employeeCostType, currencies, currencyCode));
			const costRow: CostRow = {
				employeeCost: newEmployeeCost,
				employeeCostType,
				totalCost: coverageLevel.totalCost,
			};
			validateEmployeeCost(errorKey, costRow, setSpecialErrors);
		}
	};

	const handleEmployeeCostTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newEmployeeCostType = constrainCostTypeValue(event.target.value);

		updateEmployeeCostType(newEmployeeCostType);

		validateEmployeeCostsForCostTypeChange(
			newEmployeeCostType,
			wizardValues.eligibilityGroups,
			wizardValues.coverageLevels,
			setSpecialErrors
		);
	};

	// Reformat the employee pays value when the currency code or cost type changes
	useEffect(() => {
		setInputValue(getFormattedCurrencyOrPercent(employeeCost, employeeCostType, currencies, currencyCode));
	}, [currencies, currencyCode, employeeCostType, employeeCost]);
	const isDisabled = useReactivateDisabledField(inputValue, true);

	return (
		<Fragment>
			<div className='fab-FormField'>
				<FixedPercentToggle
					costType={employeeCostType}
					currencySymbol={getCurrencySymbol(currencies, currencyCode)}
					handleChange={handleEmployeeCostTypeChange}
					idSuffix={`${coverageLevel.linkId + groupId}`}
					isDisabled={isDisabled}
				/>
			</div>
			{Boolean(specialErrors[errorKey]) && <ErrorTooltip message={specialErrors[errorKey].message} />}
			<div className='fab-FormField'>
				<TextField
					disabled={isDisabled}
					hasError={Boolean(specialErrors[errorKey])}
					onBlur={handleEmployeePaysBlur}
					onChange={handleEmployeePaysChange}
					value={inputValue}
					width={3}
				/>
			</div>
			<div className='fab-FormField EmployeePaysCell__text'>
				{employeeCostType === CostTypeEnumContract.fixed ? 'per month' : 'of total cost'}
			</div>
		</Fragment>
	);
}
