import { WizardState } from '../../../types/wizard-state';
import { FilterGroup, FilterType } from '../../../types/wizard-values';
import {
	areAllSecondaryFiltersEmpty, areAllSecondaryFiltersInActiveMenu,
	hasSelectionsInActiveMenu,
	secondaryFilterTypesAreSelectable,
} from './general-helpers';

export const shouldShowAddButton = (
	filters: FilterGroup,
	hasEditRestrictions: boolean,
	menuFilterTypeSelections: FilterType[],
	wizardState?: WizardState,
): boolean => {
	return wizardState !== WizardState.Reactivate && (secondaryFilterTypesAreSelectable(filters, hasEditRestrictions) ||
		hasSelectionsInActiveMenu(menuFilterTypeSelections));
};

export const generateAddButtonText = (
	filters: FilterGroup,
	menuFilterTypeSelections: FilterType[]
): string => {
	return (
		areAllSecondaryFiltersEmpty(filters) ||
		areAllSecondaryFiltersInActiveMenu(filters, menuFilterTypeSelections)
	) ? $.__('Add Filters') : '';
};

