module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push(' <div class="clearfix">  <div class="SettingsAccessLevels__container">   <h4 class="SettingsAccessLevels__title">    '); if (!encoreEnabled) { 
p.push('    <span class="SettingsAccessLevels__titleIcon">     <ba-icon       name="fab-lock-16x20"       brand="true"     ></ba-icon>    </span>    '); } 
p.push('    ',window.microTemplate.encodeHtml(title),'    '); if (count) { 
p.push('    <span class="count">(',window.microTemplate.encodeHtml(count),')</span>    '); } 
p.push('   </h4>   '); if (exportOptions) { 
p.push('    <div id="js-access-level-actions" class="SettingsAccessLevels__exportItems"></div>   '); } 
p.push('  </div> </div>  '); if (description) { 
p.push('  <p   class="SettingsAccessLevels__accessLevelDescription"   data-bi-id="settings-access-level-content-description-label"  >',window.microTemplate.encodeHtml(description),'</p> '); } 
p.push('  '); if (headerTmpl) { 
p.push(' <div id="js-access-level-header"></div> ',microTemplate(headerTmpl, headerTmplData),' '); } 
p.push('  '); if (bodyTmpl) { 
p.push(' ',microTemplate(bodyTmpl, bodyTmplData),' '); } 
p.push(' <div id="delete-access-level-modal-root"></div>  '); if (footerTmpl) { 
p.push(' ',microTemplate(footerTmpl, footerTmplData),' '); } 
p.push(' ');}return p.join('');
}