import { render } from 'base/wrapped-render';

import Ctrl, {
	onReady,
	onPjaxEnd,
} from 'ctrl.deco';

import AccountACA from './account-aca.react';

import './styles.styl';


@Ctrl('/settings/account/aca.php')
class SettingsAccountACACtrl {
	@onReady()
	@onPjaxEnd()
	_render(e, { _data, _wrapper }) {
		render((
			<AccountACA data={ _data } />
		), _wrapper);
	}
}
