import { BEM } from '@utils/dom';
import { Button } from '@bamboohr/fabric';
import './interstitial-setup.styl';
import { AuthSetupComponent } from '../auth-app/auth-setup-component';

type InterstitialSetupProps = {
	secret: string;
	onNextStep(): void;
	userEmail: string;
};

const bem = new BEM('InterstitialSetup');

export function InterstitialSetup(props: InterstitialSetupProps): JSX.Element {
	const { userEmail, secret, onNextStep } = props;

	return (
		<div className={bem.elem()}>
			<h2 className={bem.elem('Header')}>{$.__('Setup 2-Step Login')}</h2>
			<AuthSetupComponent secret={secret} userEmail={userEmail} />
			<div className={bem.elem('Footer')}>
				<Button clickAction={onNextStep} text={$.__('Next')} type='button' />
			</div>
		</div>
	);
}
