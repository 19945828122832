import { connect } from 'react-redux';

import { AdvancedApprovalsConfirmationModal } from '../components/advanced-approval-confirmation-modal.react';
import {
	closeConfirmModalAction,
	deleteChildPath,
	submitPathForm,
} from '../store/actions';

const mapStateToProps = (state) => {
	const {
		confirmationRequired,
		confirmationType,
		editingWorkflow,
		pathTypeOptions,
		workflow
	} = state;

	const {
		pathType
	} = workflow;

	const {
		pathsChanging = []
	} = editingWorkflow;

	let pathNames = pathsChanging.map((id) => {
		return pathTypeOptions.find((obj) => { return parseInt(obj.id) === parseInt(id); }).name;
	});

	return {
		actionType: confirmationType,
		editingWorkflow,
		modalOpen: confirmationRequired,
		paths: pathNames,
		pathType
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeConfirmModalAction: () => { dispatch(closeConfirmModalAction()); },
		handleDeleteApprovalPath: (childId, parentId) => { dispatch(deleteChildPath(childId, parentId)); },
		handleSave: (override) => { dispatch(submitPathForm(override)); }
	};
};

export const AdvancedApprovalsConfirmationModalContainer = connect(mapStateToProps, mapDispatchToProps)(AdvancedApprovalsConfirmationModal);
