import {
	BodyText,
	CardSize,
	Flex,
	Icon,
	IconV2,
	Interstitial,
	TextButton,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { redirect } from 'BambooHR.util';
import Ajax from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import '../interstitial.styl';

const TYPES_MAP = {
	helpdesk: {
		headerContent: $.__('In order to access the Bamboo Help Center, we need to verify your email address.'),
	},
	northpass: {
		headerContent: $.__('In order to access our BambooHR Learning Courses, we need to verify your email address.'),
	},
	learnupon: {
		headerContent: $.__('In order to access our BambooHR Learning Courses, we need to verify your email address.'),
	},
};

const DEFAULT_TYPE = TYPES_MAP.helpdesk;

export function EmailVerification(props) {
	const {
		email,
		resendLink,
		type = 'helpdesk',
	} = props;

	const typeData = TYPES_MAP[type] || DEFAULT_TYPE;
	const { headerContent } = typeData;

	return (
		<Interstitial cardSize={ CardSize.SMALL }>
			{ifFeature(
				"encore",
				<Flex alignItems='center' flexDirection='column' gap={2} textAlign='center'>
					<IconV2 color="primary-strong" name="envelope-circle-check-solid" size={72} />
					<BodyText color='neutral-extra-strong' size='large' weight='medium'>{headerContent}</BodyText>
					<BodyText>
							<Message
								em={ {
									tag: 'span',
									className: 'EmailVerification__email'
								} }
								params={ [email] }
								text={ $._(`We've sent a verification email to *{1}*. Please click the “Verify My Email” button in the email to continue.`) }
							/>
					</BodyText>
					<BodyText color='neutral-weak'>
						{ $.__(`Don't see the email?`) }
						{ ' ' }
						<TextButton
							inline={ true }
							onClick={ () => handleResendClick(resendLink) }
							type="button"
						>
							{ $.__('Resend') }
						</TextButton>
						.
					</BodyText>
				</Flex>,
				<div className="EmailVerification">
					<Icon brand={ true } name="fab-envelope-lock-72x67" />
					<h4 className="EmailVerification__header">{ headerContent }</h4>
					<div className="EmailVerification__instructionContainer">
						<p className="EmailVerification__instruction">
							<Message
								em={ {
									tag: 'span',
									className: 'EmailVerification__email'
								} }
								params={ [email] }
								text={ $._(`We've sent a verification email to *{1}*. Please click the “Verify My Email” button in the email to continue.`) }
							/>
						</p>
					</div>
					<p className="EmailVerification__resend">
						{ $.__(`Don't see the email?`) }
						{ ' ' }
						<TextButton
							inline={ true }
							onClick={ () => handleResendClick(resendLink) }
							type="button"
						>
							{ $.__('Resend') }
						</TextButton>
						.
					</p>
				</div>
			)}
		</Interstitial>
	);
}

function handleResendClick(url) {
	Ajax.post(url)
		.then(() => {
			window.setMessage($.__('We just sent you another verification email.'), 'success');
		})
		.catch(() => {
			redirect('/home', $.__('We were unable to send your verification email.'), 'error');
		});
}
