const ERROR_MESSAGE = $.__('Uh Oh! There was an error. Please try again or contact BambooHR support.');

/**
 * Send GET request for Enps
 * @param  {Object} requestData Data to be sent with the reqest
 * @return {Object}             Response
 */
const handleGet = (requestData) => {
	return $.get(`/settings/enps/${ requestData.endpoint }`).error(() => {
		setMessage(ERROR_MESSAGE, 'error');
	});
};

/**
 * Send POST request for eNPS
 * @param  {Object} requestData Data to be sent with the request
 * @return {Object}             Response
 */
const handlePost = (requestData) => {
	return $.post(`/settings/enps/${ requestData.endpoint }`, requestData.stepData).error(() => {
		setMessage(ERROR_MESSAGE, 'error');
	});
};

/**
 * Handles the type of request being made
 * @param  {Object} requestData Data to be sent with the request
 * @return {Object}             Respone
 */
export const handleRequest = (requestData) => {
	if (requestData.method === 'GET') {
		return handleGet(requestData);
	} else if (requestData.method === 'POST') {
		return handlePost(requestData);
	}
};

/**
 * Cancles eNPS
 * @return {Object} Response
 */
export const disableEnps = () => {
	const requestData = {
		endpoint: 'cancel',
		method: 'POST',
		stepData: {}
	};

	return handlePost(requestData);
};
