import { ReactElement } from 'react';
import { Button, BodyText, Dropdown, Flex, Headline, Icon, LayoutBox, Divider } from '@bamboohr/fabric/';

import { Message } from '@bamboohr/utils/lib/message';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface MenuItem {
	note: string;
	text: string;
	value: string;
}

interface Props {
	firstTemplate: boolean;
	isBlankState: boolean;
}

export function TemplateListHeader(props: Props): ReactElement {
	const { firstTemplate } = props;
	const { isBlankState } = props;

	const header = <Message text={$._('New Hire Packet Templates')} />;

	const subheader = (
		<Message
			text={$._('New Hire Packet Templates allow you to have specific packet setups for different offices, locations, or roles.')}
		/>
	);

	function handleSelect(value: string) {
		window.location.href = value;
	}

	const creationLink = firstTemplate
		? '/settings/onboarding/new_hire_packet_template/addInitialTemplate'
		: '/settings/onboarding/new_hire_packet_template/add';

	return ifFeature(
		'encore',
		<Flex flexDirection='column'>
			<LayoutBox>
				<Headline color='primary' size='small'>
					{header}
				</Headline>
				<BodyText size='small'>{subheader}</BodyText>
				<Flex justifyContent='space-between' marginY={2.5}>
					<Button color='secondary' href={creationLink} size='medium' startIcon='circle-plus-regular'>
						{$.__('New Template')}
					</Button>
					<Dropdown
						ButtonProps={{
							color: 'secondary',
							icon: 'gear-regular',
							title: 'Settings',
							variant: 'outlined',
						}}
						items={[
							{
								text: (
									<Flex flexDirection='column' gap={1}>
										<BodyText color='neutral-extra-strong'>{$.__('Edit “Get to Know You” Email')}</BodyText>
										<BodyText color='neutral-weak' size='extra-small'>
											{$.__(
												'Update the email that is sent out to existing employees when someone new joins. This will apply across all templates.'
											)}
										</BodyText>
									</Flex>
								),
								value: '/settings/tasklists/onboarding/welcome_email_template/',
							},
						]}
						onSelect={handleSelect}
						renderOptionContent={(item: MenuItem): ReactElement => {
							const { note, text } = item;
							return (
								<div className='fab-MenuOption__content NHPEmailLinkOption'>
									<div className='fab-MenuOption__row'>{text}</div>
									<div className='fab-MenuOption__row'>
										<p className='fab-MenuOption__note'>{note}</p>
									</div>
								</div>
							);
						}}
						width={2}
					/>
				</Flex>
				{isBlankState ? <Divider color='neutral-extra-weak' /> : undefined}
			</LayoutBox>
		</Flex>,
		<>
			<div className='NHPTemplateListHeader__text'>
				<h4 className='NHPTemplateListHeader__header'>
					<span className='NHPTemplateListHeader__icon'>
						<Icon brand={true} name='fab-badge-id-20x18' />
					</span>
					<span>{header}</span>
				</h4>
				<p className='NHPTemplateListHeader__subHeader'>{subheader}</p>
				<div className='NHPTemplateListHeader__actions'>
					<div>
						<a className='fab-Button fab-Button--small fab-Button--outline' href={creationLink} rel='noopener noreferrer'>
							<span className='fab-Button__icon--left'>
								<Icon brand={true} name='fab-circle-plus-14x14' />
							</span>
							{$.__('New Template')}
						</a>
					</div>
					<div>
						<Dropdown
							buttonSettings={{
								icon: 'fab-gear-15x16',
								outline: true,
								size: 'small',
								secondary: true,
							}}
							items={[
								{
									note: $.__(
										'Update the email that is sent out to existing employees when someone new joins. This will apply across all templates.'
									),
									text: $.__('Edit “Get to Know You” Email'),
									value: '/settings/tasklists/onboarding/welcome_email_template/',
								},
							]}
							onSelect={handleSelect}
							renderOptionContent={(item: MenuItem): ReactElement => {
								const { note, text } = item;
								return (
									<div className='fab-MenuOption__content NHPEmailLinkOption'>
										<div className='fab-MenuOption__row'>{text}</div>
										<div className='fab-MenuOption__row'>
											<p className='fab-MenuOption__note'>{note}</p>
										</div>
									</div>
								);
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
