import { createStyles, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default makeStyles(() => {
	return createStyles({
		fileListContentWrapper: {
			height: ifFeature('encore', '', 'calc(50vh - 89px)'),
			maxHeight: '415px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			position: 'relative'
		},
		scrollableFileList: {
			overflowY: 'auto',
			width: '100%',
			listStyle: 'none',
			height: '100%',
		},
		blankStateWrapper: {
			position: 'absolute',
			overflow: 'hidden',
			top: '110px',
			width: '100%',
		},
	});
});
