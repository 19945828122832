import {
	Fragment,
} from 'react';
import {
	makeStyles,
} from '@bamboohr/fabric';

const useStyles = makeStyles(({ palette, typography }) => ({
	datesAndRate: {
		color: palette.grey[1000],
	},
	detailContainer: {
		display: 'flex',
		color: palette.grey[800],
		fontSize: typography.fabricFontSize('teenie'),
	},
	labels: {
		marginRight: '18px',
	},
}));

export function WageOverlapDetail(props) {
	const {
		startDate,
		endDate,
		payRate,
		payType,
		overtime,
	} = props;
	const classes = useStyles();

	return (
		<Fragment>
			<p className={ classes.datesAndRate }>
				{ startDate } - { endDate || $.__('Present') }: { payRate }
			</p>

			<div className={ classes.detailContainer }>
				<div className={ classes.labels }>
					<p>{ $.__('Pay Type') }:</p>
					<p>{ $.__('Overtime') }:</p>
				</div>
				<div>
					<p>{ payType || '--' }</p>
					<p>{ overtime || '--' }</p>
				</div>
			</div>
		</Fragment>
	);
}
