import { Fragment, useState } from 'react';
import {isEnabled} from 'FeatureToggle.util'
import {EmployeeListModal} from 'employee-list-modal'

const isDeepLinkEnabled = isEnabled('ElfDeepLinking')

export function PeopleCount(props) {
	const {
		count,
		listId,
		statusId,
		inactiveCount,
		type,
		title
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false)

	const finalCount = type === 'terminated' ? inactiveCount : count;
	const tab = type === 'terminated' ? 2 : 1;
	const href = isDeepLinkEnabled ? null : `/employees/?tab=${ tab }&lid=${ listId }&lidval=${ statusId }`;

	return (
		<Fragment>
			{ finalCount > 0
				? (
					<a
						href={ href }
						onClick={isDeepLinkEnabled ? () => setIsModalOpen(true) : null}
						rel="noopener noreferrer"
						target="_blank"
					>
						{ finalCount }
					</a>
				)
				: finalCount }
				{isDeepLinkEnabled && (
					<EmployeeListModal 
						isOpen={isModalOpen}
						listId={listId}
						listValueId={statusId}
						onClose={() => setIsModalOpen(false)}
						subtitle={title}
						title={$.__('Employment Status')}
					/>
				)}
		</Fragment>
	);
}
