import moment from 'moment';
import {
	showSlidedown,
} from 'Alerts.mod';
import {
	getMappingItemSaveObject,
	saveStepData,
} from '../../utils/utilities';

import {
	CompanyDeductionsMetadata,
	GroupsById,
	ItemToMap,
	GetDataResponse,
	SaveFieldObject,
	SectionType,
	FieldObject,
	DatabaseString,
} from '../../utils/interfaces';

function getSystemChosen(bambooRowId: string|number, traxRowId: string|number): DatabaseString {
	if (bambooRowId && traxRowId) {
		return 'both';
	}
	if (bambooRowId && !traxRowId) {
		return 'trax';
	}
	if (traxRowId && !bambooRowId) {
		return 'bamboo';
	}
}

export function saveDatesForOverlapRecords(
	isMappingTool: boolean,
	erroredField: FieldObject|ItemToMap<CompanyDeductionsMetadata>,
	overlapRecords: Array<CompanyDeductionsMetadata>,
	groupsById: GroupsById,
	sectionType: SectionType,
	setSheetProcessing: (processing: boolean) => void,
	setSheetOpen: (open: boolean) => void
): void {
	setSheetProcessing(true);
	const saveFields: Array<SaveFieldObject> = [];
	const isCompanyDeductions = sectionType === 'companyDeductions';
	const saveUrl = isCompanyDeductions ? 'company_deductions' : 'employee_deductions';

	overlapRecords.forEach((record: CompanyDeductionsMetadata, index: number) => {
		if (index === 0) {
			return;
		}
		const {
			id,
			clientId,
			bambooRowId,
			traxRowId,
			startDate,
			endDate,
		} = record;
		const systemChosen = getSystemChosen(bambooRowId, traxRowId);
		const nullEndDate = !endDate || endDate === 'Present';
		saveFields.push(
			{
				id,
				clientId,
				bambooRowId,
				traxRowId,
				fieldName: isCompanyDeductions ? 'start_date' : 'employee_deduction_start_date',
				value: moment(startDate, moment.defaultFormat).format('YYYY-MM-DD'),
				systemChosen,
			},
			{
				id,
				clientId,
				bambooRowId,
				traxRowId,
				fieldName: isCompanyDeductions ? 'end_date' : 'employee_deduction_end_date',
				value: nullEndDate ? null : moment(endDate, moment.defaultFormat).format('YYYY-MM-DD'),
				systemChosen,
			}
		);
	});

	if (isMappingTool && 'createValue' in erroredField) {
		const {
			startDate,
			endDate,
		} = overlapRecords[0];
		const {
			createValue,
			value,
			originalDatabase,
			group,
		} = erroredField;

		if (typeof createValue === 'object' && 'StartDate' in createValue && 'EndDate' in createValue) {
			createValue.StartDate = moment(startDate, moment.defaultFormat).format('YYYY-MM-DD');
			createValue.EndDate = endDate ? moment(endDate, moment.defaultFormat).format('YYYY-MM-DD') : null;
			erroredField.createValue = createValue;
		}
		if (typeof createValue === 'object' && 'DeductStartDate' in createValue && 'DeductEndDate' in createValue) {
			createValue.DeductStartDate = moment(startDate, moment.defaultFormat).format('YYYY-MM-DD');
			createValue.DeductEndDate = endDate ? moment(endDate, moment.defaultFormat).format('YYYY-MM-DD') : null;
			erroredField.createValue = createValue;
		}

		saveFields.push(getMappingItemSaveObject(erroredField, value, originalDatabase, groupsById[group].clientId, null, sectionType));
	} else if ('id' in erroredField) {
		const {
			startDate,
			endDate,
		} = overlapRecords[0];
		const {
			id,
			group,
		} = erroredField;
		const {
			clientId,
			bamboo: {
				id: bambooRowId,
			},
			trax: {
				id: traxRowId,
			},
		} = groupsById[group];
		const systemChosen = getSystemChosen(bambooRowId, traxRowId);

		saveFields.push(
			{
				id: `${ id }_startDate`,
				systemChosen,
				clientId,
				bambooRowId,
				traxRowId,
				fieldName: 'start_date',
				value: moment(startDate, moment.defaultFormat).format('YYYY-MM-DD'),
			},
			{
				id: `${ id }_endDate`,
				systemChosen,
				clientId,
				bambooRowId,
				traxRowId,
				fieldName: 'end_date',
				value: moment(endDate, moment.defaultFormat).format('YYYY-MM-DD'),
			}
		);
	}

	saveStepData(saveUrl, { fields: saveFields })
		.then((response: GetDataResponse) => {
			const {
				status,
			} = response;
			if (status === 200) {
				showSlidedown($.__('Dates saved successfully'), 'success');
				setSheetOpen(false);
			} else {
				showSlidedown($.__('Uh oh...something went wrong saving your changes. Please try again.'), 'error');
			}
			setSheetProcessing(false);
		})
		.catch(() => {
			showSlidedown($.__('Uh oh...something went wrong saving your changes. Please try again.'), 'error');
			setSheetProcessing(false);
		});
}
