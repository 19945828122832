import { HeaderTmplData } from '../../types';
import React, { ReactElement } from 'react';
import {
	AccessSettingsButton,
	HeaderGoogleButton,
	PayrollAdminSettings,
	HeaderAddEmployeeButton,
} from '../access-level-header-buttons/index';

export function renderHeaderButtons(permission: HeaderTmplData, employeeModal: () => void): ReactElement[] {
	const buttons: ReactElement[] = [];
	if (permission.showAddBtn) {
		buttons.push(<HeaderAddEmployeeButton employeeModal={() => employeeModal()} />);
	}
	if (permission.hasGoogle) {
		buttons.push(<HeaderGoogleButton />);
	}
	if (permission.showSettingsBtn) {
		buttons.push(<AccessSettingsButton headerData={permission} />);
	}
	if (permission.levelType === 'payroll-admin') {
		buttons.push(<PayrollAdminSettings />);
	}
	return buttons;
}
