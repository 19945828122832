import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => ({
	navBorder: {
		borderRight: 'none',
	},
	navContainer: {
		marginLeft: '-15px',
	},
	countFont: {
		color: palette.grey[700],
		fontSize: typography.fabricFontSize('teenie'),
	},
	labelFont: {
		color: palette.grey[800],
		fontSize: typography.fabricFontSize('medium'),
	},
}));
