export enum EnrollmentStatus {
	enrolled = 'Enrolled',
	waived = 'Waived',
	withdrew = 'Withdrew',
	terminated = 'Terminated',
	notEligible = 'Ineligible',
	eligible = 'Eligible',
}

export enum EnrollmentStatusTab {
	enrolled,
	waived,
	eligible
}
