import { useState } from 'react';
import { BodyText, InlineMessageStatusType, InlineMessage, LayoutBox, List, SelectField } from '@bamboohr/fabric';

export function ChangeOwner({ users }: { users: { text: string; value: string }[] }) {
	const [selectedValue, setSelectedValue] = useState();
	return (
		<>
			<LayoutBox marginBottom={3}>
				<SelectField
					items={users}
					label={$.__('New Account Owner')}
					name='userId'
					note={$.__('This list includes active users currently in the Full Admin group.')}
					onClear={() => {
						setSelectedValue(undefined);
					}}
					onSelect={setSelectedValue}
					required={true}
					value={selectedValue}
					width={8}
				/>
			</LayoutBox>
			<LayoutBox>
				<BodyText color='neutral-extra-strong' size='large' weight='bold'>
					{$.__('The Account Owner is the only person that can...')}
				</BodyText>
				<LayoutBox marginTop={2}>
					<List>
						<List.Item>{$.__('Access the entire account, including the Account page')}</List.Item>
						<List.Item>{$.__('Upgrade, downgrade or change billing information')}</List.Item>
					</List>
				</LayoutBox>
				<LayoutBox marginTop={3}>
					<InlineMessage
						status={InlineMessageStatusType.warning}
						title={<LayoutBox width='800px'>{$.__('Once you make this change, you will no longer be the Account Owner.')}</LayoutBox>}
					/>
				</LayoutBox>
			</LayoutBox>
		</>
	);
}
