import { BenefitPlanListContract } from '../types/contracts';
import { useBenefitsSettingsStore } from '../data/benefits-settings-store';

interface HeadlineProps {
	rowData: BenefitPlanListContract;
}

/**
 * @deprecated remove after encore in GA
 */
export const HeadLine = (props: HeadlineProps): JSX.Element => {
	const { rowData } = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	return <>{$.__('Are you sure that you want to delete this plan?')}
		<p
			className="DeleteModal__text">{isBenefitsAdminEnabled
			? $.__('%1$s employees will be unenrolled, and %2$s pending elections will be deleted from %3$s enrollment windows.', rowData.status.enrolled, rowData.status.electionCount, rowData.status.enrollmentWindowCount)
			: $.__('%1$s employees will be unenrolled.', rowData.status.enrolled)}
		</p>
	</>
}
