import { render } from 'base/wrapped-render';
import Ctrl, {
	onReady
} from 'ctrl.deco';
import {
	toPascalCase
} from 'BambooHR.util';

import 'InfoDisplay.mod';

import { TrialExpired } from './trial-expired.react';

import './calendar-feeds';

import PrivateContent from 'PrivateContent.mod';

import './aca';
import './api-key-manager';
import './change-owner';
import './billing';
import './time-tracking';
import './translations';
import './two-step-login';
import './general';
import './webhooks';

import './styles.styl';

@Ctrl('/settings/account/expired.php')
class TrialExpiredCtrl {
	@onReady()
	_readyHandler() {
		const trialExpiredRoot = document.getElementById('trialExpiredRoot');

		if (trialExpiredRoot) {
			render(<TrialExpired />, trialExpiredRoot);
		}
	}
}

@Ctrl('/settings/account/:page(time_tracking|two_step_login|api_keys_manager|early_access_hub)(/micro)?')
class SettingsAccountCtrl {
	get subCtrl() {
		return Ctrl.getCtrl(`SettingsAccount${ toPascalCase(this.page) }Ctrl`) || {};
	}

	get mainTmpl() {
		return this.subCtrl.mainTmpl;
	}

	get mainTmplData() {
		return this.subCtrl.mainTmplData;
	}

	get subNavTmpl() {
		return this.subCtrl.subNavTmpl;
	}

	get onMainReady() {
		return this.subCtrl.onMainReady;
	}

	get page() {
		return this._params.page;
	}

	get title() {
		return this.subCtrl.title;
	}
}
