import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => ({
	sectionHeader: {
		display: 'flex',
		alignItems: 'center',
		'& h5': {
			...typography.h5,
			marginLeft: '8px',
		},
		fill: palette.primary.main
	},
	upcomingSurveysDescription: {
		marginLeft: '24px',
		...typography.body2,
		marginBottom: '24px',
		color: palette.gray[800],
	}
}));
