import { FilterSelections } from '../../types';

export const hasEligibilityFiltersCount = (filterSelections: FilterSelections): boolean => {
	if (!filterSelections) {
		return false;
	}
	const { employmentStatus, department, division, location, payType } = filterSelections;

	const employmentStatuses = employmentStatus.length;
	const departments = department.length;
	const divisions = division.length;
	const locations = location.length;
	const payTypes = payType.length;
	const total = employmentStatuses + departments + divisions + locations + payTypes;

	return Boolean(total);
};
