import { Message, ExternalLink } from '@bamboohr/utils/lib/message';

import { makeStyles } from '@bamboohr/fabric';

import { TURN_ON_AUTOSYNC_SUPPORT_DOC_URL } from './consts';

const STYLES = makeStyles(({ typography }) => ({
	boldText: {
		fontWeight: typography.fontWeightBold,
	},
}));

export const QuestionsSection = () => {
	const classes = STYLES();
	return (
		<>
			<h5>
				{ $.__('Still have questions?') }
			</h5>
			<div>
				<Message
					link={ ExternalLink(TURN_ON_AUTOSYNC_SUPPORT_DOC_URL) }
					text={ $.__('If you have any questions regarding this new experience, please review our payroll migration documentation [here].') }
				/>
				<Message
					em={ { tag: 'span', className: classes.boldText } }
					text={ $.__(' Feel free to also reach out to your friendly payroll integration specialist at *payrollmigration@bamboohr.com*.') }
				/>
			</div>
		</>
	);
};
