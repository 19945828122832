import { Typography, useTheme, BodyText } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export interface ProjectNameErrorMessageProps{
    children: string;
}

export function ProjectNameErrorMessage({ children }: ProjectNameErrorMessageProps){
    const { palette } = useTheme();
    return ifFeature('encore',
        <BodyText color="error">
            {children}
        </BodyText>,
        <Typography color={palette.error.main}>
            {children}
        </Typography>
    );
}
