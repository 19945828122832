import classnames from 'classnames';
import {
	ReactElement,
} from 'react';

import {
	useStyles,
} from './employee-record-mapping-metadata.styles';
import {
	EmployeeRecordMappingMetadataProps,
} from '../../utils/interfaces';

export function EmployeeRecordMappingMetadata(props: EmployeeRecordMappingMetadataProps): ReactElement {
	const {
		data,
	} = props;

	const styles = useStyles();

	if (!data) {
		return <div className={ styles.root }></div>;
	}

	const {
		employmentStatus,
		jobTitle,
	} = data;

	return (
		<div className={ styles.root }>
			<div className={ styles.rowContainer }>
				<p>{ $.__('Job Title') }:</p>
				<p>{ $.__('Employment Status') }:</p>
			</div>
			<div className={ classnames(styles.rowContainer, styles.values) }>
				<p>{ jobTitle || '--' }</p>
				<p>{ employmentStatus || '--' }</p>
			</div>
		</div>
	);
}
