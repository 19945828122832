export enum DisabilityTypeEnum {
	longTermDisability = 'long_term_disability',
	shortTermDisability = 'short_term_disability',
}

export enum DisabilityDisbursementTypeEnum {
	fixed = 'fixed',
	other = 'other',
	percentage = 'percentage',
}
