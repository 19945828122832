import { createStyles, Icon, makeStyles, Table } from '@bamboohr/fabric';

import './coverage-cost-table.styl';
import { EmployeePaysCell } from './employee-pays-cell';
import { TotalCostCell } from './total-cost-cell';
import { CoverageTableRow } from './types';
import { getCompanyPaysValue } from './utils';
import { isFirstGroup } from '../../../../utils';
import { useValidationContext } from '../../../../../context';
import { CostTypeEnumContract, CoverageCostValues, Currency, PlanState, WizardValues } from '../../../../../types';
import { generateTotalCostErrorKey } from '../../../../../utils';
import { CoverageTypes } from '../../../../../../types';


const useStyles = makeStyles(({ palette }) => {
	return createStyles({
		warningDiv: {
			display: 'flex',
			alignItems: 'center',
		},
		warningText: {
			color: palette.warning.main,
			fontSize: 14,
		},
	});
});

interface CoverageCostTableProps {
	coverageCosts: CoverageCostValues[];
	currencyWarning?: boolean;
	currencies: Currency[];
	groupId: string | number;
	groupIndex: number;
	handleCurrencyCodeChange: (currencyCode: string) => void;
	isBenefitsAdminEnabled: boolean;
	supportedCoverages: CoverageTypes;
	updateEmployeeCost: (value: number, groupIndex: number, coverageLinkId: string) => void;
	updateEmployeeCostType: (newValue: CostTypeEnumContract) => void;
	planState: PlanState;
	updateTotalCost: (newTotalCost: number, coverageLinkId: string) => void;
	wizardValues: WizardValues;
}

export function CoverageCostTable(props: CoverageCostTableProps): JSX.Element {
	const {
		coverageCosts,
		currencyWarning = false,
		groupId,
		updateEmployeeCostType,
		updateEmployeeCost,
		updateTotalCost,
		handleCurrencyCodeChange,
		isBenefitsAdminEnabled,
		supportedCoverages,
		currencies,
		groupIndex,
		planState,
		wizardValues,
	} = props;

	const { currencyCode, employeeCostType, coverageLevels } = wizardValues;

	const { setSpecialErrors, specialErrors } = useValidationContext();

	const classes = useStyles();

	const columns = [
		{
			cell: (row: CoverageTableRow) => row.coverageLevel.name,
			header: $.__('Coverage Level'),
			key: 'coverageLevel',
			verticalAlign: true,
		},
		{
			cell: (row: CoverageTableRow) => {
				const errorKey = generateTotalCostErrorKey(groupId, row.coverageLevelLinkId);

				if (row.currencyWarning) {
					return (
						<div className={`${classes.warningDiv} CoverageCostTable__currencyWarning`}>
							<Icon name='fab-triangle-exclamation-13x11' />
							<div className={classes.warningText}>{$.__('Employees paid by BambooHR Payroll must use USD')}</div>
						</div>
					);
				}

				return (
					<TotalCostCell
						currencies={currencies}
						currencyCode={currencyCode}
						currencyWarning={currencyWarning}
						error={specialErrors[errorKey]}
						handleCurrencyCodeChange={handleCurrencyCodeChange}
						isFirstGroup={isFirstGroup(groupIndex)}
						name={errorKey}
						planState={planState}
						row={row}
						setSpecialErrors={setSpecialErrors}
						updateTotalCost={updateTotalCost}
					/>
				);
			},
			header: $.__('Total Cost') + (groupIndex > 0 ? $.__(' (Set in group 1)') : ''),
			key: 'totalCost',
		},
		{
			cell: (row: CoverageTableRow) => {
				if (row.currencyWarning) {
					return <span />;
				}

				return (
					<EmployeePaysCell
						coverageLevel={row.coverageLevel}
						currencies={currencies}
						employeeCost={row.employeeCost}
						groupId={groupId}
						groupIndex={groupIndex}
						updateEmployeeCost={updateEmployeeCost}
						updateEmployeeCostType={updateEmployeeCostType}
						wizardValues={wizardValues}
					/>
				);
			},
			header: $.__('Employee Pays'),
			key: 'employeePays',
		},
		{
			cell: (row: CoverageTableRow) => {
				if (row.currencyWarning) {
					return <span />;
				}

				return (
					<div className='CoverageCostTable__companyPays'>{getCompanyPaysValue(row, employeeCostType, currencies, currencyCode)}</div>
				);
			},
			header: $.__('Company Pays'),
			key: 'companyPays',
			verticalAlign: true,
		},
	];

	const generateRows = (): CoverageTableRow[] => {
		const rows: CoverageTableRow[] = [];

		let coverageLevelRows;

		if (currencyWarning) {
			const currencyWarningRow = {
				currencyWarning,
				linkId: '',
				isDefault: false,
				isSelected: true,
				name: '',
				description: '',
				totalCost: null,
			};

			coverageCosts.push({ coverageLevelLinkId: '', employeeCost: null });
			coverageLevelRows = [...coverageLevels, currencyWarningRow];
		} else if (isBenefitsAdminEnabled) {
			const supportedCoverageLevels = coverageLevels.filter((coverage) => Object.keys(supportedCoverages).includes(coverage.linkId));
			coverageLevelRows = [...supportedCoverageLevels];
		} else {
			coverageLevelRows = [...coverageLevels];
		}

		coverageLevelRows.forEach((coverageLevel) => {
			if (coverageLevel.isSelected) {
				let coverageCost: CoverageCostValues = coverageCosts.find((coverageCost) => {
					return coverageCost.coverageLevelLinkId === coverageLevel.linkId;
				});
				if (!coverageCost) {
					coverageCost = {coverageLevelLinkId: coverageLevel.linkId as string, employeeCost: null}
					wizardValues.eligibilityGroups[groupIndex].coverageCosts.unshift(coverageCost)
				}
				if (coverageCost !== undefined) {
					rows.push({
						...coverageCost,
						coverageLevel: { ...coverageLevel },
						currencyWarning: coverageLevel.currencyWarning || false,
					});
				}
			}
		});

		return rows;
	};

	return (
		<Table
			caption={$.__('How much will they pay?')}
			columns={columns}
			rowKey={(row: CoverageTableRow) => row.coverageLevelLinkId}
			rows={generateRows()}
		/>
	);
}
