import {
	useState,
	useMemo,
	useEffect,
} from 'react';

import { Icon, IconV2 } from '@bamboohr/fabric';

import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	useStateContext,
} from '../state';

import {
	_bem,
} from '../util';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';


export default function AdvancedOptions() {
	const [{
		accessLevel,
		canCombine,
		editType,
		expandAdvanced = false,
		multiple,
		selectedEmployees,
	}, {
		updateEditType,
	}] = useStateContext();
	const [isOpen, setIsOpen] = useState(expandAdvanced);
	const currentLevels = useMemo(() => {
		if (selectedEmployees.length !== 1) {
			return $.__(`Current Access Level(s)`);
		}

		return selectedEmployees[0].accessLevels
			.map(({ name }) => name)
			.join(', ');
	}, [selectedEmployees]);
	const isVisible = useMemo(() => (
		canCombine &&
		(multiple || selectedEmployees.length > 0) &&
		!selectedEmployees.some(({ canCombine }) => !canCombine)
	), [canCombine, selectedEmployees, multiple]);

	useEffect(() => {
		if (editType) {
			return;
		}

		if (!isVisible) {
			updateEditType({ editType: 'change' });
		}
	}, [accessLevel.type, isVisible, editType, updateEditType]);

	return isVisible && (
		<div className={ _bem('advancedOptions') }>
			{ !expandAdvanced && (
				<button
					className="fab-TextButton fab-link"
					onClick={ () => setIsOpen(!isOpen) }
					type="button"
				>
					<span
						className={ `fab-TextButton__icon fab-TextButton__icon--left ${ _bem('advancedOptionsToggleIco', { closed: !isOpen }) }` }
					>
						{ifFeature('encore', <IconV2 name='caret-down-solid' size={12} />, <Icon name="fab-dropdown-caret-9x5" />)}
					</span>
					{ $.__('Advanced Options') }
				</button>
			) }
			<div
				className={ _bem('advancedOptionsContainer', { closed: !isOpen }) }
			>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<div className="fab-RadioGroup">
							{ !expandAdvanced && (
								<legend className="fab-RadioGroup__legend">
									{ $.__n(`For Employee I'm Adding`, `For the Employees I'm Adding`, multiple ? 2 : 1) }
								</legend>
							) }
							<div className="fab-Radio">
								<input
									checked={ editType === 'change' }
									className="fab-Radio__input"
									id="SettingsAccessLevelsAddEmployeeModal__advancedOption--change"
									name="editType"
									onChange={ () => updateEditType({ editType: 'change' }) }
									type="radio"
									value="change"
								/>
								<label
									className="fab-Radio__label"
									htmlFor="SettingsAccessLevelsAddEmployeeModal__advancedOption--change"
								>
									<span>
										<Message
											params={ [currentLevels, accessLevel.name] }
											text={ $._(`**Change** Access Level from **{1}** to **{2}**`) }
										/>
									</span>
								</label>
							</div>
							<div className="fab-Radio">
								<input
									checked={ editType === 'combine' }
									className="fab-Radio__input"
									id="SettingsAccessLevelsAddEmployeeModal__advancedOption--combine"
									name="editType"
									onChange={ () => updateEditType({ editType: 'combine' }) }
									type="radio"
									value="combine"
								/>
								<label
									className="fab-Radio__label"
									htmlFor="SettingsAccessLevelsAddEmployeeModal__advancedOption--combine"
								>
									<span>
										<Message
											params={ [currentLevels, accessLevel.name] }
											text={ $._(`Combine **{1}** with the access of **{2}**`) }
										/>
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
