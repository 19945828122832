import { Question } from "../types";

interface State {
    currentData: readonly Question[];
    lastSavedData: readonly Question[];
    loading: boolean;
    scheduledQuestionId: number;
    canSave: boolean;
    showDone: boolean;
}

interface UpdatePayload {
    questions: readonly Question[];
    surveyId: number;
}

interface Action {
    type: 'SELECT_QUESTION' | 'NEVER_ASK'| 'ENABLE' | 'UPDATE_DATA' | 'START_LOADING' | 'CLEAR_SELECTION',
    payload?: number | UpdatePayload
}

type Reducer<T> = (state: T, {type, payload}: Action) => T;

/**
 * reducer
 * @param state The previous state.
 * @param action Has type and payload all actions that update questions use the index not id of question.
 * @returns The updated state.
 */

const reducer: Reducer<Omit<State, 'scheduledQuestionId' | 'canSave' | 'showDone'>> = (state, {type, payload}) => {
    const { currentData } = state;
    switch(type) {
        case 'SELECT_QUESTION':
            return {
                ...state,
                currentData: currentData.map((d, i) => ({ ...d, isScheduledQuestion: i === payload }))
            }
        case 'NEVER_ASK':
            return {
                ...state,
                currentData: currentData.map((d, i) => ({ 
                    ...d, 
                    isScheduledQuestion: (i === payload && currentData[payload].isScheduledQuestion) ? false : d.isScheduledQuestion,
                    isQuestionEnabled: i === payload ? false : d.isQuestionEnabled,
                }))
            }
        case 'ENABLE':
            return {
                ...state,
                currentData: currentData.map((d, i) => ({...d, isQuestionEnabled: i === payload || d.isQuestionEnabled}))
            }
        case 'START_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'CLEAR_SELECTION':
            return {
                ...state,
                currentData: currentData.map(d => ({ 
                    ...d, 
                    isScheduledQuestion: false
                }))
            }
        case 'UPDATE_DATA':
            return {
                currentData: (payload as UpdatePayload).questions,
                lastSavedData: (payload as UpdatePayload).questions,
                loading: false
            }
        default: 
            return state;
    }
}

/**
 * Wraps reducer and computes any computed properties, after a state update
 * @param state current state
 * @param action action to perform
 * @returns new state
 */
const computedPropertyReducer: Reducer<State> = (state, action) => {
    const newState = reducer(state, action);
    const { currentData, lastSavedData } = newState;
    const canNeverAsk = currentData.reduce((prev, {isQuestionEnabled}) => prev + (isQuestionEnabled ? 1 : 0), 0) > 1;
    const scheduledQuestionId = currentData.find(({isScheduledQuestion}) => isScheduledQuestion)?.questionId;
    const newCurrentData = currentData.map((d) => ({
        ...d,
        showActionButton: canNeverAsk || !d.isQuestionEnabled
    }));
    return {
        ...newState,
        currentData: newCurrentData,
        scheduledQuestionId,
        canSave: scheduledQuestionId !== undefined,
        showDone: lastSavedData === currentData
    }
}

export default computedPropertyReducer;
