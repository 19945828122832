import Ajax from '@utils/ajax';
import { toQueryString } from '@utils/url';


const ERROR_SAVING = $.__('Unable to update sort order.');
const RESPONSE_ERROR = $.__('Uh-oh. Something went wrong, please try again!');
const GENERAL_ERROR = $.__('An error occurred');

/**
 * Update the sort order for Statuses
 *
 * @param {array}	data	The Serialized array from Jquery Sortable.
**/
export const updateStatusesSortOrder = (data) => {
	return Ajax.post('/settings/jobs/?update_sort_order=1', data)
		.then((response) => {
			const { data } = response;
			if (!data.success) {
				window.setMessage(ERROR_SAVING, 'error');
				reloadPage();
			}
			return data;
		})
		.catch((error) => {
			window.setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Update the sort order for Email Templates
 *
 * @param {array}	data	The Serialized array from Jquery Sortable.
 **/
export const updateEmailsSortOrder = (data) => {
	return Ajax.post('/settings/jobs/templates.php/emailTemplatesSort', data)
		.then((response) => {
			const { data } = response;
			if (!data.success) {
				window.setMessage(ERROR_SAVING, 'error');
				reloadPage();
			}
			return data;
		})
		.catch((error) => {
			window.setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Delete email/offer Template
 *
 * @param {object}	data	data for adding/Editing status
 *
 * @return {object}  The Success response
 **/
export const deleteTemplate = (type, id) => {
	return Ajax.delete(`/settings/jobs/templates.php/${type}?id=${id}`)
		.then(function (response) {
			const { data } = response;
			if (data.success) {
				return data;
			}
			window.setMessage(RESPONSE_ERROR, 'error');
		})
		.catch(function (error) {
			setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Delete a Candidate source
 *
 * @param {int}	id	The id of the Source to be deleted
 *
 * @return {object}  The Success response
 **/
export const deleteSource = (data) => {
	return Ajax.delete(`/settings/jobs/sources`, data)
		.then(function (response) {
			const { data } = response;
			if (data.hasOwnProperty('success') && !data.success) {
				window.setMessage(data.error || RESPONSE_ERROR, 'error');
				return;
			}
			return data;
		})
		.catch(function (error) {
			window.setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Add a Candidate source
 *
 * @param {string}	name	The name of the source to be added.
 *
 * @return {object}  The Success response
 **/
export const addSource = (name) => {
	const query = toQueryString({ name });
	return Ajax.post(`/settings/jobs/sources`, query)
		.then(function (response) {
			const { data } = response;
			if (data.hasOwnProperty('success') && !data.success) {
				window.setMessage(data.error || RESPONSE_ERROR, 'error');
				return;
			}
			return data;
		})
		.catch(function (error) {
			setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Add/Edit a status
 *
 * @param {object}	data	data for adding/Editing status
 *
 * @return {object}  The Success response
 **/
export const addEditStatusEndpoint = (data) => {
	return Ajax.post(`/settings/jobs/`, toQueryString(data))
		.then(function (response) {
			const { data } = response;
			if (data.error) {
				throw new Error(data.error);
			}
			if (data.ret == 'SAVED') {
				return data;
			}
		})
		.catch((error) => {
			window.setMessage(error?.message ? error.message : RESPONSE_ERROR, 'error');
			throw new Error();
		});
};

/**
 * Remove a Status
 *
 * @param {object}	statusId 	The Status Id to be removed.
 *
 * @return {object}  The Success response
 **/
export const removeStatus = (statusId) => {
	const data = {
		disable_status: true,
		status_id: statusId,
	};
	return Ajax.post(`/settings/jobs/`, toQueryString(data))
		.then(function (response) {
			const { data } = response;
			if (data.ret == 'DISABLED') {
				window.setMessage(data.msg, 'success');
				return data;
			}
			window.setMessage(RESPONSE_ERROR, 'error');
		})
		.catch((error) => {
			window.setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Re-enable a disabled default status
 *
 * @param {object}		statuses				Object containing the applicantDisabledStatuses
 * @param {array}		checked					Array of the checked values from the modal
 * @param {function}	enableDefaultStatus		Callback function to update the state for `ui-src/public/settings/jobs/statuses/table/index.js`
 **/
export const enableDefaultStatus = (formData) => {
	return Ajax.post(`/settings/jobs/`, toQueryString(formData))
		.then(function (response) {
			const { data } = response;
			if (data.ret == 'ENABLED') {
				window.setMessage(data.msg, 'success');
				return data;
			}
			window.setMessage(RESPONSE_ERROR, 'error');
		})
		.catch((error) => {
			window.setMessage(GENERAL_ERROR, 'error');
		});
};

/**
 * Add a new status to the rowData
 *
 * @param {array}	rowData		Array of the current statuses
 * @param {object}	newStatus	The new status to be added
 *
 * @return {array}  The updated rowData Array
**/
export const addRowData = (rowData, newStatus) => {
	const updatedData = rowData.map((data) => {
		if (newStatus && data.groupId === newStatus.applicantStatusGroupId) {
			data.rowData.push(newStatus);
		}
		return data;
	});
	return updatedData;
};

/**
 * Remove a status from the rowData
 *
 * @param {array}	rowData		Array of the current statuses
 * @param {int}		deleteId	The id of the status to be removed
 *
 * @return {array}  The updated rowData Array
**/
export const deleteRowData = (rowData, deleteId) => {
	const updatedData = rowData.map((data) => {
		data.rowData = data.rowData.filter(
			(item) => {
				if (item.id !== deleteId) {
					return item;
				}
			}
		);
		return data;
	});
	return updatedData;
};

/**
 * edit a status from the rowData
 *
 * @param {array}	rowData		Array of the current statuses
 * @param {object}	editStatus	An Object with the updated values.
 *
 * @return {array}  The updated rowData Array
**/
export const editRowData = (rowData, editStatus) => {
	const updatedData = rowData.map((data) => {
		data.rowData.map((row) => {
			if (row.id == editStatus.id) {
				row.name = editStatus.name;
			}
		});
		return data;
	});
	return updatedData;
};

/**
 * Remove a template from the rowData
 *
 * @param {array}	rowData		Array of the templates
 * @param {int}		deleteId	The id of the template to be removed
 *
 * @return {array}  The updated rowData Array
**/
export const deleteTemplateData = (rowData, deleteId) => {
	const updatedData = rowData.filter(
		(item) => {
			if (item.id !== deleteId) {
				return item;
			}
		}
	);
	return updatedData;
};


/**
 * Reload Applicant Statuses Page
*/
export const reloadPage = () => {
	window.Settings.loadPjaxUrl(location.pathname);
	window.BambooHR.Modal.setState({isOpen: false});
};
