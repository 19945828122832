import { render } from 'base/wrapped-render';
import { isEnabled } from 'FeatureToggle.util';
import { EmployeesNavigationPopover } from './employees-navigation-popover';

// function so we can use the popover in the legacy time tracking settings code
export function createEmployeesNavigationPopover(anchor) {
	const timeTrackingTrialEnabled = isEnabled('time_tracking_trial');
	// have to settimeout to give the subnav time to load in
	if (timeTrackingTrialEnabled) {
		setTimeout(() => {
			render(<EmployeesNavigationPopover anchor={ anchor } />, document.getElementById('js-employees-navigation-popover-root'));
		}, 500);
	}
}
