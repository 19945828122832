/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
import c from 'classnames';

import { SectionStyledBox } from '../../../section-styled-box';

export function StateWithholdingContainer(props) {
	const {
		showWithholding,
		usesSectionComponent,
	} = props;

	const classNames = ifFeature('encore',
		c('js-pi-withholdings-state', {
			'StateWithholdingContainer--hidden': showWithholding === false,
		}),
		c('pi-withholdings-state js-pi-withholdings-state', {
			'StateWithholdingContainer--hidden': showWithholding === false,
		})
	);

	return ifFeature(
		'encore',
		<div className={classNames}>
			<SectionStyledBox usesSectionComponent={usesSectionComponent}>
				<div id="js-wholdings-state">
					<div className="js-wholdings-loader circleDotLoader pi-state-loader">
						<div className="loaderCircle1 loaderCircle"></div>
						<div className="loaderCircle2 loaderCircle"></div>
						<div className="loaderCircle3 loaderCircle"></div>
						<div className="loaderCircle4 loaderCircle"></div>
						<div className="loaderCircle5 loaderCircle"></div>
						<div className="loaderCircle6 loaderCircle"></div>
						<div className="loaderCircle7 loaderCircle"></div>
						<div className="loaderCircle8 loaderCircle"></div>
						<div className="loaderCircle9 loaderCircle"></div>
					</div>
				</div>
			</SectionStyledBox>
		</div>,
		<div className={ classNames }>
			<div id="js-wholdings-state">
				<div className="js-wholdings-loader circleDotLoader pi-state-loader">
					<div className="loaderCircle1 loaderCircle"></div>
					<div className="loaderCircle2 loaderCircle"></div>
					<div className="loaderCircle3 loaderCircle"></div>
					<div className="loaderCircle4 loaderCircle"></div>
					<div className="loaderCircle5 loaderCircle"></div>
					<div className="loaderCircle6 loaderCircle"></div>
					<div className="loaderCircle7 loaderCircle"></div>
					<div className="loaderCircle8 loaderCircle"></div>
					<div className="loaderCircle9 loaderCircle"></div>
				</div>
			</div>
		</div>,
	);
}
