import { Icon, IconV2 } from '@bamboohr/fabric';
import { useStyles } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type ResponseOptionProps = {
	label: string;
	value: number;
	isSelected: boolean;
	isSmallScreen: boolean;
	handleClick: (value: number) => void;
	disabled: boolean;
};

export const ResponseOption: React.FC<ResponseOptionProps> = ({ handleClick, isSelected, isSmallScreen, label, value, disabled }) => {
	const classes = useStyles({ isSelected, isSmallScreen, disabled });

	return (
		<button
			className={`${classes.container} ${isSmallScreen ? classes.containerRow : classes.containerColumn}`}
			disabled={disabled}
			onClick={() => handleClick(value)}
			type='button'
		>
			<div className={isSmallScreen ? classes.smallCheckContainer : classes.checkContainer}>
				{ifFeature(
					'encore',
					<IconV2 name='check-regular' size={20} />,
					isSmallScreen ? <Icon name='fab-check-14x14' /> : <Icon brand={isSelected && !disabled} name='fab-check-18x18' />
				)}
			</div>
			<p className={isSmallScreen ? classes.smallScreenLabel : classes.label}>{label}</p>
		</button>
	);
};
