import Ajax from '@utils/ajax';

import Ctrl, {
	onClick,
	onPjaxEnd,
	onReady
} from 'ctrl.deco';

import {
	getJsonData
} from 'Data.util';

import {
	showDeleteEmployeeFieldModal
} from '../default/modals';

import {
	showEditEmployeeFieldModal
} from './modals';

import {
    showModal,
    closeModal
} from 'show-modal.mod'

import {
    EmployeeListModal
} from 'employee-list-modal'

import {
	attachTooltips
} from './utils';

@Ctrl('/settings/pay_groups.php*')
class SettingsEmployeeFieldsPayGroupsCtrl {
	constructor() {
		this.payGroupData = {};
	}

	@onPjaxEnd()
	@onReady()
	initPayGroups(e, ctrl) {
		ctrl.payGroupData = getJsonData('#pay-group-data');

		attachTooltips();
	}


    @onClick('.js-historical-count-link')
    addEmployeeListModal(e){
        const target = $(e.target)
        const listId = target.attr('data-list-id')
        const listValueId = target.attr('data-list-value-id')
        const label = target.attr('data-field-name')

        showModal(<EmployeeListModal
            isOpen={true}
            listId={listId}
            listValueId={listValueId}
            onClose={closeModal}
            subtitle={label}
            title={$.__("Pay Groups")}
        />)
    }

	@onClick('#add-button-group')
	addPayGroupModal(e, ctrl) {
		const data = Object.assign({}, {
			action: 'add',
			syncToolId: null
		}, ctrl.payGroupData);

		showEditEmployeeFieldModal($.__("New Pay Group"), data);
	}

	@onClick('.js-edit-pay-group')
	editPayGroup(e, ctrl) {
		const row = $(this).closest('tr');
		const data = Object.assign({}, row.data('json'), {
			action: 'edit'
		});

		showEditEmployeeFieldModal($.__("Edit Pay Group"), Object.assign({}, ctrl.payGroupData, data));
	}

	@onClick('.js-archive-pay-group')
	archivePayGroup() {
		let $row = $(this).closest('tr');
		var data = $row.data('json');
		var name = $row.find('td.title').text().trim();

		Ajax.get('/settings/pay_groups.php?pay_group_count_message=1', data)
			.then(function(info) {
				data.deleteMessage = info === null ? '' : info.deleteMessage;
				data.label = name;
				data.action = 'Delete';
				data.title = $.__('Pay Group');
				data.list_id = null;
				data.totalHistoryCount = null;

				if (data.deleteMessage) {
					data.count = 1;
				} else {
					data.count = 0;
				}

				showDeleteEmployeeFieldModal(data, function () {
					window.BambooHR.Modal.setState({
						isProcessing: true
					}, true);

					const postData = Ajax.prepareData({
						action: 'delete',
						id: data.id
					}, Ajax.prepareDataTypes.FORM_DATA);

					Ajax.post(`/settings/pay_groups.php`, postData)
						.then(() => {
							window.BambooHR.Modal.setState({
								isOpen: false
							});
							$row.remove();
							setMessage($.__('Pay group was deleted.'), 'success')
						})
						.catch(() => setMessage($.__('There was an error deleting the pay group.'), 'error'));
				});

				if (data.deleteMessage && data.syncToolId) {
					window.BambooHR.Modal.setState({
						primaryAction: null
					}, true);
				}
			})
			.catch(() => window.errorFallBack());
	}
}
