import { connect } from 'react-redux';

import { NewPath } from '../components/new-path';
import { createNewCancel, editWorkflow } from '../store/actions';

const mapStateToProps = (state) => {
	const {
		pathTypeChildren,
		pathTypeOptions,
		workflow
	} = state;
	const { pathType = '' } = workflow;

	const availablePathOptions = pathTypeOptions.filter((pathTypeOption) => {
		return !pathTypeOption.used;
	});

	return {
		notInitial: pathTypeChildren.length > 0,

		availablePathOptions,
		pathType
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleCreateNew: (workflowId, newPathSelectedOptions) => {
			dispatch(editWorkflow(workflowId, newPathSelectedOptions));
		},
		handleCreateNewCancel: () => {
			dispatch(createNewCancel());
		}
	};
};

export const NewPathContainer = connect(mapStateToProps, mapDispatchToProps)(NewPath);
