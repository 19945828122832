import benefitsAdminDemoRequest from './benefits-admin-demo-request';
import payrollDemoRequest from './payroll-demo-request';
import performanceDemoRequest from './performance-demo-request';
import timeTrackingDemoRequest from './time-tracking-demo-request';
import timeTrackingEnableRequest from './time-tracking-enable-request';

export default {
	benefitsAdminDemoRequest,
	payrollDemoRequest,
	performanceDemoRequest,
	timeTrackingDemoRequest,
	timeTrackingEnableRequest,
};
