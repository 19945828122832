export const DELETE = $.__('Delete');
export const STATUS_WARNING = $.__('Candidates currently in this status will keep it until their status is changed.');
export const PIPELINE_AND_EMAIL_WARNING = $.__('Pipelines will be changed and automated email rules will stop working.');
export const AUTOMATED_EMAIL_WARNING_STATUS = $.__('Automated email rules using this status will stop working.');
export const PIPELINE_WARNING = $.__('Pipelines will no longer use this status.');
export const PIPELINE_STATUS_WARNING = $.__('Any candidates in this status will be moved to Other.');
export const AUTOMATED_EMAIL_WARNING_TEMPLATE = $.__('Automated email rules sending this template will stop working.');
export const PIPELINE_AND_EMAIL_AND_DQ_WARNING = $.__('Pipelines will be changed and disqualifying questions and automated email rules will stop working.');
export const DISQUALIFYING_QUESTION_WARNING = $.__('Any automated disqualifying questions on your jobs using this status will no longer work.');
export const PIPELINE_AND_DQ_WARNING_STATUS = $.__('Automated disqualifying questions and job pipelines will no longer be able to use this status.');
export const AUTOMATED_EMAIL_AND_DQ_WARNING = $.__('Automated email rules and disqualifying questions will stop working.');