import { FilterSelections } from '../types/wizard-values';

export function isEligibilityFiltersValid(filterSelections: FilterSelections, selectedEmployeeIds: number[]): boolean {
	if (filterSelections.employmentStatus && filterSelections.employmentStatus.length ||
		filterSelections.department && filterSelections.department.length ||
		filterSelections.division && filterSelections.division.length ||
		filterSelections.location && filterSelections.location.length ||
		filterSelections.payType && filterSelections.payType.length ||
		selectedEmployeeIds && selectedEmployeeIds.length) {
		return true;
	}
	return false;
}
