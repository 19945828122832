import classNames from 'classnames';
import { Icon } from '@bamboohr/fabric';
import moment from 'moment';

import {
	EmployeeRecordSectionMetadataNonPayrollEmployee,
	ItemToMap,
	MapItemChangeHandler,
	SectionMetadata,
	SectionType,
	MappingTableColumnObject,
	NormalizedItemsToMap,
	WageMetadata,
	CompanyLocationMetadata,
	CompanyDeductionsMetadata,
	MappingTableRowItem,
	MappingRecordMetadataChangeHandler,
	ResponseRecordObject,
} from '../../utils/interfaces';
import {
	ADDITIONAL_BAMBOO_OPTION_ID_KEY,
	ITEM_TEXT_AND_METADATA,
} from '../../utils/utilities';
import { MappingTableItem } from '../mapping-table-item';

function filterOptions(
	options: Array<MappingTableRowItem>,
	isGrouped: boolean,
	isDeductionsSection: boolean,
	group: string,
	selectedRecordId: string|null,
	selectedRecordIds: Array<string>,
	metadata: WageMetadata|CompanyLocationMetadata|CompanyDeductionsMetadata|EmployeeRecordSectionMetadataNonPayrollEmployee
): Array<MappingTableRowItem> {
	return options.filter((option: MappingTableRowItem): boolean => {
		const {
			group: optionGroup,
			value: optionValue,
			metadata: optionMetadata,
		} = option;
		const isPartOfGroup = isGrouped ? optionGroup === group : true;
		let isSameDeductionType = true;

		if (isDeductionsSection && 'deductionTypeId' in metadata && 'deductionTypeId' in optionMetadata) {
			isSameDeductionType = metadata.deductionTypeId === optionMetadata.deductionTypeId;
		}

		return (
			isSameDeductionType &&
			isPartOfGroup &&
			(selectedRecordIds.indexOf(optionValue) === -1 ? true : optionValue === selectedRecordId)
		);
	});
}

export function getMappingTableColumns(
	isGrouped: boolean,
	type: SectionType,
	onChange: MapItemChangeHandler,
	bambooOptions: Array<MappingTableRowItem>,
	traxOptions: Array<MappingTableRowItem>,
	hasError: boolean,
	selectedRecordIds: Array<string>,
	onMetadataChange: MappingRecordMetadataChangeHandler,
	bambooNeedsTrax: NormalizedItemsToMap,
	traxNeedsBamboo: NormalizedItemsToMap
): Array<MappingTableColumnObject> {
	const isDeductionsSection = (type === 'companyDeductions') || (type === 'employeeDeductions');
	const columns: Array<MappingTableColumnObject> = isGrouped ? [
		{
			header: $.__('Name'),
			cell: () => null,
			sortBy: (row: MappingTableRowItem): string => row.group,
			width: '200px',
		},
	] : [];

	columns.push(
		{
			header: $.__('BambooHR Record'),
			width: '422px',
			cell: (row: MappingTableRowItem): JSX.Element => {
				const {
					selectedRecordId,
					originalDatabase,
					value,
					group,
					metadata,
				} = row;
				const showOptions = originalDatabase !== 'bamboo';
				const options = showOptions ? filterOptions(bambooOptions, isGrouped, isDeductionsSection, group, selectedRecordId, selectedRecordIds, metadata) : [row];

				return (
					<MappingTableItem
						databaseToAssign="trax"
						databaseToAssignItems={ traxNeedsBamboo }
						disableSelect={ hasError }
						onChange={ onChange }
						onMetadataChange={ onMetadataChange }
						options={ options }
						recordId={ value }
						selectedRecordId={ showOptions ? selectedRecordId : null }
						showOptions={ showOptions }
						type={ type }
					/>
				);
			},
		},
		{
			headerAriaLabel: $.__('Selection Icon'),
			width: '73px',
			cell: (row: MappingTableRowItem): JSX.Element => {
				const {
					selectedRecordId,
					originalDatabase,
				} = row;
				const isFromBamboo = originalDatabase === 'bamboo';
				const isAddNew = selectedRecordId === 'addNew';
				let iconName = 'fab-equal-31x24';
				const iconClasses = classNames(
					'MappingTable__icon',
					{ 'MappingTable__icon--selected': selectedRecordId !== null }
				);

				if (isAddNew && isFromBamboo) {
					iconName = 'fab-right-arrow-34x31';
				} else if (isAddNew && !isFromBamboo) {
					iconName = 'fab-left-arrow-34x31';
				}

				return (
					<span className={ iconClasses }>
						<Icon name={ iconName } />
					</span>
				);
			},
			verticalAlign: true,
		},
		{
			header: $.__('TRAXPayroll Record'),
			cell: (row: MappingTableRowItem): JSX.Element => {
				const {
					selectedRecordId,
					originalDatabase,
					value,
					group,
					metadata,
				} = row;
				const showOptions = originalDatabase !== 'trax';
				const options = showOptions ? filterOptions(traxOptions, isGrouped, isDeductionsSection, group, selectedRecordId, selectedRecordIds, metadata) : [row];

				return (
					<MappingTableItem
						databaseToAssign="bamboo"
						databaseToAssignItems={ bambooNeedsTrax }
						disableSelect={ hasError }
						onChange={ onChange }
						onMetadataChange={ onMetadataChange }
						options={ options }
						recordId={ value }
						selectedRecordId={ showOptions ? selectedRecordId : null }
						showOptions={ showOptions }
						type={ type }
					/>
				);
			},
		}
	);

	return columns;
}

export function getArrayFromNormalizedItems(
	normalizedItems: NormalizedItemsToMap,
	selectedRecordIds: Array<string>,
	hasError: boolean,
	errorRecordIds: Array<string>
): { itemsToMap: Array<MappingTableRowItem>, options: Array<MappingTableRowItem> } {
	const {
		byId,
		allIds,
	} = normalizedItems;
	const itemsToMap = [];
	const options = [];

	allIds.forEach((id) => {
		const record = byId[id];
		const {
			value,
			selectedRecordId,
		} = record;

		if (!selectedRecordId) {
			options.push(record);
		}

		if (hasError) {
			if (errorRecordIds.indexOf(id) > -1) {
				itemsToMap.push(record);
			}
			return;
		}

		if (selectedRecordIds.indexOf(value) === -1) {
			itemsToMap.push(record);
		}
	});

	return {
		itemsToMap,
		options,
	};
}

export function getShouldUseOnlyAdditionalOptions(isMultipleEin: boolean, sectionType: SectionType): boolean {
	if (!isMultipleEin) {
		return false;
	}

	return (
		sectionType === 'companyLocation' ||
		sectionType === 'companyEmploymentStatus' ||
		sectionType === 'companyDepartment' ||
		sectionType === 'companyDivision' ||
		sectionType === 'companyDeductions'
	);
}

export function getAdditionalOptionsFromSectionMetadata(
	type: SectionType,
	sectionMetadata: SectionMetadata,
	clientId: number,
	isMultipleEin: boolean
): {
	additionalBambooOptions: Array<MappingTableRowItem>,
} {
	if (type === 'employeeRecord') {
		return getEmployeeRecordAdditionalOptionsFromSectionMetadata(type, sectionMetadata);
	}
	if (type === 'companyLocation' && isMultipleEin) {
		return getCompanyLevelAdditionalOptionsFromSectionMetadata(type, sectionMetadata, clientId, 'locations');
	}
	if (type === 'companyEmploymentStatus' && isMultipleEin) {
		return getCompanyLevelAdditionalOptionsFromSectionMetadata(type, sectionMetadata, clientId, 'employmentStatuses');
	}
	if (type === 'companyDepartment' && isMultipleEin) {
		return getCompanyLevelAdditionalOptionsFromSectionMetadata(type, sectionMetadata, clientId, 'departments');
	}
	if (type === 'companyDivision' && isMultipleEin) {
		return getCompanyLevelAdditionalOptionsFromSectionMetadata(type, sectionMetadata, clientId, 'divisions');
	}
	if (type === 'companyDeductions' && isMultipleEin) {
		return getCompanyLevelAdditionalOptionsFromSectionMetadata(type, sectionMetadata, clientId, 'deductions');
	}

	return {
		additionalBambooOptions: [],
	};
}

function getEmployeeRecordAdditionalOptionsFromSectionMetadata(
	type: SectionType,
	sectionMetadata: SectionMetadata
): {
	additionalBambooOptions: Array<MappingTableRowItem>,
} {
	const {
		getText,
	} = ITEM_TEXT_AND_METADATA[type];

	let additionalBambooOptions = [];
	if (sectionMetadata && sectionMetadata.nonPayrollEmployees) {
		additionalBambooOptions = sectionMetadata.nonPayrollEmployees.map((employee) => {
			const updatedEmployee: EmployeeRecordSectionMetadataNonPayrollEmployee = {
				...employee,
				hireDate: employee.hireDate ? moment(employee.hireDate, 'YYYY-MM-DD').format('M/D/YYYY') : employee.hireDate,
				terminationDate: employee.terminationDate ? moment(employee.terminationDate, 'YYYY-MM-DD').format('M/D/YYYY') : employee.terminationDate,
			};

			const option: ItemToMap<EmployeeRecordSectionMetadataNonPayrollEmployee> = {
				bambooRowId: updatedEmployee.id,
				createValue: null,
				group: null,
				metadata: updatedEmployee,
				originalDatabase: 'bamboo',
				selectedRecordId: null,
				text: getText(updatedEmployee),
				traxRowId: null,
				value: `${ ADDITIONAL_BAMBOO_OPTION_ID_KEY }${ updatedEmployee.id }`,
				clientId: null,
			};

			return option;
		});
	}

	return {
		additionalBambooOptions,
	};
}

function getCompanyLevelAdditionalOptionsFromSectionMetadata(
	type: SectionType,
	sectionMetadata: SectionMetadata,
	clientId: number,
	additionalOptionsName: string
): {
	additionalBambooOptions: Array<MappingTableRowItem>,
} {
	const additionalBambooOptions: Array<MappingTableRowItem> = [];
	const {
		getMetadata,
		getText,
	} = ITEM_TEXT_AND_METADATA[type];

	if (sectionMetadata && additionalOptionsName in sectionMetadata) {
		const options: Array<ResponseRecordObject> = sectionMetadata[additionalOptionsName];

		options.forEach((option: ResponseRecordObject) => {
			const {
				groupData: {
					clientId: optionClientId,
					bamboo: {
						id: bambooRowId,
					},
					trax: {
						id: traxRowId,
					},
					createValue,
					metadata,
				}
			} = option;
			const optionMetadata = getMetadata(metadata, 'bamboo');

			if (optionClientId === clientId) {
				additionalBambooOptions.push({
					bambooRowId,
					createValue,
					group: null,
					metadata: optionMetadata as CompanyLocationMetadata,
					originalDatabase: 'bamboo',
					selectedRecordId: null,
					text: getText(optionMetadata),
					traxRowId,
					value: `${ ADDITIONAL_BAMBOO_OPTION_ID_KEY }${ bambooRowId }`,
					clientId,
				});
			}
		});
	}

	return { additionalBambooOptions };
}
