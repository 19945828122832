import {
	ExternalLink,
	Message,
} from '@bamboohr/utils/lib/message';

import {
	bem,
} from '../../../../utils';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BodyText, LayoutBox, StyledBox, Flex } from '@bamboohr/fabric';

export default ({
	logo = null,
	name = null,
	url = null,
}) => (logo || name || url) && (
	ifFeature('encore', 
		<StyledBox 
			borderColor='neutral-extra-weak'
			borderRadius='large' 
			borderStyle='solid' 
			boxShadow='small'
			display='flex'
			flexDirection='column'
			gap={1}
			height='auto'
			minHeight='144px'
			width='200px' 
		>
			{logo ?
				<>
					<Flex minHeight='64px' marginTop='40px' verticalAlign='middle' justifyContent='center'>
						<LayoutBox alignContent='center'>
							<Logo { ...{ logo, name, url } } />
						</LayoutBox>
					</Flex>
					<Flex justifyContent='center' paddingBottom='16px'>
						<BodyText justify='center' size='extra-extra-small'>
							<Message
								link={ ExternalLink(url) }
								params={ [name] }
								text={ $._('Published by [{1}]') }
							/>
						</BodyText>
					</Flex>
				</>:
				<Flex margin='auto'>
					<BodyText justify='center' size='extra-extra-small'>
						<Message
							link={ ExternalLink(url) }
							params={ [name] }
							text={ $._('Published by [{1}]') }
						/>
					</BodyText>
				</Flex>
			}
		</StyledBox>,
		<div className={ bem('appItemPubWrapper') }>
			{ logo && (
				<Logo { ...{ logo, name, url } } />
			) }
			<Msg { ...{ logo, name, url } } />
		</div>
	)
);

const Logo = ({
	logo = null,
	name = null,
	url = null,
}) => (url ? (
	<a
		className={ bem('appItemPubLogoWrapper') }
		href={ url }
		rel="noopener noreferrer"
		target="_blank"
	>
		<LogoImg { ...{ logo, name } } />
	</a>
) : (
	<span className={ bem('appItemPubLogoWrapper') }>
		<LogoImg { ...{ logo, name } } />
	</span>
));

const LogoImg = ({
	logo = null,
	name = null,
}) => (
	<img
		alt={ name }
		className={ bem('appItemPubLogo') }
		src={ logo }
	/>
);

const Msg = ({
	name = null,
	url = null,
}) => name && (
	<span className={ bem('appItemPubMsg') }>
		{ url ? (
			<Message
				link={ ExternalLink(url) }
				params={ [name] }
				text={ $._('Published by [{1}]') }
			/>
		) : $.__('Published by %1$s', name) }
	</span>
);
