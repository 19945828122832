import {
	BlankState,
	Button,
	Flex,
	Headline,
	IconV2,
	LayoutBox
} from '@bamboohr/fabric';
import {
	MoneyCircle20x20,
	OfficeBuildingQuestion120x120
} from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	CompanyTaxes,
} from './company-taxes';

import {
	CompanyTaxesBlankState,
} from './components/blank-state/company-taxes-blank-state';
import {
	CompanyTaxSettings,
} from './components/company-tax-settings/company-tax-settings';

import './company-taxes-wrapper.styl';

export function CompanyTaxesWrapper(props) {
	const {
		companyTaxes,
	} = props;
	const shouldRenderCompanyTaxSettings = Array.isArray(companyTaxes) && companyTaxes.length > 0;
	return (
		<div className="CompanyTaxesWrapper">
			{ ifFeature('encore', 
				<Flex alignItems="center" direction="row" justifyContent="space-between" marginBottom="14px" marginTop="10px">
					<Headline color="primary" size="small">{$.__('Taxes') }</Headline>
					{ shouldRenderCompanyTaxSettings ? (
						<CompanyTaxSettings
							companyTaxes={ companyTaxes }
						/>
					) : null }
				</Flex>,
				<h4 className="CompanyTaxesWrapper__header">
					<div className="CompanyTaxesWrapper__headerIcon">
						<MoneyCircle20x20 />
					</div>
					<span>{ $.__('Taxes') }</span>
					{ shouldRenderCompanyTaxSettings ? (
						<CompanyTaxSettings
							companyTaxes={ companyTaxes }
						/>
					) : null }
				</h4>
			) }
			{ pageContent(props) }
		</div>
	);
}

function pageContent(props) {
	const {
		companyTaxes,
	} = props;

	if (!companyTaxes || companyTaxes.length === 0) {
		return (
			<>
				{ ifFeature('encore', 
					<LayoutBox marginTop={3}>
						<BlankState
							icon={<OfficeBuildingQuestion120x120 />}
							subtitle={ $.__('You need to complete your company information before setting up taxes.') }
							title={ $.__(`Hold on, something’s missing here.`) }
						>
							<Button
								color="secondary"
								href="/settings/payroll/company_information"
								size="medium"
							>
								{ $.__('Complete Company Info') }
							</Button>
						</BlankState>
					</LayoutBox>,
					<CompanyTaxesBlankState
						buttonHref="/settings/payroll/company_information"
						buttonText="Complete Company Info"
						headerText="Hold on, something’s missing here."
						iconName="fab-office-building-question-120x120"
						subText="You need to complete you company information before setting up taxes."
					/>
				) }
			</>
		);
	}

	const clientIncludedTaxesArray = [];

	companyTaxes.forEach((company) => {
		if (company.clientIncludedTaxes) {
			company.clientIncludedTaxes.forEach((clientIncludedTax) => {
				clientIncludedTaxesArray.push(clientIncludedTax);
			});
		}
	});

	if (clientIncludedTaxesArray.length === 0) {
		return  (
			<>
				{ ifFeature('encore',
					<LayoutBox marginTop="24px">
						<BlankState
							icon={<IconV2 name="folder-open-regular" size={120} />}
							subtitle={ $.__('This is were you can manage your company’s payroll taxes and rates.') }
							title={ $.__('No payroll taxes have been added.') }
						>
							<Button
								color="secondary"
								href="/settings/payroll/taxes/tax_type"
								size="medium"
							>
								{ $.__('Add a Tax') }
							</Button>
						</BlankState>
					</LayoutBox>,
					<CompanyTaxesBlankState
							buttonHref="/settings/payroll/taxes/tax_type"
							buttonText="Add a Tax"
							headerText="No payroll taxes have been added."
							iconName="fab-gov-building-question-110x145"
							subText="This is were you can manage your company’s payroll taxes and rates."
					/>
				) }
			</>
		);
	}

	return (
		<CompanyTaxes { ...props } />
	);
}
