import { Divider, Flex, Headline, LayoutBox, useTheme } from '@bamboohr/fabric';
import { Lock15x18 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useEffect, useState } from 'react';

import { getAccountConfiguration } from 'multi-factor-authentication.mod';

import { GetStarted } from '../get-started.react';
import { MobileQrLoginWrapper } from '../mobile-qr-login-wrapper';
import { TwoStepLoginSetup } from '../two-step-login-setup';

export function TwoStepManager() {
	const { palette } = useTheme();

	const [getStartedActive, setGetStartedActive] = useState(null);
	const [mfaConfiguration, setMfaConfiguration] = useState({});

	useEffect(() => {
		document.title = $.__('2-Step Login');
		getAccountConfiguration()
			.then((resp) => {
				const { startDate, companyConfiguredAccess, selectedAccessLevelsById, canEditMfa } = resp.data || {};

				let showGetStarted = true;
				setMfaConfiguration({
					startDate,
					mfaLevel: companyConfiguredAccess,
					selectedAccessLevelsById,
					canEditMfa,
				});
				if (startDate) {
					showGetStarted = false;
				}

				setGetStartedActive(showGetStarted);
			})
			.catch(() => setGetStartedActive(true));
	}, []);

	const renderHeader = () => {
		return (
			<Flex alignItems='center' margin={getStartedActive ? '12px 0 18px' : '12px 0 0'}>
				{/* @startCleanup encore */}
				{ifFeature(
					'encore',
					undefined,
					<Flex marginRight='10px' paddingTop='4px'>
						<Lock15x18 fill={palette.primary.main} />
					</Flex>
				)}
				{/* @endCleanup encore */}
				<LayoutBox>
					<Headline color={ifFeature('encore', 'primary', 'neutral-strong')} size='small'>
						{$.__('Login Settings')}
					</Headline>
				</LayoutBox>
			</Flex>
		);
	};

	const renderTwoStepProgress = () => {
		if (getStartedActive) {
			return (
				<>
					<MobileQrLoginWrapper />
					<LayoutBox marginTop={3}>
						<Divider light noGap />
					</LayoutBox>
					<GetStarted toggleGetStarted={setGetStartedActive} />
				</>
			);
		}

		if (getStartedActive === false) {
			const TwoStepLoginComponent = (
				<TwoStepLoginSetup
					mfaConfiguration={mfaConfiguration}
					toggleGetStarted={(isVisible) => {
						if (isVisible) {
							setMfaConfiguration({});
						}
						setGetStartedActive(isVisible);
					}}
				/>
			);

			return (
				<>
					{TwoStepLoginComponent}
					<MobileQrLoginWrapper />
				</>
			);
		}

		return null;
	};

	return (
		<>
			{renderHeader()}
			{renderTwoStepProgress()}
		</>
	);
}
