import { connect } from 'react-redux';

import { StandardWorkflow } from '../components/standard-workflow';
import {
	getData,
	handleDisableApproval,
	handleEnableApproval
} from '../store/actions';

const mapStateToProps = (state) => {
	const {
		approverOptions,
		availableUsers,
		requestOptions,
		userGroups,
		workflow,
		workflowId
	} = state;
	const {
		active,
		advancedApprovalToolEnabled,
		curAppStatus,
		customFieldCount,
		description,
		lastModified,
		name,
		pathType,
		roleSectionNote,
		template,
		templateName,
		type
	} = (workflow || {});
	return {
		active,
		advancedApprovalToolEnabled,
		approverOptions,
		availableUsers,
		curAppStatus,
		customFieldCount,
		description,
		id: workflowId,
		lastModified,
		name,
		pathType,
		requestOptions,
		roleSectionNote,
		template,
		templateName,
		type,
		userGroups,
		workflow
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getWorkflowData: (workflowId) => {
			return dispatch(getData(workflowId));
		},
		handleDisableApproval: (workflowId) => {
			return dispatch(handleDisableApproval(workflowId));
		},
		handleEnableApproval: (workflowId) => {
			return dispatch(handleEnableApproval(workflowId));
		}
	}
};

export const StandardWorkflowContainer = connect(mapStateToProps, mapDispatchToProps)(StandardWorkflow);
