import { BENEFIT_TYPES, PLAN_TYPE_NAMES } from 'benefits/shared/constants';

export function formatDataForConfigureTable(rollupPlans, individualPlans) {
	return []
		.concat(rollupPlans)
		.map((rollupPlan) => {
			return ({
				...rollupPlan,
				isRollup: true,
				name: `${ $.__('All') } ${ PLAN_TYPE_NAMES[rollupPlan.type] } ${ $.__('Plans') }`,
			});
		})
		.sort((a, b) => BENEFIT_TYPES[a.type] - BENEFIT_TYPES[b.type])
		.concat(individualPlans.sort((a, b) => {
			const aName = a.name.toUpperCase();
			const bName = b.name.toUpperCase();
			if (aName > bName) {
				return 1;
			} else if (aName < bName) {
				return -1;
			}
			return 0;
		}));
}
