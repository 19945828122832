export const clientTaxSettingName_1099PrintOption = '1099PrintOption';
export const clientTaxSettingName_suppressCourtesyTax = 'suppressCourtesyWithholding';
export const clientTaxSettingName_suppressW2Box12Dd = 'suppressW2Box12Dd';

export const federal1099PrintFullService = 'fullservice';
export const federal1099PrintSelfService = 'selfservice';

export const federalW2Box12DdHide = 'hide';
export const federalW2Box12DdMinimumShowCount = 250;
export const federalW2Box12DdShow = 'show';

export const stateOhioCourtesyTaxRadioOptionCollect = 'collect';
export const stateOhioCourtesyTaxRadioOptionDoNotCollect = 'donotcollect';

export const supportGeneralLink = 'https://help.bamboohr.com/s/contactsupport';
export const supportHelpLink = 'https://help.bamboohr.com/s/article/1406711';
