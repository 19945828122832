import {
	Component,
} from 'react';

import {
	COMPANY_LOCATION,
	ALL_STEPS_IN_ORDER,
	handleRedirect,
	handleSuccessRedirect,
	handleSaveSuccess,
	handleSaveError,
	getIsFieldDisabled,
	getStepData,
	getWizardStatus,
	normalizeRecords,
	formatDataForSave,
	formatMappingDataForSave,
	saveStepData,
	getErrorIds,
	getParentFieldsById,
} from './utils/utilities';
import { CheckMigrationProgress } from './components/check-migration-progress';
import { MigrationSection } from './components/migration-section';
import { MigrationErrorModal } from './components/migration-error-modal';
import { MigrationComplete } from './components/migration-complete';
import { MigrationSectionTimeOff } from './components/migration-section-time-off';
import { MigrationSectionTimeOffTypesSummary } from './components/migration-section-time-off-types-summary';
import { DeductionSyncWizard } from './components/deduction-sync-wizard';

import {
	FieldSelectChangeHandler,
	PayInfoMigrationState,
	GetDataResponse,
	SaveDataObject,
	SaveDataResponseObject,
	FieldsNormalized,
	DatabaseString,
	SelectAllHandler,
	MappingRecordMetadataChangeParam,
	StepDataObject
} from './utils/interfaces';

import { isEnabled } from 'FeatureToggle.util';

const DEDUCTION_SYNC_WIZARD_IS_ENABLED = isEnabled('deductionSyncWizard');

export class PayInfoMigration extends Component {
	get _dataForSave(): SaveDataObject {
		const {
			groups,
			subGroups,
			fields,
			stepData: {
				type,
			},
		} = this.state;

		return formatDataForSave(fields, groups, subGroups, type);
	}

	get _mappingDataForSave(): SaveDataObject {
		const {
			bambooNeedsTrax,
			traxNeedsBamboo,
			stepData: {
				type,
			},
		} = this.state;
		return formatMappingDataForSave(bambooNeedsTrax, traxNeedsBamboo, type);
	}

	_getStepDataAndResponse = (sectionToCheckIndex: number): void => {
		const currentStep = ALL_STEPS_IN_ORDER[sectionToCheckIndex];
		const {
			url: currentStepUrl,
			type: currentStepType,
		} = currentStep;

		getStepData(currentStepUrl).then((response: GetDataResponse) => {
			const {
				status,
				data,
			} = response;
			if (status === 200 && data) {
				const {
					records,
					sectionMetadata,
					clients: clientsArray,
				} = data;
				const nextSectionToCheckIndex = sectionToCheckIndex + 1;
				const nextSection = ALL_STEPS_IN_ORDER[nextSectionToCheckIndex];
				// If there is work to be done for the section
				if (records.length) {
					window.scroll(0, 0);
					const {
						clients,
						groups,
						subGroups,
						fields,
						bambooNeedsTrax,
						traxNeedsBamboo,
						selectedMappingItems,
					} = normalizeRecords(records, clientsArray, currentStepType);
					this.setState({
						saveIsProcessing: false,
						isProcessing: false,
						sectionsBeingChecked: [ALL_STEPS_IN_ORDER[0].checkingProgressName],
						clients,
						isMultipleEin: clients.allIds.length > 1,
						groups,
						subGroups,
						fields,
						bambooNeedsTrax,
						traxNeedsBamboo,
						selectedMappingItems,
						stepData: currentStep,
						sectionMetadata: sectionMetadata || null,
						sectionToCheckIndex: 0,
					});
					// If there is no work for this section and section type is time off (the timeOff section is expected to be last)
				} else if (currentStepType === 'timeOff') {
					this.setState({
						allComplete: true,
						isProcessing: false,
						shouldShowTimeOffSummary: false,
					});
					// If there is no work for this section, but need to check the next one
				} else {
					this.setState((prevState: PayInfoMigrationState) => {
						const {
							sectionsBeingChecked,
						} = prevState;
						sectionsBeingChecked.push(nextSection.checkingProgressName);
						return {
							isProcessing: true,
							sectionsBeingChecked,
							sectionToCheckIndex: nextSectionToCheckIndex,
							selectedMappingItems: [],
						};
					}, this._handleDataRefresh);
				}
			} else {
				handleRedirect();
			}
		}).catch(handleRedirect);
	}

	_handleDataRefresh = (): void => {
		const {
			sectionToCheckIndex,
		} = this.state;
		const currentStep = ALL_STEPS_IN_ORDER[sectionToCheckIndex];

		this.setState({
			isProcessing: true,
			errorFields: [],
			overlapErrorFields: [],
		}, (): void => {
			if (currentStep.type === 'companyDeductions' && DEDUCTION_SYNC_WIZARD_IS_ENABLED) {
				getWizardStatus().then((response) => {
					const {
						status,
						data,
					} = response;
					if (status === 200 && data) {
						if (data.wizardStatus === 'skipWizard') {
							this._getStepDataAndResponse(sectionToCheckIndex);
						} else {
							this.setState({
								deductionSyncWizardStatus: data.wizardStatus,
							});
						}
					}
				}).catch(handleRedirect);
			} else {
				this._getStepDataAndResponse(sectionToCheckIndex);
			}
		});
	};

	_handleRecordMetadataChange = (param: MappingRecordMetadataChangeParam): void => {
		if (param.type === 'companyLocation') {
			const {
				checked,
				databaseToAssign,
				recordId,
			} = param;

			this.setState((prevState: PayInfoMigrationState): PayInfoMigrationState => {
				const stateProp = databaseToAssign === 'bamboo' ? 'bambooNeedsTrax' : 'traxNeedsBamboo';
				const createValue = prevState[stateProp].byId?.[recordId]?.createValue;
				if (typeof createValue === 'object') {
					createValue.remote_location = checked;
				}

				return prevState;
			});
		}
	}

	_handleRecordMapSelection = (database: DatabaseString, recordId: string, selectedRecordId: string): void => this.setState((prevState: PayInfoMigrationState): PayInfoMigrationState => {
		const selectedRecordIds: Array<string> = [];
		const {
			bambooNeedsTrax,
			traxNeedsBamboo,
		} = prevState;
		const stateObjectToModify = database === 'bamboo' ? 'bambooNeedsTrax' : 'traxNeedsBamboo';

		prevState[stateObjectToModify].byId[recordId].selectedRecordId = selectedRecordId;

		bambooNeedsTrax.allIds.forEach((id) => {
			const { selectedRecordId: currentSelection } = bambooNeedsTrax.byId[id];
			if (currentSelection) {
				selectedRecordIds.push(currentSelection);
			}
		});
		traxNeedsBamboo.allIds.forEach((id) => {
			const { selectedRecordId: currentSelection } = traxNeedsBamboo.byId[id];
			if (currentSelection) {
				selectedRecordIds.push(currentSelection);
			}
		});

		prevState.selectedMappingItems = selectedRecordIds;

		return prevState;
	});

	_handleFieldSelect: FieldSelectChangeHandler = (fieldId: string, database: DatabaseString, selectSubGroup: boolean) => {
		this.setState((prevState: PayInfoMigrationState) => {
			if (selectSubGroup) {
				const {
					subGroup,
				} = prevState.fields.byId[fieldId];

				prevState.fields.allIds.forEach((id: string): void => {
					const field = prevState.fields.byId[id];
					if (field.subGroup === subGroup) {
						field.selectedDatabase = database;
					}
				});
			} else {
				prevState.fields.byId[fieldId].selectedDatabase = database;
			}

			return prevState;
		});
	};

	_handleSelectAllClick: SelectAllHandler = (database: DatabaseString) => {
		this.setState((prevState: PayInfoMigrationState): PayInfoMigrationState => {
			const {
				fields: {
					byId,
					allIds,
				},
			} = prevState;

			allIds.forEach((fieldId) => {
				const field = byId[fieldId];

				if (!getIsFieldDisabled(field, database)) {
					field.selectedDatabase = database;
				}
			});

			return prevState;
		});
	};

	_handleGenericSaveError = (): void => this.setState({
		saveIsProcessing: false,
	}, handleSaveError);

	_handleSaveClick = (shouldExit: boolean, shouldContinue: boolean, isMappingSave: boolean): void => {
		const {
			stepData: {
				url,
				type,
			},
		} = this.state;
		const dataForSave = isMappingSave ? this._mappingDataForSave : this._dataForSave;

		this.setState({
			saveIsProcessing: true,
		}, (): void => {
			saveStepData(url, dataForSave)
				.then((response: SaveDataResponseObject) => {
					const {
						status,
						data,
					} = response;

					if (status === 200) {
						if (shouldExit) {
							handleSuccessRedirect();
						} else {
							handleSaveSuccess();

							if (shouldContinue) {
								// the timeOff section is expected to be last
								// eslint-disable-next-line max-depth
								if (type === 'timeOff') {
									this.setState({
										allComplete: true,
										saveIsProcessing: false,
										shouldShowTimeOffSummary: true,
									});
								} else {
									this._handleDataRefresh();
								}
							} else {
								this.setState({
									saveIsProcessing: false
								});
							}
						}
					} else if (status === 207) {
						const {
							genericErrorFields,
							overlappingErrorFields,
						} = getErrorIds(data, type);

						this.setState((prevState: PayInfoMigrationState): PayInfoMigrationState => {
							const newOvlerapErrorFields: Array<string> = [];
							const {
								fields: {
									allIds: allFieldIds,
								},
								bambooNeedsTrax: {
									allIds: allBambooNeedsTraxIds,
								},
								traxNeedsBamboo: {
									allIds: allTraxNeedsBambooIds,
								},
							} = prevState;

							overlappingErrorFields.forEach((field) => {
								const {
									id,
									metadata,
								} = field;

								if (!id) {
									return;
								}

								// Find out if the error record is from fields, bambooNeedsTrax, or traxNeedsBamboo
								const parentFieldObject = getParentFieldsById(id, allFieldIds, allBambooNeedsTraxIds, allTraxNeedsBambooIds);
								prevState[parentFieldObject].byId[id].overlapMetadata = metadata;

								newOvlerapErrorFields.push(id);
							});

							prevState.errorFields = genericErrorFields;
							prevState.overlapErrorFields = newOvlerapErrorFields;
							return prevState;
						});
					} else {
						this._handleGenericSaveError();
					}
				})
				.catch(this._handleGenericSaveError);
		});
	};

	_continueWithMx = (): void => {
		const {
			sectionToCheckIndex
		} = this.state;

		this.setState({
			deductionSyncWizardStatus: '',
		}, () => this._getStepDataAndResponse(sectionToCheckIndex))
	}

	state: PayInfoMigrationState = {
		isMultipleEin: false,
		clients: null,
		fields: null,
		groups: null,
		subGroups: null,
		bambooNeedsTrax: null,
		traxNeedsBamboo: null,
		sectionMetadata: null,
		selectedMappingItems: [],
		isProcessing: true,
		saveIsProcessing: false,
		allComplete: false,
		sectionsBeingChecked: [ALL_STEPS_IN_ORDER[0].checkingProgressName],
		sectionToCheckIndex: 0,
		stepData: null,
		errorFields: [],
		overlapErrorFields: [],
		isTimeOffSectionComplete: false,
		shouldShowTimeOffSummary: true,
		deductionSyncWizardStatus: '',
	};

	componentDidMount(): void {
		this._handleDataRefresh();
	}

	render(): JSX.Element {
		const {
			clients,
			fields,
			groups,
			bambooNeedsTrax,
			traxNeedsBamboo,
			sectionMetadata,
			selectedMappingItems,
			isMultipleEin,
			isProcessing,
			saveIsProcessing,
			allComplete,
			sectionsBeingChecked,
			stepData,
			errorFields,
			overlapErrorFields,
			isTimeOffSectionComplete,
			shouldShowTimeOffSummary,
			deductionSyncWizardStatus,
		} = this.state;

		// workflow completed state
		if (allComplete) {
			if (!isTimeOffSectionComplete) {
				if (shouldShowTimeOffSummary) {
					return (
						<MigrationSectionTimeOffTypesSummary
							onContinue={ () => this.setState({
								isTimeOffSectionComplete: true,
							}) }
							onExit={ () => window.location.assign('/settings/payroll/migration_tasks') }
						/>
					);
				}

				return (
					<MigrationSectionTimeOff
						onContinue={ () => this.setState({
							isTimeOffSectionComplete: true,
						}) }
						onExit={ () => window.location.assign('/settings/payroll/migration_tasks') }
					/>
				);
			}

			return (
				<div className="PayInfoMigration">
					<MigrationComplete />
				</div>
			);
		}

		// DeductionSyncWizard
		if (DEDUCTION_SYNC_WIZARD_IS_ENABLED) {
			if (deductionSyncWizardStatus === 'showRadioOptions' || deductionSyncWizardStatus === 'continueWizard') {
				return (
					<div className="PayInfoMigration">
						<DeductionSyncWizard
							continueWithMx={ this._continueWithMx}
							skipOptionsStep={ deductionSyncWizardStatus === 'continueWizard' }
						/>
					</div>
				);
			}
		}

		// Processing state
		if (isProcessing && sectionsBeingChecked.length) {
			return (
				<div className="PayInfoMigration">
					<CheckMigrationProgress
						steps={ sectionsBeingChecked }
					/>
				</div>
			);
		}

		const {
			type,
			groupIcon,
			isMappingGrouped,
			isComparisonGrouped,
		} = stepData;

		const {
			allIds: allFieldIds,
			byId: fieldsById,
		} = fields;
		const hasError = !!errorFields.length || !!overlapErrorFields.length;
		const errorFieldsNormalized: FieldsNormalized = { allIds: errorFields, byId: fieldsById };
		const showMappingTool = (bambooNeedsTrax && !!bambooNeedsTrax.allIds.length) || (traxNeedsBamboo && !!traxNeedsBamboo.allIds.length);

		const bambooCompletedMapping = bambooNeedsTrax.allIds.filter((id) => {
			const record = bambooNeedsTrax.byId[id];
			return selectedMappingItems.includes(record.selectedRecordId) || selectedMappingItems.includes(record.value);
		}).length;
		const traxCompletedMapping = traxNeedsBamboo.allIds.filter((id) => {
			const record = traxNeedsBamboo.byId[id];
			return selectedMappingItems.includes(record.selectedRecordId) || selectedMappingItems.includes(record.value);
		}).length;

		const totalCompletedMapping = bambooCompletedMapping + traxCompletedMapping;
		const totalFields = showMappingTool ? bambooNeedsTrax.allIds.length + traxNeedsBamboo.allIds.length : allFieldIds.length;
		const completedFields = showMappingTool ? totalCompletedMapping : allFieldIds.filter((id) => {
			const {
				selectedDatabase,
				canEdit,
			} = fieldsById[id];
			return selectedDatabase !== null && canEdit;
		}).length;

		return (
			<div className="PayInfoMigration">
				<MigrationSection
					bambooNeedsTrax={ bambooNeedsTrax }
					clients={ clients }
					completedFields={ completedFields }
					fields={ fields }
					groupIcon={ groupIcon }
					groups={ groups }
					isComparisonGrouped={ isComparisonGrouped }
					isMappingGrouped={ isMappingGrouped }
					isMultipleEin={ isMultipleEin }
					onFieldSelect={ this._handleFieldSelect }
					onRecordMetadataChange={ this._handleRecordMetadataChange }
					onRecordToMapSelect={ this._handleRecordMapSelection }
					onSelectAllClick={ this._handleSelectAllClick }
					saveAndContinue={ {
						isProcessing: saveIsProcessing,
						isDisabled: completedFields !== totalFields,
						onClick: (): void => this._handleSaveClick(false, true, showMappingTool),
					} }
					saveAndExit={ {
						isProcessing: saveIsProcessing,
						isDisabled: !completedFields,
						onClick: (): void => this._handleSaveClick(true, false, showMappingTool),
					} }
					saveProgress={ {
						isProcessing: saveIsProcessing,
						isDisabled: !completedFields,
						onClick: (): void => this._handleSaveClick(false, false, showMappingTool),
					} }
					sectionMetadata={ sectionMetadata }
					selectedRecordIds={ selectedMappingItems }
					showMappingTool={ showMappingTool }
					totalFields={ totalFields }
					traxNeedsBamboo={ traxNeedsBamboo }
					type={ type }
				/>

				<MigrationErrorModal
					allFieldIds={ allFieldIds }
					bambooNeedsTrax={ bambooNeedsTrax }
					clients={ clients }
					fields={ hasError ? errorFieldsNormalized : fields }
					fieldSelectChangeHandler={ this._handleFieldSelect }
					groupIcon={ groupIcon }
					groups={ groups }
					isComparisonGrouped={ isComparisonGrouped }
					isMappingError={ showMappingTool }
					isMappingGrouped={ isMappingGrouped }
					isMultipleEin={ isMultipleEin }
					isOpen={ hasError }
					mappingErrorFieldIds={ errorFields }
					onClose={ this._handleDataRefresh }
					onDoneClick={ this._handleDataRefresh }
					overlapErrorFieldIds={ overlapErrorFields }
					sectionMetadata={ sectionMetadata }
					sectionType={ type }
					traxNeedsBamboo={ traxNeedsBamboo }
				/>
			</div>
		);
	}
}
