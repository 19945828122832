import { Fragment } from 'react';

import {
	ReviewTable,
} from 'aca-status-alignment-table.react';
import {
	ACA_TYPES,
} from 'aca-status-select.react';

import { Heading } from '../heading';

export function Review(props) {
	const {
		employeeData,
		employmentStatusAcaTypes,
	} = props;
	return (
		<Fragment>
			<Heading
				subText={ $.__('Take a moment to double check that everything looks ok.') }
				text={ $.__(`Verify Employees' Employment Status`) }
				useDivider={ true }
			/>
			<ReviewTable
				columns={ [
					{
						header: $.__('Name'),
						cell: row => `${ row.firstName } ${ row.lastName }`,
						sortBy: row => `${ row.firstName } ${ row.lastName }`,
					},
					{
						header: $.__('Employment Status'),
						cell: row => row.employmentStatusName,
						sortBy: row => row.employmentStatusName,
					},
					{
						header: $.__('ACA Status'),
						cell: (row) => {
							return (ACA_TYPES[row.acaType] ? ACA_TYPES[row.acaType].label : row.acaType);
						},
						sortBy: (row) => {
							return (ACA_TYPES[row.acaType] ? ACA_TYPES[row.acaType].label : row.acaType);
						},
					},
				] }
				employeeData={ employeeData }
				employmentStatusAcaTypes={ employmentStatusAcaTypes }
				isMigration={ true }
			/>
		</Fragment>
	);
}
