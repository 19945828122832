import { LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React from 'react';
import { Content, Title, Video } from './components';
/* @startCleanup encore */
import styles from './styles';
/* @endCleanup encore */

const Upsell = ({ children }) => {
	/* @startCleanup encore */
	const classes = styles();
	/* @endCleanup encore */

	return ifFeature('encore', <LayoutBox>{children}</LayoutBox>, <div className={classes.view}>{children}</div>);
};

Upsell.defaultProps = {
	children: null,
};

Upsell.Content = Content;
Upsell.Title = Title;
Upsell.Video = Video;

export default Upsell;
