import { getSelectedCoverageLevelIds } from '../../etc/get-selected-coverage-level-ids';
import { CoverageLevelValue } from '../../types/wizard-values';
import { RuleName, SetSpecialErrors } from '../../../types/plan-validation';

export const validateCoveragesRequired = (
	coverageLevels: CoverageLevelValue[],
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const fieldName = 'coverageLevels';
	const selectedCoverages = getSelectedCoverageLevelIds(coverageLevels);
	const isValid = Boolean(selectedCoverages && selectedCoverages.length);
	if (isValid) {
		setSpecialErrors(fieldName, null);
	} else {
		setSpecialErrors(fieldName, { rule: RuleName.required, message: $.__('This field is required') });
	}
	return isValid;
};
