import { cloneDeep } from 'lodash';

import {
	CLOSE_CONFIRM_MODAL,
	CREATE_NEW_CANCEL,
	CREATE_NEW_PENDING,
	DELETE_APPROVAL_SUCCESS,
	GET_DATA_ERROR,
	GET_DATA_PENDING,
	GET_DATA_SUCCESS,
	EDIT_WORKFLOW_CANCEL,
	EDIT_WORKFLOW_START,
	REMOVE_PATH,
	PROCESSING_ACTION,
	SAVE_PATH,
	SUBNAV_ITEMS_PENDING,
	SUBNAV_ITEMS_SUCCESS,
	UPDATE_EDITING_APPROVERS,
	UPDATE_EDITING_LEVELS,
	UPDATE_EDITING_PATH_OPTIONS
} from './actionTypes';
import {
	cancelEditWorkflow,
	closeConfirmModal,
	createNewCancel,
	createNewStart,
	editWorkflow,
	openConfirmModal,
	populateSubNavItems,
	populateWorkflowData,
	resetToInitialState,
	updateEditingApprovers,
	updateEditingPathLevels,
	updateEditingPathOptions,
	updateProcessing
} from './mutators';

export const reducers = (state = {}, action) => {
	const { payload, type } = action;
	let clonedState = cloneDeep(state);
	switch (type) {
		case CLOSE_CONFIRM_MODAL:
			clonedState = closeConfirmModal(clonedState);
			break;
		case CREATE_NEW_CANCEL:
			clonedState = createNewCancel(clonedState);
			break;
		case CREATE_NEW_PENDING:
			clonedState = createNewStart(clonedState);
			break;
		case DELETE_APPROVAL_SUCCESS:
		case GET_DATA_PENDING:
			clonedState = resetToInitialState(clonedState, payload);
			break;
		case EDIT_WORKFLOW_CANCEL:
			clonedState = cancelEditWorkflow(clonedState);
			break;
		case EDIT_WORKFLOW_START:
			clonedState = editWorkflow(clonedState, payload);
			break;
		case GET_DATA_ERROR:
			break;
		case GET_DATA_SUCCESS:
			clonedState = populateWorkflowData(clonedState, payload);
			break;
		case PROCESSING_ACTION:
			clonedState = updateProcessing(clonedState, payload);
			break;
		case REMOVE_PATH:
			clonedState = openConfirmModal(clonedState, payload);
			break;
		case SAVE_PATH:
			clonedState = openConfirmModal(clonedState, payload);
			break;
		case SUBNAV_ITEMS_PENDING:
		case SUBNAV_ITEMS_SUCCESS:
			clonedState = populateSubNavItems(clonedState, payload);
			break;
		case UPDATE_EDITING_APPROVERS:
			clonedState = updateEditingApprovers(clonedState, payload);
			break;
		case UPDATE_EDITING_LEVELS:
			clonedState = updateEditingPathLevels(clonedState, payload);
			break;
		case UPDATE_EDITING_PATH_OPTIONS:
			clonedState = updateEditingPathOptions(clonedState, payload);
			break;
	}
	return clonedState;
};
