import {
	useMemo,
} from 'react';
import {
	Button,
	StandardModal,
	SheetModal,
	makeStyles,
} from '@bamboohr/fabric';

import { EmployeeObject } from '../types';

const useStyles = makeStyles(({typography, mixins, palette}) => ({
	employeeName: {
		fontSize: typography.fabricFontSize('large'),
		borderBottom: mixins.border(1, palette.grey[300]),
		marginLeft: '10px',
		lineHeight: '52px',
		'&:last-of-type': {
			borderBottom: 'none',
		}
	},
}));

interface EnrolledEmployeesModalProps {
	isSheetModal: boolean;
	deductionName: string;
	employees: EmployeeObject[];
	isOpen: boolean;
	onClose(): void;
}

export function EnrolledEmployeesModal(props: EnrolledEmployeesModalProps): JSX.Element {
	const {
		isSheetModal,
		deductionName,
		employees,
		isOpen,
		onClose,
	} = props;

	const styles = useStyles();
	const Modal = useMemo(() => {
		return isSheetModal ? SheetModal : StandardModal;
	}, [isSheetModal]);
	const headline = useMemo<string>(
		() => $.__('%1$s TRAX Employees Eligible & Enrolled', employees.length),
		[employees]
	);

	return (
		<Modal
			isOpen={ isOpen }
			onRequestClose={ onClose }
		>
			<Modal.Body
				renderFooter={ (
					<Modal.Footer
						actions={ [<Button clickAction={ onClose } key="done" type="button">{ $.__('Done') }</Button>] }
					/>
				)}
				renderHeader={ <Modal.Header title={ deductionName } /> }
			>
				<StandardModal.StandardHeadline
					// @ts-expect-error Update from  jade icon to encore Icon
					icon="fab-people-20x16"
					text={ headline }
				/>
				<StandardModal.Constraint>
					<div>
						{ employees.map(({id, employeeName}) => <p className={ styles.employeeName } key={ id }>{ employeeName }</p>) }
					</div>
				</StandardModal.Constraint>

			</Modal.Body>
		</Modal>
	);

}
