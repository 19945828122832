import './styles.styl';

import { Component, Fragment, createRef, useState } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { render } from 'base/wrapped-render';
import { Icon } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';
import { ajax } from '@bamboohr/utils/lib/ajax';
import Tooltip from 'tooltip.react';
import { TheNewEmployeeSelector } from 'employee-selector.react';
import { isEnabled } from 'FeatureToggle.util';
import EnableFutureModal from '../../setup-checklist.react/components/enable-modal/future';
import TimeTrackingNotificationModal from 'time-tracking/modals/time-tracking-notification';

const nonExemptEnabled = isEnabled('nonExemptWarningModals');
const futureSchedulingEnabled = isEnabled('timeTrackingFutureScheduling');

export function openModal(afterEnableCallback = Function.prototype, useScheduleButtons = false, onRequestEnd) {
	ajax.get('/settings/time_tracking/employees/add')
		.then(({ data }) => {
			const { employeeFilters: { employeeFilters }, employeeList } = data;

			const temporaryElement = document.createElement('div');
			render(
				<TimeTrackingSelector
					afterEnable={ afterEnableCallback }
					attachedElement={ temporaryElement }
					employeeFilters={ employeeFilters }
					employeeList={ employeeList }
					schedulingEnabled={ useScheduleButtons }
				/>,
				temporaryElement
			);
		})
		.catch(() => {
			window.errorFallBack();
		})
		.finally(() => {
			onRequestEnd();
		});
}

function bulkAddEmployees(ids, afterEnableCallback, onFail) {

	$.post('/time_tracking/enable_employees', { ids })
		.done((response) => {
			let messageType = 'success';
			if (response.status === 'FAIL') {
				messageType = 'error';
			} else {
				afterEnableCallback();
			}

			if (futureSchedulingEnabled && response.status === 'WARNING') {
				showNotificationModal(response.employees, response.type);
			} else {
				window.setMessage(response.message, messageType);
			}
		})
		.fail(() => {
			onFail();
			setMessage($.__('Sorry, something went wrong. Try giving it another shot?'), 'error');
			window.SimpleModal.Footer.Buttons.Primary.stopProcessing();
		});
}

function showNotificationModal(employees, type) {
	const temporaryElement = document.createElement('div');
	render(
		<TimeTrackingNotificationModal
			attachedElement={ temporaryElement }
			employees={ employees }
			type={ type }
		/>,
		temporaryElement
	);
}

export default {
	openModal,
};

class TimeTrackingSelector extends Component {
	constructor(props) {
		super(props);

		const { employeeList } = this.props;

		this.state = {
			employeeList,
			showEnableFutureModal: false,
			isOpen: true,
			isProcessing: false,
			sheetProcessing: false,
		};

		this.scheduleRef = createRef();
	}

	_getSelectedIds = () => {
		const { employeeList } = this.state;
		return employeeList
			.filter(employee => employee.selected)
			.map(employee => employee.id);
	};

	_createSecondaryContent = (row, selected) => {
		if (nonExemptEnabled) {
			if (!row.payScheduleId) {
				return (
					<Fragment>
						<span className="STE_ScheduleModal__issueIcon">
							<Icon name="fab-triangle-exclamation-13x11" />
						</span>
						{ $.__('No Pay Schedule') }
					</Fragment>
				);
			}

			if (row.hasFutureHireDate) {
				return $.__('Future Hire Date');
			}

			if (!isEnabled('custom-overtime')) {
				if (row.showNoLocationTooltip || row.showNoStateTooltip || row.showNoStateRemoteTooltip) {
					return (
						<Tooltip
							settings={ {
								...(row.showNoLocationTooltip && { content: $.__(`This employee has not been assigned to a location.`) }),
								...(row.showNoStateTooltip && { content: $.__(`Overtime cannot be calculated for this employee because the location does not include a US state.`) }),
								...(row.showNoStateRemoteTooltip && { content: $.__(`Overtime can’t be calculated. This employee’s address is missing a US state.`) }),
							} }
						>
							<span className="STE_ScheduleModal__issueIcon">
								<Icon name="fab-triangle-exclamation-13x11" />
							</span>
							{ row.showNoLocationTooltip && $.__('No Location') }
							{ (row.showNoStateTooltip || row.showNoStateRemoteTooltip) && $.__('No State') }
						</Tooltip>
					);
				}
	
				if (row.showNonOvertimeCountryTooltip) {
					return (
						<Tooltip settings={ { content: $.__(`Overtime cannot be calculated for addresses that do not include a US state.`) } }>
							{ $.__('International') }
						</Tooltip>
					);
				}
			}

			return;
		}
		if (row.payScheduleId) {
			let tooltipContent = null;
			if (row.showNoLocationTooltip) {
				tooltipContent = $.__(`%1$s doesn't have a location set so we can't calculate overtime.`, row.firstName);
			} else if (row.showNoStateTooltip) {
				tooltipContent = $.__(`%1$s doesn't have a state set so we can't calculate overtime.`, row.firstName);
			} else if (row.showNoStateRemoteTooltip) {
				tooltipContent = $.__(`%1$s doesn't have a home address state set so we can't calculate overtime.`, row.firstName);
			}

			return (
				<Fragment>
					{ tooltipContent && (
						<Tooltip settings={ { content: tooltipContent } }>
							<span className="STE_ScheduleModal__issueIcon">
								<Icon name="fab-location-9x12" />
							</span>
						</Tooltip>
					) }
					{ row.locationName }
				</Fragment>
			);
		}

		return (
			<div>
				<span className="STE_ScheduleModal__issueIcon">
					<Icon name="fab-triangle-exclamation-13x11" />
				</span>
				{ $.__('No Pay Schedule') }
			</div>
		);
	};

	_handleModalClose = () => {
		const { attachedElement } = this.props;
		unmountComponentAtNode(attachedElement);
	};

	_handleSelectChange = (employees, isBeingSelected) => {
		const { employeeList } = this.state;
		employees = employees.map(rec => rec.id);
		employeeList.forEach((item) => {
			if (employees.indexOf(item.id) > -1) {
				item.selected = isBeingSelected;
			}
		});

		this.setState({
			employeeList: [...employeeList],
		});
	};

	_handleEnableNowButton = () => {
		const { afterEnable } = this.props;

		this.setState({ isProcessing: true });

		const onSuccess = () => {
			this.setState({ isOpen: false }, afterEnable);
		};

		const onFail = () => {
			this.setState({ isProcessing: false });
		};

		bulkAddEmployees(this._getSelectedIds(), onSuccess, onFail);
	};

	_handleScheduleButton = () => {
		this.setState({
			isProcessing: true,
			showEnableFutureModal: true,
		});
	};

	_handleCloseScheduleModal = () => {
		this.setState({ showEnableFutureModal: false });
	};

	_handleScheduleRequestComplete = () => {
		this.setState({ isProcessing: false });
	};

	_handleScheduleSave = () => {
		const { afterEnable } = this.props;
		this.setState({
			isOpen: false,
		}, afterEnable);
	};

	_handleEnableSheetProcessing = () => {
		this.setState({ isSheetProcessing: true });
	};

	_handleEnableSheetActionable = (errorResponse) => {
		if (futureSchedulingEnabled && errorResponse && errorResponse.status === 'WARNING') {
			this._handleScheduleSave(); // close the other modals and refresh the table
			showNotificationModal(errorResponse.employees, errorResponse.type);
		} else {
			this.setState({ isSheetProcessing: false });
		}
	};


	render() {
		const { employeeFilters, schedulingEnabled } = this.props;
		const { employeeList, showEnableFutureModal, isOpen, isProcessing, isSheetProcessing } = this.state;

		const selectedEmployees = this._getSelectedIds();
		const hasSelected = selectedEmployees.length > 0;

		const overlayOptions = {
			biId: 'settings-time-tracking-add-employees',
			isOpen,
			title: $.__('Add Employees'),
			headerType: 'text',
			icon: 'fab-stopwatch-13x16',
			headline: $.__('Time Tracking Employees'),
			primaryActionText: $.__('Save'),
			primaryAction: hasSelected ? this._handleEnableNowButton : null,
			type: 'medium',
			onClose: this._handleModalClose,
			isProcessing,
		};

		if (schedulingEnabled) {
			Object.assign(overlayOptions, {
				primaryActionText: $.__('Enable Now'),
				secondaryActionText: $.__('Schedule Start Date'),
				secondaryAction: hasSelected ? this._handleScheduleButton : null,
			});
		}

		if (showEnableFutureModal) {
			overlayOptions.sheetProps = {
				isOpen: true,
				title: $.__('Schedule Time Tracking?'),
				onClose: () => this._handleCloseScheduleModal(),
				primaryActionText: $.__('Schedule Time Tracking'),
				primaryAction: () => {
					this._handleEnableSheetProcessing();
					this.scheduleRef.current._handleSaveModal();
				},
				isProcessing: isSheetProcessing,
				content: (
					<EnableFutureModal
						ref={ this.scheduleRef }
						employeeIds={ this._getSelectedIds() }
						isSheet={ true }
						onFail={ this._handleEnableSheetActionable }
						onRequestComplete={ this._handleScheduleRequestComplete }
						onSave={ this._handleScheduleSave }
						type="add"
					/>
				),
			};
		}

		return (
			<Modal { ...overlayOptions }>
				<p>{ $.__(`Select who you'd like to enable Time Tracking for:`) }</p>
				<TheNewEmployeeSelector
					employees={ employeeList }
					filters={ employeeFilters }
					isRowDisabled={ row => !row.payScheduleId }
					onDeselect={ rows => this._handleSelectChange(rows, false) }
					onSelect={ rows => this._handleSelectChange(rows, true) }
					rowDisabledTooltip={ row => $.__('%1$s needs to have a pay schedule before time tracking can be enabled.', row.firstName) }
					secondaryContent={ this._createSecondaryContent }
				/>
			</Modal>
		);
	}
}
