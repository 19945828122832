/* global tinyMCE */

import Ctrl, {
	onClick,
	onReady,
	onFooterAction,
} from 'ctrl.deco';
import Ajax from '@utils/ajax';
import { toQueryString } from '@utils/url';
import Placeholders from 'Placeholders.mod';
import setupEditor from '../setup-editor';
import { getJsonData } from 'Data.util';
import { addListeners } from 'hiring/manage-files';
import { initializeStickySidebar } from 'hiring/scrolling';
import { initFilesManager } from 'shared-files-manager.mod/offer-letter-manager';
import FileCard from 'file-card.mod';


const validationRules = {
	rules: {
		'js-mce-message': {
			bambooRichText: true,
		},
		'js-mce-oe-message': {
			bambooRichText: true,
		},
		subject: {
			bambooRichText: true,
		},
	},
};

@Ctrl('/settings/jobs/templates.php/editOfferTemplate*')
class EditOfferTemplates {
	constructor() {
		window.Placeholders = Placeholders;
		this.templateData = getJsonData('#templateData');
		const PlaceholdersData = this.templateData.placeholders;

		Placeholders.Generator.generate(PlaceholdersData);
		Placeholders.Widget.create(PlaceholdersData, Placeholders.Generator.get());

		window.previewOfferTemplateModal = this.previewOfferTemplateModal;
		window.previewOfferTemplate = this.previewOfferTemplate;
		window.previewOfferEmail = this.previewOfferEmail;

		const existingFiles = JSON.parse(document.getElementById('existingFiles').innerText);
		window.sharedFilesManager = initFilesManager(existingFiles);

		initializeStickySidebar('.PlaceholderWidget', '.PlaceholderWidget__scroll', '.PlaceholderWidget__psWrapper');
		addListeners();
	}

	@onReady()
	init(e, ctrl) {
		ctrl._setupOfferLetter();
		ctrl._setupOfferEmail();
		ctrl.getAttachments();


		// Prevent drag and drop into template name, in firefox it just puts in the data of the image
		$('.js-template-name').on('drop', function(e) {
			e.preventDefault();
		});

		$('#ATSEmailTemplateForm').bhrValidate(validationRules, $.__('Whoops... There were some errors with your form. Please fix the highlighted fields.'));
		window.enableFormChangeTracking(document.getElementById('ATSEmailTemplateForm'));
	}

	@onFooterAction('preview')
	_onSecondaryFooterAction(e, ctrl, type, index, count) {
		ctrl.previewOfferTemplate(ctrl);
	}

	@onFooterAction('primary')
	_onPrimaryFooterAction() {
		const tinyMCE = window.tinymce;
		const $form = $('#ATSEmailTemplateForm');
		const formValid = $form.valid();
		if (formValid) {
			tinyMCE.triggerSave();
			$form.submit();
		}
	}

	@onClick('.js-preview-email')
	getEmailPreview(e, ctrl) {
		const data = {
			subject: tinyMCE.get('emailSubject').getContent(),
			body: tinyMCE.get('js-mce-oe-message').getContent(),
		};
		Ajax.post('/applicant_tracking/preview_offer_letter_email', data)
			.then((response) => {
				const html = response.data;
				ctrl.previewOfferEmail(html);
			}).catch(() => {
				window.setMessage('Unable to preview the email', 'error');
			});
	}

	@onClick('.js-preview-email-link')
	fakeModalClick(e, ctrl) {
		window.setMessage($.__('Clicking this button would take the applicant to view their offer letter.'), 'info');
	}

	_setupOfferLetter = () => {
		const initSelector = '#js-mce-message';
		const inputSelector = '#js-mce-message-input';
		const toolbarSelector = '#js-mce-toolbar';
		const customOptions = {
			valid_elements: 'a[href|target=_blank],span[style],font[face|size],#p[style],br,strong/b,em/i,ul[start|style],ol[start|style],li,img[class<placeholder?embedded-image|width|height|src|alt|data*]',
			toolbar1: 'fontselect | bold italic underline forecolor | bullist numlist | link unlink embedimage',
		};

		setupEditor(initSelector, inputSelector, toolbarSelector, '', this.templateData.message, customOptions);
	}

	_setupOfferEmail = () => {
		const initSelector = '#js-mce-oe-message';
		const inputSelector = '#js-mce-oe-message-input';
		const toolbarSelector = '#js-offerEmail-toolbar';
		const subjectSelector = '#emailSubject';
		const customOptions = {
			valid_elements: '#p[style],br,img[!data-is-placeholder|class<placeholder|src|data*]',
			toolbar1: '',
		};

		setupEditor(initSelector, inputSelector, toolbarSelector, subjectSelector, this.templateData.emailMessage, customOptions, true);
	}

	previewOfferTemplate(ctrl) {
		const data = {
			offer: tinyMCE.get('js-mce-message').getContent(),
			template_id: $('.js-template-id').val(),
			duplicate_id: $('.js-duplicate-id').val(),
			file_ids: $('.js-file-ids').map((i, el) => el.value).get(),
		};
		Ajax.post('/settings/jobs/templates.php/previewOfferTemplate', toQueryString(data))
			.then((response) => {
				const html = response.data;
				ctrl.previewOfferTemplateModal(html);
				ctrl.renderAttachments(html);
			}).catch(() => {
				window.setMessage($.__('There was an error previewing this template'), 'error');
			});
	}

	previewOfferTemplateModal(html) {
		window.BambooHR.Modal.setState({
			biId: 'settings-offer-preview',
			dangerousContent: html,
			isOpen: true,
			type: 'fullScreen',
			contentHasPadding: false,
			primaryAction() {
				window.BambooHR.Modal.setState({ isOpen: false });
			},
			primaryActionText: $.__('Close'),
			alternativeAction: null,
			headline: $.__('Offer Letter Preview'),
		});
	}

	previewOfferEmail(html) {
		window.BambooHR.Modal.setState({
			dangerousContent: html,
			isOpen: true,
			type: 'medium',
			contentHasPadding: false,
			primaryAction() {
				window.BambooHR.Modal.setState({ isOpen: false });
			},
			primaryActionText: $.__('Done'),
			alternativeAction: null,
			title: $.__('Email Preview'),
		});
	}

	getAttachments(html) {
		let parsedData = null;

		if (html) {
			const parser = new window.DOMParser();
			const parsedHtml = parser.parseFromString(html, 'text/html');
			const dataNode = parsedHtml.querySelector('#offerLetterData');
			if (dataNode !== null) {
				try {
					parsedData = JSON.parse(dataNode.textContent);
				} catch (e) {
					console.warn(`Missing Attachments data from Template. ${ e }`);
					return false;
				}
			}
			return parsedData;
		}
	}

	renderAttachments(html) {
		const { attachments } = this.getAttachments(html);
		if (attachments && attachments.length) {

			const attached = attachments.map((file) => {
				file.action = null;
				file.disabled = true;
				return file;
			});
			FileCard.renderFileCards('.OLPreview__letter .FileCards', attached);
		}
	}
}
