import React from 'react';
import Ajax from '@utils/ajax';
import { NavData } from './types';

export const getAccessLevelNav = async () => {
	return new Promise((resolve, reject) => {
		Ajax.get('/settings/access_levels/group_list')
			.then((response) => {
				if (response.status === 200) {
					const processedData: NavData = {
						...response.data,
						levelTypes: response.data.levelTypes.map((type) => ({
							...type,
							href: `/settings/access_levels/${type.name}`,
							levels: type.levels
								? type.levels.map((level) => ({
										...level,
										href: `/settings/access_levels/${type.name}/${level.id}`,
								  }))
								: null,
						})),
					};
					resolve(processedData);
				} else {
					reject($.__('Failed to get data', response.statusText));
				}
			})
			.catch(() => {
				reject($.__('Uh oh, something went wrong. Please try again or contact support@bamboohr.com'));
			});
	});
};
