import {
	RadioGroup,
} from '@bamboohr/fabric';

import {
	useStyles,
} from './radio-options.styles';
import {
	federal1099PrintFullService,
	federal1099PrintSelfService,
} from '../constants';
import {
	L_FEDERAL_1099_PRINT_RADIO_OPTION_LABEL,
} from '../language';
import {
	federal1099PrintRadioOptions,
} from '../domain/federal-1099-print-radio-options';

interface Federal1099PrintProps {
	isFullService: boolean;
	setIsFullService(selfService: boolean): void;
}

export function Federal1099Print(props: Federal1099PrintProps) {
	const {
		isFullService,
		setIsFullService,
	} = props;
	const classes = useStyles();
	return (
		<div className={ classes.radioOptionsContainer }>
			<RadioGroup
				items={ federal1099PrintRadioOptions }
				label={ L_FEDERAL_1099_PRINT_RADIO_OPTION_LABEL }
				onChange={ (param) => {
					const {
						value,
					} = param;
					setIsFullService(value === federal1099PrintFullService);
				} }
				status="default"
				value={ isFullService ? federal1099PrintFullService : federal1099PrintSelfService }
			/>
		</div>
	);
}
