import {
	Fragment,
} from 'react';

import {
	BodyText,
	Divider,
	Flex,
	Headline,
	LayoutBox,
} from '@bamboohr/fabric';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { ifFeature } from '@bamboohr/utils/lib/feature';


/** @type {React.FunctionComponent} */
export default function ModalContentEnableStep1() {

	return (
		<Fragment>
			<LayoutBox marginBottom={ifFeature('encore',2.5,2)}>
				<Headline
					color={ifFeature('encore','neutral-strong','primary')}
					component='h3'
					size={ifFeature('encore','small','medium')}
				>{$.__('Okay, let’s help you get started…') }</Headline>
			</LayoutBox>
			{
				ifFeature('encore',
				<LayoutBox marginBottom={2.25}>
					<Divider color='neutral-extra-weak' />
				</LayoutBox>, <></>
				)
			}
			<LayoutBox marginBottom={2}>
			<BodyText color='neutral-strong' component='p' id='aca-step-1-text' size='medium' weight='regular'>
				{ `${ $.__('We\'ll help you assign an ACA status to each of your employment statuses.') } ` }
				{ `${ $.__('Employees will then have an ACA status by virtue of their employment status.') } ` }
				{ `${ $.__('You can change this anytime in Settings >> Employee Fields >> Employment Status.') } ` }
			</BodyText>
			</LayoutBox>
			<LayoutBox marginBottom={ifFeature('encore', 3, 0)}>
				<BodyText color='neutral-strong' component='span' id='aca-step-1-note' size='medium' weight='regular'>
					<Flex display="inline-flex" marginRight={0.25} paddingBottom={ifFeature('encore', 0.125, 0.375)}>
						<BodyText color='primary' component='span' id='aca-step-note-label' size='medium' weight='semibold'>
								{ `${ $.__('PLEASE NOTE:') } ` }
						</BodyText>
					</Flex>
					<Message
						link={ExternalLink("https://acareportingservice.com/")}
						text={ `${ $.__(`Turning on these features is simply for tracking and reporting purposes—you will still need to file with the IRS for ACA compliance. If you need assistance doing your ACA filing, we recommend you reach out to an expert service such as BambooHR’s trusted partner [ACA Reporting Service].`) } ` }
					/>
				</BodyText>
			</LayoutBox>
		</Fragment>
	);
}
