import {
	Select,
} from '@fabric/select';

import {
	ITEM_DATA_BY_TYPE,
} from './mapping-table-item-utils';

import {
	CompanyLocationMetadata,
	EmployeeRecordSectionMetadataNonPayrollEmployee,
	ItemToMap,
	MappingTableItemProps,
	WageMetadata,
	CompanyDeductionsMetadata,
	CompanyEmploymentStatusMetadata,
} from '../../utils/interfaces';

import './mapping-table-item.styl';

export function MappingTableItem(props: MappingTableItemProps): JSX.Element {
	const {
		type,
		recordId,
		selectedRecordId,
		options,
		showOptions,
		onChange,
		onMetadataChange,
		databaseToAssign,
		databaseToAssignItems,
		disableSelect,
	} = props;
	const {
		addNewTextBamboo,
		addNewTextTrax,
		placeholderBamboo,
		placeholderTrax,
		MetadataComponent,
	} = ITEM_DATA_BY_TYPE[type];
	const disabledAddNew = (type === 'companyDeductions' || type === 'employeeDeductions') && databaseToAssign === 'trax';
	const addNewText = databaseToAssign === 'bamboo' ? addNewTextTrax : addNewTextBamboo;
	const placeholder = databaseToAssign === 'bamboo' ? placeholderTrax : placeholderBamboo;

	const items = disabledAddNew ? options : [...options, { text: addNewText, value: 'addNew', anchor: 'top' as const }];
	let selectedRecord: ItemToMap<WageMetadata|CompanyLocationMetadata|CompanyDeductionsMetadata|CompanyEmploymentStatusMetadata|EmployeeRecordSectionMetadataNonPayrollEmployee> = {
		text: null,
		value: null,
		selectedRecordId: null,
		originalDatabase: 'bamboo',
		group: '',
		metadata: null,
		createValue: null,
		bambooRowId: null,
		traxRowId: null,
		clientId: null,
	};

	if (showOptions && selectedRecordId && selectedRecordId !== 'addNew') {
		selectedRecord = options.find(option => option.value === selectedRecordId);
	} else if (!showOptions) {
		selectedRecord = options[0];
	}
	const {
		text,
		metadata,
	} = selectedRecord;

	const topContent = showOptions ? (
		<Select
			isDisabled={ disableSelect }
			items={ items }
			onClear={ () => onChange(databaseToAssign, recordId, null) }
			onSelect={ value => onChange(databaseToAssign, recordId, value) }
			placeholder={ `-${ placeholder }-` }
			selectedValues={ [selectedRecordId] }
			width={ 100 }
		/>
	) : (
		<p className="MappingTableItem__displayValue">
			{ text }
		</p>
	);

	return (
		<div className="MappingTableItem">
			{ topContent }
			<MetadataComponent
				data={ metadata }
				databaseToAssign={ databaseToAssign }
				databaseToAssignItems={ databaseToAssignItems }
				disableSelect={ disableSelect }
				onChange={ onMetadataChange }
				recordId={ recordId }
				selectedRecordId={ selectedRecordId }
			/>
		</div>
	);
}
