import { Table } from '@bamboohr/fabric';

import { formatForDataTable } from './service';

export function ReviewTable(props) {
	const {
		columns,
		employeeData,
		employmentStatusAcaTypes,
	} = props;
	const reviewData = formatForDataTable(employeeData, employmentStatusAcaTypes);

	return (
		<Table
			caption="Benefits Enrollment Review"
			columns={ columns }
			rowKey={ row => row.employeeId }
			rows={ reviewData }
		/>
	);
}
