import Ajax from '@utils/ajax';

import { getReviewCycleData } from './get-review-cycle-data';

export const deleteReviewCycleConfiguration = (reviewConfigId: number): Promise<any> => {
	const payload = {
		reviewCycleConfigurationId: reviewConfigId,
	};

	return new Promise((resolve, reject) => {
		Ajax.delete('/settings/performance/home/reviewCycles/delete', payload)
			.then((response) => {
				const { data = {} } = response;

				if (data && data.success) {
					resolve(getReviewCycleData());
				}

				reject(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
