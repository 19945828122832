import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { Modal } from 'modal-legacy';
import { showSlidedown } from 'Alerts.mod';
import Ajax from '@utils/ajax';

import { PayeeNeeded } from './payee-needed';

import {
	getGarnishmentsWithoutPayees,
	validateGarnishmentsForSave,
	formatGarnishmentsForSave,
	MODAL_TITLE,
	HEADER_BY_TYPE,
} from './utils';

import './set-up-payees.styl';
import { ajax } from "@bamboohr/utils/lib/ajax";
import { ifFeature } from "@bamboohr/utils/lib/feature";
import {
	BadgeV2,
	BodyText,
	Button,
	Divider,
	Flex,
	FullScreenModal,
	Headline,
	LayoutBox,
	SheetModal,
	StyledBox,
	TextButton,
} from "@bamboohr/fabric";

export const SetUpPayees = (props) => {
	const {
		authToken,
		garnishments,
		garnishmentType,
		payees: initialPayees,
		type,
		visible,
		onSave,
		onClose,
		allowAddPayee,
		employees,
		onCancel,
	} = props;

	const [payeesNeeded, setPayeesNeeded] = useState(getGarnishmentsWithoutPayees(cloneDeep(garnishments)));
	const [payees, setPayees] = useState(initialPayees);
	const [selectedPayee, setSelectedPayee] = useState([]);
	const [selectedState, setSelectedState] = useState([]);
	const [showCancelAttempt, setShowCancelAttempt] = useState(false);
	const [showPayeeModal, setShowPayeeModal] = useState(false);
	const [allStates, setAllStates] = useState([]);
	const [federalStateObject, setFederalStateObject] = useState(null);

	const isChildSupport = useMemo(() => type === 'childSupport', [type]);

	const getDeductionType = useCallback(() => {
		if (type === 'childSupport') return 3;
		if (type === 'taxLevies') return 18;
		return 19;
	}, [type]);

	useEffect(() => {
		ajax.get('/ajax/get_states?country=1&includeFederal=true')
			.then((response) => {
				if (response.status === 200 && response.data && response.data.data) {
					const allStatesData = response.data.data;
					const federalStateData = allStatesData.find(state => state.name === 'ZZ');
					const nonFederalStateData = allStatesData.filter(state => state.name !== 'ZZ');
					setAllStates(nonFederalStateData);
					setFederalStateObject(federalStateData);
				}
			});
	}, []);

	useEffect(() => {
		setPayeesNeeded(getGarnishmentsWithoutPayees(cloneDeep(garnishments)));
	}, [garnishments]);

	const handleAddPayeeSuccess = (data) => {
		const newPayees = cloneDeep(payees);
		newPayees.push(data);
		setPayees(newPayees);
	};

	const handleConfirmCancel = () => {
		setPayeesNeeded(getGarnishmentsWithoutPayees(cloneDeep(garnishments)));
		setShowCancelAttempt(false);
		onCancel();
		onClose();
		window.setMessage($.__(`We'll leave this setting off until you're ready to add payees to your existing garnishments.`), 'info');
	};

	const handleGarnishmentFieldChange = (id, type, value) => {
		if (type === 'deductionPayeeId' && value === 'addPayeeModal') {
			setShowPayeeModal(true);
			return;
		}

		setPayeesNeeded(prevState => {
			const updatedPayeesNeeded = cloneDeep(prevState);
			updatedPayeesNeeded.byId[id][type] = value;

			if (type === 'deductionPayeeId') {
				updatedPayeesNeeded.byId[id].error = false;
			}

			return updatedPayeesNeeded;
		});
	};

	const handleSavePayees = () => {
		const { valid, garnishments, message } = validateGarnishmentsForSave(payeesNeeded, isChildSupport);

		if (!valid) {
			window.setMessage(message, 'error');
			setPayeesNeeded(garnishments);
			return;
		}

		const saveData = formatGarnishmentsForSave(payeesNeeded);

		if (saveData.length) {
			Ajax.put('/payroll/employee_non_benefit_deduction/batch_update_payees', { garnishmentType: type, employeeDeductions: saveData })
				.then((response) => {
					if (response.status === 200) {
						// Close this modal
						onClose();
						// Run save success callback
						onSave();
						window.location.reload();
					} else {
						showSlidedown($.__('Uh oh...something went wrong. Please try again later'), 'error');
					}
				})
				.catch(() => showSlidedown($.__('Uh oh...something went wrong. Please try again later'), 'error'));
		}
	};

	// @startCleanup encore
	const modalData = {
		contentHasPadding: false,
		isOpen: visible,
		onClose: () => setShowCancelAttempt(true),
		primaryAction: handleSavePayees,
		primaryActionText: $.__('Save'),
		title: MODAL_TITLE,
		header: MODAL_TITLE,
		headerType: 'text',
		icon: 'fab-money-circle-28x28',
		type: 'fullScreen',
		sheetProps: {
			isOpen: showCancelAttempt,
			primaryAction: handleConfirmCancel,
			primaryActionText: $.__(`Ok, I'll set it up later`),
			onClose: () => setShowCancelAttempt(false),
			title: $.__('Just so you know...'),
			header: <Headline color="neutral-strong" component="h4" size="small">{$.__(`We can't collect and pay garnishments until payees are entered.`)}</Headline>,
			icon: 'fab-triangle-exclamation-48x48',
			iconColor: 'attention',
			content: <Flex size="medium" textAlign="center">{ $.__(`Payees can also be added from an employee's Pay Info tab when adding a new non-benefit deduction`) }</Flex>,
		},
	};
	// @endCleanup encore

	const renderPayeesNeeded = (payeesNeeded, employees, payees) => {
		const { allIds, byId } = payeesNeeded;

		return allIds.map((id) => {
			const payeeNeeded = byId[id];

			return (
				<PayeeNeeded
					{ ...payeeNeeded }
					allStates={ allStates }
					allowAddPayee={ allowAddPayee }
					authToken={ authToken }
					clearNewPayeeId={ () => setSelectedPayee([]) }
					customPayees={ payees }
					deductionType={ getDeductionType() }
					employee={ employees.byId[payeeNeeded.employeeId] }
					federalStateObject={ federalStateObject }
					isChildSupport={ isChildSupport }
					isSetUpPayees={ true }
					key={ id }
					onFieldChange={ handleGarnishmentFieldChange }
					onPayeeSave={ handleAddPayeeSuccess }
					setShowPayeeAddModal={ setShowPayeeModal }
					setUpSetSelectedState={ setSelectedState }
				/>
			);
		});
	};

	const renderMainContent = (payeesNeeded, type, employees, payees) => {
		const labelFontColor = ifFeature('encore', 'neutral-strong', 'neutral-medium');

		return (
			<StyledBox
				backgroundColor='neutral-weak'
				borderRadius='medium'
				encoreOnly={true}
				height={ifFeature('encore', "90%")}
				marginX={4}
				marginY={3}
			>
				<LayoutBox
					paddingBottom={7.5}
					paddingLeft={4}
					paddingRight={ifFeature('encore', 4, 7.5)}
					paddingTop={3}
				>
					<LayoutBox marginBottom={3}>
						<LayoutBox encoreOnly={true} marginBottom={4}>
							{ifFeature(
								'encore',
								<BadgeV2
									icon="scissors-regular"
									size="large"
									title={$.__('Where do we send garnishment payments?')}
								/>,
								<Headline
									color="neutral-strong"
									size="extra-small"
									weight="semibold"
								>
									{$.__('Where do we send garnishment payments?')}
								</Headline>
							)}
						</LayoutBox>
						<Divider light />
					</LayoutBox>
					{ifFeature('encore',
						<Headline color="primary" size="extra-small" weight="semibold">{HEADER_BY_TYPE[type]}</Headline>,
						<BodyText color="primary" weight="bold">{HEADER_BY_TYPE[type]}</BodyText>
					)}
					<LayoutBox marginBottom={2.5}>
						<BodyText
							color="neutral-weak"
							size="small"
						>
							{$.__('We need a little more information to collect and pay garnishments.')}
						</BodyText>
					</LayoutBox>
					<StyledBox backgroundColor="neutral-strong" marginBottom={2}>
						<Flex
							alignItems="center"
							display="inline-flex"
							height={ifFeature('encore', "46px", "44px")}
							justifyContent="space-between"
							marginLeft={2}
							marginRight={3.75}
							marginY={0}
							width="251px"
						>
							<BodyText color={labelFontColor} weight="bold">{$.__('Name')}</BodyText>
							<BodyText color={labelFontColor} weight="bold">{$.__('Amount')}</BodyText>
						</Flex>
						<Flex display="inline-flex">
							<LayoutBox marginRight={2} width="143px">
								<BodyText color={labelFontColor} weight="bold">{$.__('Payee State')}</BodyText>
							</LayoutBox>
							<LayoutBox marginRight={2} width="367px">
								<BodyText color={labelFontColor} weight="bold">{$.__('Payee')}</BodyText>
							</LayoutBox>
							<LayoutBox marginRight={2} width={ifFeature('encore', "133px","113px")}>
								<BodyText color={labelFontColor} weight="bold">{$.__('Case#')}</BodyText>
							</LayoutBox>
							{isChildSupport && (
								<LayoutBox marginRight={2} width={ifFeature('encore', "165px", "143px")}>
									<BodyText color={labelFontColor} weight="bold">{$.__('Remittance ID*')}</BodyText>
								</LayoutBox>
							)}
							<LayoutBox>
								<BodyText color={labelFontColor} weight="bold">{$.__('Description')}</BodyText>
							</LayoutBox>
						</Flex>
					</StyledBox>
					{payeesNeeded && renderPayeesNeeded(payeesNeeded, employees, payees)}
				</LayoutBox>
			</StyledBox>
		);
	};

	return (
		<>
			{ifFeature(
				'encore',
				<FullScreenModal
					isOpen={visible}
					onRequestClose={() => setShowCancelAttempt(true)}
				>
					<FullScreenModal.Body
						renderFooter={(
							<FullScreenModal.Footer
								actions={[
									<Button
										key="primary"
										onClick={handleSavePayees}
										type="button"
									>
										{$.__(`Save`)}
									</Button>,
								]}
							/>
						)}
						renderHeader={(
							<FullScreenModal.Header
								hasCloseButton={true}
								title={MODAL_TITLE}
							/>
						)}
					>
						<FullScreenModal.Constraint>
							{renderMainContent(payeesNeeded, type, employees, payees)}
						</FullScreenModal.Constraint>
					</FullScreenModal.Body>
				</FullScreenModal>,
				<Modal {...modalData}>
					{renderMainContent(payeesNeeded, type, employees, payees)}
				</Modal>
			)}

			<SheetModal
				isOpen={showCancelAttempt}
				onRequestClose={() => setShowCancelAttempt(false)}
			>
				<SheetModal.Body
					renderFooter={(
						<SheetModal.Footer
							actions={[
								<TextButton
									key="cancel-button"
									onClick={() => setShowCancelAttempt(false)}
									type="button"
								>
									{$.__(`Cancel`)}
								</TextButton>,
								<Button
									key="ok-button"
									onClick={handleConfirmCancel}
									type="button"
								>
									{$.__(`Ok, I'll set it up later`)}
								</Button>,
							]}
						/>
					)}
					renderHeader={<SheetModal.Header hasCloseButton={true} title={$.__('Just so you know...')} />}
				>
					<SheetModal.Constraint>
						<SheetModal.HeroHeadline
							icon="triangle-exclamation-solid"
							iconColor="warning-medium"
							subtext={$.__(`Payees can also be added from an employee's Pay Info tab when adding a new non-benefit deduction`)}
							text={$.__(`We can't collect and pay garnishments until payees are entered.`)}
						/>
					</SheetModal.Constraint>
				</SheetModal.Body>
			</SheetModal>
		</>
	);
};