import { useEffect, useState } from 'react';
import { Select } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { buildDeductionSelectOptions } from 'benefits/deduction-date-select.react';
import { CHANGE_AVAILABILITY } from 'benefits/shared/constants';

import { DeductionDateOption } from './deduction-date-option';

export function DeductionDateSelect(props) {
	const {
		isDisabled,
		onSelectChange,
		placeholder,
		plan
	} = props;
	const [selectedValues, setSelectedValues] = useState([]);

	useEffect(() => {
		if (plan && plan.changeAvailability !== CHANGE_AVAILABILITY.NotOffered && plan.deductionRuleNumber && plan.deductionRuleDirection) {
			setSelectedValues([`${ plan.type }-${ plan.deductionRuleDirection }-${ plan.deductionRuleNumber }`]);
		}
	}, [plan]);

	function handleChange(value) {
		setSelectedValues(value);
		onSelectChange(value, plan);
	}

	/* @startCleanup encore */
	function handleRenderOption(item) {
		if (item && item.postValue) {
			return (
				<DeductionDateOption
					direction={item.postValue.deductionRuleDirection}
					number={item.postValue.deductionRuleNumber}
					showMostCommon={true}
				/>
			);
		}
	}
	/* @endCleanup encore */

	return (
		<Select
			isDisabled={isDisabled}
			items={buildDeductionSelectOptions(plan)}
			onChange={handleChange}
			placeholder={placeholder}
			renderOptionContent={ifFeature('encore', undefined, handleRenderOption)}
			selectedValues={selectedValues}
			size="small"
			tabindex="0"
			width="9"
		/>
	);
}
