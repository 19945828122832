import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ typography, palette }) => ({
	smsContainer: {
		marginTop: '27px',
		marginBottom: '5px',
		padding: '15px',
	},
	smsLegal: {
		fontSize: typography.teenie.fontSize,
		lineHeight: typography.teenie.lineHeight,
		color: palette.gray[700],
	},
	label: {
		marginRight: '4px',
	},
	boldCountdown: {
		fontWeight: typography.fontWeightSemibold,
		display: 'inline-block',
		marginRight: '3px',
		marginLeft: '3px',
	},
}));
