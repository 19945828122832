import { Label } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import c from 'classnames';
import { DropdownField } from './fields/dropdown-field';
import { EffectiveDateField } from './fields/effective-date-field';
import { InputField } from './fields/input-field';
import { PayFrequencyField } from './fields/pay-frequency-field';
import { StateField } from './fields/state-field';
import { TaxIdField } from './fields/tax-id-field';
import { TaxRateField } from './fields/tax-rate-field';
import { TaxTypeField } from './fields/tax-type-field';
import { TextareaField } from './fields/textarea-field';

import './company-tax-field.styl';
export function CompanyTaxField(props) {
	const {
		disabled,
		error,
		fieldType,
		hidden,
		label,
		name,
		required,
		unit,
	} = props;

	let editField;
	switch (fieldType) {
		case 'effectiveDate':
			editField = (
				<EffectiveDateField { ...props } />
			);
			break;

		case 'tax-id':
			editField = (
				<TaxIdField { ...props } />
			);
			break;

		case 'state':
			editField = (
				<StateField { ...props } />
			);
			break;

		case 'taxType':
			editField = (
				<TaxTypeField { ...props } />
			);
			break;

		case 'payFrequency':
			editField = (
				<PayFrequencyField { ...props } />
			);
			break;

		case 'taxRate':
			editField = (
				<TaxRateField { ...props } />
			);
			break;

		case 'select':
			editField = (
				<DropdownField { ...props } />
			);
			break;

		case 'textarea':
			editField = (
				<TextareaField { ...props } />
			);
			break;

		default:
			editField = (
				<InputField { ...props } />
			);
			break;
	}

	const companyTaxFieldClassNames = c(
		'CompanyTaxField',
		{ [`CompanyTaxField--${ name }`]: name },
		{ 'CompanyTaxField--disabled': disabled },
		{ 'CompanyTaxField--error': !disabled && error },
		{ 'CompanyTaxField--hidden': hidden }
	);

	const fabLabelClassNames = c(
		'fab-Label',
		{ 'fab-Label--required': required && !disabled },
		{ 'fab-Label--error': error }
	);

	return (
		<div className={ companyTaxFieldClassNames }>
			{ label && ifFeature('encore', 
				<Label htmlFor={ `${ name }-form` }>
					{ label }
					{ unit && `(${ unit })`}
				</Label>,
				<label className={ fabLabelClassNames } htmlFor={ `${ name }-form` }>
					{ label }
					{ unit && <span className="fab-Label__unit">{ `(${ unit })` }</span> }
				</label>
			) }
			<div className="CompanyTaxField__content">
				{ editField }
			</div>
		</div>
	);
}
