import { BodyText, Headline, LayoutBox, StyledBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import './banner-heading.styl';

export function BannerHeading({ initialChoice }) {
	return ifFeature(
		'encore',
		<StyledBox backgroundColor='neutral-extra-extra-weak' boxSizing='border-box' padding={2.5} width='100%' position='relative'>
			<LayoutBox marginBottom={0.5}>
				<Headline size='medium'>{initialChoice ? $.__('Thanks for your feedback!') : $.__('We want your feedback!')}</Headline>
			</LayoutBox>
			<BodyText size='extra-small' color='neutral-strong'>
				{initialChoice ? $.__('Just one follow-up question for you…') : $.__('Just a couple quick questions for you…')}
			</BodyText>
		</StyledBox>,
		<div className='EnpsBannerHeading'>
			<div className='EnpsBannerHeading__title'>
				{initialChoice ? $.__('Thanks for your feedback!') : $.__('We want your feedback!')}
			</div>
			<div className='EnpsBannerHeading__subtitle'>
				{initialChoice ? $.__('Just one follow-up question for you…') : $.__('Just a couple quick questions for you…')}
			</div>
		</div>
	);
}
