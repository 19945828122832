module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push('<!--  stateName  stateTaxDeductionsPermissions  stateViewState  additionalSettingsNeeded  fields -->  ');  if (perms.state === perms.PERM_LEVEL.VIEW) {   var stateViewState = true;  } 
p.push(' '); if (perms.isEncore) { 
p.push(' <fieldset data-state-tax="',window.microTemplate.encodeHtml(state),'" class="js-state-container js-state-withholding '); if (!selected) { 
p.push(' hidden '); } 
p.push(' ">  <h3 class="fab-Section__title">   ',window.microTemplate.encodeHtml($.__('%s State W-4 Withholding', stateName)),'   <span data-pop-title="W-4 Withholding" data-pop-content="This determines how much you will withhold from your paycheck for state taxes." data-pop-placement="bottom">    <ba-icon encore-color="neutral-medium" encore-name="circle-question-regular" encore-size="16"></ba-icon>   </span>   '); if (additionalSettingsNeeded) { 
p.push('   <span class="legendNote fab-FormNote">',window.microTemplate.encodeHtml($.__('There may be additional tax settings for you for this state. Get in touch with your HR admin for more information.')),'</span>   '); } 
p.push('  </h3>  <input type="hidden" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXE_FGE]" value="',window.microTemplate.encodeHtml(fields.ST_WH_EXE_FGE.value),'"/>  '); if (typeof(fields.ALTW4) != "undefined") { 
p.push('  <div class="fieldRow fab-FormRow">   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.ALTW4.displayName),'</label>    <div class="fieldDiv">     <ba-select width="5" name="state[',window.microTemplate.encodeHtml(state),'][ALTW4]" '); if (fields.ALTW4.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push(' class="js-pi-altw4 default-size">      ');       var possibleValues = fields.ALTW4.possibleValues;       for (var key in possibleValues) {        if (possibleValues.hasOwnProperty(key)) {      
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.ALTW4.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      ');  }       }      
p.push('     </ba-select>    </div>   </div>  </div>  '); } 
p.push('  <div class="fieldRow fab-FormRow">   '); if (typeof(fields.ST_STATUS != "undefined")) { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.ST_STATUS.displayName),'</label>    <div class="fieldDiv">     <ba-select width="5" name="state[',window.microTemplate.encodeHtml(state),'][ST_STATUS]" class="default-size" '); if (fields.ST_STATUS.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('>      ');       var possibleValues = fields.ST_STATUS.possibleValues;       for (var key in possibleValues) {        if (possibleValues.hasOwnProperty(key)) {      
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.ST_STATUS.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      '); }       }      
p.push('     </ba-select>    </div>   </div>   '); } 
p.push('   '); if (typeof(fields.ST_DEPS) != "undefined") { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.ST_DEPS.displayName),'</label>    <div class="fieldDiv">     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ST_DEPS]" class="xxxshort fab-TextInput fab-TextInput--width2" value="',window.microTemplate.encodeHtml(fields.ST_DEPS.value),'" '); if (fields.ST_DEPS.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>    </div>   </div>   '); } 
p.push('   '); if (typeof(fields.ITEMALLOW) != "undefined") { 
p.push('   <div class="fieldBox">    <label>',window.microTemplate.encodeHtml(fields.ITEMALLOW.displayName),'</label>    <div class="fieldDiv">     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ITEMALLOW]" class="xxxshort fab-TextInput fab-TextInput--width2" value="',window.microTemplate.encodeHtml(fields.ITEMALLOW.value),'" '); if (fields.ITEMALLOW.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>    </div>   </div>   '); } 
p.push('   '); if (typeof(fields.PERSONAL_EXE_NO) != "undefined") { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.PERSONAL_EXE_NO.displayName),'</label>    <div class="fieldDiv">     <ba-select width="5" name="state[',window.microTemplate.encodeHtml(state),'][PERSONAL_EXE_NO]" class="default-size" '); if (fields.PERSONAL_EXE_NO.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('>      ');       var possibleValues = fields.PERSONAL_EXE_NO.possibleValues;       for (var key in possibleValues) {        if (possibleValues.hasOwnProperty(key)) { 
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.PERSONAL_EXE_NO.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      '); }       }      
p.push('     </ba-select>    </div>   </div>   '); } 
p.push('        '); if (typeof(fields.MSDUALEXE) != "undefined") { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.MSDUALEXE.displayName),'</label>    <div class="fieldDiv">     <ba-select name="state[',window.microTemplate.encodeHtml(state),'][MSDUALEXE]" class="default-size" '); if (fields.MSDUALEXE.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('>      ');      var possibleValues = fields.MSDUALEXE.possibleValues;      for (var key in possibleValues) {       if (possibleValues.hasOwnProperty(key)) { 
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.MSDUALEXE.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      '); }       }      
p.push('     </ba-select>    </div>   </div>   '); } 
p.push('  </div>   '); if (typeof(fields.ST_WH_EXTRA) != "undefined") { 
p.push('  <div class="fieldRow fab-FormRow">   <label class="fab-FormField">',window.microTemplate.encodeHtml(fields.ST_WH_EXTRA.displayName),'</label>   <div class="fieldBox fab-FormField">    <div class="fieldDiv">     <div class="fab-TextToggle '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' fab-TextToggle--disabled '); } 
p.push('">      <input type="radio" id="stateWithholdExtraType-1-',window.microTemplate.encodeHtml(state),'" class="fab-TextToggle__input" onchange="BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-state-extra\')" data-currency-toggle="$" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][unit]" value="$" '); if (fields.ST_WH_EXTRA.value[1] === '$') { 
p.push(' checked '); } 
p.push(' '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>      <label for="stateWithholdExtraType-1-',window.microTemplate.encodeHtml(state),'" class="fab-TextToggle__label">$</label>      <input type="radio" id="stateWithholdExtraType-2-',window.microTemplate.encodeHtml(state),'" class="fab-TextToggle__input" onchange="BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-state-extra\')" data-currency-toggle="%" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][unit]" value="%" '); if (fields.ST_WH_EXTRA.value[1] === '%') { 
p.push(' checked '); } 
p.push(' '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>      <label for="stateWithholdExtraType-2-',window.microTemplate.encodeHtml(state),'" class="fab-TextToggle__label">%</label>      <span class="fab-TextToggle__handle"></span>     </div>    </div>   </div>   <div class="fieldBox">    <div class="fieldDiv">     '); if ( fields.ST_WH_EXTRA.value[1] === '$' ) { 
p.push('     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][value]" class="js-wholding-state-extra currency-field xxshort fab-TextInput fab-TextInput--width3" value="',window.microTemplate.encodeHtml(fields.ST_WH_EXTRA.value[0]),'" '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>     '); } else { 
p.push('     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][value]" class="js-wholding-state-extra percent-field xxshort fab-TextInput fab-TextInput--width3" value="',window.microTemplate.encodeHtml(fields.ST_WH_EXTRA.value[0]),'" '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>     '); } 
p.push('    </div>   </div>  </div>  '); } 
p.push(' </fieldset> '); } else { 
p.push(' <fieldset data-state-tax="',window.microTemplate.encodeHtml(state),'" class="js-state-container js-state-withholding '); if (!selected) { 
p.push(' hidden '); } 
p.push(' ">  <legend class="baseColor fab-FormSection__legend">   ',window.microTemplate.encodeHtml($.__('%s State W-4 Withholding', stateName)),'   <div class="semibold pi-withhold-help" data-pop-title="W-4 Withholding" data-pop-content="This determines how much you will withhold from your paycheck for state taxes." data-pop-placement="bottom" data-pop-content-css-override=\'{"maxWidth":"230px"}\'>?</div>   '); if (additionalSettingsNeeded) { 
p.push('   <span class="legendNote fab-FormNote">',window.microTemplate.encodeHtml($.__('There may be additional tax settings for you for this state. Get in touch with your HR admin for more information.')),'</span>   '); } 
p.push('  </legend>  <input type="hidden" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXE_FGE]" value="',window.microTemplate.encodeHtml(fields.ST_WH_EXE_FGE.value),'"/>  '); if (typeof(fields.ALTW4) != "undefined") { 
p.push('  <div class="fieldRow fab-FormRow">   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.ALTW4.displayName),'</label>    <div class="fieldDiv">     <ba-select width="5" name="state[',window.microTemplate.encodeHtml(state),'][ALTW4]" '); if (fields.ALTW4.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push(' class="js-pi-altw4 default-size">      ');       var possibleValues = fields.ALTW4.possibleValues;       for (var key in possibleValues) {        if (possibleValues.hasOwnProperty(key)) {      
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.ALTW4.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      ');  }       }      
p.push('     </ba-select>    </div>   </div>  </div>  '); } 
p.push('  <div class="fieldRow fab-FormRow">   '); if (typeof(fields.ST_STATUS != "undefined")) { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.ST_STATUS.displayName),'</label>    <div class="fieldDiv">     <ba-select width="5" name="state[',window.microTemplate.encodeHtml(state),'][ST_STATUS]" class="default-size" '); if (fields.ST_STATUS.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('>      ');       var possibleValues = fields.ST_STATUS.possibleValues;       for (var key in possibleValues) {        if (possibleValues.hasOwnProperty(key)) {      
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.ST_STATUS.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      '); }       }      
p.push('     </ba-select>    </div>   </div>   '); } 
p.push('   '); if (typeof(fields.ST_DEPS) != "undefined") { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.ST_DEPS.displayName),'</label>    <div class="fieldDiv">     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ST_DEPS]" class="xxxshort fab-TextInput fab-TextInput--width2" value="',window.microTemplate.encodeHtml(fields.ST_DEPS.value),'" '); if (fields.ST_DEPS.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>    </div>   </div>   '); } 
p.push('   '); if (typeof(fields.ITEMALLOW) != "undefined") { 
p.push('   <div class="fieldBox">    <label>',window.microTemplate.encodeHtml(fields.ITEMALLOW.displayName),'</label>    <div class="fieldDiv">     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ITEMALLOW]" class="xxxshort fab-TextInput fab-TextInput--width2" value="',window.microTemplate.encodeHtml(fields.ITEMALLOW.value),'" '); if (fields.ITEMALLOW.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>    </div>   </div>   '); } 
p.push('        '); if (typeof(fields.PERSONAL_EXE_NO) != "undefined") { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.PERSONAL_EXE_NO.displayName),'</label>    <div class="fieldDiv">     <ba-select width="5" name="state[',window.microTemplate.encodeHtml(state),'][PERSONAL_EXE_NO]" class="default-size" '); if (fields.PERSONAL_EXE_NO.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('>      ');      var possibleValues = fields.PERSONAL_EXE_NO.possibleValues;      for (var key in possibleValues) {       if (possibleValues.hasOwnProperty(key)) { 
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.PERSONAL_EXE_NO.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      '); }       }      
p.push('     </ba-select>    </div>   </div>   '); } 
p.push('        '); if (typeof(fields.MSDUALEXE) != "undefined") { 
p.push('   <div class="fieldBox">    <label class="fab-Label">',window.microTemplate.encodeHtml(fields.MSDUALEXE.displayName),'</label>    <div class="fieldDiv">     <ba-select name="state[',window.microTemplate.encodeHtml(state),'][MSDUALEXE]" class="default-size" '); if (fields.MSDUALEXE.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('>      ');      var possibleValues = fields.MSDUALEXE.possibleValues;      for (var key in possibleValues) {       if (possibleValues.hasOwnProperty(key)) { 
p.push('      <ba-option value="',window.microTemplate.encodeHtml(key),'" '); if (key == fields.MSDUALEXE.value) { 
p.push(' selected '); } 
p.push('>',window.microTemplate.encodeHtml(possibleValues[key]),'</ba-option>      '); }       }      
p.push('     </ba-select>    </div>   </div>   '); } 
p.push('  </div>   '); if (typeof(fields.ST_WH_EXTRA) != "undefined") { 
p.push('  <div class="fieldRow fab-FormRow">   <label class="fab-FormField">',window.microTemplate.encodeHtml(fields.ST_WH_EXTRA.displayName),'</label>   <div class="fieldBox fab-FormField">    <div class="fieldDiv">     <div class="bhrToggleSmall bhrToggle--btn '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' bhrToggle--btn--disabled '); } 
p.push('">      <input type="radio" id="stateWithholdExtraType-1-',window.microTemplate.encodeHtml(state),'" class="bhrToggle--btn-1" onchange="BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-state-extra\')" data-currency-toggle="$" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][unit]" value="$" '); if (fields.ST_WH_EXTRA.value[1] === '$') { 
p.push(' checked '); } 
p.push(' '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>      <label for="stateWithholdExtraType-1-',window.microTemplate.encodeHtml(state),'" class="bhrToggle__label bhrToggle--btn__label">$</label>      <input type="radio" id="stateWithholdExtraType-2-',window.microTemplate.encodeHtml(state),'" class="bhrToggle--btn-2" onchange="BambooHR.Utils.formatCurrencyPercentToggle(this, \'.js-wholding-state-extra\')" data-currency-toggle="%" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][unit]" value="%" '); if (fields.ST_WH_EXTRA.value[1] === '%') { 
p.push(' checked '); } 
p.push(' '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>      <label for="stateWithholdExtraType-2-',window.microTemplate.encodeHtml(state),'" class="bhrToggle__label bhrToggle--btn__label">%</label>      <span class="bhrToggle__handle bhrToggle--btn__handle brandedToggle"></span>     </div>    </div>   </div>   <div class="fieldBox">    <div class="fieldDiv">     '); if ( fields.ST_WH_EXTRA.value[1] === '$' ) { 
p.push('     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][value]" class="js-wholding-state-extra currency-field xxshort fab-TextInput fab-TextInput--width3" value="',window.microTemplate.encodeHtml(fields.ST_WH_EXTRA.value[0]),'" '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>     '); } else { 
p.push('     <input type="text" name="state[',window.microTemplate.encodeHtml(state),'][ST_WH_EXTRA][value]" class="js-wholding-state-extra percent-field xxshort fab-TextInput fab-TextInput--width3" value="',window.microTemplate.encodeHtml(fields.ST_WH_EXTRA.value[0]),'" '); if (fields.ST_WH_EXTRA.disabled || stateViewState) { 
p.push(' disabled '); } 
p.push('/>     '); } 
p.push('    </div>   </div>  </div>  '); } 
p.push(' </fieldset> '); } 
p.push(' ');}return p.join('');
}