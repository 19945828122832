import { BackupCodesContent } from 'multi-factor-authentication.mod';

import '../interstitial-backup-codes/interstitial-backup-codes.styl';

export interface InterstitialBackupCodesProps {
	onDone: () => void;
	userId: number;
	onStartProcessing(type: boolean): void;
	onEndProcessing(type: boolean): void;
	printRequestCount: number;
}

export function BackupCodesApp(props: InterstitialBackupCodesProps): JSX.Element {
	const { userId, onEndProcessing, onStartProcessing, printRequestCount } = props;

	return (
		<>
			<div className='InterstitialBackupCodes__content'>
				<div className='InterstitialBackupCodes__codes'>
					<BackupCodesContent
						onEndGeneratingNewCodes={() => onEndProcessing(false)}
						onStartGeneratingNewCodes={() => onStartProcessing(true)}
						printRequestCount={printRequestCount}
						userId={userId}
					/>
				</div>
			</div>
		</>
	);
}
