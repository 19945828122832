import React, { ReactElement } from 'react';
import { Button } from '@bamboohr/fabric';
import { PayrollAdmin } from 'access-levels.mod';

export function PayrollAdminSettings(): ReactElement {
	return (
		<Button
			color='secondary'
			data-bi-id='settings-access-levels-edit-settings'
			key='secondary'
			onClick={() => {
				PayrollAdmin.showSettingsModal();
			}}
			outline={true}
			size='small'
			text={$.__('Access Level Settings')}
			type='button'
		/>
	);
}
