import { StyledBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import { Component, Fragment } from 'react';
import { Action } from './action';
import './conduct-survey.styl';
import { SurveyPage } from './constants';
import { BannerHeading } from './heading';
import { MeteredTextarea } from './metered-textarea';
import { DetailAnswerPage, ScaleAnswerPage } from './page';
import { Question } from './question';
import { RadioScale } from './radio-scale';

const EmployeeType = {
	PROMOTER: 'promoter',
	NEUTRAL: 'neutral',
	DETRACTOR: 'detractor',
};

Object.freeze(EmployeeType);

export class ConductSurvey extends Component {
	constructor(props) {
		super(props);
		const { score } = props;

		this.state = {
			currentStep: SurveyPage.One,
			detailAnswer: '',
			isSaving: false,
			secondQuestionClassNames: '',
			selectedValue: score,
		};
	}

	_getAnswers() {
		const { screenSize } = this.props;
		const choices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
		const answers = choices.map((choice, index, originalChoices) => {
			let text = choice.toString();
			if (index === originalChoices[0]) {
				text = text.concat(` - ${$.__('Not Likely')}`);
			}
			if (index === originalChoices[originalChoices.length - 1]) {
				text = text.concat(` - ${$.__('Very Likely')}`);
			}
			return { text, value: choice };
		});
		if (screenSize.isSmallScreen) {
			return answers.reverse();
		}
		return answers;
	}

	_getEmployeeType(selectedValue) {
		if (selectedValue === null) {
			return EmployeeType.NEUTRAL;
		}
		switch (selectedValue) {
			case 10:
			case 9:
				return EmployeeType.PROMOTER;
			case 8:
			case 7:
				return EmployeeType.NEUTRAL;
			default:
				return EmployeeType.DETRACTOR;
		}
	}

	_getSecondQuestionText() {
		const { company } = this.props;
		const { selectedValue } = this.state;
		const employeeType = selectedValue >= 0 && selectedValue <= 10 ? this._getEmployeeType(selectedValue) : 'neutral';
		let question = '';
		switch (employeeType) {
			case EmployeeType.PROMOTER:
				question = $.__(`We're glad to hear you're likely to recommend %1. What makes this place so great?`, company);
				break;
			default:
				// case EmployeeType.NEUTRAL:
				// case EmployeeType.DETRACTOR:
				question = $.__('What changes could %1 make to be a better place to work?', company);
		}
		return question;
	}

	_shouldAnimate(selectedValue) {
		const { score } = this.props;

		if (score === null) return false;

		const employeeType = this._getEmployeeType(score);
		const newEmployeeType = this._getEmployeeType(selectedValue);

		if (employeeType !== EmployeeType.PROMOTER && newEmployeeType !== EmployeeType.PROMOTER) return false;

		return newEmployeeType !== employeeType;
	}

	_handleBackPress = () => {
		this.setState({ currentStep: SurveyPage.One });
	};

	_handleMeteredTextareaChange = (value) => {
		const { onTextChange } = this.props;
		onTextChange(value);
		return this.setState({ detailAnswer: value });
	};

	_handleRadioChange = (selected) => {
		const { onRadioChange } = this.props;
		const answer = selected.value;

		if (this._shouldAnimate(answer)) {
			this.setState({ secondQuestionClassNames: classNames('ConductSurvey__questionHeading--fadeOut') }, () => {
				setTimeout(() => {
					this.setState({ selectedValue: answer }, () => {
						this.setState({
							currentStep: SurveyPage.Two,
							secondQuestionClassNames: classNames('ConductSurvey__questionHeading--fadeIn'),
						});
					});
				}, 250);
			});
		} else {
			this.setState({ currentStep: SurveyPage.Two, selectedValue: answer });
		}
		onRadioChange(answer);
	};

	_handleSubmit = (e) => {
		const { onSubmit } = this.props;
		this.setState({ isSaving: true });

		onSubmit(e).catch(() => this.setState({ isSaving: false }));
	};

	render() {
		const radioChoices = this._getAnswers();
		const { company, initialChoice, score, screenSize, onAnonymityInfoClick } = this.props;
		const { currentStep, detailAnswer, isSaving, secondQuestionClassNames } = this.state;

		return ifFeature(
			'encore',
			<StyledBox
				backgroundColor='neutral-forced-white'
				borderColor='neutral-extra-weak'
				borderStyle='solid'
				borderRadius='large'
				boxShadow='large'
				overflow='hidden'
			>
				{screenSize.isSmallScreen ? (
					<Fragment>
						<ScaleAnswerPage
							company={company}
							currentPage={currentStep}
							initialChoice={initialChoice}
							onAnonymityInfoClick={onAnonymityInfoClick}
							onChange={this._handleRadioChange}
							radioChoices={radioChoices}
							score={score}
						/>
						<DetailAnswerPage
							currentPage={currentStep}
							detailAnswer={detailAnswer}
							isSaving={isSaving}
							onAnonymityInfoClick={onAnonymityInfoClick}
							onNavBack={this._handleBackPress}
							onSubmit={this._handleSubmit}
							onTextareaChange={this._handleMeteredTextareaChange}
							questionClassNames={secondQuestionClassNames}
							questionText={this._getSecondQuestionText()}
							score={score}
						/>
					</Fragment>
				) : (
					<Fragment>
						<BannerHeading initialChoice={initialChoice} />
						<ol className='ConductSurvey__questions'>
							<Question
								orderDisplay='1.'
								subtext={
									initialChoice
										? ifFeature(
												'encore',
												$.__(
													"You've already answered this question with a %1, but you can change your answer if you'd like.",
													initialChoice
												),
												$.__('You already answered %1 to this, but you can change it if you’d like.', initialChoice)
										  )
										: null
								}
								text={$.__('How likely are you to recommend %1 as a place to work?', company)}
							>
								<div className='ConductSurvey__scale'>
									<RadioScale choices={radioChoices} onChange={this._handleRadioChange} selectedValue={score} />
								</div>
							</Question>
							<Question
								isVisible={score || score === 0}
								orderDisplay='2.'
								text={this._getSecondQuestionText()}
								textClassNames={secondQuestionClassNames}
							>
								<MeteredTextarea onChange={this._handleMeteredTextareaChange} value={detailAnswer} />
							</Question>
						</ol>
						<Action
							isFormValid={detailAnswer && detailAnswer.length}
							isSaving={isSaving}
							isVisible={score || score === 0}
							onAnonymityInfoClick={onAnonymityInfoClick}
							onSubmit={this._handleSubmit}
							score={score}
						/>
					</Fragment>
				)}
			</StyledBox>,
			<div className='branded-border-bottom branded-border-top'>
				{screenSize.isSmallScreen ? (
					<Fragment>
						<ScaleAnswerPage
							company={company}
							currentPage={currentStep}
							initialChoice={initialChoice}
							onAnonymityInfoClick={onAnonymityInfoClick}
							onChange={this._handleRadioChange}
							radioChoices={radioChoices}
							score={score}
						/>
						<DetailAnswerPage
							currentPage={currentStep}
							detailAnswer={detailAnswer}
							isSaving={isSaving}
							onAnonymityInfoClick={onAnonymityInfoClick}
							onNavBack={this._handleBackPress}
							onSubmit={this._handleSubmit}
							onTextareaChange={this._handleMeteredTextareaChange}
							questionClassNames={secondQuestionClassNames}
							questionText={this._getSecondQuestionText()}
							score={score}
						/>
					</Fragment>
				) : (
					<Fragment>
						<BannerHeading initialChoice={initialChoice} />
						<ol className='ConductSurvey__questions'>
							<Question
								orderDisplay='1.'
								subtext={
									initialChoice
										? ifFeature(
												'encore',
												$.__(
													"You've already answered this question with a %1, but you can change your answer if you'd like.",
													initialChoice
												),
												$.__('You already answered %1 to this, but you can change it if you’d like. ', initialChoice)
										  )
										: null
								}
								text={$.__('How likely are you to recommend %1 as a place to work?', company)}
							>
								<div className='ConductSurvey__scale'>
									<RadioScale choices={radioChoices} onChange={this._handleRadioChange} selectedValue={score} />
								</div>
							</Question>
							<Question
								isVisible={score || score === 0}
								orderDisplay='2.'
								text={this._getSecondQuestionText()}
								textClassNames={secondQuestionClassNames}
							>
								<MeteredTextarea onChange={this._handleMeteredTextareaChange} value={detailAnswer} />
							</Question>
						</ol>
						<Action
							isFormValid={detailAnswer && detailAnswer.length}
							isSaving={isSaving}
							isVisible={score || score === 0}
							onAnonymityInfoClick={onAnonymityInfoClick}
							onSubmit={this._handleSubmit}
							score={score}
						/>
					</Fragment>
				)}
			</div>
		);
	}
}
