import { Ajax } from '@bamboohr/utils';

export const getQrLoginEnabledStatus = async () => {
	try {
		const response = await Ajax.get('/auth/mobile_qr');

		return response.data;
	} catch (error) {
		if (error.response?.status === 403) {
			throw new Error(error, { cause: error.response.status });
		}

		throw new Error(error);
	}
};
