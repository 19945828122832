import {
	useState
} from 'react';

import {
	Popover
} from '@bamboohr/fabric';

interface Props {
	anchor: string
}

export function EmployeesNavigationPopover(props: Props): JSX.Element {
	const {
		anchor
	} = props;

	const [isOpen, setIsOpen] = useState(true);

	return (
		<Popover
			anchorEl={ document.querySelector(anchor) }
			disableOuterClickOnClose={ true }
			onClose={ () => setIsOpen(false) }
			open={ isOpen }
			placement="top"
			title={ $.__('Done Adding Employees?') }
		>
			{ $.__('Complete the Setup Checklist to enable Time Tracking for your employees.') }
		</Popover>
	);
}
