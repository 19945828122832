import { BodyText, Flex, LayoutBox, StandardModal, Section, TextArea, TextButton, TextField } from '@fabric/button';

import { PureComponent, ReactElement } from 'react';
import { Message, SpanClass } from '@bamboohr/utils/lib/message';
import classNames from 'classnames';

import { ResetEmailModal } from './components/reset-email-template';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Errors {
	body?: boolean;
	subject?: boolean;
}

interface State {
	body: string;
	errorList?: Errors;
	isDefault: boolean;
	modalOpen?: boolean;
	subject: string;
	subjectInfoState: boolean;
	subjectNote: ReactElement;
}

interface Props extends State {
	handleReset: () => Promise<State>;
	handleStateChange: (newState?: State) => void;
}

export class EditEmailForm extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const { body = '', isDefault = true, subject = '' } = props;

		this.state = {
			body,
			errorList: {},
			isDefault,
			modalOpen: false,
			subject,
			subjectInfoState: false,
			subjectNote: null,
		};

		this._handleChange = this._handleChange.bind(this);
		this._resetEmail = this._resetEmail.bind(this);
	}

	_handleChange(event): void {
		const { body, errorList, subject } = this.state;

		const {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			handleStateChange = (): void => {},
		} = this.props;

		const newState = {
			body,
			errorList,
			isDefault: false,
			subject,
			subjectInfoState: false,
			subjectNote: null,
		};

		newState[event.target.dataset.stateKey] = event.target.value;
		newState.errorList[event.target.dataset.stateKey] = false;

		if (event.target.dataset.stateKey === 'subject') {
			if (event.target.value.length >= 200) {
				newState.errorList[event.target.dataset.stateKey] = true;
				newState.subjectNote = (
					<span>
						<Message
							params={[event.target.value.length]}
							text={$._(`Whoops, to make sure your subject doesn’t get cut off, keep it under 200 characters—you’re at {1} now.`)}
						/>
					</span>
				);
			} else if (event.target.value.length > 189 && event.target.value.length < 200) {
				newState.subjectInfoState = true;
				newState.subjectNote = (
					<span>
						<Message
							params={[event.target.value.length]}
							text={$._(`Head’s up! It’s best to keep the subject under 200 characters—you’re at {1} now.`)}
						/>
					</span>
				);
			}
		}

		newState.errorList[event.target.dataset.stateKey] = newState.errorList[event.target.dataset.stateKey] || !event.target.value;

		this.setState(newState, () => {
			handleStateChange(newState);
		});
	}

	_resetEmail(): void {
		const { handleReset, handleStateChange } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		try {
			handleReset()
				.then((emailData) => {
					const { body, subject } = emailData;

					const newState = {
						body,
						isDefault: true,
						modalOpen: false,
						subject,
						subjectInfoState: false,
						subjectNote: null,
					};

					self.setState(newState, () => {
						handleStateChange(newState);
					});
				})
				.catch((error) => {
					window.setMessage($.__('An error has occurred getting the default message, please try again'), 'error');
					throw error;
				});
		} catch (error) {
			console.error('Expected a promise during reset');
		}
	}

	render(): ReactElement {
		const { isDefault: initialDefault } = this.props;

		const {
			body = '',
			errorList = {},
			isDefault,
			modalOpen = false,
			subject = '',
			subjectInfoState = false,
			subjectNote,
		} = this.state;

		const { body: bodyErrorState = false, subject: subjectErrorState = false } = errorList;

		const bodyLabelClasses = classNames('fab-Label', {
			'fab-Label--error': bodyErrorState,
		});

		const bodyInputClasses = classNames('fab-Textarea', 'fab-Textarea--width10', 'EditEmailForm__messageBody', {
			'fab-Textarea--error': bodyErrorState,
		});

		const subjectLabelClasses = classNames('fab-Label', {
			'fab-Label--error': subjectErrorState,
			'fab-Label--info': subjectInfoState,
		});

		const subjectInputClasses = classNames('fab-TextInput', 'fab-TextInput--width10', 'EditEmailForm__subjectLine', {
			'fab-TextInput--error': subjectErrorState,
			'fab-TextInput--info': subjectInfoState,
		});

		const subjectNoteClasses = classNames('fab-FormNote', {
			'fab-FormNote--error': subjectErrorState,
			'fab-FormNote--info': subjectInfoState,
		});

		return ifFeature(
			'encore',
			<Section paddingTop='24px' height='100%'>
				<Flex alignItems='start' flexDirection='column' gap={2.5}>
					<LayoutBox textAlign='start'>
						<BodyText color='neutral-extra-strong' weight='regular' inline>
							{$._(
								'Customize the welcome email that goes out to your employees to speak in your company voice and make it your own. You can even personalize it by including any of the placeholders '
							)}
						</BodyText>
						<BodyText color='primary' inline weight='regular'>
							{$.__('[First Name]')}
						</BodyText>
						<BodyText color='neutral-extra-strong' inline weight='regular'>
							,{' '}
						</BodyText>
						<BodyText color='primary' inline weight='regular'>
							{$.__('[Last Name]')}
						</BodyText>
						<BodyText color='neutral-extra-strong' inline weight='regular'>
							,{' '}
						</BodyText>
						<BodyText color='primary' component='strong' inline weight='regular'>
							{$.__('[Job Title]')}
						</BodyText>
						<BodyText color='neutral-extra-strong' inline weight='regular'>
							,{' '}
						</BodyText>
						<BodyText color='primary' component='strong' inline weight='regular'>
							{$.__('[Department]')}
						</BodyText>
						<BodyText color='neutral-extra-strong' inline weight='regular'>
							{$.__(' or ')}
						</BodyText>
						<BodyText color='primary' component='strong' inline weight='regular'>
							{$.__('[Company Name]')}
						</BodyText>
						<BodyText color='neutral-extra-strong' inline weight='regular'>
							{$._(' and they will automagically be filled in when the New Hire Packet is created.')}
						</BodyText>
					</LayoutBox>
					<TextField
						id='subjectLine'
						label={$.__('Subject')}
						onChange={(e) => {
							this._handleChange({ ...e, target: { ...e.target, dataset: { stateKey: 'subject' } } });
						}}
						value={subject}
						width={10}
					/>
					<TextArea
						id='messageBody'
						label={$.__('Email Message')}
						multiline
						onChange={(e) => {
							this._handleChange({ ...e, target: { ...e.target, dataset: { stateKey: 'body' } } });
						}}
						value={body}
						width={10}
					/>
					{!isDefault && (
						<TextButton
							color='secondary'
							onClick={() => {
								this.setState({ modalOpen: true });
							}}
							startIcon='arrow-rotate-right-regular'
							size='small'
							title={$.__('Reset to Default')}
							type='button'
						>
							{$.__('Reset to Default')}
						</TextButton>
					)}
					<ResetEmailModal
						isOpen={modalOpen}
						onClose={() => {
							this.setState({ modalOpen: false });
						}}
						primaryAction={this._resetEmail}
					/>
				</Flex>
			</Section>,
			<div style={{ padding: '24px 24px' }}>
				<div className='FormInstructions'>
					<Message
						em={SpanClass(`FormInstructions__keyWords`)}
						params={['[First Name]', '[Last Name]', '[Job Title]', '[Department]', '[Company Name]']}
						text={$._(
							'Customize the welcome email that goes out to your employees to speak in your company voice and make it your own. You can even personalize it by including any of the placeholders *{1}*, *{2}*, *{3}*, *{4}* or *{5}* and they will automagically be filled in when the New Hire Packet is created.'
						)}
					/>
				</div>
				<form className='fab-Form EditEmailForm'>
					<div className='fab-FormRow'>
						<div className='fab-FormColumn'>
							<label className={subjectLabelClasses} htmlFor='subjectLine'>
								<Message text={$._('Subject')} />
							</label>
							<input
								className={subjectInputClasses}
								data-state-key='subject'
								id='subjectLine'
								name='subjectLine'
								onChange={this._handleChange}
								type='text'
								value={subject}
							/>
							{subjectNote && <span className={subjectNoteClasses}>{subjectNote}</span>}
						</div>
					</div>
					<div className='fab-FormRow finalFormRow'>
						<div className='fab-FormColumn'>
							<label className={bodyLabelClasses} htmlFor='messageBody'>
								<Message text={$._('Email Message')} />
							</label>
							<textarea
								className={bodyInputClasses}
								data-state-key='body'
								id='messageBody'
								name='messageBody'
								onChange={this._handleChange}
								value={body}
							/>
						</div>
					</div>
				</form>
				{!isDefault && (
					<div className='EditEmailFormActions'>
						<TextButton
							clickAction={() => {
								this.setState({ modalOpen: true });
							}}
							iconBefore='fab-circle-arrow-14x14'
							muted={true}
							size='small'
							text={$.__('Reset to Default')}
							type='button'
						/>
					</div>
				)}
				<ResetEmailModal
					isOpen={modalOpen}
					onClose={() => {
						this.setState({ modalOpen: false });
					}}
					primaryAction={this._resetEmail}
				/>
			</div>
		);
	}
}
