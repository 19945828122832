import {
	clientTaxSettingName_suppressCourtesyTax,
} from '../constants';
import type {
	ClientTaxSettingsResponseDataCollection,
	ClientTaxSettingSuppressCourtesyTax,
} from '../types';

export function findSuppressCourtesyTax(clientTaxSettings: ClientTaxSettingsResponseDataCollection): ClientTaxSettingSuppressCourtesyTax | null {
	if (!Array.isArray(clientTaxSettings)) {
		return undefined;
	}
	const tax = clientTaxSettings.find((clientTaxSetting) => {
		return clientTaxSetting.settingName === clientTaxSettingName_suppressCourtesyTax;
	});
	return tax as ClientTaxSettingSuppressCourtesyTax;
}
