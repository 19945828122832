import {
	createStore,
	defaults as rssDefaults,
} from 'react-sweet-state';

import {
	fetchAllClientTaxSettings,
} from './actions/fetch-all-client-tax-settings';
import {
	saveAllClientTaxSettings,
} from './actions/save-all-client-tax-settings';
import {
	setClientTaxSetting,
} from './actions/set-client-tax-setting';
import type {
	CompanyTaxSettingsActions,
	CompanyTaxSettingsStore,
} from '../types';

rssDefaults.devtools = true;

export const companyTaxSettingsStore = createStore<CompanyTaxSettingsStore, CompanyTaxSettingsActions>({
	actions: {
		fetchAllClientTaxSettings,
		saveAllClientTaxSettings,
		setClientTaxSetting,
	},
	name: 'companyTaxSettingsStore',
	initialState: {
		clientEin: null,
		clientName: null,
		clientTaxSettings: [],
		isErrorClientTaxSettings: false,
		isLoadingClientTaxSettings: true,
		isProcessingClientTaxSettings: false,
	},
});
