import { render } from 'base/wrapped-render';
import { Provider } from 'react-redux';

import Ctrl, {
	onClick,
	onFooterAction,
	onPjaxEnd,
	onReady
} from 'ctrl.deco';

import { WorkflowsAppContainer } from './containers/workflows-app';
import { createWorkflowStore } from './store';
import { SUBNAV_ITEMS_SUCCESS } from './store/actionTypes';
import {
	getWorkflowsSubNav,
	previewEditApprovalModal,
	showPreviewModal
} from './utils';

import './styles.styl';


import Employees from 'Employees.mod';

window.Employees = Employees;

@Ctrl('/settings/workflows')
class Approvals {
	_workflowStore = createWorkflowStore();

	@onPjaxEnd()
	@onReady()
	_ready(event, ctrl) {
		const workflowStore = ctrl._workflowStore;
		getWorkflowsSubNav().then(({ data }) => {
			const {
				customApprovalPerm = false,
				workflows = {}
			} = data;
			workflowStore.dispatch({
				payload: {
					customApprovalEnabled: customApprovalPerm,
					subNavItems: workflows
				},
				type: SUBNAV_ITEMS_SUCCESS
			});
			render(
				<Provider
					store={ workflowStore }
				>
					<WorkflowsAppContainer />
				</Provider>
				, document.getElementById('workflowsReactRoot'));
		});
	}

	static setup() {
		document.body.classList.add('SettingsApprovals');
	}

	static teardown() {
		document.body.classList.remove('SettingsApprovals');
	}
}

@Ctrl('/settings/workflows/custom/*')
class CreateCustomApproval {
	@onClick('.js-workflow-preview-form')
	_workflowPreviewFormOnClick(event, ctrl) {
		const workflowId = event.target.dataset.workflowId || null;

		previewEditApprovalModal(workflowId)
			.then(response => showPreviewModal(response))
			.catch(window.errorFallBack);
	}

	@onFooterAction('primary')
	_createNewCustomApproval() {
		$('#report-wizard-form').bhrValidOnClick();
	}
}
