import {
	ReimbursementFrequencyEnumContract,
} from '../types';
import {
	PlanTypeEnumContract,
} from '../../types';

export const NAME_PLACEHOLDERS = {
	[PlanTypeEnumContract.health]: $.__('e.g. SelectHealth - Platinum'),
	[PlanTypeEnumContract.dental]: $.__('e.g. Dental Select'),
	[PlanTypeEnumContract.vision]: $.__('e.g. Humana - Vision Insurance'),
	[PlanTypeEnumContract.disability]: $.__('e.g. Aetna Disability'),
	[PlanTypeEnumContract.flex]: $.__('e.g. Blue Cross - FSA'),
	[PlanTypeEnumContract.hsa]: $.__('e.g. Blue Cross - HSA'),
	[PlanTypeEnumContract.supplemental]: $.__('e.g. Aflac - Health Insurance'),
	[PlanTypeEnumContract.retirement]: $.__('e.g. Wealthfront - 401(k)'),
	[PlanTypeEnumContract.reimbursement]: $.__('e.g. Tuition Reimbursement'),
	[PlanTypeEnumContract.life]: $.__('e.g. Colonial Life Insurance'),
	[PlanTypeEnumContract.other]: $.__('e.g. Sponsored Fitness Program'),
};

export const DESCRIPTION_PLACEHOLDERS = {
	[PlanTypeEnumContract.disability]: $.__('e.g. Covers 60%% of current salary'),
	[PlanTypeEnumContract.flex]: $.__('e.g. Company matches up to 6%%'),
	[PlanTypeEnumContract.hsa]: $.__('e.g. Company matches up to 6%%'),
	[PlanTypeEnumContract.retirement]: $.__('e.g. Company matches up to 6%%'),
	[PlanTypeEnumContract.reimbursement]: $.__('e.g. Receipts must be turned in to accounting.'),
	[PlanTypeEnumContract.life]: $.__('e.g. $500,000 term coverage'),
	[PlanTypeEnumContract.other]: $.__('e.g. Company events are announced monthly'),
};

export const REIMBURSEMENT_FREQUENCY_LABELS: {
	[key in ReimbursementFrequencyEnumContract]: string
} = {
	[ReimbursementFrequencyEnumContract.month]: $.__('Every month'),
	[ReimbursementFrequencyEnumContract.once]: $.__('One time'),
	[ReimbursementFrequencyEnumContract.payPeriod]: $.__('Every pay period'),
	[ReimbursementFrequencyEnumContract.quarter]: $.__('Every quarter'),
	[ReimbursementFrequencyEnumContract.year]: $.__('Every year'),
};
