import 'email-alerts.mod';
import './pto-balance';
import './add-edit';
import './styles.styl';

import Ctrl, { onAction, onDropdownSelect } from 'ctrl.deco';

import Ajax from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';

@Ctrl('/settings/alerts.php')
class SettingsAlertsCtrl {
	@onDropdownSelect('#SettingsAlerts__addAlertDropdown')
	_onAddAlertSelect({ detail: { value } }, ctrl) {
		window.location.href = value;
	}

	@onAction('deleteEmailAlert')
	_onDeleteEmailAlert(e, ctrl, id, name) {
		window.BambooHR.Modal.setState({
			isOpen: true,
			icon: ifFeature('encore', 'trash-can-regular', 'fab-trash-can-43x48'),
			iconColor: ifFeature('encore', 'error-strong', 'danger'),
			iconV2Color: ifFeature('encore', 'error-strong', 'danger'),
			title: $.__('Delete Email Alert'),
			headline: $.__('Are you sure you want to delete "%1$s"?', name),
			primaryActionText: $.__('Delete This Email Alert'),
			primaryAction() {
				window.BambooHR.Modal.setState(
					{
						isProcessing: true,
					},
					true
				);

				Ajax.delete('/settings/alerts/edit.php', null, {
					params: { id },
				})
					.then(() => {
						window.BambooHR.Modal.setState({
							isOpen: false,
						});

						window.location.reload();
					})
					.catch((err) => {
						window.BambooHR.Modal.setState(
							{
								isProcessing: false,
							},
							true
						);

						window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
						console.error(err);
					});
			},
			onClose() {
				window.closeMessage();
			},
		});
	}
}
