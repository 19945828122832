import {
	stateOhioCourtesyTaxRadioOptionCollect,
	stateOhioCourtesyTaxRadioOptionDoNotCollect,
} from '../constants';
import {
	L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_COLLECT,
	L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_DO_NOT_COLLECT,
} from '../language';

export const stateOhioCourtesyTaxRadioOptions = [
	{
		id: stateOhioCourtesyTaxRadioOptionCollect,
		label: L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_COLLECT,
		value: stateOhioCourtesyTaxRadioOptionCollect,
	},
	{
		id: stateOhioCourtesyTaxRadioOptionDoNotCollect,
		label: L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_DO_NOT_COLLECT,
		value: stateOhioCourtesyTaxRadioOptionDoNotCollect,
	},
];
