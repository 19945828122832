import {
	CompanyDeductionsMetadataProps,
} from '../../utils/interfaces';

import './company-deductions-metadata.styl';

export function CompanyDeductionsMetadata(props: CompanyDeductionsMetadataProps): JSX.Element {
	const {
		data,
	} = props;

	if (!data) {
		return (
			<div className="CompanyDeductionMetadata--blank"></div>
		);
	}

	const {
		endDate,
	} = data;

	return (
		<div className="CompanyDeductionMetadata">
			<div className="CompanyDeductionMetadata__labelContainer">
				<p className="CompanyDeductionMetadata__label">{ $.__('Deduction End') }:</p>
			</div>

			<div className="CompanyDeductionMetadata__valueContainer">
				<p className="CompanyDeductionMetadata__value">{ endDate || '--' }</p>
			</div>
		</div>
	);
}
