import React, { useState, createContext } from 'react';
import { SlidedownState, SlidedownStatus } from './types';

const useSlidedown = (): SlidedownState => {
	const [visible, setVisible] = useState(false);
	const [status, setStatus] = useState<SlidedownStatus>();

	return {
		visible,
		showError: () => {
			setVisible(true);
			setStatus(SlidedownStatus.Error);
		},
		hide: () => {
			setVisible(false);
		},
		showSuccess: () => {
			setVisible(true);
			setStatus(SlidedownStatus.Success);
		},
		status,
	};
};

export const SlidedownContext = createContext<SlidedownState>(null);

export const SlidedownProvider: React.FC = ({children}) => {
	const slidedown = useSlidedown();
    return <SlidedownContext.Provider value={slidedown}>{children}</SlidedownContext.Provider>;
};
