export enum PlanTypeEnumContract {
	health = 'health',
	dental = 'dental',
	vision = 'vision',
	supplemental = 'supplemental',
	retirement = 'retirement',
	reimbursement = 'reimbursement',
	hsa = 'hsa',
	flex = 'flex',
	life = 'life',
	disability = 'disability',
	other = 'other',
}

export enum DeductionFrequencyEnumContract {
	daily = 'Daily',
	weekly = 'Weekly',
	fourTimesAMonth = 'Four times a month',
	everyOtherWeek = 'Every other week',
	twiceAMonth = 'Twice a month',
	monthly = 'Monthly',
	quarterly = 'Quarterly',
	twiceAYear = 'Twice a year',
	yearly = 'Yearly',
}

export enum CostTypeEnumContract {
	fixed = '$',
	percent = '%',
}

export enum CostTypeStringEnum {
	fixed = 'FIXED',
	percent = 'PERCENT',
}

export const coverageOptionsPlanTypes: PlanTypeEnumContract[] = [
	PlanTypeEnumContract.health,
	PlanTypeEnumContract.dental,
	PlanTypeEnumContract.vision,
	PlanTypeEnumContract.supplemental,
];
