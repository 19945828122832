import moment from 'moment';
import Tooltip from 'tooltip.react';
import { cloneDeep, chunk, deburr } from 'lodash';
import { BodyText, Flex, Icon, IconV2, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { hasWordStartingWith } from '@utils/string';
import c from 'classnames';
import { isEnabled } from 'FeatureToggle.util';
import { getRowAction, getRowStatusButton } from './getRowAction';
import { NATooltip } from './components';

const cttiRefactorEnabled = isEnabled('cttiRefactor');
const tpauEnabled = isEnabled('TPAU_VIEW_ACCESS');

const NAExemptTaxTypes = ['Federal', 'FUTA'];

function isNAFederalTax(row) {
	return row.state === 'ZZ' && row.frequencyOfPayment === 'N/A';
}

function hasNATooltip(row) {
	return isNAFederalTax(row) && !NAExemptTaxTypes.includes(row.taxTypeName);
}

function getRowFrequency(row) {
	const isUnset = isNAFederalTax(row) && NAExemptTaxTypes.includes(row.taxTypeName);

	return isUnset ? 'Not set' : row.frequencyOfPayment;
}

export function getTaxesTableColumns(handleRowAction, isTaxFrequencyLocked, refreshTableData, tableType) {
	const tableColumns = [
		{
			key: 'Frequency',
			header: $.__('Frequency'),
			cell: (row) => {
				// Only display tooltip next to N/A frequencies on Federal taxes
				return hasNATooltip(row) ? <NATooltip /> : <div>{getRowFrequency(row)}</div>;
			},
			sortBy: (row) => row.frequencyOfPayment,
		},
		{
			key: 'Payer',
			header: $.__('Payer'),
			cell: (row) => <div>{row.payer}</div>,
			sortBy: (row) => row.payer,
		},
		{
			key: 'Rate',
			header: $.__('Rate'),
			cell: (row) => renderTaxRate(row),
			sortBy: (row) => row.taxRate,
		},
		{
			key: 'Start/End',
			header: $.__('Start/End'),
			cell: (row) => renderDateCell(row.taxStartDate, row.taxEndDate),
			sortBy: (row) => row.startDateUnix,
		},
		{
			key: 'Tax Row Actions',
			headerAriaLabel: 'tax row action',
			cell: (row) => getRowAction(row, handleRowAction, isTaxFrequencyLocked, refreshTableData),
			showOnHover: true,
			cellPaddingReset: 'vertical',
		},
	];

	if (tpauEnabled) {
		// Render the CTA column for status buttons
		if (tableType === "setupTaxes") {
			tableColumns.splice(tableColumns.length - 1, 0, {
				key: 'Call to Actions',
				headerAriaLabel: 'tax call to action',
				cell: (row) => <>
					{row.currentTaxSetupStatus 
						? getRowStatusButton(
							row.currentTaxSetupStatus.currentStatus,
							row.currentTaxSetupStatus.daysUntilEvent,
							row.currentTaxSetupStatus.event, 
							row)
					: null}
					</>,
				cellPaddingReset: 'all',
			})
		}

		tableColumns.unshift(
			{
				key: 'Tax Type/ID',
				header: $.__('Tax Type/ID'),
				cell: (row) => <Flex flexDirection="column">
						<div>{row.taxTypeName}</div>
						<div>{renderTaxIdCell(row.clientTaxStatus, row.employerTaxId)}</div>
						{tableType === "setupTaxes" && row.currentTaxSetupStatus ? getRowStatusLabel(row.currentTaxSetupStatus.currentStatus, row.currentTaxSetupStatus.daysUntilEvent, row.currentTaxSetupStatus.event) : null}
					</Flex>,
				sortBy: (row) => { return tableType === "setupTaxes" ? row.currentTaxSetupStatus.priority : row.taxTypeName },
			}
		);
	} else {
		tableColumns.unshift(
			{
				key: 'Tax Type',
				header: $.__('Tax Type'),
				cell: (row) => <div>{row.taxTypeName}</div>,
				sortBy: (row) => row.taxTypeName,
			},
			{
				key: 'Tax ID',
				header: $.__('Tax ID'),
				cell: (row) => renderTaxIdCell(row.clientTaxStatus, row.employerTaxId),
				sortBy: (row) => row.employerTaxId,
				width: '135px',
			}
		);
	}

	return tableColumns;
}

function renderTaxRate(row) {
	const { taxRate, isWorkersComp } = row;

	if (isWorkersComp) {
		return $.__n('%1$s Rate', '%1$s Rates', taxRate);
	}

	const formattedTaxRate = +parseFloat(taxRate).toFixed(4);

	const className = c('TaxesTable__cell', { 'TaxesTable__cell--undefined': !formattedTaxRate });

	return <div className={className}>{formattedTaxRate ? `${formattedTaxRate}%` : '--'}</div>;
}

function renderTaxIdCell(clientTaxStatus, employerTaxId) {
	if(!tpauEnabled){
		if (cttiRefactorEnabled) {
			if (clientTaxStatus === 'Applied' && employerTaxId === '') {
				const tooltipOptions = {
					template: {
						name: 'tooltip-standard',
						data: {
							title: $.__('Missing Tax ID'),
							content: $.__('Add your Tax ID as soon as possible to avoid incurring fees.'),
							textAlign: 'center',
						},
					},
				};

				return (
					<div className='TaxesTable__appliedForCell'>
						<Tooltip settings={tooltipOptions}>
							{ ifFeature('encore', <IconV2 color="warning-strong" name="triangle-exclamation-solid" size={16} />, <span className='TaxesTable__appliedForCellIcon'>
								<Icon name='fab-triangle-exclamation-13x11' />
							</span>)}
						</Tooltip>
						<span className='TaxesTable__appliedForCellText'>{$.__('Applied For')}</span>
					</div>
				);
			}
		} else if (employerTaxId === 'Applied For') {
			const tooltipOptions = {
				template: {
					name: 'tooltip-standard',
					data: {
						title: $.__('Missing Tax ID'),
						content: $.__('Add your Tax ID as soon as possible to avoid incurring fees.'),
						textAlign: 'center',
					},
				},
			};

			return (
				<div className='TaxesTable__appliedForCell'>
					<Tooltip settings={tooltipOptions}>
						{ ifFeature('encore', <IconV2 color="warning-strong" name="triangle-exclamation-solid" size={16} />, <span className='TaxesTable__appliedForCellIcon'>
							<Icon name='fab-triangle-exclamation-13x11' />
						</span>)}
					</Tooltip>
					<span className='TaxesTable__appliedForCellText'>{$.__('Applied For')}</span>
				</div>
			);
		}
	}

	return <div>{employerTaxId}</div>;
}

function renderDateCell(startDate, endDate) {
	return (
		<div className='TaxesTable__cell'>
			<div className={`TaxesTable__cell${startDate ? '' : '--undefined'}`}>{startDate || '--'}</div>
			<div className={`TaxesTable__cell${endDate ? '' : '--undefined'}`}>{endDate || '--'}</div>
		</div>
	);
}

export function paginateRowData(rowData) {
	return chunk(rowData, 50);
}

function findLatestDatedTaxes(taxesByEin) {
	const latestDatedTaxes = {};

	taxesByEin.forEach((ein) => {
		const { clientId } = ein;
		latestDatedTaxes[clientId] = {};

		ein.clientIncludedTaxes.forEach((tax) => {
			const { taxEndDate, taxStartDate, taxTypeName } = tax;

			const newStartDate = moment(taxStartDate, 'M/D/YYYY');
			const newEndDate = moment(taxEndDate, 'M/D/YYYY');

			if (!newEndDate.isBefore(newStartDate)) {
				if (latestDatedTaxes[clientId][taxTypeName]) {
					const existingStartDate = moment(latestDatedTaxes[clientId][taxTypeName].taxStartDate, 'M/D/YYYY');

					if (newStartDate.isAfter(existingStartDate)) {
						latestDatedTaxes[clientId][taxTypeName] = {
							taxStartDate,
						};
					}
				} else {
					latestDatedTaxes[clientId][taxTypeName] = {
						taxTypeName,
						taxStartDate,
					};
				}
			}
		});
	});

	return latestDatedTaxes;
}

export function getTaxesTableRows(taxesByEin) {
	const latestDatedTaxes = findLatestDatedTaxes(taxesByEin);

	const tableRows = [];

	taxesByEin.forEach((ein) => {
		ein.clientIncludedTaxes.forEach((tax) => {
			const taxCopy = cloneDeep(tax);

			if (
				latestDatedTaxes[ein.clientId][tax.taxTypeName] &&
				latestDatedTaxes[ein.clientId][tax.taxTypeName].taxStartDate === tax.taxStartDate
			) {
				taxCopy.latestDatedTax = true;
			} else {
				taxCopy.latestDatedTax = false;
			}

			taxCopy.group = ein.clientId;
			taxCopy.startDateUnix = Number(moment(tax.taxStartDate, 'MM/DD/YYYY').format('X'));
			taxCopy.lastModifiedUnix = Number(moment(tax.lastModifiedDate, 'MM/DD/YYYY').format('X'));

			tableRows.push(taxCopy);
		});
	});

	return tableRows;
}

export function getTaxesTableGroups(taxesByEin) {
	if (taxesByEin.length <= 1) {
		// Returns an empty array to prevent the Table from throwing an error when receiving null.
		return [];
		// return null;
	}

	const groups = [];

	taxesByEin.forEach((company) => {
		const { clientId, clientName, clientEin } = company;

		// Add EIN as a row group
		groups.push({
			id: clientId,
			icon: ifFeature('encore', <IconV2 name='buildings-regular' size={16} />, 'fab-company-12x14'),
			content: (
				<span>
					{clientName} ({clientEin})
				</span>
			),
		});
	});

	return groups;
}

export function formatTableData(companyTaxes, filters, tableType) {
	const filteredTaxes = filterTaxes(companyTaxes, filters, tableType);
	const rowData = getTaxesTableRows(filteredTaxes);
	const paginatedRowData = paginateRowData(rowData);

	return {
		filteredTaxes,
		rowData,
		paginatedRowData,
	};
}

/**
 * Based on tax information determiene if tax is active or not.
 * Inactive taxes will have an end date that has already passed (before current date)
 * or where the enddate is before the start date (a voided tax)
 * @param tax 			{object} tax information including start and end dates
 * @return            	{boolean} Return true if is active false if inactive
 */
export function isTaxActive(tax) {
	const { taxEndDate } = tax;

	if (isTaxVoided(tax)) {
		return false;
	}

	const today = moment();
	const endDate = moment(taxEndDate, 'M-D-YYYY');

	if (endDate.isBefore(today)) {
		return false;
	}

	return true;
}

/**
 * Based on tax information determiene if tax is voided
 * @param tax 			{object} tax information including start and end dates
 * @return            	{boolean} Return true if tax is voided false if not.
 */
function isTaxVoided(tax) {
	const { taxEndDate, taxStartDate } = tax;

	const startDate = moment(taxStartDate, 'M-D-YYYY');
	const endDate = moment(taxEndDate, 'M-D-YYYY');

	if (endDate.isBefore(startDate)) {
		return true;
	}

	return false;
}

// Cleans the string by removing crazy characters
// (like ē and è) and changing everything to lowercase.
function cleanString(stringToClean) {
	return deburr(stringToClean.toLowerCase());
}

function filterBySearchTerm(item) {
	const searchTerm = cleanString(this);
	let stringToTest = Object.values(item).join(' ');
	stringToTest = cleanString(stringToTest);
	return hasWordStartingWith(stringToTest, searchTerm);
}

export function filterTaxes(companyTaxes, filters, tableType) {
	const { activeFilter, clientId, searchFilter } = filters;

	let filteredTaxes = cloneDeep(companyTaxes);

	// Filter Taxes by clientId
	if (clientId) {
		filteredTaxes = filteredTaxes.filter((company) => company.clientId === clientId);
	}

	// Filter Taxes by currentStatus (only show "Complete" taxes in the Taxes table. Any other status should show up in the Set Up Taxes Table.)
	if (tpauEnabled) {
		if (tableType === "completeTaxes") {
			for (let i = 0; i < filteredTaxes.length; i++) {
				filteredTaxes[i].clientIncludedTaxes = filteredTaxes[i].clientIncludedTaxes.filter((tax) => tax.currentTaxSetupStatus && tax.currentTaxSetupStatus.currentStatus === 'Complete');
			}
		} else {
			for (let i = 0; i < filteredTaxes.length; i++) {
				filteredTaxes[i].clientIncludedTaxes = filteredTaxes[i].clientIncludedTaxes.filter((tax) => tax.currentTaxSetupStatus && tax.currentTaxSetupStatus.currentStatus !== 'Complete');
			}
		}
	}

	// Filter taxes by active or not
	if (activeFilter === 'active') {
		for (let i = 0; i < filteredTaxes.length; i++) {
			filteredTaxes[i].clientIncludedTaxes = filteredTaxes[i].clientIncludedTaxes.filter(isTaxActive);
		}
	}

	// Filter by search criteria
	if (searchFilter) {
		for (let i = 0; i < filteredTaxes.length; i++) {
			filteredTaxes[i].clientIncludedTaxes = filteredTaxes[i].clientIncludedTaxes.filter(filterBySearchTerm, searchFilter);
		}
	}

	return filteredTaxes;
}

export function getRowStatusLabel(currentStatus, daysUntilEvent, event) {
	if (currentStatus === "PayrollPaused") {
		// return Payroll paused (red)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="error-strong" name="circle-exclamation-solid" size={16}/></LayoutBox><BodyText color="error-strong">{$.__('Payroll paused')}</BodyText></Flex>
	}

	if (currentStatus === "NewTax") {
		// return Set Up ASAP (red)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="error-strong" name="circle-exclamation-solid" size={16}/></LayoutBox><BodyText color="error-strong">{$.__('Set up ASAP')}</BodyText></Flex>
	}

	if (event === "PayrollWillPause" && daysUntilEvent <= 7) {
		// return Payroll paused in *daysUntilEvent* (red)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="error-strong" name="circle-exclamation-solid" size={16}/></LayoutBox><BodyText color="error-strong">{$.__n('Payroll paused in %1$s day', 'Payroll paused in %1$s days', daysUntilEvent)}</BodyText></Flex>
	}

	if (currentStatus === "FeesApply" && event === "PayrollWillPause" && daysUntilEvent > 7) {
		// return Fees apply (red)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="error-strong" name="circle-exclamation-solid" size={16}/></LayoutBox><BodyText color="error-strong">{$.__('Fees apply')}</BodyText></Flex>
	}

	if(currentStatus === "ThirdPartyAccessNotGranted") {
		// return Pending Access Confirmation (blue)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="info-strong" name="circle-info-solid" size={16}/></LayoutBox><BodyText color="info-strong">{$.__('Pending access confirmation')}</BodyText></Flex>
	}

	if (currentStatus === "AddTaxId" && event === "FeesWillApply" && daysUntilEvent <= 30) {
		// return Fees apply (yellow) in *daysUntilEvent* (yellow) (30 days and below)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="warning-strong" name="triangle-exclamation-solid" size={16}/></LayoutBox><BodyText color="warning-strong">{$.__n('Fees apply in %1$s day', 'Fees apply in %1$s days', daysUntilEvent)}</BodyText></Flex>
	}
	
	if (currentStatus === "AddTaxId" && (!event || (event === "FeesWillApply" && daysUntilEvent > 30))) {
		// return Set Up ASAP (blue)
		return <Flex paddingTop="4px"><LayoutBox marginRight={1} paddingTop="2px"><IconV2 color="info-strong" name="circle-info-solid" size={16}/></LayoutBox><BodyText color="info-strong">{$.__('Set up ASAP')}</BodyText></Flex>
	}
}