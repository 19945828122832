/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(() => ({
	flexNavContainer: {
		overflowX: ifFeature('encore', undefined, 'hidden'),
	},
}));
/* @endCleanup encore */
