import { AxiosResponse } from 'axios';
import { AuthOptions } from './constants';
import { CountryPhonePrefixOptions } from './components/sms-mfa/types';

type MultiFactorAuthPages = {
	login: string;
	setup: string;
};
export type MultiFactorAuthPage = keyof MultiFactorAuthPages;

type MultiFactorAuthStatuses = {
	beforeRequired: string;
	login: string;
	none: string;
	setup: string;
};

export type MultiFactorAuthStatus = keyof MultiFactorAuthStatuses;

export type MultiFactorAuthDataObject = {
	page: MultiFactorAuthPage;
	secret?: string;
	startDate: string;
	status: MultiFactorAuthStatus;
	userEmail: string;
	userId: number;
	mfaRequiredChannel?: string;
};

export enum MfaChannelOptions {
	SMS = 'sms',
	AUTHENTICATOR = 'authenticator',
	BACKUP_CODES = 'backup_codes',
}

export type MfaChannelTypes = MfaChannelOptions.SMS | MfaChannelOptions.AUTHENTICATOR | MfaChannelOptions.BACKUP_CODES;

export type MfaChannelItem = {
	phone?: string;
	key?: MfaChannelTypes;
	isEnabled: boolean;
	isPreferred: boolean;
	title: string;
	description: string;
	countries?: CountryPhonePrefixOptions[];
	type: MfaChannelTypes;
};

export type MfaUserSettingsBase = {
	startDate: string;
	status: MultiFactorAuthStatus | undefined;
	mfaChannels: MfaChannelTypes[];
	mfaChannelInfo: Record<MfaChannelTypes, MfaChannelItem>;
};

export interface GetMfaUserSettingsResponse extends MfaUserSettingsBase {
	error?: AxiosResponse;
}

export interface MfaUserSettings extends MfaUserSettingsBase {
	enabledChannels: AuthType[];
	preferredChannel: AuthOptions;
}

export type PostSmsData = {
	preferred?: boolean;
	phone?: string;
	countryCode?: string;
	countries?: CountryPhonePrefixOptions[];
};

export type PutPostData = {
	countryCode?: string;
	phone?: string;
	generateSecret: boolean;
};

export type PostCreateMultiFactorAuthenticationUserResponse = {
	id: number;
	userId: number;
	generatedMfaSecret: string;
	rememberUntil: boolean;
	failedAttempts: number;
	accountLockedUntil: string;
};

export type PostMultiFactorAuthenticationBackupCodeResponse = {
	error?: string;
};

export type PostResendSmsCodeResponse = {
	error?: AxiosResponse;
	success: boolean;
};

export type DeleteMfaResponse = {
	error?: AxiosResponse;
	success: boolean;
};

export type PostSmsCodeResponse = {
	error?: AxiosResponse;
	success: boolean;
};

export type PostMultiFactorAuthenticationLoginCodeResponse = {
	rememberToken?: string;
	error?: string;
};
export type PutMultiFactorAuthenticationChannel = {
	secret?: string;
	status?: number;
	statusText?: string;
	error?: string;
};
export type SerializedPostCreateMultiFactorAuthenticationUserResponse = {
	error?: AxiosResponse;
	id: number;
	secret: string;
	success: boolean;
};

export type SerializedPostMultiFactorAuthenticationBackupCodeResponse = {
	status: number;
	statusText?: string;
};

export type SerializedPostMultiFactorAuthenticationLoginCodeResponse = {
	rememberToken?: string;
	status: number;
	statusText?: string;
};

export type SerializedPutMultiFactorAuthenticationChannel = {
	secret?: string;
	status?: number;
	statusText?: string;
};
export type SerializedDeleteMultiFactorAuthenticationChannel = {
	status?: number;
	statusText?: string;
};

export type AuthType = AuthOptions.APP | AuthOptions.BACKUP | AuthOptions.SMS;
