import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
	Box,
	Flex,
	LayoutBox,
	makeStyles,
	Section,
	Typography,
} from '@bamboohr/fabric';
import { HeartAdd20x17 } from '@bamboohr/grim';
import Ajax from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { SadToGoBox, CancellationType, CancellationDropdown, CancellationModal, Services } from 'in-app-cancellation.mod';
import { DemoRequest } from '../benefits-admin-upsell';
import { BenefitsTabs } from '../benefits-tabs';
import { getUserDemoRequestContentPermissions } from '../benefits-tabs/utils/get-demo-request-content-permissions';
import { useBenefitsSettingsStore } from './data/benefits-settings-store';

interface PlansLandingProps {
	demoRequest: DemoRequest;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
	title: {
		marginLeft: spacing(1),
	},
	titleIcon: {
		fill: palette.primary.main,
	},
}));

export const PlansLanding = (props: PlansLandingProps): ReactElement => {
	const styles = useStyles();
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const [cancellationData, setCancellationData] = useState<any>({});
	const [cancellationPending, setCancellationPending] = useState(false);
	const [isCompanyInTrial, setIsCompanyInTrial] = useState<boolean>(false);
	const [showBenefitsAdminDemoRequestContent, setShowBenefitsAdminDemoRequestContent] = useState<boolean>(false);
	const [benAdminEmployeeCount, setBenAdminEmployeeCount] = useState<number | undefined>();

	const [modalOpen, setModalOpen] = useState(false);

	const getBenAdminEmployeeData = useCallback(() => {
		Ajax.get('/settings/benefits/employees').then((response) => {
			if (response.data?.employees && response.data?.isEligibilityBasedBilling) {
				const employees = Object.keys(response.data.employees);
				setBenAdminEmployeeCount(employees.length);
			}
		}, (err) => {
			console.error(err);
		});
	}, []);

	const getCancellationStatusAll = () => {
		Ajax.get('/settings/cancellation/status_all').then((response) => {
			if (response.data) {
				setCancellationData(
					response.data.filter((item) => item.feature === CancellationType.BENEFIT_ADMIN || item.feature === CancellationType.ACCOUNT)
				);
				setCancellationPending(response.data.find((item) => item.feature === CancellationType.BENEFIT_ADMIN).pending_cancellation);
			}
		});
	};

	const getCancellationStatus = () => {
		Services.getCancellationFeatureStatus(CancellationType.BENEFIT_ADMIN).then((resp) => {
			setCancellationData(resp.data);
			setCancellationPending(resp.data.pending_cancellation);
		});
	};

	useEffect(() => {
		getCancellationStatusAll();
	}, [cancellationPending, modalOpen]);

	useEffect(() => {
		getUserDemoRequestContentPermissions().then((response) => {
			if (response.data) {
				const { isCompanyInTrial, showBenefitsAdminDemoRequestContent } = response.data;
				setShowBenefitsAdminDemoRequestContent(showBenefitsAdminDemoRequestContent);
				setIsCompanyInTrial(isCompanyInTrial);
			}
		});
	}, []);

	useEffect(()=>{
		getBenAdminEmployeeData();
	},[getBenAdminEmployeeData]);

	const handleOnSelect = () => {
		setModalOpen(true);
	};

	const cancelButton = (
		<>
			{!!cancellationData && !cancellationPending && (
				<>
					{isBenefitsAdminEnabled && (
						<>
							<CancellationDropdown
								cancellationData={cancellationData}
								handleOnSelect={handleOnSelect}
								title={$.__('Benefits Management')}
							/>
							<CancellationModal
								isOpen={modalOpen}
								onClose={() => setModalOpen(false)}
								onSubmitSuccess={getCancellationStatus}
								types={[CancellationType.BENEFIT_ADMIN]}
							/>
						</>
					)}
				</>
			)}
		</>
	);

	const sadToGoBox = (
		<>
			{!!cancellationData && cancellationPending && (
				<SadToGoBox cancellationData={cancellationData} updateCancellationData={getCancellationStatusAll} isAddon={true} />
			)}
		</>
	);

	const resolutionLinkBanner = (
		<PoMicroFrontend
			customClassNamePrefix='resolution-link-banner'
			route='/settings/benefits/resolution-link-banner'
		/>
	);

	const benefitsTabs = (
		<Router basename='/settings/benefits/plans'>
			<BenefitsTabs
				benAdminEmployeeCount={benAdminEmployeeCount}
				isCompanyInTrial={isCompanyInTrial}
				showBenefitsAdminDemoRequestContent={showBenefitsAdminDemoRequestContent}
				{...props}
			/>
		</Router>
	);

	/**
	 * @startCleanup encore
	 */
	const jade = (
		<>
			<Flex alignItems='center' justifyContent='space-between'>
				<Flex alignItems='center'>
					<Flex alignItems='center' className={styles.titleIcon}>
						<HeartAdd20x17 />
					</Flex>
					<Box className={styles.title}>
						<Typography component='h1' variant='h4'>
							{$.__('Benefits Management')}
						</Typography>
					</Box>
				</Flex>
				{cancelButton}
			</Flex>
			{sadToGoBox}
			{resolutionLinkBanner}
			{benefitsTabs}			
		</>
	);
	/**
	 * @endCleanup encore
	 */

	return ifFeature(
		'encore',
		<>
			<LayoutBox marginBottom={3}>
				<Section.Header
					actions={[cancelButton]}
					size="large"
					title={$.__('Benefits')}
				/>
			</LayoutBox>
			{sadToGoBox}
			{resolutionLinkBanner}
			{benefitsTabs}
		</>,
		jade,
	);
};
