import { UpdateFieldValue, WizardValues } from '../../../types';

export const resetEligibilityGroupsAmounts = (updateFieldValue: UpdateFieldValue, wizardValues: WizardValues) => {
	const eligibilityGroupsRefresh = wizardValues.eligibilityGroups.map((group) => {
		group.calculatorSettings.rate = null;
		group.calculatorSettings.companyPays = null;
		return group;
	})

	updateFieldValue('eligibilityGroups', eligibilityGroupsRefresh);
};
