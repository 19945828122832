import { HeaderTmplData } from '../types';
import { ItemType } from '@bamboohr/fabric';

export const getDropdownItems = (permissions: HeaderTmplData): ItemType[] => {
	const { canDelete, canAdd, canDuplicate } = permissions;
	const items = [];

	if (canDelete) {
		items.push({
			anchor: 'bottom',
			items: [{ text: $.__('Delete Access Level'), value: 'deleteAccessLevel', key: 'delete' }],
			key: 'deleteAnchorBottom',
			type: 'group',
		});
	}

	if (canAdd || canDuplicate) {
		const actionItems = [];
		if (canAdd) {
			actionItems.push({ text: $.__('Add a Non-Employee BambooHR User'), value: 'addNonEmployee', key: 'add' });
		}
		if (canDuplicate) {
			actionItems.push({
				text: $.__('Duplicate Access Level'),
				value: 'duplicateAccessLevel',
				key: 'duplicateAccessLevel',
			});
		}

		items.push({
			items: actionItems,
			key: 'items',
			type: 'group',
		});
	}

	return items;
};
