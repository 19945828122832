import {
	Dispatch,
	SetStateAction,
	useState,
} from 'react';
import {
	Menu,
	FloatingIconButton,
	TextButton,
} from '@bamboohr/fabric';

import { ChildItem, ParentItem } from './types';
import {
	generateAddButtonText,
	generateSelectedFilterMenuItems,
	addFilterTypeToSelections,
	removeFilterTypeFromSelections,
	removeItemFromSelectedValues,
} from './utils';
import { useReactivateDisabledField } from '../../hooks/use-reactivate-disabled-field';
import {
	FilterGroup,
	FilterType,
	FilterValue,
} from '../../types/wizard-values';

interface FilterSelectorProps {
	items: ParentItem[];
	setFilter: (filterType: FilterType, selectedValues: FilterValue[]) => void;
	filterGroup: FilterGroup;
	menuFilterTypeSelections: FilterType[];
	setMenuFilterTypeSelections: Dispatch<SetStateAction<FilterType[]>>;
}

export const NestedFilterMenu = (props: FilterSelectorProps): JSX.Element => {
	const {
		filterGroup,
		items,
		setFilter,
		menuFilterTypeSelections,
		setMenuFilterTypeSelections,
	} = props;

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const DATA_BI_ID = 'add-secondary-filter-button';
	const isSecondaryFilterMenuDisabled = useReactivateDisabledField(generateSelectedFilterMenuItems(filterGroup, items), true);

	const handleMenuSelect = (selectedItem: ChildItem, parentItem: ParentItem): void => {
		const newSelectedValues = [
			...filterGroup.values[parentItem.key],
			selectedItem.value,
		];
		setFilter(parentItem.key, newSelectedValues);

		const newSelections = addFilterTypeToSelections(parentItem.key, menuFilterTypeSelections);
		setMenuFilterTypeSelections(newSelections);
	};

	const handleMenuDeselect = (deselectedItem: ChildItem, parentItem: ParentItem): void => {
		const newSelectedValues = removeItemFromSelectedValues(
			filterGroup.values[parentItem.key],
			deselectedItem.value
		);
		setFilter(parentItem.key, newSelectedValues);

		if (newSelectedValues.length === 0) {
			const newSelections = removeFilterTypeFromSelections(
				parentItem.key,
				menuFilterTypeSelections
			);
			setMenuFilterTypeSelections(newSelections);
		}
	};

	const openMenu = (): void => {
		setIsOpen(true);
	};

	const closeMenu = (): void => {
		setIsOpen(false);
		setMenuFilterTypeSelections([]);
	};

	const buttonText = generateAddButtonText(filterGroup, menuFilterTypeSelections);

	return (
		<div className="fab-FormField">
			<Menu
				biId="secondary-filter"
				canSelectMultiple={ true }
				isDisabled={ isSecondaryFilterMenuDisabled }
				isOpen={ isOpen }
				items={ items }
				onClose={ closeMenu }
				onOpen={ openMenu }
				onDeselect={ handleMenuDeselect }
				onSelect={ handleMenuSelect }
				renderToggle={ ({ isFocused }: { isFocused: boolean }, buttonProps): JSX.Element => {
					if (buttonText) {
						return (
							<TextButton
								{...buttonProps}
								data-bi-id={ DATA_BI_ID }
								iconBefore="fab-circle-plus-16x16"
								isActive={ isOpen }
								isFocused={ isFocused }
								muted={ true }
								tabindex="-1"
								text={ buttonText }
								type="button"
							/>
						);
					}

					return (
						<FloatingIconButton
							{...buttonProps}
							data-bi-id={ DATA_BI_ID }
							icon="fab-circle-plus-16x16"
							isActive={ isOpen }
							isFocused={ isFocused }
							tabindex="-1"
							type="button"
						/>
					);
				} }
				selectedItems={ generateSelectedFilterMenuItems(filterGroup, items) }
			/>
		</div>
	);
};
