import { BodyText, Button, LayoutBox, StandardModal, TextButton, useTheme } from '@bamboohr/fabric';
import { TrashCan49x54 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useState } from 'react';
import { usePerformanceSettingsContext } from '../../../../context';
import { ReviewCycleConfigurationData, ReviewCycleResponseData } from '../../../../types';
import { deleteReviewCycleConfiguration } from '../../services/delete-review-cycle-configuration';

interface Props {
	activeRowData: ReviewCycleConfigurationData;
	isDeleteModalOpen: boolean;
	setIsDeleteModalOpen: (isDeleteModalOpen: boolean) => void;
}

export const DeleteReviewCycleModal = (props: Props): JSX.Element => {
	const { isDeleteModalOpen, activeRowData, setIsDeleteModalOpen } = props;
	const [, { setReviewCycleData, setSlidedownData }] = usePerformanceSettingsContext();

	const [isModalProcessing, setIsModalProcessing] = useState(false);

	const { palette } = useTheme();

	const onModalClose = () => {
		setIsModalProcessing(false);
		setIsDeleteModalOpen(false);
	};

	const handleReviewCycleDelete = () => {
		const { id } = activeRowData;

		setIsModalProcessing(true);

		deleteReviewCycleConfiguration(id)
			.then((response: ReviewCycleResponseData) => {
				const { reviewCycleConfigurations } = response;
				setReviewCycleData(reviewCycleConfigurations);
				setSlidedownData({
					message: $.__('Review Cycle was successfully deleted.'),
					type: 'success',
				});
				onModalClose();
			})
			.catch(() => {
				setSlidedownData({
					message: $.__('Oops, something went wrong. Try deleting the review cycle later.'),
					type: 'error',
				});
				setIsModalProcessing(false);
			});
	};

	return (
		<>
			<StandardModal isOpen={isDeleteModalOpen} onCloseComplete={onModalClose} onRequestClose={onModalClose}>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={ifFeature(
								'encore',
								[
									<TextButton disabled={isModalProcessing} key='cancelDeleteConfig' onClick={onModalClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
									<Button disabled={isModalProcessing} key='deleteConfig' onClick={() => handleReviewCycleDelete()} type='button'>
										{$.__('Delete')}
									</Button>,
								],
								[
									<Button disabled={isModalProcessing} key='deleteConfig' onClick={() => handleReviewCycleDelete()} type='button'>
										{$.__('Yes, Delete')}
									</Button>,
									<TextButton disabled={isModalProcessing} key='cancelDeleteConfig' onClick={onModalClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
								]
							)}
						/>
					}
					renderHeader={<StandardModal.Header hasCloseButton={true} title={$.__('Just Checking...')} />}
				>
					<StandardModal.Constraint
						spacingOverrides={{
							top: StandardModal.Constraint.Spacing.LARGE,
							bottom: StandardModal.Constraint.Spacing.LARGE,
						}}
					>
						<StandardModal.HeroHeadline
							icon={ifFeature('encore', 'trash-can-regular', <TrashCan49x54 fill={palette.error.main} />)}
							iconColor={ifFeature('encore', 'error-strong')}
							text={$.__('Do you want to delete %1 review cycle?', activeRowData?.name)}
						/>

						<LayoutBox textAlign='center'>
							<BodyText color='neutral-strong' size='large'>
								{$.__(
									'Employees in the review cycle will no longer be able to complete new assessments or feedback. However, past assessments or feedback will be available.'
								)}
							</BodyText>
						</LayoutBox>
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		</>
	);
};
