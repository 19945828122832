import {
	ModalFooterDefault,
} from './modal-footer-default';
import {
	ModalFooterError,
} from './modal-footer-error';
import {
	ModalFooterLoading,
} from './modal-footer-loading';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';

export function ModalFooterConnector() {
	const [{
		isErrorClientTaxSettings,
		isLoadingClientTaxSettings,
	}, {
		saveAllClientTaxSettings,
	}] = useCompanyTaxSettingsStore();
	if (isLoadingClientTaxSettings) {
		return (
			<ModalFooterLoading />
		);
	}
	if (isErrorClientTaxSettings) {
		return (
			<ModalFooterError />
		);
	}
	return (
		<ModalFooterDefault
			onSubmit={ saveAllClientTaxSettings }
		/>
	);
}
