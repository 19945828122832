import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getParameterByName } from '@utils/url';
import { getJsonData } from 'Data.util';
import 'jquery.validation.lib';
import { Component } from 'react';
import { ScreenSize } from 'screen-size.react';
import { SurveyStatus } from '../../../shared/survey-status.react';
import { SurveyWrapper } from '../../../shared/survey-wrapper.react';
import AnonymityBanner from './anonymity-banner/anonymity-banner';
import { AnonymityModal } from './anonymity-modal';
import { ConductSurvey } from './conduct-survey';
import './enps-survey.styl';
import { submitSurvey } from './service';
import { isValidForm } from './util';
import { IconV2 } from '@bamboohr/fabric';

export class EnpsSurvey extends Component {
	constructor(props) {
		super(props);

		const { isPreview = false, companyName = '' } = this.props;

		const enpsData = getJsonData('#enpsData');
		let surveyClosed = false;
		let submitted = false;
		let company = '';

		if (enpsData) {
			submitted = enpsData.submitted;
			company = enpsData.company;
			surveyClosed = enpsData.surveyClosed;
		}

		if (isPreview) {
			company = companyName;
		}

		const scoreParameter = getParameterByName('score');

		this.state = {
			company,
			surveyClosed,
			initialChoice: scoreParameter ? scoreParameter : null,
			lock: getParameterByName('lock'),
			score: scoreParameter ? parseInt(scoreParameter) : null,
			submitted,
			anonymityModalIsVisible: false,
			channel: getParameterByName('channel'),
		};
	}

	_handleRadioChange = (score) => {
		this.setState({ score });
	};

	_handleSubmit = (e) => {
		e.preventDefault();
		const { score } = this.state;
		const { isPreview = false, onPreviewSubmit } = this.props;

		if (score >= 0 && score <= 10 && isValidForm(this._formElement)) {
			const { answer, lock, channel } = this.state;
			const { surveyId } = this.props;

			const postData = {
				answer,
				lock,
				score,
				surveyId,
				channel,
			};

			if (isPreview) {
				return new Promise(onPreviewSubmit);
			}

			return submitSurvey(surveyId, postData).then(
				() => this.setState({ submitted: true }),
				() => {
					window.setMessage($.__('Uh Oh! There was an error. Please try again or contact BambooHR support.'), 'error');

					// Reject the promise for any other handlers down the line,
					return Promise.reject('Failed to submit');
				}
			);
		}
	};

	_handleTextChange = (value) => {
		if (value && value.length > 0) {
			return this.setState({ answer: value });
		}
		return this.setState({ answer: null });
	};

	_handleAnonymityModalOpen = () => {
		return this.setState({ anonymityModalIsVisible: true });
	};

	_handleAnonymityModalClose = () => {
		return this.setState({ anonymityModalIsVisible: false });
	};

	_validationTimer = () => {
		return setTimeout(() => $(this._formElement).bhrValidate());
	};

	componentDidMount() {
		this.timeoutIds = [this._validationTimer()];
	}

	componentDidUpdate() {
		this.timeoutIds.push(this._validationTimer());
	}

	componentWillUnmount() {
		this.timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
	}

	render() {
		const { company, initialChoice, score, submitted, surveyClosed, anonymityModalIsVisible } = this.state;
		const { isPreview } = this.props;
		return (
			<div className='EnpsSurvey'>
				<AnonymityBanner onLearnMoreClick={this._handleAnonymityModalOpen} />
				{!surveyClosed && !submitted ? (
					<SurveyWrapper
						isFullBleed={true}
						isPreview={isPreview}
						isSmall={ifFeature('encore', false, true)}
						surveyName='enps'
						submitted={submitted}
						surveyClosed={surveyClosed}
					>
						<form className='EnpsSurvey__content--full-width' id='survey' ref={(element) => (this._formElement = element)}>
							<ScreenSize
								render={(screenSize) => (
									<ConductSurvey
										company={company}
										initialChoice={initialChoice}
										onAnonymityInfoClick={this._handleAnonymityModalOpen}
										onRadioChange={this._handleRadioChange}
										onSubmit={this._handleSubmit}
										onTextChange={this._handleTextChange}
										score={score}
										screenSize={screenSize}
									/>
								)}
							/>
						</form>
					</SurveyWrapper>
				) : (
					<SurveyWrapper surveyName='enps' surveySubmitted={submitted} surveyClosed={surveyClosed}>
						{!surveyClosed && submitted && (
							<SurveyStatus
								headerMessage={$.__('Thanks so much for your feedback!')}
								iconName={ifFeature(
									'encore',
									<IconV2 size={48} name='circle-check-solid' color='primary-strong' />,
									'fab-check-circle-48x48'
								)}
								id='submitted-status'
								messages={[$.__('You’re all set, feel free to close this window and move on with your life.')]}
							/>
						)}
						{surveyClosed && (
							<SurveyStatus
								headerMessage={$.__('Survey Period is Now Closed')}
								iconName={ifFeature(
									'encore',
									<IconV2 size={54} name='triangle-exclamation-solid' color='primary-strong' />,
									'fab-triangle-exclamation-54x54'
								)}
								id='closed-status'
								messages={[
									$.__('Your feedback is very important.'),
									$.__('Please be sure to look for the next opportunity to answer the survey.'),
								]}
							/>
						)}
					</SurveyWrapper>
				)}
				<ScreenSize
					render={(screenSize) => (
						<AnonymityModal onClose={this._handleAnonymityModalClose} screenSize={screenSize} visible={anonymityModalIsVisible} />
					)}
				/>
			</div>
		);
	}
}
