import { Button } from '@fabric/button';
import { useState } from 'react';

import { BACKUP_CODE_TITLE, BACKUP_CODES_HEADLINE, downloadCodes } from 'multi-factor-authentication.mod';

import './interstitial-backup-codes.styl';
import { BackupCodesApp } from '../backup-codes-app/backup-codes-app';

export interface InterstitialBackupCodesProps {
	onDone: () => void;
	userId: number;
}

export function InterstitialBackupCodes(props: InterstitialBackupCodesProps): JSX.Element {
	const { onDone, userId } = props;

	const [isProcessing, setIsProcessing] = useState(false);
	const [printRequestCount, setPrintRequestCount] = useState(0);

	const handleProcessing = (type: boolean) => {
		setIsProcessing(type);
	};

	return (
		<div className='InterstitialBackupCodes'>
			<div className='InterstitialBackupCodes__header'>
				<h2 className='InterstitialBackupCodes__headerTitle'>{BACKUP_CODE_TITLE}</h2>
				<h4 className='InterstitialBackupCodes__headerHeadline'>{BACKUP_CODES_HEADLINE}</h4>
			</div>
			<BackupCodesApp
				onDone={onDone}
				onEndProcessing={handleProcessing}
				onStartProcessing={handleProcessing}
				printRequestCount={printRequestCount}
				userId={userId}
			/>
			<div className='InterstitialBackupCodes__footer'>
				<Button clickAction={() => onDone()} isDisabled={isProcessing} text={$.__('Ok, Got It')} type='button' />
				<Button
					clickAction={() => downloadCodes(userId)}
					isDisabled={isProcessing}
					outline={true}
					secondary={true}
					text={$.__('Download')}
					type='button'
				/>
				<Button
					clickAction={() => setPrintRequestCount((prevCount) => prevCount + 1)}
					isDisabled={isProcessing}
					outline={true}
					secondary={true}
					text={$.__('Print')}
					type='button'
				/>
			</div>
		</div>
	);
}
