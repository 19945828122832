import { useRef, useEffect, useState } from 'react';
import './franklin-web-component';
import { Loader } from '@bamboohr/fabric';

const containerId = 'franklinContainer';

const FranklinContent = ({ url, primaryHandler, secondaryHandler, isProcessing }) => {
	const webComponentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const shouldDisplayLoader = isLoading || isProcessing;

	function handleClick(e) {
		const [element] = e.nativeEvent.composedPath();
		if (element.tagName === 'A' && element.classList.contains('button')) {
			e.preventDefault();
			if (element.dataset.secondary && secondaryHandler) {
				secondaryHandler();
			} else {
				primaryHandler();
			}
		}
	}

	useEffect(() => {
		// After the component is mounted, create the web component and append it to the React component's DOM
		const MyWebComponent = customElements.get('franklin-web-component');

		if (MyWebComponent) {
			webComponentRef.current = new MyWebComponent();
			webComponentRef.current.setAttribute('url', url);
			document.getElementById(containerId).appendChild(webComponentRef.current);
			webComponentRef.current.addEventListener('isLoaded', () => {
				setIsLoading(false);
			});
		}

		return () => {
			// Clean up: remove the web component when the React component is unmounted
			if (webComponentRef.current) {
				webComponentRef.current.remove();
			}
		};
	}, [url]);

	return (
		<>
			<div id={containerId} onClick={handleClick} style={{ display: shouldDisplayLoader ? 'none' : 'block' }}></div>
			{shouldDisplayLoader && <Loader />}
		</>
	);
};

export default FranklinContent;
