import { makeStyles, createStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, typography }) => (
	createStyles({
		twoStepLoginSetup: {
			paddingTop: ifFeature('encore', '16px', '17px'),
			position: 'relative',

			'&__header': {
				margin: '0 0 18px',
				padding: '0',
			}
		},
		utilityDropdown: {
			position: 'absolute',
			right: '0',
			// @startCleanup encore
			top: ifFeature('encore', null, '17px'),
			// @endCleanup encore
		},
		note: {
			fontSize: typography.fabricFontSize('teenie'),
			color: palette.gray[700],
			lineHeight: typography.fabricLineHeight('teenie'),
		},
		levelHeader: {
			fontSize: typography.fabricFontSize('medium'),
			fontWeight: typography.fontWeightBold,
			lineHeight: typography.fabricLineHeight('medium'),
			marginTop: '8px',
			marginBottom: '3px',
		},
		accessLevelList: {
			marginLeft: '23px',
			marginBottom: '16px',
			listStyle: 'disc',
		},
		mfaInfoContainer: {
			marginTop: '24px',
		}
	})
));
