import moment from 'moment.lib';
import {
	BodyText,
	Dropdown,
	Flex,
	IconV2,
	LayoutBox,
	TextButton,
	Tooltip,
	// @startCleanup encore
	useTheme,
	// @endCleanup encore
} from '@bamboohr/fabric';
// @startCleanup encore
import { Download12x14, EllipsisVertical4x16, Question14x14 } from '@bamboohr/grim';
// @endCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function getColumns() {
	// @startCleanup encore
	const { palette } = useTheme();
	// @endCleanup encore

	function handleDownloadSelect({ billingDate, downloadUrl, value }) {
		if (value === 'downloadPDF') {
			window.location.assign(`/settings/account/billing/invoices/download?url=${downloadUrl}`);
		}
		if (value === 'downloadCSV') {
			window.location.assign(`/settings/account/billing/invoices/employees?date=${billingDate}`);
		}
	}

	function getDownloadDropdownItems({ hasActiveEmployeeDownload, shouldShowActiveEmployees }) {
		if (shouldShowActiveEmployees) {
			return [
				{
					icon: ifFeature('encore', <IconV2 name='down-to-line-regular' size={16} />, <Download12x14 />),
					text: $.__('Download Invoice (PDF)'),
					value: 'downloadPDF',
				},
				{
					icon: ifFeature('encore', <IconV2 name='down-to-line-regular' size={16} />, <Download12x14 />),
					isDisabled: !hasActiveEmployeeDownload,
					text: $.__('Download Active Employees (CSV)'),
					value: 'downloadCSV',
				},
			];
		}
		return [
			{
				icon: ifFeature('encore', <IconV2 name='down-to-line-regular' size={16} />, <Download12x14 />),
				text: $.__('Download Invoice (PDF)'),
				value: 'downloadPDF',
			},
		];
	}

	return ifFeature(
		'encore',
		[
			{
				cell: {
					type: 'checkbox',
					selected: (row) => row.selected,
					disabled: (row) => row.disabled,
				},
				header: { type: 'checkbox' },
				headerAriaLabel: $.__('Select Invoices'),
				key: 'selectInvoices',
				width: '48px',
			},
			{
				cell: ({ billingDate }) => moment(billingDate).utc().format('MMM D, YYYY'),
				header: $.__('Billing Date'),
				key: 'billingDate',
				sortBy: ({ billingDate }) => billingDate,
				width: '25%',
			},
			{
				cell: ({ amount }) => {
					const num = Number(amount).toFixed(2);
					return num < 0 ? `($${Math.abs(num)})` : `$${num}`;
				},
				header: $.__('Amount'),
				key: 'billingAmount',
				width: '25%',
			},
			{
				cell: ({ downloadUrl, billingDate, invoiceNumber }) => {
					const number = invoiceNumber.replace('INV', '');
					const date = moment(billingDate).format('MMDDYYYY');

					return downloadUrl ? (
						<Tooltip content={$.__('Preview Invoice')} enterDelay={500}>
							<TextButton
								href={`/settings/account/billing/invoices/preview?url=${downloadUrl}&invoiceNumber=${number}&date=${date}`}
								target='_blank'
								type='button'
							>
								{invoiceNumber}
							</TextButton>
						</Tooltip>
					) : (
						<Flex>
							<BodyText>{$.__('Creating Invoice')}</BodyText>
							<LayoutBox marginLeft={0.75} marginTop={0.25}>
								<Tooltip content={$.__('Invoices may take up to 12 hours to show up here.')}>
									<span role='button' tabIndex='0'>
										<IconV2 color='neutral-medium' size={16} name='circle-question-regular' />
									</span>
								</Tooltip>
							</LayoutBox>
						</Flex>
					);
				},
				cellPaddingReset: 'vertical',
				header: $.__('Invoice #'),
				key: 'billingInvoiceNumber',
				verticalAlign: 'center',
				width: '25%',
			},
			{
				align: 'right',
				headerAriaLabel: 'Download Actions',
				key: 'downloadActions',
				showOnHover: true,
				cellPaddingReset: 'vertical',
				cell: ({ billingDate, downloadUrl, hasActiveEmployeeDownload, shouldShowActiveEmployees }) => {
					return (
						<LayoutBox paddingY={1}>
							<Dropdown
								ButtonProps={{
									color: 'secondary',
									icon: <IconV2 name='ellipsis-vertical-regular' size={16} />,
									size: 'small',
									variant: 'outlined',
								}}
								items={getDownloadDropdownItems({ hasActiveEmployeeDownload, shouldShowActiveEmployees })}
								onSelect={(value) => handleDownloadSelect({ billingDate, downloadUrl, value })}
								showCaret={false}
							/>
						</LayoutBox>
					);
				},
				width: '25%',
			},
		],
		[
			{
				cell: {
					type: 'checkbox',
					selected: (row) => row.selected,
					disabled: (row) => row.disabled,
				},
				header: { type: 'checkbox' },
				headerAriaLabel: $.__('Select Invoices'),
				key: 'selectInvoices',
				width: '100px',
			},
			{
				cell: ({ billingDate }) => moment(billingDate).utc().format('MMM D, YYYY'),
				header: $.__('Billing Date'),
				key: 'billingDate',
				sortBy: ({ billingDate }) => billingDate,
				width: '15%',
			},
			{
				align: 'right',
				cell: ({ amount }) => {
					const num = Number(amount).toFixed(2);
					return num < 0 ? `($${Math.abs(num)})` : `$${num}`;
				},
				header: $.__('Amount'),
				key: 'billingAmount',
				width: '15%',
			},
			{
				cell: () => {},
				header: '',
				headerAriaLabel: $.__('Spacer'),
				key: 'billingAmount-spacer',
				width: '15%',
			},
			{
				cell: ({ downloadUrl, billingDate, invoiceNumber }) => {
					const number = invoiceNumber.replace('INV', '');
					const date = moment(billingDate).format('MMDDYYYY');

					return downloadUrl ? (
						<Tooltip content={$.__('Preview Invoice')} enterDelay={500}>
							<TextButton
								href={`/settings/account/billing/invoices/preview?url=${downloadUrl}&invoiceNumber=${number}&date=${date}`}
								target='_blank'
								type='button'
							>
								{invoiceNumber}
							</TextButton>
						</Tooltip>
					) : (
						<Flex>
							<BodyText>{$.__('Creating Invoice')}</BodyText>
							<LayoutBox marginLeft={0.75} marginTop={0.25}>
								<Tooltip content={$.__('Invoices may take up to 12 hours to show up here.')}>
									<span role='button' tabIndex='0'>
										<Question14x14 fill={palette.gray[600]} />
									</span>
								</Tooltip>
							</LayoutBox>
						</Flex>
					);
				},
				cellPaddingReset: 'vertical',
				header: $.__('Invoice #'),
				key: 'billingInvoiceNumber',
				verticalAlign: 'center',
				width: '20%',
			},
			{
				align: 'right',
				headerAriaLabel: 'Download Actions',
				key: 'downloadActions',
				showOnHover: true,
				cellPaddingReset: 'vertical',
				cell: ({ billingDate, downloadUrl, hasActiveEmployeeDownload, shouldShowActiveEmployees }) => {
					return (
						<LayoutBox paddingY={1}>
							<Dropdown
								ButtonProps={{
									color: 'secondary',
									icon: <EllipsisVertical4x16 />,
									variant: 'outlined',
								}}
								items={getDownloadDropdownItems({ hasActiveEmployeeDownload, shouldShowActiveEmployees })}
								onSelect={(value) => handleDownloadSelect({ billingDate, downloadUrl, value })}
								showCaret={false}
							/>
						</LayoutBox>
					);
				},
			},
		]
	);
}
