import React, { useState, useEffect } from 'react';
import { StandardModal, Button, TextField } from '@bamboohr/fabric';
import { TrashCan43x48 } from '@bamboohr/grim';
import { Message } from '@bamboohr/utils/lib/message';
import { useStyles } from './styles';

export const DeleteWebhookModal = (props) => {
	const { isOpen, onClose, id, type} = props;
	const classes = useStyles();
	const [deleteInput, setDeleteInput] = useState('');
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const deleteWebhook = () => {
		$.ajax({
			type: 'post',
			url: `edit.php?id=${id}`,
			success (ret) {
				if (ret.success) location.reload();
			},
			dataType: 'json',
			data: {
				"action": "delete",
				id
			}
		});	
	}

	useEffect(() => {
		if (deleteInput.toLowerCase() === $.__('delete')) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [deleteInput])

	const header = <StandardModal.Header hasCloseButton={true} title="Just Checking..." />;

	const footer = <StandardModal.Footer 
					actions={
						[
							<Button 
								disabled={buttonDisabled} 
								key="primary"
								onClick={deleteWebhook}
								type="button"
								>
								{$.__('Delete Webhook')}
							</Button>, 
							<Button
								color='secondary'
								key="secondary"
								onClick={onClose}
								type="button"
								variant='outlined'
							>
								{$.__('Keep Webhook')}
							</Button>
						]} />;

	return (
		<StandardModal isOpen={ isOpen } onRequestClose={ onClose }>
			<StandardModal.Body renderFooter={ footer } renderHeader={ header }>
				<StandardModal.Constraint spacingOverrides={{
					bottom: StandardModal.Constraint.Spacing.TEENIE, 
					top: StandardModal.Constraint.Spacing.SMALL,
				}}
				/>
				<div className={classes.modalContent}>
					<div className={classes.heading}>
						<div className={classes.icon}>
							<TrashCan43x48 />
						</div>
						<div className={classes.title}>{`Are you sure you want to delete this ${type} webhook?`}</div>
						<div className={classes.content}>{$.__('It will be lost and gone forever, never to be seen again.')}</div>
					</div>
					<div className={classes.subsection}>
						<div className={classes.subheader}>{$.__('This may cause a couple hiccups')}</div>
						<div className={classes.content}>
							{$.__('The integration that this webhook is tied to may break if you delete this.')}
							<br/>
							{$.__('Only the webhook will be deleted, not the User\'s API Key.')}
						</div>
						<div className={classes.warning}><Message em={{ tag : 'b'}} text={ $._(`Type *"Delete"* to permanently delete this webhook.`) } /></div>
						<TextField onChange={(e) => setDeleteInput(e.target.value)} value={deleteInput} width={4}/>
					</div>
				</div>
			</StandardModal.Body>
		</StandardModal>
	)
}