import { BlankState, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { ReactElement } from 'react';
import './styles.styl';

export function TemplateListBlankState(): ReactElement {
	const subtitle = <Message text={$._('If so, New Hire Packet Templates will make your life just a bit easier.')} />;

	const title = <Message text={$._('Do you have specific onboarding setups for different offices, locations, or employee roles?')} />;

	return ifFeature("encore", (
		<LayoutBox marginTop={4} marginX="auto" maxWidth="616px">
			<BlankState icon="bs-folder-open" subtitle={subtitle} title={title} />
		</LayoutBox>
	), (
		<div className='NHPTemplateListBlankState__wrapper'>
			<BlankState icon="fab-badge-id-outlined-72x66" subtitle={subtitle} title={title} />
		</div>
	));
}
