import { Fragment, useReducer, useState } from 'react';

import { IconButton } from '@bamboohr/fabric';
import { Select } from '@fabric/select';
import { TextButton } from '@fabric/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStateContext } from '../state';

function getStrictlyEqualSelectedValue(itemsObject, selectedId) {
	let selectedItem = itemsObject.find((item) => {
		return item.value == selectedId;
	});

	if (selectedItem && selectedItem.value) {
		return selectedItem.value;
	}

	return selectedId;
}

/**
 * @param {Reminder[]} reminders
 * @param {Action} action
 * @returns {Reminder[]}
 */
function reducer(reminders, action) {
	switch (action.type) {
		case 'add':
			reminders.push({});
			break;
		case 'remove':
			reminders.splice(action.index, 1);
			break;
		case 'update':
			var { type, index, ...reminder } = action;

			reminders.splice(index, 1, {
				...reminders[index],
				...reminder,
			});
			break;
	}

	if (reminders.length < 1) {
		reminders.push({});
	}

	return [...reminders];
}

export default function Reminders() {
	const {
		beforeAfter,
		fieldId: _fieldId,
		eventFields,
		intervals,
		isCustom,
		isReminder,
		reminderFieldLabel,
		preSelectedEventField,
		reminders: _reminders,
	} = useStateContext();
	const [reminders, dispatch] = useReducer(reducer, _reminders);
	const [fieldId, setFieldId] = useState(getStrictlyEqualSelectedValue(eventFields, _fieldId));

	return (
		<Fragment>
			{isCustom && (
				<div className='fab-FormColumn fab-marginBottom--4'>
					<label className='fab-Label' htmlFor='fieldId'>
						{$.__('Event')}
					</label>
					<Select
						id='fieldId'
						isClearable={false}
						isDisabled={preSelectedEventField}
						items={eventFields}
						onChange={([value]) => setFieldId(value)}
						selectedValues={[fieldId]}
						width={ifFeature('encore', '7', '6')}
					/>
					<input name='fieldId' readOnly type='hidden' value={fieldId} />
				</div>
			)}
			{isReminder && (
				<div className='fab-FormColumn'>
					<label className='fab-Label fab-marginBottom--4' htmlFor='reminderField'>
						{$.__('Alert Field')}
					</label>
					<Select
						id='reminderField'
						isClearable={false}
						isDisabled={true}
						items={[
							{
								text: reminderFieldLabel,
								value: '',
							},
						]}
						selectedValues={['']}
					/>
					<input name='fieldId' readOnly type='hidden' value='' />
				</div>
			)}
			<div className='fab-FormColumn'>
				<span className='fab-Label fab-marginBottom--4'>{$.__('Reminder')}</span>
				<div>
					{reminders.map(({ due_within, due_interval, before_after }, index) => (
						<div className='reminder-row' key={index}>
							<div className='fab-FormField'>
								<input
									className='fab-TextInput fab-TextInput--width1'
									name='interval[]'
									onChange={({ target: { value } }) =>
										dispatch({
											type: 'update',
											index,
											due_within: value,
										})
									}
									type='text'
									value={due_within || ''}
								/>
							</div>
							<div className='fab-FormField'>
								<Select
									isClearable={false}
									items={intervals}
									name='intervalType[]'
									onChange={([value]) =>
										dispatch({
											type: 'update',
											index,
											due_interval: value,
										})
									}
									selectedValues={[due_interval || intervals[0].value]}
									width={ifFeature('encore', '4', '3')}
								/>
							</div>
							<div className='fab-FormField'>
								<Select
									isClearable={false}
									items={beforeAfter}
									name='beforeAfterType[]'
									onChange={([value]) =>
										dispatch({
											type: 'update',
											index,
											before_after: value,
										})
									}
									selectedValues={[before_after || beforeAfter[0].value]}
									width={ifFeature('encore', '4', '3')}
								/>
							</div>
							{index > 0 && (
								<span className='fab-FormField'>
									<IconButton
										icon={ifFeature('encore', 'xmark-regular', 'fab-circle-x-12x12')}
										floatingIcon={true}
										size='small'
										color='secondary'
										onClick={() => dispatch({ type: 'remove', index })}
										type='button'
									/>
								</span>
							)}
						</div>
					))}
					<p>
						<TextButton clickAction={() => dispatch({ type: 'add' })} text={'+ ' + $.__('Add Reminder')} type='button' />
					</p>
				</div>
			</div>
		</Fragment>
	);
}

/** @typedef {import('../state').Reminder} Reminder */
/**
 * @typedef {{ type: 'add' } | { type: 'remove', index: number } | ({ type: 'update', index: number } & { [key in keyof Reminder]?: Reminder[key] })} Action
 */
