import {
	DisabilityCategoryDataOptionsContract,
	DisabilityDisbursementTypeEnum,
	PlanCategoryDataOptionsContract,
	PlanRateTypeEnum,
} from '../../types';

export const getCostsMessage = (
	rateType: PlanRateTypeEnum,
	planCategoryDataOptions: PlanCategoryDataOptionsContract | undefined
): string | null => {
	const disabilityCategoryDataOptions = planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
	if (disabilityCategoryDataOptions?.disabilityDisbursementType === DisabilityDisbursementTypeEnum.other) {
		return $.__(`You will enter costs during each employee's enrollment.`);
	}

	if (rateType === PlanRateTypeEnum.variable) {
		return $.__(`As a Variable Rate plan, you will enter costs during each employee's enrollment.`);
	}

	return null;
};
