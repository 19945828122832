import React, { useState } from 'react';
import { Button } from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import Upsell from 'Upsell';
import { timeTrackingItems } from '../../../../app-configs/upsell';
import api from '../../../../app-services/api';
import { serializeDemoRequest } from './serializers';

const TimeTrackingUpsell = ({ companyInTrial, demoRequest }) => {
	// See example of demo state shape below in defaultProps
	const [demo, setDemo] = useState(serializeDemoRequest(demoRequest));
	const [isRequestPending, setIsRequestPending] = useState(false);

	const handleEnableTimeTrackingRequest = () => {
		setIsRequestPending(true);
		api.expansion.timeTrackingEnableRequest
			.post()
			.then((response) => {
				if (response.data.reload) {
					window.location.reload();
				}
			})
			.catch(() => {
				window.errorFallBack();
			});
	};

	const handleUpsellRequest = () => {
		setIsRequestPending(true);

		// Make the request
		api.expansion.timeTrackingDemoRequest
			.post()
			.then(({ data }) => {
				console.log(data);
				// Update the state
				setDemo(serializeDemoRequest(data));

				// Show success slidedown
				showSlidedown($.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."), 'success');
			})
			.catch(() => {
				// Show error slidedown
				showSlidedown($.__('Oops... something went wrong. Please try again.'), 'error');
			});

		// Disable processing state
		setIsRequestPending(false);
	};

	const getActionButtons = () => {
		if (companyInTrial) {
			return [
				<Button
					noSpacing={true}
					onClick={handleEnableTimeTrackingRequest}
					processing={isRequestPending}
					size='large'
					type='button'
					width='100'
				>
					{$.__('Enable Time Tracking')}
				</Button>,
			];
		}
		return [
			<Button
				data-bi-id='settings-time-tracking-request-demo-button'
				disabled={demo.isRequested}
				noSpacing={true}
				onClick={handleUpsellRequest}
				processing={isRequestPending}
				size='large'
				type='button'
				width='100'
			>
				{$.__('Schedule a Personalized Demo')}
			</Button>,
		];
	};

	return (
		<Upsell>
			<Upsell.Title>{$.__('Spend Minutes Tracking Hours')}</Upsell.Title>
			<Upsell.Video title={$.__('Time Tracking Video')} videoId='3ot9fc24vr' />
			<Upsell.Content
				actions={getActionButtons()}
				request={{
					demoRequestedBy: demo.requestedBy,
					demoRequestedDate: demo.requestDate,
					isDemoRequested: demo.isRequested,
				}}
				sideNavMessage={
					companyInTrial &&
					$.__(
						'*Time Tracking is an add-on feature that can be added at an additional cost. For questions and pricing, talk to your sales rep.'
					)
				}
			>
				<div style={{ textAlign: 'left' }}>
					<h4>
						{companyInTrial
							? $.__('Enable Time Tracking in Your Trial Account')
							: $.__('Time Tracking adds time management capabilities so you can:')}
					</h4>

					{companyInTrial && <p>{$.__('BambooHR Time Tracking adds time management capabilities to BambooHR so you can:')}</p>}

					<ul>
						{timeTrackingItems.map((item, index) => (
							<li key={`time-tracking-item-${index}`}>{item}</li>
						))}
					</ul>
				</div>
			</Upsell.Content>
		</Upsell>
	);
};

TimeTrackingUpsell.defaultProps = {
	companyInTrial: false,
	demoRequest: {
		isRequested: false,
		requestedBy: {
			id: null,
			firstName: '',
			lastName: '',
		},
		requestDate: null,
	},
};

export default TimeTrackingUpsell;
