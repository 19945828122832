import {
	Checkbox,
	CheckboxOnChangeParam,
	Radio,
	RadioOnChangeParam,
	RadioValue,
	makeStyles,
} from '@bamboohr/fabric';

interface DeductionSyncOptionProps {
	radioLabel: string;
	checkboxLabel: string;
	radioNote: string;
	radioIsSelected: boolean;
	radioValue: string | number;
	checkboxIsChecked: boolean;
	checkboxValue: string | number;
	onRadioChange(value: RadioValue): void;
	onCheckboxChange(value: string, checked: boolean): void;
}

const useStyles = makeStyles({
	checkboxContainer: {
		marginLeft: '25px',
		padding: '15px 20px',
		backgroundColor: '#FEF6C7',
	}
});

export function DeductionSyncOption(props: DeductionSyncOptionProps): JSX.Element {
	const {
		checkboxLabel,
		checkboxIsChecked,
		checkboxValue,
		onCheckboxChange,
		radioLabel,
		radioNote,
		radioIsSelected,
		radioValue,
		onRadioChange,
	} = props;
	const styles = useStyles();

	return (
		<>
			<Radio
				checked={ radioIsSelected }
				label={ radioLabel }
				note={ radioNote }
				onChange={ (param: RadioOnChangeParam) => onRadioChange(param.value) }
				value={ radioValue }
			/>

			{ radioIsSelected && (
				<div className={ styles.checkboxContainer }>
					<Checkbox
						checked={ checkboxIsChecked }
						label={ checkboxLabel }
						onChange={ (param: CheckboxOnChangeParam) => onCheckboxChange(param.value.toString(), param.checked) }
						value={ checkboxValue }
					/>
				</div>
			) }
		</>
	);
}
