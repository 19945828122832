import {
	values,
} from 'lodash';
import {
	showSlidedown,
} from 'Alerts.mod';
import Ajax from '@utils/ajax';
import {
	redirect,
} from 'BambooHR.util';

const MISSING_FIELDS_MSG = $.__('Whoops...please fill out all required fields and try again.');
const SOMETHING_WRONG_MSG = $.__('Uh oh...something went wrong updating codes and rates. Please try again.');

/**
 * Save the changes to codes and rates
 * @param formSelector  {string}   | Selector for the form to get the values
 * @param errorCallback {function} | Callback in case of error
 * @returns             {void}
 */
export function saveCodesRatesUpdate(formSelector, errorCallback) {
	if (!(formSelector && typeof errorCallback === 'function')) {
		return;
	}

	let error = false;
	const $form = $(formSelector);
	const fieldsArray = $form.serializeArray();

	fieldsArray.forEach((field) => {
		if ((field.name.includes("employerRate") && field.value === "$0.00") || !field.value) {
			error = true;
		}
	});

	if (error) {
		errorCallback(true);
		showSlidedown(MISSING_FIELDS_MSG, 'error');
		return;
	}

	const saveDataObject = formatSaveData(fieldsArray);

	Ajax.put(`/settings/payroll/taxes/tax_type/update_codes_rates/${ saveDataObject.taxId }`, saveDataObject)
		.then((response) => {
			if (response.status === 200) {
				redirect('/settings/payroll/taxes', $.__('Codes and rates were successfully updated'), 'success');
			} else {
				showSlidedown(SOMETHING_WRONG_MSG, 'error');
			}
		})
		.catch(() => showSlidedown(SOMETHING_WRONG_MSG, 'error'));
}

/**
 * Format the save data
 * @param fields {array}  | Array of field name/value pairs (from jQuery.serializeArray())
 * @return       {object} | Formatted data object for save
 */
export function formatSaveData(fields) {
	if (!Array.isArray(fields)) {
		return;
	}

	const saveDataObj = {};
	const codesObj = {};

	fields.forEach((field) => {
		const {
			name,
			value,
		} = field;

		if (name.indexOf('codeRateField') === -1) {
			saveDataObj[name] = value;
		} else {
			const [type, id, fieldName] = name.split('.');

			if (!codesObj.hasOwnProperty(id)) {
				codesObj[id] = {};
				codesObj[id].codeRateId = id > 0 ? id : null;
			}

			if (fieldName === 'isActive') {
				codesObj[id][fieldName] = value === 'true';
			} else if (fieldName === 'employeeRate' || fieldName === 'employerRate') {
				codesObj[id][fieldName] = parseFloat(value.replace('$', '').replace(',', ''));
			} else {
				codesObj[id][fieldName] = value;
			}

			if (!codesObj[id].hasOwnProperty('isActive')) {
				codesObj[id].isActive = false;
			}
		}
	});

	saveDataObj.codesAndRates = values(codesObj);

	return saveDataObj;
}
