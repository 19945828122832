import { toNumber, toString } from 'lodash';

const serializeDemoRequest = ((demoRequest = {}) => {
	return {
		isRequested: !!demoRequest?.isRequested,
		requestedBy: {
			id: toNumber(demoRequest?.requestedBy?.id) || null,
			firstName: toString(demoRequest?.requestedBy?.firstName),
			lastName: toString(demoRequest?.requestedBy?.lastName),
		},
		requestDate: demoRequest?.requestDate ? new Date(demoRequest.requestDate).toISOString() : null,
	}
});

export default serializeDemoRequest;