import { AcaValuesContract } from '../types/contracts';
import { BooleanSelectValue } from '../types/wizard-values';

export function getAcaPostData(
	acaMinEssentialCoverage: BooleanSelectValue,
	acaMinValueStandard: BooleanSelectValue
): AcaValuesContract {
	const acaValues: AcaValuesContract = {
		isMinEssentialCoverage: null,
		isMinValueStandard: null,
	};

	if (acaMinEssentialCoverage !== '') {
		acaValues.isMinEssentialCoverage = Boolean(acaMinEssentialCoverage);
	}

	if (acaMinValueStandard !== '') {
		acaValues.isMinValueStandard = Boolean(acaMinValueStandard);
	}

	return acaValues;
}
