import Ctrl, {
	onClick,
} from 'ctrl.deco';

import {
	isFunction
} from 'lodash';
import {
	getJsonScriptVar,
	redirect
} from 'BambooHR.util';

import './styles.styl';

let userData = getJsonScriptVar('userData', 'SettingsUsersAddEdit');

@Ctrl('/settings/users/edit/:userId')
class SettingsUsersEditCtrl {
	submitMethod = 'PUT';

	@onClick('[data-action]')
	onBtnClick(e, ctrl) {
		e.preventDefault();

		let action = $(this).attr('data-action');
		let fn = ctrl[`${ action }BtnClick`];

		if (isFunction(fn)) {
			fn.call(ctrl, e);
		}
	}

	resetPasswordBtnClick(e) {
		$.post(`/settings/users/reset-password/${ userData.id }`)
			.done((result) => {
				if (!result.success) {
					setMessage(result.errorMessage, 'error');
					console.error(result.error);
					return;
				}

				redirect('/settings/access_levels/all', result.message, 'success');
			})
			.fail((xhr, status, error) => {
				setMessage($.__('There was a problem. Please try again or contact support.'), 'error');
				console.error(error);
			});
	}

	deleteUserBtnClick(e) {
			window.BambooHR.Modal.setState({
				alternativeAction: null,
				header: $.__('Are you sure you want to delete this user?'),
				content: userData.displayName,
				isOpen: true,
				primaryAction() {
					window.BambooHR.Modal.setState({isOpen: true, isProcessing: true}, true);
					$.ajax({
						type: "DELETE",
						url: '/settings/users/delete/' + userData.id,
						dataType: 'json'
					})
						.done((result) => {
							if (!result.success) {
								window.BambooHR.Modal.setState({isOpen: false}, true);
								setMessage(result.error, 'error');
							}

							redirect('/settings/access_levels/all', result.message, 'success');
						});
				},
				primaryActionText: $.__('Delete this User'),
				secondaryActionText: $.__('No, Keep this User'),
				secondaryAction() {
					window.BambooHR.Modal.setState({isOpen: false});
				},
				title: $.__('Just checking...'),
				type: 'aggressiveConfirmation'
			});

	}
}
