import {
	showSlidedown,
} from 'Alerts.mod';

import {
	reactivateDeductionName,
	deleteDeductionName,
	ResponseObject,
	NormalizedDeductions,
} from '../../utils';

// Exports //////
export function handleReactivateClick(
	companyDeductionId: number,
	deductions: NormalizedDeductions,
	setCurrentDeductions: Function,
	setButtonProcessing: Function
): void {
	setButtonProcessing('reactivate');

	reactivateDeductionName(companyDeductionId)
		.then((response: ResponseObject) => {
			if (response.status === 200) {
				deductions.byId[companyDeductionId].isActive = true;
				setCurrentDeductions(deductions);
			} else {
				showReactivateError();
			}
			setButtonProcessing(null);
		})
		.catch(() => {
			setButtonProcessing(null);
			showReactivateError();
		});
}

export function handleDelete(
	companyDeductionId: number,
	deductions: NormalizedDeductions,
	setCurrentDeductions: Function,
	setButtonProcessing: Function
): void {
	setButtonProcessing('delete');

	deleteDeductionName(companyDeductionId)
		.then((response: ResponseObject) => {
			if (response.status === 200) {
				setCurrentDeductions(removeDeductionFromData(deductions, companyDeductionId));
			} else {
				showDeleteError();
			}

			setButtonProcessing(null);
		})
		.catch(() => {
			setButtonProcessing(null);
			showDeleteError();
		});
}

// Local functions //////
function showReactivateError(): void {
	showSlidedown($.__('Whoops...something went wrong reactivating this deduction name. Please try again.'), 'error');
}

function showDeleteError(): void {
	showSlidedown($.__('Whoops...something went wrong deleting this deduction name. Please try again.'), 'error');
}

function removeDeductionFromData(deductions: NormalizedDeductions, companyDeductionId: number): NormalizedDeductions {
	const {
		byId,
		allIds,
	} = deductions;
	const newById = {};
	const newAllIds = [];

	allIds.forEach((id) => {
		if (id !== companyDeductionId) {
			newById[id] = byId[id];
			newAllIds.push(id);
		}
	});

	return {
		byId: newById,
		allIds: newAllIds,
	};
}
