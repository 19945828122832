import { makeStyles } from '@bamboohr/fabric';

export default makeStyles(({ palette, typography }) => {
	return {
		note: {
			color: palette.gray[700],
			fontSize: typography.fabricFontSize('small'),
			lineHeight: typography.fabricLineHeight('small'),
			marginLeft: '12px',
			flex: 1,
		},
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			paddingTop: '14px',
		},
	};
});
