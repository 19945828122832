import {
	Accordion,
	BodyText,
	CheckboxGroup,
	Flex,
	Headline,
	IconV2,
	IconV2Name,
	LayoutBox,
	useAccordionBorderTheme,
	useTheme,
} from '@bamboohr/fabric';
import { GrimIcon } from '@bamboohr/grim';
import { useEffect, useState } from 'react';
import { useGoalSettingsContext } from '../../../../context';
import { FilterData, FiltersPayload } from '../../../../types';
import { CheckboxItems } from './types';
import { formatFilterData } from './utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Props {
	icon: GrimIcon | string;
	title: string;
	filters: FilterData;
	categoryValue: string;
}

export const FilterAccordion = (props: Props): JSX.Element => {
	const { categoryValue, icon: Icon, filters, title } = props;
	const [{ cancelButtonClicked, selectedFilters }, { setCancelButtonClicked, setSelectedFilters }] = useGoalSettingsContext();
	const { palette } = useTheme();
	/* @startCleanup encore */
	const classes = useAccordionBorderTheme();
	/* @endCleanup encore */

	const getFilterCount = () => {
		if (selectedFilters) {
			return selectedFilters[categoryValue]?.length;
		}

		return 0;
	};

	const [isExpanded, setIsExpanded] = useState(false);
	const [checkboxItems, setCheckboxItems] = useState(formatFilterData({ filters, categoryValue, selectedFilters }));
	const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(getFilterCount());

	useEffect(() => {
		if (selectedFilters && cancelButtonClicked) {
			setSelectedFiltersCount(selectedFilters[categoryValue]?.length);
			setCheckboxItems(formatFilterData({ filters, categoryValue, selectedFilters }));
		}
	}, [cancelButtonClicked]);

	const onCheckboxChange = (updatedItems: CheckboxItems) => {
		let checkedLength = 0;
		let newFilters = { ...selectedFilters };
		const selectedItems = updatedItems.filter((item) => item.checked).map((item) => item.value);

		updatedItems.forEach((currentFilter) => {
			if (currentFilter.checked) {
				checkedLength++;
			}
		});

		if (selectedItems?.length) {
			newFilters = { ...selectedFilters, [categoryValue]: selectedItems };
		} else {
			delete newFilters[categoryValue];
		}

		setCancelButtonClicked(false);
		setSelectedFilters(newFilters as FiltersPayload);
		setSelectedFiltersCount(checkedLength);
		setCheckboxItems(updatedItems);
	};

	const getHeaderHTML = () => {
		return (
			<Flex alignItems='center' height='30px' width='100%' justifyContent='space-between'>
				<Flex alignItems='center' gap='8px'>
					<Flex>
						{ifFeature(
							'encore',
							<IconV2 name={Icon as IconV2Name} size={20} color={isExpanded ? 'primary-strong' : 'neutral-medium'} />,
							<Icon fill={isExpanded ? palette.primary.main : palette.grey[900]} />
						)}
					</Flex>
					<LayoutBox paddingBottom={ifFeature('encore', 0, '6px')}>
						<Headline size='small' color={isExpanded ? 'primary' : 'neutral-strong'}>
							{title}
						</Headline>
					</LayoutBox>
					{selectedFiltersCount > 0 && <BodyText size='large' color='neutral-weak'>{`(${selectedFiltersCount})`}</BodyText>}
				</Flex>
			</Flex>
		);
	};

	return (
		<Accordion
			variant={ifFeature('encore', 'border')}
			classes={ifFeature('encore', undefined, classes)}
			headerContent={getHeaderHTML()}
			expanded={isExpanded}
			onChange={() => setIsExpanded(!isExpanded)}
		>
			<CheckboxGroup items={checkboxItems} onChange={({ updatedItems }): void => onCheckboxChange(updatedItems as CheckboxItems)} />
		</Accordion>
	);
};
