import { BlankState, Button, IconV2, LayoutBox } from '@bamboohr/fabric';
import { CalendarClock72x72, CirclePlus16x16 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const ReviewCyclesBlankState = (): JSX.Element => {
	const handleCreateReviewCycleClick = () => {
		window.location.href = '/settings/performance/home/setup';
	};

	return (
		<LayoutBox marginTop='94px'>
			<BlankState
			    actions={[<Button
					onClick={() => handleCreateReviewCycleClick()}
					startIcon={ifFeature('encore', 'circle-plus-regular', <CirclePlus16x16 />)}
					variant={ifFeature('encore', undefined, 'outlined')}
					color={ifFeature('encore', 'secondary')}
				>
					{$.__('Create a Review Cycle')}
				</Button>]}
				icon={ifFeature('encore', 'bs-inbox', <CalendarClock72x72 />)}
				subtitle={$.__('Setup Assessments and Feedback for employees.')}
				title={$.__('No review cycles currently exist')}
			/>
		</LayoutBox>
	);
};
