export const CHILD_SUPPORT = 'childSupport';
export const TAX_LEVIES = 'taxLevies';
export const OTHER = 'other';

/**
 * Check to see if launch of the SetUpPayees modal or JustCheckingModal is necessary
 *
 * @param initialChecked {boolean}         Whether the box was initially checked
 * @param currentChecked {boolean}         Whether the box is now checked
 * @param garnishments   {object}          Object containing garnishment data
 * @returns              {boolean|string}  Return false to launch neither, else a string to tell which modal to launch
 */
export function checkToLaunchModal(initialChecked, currentChecked, garnishments) {
	if ((!initialChecked && currentChecked && checkIfMissingPayees(garnishments))) {
		return 'showSetUpPayees';
	} else if ((initialChecked && !currentChecked && garnishments && garnishments.allIds.length)) {
		return 'showJustCheckingModal';
	}

	return false;
}

function checkIfMissingPayees(garnishments) {
	if (!garnishments || !garnishments.allIds || !garnishments.byId) {
		return false;
	}

	let missingPayees = false;
	const {
		allIds,
		byId,
	} = garnishments;

	allIds.forEach((id) => {
		const garnishment = byId[id];

		if(!garnishment.deductionPayeeId && !garnishment.globalPayeeId) {
			missingPayees = true;
		}
		
	});

	return missingPayees;
}
