import { WizardState } from '../../types/wizard-state';
import {
	EligibilityFilterValues,
	PlanState,
} from '../../types/wizard-values';
import { hasFilterEditRestrictions } from './edit-restrictions';

export const canDeleteFilterGroup = (
	groupCount: number,
	planState: PlanState,
	startDate: string,
	groupId: string | number,
	eligibilityFilters?: EligibilityFilterValues,
	wizardState?: WizardState,
): boolean => {
	return groupCount > 1 && !hasFilterEditRestrictions(planState, startDate, groupId, eligibilityFilters, wizardState);
};
