export const pandaWallace = {
	firstName: 'Panda',
	lastName: 'Wallace',
	position: 'Legendary Warrior',
	email: 'pandawallace@bamboohr.com',
	phone: '801-724-6600',
	extension: '0327',
	profileImageSrc: '/images/NHP/panda-wallace-86x86.png',
	socials: [
		{
			type: 'LinkedIn',
			alt: 'LinkedIn',
			href: 'https://www.linkedin.com/in/developertboyd/'
		},
		{
			type: 'Twitter',
			alt: 'Twitter',
			href: 'https://twitter.com/bamboohr'
		}
	]
};

export const chemistPanda = {
	firstName: 'Chemist',
	lastName: 'Panda',
	position: 'Chief Chemist',
	email: 'chemistpanda@bamboohr.com',
	phone: '801-724-6600',
	extension: '0456',
	profileImageSrc: '/images/NHP/chemist-panda-86x86.png',
};

const pandaProfileImageSrc = '/images/NHP/panda-profile-64x64.png';

export const recruiterPanda = {
	firstName: 'Pandrew',
	lastName: 'Panderson',
	position: 'Recruiter',
	email: 'pandrewson@bamboohr.com',
	phone: '801-766-3885',
	extension: '0456',
	profileImageSrc: pandaProfileImageSrc,
};

export const hrManagerPanda = {
	firstName: 'Pandrea',
	lastName: 'Pandora',
	position: 'HR Manager',
	email: 'pandreapandora@bamboohr.com',
	phone: '801-756-6068',
	extension: '1379',
	profileImageSrc: pandaProfileImageSrc,
};

export const designerPanda = {
	firstName: 'Pandi',
	lastName: 'Pandromeda',
	position: 'Graphic Designer',
	email: 'ppandromeda@bamboohr.com',
	phone: '801-772-7272',
	extension: '8228',
	profileImageSrc: pandaProfileImageSrc,
};

export const managerPanda = {
	firstName: 'Pamanda',
	lastName: 'Pandoreo',
	position: 'Team Manager',
	email: 'pandoreos@bamboohr.com',
	phone: '385-355-3883',
	extension: '1221',
	profileImageSrc: pandaProfileImageSrc,
};

export const sleepyPanda = {
	firstName: 'Sleepy',
	lastName: 'Panda',
	position: 'Pillow Tester',
	email: 'sleepypanda@bamboohr.com',
	phone: '801-724-6600',
	extension: '0789',
	profileImageSrc: '/images/NHP/sleepy-panda-86x86.png',
};

export const superPanda = {
	firstName: 'Super',
	lastName: 'Panda',
	position: 'Superhero',
	email: 'superpanda@bamboohr.com',
	phone: '801-724-6600',
	extension: '0321',
	profileImageSrc: '/images/NHP/super-panda-86x86.png',
};

export const astroPanda = {
	firstName: 'Astro',
	lastName: 'Panda',
	position: 'Mission Commander',
	email: 'astropanda@bamboohr.com',
	phone: '801-724-6600',
	extension: '0654',
	profileImageSrc: '/images/NHP/astro-panda-86x86.png',
};
