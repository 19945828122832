import moment from 'moment';

import { DateSelectOption } from './components/date-select-option/date-select-option';

import { CompanySelectOption } from './components/company-select-option/company-select-option';

export function formatAllowableDates(allowableDates) {
	const formattedDates = [];

	Object.keys(allowableDates).forEach((key) => {
		formattedDates.push({
			value: key,
			dateText: allowableDates[key].split(' (')[0],
			dateDate: moment(key, 'YYYY-MM-DD').format('M/D/YYYY'),
		});
	});

	return formattedDates;
}

export function renderDateSelectOption(item, optionState) {
	if (!Array.isArray(item)) {
		item = [item];
	}

	return (
		<DateSelectOption { ...optionState } { ...item[0] } />
	);
}

export function renderCompanySelectOption(item, optionState) {
	if (!Array.isArray(item)) {
		item = [item];
	}

	return (
		<CompanySelectOption { ...optionState } { ...item[0] } />
	);
}

export function renderDate(item) {
	if (!Array.isArray(item)) {
		item = [item];
	}

	return (
		<div className="CompanyTaxesDateSelectOption">
			<span className="CompanyTaxesDateSelectOption__text">
				{ item[0].quarterText }
			</span>
			<span className="CompanyTaxesDateSelectOption__date">
				({ item[0].quarterDate })
			</span>
		</div>
	);
}
