import { BEM } from '@utils/dom';

import './interstitial-auth.styl';

const bem = new BEM('InterstitialAuth');

import { createStyles, makeStyles } from '@bamboohr/fabric';

const styleNames = {
	Header: bem.elem('Header'),
	Auth: bem.elem('Auth'),
	Instructions: bem.elem('Instructions'),
	InstructionsError: bem.elem('InstructionsError'),
	Code: bem.elem('Code'),
	RememberMe: bem.elem('RememberMe'),
	InterstitialAuth: bem.elem(),
	Footer: bem.elem('Footer'),
	FooterAction: bem.elem('FooterAction'),
	FooterActionLast: bem.elem('FooterAction', '--last'),
	mfaLoginInterstitial: '',
	mfaChannelContainer: '',
	authContent: '',
	mfaLoginInstructionText: '',
	resendCodeContainer: '',
	smsResendCode: '',
};

const newStyling = makeStyles(({ palette, typography }) => {
	return createStyles({
		mfaLoginInstructionText: {
			color: palette.gray[1000], // Silly to add this here but I am setting up palette for refactoring styles later
			fontWeight: typography.fontWeightBold,
		},
		mfaLoginInterstitial: {
			'&.InterstitialAuth': {
				minHeight: '380px',
			},
		},
		mfaChannelContainer: {
			marginTop: '34px',
		},
		resendCodeContainer: {
			marginRight: '46px',
		},
		authContent: {
			'&.InterstitialAuth__Auth': {
				paddingBottom: '38px',
			},
		},
		smsResendCode: {
			marginLeft: '48px',
		},
	});
});

export const useStyles = () => {
	const updatedStyles = newStyling();
	return { ...styleNames, ...updatedStyles };
};
