import { normalize } from '@utils/collection';
export const MODAL_TITLE = $.__('Set Up Payees');
export const HEADER_BY_TYPE = {
	'childSupport': $.__('Child Support'),
	'taxLevies': $.__('Tax Levies'),
	'other': $.__('Other Garnishments')
};

export function getGarnishmentsWithoutPayees(garnishments) {
	if (!(garnishments && garnishments.allIds && garnishments.byId)) {
		return null;
	}

	const {
		allIds,
		byId,
	} = garnishments;
	const garnishmentsWithoutPayees = [];

	allIds.forEach((id) => {
		const garnishment = byId[id];

		if (!garnishment.deductionPayeeId && !garnishment.globalPayeeId) {
			garnishmentsWithoutPayees.push(garnishment);
		}
	});

	if (garnishmentsWithoutPayees.length) {
		return normalize(garnishmentsWithoutPayees);
	}

	return null;
}

export function validateGarnishmentsForSave(garnishments, isChildSupport) {
	if (!(garnishments && garnishments.allIds && garnishments.byId)) {
		return false;
	}

	const {
		allIds,
		byId,
	} = garnishments;
	let valid = true;
	let message = $.__('Whoops... No worries. Please make sure each garnishment has a payee selected, and try again.');

	allIds.forEach((id) => {
		const garnishment = byId[id];
		const {
			deductionPayeeId,
			globalPayeeId,
			remittanceId,
		} = garnishment;

		if (!deductionPayeeId && !globalPayeeId) {
			garnishment.error = true;
			valid = false;
		}

		if (isChildSupport && !remittanceId) {
			garnishment.error = true;
			valid = false;
			message = $.__('Whoops... No worries. Please make sure each garnishment has a Remittance ID, and try again.');
		}
	});

	return {
		garnishments,
		valid,
		message,
	};
}

export function formatGarnishmentsForSave(garnishments) {
	if (!(garnishments && garnishments.allIds && garnishments.byId)) {
		return false;
	}

	const {
		allIds,
		byId,
	} = garnishments;
	const saveData = [];

	allIds.forEach((id) => {
		const {
			deductionPayeeId,
			globalPayeeId,
			caseNumber,
			description,
			remittanceId,
		} = byId[id];

		saveData.push({
			employeeDeductionId: id,
			deductionPayeeId,
			globalPayeeId,
			caseNumber: caseNumber || '',
			caseDescription: description || '',
			caseParticipantId: remittanceId || null,
		});
	});

	return saveData;
}
