import moment from 'moment.lib';

export default (invoices) => {
	const dates = invoices.map((invoice) => invoice.billingDate);

	const firstInvoiceDate = dates.reduce((cur, next) => {
		return Date.parse(next) < Date.parse(cur) ? next : cur;
	}, new Date());

	const mFirstInvoiceDate = moment(firstInvoiceDate).utc();
	const mFirstDayOfyear = moment().utc().startOf('year');

	const startDate = mFirstInvoiceDate.isAfter(mFirstDayOfyear)
		? mFirstInvoiceDate.format('YYYY-MM-DD')
		: mFirstDayOfyear.format('YYYY-MM-DD');

	return {
		endDate: moment().utc().format('YYYY-MM-DD'),
		firstInvoiceDate: mFirstInvoiceDate.format('YYYY-MM-DD'),
		startDate,
	};
};
