import moment from 'moment';

import { BenefitPlanListContract } from '../types/contracts';

export function isPlanFutureStart(plan: BenefitPlanListContract): boolean {
	if (plan && plan.startDate) {
		return (moment(plan.startDate).isAfter(moment()));
	}
	return false;
}
