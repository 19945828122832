import React from 'react';
import { getRequestedDemoText } from '../../helpers';
import styles from './styles';

const Content = ({
	actions,
	children,
	request,
	sideNavMessage,
}) => {
	const classes = styles();
	const { isDemoRequested } = request;

	return (
		<div className={ classes.view }>
			<div className={ classes.content }>{ children }</div>
			<div className={ classes.sidenav }>
				<div className={ classes.actions }>
					{ actions.map((action, index) => (
						<div key={ `upsell-action-${ index }` } className={ classes.action }>{ action }</div>
					)) }
					{ isDemoRequested && (
						<div className={ classes.request }>
							{ getRequestedDemoText(request) }
						</div>
					) }
				</div>
				<div className={ classes.sideNavMessage }>{ sideNavMessage }</div>
			</div>
		</div>
	);
}

Content.defaultProps = {
	actions: [],
	children: null,
	request: {
		demoRequestedBy: {
			id: null,
			firstName: '',
			lastName: '',
		},
		demoRequestedDate: '',
		isDemoRequested: false,
	},
	sideNavMessage: null,
}

export default Content;