import {
	useState,
} from 'react';
import {
	normalize,
} from '@utils/collection';
import { Table } from '@bamboohr/fabric';
import BlankState from 'blank-state.react';

import {
	getColumns,
	DeductionObject,
	NormalizedDeductions,
} from './utils';

import './deductions-table.styl';
import { AddDeductionRow } from './components/add-deduction-row';

interface DeductionsTableProps {
	deductionTypeTranslated: string;
	managedDeductionType: string;
	deductions: Array<DeductionObject>;
	filter: string;
}

export function DeductionsTable(props: DeductionsTableProps): JSX.Element {
	const {
		deductionTypeTranslated,
		managedDeductionType,
		deductions,
		filter,
	} = props;
	const [editingId, setEditingId] = useState(null);
	const [currentDeductions, setcurrentDeductions] = useState<NormalizedDeductions>(normalize(deductions, 'companyDeductionId'));
	const {
		byId: deductionsById,
		allIds: allDeductionIds,
	} = currentDeductions;
	const filterByActive = filter === 'active';
	const rowData = [];
	allDeductionIds.forEach((id) => {
		const deduction = deductionsById[id];
		if (deduction.isActive === filterByActive) {
			rowData.push(deduction);
		}
	});
	const rowDataEmpty = !rowData.length;
	const blankStateTitle = filterByActive ? $.__('There are no active deductions.') : $.__('There are no inactive deductions.');

	return (
		<div className="DeductionsTable">
			<Table
				caption={ deductionTypeTranslated }
				columns={ getColumns(
					deductionTypeTranslated,
					currentDeductions,
					editingId,
					setEditingId,
					setcurrentDeductions,
					managedDeductionType
				) }
				rowKey={ (row: DeductionObject): number => row.companyDeductionId }
				rows={ rowData }
			/>
			{ filterByActive && !rowDataEmpty && (
				<AddDeductionRow
					deductions={ currentDeductions }
					managedDeductionType={ managedDeductionType }
					setCurrentDeductions={ setcurrentDeductions }
					setEditingId={ setEditingId }
				/>
			) }
			{ rowDataEmpty && (
				<div className="DeductionsTable__blank">
					<BlankState
						subtitle={ blankStateTitle }
					/>
				</div>
			) }
		</div>
	);
}
