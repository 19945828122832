import { Fragment } from 'react';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { BankAccountForm } from '../../../components/bank-account-form';

import { StatusRow } from '../../../../company-information.react/components/status-row';

import { BANK_APP_STATE } from '../../../utils/shared';

export function BankVerifiedStateComponent(props) {
	const { company, onSubmit, isProcessing, onInputChange, plaidLinkToken, plaidUpdateSuccess } = props;

	return (
		<Fragment>
			<BankInfoHeader />

			<StatusRow status={BANK_APP_STATE.VERIFIED} />

			<BankAccountForm
				isEdit
				bankAccount={company.bankAccounts[0]}
				company={company}
				isProcessing={isProcessing}
				isVerified={true}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
				plaidLinkToken={plaidLinkToken}
				plaidUpdateSuccess={plaidUpdateSuccess}
				showCompletedFormsSection={!company.isLegacy}
				statusSpacing={true}
			/>
		</Fragment>
	);
}
