import { render } from 'base/wrapped-render';

import Ctrl, {
    onPjaxEnd,
    onReady,
} from 'ctrl.deco';

import { PoMicroFrontend } from 'micro-frontend.react';

const renderSettingsCompensation = () => {
    const compensationRoot = document.getElementById('js-compensationRoot');

    if (compensationRoot) {
        render(
            <PoMicroFrontend />,
            compensationRoot
        );
    }
};

@Ctrl('/settings/compensation*')
class SettingsCompensation {
    @onReady()
    @onPjaxEnd()
    _readyHandler() {
        renderSettingsCompensation();
    }
}
