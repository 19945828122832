import { BodyText, Flex, Headline, LayoutBox, Radio } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ReactElement } from 'react';

interface Props {
	isLoading: boolean;
	toggleStatus: string;
	handleRadioChange: (value: string) => void;
}

export const MobileQrLoginControls = (props: Props): ReactElement => {
	const { isLoading, toggleStatus, handleRadioChange } = props;

	return (
		<>
			{
				ifFeature(
					'encore', 
					<Headline size='extra-small'>{$.__('Mobile App QR Code Login')}</Headline>,
					<Headline color='neutral-strong' size='small'>{$.__('Mobile App QR Code Login')}</Headline>
				)
			}
			<Flex alignItems='center' margin='20px 0 24px'>
				<LayoutBox marginRight='20px'>
					<Radio
						checked={toggleStatus === 'on'}
						disabled={isLoading}
						label={$.__('On')}
						onChange={({ value }) => handleRadioChange(value)}
						id='on'
						value='on'
					/>
				</LayoutBox>
				<LayoutBox>
					<Radio
						checked={toggleStatus === 'off'}
						disabled={isLoading}
						label={$.__('Off')}
						onChange={({ value }) => handleRadioChange(value)}
						id='off'
						value='off'
					/>
				</LayoutBox>
			</Flex>
			<BodyText color='neutral-strong' size='medium' weight='regular'>
				{$.__(
					'With this turned on, your employees will be able to log in to the BambooHR Mobile App using a QR Code after they have successfully logged in to their account on a desktop computer.'
				)}
			</BodyText>
		</>
	);
};
