import { ItemType } from '@bamboohr/fabric';

import { BenefitPlanListContract } from './types/contracts';
import { isBenefitPlanActive } from './etc/is-benefit-plan-active';
import { PlanTypeEnumContract } from '../types/plan-contracts';

export interface RevisionModalData {
	benefitType: PlanTypeEnumContract | null;
	isModalOpen: boolean;
	planId: string;
	plans: ItemType[];
}

export const DEFAULT_REVISION_MODAL_DATA: RevisionModalData = {
	benefitType: null,
	isModalOpen: false,
	planId: '',
	plans: [],
};

export const getRevisionAvailableActivePlans = (benefitType: PlanTypeEnumContract, plans: BenefitPlanListContract[]): ItemType[] => {
    return plans.reduce((acc: ItemType[], plan: BenefitPlanListContract) => {
        if (plan.type === benefitType && isBenefitPlanActive(plan)) {
            acc.push({
                text: plan.planName,
                value: String(plan.id),
            });
        }
        return acc;
    }, []);
};
