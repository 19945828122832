import { Fragment } from 'react';

import moment from 'moment';

import { TaxTypeDetails } from '../components/tax-type-details/tax-type-details';

import { CompanyTaxField } from '../components/company-tax-field/company-tax-field';

export function EditTaxForm(props) {
	const {
		effectiveDate,
		notes,
		payFrequencyId,
		state,
		employerTaxId,
		taxRate,
		taxTypeId,
		updateFormData,
	} = props;

	const { selectedState } = state;

	const { selectedTaxType } = taxTypeId;

	const taxTypeDetails = [
		{
			header: $.__('Effective Date'),
			value: moment(effectiveDate.value, 'YYYY-MM-DD').format('MMM D, YYYY'),
		},
	];

	if (selectedState) {
		taxTypeDetails.push({ header: $.__('State'), value: selectedState.value });
	}

	if (selectedTaxType) {
		taxTypeDetails.push({ header: $.__('Tax Type'), value: selectedTaxType.taxTypeName });
	}

	return (
		<Fragment>
			<TaxTypeDetails details={ taxTypeDetails } />

			<CompanyTaxField
				{ ...employerTaxId }
				fieldType="tax-id"
				label={ $.__('Tax ID') }
				name="employerTaxId"
				selectedTaxType={ selectedTaxType }
				updateFormData={ updateFormData }
				width={ 8 }
			/>
			<CompanyTaxField
				{ ...payFrequencyId }
				fieldType="payFrequency"
				label={ $.__('Pay Frequency') }
				placeholder={ $.__('First Select Tax Type') }
				selectedTaxType={ selectedTaxType }
				updateFormData={ updateFormData }
				width={ 6 }
			/>
			<CompanyTaxField
				{ ...taxRate }
				fieldType="taxRate"
				isEdit={ true }
				label={ $.__('Tax Rate') }
				selectedTaxType={ taxTypeId.selectedTaxType }
				unit="%"
				updateFormData={ updateFormData }
			/>
			{/* <CompanyTaxField
				{ ...notes }
				fieldType="textarea"
				label={ $.__('Notes') }
				updateFormData={ updateFormData }
				width={ 9 }
			/> */}
		</Fragment>
	);
}
