import { Moment } from 'moment';
import { useContext } from 'react';
import { Data } from '../../utils/';
import { getSelectedValue } from './shared';

interface UseCheckReadyProps {
	scheduleStartDate: Moment;
	selectedFrequencyValue: string;
	selectedScheduleValue: string;
	includeContractors: boolean;
	schedulePending: boolean;
}

interface UseCheckReadyState {
	isSaveReady: boolean;
	hasChangesToCancel: boolean;
}

const useCheckReady = ({scheduleStartDate, selectedFrequencyValue, selectedScheduleValue, includeContractors, schedulePending}: UseCheckReadyProps): UseCheckReadyState => {

	const {
		state: {
			nextSurveyScheduledDate,
			frequencyOptions,
			scheduleOptions,
			includeContractors: initialIncludeContractors,
		}
	} = useContext(Data);

	const lastSelectedFrequency = getSelectedValue(frequencyOptions);
	const lastSelctedScheduleOption = getSelectedValue(scheduleOptions);

	const changesHaveBeenMade = (
		!schedulePending && (
			scheduleStartDate?.format('YYYY-MM-DD') !== nextSurveyScheduledDate ||
			lastSelectedFrequency !== selectedFrequencyValue ||
			lastSelctedScheduleOption !== selectedScheduleValue ||
			initialIncludeContractors !== includeContractors
		)
	);

	// Return false, if one of the inputs is not selected
	if (scheduleStartDate === null || selectedScheduleValue === null || selectedFrequencyValue === null) {
		return {
			isSaveReady: false,
			hasChangesToCancel: changesHaveBeenMade
		};
	}

	// If there is not a previous save, return true if all values are selected and schedule is not pending.
	if (nextSurveyScheduledDate === null || lastSelectedFrequency === null || lastSelctedScheduleOption === null) {
		return {
			isSaveReady: scheduleStartDate !== null && selectedFrequencyValue !== null && selectedScheduleValue !== null && !schedulePending,
			hasChangesToCancel: changesHaveBeenMade
		};
	}

	return {
		isSaveReady: changesHaveBeenMade,
		hasChangesToCancel: changesHaveBeenMade
	};
};

export default useCheckReady;
