import {
	createContext,
	useContext,
} from 'react';


/** @type {React.Context<Partial<State>>} */
const StateContext = createContext({});

export const StateProvider = StateContext.Provider;

export const useStateContext = () => useContext(StateContext);

/**
 * @typedef State
 * @property {SelectItems<'before' | 'after'>} beforeAfter
 * @property {string} customTableId
 * @property {SelectItems<string>} customTables
 * @property {boolean} dayOfAlert
 * @property {SelectItems<string>} eventFields
 * @property {string} fieldId
 * @property {SelectItems<'days' | 'weeks' | 'months'>} intervals
 * @property {boolean} isCustom
 * @property {boolean} isCustomTable
 * @property {boolean} preSelectedEventField
 * @property {Reminder[]} reminders
 * @property {string} runAtTime
 * @property {string} runAtTimeTZ
 * @property {string[]} selectedFieldIds
 * @property {SelectItems<string>} timezones
 */

/**
 * @typedef Reminder
 * @property {string} [due_within]
 * @property {string} [due_interval]
 * @property {string} [before_after]
 */

/**
 * @typedef {{ text: string, value: V }[]} SelectItems
 * @template {string} V
 * */
