import Ajax from '@utils/ajax';

import Template from './forms.react';

export const showEditEmployeeFieldModal = (title, data) => {

	Ajax.get(`/settings/pay_groups.php?pay_group_count_message=1`, data)
		.then(function(res) {
			data.editMessage = res.data === null ? '' : res.data.editMessage;
			data.buttonText = $.__('I Understand, Let Me Edit It');

			window.BambooHR.Modal.setState({
				content: (<Template {...data } />),
				isOpen: true,
				isProcessing: false,
				primaryAction: function() {
					const theForm = document.getElementById('editForm');
					const validator = $(theForm).bhrValidate();

					validator.form();

					if (validator.valid()) {
						window.BambooHR.Modal.setState({ isProcessing: true }, true);
						const newData = new FormData(theForm);

						Ajax.post(`/settings/pay_groups.php?cid=${ data.companyId }`, newData, {
						})
							.then(function(res) {
								const {
									message,
									success
								} = res.data;

								if (success) {
									window.closeMessage();
									location.reload();
								} else {
									window.setMessage(message, 'error');
								}
							})
							.catch(() => window.errorFallBack());
					}
				},
				primaryActionText: $.__('Save'),
				title,
			});
	
			$('#value').select().focus();	
		})
		.catch(() => window.errorFallBack());
};
