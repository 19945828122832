import { AxiosError } from "axios";
import { EmployeeWellbeingData } from "../types";

export interface SurveyStatusType {
	setIsSurveyScheduled: (val: boolean) => void;
	showGetStartedPage: boolean;
	isSurveyScheduled: boolean;
	onKillSwitch: () => void;
	onSurveyScheduleSaveSuccess: (data: EmployeeWellbeingData) => void;
	onGetStartedClick: () => void;
	onRequestDemo: () => void;
	isPreviewing: boolean;
	surveyId: number|null;
	showPreview: (surveyId?: number) => void;
	hidePreview: () => void;
	previewEndpoint: string;
	displayPromoPage: boolean;
	demoPreviouslyRequested: boolean;
}

export interface SlidedownState {
    showSuccess: () => void;
    showError: () => void;
    hide: () => void;
    visible: boolean;
    status: SlidedownStatus;
}

export enum SlidedownStatus {
	Success = "success",
	Error = "error",
}

export interface EmployeeWellbeingSettingsProviderProps {
    data: EmployeeWellbeingData;
}

export type EmployeeWellbeingErrorHandler = (response: AxiosError) => void;
