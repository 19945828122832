import { EmployeeTaxBoxContent } from '../employee-tax-box-content';
import {
	DatabaseString,
	DirectDepositObject,
	EmployeeTaxFieldName,
	EmployeeTaxMetadata,
	FieldSelectBoxMetadata,
	SectionType,
} from '../../utils/interfaces';
import { BankBoxContent } from '../bank-box-content';

export function getFieldSelectBoxContent(
	sectionType: SectionType,
	isSelected: boolean,
	database: DatabaseString,
	metadata: FieldSelectBoxMetadata,
	fieldName: string
): JSX.Element {
	switch (sectionType) {
		case 'employeeBank':
			return (
				<BankBoxContent
					database={ database }
					depositData={ metadata as Array<DirectDepositObject> }
					isSelected={ isSelected }
				/>
			);
		case 'employeeTax':
			return (
				<EmployeeTaxBoxContent
					fieldName={ fieldName as EmployeeTaxFieldName }
					isSelected={ isSelected }
					metadata={ metadata as EmployeeTaxMetadata }
				/>
			);
		default:
			return null;
	}
}
