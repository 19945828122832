import { render } from 'base/wrapped-render';
import { isEnabled } from '@bamboohr/utils/lib/feature';
import { Bee18x18 } from '@bamboohr/grim';
import Ctrl, { onPjaxEnd, onReady } from 'ctrl.deco';
import { PoMicroFrontend } from 'micro-frontend.react';
import { uniqueId } from 'lodash';

const renderSettingsEmployeeCommunity = () => {
    const employeeCommunitySettingsRoot = document.getElementById('js-honeySettingsRoot');

    if (employeeCommunitySettingsRoot) {
        render(
            <PoMicroFrontend customClassNamePrefix={uniqueId('employee-community-settings-page')}/>,
            employeeCommunitySettingsRoot
        );
    }
};

// @startCleanup encore
if (!isEnabled('encore')) {
	const iconRoot = document.getElementById('employeeCommunitySettingsNavIconReactRoot');
	if (iconRoot) {
		render(<Bee18x18 className="SettingsNav__itemIcon"/>, iconRoot);
	}
}
// @endCleanup encore

@Ctrl('/settings/employee_community')
class EmployeeCommunitySettingsCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		document.title = $.__('Employee Community');
		renderSettingsEmployeeCommunity();
	}
}
