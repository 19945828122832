import { render } from 'base/wrapped-render';
import { getJsonScriptVar } from 'BambooHR.util';
import { TimeTrackingTrial } from './components';

export default () => {
	const trialData = document.getElementById('js-time-tracking-trial-data');
	const upsellData = getJsonScriptVar('upsellData', 'TimeTracking') || {};
	const jsonData = JSON.parse(trialData.innerHTML);

	render(<TimeTrackingTrial demoRequest={ upsellData } trialSettings={jsonData} />, document.getElementById('settings-trial-demo'));
};
