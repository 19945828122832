import { Modal } from 'modal-legacy';

interface SafeHarborWarningModalProps {
	isModalOpen: boolean;
	closeModal: () => void;
}

export function SafeHarborWarningModal(props: SafeHarborWarningModalProps): JSX.Element {
	const {
		closeModal,
		isModalOpen,
	} = props;

	return (
		<Modal
			alternativeActionText={ null }
			headline={ $.__('The payroll safe harbor match setting will apply to your current and future 401(k) and Roth 401(k) plans.') }
			icon="fab-piggy-bank-48x44"
			isOpen={ isModalOpen }
			onClose={ closeModal }
			primaryAction={ closeModal }
			primaryActionText={ $.__('Got it') }
			title={ $.__('Just so you know...') }
		/>
	);
}
