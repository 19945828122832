import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({
	palette,
	spacing,
}) => {
	return createStyles({
		root: {
			paddingBottom: spacing(2),
		},
	});
});
