import { Fragment } from 'react';

import { BankAccountForm } from '../../../components/bank-account-form';
import { BankInfoHeader } from '../../../components/bank-info-header';

import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';
import { StatusRow } from '../../../../company-information.react/components/status-row';

import { BANK_APP_STATE } from '../../../utils/shared';

export function BankSavedStateComponent(props) {
	const { company, onSubmit, isProcessing, onInputChange, onFileDownload, plaidLinkToken, plaidUpdateSuccess } = props;

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager
				clientId={company.clientId}
				isProcessing={isProcessing}
				onFileDownload={onFileDownload}
				statuses={[BANK_APP_STATE.BANK_SAVED]}
			/>

			<StatusRow status={BANK_APP_STATE.BANK_SAVED} />

			<BankAccountForm
				isEdit
				bankAccount={company.bankAccounts[0]}
				company={company}
				isProcessing={isProcessing}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
				plaidLinkToken={plaidLinkToken}
				plaidUpdateSuccess={plaidUpdateSuccess}
			/>
		</Fragment>
	);
}
