import {last} from 'lodash';
import Tooltip from 'Tooltip.mod';
import rowTemplate from './employment-status-row.micro.html';


let employmentStatusRowsContainer = null;
let employmentStatuses = [];
let selectedStatuses = [];

let noEmploymentStatusesTooltip = null;
let mustSelectStatusTooltip = null;


function getAndSetupData() {
	const employmentStatusesJSON = document.querySelector('#js-paid-holidays-employment-statuses');
	const statuses = employmentStatusesJSON ? JSON.parse(employmentStatusesJSON.innerHTML) : {};
	const {employeeStatusListValues = [], allEmployeesDefaultHours = null} = statuses;

	employmentStatuses = employeeStatusListValues
		.map(({id, listValueId, name, defaultHours}) => ({id, listValueId, name, defaultHours}))
		.concat({
			id: '-1',
			listValueId: '-1',
			name: $.__('All Employees'),
			defaultHours: allEmployeesDefaultHours,
		});

	employmentStatuses = employmentStatuses.map(({id, listValueId, name, defaultHours}) => {
		return {
			id,
			name,
			defaultHours: (defaultHours === null) ? '' : defaultHours,
			hiddenInputName: listValueId === '-1' ? null : `employeeStatusListValues[${ listValueId }]`,
			inputName: id === '-1' ? 'allEmployeesDefaultHours' : `paidHolidayLevel[${ id }]`,
			disabled: employmentStatuses.length === 1,
			// options: [], Setup on render
			showRemove: false,
			showAdd: false,
			canAdd: false,
			addIcon: 'fab-circle-plus-14x14'
		};
	});

	selectedStatuses = employmentStatuses.filter(status => status.defaultHours !== '');

	if (selectedStatuses.length === 0) {
		selectedStatuses.push(getStatusById('-1'));
	}
}


function dropdownChangeHandler({detail: {value: newId}, target}) {
	const oldId = getIdFromTarget(target);
	const oldStatus = getStatusById(oldId);
	const newStatus = getStatusById(newId);

	const oldValue = oldStatus.defaultHours;
	oldStatus.defaultHours = '';
	newStatus.defaultHours = oldValue;

	const selectedIndex = selectedStatuses.indexOf(oldStatus);
	selectedStatuses[selectedIndex] = newStatus;

	renderAllRows();
}


function addOrRemoveHandler({target}) {
	const add = target.closest('.js-add-status-row');
	const remove = target.closest('.js-remove-status-row');

	if (add || remove) {
		const allStatus = getStatusById('-1');

		$(add || remove)
			.closest('.js-employment-status-row')
			.find('input.js-toggle-buttons')
			.trigger('change');

		if (add) {
			selectedStatuses.push(allStatus);
			renderAllRows();

			const $row = $(employmentStatusRowsContainer).find('.js-employment-status-row');
			$row.last().hide().slideDown(100, () => {
				$row.find('input[type="text"]').focus();
			});
		} else {
			const status = getStatusById(getIdFromTarget(target));
			const selectedIndex = selectedStatuses.indexOf(status);
			status.defaultHours = '';
			selectedStatuses.splice(selectedIndex, 1);

			$(target)
				.closest('.js-employment-status-row')
				.slideUp(100, renderAllRows);
		}
	}
}


function inputChangeHandler({target}) {
	const changedId = getIdFromTarget(target);
	getStatusById(changedId).defaultHours = target.value;
}


function setRowDropDownOptions() {
	const lastSelectedStatus = last(selectedStatuses);

	employmentStatuses = employmentStatuses.map((status) => {
		status.options = employmentStatuses
			.filter((otherStatus) => {
				if (status === lastSelectedStatus && otherStatus.id === '-1') {
					return true;
				} else if (otherStatus.id === '-1') {
					return false;
				}

				return otherStatus.id === status.id || selectedStatuses.indexOf(otherStatus) === -1;
			})
			.map(({id, name}) => ({id, name}));

		return status;
	});
}


function setRowAddAndRemoveStates() {
	const lastSelectedStatus = last(selectedStatuses);

	employmentStatuses = employmentStatuses
		.map((status) => {
			status.showRemove = selectedStatuses.length > 1;
			status.showAdd = false;
			status.canAdd = false;
			return status;
		});

	if (lastSelectedStatus.options.length > 1) {
		lastSelectedStatus.showAdd = true;

		if (lastSelectedStatus.id !== '-1') {
			lastSelectedStatus.canAdd = true;
		}
	}
}


function getStatusById(id) {
	return employmentStatuses.find(obj => obj.id === id);
}


function getIdFromTarget(target) {
	return target
		.closest('.js-employment-status-row')
		.getAttribute('data-status-id');
}


function renderAllRows() {
	getStatusById('-1').name = (selectedStatuses.length > 1) ? $.__('Everyone Else') : $.__('All Employees');

	setRowDropDownOptions();
	setRowAddAndRemoveStates();

	employmentStatusRowsContainer.innerHTML = selectedStatuses
		.map(rowTemplate)
		.join('');

	moveMustSelectStatusTooltip();
}


function createNoEmploymentStatusesTooltip() {
	const anchor = document.querySelector('.js-employment-statuses-container');

	noEmploymentStatusesTooltip = Tooltip.create(anchor, {
		html: $('.js-no-statuses-tooltip').html(),
		position: 'bottom',
		triggerEvent: 'click',
	});

	anchor.addEventListener('mouseenter', () => {
		noEmploymentStatusesTooltip.show();
	});
}


function createMustSelectStatusTooltip() {
	mustSelectStatusTooltip = Tooltip.createStandard(document.createElement('noop'), {
		content: $.__('You must first select an employment status.'),
	});

	mustSelectStatusTooltip.balloonContentElement.style.width = '136px';
	mustSelectStatusTooltip.resetPositionLock();

	moveMustSelectStatusTooltip();
}

function moveMustSelectStatusTooltip() {
	const anchor = document.querySelector('.js-cannot-add-status-row');
	if (mustSelectStatusTooltip !== null && anchor) {
		mustSelectStatusTooltip.setAnchor(anchor);
	}
}


export function pjaxCleanup() {
	if (noEmploymentStatusesTooltip) {
		noEmploymentStatusesTooltip.destroy();
		noEmploymentStatusesTooltip = null;
	}

	if (mustSelectStatusTooltip) {
		mustSelectStatusTooltip.destroy();
		mustSelectStatusTooltip = null;
	}
}


export function setupEmploymentStatuses() {
	employmentStatusRowsContainer = document.getElementById('js-employment-status-rows');

	getAndSetupData();
	renderAllRows();

	if (employmentStatuses.length === 1) {
		createNoEmploymentStatusesTooltip();
	} else {
		createMustSelectStatusTooltip();
	}

	employmentStatusRowsContainer.addEventListener('change', inputChangeHandler);
	employmentStatusRowsContainer.addEventListener('ba:selectChange', dropdownChangeHandler);
	employmentStatusRowsContainer.addEventListener('click', addOrRemoveHandler);
}
