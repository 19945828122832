import { SelectItem } from '../../types';

/**
 * Returns the value of the selected selectItem as a string
 * @param options 
 * @returns 
 */
export const getSelectedValue = (options: SelectItem[]): string|null => {
	const value = options?.some(option => option.selected) ? options.find(option => option.selected).value as string : null;
	return value ?? null;
};
