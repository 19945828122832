import React, { useEffect } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';

export const ApiKeyManagementMicro = () => {
	useEffect(() => {
		document.title = $.__('API Keys');
	}, [])
	return (
		<PoMicroFrontend />
	);
};
