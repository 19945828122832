import React, { useEffect, useState, useRef } from 'react';
import clsx from 'classnames';
import Clipboard from 'clipboard';
import { Button, LayoutBox, makeStyles, TextButton } from '@bamboohr/fabric';
import { CirclePlus14x14 } from '@bamboohr/grim';
import { makeUid } from 'BambooHR.util';
import { ifFeature } from "@bamboohr/utils/lib/feature";

const useStyles = makeStyles(({ mixins, palette, typography }) => {
	return ({
		largeWidth: {
			boxSizing: 'border-box',
			width: '324px',
		},
		labelWrapper: {
			display: 'flex',
			alignItems: 'end',
			justifyContent: 'space-between',
			marginBottom: '5px',
			'& label': {
				lineHeight: typography.fabricLineHeight('small'),
			},
		},
		keyText: {
			display: 'inline-block',
			borderRadius: ifFeature('encore', 500),
			backgroundColor: ifFeature('encore', palette.info.light, mixins.alpha(palette.primary.lightest, 0.1)),
			color: palette.grey[1000],
			marginRight: ifFeature('encore' ,'16px', '20px'),
			padding: '6px 32px 6px 10px',
		},
		copyButton: {
			fontSize: typography.fabricFontSize('small'),
			lineHeight: typography.fabricLineHeight('small'),
		},
	})
});

const INPUT_NAME = 'privateKey';
const CHAR_LENGTH = 32;
const RADIX = 16;

export function PrivateSignatureKeyField(props) {
	const { savedKey } = props;
	const [newKey, setNewKey] = useState();
	const [clipboard, setClipboard] = useState();
	const copyButtonRef = useRef();
	const keyTextRef = useRef();
	const classes = useStyles();
	const privateKey = newKey || savedKey;

	useEffect(() => {
		return () => {
			if (clipboard) {
				clipboard.destroy();
			}
		};
	}, []);

	useEffect(() => {
		if (newKey && !clipboard) {
			const clipboard = new Clipboard(copyButtonRef.current, {
				target: () => keyTextRef.current
			});

			clipboard.on('success', function (e) {
				e.clearSelection();
				setMessage($.__('Private Signature Key copied to clipboard.'), 'success');
			});

			clipboard.on('error', function () {
				setMessage($.__('Private Signature Key was unable to copy to clipboard. Please try again.'), 'error');
			});

			setClipboard(clipboard);
		}
	}, [newKey]);

	const generateNewKey = () => {
		const key = makeUid(CHAR_LENGTH, RADIX);
		setNewKey(key);
	};

	return (
		<div className="fieldRow">
			<div className={ clsx(classes.labelWrapper, { [classes.largeWidth]: !!newKey }) }>
				<label
					className="fab-Label"
					htmlFor={ INPUT_NAME }
				>
					{ $.__('Private Signature Key') }
				</label>
				{
					// @startCleanup encore
					newKey &&
					ifFeature('encore',
						undefined
						,
						<TextButton
							className={ classes.copyButton }
							inline={ true }
							ref={ copyButtonRef }
							type="button"
						>
							{ $.__('COPY KEY') }
						</TextButton>
					)
					// @endCleanup encore
				}
			</div>

			{ privateKey && (
				<span
					className={ clsx(classes.keyText, {
						[classes.largeWidth]: !!newKey
					}) }
					ref={ keyTextRef }
				>
					{ privateKey }
				</span>
			)}
			{ newKey &&
				ifFeature('encore',
					<LayoutBox display="inline-block" marginRight={2}>
						<Button
							ref={ copyButtonRef }
							type="button"
							variant="outlined"
						>
							{ $.__('Copy Key') }
						</Button>
					</LayoutBox>
				)
			}
			<Button
				onClick={ () => generateNewKey() }
				startIcon={ ifFeature('encore', 'plus-regular', <CirclePlus14x14 />) }
				type="button"
				// @startCleanup encore
				variant={ ifFeature('encore', undefined, "outlined") }
				// @endCleanup encore
			>
				{ privateKey ? $.__('New Key') : $.__('Generate Key') }
			</Button>
			<input id={ INPUT_NAME } name={ INPUT_NAME } type="hidden" value={ privateKey || '' } />
		</div>
	);
}
