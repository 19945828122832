import { render } from 'base/wrapped-render';
import Ctrl, { onReady } from 'ctrl.deco';
import { PoMicroFrontend } from 'micro-frontend.react';

class SettingsAccountUpdateBillingContactsCtrl {
	@onReady()
	_render() {
		const billingContactsPopoverRoot = document.getElementById('billing-contacts-popover-root');

		if (billingContactsPopoverRoot) {
			render(
				<PoMicroFrontend customClassNamePrefix='billing-contacts-popover' route='/billing/billing-contacts-popover' />,
				billingContactsPopoverRoot
			);
		}

		const billingContactsTooltipRoot = document.getElementById('billing-contacts-tooltip-root');

		if (billingContactsTooltipRoot) {
			render(
				<PoMicroFrontend customClassNamePrefix='billing-contacts-tooltip' route='/billing/billing-contacts-tooltip' />,
				billingContactsTooltipRoot
			);
		}
	}
}

Ctrl('/settings/account/update_billing_contacts')(SettingsAccountUpdateBillingContactsCtrl);
