import { CostTypeStringEnum } from '../../types';
import { DEFAULT_CALCULATOR_SETTINGS } from '../eligibility-cost/constants';
import { EligibilityGroupCalculatorSettings, PlanRateTypeEnum } from '../types';

export const getInitialCalculatorSettings = (
	rateType: PlanRateTypeEnum,
	calculatorSettings?: EligibilityGroupCalculatorSettings
): EligibilityGroupCalculatorSettings => {
	const defaultCalculatorSettings: EligibilityGroupCalculatorSettings = {
		...DEFAULT_CALCULATOR_SETTINGS,
		companyPays: null,
		employeePays: null,
		currencyCode: 'USD',
		costType: CostTypeStringEnum.fixed,
		type: rateType || PlanRateTypeEnum.standard,
	} as EligibilityGroupCalculatorSettings;

	// New group
	if (!calculatorSettings) {
		return defaultCalculatorSettings;
	}

	return {
		...calculatorSettings,
		costType: calculatorSettings.costType || CostTypeStringEnum.fixed,
		currencyCode: 'USD',
		rate: calculatorSettings.rate ?? null,
		type: rateType || PlanRateTypeEnum.standard,
	} as EligibilityGroupCalculatorSettings;
};
