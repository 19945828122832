import { Heading } from '../heading';

export function Intro(props) {
	return (
		<Heading
			subText={ $.__('We are making some changes to BambooHR that will make it easier to track ACA data per employee. But in order to complete these changes, we need you to choose an ACA status for each of your employment statuses.') }
			text={ $.__('Choose an ACA status for each Employment Status') }
		/>
	);
}
