import { Component, Fragment } from 'react';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';

import { Modal } from 'modal-legacy';

export class AdvancedApprovalsConfirmationModal extends Component {

	constructor(props) {
		super(props);

		this._getConfirmationHandler = this._getConfirmationHandler.bind(this);
		this._handleDelete = this._handleDelete.bind(this);
		this._handleSave = this._handleSave.bind(this);
	}

	_getConfirmationHandler(type) {
		switch (type) {
			case 'delete':
				return this._handleDelete;
			case 'override':
				return this._handleSave;
		}
	}

	_getText(actionType, paths) {
		const validActions = {
			delete: 'delete',
			override: 'override'
		};

		const validPathTypes = {
			department: 'department',
			division: 'division',
			location: 'location'
		};

		paths = paths.slice(0);

		let pathLength = paths.length;
		let finalPath = (paths.length > 1) ? paths.pop() : '';
		let pathList = this._parseList(paths);

		let mainText = '';
		let subText = { override: '' };
		let confirmButtonText = '';

		switch (actionType) {
			case validActions.delete:
				mainText = $.__('Are you sure you want to delete this approval?');
				subText[validPathTypes.department] = (
					<Message
						em={ { tag: 'b', className: 'fontSemibold' } }
						params={ [pathList,finalPath] }
						text={ $._n('{1} will receive the default approval flow “*All Other Departments.*”',
							'{1} and {2} will receive the default approval flow “*All Other Departments.*”', pathLength) }
					/>
				);
				subText[validPathTypes.division] = (
					<Message
						em={ { tag: 'b', className: 'fontSemibold' } }
						params={ [pathList,finalPath] }
						text={ $._n('{1} will receive the default approval flow “*All Other Divisions.*”',
							'{1} and {2} will receive the default approval flow “*All Other Divisions.*”', pathLength) }
					/>
				);
				subText[validPathTypes.location] = (
					<Message
						em={ { tag: 'b', className: 'fontSemibold' } }
						params={ [pathList,finalPath] }
						text={ $._n('{1} will receive the default approval flow “*All Other Locations.*”',
							'{1} and {2} will receive the default approval flow “*All Other Locations.*”', pathLength) }
					/>
				);


				confirmButtonText = $.__('Delete Approval');
				break;
			case validActions.override:
				mainText = $.__n(
					'You already have an approval flow for %2.',
					'You already have an approval flow for %2 and %3.',
					pathLength,
					pathList,
					finalPath
				);
				subText.override = $.__n(
					'If you proceed, your previous approval flow will be overwritten.',
					'If you proceed, your previous approval flows will be overwritten.',
					pathLength
				);
				confirmButtonText = $.__('Overwrite Approval');
				break;
		}

		return {
			confirmButtonText,
			mainText,
			subText
		};
	}

	_parseList(paths) {
		if (Array.isArray(paths)) {
			let listSeparator = (paths.find((string) => { return string.indexOf(',') > -1; })) ? '; ' : ', ';
			return paths.join(listSeparator);
		}

		return '';
	}

	_handleDelete() {
		const {
			closeConfirmModalAction,
			editingWorkflow,
			handleDeleteApprovalPath,
			parentId
		} = this.props;

		const {
			id,
		} = editingWorkflow;

		closeConfirmModalAction();
		handleDeleteApprovalPath(id, parentId);
	}

	_handleSave() {
		const {
			closeConfirmModalAction,
			handleSave
		} = this.props;

		closeConfirmModalAction();
		handleSave(true);
	}

	render() {

		const {
			actionType,
			closeConfirmModalAction,
			pathType,
			paths,
			modalOpen
		} = this.props;

		const _handleConfirmation = this._getConfirmationHandler(actionType);
		const _handleClose = closeConfirmModalAction;

		const {
			confirmButtonText,
			mainText,
			subText
		} = this._getText(actionType, paths);
		const icon = ifFeature('encore', 'triangle-exclamation-solid', 'fab-triangle-exclamation-48x48');

		const title = $.__('Just Checking...');

		return (
			<Fragment>
				<Modal
					content={
						(
							<div className="AdvancedApprovalsConfirmationModal">
								<div className="AdvancedApprovalsConfirmationModal__text-container">
									<p className="AdvancedApprovalsConfirmationModal__text-container--sub-text">{ subText[pathType] || subText.override }</p>
								</div>
							</div>
						)
					}
					headline={ mainText }
					icon={ icon }
					iconColor="attention"
					iconV2Color={ ifFeature('encore', 'warning-medium') }
					isOpen={ modalOpen }
					onClose={ _handleClose }
					primaryAction={ _handleConfirmation }
					primaryActionText={ confirmButtonText }
					title={ title }
				/>
			</Fragment>
		);
	}

}
