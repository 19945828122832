import { FilterGroup, FilterType, FilterValue } from '../../../types';
import { ChildItem, ParentItem } from '../types';

export const generateSelectedFilterMenuItems = (
	filterGroup: FilterGroup,
	parentItems: ParentItem[]
): ChildItem[] => {
	const selectedItems: ChildItem[] = [];

	parentItems.forEach((parentItem) => {
		filterGroup.values[parentItem.key].forEach((filterValue) => {
			const childItem = parentItem.items.find(childItem => (
				childItem.value === filterValue
			));
			if (childItem) {
				selectedItems.push(childItem);
			}
		});
	});

	return selectedItems;
};

export const addFilterTypeToSelections = (
	filterType: FilterType,
	menuFilterTypeSelections: FilterType[]
): FilterType[] => {
	const newSelections: FilterType[] = [...menuFilterTypeSelections];

	if (!newSelections.includes(filterType)) {
		newSelections.push(filterType);
	}

	return newSelections;

};

export const removeFilterTypeFromSelections = (
	filterType: FilterType,
	menuFilterTypeSelections: FilterType[]
): FilterType[] => {
	const newSelections: FilterType[] = [...menuFilterTypeSelections];

	const removeIndex = newSelections.findIndex(selection => (selection === filterType));
	if (removeIndex > -1) {
		newSelections.splice(removeIndex, 1);
	}

	return newSelections;
};

export const removeItemFromSelectedValues = (
	selectedValues: FilterValue[],
	deselectedValue: FilterValue
): FilterValue[] => {
	const newSelectedValues: FilterValue[] = [...selectedValues];

	const deselectedIndex = newSelectedValues.findIndex(selectedValue => (
		selectedValue === deselectedValue
	));
	if (deselectedIndex > -1) {
		newSelectedValues.splice(deselectedIndex, 1);

	}
	return newSelectedValues;
};
