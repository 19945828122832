/** copied from `./index.js` and refactored into a functional component */

import BlankState from 'blank-state.react';
import { BlankState as FabricBlankState } from '@bamboohr/fabric';
import moment from 'moment';
import { useState } from 'react';
import TimeTrackingNotificationModal from 'time-tracking/modals/time-tracking-notification';
import { nonReactRender } from '../setup-checklist.react/components/enable-modal/future';
import './styles.styl';
import { Table } from './table';
import { ifFeature } from '@utils/feature';

export interface EmployeeSettingsProps {
	canChangeFutureDates: boolean;
	canToggleTimeTracking: boolean;
	settingsMode: 'global' | 'group',
	customOvertimeEnabled: boolean;
	employees: any[];
	reloadEmployees: () => void;
	renderActions: (props) => JSX.Element;
	renderAddEmployeesButton: () => JSX.Element;
	showEnableOptionsWhenAddingEmployees: boolean;
}

export function EmployeeSettings(props: EmployeeSettingsProps) {
	const {
		canChangeFutureDates,
		canToggleTimeTracking,
		settingsMode,
		customOvertimeEnabled,
		employees,
		reloadEmployees,
		renderActions,
		renderAddEmployeesButton,
	} = props;
	const [isChangeAllStartDatesButtonProcessing, setIsChangeAllStartDatesButtonProcessing] = useState(false);
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [notificationModalEmployees, setNotificationModalEmployees] = useState([]);
	const [notificationModalType, setNotificationModalType] = useState('someEmployeesNotRescheduled');

	const onSaveAllStartDates = (paySchedules) => {
		const dates = paySchedules.map((ps) => moment(ps.date).format('MMMM D, YYYY')).join(` ${$.__('and')} `);
		window.setMessage($.__('Got it! Time tracking will start %1$s', dates), 'success');
		reloadEmployees();
	};

	const onSaveAllStartDatesFail = (errorResponse) => {
		setNotificationModalOpen(true);
		setNotificationModalEmployees(errorResponse.employees);
		setNotificationModalType(errorResponse.type);
		reloadEmployees();
	};

	return (
		<div>
			{canToggleTimeTracking && (
				<div style={{ marginBottom: 16 }}>
					{renderActions({
						changeStartDates: canChangeFutureDates
							? () => {
									setIsChangeAllStartDatesButtonProcessing(true);
									// Need to update this to use the react modal... but for times sake...
									nonReactRender(
										'edit',
										() => setIsChangeAllStartDatesButtonProcessing(false),
										onSaveAllStartDates,
										Function.prototype,
										[],
										onSaveAllStartDatesFail
									);
							  }
							: undefined,
						isChangingStartDates: isChangeAllStartDatesButtonProcessing,
					})}
				</div>
			)}

			{employees.length === 0 && (
				<div className='STE_BlankState'>
				{ifFeature('encore',
					<FabricBlankState
						actions={[renderAddEmployeesButton()]}
						icon="bs-clock"
						title={$.__(`There aren't any employees tracking time right now.`)}
					/>,
					<BlankState
						button={renderAddEmployeesButton()}
						icon="fab-stopwatch-100x120"
						title={$.__(`There aren't any employees tracking time right now.`)}
					/>
				)}
				</div>
			)}

			{employees.length > 0 && (
				<Table
					canToggleTimeTracking={canToggleTimeTracking}
					customOvertimeEnabled={customOvertimeEnabled}
					employees={employees}
					reloadEmployees={reloadEmployees}
					settingsMode={settingsMode}
				/>
			)}

			<TimeTrackingNotificationModal
				employees={notificationModalEmployees}
				isOpen={notificationModalOpen}
				onClose={() => setNotificationModalOpen(false)}
				type={notificationModalType}
			/>
		</div>
	);
}
