import classNames from 'classnames';
import { FieldSelectBox } from '../field-select-box';
import { DeductionGroupPopover } from '../deduction-group-popover';
import { EmployeeDeductionGroupPopover } from '../employee-deduction-group-popover';
import { Icon, TableColumn, TableGroup } from '@bamboohr/fabric';

import {
	getFieldSelectBoxContent,
} from '../../components/field-select-box';
import {
	ITEM_TEXT_AND_METADATA,
	getIsFieldDisabled,
} from '../../utils/utilities';
import {
	DatabaseString,
	EmployeeDeductionsMetadata,
	FieldObject,
	FieldSelectBoxProps,
	FieldSelectChangeHandler,
	FieldsNormalized,
	GroupIcon,
	GroupsNormalized,
	ManualUpdateCheckHandler,
	SectionType,
} from '../../utils/interfaces';

const SUB_GROUPED_SECTIONS = ['employeeWage', 'employeeDeductions'];

export function getGroups(
	groups: GroupsNormalized,
	icon: GroupIcon,
	isErrorTable: boolean,
	sectionType: SectionType
): Array<TableGroup> {
	const {
		byId,
		allIds,
	} = groups;

	return allIds.map((groupId) => {
		const {
			bamboo: {
				name: bambooName,
				link: bambooLink,
			},
			trax: {
				name: traxName,
				link: traxLink,
			},
			metadata,
		} = byId[groupId];

		if (sectionType === 'companyDeductions') {
			return {
				id: groupId,
				content: <DeductionGroupPopover deductionMetadata={ metadata } />,
			};
		}

		const iconName = isErrorTable ? 'fab-triangle-exclamation-16x16' : icon;
		const bambooGroupName = bambooLink ? <a href={ bambooLink } rel="noopener noreferrer" target="_blank">{ bambooName }</a> : bambooName;
		const traxGroupName = traxLink ? <a href={ traxLink } rel="noopener noreferrer" target="_blank">{ traxName }</a> : traxName;
		const iconClasses = classNames(
			'MigrationTable__groupHeaderIcon',
			{ 'MigrationTable__groupHeaderIcon--attention': isErrorTable }
		);

		return {
			id: groupId,
			content: (
				<div className="MigrationTable__groupHeader">
					{ iconName && (
						<span className={ iconClasses }>
							<Icon name={ iconName } />
						</span>
					) }
					<span className="MigrationTable__groupHeaderName">
						{ bambooName && bambooGroupName }
						{ bambooName && traxName && ' / ' }
						{ traxName && traxGroupName }
					</span>
				</div>
			),
		};
	});
}

export function getRows(fields: FieldsNormalized, sectionType: SectionType): Array<FieldObject> {
	const {
		byId,
		allIds,
	} = fields;
	// Handle sub-grouped rows scenario for specific section types
	if (SUB_GROUPED_SECTIONS.indexOf(sectionType) > -1) {
		const rows = [];
		const subGroupRowsAdded = [];
		const isEmployeeDeductions = sectionType === 'employeeDeductions';

		allIds.forEach((fieldId) => {
			const field: FieldObject = byId[fieldId];
			const {
				subGroup,
				subGroupMetadata,
				group,
				selectedDatabase,
			} = field;

			if (subGroupRowsAdded.indexOf(subGroup) === -1) {
				const {
					getMetadata,
					getText,
				} = ITEM_TEXT_AND_METADATA[sectionType];
				const bambooMetadata = getMetadata(subGroupMetadata, 'bamboo');
				const traxMetadata = getMetadata(subGroupMetadata, 'trax');
				const isPresentEndDate = 'endDate' in bambooMetadata && bambooMetadata.endDate === 'Present';
				const fieldName = isPresentEndDate ? $.__('Current Record') : $.__('Previous Record');

				rows.push({
					fieldName: isEmployeeDeductions ? (
						<EmployeeDeductionGroupPopover
							bambooMetadata={ bambooMetadata as EmployeeDeductionsMetadata }
							traxMetadata={ traxMetadata as EmployeeDeductionsMetadata }
						/>
					) : fieldName,
					bamboo: {
						displayValue: isEmployeeDeductions ? null : getText(bambooMetadata),
					},
					trax: {
						displayValue: isEmployeeDeductions ? null : getText(traxMetadata),
					},
					canEdit: false,
					isSubGroup: true,
					id: subGroup,
					group,
					selectedDatabase,
				}, field);
				subGroupRowsAdded.push(subGroup);
			} else {
				rows.push(field);
			}
		});

		return rows;
	}

	return allIds.map(fieldId => byId[fieldId]);
}

function getIsPresentEndDate(sectionType: SectionType, fieldName: string, isNullValue: boolean): boolean {
	if (!isNullValue) {
		return false;
	}
	switch (sectionType) {
		case 'companyDeductions':
			return fieldName === 'end_date';
		case 'employeeDeductions':
			return fieldName === 'employee_deduction_end_date';
		case 'employeeWage':
			return fieldName === 'End Date';
		default:
			return false;
	}
}

function getFieldSelectBoxProps(
	fieldData: FieldObject,
	database: DatabaseString,
	changeHandler: FieldSelectChangeHandler,
	sectionType: SectionType
): FieldSelectBoxProps {
	const {
		id,
		selectedDatabase,
		fieldName,
		selectSubGroup,
	} = fieldData;
	const {
		displayValue,
		value,
		metadata,
	} = fieldData[database];
	const nullValue = value === null;
	const isPresentEndDate = getIsPresentEndDate(sectionType, fieldName, nullValue);
	const updatedDisplayValue = isPresentEndDate ? $.__('Present') : displayValue;
	const isDisabled = isPresentEndDate ? false : getIsFieldDisabled(fieldData, database);

	return {
		id,
		displayValue: updatedDisplayValue,
		value,
		isSelected: selectedDatabase === database,
		onChange: changeHandler,
		database,
		isDisabled,
		sectionType,
		metadata,
		selectSubGroup,
		fieldName,
	};
}

function getRecordCellContent(
	row: FieldObject,
	database: DatabaseString,
	changeHandler: FieldSelectChangeHandler,
	sectionType: SectionType
): JSX.Element|string {
	const {
		canEdit,
		isSubGroup,
	} = row;

	if (isSubGroup) {
		return <p className="MigrationTable__nameCell--subGroup">{ row[database].displayValue }</p>;
	}

	if (canEdit) {
		return <FieldSelectBox { ...getFieldSelectBoxProps(row, database, changeHandler, sectionType) } />;
	}

	return row[database].displayValue || '--';
}

function getColumns(changeHandler: FieldSelectChangeHandler, sectionType: SectionType): Array<TableColumn<FieldObject>> {
	return [
		{
			header: $.__('BambooHR Record'),
			cell: (row: FieldObject) => getRecordCellContent(row, 'bamboo', changeHandler, sectionType),
			width: '280px',
		},
		{
			header: $.__('TRAXPayroll Record'),
			cell: (row: FieldObject) => getRecordCellContent(row, 'trax', changeHandler, sectionType),
		},
		{
			headerAriaLabel: $.__('Help text'),
			cell: (row: FieldObject) => row.helperText || null,
			verticalAlign: 'middle',
		},
	];
}

function getGroupedColumns(changeHandler: FieldSelectChangeHandler, sectionType: SectionType): Array<TableColumn<FieldObject>> {
	return [
		{
			header: $.__('Name'),
			cell: (row: FieldObject) => {
				const {
					displayFieldName,
					fieldName,
					isSubGroup,
				} = row;

				return isSubGroup ?
					<p className="MigrationTable__nameCell--subGroup">{ fieldName }</p> :
					<p className="MigrationTable__nameCell">{ displayFieldName }</p>;
			},
			sortBy: (row: FieldObject) => row.group,
			width: '255px',
			verticalAlign: 'middle',
		},
		{
			header: $.__('BambooHR Record'),
			cell: (row: FieldObject) => getRecordCellContent(row, 'bamboo', changeHandler, sectionType),
			width: '370px',
		},
		{
			header: $.__('TRAXPayroll Record'),
			cell: (row: FieldObject) => getRecordCellContent(row, 'trax', changeHandler, sectionType),
		},
		{
			headerAriaLabel: $.__('Help text'),
			cell: (row: FieldObject) => row.helperText || null,
			verticalAlign: 'middle',
		},
	];
}

function getErrorColumns(changeHandler: ManualUpdateCheckHandler, groups: GroupsNormalized, sectionType: SectionType): Array<TableColumn<FieldObject>> {
	return [
		{
			header: $.__('Name'),
			cell: (row: FieldObject) => {
				const {
					displayFieldName,
					fieldName,
					isSubGroup,
				} = row;

				return isSubGroup ?
					<p className="MigrationTable__nameCell--subGroup">{ fieldName }</p> :
					<p className="MigrationTable__nameCell">{ displayFieldName }</p>;
			},
			sortBy: (row: FieldObject) => row.group,
			width: '255px',
			verticalAlign: 'middle',
		},
		{
			header: $.__('Correct Value'),
			cell: (row: FieldObject): JSX.Element|string => {
				const {
					fieldName,
					selectedDatabase,
					isSubGroup,
				} = row;
				const {
					displayValue,
					metadata,
				} = row[selectedDatabase];

				if (isSubGroup) {
					const originalDatabaseText = selectedDatabase === 'bamboo' ? 'BambooHR' : 'TRAXPayroll';
					return (
						<p className="MigrationTable__nameCell--subGroup">
							{ originalDatabaseText }:
						&nbsp;
							{ row[selectedDatabase].displayValue }
						</p>
					);
				}

				const fieldSelectBoxContent = getFieldSelectBoxContent(sectionType, false, selectedDatabase, metadata, fieldName);
				if (fieldSelectBoxContent) {
					return fieldSelectBoxContent;
				}

				return displayValue;
			},
		},
		{
			headerAriaLabel: $.__('Location to Update'),
			cell: (row: FieldObject): JSX.Element|string => {
				const {
					selectedDatabase,
					group,
					isSubGroup,
				} = row;

				if (isSubGroup) {
					return null;
				}

				const bambooSelected = selectedDatabase === 'bamboo';
				const oppositeDatabase = bambooSelected ? 'trax' : 'bamboo';
				const databaseLink = groups.byId[group][oppositeDatabase].link;
				const instructionText = bambooSelected ? $.__('Update in TRAXPayroll') : $.__('Update in BambooHR');

				if (!databaseLink) {
					return instructionText;
				}

				return (
					<a
						className="fab-Button fab-Button--outline"
						href={ databaseLink }
						rel="noopener noreferrer"
						target="_blank"
					>
						{ instructionText }
					</a>
				);
			},
		},
		{
			headerAriaLabel: $.__('Record updated checkbox'),
			cell: (row: FieldObject) => {
				const {
					id,
					isSubGroup,
				} = row;

				if (isSubGroup) {
					return null;
				}

				return (
					<div className="fab-Checkbox">
						<input className="fab-Checkbox__input" id={ id } onChange={ event => changeHandler(id, event.target.checked) } type="checkbox" />
						<label className="fab-Checkbox__label" htmlFor={ id }>
							{ $.__(`I've updated this record`) }
						</label>
					</div>
				);
			},
		},
	];
}

export function getColumnsByTableType(
	isGrouped: boolean,
	isErrorTable: boolean,
	groups: GroupsNormalized,
	fieldSelectChangeHandler: FieldSelectChangeHandler,
	manualUpdateCheckHandler: ManualUpdateCheckHandler,
	sectionType: SectionType
): Array<TableColumn<FieldObject>> {
	if (isErrorTable) {
		return getErrorColumns(manualUpdateCheckHandler, groups, sectionType);
	}
	if (isGrouped) {
		return getGroupedColumns(fieldSelectChangeHandler, sectionType);
	}
	return getColumns(fieldSelectChangeHandler, sectionType);
}
