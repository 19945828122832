import {
	useReducer,
	useMemo,
	useEffect,
	Fragment,
} from 'react';
import {
	chunk,
	range,
} from 'lodash';

import Ajax from '@utils/ajax';
import {
	Select,
} from '@fabric/select';
import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	useStateContext,
} from '../state';


/**
 *
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
function reducer(state, action) {
	switch (action.type) {
		case 'set':
			if (Array.isArray(action.fields)) {
				action.dateFields = action.fields
					.filter(({ type, fieldId, stored }) => (
						type === 'date' &&
						parseInt(fieldId) !== 1 &&
						stored === 'yes'
					))
					.map(({ fieldId: value, name: text }) => ({
						value,
						text,
					}));

				const dateField = action.dateFields
					.find(({ value }) => (
						value == state.dateFieldId
					)) || action.dateFields[0];

				action.dateFieldId = (dateField && dateField.value) || state.dateFieldId;
			}

			if (action.dateFieldId) {
				action.selectedFieldIds = state.selectedFieldIds
					.filter(id => id != state.dateFieldId);
				action.selectedFieldIds.push(action.dateFieldId);
			}

			Object.assign(state, action);
			break;
		case 'toggleField':
			if (action.checked) {
				state.selectedFieldIds.push(action.id);
			} else {
				state.selectedFieldIds = state.selectedFieldIds
					.filter(id => id != action.id);
			}
			break;
	}

	return {
		...state,
	};
}

export default function CustomTables() {
	const {
		isCustomTable,
		customTables,
		customTableId: _customTableId,
		fieldId: _dateFieldId,
		selectedFieldIds: _selectedFieldIds,
		dayOfAlert: _dayOfAlert,
	} = useStateContext();

	if (!isCustomTable) {
		return null;
	}

	const initialState = useMemo(() => ({
		customTableId: _customTableId || customTables[0] && customTables[0].value,
		fields: [],
		dateFields: [],
		dateFieldId: _dateFieldId,
		selectedFieldIds: [
			..._selectedFieldIds.map(String),
			_dateFieldId,
		],
		dayOfAlert: !!_dayOfAlert,
	}), []);

	const [{
		customTableId,
		fields,
		dateFields,
		dateFieldId,
		selectedFieldIds,
		dayOfAlert,
	}, dispatch] = useReducer(reducer, initialState);

	const dateFieldTxt = useMemo(() => {
		if (!fields || !dateFieldId) {
			return '';
		}

		const dateField = fields.find(({ fieldId }) => fieldId == dateFieldId);

		if (!dateField) {
			return '';
		}

		return dateField.name.replace(/\s+date$/i, '');
	}, [fields, dateFieldId]);

	useEffect(() => {
		if (customTableId) {
			Ajax.get('/ajax/custom_table_lookup.php', {
				id: customTableId,
			})
				.then(({ data }) => dispatch({
					type: 'set',
					fields: data,
				}));
		} else {
			dispatch({
				type: 'set',
				fields: [],
			});
		}
	}, [customTableId]);

	return (
		<Fragment>
			{ dateFieldId && (
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<div className="fab-Checkbox">
							<input
								className="fab-Checkbox__input"
								defaultChecked={ dayOfAlert }
								id="dayOfAlert"
								name="dayOfAlert"
								type="checkbox"
								value="true"
							/>
							<label
								className="fab-Checkbox__label"
								htmlFor="dayOfAlert"
							>
								<span>
									<Message
										params={ [dateFieldTxt] }
										text={ $._('Send me an alert on the **{1} date**') }
									/>
								</span>
							</label>
						</div>
					</div>
				</div>
			) }
			<div className="fab-FormRow">
				<div className="fab-FormColumn">
					<label
						className="fab-Label"
						htmlFor="customTables"
					>
						{ $.__('Tables') }
					</label>
					<Select
						id="customTables"
						isClearable={ false }
						items={ customTables }
						name="customTables"
						onChange={ ([value]) => dispatch({
							type: 'set',
							customTableId: value,
						}) }
						selectedValues={ [customTableId] }
					/>
				</div>
				<div className="fab-FormColumn">
					<label
						className="fab-Label"
						htmlFor="fieldId"
					>
						{ $.__('Alert Date') }
					</label>
					<Select
						id="fieldId"
						isClearable={ false }
						isDisabled={ dateFields.length < 1 }
						items={ dateFields }
						onChange={ ([value]) => dispatch({
							type: 'set',
							dateFieldId: value,
						}) }
						selectedValues={ [dateFieldId] }
					/>
					<input
						name="fieldId"
						readOnly
						type="hidden"
						value={ dateFieldId }
					/>
				</div>
			</div>
			{ (fields.length > 0) && (
				<div className="fab-FormRow">
					<div className="fab-FormColumn" style={ { width: '80%' } }>
						<span className="fab-Label">
							{ $.__('Include other fields') }
						</span>
						{ chunk(fields || [], 3).map((items, key) => (
							<div className="fab-FormRow fab-FormRow--tight" key={ key }>
								{ range(0, 3).map(index => (
									<div className="fab_FormColumn" key={ index } style={ { flex: 1 } }>
										{ items[index] && (
											<div className="fab-Checkbox">
												<input
													checked={ selectedFieldIds.includes(items[index].fieldId) }
													className="fab-Checkbox__input"
													disabled={ items[index].fieldId == dateFieldId }
													id={ `otherFields[${ items[index].fieldId }]` }
													name="otherFields[]"
													onChange={ ({ target: { checked } }) => dispatch({
														type: 'toggleField',
														id: items[index].fieldId,
														checked,
													}) }
													type="checkbox"
													value={ items[index].fieldId }
												/>
												<label
													className="fab-Checkbox__label"
													htmlFor={ `otherFields[${ items[index].fieldId }]` }
												>
													{ items[index].name }
												</label>
											</div>
										) }
									</div>
								)) }
							</div>
						)) }
					</div>
				</div>
			) }
		</Fragment>
	);
}

/**
 * @typedef State
 * @property {string} customTableId
 * @property {Field[]} fields
 * @property {SelectItems} dateFields
 * @property {string} dateFieldId
 * @property {string[]} selectedFieldIds
 * @property {boolean} dayOfAlert
 */

/**
 * @typedef Field
 * @property {string} fieldId
 * @property {string} name
 * @property {string} stored
 * @property {string} type
 */

/**
 * @typedef {{ type: 'toggleField', id: string, checked: boolean } | ({ type: 'set' } & { [key in keyof State]?: State[key] })} Action
 */

/** @typedef {import('../state').SelectItems<string>} SelectItems */
