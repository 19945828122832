import {
	useState,
	createRef,
} from 'react';
import moment from 'moment';
import {
	TextButton,
	Popover,
	makeStyles,
} from '@bamboohr/fabric';

import {
	EmployeeDeductionGroupPopoverProps,
} from '../../utils/interfaces';

const useStyles = makeStyles({
	popoverBody: {
		maxWidth: '450px',
	},
});

export function EmployeeDeductionGroupPopover(props: EmployeeDeductionGroupPopoverProps): JSX.Element {
	const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);
	const styles = useStyles();
	const textButton = createRef<HTMLButtonElement>();
	const {
		bambooMetadata,
		traxMetadata,
	} = props;

	if (!bambooMetadata || !traxMetadata) {
		return null;
	}
	const {
		name: bambooName,
		startDate: bambooStartDate,
		endDate: bambooEndDate,
		employerPays: bambooEmployerPays,
		employeePays: bambooEmployeePays,
	} = bambooMetadata;
	const {
		startDate: traxStartDate,
		employerPays: traxEmployerPays,
		employeePays: traxEmployeePays,
	} = traxMetadata;
	const isInactive = bambooEndDate ? moment(bambooEndDate, moment.defaultFormat).isBefore(moment(), 'day') : null;
	const title = `${ bambooName } (${ isInactive ? $.__('Inactive') : $.__('Active') })`;

	return (
		<>
			<TextButton
				ref={ textButton }
				onClick={ (): void => setPopoverIsOpen(!popoverIsOpen) }
				size="teenie"
				type="button"
			>
				{ title }
			</TextButton>
			<Popover
				anchorEl={ () => textButton.current }
				classes={ {
					popover: styles.popoverBody,
				} }
				onClose={ () => setPopoverIsOpen(false) }
				open={ popoverIsOpen }
				placement="right"
				title={ title }
			>
				<p>{ $.__('Ded. Start', { note: 'The abbreviation "Ded." is short for "Deduction"' }) }: { bambooStartDate } (BHR) | { traxStartDate } (TRAX)</p>
				<p>{ $.__('Employer Pays') }: { bambooEmployerPays } (BHR) | { traxEmployerPays } (TRAX)</p>
				<p>{ $.__('Employee Pays') }: { bambooEmployeePays } (BHR) | { traxEmployeePays } (TRAX)</p>
			</Popover>
		</>
	);
}
