import { createRef, PureComponent, ReactElement } from 'react';
import moment from 'moment.lib';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import { FirstDayInformationContainer } from 'NHPTemplates.mod/Form/containers/first-day';
import { TeamIntroductionContainer } from 'NHPTemplates.mod/Form/containers/team-introduction';
import { dateOptions, registerTemplateFormData, updateFormState } from 'NHPTemplates.mod/Form/utils';
import { NHPTPreviewerContainer } from 'NHPTemplates.mod/Previewer/Containers/NHPT_Previewer';
import { pandaWallace } from 'NHPTemplates.mod/Previewer/pandas';

import { ColoredIcon } from 'colored-icon';

import { TasksToCompleteBlankState } from './components/tasks-to-complete-blank-state';
import './styles.styl';

import { EmailFilterData, Question, Recipients, SelectOptions } from 'NHPTemplates.mod/Form/Interfaces';
import { isEnabled } from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex, LayoutBox, Section, StyledBox, TextField } from '@bamboohr/fabric';

interface State {
	errorState: boolean;
	name: string;
}

interface TemplateData {
	arriveByTime: string;
	contactEmployeeId: number | string;
	contactOptions: Array<SelectOptions>;
	createdUserId: number | string;
	default: boolean;
	getToKnowYouEmailTemplateId: number | string;
	gtkyQuestions: Array<Question>;
	gtkyRecipients: Array<Recipients>;
	id: number;
	includeBasicInfo: boolean;
	location: string;
	managerUserId?: number | string;
	name: string;
	otherInstructions: string;
	sendGetToKnowYouEmail: boolean;
}

interface Props {
	errorState: boolean;
	emailFilterData: EmailFilterData;
	emailPreviewer: (gtkyQuestions: Array<string>) => void;
	templateData: TemplateData;
	logo: Logo;
	saveForm: () => void;
}

export class NewHirePacketTemplate extends PureComponent<Props, State> {
	private inputRef = createRef<HTMLInputElement>();

	static defaultProps = {
		emailFilterData: {
			selectData: [],
			selectedName: '',
			selectedParentId: null,
			selectedValues: [],
		},
		templateData: {
			arriveByTime: '',
			contactOptions: [],
			contactUser: {},
			location: '',
			newHirePacket: {},
			otherInstructions: '',
			gtkyQuestions: {},
			gtkyRecipients: [],
			sendGetToKnowYouEmail: false,
			templateId: null,
		},
	};

	constructor(props: Props) {
		super(props);

		const { templateData } = this.props;
		const {
			arriveByTime = '08:00:00',
			contactEmployeeId,
			location,
			name,
			otherInstructions,
			gtkyQuestions,
			gtkyRecipients,
			sendGetToKnowYouEmail,
			id,
		} = templateData;

		registerTemplateFormData({
			arriveByTime: [arriveByTime],
			selectedContactOptions: contactEmployeeId,
			id,
			location,
			name,
			otherInstructions,
			gtkyQuestions,
			gtkyRecipients,
			sendGetToKnowYouEmail,
		});

		this.state = {
			errorState: false,
			name,
		};

		this._addErrorState = this._addErrorState.bind(this);
		this._handleUpdateName = this._handleUpdateName.bind(this);
	}

	_addErrorState() {
		this.setState(
			{
				errorState: true,
			},
			this.focus
		);
	}

	_handleUpdateName(event): void {
		const { value = '' } = event.target as HTMLInputElement;

		const { errorState } = this.state;

		const newErrorState = errorState ? value === '' : false;

		this.setState(
			{
				name: value,
				errorState: newErrorState,
			},
			() => {
				updateFormState({ name: this.state.name });
			}
		);
	}

	focus(): void {
		const node = this.inputRef.current;

		if (node) {
			node.focus();
		}
	}

	componentDidMount(): void {
		this.focus();
		document.addEventListener('nhpTemplateMissingData:name:error', this._addErrorState);
	}

	componentWillUnmount(): void {
		document.removeEventListener('nhpTemplateMissingData:name:error', this._addErrorState);
	}

	render(): ReactElement {
		const { emailFilterData, templateData, emailPreviewer, logo, saveForm } = this.props;
		const {
			arriveByTime = '08:00:00',
			contactOptions,
			contactEmployeeId = '',
			location,
			sendGetToKnowYouEmail,
			otherInstructions,
			gtkyQuestions,
			id: templateId,
		} = templateData;

		const { errorState, name } = this.state;

		const nameInputClasses = classNames('fab-TextInput fab-TextInput--width8', {
			'fab-TextInput--error': errorState,
		});

		const nameLabelClasses = classNames('fab-Label fab-Label--required', {
			'fab-Label--error': errorState,
		});

		// @ts-ignore
		const companyName: string = window.SESSION_COMPANY.name;

		const previewerData = {
			logo,
			headerName: templateData.name,
			firstDayDate: moment(Date.now()).add(14, 'days').format(moment.defaultFormat),
			companyName,
			contact: pandaWallace,
			employeeName: 'Panda',
			arriveByTime: templateData.arriveByTime,
			arrivalLocation: templateData.location,
			otherInstructions: templateData.otherInstructions,
			withFinishedFooter: false,
			sendGetToKnowYouEmail: templateData.sendGetToKnowYouEmail,
			headerAction: saveForm,
		};

		return (
			<BrowserRouter basename={window.location.pathname}>
				<Switch>
					<Route path='/'>
						{ifFeature(
							'encore',
							<LayoutBox>
								{isEnabled('NHP_Previewer') && <NHPTPreviewerContainer {...previewerData} />}
								<form className='NHPTemplateForm'>
									<Flex flexDirection='column' gap={5}>
										{templateId > 0 && <input name='templateId' type='hidden' value={templateId} />}
										<Section paddingTop='24px'>
											<TextField
												id='name'
												label={$.__('Template Name')}
												onBlur={this._handleUpdateName}
												onChange={this._handleUpdateName}
												ref={this.inputRef}
												required
												value={name}
												width={7}
											/>
										</Section>
										<FirstDayInformationContainer
											arrivalTimes={dateOptions()}
											contactOptions={contactOptions}
											location={location}
											name='the new employee'
											otherInstructions={otherInstructions}
											selectedArrivalTime={[arriveByTime]}
											selectedContactOptions={[`${contactEmployeeId}`]}
											templateView={true}
										/>
										<TeamIntroductionContainer
											customQuestions={gtkyQuestions}
											emailFilterData={emailFilterData}
											employeeName={$.__('the New Employee')}
											previewNewHireEmail={emailPreviewer}
											sendGetToKnowYouEmail={sendGetToKnowYouEmail ? 'yes' : 'no'}
										/>
										<TasksToCompleteBlankState />
									</Flex>
								</form>
							</LayoutBox>,
							<div className='NHPTemplateFormWrapper'>
								{isEnabled('NHP_Previewer') && <NHPTPreviewerContainer {...previewerData} />}
								<form className='NHPTemplateForm'>
									{templateId > 0 && <input name='templateId' type='hidden' value={templateId} />}
									<div className='fab-FormSection NHPTemplateForm__header'>
										<div className='fab-FormRow'>
											<div className='fab-FormColumn'>
												<label className={nameLabelClasses} htmlFor='name'>
													{$.__('Template Name')}
												</label>
												<div className='fab-InputWrapper'>
													<input
														id='name'
														className={nameInputClasses}
														name='name'
														onBlur={this._handleUpdateName}
														onChange={this._handleUpdateName}
														ref={this.inputRef}
														type='text'
														value={name}
													/>
												</div>
											</div>
										</div>
									</div>

									<FirstDayInformationContainer
										arrivalTimes={dateOptions()}
										contactOptions={contactOptions}
										location={location}
										name='the new employee'
										otherInstructions={otherInstructions}
										selectedArrivalTime={[arriveByTime]}
										selectedContactOptions={[`${contactEmployeeId}`]}
										templateView={true}
									/>
									<TeamIntroductionContainer
										customQuestions={gtkyQuestions}
										emailFilterData={emailFilterData}
										employeeName='the new employee'
										previewNewHireEmail={emailPreviewer}
										sendGetToKnowYouEmail={sendGetToKnowYouEmail ? 'yes' : 'no'}
									/>
									<div className='fab-FormSection'>
										<div className='fab-FormRow'>
											<ColoredIcon fabricColorName='gray7' iconName='fab-clipboard-add-12x14' />
											<span className='fab-FormNote NHPTemplateForm__taskNote'>
												{$.__('Onboarding tasks can be added when the New Hire Packet is being created.')}
											</span>
										</div>
									</div>
								</form>
							</div>
						)}
					</Route>
					<Redirect to='/' />
				</Switch>
			</BrowserRouter>
		);
	}
}
