import { FilterLabelMapItem, FilterMapItem } from './types';
import { Employee, FilterType, PayTypeEnumContract } from '../../types';

export const FILTER_TYPE_LABELS: { [key in FilterType]: FilterLabelMapItem } = {
	[FilterType.employmentStatus]: {
		singularText: $.__('Employment Status'),
		pluralText: $.__('Employment Statuses'),
	},
	[FilterType.location]: {
		singularText: $.__('Location'),
		pluralText: $.__('Locations'),
	},
	[FilterType.department]: {
		singularText: $.__('Department'),
		pluralText: $.__('Departments'),
	},
	[FilterType.division]: {
		singularText: $.__('Division'),
		pluralText: $.__('Divisions'),
	},
	[FilterType.payType]: {
		singularText: $.__('Pay Type'),
		pluralText: $.__('Pay Types'),
	},
	[FilterType.jobTitle]: {
		singularText: $.__('Job Title'),
		pluralText: $.__('Job Titles'),
	},
};

export const EMPLOYEE_FILTER_TYPE_KEYS: { [key in FilterType]: Employee[keyof Employee] } = {
	[FilterType.employmentStatus]: 'employmentStatusId',
	[FilterType.location]: 'locationId',
	[FilterType.department]: 'departmentId',
	[FilterType.division]: 'divisionId',
	[FilterType.payType]: 'payType',
	[FilterType.jobTitle]: 'jobTitleId',
};

export const DEMOGRAPHICS_FILTER_KEYS: { [key in FilterType]: FilterMapItem } = {
	[FilterType.employmentStatus]: {
		pluralKey: 'employmentStatuses',
		pluralValueKey: 'employmentStatusIds',
	},
	[FilterType.location]: {
		pluralKey: 'locations',
		pluralValueKey: 'locationIds',
	},
	[FilterType.department]: {
		pluralKey: 'departments',
		pluralValueKey: 'departmentIds',
	},
	[FilterType.division]: {
		pluralKey: 'divisions',
		pluralValueKey: 'divisionIds',
	},
	[FilterType.jobTitle]: {
		pluralKey: 'jobTitles',
		pluralValueKey: 'jobTitleIds',
	},
	[FilterType.payType]: {
		pluralValueKey: 'payTypes',
	},
};

export const PAY_TYPE_LABELS: { [key in PayTypeEnumContract]: string } = {
	[PayTypeEnumContract.salary]: $.__('Salary'),
	[PayTypeEnumContract.hourly]: $.__('Hourly'),
	[PayTypeEnumContract.exceptionHourly]: $.__('Exception hourly'),
	[PayTypeEnumContract.daily]: $.__('Daily'),
	[PayTypeEnumContract.monthly]: $.__('Monthly'),
	[PayTypeEnumContract.weekly]: $.__('Weekly'),
	[PayTypeEnumContract.salaryNonExempt]: $.__('Salary non-exempt'),
	[PayTypeEnumContract.contract]: $.__('Contract'),
	[PayTypeEnumContract.commission]: $.__('Commission'),
	[PayTypeEnumContract.pieceRate]: $.__('Piece rate'),
	[PayTypeEnumContract.proRate]: $.__('Pro rata'),
};

export const SECONDARY_FILTER_TYPES: FilterType[] = [
	FilterType.department,
	FilterType.division,
	FilterType.location,
	FilterType.payType,
	FilterType.jobTitle,
];

export const ALL_FILTER_TYPES: FilterType[] = [FilterType.employmentStatus, ...SECONDARY_FILTER_TYPES];

export const SPECIFIC_PEOPLE_VALUE = 'specific-people';
