import Loader from 'loader.react';
import './loading-placeholder.styl';

export function LoadingPlaceholder() {
	return (
		<div className="LoadingPlaceholder">
			<Loader blockName="LoadingPlaceholder" />
			{ $.__('Loading') }...
		</div>
	);
}
