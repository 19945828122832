import { DisabledOverlay } from '../disabled-overlay';
import { ApprovalFormContainer } from '../../containers/approval-form';
import { HeaderContainer } from '../../containers/header';

export const StandardWorkflow = (props) => {
	const {
		handleEnableApproval,
		workflow
	} = props;

	const {
		active,
		id
	} = workflow;

	return (
		<div className="SettingsApprovalsContainer">
			<div className="Approvals Approvals--standard">
				<HeaderContainer />
				{
					!active && (
						<DisabledOverlay
							onEnableApproval={ () => { handleEnableApproval(id); } }
						/>
					)
				}
				<ApprovalFormContainer
					id={ id }
				/>
			</div>
		</div>
	);
};
