import { Flex } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { ReactElement } from 'react';
import { SmsMfaSetupProps } from './types';
import { useStyles } from './styles';
import { SmsInputContainer } from './sms-input-container';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function SmsMfaSetup(props: SmsMfaSetupProps): ReactElement {
	const styles = useStyles();

	return (
		<div className={styles.smsContainer}>
			<Flex flexDirection='column' gap={3} justifyContent='center'>
				<b>
					<Message text={ifFeature('encore', $._('We will send a verification code to the phone number you enter below.'), $._('What phone number would you like to use? '))} />
				</b>
				<Flex flexDirection={ifFeature('encore', 'column')} gap='inherit'>
					<SmsInputContainer {...props} />
				</Flex>
				<p className={styles.smsLegal}>
					{$.__(
						'By configuring 2-step login using Text Message(SMS), we collect your phone number for the sole purpose of sending you 2-step login codes. This information is not used for any other notification or marketing purpose. We will not text you without a code being requested first.'
					)}
				</p>
			</Flex>
		</div>
	);
}
