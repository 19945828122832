import { connect } from 'react-redux';

import { deletePathConfirmation, updateSelectedEditingPath } from '../store/actions';
import { SubHeader } from '../components/sub-header';

const mapStateToProps = (state) => {
	const {
		allParsedOptions,
		editState,
		editingWorkflow,
		editingWorkflowId,
		pathTypeOptions,
		workflow
	} = state;

	const {
		active,
		pathType
	} = workflow;

	const {
		workflowPaths
	} = editingWorkflow;

	return {
		active,
		editState,
		editingWorkflowId,
		parsedOptions: allParsedOptions,
		pathType,
		pathTypeOptions,
		selectedOptions: workflowPaths
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onConfirmDelete: (id, paths) => { dispatch(deletePathConfirmation(id, paths)); },
		updateSelectedPaths: (paths) => { dispatch(updateSelectedEditingPath(paths)); }
	};
};

export const SubHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(SubHeader);
