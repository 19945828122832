import { PureComponent, Fragment } from 'react';
import {
	BodyText,
	Flex,
	Headline,
	IconV2,
	LayoutBox,
	SelectableBox,
	SelectableBoxGroup,
	StandardModal
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button } from '@fabric/button';
import { Modal as LegacyModal } from 'modal-legacy';
import { isEmpty } from 'lodash';

import { getRowData } from './columns';
import SettingTable from './table';
import { enableDefaultStatus, addRowData } from '../../utils';

import './styles.styl';

export default class CandidateStatusTable extends PureComponent {
	constructor(props) {
		super(props);

		const headers = ifFeature('encore', [], ['Status Name', '']);
		const applicantStatusesEnableFlags = {};
		for (const statusId in props.applicantDisabledStatuses) {
			applicantStatusesEnableFlags[statusId] = false;
		}
		this.state = {
			applicantDisabledStatuses: props.applicantDisabledStatuses,
			applicantStatusesEnableFlags: applicantStatusesEnableFlags,
			rowData: getRowData(props.applicantStatuses),
			headers: headers,
			modalProps: {
				isOpen: false,
				modalContent: null,
				primaryAction: null,
			}
		};
	}

	enableDefaultStatus = (defaultStatus) => {
		const { rowData, applicantDisabledStatuses } = this.state;
		const updatedRowData = addRowData(rowData, defaultStatus);
		updatedRowData.map((group) => {
			return group.rowData.sort((a,b) => {
				return a.listOrder - b.listOrder;
			});
		});

		delete applicantDisabledStatuses[defaultStatus.id];
		this.setState(
			{
				rowData: updatedRowData
			}
		);
	}

	disableDefaultStatus = (defaultStatus) => {
		const {
			applicantDisabledStatuses
		} = this.state;

		const updatedApplicantDisabledStatuses = {
			...applicantDisabledStatuses,
			[defaultStatus.id]: defaultStatus
		};
		this.setState(
			{
				applicantDisabledStatuses: updatedApplicantDisabledStatuses
			}
		);
	}

	defaultStatusButton = () => {
		return ifFeature('encore',
			<Button
				color={'secondary'}
				onClick={ () => { this.showDisabledStatusModal(); } }
				size={ 'small' }
				type={ 'button'}
				variant={ 'contained' }
			>
				{ $.__('Add Default Status') }
			</Button>
			,
			<div className="candidateStatusesHeaderAction">
				<Button
					clickAction={ () => { this.showDisabledStatusModal(); } }
					outline={ true }
					secondary={ true }
					size={ 'teenie' }
					text={ $.__('Add Default Status') }
				/>
			</div>
		);
	}

	getDisabledStatusModalContent = () => {
		const { applicantDisabledStatuses } = this.state;
		const list = [];
		for (const id in applicantDisabledStatuses) {
			const status = applicantDisabledStatuses[id];
			list.push(
				ifFeature('encore',
					<SelectableBox
						boxWidth={'100%'}
						inputId={ `status_id[${ status.id }]` }
						key={ status.id }
						name={ `status_id[${ status.id }]` }
						onChange={ () => {
							this.state.applicantStatusesEnableFlags[status.id] = !this.state.applicantStatusesEnableFlags[status.id];
							this.setState( { applicantStatusesEnableFlags: this.state.applicantStatusesEnableFlags }, () => {
								this.updateDisabledStatusModalContent();
							});
						} }
						isChecked={ this.state?.applicantStatusesEnableFlags[status.id] === true }
						title={ status.name }
						type="checkbox"
						value={ status.id }
					/>,
					<div className="fab-Checkbox candidateStatuses__modalListCheckbox" key={ status.id }>
						<input className="fab-Checkbox__input" id={ `status_id[${ status.id }]` } name={ `status_id[${ status.id }]` } type="checkbox" value={ status.id } />
						<label className="fab-Checkbox__label" htmlFor={ `status_id[${ status.id }]` }>{ status.name }</label>
					</div>
				)
			);
		}

		const content = ifFeature('encore',
			<LayoutBox>
				<LayoutBox marginBottom={ 4 }>
					<BodyText color={'neutral-extra-strong'} size={'large'} weight={'regular'}>
						{ $.__('Select the statuses that you would like to restore to the hiring workflow. These will be restored to their original position.') }
					</BodyText>
				</LayoutBox>
				<LayoutBox marginTop={ 4 }>
					<form id="addDefaultStatus">
						<SelectableBoxGroup
							aria-label="Default candidate status select"
							type="checkbox"
						>
							<Flex flexDirection={'column'} gap={2}>
								{ list }
							</Flex>
						</SelectableBoxGroup>
					</form>
				</LayoutBox>
			</LayoutBox>,
			<Fragment>
				{ $.__('Select the statuses that you would like to restore to the hiring workflow. These will be restored to their original position.') }
				<form id="addDefaultStatus">
					<div className="candidateStatuses__modal candidateStatuses__modalBorder" key={ `${ name }_default` }>
						<div id="candidateStatuses__modalList">
							{ list }
						</div>
					</div>
				</form>
			</Fragment>
		);

		return content;
	}

	updateDisabledStatusModalContent = () => {
		this.setState(
			{
				modalProps: {
					isOpen: this.state.modalProps.isOpen,
					content: this.getDisabledStatusModalContent(),
					primaryAction: this.state.modalProps.primaryAction,
				}
			}
		);
	}

	showDisabledStatusModal = () => {
		const primaryAction = () => {
			const { applicantDisabledStatuses } = this.state;
			let formData = $('form#addDefaultStatus').serializeArray();
			formData.push({
				name: 'add_default',
				value: true
			});
			enableDefaultStatus(formData)
				.then(() => {
					formData.map((status) => {
						if (status.name !== 'add_default') {
							this.enableDefaultStatus(applicantDisabledStatuses[status.value]);
						}
					});
					this.closeModal();
				});
		};

		this.setState(
			{
				modalProps: {
					isOpen: true,
					content: this.getDisabledStatusModalContent(),
					primaryAction
				}
			}
		);
	}

	closeModal = () => {
		this.setState(
			{
				modalProps: {
					isOpen: false
				}
			}
		);
	}

	render() {
		const {
			rowData,
			headers,
			applicantDisabledStatuses,
			modalProps
		} = this.state;
		const enableStatusBtn = !isEmpty(applicantDisabledStatuses);

		return (
			<Fragment>
				{ ifFeature('encore',
					<Flex alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
						<Headline color={'primary'} size={'small'}>
							{ $.__('Candidate Statuses') }
						</Headline>
						{ enableStatusBtn ? this.defaultStatusButton() : null }
					</Flex>,
					<h4 className="candidateStatusesHeader">
						{ $.__('Candidate Statuses') }
						{ enableStatusBtn ? this.defaultStatusButton() : null }
					</h4>
				)}

				<SettingTable disableDefaultStatus={ this.disableDefaultStatus } headers={ headers } rowData={ rowData } />
				{ ifFeature('encore',
					<StandardModal isOpen={ modalProps.isOpen } onRequestClose={ () => { this.closeModal(); }}>
						<StandardModal.Body
							renderFooter={ (
								<StandardModal.Footer
									actions={ [
										<Button
											key="secondary"
											onClick={ () => { this.closeModal(); } }
											type="button"
											variant={ 'text' }
										>
											{ $.__('Cancel') }
										</Button>,
										<Button
											key="primary"
											onClick={ modalProps.primaryAction }
											type="button"
										>
											{ $.__('Add Status') }
										</Button>
									] }
								/>
							) }
							renderHeader={ (
								<StandardModal.Header
									hasCloseButton={ true }
									title={ $.__('Add Default Status') }
								/>
							) }
						>
							<StandardModal.Constraint>
								{ modalProps.content }
							</StandardModal.Constraint>
						</StandardModal.Body>
					</StandardModal>,
					<LegacyModal
						content={ modalProps.content }
						isOpen={ modalProps.isOpen }
						onClose={ () => { this.closeModal(); } }
						primaryAction={ modalProps.primaryAction }
						primaryActionText={ $.__('Add Status') }
						title={ $.__('Add Default Status') }
					/>
				) }
			</Fragment>
		);
	}
}
