import {
	isNaN,
	kebabCase,
	sortBy,
} from 'lodash';
import { Component, Fragment } from 'react';

import BaTabs from 'ba-tabs.react';
import { Icon, IconButton } from '@bamboohr/fabric';
import { getParameterByName } from '@utils/url';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { WorkflowsContainer } from '../../containers/workflows';
import { SettingsPageHeader } from 'base/settings/workflows/components/settings-page-header';

export class WorkflowsApp extends Component {
	get workflowId() {
		if (typeof this._workflowId !== 'undefined') {
			return this._workflowId;
		}
		this._workflowId = Number(this._getParam('workflow_id'));
		if (typeof this._workflowId === 'undefined' || isNaN(this._workflowId)) {
			this._workflowId = undefined;
		}
		return this._workflowId;
	}

	_getActiveTab = () => {
		const { subNavItems } = this.props;
		if (typeof this.workflowId === 'undefined') {
			return 0;
		}
		return Math.max(0, subNavItems.findIndex((item) => {
			return item.id === this.workflowId;
		}));
	};

	_getParam = (parameter) => {
		return getParameterByName(parameter);
	};

	_getTabs = () => {
		const { getWorkflowData, subNavItems } = this.props;
		const templateItems = subNavItems.reduce((obj, item) => {
			if (!obj[item.groupName]) {
				return obj;
			}
			obj[item.groupName].push({
				label: item.name,
				getData(Tab) {
					return getWorkflowData(item.id);
				},
				group: item.groupName,
				id: item.id,
				key: `#${ item.name }`,
				path: {
					pathname: '',
					query: {
						workflow_id: item.id
					}
				}
			});
			return obj;
		}, { custom: [], standard: [], template: [] });
		templateItems.custom = sortBy(templateItems.custom, 'sortOrder');
		templateItems.standard = sortBy(templateItems.standard, 'sortOrder');
		templateItems.template = sortBy(templateItems.template, 'sortOrder');
		return [
			...templateItems.standard,
			...templateItems.template,
			...templateItems.custom
		];
	};

	// @startCleanup encore
	_handleTabChange = () => {
		window.requestAnimationFrame(window.Settings.calculateHeight);
	};
	// @endCleanup encore

	_TabWrapperHeaderActions = (props) => {
		return ifFeature(
			'encore',
			<IconButton href='/settings/workflows/custom/add_custom_approval' icon='circle-plus-regular' size='small' variant='outlined' />,
			<div className="SettingsApprovalsTabs__tabWrapperActions">
				<a
					className="fab-FloatingIconButton fab-FloatingIconButton--secondary fab-FloatingIconButton--teenie"
					href="/settings/workflows/custom/add_custom_approval"
				>
					<Icon name="fab-circle-plus-14x14" />
				</a>
			</div>
		);
	};

	_workflowId = undefined;

	render() {
		const {
			customApprovalEnabled,
			subNavItems
		} = this.props;

		if (subNavItems.length === 0) {
			return null;
		}
		const tabs = this._getTabs();
		const headerAction = (customApprovalEnabled) ? this._TabWrapperHeaderActions : null;

		return (
			<Fragment>
				{
					ifFeature('encore', <SettingsPageHeader />)
				}
				<BaTabs
					extraClasses={ {
						tab: 'truncate',
						tabContentHeaderActions: 'SettingsApprovalsContainerHeaderActions',
						tabContentWrapper: 'SettingsApprovalsContainerWrapper',
					} }
					id="SettingsApprovalsTabs"
					load={ {
						render: 'active',
						resolve: 'always',
						preload: 'none',
					} }
					// @startCleanup encore
					onTabClick={ this._handleTabChange }
					// @endCleanup encore
					path="/settings/workflows/"
					TabContent={ WorkflowsContainer }
					tabGroups={ [
						{ name: 'standard' },
						{ name: 'template' },
						{ name: 'custom' },
					] }
					TabPrimaryText={ (tab) => {
						const biIdName = `settings-approvals-sidebar-${ kebabCase(tab.tab.key).toLowerCase() }-option`;

						return (
							<span className={ tab.className } data-bi-id={ biIdName }>
								{ tab.primaryText }
							</span>
						);
					} }
					tabs={ tabs }
					TabWrapperHeaderActions={ headerAction }
					theme="side:sub"
					title={ <span className="SettingsApprovalsTabsTitle"> { $.__('Approvals') } </span> }
				/>
			</Fragment>
		);
	}
}
