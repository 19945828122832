import {
	useContext,
	useEffect,
	useReducer,
	createContext,
} from 'react';

/**
 * A utility for combining the `useReducer()` and `useContext()` hooks
 *
 * @param {A} actions A map of action reducers where the `key` is the action type, and the `value` is the reducer function for that action
 * @param {React.Reducer<S, { type: keyof A, payload?: any }>} [reducer] An optional reducer to use in addition to the auto-mapped `actions`
 * @returns {ReducerContextAPI<S, A>}
 * @template {{}} S
 * @template {{ [type: string]: (state: S, payload?: any) => S }} A
 */
export function createReducerContext(actions, reducer = null) {
	if (
		typeof actions === 'function' &&
		typeof reducer === 'function'
	) {
		throw new Error('createReducerContext can accept only one reducer');
	}

	if (typeof actions === 'function') {
		[reducer, actions] = [actions, null];
	}

	/** @type {Reducer<S, A>} */
	function reducerFn(state, action) {
		const {
			type,
			payload,
		} = action;

		if (type === '__SET_STATE__') {
			state = {
				...state,
				...payload,
			};
		}

		if (
			typeof actions === 'object' &&
			typeof actions[type] === 'function'
		) {
			state = {
				...(actions[type](state, payload) || state),
			};
		}

		if (typeof reducer === 'function') {
			state = {
				...(reducer(state, action) || state),
			};
		}

		return state;
	}

	const actionCreators = {};

	if (typeof actions === 'object') {
		Object.keys(actions)
			.forEach((type) => {
				actionCreators[type] = payload => ({
					type,
					payload,
				});
			});
	}

	/** @type {React.Context<Partial<ReducerContext<S, A>>>} */
	const Context = createContext({});

	return {
		useReducerContext: () => useContext(Context),

		Provider({ value, children }) {
			const [state, dispatch] = useReducer(reducerFn, value);

			useEffect(() => {
				dispatch({
					type: '__SET_STATE__',
					payload: {
						...state,
						...value,
					},
				});
			}, [value]);

			return (
				<Context.Provider
					value={ {
						...state,
						...actionCreators,
						dispatch,
					} }
				>
					{ children }
				</Context.Provider>
			);
		},
	};
}

/**
 * @typedef {(...args: any[]) => any} ActionReducer
 */

/**
 * @typedef {{ [type: string]: ActionReducer}} ActionReducers
 */

/**
 * @typedef {{ [type in ActionType<A>]: ActionCreator<A, type> }} ActionCreators
 * @template {ActionReducers} A
 */

/**
 * @typedef {Parameters<A[T]>['length'] extends 1 ? never : Parameters<A[T]>[1]} ActionPayload
 * @template {ActionReducers} A
 * @template {string} T
 */

/**
 * @typedef {keyof A & string} ActionType
 * @template {ActionReducers} A
 */

/**
 * @typedef {{ type: T, payload: ActionPayload<A, T>}} Action
 * @template {ActionReducers} A
 * @template {string} T
 */

/**
 * @typedef {ActionPayload<A, T> extends undefined ? () => Action<A, T> : (payload: ActionPayload<A, T>) => Action<A, T> } ActionCreator
 * @template {ActionReducers} A
 * @template {string} T
*/

/**
 * @typedef {Action<A, ActionType<A>>} ReducerAction
 * @template {ActionReducers} A
 */

/**
 * @typedef {React.Reducer<S, ReducerAction<A>>} Reducer
 * @template {{}} S
 * @template {ActionReducers} A
 */

/**
 * @typedef {React.Dispatch<ReducerAction<A>>} ReducerDispatch
 * @template {ActionReducers} A
 */

/**
 * The state object returned by the `useReducerContext` hook
 *
 * @typedef {S & ActionCreators<A> & { dispatch: ReducerDispatch<A> }} ReducerContext
 * @template {{}} S
 * @template {ActionReducers} A
 */

/**
 * The API that is returned from the `createReducerContext()` utility
 *
 * @typedef ReducerContextAPI
 * @property {() => ReducerContext<S, A>} useReducerContext A hook that will return the current state of the `ReducerContext`
 * @property {React.Provider<ReducerContext<S, A>>} Provider The `React.Provider` for wrapping your shared context
 * @template {{}} S
 * @template {ActionReducers} A
 */
