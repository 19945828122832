import { TURN_ON_AUTOSYNC_SUPPORT_DOC_URL } from './consts';

export const RedirectMessage = () => {
	return (
		`
			<span class="jade-Alert">
				<span class="jade-Alert__left">${ $.__(`Congratulations! You're Ready to Manage Payroll in BambooHR!`) }</span>
				<span class="jade-Alert__right">
					<a class="jade-Alert__link" href=${ TURN_ON_AUTOSYNC_SUPPORT_DOC_URL }>${ $.__(`How to Get Started`) }&#187;</a>
				</span>
			</span>
		`
	);
};
