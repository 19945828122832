import { validateGreaterThan, validateGreaterThanOrEqualTo, validateLessThanOrEqualTo } from './rules';
import { DisabilityCategoryDataOptionsContract } from '../../types';
import { DisabilityDisbursementTypeEnum, DisabilityTypeEnum } from '../../types/disability';
import { SetSpecialErrors } from '../../../types';
import { validateRequiredField } from '../../../utils/validation';

export const validateDisabilityCompanyPays = (errorKey: string, companyPays: number, setSpecialErrors: SetSpecialErrors): boolean => {
	return (
		validateRequiredField(errorKey, companyPays, setSpecialErrors) &&
		validateGreaterThanOrEqualTo(errorKey, companyPays, 0, setSpecialErrors) &&
		validateLessThanOrEqualTo(errorKey, companyPays, 100, setSpecialErrors)
	);
};

export const validateDisabilityType = (disabilityType: DisabilityTypeEnum, setSpecialErrors: SetSpecialErrors): boolean => {
	return validateRequiredField('disabilityType', disabilityType, setSpecialErrors);
};

export const validateDisabilityDisbursementAmount = (
	disabilityPlanCategoryData: DisabilityCategoryDataOptionsContract,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const { disabilityDisbursementAmount, disabilityDisbursementType, disabilityMaxBenefitAmount, isMaxBenefitAmountChecked } =
		disabilityPlanCategoryData;
	switch (disabilityDisbursementType) {
		case DisabilityDisbursementTypeEnum.percentage: {
			const isPercentAmountValid =
				validateRequiredField('disabilityDisbursementAmount', disabilityDisbursementAmount, setSpecialErrors) &&
				validateGreaterThanOrEqualTo('disabilityDisbursementAmount', disabilityDisbursementAmount, 1, setSpecialErrors) &&
				validateLessThanOrEqualTo('disabilityDisbursementAmount', disabilityDisbursementAmount, 100, setSpecialErrors);
			let isMaxBenefitAmountValid = true;
			if (isMaxBenefitAmountChecked) {
				isMaxBenefitAmountValid =
					validateRequiredField('disabilityMaxBenefitAmount', disabilityMaxBenefitAmount, setSpecialErrors) &&
					validateGreaterThanOrEqualTo('disabilityMaxBenefitAmount', disabilityMaxBenefitAmount, 0, setSpecialErrors);
			}
			return isPercentAmountValid && isMaxBenefitAmountValid;
		}
		default:
			return true;
	}
};
export const validateDisabilityRate = (
	disabilityType: DisabilityTypeEnum,
	errorKey: string,
	rate: number,
	setSpecialErrors: SetSpecialErrors
): boolean => {
	const maxRate = disabilityType === DisabilityTypeEnum.shortTermDisability ? 10 : 100;

	return (
		validateRequiredField(errorKey, rate, setSpecialErrors) &&
		validateGreaterThan(errorKey, rate, 0, setSpecialErrors) &&
		validateLessThanOrEqualTo(errorKey, rate, maxRate, setSpecialErrors)
	);
};
