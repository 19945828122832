import classNames from 'classnames';
import { cloneDeep, noop } from 'lodash';
import { Component, Fragment } from 'react';

import { FloatingIconButton } from '@fabric/button';
import { Icon, IconV2 } from '@bamboohr/fabric';
import { Select } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export class SubHeader extends Component {
	constructor(props) {
		super(props);

		const {
			onConfirmDelete
		} = this.props;

		this._confirmDelete = onConfirmDelete;
		this._handleUpdateAvailableSelect = this._handleUpdateAvailableSelect.bind(this);
		this._renderPathOptionsList = this._renderPathOptionsList.bind(this);
		this._renderPathOptionsTitle = this._renderPathOptionsTitle.bind(this);
		this._renderPathSelect = this._renderPathSelect.bind(this);
	}

	_filterUnusedOptions(pathTypeOptions) {
		return pathTypeOptions.filter((pathTypeOption) => {
			return !pathTypeOption.used;
		});
	}

	_filterSelectedOptions(pathTypeOptions, selectedPaths) {
		return pathTypeOptions.filter((pathTypeOption) => {
			// We will get both ints or strings in selectedPaths so we need to compare for both
			return selectedPaths.indexOf(parseInt(pathTypeOption.id)) !== -1 ||
				selectedPaths.indexOf(pathTypeOption.id) !== -1;
		});
	}

	_handleUpdateAvailableSelect(selectedOption) {
		const {
			updateSelectedPaths,
		} = this.props;

		updateSelectedPaths(selectedOption);
	}

	_renderActionButtons(editState, catchAll) {
		const actionsClassName = classNames('ActionButtons', {
			'ActionButtons--read': !editState,
			'ActionButtons--edit': editState,
			'ActionButtons--catchAll': catchAll
		});
		const {
			id,
			onEdit = noop,
			selectedPaths
		} = this.props;

		const deleteIcon = 'fab-trash-can-14x16';
		const editIcon = 'fab-pencil-16x16';

		return (
			<div className={ actionsClassName }>
				{ ifFeature('encore',
					<div className="ActionButton ActionButton__container">
						<div
							className="ActionButton ActionButton__editButton"
							onClick={ onEdit }
						>
							<IconV2 color={'neutral-strong'} name={'pen-solid'} size={16} />
						</div>
						<div
							className="ActionButton ActionButton__deleteButton"
							onClick={  () => {
								this._confirmDelete(
									id,
									selectedPaths
								);
							} }
						>
							<IconV2 color={'neutral-strong'} name={'trash-can-solid'} size={16} />
						</div>
					</div>,
					<Fragment>
						<span className="ActionButton ActionButton__editButton">
							<FloatingIconButton
								clickAction={ onEdit }
								icon={ editIcon }
								noSpacing={ true }
								secondary={ true }
								size="teenie"
								type="button"
							/>
						</span>
							<span className="ActionButton ActionButton__deleteButton">
							<FloatingIconButton
								clickAction={ () => {
									this._confirmDelete(
										id,
										selectedPaths
									);
								} }
								icon={ deleteIcon }
								noSpacing={ true }
								secondary={ true }
								size="teenie"
								type="button"
							/>
						</span>
					</Fragment>
				)}

			</div>
		);
	}

	_renderDivider() {
		return (
			<hr className="Subheader__catch-all-divider" />
		);
	}

	_renderPathOptionsList() {
		const {
			catchAll,
			pathTypeOptions,
			pathType,
			selectedPaths = []
		} = this.props;

		const optionList = (catchAll ? this._filterUnusedOptions : this._filterSelectedOptions)(pathTypeOptions, selectedPaths);
		if (selectedPaths.length < 2 && !catchAll) {
			return null;
		} else if (catchAll) {
			let catchAllText = '';
			switch (pathType) {
				case 'department':
					catchAllText = $.__('Employees without an assigned department');
					break;
				case 'division':
					catchAllText = $.__('Employees without an assigned division');
					break;
				case 'location':
					catchAllText = $.__('Employees without an assigned location');
					break;
			}
			optionList.push({
				id: -1,
				name: catchAllText,
				used: false
			});
		}

		return (
			<ul className="PathTypeOptions__list">
				{
					optionList.map((option) => {
						if (catchAll) {
							return <li className="PathTypeOptions__listItem" key={ option.id }>{ option.name }</li>;
						}

						return (selectedPaths.includes(parseInt(option.id)) || selectedPaths.includes(option.id)) ? <li className="PathTypeOptions__listItem" key={ option.id }>{ option.name }</li> : null;
					})
				}
			</ul>
		);
	}

	_renderPathOptionsTitle() {
		const {
			catchAll,
			editState = false,
			editingWorkflowId = -1,
			id,
			pathTypeOptions,
			pathType,
			selectedPaths = []
		} = this.props;
		const optionList = (catchAll ? this._filterUnusedOptions : this._filterSelectedOptions)(pathTypeOptions, selectedPaths);
		let pathOptionTitle = '';
		let pathOptionTitleEditState = '';
		switch (pathType) {
			case 'department':
				pathOptionTitleEditState = $.__('Department(s):');
				break;
			case 'division':
				pathOptionTitleEditState = $.__('Division(s):');
				break;
			case 'location':
				pathOptionTitleEditState = $.__('Location(s):');
				break;
		}
		if (catchAll) {
			switch (pathType) {
				case 'department':
					pathOptionTitle = $.__('All Other Departments');
					break;
				case 'division':
					pathOptionTitle = $.__('All Other Divisions');
					break;
				case 'location':
					pathOptionTitle = $.__('All Other Locations');
					break;
			}
		} else if (optionList.length === 1) {
			pathOptionTitle = optionList[0].name;
		} else {
			switch (pathType) {
				case 'department':
					pathOptionTitle = $.__('%1 Departments', optionList.length);
					break;
				case 'division':
					pathOptionTitle = $.__('%1 Divisions', optionList.length);
					break;
				case 'location':
					pathOptionTitle = $.__('%1 Locations', optionList.length);
					break;
			}
		}
		return (
			<h4 className="PathTypeOptions__title">
				{
					(
						editState &&
						editingWorkflowId !== 0 &&
						editingWorkflowId === id &&
						!catchAll
					) ? pathOptionTitleEditState : pathOptionTitle
				}
			</h4>
		);
	}

	_renderPathSelect() {

		const {
			parsedOptions = {},
			selectedOptions = []
		} = this.props;

		let selectOptions = cloneDeep(parsedOptions);

		selectedOptions.map((index) => {
			selectOptions[index].selected = true;
		});

		return (
			<div className="PathTypeSelect">
				<Select
					canSelectMultiple={ true }
					isClearable={ false }
					items={ Object.keys(selectOptions).map((index) => { return selectOptions[index]; }) }
					menuWidth={7}
					onChange={ this._handleUpdateAvailableSelect }
					selectedValues={ selectedOptions }
					width="7"
				/>
			</div>
		);
	}

	_renderPathTypeIcon(pathType) {
		let icon = '';
		switch (pathType) {
			case 'department':
				icon = ifFeature('encore', 'user-group-solid', 'fab-employees-20x18');
				break;
			case 'division':
				icon = ifFeature('encore', 'sitemap-solid', 'fab-org-chart-20x19');
				break;
			case 'location':
				icon = ifFeature('encore', 'location-dot-solid', 'fab-location-15x20');
				break;
		}

		const {
			editState,
			editingWorkflowId,
			id
		} = this.props;

		const editingThisWorkflow = editState && editingWorkflowId === Number(id);

		const className = classNames(
			'Subheader__pathTypeIcon', `Subheader__pathTypeIcon--${ pathType }`,
			{
				'baseFillColor': editingThisWorkflow,
			}
		);
		return (
			<Fragment>
				<span className={ className }>
					{ifFeature('encore', 
						<IconV2 color="primary-strong" name={icon} size={16} />,
						<Icon
							brand={ true }
							name={ icon }
						/>
					)}
				</span>
			</Fragment>
		);
	}

	render() {
		const {
			active,
			catchAll = false,
			editState = false,
			editingWorkflowId = -1,
			id,
			pathTypeOptions = [],
			pathType
		} = this.props;

		const convertedEditingWorkflowId = Number(editingWorkflowId);
		const convertedId = Number(id);

		const typeClassNames = classNames(
			'Subheader__type',
			{
				'Subheader__type--editing': editState && convertedEditingWorkflowId !== 0 && convertedEditingWorkflowId === convertedId && !catchAll,
				'Subheader__type--new': editState && convertedEditingWorkflowId === 0,
			}
		);

		return (
			<div className="AdvancedApprovals__subheader">
				<div className="Subheader">
					<div className="Subheader__icon">
						{ this._renderPathTypeIcon(pathType) }
					</div>
					<div className={ typeClassNames }>
						<div className="PathTypeOptions">
							{ this._renderPathOptionsTitle() }
							{
								editState &&
								convertedEditingWorkflowId !== 0 &&
								convertedEditingWorkflowId === convertedId &&
								!catchAll &&
								this._renderPathSelect()
							}
							{
								pathTypeOptions.length > 1 &&
								this._renderPathOptionsList()
							}
						</div>
					</div>
					<div className="Subheader__actions">
						{ active && !editState && this._renderActionButtons(editState, catchAll) }
					</div>
				</div>
			</div>

		);
	}
}
