import { connect } from 'react-redux';

import { Workflows } from '../components/workflows';
import { getData } from '../store/actions';

const mapStateToProps = (state) => {
	const { workflow } = state;

	return {
		advancedWorkflow: workflow ? workflow.advancedWorkflow : undefined,
		workflow: workflow
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getWorkflowData: (workflowId) => {
			return dispatch(getData(workflowId));
		}
	};
};

export const WorkflowsContainer = connect(mapStateToProps, mapDispatchToProps)(Workflows);
