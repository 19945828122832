export const generateAgeBandRateErrorKey = (index: number): string => {
	return `calculatorSettings_ageBands_rate_${index}`;
};

export const generateCompanyPaysErrorKey = (groupId: string | number): string => {
	return `eligibilityGroups_${String(groupId)}_companyPays`;
};

export const generateEmployeeCostErrorKey = (groupId: string | number, coverageLinkId: string): string => {
	return `eligibilityGroups_${String(groupId)}_${coverageLinkId}_employeeCost`;
};
export const generateEmployeePaysErrorKey = (groupId: string | number): string => {
	return `eligibilityGroups_${String(groupId)}_employeePays`;
};

export const generateEligibilityFiltersErrorKey = (groupId: string | number): string => {
	return `eligibilityGroups_${String(groupId)}_eligibilityFilters`;
};

export const generateTotalCostErrorKey = (groupId: string | number, linkId: string): string => {
	return `coverageLevels_${groupId.toString()}_${linkId}_totalCost`;
};

export const generateRateErrorKey = (groupId: string | number): string => {
	return `eligibilityGroups_${String(groupId)}_rate`;
};
