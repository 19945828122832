export const isValidPhoneNumberLength = (phoneNumber: string): boolean => {
	if (phoneNumber.length <= 6) {
		return true;
	}
	if (phoneNumber.length >= 16) {
		return true;
	}
	return false;
};
export const isDisabledButton = (phoneNumber: string, countryCode: string, parsedNumber: string): boolean => {
	if (!phoneNumber || !countryCode) {
		return true;
	}
	return isValidPhoneNumberLength(parsedNumber);
};
