import {useState} from 'react';
import { 
	Table, 
	BlankState,
	LayoutBox, 
} from '@bamboohr/fabric';

import {PeopleModal} from './people-modal.tsx'

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { _makeColumns } from './utils.js';
import PayScheduleCalendarModal from './pay-calendar-modal.react/pay-calendar-modal.jsx';


export default function PaySchedulesList({paySchedules}) {

	const [peopleModalIsOpen, setPeopleModalIsOpen] = useState(false);
	const [byId, setById] = useState(undefined);
	const [allIds, setAllIds] = useState(undefined);
	const [peopleModalTitle, setPeopleModalTitle] = useState($.__("Pay Schedule"));

	const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
	const [calendarModalName, setCalendarModalName] = useState($.__("Pay Schedule"));
	const [disableDays, setDisableDays] = useState([]);
	const [isCalendarPreview, setIsCalendarPreview] = useState(false);
	const [payPeriods, setPayPeriods] = useState([]);

	if (paySchedules.length === 0) {
		return (
			<LayoutBox marginTop={8}>
				<BlankState
					icon={ifFeature('encore', "bs-calendar", "fab-person-index-card-120x92")}
					title={ $.__(`Add a pay schedule`) }
				/>
			</LayoutBox>
		)
	}
	return (
		<>
			<Table
				caption={ $.__('Pay schedules') }
				columns={ _makeColumns(
					paySchedules, 
					(isOpen, byId, allIds, title) => {
						setById(byId);
						setAllIds(allIds);
						setPeopleModalTitle(title);
						setPeopleModalIsOpen(isOpen); 
					},
					(calendarModalName, disableDays, isOpen, isPreview, payPeriodsArray) => {
						setCalendarModalName(calendarModalName);
						setDisableDays(disableDays);
						setIsCalendarPreview(isPreview);
						setPayPeriods(payPeriodsArray);
						setCalendarModalIsOpen(isOpen);
					}
				)}
				rows={ paySchedules }
			/>
			<PeopleModal allIds={allIds} byId={byId} isOpen={peopleModalIsOpen} handleClose={() => setPeopleModalIsOpen(false)} title={peopleModalTitle} />
			{calendarModalIsOpen && <PayScheduleCalendarModal 
				disableDays={disableDays}
				handleClose={() => setCalendarModalIsOpen(false)}
				isOpen={calendarModalIsOpen}
				isPreview={isCalendarPreview}
				payPeriods={payPeriods}
				title={isCalendarPreview ? $.__('Preview Pay Calendar') : `${ $.__('Pay Calendar') } - ${ calendarModalName }`}
			/>}
		</>
	)
}
