import { Divider, Flex, Headline } from '@bamboohr/fabric';
import { HeaderActionsContainer } from 'base/settings/workflows/containers/header-actions';

export const SettingsPageHeader = () => {
	return (
		<Flex flexDirection="column" gap={2} marginBottom={4}>
			<Flex alignItems="center" justifyContent="space-between" width="100%">
				<Headline size="medium">
					{$.__('Approvals')}
				</Headline>
				<HeaderActionsContainer />
			</Flex>
			<Divider color="neutral-extra-weak" />
		</Flex>
	);
}