import { render } from 'base/wrapped-render';
import { isEnabled } from 'FeatureToggle.util';
import { PoMicroFrontend } from 'micro-frontend.react';

if (isEnabled('MIGRATE_HOLIDAY_SETTINGS_TO_PO')) {
	// @ts-expect-error `window` not fully typed
	window.HolidaySettings = {
		renderInPo(root: string, props) {
			render(<PoMicroFrontend {...props} route='/holidays/settings' />, document.getElementById(root));
		}
	}
}
