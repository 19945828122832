import {
	SerializedPostMultiFactorAuthenticationBackupCodeResponse,
	SerializedPostMultiFactorAuthenticationLoginCodeResponse,
} from './types';
import { getRedirect } from './domain';

export function isSerializedPostMultiFactorAuthenticationLoginCodeResponse(
	response: SerializedPostMultiFactorAuthenticationBackupCodeResponse | SerializedPostMultiFactorAuthenticationLoginCodeResponse
): response is SerializedPostMultiFactorAuthenticationLoginCodeResponse {
	return 'rememberToken' in response;
}

export function redirectToLogin(): void {
	const redirect = getRedirect();
	const url = `/login.php?r=${redirect}&message[]=6`;
	window.location.replace(url);
}
