import { makeStyles, createStyles } from "@bamboohr/fabric";

export const useStyles = makeStyles(({ spacing, palette, typography }) => {
	return createStyles({
		enpsHeader: {
			margin: '19px 0 16px 0',
			paddingBottom: 20,
			borderBottom: `1px solid ${palette.gray[400]}`,
			minWidth: 'auto',
			textAlign: 'left',
		},
		enpsHeading: {
			alignItems: 'center',
			color: palette.gray[1200],
			display: 'flex',
			height: 25,
		},
		enpsHeadingIcon: {
			lineHeight: 1,
			fill: palette.primary.main,
			display: 'inline-flex',
			marginTop: 1,
		},
		enpsHeaderTitle: {
			fontSize: 18,
			marginLeft: 10,
			color: palette.gray[1000],
			fontWeight: typography.fontWeightSemibold,
			lineHeight: '25px',
		},
		enpsHeadingSpacer: {
			flex: '1 1 auto'
		},
		trialMessage: {
			margin: '10px 30px 0'
		},
		trialMessageIcon: {
			fill: palette.warning.main,
			marginRight: 5,
		},
		trialMessageHighlight: {
			color: palette.warning.main,
		}
	});
});
