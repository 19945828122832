import {AuthSetupComponent} from '../../../login/multi-factor-auth/components/auth-app/auth-setup-component';
import {
	MfaCodeAuthentication
} from '../../../login/multi-factor-auth/components/interstitial-auth.react/mfa-code-authentication';
import {BackupCodesApp} from '../../../login/multi-factor-auth/components/backup-codes-app/backup-codes-app';
import {SmsMfaSetup} from '../../../login/multi-factor-auth/components/sms-mfa';
import {
	isDisabledButton
} from '../../../login/multi-factor-auth/components/sms-mfa/sms-phone-validation/sms-phone-validation';
import {
	deleteMultiFactorAuthenticationChannel,
	getUserMfaSettings,
	postMultiFactorAuthenticationLoginCode,
	postSmsCode,
	putMultiFactorAuthenticationChannel
} from '../../../login/multi-factor-auth/service';
import {
	BACKUP_CODES_HEADLINE,
	downloadCodes,
	getStoredRememberToken,
	setStoredRememberToken
} from 'multi-factor-authentication.mod';

export const mfaData = {
	BackupCodesApp,
	MfaAuthenticator: AuthSetupComponent,
	MfaCodeAuthentication,
	SmsMfaSetup,
	backupCodesHeadline: BACKUP_CODES_HEADLINE,
	deleteMultiFactorAuthenticationChannel,
	downloadCodes,
	getStoredRememberToken,
	getUserMfaSettings,
	isDisabledButton,
	postMultiFactorAuthenticationLoginCode,
	postSmsCode,
	putMultiFactorAuthenticationChannel,
	setStoredRememberToken,
}