import React, { useState } from 'react';
import { Modal } from 'modal-legacy';
import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';

export default function EnableModal(props) {
	const [isProcessing, setIsProcessing] = useState(false);

	const {
		onSave,
		onClose,
		visible,
		onOpen
	} = props;

	const title = $.__('Enable Time Tracking?');
	const primaryActionText = $.__('Yes, Enable Time Tracking');
	let modalOptions = {
		onClose,
		onOpen,
		visible,
		options: {
			title,
			footer: {
				buttons: {
					primary: {
						text: primaryActionText,
						action: onSave,
					},
				},
			},
		},
	};

	modalOptions = {
		biId: 'settings-time-tracking-enable',
		isOpen: visible,
		onClose,
		onOpen,
		primaryAction() {
			setIsProcessing(true);
			onSave()
				.catch(() => {
					setIsProcessing(false);
				});
		},
		primaryActionText,
		isProcessing,
		title,
	};

	return (
		<Modal { ...modalOptions }>
			<div className="EnableModal">
				<span className="EnableModal__clock-success-icon">
					{ifFeature('encore', <IconV2 name="stopwatch-solid" size={48} />, <Icon name="fab-stopwatch-check-57x55" />)}
				</span>
				<h3 className="EnableModal__text">{ $.__('You’ve finished all the setup tasks!') }</h3>
				<p className="EnableModal__sub-text">{ $.__('Are you ready to enable time tracking? Your employees will be able to start tracking their time through BambooHR immediately.') }</p>
			</div>
		</Modal>
	);
}
