import React, { useState } from 'react';

import classnames from 'classnames';

import { Icon, TileV2, Button, LayoutBox, Truncate } from '@bamboohr/fabric';

// @startCleanup encore
import './file-tile.styl';
// @endCleanup encore

import { ifFeature } from '@bamboohr/utils/lib/feature';

export function FileTile(props) {
	const {
		fileName,
		fileType,
		clientId,
		onFileDownload,
		horizontal = false,
	} = props;
	const [isDownloaded, setIsDownloaded] = useState(false);
	const buttonText = isDownloaded ? 'Download Again' : 'Download';

	const initFileDownload = () => {
		setIsDownloaded(true);
		onFileDownload(fileType);
	};

	// @startCleanup encore
	const buttonClasses = classnames(
		`fab-Button fab-Button--outline fab-Button--medium fab-Button--width5 `,
		{
			'fab-Button--secondary': isDownloaded,
		},
	);
	// @endCleanup encore

	return ifFeature(
		'encore',
		<TileV2
			actions={[
				<Button
					color="secondary"
					download={true}
					href={`/payroll/documents/prefilled_client_doc/${fileType}/${clientId}`}
					key="download_file"
					onClick={initFileDownload}
					size="small"
					variant={isDownloaded ? 'outlined' : undefined}
				>
					{buttonText}
				</Button>,
			]}
			actionPlacement={horizontal ? 'right' : 'bottom'}
			orientation={horizontal ? 'horizontal' : 'vertical'}
			icon="file-pdf-regular"
			title={
				<LayoutBox title={fileName} width={horizontal ? undefined : 150}>
					<Truncate>{fileName}</Truncate>
				</LayoutBox>
			}
			titleSize="medium"
		/>,
		<div className="FileTile">
			<div className="FileTile__name">{fileName}</div>

			<div className="FileTile__specs">
				<span className="FileTile__icon">
					<Icon name="fab-file-pdf-16x20" />
				</span>

				<span className="FileTile__extension">PDF</span>
			</div>

			<div className="FileTile__button">
				<a
					href={`/payroll/documents/prefilled_client_doc/${fileType}/${clientId}`}
					className={buttonClasses}
					download={true}
					onClick={initFileDownload}
				>
					<span className="FileTile__downloadIcon">
						<Icon name="fab-download-13x16" />
					</span>{' '}
					{buttonText}
				</a>
			</div>
		</div>,
	);
}
