import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, constructs, borderRadiuses, spacing }) => ({
	/* @startCleanup encore */
	titleIcon: {
		fill: palette.primary.main,
	},
	title: {
		color: palette.primary.main,
		fontSize: 24,
		margin: '12px 0px 12px 0px',
	},
	subTitle: {
		color: palette.primary.main,
		fontSize: 16,
	},
	/* @endCleanup encore */
	dataOuterContainer: {
		marginTop: ifFeature('encore', spacing(3), 16),
		padding: spacing(3),
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[200]),
		borderRadius: ifFeature('encore', borderRadiuses[200]),
		border: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`),
	},

	dataItems: {
		width: '74%',
		/* @startCleanup encore */
		marginLeft: ifFeature('encore', undefined, 8),
		'& p': {
			padding: ifFeature('encore', undefined, '4px 0'),
		},
		'& p:first-of-type': {
			padding: ifFeature('encore', undefined, '0 0 4px 0'),
		},
		'& button': {
			marginTop: ifFeature('encore', undefined, '12px'),
		},
	},
	centerIcon: {
		fill: palette.gray[700],
	},
	/* @endCleanup encore */
}));
