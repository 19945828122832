import {
	clientTaxSettingName_1099PrintOption,
} from '../constants';
import type {
	ClientTaxSettingsResponseDataCollection,
	ClientTaxSetting1099PrintOption,
} from '../types';

export function find1099PrintOption(clientTaxSettings: ClientTaxSettingsResponseDataCollection): ClientTaxSetting1099PrintOption | undefined {
	if (!Array.isArray(clientTaxSettings)) {
		return undefined;
	}
	const tax = clientTaxSettings.find((clientTaxSetting) => {
		return clientTaxSetting.settingName === clientTaxSettingName_1099PrintOption;
	});
	return tax as ClientTaxSetting1099PrintOption;
}
