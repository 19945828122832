import { getCurrencyPosition, getCurrencySymbol, getFormattedAmountFromNumber } from 'form-fields.react';
import { CostTypeEnumContract, Currency } from '../types';

export const getFormattedCurrencyOrPercent = (
	cost: number,
	costType: CostTypeEnumContract,
	currencies: Currency[],
	currencyCode: string
): string => {
	const currencySymbol = getCurrencySymbol(currencies, currencyCode);
	const currencyPosition = getCurrencyPosition(currencies, currencyCode);

	return getFormattedAmountFromNumber(
		cost,
		costType === CostTypeEnumContract.percent ? '%' : currencySymbol,
		costType === CostTypeEnumContract.percent ? 1 : currencyPosition
	);
};
