import {
	Flex,
	StandardModal,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	Fragment,
} from 'react';

import {
	FederalConnector,
} from './federal-connector';
import {
	ModalFooterMessage,
} from './modal-footer-message';
import {
	StateConnector,
} from './state-connector';

export function ModalBodyDefault() {
	return (
		<>
			<StandardModal.Constraint
				spacingOverrides={ {
					left: StandardModal.Constraint.Spacing.NONE,
					top: StandardModal.Constraint.Spacing.NONE,
				} }
			>
				<Flex flexDirection='column' marginLeft={ ifFeature('encore', '-20px', 0) } marginTop={ ifFeature('encore', '-32px', 0) }>
					<StateConnector />
					<FederalConnector />
					{ ifFeature('encore', 
						<ModalFooterMessage />,
						null
					) }
				</Flex>
			</StandardModal.Constraint>
			{ ifFeature('encore', 
				null,
				<ModalFooterMessage />
			) }
		</>
	);
}
