import {
	StandardModal,
} from '@bamboohr/fabric';
import {
	TriangleExclamation18x16,
} from '@bamboohr/grim';
import {
	ExternalLink,
	Message,
} from '@bamboohr/utils/lib/message';

import {
	useStyles,
} from './modal-body-error.styles';
import {
	supportGeneralLink,
} from '../constants';
import {
	L_MODAL_ERROR_MESSAGE_BODY_TEXT,
	L_MODAL_ERROR_MESSAGE_HEADER_TEXT,
} from '../language';

export function ModalBodyError() {
	const classes = useStyles();
	return (
		<StandardModal.Constraint>
			<StandardModal.StandardHeadline
				icon={ (
					<div className={ classes.iconContainer }>
						<TriangleExclamation18x16 />
					</div>
				) }
				text={ L_MODAL_ERROR_MESSAGE_HEADER_TEXT }
			/>
			<div className={ classes.body }>
				<Message
					// eslint-disable-next-line new-cap
					link={ ExternalLink(supportGeneralLink) }
					text={ L_MODAL_ERROR_MESSAGE_BODY_TEXT }
				/>
			</div>
		</StandardModal.Constraint>
	);
}
