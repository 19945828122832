import { Icon } from '@bamboohr/fabric';

import './icon-heading.styl';

export function IconHeading(props) {
	const { iconName } = props;
	return (
		<div className="EnpsIconHeading baseFillColor">
			<Icon name={ iconName } />
		</div>
	);
}
