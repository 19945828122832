import React, { ReactElement } from 'react';
import { Dropdown } from '@bamboohr/fabric';
import { Gear15x16 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { HeaderData, HeaderTmplData } from '../../types';
import { getDropdownItems } from '../../etc/get-dropdown-items';
import { handleActionSelect } from '../../etc/actions';

export function renderHeaderDropdown(permission: HeaderTmplData, mainTemplateData: HeaderData): ReactElement {
	if (permission.canAdd || permission.canDuplicate || permission.canDelete) {
		return (
			<Dropdown
				ButtonProps={ifFeature('encore', {
					color: 'secondary',
					startIcon: 'gear-regular',
					variant: 'outlined',
					size: 'small',
				})}
				// @startCleanup encore
				buttonSettings={{
					color: 'secondary',
					startIcon: <Gear15x16 />,
					variant: 'outlined',
					size: 'small',
				}}
				// @endCleanup encore
				items={getDropdownItems(permission)}
				onSelect={(val) => handleActionSelect(val, permission.levelId, mainTemplateData)}
				type={ifFeature('encore', 'icon')}
			/>
		);
	}
}
