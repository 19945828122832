import { AxiosPromise } from 'axios';
import { TableColumn } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';
import { ActionButtonsCell } from './components/action-buttons-cell';
import { DeductionNameCell } from './components/deduction-name-cell';

export function getColumns(
	deductionType: string,
	deductions: NormalizedDeductions,
	editingId: number,
	setEditingId: (id: number) => void,
	setCurrentDeductions: (newDeductions: NormalizedDeductions) => void,
	managedDeductionType: string
): Array<TableColumn<DeductionObject>> {
	return [
		{
			header: deductionType,
			cell: (row: DeductionObject): JSX.Element => (
				<DeductionNameCell
					{ ...row }
					deductions={ deductions }
					isEditing={ editingId === row.companyDeductionId }
					managedDeductionType={ managedDeductionType }
					setCurrentDeductions={ setCurrentDeductions }
					setEditingId={ setEditingId }
				/>
			),
			cellPaddingReset: 'vertical',
		},
		{
			header: '',
			headerAriaLabel: 'row actions',
			align: 'right',
			cell: (row: DeductionObject): JSX.Element => (
				<ActionButtonsCell
					{ ...row }
					deductions={ deductions }
					isEditing={ editingId === row.companyDeductionId }
					setCurrentDeductions={ setCurrentDeductions }
					setEditingId={ setEditingId }
				/>
			),
			cellPaddingReset: 'vertical',
			showOnHover: (row: DeductionObject): boolean => row.isActive,
		},
	];
}

// AJAX utilities //////
const DEDUCTIONS_PREFIX = '/settings/payroll/ajax/deductions';

export function addDeductionName(deductionName: string, managedDeductionType: string): AxiosPromise {
	return Ajax.post(
		DEDUCTIONS_PREFIX,
		{
			deductionName,
			managedDeductionType,
		}
	);
}

export function editDeductionName(companyDeductionId: number, managedDeductionType: string, deductionName: string): AxiosPromise {
	return Ajax.put(
		DEDUCTIONS_PREFIX,
		{
			companyDeductionId,
			managedDeductionType,
			deductionName,
		}
	);
}

export function deleteDeductionName(companyDeductionId: number): AxiosPromise {
	return Ajax.delete(`${ DEDUCTIONS_PREFIX }/${ companyDeductionId }`);
}

export function reactivateDeductionName(companyDeductionId: number): AxiosPromise {
	return Ajax.put(`${ DEDUCTIONS_PREFIX }/reactivate/${ companyDeductionId }`);
}

// TypeScript Interfaces //////
export interface DeductionObject {
	companyDeductionId: number;
	deductionName: string;
	numberOfEnrollments: number;
	isActive: boolean;
}

export interface ResponseObject {
	status: number;
	data: object;
}

export interface NormalizedDeductions {
	byId: DeductionsById;
	allIds: Array<number|string>;
}

interface DeductionsById {
	[companyDeductionId: number]: DeductionObject;
}
