import { Moment } from 'moment';
import { ChangeEvent, FormEvent, useContext } from 'react';

import { UpcomingSurvey, UpcomingSurveysRequest } from '../types';
import { SlidedownContext, SurveyStatus } from '../../utils/';
import { SelectItem } from '../../types';
import useUpcoming from './upcoming';
import useCheckReady from './check-ready';
import useScheduleManager from './schedule-manager';
import { CheckboxOnChangeParam } from '@bamboohr/fabric';
import { useSetupServices } from '../service';

interface UseSetupReturn {
	includeContractors: boolean;
	handleIncludeContractorsChange: (param: CheckboxOnChangeParam, event: ChangeEvent<HTMLInputElement>) => void;
	isSaveReady: boolean;
	scheduleStartDate: Moment;
	scheduleOptions: SelectItem[];
	setScheduleStartDate: (val: Moment) => void;
	handleFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
	setSelectedFrequencyValue: (val: string) => void; 
	setSelectedScheduleValue: (val: string) => void; 
	selectedFrequencyValue: string;
	selectedScheduleValue: string;
	upcomingSurveys: UpcomingSurvey[];
	handleCancelClick: () => void;
	hasChangesToCancel: boolean;
	onKillSwitch: () => void;
	scheduleData: UpcomingSurveysRequest;
	updateUpcomingSurveys: (surveys: UpcomingSurvey[]) => void;
}

const useSetup = (): UseSetupReturn => {
	// Getting values from context
	const {
		onSurveyScheduleSaveSuccess,
		onKillSwitch
	} = useContext(SurveyStatus);
	const { showSuccess, hide } = useContext(SlidedownContext);
	
	// Calling custom hooks that handle specific concerns
	const {
		includeContractors,
		setIncludeContractors,
		scheduleStartDate,
		setScheduleStartDate,
		selectedFrequencyValue,
		selectedScheduleValue,
		setSelectedScheduleValue,
		setSelectedFrequencyValue,
		submitData,
		scheduleOptions,
		handleCancelClick,
		clearSchedule,
	} = useScheduleManager();
	const { upcomingSurveys, scheduleData, updateUpcomingSurveys, schedulePending } = useUpcoming({ selectedFrequencyValue, scheduleStartDate, selectedScheduleValue, clearSchedule });
	const { isSaveReady, hasChangesToCancel } = useCheckReady({ scheduleStartDate, selectedFrequencyValue, selectedScheduleValue, includeContractors, schedulePending });
	const { saveSurvey } = useSetupServices();

	// Creating callbacks
	const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!isSaveReady) {
			return;
		}
		hide();
		saveSurvey(submitData).then(data => {
			onSurveyScheduleSaveSuccess(data);
			showSuccess();
		});
	};

	return {
		includeContractors,
		handleIncludeContractorsChange: (param, event) => {
			setIncludeContractors(param.checked);
		},
		isSaveReady,
		scheduleStartDate,
		scheduleOptions,
		setScheduleStartDate,
		handleFormSubmit,
		setSelectedFrequencyValue,
		setSelectedScheduleValue,
		selectedFrequencyValue,
		selectedScheduleValue,
		upcomingSurveys,
		handleCancelClick,
		hasChangesToCancel,
		onKillSwitch: () => {
			hide();
			onKillSwitch();
		},
		scheduleData,
		updateUpcomingSurveys,
	};
};

export default useSetup;
