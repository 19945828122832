import Ctrl, {
} from 'ctrl.deco';

@Ctrl('/settings/access_levels/payroll-admin')
class SettingsAccessLevelsPayrollAdminCtrl {

	headerTmplData = {
		showSettingsBtn: false,
		extraButtons: [],
	};

	tmplData = {
		canDuplicate: false,
		canDelete: false,
	};
}
