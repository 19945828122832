

import {
	ModalFooterMessageInfo,
} from './modal-footer-message-info';
import {
	ModalFooterMessageLink,
} from './modal-footer-message-link';
import {
	shouldShowEndOfYearMessage,
} from '../domain/should-show-end-of-year-message';

export function ModalFooterMessage() {
	const showEndOfYearMessage = shouldShowEndOfYearMessage();
	if (showEndOfYearMessage) {
		return (
			<ModalFooterMessageInfo />
		);
	}
	return (
		<ModalFooterMessageLink />
	);
}
