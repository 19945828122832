import Ctrl, {
	onChange,
} from 'ctrl.deco';

const APP_MODAL_FORM_SELECTOR = '.SettingsApps__appModalForm--swipeclock';
const CHECKBOX_SELECTOR = `${ APP_MODAL_FORM_SELECTOR } input[name="enabled"]`;
const SYNC_FORM_SELECTOR = `${ APP_MODAL_FORM_SELECTOR } #loginInfo`;


@Ctrl('/settings/apps')
class SettingsAppsSwipeclock {
	/**
	 * @type {HTMLInputElement}
	 */
	get $checkbox() {
		return document.querySelector(CHECKBOX_SELECTOR);
	}

	/**
	 * @type {HTMLFormElement}
	 */
	get $formWrapper() {
		return document.querySelector(SYNC_FORM_SELECTOR);
	}

	get enabled() {
		const {
			$checkbox,
		} = this;

		return (
			$checkbox &&
			$checkbox.checked
		);
	}

	@onChange(CHECKBOX_SELECTOR)
	onSyncChange(e, ctrl) {
		ctrl.toggleForm();
	}

	toggleForm() {
		const {
			enabled,
			$formWrapper,
		} = this;

		if ($formWrapper) {
			$formWrapper.classList
				.toggle('hidden', !enabled);
		}
	}
}
