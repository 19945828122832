import { useContext, ReactElement } from 'react';
import { LayoutBox, Loader } from '@bamboohr/fabric';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';
import { BackToChecklist } from './back-to-checklist';
import { ActiveMissingInfoTable } from './active-missing-info-table';

import { ActiveMissingInfoContextData } from '../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function ActiveMissingInfoContent(): ReactElement {
	const { isLoading, hasMissingData } = useContext(ActiveMissingInfoContext) as ActiveMissingInfoContextData;

	if (isLoading) {
		return (
			<LayoutBox marginY={20}>
				<Loader
					/* @startCleanup encore */
					small={ifFeature('encore', undefined, true)}
					/* @endCleanup encore */
				/>
			</LayoutBox>
		);
	}

	if (!hasMissingData) {
		return (
			<LayoutBox marginY={9} textAlign='center'>
				<BackToChecklist />
			</LayoutBox>
		);
	}

	if (hasMissingData) {
		return (
			<LayoutBox
				/* @startCleanup encore */
				marginY={ifFeature('encore', undefined, 3)}
				/* @endCleanup encore */
				textAlign='center'
			>
				<ActiveMissingInfoTable />
			</LayoutBox>
		);
	}
}
