import {
	DecisionDataContract,
	DeductionTypeEnumContract,
	HealthCategoryDataOptionsContract,
	PreTaxDeductionTypeValue,
} from '../types';
import { PlanTypeEnumContract } from '../../types';

export const isPlanRateIncluded = (planType: PlanTypeEnumContract): boolean => {
	const planRateTypes = [
		PlanTypeEnumContract.health,
		PlanTypeEnumContract.dental,
		PlanTypeEnumContract.vision,
		PlanTypeEnumContract.supplemental,
	];

	return planRateTypes.includes(planType);
};

export const isDeductionTypeSupported = (benefitType: PlanTypeEnumContract, decisionData: DecisionDataContract): boolean => {
	const payrollSupported = [
		PlanTypeEnumContract.other,
		PlanTypeEnumContract.retirement,
		PlanTypeEnumContract.flex,
		PlanTypeEnumContract.supplemental,
	];
	const benAdminSupported = [PlanTypeEnumContract.flex, PlanTypeEnumContract.supplemental];

	return (
		(payrollSupported.includes(benefitType) && decisionData.hasTrax) ||
		(benAdminSupported.includes(benefitType) && decisionData.benefitsAdministrationEnabled)
	);
};

export const isAcaComplianceIncluded = (planType: PlanTypeEnumContract, acaEnabled: boolean): boolean => {
	return planType === PlanTypeEnumContract.health && acaEnabled;
};

export const isPreTaxFieldIncluded = (planType: PlanTypeEnumContract, hasTrax: boolean): boolean => {
	const includedForTypes = [
		PlanTypeEnumContract.health,
		PlanTypeEnumContract.dental,
		PlanTypeEnumContract.vision,
		PlanTypeEnumContract.supplemental,
		PlanTypeEnumContract.disability,
	];

	return includedForTypes.includes(planType) && hasTrax;
};

export const isReportableOnW2Included = (
	planType: PlanTypeEnumContract,
	hasTrax: boolean,
	preTaxDeductionType: PreTaxDeductionTypeValue,
	planCategoryDataOptions: HealthCategoryDataOptionsContract | null
): boolean => {
	if (
		preTaxDeductionType === DeductionTypeEnumContract.preTaxSubtype ||
		(planType === PlanTypeEnumContract.supplemental && preTaxDeductionType === DeductionTypeEnumContract.preTaxGeneralHealthcare)
	) {
		if (planType === PlanTypeEnumContract.supplemental) {
			if (hasTrax) {
				return Boolean(!planCategoryDataOptions?.typeId);
			}
			return true;
		}
		return isPreTaxFieldIncluded(planType, hasTrax);
	}
	return false;
};

export const isDeductionOffsetIncluded = (decisionData: DecisionDataContract): boolean => {
	return decisionData.deductionDateRule.isVisible;
};

export const isReimbursementAmountIncluded = (planType: PlanTypeEnumContract): boolean => {
	return planType === PlanTypeEnumContract.reimbursement;
};

export const showVoluntaryPlanCoverageOnPlanDetails = (
	isBenefitsAdminEnabled: boolean,
	hasVoluntaryCoverage: boolean,
	isVoluntaryLifeCoverageSelectorEnabled: boolean
): boolean => {
	return isBenefitsAdminEnabled && hasVoluntaryCoverage && !isVoluntaryLifeCoverageSelectorEnabled;
};
