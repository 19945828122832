import { Table } from '@bamboohr/fabric';
import {
	getGroups,
	getRows,
	getColumnsByTableType,
} from './migration-table-utils';

import {
	FieldObject,
	MigrationTableProps,
} from '../../utils/interfaces';

import './migration-table.styl';

export function MigrationTable(props: MigrationTableProps): JSX.Element {
	const {
		isGrouped,
		isErrorTable,
		groupIcon,
		groups,
		fields,
		fieldSelectChangeHandler,
		manualUpdateCheckHandler,
		sectionType,
	} = props;
	const columns = getColumnsByTableType(
		isGrouped,
		isErrorTable,
		groups,
		fieldSelectChangeHandler,
		manualUpdateCheckHandler,
		sectionType
	);

	return (
		<div className="MigrationTable">
			<Table
				caption={ $.__('Payroll info migration') }
				columns={ columns }
				groupBy={ isGrouped ? (row: FieldObject) => row.group : null }
				groups={ isGrouped ? getGroups(groups, groupIcon, isErrorTable, sectionType) : [] }
				rowKey={ (row: FieldObject) => row.id }
				rows={ getRows(fields, sectionType) }
			/>
		</div>
	);
}
