import {
	RadioGroup,
} from '@bamboohr/fabric';

import {
	useStyles,
} from './radio-options.styles';
import {
	stateOhioCourtesyTaxRadioOptionCollect,
	stateOhioCourtesyTaxRadioOptionDoNotCollect,
} from '../constants';
import {
	L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_LABEL,
} from '../language';
import {
	stateOhioCourtesyTaxRadioOptions,
} from '../domain/state-ohio-courtesy-tax-radio-options';

interface StateOhioCourtesyTaxProps {
	setSuppressTax(checked: boolean): void;
	suppressTax: boolean;
}

export function StateOhioCourtesyTax(props: StateOhioCourtesyTaxProps) {
	const {
		setSuppressTax,
		suppressTax,
	} = props;
	const classes = useStyles();
	return (
		<div className={ classes.radioOptionsContainer }>
			<RadioGroup
				items={ stateOhioCourtesyTaxRadioOptions }
				label={ L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_LABEL }
				onChange={ (param) => {
					const {
						value,
					} = param;
					setSuppressTax(value === stateOhioCourtesyTaxRadioOptionDoNotCollect);
				} }
				status="default"
				value={ suppressTax ? stateOhioCourtesyTaxRadioOptionDoNotCollect : stateOhioCourtesyTaxRadioOptionCollect }
			/>
		</div>
	);
}
