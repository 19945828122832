import { Fragment, useContext, useMemo, useState } from 'react';
import { Select, TextButton } from '@bamboohr/fabric';
import { ValidationError } from '@utils/validation';

import { SPECIFIC_PEOPLE_VALUE } from './constants';
import { EmployeeListModal } from './employee-list-modal';
import { EmployeePickerModal } from './employee-picker-modal';
import { FilterDiscardWarningModal } from './filter-discard-warning-modal';
import { FilterSelect } from './filter-select';
import { useSetFilter } from './hooks';
import { NestedFilterMenu } from './nested-filter-menu';
import { ChildItem } from './types';
import {
	canSkipFilterDiscardWarning,
	generateChildItems,
	generateParentFilterItems,
	generatePrimaryFilterItems,
	generateToggleText,
	getDisabledEmployeeIdsForPicker,
	getDisabledFilterValues,
	isFilterTypeInActiveMenu,
	shouldShowAddButton,
} from './utils';
import { EmployeesInGroups, UpdateGroupProperty } from '../types';
import { INITIAL_FILTERS } from '../../constants';
import { WizardStateContext } from '../../context/wizard-state-context';
import { useReactivateDisabledField } from '../../hooks/use-reactivate-disabled-field';
import { DemographicsContract, EligibilityFilterValues, FilterType, FilterValue } from '../../types';
import { WizardState } from '../../types/wizard-state';
import { SectionTitle } from '../../../shared/section-title.react';

import './eligibility-filters.styl';

interface EligibilityFiltersProps {
	allEmployeesInGroups: EmployeesInGroups;
	demographics?: DemographicsContract;
	eligibilityFilters: EligibilityFilterValues;
	error?: ValidationError;
	groupEmployeeIds: number[];
	hasEditRestrictions: boolean;
	initialFilters: EligibilityFilterValues;
	planName: string;
	setSelectedEmployeeIds: (selectedEmployeeIds: number[]) => void;
	updateGroupProperty: UpdateGroupProperty;
}

export const EligibilityFilters = (props: EligibilityFiltersProps): JSX.Element => {
	const {
		allEmployeesInGroups,
		demographics,
		eligibilityFilters,
		error,
		groupEmployeeIds,
		hasEditRestrictions,
		initialFilters,
		planName,
		setSelectedEmployeeIds,
		updateGroupProperty,
	} = props;
	const wizardState: WizardState = useContext(WizardStateContext);

	const { filterGroup, areSpecificPeopleSelected, selectedEmployeeIds } = eligibilityFilters;

	const [isPeoplePickerModalOpen, setIsPeoplePickerModalOpen] = useState<boolean>(false);
	const [isFilterDiscardModalOpen, setIsFilterDiscardModalOpen] = useState<boolean>(false);
	const [menuFilterTypeSelections, setMenuFilterTypeSelections] = useState<FilterType[]>([]);
	const { setFilter } = useSetFilter(updateGroupProperty, filterGroup);

	const hasFilterError = Boolean(error) && !isPeoplePickerModalOpen;

	const setSpecificPeopleToSelected = (): void => {
		const newEligibleEmployees = {
			...eligibilityFilters,
			filterGroup: INITIAL_FILTERS,
			areSpecificPeopleSelected: true,
		};
		updateGroupProperty('eligibilityFilters', newEligibleEmployees, true);
	};

	const unselectSpecificPeopleOption = (selectedValues: FilterValue[]): void => {
		const newSelectedValues = selectedValues.filter((val) => val !== SPECIFIC_PEOPLE_VALUE);
		setFilter(FilterType.employmentStatus, newSelectedValues);
	};

	const handleSelectSpecificPeopleOption = (): void => {
		if (canSkipFilterDiscardWarning(eligibilityFilters.filterGroup)) {
			// Remove all other selections and just select the "Specific People" option
			setSpecificPeopleToSelected();
			setIsPeoplePickerModalOpen(true);
		} else {
			setIsFilterDiscardModalOpen(true);
		}
	};

	const handlePrimaryFilterChange = (selectedValues: FilterValue[]): void => {
		// Keep the specific people option selected if it is clicked again
		if (areSpecificPeopleSelected && selectedValues.length === 0) {
			return;
		}

		if (selectedValues.includes(SPECIFIC_PEOPLE_VALUE)) {
			if (areSpecificPeopleSelected) {
				unselectSpecificPeopleOption(selectedValues);
			} else {
				handleSelectSpecificPeopleOption();
			}
		} else {
			setFilter(FilterType.employmentStatus, selectedValues);
		}
	};

	const handleAcceptFilterDiscard = (): void => {
		setSpecificPeopleToSelected();
		setIsFilterDiscardModalOpen(false);
		setIsPeoplePickerModalOpen(true);
	};

	const getPrimaryFilterSelections = (): FilterValue[] => {
		if (areSpecificPeopleSelected) {
			return [SPECIFIC_PEOPLE_VALUE];
		}

		return filterGroup.values[FilterType.employmentStatus];
	};
	const isPrimaryFilterDisabled = useReactivateDisabledField(getPrimaryFilterSelections(), hasEditRestrictions);

	const generateSecondaryFilterSelectors = (): JSX.Element[] => {
		const filterSelectors = [];

		filterGroup.order.forEach((filterType, index) => {
			if (filterType !== FilterType.employmentStatus && !isFilterTypeInActiveMenu(filterType, menuFilterTypeSelections)) {
				const separator = index === 1 ? $.__('in') : $.__('and');
				filterSelectors.push(
					<FilterSelect
						biId='secondary-filter'
						filterType={filterType}
						hasEditRestrictions={hasEditRestrictions}
						hasError={hasFilterError}
						items={generateChildItems(
							demographics,
							filterType,
							getDisabledFilterValues(hasEditRestrictions, initialFilters, filterType)
						)}
						key={filterType}
						selectedValues={filterGroup.values[filterType]}
						separator={separator}
						setSelectedValues={setFilter}
					/>
				);
			}
		});

		return filterSelectors;
	};

	const primaryFilterItems = useMemo(() => {
		return generatePrimaryFilterItems(demographics, hasEditRestrictions, initialFilters);
	}, [demographics, initialFilters, hasEditRestrictions]);

	if (!demographics) {
		return null;
	}

	const noEmployeesCapturedWarning = Boolean(filterGroup.order.length) && groupEmployeeIds.length === 0;

	return (
		<Fragment>
			<div className='BftEligibilityFilters__titleWrapper'>
				<div className='BftEligibilityFilters__sectionTitle'>
					<SectionTitle text={$.__('Which employees are eligible?')} />
				</div>
				<EmployeeListModal
					allEmployees={demographics.employees}
					employeeIds={groupEmployeeIds}
					noEmployeesCapturedWarning={noEmployeesCapturedWarning}
					planName={planName}
				/>
				<EmployeePickerModal
					closeModal={(): void => setIsPeoplePickerModalOpen(false)}
					demographics={demographics}
					disabledEmployeeIds={getDisabledEmployeeIdsForPicker(hasEditRestrictions, initialFilters.selectedEmployeeIds)}
					employeesInGroups={allEmployeesInGroups}
					isOpen={isPeoplePickerModalOpen}
					planName={planName}
					selectedEmployeeIds={selectedEmployeeIds}
					setSelectedEmployeeIds={setSelectedEmployeeIds}
				/>
				<FilterDiscardWarningModal
					closeModal={(): void => setIsFilterDiscardModalOpen(false)}
					isOpen={isFilterDiscardModalOpen}
					onContinueClick={handleAcceptFilterDiscard}
				/>
			</div>
			<div className='fab-FormRow'>
				<div className='fab-FormField'>
					<Select
						biId='add-primary-filter'
						canSelectMultiple={true}
						condition={hasFilterError ? 'error' : null}
						isClearable={false}
						isDisabled={isPrimaryFilterDisabled}
						items={primaryFilterItems}
						onChange={handlePrimaryFilterChange}
						renderOptionContent={(item: ChildItem): JSX.Element => {
							if (item && item.value === SPECIFIC_PEOPLE_VALUE) {
								return <div className='BftEligibilityFilters__specificPeopleItem'>{item.text}</div>;
							}
						}}
						renderToggleContent={(selectedItems: ChildItem[]): string =>
							generateToggleText(selectedItems, FilterType.employmentStatus)
						}
						selectedValues={getPrimaryFilterSelections()}
						width={7}
					/>
					{areSpecificPeopleSelected && (
						<span className='BftEligibilityFilters__editButton'>
							<TextButton
								clickAction={(): void => setIsPeoplePickerModalOpen(true)}
								disabled={wizardState === WizardState.Reactivate}
								text={$.__('Edit')}
								type='button'
							/>
						</span>
					)}
				</div>
				{generateSecondaryFilterSelectors()}
				{shouldShowAddButton(filterGroup, hasEditRestrictions, menuFilterTypeSelections, wizardState) && (
					<NestedFilterMenu
						filterGroup={filterGroup}
						items={generateParentFilterItems(demographics, filterGroup.values, menuFilterTypeSelections)}
						menuFilterTypeSelections={menuFilterTypeSelections}
						setFilter={setFilter}
						setMenuFilterTypeSelections={setMenuFilterTypeSelections}
					/>
				)}
				{hasFilterError && error.message && (
					<span className='fab-FormNote fab-FormNote--error BftEligibilityFilters__formNote'>{error.message}</span>
				)}
			</div>
		</Fragment>
	);
};
