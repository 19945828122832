import { useEffect, useState } from 'react';

interface VisualEffectJssClasses {
	expandedFreeResponse: string;
}

interface VisualEffects {
	textAreaClass: string;
	expandTextArea: (isSmallScreen: boolean) => void;
}

interface VisualEffectArguments {
	classes: VisualEffectJssClasses;
	questionNumber: number;
}

const useVisualEffects = ({ classes: { expandedFreeResponse }, questionNumber }: VisualEffectArguments): VisualEffects => {
	const [textAreaClass, setTextAreaClass] = useState('');

	useEffect(() => {
		setTextAreaClass('');
	}, [questionNumber]);

	return {
		textAreaClass,
		expandTextArea: (isSmallScreen) => !isSmallScreen && setTextAreaClass(expandedFreeResponse)
	};
};

export default useVisualEffects;
