/**
 * Saves the form
 * @param {object} data Setup component state data
 * @param {String} [endpoint='save'] Where to save
 */
export function getRequestData(data, endpoint = 'save') {
	const requestData = {
		endpoint,
		method: 'POST',
		stepData: prepareStepData(data),
	};
	return requestData;
}

/**
 * Gets necessary data for saving
 * @param {object} saveData Setup component state data
 * @return {object} Request Data
 */
function prepareStepData(saveData) {
	const { includeContractors, sendDate, surveyId } = saveData;
	let { fromUserId, surveyFrequency } = saveData;

	const emailElement = document.getElementById('emailMessage');
	let emailMessage;

	if (emailElement) {
		emailMessage = emailElement.value;
	}

	if (!saveData.fromUserId && saveData.selectData) {
		const selectedItem = saveData.selectData.items.find(item => item.selected);
		if (selectedItem) {
			fromUserId = selectedItem.value;
		}
	}

	if (!saveData.surveyFrequency && saveData.surveyFrequencyData) {
		const selectedItem = saveData.surveyFrequencyData.items.find(item => item.selected);
		if (selectedItem) {
			surveyFrequency = selectedItem.value;
		}
	}

	return {
		emailMessage,
		fromUserId,
		includeContractors,
		sendDate,
		surveyFrequency,
		surveyId,
	};
}
