import {
	companyTaxSettingsService,
} from './company-tax-settings-service';
import type {
	SaveClientTaxSettingsRequestDataCollection,
} from '../types';

export async function saveClientTaxSettingsAsync(clientId: number, clientTaxSettings: SaveClientTaxSettingsRequestDataCollection) {
	try {
		await companyTaxSettingsService.request({
			data: clientTaxSettings,
			method: 'PUT',
			url: '/taxes/settings',
			urlParams: {
				clientId,
			},
		});
	} catch (err) {
		console.error('saveClientTaxSettingsAsync err -', err);
		throw err;
	}
}
