import {
	getDetail,
	getDetailKeys,
	getTitle,
} from './employee-tax-box-content-federal-tax-withholding.domain';
import {
	useStyles,
} from './employee-tax-box-content.styles';
import {
	EmployeeTaxBoxContentFederalTaxWithholdingProps,
} from '../../utils/interfaces';

export function EmployeeTaxBoxContentFederalTaxWithholding({
	isSelected,
	metadata,
}: EmployeeTaxBoxContentFederalTaxWithholdingProps): JSX.Element {
	const styles = useStyles({
		isSelected,
	});

	return (
		<div className={ styles.root }>
			<p className={ styles.title }>
				{ getTitle(metadata) }
			</p>
			<div className={ styles.details }>
				{ getDetailKeys(metadata).map(key => (
					<p
						key={ key }
						className={ styles.detail }
					>
						{ getDetail(key, metadata) }
					</p>
				)) }
			</div>
		</div>
	);
}
