import {
	Select,
	DatePickerRange,
	makeStyles,
} from '@bamboohr/fabric';

import {
	FieldObject,
} from '../types';

const useStyles = makeStyles(({mixins, palette, typography}) => ({
	formSection: {
		borderTop: mixins.border(1, palette.grey[300]),
		padding: '12px 0 23px',
	},
	explainer: {
		fontSize: typography.fabricFontSize('small'),
		marginBottom: 14,
	},
	formStatementText: {
		fontSize: typography.fabricFontSize('small'),
		padding: '0 10px',
	},
	acaField: {
		paddingRight: 35,
		marginRight: 35,
		display: 'inline-block',
		width: 192,
		'&:not(:last-of-type)': {
			borderRight: mixins.border(1, palette.grey[300]),
		}
	},
	acaFieldLabel: {
		fontSize: typography.fabricFontSize('small'),
		marginBottom: 25
	}
}));

interface AddBenefitPlanFormProps {
	startDate: FieldObject;
	endDate: FieldObject;
	offsetAmount: FieldObject;
	beforeOrAfter: FieldObject;
	meetsValueStandard: FieldObject;
	meetsEssentialCoverage: FieldObject;
	showBeforeOrAfter: boolean;
	showAcaFields: boolean;
	onFieldChange(name: string, value: string | number | boolean): void;
}

export function AddBenefitPlanForm(props: AddBenefitPlanFormProps): JSX.Element {
	const styles = useStyles();

	const {
		startDate,
		endDate,
		offsetAmount,
		showBeforeOrAfter,
		beforeOrAfter,
		showAcaFields,
		meetsValueStandard,
		meetsEssentialCoverage,
		onFieldChange,
	} = props;

	const translationNote = {note: 'Part of a sentence like "One pay date after the effective date"'};

	return (
		<>
			<div className={ styles.formSection }>
				<h5>
					{ $.__('When did coverage for this plan start and end?') }
				</h5>
				<p className={ styles.explainer }>
					{ $.__('This might be different than when the deductions come out of payroll') }.
				</p>
				<DatePickerRange
					endLabel={ $.__('Plan Ends') }
					endStatus={ endDate.error ? 'error' : 'default' }
					endValue={ (endDate.value as string) || '' }
					onChange={ ({startValue, endValue}) => {
						if (startValue) {
							onFieldChange('startDate', startValue);
						}
						if (endValue) {
							onFieldChange('endDate', endValue);
						}
					} }
					required={ endDate.required }
					startLabel={ $.__('Plan Starts') }
					startStatus={ startDate.error ? 'error' : 'default' }
					startValue={ (startDate.value as string) || '' }
				/>
			</div>

			<div className={ styles.formSection }>
				<h5>
					{ $.__('When does this deduction start for people you may enroll in the future?') }
				</h5>
				<p className={ styles.explainer }>
					{ $.__('This deduction will be taken from every paycheck after the deduction start date') }.
				</p>
				<div>
					<Select
						isClearable={ false }
						items={ [
							{
								text: $.__('One'),
								value: 1,
							},
							{
								text: $.__('Two'),
								value: 2,
							}
						] }
						onSelect={ value => onFieldChange('offsetAmount', value) }
						selectedValues={ [offsetAmount.value] }
					/>
					{ showBeforeOrAfter ? (
						<>
							<span className={ styles.formStatementText }>
								{ $.__n('pay date', 'pay dates', (offsetAmount.value as number), translationNote) }
							</span>
							<Select
								isClearable={ false }
								items={ [
									{
										text: $.__('Before', translationNote),
										value: 'before',
									},
									{
										text: $.__('After', translationNote),
										value: 'after'
									},
								] }
								onSelect={ value => onFieldChange('beforeOrAfter', value) }
								selectedValues={ [beforeOrAfter.value] }
							/>
							<span className={ styles.formStatementText }>
								{ $.__('the benefit effective date', translationNote) }
							</span>
						</>
					) : (
						<span className={ styles.formStatementText }>
							{ $.__n(
								'pay date after the benefit effective date',
								'pay dates after the benefit effective date',
								(offsetAmount.value as number),
								translationNote
							) }
						</span>
					) }

				</div>
			</div>

			{ showAcaFields && (
				<div className={ styles.formSection }>
					<h5>
						{ $.__('ACA Compliance?') }
					</h5>

					<div className={ styles.acaField }>
						<p className={ styles.acaFieldLabel }>{ $.__('Does this plan meet the minimum value standard?') }</p>
						<Select
							isClearable={ false }
							items={ [
								{
									text: $.__('Yes'),
									value: true,
								},
								{
									text: $.__('No'),
									value: false,
								}
							] }
							onSelect={ value => onFieldChange('meetsValueStandard', value) }
							selectedValues={ [meetsValueStandard.value] }
						/>
					</div>

					<div className={ styles.acaField }>
						<p className={ styles.acaFieldLabel }>{ $.__('Does this plan qualify as minimum essential coverage?') }</p>
						<Select
							isClearable={ false }
							items={ [
								{
									text: $.__('Yes'),
									value: true,
								},
								{
									text: $.__('No'),
									value: false,
								}
							] }
							onSelect={ value => onFieldChange('meetsEssentialCoverage', value) }
							selectedValues={ [meetsEssentialCoverage.value] }
						/>
					</div>
				</div>
			) }
		</>
	);
}
