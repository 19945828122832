import { render } from 'base/wrapped-render';

import Ctrl from 'ctrl.deco';

import mainTmpl from './main.micro.html';

import {
	TwoStepManager,
} from './components/two-step-manager.react';

@Ctrl('/settings/account/two_step_login')
class SettingsAccountTwoStepLoginCtrl {
	get mainTmpl() {
		return mainTmpl;
	}

	onMainReady() {
		const settingsTwoStepReactRoot = document.getElementById('js-SettingsTwoStepReactRoot');
		if (settingsTwoStepReactRoot) {
			render(
				(
					<TwoStepManager />
				),
				settingsTwoStepReactRoot
			);
		}
	}
}
