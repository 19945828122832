import {
	clamp,
} from 'lodash';

import {
	createReducerContext,
} from '../../shared';

const actions = {
	/**
	 * @param {State} state
	 * @returns {State}
	 */
	TOGGLE(state) {
		const {
			isEnabled,
		} = state;

		return {
			...state,
			modal: isEnabled ? 'DISABLE' : 'ENABLE',
			currentStep: 1,
			stepCount: 4,
		};
	},
	/**
	 * @param {State} state
	 * @returns {State}
	 */
	CLOSE_MODAL(state) {
		return {
			...state,
			modal: false,
		};
	},
	/**
	 * @param {State} state
	 * @returns {State}
	 */
	PREV_STEP(state) {
		const {
			currentStep,
			stepCount,
		} = state;

		return {
			...state,
			currentStep: clamp(currentStep - 1, 1, stepCount),
		};
	},
	/**
	 * @param {State} state
	 * @returns {State}
	 */
	NEXT_STEP(state) {
		const {
			currentStep,
			stepCount,
		} = state;

		return {
			...state,
			currentStep: clamp(currentStep + 1, 1, stepCount),
		};
	},
	/**
	 * @param {State} state
	 * @returns {State}
	 */
	ENABLE: (state) => {
		return {
			...state,
			isEnabled: true,
			modal: false,
		};
	},
	/**
	 * @param {State} state
	 * @returns {State}
	 */
	DISABLE(state) {
		return {
			...state,
			isEnabled: false,
			modal: false,
		};
	},
};

/**
 * @type {import('../../shared').ReducerContextAPI<State, typeof actions>}
*/
const {
	useReducerContext,
	Provider,
} = createReducerContext(actions);

export {
	useReducerContext,
	Provider,
};

/**
 * @typedef State
 * @property {boolean} isEnabled
 * @property {number} currentStep
 * @property {number} stepCount
 * @property {'ENABLE'|'DISABLE'|false} modal
 * @property {CompanyInfo} companyInfo
 * @property {import('@utils/dom/bem').BEM} bem
 */

/** @typedef {import('../../shared').ReducerContext<State, typeof actions>} Context */

/**
 * @typedef CompanyInfo
 * @property {string} city
 * @property {string} companyName
 * @property {string} contactPhone
 * @property {{ [id: CompanyInfoCountry['id']]: CompanyInfoCountry }} countries
 * @property {CompanyInfoCountry} country
 * @property {CompanyInfoCountry['id']} countryId
 * @property {string} ein
 * @property {string} employerAddress
 * @property {string} id
 * @property {CompanyInfoState} state
 * @property {{ [id: CompanyInfoState['id']]: CompanyInfoState }} states
 * @property {string} zipCode
 */

/**
 * @typedef CompanyInfoCountry
 * @property {string} dataCenter
 * @property {string} id
 * @property {string} isoCode
 * @property {string} name
 */

/**
 * @typedef CompanyInfoState
 * @property {string} abbrev
 * @property {CompanyInfoCountry['id']} countryId
 * @property {string} id
 * @property {string} isoCode
 * @property {string} name
 * @property {string} [paySimpleStateId]
 */
