import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BEM } from '@utils/dom';
import './answer-scale.styl';

const scaleBem = new BEM('SurveyAnswerScale');

export function AnswerScale({ answers, onSelect, selected, useLargeText }) {
	const scaleClassNames = [scaleBem.elem()];
	if (selected || selected === 0) {
		scaleClassNames.push(scaleBem.mod('hasSelected'));
	}

	return (
		<div className={scaleClassNames.join(' ')}>
			{answers.map((answer) => {
				const { text, value } = answer;
				const isSelected = answer.value === selected;
				const textClassNames = scaleBem.elem('text', {
					centered: useLargeText,
					selected: isSelected,
				});
				const boxClassNames = [scaleBem.elem('box')];
				const iconClassNames = [scaleBem.elem('checkIcon')];

				if (isSelected) {
					boxClassNames.push(scaleBem.elem('box', 'selected'));
					iconClassNames.push(scaleBem.elem('checkIcon', 'selected'));
				}

				if (useLargeText) {
					boxClassNames.push(scaleBem.elem('box', 'large-text'));
					iconClassNames.push(scaleBem.elem('checkIcon', 'centered'));
				}

				return (
					<div
						key={value}
						className={boxClassNames.join(' ')}
						onClick={() => onSelect(answer)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onSelect(answer);
							}
						}}
						role='button'
						tabIndex='0'
					>
						<span className={`${iconClassNames.join(' ')} ${scaleBem.elem('checkIcon', 'small')}`}>
							{ifFeature(
								'encore',
								<IconV2
									name={isSelected ? 'circle-check-solid' : 'circle-check-regular'}
									size={20}
									color={isSelected ? 'primary-strong' : 'neutral-strong'}
								/>,
								<Icon name={isSelected ? 'fab-check-circle-20x20' : 'fab-check-circle-outline-20x20'} />
							)}
						</span>
						<span className={`${iconClassNames.join(' ')} ${scaleBem.elem('checkIcon', 'large')}`}>
							{ifFeature(
								'encore',
								<IconV2
									name={isSelected ? 'circle-check-solid' : 'circle-check-regular'}
									size={28}
									color={isSelected ? 'primary-strong' : 'neutral-strong'}
								/>,
								<Icon name={isSelected ? 'fab-check-circle-28x28' : 'fab-check-circle-outlined-28x28'} />
							)}
						</span>
						<span className={textClassNames}>{text}</span>
					</div>
				);
			})}
		</div>
	);
}
