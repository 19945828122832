import Ajax from '@utils/ajax';

import {
	AxiosPromise,
} from 'axios';

import { CreateBenefitPlanDeductionPayloadObject } from '../types';

export function createBenefitPlanDeduction(payload: CreateBenefitPlanDeductionPayloadObject): AxiosPromise {
	return Ajax.post('/payroll/deduction_sync/ajax/create_benefit_plan_deductions', payload);
}
