import {
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Modal } from 'modal-legacy';

import { TheNewEmployeeSelector } from 'employee-selector.react';

import {
	getEmployeesForPicker,
	getPickerFilters,
} from './utils';
import { DemographicsContract } from '../../types';
import { EmployeesInGroups } from '../types';
import {
	EmployeeForPicker,
} from './types';

interface EmployeePickerModalProps {
	closeModal: () => void;
	demographics: DemographicsContract;
	employeesInGroups: EmployeesInGroups;
	disabledEmployeeIds: number[];
	isOpen: boolean;
	planName: string;
	selectedEmployeeIds: number[];
	setSelectedEmployeeIds: (employees: number[]) => void;
}

export const EmployeePickerModal = (props: EmployeePickerModalProps): JSX.Element => {
	const {
		closeModal,
		demographics,
		employeesInGroups,
		disabledEmployeeIds,
		isOpen,
		planName,
		selectedEmployeeIds,
		setSelectedEmployeeIds,
	} = props;

	const [pickerEmployeeIds, setPickerEmployeeIds] = useState(selectedEmployeeIds);

	// Reset the modal state when it's closed and initialize when opened
	useEffect(() => {
		if (isOpen) {
			setPickerEmployeeIds(selectedEmployeeIds);
		} else {
			setPickerEmployeeIds([]);
		}

	}, [selectedEmployeeIds, isOpen]);

	const employeesForPicker = useMemo(() => getEmployeesForPicker(
		demographics.employeeIds,
		demographics.employees,
		employeesInGroups,
		pickerEmployeeIds,
		disabledEmployeeIds
	), [demographics.employeeIds, demographics.employees, employeesInGroups, pickerEmployeeIds, disabledEmployeeIds]);

	const employeeFilters = useMemo(
		() => getPickerFilters(demographics),
		[demographics]
	);

	const syncSelectedEmployeeIds = (): void => {
		const newSelectedEmployeeIds = [];
		employeesForPicker.forEach((employee) => {
			if (employee.selected) {
				newSelectedEmployeeIds.push(employee.id);
			}
		});

		setSelectedEmployeeIds(newSelectedEmployeeIds);
		closeModal();
	};

	const handleSelect = (selectedEmployees: EmployeeForPicker[]): void => {
		const newPickerEmployeeIds = [...pickerEmployeeIds];
		selectedEmployees.forEach((employee) => {
			if (!newPickerEmployeeIds.includes(employee.id)) {
				newPickerEmployeeIds.push(employee.id);
			}
		});
		setPickerEmployeeIds(newPickerEmployeeIds);
	};

	const handleDeselect = (deselectedEmployees: EmployeeForPicker[]): void => {
		const newPickerEmployeeIds = [...pickerEmployeeIds];
		deselectedEmployees.forEach((employee) => {
			const employeeIndex = newPickerEmployeeIds.indexOf(employee.id);
			if (employeeIndex > -1) {
				newPickerEmployeeIds.splice(employeeIndex, 1);
			}
		});
		setPickerEmployeeIds(newPickerEmployeeIds);
	};

	const generateSecondaryContent = (employee: EmployeeForPicker): string => {
		return employee.groupName;
	};

	return (
		<Modal
			headerType="text"
			headline={ planName }
			icon="fab-people-20x16"
			isOpen={ isOpen }
			onClose={ closeModal }
			primaryAction={ syncSelectedEmployeeIds }
			primaryActionText={ $.__('Save') }
			title={ $.__('Choose Specific Employees') }
			type="medium"
		>
			<span>
				{ $.__('Select the employees who are eligible for %1:', planName) }
				<TheNewEmployeeSelector
					employees={ employeesForPicker }
					filters={ employeeFilters }
					mainContent={ (employee): string => employee.displayName }
					onDeselect={ handleDeselect }
					onSelect={ handleSelect }
					secondaryContent={ generateSecondaryContent }
				/>
			</span>
		</Modal>
	);
};
