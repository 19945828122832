
import {
	Flex,
	IconButton,
	IconV2,
	Label,
	LayoutBox,
	Popover,
	TextButton
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	InternalLink,
	Message,
} from '@bamboohr/utils/lib/message';
import classNames from 'classnames';
import {
	cloneDeep,
} from 'lodash';
import {
	Component,
} from 'react';

import {
	CodeRateRow,
} from '../code-rate-row';
import {
	BLANK_CODE_ROW,
} from './utils';

import './codes-and-rates.styl';

export class CodesAndRates extends Component {
	constructor(props) {
		super(props);

		const {
			codesRatesData: dataFromProps,
			hasError = false,
		} = props;
		let currentNewId = -1;
		let codesRatesData;

		if (dataFromProps && dataFromProps.length) {
			codesRatesData = dataFromProps;
		} else {
			const initialRow = cloneDeep(BLANK_CODE_ROW);
			initialRow.codeRateId = currentNewId;
			codesRatesData = [initialRow];
			currentNewId--;
		}

		codesRatesData.forEach((row) => {
			row.hasError = hasError;
		});

		this.state = {
			codesRatesData,
			currentNewId,
			activeOnly: true,
		};
	}

	_handleActiveOnlyToggle = active => this.setState({ activeOnly: active });

	_handleActiveChange = (id, active) => this.setState((prevState) => {
		const {
			codesRatesData,
		} = prevState;
		const currentRowIndex = codesRatesData.findIndex(row => row.codeRateId === id);
		codesRatesData[currentRowIndex].isActive = active;

		return { codesRatesData };
	});

	_handleAddNewCodeClick = () => this.setState((prevState) => {
		const {
			codesRatesData,
			currentNewId,
		} = prevState;
		const newRow = cloneDeep(BLANK_CODE_ROW);
		newRow.codeRateId = currentNewId;
		codesRatesData.push(newRow);

		return {
			codesRatesData,
			currentNewId: currentNewId - 1,
		};
	});

	_handleRemoveCodeClick = codeId => this.setState((prevState) => {
		const {
			codesRatesData,
		} = prevState;

		return {
			codesRatesData: codesRatesData.filter(row => row.codeRateId !== codeId),
		};
	});

	_hasZeroDollarValue = (codesRatesRows) => {
		return codesRatesRows.some(row => row.props.employerRate === "$0.00" || row.props.employerRate === "");
	}

	_hasMissingCodeValue = (codesRatesRows) => {
		return codesRatesRows.some(row => row.props.code === "");
	}

	_hasMissingDescriptionValue = (codesRatesRows) => {
		return codesRatesRows.some(row => row.props.description === "");
	}

	_getCodesRatesRows = () => {
		const {
			codesRatesData,
			activeOnly,
		} = this.state;
		const {
			isTaxEdit,
			isCodesRatesEdit,
			hasError,
		} = this.props;

		const codesRatesDataLength = codesRatesData.length;
		let activeNum = 0;

		const codesRatesRows = codesRatesData.map((row, index) => {
			const {
				codeRateId,
				isActive,
			} = row;
			const hide = activeOnly && !isActive;

			const isLast = index === (codesRatesDataLength - 1);
			const showRemoveBtn = (codeRateId < 0) && index > 0;
			activeNum = isActive ? ++activeNum : activeNum;
			row.hasError = hasError;

			return (
				<CodeRateRow
					{ ...row }
					allDisabled={ isTaxEdit }
					handleActiveChange={ this._handleActiveChange }
					handleRemoveClick={ this._handleRemoveCodeClick }
					hide={ hide }
					isCodesRatesEdit={ isCodesRatesEdit }
					isLast={ isLast }
					key={ codeRateId.toString() }
					showActiveCheck={ isCodesRatesEdit }
					showHistory={ isCodesRatesEdit }
					showRemoveBtn={ showRemoveBtn }
				/>
			);
		});

		return {
			codesRatesRows,
			activeNum,
			totalNum: codesRatesDataLength,
		};
	};

	render() {
		const {
			activeOnly,
		} = this.state;
		const {
			isTaxEdit,
			isCodesRatesEdit,
			clientId,
			clientTaxTypeIncludeId,
			hasError,
		} = this.props;

		const {
			codesRatesRows,
			activeNum,
			totalNum,
		} = this._getCodesRatesRows();
		const hasMissingEmployerValue = this._hasZeroDollarValue(codesRatesRows);
		const hasMissingCodeValue = this._hasMissingCodeValue(codesRatesRows);
		const hasMissingDescValue = this._hasMissingDescriptionValue(codesRatesRows);

		const activeFilterClasses = classNames({ 'CodesAndRates__filter--selected': activeOnly });
		const allFilterClasses = classNames({ 'CodesAndRates__filter--selected': !activeOnly });

		return (
			<div className="CodesAndRates">
				{ ifFeature('encore', null, <h5>{ $.__('Codes & Rates') }</h5>) }

				{ isCodesRatesEdit && (
					<div className="CodesAndRates__activeToggle">
						<TextButton
							clickAction={ () => this._handleActiveOnlyToggle(true) }
							text={ (
								<span className="CodesAndRates__filter">
									<span className={ activeFilterClasses }>{ $.__('Active') } </span>
									({ activeNum })
								</span>
							) }
							type="button"
						/>
						<TextButton
							clickAction={ () => this._handleActiveOnlyToggle(false) }
							text={ (
								<span className="CodesAndRates__filter">
									<span className={ allFilterClasses }>{ $.__('All') } </span>
									({ totalNum })
								</span>
							) }
							type="button"
						/>
					</div>
				) }

				{ ifFeature('encore', 	
					<div className="CodesAndRates__labels">
						{ isCodesRatesEdit && 
							<LayoutBox marginRight={1}>
								<Label>{ $.__('Active') }</Label>
							</LayoutBox> 
						}
						<LayoutBox marginRight={hasError && hasMissingCodeValue ? 5 : 8}>
							<Label required={true} status={hasError && hasMissingCodeValue ? 'error' : undefined}>{ $.__('Class Code') }</Label>
						</LayoutBox>
						<LayoutBox marginRight={hasError && hasMissingDescValue ? 15 : 18}>
							<Label required={true} status={hasError && hasMissingDescValue ? 'error' : undefined}>{ $.__('Class Code Description') }</Label>
						</LayoutBox>
						<LayoutBox marginRight={hasError && hasMissingEmployerValue ? "30px" : "55px"}>
							<Label required={true} status={hasError && hasMissingEmployerValue ? 'error' : undefined}>{ $.__('Employer Rate') }</Label>
						</LayoutBox>
						<LayoutBox marginRight="55px">
							<Label>{ $.__('Employee Rate') }</Label>
						</LayoutBox>
						<Flex>
							<Label>{ $.__('Total') }</Label>
						</Flex>
					</div>,
					<div className="CodesAndRates__labels">
						{ isCodesRatesEdit && <p className="fab-Label CodesAndRates__label--active">{ $.__('Active') }</p> }
						<p className={ classNames(
							"fab-Label",
							"fab-Label--required",
							"CodesAndRates__label--code",
							{ 'CodesAndRates__label--codeError': hasError && hasMissingCodeValue },
							{ 'fab-Label--error': hasError && hasMissingCodeValue },
						) }>{ $.__('Class Code') }</p>
						<p className={ classNames(
							"fab-Label",
							"fab-Label--required",
							"CodesAndRates__label--description",
							{ 'CodesAndRates__label--descriptionError': hasError && hasMissingCodeValue },
							{ 'fab-Label--error': hasError && hasMissingDescValue },
						) }>{ $.__('Class Code Description') }</p>
						<p className={ classNames(
							"fab-Label",
							"fab-Label--required",
							"CodesAndRates__label--employer",
							{ 'CodesAndRates__label--employerError': hasError && hasMissingCodeValue },
							{ 'fab-Label--error': hasError && hasMissingEmployerValue },
						) }>{ $.__('Employer Rate') }</p>
						<p className="fab-Label fab-Label--required CodesAndRates__label--employee">{ $.__('Employee Rate') }</p>
						<p className="fab-Label">{ $.__('Total') }</p>
					</div>
				) }
				
				{ codesRatesRows }

				{ isTaxEdit ? (
					<p className="CodesAndRates__cantEdit">
						<Message
							link={ InternalLink(`/settings/payroll/taxes/tax_type/update_codes_rates?clientId=${ clientId }&taxId=${ clientTaxTypeIncludeId }`) }
							text={ $._(`You can't edit the original codes and rates. [Add new codes and rates here].`) }
						/>
					</p>
				) : (
					<TextButton
						clickAction={ this._handleAddNewCodeClick }
						muted={ true }
						text={ `+ ${ $.__('Add New Class Code') }` }
						type="button"
					/>
				) }
			</div>
		);
	}
}
