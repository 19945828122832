import { TableColumn } from '@bamboohr/fabric';
import moment from 'moment';
import CalendarPicker from 'calendar-picker.react';
import {
	WageOverlapDateObject,
	onOverlappingDateChange,
	SectionType,
	CompanyDeductionsMetadata,
	EmployeeDeductionsMetadata,
} from '../../utils/interfaces';

export const GROUPS = [{
	id: 'overlappingDates',
	title: $.__('Records with Overlapping Dates'),
}];

export function addGroupToRows(records: Array<WageOverlapDateObject>): Array<WageOverlapDateObject> {
	return records.map((record: WageOverlapDateObject, index: number) => {
		record.group = index > 0 ? 'overlappingDates' : '';
		return record;
	});
}

function getWageColumns(): Array<TableColumn<WageOverlapDateObject>> {
	return [
		{
			header: $.__('Pay Rate'),
			cell: (row: WageOverlapDateObject) => row.payRate || '--',
		},
		{
			header: $.__('Pay Type'),
			cell: (row: WageOverlapDateObject) => row.payType || '--',
		},
		{
			header: $.__('Overtime'),
			cell: (row: WageOverlapDateObject) => row.overtime || '--',
		},
		{
			header: $.__('Start Date'),
			cell: (row: WageOverlapDateObject) => {
				const {
					startDate,
				} = row;
				return startDate;
			},
		},
		{
			header: $.__('End Date'),
			cell: (row: WageOverlapDateObject) => {
				const {
					endDate,
				} = row;
				return endDate;
			},
		},
	];
}

function getCompanyDeductionsColumns(onDateChange: onOverlappingDateChange): Array<TableColumn<unknown>> {
	return [
		{
			header: $.__('Name'),
			cell: (row: CompanyDeductionsMetadata) => row.name || '--',
		},
		{
			header: $.__('Start Date'),
			cell: (row: WageOverlapDateObject) => {
				const {
					startDate,
					endDate,
					id,
				} = row;
				const max = endDate ? moment(endDate, moment.defaultFormat) : null;
				return (
					<CalendarPicker
						onChange={ (value: string) => {
							const newValue = value ? moment(value, 'YYYY-MM-DD').format(moment.defaultFormat) : value;
							onDateChange(id, 'startDate', newValue);
						} }
						settings={ {
							start: moment(startDate, moment.defaultFormat),
							max,
						} }
						type="date"
					/>
				);
			},
		},
		{
			header: $.__('End Date'),
			cell: (row: WageOverlapDateObject) => {
				const {
					startDate,
					endDate,
					id,
				} = row;
				return (
					<CalendarPicker
						onChange={ (value: string) => {
							const newValue = value ? moment(value, 'YYYY-MM-DD').format(moment.defaultFormat) : value;
							onDateChange(id, 'endDate', newValue);
						} }
						settings={ {
							start: moment(endDate, moment.defaultFormat),
							min: moment(startDate, moment.defaultFormat),
						} }
						type="date"
					/>
				);
			},
		},
	];
}

function getEmployeeDeductionsColumns(onDateChange: onOverlappingDateChange): Array<TableColumn<unknown>> {
	return [
		{
			header: $.__('Name'),
			cell: (row: EmployeeDeductionsMetadata) => row.name || '--',
		},
		{
			header: $.__('Employee/Employer Pays'),
			cell: (row: EmployeeDeductionsMetadata) => `${ row.employeePays } / ${ row.employerPays }`,
		},
		{
			header: $.__('Start Date'),
			cell: (row: WageOverlapDateObject) => {
				const {
					startDate,
					endDate,
					id,
				} = row;
				const max = endDate ? moment(endDate, moment.defaultFormat) : null;
				return (
					<CalendarPicker
						onChange={ (value: string) => {
							const newValue = value ? moment(value, 'YYYY-MM-DD').format(moment.defaultFormat) : value;
							onDateChange(id, 'startDate', newValue);
						} }
						settings={ {
							start: moment(startDate, moment.defaultFormat),
							max,
						} }
						type="date"
					/>
				);
			},
		},
		{
			header: $.__('End Date'),
			cell: (row: WageOverlapDateObject) => {
				const {
					startDate,
					endDate,
					id,
				} = row;
				return (
					<CalendarPicker
						onChange={ (value: string) => {
							const newValue = value ? moment(value, 'YYYY-MM-DD').format(moment.defaultFormat) : value;
							onDateChange(id, 'endDate', newValue);
						} }
						settings={ {
							start: moment(endDate, moment.defaultFormat),
							min: moment(startDate, moment.defaultFormat),
						} }
						type="date"
					/>
				);
			},
		},
	];
}

export function getColumnsByType(type: SectionType, onDateChange: onOverlappingDateChange): Array<TableColumn<unknown>> {
	if (type === 'employeeWage') {
		return getWageColumns();
	}
	if (type === 'employeeDeductions') {
		return getEmployeeDeductionsColumns(onDateChange);
	}
	return getCompanyDeductionsColumns(onDateChange);
}
