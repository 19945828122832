import { noop } from 'lodash';
import classNames from 'classnames';
import { SelectableBox } from '@fabric/selectable-box';
import {
	getFieldSelectBoxContent,
} from './field-select-box-utils';

import {
	FieldSelectBoxProps,
} from '../../utils/interfaces';

import './field-select-box.styl';

export function FieldSelectBox(props: FieldSelectBoxProps): JSX.Element {
	const {
		id,
		displayValue,
		value,
		isDisabled,
		isSelected,
		onChange,
		database,
		sectionType,
		metadata,
		selectSubGroup,
		fieldName,
	} = props;
	const fieldSelectBoxId = `${ id }_${ database }`;
	const handleChange = isDisabled ? noop : onChange;
	const displayValueClasses = classNames(
		'FieldSelectBox__displayValue truncate',
		{
			'FieldSelectBox__displayValue--selected': isSelected,
			'FieldSelectBox__displayValue--disabled': isDisabled,
		}
	);
	const boxContent = getFieldSelectBoxContent(sectionType, isSelected, database, metadata, fieldName);

	return (
		<div className="FieldSelectBox">
			<SelectableBox
				inputId={ fieldSelectBoxId }
				isChecked={ isDisabled ? false : isSelected }
				isDisabled={ isDisabled }
				name={ fieldSelectBoxId }
				onChange={ () => handleChange(id, database, selectSubGroup) }
				type="checkbox"
				value={ value }
			>
				{ boxContent || <p className={ displayValueClasses }>{ displayValue || '--' }</p> }
			</SelectableBox>
		</div>
	);
}
