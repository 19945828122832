import moment from 'moment';

import {
	UPCOMING_DAYS_THRESHOLD,
} from '../constants';
import { isPlanExpired } from '../etc/is-plan-expired';
import { isPlanFutureStart } from '../etc/is-plan-future-start';
import { BenefitPlanListContract } from '../types';
export { getFormattedDate } from '../../utils';

function getDaysUntilDate(date: string): number {
	const dateMoment = moment(date);
	return dateMoment.diff(moment(), 'days');
}

export function isDateApproachingUpcomingThreshold(date: string): boolean {
	return (getDaysUntilDate(date) <= UPCOMING_DAYS_THRESHOLD);
}

export function isDatePast(date: string): boolean {
	return (getDaysUntilDate(date) < 0);
}

export function getPlanActivityState(plan: BenefitPlanListContract): string {
	if (plan.isDraft) {
		return 'incomplete';
	}
	if (isPlanFutureStart(plan)) {
		return 'future-dated';
	}
	if (isPlanExpired(plan)) {
		return 'expired';
	}
	if (plan.active) {
		return 'active';
	}
}
