import { ReactElement, useEffect, useState } from 'react';
import { Flex, TextButton } from '@bamboohr/fabric';
import { CheckCircle16x16, CircleArrow16x16 } from '@bamboohr/grim';
import { Message } from '@bamboohr/utils/lib/message';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from '../styles';

type Props = {
	onResendSms: () => void;
};

export function SmsResendCode({ onResendSms }: Props): ReactElement {
	const [countdown, setCountdown] = useState(20);
	const styles = useStyles();
	let timer;
	const decreaseSeconds = () => {
		setCountdown((prev) => {
			if (prev === 0) {
				clearInterval(timer);
				return 0;
			}
			return prev - 1;
		});
	};

	const handleCountdown = () => {
		setCountdown(20);
		clearInterval(timer);
		timer = setInterval(decreaseSeconds, 1000);
	};

	const codeResend = () => {
		onResendSms();
		handleCountdown();
	};

	useEffect(() => {
		handleCountdown();
	}, []);

	return (
		<Flex>
			{countdown > 15 && (
				<TextButton color='secondary' disabled={true} startIcon={ifFeature('encore', 'circle-check-solid', <CheckCircle16x16 />)} type='button'>
					{$.__('Code Sent!')}
				</TextButton>
			)}
			{countdown > 0 && countdown <= 15 && (
				<TextButton color='secondary' disabled={true} startIcon={ifFeature('encore', 'arrow-rotate-right-regular', <CircleArrow16x16 />)}>
					<Message
						params={[countdown]}
						strong={{ tag: 'p', className: styles.boldCountdown }}
						text={$._(`Resend Code in **{1}** sec`)}
					/>
				</TextButton>
			)}
			{countdown <= 0 && (
				<TextButton onClick={codeResend} startIcon={ifFeature('encore', 'arrow-rotate-right-regular', <CircleArrow16x16 />)} type='button'>
					{$.__(`Resend Code`)}
				</TextButton>
			)}
		</Flex>
	);
}
