import { ifFeature, isEnabled } from '@bamboohr/utils/lib/feature';
import { unmountComponentAtNode } from 'react-dom';
import Ctrl, {
	onClick,
	onStateChange,
} from 'ctrl.deco';

import {
	noop,
	isFunction
} from 'lodash';

import {
	toPascalCase
} from 'BambooHR.util';

import './styles.styl';

const $rootElem = $('#settings-page');
const $main = $('#settings-main-content', $rootElem);
const $mainNav = $('#settings-left', $rootElem);
const $subNav = $('.Settings__subNav, .settings-second-nav', $rootElem);

const SELECTED_CLASS = ifFeature('encore', 'fab2-SideNavigation__link--active', 'settings-selected');
const SELECTED_SUB_CLASS = 'SettingsSubNav__link--active';
const FULL_WIDTH_CLASS = 'settings--full-width';
const encoreEnabled = isEnabled('encore');

// Pages that render everything, including the subnav, in Po, and thus should not have any padding or offsets.
const fullWidthPaths = ['pto', 'time_tracking'];

function getUri() {
	return window.location.href.replace(window.location.origin, '');
}

let isUpdating = false;

// Wait for fade out of content to avoid jitter
function afterContentFade(fn, abortSignal) {
	setTimeout(() => {
		if (!abortSignal.aborted) fn();
	}, 200);
}

// Used to cancel any pending delays, for example if the user navigates away before the delay is up
let abortController = new AbortController();

/**
* Shows sub navigation content by rendering this instance's subNavTmplData inside of the
* template contained in the instance's subNavTmpl field.
 */
function showSubNav() {
	const subNavTmpl = this.subNavTmpl;
	const uri = getUri();
	const hrefSelector = `[href="${ uri }"]`;

	if (subNavTmpl) {
		let tmplData = this.subNavTmplData || {};

		if (isFunction(tmplData)) {
			tmplData = tmplData.call(this, cb);
		} else {
			cb(tmplData);
		}
	}

	function cb(data) {
		let subNav = $('.settings-sub', $rootElem).css({
			left: 0,
			position: 'absolute',
		})
			.filter($subNav)
			.html(microTemplate(subNavTmpl, data));
		// @startCleanup encore
		if (encoreEnabled) {
		// @endCleanup encore
			$('.section-header').css({ display: 'none' });
			subNav.css({
				left: 0,
				opacity: 1,
				visibility: 'visible',
				position: 'static',
			});
		// @startCleanup encore
		} else {
			subNav.css({
				left: 225,
				opacity: 1,
				visibility: 'visible',
			});
		}
		// @endCleanup encore

		let subPage = subNav.find(hrefSelector);

		subPage.addClass(SELECTED_CLASS);

		subNav.css({ left: $('.js-settingsLeft').innerWidth() });
		subPage
			.parent()
			.addClass(SELECTED_CLASS);

		// @startCleanup encore
		if (encoreEnabled) {
		// @endCleanup encore
			$main.css({
				left: 0,
			});
		// @startCleanup encore
		} else {
			$main.css({
				width: '62%'
			});

			const offsetMax = 16;
			const offsetRatio = 1.333; //Found from the subnav and main nav width range and the main setting left padding range (offsetMax/12)
			const leftPaddingMin = 22;
			const mainNavWidth = 262;
			const minSubNavWidth = 225;
			const subNavWidth = subNav.outerWidth();
			let offset = Math.ceil((subNavWidth - minSubNavWidth) * offsetRatio);
			offset = (offset < 0) ? 0 : offset;
			offset = (offset > offsetMax) ? offsetMax : offset;
			$main.css({
				left: subNavWidth + leftPaddingMin + offset,
				width: `calc(100% - ${ subNavWidth + leftPaddingMin + offset + mainNavWidth }px)`
			});
		}
		// @endCleanup encore

	}
}

/**
 * Shows main content by rendering this instance's mainTmplData inside of the
 * template contained in the instance's mainTmplData field.
 *
 * When the rendering is complete the onMainReady callback is called
 */
function showMainContent() {
	const mainTmpl = this.mainTmpl;

	if (mainTmpl) {
		let tmplData = this.mainTmplData || {};

		if (isFunction(tmplData)) {
			tmplData = tmplData.call(this, cb.bind(this));
		} else {
			cb.call(this, tmplData);
		}
	}

	function cb(data) {
		const mainReadyFn = this.onMainReady || noop;

		data = Object.assign({
			description: null
		}, data);

		$main
			.html(microTemplate(mainTmpl, data))
			.css({
				opacity: 1
			});

		if (this.subCtrl && mainReadyFn === this.subCtrl.onMainReady) {
			this.subCtrl::mainReadyFn($main);
		} else {
			this::mainReadyFn($main);
		}
	}
}

@Ctrl('/settings/:page/(.*)?')
class SettingsNavCtrl {
	get page() {
		return this._params.page;
	}

	get subCtrl() {
		return Ctrl.getCtrl(`Settings${ toPascalCase(this.page) }Ctrl`);
	}

	get title() {
		return (this.subCtrl || {}).title;
	}

	@onClick('.Settings__mainNav-link')
	onNavClick(e, ctrl) {
		e.preventDefault();

		window.history.pushState({}, '', $(this).attr('href'));
	}

	@onClick('.Settings__subNav li a, .settings-second-nav li a.nopjax:not(.poLink)')
	onSubNavClick(e, ctrl) {
		e.preventDefault();

		window.history.pushState({}, '', $(this).attr('href'));
		((ctrl.subCtrl || {}).onSubNavClick || noop).call(this, e, ctrl.subCtrl);
	}

	@onStateChange()
	onStateChange(e, ctrl, oldUri, newUri) {
		let newUriSplit = newUri.split('/');
		let oldUriSplit = oldUri.split('/');
		const poNode = document.getElementById('js-SettingsPoRoot');

		// Remove any leftover po components
		if (poNode) {
			unmountComponentAtNode(poNode);
		}

		newUriSplit.splice(0, 2);
		oldUriSplit.splice(0, 2);

		var samePage = (e.type == 'refreshState') ? false : newUriSplit.shift() == oldUriSplit.shift();
		var sameSubPage = newUriSplit.join('/') == oldUriSplit.join('/');

		if (!samePage && !sameSubPage) {
			// Remove any po React nodes at the top level to ensure Popovers are removed
			const timeTrackingSettingsRoot = document.getElementById('time-tracking-settings-root');
			if (timeTrackingSettingsRoot) {
				unmountComponentAtNode(timeTrackingSettingsRoot)
			}
		}

		setTimeout(() => {
			ctrl.updatePage(samePage && !isUpdating, samePage && sameSubPage && !isUpdating, newUri);
		}, 0);
	}

	constructor() {
		$(() => {
			this.updatePage(false, false, getUri());
		});
	}

	/**
	 * For toggling the active icons in encore
	 * 
	 * @param {HTMLElement} selector The element to toggle the icons on
	 * @param {Boolean} state Whether or not the active icon should show
	 * @returns {undefined}
	 */
	toggleActiveIcons(selector, state) {
		const icons = $(selector).find('ba-icon');
		$(icons[0]).toggleClass('SettingsNav__hiddenIcon', state);
		$(icons[1]).toggleClass('SettingsNav__hiddenIcon', !state);
	}

	updatePage(samePage = false, sameSubPage = false, newUri) {
		isUpdating = true;

		/* @startCleanup encore */
		if (ifFeature('encore', true)) {
		/* @endCleanup encore */
			// cancel any pending UI updates 
			abortController.abort();
			abortController = new AbortController();
		/* @startCleanup encore */
		}
		/* @endCleanup encore */

		const subCtrl = this.subCtrl;
		let hrefSelector = `[href^="${ newUri }"]`;

		/* @startCleanup encore */
		if (ifFeature('encore', true)) {
		/* @endCleanup encore */
			afterContentFade(() => {
				const isFullWidthPage = fullWidthPaths.some(path => window.location.pathname.startsWith(`/settings/${path}`));
				document.body.classList.toggle(FULL_WIDTH_CLASS, isFullWidthPage);
			}, abortController.signal);
		/* @startCleanup encore */
		} else {
			// Wait for fade out of content to avoid jitter
			setTimeout(() => {
				const isFullWidthPage = fullWidthPaths.some(path => window.location.pathname.startsWith(`/settings/${path}`));
				document.body.classList.toggle(FULL_WIDTH_CLASS, isFullWidthPage);
			}, 200);
		}
		/* @endCleanup encore */

		if (!subCtrl) {
			return;
		}

		if (this.title) {
			document.title = this.title;
		}

		if (!samePage) {
			let $newPage = $('a', $mainNav).filter(`[href^="/settings/${ this.page }"]`);
			if (this.page === 'account') {
				$newPage = $('a', $mainNav).filter(`[href^="/app/settings/account"]`);
			}
			const oldPage = $('.' + SELECTED_CLASS, $mainNav);
			oldPage.removeClass(SELECTED_CLASS);
			// @startCleanup encore
			if (encoreEnabled) {
			// @endCleanup encore
				this.toggleActiveIcons(oldPage, false);
			// @startCleanup encore
			}
			// @endCleanup encore
			$newPage.addClass(SELECTED_CLASS);
			// @startCleanup encore
			if (encoreEnabled) {
			// @endCleanup encore
				this.toggleActiveIcons($newPage, true);
			// @startCleanup encore
			}
			// @endCleanup encore
			
			// @startCleanup encore
			if (!encoreEnabled) {
				$newPage
					.parent()
					.addClass(SELECTED_CLASS);
			}
			// @endCleanup encore

			const settingsSubNav = $('.settings-sub', $rootElem);
			settingsSubNav.css({
				opacity: 0,
			});

			if (this.page !== 'account' && this.page !== 'access_levels') {
				setTimeout(() => {
					settingsSubNav.css({
						position: 'absolute',
					});
				}, 200);
			}
		}

		if (!sameSubPage) {
			hrefSelector = `[href="${ newUri }"]`;
			let newSubPage = $('a', $subNav).filter(hrefSelector);
			// @startCleanup encore
			if (encoreEnabled) {
				$('.' + SELECTED_SUB_CLASS, $subNav).removeClass(SELECTED_SUB_CLASS);
				newSubPage.addClass(SELECTED_SUB_CLASS);
			} else {
				$('.' + SELECTED_CLASS, $subNav).removeClass(SELECTED_CLASS);
				newSubPage.addClass(SELECTED_CLASS);
			}
			// @endCleanup encore

			// @startCleanup encore
			if (encoreEnabled) {
			// @endCleanup encore
				this.toggleActiveIcons(newSubPage, true);
			// @startCleanup encore
			}
			// @endCleanup encore

			// @startCleanup encore
			if (!encoreEnabled) {
				newSubPage
				.parent()
				.addClass(SELECTED_CLASS);
			}
			// @endCleanup encore

			$main.css({
				opacity: 0
			});
		}

		/* @startCleanup encore */
		if (ifFeature('encore', true)) {
		/* @endCleanup encore */
			afterContentFade(() => {
				if (!samePage) {
					(subCtrl.showSubNav || showSubNav).call(subCtrl, $subNav);
				}

				if (!sameSubPage) {
					(subCtrl.showMainContent || showMainContent).call(subCtrl, $main);
				}
				
				isUpdating = false;
			}, abortController.signal);
		/* @startCleanup encore */
		} else {
			setTimeout(() => {
				if (!samePage) {
					(subCtrl.showSubNav || showSubNav).call(subCtrl, $subNav);
				}

				if (!sameSubPage) {
					(subCtrl.showMainContent || showMainContent).call(subCtrl, $main);
				}
			}, 200);
		}
		/* @endCleanup encore */
	}
}