import {
	Fragment,
} from 'react';
import { Icon } from '@bamboohr/fabric';
import {
	Button,
} from '@fabric/button';

import {
	getHeaderDataByType,
} from './migration-section-head-utils';
import {
	HelpButton,
} from '../help-button';
import {
	MigrationSectionHeadProps,
} from '../../utils/interfaces';

import './migration-section-head.styl';

export function MigrationSectionHead(props: MigrationSectionHeadProps): JSX.Element {
	const {
		type,
		completedFields,
		totalFields,
		onSelectAllClick,
		showMappingTool,
		bambooLink,
		traxLink,
	} = props;
	const {
		iconName,
		headerText,
		upNextText,
		showButtons,
		subtitle,
		mappingToolInstruction,
		mappingToolBtnLeft,
		mappingToolBtnRight,
	} = getHeaderDataByType(type, showMappingTool);
	const useMappingToolBtns = showMappingTool && (mappingToolBtnRight || mappingToolBtnLeft);
	const actionParam = {
		bambooLink,
		traxLink,
	};

	return (
		<div className="MigrationSectionHead">
			<div className="MigrationSectionHead__upper">
				<div className="MigrationSectionHead__iconAndHeader">
					<Icon brand={ true } name={ iconName } />
					<h2 className="MigrationSectionHead__headerText">{ headerText }</h2>
					{ subtitle && <h4 className="MigrationSectionHead__subtitle">{ subtitle }</h4> }
				</div>
				<p className="MigrationSectionHead__upNextText">{ upNextText }</p>
			</div>

			<p className="MigrationSectionHead__instruction">
				{ showMappingTool ? mappingToolInstruction : (
					<span>
						{ $.__('Tell us which record is correct out of the two systems') }.
						&nbsp;
						{ $.__('If neither value is correct, please go update it in BambooHR and apply the change in TRAXPayroll') }.
					</span>
				) }
			</p>

			<div className="MigrationSectionHead__lower">
				<div className="MigrationSectionHead__selectAllContainer">
					{ showButtons && !useMappingToolBtns && (
						<Fragment>
							<Button
								clickAction={ (): void => onSelectAllClick('bamboo') }
								iconBefore="fab-check-14x14"
								outline={ true }
								text={ $.__('Select All BambooHR') }
								type="button"
							/>
							<Button
								clickAction={ (): void => onSelectAllClick('trax') }
								iconBefore="fab-check-14x14"
								outline={ true }
								text={ $.__('Select All TRAXPayroll') }
								type="button"
							/>
						</Fragment>
					) }
					{ showButtons && useMappingToolBtns && (
						<Fragment>
							{ mappingToolBtnLeft && (
								<Button
									clickAction={ () => mappingToolBtnLeft.action(actionParam) }
									outline={ true }
									text={ mappingToolBtnLeft.text }
									type="button"
								/>
							) }
							{ mappingToolBtnRight && (
								<Button
									clickAction={ () => mappingToolBtnRight.action(actionParam) }
									outline={ true }
									text={ mappingToolBtnRight.text }
									type="button"
								/>
							) }
						</Fragment>
					) }
				</div>

				<div className="MigrationSectionHead__lowerRight">
					<p className="MigrationSectionHead__progress">
						{ $.__('%1$s fields complete', `${ completedFields }/${ totalFields }`) }
					</p>
					<span className="MigrationSectionHead__helpButton">
						<HelpButton />
					</span>
				</div>
			</div>
		</div>
	);
}
