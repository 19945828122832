import {
	AdditionalPlanUrlsContract,
	AutoEnrollTypeEnum,
	BasicLifeOrADandDEnum,
	ClassCodeDetails,
	LifeInsuranceTypeEnum,
	MetalTier,
	PlanFilesContract,
	PlanState,
	SimpleItem,
	Vendor,
	VoluntaryLifeOrADandDEnum,
} from '.';
import { DisabilityDisbursementTypeEnum, DisabilityTypeEnum } from './disability';
import { PlanRateTypeEnum } from './rate-type';
import {
	CostTypeEnumContract,
	CostTypeStringEnum,
	DeductionFrequencyEnumContract,
	PayFrequencyEnumContract,
	PlanTypeEnumContract,
} from '../../types';
import { DeductionDirectionEnumContract } from '../../shared/deduction-migration-modal.react/types';

export type PlanStateEnumContract =
	| PlanState.complete
	| PlanState.draft
	| PlanState.recovery
	| PlanState.duplicate
	| PlanState.revision;

export enum PayTypeEnumContract {
	salary = 'Salary',
	hourly = 'Hourly',
	exceptionHourly = 'Exception hourly',
	daily = 'Daily',
	monthly = 'Monthly',
	weekly = 'Weekly',
	salaryNonExempt = 'Salary non-exempt',
	contract = 'Contract',
	commission = 'Commission',
	pieceRate = 'Piece rate',
	proRate = 'Pro rata',
}

export enum TTERequirement {
	markedManually = 'manual',
	immediatelyOnHire = 'hire_date',
	afterWaitingPeriod = 'waiting_period',
	firstOfMonthAfterWaitingPeriod = 'month_after_waiting_period',
}

export enum TTEWaitPeriod {
	day = 'days',
	week = 'weeks',
	month = 'months',
	year = 'years',
}

export enum SupplementalPlanType {
	HospitalIndemnity = '8',
	CriticalIllness = '9',
	Accident = '10',
	Cancer = '11',
}

export enum DeductionTypeEnumContract {
	preTaxSubtype = 'pretax_subtype',
	preTaxGeneralHealthcare = '5',
	postTax = '4',
	'401k' = '1',
	roth401k = '17',
	'408p' = '11',
	'403b' = '12',
	roth403b = '20',
	'457b' = '13',
	roth457b = '29',
	'401a' = '28',
	'408k' = '15',
	'409a' = '16',
	commuterBenefit = '8',
	'501c' = '14',
	dependentCare = '24',
	hospitalIndemnityPreTax = '33',
	updatedPreTaxGeneralHealthcare = '31',
	afterTax401k = '36',
}

export enum ReimbursementFrequencyEnumContract {
	payPeriod = 'pay period',
	month = 'month',
	quarter = 'quarter',
	year = 'year',
	once = 'one time',
}

export enum OrdinalPayDateEnumContract {
	first = 'FIRST',
	second = 'SECOND',
	third = 'THIRD',
	fourth = 'FOURTH',
	last = 'LAST',
}

export interface DeductionFrequencyContract {
	payScheduleId: number;
	frequency: DeductionFrequencyEnumContract;
	ordinalPayDatesInPeriod?: OrdinalPayDateEnumContract[];
}

export interface DeductionDateRuleContract {
	number: number | null;
	direction: DeductionDirectionEnumContract | null;
}

export interface PlanTypeContract {
	type: PlanTypeEnumContract;
	defaults: {
		deductionDateRule?: DeductionDateRuleContract;
	};
	acaMetalTiers: MetalTier[];
	vendors: Vendor[];
	planTypes: SimpleItem[];
}

export interface SelectedCoverageLevelContract {
	linkId: string;
	totalCost: number | null;
	currencyCode: string;
}

export interface CustomCoverageLevelGetContract {
	linkId: string;
	name: string;
}

interface DefaultCoverageLevelContract {
	id: number;
	shortName: string;
	description: string;
	sortOrder: number;
}

export interface PayScheduleContract {
	id: number;
	name: string;
	frequency: PayFrequencyEnumContract;
	deductionFrequencies: DeductionFrequencyEnumContract[];
}

export interface DeductionOffsetOptionsContract {
	allowedDirections: DeductionDirectionEnumContract[];
	allowedNumbers: number[];
}

export interface TimeToEligibilityOptionsContract {
	requirementOptions: TTERequirement[];
	waitPeriods: TTEWaitPeriod[];
}

export interface OptionsContract {
	deductionTypes: DeductionTypeEnumContract[];
	defaultCoverageLevels: DefaultCoverageLevelContract[];
	paySchedules: PayScheduleContract[];
	reimbursementFrequencies: ReimbursementFrequencyEnumContract[];
	deductionDateRule: DeductionOffsetOptionsContract;
	timeToEligibility: TimeToEligibilityOptionsContract;
}

export interface DecisionDataContract {
	hasMasterTaxStartDate?: boolean | null;
	hasTrax: boolean;
	countOfSafeHarborPlans: number;
	acaEnabled: boolean;
	deductionDateRule: {
		isVisible: boolean;
		isEditable: boolean;
	};
	benefitsAdministrationEnabled: boolean;
}

export interface ReimbursementContract {
	amount: number | null;
	frequency: ReimbursementFrequencyEnumContract | null;
	currencyCode: string | null;
}

export interface AcaValuesContract {
	isMinValueStandard: boolean | null;
	isMinEssentialCoverage: boolean | null;
}

interface FilterItem {
	id: number;
	name: string;
}

interface FilterList {
	[key: number]: FilterItem;
}

export interface Employee {
	id: number;
	photoUrl: string;
	firstName: string;
	lastName: string;
	locationId: number;
	divisionId: number;
	departmentId: number;
	employmentStatusId: number;
	jobTitleId: number;
	payType: PayTypeEnumContract | '';
}

export interface EmployeeList {
	[key: number]: Employee;
}

export interface DemographicsContract {
	employees: EmployeeList;
	locations: FilterList;
	departments: FilterList;
	divisions: FilterList;
	employmentStatuses: FilterList;
	payTypes: PayTypeEnumContract[];
	employeeIds: number[];
	locationIds: number[];
	departmentIds: number[];
	divisionIds: number[];
	employmentStatusIds: number[];
	jobTitleIds: number[];
	jobTitles: FilterList;
}

export interface FiltersContract {
	employmentStatuses: number[];
	departments: number[];
	divisions: number[];
	locations: number[];
	payTypes: PayTypeEnumContract[];
	jobTitles: number[];
}

export interface TimeToEligibilityContract {
	requirement: TTERequirement | '' | null;
	waitTime: number | null;
	waitPeriod: TTEWaitPeriod | '' | null;
}

export interface CoverageCostGetContract {
	linkId: string;
	employeeCostType: CostTypeEnumContract | null;
	employeeCost: number | null;
}

export interface LossOfEligibilityContract {
	ruleCalculationType: string | null;
}

export interface AgeBand {
	maxAge: number;
	minAge: number;
	rate: number;
}

export interface EligibilityGroupCalculatorSettings {
	companyPays: number | null;
	currencyCode: string;
	costType: CostTypeStringEnum;
	employeePays: number | null;
	rate: number | null;
	type: PlanRateTypeEnum;
}

export interface NewEligibilityGroupGetContract {
	timeToEligibility: TimeToEligibilityContract;
	filters: FiltersContract;
	lossOfEligibility?: LossOfEligibilityContract;
	specificEmployeeIds: number[];
	coverageCosts: CoverageCostGetContract[];
	class: ClassCodeDetails;
}

export interface EligibilityGroupGetContract extends NewEligibilityGroupGetContract {
	id: number;
	calculatorSettings: EligibilityGroupCalculatorSettings;
}

export interface EligibilityGroupsGetContract {
	existing: EligibilityGroupGetContract[];
	new?: NewEligibilityGroupGetContract[];
}

export interface EnrollmentInfo {
	hasFutureEnrollments: boolean;
	hasHadEnrollments: boolean;
	enrolledEmployeeIds: number[];
	scheduledEnrollmentEmployeeIds: number[];
	enrolledEmployeesByCoverageId: { [key: string]: number[] };
}

export interface BenefitPlanGetContract {
	additionalPlanUrls: AdditionalPlanUrlsContract[];
	carrierPlanId: string | null;
	dependentQuestions: string[];
	employeeQuestions: string[];
	id: number;
	isRestorable: boolean;
	parentBenefitPlanId: string;
	planState: PlanStateEnumContract;
	enrollmentInfo: EnrollmentInfo;
	name: string;
	planDates: {
		startYmd: string | null;
		endYmd: string | null;
	};
	description: string | null;
	summary: string | null;
	url: string | null;
	planFiles: PlanFilesContract[];
	planTypeData: {
		autoEnrollSetting: AutoEnrollTypeEnum | null;
		isVariable: boolean | null;
		rateType: PlanRateTypeEnum | null;
		deductionTypeId: DeductionTypeEnumContract | null;
		reportableOnW2: boolean | null;
		aca: AcaValuesContract;
		safeHarbor: boolean | null;
		reimbursement: ReimbursementContract;
		coverageLevels: {
			selected: {
				default: SelectedCoverageLevelContract[];
				custom: {
					existing: SelectedCoverageLevelContract[];
					new: SelectedCoverageLevelContract[];
				};
			};
			custom: {
				existing: CustomCoverageLevelGetContract[];
				new: CustomCoverageLevelGetContract[];
			};
		};
		deductionDateRule: DeductionDateRuleContract;
		deductionFrequencies: DeductionFrequencyContract[];
		coverageRelationships?: string[];
		carrierId: string | null;
		metalTier: string | null;
		planCategoryDataOptions?: PlanCategoryDataOptionsContract;
	};
	eligibilityGroups: EligibilityGroupsGetContract;
	legalText: string | null;
}

export interface CoverageAmount {
	amount: number;
}

export interface FixedAmountCoverageContract extends BasicCoverageTypeContract {
	employee: CoverageAmount | null;
	spouse: CoverageAmount | null;
	child: CoverageAmount | null;
}

export interface PlanCategoryDataOptionsContract {
	calculatorSettings?: PlanCalculatorSettings;
	lifeInsuranceType?: LifeInsuranceTypeEnum | null;
}

export interface HealthCategoryDataOptionsContract extends PlanCategoryDataOptionsContract {
	typeId: string | null;
}

export interface DisabilityCategoryDataOptionsContract extends PlanCategoryDataOptionsContract {
	disabilityDisbursementAmount: number | null;
	disabilityDisbursementCurrencyCode: string;
	disabilityDisbursementType: DisabilityDisbursementTypeEnum;
	disabilityMaxBenefitAmount: number | null;
	disabilityType: DisabilityTypeEnum;
	isMaxBenefitAmountChecked?: boolean;
}

export interface LifeCategoryDataOptionsContract extends PlanCategoryDataOptionsContract {
	lifeInsuranceSpouseCoverage?: boolean | null;
	lifeInsuranceChildCoverage?: boolean | null;
	type?: BasicLifeOrADandDEnum | VoluntaryLifeOrADandDEnum | null;
}

export interface BasicCoverageTypeContract {
	type: 'fixed_amount' | 'salary_based' | 'other' | '';
}

export enum SalaryBasedSpouseType {
	percentage = 'percentage',
	fixed_amount = 'fixed_amount',
}

export interface CoverageAmount {
	amount: number;
}

export interface SalaryBasedCoverageAmount {
	type: SalaryBasedSpouseType;
	amount: number;
}
export interface EmployeeSalaryBasedCoverageAmount {
	multiplier: number;
	minimumAmount: number | null;
	maximumAmount: number | null;
}

export interface SalaryBasedCoverageContract extends BasicCoverageTypeContract {
	employee: EmployeeSalaryBasedCoverageAmount | null;
	spouse: SalaryBasedCoverageAmount | null;
	child: CoverageAmount | null;
}

export interface BasicLifeCategoryDataOptionsContract extends PlanCategoryDataOptionsContract {
	lifeInsuranceType: LifeInsuranceTypeEnum | null;
	spouseCoverage?: boolean | null;
	childCoverage?: boolean | null;
	type: BasicLifeOrADandDEnum | VoluntaryLifeOrADandDEnum | null;
	coverage: BasicCoverageTypeContract | FixedAmountCoverageContract | SalaryBasedCoverageContract;
}

export interface VoluntaryCoverageAmountContract {
	increment: number;
	minimumAmount?: number;
	maximumAmount: number;
	multiplier?: number | null;
	percentOfEmployeeCoverage?: number | null;
}

export enum VoluntaryCoverageTypeEnum {
	voluntary = 'voluntary_life',
}

export interface VoluntaryCoverageContract {
	type: VoluntaryCoverageTypeEnum | '';
	employee: VoluntaryCoverageAmountContract;
	spouse: VoluntaryCoverageAmountContract | null;
	child: VoluntaryCoverageAmountContract | null;
}

export interface VoluntaryLifeCategoryDataOptionsContract extends PlanCategoryDataOptionsContract {
	lifeInsuranceType: LifeInsuranceTypeEnum | null;
	type: BasicLifeOrADandDEnum | VoluntaryLifeOrADandDEnum | null;
	spouseCoverage: boolean;
	childCoverage: boolean;
	coverage: VoluntaryCoverageContract;
}

export interface PlanWizardGetContract {
	benefitPlan?: BenefitPlanGetContract;
	planType?: PlanTypeContract;
	options: OptionsContract;
	decisionData: DecisionDataContract;
	medicalPlans: MedicalPlanContract;
}

export interface MedicalPlanContract {
	[id: string | number]: PlanDependencyContract;
}

export interface PlanDependencyContract {
	id: number;
	isDependency: boolean;
	name: string;
}

export interface NewCustomCoverageLevelContract {
	linkId: string;
	name: string;
}

export interface ExistingCustomCoverageLevelContract extends NewCustomCoverageLevelContract {
	id: number;
}

export interface NewSelectedCoverageLevelContract {
	linkId: string;
	totalCost: number;
	currencyCode: string;
}

export interface ExistingSelectedCoverageLevelContract extends NewSelectedCoverageLevelContract {
	id: number;
}

export interface CoverageLevelsPostContract {
	custom: {
		existing: ExistingCustomCoverageLevelContract[];
		new: NewCustomCoverageLevelContract[];
	};
	selected: {
		default: ExistingSelectedCoverageLevelContract[];
		custom: {
			existing: ExistingSelectedCoverageLevelContract[];
			new: NewSelectedCoverageLevelContract[];
		};
	};
}

export interface CoverageCostPostContract {
	linkId: string;
	employeeCostType: CostTypeEnumContract;
	employeeCost: number;
}

export interface NewEligibilityGroupPostContract {
	calculatorSettings: EligibilityGroupCalculatorSettings;
	class: ClassCodeDetails;
	coverageCosts: CoverageCostPostContract[];
	filters: FiltersContract;
	lossOfEligibility?: LossOfEligibilityContract;
	specificEmployeeIds: number[];
	timeToEligibility: TimeToEligibilityContract;
}

export interface ExistingEligibilityGroupPostContract extends NewEligibilityGroupPostContract {
	id: number;
}

export interface PlanCalculatorSettings {
	ageBands: AgeBand[];
}

export interface PlanWizardPostContract {
	additionalPlanUrls: AdditionalPlanUrlsContract[];
	parentBenefitPlanId?: string;
	id?: number;
	type: PlanTypeEnumContract;
	name: string;
	planDates: {
		startYmd: string | null;
		endYmd: string | null;
	};
	description: string | null;
	summary: string | null;
	url: string | null;
	deletedPlanFileIds: string[] | number[];
	duplicatePlanFileIds: string[];
	temporaryPlanFileIds: string[] | number[];
	employeeRequiredQuestionIds: string[];
	dependentRequiredQuestionIds: string[];
	carrierPlanId: string | null;
	groupNumber: string | null;
	planTypeData: {
		aca: AcaValuesContract;
		autoEnrollSetting: AutoEnrollTypeEnum | null;
		carrierId: string | null;
		coverageLevels: CoverageLevelsPostContract;
		coverageRelationships?: string[];
		deductionDateRule: DeductionDateRuleContract;
		deductionFrequencies: DeductionFrequencyContract[];
		deductionTypeId: DeductionTypeEnumContract | null;
		isVariable: boolean | null;
		planCategoryDataOptions: PlanCategoryDataOptionsContract;
		rateType: PlanRateTypeEnum;
		reimbursement: ReimbursementContract;
		reportableOnW2: boolean | null;
		requiredPlanIds: number[] | null;
		safeHarbor: boolean | null;
	};
	eligibilityGroups: {
		existing: ExistingEligibilityGroupPostContract[];
		new: NewEligibilityGroupPostContract[];
	};
	legalText: string | null;
}

export enum LifeCoverageEnum {
	fixed = 'fixed_amount',
	salary = 'salary_based',
	other = 'other',
	voluntary = 'voluntary_life',
}

export enum PersonCoveredEnum {
	child = 'child',
	employee = 'employee',
	spouse = 'spouse',
}

export type CoverageAmountInput = string | number | null;

export { CostTypeEnumContract, DeductionFrequencyEnumContract, PayFrequencyEnumContract };
