import { cloneDeep } from 'lodash';

import { EmployeeFieldData } from '../types';

const USD_CURRENCY_PICKER_OPTION = {
	code: 'USD',
	decodedSymbol: '$',
	id: 1,
	name: 'US Dollar',
	symbol: '$',
	symbolPosition: 0,
};

export function formatEmployeeFieldDataFromGet(data: EmployeeFieldData): EmployeeFieldData {
	const clonedData = cloneDeep(data);
	const { payRate, overtimeRate } = clonedData.fields;

	if (payRate && typeof payRate === 'string') {
		clonedData.fields.payRate = removePotentialMiscCurrencyCharacters(payRate);
	}

	if (overtimeRate && typeof overtimeRate === 'string') {
		clonedData.fields.overtimeRate = removePotentialMiscCurrencyCharacters(overtimeRate);
	}

	return clonedData;
}

export function getCurrencyFieldValue(amount: string) {
	return {
		amount,
		currencyCode: 'USD',
	};
}

export function getCurrencyItems() {
	return Promise.resolve([USD_CURRENCY_PICKER_OPTION]);
}

export function removePotentialMiscCurrencyCharacters(value: string) {
	return value.replace('$', '').replace('USD', '').trim();
}
