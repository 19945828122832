import Ajax from '@utils/ajax';
import { EligibleEmployeeRequestContract } from '../../types';

export function getEligibleEmployees(planId: number): Promise<any> {
	return Ajax.get(`/settings/benefits/employee/enrollment_status/eligible_manually/${ planId }`);
}

export function saveEligibleEmployees(planId: number, employees: EligibleEmployeeRequestContract[]): Promise<any> {
	return Ajax.post(`/settings/benefits/employee/enrollment_status/eligible_manually/${ planId }`, [...employees], {
		headers: { Accept: 'application/json' }
	});
}
