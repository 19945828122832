import Ctrl, {
	onReady,
	onPjaxEnd
} from 'ctrl.deco';

import { render } from 'base/wrapped-render';
import { getJsonData } from 'Data.util';

import CandidateSources from './component';

const APP_ELEM = 'settings-sources-app';

@Ctrl('/settings/jobs/sources')
export default class SettingsSourcesTemplates {
	constructor() {
		this._pageData = getJsonData('#js-sources', true);
	}

	@onReady()
	_readyHandler(e, ctrl) {
		ctrl.renderSourcesPage(ctrl._pageData);
	}

	@onPjaxEnd()
	_pjaxEndHandler(e, ctrl) {
		window.requestAnimationFrame(() => {
			const data = getJsonData('#js-sources', true);
			ctrl.renderSourcesPage(data);
		});
	}

	renderSourcesPage(pageData) {
		render(
			<CandidateSources { ...pageData } />,
			document.getElementById(APP_ELEM)
		);
	}
}
