export const L_CANCEL = $.__('Cancel');
export const L_COMPANY_TAX_SETTINGS = $.__('Company Tax Settings');
export const L_FEDERAL_SECTION_LABEL = $.__('Federal');
export const L_FEDERAL_1099_PRINT_RADIO_OPTION_LABEL = $.__("How will you file 1099's for contractors?");
export const L_FEDERAL_1099_PRINT_RADIO_OPTION_SELF_SERVICE = $.__('I will file, print, and mail my own 1099-NECs');
export const L_FEDERAL_1099_PRINT_RADIO_OPTION_FULL_SERVICE = $.__('BambooHR files, prints, and mails all of my contractors 1099-NECs');
export const L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_LABEL = $.__('Display employer-sponsored health coverage (box 12DD) on W-2s?');
export const L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_HIDE = $.__('Hide employer-sponsored health coverage on W-2s');
export const L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_SHOW = $.__('Show employer-sponsored health coverage on W-2s');
export const L_FEDERAL_W2_BOX_12DD_RADIO_OPTION_SHOW_SUBTEXT = $.__('Since you have 250+ employees, you are legally required to show this information');
export function L_FUNC_MODAL_FOOTER_MESSAGE_INFO_SUB_TEXT(month: string, day: string) {
	// Any changes made after Dec 31 will only be applied to future documents.
	return $.__('Any changes made after %1$s %2$s will only be applied to future documents.', month, day);
}
export function L_FUNC_MODAL_FOOTER_MESSAGE_INFO_TEXT(month: string, day: string, year: string) {
	// December 31st Is the Deadline to Update 2023 W-2s and 1099-NECs.
	return $.__('%1$s %2$s Is the Deadline to Update %3$s W-2s and 1099-NECs.', month, day, year);
}
export function L_FUNC_MODAL_FOOTER_MESSAGE_INFO_TEXT2({
	day,
	dayOrdinal,
	month,
	shortMonth,
	year,
}: {
	day: string;
	dayOrdinal: string;
	month: string;
	shortMonth: string;
	year: string;
}) {
	// December 31st is the deadline to update 2023 W2s and 1099s. Any changes made after Dec 31 will only be applied to future documents.
	return $.__(
		'%1$s %2$s is the deadline to update %3$s W2s and 1099s. Any changes made after %4$s %5$s will only be applied to future documents',
		month, dayOrdinal, year, shortMonth, day
	);
}
export const L_GO_BACK_TO_TAXES = $.__('Go Back To Taxes');
export const L_MODAL_FOOTER_MESSAGE_LINK_TEXT = $.__('To correct any of these settings for the previous year, please file a correction request by following the steps [here].');
export const L_MODAL_ERROR_MESSAGE_BODY_TEXT = $.__('Try again later or contact [support] if the issue persists.');
export const L_MODAL_ERROR_MESSAGE_HEADER_TEXT = $.__('Unable to load Company Tax Settings');
export const L_STATE_SECTION_LABEL = $.__('State');
export const L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_LABEL = $.__('Collect local Ohio courtesy tax for my employees?');
export const L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_COLLECT = $.__('Yes, collect Ohio courtesy tax based on employees location');
export const L_STATE_OHIO_COURTESY_TAX_RADIO_OPTION_DO_NOT_COLLECT = $.__('No, do not collect Ohio courtesy tax based on employees location');
export const L_SAVE = $.__('Save');
export const L_SAVE_ERROR = $.__('Shoot, we couldn’t save those changes. Try again or reach out to support.');
export const L_SAVE_SUCCESS = $.__('Those changes have been saved, your future self thanks you.');
