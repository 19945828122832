import { Button, DatePickerRange, Flex, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { useEffect, useState } from 'react';
import moment from 'moment.lib';

export const InvoiceDateRangePicker = ({ endDate, firstInvoiceDate, onDateChange, startDate }) => {
	const [viewMode, setViewMode] = useState(true);
	const [localEndDate, setLocalEndDate] = useState(endDate);
	const [localStartDate, setLocalStartDate] = useState(startDate);

	const showActions =
		(moment(endDate) && !moment(endDate).isSame(localEndDate, 'days')) ||
		(moment(startDate) && !moment(startDate).isSame(localStartDate, 'days'));

	const handleActionButtonClick = () => {
		onDateChange({ endValue: localEndDate, startValue: localStartDate });
	};

	const handleLocalDateChange = ({ endValue, startValue }) => {
		setLocalEndDate(endValue ? endValue : null);
		setLocalStartDate(startValue ? startValue : null);
	};

	const handleCancelButtonClick = () =>
		handleLocalDateChange({
			endValue: endDate,
			startValue: startDate,
		});

	const handleClose = () => setViewMode(true);
	const handleFocus = () => setViewMode(false);

	useEffect(() => {
		setLocalEndDate(endDate);
		setLocalStartDate(startDate);
	}, [endDate, startDate]);

	const isDisabled = !localStartDate || !localEndDate;

	return (
		<Flex alignItems={ifFeature('encore', 'flex-end')} flex={1}>
			<DatePickerRange
				endInputProps={{ onFocus: handleFocus }}
				endLabel={ifFeature('encore', $.__('To'), null)}
				endValue={localEndDate}
				id='invoice-date-range'
				minDate={firstInvoiceDate}
				onChange={handleLocalDateChange}
				onClose={handleClose}
				startInputProps={{ onFocus: handleFocus }}
				startLabel={ifFeature('encore', $.__('From'), null)}
				startValue={localStartDate}
				viewMode={viewMode}
			/>
			{showActions && (
				<Flex gap={ifFeature('encore', 1.5)} marginLeft={2}>
					<Button disabled={isDisabled} onClick={handleActionButtonClick} type='button'>
						{$.__('Apply')}
					</Button>
					<TextButton onClick={handleCancelButtonClick} type='button'>
						{$.__('Cancel')}
					</TextButton>
				</Flex>
			)}
		</Flex>
	);
};

InvoiceDateRangePicker.defaultProps = {
	endDate: moment().utc().format('YYYY-MM-DD'),
	firstInvoiceDate: null,
	onDateChange: () => {},
	startDate: moment().utc().startOf('year').format('YYYY-MM-DD'),
};
