import { Select } from '@fabric/select';

export function DropdownField(props) {
	const {
		error,
		disabled,
		items,
		name,
		placeholder,
		renderOptionContent,
		renderToggleContent,
		updateFormData,
		value,
		width,
	} = props;

	function handleOnChange(selection) {
		updateFormData(name, { error: false, value: selection[0] });
	}

	return (
		<Select
			condition={ error ? 'error' : '' }
			isDisabled={ disabled }
			items={ items }
			name={ name }
			onChange={ handleOnChange }
			placeholder={ disabled ? placeholder : `-${ $.__('Select') }-` }
			renderOptionContent={ renderOptionContent }
			renderToggleContent={ renderToggleContent }
			selectedValues={ [value] }
			width={ width || 5 }
		/>
	);
}
