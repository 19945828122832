import Ctrl, {
	onReady,
	onFooterAction
} from 'ctrl.deco';
import { addListeners } from 'hiring/manage-files';
import { initializeStickySidebar } from 'hiring/scrolling';
import Placeholders from 'Placeholders.mod';
import { getJsonData } from 'Data.util';
import setupEditor from '../setup-editor';

@Ctrl('/settings/jobs/templates.php/emailTemplate')
class EditEmailTemplates {
	constructor() {
		window.Placeholders = Placeholders;
		this.templateData = getJsonData('#templateData');
		const PlaceholdersData = this.templateData.placeholders;
		Placeholders.Generator.generate(PlaceholdersData);
		Placeholders.Widget.create(PlaceholdersData, Placeholders.Generator.get());

		initializeStickySidebar('.PlaceholderWidget', '.PlaceholderWidget__scroll', '.PlaceholderWidget__psWrapper');
		addListeners();

		$('#main_form').bhrValidate();
		this.validationRules = {
			rules: {
				'message': {
					bambooRichText: true,
				},
				'subject': {
					bambooRichText: true,
				},
			}
		};
	}

	@onFooterAction('primary')
	_onPrimaryFooterAction() {
		const tinyMCE = window.tinymce;
		const $form = $('#ATSEmailTemplateForm');
		const formValid = $form.valid();
		if (formValid) {
			tinyMCE.triggerSave();
			$form.submit();
		}
	}

	@onReady()
	init(e, ctrl) {
		ctrl._setupEmail();

		// Prevent drag and drop into template name, in firefox it just puts in the data of the image
		$('.js-template-name').on('drop', function(e) {
			e.preventDefault();
		});

		$('#ATSEmailTemplateForm').bhrValidate(ctrl.validationRules,$.__('Whoops... There were some errors with your form. Please fix the highlighted fields.'));
		window.enableFormChangeTracking(document.getElementById('ATSEmailTemplateForm'));
	}

	_setupEmail = () => {
		const {
			message,
			isApplicationConfirmationTemplate
		} = this.templateData;

		const tinyMCE = window.tinymce;
		if (tinyMCE.initialized) {
			tinyMCE.remove();
			this.Placeholders.Widget.destroy();
		}

		const initSelector = '#js-mce-message';
		const inputSelector = '#js-mce-message-input';
		const toolbarSelector = '#js-mce-toolbar';
		const subjectSelector = '#subject';
		const customOptions = {
			valid_elements: 'a[href|target=_blank],span[style],font[face|size],#p[style],br,strong/b,em/i,ul[start|style],ol[start|style],li',
			toolbar1: 'bold italic underline forecolor | bullist numlist | link unlink',
			paste_retain_style_properties: 'color font-style font-weight text-decoration',
		};
		if (isApplicationConfirmationTemplate) {
			customOptions.valid_elements = `${ customOptions.valid_elements },img[!data-is-placeholder|class<placeholder|src|data*]`;
		} else {
			customOptions.toolbar1 = `${ customOptions.toolbar1 } embedimage`;
			customOptions.valid_elements = `${ customOptions.valid_elements },img[class<placeholder?embedded-image|width|height|src|alt|data*]`;
		}

		setupEditor(initSelector, inputSelector, toolbarSelector, subjectSelector, message, customOptions, true);
	}
}
