import {
	getSupplementalDeductionTypeForPostData,
} from './supplemental-deduction-type';
import { DecisionDataContract, DeductionTypeEnumContract } from '../types/contracts';
import {
	DeductionTypeValue,
	PreTaxDeductionTypeValue,
} from '../types/wizard-values';
import { PlanTypeEnumContract } from '../../types/plan-contracts';

export function getDeductionTypePostData(
	deductionTypeValue: DeductionTypeValue,
	preTaxDeductionTypeValue: PreTaxDeductionTypeValue,
	benefitType: PlanTypeEnumContract,
	selectedPlanType: string,
	decisionData: DecisionDataContract,
): DeductionTypeEnumContract | null {
	if (benefitType === PlanTypeEnumContract.supplemental) {
		return getSupplementalDeductionTypeForPostData(
			deductionTypeValue,
			decisionData.hasTrax,
			preTaxDeductionTypeValue,
			selectedPlanType
		);
	}

	if (deductionTypeValue !== '') {
		return deductionTypeValue;
	}

	if (preTaxDeductionTypeValue !== '') {
		return preTaxDeductionTypeValue;
	}

	return null;
}
