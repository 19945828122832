import {
	createContext,
	useState,
	useEffect,
	useCallback,
	ReactElement,
} from 'react';
import Ajax from '@utils/ajax';
import {
	showSlidedown,
} from 'Alerts.mod';

import {
	ActiveMissingInfoContextData,
	ActiveMissingInfoProviderProps,
	TableDataItem,
	GetTableDataResponse,
	GetStateDataResponse,
	SelectItem,
	MissingTaxInfoObject
} from '../types';

export const ActiveMissingInfoContext = createContext({});

function getResponseError(): void {
	showSlidedown($.__('Uh oh...there was a problem getting missing employee info. Please try again.'), 'error');
}


export function ActiveMissingInfoProvider(props: ActiveMissingInfoProviderProps): ReactElement {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasMissingData, setHasMissingData] = useState<boolean>(false);
	const [tableData, setTableData] = useState<TableDataItem[]>([]);
	const [states, setStates] = useState<SelectItem[]>([]);
	const [stillMissingTaxInfo, setStillMissingTaxInfo] = useState<MissingTaxInfoObject>({
		federalFilingStatus: false,
		stateTaxLocation: false,
		unemploymentTaxLocation: false,
	});
	const {
		companies,
		isMultiEin,
		children,
	} = props;

	const updateTableData = useCallback(() => {
		setIsLoading(true);
		Ajax.get('/settings/payroll/payroll_checklist/active_employees/table')
			.then((response: GetTableDataResponse) => {
				const {
					status,
					data,
				} = response;

				if (status === 200 && data) {
					const {
						hasMissingData: responseHasMissingData,
						tableData: responseTableData,
					} = data;

					setHasMissingData(responseHasMissingData);
					setTableData(responseTableData);
					setIsLoading(false);
				}
			})
			.catch(getResponseError);
	}, []);

	useEffect(updateTableData, []);
	useEffect(() => {
		Ajax.get(`/ajax/get_states?country=1`)
			.then((response: GetStateDataResponse) => {
				const {
					data: {
						data,
					},
				} = response;
				setStates(data.map(state => ({ text: state.name, value: state.id })));
			})
			.catch(getResponseError);
	}, []);

	const value: ActiveMissingInfoContextData = {
		companies,
		isMultiEin,
		isLoading: isLoading || !states.length,
		hasMissingData,
		tableData,
		updateTableData,
		states,
		stillMissingTaxInfo,
		setStillMissingTaxInfo
	};

	return (
		<ActiveMissingInfoContext.Provider value={ value }>
			{ children }
		</ActiveMissingInfoContext.Provider>
	);
}
