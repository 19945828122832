import React, {
	Fragment,
} from 'react';

import { BodyText, Headline, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { CompanyInfoFieldset } from '../company-info';
import { useReducerContext } from '../state';
import {
	useForm,
	FormInput,
	FormSelect,
} from '../form';

/** @type {React.FunctionComponent} */
export default function ModalContentEnableStep2() {
	const {
		isBambooHrPayrollEnabled,
	} = useReducerContext();

	return (
		<Fragment>
			{ isBambooHrPayrollEnabled ? <PayrollModalContent /> : (
				<LayoutBox marginBottom={ifFeature('encore', 2.5, 0)}>
					<CompanyInfoFieldset
						FormInput={ FormInput }
						FormSelect={ FormSelect }
						companyNameField="nameOfEmployer"
						useForm={ useForm }
					/>
				</LayoutBox>
			) }
		</Fragment>
	);
}

function PayrollModalContent() {
	return (
		<Fragment>
			<LayoutBox marginBottom={2}>
				<Headline
					color='primary'
					size='medium'
				>
					{$.__('Enter Company Information') }
				</Headline>
			</LayoutBox>
			<BodyText color='neutral-strong' component='p' id='aca-step-2-text' size='medium' weight='regular'>
				{ $.__('Since you\'re using the Payroll feature, we will use the company information that you\'ve set up in Payroll settings to populate the employer section of each employee\'s 1095-C.') }
			</BodyText>
		</Fragment>
	);
}
