import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default makeStyles(({ spacing }) => {
	return {
		videoPlayer: {
			height: ifFeature('encore', '394px', '366px'),
			margin: `${spacing(4)} auto 0`,
			width: ifFeature('encore', '700px', '653px'),
		},
	};
});
