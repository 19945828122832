import { IconButton, Tooltip, makeStyles } from '@bamboohr/fabric';
import { Lock28x34, PadlockUnlock28x36 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import Ajax from '@utils/ajax';
import { showSlidedown } from 'Alerts.mod';
import { useRef, useState } from 'react';

export function setTaxFrequencyLock(locked, clientId, refreshTable) {
	const successMessage = locked
		? $.__('Federal Taxes are now locked so the customer cannot make any edits.')
		: $.__('Federal Taxes are now unlocked so the customer can make a one-time change.');

	const errorMessage = $.__('Uh oh... something went wrong. Please try to refresh your page.');

	// Want slidedowns to be called after the table is refreshed to keep the UX smooth
	let slidedownState = 'success';

	return Ajax.put(`taxes/federal_tax_frequency_locked/${clientId}`, { locked })
		.catch(() => {
			slidedownState = 'error';
		})
		.then(refreshTable)
		.finally(() => showSlidedown(slidedownState === 'success' ? successMessage : errorMessage, slidedownState));
}

const useStyles = makeStyles(() => {
	return {
		tooltip: {
			maxWidth: '11rem',
			zIndex: '10', // Fixes table overlap when unhovering
		},
	};
});

export function LockAction({ isLocked, clientId, openUnlockModal, reloadTable }) {
	const buttonRef = useRef(null);
	const styles = useStyles();
	const [processing, setProcessing] = useState(false);

	const getIcon = () => {
		if (isLocked) {
			return ifFeature('encore', 'lock-solid', <Lock28x34 height='16px' width='15px' />)
		} 
		
		return ifFeature('encore', 'lock-open-solid', <PadlockUnlock28x36 height='16px' width='15px' />)
	}

	return (
		<div className='TaxesTable__gearDropdown'>
			<Tooltip
				anchorEl={() => buttonRef.current}
				classes={{ tooltip: styles.tooltip }}
				content={
					isLocked
						? $.__('Allow a customer to make a one time edit.')
						: $.__('You can lock this to prevent the customer from making changes.')
				}
				placement='bottom'
			/>

			<IconButton
				color='secondary'
				floatingIcon={true}
				icon={getIcon()}
				noSpacing={true}
				onClick={
					isLocked
						? (e) => {
								e.currentTarget.blur(); //prevent tooltip from being stuck open after modal close
								openUnlockModal();
						  }
						: () => {
								setProcessing(true);
								setTaxFrequencyLock(!isLocked, clientId, () => reloadTable().then(() => setProcessing(false)));
						  }
				}
				processing={processing}
				ref={buttonRef}
				type='button'
			/>
		</div>
	);
}
