import { isEnabled } from '@bamboohr/utils/lib/feature';

import { render } from 'base/wrapped-render';
import Ctrl, { onReady } from 'ctrl.deco';
import { getJsonData } from 'Data.util';

import { ChangeOwner } from './change-owner';

function parseUserOwnerData(users: Record<string, string>): { text: string; value: string }[] {
	try {
		const keys = Object.keys(users);
		return keys.map((key) => {
			return { text: users[key], value: key };
		});
	} catch (e) {
		return [] as { text: string; value: string }[];
	}
}

@Ctrl('/settings/account/edit_owner.php')
class SettingsAccountChangeOwnerCtrl {
	@onReady()
	ready() {
		if (!isEnabled('encore')) {
			return;
		}
		const potentialOwnerUsers = (getJsonData('#js-newOwnerUsers') || '{}') as Record<string, string>;
		const targetElement = document.getElementById('js-ownerActionList');

		if (targetElement) {
			render(<ChangeOwner users={parseUserOwnerData(potentialOwnerUsers)} />, targetElement);
		}
	}
}
