import Ajax from '@utils/ajax';
import { showSlidedown } from 'Alerts.mod';

// Interfaces
export interface LinkPlaidStateProps {
	plaidLinkToken: string;
	handleLinkSuccess(): void;
	clientId: number;
	toggleBankInfoLoading(loading: boolean): void;
	onAddManually(): void;
}
export interface PlaidMetadata {
	account_id: string;
}

// Constants
export const POPOVER_SETTINGS = {
	position: 'bottom',
	template: {
		name: 'popover-standard',
		data: {
			content: $.__(
				'Please email our support team at payrollimp@bamboohr.com and they will be happy to help you get your bank account set up.'
			),
			title: $.__('No Problem!'),
		},
	},
};

const PLAID_FAIL_MSG = $.__('Uh oh...something went wrong linking your account to Plaid. Please try again later.');

// Functions
function showPlaidFailedMessage(): void {
	showSlidedown(PLAID_FAIL_MSG, 'error');
}
export function handlePlaidLinkSuccess(
	token: string,
	metadata: PlaidMetadata,
	clientId: number,
	successCallback: Function,
	toggleBankInfoLoading: Function
): void {
	toggleBankInfoLoading(true);
	const { account_id } = metadata;
	Ajax.post('/settings/payroll/bank_account/plaid', {
		clientId,
		plaidToken: token,
		plaidAccountId: account_id,
	})
		.then((response) => {
			if (response.status === 200) {
				successCallback();
			} else {
				showPlaidFailedMessage();
				toggleBankInfoLoading(false);
			}
		})
		.catch(() => {
			showPlaidFailedMessage();
			toggleBankInfoLoading(false);
		});
}
