import { ItemType } from '@bamboohr/fabric';

import { LossOfEligibilityLabels } from './loss-of-eligibility-labels';
import { LossOfEligibilityValues } from './loss-of-eligibility-values';

export function getLossOfEligibilityOptions(): ItemType[] {
	return Object.values(LossOfEligibilityValues).map((value: string) => {
		return {
			text: LossOfEligibilityLabels[value],
			value: value,
		};
	});
}
