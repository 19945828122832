import { Table } from '@bamboohr/fabric';

import './multi-ein-banks-table.styl';

import { getColumns, getGroupsAndRows } from './utils';

export function MultiEinBanksTable(props) {
	const { companies, handleOpenUploadModal, handleOpenDownloadModal, handleOpenVerificationModal, plaidLinkToken, handleLinkSuccess } =
		props;

	const { groups, rows } = getGroupsAndRows(companies);

	return (
		<div className='MultiEinBanksTable'>
			<Table
				caption={$.__('Bank accounts for multiple EINs')}
				columns={getColumns(
					handleOpenDownloadModal,
					handleOpenUploadModal,
					handleOpenVerificationModal,
					plaidLinkToken,
					handleLinkSuccess
				)}
				groups={groups}
				rowKey={(row) => row.bankId || row.clientId}
				rows={rows}
			/>
		</div>
	);
}
