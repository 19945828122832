import {
	BodyText,
	Dropdown,
	DropdownTypes,
	Flex,
	Headline,
	InPageMessaging,
	LayoutBox,
	makeStyles,
	Select,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { isEnabled as isFeatureEnabled } from 'FeatureToggle.util';

import { PLAN_FILTER_LABEL, PLAN_TYPE_DROPDOWN_LABEL } from '../constants';
import { PlanFilter } from '../types/plan-filter';
import { FEATURE_TOGGLE_KEYS } from '../../etc/feature-toggle';
import { PlanTypeEnumContract } from '../../types';
/* @startCleanup encore */
// eslint-disable-next-line no-duplicate-imports
import { Typography } from '@bamboohr/fabric';
/* @endCleanup encore */

interface BenefitPlansHeaderProps {
	benefitPlansMissingDetailsCount: number;
	hasPlans: boolean;
	onFilterChange: (value: PlanFilter) => void;
	onNewTypeSelect: (type: PlanTypeEnumContract) => void;
	planFilter: PlanFilter;
}

/* @startCleanup encore */
const rowStyles = {
	alignItems: 'center',
	display: 'flex',
};
const useStyles = makeStyles(({ palette }) => ({
	root: {
		borderBottom: `1px solid ${palette.gray[400]}`,
	},
	actions: {
		...rowStyles,
		margin: '16px 0',
	},
	carriers: {
		marginLeft: '12px',
	},
	filter: {
		marginLeft: '7px',
	},
	spacer: {
		flex: '1 1 auto',
	},
}));
/* @endCleanup encore */

export function BenefitPlansHeader(props: BenefitPlansHeaderProps) {
	const { benefitPlansMissingDetailsCount, hasPlans, onFilterChange, onNewTypeSelect, planFilter } = props;
	/* @startCleanup encore */
	const classes = useStyles();
	/* @endCleanup encore */

	const newPlanDropdownItems = Object.values(PlanTypeEnumContract).map((type) => ({
		text: PLAN_TYPE_DROPDOWN_LABEL[type],
		value: type,
	}));

	const dropdownBiId = 'add-new-plan';

	const filter = (
		<Select
			biId="plan-filter"
			isClearable={false}
			items={[
				{ text: PLAN_FILTER_LABEL[PlanFilter.Active], value: PlanFilter.Active },
				{ text: PLAN_FILTER_LABEL[PlanFilter.Inactive], value: PlanFilter.Inactive },
				{ text: PLAN_FILTER_LABEL[PlanFilter.All], value: PlanFilter.All },
			]}
			onChange={(selectedValues: any[]) => onFilterChange(selectedValues[0])}
			selectedValues={[planFilter]}
			size={ifFeature('encore', 'small', 'medium')}
			variant={ifFeature('encore', 'single')}
			width={ifFeature('encore', 6, 5)}
		/>
	);

	/* @startCleanup encore */
	const jadeHeader = (
		<div className={classes.root}>
			<Typography variant="h4">{$.__('Benefit Plans')}</Typography>
			<div className={classes.actions}>
				<Dropdown
					biId={dropdownBiId}
					buttonSettings={{
						outline: hasPlans,
						size: 'large',
						text: $.__('Add New Plan'),
						type: 'button',
					}}
					items={newPlanDropdownItems}
					onSelect={(selected) => onNewTypeSelect(selected)}
				/>
				<div className={classes.spacer}></div>
				<BodyText size="medium">{$.__('Showing')}</BodyText>
				<div className={classes.filter}>{filter}</div>
			</div>
		</div>
	);
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<>
			<Headline size="small">{$.__('Plans')}</Headline>
				{isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_HOME_PAGE) && benefitPlansMissingDetailsCount > 0 && (
					<LayoutBox marginBottom={3} marginTop={2}>
						<InPageMessaging
							body={$.__n(
								'This plan will need to be updated before you can continue managing employee enrollments.',
								'These plans will need to be updated before you can continue managing employee enrollments.',
								benefitPlansMissingDetailsCount,
							)}
							header={$.__n(
								'%s plan is missing required details based on updated plan settings.',
								'%s plans are missing required details based on updated plan settings.',
								benefitPlansMissingDetailsCount,
							)}
							type="error"
						/>
					</LayoutBox>
				)}
			<Flex alignItems="flex-end" justifyContent="space-between" marginY={2}>
				<Dropdown
					ButtonProps={{
						color: 'secondary',
						size: 'small',
						startIcon: 'circle-plus-regular',
						variant: 'outlined',
					}}
					biId={dropdownBiId}
					items={newPlanDropdownItems}
					onSelect={(selected) => onNewTypeSelect(selected)}
					type={DropdownTypes.BUTTON}
				>
					{$.__('Create New Plan')}
				</Dropdown>
				<Flex alignItems="center" gap={1}>
					<BodyText size="small">{$.__('Showing')}</BodyText>
					{filter}
				</Flex>
			</Flex>
		</>,
		jadeHeader,
	);
}
