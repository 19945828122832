import { Flex, makeStyles, TextButton, Theme } from '@bamboohr/fabric';
import { SpeechBubbleCheck16x16, SpeechBubbleX16x16 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface StyleProps {
	show: boolean;
	isQuestionEnabled: boolean;
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
	textButton: ({ show, isQuestionEnabled }: StyleProps) => {
		const visibility = show
			? {
					'&:hover': {
						cursor: 'pointer',
					},
			  }
			: {
					opacity: 0,
					flexShrink: 0,
					'&:hover': {
						cursor: 'unset',
					},
			  };
		const iconStyles = isQuestionEnabled
			? {
					fill: ifFeature('encore', undefined, palette.gray[800]),
			  }
			: {};
		return {
			...visibility,
			...iconStyles,
		};
	},
}));

interface ActionProps {
	neverAsk: () => void;
	enable: () => void;
	showActionButton: boolean;
	isQuestionEnabled: boolean;
	hovering: boolean;
}

const Action: React.FC<ActionProps> = ({ neverAsk, enable, showActionButton, isQuestionEnabled, hovering }) => {
	const show = hovering && showActionButton;
	const classes = useStyles({ show, isQuestionEnabled });
	const onClick: undefined | (() => void) = (() => {
		if (!show) {
			return;
		}
		if (isQuestionEnabled) {
			return neverAsk;
		}
		return enable;
	})();
	return (
		<Flex justifyContent='flex-end' width={ifFeature('encore', undefined, '150px')}>
			<TextButton
				className={classes.textButton}
				onClick={onClick}
				startIcon={
					isQuestionEnabled
						? ifFeature('encore', 'comment-xmark-solid', <SpeechBubbleX16x16 />)
						: ifFeature('encore', 'comment-check-solid', <SpeechBubbleCheck16x16 />)
				}
				type='button'
			>
				{isQuestionEnabled ? ifFeature('encore', $.__('Never ask this question'), '') : $.__('Enable Question')}
			</TextButton>
		</Flex>
	);
};

export default Action;
