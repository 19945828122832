import {
	Button,
	Checkbox,
	Icon,
	TextButton,
} from '@bamboohr/fabric';
import {
	useLayoutEffect,
	useState,
} from 'react';

import {
	PageFooter,
} from 'page-footer.react';

import {
	useStyles,
} from './migration-section-time-off.styles';
import {
	HelpButton,
} from '../help-button';
import {
	CLOCK_ICON,
} from '../migration-section-head/migration-section-head-utils';
import {
	MigrationSectionTimeOffProps,
} from '../../utils/interfaces';

export function MigrationSectionTimeOff(props: MigrationSectionTimeOffProps): JSX.Element {
	const {
		onContinue,
		onExit,
	} = props;

	const styles = useStyles();
	const [isChecked, setIsChecked] = useState(false);

	useLayoutEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className={ styles.root }>
			<div className={ styles.header }>
				<Icon
					brand={ true }
					name={ CLOCK_ICON }
				/>
				<h2 className={ styles.headerText }>
					{ $.__('Time Off') }
				</h2>
			</div>
			<div className={ styles.body }>
				<div>
					<div className={ styles.settingsHeaderContainer }>
						<h4 className={ styles.settingsHeader }>
							{ $.__(`Let's Work On Time Off!`) }
						</h4>
						<HelpButton />
					</div>
					<p className={ styles.paragraphBody }>
						{ $.__(`If you haven't set up time off in BambooHR already, you'll need to do that first; otherwise, you can jump right in.`) }
					</p>
					<a
						className={ styles.link }
						href="/settings/pto"
						rel="noopener noreferrer"
						target="_blank"
					>
						{ $.__('Go to Time Off in Settings') }
					</a>
				</div>
				<div>
					<h4 className={ styles.trainingHeader }>
						{ $.__('Time Off Training Course') }
					</h4>
					<p className={ styles.paragraphBody }>
						{ $.__(`We've made a Northpass training course to refresh your memory about how time off types work and how you can set them up.`) }
						{ $.__(` The course will walk you through step by step and will take about 10 to 15 minutes to complete.`) }
					</p>
					<a
						className={ styles.link }
						href="https://bamboohr.formstack.com/forms/time_off_training_northpass_terms_of_service?Redirect_URL=https://learning.bamboohr.com/c/80d689196af51a585ad708029c8e1880df0a8062"
						rel="noopener external nofollow noreferrer"
						target="_blank"
					>
						{ $.__('Start Time Off Type Training Course') }
					</a>
				</div>
				<div className={ styles.checkboxContainer }>
					<Checkbox
						checked={ isChecked }
						id="migration-tasks-time-off-section-checkbox-id"
						label={ $.__('My time off types are all set up. Onward!') }
						onChange={ ({ checked }) => setIsChecked(checked) }
						value="migration-tasks-time-off-section-checkbox"
					/>
				</div>
			</div>
			<div className={ styles.footer }>
				<PageFooter
					left={ (
						<div>
							<Button
								clickAction={ onContinue }
								isDisabled={ !isChecked }
								text={ $.__('Continue') }
								type="button"
							/>
							<TextButton
								clickAction={ onExit }
								text={ $.__('Save & Exit') }
								type="button"
							/>
						</div>
					) }
				/>
			</div>
		</div>
	);
}
