import {
	getJwtAuthToken,
} from './get-jwt-auth-token';

export function jwtInteceptor(config) {
	delete config.headers['X-CSRF-TOKEN'];
	return getJwtAuthToken().then((authToken) => {
		const bearerToken = `Bearer ${ authToken }`;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		config.headers = {
			...config.headers,
			Authorization: bearerToken,
		};
		return config;
	});
}
