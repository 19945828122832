import { Flex } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { useEffect, useState } from 'react';
import merge from 'lodash/merge';
import { BlankStateError, InvoiceHistoryHeader, InvoiceHistoryBody, InvoiceHistoryTable, InvoiceHistoryLoader } from './components';
import { filterInvoices, getDates, getPreselectedInvoices } from './helpers';
import { api } from './services';

const InvoiceHistory = ({ selectedInvoices }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [allInvoices, setAllInvoices] = useState([]);
	const [displayInvoices, setDisplayInvoices] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);

	const [datePickerState, setDatePickerState] = useState({
		endDate: null,
		firstInvoiceDate: null,
		startDate: null,
	});

	const mergeDatePickerState = (updatedState) => {
		setDatePickerState((prevState) => ({ ...merge(prevState, updatedState) }));
	};

	const filterByDateRange = (collection) => {
		return filterInvoices({ collection, ...datePickerState });
	};

	const handleDateChange = ({ endValue, startValue }) => {
		mergeDatePickerState({
			endDate: endValue,
			startDate: startValue,
		});
	};

	const handleRowSelect = (keysChanged, selected) => {
		const selectedInvoices = [...displayInvoices];

		keysChanged.forEach((id) => {
			selectedInvoices.find((inv) => inv.id === id).selected = selected;
		});

		const currentlySelected = selectedInvoices.filter((inv) => inv.selected === true);

		setSelectedItems(currentlySelected);
		setDisplayInvoices(selectedInvoices);
	};

	useEffect(() => {
		api.invoices.allInvoices
			.get()
			.then((response) => {
				const invoices = response;
				const preSelectedInvoices = getPreselectedInvoices(selectedInvoices, invoices);

				// Handle pre-selected
				if (preSelectedInvoices) {
					const { updatedInvoices, ...dates } = preSelectedInvoices;
					mergeDatePickerState(dates);
					setAllInvoices(updatedInvoices);
				} else {
					mergeDatePickerState(getDates(invoices));
					setAllInvoices(invoices);
				}

				setIsLoading(false);
			})
			.catch((error) => {
				window.Rollbar.error('POSH: Unable to retrieve billing invoices', error);
				setIsError(true);
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		setDisplayInvoices(filterByDateRange(allInvoices));
	}, [allInvoices, datePickerState]);

	return ifFeature(
		'encore',
		<Flex flexDirection='column' flexGrow={1} marginBottom={5}>
			<InvoiceHistoryHeader onDateChange={handleDateChange} selectedInvoices={selectedItems} {...datePickerState} />
			<InvoiceHistoryBody
				isLoading={isLoading}
				isError={isError}
				onDateChange={handleDateChange}
				onRowSelect={handleRowSelect}
				selectedInvoices={selectedItems}
				rows={displayInvoices}
				{...datePickerState}
			/>
		</Flex>,
		<>
			<InvoiceHistoryHeader onDateChange={handleDateChange} selectedInvoices={selectedItems} {...datePickerState} />
			{isLoading && <InvoiceHistoryLoader />}
			{!isLoading && !isError && <InvoiceHistoryTable onRowSelect={handleRowSelect} rows={displayInvoices} />}
			{isError && <BlankStateError />}
		</>
	);
};

InvoiceHistory.defaultProps = {
	selectedInvoices: [],
};

export default InvoiceHistory;
