import uniqueId from 'lodash/uniqueId';

import { getInitialCalculatorSettings } from './get-initial-calculator-settings';

import {
	getInitialClassCodes,
	getInitialCoverageCosts,
	getInitialEligibilityFiltersFromSavedGroup,
	getInitialLossOfEligibility,
	getInitialTimeToEligibility,
} from './initialization';
import {
	CoverageLevelValue,
	EligibilityGroupGetContract,
	EligibilityGroupValues,
	NewEligibilityGroupGetContract,
	PlanWizardGetContract,
} from '../types';

export const getInitialEligibilityGroups = (
	wizardContract: PlanWizardGetContract,
	coverageLevels: CoverageLevelValue[],
): EligibilityGroupValues[] => {
	const { benefitPlan } = wizardContract;
	if (benefitPlan?.eligibilityGroups) {
		if (benefitPlan.eligibilityGroups.existing.length > 0) {
			const existingEligibilityGroups = benefitPlan.eligibilityGroups.existing;
			return existingEligibilityGroups.map((group: EligibilityGroupGetContract) => ({
				id: group.id,
				class: getInitialClassCodes(group.class),
				eligibilityFilters: getInitialEligibilityFiltersFromSavedGroup(group),
				lossOfEligibility: getInitialLossOfEligibility(group.lossOfEligibility),
				timeToEligibility: getInitialTimeToEligibility(group.timeToEligibility),
				coverageCosts: getInitialCoverageCosts(group.coverageCosts, coverageLevels),
				calculatorSettings: getInitialCalculatorSettings(benefitPlan?.planTypeData.rateType, group.calculatorSettings),
			}));
		}

		if (benefitPlan.eligibilityGroups.new && benefitPlan.eligibilityGroups.new.length > 0) {
			const newEligibilityGroups = benefitPlan.eligibilityGroups.new;
			return newEligibilityGroups.map((group: NewEligibilityGroupGetContract) => ({
				id: uniqueId('new_'),
				class: getInitialClassCodes(group.class),
				eligibilityFilters: getInitialEligibilityFiltersFromSavedGroup(group),
				lossOfEligibility: getInitialLossOfEligibility(group.lossOfEligibility),
				timeToEligibility: getInitialTimeToEligibility(group.timeToEligibility),
				coverageCosts: getInitialCoverageCosts(group.coverageCosts, coverageLevels),
				calculatorSettings: getInitialCalculatorSettings(benefitPlan?.planTypeData.rateType),
			}));
		}
	}

	return [];
};
