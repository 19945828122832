import './styles.styl';

export default function PlaceholderItem() {
	return (
		<div className="PlaceholderItem">
			<div className="PlaceholderItem__checkbox"></div>
			<div className="PlaceholderItem__content">
				<div className="PlaceholderItem__title"></div>
				<div className="PlaceholderItem__text"></div>
				<div className="PlaceholderItem__link"></div>
			</div>
		</div>
	);
}
