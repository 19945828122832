import React, { Fragment, useState } from 'react';

import { AcaStatusSelect } from 'aca-status-select.react';

export function AcaStatusRow(props) {
	const {
		acaTypes,
		alignment,
		onRowChange,
	} = props;
	const [selectedItem, setSelectedItem] = useState((alignment) ? alignment.acaStatus : null);

	const handleSelect = (newValue) => {
		setSelectedItem(newValue);
	};

	return (
		<Fragment>
			<AcaStatusSelect
				allowedAcaStatuses={ acaTypes }
				onChange={ onRowChange }
				onSelect={ handleSelect }
				selectedValue={ selectedItem }
			/>
		</Fragment>
	);
}
