import { EligibilityGroupCalculatorSettings, PlanRateTypeEnum } from '../types';

const STARTING_MAX_AGE = 15;

export const DEFAULT_AGE_BANDS = [
	{
		maxAge: STARTING_MAX_AGE,
		minAge: 0,
		rate: null,
	},
];

export const DEFAULT_CALCULATOR_SETTINGS: EligibilityGroupCalculatorSettings = {
	companyPays: null,
	currencyCode: null,
	costType: null,
	employeePays: null,
	rate: null,
	type: PlanRateTypeEnum.standard,
};
