import moment from 'moment.lib';

import { SelectItem } from 'form-fields.react';

import {
	DESCRIPTION_PLACEHOLDERS,
	NAME_PLACEHOLDERS,
	REIMBURSEMENT_FREQUENCY_LABELS,
} from './constants';
import {
	doesPlanHavePastPresentOrFutureEnrollments,
	hasPlanStarted,
	isIncompletePlan,
} from '../utils';
import {
	DeductionTypeValue,
	PlanEnrollments,
	PlanState,
	RateValue,
	ReimbursementFrequencyEnumContract,
} from '../types';
import { PlanTypeEnumContract } from '../../types/plan-contracts';

export const getNamePlaceholder = (planType: PlanTypeEnumContract): string => {
	return NAME_PLACEHOLDERS[planType] ? NAME_PLACEHOLDERS[planType] : '';
};

export const getDescriptionPlaceholder = (planType: PlanTypeEnumContract): string => {
	return DESCRIPTION_PLACEHOLDERS[planType] ? DESCRIPTION_PLACEHOLDERS[planType] : '';
};

export const isPlanEndsIncluded = (
	planType: PlanTypeEnumContract,
	planState: PlanState,
	endDate: string,
): boolean => {
	const initialPlanEndsTypes = [
		PlanTypeEnumContract.health,
		PlanTypeEnumContract.dental,
		PlanTypeEnumContract.vision,
		PlanTypeEnumContract.supplemental,
		PlanTypeEnumContract.other,
	];

	return Boolean(endDate) || planState === PlanState.complete || (
		initialPlanEndsTypes.includes(planType) && isIncompletePlan(planState)
	);
};

export const hasStartDateChanged = (initialStartDate: string, currentStartDate: string): boolean => {
	return !moment(currentStartDate).isSame(initialStartDate);
};

export const isStartDateDisabled = (
	startDate: string,
	planState: PlanState,
	hasFutureEnrollments: boolean,
	startDateHasChanged: boolean
): boolean => {
	if (isIncompletePlan(planState)) {
		return false;
	}

	const hasStartDatePassed = hasPlanStarted(startDate) && !startDateHasChanged;

	return hasStartDatePassed || hasFutureEnrollments;
};

export const isRateDisabled = (
	value: RateValue,
	planState: PlanState
): boolean => {
	return value !== '' && !isIncompletePlan(planState);
};

export const isDeductionTypeDisabled = (
	value: DeductionTypeValue,
	enrollments?: PlanEnrollments,
	hasMasterTaxStartDate = true,
): boolean => {
	if (hasMasterTaxStartDate === false) {
		return false;
	}
	return value !== '' && doesPlanHavePastPresentOrFutureEnrollments(enrollments);
};

export const generateReimbursementFrequencyItems =
	(frequencies: ReimbursementFrequencyEnumContract[]): SelectItem[] => {
		const items = [];

		frequencies.forEach((frequency) => {
			if (REIMBURSEMENT_FREQUENCY_LABELS[frequency]) {
				items.push({
					text: REIMBURSEMENT_FREQUENCY_LABELS[frequency],
					value: frequency,
				});
			}
		});

		return items;
	};

export const constrainReimbursementFrequency = (
	value: string,
	allowedFrequencies: ReimbursementFrequencyEnumContract[]
): ReimbursementFrequencyEnumContract => {
	let constrainedValue: ReimbursementFrequencyEnumContract = allowedFrequencies[0];

	allowedFrequencies.forEach((enumValue) => {
		if (enumValue === value) {
			constrainedValue = enumValue;
		}
	});

	return constrainedValue;
};
