import moment, { Moment } from 'moment';

import { MultiFactorAuthStatus } from './types';

export const APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
export const APP_LINK_IOS = 'https://apps.apple.com/app/google-authenticator/id388497605';
export const CODE_LENGTH_LOGIN = 6;
export const CODE_LENGTH_BACKUP = 8;
export const DEFAULT_REDIRECT = '/';
// *note product decision to do 30 days out from start date
const REMINDER_TIME_IN_DAYS = 30;

export function calculateTimeRemaining(baseDate: Moment, compareDate: Moment, unitOfTime: moment.unitOfTime.Diff = 'days'): number {
	let difference = baseDate.diff(compareDate, unitOfTime);
	if (Number.isNaN(difference) || difference < 0) {
		if (window.BambooHR.env.dev) {
			console.error('difference is NaN or less than 0, resetting to 0');
		}
		difference = 0;
	}
	return difference;
}

export function generateQrCode(label = '', secret: string, issuer: string): string {
	return `otpauth://totp/${label?.length ? encodeURIComponent(label) : 'BambooHR'}?secret=${secret}&issuer=${issuer}`;
}

export function getRedirect(): string {
	const urlParams = new URLSearchParams(window.location.search);
	const redirect = urlParams.get('r') ?? DEFAULT_REDIRECT;
	if (redirect === DEFAULT_REDIRECT) {
		return redirect;
	}
	const redirectUrl = new URL(redirect, window.location.origin);
	if (redirectUrl.origin !== window.location.origin) {
		return DEFAULT_REDIRECT;
	}

	if (redirectUrl.protocol !== 'https:') {
		redirectUrl.protocol = 'https:';
	}

	return redirectUrl.toString();
}

export function hasTimeRemaining(baseDate: Moment, compareDate: Moment, unitOfTime: moment.unitOfTime.Diff = 'days'): boolean {
	const difference = calculateTimeRemaining(baseDate, compareDate, unitOfTime);
	return difference > 0;
}

export function shouldRedirectOnLoad(startDate: Moment, today: Moment): boolean {
	if (!startDate.isValid() || !today.isValid()) {
		return false;
	}
	const difference = calculateTimeRemaining(startDate, today, 'days');
	if (difference <= REMINDER_TIME_IN_DAYS) {
		return false;
	}
	return true;
}

export function shouldShowSetupModal(startDate: Moment, today: Moment, status: MultiFactorAuthStatus): boolean {
	if (status !== 'beforeRequired' || !startDate.isValid() || !today.isValid()) {
		return false;
	}
	const difference = calculateTimeRemaining(startDate, today, 'days');
	if (difference === 0 || difference > REMINDER_TIME_IN_DAYS) {
		return false;
	}
	return true;
}
