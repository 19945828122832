import { bothGroupsHaveSelectionsForFilter } from './group-selections';
import { FilterSelections } from '../../types';
import { ALL_FILTER_TYPES } from '../eligibility-filters/constants';
import { groupsHaveUniqueSelectionsForFilter } from './get-superset-group';

// If selections are unique in at least one filter type, then they can overlap
// to any degree in any other filter type.
export const areGroupsMutuallyExclusive = (
	firstGroup: FilterSelections,
	secondGroup: FilterSelections
): boolean => {
	let isMutuallyExclusive = true;

	for (const filterType of ALL_FILTER_TYPES) {
		if (bothGroupsHaveSelectionsForFilter(firstGroup, secondGroup, filterType)) {

			if (groupsHaveUniqueSelectionsForFilter(firstGroup[filterType], secondGroup[filterType])) {
				return true;
			}

			isMutuallyExclusive = false;
		}
	}

	return isMutuallyExclusive;
};

