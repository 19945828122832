import { Flex, LayoutBox, Section } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Component } from 'react';

import { TaxTypeDetails } from '../components/tax-type-details/tax-type-details';

import moment from 'moment';

import { CompanyTaxField } from '../components/company-tax-field/company-tax-field';

import { renderDateSelectOption } from '../utils';

import { redirect } from 'BambooHR.util';

import { showSlidedown } from 'Alerts.mod';

import {
	createInitialState,
	saveNewRate,
} from './utils';

import './add-tax-rate.styl';
export class AddTaxRate extends Component {
	_successCallback = () => {
		const { effectiveDate } = this.state;

		const formattedEffectiveDate = moment(effectiveDate.value, 'YYYY-MM-DD').format(moment.defaultFormat);

		const { taxTypeName } = this.props;
		redirect('/settings/payroll/taxes', $.__('Your rate change for %1$s has been saved and will take effect on %2$s.', taxTypeName, formattedEffectiveDate), 'success');
	}

	_failureCallback = (errorMessage) => {
		if (errorMessage) {
			showSlidedown(errorMessage, 'error');
		} else {
			showSlidedown(window.DEFAULT_ERROR_MESSAGE, 'error');
		}

		document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
	}

	_saveTaxRate = () => {
		document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));

		const callbackFunctions = {
			success: this._successCallback,
			failure: this._failureCallback,
		};

		saveNewRate(this.state, this.props, this._updateFormData, callbackFunctions);
	}

	_updateFormData = (field, updates) => {
		this.setState(prevState => ({
			[field]: {
				...prevState[field],
				...updates,
			},
		}));
	}

	state = createInitialState(this.props);

	componentDidMount() {
		const footerSaveButton = document.querySelector('[data-action="SiteFooterAction:saveTaxRate"]');
		footerSaveButton.addEventListener('click', this._saveTaxRate);
	}

	componentWillUnmount() {
		const footerSaveButton = document.querySelector('[data-action="SiteFooterAction:saveTaxRate"]');
		footerSaveButton.removeEventListener('click', this._saveTaxRate);
	}

	render() {
		const {
			clientInfo,
			employerTaxId,
			taxTypeName,
			taxTypeState,
		} = this.props;

		const {
			effectiveDate,
			payFrequencyId,
			taxRate,
			notes,
		} = this.state;

		const taxTypeDetails = [];

		if (clientInfo) {
			taxTypeDetails.push({
				header: $.__('Company/EIN'),
				value: `${ clientInfo.name } (${ clientInfo.ein })`,
			});
		}

		if (taxTypeState) {
			taxTypeDetails.push({
				header: $.__('State'),
				value: taxTypeState,
			});
		}

		if (taxTypeName) {
			taxTypeDetails.push({
				header: $.__('Tax Type'),
				value: taxTypeName,
			});
		}

		if (employerTaxId) {
			taxTypeDetails.push({
				header: $.__('Tax ID'),
				value: employerTaxId,
			});
		}

		return (
			<div className={ifFeature('encore', '', 'AddTaxRate')}>
				{ifFeature('encore',
					<LayoutBox marginBottom={5}>
						<AddTaxRateSection sectionHeader={ $.__('Tax Details') }>
							<TaxTypeDetails details={ taxTypeDetails } />
						</AddTaxRateSection>
					</LayoutBox>,
					<AddTaxRateSection>
						<TaxTypeDetails details={ taxTypeDetails } />
					</AddTaxRateSection>
				)}
				<AddTaxRateSection sectionHeader={ $.__('New Rate') }>
					<CompanyTaxField
						{ ...effectiveDate }
						fieldType="select"
						label={ $.__('Effective Date') }
						renderOptionContent={ renderDateSelectOption }
						renderToggleContent={ renderDateSelectOption }
						updateFormData={ this._updateFormData }
						width={ 8 }
					/>
					<CompanyTaxField
						{ ...payFrequencyId }
						fieldType="payFrequency"
						label={ $.__('Pay Frequency') }
						placeholder="First Select Tax Type"
						selectedTaxType={ this.props }
						updateFormData={ this._updateFormData }
						width={ 8 }
					/>
					<CompanyTaxField
						{ ...taxRate }
						fieldType="taxRate"
						label={ $.__('Tax Rate') }
						selectedTaxType={ this.props }
						unit="%"
						updateFormData={ this._updateFormData }
					/>
					{/* <CompanyTaxField
						{ ...notes }
						fieldType="textarea"
						label={ $.__('Notes') }
						updateFormData={ this._updateFormData }
						width={ 9 }
					/> */}
				</AddTaxRateSection>
			</div>
		);
	}
}

function AddTaxRateSection(props) {
	const {
		children,
		sectionHeader,
	} = props;

	return ifFeature('encore', 
		<Section title={ sectionHeader }>
			<Flex flexDirection="column" gap={2} marginTop={2}>
				{ children }
			</Flex>
		</Section>,
		<div className="AddTaxRate__section">
			{ sectionHeader && (
				<div className="AddTaxRate__sectionHeader">
					{ sectionHeader }
				</div>
			) }
			{ children }
		</div>
	);
}
