import {
	EmployeeTaxBoxContentFederalTaxWithholding,
} from './employee-tax-box-content-federal-tax-withholding';
import {
	EmployeeTaxBoxContentStateTaxWithholding,
} from './employee-tax-box-content-state-tax-withholding';
import {
	EmployeeTaxBoxContentStateUnemploymentInsurance,
} from './employee-tax-box-content-state-unemployment-insurance';
import {
	EmployeeTaxBoxContentProps,
	EmployeeTaxMetadataFederalTaxWitholding,
	EmployeeTaxMetadataStateTaxWitholding,
	EmployeeTaxMetadataStateUnemploymentInsurance,
} from '../../utils/interfaces';

export function EmployeeTaxBoxContent({
	fieldName,
	isSelected,
	metadata,
}: EmployeeTaxBoxContentProps): JSX.Element {
	if (fieldName === 'federal_withholding') {
		return (
			<EmployeeTaxBoxContentFederalTaxWithholding
				isSelected={ isSelected }
				metadata={ metadata as EmployeeTaxMetadataFederalTaxWitholding }
			/>
		);
	}

	if (fieldName === 'state_withholding') {
		return (
			<EmployeeTaxBoxContentStateTaxWithholding
				isSelected={ isSelected }
				metadata={ metadata as EmployeeTaxMetadataStateTaxWitholding }
			/>
		);
	}

	if (fieldName === 'state_unemployment_insurance') {
		return (
			<EmployeeTaxBoxContentStateUnemploymentInsurance
				isSelected={ isSelected }
				metadata={ metadata as EmployeeTaxMetadataStateUnemploymentInsurance }
			/>
		);
	}

	return null;
}
