import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => ({
	standard: {
		backgroundColor: palette.gray[100],
		borderBottom: `1px solid ${palette.gray[400]}`,
	},
	pageTitle: {
		fontSize: typography.fabricFontSize('biggie'),
		fontWeight: typography.fontWeightSemibold,
	},
	titleIcon: {
		fill: palette.primary.main,
		marginRight: 12,
	},
	titleAlign: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
	},
	settingsGearPadding: {
		marginLeft: '10px',
	},
	settingsButtons: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	titleWrapper: {
		alignItems: 'center',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'row',
		margin: '21px 0 27px',
	},
	goalSettingsIcon: {
		marginBottom: 3,
	},
}));
