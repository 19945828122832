import { WageMetadata } from '../wage-metadata';
import { LocationMetadata } from '../location-metadata';
import { CompanyDeductionsMetadata } from '../company-deductions-metadata';
import { CompanyEmploymentStatusMetadata } from '../company-employment-status-metadata';
import { EmployeeRecordMappingMetadata } from '../employee-record-mapping-metadata';
import { EmployeeDeductionsMetadata } from '../employee-deductions-metadata';

export const ITEM_DATA_BY_TYPE = {
	employeeRecord: {
		addNewTextBamboo: $.__('Add This Employee to BambooHR'),
		addNewTextTrax: $.__('Add This Employee to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: EmployeeRecordMappingMetadata,
	},
	employeeWage: {
		addNewTextBamboo: $.__('Add This Compensation to BambooHR'),
		addNewTextTrax: $.__('Add This Compensation to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: WageMetadata,
	},
	companyLocation: {
		addNewTextBamboo: $.__('Add This Location to BambooHR'),
		addNewTextTrax: $.__('Add This Location to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: LocationMetadata,
	},
	companyDeductions: {
		addNewTextBamboo: $.__('Add This Deduction to BambooHR'),
		addNewTextTrax: $.__('Add This Deduction to TRAXPayroll'),
		placeholderBamboo: $.__('Match to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: CompanyDeductionsMetadata,
	},
	companyDivision: {
		addNewTextBamboo: $.__('Add This Division to BambooHR'),
		addNewTextTrax: $.__('Add This Division to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: (): null => null,
	},
	companyDepartment: {
		addNewTextBamboo: $.__('Add This Department to BambooHR'),
		addNewTextTrax: $.__('Add This Department to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: (): null => { return null; },
	},
	companyEmploymentStatus: {
		addNewTextBamboo: $.__('Add This Status to BambooHR'),
		addNewTextTrax: $.__('Add This Status to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: CompanyEmploymentStatusMetadata,
	},
	employeeDeductions: {
		addNewTextBamboo: $.__('Add This Deduction to BambooHR'),
		addNewTextTrax: $.__('Add This Deduction to TRAXPayroll'),
		placeholderBamboo: $.__('Match to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: EmployeeDeductionsMetadata,
	},
	timeOff: {
		addNewTextBamboo: $.__('Add This Time Off Type to BambooHR'),
		addNewTextTrax: $.__('Add This Time Off Type to TRAXPayroll'),
		placeholderBamboo: $.__('Match or Add to BambooHR'),
		placeholderTrax: $.__('Match or Add to TRAXPayroll'),
		MetadataComponent: (): null => null,
	},
};
