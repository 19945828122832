import {
	isFunction,
} from 'lodash';
import {
	useRef,
	useState,
} from 'react';

import {
	Modal,
} from 'modal-legacy';
import {
	closeModal,
} from 'show-modal.mod';

import {
	bem,
} from '../../../utils';


export default function AppModal({
	type,
	children,
	isLoading,
	isOpen,
	onClose,
	onOpen,
	primaryAction,
	...props
}) {
	const {
		app,
	} = props;
	const contentWrapperRef = useRef(null);
	const [processing, setProcessing] = useState(false);

	let modalType;

	if (
		type === 'config' &&
		app &&
		(
			app.name === 'scimGoogle' ||
			app.name === 'scimJumpCloud'
		)
	) {
		modalType = 'medium';
	}

	return (
		<Modal
			{ ...props }
			isLoading={ isLoading }
			isOpen={ app && isOpen }
			isProcessing={ processing }
			onClose={ (...args) => {
				if (isFunction(onClose)) {
					if (onClose(...args) !== false) {
						closeModal();
					}
				} else {
					closeModal();
				}
			} }
			onOpen={ (...args) => {
				const {
					current: modal,
				} = contentWrapperRef;

				if (isFunction(onOpen)) {
					onOpen(...args);
				}

				document.dispatchEvent(new CustomEvent(`SettingsAppsModal:open:${ type }:${ app.name }`, {
					detail: {
						modal,
						app,
					},
				}));
			} }
			primaryAction={
				primaryAction === null ? null : (
					() => {
						if (isFunction(primaryAction)) {
							setProcessing(true);

							Promise.all([primaryAction()])
								.then(() => closeModal())
								.catch(() => setProcessing(false));
						} else {
							closeModal();
						}
					}
				)
			}
			type={ modalType }
		>
			{ app && isOpen && (
				<div
					className={ bem('appModal', [app.name, type]) }
					ref={ contentWrapperRef }
				>
					{ children }
				</div>
			) }
		</Modal>
	);
}
