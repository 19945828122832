/* eslint-disable implicit-arrow-linebreak */
import { isEnabled } from 'FeatureToggle.util';
import {
	BasicLifeCategoryDataOptionsContract,
	BasicLifeOrADandDEnum,
	DisabilityCategoryDataOptionsContract,
	LifeCategoryDataOptionsContract,
	LifeCoverageEnum,
	LifeCoverageOptions,
	LifeInsuranceTypeEnum,
	LifeOrADandDEnum,
	PlanCategoryDataOptionsContract,
	SalaryBasedSpouseType,
	VoluntaryCoverageContract,
	VoluntaryCoverageTypeEnum,
	VoluntaryLifeCategoryDataOptionsContract,
	VoluntaryLifeOrADandDEnum,
	WizardValues,
} from '../types';
import { PlanTypeEnumContract } from '../../types';
import { makeLifeOrAddValue } from './life-or-add-value-factory';
import { DEFAULT_COVERAGE_AMOUNTS } from '../constants';
import { FEATURE_TOGGLE_KEYS } from '../../etc/feature-toggle';

const buildBasicLifePlanCategoryDataOptions = (
	coverageOptions: LifeCoverageOptions,
	contractType: BasicLifeOrADandDEnum
): PlanCategoryDataOptionsContract => {
	const coverage = {
		employee: null,
		spouse: null,
		child: null,
		type: coverageOptions.type || DEFAULT_COVERAGE_AMOUNTS.type,
	};
	const { spouseCoverage, childCoverage } = coverageOptions;

	const buildChildCoverage = () => {
		if (coverageOptions.childCoverage && coverageOptions.childAmount) {
			coverage.child = {
				amount: coverageOptions.childAmount,
			};
		}
	};

	if (coverageOptions.type === LifeCoverageEnum.salary) {
		buildChildCoverage();
		coverage.employee = {
			multiplier: coverageOptions.employeeMultiplier,
			maximumAmount: coverageOptions.employeeMaximumAmount,
			minimumAmount: coverageOptions.employeeMinimumAmount || 0,
		};

		if (coverageOptions.spouseCoverage) {
			coverage.spouse = {
				type: coverageOptions.spouseAmountType || SalaryBasedSpouseType.percentage,
				amount: coverageOptions.spouseAmount || 0,
			};
		}
	}
	if (coverageOptions.type === LifeCoverageEnum.fixed) {
		buildChildCoverage();
		if (coverageOptions.employeeAmount) {
			coverage.employee = {
				amount: coverageOptions.employeeAmount,
			};
		}

		if (coverageOptions.spouseCoverage && coverageOptions.spouseAmount) {
			coverage.spouse = {
				amount: coverageOptions.spouseAmount,
			};
		}
	}

	return {
		childCoverage,
		coverage,
		spouseCoverage,
		type: contractType,
	} as BasicLifeCategoryDataOptionsContract;
};

const buildVoluntaryLifePlanCategoryDataOptions = (
	coverageOptions: LifeCoverageOptions,
	contractType: VoluntaryLifeOrADandDEnum
): PlanCategoryDataOptionsContract => {
	const coverage: VoluntaryCoverageContract = {
		employee: null,
		spouse: null,
		child: null,
		type: VoluntaryCoverageTypeEnum.voluntary,
	};
	const { spouseCoverage, childCoverage } = coverageOptions;

	coverage.employee = {
		increment: coverageOptions.employeeIncrement,
		maximumAmount: coverageOptions.employeeMaximumAmount,
		minimumAmount: coverageOptions.employeeMinimumAmount || 0,
		multiplier: coverageOptions.employeeMultiplier,
	};

	if (coverageOptions.spouseCoverage) {
		coverage.spouse = {
			increment: coverageOptions.spouseIncrement || null,
			maximumAmount: coverageOptions.spouseMaximumAmount || null,
			minimumAmount: coverageOptions.spouseMinimumAmount || 0,
			percentOfEmployeeCoverage: coverageOptions.spousePercentOfEmployeeCoverage || null,
		};
	}

	if (coverageOptions.childCoverage) {
		coverage.child = {
			increment: coverageOptions.childIncrement || null,
			maximumAmount: coverageOptions.childMaximumAmount || null,
			minimumAmount: coverageOptions.childMinimumAmount || 0,
		};
	}

	return {
		childCoverage,
		coverage,
		spouseCoverage,
		type: contractType,
	} as VoluntaryLifeCategoryDataOptionsContract;
};

export const packagePlanCategoryDataOptions = (wizardValues: WizardValues, planType: PlanTypeEnumContract) => {
	const { planCategoryDataOptions } = wizardValues;

	switch (planType) {
		case PlanTypeEnumContract.disability:
			return planCategoryDataOptions as DisabilityCategoryDataOptionsContract;
		case PlanTypeEnumContract.life:
			switch (wizardValues.lifeInsuranceType) {
				case LifeInsuranceTypeEnum.voluntary: {
					const lifePlanCategoryData = planCategoryDataOptions as LifeCategoryDataOptionsContract;
					const isVoluntaryLifeCoverageSelectorEnabled = isEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_VOLUNTARY_LIFE_COVERAGE_SELECTOR);
					const lifeOrADD = isVoluntaryLifeCoverageSelectorEnabled ? wizardValues.lifeOrAdd : null;

					const voluntaryLifeOrADandD = {
						[LifeOrADandDEnum.life]: VoluntaryLifeOrADandDEnum.life,
						[LifeOrADandDEnum.add]: VoluntaryLifeOrADandDEnum.add,
						[LifeOrADandDEnum.both]: VoluntaryLifeOrADandDEnum.both,
					};

					return makeLifeOrAddValue(
						lifeOrADD,
						{
							[LifeOrADandDEnum.life]: () =>
								buildVoluntaryLifePlanCategoryDataOptions(wizardValues.coverageOptions, VoluntaryLifeOrADandDEnum.life),
							[LifeOrADandDEnum.add]: () =>
								buildVoluntaryLifePlanCategoryDataOptions(wizardValues.coverageOptions, VoluntaryLifeOrADandDEnum.add),
							[LifeOrADandDEnum.both]: () =>
								buildVoluntaryLifePlanCategoryDataOptions(wizardValues.coverageOptions, VoluntaryLifeOrADandDEnum.both),
						},
						() => ({
							lifeInsuranceType: LifeInsuranceTypeEnum.voluntary,
							lifeInsuranceSpouseCoverage: lifePlanCategoryData?.lifeInsuranceSpouseCoverage || false,
							lifeInsuranceChildCoverage: lifePlanCategoryData?.lifeInsuranceChildCoverage || false,
							type: voluntaryLifeOrADandD[wizardValues.lifeOrAdd] || null,
						})
					);
				}
				case LifeInsuranceTypeEnum.basic:
					return makeLifeOrAddValue(
						wizardValues.lifeOrAdd,
						{
							[LifeOrADandDEnum.life]: () =>
								buildBasicLifePlanCategoryDataOptions(wizardValues.coverageOptions, BasicLifeOrADandDEnum.life),
							[LifeOrADandDEnum.add]: () =>
								buildBasicLifePlanCategoryDataOptions(wizardValues.coverageOptions, BasicLifeOrADandDEnum.add),
							[LifeOrADandDEnum.both]: () =>
								buildBasicLifePlanCategoryDataOptions(wizardValues.coverageOptions, BasicLifeOrADandDEnum.both),
						},
						() => ({ lifeInsuranceType: LifeInsuranceTypeEnum.basic })
					);
				default:
					break;
			}
			break;
		default:
			return planCategoryDataOptions;
	}
};
