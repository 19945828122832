import {
	makeStyles
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({palette}) => {
	return {
		icon: {
			fill: palette.primary.main
		},
		bodyText: {
			paddingTop: '8px',
			paddingBottom: '12px'
		},
		midHeadingText: {
			color: palette.primary.main
		},
		inlineTextField: {
			paddingLeft: '18px',
			paddingRight: '18px',
			marginTop: '8px'
		},
		textArea: {
			marginTop: '18px'
		}
	};
});
