import {
	BodyText,
	Button,
	Flex,
	Headline,
	LayoutBox,
	StandardModal,
	StyledBox,
	TextButton,
	makeStyles
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { TrashCan49x54 } from '@bamboohr/grim';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { isEnabled } from 'FeatureToggle.util';
import { IntegerLikeId } from 'base/hiring/shared/types';
import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';

import {
	AUTOMATED_EMAIL_AND_DQ_WARNING,
	AUTOMATED_EMAIL_WARNING_STATUS,
	AUTOMATED_EMAIL_WARNING_TEMPLATE,
	DELETE,
	DISQUALIFYING_QUESTION_WARNING,
	PIPELINE_AND_DQ_WARNING_STATUS,
	PIPELINE_AND_EMAIL_AND_DQ_WARNING,
	PIPELINE_AND_EMAIL_WARNING,
	PIPELINE_STATUS_WARNING,
	PIPELINE_WARNING,
	STATUS_WARNING,
} from './constants';
import { AutomatedEmailCountObject, DeletableObject, JobPipelineCountObject, JobRuleCountObject } from './types';

interface Props {
	automatedEmailCounts?: AutomatedEmailCountObject;
	isOpen: boolean;
	closeModal: () => void;
	onDelete: (toDelete: IntegerLikeId | DeletableObject) => void;
	companyAtsPlusEnabled: boolean;
	jobRuleCounts?: JobRuleCountObject;
	jobPipelineCounts?: JobPipelineCountObject;
	deleting: DeletableObject;
	deletingType: 'template' | 'status';
}

export const useStyles = makeStyles(({ palette }) => ({
	danger: {
		fill: palette.error.main,
	},
}));

const AUTOMATED_EMAILS_ENABLED = isEnabled('ATS_AUTOMATED_EMAILS');
const DISQUALIFYING_QUESTIONS_ENABLED = isEnabled('ATS_DISQUALIFYING_QUESTION');
const IS_PIPELINES_ENABLED = isEnabled('ATS_CANDIDATE_PIPELINE');

export const DeleteModal = (props: Props) => {
	const {
		automatedEmailCounts,
		isOpen,
		closeModal,
		deleting,
		onDelete,
		companyAtsPlusEnabled,
		jobPipelineCounts,
		jobRuleCounts,
		deletingType,
	} = props;

	const [confirmationText, setConfirmationText] = useState('');

	const handleClose = () => {
		setConfirmationText('');
		closeModal();
	};

	const handleDelete = () => {
		const deleteArg = deletingType === 'status' ? deleting : deleting.id;
		onDelete(deleteArg);
	};

	const shouldShowEmailWarning =
		companyAtsPlusEnabled &&
		AUTOMATED_EMAILS_ENABLED &&
		(!!jobRuleCounts?.ruleTotals.automatedEmail || !!automatedEmailCounts?.totalRules);
	const shouldShowDisqualifyingQuestionWarning =
		companyAtsPlusEnabled && DISQUALIFYING_QUESTIONS_ENABLED && !!jobRuleCounts?.ruleTotals.disqualifyingQuestion;
	const shouldShowPipelineWarning = companyAtsPlusEnabled && IS_PIPELINES_ENABLED && !!jobPipelineCounts;
	const isDeleteDisabled =
		(shouldShowDisqualifyingQuestionWarning || shouldShowEmailWarning) && confirmationText.toLowerCase() !== DELETE.toLowerCase();

	const headingLabel = deletingType === 'status' ? $.__('status') : $.__('email template');

	const styles = useStyles();

	const subtext = useMemo(() => {
		if (!isOpen) {
			return '';
		}

		switch (deletingType) {
			case 'status':
				switch (true) {
					case shouldShowEmailWarning && shouldShowDisqualifyingQuestionWarning && shouldShowPipelineWarning:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{PIPELINE_AND_EMAIL_AND_DQ_WARNING}
							</BodyText>
						);
					case shouldShowEmailWarning && shouldShowPipelineWarning:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{PIPELINE_AND_EMAIL_WARNING}
							</BodyText>
						);
					case shouldShowEmailWarning && shouldShowDisqualifyingQuestionWarning:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{AUTOMATED_EMAIL_AND_DQ_WARNING}
							</BodyText>
						);
					case shouldShowPipelineWarning && shouldShowDisqualifyingQuestionWarning:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{PIPELINE_AND_DQ_WARNING_STATUS}
							</BodyText>
						);
					case shouldShowEmailWarning:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{AUTOMATED_EMAIL_WARNING_STATUS}
							</BodyText>
						);
					case shouldShowPipelineWarning:
						return (
							<>
								<BodyText
									color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
									justify='center'
									size={ifFeature('encore', 'medium', 'small')}
									weight='regular'
								>
									{PIPELINE_WARNING}
								</BodyText>
								<BodyText
									color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
									justify='center'
									size={ifFeature('encore', 'medium', 'small')}
									weight='regular'
								>
									{PIPELINE_STATUS_WARNING}
								</BodyText>
							</>
						);
					case shouldShowDisqualifyingQuestionWarning:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{DISQUALIFYING_QUESTION_WARNING}
							</BodyText>
						);
					default:
						return (
							<BodyText
								color={ifFeature('encore', 'neutral-extra-strong', 'neutral-weak')}
								justify='center'
								size={ifFeature('encore', 'medium', 'small')}
								weight='regular'
							>
								{STATUS_WARNING}
							</BodyText>
						);
				}
			case 'template':
				switch (true) {
					case shouldShowEmailWarning:
						return AUTOMATED_EMAIL_WARNING_TEMPLATE;
					default:
						return '';
				}
			default:
				return '';
		}
	}, [shouldShowEmailWarning, shouldShowPipelineWarning, shouldShowDisqualifyingQuestionWarning, deletingType, isOpen]);

	return (
		<StandardModal isOpen={isOpen} onRequestClose={handleClose}>
			{!!deleting && (
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={ifFeature(
								'encore',
								[
									<TextButton key='keep' onClick={handleClose} type='button'>
										{$.__('Cancel')}
									</TextButton>,
									<Button disabled={isDeleteDisabled} key='remove' onClick={handleDelete} type='button'>
										{$.__('Delete %s', capitalize(deletingType))}
									</Button>,
								],
								[
									<Button disabled={isDeleteDisabled} key='remove' onClick={handleDelete} type='button'>
										{$.__('Delete %s', capitalize(deletingType))}
									</Button>,
									<Button color='secondary' key='keep' onClick={handleClose} type='button' variant='outlined'>
										{$.__('Keep %s', capitalize(deletingType))}
									</Button>,
								]
							)}
						/>
					}
					// @startCleanup encore
					renderHeader={ifFeature(
						'encore',
						null,
						<StandardModal.Header hasCloseButton={true} title={$.__('Just Checking...')} />
					)}
					// @endCleanup encore
				>
					<StandardModal.Constraint
						spacingOverrides={{
							bottom: StandardModal.Constraint.Spacing.MEDIUM,
							top: StandardModal.Constraint.Spacing.MEDIUM,
						}}
					>
						<StandardModal.HeroHeadline
							icon={ifFeature(
								'encore',
								'trash-can-regular',
								<TrashCan49x54 className={styles.danger} />
							)}
							iconColor={ifFeature('encore', 'error-strong')}
							subtext={
								<Flex alignItems='center' flexDirection='column' paddingBottom={1}>
									{subtext}
								</Flex>
							}
							text={$.__('Are you sure you want to delete this %s?', headingLabel)}
						/>

						{ifFeature(
							'encore',
							<LayoutBox marginTop={4}>
								<StyledBox backgroundColor="neutral-extra-extra-weak" borderRadius="small" width="100%">
									<LayoutBox padding="20px">
										<Flex alignItems='center' columnGap={2} flexDirection='column'>
											<BodyText color='neutral-strong' size='small' weight='bold'>
												{deleting.name}
											</BodyText>
											{shouldShowPipelineWarning && (
												<BodyText color='neutral-medium' size='small' weight='regular'>
													{$.__n('%s job pipeline', '%s job pipelines', jobPipelineCounts?.totalJobs)}
												</BodyText>
											)}
											{shouldShowDisqualifyingQuestionWarning && (
												<Flex alignItems='center' flexDirection='column'>
													<BodyText color='neutral-medium' size='small' weight='regular'>
														{$.__n(
															'%s disqualifying question',
															'%s disqualifying questions',
															jobRuleCounts?.ruleTotals.disqualifyingQuestion
														)}
													</BodyText>
												</Flex>
											)}
											{shouldShowEmailWarning && (
												<Flex alignItems='center' flexDirection='column'>
													<BodyText color='neutral-medium' size='small' weight='regular'>
														{$.__n(
															'%s automated email',
															'%s automated emails',
															jobRuleCounts?.ruleTotals.automatedEmail || automatedEmailCounts?.totalRules
														)}
													</BodyText>
												</Flex>
											)}
											{(shouldShowDisqualifyingQuestionWarning || shouldShowEmailWarning) && (
												<Flex alignItems='center' flexDirection='column'>
													<LayoutBox marginTop={3}>
														<StandardModal.ConfirmationContent
															confirmationText={
																<Message
																	params={[DELETE, deletingType]}
																	text={$.__('Type **"%s"** to permanently delete this %2.', DELETE, deletingType)}
																/>
															}
															onConfirmationChange={(e) => setConfirmationText(e.target.value)}
														/>
													</LayoutBox>
												</Flex>
											)}
										</Flex>
									</LayoutBox>
								</StyledBox>
							</LayoutBox>,
							<StandardModal.LowerContent>
								<Flex alignItems='center' columnGap={2} flexDirection='column'>
									<Headline color='neutral-strong' component='h5' size='extra-small'>
										{deleting.name}
									</Headline>
									{shouldShowPipelineWarning && (
										<BodyText color='neutral-medium' size='small' weight='regular'>
											{$.__n('%s job pipeline', '%s job pipelines', jobPipelineCounts?.totalJobs)}
										</BodyText>
									)}
									{shouldShowDisqualifyingQuestionWarning && (
										<BodyText color='neutral-medium' size='small' weight='regular'>
											{$.__n(
												'%s disqualifying question',
												'%s disqualifying questions',
												jobRuleCounts?.ruleTotals.disqualifyingQuestion
											)}
										</BodyText>
									)}
									{shouldShowEmailWarning && (
										<Flex alignItems='center' flexDirection='column'>
											<BodyText color='neutral-medium' size='small' weight='regular'>
												{$.__n('%s automated email', '%s automated emails', jobRuleCounts?.ruleTotals.automatedEmail)}
											</BodyText>
										</Flex>
									)}
									{(shouldShowDisqualifyingQuestionWarning || shouldShowEmailWarning) && (
										<Flex alignItems='center' flexDirection='column'>
											<LayoutBox marginTop={3}>
												<StandardModal.ConfirmationContent
													confirmationText={
														<Message
															params={[DELETE, deletingType]}
															text={$.__('Type **"%s"** to permanently delete this %2.', DELETE, deletingType)}
														/>
													}
													onConfirmationChange={(e) => setConfirmationText(e.target.value)}
												/>
											</LayoutBox>
										</Flex>
									)}
								</Flex>
							</StandardModal.LowerContent>
						)}
					</StandardModal.Constraint>
				</StandardModal.Body>
			)}
		</StandardModal>
	);
};
