import { Flex, TextField, Typography } from '@bamboohr/fabric';
import { convertStringToNumber } from 'base/_components/form-fields.react';
import { ChangeEvent, useEffect, useState } from 'react';

import { useValidationContext } from '../../../../../context';
import { DisabilityTypeEnum, EligibilityGroupCalculatorSettings, EligibilityGroupValues } from '../../../../../types';
import { generateRateErrorKey, validateDisabilityRate } from '../../../../../utils';
import { ErrorTooltip } from '../error-tooltip/error-tooltip';
import { DEFAULT_CALCULATOR_SETTINGS } from '../../../../constants';

interface MonthlyRateMultiplierProps {
	disabilityType: DisabilityTypeEnum;
	eligibilityGroup: EligibilityGroupValues;
	groupIndex: number;
	updateGroupProperty: (
		groupIndex: number,
		key: keyof EligibilityGroupValues,
		calculatorSettings: EligibilityGroupCalculatorSettings
	) => void;
}

export const MonthlyRateMultiplier = (props: MonthlyRateMultiplierProps): JSX.Element => {
	const { disabilityType, eligibilityGroup, groupIndex, updateGroupProperty } = props;
	const errorKey: string = generateRateErrorKey(eligibilityGroup.id);
	const rate = String(eligibilityGroup?.calculatorSettings?.rate ?? '');

	const { setSpecialErrors, specialErrors } = useValidationContext();
	const [inputValue, setInputValue] = useState<string>(rate);

	const rules = {
		required: true,
		greaterThanOrEqualTo: true,
	};

	const isShortTerm = disabilityType === DisabilityTypeEnum.shortTermDisability;
	const timeFrame = isShortTerm ? 'weekly' : 'monthly';
	const rateDollarAmount = isShortTerm ? '$10' : '$100';

	useEffect(() => {
		if (eligibilityGroup?.calculatorSettings?.rate) {
			setInputValue(rate);
		} else {
			setInputValue(null);
		}
	}, [eligibilityGroup, rate]);

	const packageCalculatorSettings = (newRate: number): EligibilityGroupCalculatorSettings => {
		let tempCalculatorSettings: EligibilityGroupCalculatorSettings;

		if (eligibilityGroup.calculatorSettings) {
			tempCalculatorSettings = { ...eligibilityGroup.calculatorSettings, rate: newRate };
		} else {
			tempCalculatorSettings = {
				...DEFAULT_CALCULATOR_SETTINGS,
				rate: newRate,
			} as EligibilityGroupCalculatorSettings;
		}

		return tempCalculatorSettings;
	};

	const handleRateChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleRateBlur = (): void => {
		const newRate: number = convertStringToNumber(inputValue);
		if (newRate > 0) {
			setInputValue(String(newRate));
		} else {
			setInputValue('');
		}

		const newCalculatorSettings: EligibilityGroupCalculatorSettings = packageCalculatorSettings(newRate);
		updateGroupProperty(groupIndex, 'calculatorSettings', newCalculatorSettings);
		validateDisabilityRate(disabilityType, errorKey, newRate, setSpecialErrors);
	};

	return (
		<Flex alignItems='center' gap={1}>
			{Boolean(specialErrors[errorKey]) && <ErrorTooltip message={specialErrors[errorKey].message} />}
			<TextField
				biId='disability-monthly-rate-multiplier-input'
				id={`monthlyRate_${groupIndex}`}
				name='monthlyRate'
				onBlur={handleRateBlur}
				onChange={handleRateChange}
				required={rules.required}
				status={specialErrors[errorKey] ? 'error' : undefined}
				value={inputValue}
				width={4}
			/>
			<Typography>{$.__('per %s of %s coverage', rateDollarAmount, timeFrame)}</Typography>
		</Flex>
	);
};
