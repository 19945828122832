import { Fragment } from 'react';
import { Select } from '@fabric/select';

import {
	ChildItem,
} from './types';
import { generateToggleText } from './utils';
import { useReactivateDisabledField } from '../../hooks/use-reactivate-disabled-field';
import {
	FilterType,
	FilterValue,
} from '../../types/wizard-values';

interface FilterSelectProps {
	biId?: string;
	filterType: FilterType;
	hasEditRestrictions: boolean;
	hasError: boolean;
	items: ChildItem[];
	selectedValues: FilterValue[];
	separator: string;
	setSelectedValues: (filterType: FilterType, selectedValues: FilterValue[]) => void;
}

export const FilterSelect = (props: FilterSelectProps): JSX.Element => {
	const {
		biId,
		filterType,
		hasEditRestrictions,
		hasError,
		items,
		selectedValues,
		separator,
		setSelectedValues,
	} = props;
	const isDisabled = useReactivateDisabledField(selectedValues, hasEditRestrictions);

	const handleChange = (newSelectedValues: FilterValue[]): void => {
		setSelectedValues(filterType, newSelectedValues);
	};

	const getToggleText = (selectedItems: ChildItem[]): string => {
		return generateToggleText(selectedItems, filterType);
	};

	return (
		<Fragment>
			<div className="fab-FormField">
				{ `${ separator }` }
			</div>
			<div className="fab-FormField">
				<Select
					biId={ biId }
					canSelectMultiple={ true }
					condition={ hasError ? 'error' : null }
					isClearable={ !hasEditRestrictions }
					isDisabled={ isDisabled }
					items={ items }
					onChange={ handleChange }
					renderToggleContent={ getToggleText }
					selectedValues={ selectedValues }
				/>
			</div>
		</Fragment>
	);
};
