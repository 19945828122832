import { render } from 'base/wrapped-render';
import Ctrl, {
	onReady,
} from 'ctrl.deco';

import WhenSent from './when-sent.react';


@Ctrl('/settings/alerts/:type(custom|custom_table|reminder).php')
class SettingsAlertsWhenSentCtrl {
	@onReady()
	_mount(e, { _wrapper, _data }) {
		render((
			<WhenSent { ..._data } />
		), _wrapper);
	}
}
