import {
	useState,
} from 'react';
import { Icon } from '@bamboohr/fabric';
import {
	Select,
} from '@fabric/select';

import { DeductionsTable } from '../deductions-table';

import {
	DeductionObject,
} from '../deductions-table/utils';

import './deduction-settings.styl';

export function DeductionSettings(props: DeductionSettingsProps): JSX.Element {
	const {
		deductionTypes,
	} = props;
	const [filter, setFilter] = useState('active');

	return (
		<div className="DeductionSettings">
			<div className="DeductionSettings__top">
				<div className="DeductionSettings__topHeader">
					<span className="DeductionSettings__topIcon">
						<Icon brand={ true } name="fab-scissors-20x20" />
					</span>
					<h4>{ $.__('Deductions') }</h4>
				</div>
				<div className="DeductionSettings__topFilter">
					<span className="fab-FormField">{ $.__('Showing') }</span>
					<span className="fab-FormField">
						<Select
							isClearable={ false }
							items={ FILTER_OPTIONS }
							onSelect={ (value): void => setFilter(value) }
							selectedValues={ [filter] }
							width={ 5 }
						/>
					</span>
				</div>
			</div>
			<DeductionsTable { ...deductionTypes[0] } filter={ filter } />
		</div>
	);
}

// Interfaces //////
interface DeductionSettingsProps {
	deductionTypes: Array<DeductionType>;
}

interface DeductionType {
	deductionTypeTranslated: string;
	managedDeductionType: string;
	deductions: Array<DeductionObject>;
}

// Local constants //////
const FILTER_OPTIONS = [
	{
		text: $.__('Active'),
		value: 'active',
	},
	{
		text: $.__('Inactive'),
		value: 'inactive',
	},
];
