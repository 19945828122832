import { useEffect } from 'react';

import { getDefaultEligibilityFilters, getDefaultEligibilityGroup } from './utils';
import { INITIAL_FILTERS } from '../../constants';
import { UpdateEligibilityGroup } from '../../steps/types';
import {
	CoverageLevelValue,
	DemographicsContract,
	EligibilityFilterValues,
	EligibilityGroupValues,
	FilterGroup,
	FilterType,
	PlanRateTypeEnum,
} from '../../types';

export const getDefaultFilterGroupWithFullTimeStatus = (demographics?: DemographicsContract): FilterGroup => {
	const initialFilterGroup = { ...INITIAL_FILTERS };
	if (!demographics) {
		return initialFilterGroup;
	}

	const { employmentStatuses, employmentStatusIds } = demographics;

	const fullTimeLabels = ['Full-time', 'Full time', 'Full Time', 'Full-Time'];
	employmentStatusIds.some((id) => {
		if (employmentStatuses[id] && fullTimeLabels.includes(employmentStatuses[id].name)) {
			initialFilterGroup.isAutoGenerated = true;
			initialFilterGroup.order = [FilterType.employmentStatus];
			initialFilterGroup.values = {
				...initialFilterGroup.values,
				[FilterType.employmentStatus]: [id],
			};
			return true;
		}
		return false;
	});

	return initialFilterGroup;
};

export const getDefaultEligibilityFiltersWithFullTimeStatus = (demographics?: DemographicsContract): EligibilityFilterValues => {
	return {
		...getDefaultEligibilityFilters(),
		filterGroup: getDefaultFilterGroupWithFullTimeStatus(demographics),
	};
};

export const getDefaultEligibilityGroupWithFullTimeStatus = (
	coverageLevels: CoverageLevelValue[],
	demographics: DemographicsContract,
	rateType: PlanRateTypeEnum
): EligibilityGroupValues => {
	return {
		...getDefaultEligibilityGroup(coverageLevels, rateType),
		eligibilityFilters: getDefaultEligibilityFiltersWithFullTimeStatus(demographics),
	};
};

export const useDefaultEligibilityGroup = (
	coverageLevels: CoverageLevelValue[],
	demographics: DemographicsContract | null,
	eligibilityGroups: EligibilityGroupValues[],
	rateType: PlanRateTypeEnum,
	updateEligibilityGroup: UpdateEligibilityGroup
): void => {
	useEffect(() => {
		if (eligibilityGroups.length < 1 && demographics) {
			const newEligibilityGroup = getDefaultEligibilityGroupWithFullTimeStatus(coverageLevels, demographics, rateType);
			updateEligibilityGroup(0, newEligibilityGroup);
		}
	}, [coverageLevels, demographics, eligibilityGroups, rateType, updateEligibilityGroup]);
};
