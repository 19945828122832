import { BenefitSettingsContract } from '../types/contracts';

export function benefitPlansContractShim(response): BenefitSettingsContract {
	if (response && response.data) {
		if (Array.isArray(response.data)) {
			return ({
				benefitPlans: response.data,
				migrationsNeeded: {
					deductionDateRule: false,
				},
			});
		} else if (typeof response.data === 'object' && response.data !== null) {
			return response.data;
		}
	}
	return null;
}
