import { LayoutBox, Loader } from '@bamboohr/fabric';
import { useEffect, useState } from 'react';

import { GoalSettingsWrapper } from './components/goal-settings-wrapper.react';
import { Consumer, Provider } from './context';
import { getGoalSettingsData } from './services/get-goal-settings-data';
import { FilterListData, GoalSettingsData } from './types';

interface Props {
	filterListData: FilterListData;
}

export const GoalSettings = (props: Props): JSX.Element => {
	const { filterListData } = props;

	const [dataReady, setDataReady] = useState(false);
	const [selectedGoalGroup, setSelectedGoalGroup] = useState<string>(null);

	useEffect(() => {
		getGoalSettingsData().then((response) => {
			const { goalsEligibleParticipants } = response as GoalSettingsData;

			setSelectedGoalGroup(goalsEligibleParticipants);
		});
	}, []);

	useEffect(() => {
		if (selectedGoalGroup) {
			setDataReady(true);
		}
	}, [selectedGoalGroup]);

	return (
		<Provider value={{}}>
			<Consumer>
				{dataReady
					? () => <GoalSettingsWrapper filterListData={filterListData} selectedGoalGroup={selectedGoalGroup} />
					: () => (
							<LayoutBox marginTop='72px'>
								<Loader />
							</LayoutBox>
					  )}
			</Consumer>
		</Provider>
	);
};
