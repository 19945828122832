import { Component, Fragment } from 'react';
import { render } from 'base/wrapped-render';
import disableModal from 'time-tracking/modals/disable-employee';
import bulkModal from './bulk-add';
import BlankState from 'blank-state.react';
import ReactTooltip from 'tooltip.react';
import { nonReactRender } from '../setup-checklist.react/components/enable-modal/future';
import enableTimeTrackingModal from 'time-tracking/modals/enable-time-tracking';
import { isEnabled } from 'FeatureToggle.util';
import { Button } from '@fabric/button';
import { Box, Icon, Table } from '@bamboohr/fabric';
import { Stopwatch17x20 } from '@bamboohr/grim';
import TimeTrackingNotificationModal from 'time-tracking/modals/time-tracking-notification';
import { TrialBanner } from '../trial/trial-banner';
import { SadToGoBox, CancellationType } from 'in-app-cancellation.mod';
import { PoMicroFrontend } from 'micro-frontend.react';
import { uniqueId } from 'lodash';
import Ajax from '@utils/ajax';

import './styles.styl';

const nonExemptEnabled = isEnabled('nonExemptWarningModals');
const futureSchedulingEnabled = isEnabled('timeTrackingFutureScheduling');
const customOvertimeFeatureEnabled = isEnabled('custom-overtime');

let jsonData;
let timeTrackingEmployees;
let canChangeFutureDates;
let canToggleTimeTracking;
let cancellationData;
let cancellationPending;
let isAdmin = false;

const tooltipContent = {
	noLocation: $.__('This employee has not been assigned to a location.'),
	noRemoteState: $.__('Overtime can’t be calculated. This employee’s address is missing a state.'),
	noState: $.__('Overtime can’t be calculated. The address is missing a state.'),
	international: $.__('Overtime can’t be calculated for international addresses.'),

	// These are used when the nonExemptWarningModals flag is enabled
	exempt: $.__('Overtime is not calculated for exempt employees.'),
	noRemoteStateUpdated: $.__('Overtime can’t be calculated. This employee’s address is missing a US state.'),
	noStateUpdated: $.__('Overtime cannot be calculated for this employee because the location does not include a US state.'),
	internationalUpdated: $.__('Overtime cannot be calculated for addresses that do not include a US state.'),
};

export default async () => {
	jsonData = JSON.parse($('#js-ste-data').html());

	timeTrackingEmployees = jsonData.employees;
	canChangeFutureDates = jsonData.canChangeFutureDates;
	canToggleTimeTracking = jsonData.canToggleTimeTracking;

	if (window.SESSION_USER.isAdmin || window.SESSION_USER.isOwner || window.SESSION_USER.supportAdmin) {
		isAdmin = true;
		try {
			await Ajax.get('/settings/cancellation/status_all').then((response) => {
				if (response.data) {
					cancellationData = response.data.filter(
						(item) => item.feature === CancellationType.TIME_TRACKING || item.feature === CancellationType.ACCOUNT
					);
					cancellationPending = response.data.find((item) => item.feature === CancellationType.TIME_TRACKING)?.pending_cancellation;
				}
			});
		} catch (err) {
			window.Rollbar.error('TIME_TRACKING: Unable to get cancellation data on employee settings page', err);
		}
	}

	renderEmployeesTrackingTime(jsonData);
};

function disableTimeTrackingForEmployee(employeeId) {
	const record = timeTrackingEmployees.find((record) => record.employeeId === employeeId);
	disableModal.openModal(record.employeeId, record.preferredName, record.lastName, reloadEmployees);
}

function changeSingleSchedule(employeeId) {
	const record = timeTrackingEmployees.find((record) => record.employeeId === employeeId);
	const { employeeId: id, startDate, preferredName } = record;

	const onSave = (request, startDate) => {
		if (startDate === 'immediately') {
			setMessage($.__('Alright, %1$s can now track time worked and access the timesheet.', preferredName), 'success');
		} else {
			const formattedDate = moment(startDate).format('MMMM D, YYYY');
			setMessage($.__('Alright, %1$s is scheduled to start tracking time on %2$s.', preferredName, formattedDate), 'success');
		}
		reloadEmployees();
	};

	enableTimeTrackingModal({ id, startDate, preferredName }, onSave, window.errorFallBack, false);
}

function reloadEmployees() {
	window.Settings.fadedPjaxReloadSettingsContent().done(() => {
		const empCount = $('.js-ste-main-emp-count').text();
		$('.js-ste-sub-nav-emp-count').text(empCount);
	});
}

function makeColumns() {
	const columns = [
		{
			header: $.__('Name'),
			cell: (employee) => {
				return (
					<Fragment>
						<ConditionalWrap
							condition={employee.canSeeEmployee}
							wrap={(children) => <a href={`/employees/employee.php?id=${employee.employeeId}`}>{children}</a>}
						>
							{employee.displayName}
						</ConditionalWrap>
						{employee.hasFutureStartDate && <div className='STE_EmpTable__startDate'>{employee.startsOnString}</div>}
					</Fragment>
				);
			},
			sortBy: (e) => e.displayName,
		},
		{
			header: $.__('Location'),
			cell: (employee) => {
				if (employee.hasLocation) {
					return employee.location;
				}

				return (
					<ReactTooltip
						settings={{
							content: tooltipContent.noLocation,
						}}
					>
						<div className='STE_EmpTable__warning STE_EmpTable__data--gray'>
							<span className='STE_EmpTable__warning__icon'>
								<Icon name='fab-triangle-exclamation-16x16' />
							</span>
							{$.__('No Location')}
						</div>
					</ReactTooltip>
				);
			},
			sortBy: (e) => e.location,
		},
		{
			header: $.__('Overtime State'),
			cell: (employee) => {
				employee.isOvertimeExempt = employee.isOvertimeExempt && nonExemptEnabled;
				if (employee.isOvertimeExempt) {
					return (
						<ReactTooltip settings={{ content: tooltipContent.exempt }}>
							<span className='STE_EmpTable__data--gray'>{$.__('Exempt')}</span>
						</ReactTooltip>
					);
				}
				if (employee.hasLocation) {
					if (!employee.overtimeState && employee.hasRemoteLocation === true && employee.inOvertimeCountry === true) {
						return (
							<ReactTooltip
								settings={{ content: nonExemptEnabled ? tooltipContent.noRemoteStateUpdated : tooltipContent.noRemoteState }}
							>
								<div className='STE_EmpTable__warning STE_EmpTable__data--gray'>
									<span className='STE_EmpTable__warning__icon'>
										<Icon name='fab-triangle-exclamation-16x16' />
									</span>
									{$.__('No State')}
								</div>
							</ReactTooltip>
						);
					}
					if (!employee.overtimeState && employee.hasRemoteLocation === false && employee.inOvertimeCountry === true) {
						return (
							<ReactTooltip settings={{ content: nonExemptEnabled ? tooltipContent.noStateUpdated : tooltipContent.noState }}>
								<div className='STE_EmpTable__warning STE_EmpTable__data--gray'>
									<span className='STE_EmpTable__warning__icon'>
										<Icon name='fab-triangle-exclamation-16x16' />
									</span>
									{$.__('No State')}
								</div>
							</ReactTooltip>
						);
					}
					if (!employee.overtimeState && employee.inOvertimeCountry === false) {
						return (
							<ReactTooltip
								settings={{ content: nonExemptEnabled ? tooltipContent.internationalUpdated : tooltipContent.international }}
							>
								<span className='STE_EmpTable__data--gray'>{$.__('International')}</span>
							</ReactTooltip>
						);
					}

					return employee.overtimeState;
				}

				return <span className='STE_EmpTable__data--gray'>-</span>;
			},
			sortBy: (e) => e.overtimeState,
		},
	];

	columns.push({
		header: $.__('Pay Schedule'),
		cell: (employee) => {
			if (employee.hasPaySchedule) {
				return employee.paySchedule;
			}
			return (
				<Fragment>
					<div className='STE_EmpTable__warning'>
						<span className='STE_EmpTable__warning__icon'>
							<Icon name='fab-triangle-exclamation-16x16' />
						</span>
						{$.__('No Pay Schedule')}
					</div>
				</Fragment>
			);
		},
		sortBy: (e) => e.paySchedule,
	});

	if (canToggleTimeTracking) {
		columns.push({
			showOnHover: true,
			headerAriaLabel: $.__('Actions'),
			cell: {
				type: 'actions',
				actions: (employee) => {
					const actions = [
						{
							icon: 'fab-circle-x-16x16',
							action: () => disableTimeTrackingForEmployee(employee.employeeId),
							tooltipContent: $.__('Disable Time Tracking'),
						},
					];
					if (employee.hasFutureStartDate) {
						actions.unshift({
							icon: 'fab-calendar-15x16',
							action: () => changeSingleSchedule(employee.employeeId),
							tooltipContent: $.__('Change scheduled start date'),
						});
					}
					return actions;
				},
			},
		});
	}

	return columns;
}

// Used when custom overtime feature is enabled
const tooltips = {
	noLocation: $.__('This employee has not been assigned to a location'),
	exempt: $.__('Overtime is not calculated for exempt employees'),
	noState: $.__('Overtime cannot be calculated without a U.S. state or Canadian Province selected'),
	international: $.__('Overtime cannot be calculated for addresses outside the U.S. or Canada'),
};

/**
 * Used when custom overtime feature is enabled
 * @param {boolean} customOvertimeEnabled
 */
function makeColumnsWithCustomOvertimeFeatureEnabled(customOvertimeEnabled) {
	const columns = [
		{
			header: $.__('Name'),
			cell: (employee) => {
				return (
					<Fragment>
						<ConditionalWrap
							condition={employee.canSeeEmployee}
							wrap={(children) => <a href={`/employees/employee.php?id=${employee.employeeId}`}>{children}</a>}
						>
							{employee.displayName}
						</ConditionalWrap>
						{employee.hasFutureStartDate && <div className='STE_EmpTable__startDate'>{employee.startsOnString}</div>}
					</Fragment>
				);
			},
			sortBy: (e) => e.displayName,
		},
		{
			header: $.__('Location'),
			cell: (employee) => {
				if (employee.hasLocation) {
					return employee.location;
				}

				return (
					<ReactTooltip
						settings={{
							content: tooltips.noLocation,
						}}
					>
						<div className='STE_EmpTable__warning STE_EmpTable__data--gray'>
							<span className='STE_EmpTable__warning__icon'>
								<Icon name='fab-triangle-exclamation-16x16' />
							</span>
							{$.__('No Location')}
						</div>
					</ReactTooltip>
				);
			},
			sortBy: (e) => e.location,
		},
		{
			header: $.__('Overtime State'),
			cell: (employee) => {
				employee.isOvertimeExempt = employee.isOvertimeExempt && nonExemptEnabled;
				if (employee.isOvertimeExempt) {
					return (
						<ReactTooltip settings={{ content: tooltips.exempt }}>
							<span className='STE_EmpTable__data--gray'>{$.__('Exempt')}</span>
						</ReactTooltip>
					);
				} else if (customOvertimeEnabled) {
					return $.__('Custom');
				} else if (employee.hasLocation) {
					if (!employee.overtimeState && employee.inOvertimeCountry === true) {
						return (
							<ReactTooltip settings={{ content: tooltips.noState }}>
								<div className='STE_EmpTable__warning STE_EmpTable__data--gray'>
									<span className='STE_EmpTable__warning__icon'>
										<Icon name='fab-triangle-exclamation-16x16' />
									</span>
									{employee.overtimeCountryISO === 'US' ? $.__('No State') : $.__('No Province')}
								</div>
							</ReactTooltip>
						);
					} else if (!employee.overtimeState && employee.inOvertimeCountry === false) {
						return (
							<ReactTooltip settings={{ content: tooltips.international }}>
								<span className='STE_EmpTable__data--gray'>{$.__('Non U.S. or Canada')}</span>
							</ReactTooltip>
						);
					}

					return employee.overtimeState;
				}

				return <span className='STE_EmpTable__data--gray'>-</span>;
			},
			sortBy: (e) => e.overtimeState,
		},
	];

	columns.push({
		header: $.__('Pay Schedule'),
		cell: (employee) => {
			if (employee.hasPaySchedule) {
				return employee.paySchedule;
			}
			return (
				<Fragment>
					<div className='STE_EmpTable__warning'>
						<span className='STE_EmpTable__warning__icon'>
							<Icon name='fab-triangle-exclamation-16x16' />
						</span>
						{$.__('No Pay Schedule')}
					</div>
				</Fragment>
			);
		},
		sortBy: (e) => e.paySchedule,
	});

	if (canToggleTimeTracking) {
		columns.push({
			showOnHover: true,
			headerAriaLabel: $.__('Actions'),
			cell: {
				type: 'actions',
				actions: (employee) => {
					const actions = [
						{
							icon: 'fab-circle-x-16x16',
							action: () => disableTimeTrackingForEmployee(employee.employeeId),
							tooltipContent: $.__('Disable Time Tracking'),
						},
					];
					if (employee.hasFutureStartDate) {
						actions.unshift({
							icon: 'fab-calendar-15x16',
							action: () => changeSingleSchedule(employee.employeeId),
							tooltipContent: $.__('Change scheduled start date'),
						});
					}
					return actions;
				},
			},
		});
	}

	return columns;
}

class EmployeesTrackingTime extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAddEmployeesButtonProcessing: false,
			isChangeAllStartDatesButtonProcessing: false,
			notificationModalOpen: false,
			notificationModalEmployees: [],
			notificationModalType: 'someEmployeesNotRescheduled',
			cancellationData: {},
		};
	}

	_onAddEmployeesClick = () => {
		this.setState({
			isAddEmployeesButtonProcessing: true,
		});

		const onRequestEnd = () => {
			this.setState({ isAddEmployeesButtonProcessing: false });
		};

		bulkModal.openModal(reloadEmployees, jsonData.showEnableOptionsWhenAddingEmployees, onRequestEnd);
	};

	_setStartProcessingChangeAllStartDatesButton = () => {
		this.setState({
			isChangeAllStartDatesButtonProcessing: true,
		});
	};

	_setStopProcessingChangeAllStartDatesButton = () => {
		this.setState({
			isChangeAllStartDatesButtonProcessing: false,
		});
	};

	_onSaveAllStartDates = (paySchedules) => {
		const dates = paySchedules.map((ps) => moment(ps.date).format('MMMM D, YYYY')).join(` ${$.__('and')} `);
		window.setMessage($.__('Got it! Time tracking will start %1$s', dates), 'success');
		reloadEmployees();
	};

	_onSaveAllStartDatesFail = (errorResponse) => {
		if (futureSchedulingEnabled) {
			this.setState({
				notificationModalOpen: true,
				notificationModalEmployees: errorResponse.employees,
				notificationModalType: errorResponse.type,
			});
			reloadEmployees();
		}
	};

	_classNamePrefix = uniqueId();

	render() {
		const {
			employees,
			timeTrackingTrialStatus: { daysRemainingInTrial, subscriptionRequested, trialActive, trialExtended },
		} = this.props;

		const {
			isAddEmployeesButtonProcessing,
			isChangeAllStartDatesButtonProcessing,
			notificationModalEmployees,
			notificationModalOpen,
			notificationModalType,
		} = this.state;

		return (
			<div>
				{trialActive && (
					<>
						<TrialBanner
							daysRemainingInTrial={daysRemainingInTrial}
							subscriptionRequested={subscriptionRequested}
							trialExtended={trialExtended}
						/>

						<div className='STS_Header'>
							<span className='STS_HeaderIcon STS_HeaderIcon--trial'>
								<Stopwatch17x20 />
							</span>
							<h4>
								{$.__('Employees Tracking Time')}
								{` (${employees.length})`}
							</h4>
						</div>
					</>
				)}

				{cancellationPending && isAdmin && <SadToGoBox cancellationData={cancellationData} isAddon={true} />}

				{(() => {
					if (!canToggleTimeTracking) {
						return null;
					}
					const changeStartDates =
						canChangeFutureDates &&
						(() => {
							this._setStartProcessingChangeAllStartDatesButton();
							// Need to update this to use the react modal... but for times sake...
							nonReactRender(
								'edit',
								this._setStopProcessingChangeAllStartDatesButton,
								this._onSaveAllStartDates,
								Function.prototype,
								[],
								this._onSaveAllStartDatesFail
							);
						});
					return (
						<Box mt={2} mb={1.25}>
							<PoMicroFrontend
								changeStartDates={changeStartDates ?? undefined}
								classNamePrefix={this._classNamePrefix}
								isChangingStartDates={isChangeAllStartDatesButtonProcessing}
								refresh={reloadEmployees}
								route='/time-tracking/settings/employee-actions'
							/>
						</Box>
					);
				})()}

				{timeTrackingEmployees.length === 0 && (
					<div className='STE_BlankState'>
						<BlankState
							button={
								<Button
									clickAction={this._onAddEmployeesClick}
									iconBefore='fab-circle-plus-14x14'
									isProcessing={isAddEmployeesButtonProcessing}
									outline={true}
									secondary={true}
									size='small'
									text={$.__('Add Employees')}
								/>
							}
							icon='fab-stopwatch-100x120'
							title={$.__(`There aren't any employees tracking time right now.`)}
						/>
					</div>
				)}

				{timeTrackingEmployees.length > 0 && (
					<Table
						caption='Employees tracking time'
						columns={
							customOvertimeFeatureEnabled
								? makeColumnsWithCustomOvertimeFeatureEnabled(this.props.configuration?.customOvertimeEnabled)
								: makeColumns()
						}
						initialSort={{ columnIndex: 0 }}
						rowKey={(r) => r.employeeId}
						rows={timeTrackingEmployees}
					/>
				)}

				{futureSchedulingEnabled && (
					<TimeTrackingNotificationModal
						employees={notificationModalEmployees}
						isOpen={notificationModalOpen}
						onClose={() => this.setState({ notificationModalOpen: false })}
						type={notificationModalType}
					/>
				)}
			</div>
		);
	}
}

function renderEmployeesTrackingTime(data) {
	render(<EmployeesTrackingTime {...data} />, document.getElementById('js-ste-html'));
}

function ConditionalWrap({ condition, wrap, children }) {
	return condition ? wrap(children) : children;
}
