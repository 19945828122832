import {
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		marginBottom: '40px',
	},
	modalContent: {
		display: 'grid',
		gridTemplateColumns: '150px 150px',
		gridTemplateRows: 'auto',
		rowGap: '10px',
		gridTemplateAreas: `
		"user user"
		"created last-used" 
		"url url" 
		"app app"`,
	},
	subsection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	title: {
		fontSize: '18px',
		fontWeight: 600,
	},
	adornedLayout: {
		marginBottom: '0px',
		alignItems: 'center'
	},
	adornment: {
		minHeight: '18px',
		height: '18px',
	},
	divider: {
		marginTop: '6px',
		marginBottom: '14px',
	},
	label: {
		fontSize: '14px',
		color: theme.palette.gray[700]
	},
	user: {
		gridArea: 'user'
	},
	created: {
		gridArea: 'created'
	},
	lastUsed: {
		gridArea: 'last-used',
	},
	url: {
		gridArea: 'url'
	},
	app: {
		gridArea: 'app',
	}
}));