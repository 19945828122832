import { Box, Flex, makeStyles } from '@bamboohr/fabric';
import { CircleExclamation13x13 } from '@bamboohr/grim';

interface SectionTitleProps {
	error?: string;
	isRequired?: boolean;
	note?: string;
	text: string;
}

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	errorMessage: {
		color: palette.error.main,
		fontSize: typography.teenie.fontSize,
		fontWeight: typography.fontWeightSemibold,
		marginLeft: spacing(1),
	},
	exclamationIcon: {
		fill: palette.error.main,
		marginRight: spacing(1),
	},
	label: {
		fontSize: typography.large.fontSize,
		fontWeight: typography.fontWeightSemibold,
	},
	legend: {
		margin: `0 0 ${spacing(2)}`,
	},
	note: {
		color: palette.grey[700],
		fontSize: typography.medium.fontSize,
		fontWeight: typography.fontWeightRegular,
	},
	requiredIcon: {
		color: palette.primary.main,
		marginLeft: '3px',
	},
}));

export const SectionTitle = (props: SectionTitleProps): JSX.Element => {
	const { error, isRequired, note, text } = props;
	const styles = useStyles();

	return (
		<legend className={styles.legend}>
			<Flex alignItems='center'>
				{error && <CircleExclamation13x13 className={styles.exclamationIcon} />}
				<Box className={styles.label}>{text}</Box>
				<Box className={styles.requiredIcon}>{isRequired && '*'}</Box>
				{error && (
					<Flex alignItems='center'>
						<Box className={styles.errorMessage}>{error}</Box>
					</Flex>
				)}
			</Flex>
			{note && <Box className={styles.note}>{note}</Box>}
		</legend>
	);
};
