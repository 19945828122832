import React from 'react';
import { FullScreenModal } from '@bamboohr/fabric';

export const FullScreenUpsell = ({ children, isOpen, onRequestClose, title }) => {
	return (
		<FullScreenModal isOpen={isOpen} onRequestClose={onRequestClose}>
			<FullScreenModal.Body renderHeader={<FullScreenModal.Header title={title} />}>
				<FullScreenModal.Constraint
					spacingOverrides={{
						top: FullScreenModal.Constraint.Spacing.LARGE,
					}}
				>
					{children}
				</FullScreenModal.Constraint>
			</FullScreenModal.Body>
		</FullScreenModal>
	);
};
