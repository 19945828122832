import Ctrl, {
	onReady,
	onPjaxEnd
} from 'ctrl.deco';

import { render } from 'base/wrapped-render';
import { getJsonData } from 'Data.util';

import OfferTemplates from './component';

const APP_ELEM = 'settings-offer-templates-app';

@Ctrl('/settings/jobs/templates.php/offerLetterTemplates')
export default class SettingsOfferTemplates {
	constructor() {
		this._pageData = getJsonData('#js-offerTemplates', true);
	}

	@onReady()
	_readyHandler(e, ctrl) {
		ctrl.renderOfferTemplatesPage(ctrl._pageData);
	}

	@onPjaxEnd()
	_pjaxEndHandler(e, ctrl) {
		window.requestAnimationFrame(() => {
			const data = getJsonData('#js-offerTemplates', true);
			ctrl.renderOfferTemplatesPage(data);
		});
	}

	renderOfferTemplatesPage(pageData) {
		render(
			<OfferTemplates { ...pageData } />,
			document.getElementById(APP_ELEM)
		);
	}
}
