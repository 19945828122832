import {
	Message,
	ExternalLink,
} from '@bamboohr/utils/lib/message';

import {
	bem,
} from '../utils';

import {
	SettingsAppsProvider,
} from './context';
import AppList from './components/app-list';

import { ifFeature } from "@bamboohr/utils/lib/feature";
import { BodyText } from '@bamboohr/fabric';

import './styles.styl';


const SettingsApps = ({
	appList,
}) => (
	<SettingsAppsProvider value={ { appList } }>
		<div className={ bem() }>
			{ifFeature('encore',
				<h5 className='SettingsApps__installHeaders--installed'>
					{ $.__('Installed') }
				</h5>,
				<h4>
					{ $.__('Installed') }
				</h4>
			)}
			<AppList installed={ true } />
			<hr class="fab-borderBottom--neutral-weak"/>
			{ifFeature('encore',
				<h5 className='fab-marginTop--20 SettingsApps__installHeaders'>
					{ $.__('Not Installed') }
				</h5>,
				<h4>
					{ $.__('Not Installed') }
				</h4>
			)}
			<p className={ bem('msg', 'uninstalled') }>
				{ifFeature('encore', 
					<BodyText size="small">
						<Message
							link={ ExternalLink(`https://marketplace.bamboohr.com`) }
							text={ $._(`These are the apps that you can install directly from BambooHR, but there are many more available in the [BambooHR Marketplace]`) }
						/>
					</BodyText>,
					<Message
						link={ ExternalLink(`https://marketplace.bamboohr.com`) }
						text={ $._(`These are the apps that you can install directly from BambooHR, but there are many more available in the [BambooHR Marketplace]`) }
					/>
				)}
			</p>
			<AppList />
		</div>
	</SettingsAppsProvider>
);

SettingsApps.displayName = 'SettingsApps';

export default SettingsApps;
