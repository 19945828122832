/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
// This module expects existing elements to exist in the UI when the page
// renders. You can add these elements to your twig template using the
// following twig template: /payroll/includes/withholding.html.twig
import { defaultsDeep, isNil } from 'lodash';

import PERM_LEVEL from '../constants/permission-level';
import errorBlankTemplate from './templates/blank-wholding.micro.html';
import federalTemplate from './templates/federal-wholding.micro.html';
import stateTemplate from './templates/state-wholding.micro.html';
import exemptTemplate from './templates/exempt-wholding.micro.html';
import './styles.styl';

const WITHHOLDING_PARENT = {
	federal: $('#js-wholdings-federal'),
	state: $('#js-wholdings-state'),
};
const FEDERAL = 'federal';
const STATE = 'state';

/**
 * Parent function that handles initializing state and local wholding
 * @param  {number}   fedPerm          The perm level for federal tax deductions
 * @param  {number}   statePerm        The perm level for state tax deductions
 * @param  {number}   empId            The current employees's ID
 * @param  {Boolean}  hasTrax          Flag to indicate if Trax is enabled
 * @param  {object}   data             Withholding object
 * @param  {object}   options          [update=false] Required for federal withholding rendering
 * @param  {function} handle2020Update Allows lower-level function to update state withing withholding wrapper
 */
export function populateFederalAndState(fedPerm, statePerm, hasTrax, empId, data, options = {}, handle2020Update) {
	WITHHOLDING_PARENT.federal = $('#js-wholdings-federal');
	WITHHOLDING_PARENT.state = $('#js-wholdings-state');

	let defaults = {
		federal: '/payroll_ajax/taxDeductions/federal/',
		state: '/payroll_ajax/taxDeductions/state/',
		update: false,
		stateTpl: stateTemplate,
		fedTpl: federalTemplate,
	};

	const perms = {
		PERM_LEVEL,
		federal: fedPerm,
		state: statePerm,
		hasTrax,
		isEncore: ifFeature('encore', true, false),
	};

	defaults = defaultsDeep(options, defaults);
	populateFederalWithholding(empId, perms, defaults, handle2020Update);

	if (hasTrax) {
		if (data && data.state.name !== null) {
			$('.js-pi-state-num').text(data.state.number);
			$('.js-piStateName').text(data.state.name);
			$('.js-piWithholdWritten').html(buildWithHoldingStringForStateOnly(data, 'present'));
		} else {
			$('.js-pi-withholdings-state').hide();
		}
	} else {
		populateStateWithholding(empId, perms, defaults);
	}
}

/*
 * Validate tax states, update DOM and return isValid
 * if fields are valid return isValid = true
 * if fields are not valid return isValid = false
 * updates DOM accordingly with UI error states
 */
export function validateTaxStates() {
	let isValid = true;

	if ($('.js-employee_tax_states_container').length > 0) {
		const $taxStates = $('.js-employee_tax_states');
		const $taxStatesSelected = $('.js-employee_tax_states ba-option:selected');
		const $taxStatesSelectedFieldbox = $taxStates.closest('.fieldBox');
		const $unemploymentState = $('.js-employee_unemployment_state');
		const $unemploymentStateFieldbox = $unemploymentState.closest('.fieldBox');
		const $residentTaxState = $('.js-employee_resident_tax_state');
		const $residentTaxStateFieldBox = $residentTaxState.closest('.fieldBox');

		if ($taxStatesSelected.length == 0) {
			$taxStatesSelectedFieldbox.addClass('error');
			isValid = false;
		} else {
			$taxStatesSelectedFieldbox.removeClass('error');
		}

		if ($unemploymentState.val() == '') {
			$unemploymentStateFieldbox.addClass('error');
			isValid = false;
		} else {
			$unemploymentStateFieldbox.removeClass('error');
		}

		if ($residentTaxState.val() == '') {
			$residentTaxStateFieldBox.addClass('error');
			isValid = false;
		} else {
			$residentTaxStateFieldBox.removeClass('error');
		}
	}
	return isValid;
}

/**
 * Populate existing withholding information
 * @param  {Object} data
 */
export function handleWithholdingData(data) {
	if (data && data.federal.number !== null && data.state.number !== null) {
		$('.js-pi-federal-num').text(data.federal.number);
		$('.js-pi-state-num').text(data.state.number);
		$('.js-piStateName').text(data.state.name);
		$('.js-piWithholdWritten').html(buildWithHoldingString(data, 'present'));
	} else {
		$('#js-pi-withholdings').hide();
	}
}

/**
 * Utility function for building translated strings
 * @param  {object} withholdingObject
 * @param  {string} tense
 * @return {string}
 */
let buildWithHoldingString = function (withholdingObject, tense) {
	const data = withholdingObject;
	const fed = {
		past: $.__('Your Federal Withholding was'),
		present: $.__('Your Federal Withholding is'),
	};

	const state = {
		past: $.__('and your State Withholding was'),
		present: $.__('and your State Withholding is'),
	};

	const additional = $.__('plus an additional');

	let fedString = `${ fed[tense] } <span class="bold">${ data.federal.number }</span>`;
	if (data.federal.additional !== null) {
		fedString += ` (${ additional } ${ data.federal.additional })`;
	}

	let stateString = `${ state[tense] } <span class="bold">${ data.state.number }</span>`;
	if (data.state.additional !== null) {
		stateString += ` (${ additional } ${ data.state.additional })`;
	}

	return `${ fedString }, ${ stateString }.`;
};

/**
 * Utility function for building translated strings
 * @param  {object} withholdingObject
 * @param  {string} tense
 * @return {string}
 */
let buildWithHoldingStringForStateOnly = function (withholdingObject, tense) {
	const data = withholdingObject;

	const state = {
		past: $.__('Your State Withholding was'),
		present: $.__('Your State Withholding is'),
	};

	const additional = $.__('plus an additional');

	let stateString = `${ state[tense] } <span class="bold">${ data.state.number }</span>`;
	if (data.state.additional !== null) {
		stateString += ` (${ additional } ${ data.state.additional })`;
	}
	return `${ stateString }.`;
};

/**
 * Run on federal successful response
 * @param  {object}  data   The data response
 * @param  {object}  perms  The perms object
 * @param  {object} defaults Contains Federal Withholding Template to be used and Update Boolean representing whether to update or not.
 * @param  {object}  tpl    Federal withholding template
 */
const onSuccessFederal = function (data, perms, defaults, tpl) {
	if (data.success && data.federalTaxInfo) {
		if (defaults.update) {
			tpl = $('#js-wholdings-federal');
		}
		if (data.federalTaxInfo.fedWithholdExemptionFlag && !data.hasTrax) {
			populateExemptState();
		} else {
			tpl.find('.js-wholdings-federal-status').val(data.federalTaxInfo.fedStatus).attr('data-initial-value', data.federalTaxInfo.fedStatus).trigger('liszt:updated')
				.find(`ba-option[value="${ data.federalTaxInfo.fedStatus }"]`)
				.attr('selected', true);
			tpl.find('.js-wholdings-federal-dependents').val(data.federalTaxInfo.fedDependents).attr('data-initial-value', data.federalTaxInfo.fedDependents);
			if (data.federalTaxInfo.fedWithholdExtraType === '$' || data.federalTaxInfo.fedWithholdExtraType === 'FLAT') {
				tpl.find('.js-fedWithholdExtraType-1').attr('checked', true).attr('data-initial-value', '$');
				tpl.find('.js-fedWithholdExtraType-2').attr('checked', false);
			} else {
				tpl.find('.js-fedWithholdExtraType-1').attr('checked', false);
				tpl.find('.js-fedWithholdExtraType-2').attr('checked', true).attr('data-initial-value', '%');
			}
			if (!isNil(data.federalTaxInfo.fedWithholdExtraValue)) {
				tpl.find('.js-wholding-federal-extra').val(data.federalTaxInfo.fedWithholdExtraValue).attr('data-initial-value', data.federalTaxInfo.fedWithholdExtraValue);
			} else {
				tpl.find('.js-wholding-federal-extra').val('0').attr('data-initial-value', '0');
			}
			if (data.federalTaxInfo.fedWithholdExtraType === '$' || data.federalTaxInfo.fedWithholdExtraType === 'FLAT') {
				tpl.find('.js-wholding-federal-extra').addClass('currency-field');
			} else {
				tpl.find('.js-wholding-federal-extra').addClass('percent-field');
			}
			if (data.hasTrax && data.federalTaxInfo.headOfHousehold) {
				tpl.find('.js-headOfHousehold').attr('checked', true);
			} else if (data.hasTrax && !data.federalTaxInfo.headOfHousehold) {
				tpl.find('.js-headOfHousehold').attr('checked', false);
			}
			if (data.hasTrax && data.federalTaxInfo.fedWithholdExemptionFlag) {
				tpl.find('.js-fedWithholdingTaxExempt').attr('checked', true);
				tpl.find('.js-wholdings-federal-status').disable();
				tpl.find('.js-wholdings-federal-dependents').disable();
				tpl.find('.js-headOfHousehold').disable();
			} else if (perms.federal === perms.PERM_LEVEL.VIEW_EDIT && !perms.is2020Release) {
				tpl.find('.js-fedWithholdingTaxExempt').attr('checked', false);
				tpl.find('.js-wholdings-federal-status').enable();
				tpl.find('.js-wholdings-federal-dependents').enable();
				tpl.find('.js-fedWithholdExtraType-1').enable();
				tpl.find('.js-fedWithholdExtraType-2').enable();
				tpl.find('.js-wholding-federal-extra').enable();
				tpl.find('.js-headOfHousehold').enable();
			}
			if (!defaults.update) {
				WITHHOLDING_PARENT.federal.find('.js-wholdings-loader').hide();
				WITHHOLDING_PARENT.federal.append(tpl);
			}
		}
	} else {
		populateErrorState(FEDERAL);
	}
};


/**
 * Run on state successful response
 * @param  {object}  data   The response data
 * @param  {object}  perms  The perms object
 * @param  {object}  defaults The defaults object, contains State Witholdings template to be used
 */
const onSuccessState = function (data, perms, defaults) {
	if (data.success) {
		WITHHOLDING_PARENT.state.html('');

		let stateSelected = false;
		$.each(data.stateTaxInformation, (state, stateInfo) => {
			stateInfo.state = state;
			if (stateInfo.selected === true) {
				$(`.js-employee_tax_states option[value=${ state }]`).attr('selected', 'selected');
				$('.js-employee_tax_states').trigger('liszt:updated');
				stateSelected = true;
			}
			if (!isStateInfoEmpty(stateInfo) && isStateTaxExempt(stateInfo)) {
				populateExemptState(stateInfo);
			} else if (!isStateInfoEmpty(stateInfo)) {
				stateInfo.perms = perms;
				WITHHOLDING_PARENT.state.append(window.microTemplate(defaults.stateTpl, stateInfo));
			}
		});
		if (!stateSelected) {
			$('.js-employee_tax_states').trigger('liszt:updated');
		}

		$('.js-pi-altw4').on('ba:selectChange', function (event) {
			const parentContainer = $(this).closest('.js-state-container');

			if (event.originalEvent.detail.value == 'Yes') {
				parentContainer.find(':input:not(.js-pi-altw4), ba-select:not(.js-pi-altw4)').each(function () {
					$(this).attr('disabled', false).trigger('liszt:updated');
				});
				parentContainer.find('.bhrToggle--btn').removeClass('bhrToggle--btn--disabled');
			} else {
				parentContainer.find(':input:not(.js-pi-altw4), ba-select:not(.js-pi-altw4)').each(function () {
					$(this).attr('disabled', true).trigger('liszt:updated');
				});
				parentContainer.find('.bhrToggle--btn').addClass('bhrToggle--btn--disabled');
			}
		});
	} else {
		populateErrorState(STATE);
	}

	function isStateTaxExempt(stateInfo) {
		return stateInfo.fields.ST_WH_EXE_FGE.value == 1;
	}

	function isStateInfoEmpty(stateInfo) {
		return $.isEmptyObject(stateInfo.fields);
	}
};

/**
 * [populateErrorState description]
 * @param  {[type]} wholdingType [description]
 */
let populateErrorState = function (wholdingType) {
	const html = window.microTemplate(errorBlankTemplate, {});
	WITHHOLDING_PARENT[wholdingType].html(html);
};

/**
 * Update federal or state with exempt status
 * @param  {object} [stateInfo] Contains state information
 */
let populateExemptState = function (stateInfo) {
	const isEncore = ifFeature('encore', true, false);
	if (stateInfo) {
		WITHHOLDING_PARENT.federal.append(window.microTemplate(exemptTemplate, { isEncore, stateName: stateInfo.stateName }));
	} else {
		WITHHOLDING_PARENT.federal.html(window.microTemplate(exemptTemplate, { isEncore, stateName: '' }));
	}
};

/**
 * Get information about state withholding for current employee
 * @param  {number}  empId  The current employee's ID
 * @param  {object}  perms  The perms object
 */
let populateStateWithholding = function (empId, perms, defaults) {
	$.ajax({
		type: 'GET',
		url: defaults.state + empId,
		dataType: 'json',
	})
		.success((data) => {
			onSuccessState(data, perms, defaults);
		})
		.fail(() => {
			populateErrorState(STATE);
		});
};

/**
 * Get information about federal withholding for current employee
 * @param  {number}   empId         The current employee's ID
 * @param  {object}   perms         The perms object
 * @param  {object}   defaults      The default settings object
 * @param  {function} handleFed2020 Handles population of 2020 W4 data
 */
let populateFederalWithholding = function (empId, perms, defaults, handleFed2020) {
	$.ajax({
		method: 'GET',
		url: defaults.federal + empId,
		dataType: 'json',
	})
		.success((data) => {
			const {
				federalTaxInfo,
				is2020Release = false,
				has2020W4 = false,
				federal2020,
				hasTrax,
			} = data;

			perms.is2020Release = is2020Release;

			const tpl = $(window.microTemplate(defaults.fedTpl, perms));

			onSuccessFederal(data, perms, defaults, tpl);

			if (hasTrax) {
				activateTraxSpecificValidationOnChanges(tpl, perms, federalTaxInfo.fedStatus);
			}

			if (is2020Release && typeof handleFed2020 === 'function') {
				handleFed2020(is2020Release, has2020W4, federal2020);
			}

		})
		.fail(() => {
			populateErrorState(FEDERAL);
		});

};

/**
 * @param {object} tpl   federal template
 * @param {object} perms the perms object
 * @param {string} fedStatusVal - federal withholding status
 */
function activateTraxSpecificValidationOnChanges(tpl, perms, fedStatusVal) {
	window.currentFedStatus = fedStatusVal;
	checkHeadOfHouseholdOptions(tpl, window.currentFedStatus);

	$('.js-wholdings-federal-status').on('ba:selectChange', (event) => {
		window.currentFedStatus = event.originalEvent.detail.value;
		checkHeadOfHouseholdOptions(tpl, window.currentFedStatus);
	});

	$('.js-headOfHousehold').on('change', () => {
		checkHeadOfHouseholdOptions(tpl, window.currentFedStatus);
	});

	$('.js-fedWithholdingTaxExempt').on('change', () => {
		checkHeadOfHouseholdOptions(tpl, window.currentFedStatus);
		changeFederalWithholdingInputState(perms, window.currentFedStatus);
	});
}

/**
 * @param {object} tpl federal template
 * @param {string} value - current value of federal withholding status
 */
function checkHeadOfHouseholdOptions(tpl, value) {
	if (value === 'Married') {
		tpl.find('.js-headOfHousehold').enable();
	} else {
		tpl.find('.js-headOfHousehold').attr('checked', false);
		tpl.find('.js-headOfHousehold').disable();
	}

}

/**
 *
 * @param {object} perms the perms object
 */
function changeFederalWithholdingInputState(perms, fedStatus) {
	if ($('.js-fedWithholdingTaxExempt').is(':checked')) {
		$('.js-wholdings-federal-status').disable();
		$('select').trigger('liszt:updated');
		$('.js-wholdings-federal-dependents').disable();
		$('.js-headOfHousehold').disable();
		$('.js-headOfHouseholdText').hide();
	} else if (perms.federal === perms.PERM_LEVEL.VIEW_EDIT) {
		$('.js-wholdings-federal-status').enable();
		$('select').trigger('liszt:updated');
		$('.js-wholdings-federal-dependents').enable();
		$('.js-fedWithholdExtraType-1').enable();
		$('.js-fedWithholdExtraType-2').enable();
		$('.js-wholding-federal-extra').enable();
		if (fedStatus === 'Married') {
			$('.js-headOfHousehold').enable();
		}
		$('.js-headOfHouseholdText').show();
	}
}
