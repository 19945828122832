import {
	Fragment,
	useEffect,
	useMemo,
	useState,
} from 'react';

import {
	Select,
} from '@fabric/select';

import {
	useModalData,
} from '../state';
import {
	submitModal,
} from '../util';

export default function GroupSelector() {
	const {
		groups,
		userIds,
		employeeIds,
	} = useModalData();
	const [selectedValues, setSelectedValues] = useState([]);
	const hasSelectedValues = selectedValues.length > 0;
	const items = useMemo(() => groups
		.map(({
			id: value,
			name: text,
		}) => ({
			text,
			value,
		})), [groups]);

	useEffect(() => {
		window.BambooHR.Modal.setState({
			primaryAction: hasSelectedValues ? () => {
				submitModal({
					userIds,
					employeeIds,
					accessLevelIds: selectedValues,
				});
			} : null,
		}, true);
	}, [hasSelectedValues]);

	return (
		<Select
			items={ items }
			name="accessLevel"
			onChange={ (values) => {
				setSelectedValues(values);
			} }
			placeholder={ $.__(`–Select Access Level–`) }
			selectedValues={ selectedValues }
			width="8"
		/>
	);
}
