import { TextButton as EncoreTextButton, Flex, Icon, IconV2, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { TextButton } from '@fabric/button';
import { Fragment } from 'react';
import { ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED } from '../../../../../../reports/shared/feature-toggles';
import { AnswerScale } from '../../../../../shared/answer-scale.react';
import { SurveyPage } from '../constants';
import { IconHeading } from '../heading';
import { Question } from '../question';
import './scale-answer-page.styl';

export function ScaleAnswerPage(props) {
	const { company, currentPage, initialChoice, onAnonymityInfoClick, onChange, radioChoices, score } = props;

	return (
		<Fragment>
			{currentPage === SurveyPage.One ? (
				<div>
					{ifFeature(
						'encore',
						<Flex width='100%' justifyContent='center' marginY={3}>
							<IconV2 name='face-smile-solid' size={48} color='primary-strong' />
						</Flex>,
						<IconHeading iconName='fab-smile-48x48' />
					)}
					<ol className='EnpsScaleAnswer__questions'>
						<Question
							subtext={
								initialChoice || initialChoice === 0
									? ifFeature(
											'encore',
											$.__(
												"You've already answered this question with a %1, but you can change your answer if you'd like.",
												initialChoice
											),
											$.__('You already answered %1 to this, but you can change it if you’d like. ', initialChoice)
									  )
									: null
							}
							text={$.__('How likely are you to recommend %1 as a place to work?', company)}
						>
							{ifFeature(
								'encore',
								<LayoutBox marginY={3}>
									<AnswerScale answers={radioChoices} onSelect={onChange} selected={score} useLargeText={true} />
								</LayoutBox>,
								<div className='EnpsScaleAnswer__scale'>
									<AnswerScale answers={radioChoices} onSelect={onChange} selected={score} useLargeText={true} />
								</div>
							)}
						</Question>
					</ol>
					<div className='ConductSurvey__privacyMessage'>
						{ifFeature('encore', undefined, <Icon name='fab-lock-10x12' />)}
						<span className='ConductSurvey__privacyMessageText'>
							{ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED ? (
								<>
									{ifFeature(
										'encore',
										<LayoutBox display='inline-block' marginRight={0.5} position='relative' top='1px'>
											<IconV2 name='lock-regular' size={16} color='neutral-strong' />
										</LayoutBox>
									)}
									{$.__('Your feedback will be kept completely ')}
									{ifFeature(
										'encore',
										<EncoreTextButton onClick={onAnonymityInfoClick} type='button' inline>
											{$.__('anonymous')}
										</EncoreTextButton>,
										<>
											<TextButton onClick={onAnonymityInfoClick} type='button'>
												{$.__('anonymous')}
											</TextButton>
											.
										</>
									)}
								</>
							) : (
								<Message
									link={ExternalLink(
										'https://help.bamboohr.com/hc/en-us/articles/360006948434-Employee-Satisfaction-from-the-Employee-s-Perspective'
									)}
									text={$._('Your feedback will be kept completely [anonymous].')}
								/>
							)}
						</span>
					</div>
				</div>
			) : null}
		</Fragment>
	);
}
