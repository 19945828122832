import { SlidedownPortal } from '@bamboohr/fabric';
import { useEffect, useState } from 'react';

import { usePerformanceSettingsContext } from '../../context';

export const SlidedownController = (): JSX.Element => {
	const [{ slidedownData }] = usePerformanceSettingsContext();
	const { message, type } = slidedownData;

	const [showSlidedown, setShowSlidedown] = useState(false);

	useEffect(() => {
		if (message) {
			setShowSlidedown(true);
		}
	}, [slidedownData]);

	return (
		<SlidedownPortal
			message={ message }
			onDismiss={ () => setShowSlidedown(false) }
			show={ showSlidedown }
			type={ type }
		/>
	)
}