import { Modal } from 'modal-legacy';


export function EnpsModal(props) {
	const { children, isVisible, onCloseModal, options } = props;
	return (
		<Modal
			content={ children }
			isOpen={ isVisible }
			onClose={ onCloseModal }
			{ ...options }
		/>
	);
}
