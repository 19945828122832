import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';

export function buildDeductionSelectOptions(plan) {
	const deductionNumbers = [1, 2];
	const options = [];

	if (plan && plan.allowedDeductionDirections) {
		deductionNumbers.forEach((number) => {
			plan.allowedDeductionDirections.forEach((direction) => {
				options.push(getDeductionOption(plan, number, direction));
			});
		});
	}
	return options;
}

export function getDeductionOption(plan, value, direction) {
	const itemTextDictionary = {
		before: {
			1: <Message text={$.__('**One** pay date **before** the benefit effective date')} />,
			2: <Message text={$.__('**Two** pay dates **before** the benefit effective date')} />,
		},
		after: {
			1: <Message text={$.__('**One** pay date **after** benefit effective date')} />,
			2: <Message text={$.__('**Two** pay date **after** benefit effective date')} />,
		},
	};

	return {
		isMostCommon: (value === 1 && direction === 'after'),
		note: value === 1 && direction === 'after' ? $.__('Most Common') : undefined,
		postValue: {
			deductionRuleDirection: direction,
			deductionRuleNumber: value,
			id: plan.id,
			isRollup: plan.isRollup,
			name: plan.name,
			type: plan.type,
		},
		text: ifFeature(
			'encore',
			itemTextDictionary[direction][value] || '',
			`${ (value === 2) ? $.__('Two pay dates') : $.__('One pay date') } ${ (direction === 'before') ? $.__('before') : $.__('after') } ${ (value === 1 && direction === 'after') ? $.__('the benefit effective date') : $.__('benefit effective date') }`,
		),
		value: `${ plan.type }-${ direction }-${ value }`,
	};
}
