import Ctrl, {
	onReady,
	onPjaxEnd
} from 'ctrl.deco';

import { render } from 'base/wrapped-render';
import { getJsonData } from 'Data.util';

import EmailTemplates from './component';

const APP_ELEM = 'settings-email-templates-app';

@Ctrl('/settings/jobs/templates.php/emailTemplates')
export default class SettingsEmailTemplates {
	constructor() {
		this._pageData = getJsonData('#js-emailTemplates', true);
	}

	@onReady()
	_readyHandler(e, ctrl) {
		ctrl.renderEmailTemplatesPage(ctrl._pageData);
	}

	@onPjaxEnd()
	_pjaxEndHandler(e, ctrl) {
		window.requestAnimationFrame(() => {
			const data = getJsonData('#js-emailTemplates', true);
			ctrl.renderEmailTemplatesPage(data);
		});
	}

	renderEmailTemplatesPage(pageData) {
		render(
			<EmailTemplates { ...pageData } />,
			document.getElementById(APP_ELEM)
		);
	}
}
