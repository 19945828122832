/**
 * Will return the brand theme name Example: "lime2"
 * Fallback to sending null
 *
 * @method getBrandColor
 * @returns {*}
 */
export function getBrandColor() {
	let brandColor = window.document.body.getAttribute('ba-theme');
	if (!brandColor) {
		brandColor = 'lime1';
	}

	return brandColor;
}
