/* @startCleanup encore */
import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette }) => ({
    icon: {
        fill: palette.error.main,
    },
    iconWrapper: {
        marginTop: '18px',
    }
}));
/* @endCleanup encore */