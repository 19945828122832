import { Component, Fragment } from 'react';

import { MarketingPromo } from './marketing-promo';
import { Steps } from './steps';
import { scheduleEnpsDemo } from '../../settings-enps-service';

export class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isDemoScheduled: props.data.userScheduledDemo,
			isProcessing: false,
		};
	}

	_handleContactBtnPress = () => {
		const { isDemoScheduled } = this.state;
		this.setState({ isProcessing: true });

		if (!isDemoScheduled) {
			scheduleEnpsDemo();
			this.setState({ isDemoScheduled: true, isProcessing: false });
		}
	};

	render() {
		const { data } = this.props;
		const { isDemoScheduled, isProcessing } = this.state;

		return (
			<Fragment>
				{data && data.displayPromoPage ? (
					<MarketingPromo
						isDemoScheduled={isDemoScheduled}
						isProcessing={isProcessing}
						onContactBtnPress={this._handleContactBtnPress}
					/>
				) : (
					<Steps data={data} />
				)}
			</Fragment>
		);
	}
}
