import React from "react";

import {
    BodyText,
    Button,
    Headline,
    LayoutBox,
    StandardModal
} from '@bamboohr/fabric';
// @startCleanup encore
import { GovBuilding16x16 } from "@bamboohr/grim";
// @endCleanup encore
import { useStyles } from './global-payee-edit-modal.styles';
import { Message } from "@bamboohr/utils/lib/message";

import { ifFeature } from "@bamboohr/utils/lib/feature";


export function GlobalPayeeEditModal(props) {

    const {
        visible,
        onClose,
        payee,
    } = props;

    const styles = useStyles();

    return(
        <StandardModal isOpen={ visible } onRequestClose={ onClose }>
            <StandardModal.Body
                renderFooter={(
                    <StandardModal.Footer
                        actions={[
                            <Button
                                key='button'
                                onClick={ onClose }
                                type='button'
                            >
                                {$.__('Done')}
                            </Button>
                        ]}
                    />
                )}
                renderHeader={(
                    <StandardModal.Header
                        hasCloseButton={ true }
                        title={ ($.__('Payee Details')) }
                    />
                )}
            >
                <StandardModal.Constraint>
                    <section className={ styles.employeeModalDescription }>
                        <div className={ styles.modalTitleSection }>
                            {ifFeature(
                                'encore',
                                <LayoutBox marginBottom={1}>
                                    <Headline component="h5" icon="building-columns-regular" size="small">
                                        {$.__('Shared Payee')}
                                    </Headline>
                                </LayoutBox>,
                                <>
                                    <span><GovBuilding16x16 className={styles.modalIcon}/></span>
                                    <h5 className={styles.modalTitle}>{$.__('Shared Payee')}</h5>
                                </>
                            )}
                        </div>
                        {ifFeature(
                            'encore',
                            <BodyText>
                                <Message text={$.__('This payee was set up by BambooHR and is shared by multiple organizations. **If any of the information for this payee needs to be updated, please contact our payroll support team.**')} />
                            </BodyText>,
                            <p className={styles.modalDescription}>
                                {$.__('This payee was set up by BambooHR and is shared by multiple organizations.')}
                                <strong>{$.__(' If any of the information for this payee needs to be updated, please contact our payroll support team.')}</strong>
                            </p>
                        )}
                    </section>
                    <section className={ styles.employeeContactInfo }>
                        <div className={ styles.employeeInfoSection }>
                            <div className={ styles.employeeInfoLabel }>{ $.__('Payee Name') }</div>
                            <div>{ payee.payeeName }</div>
                        </div>
                        <div className={ styles.employeeInfoSection }>
                            <div className={ styles.employeeInfoLabel }>{ $.__('Address') }</div>
                            <address className={ styles.employeeAddress }>
                                <div>{ payee.addressLine1 }</div>
                                <div>{ payee.addressLine2 }</div>
                                <div>{ payee.city }, { payee.state } { payee.zipcode }</div>
                            </address>
                        </div>
                        { payee.phone && (
                            <div className={ styles.employeeInfoSection }>
                                <div className={ styles.employeeInfoLabel }>{ $.__('Phone Number') }</div>
                                <div>{ payee.phone }</div>
                            </div>
                        ) }
                        { payee.email && (
                            <div className={ styles.employeeInfoSection }>
                                <div className={ styles.employeeInfoLabel }>{ $.__('Email') }</div>
                                <div>{ payee.email }</div>
                            </div>
                        ) }
                    </section>
                </StandardModal.Constraint>
            </StandardModal.Body>
        </StandardModal>
    )
}
