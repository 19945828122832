import { bothGroupsHaveSelectionsForFilter, groupHasSelectionForFilter } from './group-selections';
import { ALL_FILTER_TYPES } from '../eligibility-filters/constants';
import { FilterSelections, FilterValue } from '../../types';
import { SupersetType } from '../../../etc/superset-type';

export const groupsHaveUniqueSelectionsForFilter = (
	groupOneSelections: FilterValue[],
	groupTwoSelections: FilterValue[]
): boolean => {
	let hasUniqueSelections = true;
	for (const selectedValue of groupOneSelections) {
		if (groupTwoSelections.includes(selectedValue)) {
			hasUniqueSelections = false;
		}
	}

	return hasUniqueSelections;
};

// Superset/subset if the same selection is made for at least one category
// and one group (and only one group) has at least one category with selections
// where the other group has no selections in that category
export const getSupersetGroup = (
	firstGroup: FilterSelections,
	secondGroup: FilterSelections
): SupersetType => {
	let superset = SupersetType.None;
	for (const filterType of ALL_FILTER_TYPES) {

		if (bothGroupsHaveSelectionsForFilter(firstGroup, secondGroup, filterType)) {
			if (groupsHaveUniqueSelectionsForFilter(firstGroup[filterType], secondGroup[filterType])) {
				return SupersetType.None;
			}

		} else if (groupHasSelectionForFilter(firstGroup, filterType)) {
			if (superset === SupersetType.FirstIsSuperset) {
				return SupersetType.None;
			}
			superset = SupersetType.SecondIsSuperset;

		} else if (groupHasSelectionForFilter(secondGroup, filterType)) {
			if (superset === SupersetType.SecondIsSuperset) {
				return SupersetType.None;
			}
			superset = SupersetType.FirstIsSuperset;
		}
	}

	return superset;
};
