
export enum EligibilityOption {
	notEligible = 'Not Eligible',
	eligible = 'Eligible',
}

export interface EmployeeEligibility {
	[id: number]: EligibilityData;
}

export interface EligibilityData {
	displayName: string;
	id: number;
	eligibility: EligibilityOption;
	effectiveDate: string;
}

export interface EligibleEmployee {
	id: number;
}

export type UpdateEligibility = (id: number, value: EligibilityOption) => void;
export type UpdateEffectiveDate = (id: number, date: string) => void;
