import {
	useState,
	useCallback,
} from 'react';

import { Message } from '@bamboohr/utils/lib/message';

import {
	cloneDeep,
	get,
} from 'lodash';

import {
	showSlidedown,
} from 'Alerts.mod';

import {
	Button,
	StandardModal,
	makeStyles,
} from '@bamboohr/fabric';

import {
	EnrolledEmployeesModal,
	AddBenefitPlanForm,
} from '../components';
import {
	AddPlanFormDataObject,
	DeductionObject,
	FieldObject,
} from '../types';
import {
	buildCreateBenefitPlanDeductionPayload,
	responseMessages,
} from '../utils';
import {
	createBenefitPlanDeduction,
} from '../api';

interface AddBenefitPlanModalProps {
	type: string;
	typeName: string;
	deduction: DeductionObject;
	isOpen: boolean;
	onClose(): void;
	updateDeductions: (newDeductionData: DeductionObject) => void;
}

const useStyles = makeStyles(({typography, palette}) => ({
	container: {
		maxWidth: 655,
	},
	tellUsMore: {
		color: palette.grey[700],
		fontSize: typography.fabricFontSize('small'),
		marginBottom: 19,
	},
	planNameLabel: {
		color: palette.grey[600],
		fontSize: typography.fabricFontSize('small'),
		marginBottom: 2,
	},
	enrollmentInfo: {
		fontSize: typography.fabricFontSize('small'),
		margin: '13px 0 21px',
	},
}));

export function AddBenefitPlanModal(props: AddBenefitPlanModalProps): JSX.Element {
	const styles = useStyles();

	const {
		type,
		typeName,
		deduction,
		deduction: {
			deductionName,
			employees,
			allowableTypes,
		},
		isOpen,
		onClose,
		updateDeductions,
	} = props;

	const [isProcessing, setIsProcessing] = useState(false);
	const [employeesSheetOpen, setEmployeesSheetOpen] = useState<boolean>(false);

	const defaultPlanFormData = {
		startDate: {
			value: '',
			error: false,
			required: true,
			payloadName: 'planStartDate',
		},
		endDate: {
			value: '',
			error: false,
			required: type === 'health' || type === 'supplemental' || type === 'vision' || type === 'dental',
			payloadName: 'planEndDate',
		},
		beforeOrAfter: {
			value: 'after',
			error: false,
			required: false,
			payloadName: 'offsetDirection',
		},
		offsetAmount: {
			value: 1,
			error: false,
			required: false,
			payloadName: 'offsetAmount',
		},
		meetsValueStandard: {
			value: true,
			error: false,
			required: false,
			payloadName: 'minValueStandard',
		},
		meetsEssentialCoverage: {
			value: true,
			error: false,
			required: false,
			payloadName: 'minEssentialCoverage',
		},
	}

	const [planFormData, setPlanFormData] = useState<AddPlanFormDataObject>(defaultPlanFormData)

	const employeesLength = employees.length;
	const {
		showBeforeAndAfter,
		showAcaFields,
	} = allowableTypes.find((allowableType) => allowableType.type === type);

	const handleSheetClose = useCallback(() => setEmployeesSheetOpen(false), []);
	const handleSheetOpen = useCallback(() => setEmployeesSheetOpen(true), []);
	const handleFormDataChange = useCallback((name: string, value: string | number | boolean) => {
		const newFieldObject = cloneDeep(planFormData[name]);
		newFieldObject.value = value;
		newFieldObject.error = false;

		setPlanFormData((prevState) => ({ ...prevState, [name]: newFieldObject }));
	}, []);

	const handleModalClose = () => {
		setPlanFormData(defaultPlanFormData);
		onClose();
	}

	const handleDoneClickAction = () => {
		setIsProcessing(true);

		const payloadData = buildCreateBenefitPlanDeductionPayload(deduction, planFormData, type, setPlanFormData);

		if (!payloadData) {
			showSlidedown('Whoops... No worries. Please fix any missing or incorrect information and try again.', 'error');
			setIsProcessing(false);
			return;
		}

		createBenefitPlanDeduction(payloadData).then((response) => {
			if (response.status === 201 && response.data === 'Deductions Synced') {
				const updatedDeduction = cloneDeep(deduction);
				updatedDeduction.created = true;

				updateDeductions(updatedDeduction);
				setIsProcessing(false);
				handleModalClose();
			}
		}).catch((error) => {
			let errorMessage = responseMessages.saveBenefitPlanError(deduction.deductionName);

			if (get(error, 'response.status') === 409) {
				errorMessage = responseMessages.unableToSyncError;
			}

			showSlidedown(errorMessage, 'error');
			setIsProcessing(false);
		})
	}

	return (
		<StandardModal
			isOpen={ isOpen }
			onRequestClose={ handleModalClose }
		>
			<StandardModal.Body
				renderFooter={ (
					<StandardModal.Footer
						actions={ [<Button clickAction={ handleDoneClickAction } key="done" processing={ isProcessing } type="button">{ $.__('Done') }</Button>] }
					/>
				)}
				renderHeader={ <StandardModal.Header title={ $.__('Add This Plan to BambooHR') } /> }
				size={ StandardModal.Body.Size.MEDIUM }
			>
				<StandardModal.StandardHeadline
					// @ts-expect-error Update from  jade icon to encore Icon
					icon="fab-heart-ekg-28x24"
					text={ typeName }
				/>

				<StandardModal.Constraint>
					<div className={ styles.container }>
						<p className={ styles.tellUsMore }>
							{ $.__('Tell use a little more about your TRAXPayroll Deduction so we can set it up as a BambooHR Benefit plan') }.
						</p>
						<p className={ styles.planNameLabel }>{ $.__('Plan Name') }</p>
						<p>{ deductionName }</p>
						<p className={ styles.enrollmentInfo }>
							<Message
								link={ {
									tag: 'button',
									className: 'fab-TextButton fab-TextButton--small fab-TextButton--inline fab-Link',
									onClick: handleSheetOpen,
									type: 'button',
								} }
								params={ [employeesLength] }
								text={ $._n(
									'[{1} Employee] will be eligible and enrolled automatically in this plan',
									'[{1} Employees] will be eligible and enrolled automatically in this plan',
									employeesLength
								) }
							/>.
							&nbsp;
							{ $.__('If more people will be eligible in the future, you can add them in Benefit settings') }.
						</p>

						<AddBenefitPlanForm
							{ ...planFormData }
							onFieldChange={ handleFormDataChange }
							showAcaFields={ showAcaFields }
							showBeforeOrAfter={ showBeforeAndAfter }
						/>
					</div>
				</StandardModal.Constraint>

			</StandardModal.Body>

			<EnrolledEmployeesModal
				deductionName={ deductionName }
				employees={ employees }
				isOpen={ employeesSheetOpen }
				isSheetModal={ true }
				onClose={ handleSheetClose }
			/>
		</StandardModal>
	);

}
