export const TIME_TRACKING_NOTIFICATION_MODAL_DATA = {
	someEmployeeScheduledInFuture: {
		headline: $.__('Some Employees weren’t Enabled Now'),
		description: $.__('The following employees haven’t started working so they can’t start time tracking yet. You can see their time tracking status below. Individual start dates can be changed in their employee record.'),
	},
	someEmployeesNotEnabledNow: {
		headline: $.__('Some Employees weren’t Enabled Now'),
		description: $.__('The following employees haven’t started working so they can’t start time tracking yet. You can see their time tracking status below. Individual start dates can be changed in their employee record.'),
	},
	someEmployeesNotScheduledOnSelectedDate: {
		headline: $.__('Some Employees weren’t Scheduled on the Selected Date'),
		description: $.__('The following employees haven’t been scheduled on your selected date because their hire and compensation dates don’t align or their start date is outside the date you chose. You can see their time tracking status below. Individual start dates can be altered individually in the employee record.'),
	},
	someEmployeesNotRescheduled: {
		headline: $.__('Some Employees weren’t Rescheduled'),
		description: $.__('The following employees have not been rescheduled because they don’t have a current compensation date or their start date is outside the date you chose. These start dates can be altered individually in the employee record.'),
	},
	statuses: {
		'scheduled': $.__('Scheduled'),
		'not scheduled': $.__('Not Scheduled')
	},
	errors: {
		'dates do not align': $.__('Compensation Date and Hire Date don’t align.')
	}
};
