import Ctrl,
{
	onReady,
	onFooterAction,
} from 'ctrl.deco';

import { render } from 'base/wrapped-render';

import Ajax from '@utils/ajax';
import { footerStartProcessing, footerEndProcessing } from 'Form.util';

import { EditEmailForm } from './edit_new_hire_welcome_email/components/edit_email_form/edit_email_form';
import { EditEmailAPI, NewHirePacketAPI } from 'NHPTemplates.mod/API';
import { parseEmailFilterData } from 'NHPTemplates.mod/API/utils';
import { getFormSaveData } from 'NHPTemplates.mod/Form/utils';
import { WelcomeEmailPreviewer } from 'NHPTemplates.mod/WelcomeEmailPreviewer';
import BadgeComponent from 'badge.react';
import { EmploymentSubtitles } from 'badge.react/sub-components/employment-subtitles';
import { NewHirePacketTemplate } from './nhp-template';

import { showModal, closeModal } from 'show-modal.mod';

import './styles.styl';
import { getJsonData } from 'Data.util';

@Ctrl('/settings/tasklists/onboarding/welcome_email_template/')
class EditWelcomeEmail {
	@onReady()
	onReady(e, ctrl) {
		ctrl.emailFormData = {};
		ctrl.emailAPI = new EditEmailAPI(Ajax);
		ctrl.emailAPI.getCurrentEmailTemplate().then((data) => {
			ctrl.currentEmailTemplateData = data;
			ctrl.initiateForm(ctrl);
		});

		ctrl.updateFormData = ctrl.updateFormData.bind(ctrl);
	}

	initiateForm(ctrl) {
		const {
			body = '',
			defaulted = true,
			subject = '',
		} = ctrl.currentEmailTemplateData;

		ctrl.emailFormData = {
			body,
			subject,
		};

		render(<EditEmailForm
			body={ body }
			handleReset={ ctrl.emailAPI.getEmailDefaultTemplate }
			handleStateChange={ ctrl.updateFormData }
			isDefault={ defaulted }
			subject={ subject }
		/>, document.getElementById('EditEmailForm'));
	}

	updateFormData(emailFormData) {
		this.emailFormData = emailFormData;
	}

	@onFooterAction('Save')
	submitFormData(e, ctrl) {
		footerStartProcessing();
		const endPoint = (ctrl.emailFormData.isDefault) ? ctrl.emailAPI.DEFAULT_RESET_END_POINT : ctrl.emailAPI.DEFAULT_SAVE_END_POINT;
		const saveEmail = (ctrl.emailFormData.isDefault) ? ctrl.emailAPI.resetEmailTemplateToDefault : ctrl.emailAPI.saveEmailTemplate;
		saveEmail(endPoint, ctrl.emailFormData)
			.then((response) => {
				if (response.data.error) {
					footerEndProcessing();
					window.setMessage($.__('An error has occurred while saving, please try again'), 'error');
				} else {
					window.location.href = '/settings/onboarding/new_hire_packet_template?emailUpdated=1';
				}
			})
			.catch(() => {
				footerEndProcessing();
			});

	}

	@onFooterAction('Preview')
	previewEmailTemplate(e, ctrl) {
		footerStartProcessing();
		ctrl.emailAPI.previewEmailTemplate(ctrl.emailAPI.DEFAULT_PREVIEW_END_POINT, ctrl.emailFormData)
			.then((data) => {
				showEmailPreview(data);
			})
			.catch()
			.finally(() => {
				footerEndProcessing();
			});
	}
}

@Ctrl('/settings/onboarding/new_hire_packet_template/add')
class AddNHPTemplate {

	@onReady()
	onReady(e, ctrl) {
		ctrl.setupAPIS(ctrl);
		Promise.all([
			ctrl.packetAPI.getContactAndManagerInfo(`${ctrl.endpointRootTasks  }people`),
			ctrl.emailAPI.getCurrentEmailTemplate(ctrl.endpointRootEmail + ctrl.emailAPI.DEFAULT_CURRENT_END_POINT),
			ctrl.packetAPI.getGTKYFilterData(`${ctrl.endpointRootTasks  }gtkyoptions`),
		]).then((data) => {
			ctrl.emailData = data[1];
			const props = {
				emailFilterData: {
					selectData: data[2],
					selectedName: '',
					selectedParentId: '',
					selectedValues: [],
				},
				templateData: {
					contactOptions: data[0].employeeOptions,
				},
				logo: getJsonData('#logoData') || {},
				saveForm: ctrl.previewerSaveForm(ctrl),
			};

			ctrl.renderNHPTemplateForm(props, ctrl.emailPreviewer(ctrl));
			window.customFormChangedAction = () => { ctrl.saveForm(e, ctrl); };
		});
	}

	emailPreviewer(ctrl) {
		return (id, gtkyQuestions = []) => {
			ctrl.emailAPI.previewEmailTemplate(ctrl.endpointRootEmail + ctrl.emailAPI.DEFAULT_PREVIEW_END_POINT, ctrl.emailData)
				.then((data) => {
					showEmailPreview(data, gtkyQuestions);
				})
				.catch();
		};
	}

	renderNHPTemplateForm(props, emailPreviewer) {
		render(<NewHirePacketTemplate { ...props } emailPreviewer={ emailPreviewer } />, document.getElementById('js-EditTemplateForm'));
	}

	setupAPIS(ctrl) {
		ctrl.endpointRootTasks = '/settings/onboarding/new_hire_packet/';
		ctrl.endpointRootTemplate = '/settings/onboarding/new_hire_packet_template/';
		ctrl.endpointRootEmail = '/settings/tasklists/onboarding/welcome_email_template/';
		ctrl.emailAPI = new EditEmailAPI(Ajax);
		ctrl.packetAPI = new NewHirePacketAPI(Ajax);
	}

	@onFooterAction('Preview')
	previewForm(e, ctrl) {
		document.dispatchEvent(new Event('nhpTemplate:name:showPreview'));
	}

	@onFooterAction('Save')
	saveForm(e, ctrl) {
		footerStartProcessing();
		const saveData = getFormSaveData();
		saveData.createdUserId = window.SESSION_USER.id;
		if (!ctrl.validateData(saveData)) {
			window.setMessage('Whoops... No worries. Please fix any missing or incorrect information and try again.', 'error');
			footerEndProcessing();
			return;
		}

		ctrl.packetAPI.saveTemplate(`${ ctrl.endpointRootTemplate }create`, saveData).then((data) => {
			if (!data?.savedNHPTemplate) {
				footerEndProcessing();
				window.setMessage('An error has occurred while saving please try again later', 'error');
				return;
			}
			window.location.href = '/settings/onboarding/new_hire_packet_template?saved=1';
		});
	}

	previewerSaveForm(ctrl) {
		return () => this.saveForm(null, ctrl);
	}

	validateData(data) {
		const {
			name,
		} = data;

		if (name && name !== '' && typeof name === 'string') {
			return true;
		}

		document.dispatchEvent(new Event('nhpTemplateMissingData:name:error'));

		return false;
	}
}

@Ctrl('/settings/onboarding/new_hire_packet_template/addInitialTemplate')
class AddInitialTemplate extends AddNHPTemplate {
	@onReady()
	onReady(e, ctrl) {
		ctrl.setupAPIS(ctrl);
		Promise.all([
			ctrl.packetAPI.getContactAndManagerInfo(`${ctrl.endpointRootTasks  }people`),
			ctrl.emailAPI.getCurrentEmailTemplate(ctrl.endpointRootEmail + ctrl.emailAPI.DEFAULT_CURRENT_END_POINT),
			ctrl.packetAPI.getGTKYFilterData(`${ctrl.endpointRootTasks  }gtkyoptions`),
			ctrl.packetAPI.getTemplates(`${ctrl.endpointRootTemplate  }list`)
		]).then((data) => {
			ctrl.emailData = data[1];
			const defaultTemplateKey = 'default';
			const defaultTemplate = data[3][defaultTemplateKey] || {};
			const templateData = {
				arriveByTime: defaultTemplate.arriveByTime || undefined,
				contactEmployeeId: defaultTemplate.contactEmployeeId || undefined,
				contactOptions: data[0].employeeOptions,
				getToKnowYouEmailTemplateId: defaultTemplate.getToKnowYouEmailTemplateId || undefined,
				gtkyQuestions: defaultTemplate.gtkyQuestions || [],
				gtkyRecipients: defaultTemplate.gtkyRecipients || [],
				location: defaultTemplate.location || undefined,
				otherInstructions: defaultTemplate.otherInstructions || undefined,
				sendGetToKnowYouEmail: defaultTemplate.sendGetToKnowYouEmail || undefined
			};

			const emailFilter = templateData.gtkyRecipients || [];

			const {
				selectedFilters,
				selectedParentId,
			} = parseEmailFilterData(emailFilter);
			const props = {
				emailFilterData: {
					selectData: data[2],
					selectedName: selectedParentId,
					selectedParentId,
					selectedValues: selectedFilters,
				},
				templateData,
				logo: getJsonData('#logoData') || {},
				saveForm: ctrl.previewerSaveForm(ctrl),
			};

			ctrl.renderNHPTemplateForm(props, ctrl.emailPreviewer(ctrl));
		});

	}

	@onFooterAction('Preview')
	previewForm(e, ctrl) {
		super.previewForm(e, ctrl);
	}

	@onFooterAction('Save')
	saveForm(e, ctrl) {
		super.saveForm(e, ctrl);
	}
}

@Ctrl('/settings/onboarding/new_hire_packet_template/edit/*')
class EditNHPTemplate extends AddNHPTemplate {
	getTemplateData(ctrl, pathData) {
		return Promise.all([
			ctrl.packetAPI.getContactAndManagerInfo(`${ctrl.endpointRootTasks  }people`),
			ctrl.emailAPI.getCurrentEmailTemplate(ctrl.endpointRootEmail + ctrl.emailAPI.DEFAULT_CURRENT_END_POINT),
			ctrl.packetAPI.getTemplateData(pathData.join('/')),
			ctrl.packetAPI.getGTKYFilterData(`${ctrl.endpointRootTasks  }gtkyoptions`),
		]).then((data) => {
			ctrl.emailData = data[1];
			const templateData = Object.assign({
				contactOptions: data[0].employeeOptions,
			}, data[2]);
			const emailFilter = templateData.gtkyRecipients;

			const {
				selectedFilters,
				selectedParentId,
			} = parseEmailFilterData(emailFilter);

			return {
				emailFilterData: {
					selectData: data[3],
					selectedName: selectedParentId,
					selectedParentId,
					selectedValues: selectedFilters,
				},
				templateData,
				logo: getJsonData('#logoData') || {},
				saveForm: ctrl.previewerSaveForm(ctrl),
			};
		});
	}

	@onReady()
	ready(e, ctrl) {
		ctrl.setupAPIS(ctrl);
		const pathData = window.location.pathname.split('/');
		pathData.splice(pathData.indexOf('edit'), 1);

		ctrl.getTemplateData(ctrl, pathData).then((props) => {
			ctrl.renderNHPTemplateForm(props, ctrl.emailPreviewer(ctrl));
			window.customFormChangedAction = () => { ctrl.saveForm(e, ctrl); };
		});
	}

	@onFooterAction('Preview')
	previewForm(e, ctrl) {
		document.dispatchEvent(new Event('nhpTemplate:name:showPreview'));
	}

	@onFooterAction('Save')
	saveForm(e, ctrl) {
		footerStartProcessing();
		const saveData = getFormSaveData();
		saveData.createdUserId = window.SESSION_USER.id;
		if (!ctrl.validateData(saveData)) {
			window.setMessage('Whoops... No worries. Please fix any missing or incorrect information and try again.', 'error');
			footerEndProcessing();
			return;
		}

		const templateId = saveData.id;
		delete (saveData.id);

		ctrl.packetAPI.saveTemplate(`${ ctrl.endpointRootTemplate }${ templateId }`, saveData, 'put').then((data) => {
			if (!data?.savedNHPTemplate) {
				footerEndProcessing();
				window.setMessage('An error has occurred while saving please try again later', 'error');
				return;
			}
			window.location.href = '/settings/onboarding/new_hire_packet_template?updated=1';
		});
	}

	previewerSaveForm(ctrl) {
		return () => this.saveForm(null, ctrl);
	}
}

@Ctrl('/settings/onboarding/new_hire_packet_template/duplicate/*')
class DuplicateNHPTemplate extends EditNHPTemplate {
	@onReady()
	ready(e, ctrl) {
		ctrl.setupAPIS(ctrl);
		const pathData = window.location.pathname.split('/');
		pathData.splice(pathData.indexOf('duplicate'), 1);

		ctrl.getTemplateData(ctrl, pathData).then((props) => {
			const {
				arriveByTime,
				contactEmployeeId,
				contactOptions,
				createdUserId,
				getToKnowYouEmailTemplateId,
				gtkyQuestions,
				gtkyRecipients,
				location,
				name,
				otherInstructions,
				sendGetToKnowYouEmail,
			} = props.templateData;

			props.templateData = {
				arriveByTime: arriveByTime || undefined,
				contactEmployeeId: contactEmployeeId || undefined,
				contactOptions: contactOptions || undefined,
				createdUserId: createdUserId || undefined,
				getToKnowYouEmailTemplateId: getToKnowYouEmailTemplateId || undefined,
				gtkyQuestions: gtkyQuestions || undefined,
				gtkyRecipients: gtkyRecipients || undefined,
				location: location || undefined,
				name: `${ name } ${ $.__('copy') }`,
				otherInstructions: otherInstructions || undefined,
				sendGetToKnowYouEmail: sendGetToKnowYouEmail || undefined,
			};

			ctrl.renderNHPTemplateForm(props, ctrl.emailPreviewer(ctrl));
		});
	}

	@onFooterAction('Preview')
	previewForm(e, ctrl) {
		super.previewForm(e, ctrl);
	}

	@onFooterAction('Save')
	saveForm(e, ctrl) {
		footerStartProcessing();
		const saveData = getFormSaveData();
		saveData.createdUserId = window.SESSION_USER.id;
		if (!ctrl.validateData(saveData)) {
			window.setMessage('Whoops... No worries. Please fix any missing or incorrect information and try again.', 'error');
			footerEndProcessing();
			return;
		}

		ctrl.packetAPI.saveTemplate(`${ ctrl.endpointRootTemplate }create`, saveData).then((data) => {
			if (!data?.savedNHPTemplate) {
				footerEndProcessing();
				window.setMessage('An error has occurred while saving please try again later', 'error');
				return;
			}
			window.location.href = '/settings/onboarding/new_hire_packet_template?saved=1';
		});
	}
}

function showEmailPreview(emailData, gtkyQuestions = []) {
	const {
		body,
		errorCode,
		subject,
	} = emailData;

	if (errorCode) {
		window.setMessage($.__('An error has occurred while getting the preview, please try again later'), 'error');
	} else {

		const employeeData = {
			fullName: 'Astro Panda',
			firstName: 'Astro',
			jobTitle: 'Mission Commander',
		};

		const showQuestionPlaceholder = gtkyQuestions.length === 0;

		const badge = (
			<BadgeComponent
				imageAlt="Image of employee"
				imageSrc="/images/settings/tasklists/onboarding/welcome_email_template/astro-panda-90x90.png"
				isModalHeader={ false }
				theme="newHire"
				title={ employeeData.fullName }
			>
				<EmploymentSubtitles jobTitle={ employeeData.jobTitle } location="Cape Canaveral" />
			</BadgeComponent>
		);

		showModal(<WelcomeEmailPreviewer
			body={ body }
			employeeBadgeComponent={ badge }
			employeeData={ employeeData }
			getToKnowYouQuestions={ gtkyQuestions }
			isOpen={ true }
			primaryAction={ closeModal }
			showQuestionsPlaceholder={ showQuestionPlaceholder }
		/>);
	}
}
