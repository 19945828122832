import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(() => {
	return {
		root: {
			display: 'flex',
			paddingTop: 24,
			minHeight: 64,
		},
		rowContainer: {
			'& > p': {
				marginBottom: 12,
			},
		},
		values: {
			marginLeft: 24,
		},
	};
});
