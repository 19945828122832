import {
	CardSize,
	Icon,
	Interstitial,
} from '@bamboohr/fabric';
import { TosConsentForm } from '../../shared/tos-consent-form.react';

import './styles.styl';

export function ConsentOnlyInterstitial(props) {
	const {
		consentCopy,
		continueLink,
	} = props;

	const headerContent = $.__("We use a service called Northpass for our BambooHR Learning Courses. To continue, you'll need to agree to our Northpass Terms of Use.");

	return (
		<Interstitial cardSize={ CardSize.SMALL }>
			<div className="ConsentOnlyInterstitial">
				<Icon brand={ true } name="fab-agreement-alert-44x54" />
				<h4 className="ConsentOnlyInterstitial__header">{ headerContent }</h4>
				<TosConsentForm
					consentCopy={ consentCopy }
					continueLink={ continueLink }
				/>
			</div>
		</Interstitial>
	);
}
