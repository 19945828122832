import { getParameterByName, extendURL } from 'BambooHR.util';
import { Dispatch, FormEvent, useCallback, useEffect, useReducer, useRef } from 'react';
import { reducer } from './reducer';
import { DispatchAction, SubmitSurveyData, UseEmployeeWellbeingSurveyArguments, UseEmployeeWellbeingSurveyReturn, ReducerAction, EmployeeWellbeingSurveyState, HistoryHook } from '../types';
import { AxiosError, CancelTokenSource } from 'axios';
import Ajax from '@utils/ajax';

/**
 * Adjusts question query param by question delta
 * @param questionDelta number that is added to current question number
 */
const handleURLUpdate = (questionDelta: number, questionNumber: number) => {
	return extendURL({ question: questionNumber + questionDelta }, window.location.href);
};

const defaultHistoryHook: HistoryHook = () => {
	return {
		push: () => undefined
	};
};

const defaultUpdateQuestonUrl = () => '';

const useEmployeeWellbeingSurvey = ({service, surveyId, useHistoryHook = defaultHistoryHook, updateQuestionUrl = defaultUpdateQuestonUrl}: UseEmployeeWellbeingSurveyArguments): UseEmployeeWellbeingSurveyReturn => {
	const cancelTokenSourceRef = useRef<CancelTokenSource>(null);
	const [state, dispatch]: [EmployeeWellbeingSurveyState, Dispatch<ReducerAction>] = useReducer(reducer, {
		question: {},
		submitted: false,
		closed: false,
		answer: null,
		freeResponse: '',
		totalQuestions: 4,
		loaded: false,
	} as EmployeeWellbeingSurveyState);

	const updateQuestionService = useCallback(() => {
		cancelTokenSourceRef.current = Ajax.createCancelSource();
		service.getQuestion(updateQuestionUrl(surveyId, getParameterByName('lock') as string, getParameterByName('question') ? parseInt(getParameterByName('question') as string) : 1), cancelTokenSourceRef.current?.token).then(data => dispatch({
			type: 'UPDATE_QUESTION',
			payload: data
		}));
	}, [surveyId, service, updateQuestionUrl]);

	useEffect(() => {
		service.getData().then(data => dispatch({
			type: 'INIT_STATE',
			payload: data
		}));
	}, [service]);

	const {
		answer,
		freeResponse,
		questionNumber,
		nextQuestionUrl,
		previousQuestionUrl,
		closed,
		submitted,
		canContinue,
	} = state;

	const isOpen = !(closed || submitted);
	const history = useHistoryHook(updateQuestionService, isOpen);

	const generateSaveData = ({score = answer}: {score?: number}): SubmitSurveyData => {
		const lock = getParameterByName('lock') as string;
		const channel = getParameterByName('channel') as string;
		const saveData: SubmitSurveyData = {
			score,
			freeResponse,
			question: questionNumber,
			lock,
			surveyId,
			channel,
		};
		return saveData;
	};

	const handleRequestError = (error: AxiosError) => {
		if (!Ajax.isCancelledReason(error)) {
			dispatch({ type: 'SERVER_ERROR' });
		}
	};

	const updateQuestionData = (url: string, questionDelta: number) => {
		cancelTokenSourceRef.current = Ajax.createCancelSource();
		service.getQuestion(url, cancelTokenSourceRef.current.token)
			.then(data => {
				dispatch({
					type: 'UPDATE_QUESTION',
					payload: data
				});
				const url = handleURLUpdate(questionDelta, questionNumber);
				history.push(url);
			})
			.catch(handleRequestError);
	};
	
	const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		cancelTokenSourceRef.current = Ajax.createCancelSource();
		dispatch({ type: 'START_PENDING_UPDATE' });
		event.preventDefault();
		const saveData = generateSaveData({});

		if (nextQuestionUrl) {
			return service.saveResponse(saveData, cancelTokenSourceRef.current.token)
				.then(() => updateQuestionData(nextQuestionUrl, 1))
				.catch(handleRequestError);
		}
		service.submit(saveData, cancelTokenSourceRef.current.token)
			.then(() => dispatch({
				type: 'SUBMIT_SURVEY'
			}))
			.catch(handleRequestError);
	};

	const handleBackButtonClick = () => {
		if (cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current.cancel();
		}
		cancelTokenSourceRef.current = Ajax.createCancelSource();
		dispatch({ type: 'START_PENDING_UPDATE' });
		// TODO: clean up error handling once the 400 that gets thrown, when no answer is provided is resolved by BE.
		return service.saveResponse(generateSaveData({}), cancelTokenSourceRef.current.token)
			.then(() => updateQuestionData(previousQuestionUrl, -1))
			.catch((error: AxiosError) => {
				if (error.response?.data?.message === 'The provided score is invalid: ') {
					updateQuestionData(previousQuestionUrl, -1);
					return;
				}
				handleRequestError(error);
			});
	};

	const handleUpdatingAnswer = (action: ReducerAction) => {
		dispatch(action);
	};

	const dispatchUpdate = (action: DispatchAction): void => {
		const {type, payload} = action;
		switch (type) {
			case 'UPDATE_ANSWER':
				handleUpdatingAnswer(action as ReducerAction);
				break;
			case 'UPDATE_RESPONSE':
				dispatch(action as ReducerAction);
				break;
			case 'SUBMIT_ANSWER':
			case 'NEXT_QUESTION':
				handleFormSubmit(payload as FormEvent<HTMLFormElement>);
				break;
			case 'PREVIOUS_QUESTION':
				handleBackButtonClick();
				break;
			case 'CLOSE_SURVEY':
				dispatch(action as ReducerAction);
				break;
			default:
				break;
		}
	};

	return {
		...state,
		dispatchUpdate
	};    
};

export default useEmployeeWellbeingSurvey;
