import { ifFeature } from '@bamboohr/utils/lib/feature';

const BAMBOO_SOURCE = 'bamboohr';

export const columns = [
	{
		header: $.__('Source Name'),
		cell: (row) => row.name,
	},
	{
		showOnHover: true,
		width: '120px',
		cell: {
			type: 'actions',
			actions: (row) => {
				// Bamboo is the default candidate source if no other source is provided
				// Because of this, we don't want to let customers delete it
				if (row.name.toLowerCase() === BAMBOO_SOURCE) {
					return [];
				}
				return [
					{
						ariaLabelledBy: 'delete-source',
						icon: ifFeature('encore', 'trash-regular', 'fab-trash-can-14x16'),
						action: () => {
							row.action(row);
						},
						tooltipContent: $.__('Delete Source'),
					},
				];
			},
		},
	},
];
