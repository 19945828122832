import Popover from 'Popover.mod';

export default function createNavigationPopover(anchor, page?: string) {
	return Popover.create(anchor, {
		template: {
			name: 'popover-standard',
			data: {
				content: $.__('Return to Time Tracking to continue the Setup Checklist.'),
				title: page ? $.__('Finished With %s?', page) : $.__('Done reviewing this page?'),
			},
		},
		showImmediately: true,
		persistent: true,
		tailOnBalloon: 60,
		onHide: (instance) => {
			instance.destroy();
		}
	});
}
